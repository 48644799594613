import React from 'react'

const PunchIcon = ({
    size = undefined,
    color = undefined,
    ...props
}) => (
    <svg
        {...props}
        viewBox='0 0 512 512'
        width={size}
        height={size}
        stroke={color}
    >
        <path d="M221.6 28.53c-5.2.1-10.6 2.18-15.8 6.35-7.7 6.03-14.4 16.44-17.8 29.58-3.3 13.14-2.5 26.2 1.1 36.24 3.6 9.9 9.4 16.4 16.6 18.9 6 2.1 12.6 1.3 19-2.6 2.3-13.6 9.9-24.28 21.3-31.6.1-.19.1-.38.2-.58 3.3-13.15 2.5-26.2-1.1-36.2-3.6-9.95-9.4-16.46-16.6-18.97-2.3-.78-4.6-1.16-6.9-1.12zm212.5 49.31c-3.2.72-11 3.44-18.7 7.15-8.7 4.14-17.4 8.86-22.4 11.5l-.9.45-.9.25c-38.1 10.61-57.5 17.91-88.3 23.21-2.2 6.1-6 12.5-11.3 19h-.1v.1c-6.2 7.4-12.4 14.7-18.7 21.9 45.8-8.5 78.8-16.7 132.7-38l4.1-1.6 3.9 2.3c5.5 3.4 13.4 5.8 19.6 6.3 3.1.3 5.8 0 7.4-.4 1.4-.4 1.4-.7 1.4-.6 5.4-11.9 7.3-24.4 5.5-33.8-1.7-8.89-5.7-14.64-13.3-17.76zM272.2 96.08c-8.9-.1-20.1 4.32-27.6 15.62-5.9 29.5-26.7 50.1-57.2 62.6l-6.8-16.6c12.7-5.2 22.6-11.2 30.1-18.6-3.1-.2-6.3-.9-9.4-2-10.4-3.7-18.6-11.6-24.1-21.4-14.6 2.8-22.6 6.3-32.8 14.6-4.1 36.5 6.8 67.5 27.6 96.6 41.7-25.4 73.7-60.5 105.7-98.9 8.2-10.2 10-17.6 9.5-21.9-.6-4.2-2.7-6.73-7.2-8.58-2.2-.91-4.9-1.42-7.8-1.44zM131.5 185.5c-1.7 23.2-.7 48.5-1 75v3.8l-2.7 2.6C75.51 317.5 33.9 400.5 20.36 495.7h40.62c9.12-41.2 17.47-82.3 34.69-115.7 18.23-35.3 47.83-62 96.23-67.5l4.8-.6 3.1 3.7c38.2 45.7 75.3 103.9 85.9 180.1h39.6c-6.3-88.4-46.8-156.5-97.1-229-9.8-14.1-10.2-29.8-6.5-44.9.9-3.8 2.1-7.7 3.4-11.7-15.5 13.6-32.3 26-51 36.7l-6.9 3.9-4.7-6.2c-14-18.2-24.8-37.8-31-59z" fill={color}></path>
    </svg>
)

PunchIcon.displayName = 'PunchIcon'

PunchIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
}

export default PunchIcon 
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { useState, FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Box, Card, Grid, Heading, Stack } from "./primitives";
import { Button } from "./Button";
import { motion } from "framer-motion";

import { Campaign, VTTStore, CampaignSummary, CampaignInit } from "../store";

import Identity from "./Identity";
import { useErrorHandler } from "./utils";
import { Loading } from "./common";
import { ScrollableTest } from "./ScrollableTest";
import { CampaignSummaryCard } from "./CampaignSummary/CampaignSummaryCard";
import { ColumnGrid } from "./primitives/ColumnGrid";
import { CampaignSummaryDetails } from "./CampaignSummary/CampaignSummaryDetails";

const itemVariants = {
    hidden: {
        opacity: 0,
        translateY: 8,
        filter: "grayscale(100%)",
    },
    visible: i => ({
        opacity: 1,
        translateY: 0,
        transition: {
            delay: i * 0.05,
        },
    }),
    visiblePrimary: {
        opacity: 1,
        translateY: 0,
        filter: "grayscale(0%)",
    },
};

const mapStateToProps = (state: VTTStore) => state.userInfo;
const Home: FunctionComponent<ReturnType<typeof mapStateToProps>> = props => {
    const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
    const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false);
    const [campaigns, setCampaigns] = useState<CampaignSummary[]>([]);
    const [latestCampaign, setLatestCampaign] = useState<CampaignSummary | null>(null);
    const { handleResponse } = useErrorHandler();
    const history = useHistory();

    useEffect(() => {
        setIsLoadingCampaigns(true);
        (async () => {
            const campaignsResponse = await fetch("api/campaigns/all");
            if (handleResponse(campaignsResponse, () => `Failed to load campaigns:\n${campaignsResponse.statusText}`)) {
                const c = (await campaignsResponse.json()) as CampaignSummary[];

                // For testing when there's no campaigns yet:
                // const c: CampaignSummary[] = [];

                // Get the most recent campaign
                if (c != null && c.length) {
                    const latest = c.reduce((a, b) => {
                        return (a.lastSeen ?? 0) > (b.lastSeen ?? 0) ? a : b;
                    });

                    setLatestCampaign(latest);
                    setCampaigns(c.filter(n => n.id !== latest.id));
                }
            }

            setIsLoadingCampaigns(false);
        })();
    }, [handleResponse]);

    if (!props.profile) {
        return <Box>Please log in.</Box>;
    }

    const createNewCampaign = async () => {
        setIsCreatingCampaign(true);

        let campaignNoId: CampaignInit = {
            label: "New campaign",
            locations: {},
            players: {},
            tokens: {},
            handouts: {},
            gameTime: 0,
        };

        let response = await fetch("api/campaigns/add", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(campaignNoId),
        });
        if (handleResponse(response, () => `Campaign creation failed:\n${response.statusText}`)) {
            let campaign = (await response.json()) as Campaign;
            history.push(`/campaign/${campaign.id}`);
        } else {
            setIsCreatingCampaign(false);
        }
    };

    return (
        <ColumnGrid
            css={{
                gridArea: "main",
                "&::before": {
                    content: "''",
                    width: "100vw",
                    height: "100vh",
                    opacity: 0.3,
                    backgroundImage: `url(CampaignCover${Math.floor(Math.random() * 10) + 1}.jpg)`, //radial-gradient(transparent, black),
                    backgroundSize: "cover",
                    filter: "blur(2px)",
                    boxShadow: "inset 0 0 10vw 10vw var(--c-background)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                },
            }}
            gridTemplateRows="auto 1fr"
            placeItems="start"
            bg="grayscale.8"
            gridRowGap={6}
            px={4}
            py={5}>
            <Stack as="header" flexDirection="column" fullWidth css={{ gridColumn: "span 12" }} gap={3}>
                <Identity />
                <Stack alignItems="flex-start" fullWidth gap={3}>
                    <Box fullWidth justifyContent="space-between">
                        <Heading as="h2" fontWeight="bold" color="grayscale.4" css={{ textShadow: "black 0 0 12px" }}>
                            Your Campaigns
                        </Heading>
                        {!isCreatingCampaign && (
                            <Button variant="primary" onClick={createNewCampaign}>
                                Create a new campaign
                            </Button>
                        )}
                        {isCreatingCampaign && <Box>Creating new campaign...</Box>}
                    </Box>
                </Stack>
            </Stack>

            {isLoadingCampaigns && <Loading />}

            <Grid
                fullWidth
                fullHeight
                placeItems="start"
                gridTemplateColumns="repeat(12, 1fr)"
                gridColumnGap={6}
                css={{
                    gridColumn: "span 12",
                }}>
                {!isLoadingCampaigns && (
                    <React.Fragment>
                        <motion.div
                            variants={itemVariants}
                            custom={0}
                            initial="hidden"
                            animate="visiblePrimary"
                            css={{
                                gridArea: "1 / 1 / span 1 / span 4",
                                width: "100%",
                                height: "100%",
                            }}>
                            {latestCampaign && (
                                <NavLink
                                    to={`/campaign/${latestCampaign.id}`}
                                    css={{
                                        "&:hover": {
                                            color: "unset",
                                            textDecoration: "none",
                                        },
                                    }}>
                                    <CampaignSummaryCard campaign={latestCampaign} full />
                                </NavLink>
                            )}
                            {!latestCampaign && (
                                <Button variant="clean" css={{ height: "auto" }} onClick={createNewCampaign}>
                                    <Card
                                        borderRadius={4}
                                        boxShadowSize="l"
                                        minHeight="50vh"
                                        css={{
                                            transition: "transform 90ms ease-out",
                                            "&::before": {
                                                opacity: 0,
                                            },
                                            "&:hover": {
                                                "&::before": {
                                                    opacity: 0.5,
                                                },
                                            },
                                        }}
                                        bg="background">
                                        {/* TODO: This should allow you to edit the details of the campaign before creating it. Select the cover image, select the system, etc.
                                        Whether that's inline or in some kind of wizard thing, which also appears when you click the "Create a new campaign" button is TBD. */}
                                        <CampaignSummaryDetails
                                            campaign={{
                                                id: "__new__",
                                                gameTime: 0,
                                                label: "New campaign",
                                                coverImageUrl: `CampaignCover1.jpg`,
                                                description:
                                                    "You don't currently have any campaigns. Click here to create one!",
                                            }}
                                            full
                                        />
                                    </Card>
                                </Button>
                            )}
                        </motion.div>

                        {/* Other campaigns */}
                        <ScrollableTest
                            fullWidth
                            fullHeight
                            minimal
                            style={{
                                gridArea: "1 / 5 / span 1 / span 8",
                            }}>
                            <Grid fullWidth gridTemplateColumns="repeat(6, 1fr)" gridGap={5}>
                                {campaigns.map((campaign, i) => (
                                    <motion.div
                                        key={campaign.id}
                                        custom={i + 1}
                                        variants={itemVariants}
                                        initial="hidden"
                                        animate="visible"
                                        whileHover={{
                                            filter: "grayscale(0%)",
                                        }}
                                        css={{
                                            gridColumn: "span 2",
                                        }}>
                                        <NavLink
                                            to={`/campaign/${campaign.id}`}
                                            css={{
                                                "&:hover": {
                                                    color: "unset",
                                                    textDecoration: "none",
                                                },
                                            }}>
                                            <CampaignSummaryCard key={campaign.id} campaign={campaign} />
                                        </NavLink>
                                    </motion.div>
                                ))}
                            </Grid>
                        </ScrollableTest>
                    </React.Fragment>
                )}
            </Grid>
        </ColumnGrid>
    );
};

export default connect(mapStateToProps)(Home);

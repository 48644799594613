import * as THREE from "three";
import { Vector3, Matrix4, InstancedBufferAttribute } from "three";
import { ParticleMaterial, ZoneParticleSystem, useZoneParticleSystem } from "./particlesystem";
import { LocalPixelPosition, LocalRect, Size } from "../../../position";
import { MutableRefObject } from "react";
import { IGrid, degToRad } from "../../../grid";
import { Location } from "../../../store";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";

import { LevelInfo, VttCameraLayers } from "../common";

const tempPoint = new Vector3();
const tempMatrix = new Matrix4();
const tempQuaternion = new THREE.Quaternion();

const MAX_PARTICLES = 30000;

export enum RainLevel {
    Drizzle = 0,
    Light = 300,
    Heavy = 700,
}

abstract class RainParticleSystemBase extends ZoneParticleSystem {
    private _minVelocity: number | undefined;
    private _maxVelocity: number | undefined;
    private _particleCount: number = 0;
    private _maxHeight: number = 0;
    private _level: RainLevel | undefined;

    private _lateralVelocityRange: number | undefined;
    private _halfLateralVelocityRange: number | undefined;

    get particleCount() {
        return this._particleCount;
    }

    get maxParticleHeight() {
        return this._maxHeight;
    }

    get rainLevel() {
        return this._level;
    }

    protected onTileWidthChanged(tileWidth: number) {
        this._maxHeight = tileWidth * ZoneParticleSystem.MAX_HEIGHT_GRID;
        this._lateralVelocityRange = tileWidth * 0.2;
        this._halfLateralVelocityRange = this._lateralVelocityRange / 2;
    }

    protected override onRateChanged(): void {
        // Drizzling rain falls between 0.7-2m/s (this.tileWidth * 0.4)-(this.tileWidth * 1.4)
        // Medium rain falls around 2-9m/s (this.tileWidth * 1.4)-(this.tileWidth * 6)
        // Heavy rain falls around 9m/s (this.tileWidth * 6)
        // Adjusted below for feel.
        if (this.particlesPerGridUnitPerMinute < RainLevel.Light) {
            // Drizzle
            this._minVelocity = this.tileWidth * 1.4;
            this._maxVelocity = this.tileWidth * 3;
            this._level = RainLevel.Drizzle;
        } else if (this.particlesPerGridUnitPerMinute < RainLevel.Heavy) {
            // Light
            this._minVelocity = this.tileWidth * 3;
            this._maxVelocity = this.tileWidth * 6;
            this._level = RainLevel.Light;
        } else {
            // Heavy
            this._minVelocity = this.tileWidth * 5;
            this._maxVelocity = this.tileWidth * 7;
            this._level = RainLevel.Heavy;
        }

        if (this.zoneArea) {
            const particlesPerGridPerMinute = this.particlesPerGridUnitPerMinute;
            const particlesPerGridPerSecond = particlesPerGridPerMinute / 60;

            this._particleCount = Math.min(
                Math.round(this.zoneArea * particlesPerGridPerSecond * (ZoneParticleSystem.MAX_HEIGHT_GRID / 10)),
                MAX_PARTICLES
            );

            this.updateGeometry();
        }
    }

    protected abstract updateGeometry();

    /**
     * Gets a random velocity suitable for the current rain level.
     */
    protected setRainVelocity(v: Vector3) {
        const range = this._maxVelocity! - this._minVelocity!;
        v.set(
            Math.random() * this._lateralVelocityRange! - this._halfLateralVelocityRange!,
            Math.random() * this._lateralVelocityRange! - this._halfLateralVelocityRange!,
            -(Math.random() * range + this._minVelocity!)
        );

        v.set(v.x + this.windLocal.x, v.y + this.windLocal.y, v.z);
    }
}

const positionTempPoint = new Vector3();

class RainParticleSystem extends RainParticleSystemBase {
    private _rainMesh: THREE.InstancedMesh | undefined;
    private _rainGeometry: THREE.BufferGeometry | undefined;
    private _rainGeometryLevel: RainLevel | undefined;
    private _rainMaterial: ParticleMaterial | undefined;
    private _texture: THREE.Texture | undefined;

    private _velocities: Vector3[] = [];

    protected override async startCore() {
        const loader = new THREE.TextureLoader();
        this._texture = await loader.loadAsync("raindrop.png");

        this.recreateGeometry();
        this._rainMaterial = new ParticleMaterial({
            color: 0xaaaaaa,
            map: this._texture,
            opacity: 0.7,
            transparent: true,
            side: THREE.DoubleSide,
            depthWrite: false,
        });

        this._rainMesh = new THREE.InstancedMesh(this._rainGeometry, this._rainMaterial, MAX_PARTICLES);
        this._rainMesh.renderOrder = 9999;
        this._rainMesh.layers.set(VttCameraLayers.PerspectiveLighting);
        this.updateGeometry();

        this.scene.add(this._rainMesh);
    }

    protected updateCore(delta: number) {
        if (this._rainMaterial) {
            const maxHeight = this.maxParticleHeight;
            const count = this._rainMesh!.count;
            const bounds = this.bounds!;

            this.updateParticleMaterialUniforms(this._rainMaterial);

            for (let i = 0; i < count; i++) {
                const velocity = this._velocities[i];
                this._rainMesh!.getMatrixAt(i, tempMatrix);
                positionTempPoint.setFromMatrixPosition(tempMatrix);
                positionTempPoint.set(
                    positionTempPoint.x + delta * velocity.x,
                    positionTempPoint.y + delta * velocity.y,
                    positionTempPoint.z + delta * velocity.z
                );

                // If the particle has gone out of bounds, wrap it around.
                if (positionTempPoint.x > bounds.x + bounds.width) {
                    positionTempPoint.setX(positionTempPoint.x - bounds.width);
                } else if (positionTempPoint.x < bounds.x) {
                    positionTempPoint.setX(positionTempPoint.x + bounds.width);
                }

                if (-positionTempPoint.y > bounds.y + bounds.height) {
                    positionTempPoint.setY(positionTempPoint.y + bounds.height);
                } else if (-positionTempPoint.y < bounds.y) {
                    positionTempPoint.setY(positionTempPoint.y - bounds.height);
                }

                tempPoint.copy(velocity);

                if (positionTempPoint.z < 0) {
                    // This particle has hit the ground, respawn it.
                    this.getPointInBounds(maxHeight, maxHeight, positionTempPoint);
                    positionTempPoint.setY(-positionTempPoint.y);
                    this.setRainVelocity(velocity);
                    tempPoint.copy(velocity);
                    tempQuaternion.setFromUnitVectors(new Vector3(0, -1, 0), tempPoint.normalize());
                    tempMatrix.makeRotationFromQuaternion(tempQuaternion);
                    tempMatrix.setPosition(positionTempPoint.x, positionTempPoint.y, positionTempPoint.z);
                    this._rainMesh!.setMatrixAt(i, tempMatrix);
                } else {
                    tempMatrix.setPosition(positionTempPoint.x, positionTempPoint.y, positionTempPoint.z);
                    this._rainMesh!.setMatrixAt(i, tempMatrix);
                }
            }

            this._rainMesh!.instanceMatrix.needsUpdate = true;
        }
    }

    protected override stopCore() {
        if (this._rainMesh) {
            this.scene.remove(this._rainMesh);
        }

        this._velocities.length = 0;
        delete this._rainMesh;
        delete this._rainGeometry;
        delete this._rainMaterial;
        delete this._texture;
    }

    protected override updateGeometry() {
        if (this._rainMesh) {
            const opacities = this._rainGeometry!.getAttribute("instanceOpacity") as InstancedBufferAttribute;

            if (this._velocities.length < this.particleCount) {
                this._rainMesh!.count = this.particleCount;

                const maxHeight = this.maxParticleHeight;
                for (let i = this._velocities.length; i < this.particleCount; i++) {
                    this.getPointInBounds(0, maxHeight, positionTempPoint);
                    positionTempPoint.setY(-positionTempPoint.y);
                    this._rainMesh?.setMatrixAt(i, tempMatrix);
                    const velocity = new Vector3();
                    this.setRainVelocity(velocity);
                    tempPoint.copy(velocity);
                    tempQuaternion.setFromUnitVectors(new Vector3(0, -1, 0), tempPoint.normalize());
                    tempMatrix.makeRotationFromQuaternion(tempQuaternion);
                    tempMatrix.setPosition(positionTempPoint.x, positionTempPoint.y, positionTempPoint.z);
                    this._velocities.push(velocity);
                    opacities.setX(i, 0);
                }
            } else {
                this._velocities.length = this.particleCount;
                this._rainMesh!.count = this.particleCount;
                opacities.count = this.particleCount;
            }

            if (this.rainLevel !== this._rainGeometryLevel) {
                this.recreateGeometry();
                this._rainMesh!.geometry = this._rainGeometry!;
            }

            opacities.needsUpdate = true;
        }
    }

    private recreateGeometry() {
        let width = this.tileWidth / 100;

        if (this.rainLevel === RainLevel.Drizzle) {
            // Drizzle
            width *= 0.6;
        } else if (this.rainLevel === RainLevel.Light) {
            // Light
            width *= 0.8;
        } else {
            // Heavy
            width *= 1;
        }

        this._rainGeometry = new THREE.PlaneGeometry(width, width * 10);

        // Add a second plane 90 deg rotated from the first, so that we can see the particle from all angles.
        const plane2 = new THREE.PlaneGeometry(width, width * 10);
        const q = new THREE.Quaternion();
        q.setFromEuler(new THREE.Euler(0, degToRad(90), 0));
        plane2.applyQuaternion(q);
        this._rainGeometry = BufferGeometryUtils.mergeBufferGeometries([this._rainGeometry, plane2]);

        this._rainGeometry.setAttribute(
            "instanceOpacity",
            new InstancedBufferAttribute(new Float32Array(MAX_PARTICLES), 1)
        );
        this._rainGeometryLevel = this.rainLevel;
    }
}

const createRain: (scene: THREE.Scene) => ZoneParticleSystem = o => new RainParticleSystem(o);

export function useRain(
    mainCamera: THREE.OrthographicCamera | THREE.PerspectiveCamera,
    levelInfo: LevelInfo,
    levelKey: string,
    totalSize: LocalRect | undefined,
    depthMap: THREE.Texture,
    size: Size,
    scene: MutableRefObject<THREE.Scene | undefined>,
    particlesCamera: THREE.PerspectiveCamera,
    grid: IGrid,
    location: Location,
    zone: LocalPixelPosition[] | undefined,
    amt: number | undefined
) {
    return useZoneParticleSystem(
        createRain,
        mainCamera,
        levelInfo,
        levelKey,
        totalSize,
        depthMap,
        size,
        scene,
        particlesCamera,
        grid,
        location,
        zone,
        amt
    );
}

import {
    getObstructionPolygon,
    getObstructions,
    isDoorAnnotation,
    isWindowAnnotation,
    ObstructingAnnotation,
    RectAnnotation,
} from "./annotations";
import { findPathAStar, PathResult } from "./astar";
import { keyedListToKeyArray } from "./common";
import {
    distanceBetween,
    getClosestPointOnLines,
    ILocalGrid,
    intersect,
    intersectPath,
    isPointInPolygon,
    localPoint,
    pointsEqual,
    RelativePolygon,
} from "./grid";
import { GridPosition, LocalPixelPosition, LocalRect, Point } from "./position";
import { Campaign, IGameSystem, Location, Token, WithLevel } from "./store";

/**
 * Classic Cantor Pairing Function
 * Works only on non-negative numbers.
 */
export function cantorPair(x: number, y: number): number {
    return 0.5 * (x + y) * (x + y + 1) + y;
}

/**
 * Modified Cantor Pairing that works with negative values.
 */
export function cantorPairSigned(x: number, y: number): number {
    const a = x >= 0.0 ? 2.0 * x : -2.0 * x - 1.0;
    const b = y >= 0.0 ? 2.0 * y : -2.0 * y - 1.0;
    return cantorPair(a, b);
}

function isObstructed(
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    a: LocalPixelPosition,
    b: LocalPixelPosition,
    obstructions: ObstructingAnnotation[]
) {
    // Check if anything obstructs the path between grid points a and b.
    for (let i = 0; i < obstructions.length; i++) {
        // TODO: Lots of mapping going on here, must be a more efficient way.
        const polygon = getObstructionPolygon(obstructions[i], campaign, location, grid);
        const points = intersectPath(
            a,
            b,
            false,
            polygon.points.map(o => localPoint(o.x + polygon.pos.x, o.y + polygon.pos.y)),
            polygon.isClosed
        );
        if (points.length) {
            return true;
        }
    }

    return false;
}

function getIntersectingObstructions(
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    a: LocalPixelPosition,
    b: LocalPixelPosition,
    obstructions: ObstructingAnnotation[]
): ObstructingAnnotation[] | undefined {
    // Check if anything obstructs the path between grid points a and b.
    let ret: ObstructingAnnotation[] | undefined;
    for (let i = 0; i < obstructions.length; i++) {
        // TODO: Lots of mapping going on here, must be a more efficient way.
        const polygon = getObstructionPolygon(obstructions[i], campaign, location, grid);
        const points = intersectPath(
            a,
            b,
            false,
            polygon.points.map(o => localPoint(o.x + polygon.pos.x, o.y + polygon.pos.y)),
            polygon.isClosed
        );
        if (points.length) {
            if (!ret) {
                ret = [obstructions[i]];
            } else {
                ret.push(obstructions[i]);
            }
        }
    }

    return ret;
}

export class PathFinder {
    private _campaign: Campaign;
    private _location: Location;
    private _grid: ILocalGrid;
    private _system: IGameSystem;
    private _size: LocalRect;
    private _movementObstructions: { [levelKey: string]: ObstructingAnnotation[] } = {};
    private _levelIdentities: { [levelKey: string]: number } | undefined;

    private _goToLevels:
        | {
              [id: string]: {
                  annotation: ObstructingAnnotation;
                  polygon: {
                      pos: LocalPixelPosition;
                      points: Point[];
                      isClosed?: boolean | undefined;
                  };
                  lines: { from: LocalPixelPosition; to: LocalPixelPosition }[];
                  isClosed: boolean;
              };
          }
        | undefined;

    constructor(campaign: Campaign, location: Location, size: LocalRect, grid: ILocalGrid, system: IGameSystem) {
        this._campaign = campaign;
        this._location = location;
        this._grid = grid;
        this._system = system;
        this._size = size;
    }

    get location() {
        return this._location;
    }

    get grid() {
        return this._grid;
    }

    get system() {
        return this._system;
    }

    get size() {
        return this._size;
    }

    /**
     * Finds a path between the specified positions using token movement rules.
     * @param token The token being moved.
     * @param from The start point.
     * @param to The end point.
     * @param previousPath If this path follows directly from another path (i.e. waypoints along a path) then the previous path can be passed in here. This can be relevant if previous node states should affect the new path.
     * @returns The grid points that were traversed to get from the start to the end, including the start and end points - or undefined if there is no path between the points.
     */
    findMovementPath(
        token: Token | undefined,
        from: WithLevel<GridPosition>,
        to: WithLevel<GridPosition>,
        previousPath?: PathResult<WithLevel<GridPosition>>
    ): PathResult<WithLevel<GridPosition>> | undefined {
        return this.findGridPathToPos(
            from,
            to,
            (a, b, from, to, state, nodeState) => this.movementTraversalCost(token, a, b, from, to, state, nodeState),
            previousPath
        );
    }

    /**
     * Given a path previously calculated by findMovementPath, calculates its costs.
     * @param token The token being moved.
     * @param path The previously calculated path.
     */
    evaluateMovementPath(
        token: Token,
        path: WithLevel<GridPosition>[]
    ): PathResult<WithLevel<GridPosition>> | undefined {
        return this.evaluateGridPath(path, (a, b, from, to, state, nodeState) =>
            this.movementTraversalCost(token, a, b, from, to, state, nodeState)
        );
    }

    /**
     * Finds a path between the specified positions using sound movement rules.
     * @param token The token being moved.
     * @param from The start point.
     * @param to The end point.
     * @returns The grid points that were traversed to get from the start to the end, including the start and end points - or undefined if there is no path between the points.
     */
    findSoundPath(
        radius: number,
        from: WithLevel<GridPosition>,
        to: RelativePolygon<WithLevel<LocalPixelPosition>>
    ): PathResult<WithLevel<GridPosition>> | undefined;
    findSoundPath(
        radius: number,
        from: WithLevel<GridPosition>,
        to: WithLevel<GridPosition>
    ): PathResult<WithLevel<GridPosition>> | undefined;
    findSoundPath(
        radius: number,
        from: WithLevel<GridPosition>,
        to: WithLevel<GridPosition> | RelativePolygon<WithLevel<LocalPixelPosition>>
    ) {
        if (to["pos"]) {
            const poly = to as RelativePolygon<WithLevel<LocalPixelPosition>>;
            return this.findGridPathToPolygon(from, poly, (a, b, from) => this.soundTraversalCost(radius, a, b, from));
        }

        return this.findGridPathToPos(from, to as WithLevel<GridPosition>, (a, b, from) =>
            this.soundTraversalCost(radius, a, b, from)
        );
    }

    /**
     * Given a path previously calculated by findSoundPath, calculates its costs.
     * @param token The token being moved.
     * @param path The previously calculated path.
     */
    evaluateSoundPath(
        radius: number,
        path: WithLevel<GridPosition>[]
    ): PathResult<WithLevel<GridPosition>> | undefined {
        return this.evaluateGridPath(path, (a, b, from) => this.soundTraversalCost(radius, a, b, from));
    }

    /**
     * Gets the level that the token should be on if it transitions between the specified grid positions.
     * @param from The start point.
     * @param to The end point.
     * @returns The level that the token would be on if it made the specified transition.
     */
    getLevel(from: WithLevel<GridPosition>, to: GridPosition) {
        // If we haven't already, work out exactly where all the annotations that might take us to another level are.
        if (!this._goToLevels) {
            this._goToLevels = {};

            const goToLevels = getObstructions(this._location.annotations, o => o.goToLevel != null && !!o.pos);
            for (let i = 0; i < goToLevels.length; i++) {
                const poly = getObstructionPolygon(goToLevels[i], this._campaign, this._location, this._grid);
                if (poly) {
                    const lines: { from: LocalPixelPosition; to: LocalPixelPosition }[] = [];
                    for (let i = 1; i < poly.points.length; i++) {
                        const from = poly.points[i - 1];
                        const to = poly.points[i];
                        lines.push({
                            from: localPoint(poly.pos.x + from.x, poly.pos.y + from.y),
                            to: localPoint(poly.pos.x + to.x, poly.pos.y + to.y),
                        });
                    }

                    const firstPoint = poly.points[0];
                    const lastPoint = poly.points[poly.points.length - 1];
                    let isClosed = pointsEqual(firstPoint, lastPoint);
                    if (!isClosed && poly.isClosed) {
                        const from = lastPoint;
                        const to = firstPoint;
                        lines.push({
                            from: localPoint(poly.pos.x + from.x, poly.pos.y + from.y),
                            to: localPoint(poly.pos.x + to.x, poly.pos.y + to.y),
                        });
                        isClosed = true;
                    }

                    this._goToLevels[goToLevels[i].id] = {
                        annotation: goToLevels[i],
                        polygon: poly,
                        lines: lines,
                        isClosed,
                    };
                }
            }
        }

        // Work out if the neighbour takes us to another level.
        let toLevel: string | undefined;
        const goToLevels = Object.values(this._goToLevels);
        if (goToLevels.length) {
            const fromPos = this._grid.toLocalCenterPoint(from);
            const toPos = this._grid.toLocalCenterPoint(to);
            for (let j = 0; j < goToLevels.length && toLevel == null; j++) {
                const goToLevel = goToLevels[j];
                if (goToLevel.annotation.pos?.level === from.level) {
                    // Work out if a line from node to the neighbour crosses any of the lines in the polygon.
                    for (let line of goToLevel.lines) {
                        const intersectPoint = intersect(fromPos, toPos, false, line.from, line.to, false);
                        if (intersectPoint && (!goToLevel.isClosed || !isPointInPolygon(fromPos, goToLevel.polygon))) {
                            // console.log(`Path from ${nodePos.x},${nodePos.y} to ${neighbourLocalPos.x},${neighbourLocalPos.y} intersects annotation ${goToLevel.annotation.id}, specifically the line from ${line.from.x},${line.from.y} to ${line.to.x},${line.to.y}`);

                            // This movement would take us to a new level!
                            toLevel = goToLevel.annotation.goToLevel!;
                            break;
                        }
                    }
                }
            }
        }

        return toLevel ?? from.level;
    }

    private getIdentityWithLevel(node: WithLevel<GridPosition>) {
        // Need to assign a number to each level so that we can use them in a cantor pairing, so do that now if we haven't already.
        if (!this._levelIdentities) {
            this._levelIdentities = {};

            // The only way we can get a unique number for each level is to use their ordered index.
            // That can't change during the lifetime of this pathfinder, so it's fine.
            const levels = keyedListToKeyArray(this._location.levels);
            for (let i = 0; i < levels.length; i++) {
                this._levelIdentities[levels[i]] = i;
            }
        }

        // Use a pairing function to combine the x and y and level into a single unique number. See:
        // https://en.wikipedia.org/wiki/Pairing_function
        return cantorPairSigned(cantorPairSigned(node.x, node.y), this._levelIdentities[node.level]);
    }

    private getMovementObstructions(levelKey: string) {
        let obstructions = this._movementObstructions[levelKey];
        if (obstructions) {
            return obstructions;
        }

        // TODO: This doesn't work for annotations that are centered on a token. They will not be able to obstruct movement.
        obstructions = getObstructions(
            this._location.annotations,
            o => o.pos?.level === levelKey && o.obstructsMovement
        );

        // Put in the edge of the map as a barrier.
        const level = this._location.levels[levelKey];
        if (level) {
            obstructions.push({
                id: "background",
                userId: "system",
                type: "rect",
                pos: localPoint(this._size.x, this._size.y),
                width: this._size.width,
                height: this._size.height,
                obstructsMovement: true,
            } as RectAnnotation);
        }

        this._movementObstructions[levelKey] = obstructions;

        return obstructions;
    }

    private findGridPathToPolygon(
        from: WithLevel<GridPosition>,
        to: RelativePolygon<WithLevel<LocalPixelPosition>>,
        traversalCost: (
            a: WithLevel<GridPosition>,
            b: WithLevel<GridPosition>,
            from: WithLevel<GridPosition>,
            to: (pos: WithLevel<GridPosition>) => boolean,
            state: any
        ) => number
    ) {
        const isInPolygon = (pos: WithLevel<GridPosition>) => {
            if (pos.level !== to.pos.level) {
                return false;
            }

            const centerPoint = this._grid.toLocalCenterPoint(pos);
            return isPointInPolygon(centerPoint, to);
        };

        const aXys = to.points.map(o => localPoint(o.x + to.pos.x, o.y + to.pos.y));
        const path = findPathAStar(from, isInPolygon, {
            identity: node => {
                return this.getIdentityWithLevel(node);
            },
            heuristic: a => {
                if (a.level === to.pos.level) {
                    const center = this._grid.toLocalCenterPoint(a);
                    const closest = getClosestPointOnLines(center, aXys);
                    return closest.dist / this.location.tileSize.width;
                }

                return 0;
            },
            traversalCost: traversalCost,
            getNeighbours: node => this.getNeighbours(node),
        });

        return path;
    }

    private findGridPathToPos(
        from: WithLevel<GridPosition>,
        to: WithLevel<GridPosition>,
        traversalCost: (
            a: WithLevel<GridPosition>,
            b: WithLevel<GridPosition>,
            from: WithLevel<GridPosition>,
            to: WithLevel<GridPosition>,
            state: { previousPath?: PathResult<WithLevel<GridPosition>>; [id: string]: any },
            nodeState?: any
        ) => number | { cost: number; compareCost?: number; nodeState?: any },
        previousPath?: PathResult<WithLevel<GridPosition>>
    ): PathResult<WithLevel<GridPosition>> | undefined {
        const path = findPathAStar(from, to, {
            state: {
                previousPath: previousPath,
            },
            identity: node => {
                return this.getIdentityWithLevel(node);
            },
            heuristic: (a, b) => {
                if (a.level === b.level) {
                    var d1 = Math.abs(a.x - b.x);
                    var d2 = Math.abs(a.y - b.y);
                    return d1 + d2;
                }

                // TODO: The positions are on different levels. Find the closest annotation that will bring us to the specified level and use that.
                return 0;
            },
            traversalCost: traversalCost,
            getNeighbours: node => this.getNeighbours(node),
        });

        return path;
    }

    private getNeighbours(node: WithLevel<GridPosition>) {
        const neighbours = this._grid.getNeighbours(node);

        for (let i = 0; i < neighbours.length; i++) {
            const level = this.getLevel(node, neighbours[i]);
            (neighbours[i] as WithLevel<GridPosition>).level = level;
        }

        return neighbours as WithLevel<GridPosition>[];
    }

    private evaluateGridPath(
        path: WithLevel<GridPosition>[],
        traversalCost?: (
            a: WithLevel<GridPosition>,
            b: WithLevel<GridPosition>,
            from: WithLevel<GridPosition>,
            to: WithLevel<GridPosition>,
            state: { previousPath?: PathResult<WithLevel<GridPosition>>; [id: string]: any },
            nodeState?: any
        ) => number | { cost: number; compareCost?: number; nodeState?: any }
    ): PathResult<WithLevel<GridPosition>> | undefined {
        const from = path[0];
        const to = path[path.length - 1];
        let totalCost = 0;
        let states: any[] | undefined;
        let costs: number[] = [];

        // Follow the path and build up the same kind of result that the astar path finding would.
        for (let i = 1; i < path.length; i++) {
            const a = path[i - 1];
            const b = path[i];

            const cost = traversalCost ? traversalCost(a, b, from, to, {}, states?.[i - 2]) : 1;
            if (typeof cost === "object") {
                if ((cost.compareCost ?? cost.cost) < 1) {
                    return undefined;
                }

                if (cost.nodeState) {
                    if (!states) {
                        states = [];
                    }

                    states[i - 1] = cost.nodeState;
                }

                totalCost += cost.cost;
            } else {
                if (cost < 1) {
                    return undefined;
                }

                totalCost += cost;
            }

            costs.push(totalCost);
        }

        return {
            cost: totalCost,
            path: path,
            pathCost: costs,
            pathState: states,
        };
    }

    private soundTraversalCost(
        radius: number,
        a: WithLevel<GridPosition>,
        b: WithLevel<GridPosition>,
        from: WithLevel<GridPosition>
    ) {
        if (distanceBetween(from, b) > radius) {
            return -1;
        }

        const al = this._grid.toLocalCenterPoint(a);
        const bl = this._grid.toLocalCenterPoint(b);

        const intersecting = getIntersectingObstructions(
            this._campaign,
            this._location,
            this._grid,
            al,
            bl,
            this.getMovementObstructions(a.level)
        );
        if (intersecting) {
            // Get the cumulative sound resistance of the obstructions, work out the cost from that.
            return intersecting.reduce((previousValue, currentValue) => {
                let cost = 0;
                if (isDoorAnnotation(currentValue)) {
                    const doorCost = 10;
                    if (currentValue.rotation == null || currentValue.rotation === 0) {
                        cost = doorCost;
                    } else {
                        cost = 0.2;
                    }
                } else if (isWindowAnnotation(currentValue)) {
                    if (currentValue.obstructsLight && currentValue.obstructsMovement) {
                        // Window is completely closed (shuttered?), obstructs sound quite a bit.
                        cost = 7;
                    } else if (currentValue.obstructsMovement) {
                        // The window is closed but can still be looked through, it will obstruct sound a bit.
                        cost = 3;
                    } else {
                        // Window is completely open, you can jump through it - no obstruction to sound.
                        cost = 0;
                    }
                } else {
                    // Standard obstruction, e.g. wall - should completely stop sound.
                    cost = radius;
                }

                return previousValue + cost;
            }, 1);
        }

        // No obstructions, so the standard cost of 1.
        return 1;
    }

    private movementTraversalCost(
        token: Token | undefined,
        a: WithLevel<GridPosition>,
        b: WithLevel<GridPosition>,
        from: WithLevel<GridPosition>,
        to: WithLevel<GridPosition>,
        state?: any,
        nodeState?: any
    ) {
        // Cap the distance we'll travel to find a path, for performance reasons.
        if (distanceBetween(from, b) > 20) {
            return -1;
        }

        // TODO: If b is not within the player's visible area, return -1.
        // Unless this is the DM moving them, of course...

        const al = this._grid.toLocalCenterPoint(a);
        const bl = this._grid.toLocalCenterPoint(b);

        // Use the from point to check for obstructions. If the to point is on another level then any obstructions are ignored.
        const obstructions = this.getMovementObstructions(from.level);

        // Check if anything obstructs the path between grid points a and b.
        if (isObstructed(this._campaign, this._location, this._grid, al, bl, obstructions)) {
            return -1;
        }

        let cost = this._system.getTraversalCost(
            this._campaign,
            this._location,
            this._grid,
            token,
            a,
            b,
            from,
            to,
            state,
            nodeState
        );

        // If the grid has any modifier, that is added to the compare cost, not the actual cost.
        let gridCost = this._grid.getTraversalCostModifier(a, b);

        // Slightly favour non-diagonal movement. This prevents unnecessary diagonal movement if there's no other cost to do so.
        if (a.x !== b.x && a.y !== b.y) {
            gridCost += 0.001;
        }

        // Favour routes that don't change the level.
        if (a.level !== b.level) {
            gridCost += 0.01;
        }

        if (gridCost > 0) {
            if (typeof cost === "object") {
                cost.compareCost = (cost.compareCost ?? cost.cost) + gridCost;
            } else {
                cost = { cost: cost, compareCost: cost + gridCost };
            }
        }

        return cost;
    }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { theme } from "../design";
import { Box, BoxProps } from "./primitives";
import { Button } from "./Button";
import React, { FunctionComponent } from "react";
import { SpeakerOffIcon, SpeakerQuietIcon, SpeakerModerateIcon, SpeakerLoudIcon } from "@radix-ui/react-icons";
import Slider from "rc-slider";

export interface VolumeSliderProps {
    isMuted?: boolean;
    onIsMutedChanged?: (isMuted: boolean) => void;
    volume: number;
    onVolumeChanged: (volume: number) => void;
}

const SpeakerIcon: FunctionComponent<{ volume: number }> = ({ volume }) => {
    return (
        <React.Fragment>
            {volume === 0 && <SpeakerOffIcon />}
            {volume > 0 && volume <= 33 && <SpeakerQuietIcon />}
            {volume > 33 && volume <= 66 && <SpeakerModerateIcon />}
            {volume > 66 && <SpeakerLoudIcon />}
        </React.Fragment>
    );
};

export const VolumeSlider: FunctionComponent<VolumeSliderProps & BoxProps> = ({
    isMuted,
    onIsMutedChanged,
    volume,
    onVolumeChanged,
    ...props
}) => {
    const effectiveVolume = isMuted ? 0 : volume;
    const handleColor = isMuted ? theme.colors.grayscale[6] : theme.colors.foreground;
    return (
        <Box {...(props as any)} fullWidth flexDirection="row">
            {onIsMutedChanged && (
                <Button
                    toggled={isMuted}
                    mr={2}
                    variant="tertiary"
                    shape="square"
                    size="s"
                    onClick={() => onIsMutedChanged(!isMuted)}>
                    <SpeakerIcon volume={effectiveVolume} />
                </Button>
            )}
            {!onIsMutedChanged && (
                <Box mr={2} flexShrink={1}>
                    <SpeakerIcon volume={effectiveVolume} />
                </Box>
            )}
            <Slider
                style={{ flexGrow: 1 }}
                railStyle={{
                    backgroundColor: theme.colors.grayscale[7],
                }}
                trackStyle={{
                    backgroundColor: isMuted ? theme.colors.grayscale[7] : theme.colors.accent[5],
                    transition: "background-color ease-out 200ms",
                }}
                handleStyle={{
                    border: "none",
                    backgroundColor: handleColor,
                    boxShadow: "unset",
                    transition: "background-color ease-out 200ms",
                }}
                value={volume}
                onChange={v => {
                    onVolumeChanged(v);
                    if (isMuted && onIsMutedChanged) {
                        onIsMutedChanged(false);
                    }
                }}
            />
        </Box>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useState, useEffect, ChangeEvent, useRef } from "react";
import styled from "@emotion/styled";
import { Box, Input, Text } from "../primitives";
import { Button } from "../Button";
import { Form } from "../Form";

import Map from "../icons/Location";
import SearchIcon from "../icons/Search";
import ArtLibraryIcon from "../icons/ArtLibrary";
import CampaignSettings from "../icons/CampaignSettings";

import { Toolbar, ToolbarButton } from "../Toolbar";
import Identity from "../Identity";

import { useRole, useUser, useCampaign, useAppState } from "../contexts";
import {
    Campaign,
    IGameSystem,
    GmSection,
    CampaignRole,
    TokenTemplate,
    UserInfo,
    SearchableSetting,
    Location,
    LightType,
    LocationSummary,
    Handout,
} from "../../store";

import { LocationList, LocationListTools } from "./LocationList";
import { locationSettingsSearch } from "./LocationProperties";
import { CampaignProperties, playerCampaignSettings, allCampaignSettings } from "./CampaignProperties";
import { ArtLibrary, ArtLibraryTools } from "./ArtLibrary";
import { LocationSummaryHost, LocationSummaryNode } from "./LocationSummary";
import { LocalSearchable, SearchResult } from "../../localsearchable";

import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import {
    MotionBox,
    AnimatedListItem,
    AnimatedList,
    sectionInitial,
    sectionAnimate,
    sectionExit,
    defaultExit,
    MotionOverlayCard,
    defaultInitial,
    defaultAnimate,
} from "../motion";
import { LibraryItemHost, UserArtLibrary } from "../../library";
import { TokenTemplateLibrary, TokenTemplateLibraryTools } from "./TokenTemplateLibrary";
import { useErrorHandler, useKeyboardShortcut } from "../utils";
import { lightTemplates, lightTokenTemplates } from "../LocationStage/Lighting";
import { TokenTemplateHost, TokenTemplateSearchResult } from "./TokenTemplateListItem";
import { InlineScrollable } from "../InlineScrollable";
import { SidebarButton, useVttApp } from "../common";
import DragonIcon from "../icons/Dragon";
import { UserSettings, userSettingsSearch } from "./UserSettings";
import SettingsIcon from "../icons/Settings";
import { useDroppable } from "@dnd-kit/core";
import { Cross1Icon, DrawingPinFilledIcon, DrawingPinIcon } from "@radix-ui/react-icons";
import { theme } from "../../design";
import { DropData, TypedDroppableArea } from "../draggable";
import { TimeTracker } from "./TimeTracker";
import { HandoutSearchResult, HandoutsHost, HandoutsLibrary, HandoutsTools } from "./HandoutsLibrary";
import NotebookIcon from "../icons/Notebook";

export const DEFAULT_PROPERTIES_WIDTH = 400;

const TransparentTextInput = styled(Input)`
    background: transparent;
    border: none;
    text-shadow: black 0 0 4px;
`;

const UpperText = motion(styled(Text)`
    text-transform: uppercase;
`);

const LobotomizedForm = styled(Form)`
    > * + * {
        margin-top: ${props => props.theme.space[2]}px;
    }
`;

const TransparentToolbar = styled(Toolbar)`
    &::after {
        background: transparent;
    }
`;

const MotionTransparentToolbar = motion(TransparentToolbar);

export enum Pages {
    Locations = "Locations",
    CampaignSettings = "CampaignSettings",
    Art = "Art",
    Tokens = "Tokens",
    Handouts = "Handouts",
    UserSettings = "UserSettings",
}

export const settingsSection: GmSection = {
    id: Pages.UserSettings,
    label: "Settings",
    roles: ["GM", "Player"],
    renderGlyph: () => <SettingsIcon />,
    render: () => <UserSettings />,
};
export const defaultSectionsNoSettings: GmSection[] = [
    {
        id: Pages.Locations,
        label: "Locations",
        roles: ["GM"],
        renderGlyph: () => <Map />,
        renderTools: () => <LocationListTools />,
        render: () => <LocationList />,
    },
    {
        id: Pages.CampaignSettings,
        label: "Campaign settings",
        roles: ["GM"],
        renderGlyph: () => <CampaignSettings />,
        render: () => <CampaignProperties />,
    },
    {
        id: Pages.Art,
        label: "Art",
        roles: ["GM", "Player"],
        renderGlyph: () => <ArtLibraryIcon />,
        renderTools: () => <ArtLibraryTools />,
        render: () => <ArtLibrary />,
    },
    {
        id: Pages.Tokens,
        label: "Tokens",
        roles: ["GM", "Player"],
        renderGlyph: () => <DragonIcon />,
        renderTools: () => <TokenTemplateLibraryTools />,
        render: () => <TokenTemplateLibrary />,
    },
    {
        id: Pages.Handouts,
        label: "Handouts & notes",
        roles: ["GM", "Player"],
        renderGlyph: () => <NotebookIcon />,
        renderTools: () => <HandoutsTools />,
        render: () => <HandoutsLibrary />,
    },
];
export const defaultSections: GmSection[] = [...defaultSectionsNoSettings, settingsSection];

async function checkResults(search: Promise<SearchResult<any, () => JSX.Element>[]>) {
    try {
        return await search;
    } catch {
        // This search failed, but that shouldn't stop any others from completing.
    }

    return [];
}

function getTokenTemplateSearchable(tokenTemplates: TokenTemplate[], system: IGameSystem) {
    return new LocalSearchable(tokenTemplates, {
        idField: "templateId",
        searchableFields: system.getTokenTemplateSearchFields(),
        toResult: o => () => <TokenTemplateSearchResult tokenTemplate={o} />,
    });
}

function getHandoutsSearchable(handouts: Handout[], system: IGameSystem) {
    return new LocalSearchable(handouts, {
        idField: "id",
        searchableFields: ["label", "content"],
        toResult: o => () => <HandoutSearchResult handout={o} />,
    });
}

export const lightsSearch = new LocalSearchable(lightTokenTemplates, {
    idField: "templateId",
    searchableFields: [
        {
            name: "label",
            extractor: o => lightTemplates[o.templateId as LightType].label,
        },
        {
            name: "tags",
            extractor: o => lightTemplates[o.templateId as LightType].tags,
        },
    ],
    toResult: f => () => {
        return <TokenTemplateSearchResult tokenTemplate={f} />;
    },
});

let lastSystem: IGameSystem;
const searchableFields: (keyof SearchableSetting)[] = ["label", "tags"];
const toResult: (o: SearchableSetting) => () => JSX.Element = o => () => o.render();
let campaignSettingsPlayerSearch: LocalSearchable<SearchableSetting, () => JSX.Element> = new LocalSearchable(
    playerCampaignSettings,
    {
        idField: "id",
        searchableFields: searchableFields,
        toResult: toResult,
    }
);
let campaignSettingsGmSearch: LocalSearchable<SearchableSetting, () => JSX.Element> | undefined;

let locationsSearchable: LocalSearchable<LocationSummary, () => JSX.Element>;
let lastLocations: { [locationId: string]: LocationSummary | Location } | undefined;

let tokenTemplatesSearchable: LocalSearchable<TokenTemplate, () => JSX.Element>;
let lastTokenTemplates: { [templateId: string]: TokenTemplate } | undefined;

let handoutsSearchable: LocalSearchable<Handout, () => JSX.Element>;
let lastHandouts: { [handoutId: string]: Handout } | undefined;

async function search(
    query: string,
    campaign: Campaign,
    user: UserInfo,
    role: CampaignRole,
    system: IGameSystem,
    systemTokens: LocalSearchable<TokenTemplate, () => jsx.JSX.Element>
) {
    const results: {
        categoryId: string;
        categoryLabel: string;
        results: SearchResult<any, () => JSX.Element>[];
    }[] = [];

    if (!query) {
        return results;
    }

    query = query.trim();
    if (!query) {
        return results;
    }

    // Search locations.
    if (role === "GM") {
        if (lastLocations !== campaign.locations) {
            lastLocations = campaign.locations;
            const locationKeys = Object.keys(campaign.locations);
            locationsSearchable = new LocalSearchable(
                locationKeys.map(o => campaign.locations[o]),
                {
                    idField: "id",
                    searchableFields: ["label"],
                    toResult: o => () => <LocationSummaryNode key={o.id} location={o} />,
                }
            );
        }

        results.push({
            categoryId: Pages.Locations,
            categoryLabel: "Locations",
            results: await checkResults(locationsSearchable.searchAsync(query)),
        });
    }

    // Search the token templates.
    try {
        if (lastTokenTemplates !== campaign.tokens) {
            let campaignTemplates = Object.values(campaign.tokens);
            if (role === "Player") {
                campaignTemplates = campaignTemplates.filter(o => o.owner === user.id);
            }

            tokenTemplatesSearchable = getTokenTemplateSearchable(campaignTemplates, system);
        }

        results.push({
            categoryId: Pages.Tokens,
            categoryLabel: "Tokens",
            results:
                role === "Player"
                    ? await checkResults(tokenTemplatesSearchable.searchAsync(query))
                    : [
                          ...(await checkResults(tokenTemplatesSearchable.searchAsync(query))),
                          ...(await checkResults(lightsSearch.searchAsync(query))),
                          ...(await checkResults(systemTokens.searchAsync(query))),
                      ],
        });
    } catch (e) {
        console.warn(e);
    }

    // Search the handouts.
    try {
        if (lastHandouts !== campaign.handouts) {
            let campaignHandouts = Object.values(campaign.handouts);
            handoutsSearchable = getHandoutsSearchable(campaignHandouts, system);
        }

        results.push({
            categoryId: Pages.Handouts,
            categoryLabel: "Handouts",
            results: await checkResults(handoutsSearchable.searchAsync(query)),
        });
    } catch (e) {
        console.warn(e);
    }

    // Search anything specific to the current system.
    if (system.search) {
        try {
            const systemResults = await system.search(query, role);

            systemResults.forEach(o => (o.categoryId = system.id + "_" + o.categoryId));

            results.push(...systemResults);
        } catch (e) {
            // System search failed, but that shouldn't stop others from completing.
            console.warn(e);
        }
    }

    // Search the art library.
    const artSearch = await UserArtLibrary.current.getItemsSearchable();
    results.push({
        categoryId: Pages.Art,
        categoryLabel: "Art",
        results: await checkResults(artSearch.searchAsync(query)),
    });

    // Settings come last, they're probably what you're going to search for the least?
    if (role === "GM") {
        results.push({
            categoryId: "LocationSettings",
            categoryLabel: "Location Settings",
            results: await checkResults(locationSettingsSearch.searchAsync(query)),
        });

        if (lastSystem !== system) {
            const systemSettings = system.getCampaignSettings?.();
            campaignSettingsGmSearch = new LocalSearchable([...allCampaignSettings, ...(systemSettings ?? [])], {
                idField: "id",
                searchableFields: searchableFields,
                toResult: toResult,
            });
        }

        results.push({
            categoryId: Pages.CampaignSettings,
            categoryLabel: "Campaign Settings",
            results: await checkResults(campaignSettingsGmSearch!.searchAsync(query)),
        });
    } else {
        results.push({
            categoryId: Pages.CampaignSettings,
            categoryLabel: "Campaign Settings",
            results: await checkResults(campaignSettingsPlayerSearch.searchAsync(query)),
        });
    }

    results.push({
        categoryId: Pages.UserSettings,
        categoryLabel: "Settings",
        results: await checkResults(userSettingsSearch.searchAsync(query)),
    });

    return results;
}

export const SearchBar: FunctionComponent<{}> = React.memo(({ ...props }) => {
    const { system, campaign } = useCampaign();
    const user = useUser();
    const role = useRole();
    const errorHandler = useErrorHandler();

    const { searchPropertiesSection, setSearchPropertiesSection, searchPropertiesSections } = useAppState();
    const renderPropertiesOptions = useVttApp();
    const { isSearchExpanded, setIsSearchExpanded } = renderPropertiesOptions;

    const { searchTerm, searchResults, setSearchTerm, setSearchResults, panels } = renderPropertiesOptions;

    const overlayPosition = useRef<"left" | "right">();
    if (renderPropertiesOptions.overlay) {
        overlayPosition.current = renderPropertiesOptions.overlay.align;
    }

    const queryInputRef = useRef<HTMLInputElement>();
    useKeyboardShortcut(
        "Escape",
        ev => {
            if (queryInputRef.current) {
                queryInputRef.current.value = "";
                setSearchTerm("");
            }

            ev.preventDefault();
        },
        { isDisabled: !searchTerm }
    );

    useKeyboardShortcut(["f"], () => {
        setTimeout(() => queryInputRef.current?.focus(), 0);
    });

    useKeyboardShortcut(["j"], () => {
        const searchSection = searchPropertiesSections.current.find(o => o.id === Pages.Handouts);
        if (searchSection) {
            setIsSearchExpanded(true);
            setSearchPropertiesSection(searchSection);
        }
    });

    // Rerender when the art library changes. If the library has changed, then rerun the search.
    UserArtLibrary.useItems();

    const systemTokensRef = useRef<{ [id: string]: TokenTemplate }>();
    const systemTokensSearchableRef = useRef<LocalSearchable<TokenTemplate, () => jsx.JSX.Element>>();
    useEffect(() => {
        const handle = setTimeout(async () => {
            if (searchTerm === "") {
                setSearchTerm("");
                setSearchResults([]);
                return;
            }

            // Re-create the system tokens searchable if necessary.
            const newSystemTokens = system.getTokenTemplates();
            if (newSystemTokens !== systemTokensRef.current) {
                systemTokensRef.current = newSystemTokens;
                systemTokensSearchableRef.current = getTokenTemplateSearchable(Object.values(newSystemTokens), system);
            }

            // Perform the query.
            const results = await search(searchTerm, campaign, user, role, system, systemTokensSearchableRef.current!);
            setSearchTerm(searchTerm);
            setSearchResults(results);
        }, 500);
        return () => clearTimeout(handle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, campaign, system, user, role, errorHandler, UserArtLibrary.currentVersion]);

    const isSearchResults = !isSearchExpanded && searchResults && searchResults.some(o => o.results.length > 0);
    const resultsWithJumpTo = searchResults.filter(
        o =>
            o.results.length > 0 &&
            (searchPropertiesSections.current.find(s => o.categoryId === s.id) || o.jumpTo != null)
    );

    // Register the base of this area as droppable but don't do anything with it - this stops drops from falling through
    // to the canvas underneath.
    var dropData: DropData = { onDrop: () => {} };
    const { setNodeRef } = useDroppable({ id: "sidebar", data: dropData });

    // Remove properties when the sidebar is closed - but do it AFTER the previous render, so that it gets to change
    // its exit prop before being removed.
    const isPropertiesOpen = renderPropertiesOptions != null;
    const clearPanels = renderPropertiesOptions.clearPanels;
    useEffect(() => {
        if (!isSearchExpanded) {
            clearPanels();
        }
    }, [isSearchExpanded, clearPanels, isSearchResults, isPropertiesOpen]);
    const sectionTools =
        isSearchExpanded && searchPropertiesSection.renderTools ? searchPropertiesSection.renderTools() : undefined;
    const sectionContent =
        isSearchExpanded && searchPropertiesSection.render ? searchPropertiesSection.render() : undefined;

    const [isAnimatingLayout, setIsAnimatingLayout] = useState(false);

    const width = 400;
    return (
        <LayoutGroup>
            <MotionBox
                fullHeight
                zIndex={100}
                p={3}
                initial={{ opacity: 0, x: "-12px" }}
                animate={{ opacity: 1, x: 0 }}
                css={{
                    gridArea: isSearchResults || isSearchExpanded ? "leftdrawer" : undefined,
                    position: isSearchExpanded ? "relative" : "absolute",
                    top: 0,
                    left: 0,
                    pointerEvents: "none",
                }}>
                <MotionOverlayCard
                    layout
                    ref={setNodeRef}
                    bg="background"
                    width={width}
                    justifyContent="flex-start"
                    zIndex={2}
                    alignSelf="flex-start"
                    flexDirection="column"
                    flexShrink={0}
                    onLayoutAnimationStart={() => {
                        setIsAnimatingLayout(true);
                    }}
                    onLayoutAnimationComplete={() => {
                        setIsAnimatingLayout(false);
                    }}
                    style={{
                        borderTopLeftRadius: theme.radii[4],
                        borderBottomLeftRadius: theme.radii[4],
                        borderTopRightRadius:
                            renderPropertiesOptions.panels.length > 0 && isSearchExpanded ? 0 : theme.radii[4],
                        borderBottomRightRadius:
                            renderPropertiesOptions.panels.length > 0 && isSearchExpanded ? 0 : theme.radii[4],
                    }}
                    css={{
                        maxHeight: "100%",
                        pointerEvents: "all",
                        height: isSearchExpanded ? "100%" : "auto",
                        div: isAnimatingLayout
                            ? {
                                  "&::-webkit-scrollbar-thumb": { backgroundColor: "hsla(0, 0%, 0%, 0.0)" },
                              }
                            : undefined,
                    }}
                    {...props}>
                    <MotionTransparentToolbar layout px={3} fullWidth justifyContent="flex-start" space={0}>
                        <ToolbarButton
                            isToggled={isSearchExpanded}
                            onClick={() => {
                                const newIsSearchExpanded = !isSearchExpanded;
                                setIsSearchExpanded(newIsSearchExpanded);
                            }}>
                            <Identity />
                        </ToolbarButton>
                        <Box flex={1} px={2}>
                            <TransparentTextInput
                                ref={queryInputRef as any}
                                fullWidth
                                placeholder={`Search ${
                                    isSearchExpanded
                                        ? searchPropertiesSection.labelLowerCase ??
                                          searchPropertiesSection.label.toLocaleLowerCase()
                                        : campaign.label
                                }`}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setSearchTerm(e.target.value);
                                }}
                            />
                        </Box>
                        <SidebarButton variant="tertiary">
                            <SearchIcon size={20 as any} />
                        </SidebarButton>
                    </MotionTransparentToolbar>
                    {isSearchResults && (
                        <TokenTemplateHost>
                            <LocationSummaryHost>
                                <LibraryItemHost>
                                    <HandoutsHost>
                                        <InlineScrollable>
                                            <LobotomizedForm px={3} pb={3} fullWidth flexDirection="column">
                                                <AnimatePresence mode="popLayout">
                                                    {searchResults
                                                        .filter(o => o.results.length)
                                                        .map(o => {
                                                            const numToDisplay = 10;
                                                            const resultsToDisplay =
                                                                o.results.length > numToDisplay
                                                                    ? o.results.slice(0, numToDisplay)
                                                                    : o.results;
                                                            return (
                                                                <MotionBox
                                                                    layout
                                                                    initial={sectionInitial}
                                                                    animate={sectionAnimate}
                                                                    exit={sectionExit}
                                                                    key={o.categoryId}
                                                                    fullWidth
                                                                    flexDirection="column"
                                                                    alignItems="flex-start">
                                                                    <UpperText
                                                                        fontWeight="bold"
                                                                        fontSize={0}
                                                                        mb={2}
                                                                        layout>
                                                                        {o.categoryLabel}
                                                                    </UpperText>
                                                                    <AnimatedList>
                                                                        {resultsToDisplay.map((r, i) => (
                                                                            <AnimatedListItem key={r.id} index={i}>
                                                                                {r.item()}
                                                                            </AnimatedListItem>
                                                                        ))}
                                                                    </AnimatedList>
                                                                    {o.results.length > numToDisplay && (
                                                                        <Text>
                                                                            {o.results.length - numToDisplay} more...
                                                                        </Text>
                                                                    )}
                                                                </MotionBox>
                                                            );
                                                        })}
                                                </AnimatePresence>
                                            </LobotomizedForm>
                                            {resultsWithJumpTo.length > 0 && (
                                                <MotionBox
                                                    layout
                                                    px={3}
                                                    pb={3}
                                                    css={{
                                                        gap: theme.space[1],
                                                        flexWrap: "wrap",
                                                        justifyContent: "flex-start",
                                                    }}>
                                                    <span>Jump to:</span>
                                                    {resultsWithJumpTo.map(o =>
                                                        o.jumpTo ? (
                                                            o.jumpTo()
                                                        ) : (
                                                            <Button
                                                                size="s"
                                                                key={o.categoryId}
                                                                onClick={() => {
                                                                    setIsSearchExpanded(true);
                                                                    renderPropertiesOptions.clearPanels();
                                                                    setSearchPropertiesSection(
                                                                        searchPropertiesSections.current.find(
                                                                            s => s.id === o.categoryId
                                                                        ) as GmSection
                                                                    );
                                                                }}>
                                                                {o.categoryLabel}
                                                            </Button>
                                                        )
                                                    )}
                                                </MotionBox>
                                            )}
                                        </InlineScrollable>
                                    </HandoutsHost>
                                </LibraryItemHost>
                            </LocationSummaryHost>
                        </TokenTemplateHost>
                    )}
                    <AnimatePresence mode="popLayout">
                        {isSearchExpanded && (
                            <MotionBox
                                fullWidth
                                flex={1}
                                flexDirection="column"
                                justifyContent="flex-start"
                                layout
                                initial={{
                                    opacity: 0,
                                    scale: 0.95,
                                    originX: 0.5,
                                    originY: 0,
                                }}
                                animate={{
                                    opacity: 1,
                                    scale: 1,
                                    originX: 0.5,
                                    originY: 0,
                                }}
                                exit={{
                                    opacity: 0,
                                    scale: 0.95,
                                    originX: 0.5,
                                    originY: 0,
                                }}
                                transition={{
                                    duration: 0.2,
                                    ease: "easeOut",
                                }}>
                                <MotionBox layout px={3} py={2} fullWidth justifyContent="flex-start" zIndex={11}>
                                    {searchPropertiesSections.current.map(o => (
                                        <SidebarButton
                                            key={o.id}
                                            tooltip={o.label}
                                            toggled={searchPropertiesSection.id === o.id}
                                            onClick={() => {
                                                renderPropertiesOptions.clearPanels();
                                                setSearchPropertiesSection(o);
                                            }}
                                            variant="tertiary"
                                            mr={2}>
                                            {o.renderGlyph()}
                                        </SidebarButton>
                                    ))}
                                </MotionBox>
                                <AnimatePresence mode="popLayout" initial={false}>
                                    {sectionTools && (
                                        <MotionBox
                                            key={searchPropertiesSection.id + "_tools"}
                                            layout
                                            pt={2}
                                            pb={2}
                                            fullWidth
                                            zIndex={10}
                                            flexDirection="column"
                                            initial={{ opacity: 0, y: -theme.space[1] }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -theme.space[1] }}>
                                            {sectionTools}
                                        </MotionBox>
                                    )}
                                </AnimatePresence>
                                <AnimatePresence mode="wait" initial={false}>
                                    <MotionBox
                                        pt={sectionTools ? 0 : 2}
                                        key={searchPropertiesSection.id}
                                        initial={sectionInitial}
                                        animate={sectionAnimate}
                                        exit={sectionExit}
                                        flex={1}
                                        fullHeight
                                        fullWidth
                                        flexDirection="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start">
                                        {sectionContent}
                                    </MotionBox>
                                </AnimatePresence>
                            </MotionBox>
                        )}
                    </AnimatePresence>
                </MotionOverlayCard>
                <AnimatePresence mode="popLayout">
                    {/* @ts-ignore Why does this line have an issue with multiple children and nowhere else? */}
                    {panels.length > 0 &&
                        isSearchExpanded &&
                        renderPropertiesOptions.panels.map((o, i) => {
                            const isLastPanel = i === renderPropertiesOptions.panels.length - 1;
                            return (
                                <MotionOverlayCard
                                    key={o.id}
                                    layout
                                    bg="background"
                                    borderTopRightRadius={isLastPanel ? 4 : 0}
                                    borderBottomRightRadius={isLastPanel ? 4 : 0}
                                    css={{ pointerEvents: "all" }}
                                    width={o.width ?? DEFAULT_PROPERTIES_WIDTH}
                                    fullHeight
                                    zIndex={1}
                                    alignSelf="flex-start"
                                    flexDirection="column"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ ease: "easeOut" }}>
                                    <MotionBox
                                        flexDirection="column"
                                        fullWidth
                                        fullHeight
                                        initial={{
                                            x: isLastPanel ? -theme.space[3] : 0,
                                            opacity: 0,
                                        }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: -theme.space[3], opacity: 0 }}>
                                        <TypedDroppableArea
                                            fullWidth
                                            justifyContent="flex-start"
                                            flexDirection="column"
                                            flexGrow={1}
                                            droppableId={"sidepanel_" + o.id}>
                                            {o.children()}
                                        </TypedDroppableArea>
                                    </MotionBox>
                                    <Box
                                        css={{
                                            position: "absolute",
                                            right: theme.space[2],
                                            top: theme.space[2],
                                        }}>
                                        <Button
                                            shape="square"
                                            variant="tertiary"
                                            toggled={o.isPinned}
                                            onClick={() => {
                                                renderPropertiesOptions.addPanel({
                                                    ...o,
                                                    isPinned: !o.isPinned,
                                                });
                                            }}>
                                            {o.isPinned ? <DrawingPinFilledIcon /> : <DrawingPinIcon />}
                                        </Button>
                                        <Button
                                            shape="square"
                                            variant="tertiary"
                                            onClick={() => {
                                                renderPropertiesOptions.closePanel(o.id);
                                            }}>
                                            <Cross1Icon />
                                        </Button>
                                    </Box>
                                </MotionOverlayCard>
                            );
                        })}
                </AnimatePresence>
                <AnimatePresence mode="popLayout">
                    {panels.length < 2 && (
                        <Box
                            mt={3}
                            css={{
                                position: "absolute",
                                left: "100%",
                                top: 0,
                                justifyContent: "flex-start",
                                pointerEvents: "all",
                            }}>
                            <TimeTracker campaign={campaign} />
                        </Box>
                    )}
                </AnimatePresence>
            </MotionBox>

            <Box
                mt={7}
                css={{
                    flexDirection: overlayPosition.current === "right" ? "row-reverse" : "row",
                    alignItems: "stretch",
                    justifyContent: "flex-start",
                    pointerEvents: "none",
                    gridColumnStart: "leftinner",
                    gridColumnEnd: "main",
                    gridRowStart: "top",
                    gridRowEnd: "bottom",
                }}>
                <AnimatePresence>
                    {renderPropertiesOptions.overlay && (
                        <MotionOverlayCard
                            layout
                            flexGrow={1}
                            flexShrink={1}
                            my={3}
                            ml={isSearchExpanded ? 0 : isSearchResults ? `${width + theme.space[3] * 2}px` : 3}
                            mr={3}
                            borderRadius={4}
                            zIndex={99}
                            minWidth={renderPropertiesOptions.overlay.width}
                            maxWidth={renderPropertiesOptions.overlay.maxWidth}
                            css={{ pointerEvents: "all" }}
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            {renderPropertiesOptions.overlay.children()}
                            <Box
                                css={{
                                    position: "absolute",
                                    right: theme.space[2],
                                    top: theme.space[2],
                                }}>
                                <Button
                                    shape="square"
                                    variant="tertiary"
                                    onClick={() => {
                                        renderPropertiesOptions.closePanel(renderPropertiesOptions.overlay!.id);
                                    }}>
                                    <Cross1Icon />
                                </Button>
                            </Box>
                        </MotionOverlayCard>
                    )}
                </AnimatePresence>
            </Box>
        </LayoutGroup>
    );
});

import {
    addToKeyedList,
    KeyedList,
    KeyedListItem,
    keyedListToArray,
    keyedListToKeyArray,
    mapKeyedList,
    Overwrite,
} from "../../common";
import { copyState } from "../../reducers/common";
import {
    Campaign,
    DiceBagTerm,
    DiceType,
    getAverageResult,
    isLocation,
    isTokenTemplate,
    LocationSummary,
    StoredDiceBag,
    TokenImageMetadata,
    TokenTemplate,
} from "../../store";
import {
    CoreAbility,
    DamageType,
    WeaponType,
    NamedRuleStore,
    NamedRuleRef,
    createNamedRuleStore,
    DnD5EToken,
    isDnD5ETokenTemplate,
    DnD5ETokenTemplate,
    getRuleKey,
    ModifiedValue,
    ValueModifier,
    modifiedValue,
    isDnD5EMonsterToken,
    isDnD5EMonsterTemplate,
    damageTypes,
    Ability,
    AbilityUse,
    RestType,
    AttackType,
    SingleAttackType,
    SingleAttackTypes,
    coreAbilities,
    AppliedAbilityEffect,
    isNamedRuleRef,
    CreatureTransformation,
    ApplicableAbilityEffect,
    AppliedAbilityEffectChoices,
    MonsterFilter,
    DnD5EMonsterTemplate,
    AppliedAbilityEffectRef,
    StoredMonster,
    RollModifiers,
    evaluateCreatureExpression,
    AbilityTime,
    evaluateCharacterExpression,
    ExpressionableValue,
    MergeableRule,
    DnD5EAnnotation,
    AppliedAbilityEffectSource,
    AttackModifiers,
    getSingleAttackType,
    isMergeRule,
    mergeRules,
    CreatureConditions,
    CreatureConditionName,
    fromRuleKey,
} from "./common";
import {
    Item,
    ItemStore,
    resolveItem,
    NamedItem,
    ItemFilter,
    Inventory,
    ResolvedInventory,
    ResolvedInventoryItem,
    ItemGroup,
    ItemProperty,
    createItemStore,
    ResolvedWeapon,
    ResolvedItem,
    ItemType,
    isInventoryWeapon,
    ItemVariant,
    Shield,
    isShield,
    ResolvedInventoryWeapon,
    isWeapon,
    filterItem,
    isInventoryArmor,
    isInventoryItem,
} from "./items";
import { SchoolOfMagic, Spell } from "./spells";

export const defaultStandardLanguages = [
    "Common",
    "Dwarvish",
    "Elvish",
    "Giant",
    "Gnomish",
    "Goblin",
    "Halfling",
    "Orc",
];
export const defaultExoticLanguages = [
    "Abyssal",
    "Celestial",
    "Draconic",
    "Deep speech",
    "Infernal",
    "Primordial",
    "Sylvan",
    "Undercommon",
];

const spellSlots = [
    [2],
    [3],
    [4, 2],
    [4, 3],
    [4, 3, 2],
    [4, 3, 3],
    [4, 3, 3, 1],
    [4, 3, 3, 2],
    [4, 3, 3, 3, 1],
    [4, 3, 3, 3, 2],
    [4, 3, 3, 3, 2, 1],
    [4, 3, 3, 3, 2, 1],
    [4, 3, 3, 3, 2, 1, 1],
    [4, 3, 3, 3, 2, 1, 1],
    [4, 3, 3, 3, 2, 1, 1, 1],
    [4, 3, 3, 3, 2, 1, 1, 1],
    [4, 3, 3, 3, 2, 1, 1, 1, 1],
    [4, 3, 3, 3, 3, 1, 1, 1, 1],
    [4, 3, 3, 3, 3, 2, 1, 1, 1],
    [4, 3, 3, 3, 3, 2, 2, 1, 1],
];

export const MAX_ATTUNED_ITEMS = 3;

export type Conditional<T extends {}> = T & { condition?: string };

export type MovementType = "walk" | "fly" | "swim" | "burrow" | "climb";
export const movementTypes: MovementType[] = ["walk", "fly", "swim", "burrow", "climb"];

export type MovementSpeeds<T> = {
    [type in MovementType]: T;
};

export type Alignment = "LG" | "LN" | "LE" | "NG" | "N" | "NE" | "CG" | "CN" | "CE";
export const alignments: Alignment[] = ["CE", "CG", "CN", "LE", "LG", "LN", "N", "NE", "NG"];

export type TerrainType = "arctic" | "coast" | "desert" | "forest" | "grassland" | "mountain" | "swamp" | "underdark";
export const terrainTypes: TerrainType[] = [
    "arctic",
    "coast",
    "desert",
    "forest",
    "grassland",
    "mountain",
    "swamp",
    "underdark",
];

export type TerrainTypes<T> = {
    [type in TerrainType]: T;
};

export type CreatureType =
    | "aberration"
    | "beast"
    | "celestial"
    | "construct"
    | "dragon"
    | "elemental"
    | "fey"
    | "fiend"
    | "giant"
    | "humanoid"
    | "monstrosity"
    | "ooze"
    | "plant"
    | "undead";
export const creatureTypes: CreatureType[] = [
    "aberration",
    "beast",
    "celestial",
    "construct",
    "dragon",
    "elemental",
    "fey",
    "fiend",
    "giant",
    "humanoid",
    "monstrosity",
    "ooze",
    "plant",
    "undead",
];

export type CreatureTypes<T> = {
    [type in CreatureType]: T;
};

export type CreatureSize = "Tiny" | "Small" | "Medium" | "Large" | "Huge" | "Gargantuan";
export const creatureSizes: CreatureSize[] = ["Tiny", "Small", "Medium", "Large", "Huge", "Gargantuan"];

export type CoreAbilities<T> = {
    [ability in CoreAbility]: T;
};

export type DamageTypes<T, DT extends string = DamageType> = Conditional<
    Partial<{ [damageType in DT]: T }> & { special?: string; preContent?: string; postContent?: string }
>;

export type Skill =
    | "acrobatics"
    | "animal_handling"
    | "arcana"
    | "athletics"
    | "deception"
    | "history"
    | "insight"
    | "intimidation"
    | "investigation"
    | "medicine"
    | "nature"
    | "perception"
    | "performance"
    | "persuasion"
    | "religion"
    | "sleight_of_hand"
    | "stealth"
    | "survival";
export const skills: Skill[] = [
    "acrobatics",
    "animal_handling",
    "arcana",
    "athletics",
    "deception",
    "history",
    "insight",
    "intimidation",
    "investigation",
    "medicine",
    "nature",
    "perception",
    "performance",
    "persuasion",
    "religion",
    "sleight_of_hand",
    "stealth",
    "survival",
];

export type Skills<T> = {
    [skill in Skill]: T;
};

export type SenseType = "blindsight" | "darkvision" | "tremorsense" | "truesight";
export const senseTypes: SenseType[] = ["blindsight", "darkvision", "tremorsense", "truesight"];

// These are the distance that the sense reaches in ft.
export type Senses<T> = {
    [sense in SenseType]: T;
};

// TODO: More details - description textentry, etc.
export interface SkillDetails {
    label: string;
    modifier: CoreAbility;
    content: string;
}

export type ArmorType = "light" | "medium" | "heavy" | "shields";

export type ArmorProficiencies<T> = {
    [armorType in ArmorType]: T;
};

export type WeaponProficiencies<T> = {
    [weaponType in WeaponType | string]: T;
};

// These are the check values (i.e. passive perception 14).
interface PassiveAbilities<T> {
    perception: T;
    investigation: T;
    insight: T;
}

export interface AdvantageOrDisadvantage {
    reason: string;
    condition?: string;
    source?: AppliedAbilityEffectSource;
}

export interface CurrencyType {
    name: string;
    abbreviation: string;
    value: number; // Number of the standard currency unit (GP) to make one of this currency.
}

export const CURRENCY_TYPES: CurrencyType[] = [
    {
        name: "Platinum",
        abbreviation: "PP",
        value: 10,
    },
    {
        name: "Gold",
        abbreviation: "GP",
        value: 1,
    },
    {
        name: "Electrum",
        abbreviation: "EP",
        value: 0.5,
    },
    {
        name: "Silver",
        abbreviation: "SP",
        value: 0.1,
    },
    {
        name: "Copper",
        abbreviation: "CP",
        value: 0.01,
    },
];
export const CURRENCY_TYPES_BY_ABBR: Map<string, CurrencyType> = new Map(CURRENCY_TYPES.map(o => [o.abbreviation, o]));

export interface Choice<T, C = T extends object ? KeyedList<T> : T[]> {
    from?: C;
    except?: C;
    allowDuplicates?: boolean;
    count: number;
}

// TODO: Not used yet. Should be used along with meetsPrerequisite to filter the feats/features available for a character to pick.
export interface Prerequisite {
    ability?: Partial<CoreAbilities<number>>;
    race?: NamedRuleRef & {
        subrace?: NamedRuleRef;
    };
    class?: NamedRuleRef & {
        level?: number;
        subclass?: NamedRuleRef;
    };
    feature?: NamedRuleRef;
    spell?: NamedRuleRef;
}

export function proficiencyBonusByCr(cr: number) {
    if (cr < 5) {
        return 2;
    }

    if (cr < 9) {
        return 3;
    }

    if (cr < 13) {
        return 4;
    }

    if (cr < 17) {
        return 5;
    }

    if (cr < 21) {
        return 6;
    }

    if (cr < 25) {
        return 7;
    }

    if (cr < 29) {
        return 8;
    }

    return 9;
}

export function meetsPrerequisites(character: ResolvedCharacter, prerequisites: KeyedList<Prerequisite> | undefined) {
    return !prerequisites || Object.values(prerequisites).some(o => meetsPrerequisite(character, o));
}

export function meetsPrerequisite(character: ResolvedCharacter, prerequisite: Prerequisite) {
    const ability = prerequisite.ability;
    if (ability) {
        // TODO: Some of these modifiers might come from items? Presumably they shouldn't count for prerequisites.
        // Or maybe you can pick them, but they don't apply if the prerequisite isn't met? Check rules.
        if (ability.strength && resolveModifiedValue(character.strength) < ability.strength) {
            return false;
        }

        if (ability.dexterity && resolveModifiedValue(character.dexterity) < ability.dexterity) {
            return false;
        }

        if (ability.constitution && resolveModifiedValue(character.constitution) < ability.constitution) {
            return false;
        }

        if (ability.intelligence && resolveModifiedValue(character.intelligence) < ability.intelligence) {
            return false;
        }

        if (ability.wisdom && resolveModifiedValue(character.wisdom) < ability.wisdom) {
            return false;
        }

        if (ability.charisma && resolveModifiedValue(character.charisma) < ability.charisma) {
            return false;
        }
    }

    if (prerequisite.race) {
        if (!character.race) {
            return false;
        }

        const characterRace = character.race.base;
        if (characterRace.name !== prerequisite.race.name || characterRace.source !== prerequisite.race.source) {
            return false;
        }

        if (prerequisite.race.subrace) {
            const sr = character.race?.subrace;
            if (!sr || sr.name !== prerequisite.race.subrace.name || sr.source !== prerequisite.race.subrace.source) {
                return false;
            }
        }
    }

    if (prerequisite.class) {
        const c = character.classes[prerequisite.class.name];
        if (!c || c.classData.originalClass.source !== prerequisite.class.source) {
            return false;
        }

        if (prerequisite.class.level != null && prerequisite.class.level > c.level) {
            return false;
        }

        if (prerequisite.class.subclass) {
            if (
                !c.classData.subclass ||
                c.classData.subclass.name !== prerequisite.class.subclass.name ||
                c.classData.subclass.source !== prerequisite.class.subclass.source
            ) {
                return false;
            }
        }
    }

    if (prerequisite.feature) {
        if (
            !character.allFeatures.find(
                o => o.name === prerequisite.feature!.name && o.source === prerequisite.feature!.source
            )
        ) {
            return false;
        }
    }

    if (prerequisite.spell) {
        // TODO: Check if the character has access to this spell.
        // Check:
        // For each class, the spellcaster known spells progression. If "all", check spell list for class.
        // For each class, the known spells.
        // For each class, the additional spells (access to the spell via subclass feature etc).
        // For the character, the additional spells (access to the spell via racial traits etc).
    }

    return true;
}

export type LanguageChoice = Choice<string> & { type: "standard" | "exotic" };

export interface NamedContent {
    name: string;
    content?: string;
}

export interface MonsterMultiattackPart {
    /**
     * Any of these specific actions can be taken for this slot.
     * Values are names of other abilities available to the monster.
     */
    abilities?: string[];

    /**
     * If specified (and no names specified) then any ability with this attack type qualifies.
     */
    type?: SingleAttackType;

    /**
     * The number of attacks matching this option that can be taken as part of a multiattack action.
     * If not specified, assume 1.
     */
    amount?: number;
}

export interface MonsterAbility extends Ability {
    attackOptions?: KeyedList<KeyedList<MonsterMultiattackPart>>;
    rechargeOn?: number;
}

interface ItemModifiers<T> {
    /**
     * Creature gets a to-hit bonus when using items that match the filter.
     */
    attack?: T;

    /**
     * Specifies the base damage to use for items that match the filter as dice notation.
     * This can also refer to a lookup, which should return dice notation.
     */
    dmg1h?: string;

    /**
     * Specifies the base damage to use for items that match the filter as dice notation.
     * This can also refer to a lookup, which should return dice notation.
     */
    dmg2h?: string;

    /**
     * Adds the specified properties to the item.
     */
    addProperties?: string[];

    /**
     * Bonuses apply only to items that match this filter.
     */
    filter: ItemFilter;
}

export interface Feature extends NamedContent {
    source?: string;

    senses?: Partial<Senses<number>>;

    skillProficiencies?: Partial<Skills<number>>;
    skillProficiencyChoice?: Choice<Skill> & { value?: number; current?: number };

    weaponProficiencies?: Partial<WeaponProficiencies<boolean>>;

    languages?: string[];
    languageChoice?: LanguageChoice;

    abilities?: Partial<CoreAbilities<number>>;
    abilityChoice?: Choice<CoreAbility>;

    creatureTypes?: Partial<CreatureTypes<boolean>>;
    creatureTypeChoice?: Choice<CreatureType>;

    terrainTypes?: Partial<TerrainTypes<boolean>>;
    terrainTypeChoice?: Choice<TerrainType>;

    damageImmunities?: Partial<DamageTypes<boolean>>;
    damageImmunitiesChoice?: Choice<DamageType>;

    /**
     * Choose from a list of other features.
     */
    featureChoice?: Choice<NamedRuleRef | OptionalFeature, KeyedList<NamedRuleRef | OptionalFeature> | string>;

    /**
     * Choose from a list of feats.
     */
    featChoice?: Choice<NamedRuleRef>;

    additionalSpells?: AdditionalSpells;

    tools?: { [tool: string]: number };
    toolChoice?: Choice<NamedRuleRef, KeyedList<ItemFilter | NamedRuleRef>> & {
        value?: number;
        current?: number;
    };

    /**
     * Sets the number of attacks that can be made by the character in a single attack action.
     */
    attacks?: number;

    /**
     * Sets the critical range for the specified attack type(s).
     */
    criticalRange?: Partial<SingleAttackTypes<number>>;

    /**
     * The feat can apply advantage/disadvantage to saving throws, with an optional condition for each.
     */
    savingThrows?: Partial<
        CoreAbilities<{ advantage?: AdvantageOrDisadvantage; disadvantage?: AdvantageOrDisadvantage }>
    > & { advantage?: AdvantageOrDisadvantage; disadvantage?: AdvantageOrDisadvantage };

    /**
     * The feat can apply advantage/disadvantage to ability checks, with an optional condition for each.
     */
    abilityChecks?: Partial<
        CoreAbilities<{ advantage?: AdvantageOrDisadvantage; disadvantage?: AdvantageOrDisadvantage }>
    > & { initiative?: { advantage?: AdvantageOrDisadvantage; disadvantage?: AdvantageOrDisadvantage } };

    /**
     * If true, attacking at long range with a ranged weapon does not impose disadvantage.
     */
    ignoreLongRangePenalty?: boolean;

    /**
     * If specified, attacks of this type ignore half and three quarters cover.
     */
    ignoreCover?: SingleAttackType;

    /**
     * Options that can be selected BEFORE an attack roll is made.
     */
    beforeAttack?: KeyedList<BeforeAttackOption>;

    /**
     * Options that can be selected after an attack roll has hit.
     */
    afterAttack?: KeyedList<AfterAttackOption>;

    /**
     * Abilities that are granted by the feature.
     */
    otherAbilities?: KeyedList<Ability>;

    /**
     * Modifications to existing abilities.
     */
    modifyAbilities?: { [abilityKey: string]: Partial<Omit<Ability, "name" | "content">> };

    /**
     * Increases the maximum of the specified resource by the specified amount.
     */
    resources?: {
        [name: string]: number;
    };

    /**
     * The feature gives an AC boost by the specified amount.
     */
    ac?: ExpressionableValue<number>;

    /**
     * The feature modifies movement speeds by the specified amount.
     */
    speed?: Partial<MovementSpeeds<ExpressionableValue<number>>>;

    /**
     * Bonuses dependent on certain items being equipped.
     */
    itemModifiers?: KeyedList<ItemModifiers<ExpressionableValue<number>>>;

    condition?: {
        expression?: string;
    };

    /**
     * If specified, increases the maximum number of cantrip that the character can know by the value.
     * Note that this is only valid when used as a class/subclass feature.
     */
    maxCantrips?: number;
}

export interface OptionalFeature extends Feature {
    prerequisite?: KeyedList<Prerequisite>;
    tags?: string[];
}

// NamedRuleRef can refer to a specific item or an item group here.
export type ItemChoiceOptionItem = (NamedRuleRef | NamedItem | ItemFilter) & { quantity?: number };
export type ItemChoiceOptionCurrency = number;
export type ItemChoiceOption = (ItemChoiceOptionItem | ItemChoiceOptionCurrency)[];

export type ItemChoice = ItemChoiceOption[];

export type ModifiedNamedContent = NamedContent & {
    index?: number;
};

interface BackgroundBase extends Feature {
    source: string;
    backgroundContent?: KeyedList<NamedContent>;
    items?: ItemChoice[];
}

export interface Background extends BackgroundBase {
    variantOf?: NamedRuleRef & { modifiedContent?: KeyedList<ModifiedNamedContent> };
}

export interface ResolvedBackground extends BackgroundBase {
    variantOf?: Background;
    variant?: Background;
}

function resolveBackground(background: Background, rules: CharacterRuleSet): ResolvedBackground | undefined {
    if (!background.variantOf) {
        return background;
    }

    var variantOf = rules.backgrounds.get(background.variantOf);
    if (!variantOf) {
        console.warn(
            `Background "${background.name}" (${background.source}) could not be correctly resolved. Parent background "${background.variantOf.name}" (${background.variantOf.source}) could not be found."`
        );
        return undefined;
    }

    // Take a copy of the original, we can modify it from there.
    var bg: ResolvedBackground = Object.assign({}, variantOf, {
        name: background.name,
        source: background.source,
        content: (variantOf.content ?? "") + (background.content ? "\n\n" + background.content : ""),
        variantOf: variantOf,
        variant: background,
    });
    delete bg["key"];

    // Now modify the individual contents.
    if (background.variantOf.modifiedContent) {
        bg.backgroundContent = { ...bg.backgroundContent };

        let backgroundContentKeys: string[] | undefined;
        const modifiedKeys = keyedListToKeyArray(background.variantOf.modifiedContent);
        for (var modifiedKey of modifiedKeys) {
            var modifiedContent = background.variantOf.modifiedContent[modifiedKey];

            if (bg.backgroundContent[modifiedKey]) {
                // This replaces an existing content.
                bg.backgroundContent[modifiedKey] = modifiedContent;
            } else {
                // Either inserting or adding to the end. We'll need to set the order to be sure.
                if (backgroundContentKeys == null) {
                    backgroundContentKeys = keyedListToKeyArray(bg.backgroundContent);
                }

                if (modifiedContent.index != null && modifiedContent.index < backgroundContentKeys.length) {
                    backgroundContentKeys.splice(modifiedContent.index, 0, modifiedKey);
                } else {
                    backgroundContentKeys.push(modifiedKey);
                }
            }
        }

        // If we've changed the order by setting an index, then we'll need to update the order field
        // of every list item to be sure the order remains correct. This involved modifying the items,
        // so we need to make a copy of each one.
        if (backgroundContentKeys) {
            for (let i = 0; i < backgroundContentKeys.length; i++) {
                const key = backgroundContentKeys[i];
                bg.backgroundContent[key] = {
                    ...(background.variantOf.modifiedContent[key] ?? bg.backgroundContent[key]),
                    order: i,
                };
            }
        }
    }

    return bg;
}

export interface FeatureChoice {
    skillProficiencies?: (Skill | null)[];
    languages?: (string | null)[];
    abilities?: (CoreAbility | null)[];
    creatureTypes?: (CreatureType | null)[];
    terrainTypes?: (TerrainType | null)[];
    features?: (({ name: string; source?: string } & FeatureChoice) | null)[];
    feats?: ((NamedRuleRef & FeatureChoice) | null)[];
    tools?: (string | null)[];
    damageImmunities?: (string | null)[];

    additionalSpells?: ((NamedRuleRef | null)[] | null)[];
}

export interface AdditionalSpellBase extends Partial<AbilityUse> {
    gainAtLevel?: number;

    /**
     * If true, this spell is considered always prepared.
     */
    prepared?: boolean;

    /**
     * If true, this additional spell counts against the character's known spells count.
     */
    known?: boolean;
}

export type AdditionalSpell = NamedRuleRef & AdditionalSpellBase;
export type AdditionalSpellChoice = Choice<NamedRuleRef> & AdditionalSpellBase & { filter?: SpellFilter };
export type ResolvedAdditionalSpell = Spell & AdditionalSpellBase & { used?: number };

export interface ResolvedAdditionalSpells {
    name: string;
    source: Overwrite<NamedRuleRef, { source?: string }>;
    ability?: CoreAbility;
    spells: ResolvedAdditionalSpell[];
}

export interface AdditionalSpells {
    spells: (AdditionalSpell | AdditionalSpellChoice)[];
    ability?: CoreAbility;
}

export interface SpellFilter {
    level?: number;
    maxLevel?: number;

    school?: SchoolOfMagic[];
    class?: NamedRuleRef;
    isRitual?: boolean;
    attack?: AttackType[];
}

export function filterSpells(filter: SpellFilter | undefined, rules: CharacterRuleSet) {
    if (!filter) {
        return rules.spells.all;
    }

    const requiredClass = filter.class ? rules.classes.get(filter.class) : undefined;
    if (!requiredClass && filter.class) {
        return [];
    }

    const spells = requiredClass ? resolveClassSpellList(requiredClass, rules) ?? [] : rules.spells.all;
    return spells.filter(o => {
        if (filter.level != null && o.level !== filter.level) {
            return false;
        }

        if (filter.maxLevel != null && o.level > filter.maxLevel) {
            return false;
        }

        if (filter.school != null && filter.school.indexOf(o.school) < 0) {
            return false;
        }

        if (filter.isRitual && !o.isRitual) {
            return false;
        }

        if (filter.attack && filter.attack.length) {
            if (!o.attack || filter.attack.indexOf(o.attack) < 0) {
                return false;
            }
        }

        return true;
    });
}

export function getCurrencyTotal(currency: { [abbr: string]: number }, max?: { [abbr: string]: number }) {
    let total = 0;
    for (let abbr in currency) {
        const currencyType = CURRENCY_TYPES_BY_ABBR.get(abbr);
        let currencyValue = currency[abbr];
        if (currencyType && currencyValue) {
            if (max) {
                currencyValue = Math.min(currencyValue, max[abbr] ?? 0);
            }

            total += currencyType.value * currencyValue;
        }
    }

    return total;
}

export type BackgroundChoice = FeatureChoice & NamedRuleRef;

interface RaceBase {
    size: CreatureSize;
    speed: Partial<MovementSpeeds<number>>;
    resistances: DamageTypes<boolean>;
    vulnerabilities: DamageTypes<boolean>;
    damageImmunities: DamageTypes<boolean>;
}

export interface Race extends RaceBase {
    name: string;
    source: string;
    fluff?: string;
    subraces?: KeyedList<Subrace>;
    traits: KeyedList<Feature>;
}

export interface Subrace extends Partial<RaceBase> {
    name: string;
    source: string;
    traits: KeyedList<Feature & { replaces?: string }>;
    fluff?: string;
}

export interface ResolvedRace extends RaceBase {
    name: string;
    base: Race;
    subrace?: Subrace;
    fluff?: string;
    traits: Feature[];
}

export interface ClassFeature extends Feature {
    /**
     * The level that this class feature is gained.
     * If the feature is referenced by another feature (e.g. it's an optional feature you can choose as part of
     * a different feature) then the level can be undefined.
     */
    level?: number;

    isSubclassFeature?: boolean;
}

export interface CharacterSubclass {
    name: string;
    source: string;
    summary?: Feature;
    features: KeyedList<ClassFeature>;
    resources?: KeyedList<CharacterResource>;

    /**
     * Any effects that are referenced by features of this subclass.
     */
    effects?: KeyedList<ApplicableAbilityEffect>;

    /**
     * Any optional features that are relevant only to this subclass.
     */
    optionalFeatures?: KeyedList<OptionalFeature & Partial<NamedRuleRef>>;
}

export interface TableLookup {
    type: "dice" | "text" | "value";
    values: (string | number)[];
}

interface CharacterClassBase extends NamedRuleRef {
    /**
     * Uri to an image to use as the icon/logo for this class.
     */
    icon?: string;

    fluff?: string;

    hitDie: DiceType;

    savingThrowProficiencies: Partial<CoreAbilities<boolean>>;
    armorProficiencies: Partial<ArmorProficiencies<boolean>>;
    weaponProficiencies: Partial<WeaponProficiencies<boolean>>;
    skillProficiencies?: Partial<Skills<number>>;
    skillProficiencyChoice?: Choice<Skill> & {
        value?: number;
    };

    /**
     * The tools that the character is proficient in. The key here is the rule ref for the
     * relevant item.
     */
    toolProficiencies?: { [tool: string]: number };
    toolProficiencyChoice?: Choice<NamedRuleRef, KeyedList<ItemFilter | NamedRuleRef>> & {
        value?: number;
        current?: number;
    };

    startingItems: ItemChoice[];
    startingGold: string;

    additionalSpellSlots?: {
        name: string;
        spellSlots: number[][];
        reset?: RestType;
    };

    /**
     * A collection of lookups that have a value based on the number of levels taken in this class.
     * These can be referenced from elsewhere and should be substituted in during the resolve process.
     * They can be used to reproduce the class table that appears near the start of each class in the PHB.
     */
    byLevel?: { [name: string]: TableLookup };

    /**
     * The requirements for and proficiencies gained by multiclassing into this class.
     */
    multiclassing: {
        requirements: Partial<CoreAbilities<number>>;
        armorProficiencies: Partial<ArmorProficiencies<boolean>>;
        weaponProficiencies: Partial<WeaponProficiencies<boolean>>;
        skillProficiencies?: Partial<Skills<number>>;
        skillProficiencyChoice?: Choice<Skill> & {
            value?: number;
            current?: number;
        };
        toolProficiencies?: { [tool: string]: number };
        toolProficiencyChoice?: Choice<NamedRuleRef, KeyedList<ItemFilter | NamedRuleRef>> & {
            value?: number;
            current?: number;
        };
    };

    /**
     * Wizard - spellcastingProgression === 1, cantripProgression != null, preparedSpells != null
     * Cleric - spellcastingProgression === 1, cantripProgression != null, preparedSpells != null
     * Ranger - spellcastingProgression === 0.5, spellsKnownProgression != null
     * Bard   - spellcastingProgression === 1, cantripProgression != null, spellsKnownProgression != null
     */

    /**
     * The ability to use when casting spells for this class.
     */
    spellcastingAbility?: CoreAbility;

    /**
     * The number of spells known for each level of this class.
     */
    spellsKnownProgression?: number[] | "all";

    /**
     * How much the character progresses in spellcasting level for each level in this class.
     * This determines the number of spell slots for the character. Most spellcasting classes
     * (e.g. wizard, cleric) will have 1 here. Others such as ranger will have 0.5. Fighters
     * and other classes (including Warlock, which uses pact magic instead) will have undefined
     * or 0.
     */
    spellcastingProgression?: number;

    /**
     * The number of cantrips known at each level for the class.
     * Should be undefined for classes that do not known cantrips.
     */
    cantripProgression?: number[];

    subclassTitle: string;
    subclasses: KeyedList<CharacterSubclass>;

    /**
     * Any effects that are referenced by features of this class.
     */
    effects?: KeyedList<ApplicableAbilityEffect & Partial<NamedRuleRef>>;

    /**
     * Any optional features that are relevant only to this class.
     */
    optionalFeatures?: KeyedList<OptionalFeature & Partial<NamedRuleRef>>;

    spellList?: KeyedList<NamedRuleRef>;
}

export interface CharacterClass extends CharacterClassBase {
    features: KeyedList<ClassFeature>;
    resources?: KeyedList<CharacterResource>;
}

export interface CharacterResource {
    name: string;
    reset: RestType;

    /**
     * The maximum uses of this resource comes from a lookup of this value.
     * The lookup could be a byLevel lookup, or a core ability to use the modifier for that ability.
     */
    maxFrom?: string | CoreAbility;

    /**
     * An expression for calculating the maximum number of uses for this resource. Use this if the
     * maximum resource calculation is more complicated than a single core ability modifier (i.e.
     * if it's 1 + charisma instead of just charisma).
     */
    maxExpr?: string;
}

export type ResolvedCharacterResource = CharacterResource & { used: number; max: number };

export interface ResolvedCharacterClass extends CharacterClassBase {
    subclass?: CharacterSubclass;

    /**
     * The original character class, before resolving.
     */
    originalClass: CharacterClass;

    features: ClassFeature[];
    resources?: CharacterResource[];
}

export interface CreatureCombatTurn {
    /**
     * Gets a value indicating whether the creature has used its action this combat turn.
     */
    action?: boolean;

    /**
     * Gets a value indicating whether the creature has been surprised.
     */
    surprised?: boolean;

    /**
     * Gets the number of attacks that have been made so far this turn as a part of an attack action.
     * The key can be either the inventory key of the weapon that was used for the attack (for characters),
     * or the attack ability used (for monsters).
     */
    attacks?: { [key: string]: number };

    /**
     * If the creature has a choice of multiattacks, then this is the key that identifies which one was chosen.
     */
    attackOption?: string;

    /**
     * If the creature has a choice of multiattacks, then this is the key of the ability that contains the chosen option.
     */
    attackAbility?: string;

    /**
     * For monster abilities requiring a recharge, this represents the recharge roll made for this turn.
     */
    recharges?: { [key: string]: number };

    /**
     * The number of legendary actions that have been taken since the start of the creature's last turn.
     */
    legendary?: number;

    /**
     * Gets a value indicating whether the creature has used its bonus action this combat turn.
     */
    bonus?: boolean;

    /**
     * Gets a value indicating whether the creature has used its reaction this combat turn.
     */
    reaction?: boolean;

    /**
     * Gets the movement remaining for this turn. If not specified, defaults to the creatures movement speed.
     */
    movement?: Partial<MovementSpeeds<number>>;

    /**
     * Records whether the creature has made its death saving throw this turn, if required.
     */
    deathSavingThrow?: boolean;

    /**
     * Records whether the creature has made their saving throw against any effects that require it this turn.
     */
    savingThrows?: {
        [key: string]: boolean;
    };
}

export enum ActorType {
    Monster = "monster",
    Character = "character",
}

interface CreatureBase<TType extends ActorType, T> extends CoreAbilities<T> {
    // The current HP of the creature. If undefined assume max HP.
    hp?: number;

    // Temp HP on top of the creature's current/max HP. Damage is subtracted from it first, but it cannot be healed.
    tempHp?: number;

    name: string;
    type: TType;
    resistances?: KeyedList<DamageTypes<boolean>>;
    vulnerabilities?: KeyedList<DamageTypes<boolean>>;
    damageImmunities?: KeyedList<DamageTypes<boolean>>;
    conditionImmunities?: KeyedList<ConditionalCreatureConditions>;

    abilityUsage?: {
        [key: string]: {
            // The number of times this ability has been used since the last short/long rest as applicable.
            used?: number;
        };
    };

    conditions?: Partial<CreatureConditions<boolean>>;

    combatTurn?: CreatureCombatTurn;

    /**
     * The last ability used by the character. Can be any of:
     * a) A spell key.
     * b) An ability key.
     * c) An annotation that is related to the spell or ability last used - the spell or ability itself can be found via the annotation.
     * This is used to trigger animations when a spell is used or annotation triggered in some way.
     */
    lastUsedAbility?: { instanceId: string; id: string };
    lastAppliedAbility?: DnD5EAnnotation;
}

export interface MonsterArmorClass {
    value: number;
    from?: string[];
}

/**
 * Describes a spell and how (and if) a monster can cast it.
 */
export interface MonsterSpell extends Partial<AbilityUse> {
    /**
     * If specified, the spell can be cast using spell slots of the specified level or higher.
     */
    level?: number;
}

export interface MonsterSpellcasting {
    headerContent?: string;
    footerContent?: string;
    ability: CoreAbility;
    spells: { [key: string]: MonsterSpell };
    spellSlots?: number[];
    dc?: number;
}

export type ConditionalCreatureConditions = Conditional<Partial<CreatureConditions<boolean>>>;
// export type ConditionalMovementSpeeds<T> = Conditional<Partial<MovementSpeeds<T>>>;
// export type ConditionalSenses = Conditional<Partial<Senses<number>>>;

export const challengeRatings: number[] = [
    0, 0.125, 0.25, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30,
];

const xpForChallengeRatings: number[] = [
    10, 25, 50, 100, 200, 450, 700, 1100, 1800, 2300, 2900, 3900, 5000, 5900, 7200, 8400, 10000, 11500, 13000, 15000,
    18000, 20000, 22000, 25000, 33000, 41000, 50000, 62000, 75000, 90000, 105000, 120000, 135000, 155000,
];

export function crToString(cr: number) {
    if (cr === 0.125) {
        return "⅛";
    } else if (cr === 0.25) {
        return "¼";
    } else if (cr === 0.5) {
        return "½";
    }

    return cr.toString();
}

export function crToXp(cr: number) {
    if (cr === 0) {
        return 0;
    }

    const i = challengeRatings.indexOf(cr);
    if (i < 0) {
        throw new Error("Invalid CR " + cr);
    }

    return xpForChallengeRatings[i];
}

export interface MonsterBase<T> extends CreatureBase<ActorType.Monster, T> {
    name: string;
    source: string;
    group?: string;
    alignment?: Alignment | "Any"; // Alignments are optional, Any means any alignment, undefined means no alignment.
    size?: CreatureSize;
    creatureType?: CreatureType;
    languages?: string[];
    environments?: Partial<TerrainTypes<boolean>>;
    cr?: number;
    passivePerception?: number;
    ac?: MonsterArmorClass;
    maxHpInfo: {
        average?: number;
        dice?: StoredDiceBag;
    };

    spellcasting?: KeyedList<MonsterSpellcasting>;

    // Keeps track of additional spell usages for innate spells that have a use per short/long rest count.
    // May be used to track other things related to additional spells in future?
    innateSpellUsage?: {
        // The key of the spellcasting entry the spell relates to (i.e. the key of the KeyedList<MonsterSpellcasting> in spellcasting).
        [name: string]: {
            // The key (see getRuleKey) of the spell.
            [spellKey: string]: {
                // The number of times this spell has been used since the last short/long rest as applicable.
                used?: number;
            };
        };
    };

    usedSpellSlots?: number[];

    traits?: KeyedList<NamedContent>;
    abilities?: KeyedList<MonsterAbility>;

    variants?: KeyedList<NamedContent>;

    /**
     * The number of hit dice that have been spent during a short rest.
     * On a long rest, this number is reduced by half the monster's total hit dice.
     */
    hitDiceSpent?: { [dice in DiceType]?: number };

    /**
     * The number of legendary actions that can be taken per round by this monster.
     */
    legendaryActions?: number;

    /**
     * The number of legendary resistances that have been used by the monster.
     */
    usedLegendaryResistances?: number;

    /**
     * The maximum number of legendary resistances that the monster can use in one day.
     */
    legendaryResistances?: number;

    /**
     * If the monster is dead, this flag will be set. If the monster has 0 hp but this flag is NOT set, then it's unconscious.
     */
    isDead?: boolean;
}

export interface Concentration {
    instanceId: string;
    annotation?: string;
    location?: string;
}

/**
 * Common properties for both Characters and Monsters that are different from their ResolvedCharacter/ResolvedMonster
 * counterparts (and therefore can't go on CreatureBase).
 */
interface CreatureCommon {
    speed?: Partial<MovementSpeeds<number>>;
}

// Only works if I declare it as a type first for some reason.
type MonsterBaseInterface = CreatureCommon & Partial<MonsterBase<number>>;
export interface Monster extends MonsterBaseInterface {
    name: string;
    source: string;

    images?: TokenImageMetadata[];

    /**
     * The name of the monster. This is required to change the name used for the monster, as the name field is used as part of the ID
     * used to reference the base monster.
     */
    label?: string;
    savingThrows?: Partial<CoreAbilities<number>>;
    skills?: Partial<Skills<number>>;
    senses?: Partial<Senses<number>>;

    /**
     * A reference to the spell the character is currently concentrating on, if any.
     * If the spell is represented by an annotation, a reference to that can also be included.
     */
    concentrating?: NamedRuleRef & Concentration;
    effects?: KeyedList<
        (AppliedAbilityEffect | AppliedAbilityEffectRef) & {
            feature?: NamedRuleRef;
            ability?: string;
            spell?: NamedRuleRef;
        }
    >;

    /**
     * Holds the current state of any monster abilities that have state (i.e. recharging abilities).
     */
    abilityState?: KeyedList<MonsterAbilityState>;

    maxHp?: number; // Optionally filled out for individual monster instances - assume maxHpInfo.average if undefined.

    /**
     * Contains other forms that the monster might take. These can then be referenced by ability effects to transform
     * the monster into these forms. These forms will not appear in bestiaries etc as a monster in their own right.
     * Any parts of the monster not specified will be inherited from the base form.
     */
    otherForms?: KeyedList<MergeableRule<Monster>>;
}

export interface MonsterAbilityState {
    isRecharging?: boolean;
}

interface ResolvedCreatureCommon {
    initiativeBonus: ModifiedValue;
    speed: MovementSpeeds<ModifiedValue>;
    skillChecks: Required<Skills<ModifiedValue>>;
    senses: Senses<ModifiedValue>;

    /**
     * For every 1ft of movement, the creature requires the amount specified here as well.
     */
    moveCost: number;

    /**
     * If the creature is under a shape changing effect (e.g. polymorph spell, druid wild shape) then the
     * form that they have been transformed into is available here.
     */
    transformedInto?: ResolvedMonster & { byEffect: string; transform: CreatureTransformation };

    /**
     * Gets a value indicating whether this creature is dead.
     */
    isDead: boolean;

    /**
     * Gets a value indicating whether this creature is currently able to take actions (including bonus actions).
     */
    canTakeActions: boolean;

    /**
     * Gets a value indicating whether this creature is currently able to take reactions.
     */
    canTakeReactions: boolean;

    /**
     * Gets a value indicating whether this creature is able to cast spells (if it knows any).
     * Note that even if this is true the creature must also be able to take actions or reactions (depending on the
     * spell casting time) to be able to successfully cast a spell.
     */
    canCastSpells: boolean;
}

// TODO: Rework to remove most of the collections of things like senses, based on the currently enabled conditions for the monster.
// i.e. one of the senses entries means that the monster has blindsight if it's in bat form - collapse that to a single senses object
// based on a collection of currently enabled conditions on the stored monster.
type ResolvedMonsterBaseInterface = ResolvedCreatureCommon & MonsterBase<ModifiedValue>;
export interface ResolvedMonster extends ResolvedMonsterBaseInterface {
    resolvedFrom: Monster;

    savingThrows: CoreAbilities<ModifiedValue>;

    spellSlots: number[];
    usedSpellSlots: number[];

    concentrating?: Spell & Concentration;

    hitDice: number;
    effects?: KeyedList<
        AppliedAbilityEffect & {
            source?: string;
            condition?: CreatureCondition;
        } & AppliedAbilityEffectSource
    >;

    maxHp: ModifiedValue;

    abilities?: KeyedList<MonsterAbility & MonsterAbilityState>;

    /**
     * If this is the form that a creature has been transformed into by a shape changing effect (e.g. polymorph
     * spell, druid wild shape) then their original form is available here.
     */
    transformedFrom?: ResolvedMonster | ResolvedCharacter;
}

export interface CreatureDying {
    stable?: boolean;
    failure?: number;
    success?: number;
}

interface CharacterBase<T = number> extends CreatureBase<ActorType.Character, T> {
    // Flag set on character creation, cleared when the player considers the character done.
    // This can be used to provide a curated walkthrough when creating the character, but switch to
    // a more freeform advanced mode when the character is complete.
    isIncomplete?: boolean;

    alignment?: Alignment; // Alignments are optional

    // Extra saving throw proficiencies, above those provided by class etc.
    // This won't normally need to be changed.
    savingThrowProficiencies: Partial<CoreAbilities<boolean>>;

    // Extra skill proficiencies, above those provided by class/background features.
    skillProficiencies: Partial<Skills<number>>;

    /**
     * The number of spell slots that have been used for each spell level.
     */
    usedSpellSlots?: { default: number[]; [name: string]: number[] };

    raceChoices: {
        [trait: string]: FeatureChoice;
    };

    /**
     * The current exhaustion level for this character.
     */
    exhaustion?: number;

    /**
     * If the character drops to 0 hp, stability and death saving throws are tracked here.
     */
    dying?: CreatureDying;

    /**
     * Markdown describing the appearance of the character.
     */
    appearance?: string;
}

export interface ClassLevels {
    /**
     * The name of the class.
     */
    class: NamedRuleRef;

    /**
     * The name of the subclass.
     */
    subclass?: NamedRuleRef;

    /**
     * True if this class is the initial class (the one taken at level 1) for the character.
     */
    isInitial?: boolean;

    /**
     * The level of the character in this particular class.
     */
    level: number;

    /**
     * The HP rolls for the character, one for each level in the class.
     * The key is the level as a string (i.e. "1", "4").
     */
    hpPerLevel: {
        [level: string]: number;
    };

    /**
     * The skill proficiency choices for the class.
     */
    skillProficiencies?: (Skill | null)[];

    /**
     * The tool proficiency choices for the class.
     */
    tools?: (string | null)[];

    /**
     * The feature choices for the class, per level.
     */
    choices: {
        [level: number]: {
            [feature: string]: FeatureChoice;
        };
    };

    /**
     * The spells that have been learned - relevant for classes such as Wizard that choose spells on level up
     * and can scribe others at any time.
     */
    knownSpells?: NamedRuleRef[];

    /**
     * The spells from the known spells for this class/character that are currently prepared for use.
     */
    preparedSpells?: NamedRuleRef[];

    /**
     * The number of hit dice that have been spent during a short rest.
     * On a long rest, this number is reduced by half the character's total level.
     */
    hitDiceSpent?: number;
}

/**
 * Everything needed to store a complete character and nothing more.
 * To get an object that accurately represents the current state of the character, with full info and
 * modifiers applied etc, the character must be resolved into a ResolvedCharacter using resolveCharacter.
 */
type CharacterBaseInterface = CreatureCommon & Partial<CharacterBase<number>>;
export interface Character extends CharacterBaseInterface {
    name: string;
    type: ActorType.Character; // Need to redefine this here as we're doing a Partial<> on CharacterBase, and type is on CreatureBase.
    race?: NamedRuleRef;
    subrace?: NamedRuleRef;
    classes?: { [id: string]: ClassLevels };
    inventory?: Inventory;
    background?: BackgroundChoice;

    /**
     * A reference to the spell the character is currently concentrating on, if any.
     * If the spell is represented by an annotation, a reference to that can also be included.
     */
    concentrating?: NamedRuleRef & Concentration;

    // Keeps track of additional spell usages for innate spells that have a use per short/long rest count.
    // May be used to track other things related to additional spells in future?
    additionalSpells?: {
        // The name of the additional spell source. This is often the name of the feat/subclass etc that provides the spell(s).
        [name: string]: {
            // The key (see getRuleKey) of the spell.
            [spellKey: string]: {
                // The number of times this spell has been used since the last short/long rest as applicable.
                used?: number;
            };
        };
    };

    currency?: {
        [type: string]: number; // Key is abbr of currency (i.e. gp, sp, pp).
    };

    /**
     * The amount of each character/class specific resource that has been used.
     */
    usedResources?: {
        [name: string]: number;
    };

    effects?: KeyedList<
        (AppliedAbilityEffect | AppliedAbilityEffectRef) & {
            feature?: NamedRuleRef;
            ability?: string;
            spell?: NamedRuleRef;
        }
    >;
}

export type Creature = Character | Monster;

export function isCharacter(creature: ResolvedCharacter | ResolvedMonster | undefined): creature is ResolvedCharacter;
export function isCharacter(creature: any): creature is Character;
export function isCharacter(creature: any): creature is Character {
    return !!creature && creature.type === ActorType.Character;
}

export function isMonster(creature: ResolvedCharacter | ResolvedMonster): creature is ResolvedMonster;
export function isMonster(creature: any): creature is Monster;
export function isMonster(creature: any): creature is Monster {
    return !!creature && creature.type === ActorType.Monster;
}

export interface CreatureCondition extends NamedContent {
    /**
     * String containing an svg to use as the icon for this condition.
     */
    icon?: string;
}

export interface CreatureStatus extends NamedRuleRef {
    content?: string;
}

export interface CharacterRuleStoreSummary {
    id: string;
    name: string;
    description?: string;
    attribution?: string;

    // If the rule store is in a user's private library (rather than being a core ruleset available directly
    // from the server) then the URI will be recorded here and can be used to identify those rule stores.
    uri?: string;
}

export interface CharacterRuleStore extends CharacterRuleStoreSummary {
    dependencies?: {
        [id: string]: string;
    };
    optionalDependencies?: {
        [id: string]: string;
    };

    classes?: MergeableRule<CharacterClass>[];
    races?: MergeableRule<Race>[];
    optionalFeatures?: (NamedRuleRef & OptionalFeature)[];
    feats?: (NamedRuleRef & Feature)[];
    spells?: MergeableRule<Spell>[];
    backgrounds?: Background[];
    items?: {
        properties?: ItemProperty[];
        groups?: MergeableRule<ItemGroup>[];
        items?: Item[];
        variants?: ItemVariant[];
    };
    monsters?: MergeableRule<Monster>[];
    actions?: (Ability & NamedRuleRef)[];
    conditions?: CreatureCondition[];
    statuses?: CreatureStatus[];
    effects?: MergeableRule<ApplicableAbilityEffect & NamedRuleRef>[];
}

const coreSets: { [id: string]: string | boolean } = {
    SRD: true,
    PHB: true,
    DMG: true,
    MM: true,
};

function addRuleStoreDependencies(
    store: CharacterRuleStore,
    dependencies: { [id: string]: string | boolean },
    isRequired: boolean,
    stores: CharacterRuleStore[],
    allStores: CharacterRuleStore[]
) {
    const keys = Object.keys(dependencies);
    for (let dependsOnId of keys) {
        // TODO: At the moment we just interpret any truthy value as a dependency.
        // In the future we can use this value for version numbers etc if necessary.
        if (dependencies[dependsOnId]) {
            const dependency = allStores.find(o => o.id === dependsOnId);
            if (dependency) {
                addRuleStore(dependency, stores, allStores);
            } else if (isRequired) {
                console.warn(`DnD5E rule set ${store.id} depends on ${dependsOnId}, but it could not be found.`);
            }
        }
    }
}

function addRuleStore(store: CharacterRuleStore, stores: CharacterRuleStore[], allStores: CharacterRuleStore[]) {
    if (stores.indexOf(store) >= 0) {
        return;
    }

    if (store.id !== "SRD") {
        // Make sure all dependencies are added.
        // The core sets all depend on the SRD, but don't necessarily expect to find it as the SRD is only strictly
        // required if one of the core sets (PHB, DMG, MM) is missing.
        if (coreSets[store.id]) {
            addRuleStoreDependencies(store, { SRD: true }, false, stores, allStores);
        } else {
            // Non-core sets rely on the core sets, but if there's no explicit dependency then it's not an
            // error if we don't find them. Just make sure non-core sets are processed AFTER any core sets.
            addRuleStoreDependencies(store, coreSets, false, stores, allStores);
        }

        if (store.dependencies) {
            addRuleStoreDependencies(store, store.dependencies, true, stores, allStores);
        }
    }

    stores.push(store);
}

export function createRuleSet(allStores: CharacterRuleStore[]): CharacterRuleSet {
    const ruleStores: CharacterRuleStore[] = [];
    for (let store of allStores) {
        addRuleStore(store, ruleStores, allStores);
    }

    const itemProperties = ruleStores.flatMap(o => o.items?.properties ?? []);
    const itemGroups = ruleStores.flatMap(o => o.items?.groups ?? []);
    const items = ruleStores.flatMap(o => o.items?.items ?? []);
    const itemVariants = ruleStores.flatMap(o => o.items?.variants ?? []);

    const conditions = ruleStores.flatMap(o => o.conditions ?? []);

    const effects: MergeableRule<ApplicableAbilityEffect & NamedRuleRef>[] = [];
    const features: (NamedRuleRef & Feature)[] = [];
    const addEffects = (source: string, ef: KeyedList<ApplicableAbilityEffect & Partial<NamedRuleRef>> | undefined) => {
        if (!ef) {
            return;
        }

        for (let effect of keyedListToArray(ef)) {
            if (isNamedRuleRef(effect)) {
                effects.unshift(effect);
            } else {
                effects.unshift(Object.assign({}, effect, { source: source }));
            }
        }
    };

    const optionalFeatures: (NamedRuleRef & OptionalFeature)[] = [];
    const addOptionalFeatures = (
        source: string,
        of: KeyedList<OptionalFeature & Partial<NamedRuleRef>> | undefined
    ) => {
        if (!of) {
            return;
        }

        for (let optionalFeature of keyedListToArray(of)) {
            if (optionalFeature.source) {
                optionalFeatures.push(optionalFeature as NamedRuleRef & OptionalFeature);
            } else {
                optionalFeatures.push({ ...optionalFeature, source: source });
            }
        }
    };

    const skills: Skills<SkillDetails> = {
        acrobatics: {
            label: "Acrobatics",
            modifier: "dexterity",
            content:
                "Your Dexterity (:skill[Acrobatics]) check covers your attempt to stay on your feet in a tricky situation, such as when you're trying to run across a sheet of ice, balance on a tightrope, or stay upright on a rocking ship's deck. The DM might also call for a Dexterity (:skill[Acrobatics]) check to see if you can perform acrobatic stunts, including dives, rolls, somersaults, and flips.",
        },
        animal_handling: {
            label: "Animal Handling",
            modifier: "wisdom",
            content:
                "When there is any question whether you can calm down a domesticated animal, keep a mount from getting spooked, or intuit an animal's intentions, the DM might call for a Wisdom (:skill[Animal Handling]) check. You also make a Wisdom (:skill[Animal Handling]) check to control your mount when you attempt a risky maneuver.",
        },
        arcana: {
            label: "Arcana",
            modifier: "intelligence",
            content:
                "Your Intelligence (:skill[Arcana]) check measures your ability to recall lore about spells, magic items, eldritch symbols, magical traditions, the planes of existence, and the inhabitants of those planes.",
        },
        athletics: {
            label: "Athletics",
            modifier: "strength",
            content:
                "Your Strength (:skill[Athletics]) check covers difficult situations you encounter while climbing, jumping, or swimming. Examples include the following activities:\n- You attempt to climb a sheer or slippery cliff, avoid hazards while scaling a wall, or cling to a surface while something is trying to knock you off.\n- You try to jump an unusually long distance or pull off a stunt mid jump.\n- You struggle to swim or stay afloat in treacherous currents, storm-tossed waves, or areas of thick seaweed. Or another creature tries to push or pull you underwater or otherwise interfere with your swimming.",
        },
        deception: {
            label: "Deception",
            modifier: "charisma",
            content:
                "Your Charisma (:skill[Deception]) check determines whether you can convincingly hide the truth, either verbally or through your actions. This deception can encompass everything from misleading others through ambiguity to telling outright lies. Typical situations include trying to fast-talk a guard, con a merchant, earn money through gambling, pass yourself off in a disguise, dull someone's suspicions with false assurances, or maintain a straight face while telling a blatant lie.",
        },
        history: {
            label: "History",
            modifier: "intelligence",
            content:
                "Your Intelligence (:skill[History]) check measures your ability to recall lore about historical events, legendary people, ancient kingdoms, past disputes, recent wars, and lost civilizations.",
        },
        insight: {
            label: "Insight",
            modifier: "wisdom",
            content:
                "Your Wisdom (:skill[Insight]) check decides whether you can determine the true intentions of a creature, such as when searching out a lie or predicting someone's next move. Doing so involves gleaning clues from body language, speech habits, and changes in mannerisms.",
        },
        intimidation: {
            label: "Intimidation",
            modifier: "charisma",
            content:
                "When you attempt to influence someone through overt threats, hostile actions, and physical violence, the DM might ask you to make a Charisma (:skill[Intimidation]) check. Examples include trying to pry information out of a prisoner, convincing street thugs to back down from a confrontation, or using the edge of a broken bottle to convince a sneering vizier to reconsider a decision.",
        },
        investigation: {
            label: "Investigation",
            modifier: "intelligence",
            content:
                "When you look around for clues and make deductions based on those clues, you make an Intelligence (:skill[Investigation]) check. You might deduce the location of a hidden object, discern from the appearance of a wound what kind of weapon dealt it, or determine the weakest point in a tunnel that could cause it to collapse. Poring through ancient scrolls in search of a hidden fragment of knowledge might also call for an Intelligence (:skill[Investigation]) check.",
        },
        medicine: {
            label: "Medicine",
            modifier: "wisdom",
            content:
                "A Wisdom (:skill[Medicine]) check lets you try to stabilize a dying companion or diagnose an illness.",
        },
        nature: {
            label: "Nature",
            modifier: "intelligence",
            content:
                "Your Intelligence (:skill[Nature]) check measures your ability to recall lore about terrain, plants and animals, the weather, and natural cycles.",
        },
        perception: {
            label: "Perception",
            modifier: "wisdom",
            content:
                "Your Wisdom (:skill[Perception]) check lets you spot, hear, or otherwise detect the presence of something. It measures your general awareness of your surroundings and the keenness of your senses.\n\nFor example, you might try to hear a conversation through a closed door, eavesdrop under an open window, or hear monsters moving stealthily in the forest. Or you might try to spot things that are obscured or easy to miss, whether they are orcs lying in ambush on a road, thugs hiding in the shadows of an alley, or candlelight under a closed secret door.",
        },
        performance: {
            label: "Performance",
            modifier: "charisma",
            content:
                "Your Charisma (:skill[Performance]) check determines how well you can delight an audience with music, dance, acting, storytelling, or some other form of entertainment.",
        },
        persuasion: {
            label: "Persuasion",
            modifier: "charisma",
            content:
                "When you attempt to influence someone or a group of people with tact, social graces, or good nature, the DM might ask you to make a Charisma (:skill[Persuasion]) check. Typically, you use persuasion when acting in good faith, to foster friendships, make cordial requests, or exhibit proper etiquette. Examples of persuading others include convincing a chamberlain to let your party see the king, negotiating peace between warring tribes, or inspiring a crowd of townsfolk.",
        },
        religion: {
            label: "Religion",
            modifier: "intelligence",
            content:
                "Your Intelligence (:skill[Religion]) check measures your ability to recall lore about deities, rites and prayers, religious hierarchies, holy symbols, and the practices of secret cults.",
        },
        sleight_of_hand: {
            label: "Sleight of Hand",
            modifier: "dexterity",
            content:
                "Whenever you attempt an act of legerdemain or manual trickery, such as planting something on someone else or concealing an object on your person, make a Dexterity (:skill[Sleight of Hand]) check. The DM might also call for a Dexterity (:skill[Sleight of Hand]) check to determine whether you can lift a coin purse off another person or slip something out of another person's pocket.",
        },
        stealth: {
            label: "Stealth",
            modifier: "dexterity",
            content:
                "Make a Dexterity (:skill[Stealth]) check when you attempt to conceal yourself from enemies, slink past guards, slip away without being noticed, or sneak up on someone without being seen or heard.",
        },
        survival: {
            label: "Survival",
            modifier: "wisdom",
            content:
                "The DM might ask you to make a Wisdom (:skill[Survival]) check to follow tracks, hunt wild game, guide your group through frozen wastelands, identify signs that owlbears live nearby, predict the weather, or avoid quicksand and other natural hazards.",
        },
    };

    const classesStore = createNamedRuleStore(ruleStores, o => o.classes);
    const racesStore = createNamedRuleStore(ruleStores, o => o.races);

    // Collect monster alternate forms.
    const monsters = createNamedRuleStore<
        Monster & {
            mainForm?: Monster | undefined;
        }
    >(ruleStores, o => {
        if (!o.monsters) {
            return undefined;
        }

        const monsters = [...o.monsters];
        for (let i = 0; i < monsters.length; i++) {
            const monster = monsters[i];
            if (isMergeRule(monster)) {
                if (monster.otherForms) {
                    console.warn(
                        `Monster ${monster.name} (${monster.source}) cannot apply extra forms as a merge operation.`
                    );
                }
            } else if (monster.otherForms) {
                const otherForms = mapKeyedList(monster.otherForms, o => {
                    let finalForm: Monster & { mainForm: Monster };
                    if (isMergeRule(o)) {
                        // Merge this form with the main monster to produce the final form.
                        finalForm = mergeRules(monster, o as any) as Monster & { mainForm: Monster };
                        finalForm.mainForm = monster;
                        delete finalForm["ruleAction"];
                    } else {
                        finalForm = Object.assign({}, o, { mainForm: monster });
                    }

                    return finalForm;
                });

                if (otherForms) {
                    monsters.splice(i + 1, 0, ...otherForms);
                    i += otherForms?.length;
                }
            }
        }

        return monsters;
    });

    // Collect global effects & features from classes.
    for (let cls of classesStore.all) {
        addEffects(cls.source, cls.effects);
        addOptionalFeatures(cls.source, cls.optionalFeatures);

        for (let sc of Object.values(cls.subclasses)) {
            addEffects(sc.source, sc.effects);
            addOptionalFeatures(cls.source, cls.optionalFeatures);
        }
    }

    // Put all the class features into one searchable rule store.
    for (let c of classesStore.all) {
        features.push(...(Object.values(c.features).filter(o => o.name && o.source) as (NamedRuleRef & Feature)[]));
        for (let sc of Object.values(c.subclasses)) {
            features.push(
                ...(Object.values(sc.features).filter(o => o.name && o.source) as (NamedRuleRef & Feature)[])
            );
        }
    }

    const spellsStore = createNamedRuleStore(ruleStores, o => o.spells);
    for (let spell of spellsStore.all) {
        if (spell.applicableEffects) {
            addEffects(spell.source, spell.applicableEffects);
        }
    }

    return {
        createdFrom: ruleStores,
        classes: classesStore,
        races: racesStore,
        optionalFeatures: createNamedRuleStore(
            [{ optionalFeatures: optionalFeatures }, ...ruleStores],
            o => o.optionalFeatures
        ),
        feats: createNamedRuleStore(ruleStores, o => o.feats),
        spells: spellsStore,
        backgrounds: createNamedRuleStore(ruleStores, o => o.backgrounds),
        items: createItemStore(new Map(itemProperties.map(o => [o.abbreviation, o])), items, itemGroups, itemVariants),
        monsters: monsters,
        skills: skills,
        actions: createNamedRuleStore(ruleStores, o => o.actions),
        conditions: new Map(conditions.map(o => [o.name.toLowerCase(), o])),
        statuses: createNamedRuleStore(ruleStores, o => o.statuses),
        features: createNamedRuleStore(features),
        effects: createNamedRuleStore([{ effects: effects }, ...ruleStores], o => o.effects),
    };
}

export interface CharacterRuleSet {
    createdFrom: CharacterRuleStore[];

    classes: NamedRuleStore<CharacterClass>;
    races: NamedRuleStore<Race>;
    optionalFeatures: NamedRuleStore<NamedRuleRef & OptionalFeature>;
    feats: NamedRuleStore<NamedRuleRef & OptionalFeature>;
    spells: NamedRuleStore<Spell>;
    backgrounds: NamedRuleStore<Background>;
    items: ItemStore;
    monsters: NamedRuleStore<Monster & { mainForm?: Monster }>;
    skills: Skills<SkillDetails>;
    actions: NamedRuleStore<Ability & NamedRuleRef>;
    conditions: Map<string, CreatureCondition>;
    statuses: NamedRuleStore<CreatureStatus>;
    features: NamedRuleStore<Feature>;
    effects: NamedRuleStore<ApplicableAbilityEffect>;
}

export function getMaxSpellLevel(c: ResolvedClassLevels) {
    // Work out the highest spell level available for this class.
    const spellcasterProgression = Math.floor(c.level * (c.classData.spellcastingProgression ?? 0));
    let maxSpellLevel = spellcasterProgression < 1 ? 0 : spellSlots[spellcasterProgression - 1].length;

    // If additional spell slots are specified, use that instead of spellcasting progression if it's higher.
    // This accounts for classes like Warlock, which have spell casting but don't have spellcasting progression.
    maxSpellLevel = Math.max(c.additionalSpellSlots?.spellSlots.length ?? 0, maxSpellLevel);

    return maxSpellLevel;
}

export function filterClassSpellList(c: ResolvedClassLevels, spells: Spell[], excludeCantrips?: boolean) {
    const maxSpellLevel = getMaxSpellLevel(c);
    return spells.filter(o => o.level <= maxSpellLevel && (!excludeCantrips || o.level !== 0));
}

// export function resolveCharacterSpellList(
//     c: ResolvedCharacter,
//     classLevels: ResolvedClassLevels,
//     rules: CharacterRuleSet
// ) {
//     const baseList = classLevels.classData.spellList ? Object.values(classLevels.classData.spellList) : [];
//     let spells =
//         baseList?.reduce<Spell[]>((p, c) => {
//             const spell = rules.spells.get(c);
//             if (spell) {
//                 p.push(spell);
//             }

//             return p;
//         }, []) ?? [];

//     //findme
// }

// TODO: If this is ever changed to cache the spell list, make sure to find all uses and make sure that they're
// making a copy before modifying the array.
export function resolveClassSpellList(c: ResolvedCharacterClass | CharacterClassBase, rules: CharacterRuleSet) {
    if (c.spellcastingAbility == null) {
        return undefined;
    }

    const baseList = c.spellList ? Object.values(c.spellList) : [];

    // Add anything in the additional spells of the subclass.
    const rc = c as ResolvedCharacterClass;
    if (rc.subclass) {
        for (let feature of Object.values(rc.subclass.features)) {
            if (feature.additionalSpells) {
                for (let additionalSpell of feature.additionalSpells.spells) {
                    if (additionalSpell["count"] != null) {
                        const spellChoice = additionalSpell as AdditionalSpellChoice;

                        // TODO: Do we even want to include any optional spells here?
                        if (spellChoice.from) {
                            baseList?.push(...keyedListToArray(spellChoice.from));
                        }

                        // TODO: If there's a filter specified here, it's generally because the user is asked to pick
                        // a spell from an entire other spell list. At that point, it's probably not very useful to
                        // include that entire spell list here.
                    } else {
                        const spell = additionalSpell as AdditionalSpell;
                        baseList.push(spell);
                    }
                }
            }
        }
    }

    let spells =
        baseList?.reduce<Spell[]>((p, c) => {
            const spell = rules.spells.get(c);
            if (spell) {
                p.push(spell);
            }

            return p;
        }, []) ?? [];
    spells.sort(sortSpells);
    return spells;
}

export function damageTypesToMarkdown(prefix: string | undefined, dt: DamageTypes<boolean>) {
    var types: string[] = damageTypes.filter(o => dt[o]);
    if (dt.special) {
        types.unshift(dt.special);
    }

    return types.length
        ? `${prefix ? `${prefix} to ` : ""}${dt.preContent ? dt.preContent + " " : ""}${types.join(", ")}${
              dt.postContent ? " " + dt.postContent : ""
          }${dt.condition ? ` (${dt.condition})` : ""}`
        : "None";
}

export function attackTypeToString(attackType: AttackType) {
    switch (attackType) {
        case "ms":
            return "Melee spell attack";
        case "ms,rs":
            return "Melee/ranged spell attack";
        case "mw":
            return "Melee weapon attack";
        case "mw,rw":
            return "Melee/ranged weapon attack";
        case "rs":
            return "Ranged spell attack";
        case "rw":
            return "Ranged weapon attack";
        default:
            return "Unknown attack";
    }
}

export function alignmentToString(alignment: Alignment | "Any") {
    switch (alignment) {
        case "CE":
            return "Chaotic Evil";
        case "CG":
            return "Chaotic Good";
        case "CN":
            return "Chaotic Neutral";
        case "LE":
            return "Lawful Evil";
        case "LG":
            return "Lawful Good";
        case "LN":
            return "Lawful Neutral";
        case "N":
            return "True Neutral";
        case "NE":
            return "Neutral Evil";
        case "NG":
            return "Neutral Good";
        default:
            return alignment;
    }
}

export function creatureTypeToString(creatureType: CreatureType) {
    switch (creatureType) {
        case "aberration":
            return "Aberration";
        case "beast":
            return "Beast";
        case "celestial":
            return "Celestial";
        case "construct":
            return "Construct";
        case "dragon":
            return "Dragon";
        case "elemental":
            return "Elemental";
        case "fey":
            return "Fey";
        case "fiend":
            return "Fiend";
        case "giant":
            return "Giant";
        case "humanoid":
            return "Humanoid";
        case "monstrosity":
            return "Monstrosity";
        case "ooze":
            return "Ooze";
        case "plant":
            return "Plant";
        case "undead":
            return "Undead";
        default:
            return creatureType;
    }
}

export function terrainTypeToString(terrainType: TerrainType) {
    switch (terrainType) {
        case "arctic":
            return "Arctic";
        case "coast":
            return "Coast";
        case "desert":
            return "Desert";
        case "forest":
            return "Forest";
        case "grassland":
            return "Grassland";
        case "mountain":
            return "Mountain";
        case "swamp":
            return "Swamp";
        case "underdark":
            return "Underdark";
        default:
            return terrainType;
    }
}

export function movementTypeToString(movementType: MovementType) {
    switch (movementType) {
        case "walk":
            return "Walk";
        case "fly":
            return "Fly";
        case "swim":
            return "Swim";
        case "burrow":
            return "Burrow";
        case "climb":
            return "Climb";
        default:
            return movementType;
    }
}

export function senseTypeToString(senseType: SenseType) {
    switch (senseType) {
        case "blindsight":
            return "Blindsight";
        case "darkvision":
            return "Darkvision";
        case "tremorsense":
            return "Tremorsense";
        case "truesight":
            return "Truesight";
        default:
            return senseType;
    }
}

export function skillToString(skill: Skill) {
    switch (skill) {
        case "acrobatics":
            return "Acrobatics";
        case "animal_handling":
            return "Animal Handling";
        case "arcana":
            return "Arcana";
        case "athletics":
            return "Athletics";
        case "deception":
            return "Deception";
        case "history":
            return "History";
        case "insight":
            return "Insight";
        case "intimidation":
            return "Intimidation";
        case "investigation":
            return "Investigation";
        case "medicine":
            return "Medicine";
        case "nature":
            return "Nature";
        case "perception":
            return "Perception";
        case "performance":
            return "Performance";
        case "persuasion":
            return "Persuasion";
        case "religion":
            return "Religion";
        case "sleight_of_hand":
            return "Sleight of Hand";
        case "stealth":
            return "Stealth";
        case "survival":
            return "Survival";
        default:
            return skill;
    }
}

export function getCoreAbilityAbbr(ability: CoreAbility) {
    switch (ability) {
        case "strength":
            return "STR";
        case "dexterity":
            return "DEX";
        case "constitution":
            return "CON";
        case "intelligence":
            return "INT";
        case "wisdom":
            return "WIS";
        case "charisma":
            return "CHA";
    }
}

export function getCoreAbilityLabel(ability: CoreAbility) {
    switch (ability) {
        case "strength":
            return "Strength";
        case "dexterity":
            return "Dexterity";
        case "constitution":
            return "Constitution";
        case "intelligence":
            return "Intelligence";
        case "wisdom":
            return "Wisdom";
        case "charisma":
            return "Charisma";
    }
}

export function conditionToString(condition: CreatureConditionName) {
    switch (condition) {
        case "blinded":
            return "Blinded";
        case "charmed":
            return "Charmed";
        case "deafened":
            return "Deafened";
        case "exhaustion":
            return "Exhaustion";
        case "frightened":
            return "Frightened";
        case "grappled":
            return "Grappled";
        case "incapacitated":
            return "Incapacitated";
        case "invisible":
            return "Invisible";
        case "paralyzed":
            return "Paralyzed";
        case "petrified":
            return "Petrified";
        case "poisoned":
            return "Poisoned";
        case "prone":
            return "Prone";
        case "restrained":
            return "Restrained";
        case "stunned":
            return "Stunned";
        case "unconscious":
            return "Unconscious";
    }
}

export function armorProficienciesToString(armorProfs: Partial<ArmorProficiencies<boolean>>) {
    const list: string[] = [];
    if (armorProfs.light) {
        list.push("light armor");
    }

    if (armorProfs.medium) {
        list.push("medium armor");
    }

    if (armorProfs.heavy) {
        list.push("heavy armor");
    }

    if (armorProfs.shields) {
        list.push("shields");
    }

    return list.join(", ");
}

export function weaponProficienciesToString(weaponProfs: Partial<WeaponProficiencies<boolean>>) {
    const list: string[] = [];
    if (weaponProfs.simple) {
        list.push("simple weapons");
    }

    if (weaponProfs.martial) {
        list.push("martial weapons");
    }

    return list.join(", ");
}

export function coreAbilitiesToString(coreAbilities: Partial<CoreAbilities<boolean>>) {
    const list: string[] = [];
    if (coreAbilities.strength) {
        list.push("Strength");
    }

    if (coreAbilities.dexterity) {
        list.push("Dexterity");
    }

    if (coreAbilities.constitution) {
        list.push("Constitution");
    }

    if (coreAbilities.intelligence) {
        list.push("Intelligence");
    }

    if (coreAbilities.wisdom) {
        list.push("Wisdom");
    }

    if (coreAbilities.charisma) {
        list.push("Charisma");
    }

    return list.join(", ");
}

export function skillProficienciesToString(
    skills: Partial<Skills<number>> | undefined,
    choice: Choice<Skill> | undefined
) {
    let str = "";
    if (skills) {
        const keys = Object.keys(skills);
        str = keys
            .filter(o => skills[o] != null && skills[o] > 0)
            .map(o => skillToString(o as Skill))
            .join(", ");
    }

    if (choice) {
        if (str) {
            str += ", ";
        }

        str += `choose ${choice.count} from ${choice.from?.map(o => skillToString(o as Skill)).join(", ")}`;
    }

    if (str.length > 0) {
        str = str[0].toUpperCase() + str.substr(1);
    }

    return str;
}

export function itemToString(item: string | NamedRuleRef | ItemFilter, rules: CharacterRuleSet) {
    if (typeof item === "string") {
        return item;
    }

    if (isNamedRuleRef(item)) {
        const group = rules.items.groups.get(item);
        if (group) {
            return group.name;
        }

        const o = rules.items.items.get(item);
        if (o) {
            return o.name;
        }

        return "unknown";
    }

    // TODO: Item filter to string?
    return "items";
}

export function itemProficienciesToString(
    items: { [name: string]: number } | undefined,
    choice:
        | (Choice<NamedRuleRef, KeyedList<NamedRuleRef | ItemFilter>> & {
              value?: number | undefined;
              current?: number | undefined;
          })
        | undefined,
    rules: CharacterRuleSet
) {
    let str = "";
    if (items) {
        const keys = Object.keys(items);
        str = keys
            .filter(o => items[o])
            .map(o => {
                const ref = fromRuleKey(o);
                if (ref) {
                    const itm = rules.items.items.get(ref);
                    if (itm) {
                        return itm.name;
                    }
                }

                const itm = rules.items.items.getByName(o);
                if (itm) {
                    return itm.name;
                }

                return o;
            })
            .join(", ");
    }

    if (choice) {
        const from = keyedListToArray(choice.from);
        str += `choose ${choice.count} from ${from?.map(o => itemToString(o, rules)).join(", ")}`;
    }

    if (str.length > 0) {
        str = str[0].toUpperCase() + str.substr(1);
    }

    return str;
}

export interface NamedSpellSlots {
    name: string;
    spellSlots: number[];
    reset?: RestType;
}

export interface ResolvedClassLevels {
    resolvedFrom: ClassLevels;
    classData: ResolvedCharacterClass;
    level: number;
    isInitial: boolean;
    hpPerLevel: { [level: string]: number };
    skillProficiencies?: (Skill | null)[];
    tools?: (string | null)[];
    spellSaveDC?: ModifiedValue;
    spellAttackModifier?: ModifiedValue;

    /**
     * The number of cantrips currently known by the character.
     */
    currentCantripsKnown?: number;

    /**
     * The number of (non-cantrip) spells currently known by the character.
     */
    currentSpellsKnown?: number;

    /**
     * The maximum number of cantrips that this character should know.
     */
    maxCantripsKnown?: number;

    /**
     * The number of (non-cantrip) spells currently prepared by the character.
     */
    currentSpellsPrepared?: number;

    /**
     * The maximum number of spells that can be prepared by the character.
     */
    maxSpellsPrepared?: number;

    choices: {
        [level: number]: {
            [feature: string]: FeatureChoice;
        };
    };

    /**
     * All the spells known by the character, including cantrips.
     */
    knownSpells?: Spell[];

    /**
     * All the spells currently prepared by the character.
     */
    preparedSpells?: Spell[];

    additionalSpellSlots?: NamedSpellSlots;

    additionalSpells?: ResolvedAdditionalSpells[];

    /**
     * The number of hit dice that have been spent during a short rest.
     * On a long rest, this number is reduced by half the character's total level.
     */
    hitDiceSpent: number;
}

/**
 * TODO: The weapon part here could end up being very similar to ItemFilter, but ItemFilter is generally
 * interpreted as OR (i.e. if the props that accept multiple values have a single item that matches, then
 * the filter matches) whereas we want AND here.
 * TODO: Should this extend AbilityEffect or something, to pick up the damage from there?
 */
export interface AttackOptionBase {
    /**
     * The type of attack this option can apply to.
     */
    type: SingleAttackType;

    /**
     * The option should only be available for a weapon with the specified attributes.
     */
    weapon?: {
        /**
         * The character must be proficient with the weapon to choose this option.
         */
        proficient?: boolean;

        /**
         * The weapon must have all of these properties to choose this option.
         */
        properties?: string[];
    };

    /**
     * The modifier for damage made with this option active.
     * Applies to the first damage type used in the effect.
     * The damage value can either be a single value (i.e. "4"), a dice value ("1d6"), or a reference to a
     * lookup (i.e. "Sneak Attack"). If a lookup is used, then it must be a dice or value lookup.
     */
    damage?: Partial<{ [damageType in DamageType | "weapon"]: ExpressionableValue<string> }>;

    /**
     * Effects that the attack applies to the source (i.e. caster if it's a spell, attacker if it's a melee/ranged attack).
     */
    source?: ApplicableAbilityEffect | AppliedAbilityEffectRef;

    /**
     * Effects that apply to the target if the attack is successful.
     */
    target?: ApplicableAbilityEffect | AppliedAbilityEffectRef;

    /**
     * The resource cost to activate this attack option, if any.
     */
    resourceCost?: {
        [resource: string]: number;
    };

    /**
     * The spell slot cost for this ability.
     * If the level is not specified, then a spell slot of any level can be chosen.
     * TODO: Make the chosen spell slot available when looking up the damage. The damage must be able to understand
     * expressions and the chosen spell slot must be available there too, so you can do something like:
     * damage: { "radiant": "max(5, (spellSlotCost.level + 1)) + \"d8\"" }
     * for divine smite as an example.
     */
    spellSlotCost?: {
        level?: number;
        amount?: number;
    };
}

/**
 * An option that can be selected before an attack.
 */
export interface BeforeAttackOption extends AttackOptionBase {
    /**
     * The modifer for attack made with this option active.
     */
    attack?: number;

    /**
     * If true, the option applies advantage to the attack.
     */
    advantage?: boolean;

    /**
     * If true, the option applies disadvantage to the attack.
     */
    disadvantage?: boolean;
}

/**
 * An option that can be selected after an attack hits but before damage is applied.
 */
export interface AfterAttackOption extends AttackOptionBase {}

interface ResolvedAttackOption {
    /**
     * The feature that provided this option.
     */
    feature: Feature;

    /**
     * The key within the feature's beforeAttack that identifies this option.
     */
    key: string;
}

export type ResolvedBeforeAttackOption = BeforeAttackOption & ResolvedAttackOption;

export type ResolvedAfterAttackOption = AfterAttackOption & ResolvedAttackOption;

/**
 * Complete information about a single character.
 * All values have relevant modifiers applied based on race/class choices and items.
 */
type ResolvedCharacterBaseInterface = ResolvedCreatureCommon & CharacterBase<ModifiedValue & { modifier: number }>;
export interface ResolvedCharacter extends ResolvedCharacterBaseInterface {
    resolvedFrom: Character;

    level: number;
    maxHp: ModifiedValue;

    proficiencyBonus: number;

    ac: ModifiedValue;

    race?: ResolvedRace;
    passive: PassiveAbilities<ModifiedValue>;

    armorProficiencies: Partial<ArmorProficiencies<boolean>>;
    weaponProficiencies: Partial<WeaponProficiencies<boolean>>;

    /**
     * The tools that the character is proficient in. The key here is the rule ref for the
     * relevant item.
     */
    toolProficiencies: { [tool: string]: number | undefined };

    languages: string[];

    /**
     * The class information for this character, keyed by the class name.
     */
    classes: { [id: string]: ResolvedClassLevels };

    /**
     * The racial traits that should appear on a character sheet (i.e. not ASI, skill profs, etc).
     * Usually these are traits that cannot be applied directly or universally to stats/proficiencies.
     * e.g. Elven/half-elven Fey Ancestry trait.
     */
    racialTraits: { feature: Feature; choices?: FeatureChoice }[];

    /**
     * The class features that should appear on a character sheet (i.e. not ASI, skill profs, etc).
     * Usually these are traits that cannot be applied directly or universally to stats/proficiencies.
     * e.g. Elven/half-elven Fey Ancestry trait.
     */
    classFeatures: { feature: ClassFeature; choices: FeatureChoice }[];

    /**
     * All features (including optional features) that have been applied to this character.
     */
    allFeatures: Feature[];

    /**
     * Any additional spells that come from race/class/feats/items will be collected here.
     */
    additionalSpells: ResolvedAdditionalSpells[];

    savingThrows: CoreAbilities<ModifiedValue> & {
        death: ModifiedValue;
        advantage?: AdvantageOrDisadvantage[];
        disadvantage?: AdvantageOrDisadvantage[];
    };

    /**
     * The number of attacks that the character can make when taking the attack action.
     */
    attacks: number;

    /**
     * The minimum value required for a critical hit using specific attack types.
     */
    criticalRange?: Partial<SingleAttackTypes<number>>;

    spellcasterLevel: number;
    spellSlots: (number[] | NamedSpellSlots)[];

    background?: ResolvedBackground;

    inventory: ResolvedInventory;
    attunedItems: ResolvedInventoryItem[];

    /**
     * The armor that the character is wearing, if any.
     */
    armor?: Shield & ResolvedItem;

    /**
     * The shield that the character has equipped, if any.
     */
    shield?: ResolvedInventoryItem;

    concentrating?: Spell & Concentration;

    currency: {
        total: number; // Total value in GP.
        [type: string]: number; // Key is abbr of currency (i.e. GP, SP, PP).
    };

    /**
     * If true ranged weapon attacks ignore the long range disadvantage penalty.
     */
    ignoreLongRangePenalty?: boolean;

    /**
     * Attacks of these types can ignore half and three quarters cover.
     */
    ignoreCover?: SingleAttackType[];

    /**
     * Options that can be selected before an attack to modify it.
     */
    beforeAttack?: ResolvedBeforeAttackOption[];

    /**
     * Options that can be selected after an attack has hit to modify it before damage.
     */
    afterAttack?: ResolvedAfterAttackOption[];

    lookups?: { [name: string]: TableLookup & { class: ResolvedClassLevels; value: string | number } };

    errors?: string[];

    resources?: {
        [name: string]: ResolvedCharacterResource;
    };

    /**
     * Extra abilities that have been granted by features.
     */
    abilities?: KeyedList<Ability & { feature?: Feature }>;

    /**
     * Extra abilities that have been granted by features, but replaced by other features or otherwise removed.
     * Made available here to enable them to be easily referenced for finding icons etc.
     */
    inactiveAbilities?: KeyedList<Ability & { feature?: Feature }>;

    effects?: KeyedList<
        AppliedAbilityEffect & {
            source?: string;
            feature?: Feature;
            ability?: Ability;
            spell?: Spell;
            condition?: CreatureCondition;
        }
    >;

    /**
     * Bonuses that apply conditionally, depending on the items the character has equipped.
     */
    itemModifiers: ItemModifiers<ValueModifier | (() => ValueModifier)>[];

    /**
     * Gets the items that are currently equipped by the character.
     */
    equippedItems: ResolvedInventoryItem[];
}

export function isProficient(character: ResolvedCharacter, item: ResolvedWeapon & ResolvedItem) {
    return (
        character.weaponProficiencies[item.weaponType] ||
        character.weaponProficiencies[getRuleKey(item.baseItem ?? item)]
    );
}

function addLazyModifier(value: ModifiedValue, modifier: ValueModifier | (() => ValueModifier)) {
    if (typeof modifier === "function") {
        addNonZeroModifier(value, modifier());
    } else {
        addNonZeroModifier(value, modifier);
    }
}

export function applyItemModifiers(item: ResolvedItem, character: ResolvedCharacter, rules: CharacterRuleSet) {
    if (character.itemModifiers.length === 0) {
        return;
    }

    for (let modifier of character.itemModifiers) {
        if (filterItem(item, modifier.filter, rules.items)) {
            if (modifier.attack) {
                if (isInventoryWeapon(item)) {
                    addLazyModifier(item.hitBonus, modifier.attack);
                } else {
                    console.log(
                        `Character ${character.name} has a conditional bonus granting a to-hit bonus to non-weapon item ${item.name}.`
                    );
                }
            }

            if (modifier.dmg1h) {
                if (isInventoryWeapon(item)) {
                    const dmg1h = character.lookups?.[modifier.dmg1h]?.value?.toString() ?? modifier.dmg1h;
                    if (dmg1h && (item.dmg1h == null || getAverageResult(dmg1h) > getAverageResult(item.dmg1h))) {
                        item.dmg1h = dmg1h;
                    }
                }
            }

            if (modifier.dmg2h) {
                if (isInventoryWeapon(item)) {
                    const dmg2h = character.lookups?.[modifier.dmg2h]?.value?.toString() ?? modifier.dmg2h;
                    if (dmg2h && (item.dmg2h == null || getAverageResult(dmg2h) > getAverageResult(item.dmg2h))) {
                        item.dmg2h = dmg2h;
                    }
                }
            }

            if (modifier.addProperties) {
                if (isInventoryWeapon(item)) {
                    for (let abbr of modifier.addProperties) {
                        const property = rules.items.properties.get(abbr);
                        if (property) {
                            if (item.properties == null) {
                                item.properties = [property];
                            } else if (item.properties.indexOf(property) < 0) {
                                item.properties.push(property);
                            }
                        }
                    }
                }
            }
        }
    }
}

export function applyWeaponModifiers(
    item: ResolvedWeapon & ResolvedItem,
    modifiers: { hitBonus: ModifiedValue; damageBonus: ModifiedValue; ability?: "strength" | "dexterity" },
    rules: CharacterRuleSet,
    character?: ResolvedCharacter,
    abilityMods?: CoreAbilities<ValueModifier>,
    proficiencyMod?: ValueModifier,
    omitDamageAbilityModifier?: boolean
) {
    if (item.attackModifier) {
        addNonZeroModifier(modifiers.hitBonus, { name: item.name, value: item.attackModifier });
    }

    if (item.dmgModifier) {
        addNonZeroModifier(modifiers.damageBonus, { name: item.name, value: item.dmgModifier });
    }

    if (!character) {
        return;
    }

    if (isProficient(character, item)) {
        addNonZeroModifier(
            modifiers.hitBonus,
            proficiencyMod ?? { name: "Proficiency", value: character.proficiencyBonus }
        );
    }

    const strMod = abilityMods
        ? abilityMods.strength
        : {
              name: getCoreAbilityLabel("strength"),
              value: modifierFromAbilityScore(resolveModifiedValue(character.strength)),
          };
    const dexMod = abilityMods
        ? abilityMods.dexterity
        : {
              name: getCoreAbilityLabel("dexterity"),
              value: modifierFromAbilityScore(resolveModifiedValue(character.dexterity)),
          };

    let abilityMod: ValueModifier;
    if (item.type === ItemType.Melee) {
        // Use strength modifier unless the weapon is finesse, in which case use the best of str and dex.
        if (item.properties && item.properties.some(o => o.abbreviation === "F") && dexMod.value > strMod.value) {
            abilityMod = dexMod;
            modifiers.ability = "dexterity";
        } else {
            abilityMod = strMod;
            modifiers.ability = "strength";
        }
    } else if (item.type === ItemType.Ranged) {
        abilityMod = dexMod;
        modifiers.ability = "dexterity";
    } else {
        abilityMod = strMod;
        modifiers.ability = "strength";
    }

    addNonZeroModifier(modifiers.hitBonus, abilityMod);
    if (!omitDamageAbilityModifier || abilityMod.value < 0) {
        addNonZeroModifier(modifiers.damageBonus, abilityMod);
    }

    if (character) {
        applyItemModifiers(item, character, rules);
    }
}

function addCharacterModifierToWeaponDamage(dmg: string | undefined, bonus: ModifiedValue) {
    if (dmg == null) {
        return dmg;
    }

    const modifier = resolveModifiedValue(bonus);
    return `${dmg}${modifier > 0 ? "+" : ""}${modifier === 0 ? "" : modifier}`;
}

/**
 * Gets the weapon damage for a weapon if it were wielded by the specified character.
 * @param weapon The weapon to get the damage for.
 * @param rules The rules set.
 * @param character Calculate the damage as if it were wielded by the specified character.
 * @param handed The number of hands being used to wield the weapon.
 * @returns The damage as a dice roll string.
 */
export function getWeaponDamage(
    weapon: ResolvedWeapon & ResolvedItem,
    rules: CharacterRuleSet,
    character?: ResolvedCharacter,
    handed?: 1 | 2
) {
    const mods = { hitBonus: modifiedValue(0), damageBonus: modifiedValue(0) };

    applyWeaponModifiers(weapon, mods, rules, character);

    let dmg: string | undefined;
    if (weapon.properties) {
        if (weapon.properties.some(o => o.abbreviation === "2H")) {
            // The weapon is two handed, so our base damage stat is the 2h one.
            dmg = addCharacterModifierToWeaponDamage(weapon.dmg2h, mods.damageBonus);
        } else if (weapon.properties.some(o => o.abbreviation === "V")) {
            // A versatile weapon can be used 1h or 2h.
            if (handed == null) {
                dmg = addCharacterModifierToWeaponDamage(weapon.dmg1h, mods.damageBonus);
                dmg = `${dmg} (${addCharacterModifierToWeaponDamage(weapon.dmg2h, mods.damageBonus)})`;
            } else if (handed === 2) {
                dmg = addCharacterModifierToWeaponDamage(weapon.dmg2h, mods.damageBonus);
            } else {
                dmg = addCharacterModifierToWeaponDamage(weapon.dmg1h, mods.damageBonus);
            }
        } else {
            dmg = addCharacterModifierToWeaponDamage(weapon.dmg1h, mods.damageBonus);
        }
    }

    if (!dmg) {
        dmg = addCharacterModifierToWeaponDamage(weapon.dmg1h, mods.damageBonus);
    }

    return dmg ?? "--";
}

export function resolveCreature(
    creature: Creature,
    campaign: Campaign,
    rules: CharacterRuleSet,
    transformOverrides?: TransformedMonsterOverrides
) {
    if (isCharacter(creature)) {
        return resolveCharacter(creature, campaign, rules);
    }

    return resolveMonster(creature, campaign, rules, transformOverrides);
}

/**
 * Fully resolves the creature for the specified token or token template.
 * TODO: To cache the results here, we have to make sure that the token being passed in is actually a descendant of the campaign.
 * It's possible for the campaign to change but React to update components with a token from a previous iteration of the campaign
 * because of the async scheduling. Need to rethink how we cache here.
 */
export function fullResolveTokenCreature(
    token: DnD5EToken | DnD5ETokenTemplate | undefined,
    campaign: Campaign,
    rules: CharacterRuleSet
) {
    if (!token) {
        return undefined;
    }

    const creature = resolveTokenCreature(token, campaign, rules);
    const resolvedCreature = creature ? resolveCreature(creature, campaign, rules) : undefined;
    return resolvedCreature;
}

export function resolveStoredMonster(
    storedMonster: StoredMonster,
    templateId: string | undefined,
    campaign: Campaign,
    rules: CharacterRuleSet
): Monster | undefined {
    var baseMonster: Monster | undefined;

    // If a template ID was specified, then the monster must be from this campaign's custom tokens.
    // In this case, template.dnd5e.source should also be the campaign's ID (i.e. the source book).
    if (templateId) {
        const template = campaign.tokens[templateId];
        if (template && isDnD5EMonsterTemplate(template)) {
            baseMonster = template.dnd5e;
        }
    }

    if (!baseMonster) {
        baseMonster = rules.monsters.get(storedMonster);
    }

    if (!baseMonster) {
        return undefined;
    }

    return copyState(baseMonster, storedMonster);
}

export function resolveTokenCreature(
    token: DnD5EToken | DnD5ETokenTemplate,
    campaign: Campaign,
    rules: CharacterRuleSet
): Creature | undefined {
    if (isDnD5EMonsterToken(token)) {
        return resolveStoredMonster(token.dnd5e, token.templateId, campaign, rules);
    } else if (isDnD5EMonsterTemplate(token)) {
        return token.dnd5e;
    }

    const characterId = token.templateId;
    if (characterId && !isTokenTemplate(token)) {
        // This is a partial character, or just a reference to an existing character within the campaign.
        const baseChar = campaign.tokens[characterId];
        if (baseChar) {
            if (isDnD5ETokenTemplate(baseChar)) {
                const char = Object.assign({}, baseChar.dnd5e, token.dnd5e);
                return char;
            } else {
                console.warn(`Base character token template with ID ${characterId} does not contain a character.`);
                return undefined;
            }
        } else {
            console.warn(
                `Could not find base character token template with ID ${characterId}. Token name is ${token.dnd5e.name} and it is at position ${token.pos.x},${token.pos.y}`
            );
            return undefined;
        }
    }

    return token.dnd5e as Creature;
}

export function resolveModifiedValue(value: ModifiedValue, skipMultipliers?: boolean) {
    let currentValue = value.baseValue;

    if (!value.type || value.type === "sum") {
        for (let i = 0; i < value.modifiers.length; i++) {
            currentValue += value.modifiers[i].value;
        }
    } else if (value.type === "max") {
        for (let i = 0; i < value.modifiers.length; i++) {
            currentValue = Math.max(currentValue, value.baseValue + value.modifiers[i].value);
        }
    } else {
        console.error("Unknown modified value type: " + value.type);
    }

    if (value.multipliers && !skipMultipliers) {
        for (let i = 0; i < value.multipliers.length; i++) {
            currentValue = currentValue * value.multipliers[i].value;
        }
    }

    return Math.floor(currentValue);
}

function proficiencyBonusForLevel(level: number) {
    if (level <= 4) {
        return 2;
    } else if (level <= 8) {
        return 3;
    } else if (level <= 12) {
        return 4;
    } else if (level <= 16) {
        return 5;
    }

    return 6;
}

export function modifierFromAbilityScore(abilityScore: number | undefined) {
    return Math.trunc(((abilityScore ?? 10) - 10) / 2);
}

export function addNonZeroModifier(modifiedValue: ModifiedValue, modifier: ValueModifier) {
    if (modifier.value !== 0) {
        modifiedValue.modifiers.push(modifier);
    }
}

const abilityDoNotMerge: (keyof Ability)[] = ["name", "content", "mergesWith", "replaces"];

function convertValueOrExpressionToModifier(
    feature: Feature,
    item: ExpressionableValue<number>,
    character: ResolvedCharacter
): ValueModifier | (() => ValueModifier) | undefined {
    if (typeof item !== "object") {
        return { name: feature.name, value: item };
    } else if (item.value != null) {
        return { name: feature.name, value: item.value };
    } else if (item.expression != null) {
        return () => {
            return { name: feature.name, value: evaluateCreatureExpression(character, item.expression) };
        };
    }

    return undefined;
}

function resolveFeature(
    character: ResolvedCharacter,
    originalCharacter: Character,
    classLevels: ResolvedClassLevels | undefined,
    conditionalFeatures: (() => void)[],
    resolvedFeatures: { feature: Feature; choices?: FeatureChoice }[] | undefined,
    modifierName: string,
    feature: Feature,
    choices: FeatureChoice | undefined,
    rules: CharacterRuleSet
) {
    if (feature.condition?.expression) {
        conditionalFeatures.push(() => {
            if (evaluateCharacterExpression(character, feature.condition?.expression)) {
                resolveFeatureInternal(
                    character,
                    originalCharacter,
                    classLevels,
                    conditionalFeatures,
                    resolvedFeatures,
                    modifierName,
                    feature,
                    choices,
                    rules
                );
            }
        });
    } else {
        resolveFeatureInternal(
            character,
            originalCharacter,
            classLevels,
            conditionalFeatures,
            resolvedFeatures,
            modifierName,
            feature,
            choices,
            rules
        );
    }
}

function resolveFeatureInternal(
    character: ResolvedCharacter,
    originalCharacter: Character,
    classLevels: ResolvedClassLevels | undefined,
    conditionalFeatures: (() => void)[],
    resolvedFeatures: { feature: Feature; choices?: FeatureChoice }[] | undefined,
    modifierName: string,
    feature: Feature,
    choices: FeatureChoice | undefined,
    rules: CharacterRuleSet
) {
    let showInCharacter = true;

    character.allFeatures.push(feature);

    // Ability scores
    const asi = feature.abilities;
    if (asi) {
        if (asi.strength) {
            character.strength.modifiers.push({ name: modifierName, value: asi.strength });
        }

        if (asi.dexterity) {
            character.dexterity.modifiers.push({ name: modifierName, value: asi.dexterity });
        }

        if (asi.constitution) {
            character.constitution.modifiers.push({ name: modifierName, value: asi.constitution });
        }

        if (asi.intelligence) {
            character.intelligence.modifiers.push({ name: modifierName, value: asi.intelligence });
        }

        if (asi.wisdom) {
            character.wisdom.modifiers.push({ name: modifierName, value: asi.wisdom });
        }

        if (asi.charisma) {
            character.charisma.modifiers.push({ name: modifierName, value: asi.charisma });
        }

        showInCharacter = false;
    }

    if (feature.abilityChoice) {
        const choice = choices?.abilities;
        if (choice) {
            for (let i = 0; i < feature.abilityChoice.count && i < choice.length; i++) {
                const ability = choice[i];
                if (ability) {
                    character[ability].modifiers.push({ name: modifierName, value: 1 });
                }
            }

            showInCharacter = false;
        }
    }

    // Damage immunities
    if (feature.damageImmunities) {
        const keys = keyedListToKeyArray(character.damageImmunities);
        if (keys) {
            // Find the first key with no condition. We'll merge into that.
            // TODO: This should really be resolved better at some point.
            const noConditionKey = keys.find(o => character.damageImmunities![o].condition == null);
            if (noConditionKey) {
                character.damageImmunities![noConditionKey] = {
                    ...character.damageImmunities![noConditionKey],
                    ...feature.damageImmunities,
                };
            } else {
                character.damageImmunities = addToKeyedList(character.damageImmunities, feature.damageImmunities);
            }
        } else {
            character.damageImmunities = addToKeyedList(character.damageImmunities, feature.damageImmunities);
        }
    }

    if (feature.damageImmunitiesChoice) {
        const choice = choices?.damageImmunities;
        if (choice) {
            const damageImmunities: Partial<DamageTypes<boolean>> = {};
            for (let i = 0; i < choice.length; i++) {
                const damageType = choice[i] as DamageType;
                damageImmunities[damageType] = true;
            }

            const keys = keyedListToKeyArray(character.damageImmunities);
            if (keys) {
                // Find the first key with no condition. We'll merge into that.
                // TODO: This should really be resolved better at some point.
                const noConditionKey = keys.find(o => character.damageImmunities![o].condition == null);
                if (noConditionKey) {
                    character.damageImmunities![noConditionKey] = {
                        ...character.damageImmunities![noConditionKey],
                        ...feature.damageImmunities,
                    };
                } else {
                    character.damageImmunities = addToKeyedList(character.damageImmunities, damageImmunities);
                }
            } else {
                character.damageImmunities = addToKeyedList(character.damageImmunities, damageImmunities);
            }
        }
    }

    // Languages
    if (feature.languages) {
        for (let i = 0; i < feature.languages.length; i++) {
            if (character.languages.indexOf(feature.languages[i]) < 0) {
                character.languages.push(feature.languages[i]);
            }
        }
    }

    if (feature.languageChoice) {
        const choice = choices?.languages;
        if (choice) {
            for (let i = 0; i < choice.length; i++) {
                const language = choice[i];
                if (language && character.languages.indexOf(language) < 0) {
                    character.languages.push(language);
                }
            }
        }
    }

    // Senses
    const senses = feature.senses;
    if (senses) {
        if (senses.darkvision) {
            character.senses.darkvision.modifiers.push({ name: modifierName, value: senses.darkvision });
        }

        if (senses.blindsight) {
            character.senses.blindsight.modifiers.push({ name: modifierName, value: senses.blindsight });
        }

        if (senses.tremorsense) {
            character.senses.tremorsense.modifiers.push({ name: modifierName, value: senses.tremorsense });
        }

        if (senses.truesight) {
            character.senses.truesight.modifiers.push({ name: modifierName, value: senses.truesight });
        }

        showInCharacter = true;
    }

    // Skills
    const skills = feature.skillProficiencies;
    if (skills) {
        for (let skillName in skills) {
            const skill = skillName as Skill;
            const maxSkill = Math.max(character.skillProficiencies[skill] ?? 0, skills[skill] ?? 0);
            character.skillProficiencies[skill] = maxSkill === 0 ? undefined : maxSkill;
        }

        showInCharacter = true;
    }

    if (feature.skillProficiencyChoice) {
        const choice = choices?.skillProficiencies;
        if (choice) {
            for (let i = 0; i < feature.skillProficiencyChoice.count && i < choice.length; i++) {
                const skill = choice[i] as Skill;
                if (skill) {
                    const maxSkill = Math.max(
                        character.skillProficiencies[skill] ?? 0,
                        feature.skillProficiencyChoice.value ?? 1
                    );
                    character.skillProficiencies[skill] = maxSkill;
                }
            }
        }

        showInCharacter = true;
    }

    // Weapons
    const weapons = feature.weaponProficiencies;
    if (weapons) {
        Object.assign(character.weaponProficiencies, weapons);
        showInCharacter = true;
    }

    // Nested features
    const features = feature.featureChoice;
    if (features) {
        const choice = choices?.features;
        if (choice) {
            for (let i = 0; i < features.count && i < choice.length; i++) {
                const featureChoice = choice[i];
                if (featureChoice) {
                    // Get the feature with that name - it might be part of the choice, or from the
                    // optional features list.
                    let f = features.from
                        ? (Object.values(features.from).find(o => o.name === featureChoice.name) as Feature | undefined)
                        : undefined;
                    if (featureChoice.source) {
                        f = rules.optionalFeatures.get(featureChoice as NamedRuleRef) ?? f;
                    }

                    if (f) {
                        resolveFeature(
                            character,
                            originalCharacter,
                            classLevels,
                            conditionalFeatures,
                            resolvedFeatures,
                            f.name,
                            f,
                            featureChoice,
                            rules
                        );
                    }
                }
            }
        }
    }

    // Feats
    const feats = feature.featChoice;
    if (feats) {
        const choice = choices?.feats;
        if (choice) {
            for (let i = 0; i < feats.count && i < choice.length; i++) {
                const featChoice = choice[i];
                if (featChoice) {
                    const feat = rules.feats.get(featChoice);
                    if (feat) {
                        resolveFeature(
                            character,
                            originalCharacter,
                            classLevels,
                            conditionalFeatures,
                            resolvedFeatures,
                            feat.name,
                            feat,
                            featChoice,
                            rules
                        );
                    }
                }
            }
        }
    }

    // Additional spells
    if (feature.additionalSpells) {
        const innateSpellState =
            (originalCharacter.additionalSpells
                ? originalCharacter.additionalSpells[getRuleKey(feature)]
                : undefined) ?? {};
        const additionalSpells: ResolvedAdditionalSpells = {
            name: feature.name,
            source: feature,
            ability: feature.additionalSpells.ability,
            spells: feature.additionalSpells.spells
                .flatMap((o, i) => {
                    if (o.gainAtLevel != null && o.gainAtLevel >= (classLevels?.level ?? character.level)) {
                        return undefined;
                    }

                    // If there's no gainAtLevel and no classLevels, then we can't know when the spells should be gained,
                    // so we just ignore them. This shouldn't happen.
                    if (o.gainAtLevel == null && classLevels == null) {
                        return undefined;
                    }

                    // If the class levels are provided, then this is a class feature so the level is relative to those.
                    // Otherwise, it's relative to the overall character level.
                    let spells: Spell[] | undefined = undefined;
                    if (o["name"] && o["source"]) {
                        // This is a reference to a particular spell.
                        const spell = rules.spells.get(o as AdditionalSpell);
                        spells = spell ? [spell] : undefined;
                    } else {
                        const choice = o as Choice<NamedRuleRef>;
                        const chosenSpells: Spell[] = [];

                        // This is a spell choice.
                        const spellChoices = choices?.additionalSpells;
                        const spellChoice = spellChoices ? spellChoices[i] : undefined;
                        if (spellChoice != null) {
                            for (let j = 0; j < choice.count; j++) {
                                const c = spellChoice[j];
                                if (c != null) {
                                    const spell = rules.spells.get(c);
                                    if (spell) {
                                        chosenSpells.push(spell);
                                    }
                                }
                            }
                        }

                        if (chosenSpells.length > 0) {
                            spells = chosenSpells;
                        }
                    }

                    if (
                        spells &&
                        (classLevels == null ||
                            o.gainAtLevel != null ||
                            spells.every(o => o.level <= getMaxSpellLevel(classLevels)))
                    ) {
                        return spells.map(spell =>
                            Object.assign({}, spell, {
                                maxUses: o.maxUses,
                                maxUsesExpr: o.maxUsesExpr,
                                used: innateSpellState[getRuleKey(spell)]?.used ?? 0,
                                reset: o.reset,
                                prepared: o.prepared,
                                known: o.known,
                            })
                        );
                    }

                    return undefined;
                })
                .filter(o => o != null) as ResolvedAdditionalSpell[],
        };

        if (classLevels) {
            if (!classLevels.additionalSpells) {
                classLevels.additionalSpells = [];
            }

            classLevels.additionalSpells.push(additionalSpells);

            // If the additional spell is always prepared, then add it to the prepared spells list
            // for the class.
            if (
                classLevels.classData.spellsKnownProgression == null ||
                classLevels.classData.spellsKnownProgression === "all"
            ) {
                for (let spell of additionalSpells.spells) {
                    if (spell.prepared) {
                        if (!classLevels.preparedSpells) {
                            classLevels.preparedSpells = [];
                        }

                        classLevels.preparedSpells.push(spell);
                    }
                }
            }
        } else {
            character.additionalSpells.push(additionalSpells);
        }
    }

    const tools = feature.tools;
    if (tools) {
        for (let toolKey in tools) {
            const maxTool = Math.max(character.toolProficiencies[toolKey] ?? 0, tools[toolKey] ?? 0);
            character.toolProficiencies[toolKey] = maxTool === 0 ? undefined : maxTool;
        }

        showInCharacter = true;
    }

    if (feature.toolChoice && choices?.tools) {
        for (let j = 0; j < feature.toolChoice.count && j < choices.tools.length; j++) {
            const tool = choices.tools[j];
            if (tool) {
                const maxTool = Math.max(character.toolProficiencies[tool] ?? 0, feature.toolChoice.value ?? 1);
                character.toolProficiencies[tool] = maxTool;
            }
        }

        showInCharacter = true;
    }

    if (feature.attacks != null) {
        character.attacks = Math.max(character.attacks, feature.attacks);
    }

    if (feature.criticalRange != null) {
        if (!character.criticalRange) {
            character.criticalRange = {};
        }

        for (let key in feature.criticalRange) {
            const attackType = key as SingleAttackType;
            character.criticalRange[attackType] = Math.min(
                character.criticalRange[attackType] ?? 20,
                feature.criticalRange[attackType] ?? 20
            );
        }

        showInCharacter = true;
    }

    // Merge in advantage/disadvantage on saving throws.
    if (feature.savingThrows) {
        if (feature.savingThrows.advantage) {
            if (character.savingThrows.advantage) {
                character.savingThrows.advantage.push(feature.savingThrows.advantage);
            } else {
                character.savingThrows.advantage = [feature.savingThrows.advantage];
            }
        }

        if (feature.savingThrows.disadvantage) {
            if (character.savingThrows.disadvantage) {
                character.savingThrows.disadvantage.push(feature.savingThrows.disadvantage);
            } else {
                character.savingThrows.disadvantage = [feature.savingThrows.disadvantage];
            }
        }

        for (let ability of coreAbilities) {
            const ad = feature.savingThrows[ability];
            if (ad) {
                if (ad.advantage) {
                    const mv = character.savingThrows[ability];
                    if (mv.advantage) {
                        mv.advantage.push(ad.advantage);
                    } else {
                        mv.advantage = [ad.advantage];
                    }
                }

                if (ad.disadvantage) {
                    const mv = character.savingThrows[ability];
                    if (mv.disadvantage) {
                        mv.disadvantage.push(ad.disadvantage);
                    } else {
                        mv.disadvantage = [ad.disadvantage];
                    }
                }
            }
        }

        showInCharacter = true;
    }

    if (feature.abilityChecks) {
        // TODO: Apply these to all of the relevant abilities and skills as well.

        const init = feature.abilityChecks.initiative;
        if (init) {
            if (init.advantage) {
                const mv = character.initiativeBonus;
                if (mv.advantage) {
                    mv.advantage.push(init.advantage);
                } else {
                    mv.advantage = [init.advantage];
                }
            }

            if (init.disadvantage) {
                const mv = character.initiativeBonus;
                if (mv.disadvantage) {
                    mv.disadvantage.push(init.disadvantage);
                } else {
                    mv.disadvantage = [init.disadvantage];
                }
            }
        }
    }

    if (feature.ignoreLongRangePenalty) {
        character.ignoreLongRangePenalty = true;
        showInCharacter = true;
    }

    if (feature.ignoreCover) {
        if (character.ignoreCover) {
            if (character.ignoreCover.indexOf(feature.ignoreCover) < 0) {
                character.ignoreCover.push(feature.ignoreCover);
            }
        } else {
            character.ignoreCover = [feature.ignoreCover];
        }

        showInCharacter = true;
    }

    if (feature.resources) {
        for (let key in feature.resources) {
            const amount = feature.resources[key];
            const resource = character.resources?.[key];
            if (resource) {
                resource.max += amount;
            } else {
                console.warn(
                    `Character ${character.name} has the feature ${feature.name} which references the resource ${key}, but the resource is not available on the character.`
                );
            }
        }
    }

    if (feature.maxCantrips != null && classLevels) {
        classLevels.maxCantripsKnown = (classLevels.maxCantripsKnown ?? 0) + feature.maxCantrips;
    }

    if (feature.otherAbilities) {
        const abilityKeys = keyedListToKeyArray(feature.otherAbilities);
        for (let key of abilityKeys) {
            const ability = feature.otherAbilities[key];
            if (!character.abilities) {
                character.abilities = {};
            }

            if (ability.replaces) {
                if (character.abilities[ability.replaces]) {
                    if (!character.inactiveAbilities) {
                        character.inactiveAbilities = {};
                    }

                    character.inactiveAbilities[ability.replaces] = character.abilities[ability.replaces];
                    delete character.abilities[ability.replaces];
                } else {
                    console.warn(
                        `Character ${character.name} has the feature ${feature.name} which has the ability ${ability.name} which should replace ${ability.replaces}, but it was not found.`
                    );
                }
            }

            if (ability.mergesWith) {
                let mergeInto = character.abilities[ability.mergesWith];
                if (mergeInto) {
                    const merged = Object.assign({}, mergeInto);
                    for (let key in ability) {
                        if (abilityDoNotMerge.indexOf(key as keyof Ability) < 0) {
                            merged[key] = ability[key];
                        }
                    }

                    character.abilities[ability.mergesWith] = merged;
                } else {
                    console.warn(
                        `Character ${character.name} has the feature ${feature.name} which has the ability ${ability.name} which should merge into ${ability.replaces}, but it was not found.`
                    );
                }
            }

            character.abilities[getRuleKey(feature) + "~" + key] = Object.assign({}, ability, { feature: feature });
        }
    }

    if (feature.modifyAbilities) {
        for (let abilityKey in feature.modifyAbilities) {
            let mergeInto = character.abilities?.[abilityKey];
            if (mergeInto) {
                const modification = feature.modifyAbilities[abilityKey];
                const merged = Object.assign({}, mergeInto, modification);

                character.abilities![abilityKey] = merged;
            } else {
                console.warn(
                    `Character ${character.name} has the feature ${feature.name} which has an ability modification for ${abilityKey}, but it was not found.`
                );
            }
        }
    }

    if (feature.beforeAttack) {
        if (!character.beforeAttack) {
            character.beforeAttack = [];
        }

        for (let key in feature.beforeAttack) {
            let option = resolveAttackOption(character, feature.beforeAttack[key]);
            character.beforeAttack?.push(Object.assign({}, option, { feature: feature, key: key }));
        }

        showInCharacter = true;
    }

    if (feature.afterAttack) {
        if (!character.afterAttack) {
            character.afterAttack = [];
        }

        for (let key in feature.afterAttack) {
            let option = resolveAttackOption(character, feature.afterAttack[key]);
            character.afterAttack?.push(Object.assign({}, option, { feature: feature, key: key }));
        }

        showInCharacter = true;
    }

    if (feature.itemModifiers) {
        const characterModifiers = character.itemModifiers;
        const featureModifiers = keyedListToKeyArray(feature.itemModifiers);
        for (let i = 0; i < featureModifiers.length; i++) {
            const featureModifier = feature.itemModifiers[featureModifiers[i]];
            characterModifiers.push({
                filter: featureModifier.filter,
                attack: featureModifier.attack
                    ? convertValueOrExpressionToModifier(feature, featureModifier.attack, character)
                    : undefined,
                dmg1h: featureModifier.dmg1h,
                dmg2h: featureModifier.dmg2h,
                addProperties: featureModifier.addProperties,
            });
        }

        showInCharacter = true;
    }

    const ac = feature.ac;
    if (ac != null) {
        if (typeof ac === "number") {
            addNonZeroModifier(character.ac, { name: feature.name, value: ac });
        } else if (ac.value != null) {
            addNonZeroModifier(character.ac, { name: feature.name, value: ac.value! });
        } else if (ac.expression) {
            conditionalFeatures.push(() => {
                const value = evaluateCharacterExpression(character, ac.expression);
                addNonZeroModifier(character.ac, { name: feature.name, value: value });
            });
        }

        showInCharacter = true;
    }

    if (feature.speed) {
        for (let mt in feature.speed) {
            const movementType = mt as MovementType;
            const amount = feature.speed[movementType]!;
            if (typeof amount === "number") {
                addNonZeroModifier(character.speed[movementType], { name: feature.name, value: amount });
            } else if (amount.value) {
                addNonZeroModifier(character.speed[movementType], { name: feature.name, value: amount.value });
            } else if (amount.expression) {
                conditionalFeatures.push(() => {
                    const value = evaluateCharacterExpression(character, amount.expression);
                    addNonZeroModifier(character.speed[movementType], { name: feature.name, value: value });
                });
            }
        }

        showInCharacter = true;
    }

    if (showInCharacter && resolvedFeatures) {
        resolvedFeatures.push({ feature: feature, choices: choices });
    }
}

function resolveAttackOption(character: ResolvedCharacter, option: BeforeAttackOption) {
    if (option.damage) {
        for (let damageType in option.damage) {
            const damage = option.damage[damageType];
            if (damage) {
                // If the damage string is a reference to a lookup table, sub it in.
                const lookup = character.lookups?.[damage];
                if (lookup) {
                    // We found a lookup, this means that we'll need to modify the existing feature to resolve it.
                    const newDamage = Object.assign({}, option.damage, { [damageType]: lookup.value.toString() });
                    option = Object.assign({}, option, { damage: newDamage });
                }
            }
        }
    }

    return option;
}

function resolveRacialTrait(
    character: ResolvedCharacter,
    originalCharacter: Character,
    conditionalFeatures: (() => void)[],
    trait: Feature,
    rules: CharacterRuleSet
) {
    resolveFeature(
        character,
        originalCharacter,
        undefined,
        conditionalFeatures,
        character.racialTraits,
        character.race!.name,
        trait,
        character.raceChoices[trait.name],
        rules
    );
}

/**
 * Adds all modifiers and trait entries for the specified character's race to the resolved character, using the
 * the choices stored on the character.
 * @param character The character to resolve the race modifiers/entries for.
 */
function applyRace(
    character: ResolvedCharacter,
    originalCharacter: Character,
    conditionalFeatures: (() => void)[],
    rules: CharacterRuleSet
) {
    const race = character.race!;
    const speed = race.speed;
    if (speed.walk) {
        character.speed.walk.modifiers.push({ name: race.name, value: speed.walk });
    }

    if (speed.fly) {
        character.speed.walk.modifiers.push({ name: race.name, value: speed.fly });
    }

    if (speed.swim) {
        character.speed.swim.modifiers.push({ name: race.name, value: speed.swim });
    }

    if (speed.burrow) {
        character.speed.burrow.modifiers.push({ name: race.name, value: speed.burrow });
    }

    if (race.damageImmunities && Object.keys(race.damageImmunities).length > 0) {
        character.damageImmunities = addToKeyedList(character.damageImmunities, race.damageImmunities);
    }

    if (race.resistances && Object.keys(race.damageImmunities).length > 0) {
        character.resistances = addToKeyedList(character.resistances, race.resistances);
    }

    if (race.vulnerabilities && Object.keys(race.damageImmunities).length > 0) {
        character.vulnerabilities = addToKeyedList(character.vulnerabilities, race.vulnerabilities);
    }

    for (let i = 0; i < race.traits.length; i++) {
        resolveRacialTrait(character, originalCharacter, conditionalFeatures, race.traits[i], rules);
    }
}

export function getRaceDisplayName(race: Race | NamedRuleRef, subrace?: Subrace | NamedRuleRef) {
    if (subrace) {
        return `${subrace.name} ${race.name}`;
    }

    return race.name;
}

export function getClassDisplayName(c: CharacterClass | NamedRuleRef, sc?: CharacterSubclass | NamedRuleRef) {
    if (sc) {
        return sc.name;
    }

    return c.name;
}

function findRace(raceRef: NamedRuleRef | undefined, subraceRef: NamedRuleRef | undefined, rules: CharacterRuleSet) {
    if (!raceRef) {
        return undefined;
    }

    let race = rules.races.get(raceRef);
    if (!race) {
        return undefined;
    }

    let subrace =
        subraceRef != null && race.subraces
            ? Object.values(race.subraces).find(o => o.name === subraceRef.name && o.source === subraceRef.source)
            : undefined;
    return {
        race: race,
        subrace: subrace,
    };
}

function resolveRace(
    raceName: NamedRuleRef,
    subraceName: NamedRuleRef | undefined,
    rules: CharacterRuleSet
): ResolvedRace | undefined {
    const races = findRace(raceName, subraceName, rules);
    if (!races) {
        return undefined;
    }

    const { race, subrace } = races;
    const traits = keyedListToArray(race.traits);
    const subraceTraits = keyedListToArray(subrace?.traits);
    if (subraceTraits) {
        for (let subraceTrait of subraceTraits) {
            // If the subrace trait replaces a main race trait, make sure it gets removed.
            if (subraceTrait.replaces) {
                const i = traits.findIndex(o => o.name === subraceTrait.replaces);
                if (i >= 0) {
                    traits.splice(i, 1, subraceTrait);
                } else {
                    traits.push(subraceTrait);
                }
            } else {
                traits.push(subraceTrait);
            }
        }
    }

    let fluff = race.fluff;
    if (subrace?.fluff) {
        fluff = fluff ? subrace.fluff + "\n\n" + fluff : subrace.fluff;
    }

    // Make a copy of the original race, since we'll be modifying it.
    const r: ResolvedRace = {
        name: getRaceDisplayName(race, subrace),
        base: race,
        subrace: subrace,
        damageImmunities: subrace
            ? Object.assign({}, race.damageImmunities, subrace.damageImmunities)
            : race.damageImmunities,
        resistances: subrace ? Object.assign({}, race.resistances, subrace.resistances) : race.resistances,
        size: subrace && subrace.size != null ? subrace.size : race.size,
        speed: subrace ? Object.assign({}, race.speed, subrace.speed) : race.speed,
        vulnerabilities: subrace
            ? Object.assign({}, race.vulnerabilities, subrace.vulnerabilities)
            : race.vulnerabilities,
        traits: traits,
        fluff: fluff,
    };
    return r;
}

function resolveClassFeature(
    character: ResolvedCharacter,
    originalCharacter: Character,
    conditionalFeatures: (() => void)[],
    resolvedClass: ResolvedClassLevels,
    feature: ClassFeature,
    rules: CharacterRuleSet
) {
    // If the feature doesn't have an assigned level, it isn't gained directly. It might be optionally selected
    // as part of another feature, and if so will be resolved as part of that other feature.
    if (feature.level) {
        const choicesForLevel = resolvedClass.choices[feature.level];
        const choices = choicesForLevel ? choicesForLevel[getRuleKey(feature)] : undefined;
        resolveFeature(
            character,
            originalCharacter,
            resolvedClass,
            conditionalFeatures,
            character.classFeatures,
            resolvedClass.classData.name,
            feature,
            choices,
            rules
        );
    }
}

export function resolveClass(c: CharacterClass, subclass?: NamedRuleRef): ResolvedCharacterClass {
    // TODO: If there is no subclass, then the character class can be resolved once and remain valid forever more.
    // Or it could be generated during the ruleset generation.
    // if (!subclass) {
    //     return c;
    // }

    let features: ClassFeature[];
    let resources = c.resources ? keyedListToArray(c.resources) : undefined;
    const sc = subclass
        ? Object.values(c.subclasses).find(o => o.name === subclass.name && o.source === subclass.source)
        : undefined;
    if (sc) {
        // if (!sc) {
        //     return c;
        // }
        const scFeatures = keyedListToArray(sc.features);

        // Swap out the subclass placeholder features.
        features = Object.values(c.features).flatMap(o => {
            if (o.isSubclassFeature) {
                // If this feature isn't the original one that allows selection of the subclass (i.e. the one with
                // the same name as the subclass title), then swap it out for the relevant one from that subclass.
                // If it IS the original one, keep it but include the subclass ones as well.
                const subclassFeatures = scFeatures.filter(scf => scf.level === o.level);
                return o.name !== c.subclassTitle ? subclassFeatures : [o, ...subclassFeatures];
            }

            return o;
        });

        // Include all features that might be referenced.
        features.push(...scFeatures.filter(o => o.level == null));

        if (sc.resources) {
            resources = resources ?? [];
            resources.push(...keyedListToArray(sc.resources));
        }
    } else {
        features = Object.values(c.features);
    }

    return copyState<ResolvedCharacterClass>(c as any, {
        features: features,
        subclass: sc,
        resources: resources,
        originalClass: c,
    });
}

function lookupNumber(character: ResolvedCharacter, name: string) {
    const value = character.lookups?.[name]?.value;
    if (typeof value === "string") {
        if (value === "Unlimited" || value === "Infinite") {
            return Number.POSITIVE_INFINITY;
        }

        const num = Number(value);
        return isNaN(num) ? undefined : num;
    }

    return value;
}

function applyClass(
    character: ResolvedCharacter,
    originalCharacter: Character,
    conditionalFeatures: (() => void)[],
    resolvedClass: ResolvedClassLevels,
    rules: CharacterRuleSet
) {
    const characterClass = resolvedClass.classData;

    // Max HP
    let maxHp = 0;
    for (let i = 1; i <= resolvedClass.level; i++) {
        // if (resolvedClass.hpPerLevel[i] > characterClass.hitDie) {
        //     console.warn("HP gain for character level greater than the hit die max for the class.");
        // }

        const hpForLevel = resolvedClass.hpPerLevel[i];
        if (hpForLevel != null) {
            maxHp += hpForLevel;
        }
    }

    character.maxHp.modifiers.push({ name: characterClass.name, value: maxHp });

    // If this is a multiclassed character, and this isn't the original class, we should only apply the multiclass proficiencies.
    if (resolvedClass.isInitial) {
        Object.assign(character.savingThrowProficiencies, characterClass.savingThrowProficiencies);
        Object.assign(character.armorProficiencies, characterClass.armorProficiencies);
        Object.assign(character.weaponProficiencies, characterClass.weaponProficiencies);
        Object.assign(character.skillProficiencies, characterClass.skillProficiencies);
        if (characterClass.skillProficiencyChoice) {
            const choice = resolvedClass?.skillProficiencies;
            if (choice) {
                for (let i = 0; characterClass.skillProficiencyChoice.count && i < choice.length; i++) {
                    const skill = choice[i];
                    if (skill) {
                        character.skillProficiencies[skill] = 1;
                    }
                }
            }
        }

        Object.assign(character.toolProficiencies, characterClass.toolProficiencies);
    } else {
        Object.assign(character.armorProficiencies, characterClass.multiclassing.armorProficiencies);
        Object.assign(character.weaponProficiencies, characterClass.multiclassing.weaponProficiencies);
        Object.assign(character.skillProficiencies, characterClass.multiclassing.skillProficiencies);
        if (characterClass.multiclassing.skillProficiencyChoice) {
            const choice = resolvedClass?.skillProficiencies;
            if (choice) {
                for (let i = 0; characterClass.multiclassing.skillProficiencyChoice.count && i < choice.length; i++) {
                    const skill = choice[i];
                    if (skill) {
                        character.skillProficiencies[skill] = 1;
                    }
                }
            }
        }

        Object.assign(character.toolProficiencies, characterClass.multiclassing.toolProficiencies);
    }

    // Merge the lookups from the different classes into the character, to make them easier to look up.
    if (resolvedClass.classData.byLevel) {
        if (!character.lookups) {
            character.lookups = {};
        }

        for (let f in resolvedClass.classData.byLevel) {
            const lookup = resolvedClass.classData.byLevel[f];
            character.lookups[f] = Object.assign({}, lookup, {
                class: resolvedClass,
                value: lookup.values[resolvedClass.level - 1],
            });
        }
    }

    // Ensure any resources for the class are available on the character.
    // They all start off with 0 max, and will be added to by features.
    if (characterClass.resources) {
        for (let i = 0; i < characterClass.resources.length; i++) {
            const resource = characterClass.resources[i];
            if (!character.resources) {
                character.resources = {};
            }

            const max = lookupNumber(character, resource.maxFrom ?? resource.name) ?? 0;
            const used = originalCharacter.usedResources?.[resource.name] ?? 0;
            character.resources[resource.name] = Object.assign({}, resource, { used: used, max: max });
        }
    }

    for (let i = 0; i < characterClass.features.length; i++) {
        const feature = characterClass.features[i];
        if (feature.level != null && feature.level <= resolvedClass.level) {
            resolveClassFeature(character, originalCharacter, conditionalFeatures, resolvedClass, feature, rules);
        }
    }

    // The subclass summary feature doesn't get included in the above list.
    // TODO: Not sure about this yet, as we can't attach any choices to it, but need somewhere to put passive bonuses
    // attached to the class that don't have other specific features, such as prepared spells for (for e.g. cleric domains).
    const subclassSummary = characterClass.subclass?.summary;
    if (subclassSummary) {
        resolveFeature(
            character,
            originalCharacter,
            resolvedClass,
            conditionalFeatures,
            undefined,
            subclassSummary.name,
            subclassSummary,
            undefined,
            rules
        );
    }

    // If resolving the class has added spells, we need to update the known spells.
    if (
        resolvedClass.additionalSpells &&
        resolvedClass.classData.spellsKnownProgression != null &&
        resolvedClass.classData.spellsKnownProgression !== "all"
    ) {
        // This class uses known spells, so any additional spells for this class should be added.
        if (!resolvedClass.knownSpells) {
            resolvedClass.knownSpells = [];
        }

        for (let additionalSpells of resolvedClass.additionalSpells) {
            for (let additionalSpell of additionalSpells.spells) {
                resolvedClass.knownSpells.push(additionalSpell);

                // Sometimes those spells count against the character's known spells (i.e. Bard's Magical Secrets).
                if (additionalSpell.known) {
                    if (additionalSpell.level === 0) {
                        resolvedClass.currentCantripsKnown = (resolvedClass.currentCantripsKnown ?? 0) + 1;
                    } else {
                        resolvedClass.currentSpellsKnown = (resolvedClass.currentSpellsKnown ?? 0) + 1;
                    }
                }
            }
        }
    }
}

export function getSpellAttackModifier(creature: ResolvedMonster, spellType: MonsterSpellcasting);
export function getSpellAttackModifier(
    creature: ResolvedCharacter,
    spellType: ResolvedClassLevels | ResolvedAdditionalSpells
);
export function getSpellAttackModifier(
    creature: ResolvedCharacter | ResolvedMonster,
    spellType: MonsterSpellcasting | ResolvedClassLevels | ResolvedAdditionalSpells
) {
    let mod: number | undefined;
    if (isMonster(creature)) {
        const spellcastingAbility = (spellType as MonsterSpellcasting).ability;
        if (spellcastingAbility != null) {
            // By default spell attack mod is abilitymod + proficiency.
            mod =
                modifierFromAbilityScore(resolveModifiedValue(creature[spellcastingAbility])) +
                proficiencyBonusByCr(creature.cr ?? 0);
        }
    } else {
        const attackMod = spellType["spellAttackModifier"] as ModifiedValue | undefined;
        if (attackMod) {
            // spellType is a ResolvedClassLevels.
            mod = resolveModifiedValue(attackMod);
        } else {
            // spellType is a ResolvedAdditionalSpells.
            const asa = (spellType as ResolvedAdditionalSpells).ability;
            if (asa != null) {
                // By default spell save dc is abilitymod + proficiency.
                mod = modifierFromAbilityScore(resolveModifiedValue(creature[asa])) + creature.proficiencyBonus;
            }
        }
    }

    return mod ?? 0;
}

export function getAbilityDc(creature: ResolvedCharacter | ResolvedMonster, ability: CoreAbility) {
    if (isCharacter(creature)) {
        return 8 + creature.proficiencyBonus + modifierFromAbilityScore(resolveModifiedValue(creature[ability]));
    }

    return 8 + proficiencyBonusByCr(creature.cr ?? 0) + resolveModifiedValue(creature[ability]);
}

export function getSpellDc(creature: ResolvedMonster, spellType: MonsterSpellcasting);
export function getSpellDc(creature: ResolvedCharacter, spellType: ResolvedClassLevels | ResolvedAdditionalSpells);
export function getSpellDc(
    creature: ResolvedCharacter | ResolvedMonster,
    spellType: ResolvedClassLevels | MonsterSpellcasting | ResolvedAdditionalSpells
) {
    let dc: number | undefined;
    if (isMonster(creature)) {
        dc = (spellType as MonsterSpellcasting).dc;
        if (dc == null) {
            const spellcastingAbility = (spellType as MonsterSpellcasting).ability;
            if (spellcastingAbility != null) {
                // By default spell save dc is 8 + abilitymod + proficiency.
                dc =
                    8 +
                    modifierFromAbilityScore(resolveModifiedValue(creature[spellcastingAbility])) +
                    proficiencyBonusByCr(creature.cr ?? 0);
            }
        }
    } else {
        const dcMod = spellType["spellSaveDC"] as ModifiedValue | undefined;
        if (dcMod) {
            // spellType is a ResolvedClassLevels.
            dc = resolveModifiedValue(dcMod);
        } else {
            // spellType is a ResolvedAdditionalSpells.
            const asa = (spellType as ResolvedAdditionalSpells).ability;
            if (asa != null) {
                // By default spell save dc is 8 + abilitymod + proficiency.
                dc = 8 + modifierFromAbilityScore(resolveModifiedValue(creature[asa])) + creature.proficiencyBonus;
            }
        }
    }

    return dc ?? 10;
}

export function sortSpells(a: Spell, b: Spell) {
    let r = a.level - b.level;
    if (r === 0) {
        r = a.name.localeCompare(b.name);
    }

    return r;
}

/**
 * Gets the level of the first free spell slot that can cast a spell of the specified level.
 * @param creature The character to check the spell slots for.
 * @param level The level of the spell that needs the spell slot.
 * @param name The name of the spell slot type. If undefined, any slot is acceptable. If null, only basic spellcasting slots are considered.
 * @returns The spell level of the first free spell slot.
 */
export function getFreeSpellSlot(creature: ResolvedMonster, level: number): number | undefined;
export function getFreeSpellSlot(
    creature: ResolvedCharacter,
    level: number,
    name?: string | null
): number | { name: string; level: number } | undefined;
export function getFreeSpellSlot(
    creature: ResolvedCharacter | ResolvedMonster,
    level: number,
    name?: string | null
): number | { name: string; level: number } | undefined {
    if (isMonster(creature)) {
        for (let spellLevel = level; spellLevel <= creature.spellSlots.length; spellLevel++) {
            const slots = creature.spellSlots[spellLevel - 1] ?? 0;
            const used = creature.usedSpellSlots[spellLevel - 1] ?? 0;
            if (used < slots) {
                return spellLevel;
            }
        }
    } else {
        const freeSlots = creature.spellSlots
            .filter(o => (Array.isArray(o) ? name == null : name === undefined || o.name === name))
            .map(o => {
                const spellSlots = Array.isArray(o) ? o : o.spellSlots;
                for (let spellLevel = level; spellLevel <= spellSlots.length; spellLevel++) {
                    const slots = spellSlots[spellLevel - 1] ?? 0;
                    const used = Array.isArray(o)
                        ? creature.usedSpellSlots?.default?.[spellLevel - 1] ?? 0
                        : creature.usedSpellSlots?.[o.name]?.[spellLevel - 1] ?? 0;
                    if (used < slots) {
                        return Array.isArray(o) ? spellLevel : { name: o.name, level: spellLevel };
                    }
                }

                return undefined;
            });

        let lvl: number | undefined;
        let n: string | undefined;
        for (let i = 0; i < freeSlots.length; i++) {
            const fs = freeSlots[i];
            if (fs != null) {
                if (typeof fs === "number") {
                    if (lvl == null || fs < lvl) {
                        lvl = fs;
                        n = undefined;
                    }
                } else {
                    if (lvl == null || fs.level < lvl) {
                        lvl = fs.level;
                        n = fs.name;
                    }
                }
            }
        }

        if (lvl != null) {
            return n != null ? { name: n, level: lvl } : lvl;
        }
    }

    return undefined;
}

function resolveInventory(
    inventory: Inventory,
    items: ItemStore,
    attunedItems: ResolvedInventoryItem[]
): ResolvedInventory {
    const resolvedInventory: ResolvedInventory = {};
    const keys = Object.keys(inventory);
    for (let i = 0; i < keys.length; i++) {
        const item = inventory[keys[i]];
        if (item) {
            const resolvedItem = resolveItem(item, items) as KeyedListItem<ResolvedInventoryItem>;
            resolvedItem.id = keys[i];
            if (item.acquired) {
                resolvedItem.acquired = item.acquired;
            }

            if (item.quantity != null) {
                resolvedItem.quantity = item.quantity;
            }

            if (item.active != null) {
                resolvedItem.active = item.active;
            }

            if (item.attuned != null) {
                resolvedItem.attuned = item.attuned;
            }

            if (item.order != null) {
                resolvedItem.order = item.order;
            }

            if (isInventoryWeapon(resolvedItem)) {
                resolvedItem.hitBonus = modifiedValue(0);
                resolvedItem.damageBonus = modifiedValue(0);
                resolvedItem.ability = "strength";
            } else if (isInventoryArmor(resolvedItem)) {
                resolvedItem.acBonus = modifiedValue(0);
            }

            resolvedInventory[keys[i]] = resolvedItem;

            if (attunedItems.length < MAX_ATTUNED_ITEMS && resolvedItem.active && resolvedItem.attuned) {
                attunedItems.push(resolvedItem);
            }
        }
    }

    return resolvedInventory;
}

function addAdvantage(
    modifiedValue: ModifiedValue,
    advantage: { reason: string; condition?: string; source?: AppliedAbilityEffectSource }
) {
    if (!modifiedValue.advantage) {
        modifiedValue.advantage = [advantage];
    } else {
        modifiedValue.advantage.push(advantage);
    }
}

function addDisadvantage(
    modifiedValue: ModifiedValue,
    disadvantage: { reason: string; condition?: string; source?: AppliedAbilityEffectSource }
) {
    if (!modifiedValue.disadvantage) {
        modifiedValue.disadvantage = [disadvantage];
    } else {
        modifiedValue.disadvantage.push(disadvantage);
    }
}

function addFail(
    modifiedValue: ModifiedValue,
    fail: { reason: string; condition?: string; source?: AppliedAbilityEffectSource }
) {
    if (!modifiedValue.fail) {
        modifiedValue.fail = [fail];
    } else {
        modifiedValue.fail.push(fail);
    }
}

const blindedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Blinded",
    isReadOnly: true,
    attacksBy: {
        "1": {
            disadvantage: true,
        },
    },
    attacksOn: {
        "1": {
            advantage: true,
        },
    },
    abilityChecks: {
        all: {
            fail: true,
            condition: "If sight is required",
        },
    },
};

const deafenedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Deafened",
    isReadOnly: true,
    abilityChecks: {
        all: {
            fail: true,
            condition: "If hearing is required.",
        },
    },
};

const exhaustion1Effect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Exhaustion 1",
    isReadOnly: true,
    abilityChecks: {
        all: {
            disadvantage: true,
        },
    },
};

const exhaustion2Effect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Exhaustion 2",
    isReadOnly: true,
    speedMultiplier: 0.5,
};

const exhaustion3Effect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Exhaustion 3",
    isReadOnly: true,
    savingThrows: {
        all: {
            disadvantage: true,
        },
    },
    attacksBy: {
        "1": {
            disadvantage: true,
        },
    },
};

const exhaustion4Effect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Exhaustion 4",
    isReadOnly: true,
    maxHpMultiplier: 0.5,
};

const exhaustion5Effect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Exhaustion 5",
    isReadOnly: true,
    speedMultiplier: 0,
};

const frightenedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Frightened",
    isReadOnly: true,
    abilityChecks: {
        all: {
            disadvantage: true,
            condition: "If the source of the fear is within line of sight.",
        },
    },
    attacksBy: {
        "1": {
            disadvantage: true,
            condition: { special: "If the source of the fear is within line of sight." },
        },
    },
    unhandled: "The creature can't willingly move closer to the source of its fear.",
};

const grappledEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Grappled",
    isReadOnly: true,
    speedMultiplier: 0,
};

const invisibleEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Invisible",
    isReadOnly: true,
    attacksOn: {
        "1": {
            disadvantage: true,
        },
    },
    attacksBy: {
        "1": {
            advantage: true,
        },
    },
};

const paralyzedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Paralyzed",
    isReadOnly: true,
    speedMultiplier: 0,
    savingThrows: {
        strength: {
            fail: true,
        },
        dexterity: {
            fail: true,
        },
    },
    attacksOn: {
        "1": {
            crit: true,
            condition: {
                maxDistance: 5,
            },
        },
    },
};

const petrifiedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Petrified",
    isReadOnly: true,
    speedMultiplier: 0,
    savingThrows: {
        strength: {
            fail: true,
        },
        dexterity: {
            fail: true,
        },
    },
    attacksOn: {
        "1": {
            advantage: true,
        },
    },
    resistances: {
        acid: true,
        bludgeoning: true,
        cold: true,
        fire: true,
        force: true,
        lightning: true,
        necrotic: true,
        piercing: true,
        psychic: true,
        radiant: true,
        slashing: true,
        thunder: true,
    },
    immunities: {
        poison: true,
    },
    unhandled:
        "A petrified creature is transformed, along with any nonmagical object it is wearing or carrying, into a solid inanimate substance (usually stone). Its weight increases by a factor of ten, and it ceases aging. The creature is immune to poison and disease, although a poison or disease already in its system is suspended, not neutralized.",
};

const poisonedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Poisoned",
    isReadOnly: true,
    abilityChecks: {
        all: {
            disadvantage: true,
        },
    },
    attacksBy: {
        "1": {
            disadvantage: true,
        },
    },
};

const restrainedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Restrained",
    isReadOnly: true,
    speedMultiplier: 0,
    attacksBy: {
        "1": {
            disadvantage: true,
        },
    },
    attacksOn: {
        "1": {
            advantage: true,
        },
    },
    savingThrows: {
        dexterity: {
            disadvantage: true,
        },
    },
};

const stunnedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Stunned",
    isReadOnly: true,
    speedMultiplier: 0,
    savingThrows: {
        strength: {
            fail: true,
        },
        dexterity: {
            fail: true,
        },
    },
    attacksOn: {
        "1": {
            advantage: true,
        },
    },
};

const unconsciousEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Unconscious",
    isReadOnly: true,
    speedMultiplier: 0,
    savingThrows: {
        strength: {
            fail: true,
        },
        dexterity: {
            fail: true,
        },
    },
    attacksOn: {
        "1": {
            advantage: true,
        },
        "2": {
            crit: true,
            condition: {
                maxDistance: 5,
            },
        },
    },
};

const incapacitatedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Incapacitated",
    isReadOnly: true,
    canTakeActions: false,
    canTakeReactions: false,
};

const proneEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Prone",
    isReadOnly: true,
    attacksBy: {
        "1": {
            disadvantage: true,
        },
    },
    attacksOn: {
        "1": {
            advantage: true,
            condition: {
                maxDistance: 5,
            },
        },
        "2": {
            disadvantage: true,
            condition: {
                minDistance: 5,
                minExclusive: true,
            },
        },
    },
    moveCost: 1,
};

const surprisedEffect: AppliedAbilityEffect & {
    source?: string;
    feature?: Feature;
    spell?: Spell;
    condition?: CreatureCondition;
} = {
    name: "Surprised",
    isReadOnly: true,
    canTakeActions: false,
    canTakeReactions: false,
    speedMultiplier: 0,
    condition: {
        name: "Surprised",
        content:
            "A surprised creature cannot move and cannot take actions or reactions. A creature is no longer surprised after its turn ends.",
        icon: "Surprised",
    },
};

function addEffectToCreature(
    creature: ResolvedMonster | ResolvedCharacter,
    key: string,
    effect: AppliedAbilityEffect & { source?: string; feature?: Feature; spell?: Spell; condition?: CreatureCondition },
    condition?: CreatureCondition
) {
    if (!creature.effects) {
        creature.effects = {};
    }

    creature.effects[key] = condition != null ? Object.assign({}, effect, { condition: condition }) : effect;
}

function applyConditions(creature: ResolvedMonster | ResolvedCharacter, rules: CharacterRuleSet) {
    if (creature.conditions?.incapacitated) {
        addEffectToCreature(creature, "incapacitated", incapacitatedEffect, rules.conditions.get("incapacitated"));
    }

    if (creature.conditions?.prone) {
        addEffectToCreature(creature, "prone", proneEffect, rules.conditions.get("prone"));
    }

    if (creature.conditions?.blinded) {
        addEffectToCreature(creature, "blinded", blindedEffect, rules.conditions.get("blinded"));
    }

    if (creature.conditions?.deafened) {
        addEffectToCreature(creature, "deafened", deafenedEffect, rules.conditions.get("deafened"));
    }

    if (isCharacter(creature) && creature.exhaustion != null && creature.exhaustion >= 1) {
        if (creature.exhaustion >= 1) {
            addEffectToCreature(creature, "exhaustion1", exhaustion1Effect, rules.conditions.get("exhaustion"));
        }

        if (creature.exhaustion >= 2) {
            addEffectToCreature(creature, "exhaustion2", exhaustion2Effect, rules.conditions.get("exhaustion"));
        }

        if (creature.exhaustion >= 3) {
            addEffectToCreature(creature, "exhaustion3", exhaustion3Effect, rules.conditions.get("exhaustion"));
        }

        if (creature.exhaustion >= 4) {
            addEffectToCreature(creature, "exhaustion4", exhaustion4Effect, rules.conditions.get("exhaustion"));
        }

        if (creature.exhaustion >= 5) {
            addEffectToCreature(creature, "exhaustion5", exhaustion5Effect, rules.conditions.get("exhaustion"));
        }
    }

    if (creature.conditions?.frightened) {
        addEffectToCreature(creature, "frightened", frightenedEffect, rules.conditions.get("frightened"));
    }

    if (creature.conditions?.grappled) {
        addEffectToCreature(creature, "grappled", grappledEffect, rules.conditions.get("grappled"));
    }

    if (creature.conditions?.invisible) {
        addEffectToCreature(creature, "invisible", invisibleEffect, rules.conditions.get("invisible"));
    }

    if (creature.conditions?.paralyzed) {
        applyConditionToResolvedCharacter(creature, "incapacitated");
        addEffectToCreature(creature, "incapacitated", incapacitatedEffect, rules.conditions.get("incapacitated"));
        addEffectToCreature(creature, "paralyzed", paralyzedEffect, rules.conditions.get("paralyzed"));
    }

    if (creature.conditions?.petrified) {
        applyConditionToResolvedCharacter(creature, "incapacitated");
        addEffectToCreature(creature, "incapacitated", incapacitatedEffect, rules.conditions.get("incapacitated"));
        addEffectToCreature(creature, "petrified", petrifiedEffect, rules.conditions.get("petrified"));
    }

    if (creature.conditions?.poisoned) {
        addEffectToCreature(creature, "poisoned", poisonedEffect, rules.conditions.get("poisoned"));
    }

    if (creature.conditions?.restrained) {
        addEffectToCreature(creature, "restrained", restrainedEffect, rules.conditions.get("restrained"));
    }

    if (creature.conditions?.stunned) {
        applyConditionToResolvedCharacter(creature, "incapacitated");
        addEffectToCreature(creature, "incapacitated", incapacitatedEffect, rules.conditions.get("incapacitated"));
        addEffectToCreature(creature, "stunned", stunnedEffect, rules.conditions.get("stunned"));
    }

    if (creature.conditions?.unconscious) {
        addUnconsciousToCreature(creature, rules);
    }

    if (creature.isDead || (creature.hp != null && creature.hp <= 0)) {
        applyConditionToResolvedCharacter(creature, "unconscious");
        addUnconsciousToCreature(creature, rules);
    }

    if (creature.combatTurn?.surprised) {
        addEffectToCreature(creature, "surprised", surprisedEffect);
    }
}

function addUnconsciousToCreature(creature: ResolvedCharacter | ResolvedMonster, rules: CharacterRuleSet) {
    applyConditionToResolvedCharacter(creature, "incapacitated");
    applyConditionToResolvedCharacter(creature, "prone");
    addEffectToCreature(creature, "incapacitated", incapacitatedEffect, rules.conditions.get("incapacitated"));
    addEffectToCreature(creature, "prone", proneEffect, rules.conditions.get("prone"));
    addEffectToCreature(creature, "unconscious", unconsciousEffect, rules.conditions.get("unconscious"));
}

export function getAppliedEffect(applicable: ApplicableAbilityEffect, choices?: AppliedAbilityEffectChoices) {
    if (!applicable.transform) {
        return applicable as AppliedAbilityEffect;
    } else if (choices && choices.transform) {
        const applied: AppliedAbilityEffect = Object.assign({}, applicable, {
            transform: Object.assign({}, applicable.transform, choices.transform),
        });
        return applied;
    }

    // TODO: Need to get the choices for the effect and use them to resolve the applicable effect into an actual applied effect.
    return applicable as AppliedAbilityEffect;
}

function findAbilityForEffect(creature: ResolvedMonster | ResolvedCharacter, feature?: Feature, ability?: string) {
    if (!ability) {
        return undefined;
    }

    // if (feature) {
    //     const a = feature.otherAbilities?.[ability];
    //     if (a) {
    //         return a;
    //     }
    // }
    return creature.abilities?.[ability];
}

function resolveEffect(
    effect: (AppliedAbilityEffect | AppliedAbilityEffectRef) & {
        feature?: NamedRuleRef;
        spell?: NamedRuleRef;
        ability?: string;
        condition?: string;
    },
    creature: ResolvedMonster | ResolvedCharacter,
    rules: CharacterRuleSet
):
    | (AppliedAbilityEffect & { feature?: Feature; spell?: Spell; ability?: Ability; condition?: CreatureCondition })
    | undefined {
    let baseEffect: AppliedAbilityEffect & { feature?: NamedRuleRef; spell?: NamedRuleRef; ability?: string };
    if (isNamedRuleRef(effect)) {
        const lookup = rules.effects.get(effect);
        if (!lookup) {
            return undefined;
        }

        baseEffect = getAppliedEffect(lookup, effect);
    } else {
        baseEffect = effect;
    }

    if (baseEffect.extends) {
        baseEffect = Object.assign({}, resolveEffect(baseEffect.extends, creature, rules), baseEffect);
    }

    const feature = effect.feature ? rules.features.get(effect.feature) : undefined;
    const ability = findAbilityForEffect(creature, feature, effect.ability);
    const spell = effect.spell ? rules.spells.get(effect.spell) : undefined;
    const condition = effect.condition ? rules.conditions.get(effect.condition) : undefined;

    return Object.assign({}, baseEffect, {
        duration: effect.duration ?? baseEffect.duration,
        feature: feature,
        spell: spell,
        ability: ability,
        condition: condition,
    });
}

function resolveEffectList(
    creature: ResolvedMonster | ResolvedCharacter,
    creatureEffects:
        | KeyedList<
              (AppliedAbilityEffect | AppliedAbilityEffectRef) & {
                  feature?: NamedRuleRef;
                  spell?: NamedRuleRef;
                  ability?: string;
              }
          >
        | undefined,
    rules: CharacterRuleSet
) {
    let resolvedEffects:
        | KeyedList<AppliedAbilityEffect & { feature?: Feature; spell?: Spell; ability?: Ability }>
        | undefined;
    if (creatureEffects) {
        resolvedEffects = {};
        const effectKeys = keyedListToKeyArray(creatureEffects);
        for (let effectKey of effectKeys) {
            const effect = creatureEffects[effectKey];
            const resolvedEffect = resolveEffect(effect, creature, rules);
            if (resolvedEffect) {
                resolvedEffects[effectKey] = resolvedEffect;
            }
        }
    }

    return resolvedEffects;
}

interface TransformedMonsterOverrides extends Partial<CoreAbilities<ModifiedValue>> {
    // Extra skill proficiencies, above those provided by class/background features.
    skillProficiencies?: Partial<Skills<number>>;

    // Extra saving throw proficiencies, above those provided by class etc.
    // This won't normally need to be changed.
    savingThrowProficiencies?: Partial<CoreAbilities<number>>;
}

function resolveMonsterSavingThrowModifiers(
    monster: Monster,
    resolvedMonster: ResolvedMonster,
    ability: CoreAbility,
    abilityMods: CoreAbilities<ValueModifier>,
    transformOverrides?: TransformedMonsterOverrides
) {
    addNonZeroModifier(resolvedMonster.savingThrows[ability], abilityMods[ability]);

    const withProficiency = monster.savingThrows?.[ability];
    let proficiency = withProficiency != null ? withProficiency - modifierFromAbilityScore(monster[ability]) : 0;
    const transformOverride = transformOverrides?.savingThrowProficiencies?.[ability];
    if (transformOverride != null) {
        // If a transform override is specified for this core ability, take the highest value.
        proficiency = Math.max(transformOverride, proficiency);
    }

    addNonZeroModifier(resolvedMonster.savingThrows[ability], { name: "Proficiency", value: proficiency });
}

function resolveMonsterAbilities(monster: Monster) {
    if (monster.abilityState == null || monster.abilities == null) {
        return monster.abilities;
    }

    const resolvedAbilities: KeyedList<MonsterAbility & MonsterAbilityState> = {};
    for (let key in monster.abilities) {
        const ability = monster.abilities[key];
        const state = monster.abilityState[key];
        resolvedAbilities[key] = state != null ? Object.assign({}, ability, state) : ability;
    }

    return resolvedAbilities;
}

export function resolveMonster(
    monster: Monster,
    campaign: Campaign,
    rules: CharacterRuleSet,
    transformOverrides?: TransformedMonsterOverrides
): ResolvedMonster {
    const skillChecks: Required<Skills<ModifiedValue>> = {
        acrobatics: modifiedValue(0),
        animal_handling: modifiedValue(0),
        arcana: modifiedValue(0),
        athletics: modifiedValue(0),
        deception: modifiedValue(0),
        history: modifiedValue(0),
        insight: modifiedValue(0),
        intimidation: modifiedValue(0),
        investigation: modifiedValue(0),
        medicine: modifiedValue(0),
        nature: modifiedValue(0),
        perception: modifiedValue(0),
        performance: modifiedValue(0),
        persuasion: modifiedValue(0),
        religion: modifiedValue(0),
        sleight_of_hand: modifiedValue(0),
        stealth: modifiedValue(0),
        survival: modifiedValue(0),
    };

    // Combine all the spell slots for the various spellcastings.
    // In practice there should really only be one anyway.
    // TODO: Do I need to look up how casters with different spell slots work? I think they just get combined into one...
    // (i.e. a Wizard/Cleric or Ranger/Warlock etc)
    const spellSlots: number[] = [];
    if (monster.spellcasting) {
        var scs = keyedListToArray(monster.spellcasting);
        for (let sc of scs) {
            if (sc.spellSlots) {
                for (let i = 0; i < sc.spellSlots.length; i++) {
                    if (sc.spellSlots[i] != null) {
                        spellSlots[i] = spellSlots[i] != null ? spellSlots[i] + sc.spellSlots[i] : sc.spellSlots[i];
                    }
                }
            }
        }
    }

    const concentratingOn = monster.concentrating ? rules.spells.get(monster.concentrating) : undefined;

    // The number of hit dice for the monster is the number of dice in its HP formula.
    let hitDice = 0;
    const maxHpDice = monster.maxHpInfo?.dice;
    if (maxHpDice != null) {
        for (let d in maxHpDice) {
            const dv = maxHpDice[d];
            if (Array.isArray(dv)) {
                for (let dvv of dv) {
                    hitDice += (dvv as DiceBagTerm).amount;
                }
            }
        }
    }

    const dex = transformOverrides?.dexterity ?? modifiedValue(monster.dexterity ?? 10);
    const resolvedMonster: ResolvedMonster = {
        name: monster.name,
        source: monster.source,
        type: ActorType.Monster,
        resolvedFrom: monster,
        charisma: transformOverrides?.charisma ?? modifiedValue(monster.charisma ?? 10),
        constitution: transformOverrides?.constitution ?? modifiedValue(monster.constitution ?? 10),
        dexterity: dex,
        intelligence: transformOverrides?.intelligence ?? modifiedValue(monster.intelligence ?? 10),
        strength: transformOverrides?.strength ?? modifiedValue(monster.strength ?? 10),
        initiativeBonus: modifiedValue(0),
        wisdom: transformOverrides?.wisdom ?? modifiedValue(monster.wisdom ?? 10),
        maxHpInfo: monster.maxHpInfo ?? {},
        abilities: resolveMonsterAbilities(monster),
        abilityUsage: monster.abilityUsage,
        ac: monster.ac,
        alignment: monster.alignment,
        conditionImmunities: monster.conditionImmunities,
        conditions: monster.conditions,
        cr: monster.cr,
        creatureType: monster.creatureType,
        environments: monster.environments,
        group: monster.group,
        hitDiceSpent: monster.hitDiceSpent,
        hp: monster.hp,
        damageImmunities: monster.damageImmunities,
        innateSpellUsage: monster.innateSpellUsage,
        languages: monster.languages,
        maxHp: modifiedValue(monster.maxHp ?? monster.maxHpInfo?.average ?? 1),
        passivePerception: monster.passivePerception,
        resistances: monster.resistances,
        senses: {
            blindsight: modifiedValue(monster.senses?.blindsight ?? 0),
            darkvision: modifiedValue(monster.senses?.darkvision ?? 0),
            tremorsense: modifiedValue(monster.senses?.tremorsense ?? 0),
            truesight: modifiedValue(monster.senses?.truesight ?? 0),
        },
        size: monster.size,
        speed: {
            walk: modifiedValue(monster.speed?.walk ?? 0),
            fly: modifiedValue(monster.speed?.fly ?? 0),
            swim: modifiedValue(monster.speed?.swim ?? 0),
            burrow: modifiedValue(monster.speed?.burrow ?? 0),
            climb: modifiedValue(monster.speed?.climb ?? 0),
        },
        spellcasting: monster.spellcasting,
        tempHp: monster.tempHp,
        traits: monster.traits,
        variants: monster.variants,
        vulnerabilities: monster.vulnerabilities,
        skillChecks: skillChecks,
        spellSlots: spellSlots,
        usedSpellSlots: monster.usedSpellSlots ?? [],
        savingThrows: {
            strength: modifiedValue(0),
            dexterity: modifiedValue(0),
            constitution: modifiedValue(0),
            intelligence: modifiedValue(0),
            wisdom: modifiedValue(0),
            charisma: modifiedValue(0),
        },
        concentrating: concentratingOn
            ? Object.assign({}, concentratingOn, {
                  instanceId: monster.concentrating!.instanceId,
                  annotation: monster.concentrating?.annotation,
                  location: monster.concentrating?.location,
              })
            : undefined,
        hitDice: hitDice,
        combatTurn: monster.combatTurn,
        legendaryActions: monster.legendaryActions,
        usedLegendaryResistances: monster.usedLegendaryResistances,
        legendaryResistances: monster.legendaryResistances,
        isDead: !!monster.isDead,
        canTakeActions: true,
        canTakeReactions: true,
        canCastSpells: true,
        moveCost: 0,
    };

    const resolvedEffects = resolveEffectList(resolvedMonster, monster.effects, rules);
    resolvedMonster.effects = resolvedEffects;

    applyConditions(resolvedMonster, rules);

    applyEffects(resolvedMonster, campaign, rules, transformOverrides != null);

    const abilityMods: CoreAbilities<ValueModifier> = {
        strength: { name: "Strength", value: modifierFromAbilityScore(resolveModifiedValue(resolvedMonster.strength)) },
        dexterity: {
            name: "Dexterity",
            value: modifierFromAbilityScore(resolveModifiedValue(resolvedMonster.dexterity)),
        },
        constitution: {
            name: "Constitution",
            value: modifierFromAbilityScore(resolveModifiedValue(resolvedMonster.constitution)),
        },
        intelligence: {
            name: "Intelligence",
            value: modifierFromAbilityScore(resolveModifiedValue(resolvedMonster.intelligence)),
        },
        wisdom: { name: "Wisdom", value: modifierFromAbilityScore(resolveModifiedValue(resolvedMonster.wisdom)) },
        charisma: { name: "Charisma", value: modifierFromAbilityScore(resolveModifiedValue(resolvedMonster.charisma)) },
    };

    resolveMonsterSavingThrowModifiers(monster, resolvedMonster, "strength", abilityMods, transformOverrides);
    resolveMonsterSavingThrowModifiers(monster, resolvedMonster, "dexterity", abilityMods, transformOverrides);
    resolveMonsterSavingThrowModifiers(monster, resolvedMonster, "constitution", abilityMods, transformOverrides);
    resolveMonsterSavingThrowModifiers(monster, resolvedMonster, "intelligence", abilityMods, transformOverrides);
    resolveMonsterSavingThrowModifiers(monster, resolvedMonster, "wisdom", abilityMods, transformOverrides);
    resolveMonsterSavingThrowModifiers(monster, resolvedMonster, "charisma", abilityMods, transformOverrides);

    for (let skill in skillChecks) {
        const ability = rules.skills[skill as Skill].modifier;
        addNonZeroModifier(skillChecks[skill], abilityMods[ability]);

        const withProficiency = monster.skills?.[skill];
        let proficiency = withProficiency != null ? withProficiency - modifierFromAbilityScore(monster[ability]) : 0;
        const transformOverride = transformOverrides?.skillProficiencies?.[skill];
        if (transformOverride != null) {
            // If a transform override has been specified for this skill, take the highest value.
            proficiency = Math.max(transformOverride, proficiency);
        }

        addNonZeroModifier(skillChecks[skill], { name: "Proficiency", value: proficiency });

        // Copy any adv/dis/fail from the appropriate ability modifier.
        const abilityValue = resolvedMonster[ability];
        const skillValue = skillChecks[skill as Skill];
        skillValue.advantage = abilityValue.advantage;
        skillValue.disadvantage = abilityValue.disadvantage;
        skillValue.fail = abilityValue.fail;
    }

    addNonZeroModifier(resolvedMonster.initiativeBonus, abilityMods.dexterity);

    return resolvedMonster;
}

function applyRollModifiers(
    modifiedValue: ModifiedValue,
    modifiers: RollModifiers | undefined,
    reason: string,
    source: AppliedAbilityEffectSource
) {
    if (!modifiers) {
        return;
    }

    if (modifiers?.advantage) {
        addAdvantage(modifiedValue, { reason: reason, condition: modifiers.condition, source });
    } else if (modifiers?.disadvantage) {
        addDisadvantage(modifiedValue, { reason: reason, condition: modifiers.condition, source });
    } else if (modifiers?.fail) {
        addFail(modifiedValue, { reason: reason, condition: modifiers.condition, source });
    }

    if (modifiers?.extraRoll) {
        if (!modifiedValue.extraRolls) {
            modifiedValue.extraRolls = [{ roll: modifiers.extraRoll, reason: reason, source }];
        } else {
            modifiedValue.extraRolls.push({ roll: modifiers.extraRoll, reason: reason, source });
        }
    }
}

function applyEffect(
    effect: AppliedAbilityEffect & {
        source?: string | undefined;
        feature?: Feature | undefined;
        ability?: Ability | undefined;
        spell?: Spell | undefined;
        condition?: CreatureCondition | undefined;
    },
    resolvedCreature: ResolvedCharacter | ResolvedMonster,
    campaign: Campaign,
    rules: CharacterRuleSet,
    ignoreTransforms: boolean | undefined,
    extraEffects: (AppliedAbilityEffect & {
        source?: string | undefined;
        feature?: Feature | undefined;
        ability?: Ability | undefined;
        spell?: Spell | undefined;
        condition?: CreatureCondition | undefined;
        key: string;
    })[]
): CreatureTransformation | undefined {
    let transform: CreatureTransformation | undefined;
    if (effect.abilityChecks) {
        if (effect.abilityChecks.all) {
            applyRollModifiers(resolvedCreature.strength, effect.abilityChecks.all, effect.name, effect);
            applyRollModifiers(resolvedCreature.dexterity, effect.abilityChecks.all, effect.name, effect);
            applyRollModifiers(resolvedCreature.constitution, effect.abilityChecks.all, effect.name, effect);
            applyRollModifiers(resolvedCreature.wisdom, effect.abilityChecks.all, effect.name, effect);
            applyRollModifiers(resolvedCreature.intelligence, effect.abilityChecks.all, effect.name, effect);
            applyRollModifiers(resolvedCreature.charisma, effect.abilityChecks.all, effect.name, effect);
            applyRollModifiers(resolvedCreature.initiativeBonus, effect.abilityChecks.all, effect.name, effect);

            if (isCharacter(resolvedCreature)) {
                for (let skill of skills) {
                    applyRollModifiers(
                        resolvedCreature.skillChecks[skill],
                        effect.abilityChecks.all,
                        effect.name,
                        effect
                    );
                }
            }
        } else {
            applyRollModifiers(resolvedCreature.strength, effect.abilityChecks.strength, effect.name, effect);
            applyRollModifiers(resolvedCreature.dexterity, effect.abilityChecks.dexterity, effect.name, effect);
            applyRollModifiers(resolvedCreature.constitution, effect.abilityChecks.constitution, effect.name, effect);
            applyRollModifiers(resolvedCreature.wisdom, effect.abilityChecks.wisdom, effect.name, effect);
            applyRollModifiers(resolvedCreature.intelligence, effect.abilityChecks.intelligence, effect.name, effect);
            applyRollModifiers(resolvedCreature.charisma, effect.abilityChecks.charisma, effect.name, effect);
            applyRollModifiers(resolvedCreature.initiativeBonus, effect.abilityChecks.initiative, effect.name, effect);
            applyRollModifiers(resolvedCreature.initiativeBonus, effect.abilityChecks.dexterity, effect.name, effect);

            if (isCharacter(resolvedCreature)) {
                applyRollModifiers(
                    resolvedCreature.skillChecks.acrobatics,
                    effect.abilityChecks.dexterity,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.animal_handling,
                    effect.abilityChecks.wisdom,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.arcana,
                    effect.abilityChecks.intelligence,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.athletics,
                    effect.abilityChecks.strength,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.deception,
                    effect.abilityChecks.charisma,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.history,
                    effect.abilityChecks.intelligence,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.insight,
                    effect.abilityChecks.wisdom,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.intimidation,
                    effect.abilityChecks.charisma,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.investigation,
                    effect.abilityChecks.intelligence,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.medicine,
                    effect.abilityChecks.wisdom,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.nature,
                    effect.abilityChecks.intelligence,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.perception,
                    effect.abilityChecks.wisdom,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.performance,
                    effect.abilityChecks.charisma,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.persuasion,
                    effect.abilityChecks.charisma,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.religion,
                    effect.abilityChecks.intelligence,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.sleight_of_hand,
                    effect.abilityChecks.dexterity,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.stealth,
                    effect.abilityChecks.dexterity,
                    effect.name,
                    effect
                );
                applyRollModifiers(
                    resolvedCreature.skillChecks.survival,
                    effect.abilityChecks.wisdom,
                    effect.name,
                    effect
                );
            }
        }
    }

    if (effect.savingThrows) {
        if (effect.savingThrows.all) {
            applyRollModifiers(resolvedCreature.savingThrows.strength, effect.savingThrows.all, effect.name, effect);
            applyRollModifiers(resolvedCreature.savingThrows.dexterity, effect.savingThrows.all, effect.name, effect);
            applyRollModifiers(
                resolvedCreature.savingThrows.constitution,
                effect.savingThrows.all,
                effect.name,
                effect
            );
            applyRollModifiers(resolvedCreature.savingThrows.wisdom, effect.savingThrows.all, effect.name, effect);
            applyRollModifiers(
                resolvedCreature.savingThrows.intelligence,
                effect.savingThrows.all,
                effect.name,
                effect
            );
            applyRollModifiers(resolvedCreature.savingThrows.charisma, effect.savingThrows.all, effect.name, effect);

            if (isCharacter(resolvedCreature)) {
                applyRollModifiers(resolvedCreature.savingThrows.death, effect.savingThrows.all, effect.name, effect);
            }
        } else {
            applyRollModifiers(
                resolvedCreature.savingThrows.strength,
                effect.savingThrows.strength,
                effect.name,
                effect
            );
            applyRollModifiers(
                resolvedCreature.savingThrows.dexterity,
                effect.savingThrows.dexterity,
                effect.name,
                effect
            );
            applyRollModifiers(
                resolvedCreature.savingThrows.constitution,
                effect.savingThrows.constitution,
                effect.name,
                effect
            );
            applyRollModifiers(resolvedCreature.savingThrows.wisdom, effect.savingThrows.wisdom, effect.name, effect);
            applyRollModifiers(
                resolvedCreature.savingThrows.intelligence,
                effect.savingThrows.intelligence,
                effect.name,
                effect
            );
            applyRollModifiers(
                resolvedCreature.savingThrows.charisma,
                effect.savingThrows.charisma,
                effect.name,
                effect
            );
        }
    }

    if (effect.canTakeActions != null) {
        resolvedCreature.canTakeActions = effect.canTakeActions;
    }

    if (effect.canTakeReactions != null) {
        resolvedCreature.canTakeReactions = effect.canTakeReactions;
    }

    if (effect.canCastSpells != null) {
        resolvedCreature.canCastSpells = effect.canCastSpells;
    }

    // TODO: Should have something here so that you can tell where it came from?
    if (effect.resistances) {
        resolvedCreature.resistances = addToKeyedList(resolvedCreature.resistances, effect.resistances);
    }

    if (effect.immunities) {
        resolvedCreature.damageImmunities = addToKeyedList(resolvedCreature.damageImmunities, effect.immunities);
    }

    if (effect.vulnerabilities) {
        resolvedCreature.vulnerabilities = addToKeyedList(resolvedCreature.vulnerabilities, effect.vulnerabilities);
    }

    if (effect.transform && !ignoreTransforms) {
        transform = effect.transform;
    }

    if (effect.speedMultiplier != null) {
        if (isCharacter(resolvedCreature)) {
            for (let movementType of movementTypes) {
                const value = resolvedCreature.speed[movementType];
                if (value) {
                    if (!value.multipliers) {
                        value.multipliers = [];
                    }

                    value.multipliers.push({ name: effect.name, value: effect.speedMultiplier });
                }
            }
        } else {
            if (resolvedCreature.speed) {
                const speeds = keyedListToArray(resolvedCreature.speed);
                for (let speed of speeds) {
                    for (let movementType of movementTypes) {
                        const value = speed[movementType];
                        if (value) {
                            if (!value.multipliers) {
                                value.multipliers = [];
                            }

                            value.multipliers.push({ name: effect.name, value: effect.speedMultiplier });
                        }
                    }
                }
            }
        }
    }

    if (effect.maxHpMultiplier != null) {
        if (!resolvedCreature.maxHp.multipliers) {
            resolvedCreature.maxHp.multipliers = [];
        }

        resolvedCreature.maxHp.multipliers.push({ name: effect.name, value: effect.maxHpMultiplier });
    }

    if (effect.moveCost != null) {
        resolvedCreature.moveCost += effect.moveCost;
    }

    if (effect.ac != null) {
        if (isCharacter(resolvedCreature)) {
            resolvedCreature.ac.modifiers.push({ name: effect.name, value: effect.ac });
        } else {
            // TODO: How to apply HP bonuses to monsters? The resolved monster should have an ac property same as characters.
        }
    }

    if (effect.conditions) {
        if (effect.conditions.blinded) {
            applyConditionToResolvedCharacter(resolvedCreature, "blinded");
            extraEffects.push(
                Object.assign({}, blindedEffect, { key: "blinded", condition: rules.conditions.get("blinded") })
            );
        }

        if (effect.conditions.deafened) {
            applyConditionToResolvedCharacter(resolvedCreature, "deafened");
            extraEffects.push(
                Object.assign({}, deafenedEffect, { key: "deafened", condition: rules.conditions.get("deafened") })
            );
        }

        if (effect.conditions.frightened) {
            applyConditionToResolvedCharacter(resolvedCreature, "frightened");
            extraEffects.push(
                Object.assign({}, frightenedEffect, {
                    key: "frightened",
                    condition: rules.conditions.get("frightened"),
                })
            );
        }

        if (effect.conditions.grappled) {
            applyConditionToResolvedCharacter(resolvedCreature, "grappled");
            extraEffects.push(
                Object.assign({}, grappledEffect, { key: "grappled", condition: rules.conditions.get("grappled") })
            );
        }

        if (effect.conditions.incapacitated) {
            applyConditionToResolvedCharacter(resolvedCreature, "incapacitated");
            extraEffects.push(
                Object.assign({}, incapacitatedEffect, {
                    key: "incapacitated",
                    condition: rules.conditions.get("incapacitated"),
                })
            );
        }

        if (effect.conditions.invisible) {
            applyConditionToResolvedCharacter(resolvedCreature, "invisible");
            extraEffects.push(
                Object.assign({}, invisibleEffect, { key: "invisible", condition: rules.conditions.get("invisible") })
            );
        }

        if (effect.conditions.paralyzed) {
            applyConditionToResolvedCharacter(resolvedCreature, "incapacitated");
            applyConditionToResolvedCharacter(resolvedCreature, "paralyzed");
            extraEffects.push(
                Object.assign({}, incapacitatedEffect, {
                    key: "incapacitated",
                    condition: rules.conditions.get("incapacitated"),
                })
            );
            extraEffects.push(
                Object.assign({}, paralyzedEffect, { key: "paralyzed", condition: rules.conditions.get("paralyzed") })
            );
        }

        if (effect.conditions.petrified) {
            applyConditionToResolvedCharacter(resolvedCreature, "incapacitated");
            applyConditionToResolvedCharacter(resolvedCreature, "petrified");
            extraEffects.push(
                Object.assign({}, incapacitatedEffect, {
                    key: "incapacitated",
                    condition: rules.conditions.get("incapacitated"),
                })
            );
            extraEffects.push(
                Object.assign({}, petrifiedEffect, { key: "petrified", condition: rules.conditions.get("petrified") })
            );
        }

        if (effect.conditions.poisoned) {
            applyConditionToResolvedCharacter(resolvedCreature, "poisoned");
            extraEffects.push(
                Object.assign({}, poisonedEffect, { key: "poisoned", condition: rules.conditions.get("poisoned") })
            );
        }

        if (effect.conditions.prone) {
            applyConditionToResolvedCharacter(resolvedCreature, "prone");
            extraEffects.push(
                Object.assign({}, proneEffect, { key: "prone", condition: rules.conditions.get("prone") })
            );
        }

        if (effect.conditions.restrained) {
            applyConditionToResolvedCharacter(resolvedCreature, "restrained");
            extraEffects.push(
                Object.assign({}, restrainedEffect, {
                    key: "restrained",
                    condition: rules.conditions.get("restrained"),
                })
            );
        }

        if (effect.conditions.stunned) {
            applyConditionToResolvedCharacter(resolvedCreature, "incapacitated");
            applyConditionToResolvedCharacter(resolvedCreature, "stunned");
            extraEffects.push(
                Object.assign({}, incapacitatedEffect, {
                    key: "incapacitated",
                    condition: rules.conditions.get("incapacitated"),
                })
            );
            extraEffects.push(
                Object.assign({}, stunnedEffect, { key: "stunned", condition: rules.conditions.get("stunned") })
            );
        }

        if (effect.conditions.unconscious) {
            applyConditionToResolvedCharacter(resolvedCreature, "incapacitated");
            applyConditionToResolvedCharacter(resolvedCreature, "prone");
            applyConditionToResolvedCharacter(resolvedCreature, "unconscious");
            extraEffects.push(
                Object.assign({}, incapacitatedEffect, {
                    key: "incapacitated",
                    condition: rules.conditions.get("incapacitated"),
                })
            );
            extraEffects.push(
                Object.assign({}, proneEffect, { key: "prone", condition: rules.conditions.get("prone") })
            );
            extraEffects.push(
                Object.assign({}, unconsciousEffect, {
                    key: "unconscious",
                    condition: rules.conditions.get("unconscious"),
                })
            );
        }
    }

    return transform;
}

// The idea here was that when applying an effect that applied a condition (i.e. a Hold Person spell effect applies Paralyzed)
// that the effect would in turn flag on the condition on the character. Since we're already past the point of adding effects
// at this point (because we're applying them) this won't have any further effect.
function applyConditionToResolvedCharacter(
    resolvedCreature: ResolvedCharacter | ResolvedMonster,
    condition: CreatureConditionName
) {
    if (!resolvedCreature.conditions) {
        resolvedCreature.conditions = {};
    } else if (resolvedCreature.conditions === resolvedCreature.resolvedFrom.conditions) {
        resolvedCreature.conditions = Object.assign({}, resolvedCreature.conditions);
    }

    resolvedCreature.conditions[condition] = true;
}

function applyEffects(
    resolvedCreature: ResolvedCharacter | ResolvedMonster,
    campaign: Campaign,
    rules: CharacterRuleSet,
    ignoreTransforms: boolean | undefined
) {
    // Apply any effects that modify the character.
    let transform: CreatureTransformation | undefined;
    let transformEffectKey: string | undefined;
    if (resolvedCreature.effects) {
        const effectKeys = keyedListToKeyArray(resolvedCreature.effects);
        if (effectKeys) {
            let extraEffects: (AppliedAbilityEffect & {
                source?: string | undefined;
                feature?: Feature | undefined;
                ability?: Ability | undefined;
                spell?: Spell | undefined;
                condition?: CreatureCondition | undefined;
                key: string;
            })[] = [];
            for (let effectKey of effectKeys) {
                const effect = resolvedCreature.effects[effectKey];

                transform = applyEffect(effect, resolvedCreature, campaign, rules, ignoreTransforms, extraEffects);
                if (transform) {
                    transformEffectKey = effectKey;
                }
            }

            for (let i = 0; i < extraEffects.length; i++) {
                applyEffect(extraEffects[i], resolvedCreature, campaign, rules, true, extraEffects);
                resolvedCreature.effects[extraEffects[i].key] = extraEffects[i];
            }
        }
    }

    // The transformed creature should inherit all of our effects, but ignore any with transforms.
    // TODO: What if we want to transform to a custom creature? Test that.
    let transformedTo: Monster | undefined;
    if (transform) {
        // First look for a template within the campaign.
        const transformedTemplate = campaign.tokens[transform.templateId];
        if (isDnD5EMonsterTemplate(transformedTemplate)) {
            transformedTo = transformedTemplate.dnd5e;
        } else {
            // Not a template, probably referencing a base monster directly.
            if (transform.creature) {
                transformedTo = rules.monsters.get(transform.creature);
            } else {
                console.error("Invalid transform.");
                transform = undefined;
            }
        }
    }

    if (transformedTo) {
        const transformOverrides: TransformedMonsterOverrides = {};

        // We've been transformed to another creature - copy across any ability scores that aren't specifically excluded.
        if (transform?.coreAbilities?.strength) {
            transformOverrides.strength = resolvedCreature.strength;
        }

        if (transform?.coreAbilities?.dexterity) {
            transformOverrides.dexterity = resolvedCreature.dexterity;
        }

        if (transform?.coreAbilities?.constitution) {
            transformOverrides.constitution = resolvedCreature.constitution;
        }

        if (transform?.coreAbilities?.intelligence) {
            transformOverrides.intelligence = resolvedCreature.intelligence;
        }

        if (transform?.coreAbilities?.wisdom) {
            transformOverrides.wisdom = resolvedCreature.wisdom;
        }

        if (transform?.coreAbilities?.charisma) {
            transformOverrides.charisma = resolvedCreature.charisma;
        }

        if (transform?.skillProficiencies === "max") {
            transformOverrides.skillProficiencies = {};

            if (isCharacter(resolvedCreature)) {
                for (let skill in resolvedCreature.skillProficiencies) {
                    // TODO: Deliberately using === true so that if we support expertise by changing bool to multiplier, this will show up as an error.
                    transformOverrides.skillProficiencies[skill] =
                        resolvedCreature.skillProficiencies[skill] != null
                            ? resolvedCreature.proficiencyBonus * resolvedCreature.skillProficiencies[skill]
                            : undefined;
                }
            } else {
                // For monsters it's a bit tricky. The proficiency bonus is the specified bonus minus the normal bonus for the relevant ability.
                for (let skill in resolvedCreature.skillChecks) {
                    const totalBonus = resolvedCreature.skillChecks[skill];
                    const coreAbility = rules.skills[skill as Skill].modifier;
                    const abilityModifier = modifierFromAbilityScore(resolvedCreature.resolvedFrom[coreAbility]);
                    transformOverrides.skillProficiencies[skill] = totalBonus - abilityModifier;
                }
            }
        }

        if (transform?.savingThrowProficiencies === "max") {
            transformOverrides.savingThrowProficiencies = {};

            if (isCharacter(resolvedCreature)) {
                for (let ability in resolvedCreature.savingThrowProficiencies) {
                    // TODO: Deliberately using === true so that if we support expertise by changing bool to multiplier, this will show up as an error.
                    transformOverrides.savingThrowProficiencies[ability as CoreAbility] =
                        resolvedCreature.savingThrowProficiencies[ability] === true
                            ? resolvedCreature.proficiencyBonus
                            : undefined;
                }
            } else {
                for (let ability in resolvedCreature.savingThrows) {
                    const totalBonus = resolvedCreature.savingThrows[ability];
                    const abilityModifier = modifierFromAbilityScore(resolvedCreature.resolvedFrom[ability]);
                    transformOverrides.savingThrowProficiencies[ability as CoreAbility] = totalBonus - abilityModifier;
                }
            }
        }

        const resolvedTransformedTo = transformedTo
            ? (resolveCreature(
                  Object.assign({}, transformedTo, transform!.creature, {
                      effects: resolvedCreature.effects,
                      conditions: resolvedCreature.conditions,
                      concentrating: resolvedCreature.concentrating,
                  }),
                  campaign,
                  rules,
                  transformOverrides
              ) as ResolvedMonster)
            : undefined;
        if (resolvedTransformedTo != null) {
            // Need to keep the keys consistent every time we resolve the abilities, and we need to make sure that the
            // abilities on the transformed creature never conflict with the ones from the base creature.
            // We need to change the keys so that they won't conflict with the resolved creature's abilities.
            if (resolvedTransformedTo.abilities) {
                const abilities = {};
                for (let existingKey in resolvedTransformedTo.abilities) {
                    abilities["~" + existingKey] = resolvedTransformedTo.abilities[existingKey];
                }

                if (transform?.abilities) {
                    resolvedTransformedTo.abilities = Object.assign(abilities, resolvedCreature.abilities);
                } else {
                    resolvedTransformedTo.abilities = abilities;
                }
            } else if (transform?.abilities) {
                resolvedTransformedTo.abilities = resolvedCreature.abilities;
            }

            // If the transform is meant to preserve the original creature's HP, copy that over.
            if (transform?.hp) {
                resolvedTransformedTo.hp = resolvedCreature.hp;
                resolvedTransformedTo.maxHp = resolvedCreature.maxHp;
                resolvedTransformedTo.tempHp = resolvedCreature.tempHp;
            }

            resolvedTransformedTo.combatTurn = resolvedCreature.combatTurn;
            resolvedTransformedTo.transformedFrom = resolvedCreature;
            resolvedCreature.transformedInto = Object.assign({}, resolvedTransformedTo, {
                byEffect: transformEffectKey!,
                transform: transform!,
            });
        }
    }
}

function addProficiency(
    multiplier: number | undefined,
    proficiency: number,
    modifiedValue: ModifiedValue,
    defaultProficiencyModifier: ValueModifier
) {
    if (multiplier != null) {
        addNonZeroModifier(
            modifiedValue,
            multiplier === 1
                ? defaultProficiencyModifier
                : {
                      name: "Proficiency",
                      value: Math.floor(proficiency * multiplier),
                  }
        );
    }
}

export function resolveCharacter(
    character: Character,
    campaign: Campaign,
    rules: CharacterRuleSet,
    ignoreTransforms?: boolean
) {
    let errors: string[] | undefined;

    let race: ResolvedRace | undefined;
    if (character.race) {
        race = resolveRace(character.race, character.subrace, rules);

        if (!race) {
            errors = errors ?? [];
            errors.push(`Unknown race ${character.race}.`);
        }
    }

    let spellcasterLevel = 0;
    let totalLevel = 0;
    const characterClassesByName: { [id: string]: ResolvedClassLevels } = {};
    const characterClasses: ResolvedClassLevels[] = [];
    if (character.classes) {
        var classes = Object.values(character.classes);
        for (let o of classes) {
            totalLevel += o.level;
            const c = rules.classes.get(o.class);
            if (c) {
                const classData = resolveClass(c, o.subclass);
                spellcasterLevel += Math.floor(o.level * (classData.spellcastingProgression ?? 0));
                const rc: ResolvedClassLevels = {
                    resolvedFrom: o,
                    classData: classData,
                    level: o.level,
                    isInitial: !!o.isInitial,
                    hpPerLevel: o.hpPerLevel,
                    skillProficiencies: o.skillProficiencies,
                    tools: o.tools,
                    choices: o.choices,
                    hitDiceSpent: o.hitDiceSpent ?? 0,
                };

                if (classData.cantripProgression) {
                    rc.maxCantripsKnown =
                        classData.cantripProgression[Math.min(o.level, classData.cantripProgression.length) - 1];
                }

                if (o.preparedSpells) {
                    rc.preparedSpells = [];
                    for (let i = 0; i < o.preparedSpells.length; i++) {
                        const preparedSpell = rules.spells.get(o.preparedSpells[i]);

                        // Don't include cantrips - they shouldn't be able to be included, but check anyway just in case.
                        if (preparedSpell && preparedSpell.level > 0) {
                            rc.preparedSpells.push(preparedSpell);
                        }
                    }

                    rc.currentSpellsPrepared = rc.preparedSpells.length;
                }

                if (o.knownSpells) {
                    rc.knownSpells = [];
                    let cantripsKnown = 0;
                    for (let i = 0; i < o.knownSpells.length; i++) {
                        const knownSpell = rules.spells.get(o.knownSpells[i]);
                        if (knownSpell) {
                            rc.knownSpells.push(knownSpell);

                            if (knownSpell.level === 0) {
                                cantripsKnown++;
                            }
                        }
                    }

                    rc.currentCantripsKnown = cantripsKnown;
                    rc.currentSpellsKnown = rc.knownSpells.length - rc.currentCantripsKnown;
                }

                if (c.additionalSpellSlots) {
                    rc.additionalSpellSlots = {
                        name: c.additionalSpellSlots.name,
                        spellSlots: c.additionalSpellSlots.spellSlots[o.level],
                        reset: c.additionalSpellSlots.reset,
                    };
                }

                characterClasses.push(rc);
                characterClassesByName[c.name] = rc;
            } else {
                errors = errors ?? [];
                errors.push(`Unknown class ${o.class.name} (${o.class.source}).`);
            }
        }
    }

    const proficiencyBonus = proficiencyBonusForLevel(totalLevel);

    const skillChecks: Required<Skills<ModifiedValue>> = {
        acrobatics: modifiedValue(0),
        animal_handling: modifiedValue(0),
        arcana: modifiedValue(0),
        athletics: modifiedValue(0),
        deception: modifiedValue(0),
        history: modifiedValue(0),
        insight: modifiedValue(0),
        intimidation: modifiedValue(0),
        investigation: modifiedValue(0),
        medicine: modifiedValue(0),
        nature: modifiedValue(0),
        perception: modifiedValue(0),
        performance: modifiedValue(0),
        persuasion: modifiedValue(0),
        religion: modifiedValue(0),
        sleight_of_hand: modifiedValue(0),
        stealth: modifiedValue(0),
        survival: modifiedValue(0),
    };

    // Create the base resolved character from the base character info. We can then add to it by
    // resolving everything from race/class/items/etc.
    const attunedItems: ResolvedInventoryItem[] = [];
    const inventory = character.inventory ? resolveInventory(character.inventory, rules.items, attunedItems) : {};
    const currency = { total: 0 };
    if (character.currency) {
        for (let abbr in character.currency) {
            const currencyType = CURRENCY_TYPES_BY_ABBR.get(abbr);
            const currencyValue = character.currency[abbr];
            if (currencyType && currencyValue) {
                currency.total += currencyType.value * currencyValue;
                currency[abbr] = currencyValue;
            }
        }
    }

    // TODO: Don't merge these here - make them available separately.
    // Merge any additional spell slots from classes (i.e. Warlock pact magic) into the main spell slots.
    const baseSpellSlots = spellcasterLevel > 0 ? spellSlots[spellcasterLevel - 1] : [];
    const finalSpellSlots: (number[] | NamedSpellSlots)[] = [baseSpellSlots];
    if (characterClasses) {
        for (let i = 0; i < characterClasses.length; i++) {
            const additionalSpellSlots = characterClasses[i].additionalSpellSlots;
            if (additionalSpellSlots) {
                finalSpellSlots.push(additionalSpellSlots);
            }
        }
    }

    const concentratingOn = character.concentrating ? rules.spells.get(character.concentrating) : undefined;

    const strength = modifiedValue(Math.min(Math.max(character.strength ?? 10, 3), 18)) as ModifiedValue & {
        modifier: number;
    };
    const dexterity = modifiedValue(Math.min(Math.max(character.dexterity ?? 10, 3), 18)) as ModifiedValue & {
        modifier: number;
    };
    const constitution = modifiedValue(Math.min(Math.max(character.constitution ?? 10, 3), 18)) as ModifiedValue & {
        modifier: number;
    };
    const intelligence = modifiedValue(Math.min(Math.max(character.intelligence ?? 10, 3), 18)) as ModifiedValue & {
        modifier: number;
    };
    const wisdom = modifiedValue(Math.min(Math.max(character.wisdom ?? 10, 3), 18)) as ModifiedValue & {
        modifier: number;
    };
    const charisma = modifiedValue(Math.min(Math.max(character.charisma ?? 10, 3), 18)) as ModifiedValue & {
        modifier: number;
    };

    const resolvedCharacter: ResolvedCharacter = {
        resolvedFrom: character,
        name: character.name ?? "",
        type: ActorType.Character,
        level: totalLevel,
        hp: character.hp,
        tempHp: character.tempHp,
        maxHp: modifiedValue(0),
        proficiencyBonus: proficiencyBonus,
        strength: strength,
        dexterity: dexterity,
        constitution: constitution,
        intelligence: intelligence,
        wisdom: wisdom,
        charisma: charisma,
        initiativeBonus: modifiedValue(0),
        skillChecks: skillChecks,
        skillProficiencies: Object.assign({}, character.skillProficiencies),
        armorProficiencies: {},
        weaponProficiencies: {},
        toolProficiencies: {},
        ac: modifiedValue(10),
        conditionImmunities: {},
        conditions: character.conditions,
        race: race,
        savingThrowProficiencies: Object.assign({}, character.savingThrowProficiencies),
        speed: {
            walk: modifiedValue(character.speed?.walk ?? 0),
            fly: modifiedValue(character.speed?.fly ?? 0),
            swim: modifiedValue(character.speed?.swim ?? 0),
            burrow: modifiedValue(character.speed?.burrow ?? 0),
            climb: modifiedValue(character.speed?.climb ?? 0),
        },
        passive: {
            perception: modifiedValue(10),
            investigation: modifiedValue(10),
            insight: modifiedValue(10),
        },
        senses: {
            blindsight: modifiedValue(0, "max"),
            darkvision: modifiedValue(0, "max"),
            tremorsense: modifiedValue(0, "max"),
            truesight: modifiedValue(0, "max"),
        },
        racialTraits: [],
        raceChoices: character.raceChoices ?? {},
        classes: characterClassesByName,
        classFeatures: [],
        allFeatures: [],
        additionalSpells: [],
        savingThrows: {
            strength: modifiedValue(0),
            dexterity: modifiedValue(0),
            constitution: modifiedValue(0),
            intelligence: modifiedValue(0),
            wisdom: modifiedValue(0),
            charisma: modifiedValue(0),
            death: modifiedValue(0),
        },
        attacks: 1,
        languages: [],
        spellcasterLevel: spellcasterLevel,
        spellSlots: finalSpellSlots,
        usedSpellSlots: character.usedSpellSlots,
        inventory: inventory,
        attunedItems: attunedItems,
        currency: currency,
        concentrating: concentratingOn
            ? Object.assign({}, concentratingOn, {
                  instanceId: character.concentrating!.instanceId,
                  annotation: character.concentrating?.annotation,
                  location: character.concentrating?.location,
              })
            : undefined,
        exhaustion: character.exhaustion,
        combatTurn: character.combatTurn,
        abilityUsage: character.abilityUsage,
        dying: character.dying,
        isDead: !character.dying?.stable && (character.dying?.failure ?? 0) >= 3,
        canTakeActions: true,
        canTakeReactions: true,
        canCastSpells: true,
        itemModifiers: [],
        equippedItems: [],
        moveCost: 0,
        alignment: character.alignment,
        appearance: character.appearance,
    };

    const conditionalFeatures: (() => void)[] = [];

    // Add any modifiers from race/class/items
    if (race) {
        applyRace(resolvedCharacter, character, conditionalFeatures, rules);
    }

    if (characterClasses) {
        for (let i = 0; i < characterClasses.length; i++) {
            applyClass(resolvedCharacter, character, conditionalFeatures, characterClasses[i], rules);
        }
    }

    let resolvedEffects = resolveEffectList(resolvedCharacter, character.effects, rules);
    resolvedCharacter.effects = resolvedEffects;

    applyConditions(resolvedCharacter, rules);

    applyEffects(resolvedCharacter, campaign, rules, ignoreTransforms);

    const abilityMods: CoreAbilities<ValueModifier> = {
        strength: {
            name: "Strength",
            value: modifierFromAbilityScore(resolveModifiedValue(resolvedCharacter.strength)),
        },
        dexterity: {
            name: "Dexterity",
            value: modifierFromAbilityScore(resolveModifiedValue(resolvedCharacter.dexterity)),
        },
        constitution: {
            name: "Constitution",
            value: modifierFromAbilityScore(resolveModifiedValue(resolvedCharacter.constitution)),
        },
        intelligence: {
            name: "Intelligence",
            value: modifierFromAbilityScore(resolveModifiedValue(resolvedCharacter.intelligence)),
        },
        wisdom: { name: "Wisdom", value: modifierFromAbilityScore(resolveModifiedValue(resolvedCharacter.wisdom)) },
        charisma: {
            name: "Charisma",
            value: modifierFromAbilityScore(resolveModifiedValue(resolvedCharacter.charisma)),
        },
    };

    if (resolvedCharacter.inventory) {
        const keys = Object.keys(resolvedCharacter.inventory);
        for (let i = 0; i < keys.length; i++) {
            const item = resolvedCharacter.inventory[keys[i]];
            if (item.active) {
                resolvedCharacter.equippedItems.push(item);

                // If the item does not require attunement, or it is attuned, then it is eligible to apply bonuses.
                if (!item.requiresAttunement || item.attuned) {
                    if (item.acModifier != null) {
                        addNonZeroModifier(resolvedCharacter.ac, { name: item.name, value: item.acModifier });
                    }
                }

                if (isInventoryArmor(item) && !resolvedCharacter.armor) {
                    resolvedCharacter.armor = item;
                    addNonZeroModifier(resolvedCharacter.ac, {
                        name: item.name,
                        value: item.ac - resolvedCharacter.ac.baseValue,
                    });

                    // TODO: If the Armor table shows "Str 13" or "Str 15" in the Strength column for an armor type, the armor reduces the wearer's speed by 10 feet unless the wearer has a Strength score equal to or higher than the listed score.
                    // However, the strength bonus hasn't been calculated yet. Maybe we need to delay this until after that? Can armor change the strength score at all?
                    // We can just move this move speed penalty to the end.
                } else if (isShield(item) && !resolvedCharacter.shield) {
                    resolvedCharacter.shield = item;
                    addNonZeroModifier(resolvedCharacter.ac, { name: item.name, value: item.ac });
                }

                if (!item.requiresAttunement || item.attuned) {
                    // TODO: Other item bonuses.
                }
            }
        }
    }

    // Non-heavy armour allows a dex modifier to be applied to AC.
    if (!resolvedCharacter.armor || resolvedCharacter.armor.type !== ItemType.HeavyArmor) {
        if (
            resolvedCharacter.armor &&
            resolvedCharacter.armor.type === ItemType.MediumArmor &&
            abilityMods.dexterity.value > 2
        ) {
            // Medium armour has a max dex bonus of +2.
            addNonZeroModifier(resolvedCharacter.ac, { name: abilityMods.dexterity.name, value: 2 });
        } else {
            addNonZeroModifier(resolvedCharacter.ac, abilityMods.dexterity);
        }
    }

    if (abilityMods.constitution.value > 0) {
        resolvedCharacter.maxHp.modifiers.push({
            name: abilityMods.constitution.name,
            value: totalLevel * abilityMods.constitution.value,
        });
    }

    if (resolvedCharacter.hp != null) {
        const maxHp = resolveModifiedValue(resolvedCharacter.maxHp);
        resolvedCharacter.hp = Math.min(maxHp, resolvedCharacter.hp);
    }

    const proficiencyModifier = { name: "Proficiency", value: proficiencyBonus };

    addNonZeroModifier(resolvedCharacter.passive.perception, abilityMods.wisdom);
    addProficiency(
        resolvedCharacter.skillProficiencies.perception,
        proficiencyBonus,
        resolvedCharacter.passive.perception,
        proficiencyModifier
    );

    addNonZeroModifier(resolvedCharacter.passive.insight, abilityMods.wisdom);
    addProficiency(
        resolvedCharacter.skillProficiencies.insight,
        proficiencyBonus,
        resolvedCharacter.passive.insight,
        proficiencyModifier
    );

    addNonZeroModifier(resolvedCharacter.passive.investigation, abilityMods.intelligence);
    addProficiency(
        resolvedCharacter.skillProficiencies.investigation,
        proficiencyBonus,
        resolvedCharacter.passive.investigation,
        proficiencyModifier
    );

    addNonZeroModifier(resolvedCharacter.savingThrows.strength, abilityMods.strength);
    addNonZeroModifier(resolvedCharacter.savingThrows.dexterity, abilityMods.dexterity);
    addNonZeroModifier(resolvedCharacter.savingThrows.constitution, abilityMods.constitution);
    addNonZeroModifier(resolvedCharacter.savingThrows.intelligence, abilityMods.intelligence);
    addNonZeroModifier(resolvedCharacter.savingThrows.wisdom, abilityMods.wisdom);
    addNonZeroModifier(resolvedCharacter.savingThrows.charisma, abilityMods.charisma);

    if (resolvedCharacter.savingThrowProficiencies.strength) {
        resolvedCharacter.savingThrows.strength.modifiers.push(proficiencyModifier);
    }

    if (resolvedCharacter.savingThrowProficiencies.dexterity) {
        resolvedCharacter.savingThrows.dexterity.modifiers.push(proficiencyModifier);
    }

    if (resolvedCharacter.savingThrowProficiencies.constitution) {
        resolvedCharacter.savingThrows.constitution.modifiers.push(proficiencyModifier);
    }

    if (resolvedCharacter.savingThrowProficiencies.intelligence) {
        resolvedCharacter.savingThrows.intelligence.modifiers.push(proficiencyModifier);
    }

    if (resolvedCharacter.savingThrowProficiencies.wisdom) {
        resolvedCharacter.savingThrows.wisdom.modifiers.push(proficiencyModifier);
    }

    if (resolvedCharacter.savingThrowProficiencies.charisma) {
        resolvedCharacter.savingThrows.charisma.modifiers.push(proficiencyModifier);
    }

    addProficiency(
        resolvedCharacter.skillProficiencies.acrobatics,
        proficiencyBonus,
        skillChecks.acrobatics,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.animal_handling,
        proficiencyBonus,
        skillChecks.animal_handling,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.arcana,
        proficiencyBonus,
        skillChecks.arcana,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.athletics,
        proficiencyBonus,
        skillChecks.athletics,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.deception,
        proficiencyBonus,
        skillChecks.deception,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.history,
        proficiencyBonus,
        skillChecks.history,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.insight,
        proficiencyBonus,
        skillChecks.insight,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.intimidation,
        proficiencyBonus,
        skillChecks.intimidation,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.investigation,
        proficiencyBonus,
        skillChecks.investigation,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.medicine,
        proficiencyBonus,
        skillChecks.medicine,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.nature,
        proficiencyBonus,
        skillChecks.nature,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.perception,
        proficiencyBonus,
        skillChecks.perception,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.performance,
        proficiencyBonus,
        skillChecks.performance,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.persuasion,
        proficiencyBonus,
        skillChecks.persuasion,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.religion,
        proficiencyBonus,
        skillChecks.religion,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.sleight_of_hand,
        proficiencyBonus,
        skillChecks.sleight_of_hand,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.stealth,
        proficiencyBonus,
        skillChecks.stealth,
        proficiencyModifier
    );
    addProficiency(
        resolvedCharacter.skillProficiencies.survival,
        proficiencyBonus,
        skillChecks.survival,
        proficiencyModifier
    );

    if (character.background) {
        const background = rules.backgrounds.get(character.background);
        if (background) {
            resolvedCharacter.background = resolveBackground(background, rules);
            if (resolvedCharacter.background) {
                resolveFeature(
                    resolvedCharacter,
                    character,
                    undefined,
                    conditionalFeatures,
                    undefined,
                    character.background.name,
                    resolvedCharacter.background,
                    character.background,
                    rules
                );
            }
        }
    }

    for (let c of characterClasses) {
        if (c.classData.spellcastingAbility) {
            let spellMod: ValueModifier;
            switch (c.classData.spellcastingAbility) {
                case "strength":
                    spellMod = abilityMods.strength;
                    break;
                case "dexterity":
                    spellMod = abilityMods.dexterity;
                    break;
                case "constitution":
                    spellMod = abilityMods.constitution;
                    break;
                case "intelligence":
                    spellMod = abilityMods.intelligence;
                    break;
                case "wisdom":
                    spellMod = abilityMods.wisdom;
                    break;
                case "charisma":
                    spellMod = abilityMods.charisma;
                    break;
            }

            c.spellSaveDC = modifiedValue(8);
            c.spellSaveDC.modifiers.push(proficiencyModifier);
            addNonZeroModifier(c.spellSaveDC, spellMod);

            c.spellAttackModifier = modifiedValue(0);
            c.spellAttackModifier.modifiers.push(proficiencyModifier);
            addNonZeroModifier(c.spellAttackModifier, spellMod);

            if (c.classData.spellsKnownProgression == null || c.classData.spellsKnownProgression === "all") {
                // The class doesn't have known spells progression, which means that it must prepare spells from either
                // a spellbook (e.g. wizard) or a full list of spells (e.g. cleric).
                c.maxSpellsPrepared = Math.max(1, spellMod.value + c.level);
                if (c.preparedSpells && c.maxSpellsPrepared < c.preparedSpells.length) {
                    c.preparedSpells = c.preparedSpells.slice(0, c.maxSpellsPrepared);
                }

                // Cantrips are always prepared.
                if (c.knownSpells) {
                    for (let i = 0; i < c.knownSpells.length; i++) {
                        if (c.knownSpells[i].level === 0) {
                            if (c.preparedSpells == null) {
                                c.preparedSpells = [c.knownSpells[i]];
                            } else {
                                c.preparedSpells.push(c.knownSpells[i]);
                            }
                        }
                    }
                }
            }

            if (c.knownSpells) {
                c.knownSpells.sort(sortSpells);
                if (c.preparedSpells) {
                    c.preparedSpells.sort(sortSpells);
                }
            }
        }
    }

    // Evaluate conditional features. These are features that use expressions, they are delayed so that
    // the expressions are as accurate as possible.
    // Update the ability modifiers - these are often used in expressions.
    strength.modifier = abilityMods.strength.value;
    dexterity.modifier = abilityMods.dexterity.value;
    constitution.modifier = abilityMods.constitution.value;
    intelligence.modifier = abilityMods.intelligence.value;
    wisdom.modifier = abilityMods.wisdom.value;
    charisma.modifier = abilityMods.charisma.value;
    for (let i = 0; i < conditionalFeatures.length; i++) {
        conditionalFeatures[i]();
    }

    // Update any resources that are based on ability mods.
    if (resolvedCharacter.resources) {
        const resources = Object.values(resolvedCharacter.resources);
        for (let resource of resources) {
            if (resource.maxFrom && resource.max === 0) {
                // Resource wasn't found by lookup or increased by features - might be a core ability lookup.
                const mod = abilityMods[resource.maxFrom as CoreAbility]?.value;
                if (mod != null) {
                    resource.max = mod;
                }
            } else if (resource.maxExpr) {
                resource.max = evaluateCharacterExpression(resolvedCharacter, resource.maxExpr);
            }
        }
    }

    // Calculate weapon bonuses now that everything else is resolved.
    const items = Object.values(resolvedCharacter.inventory);
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (isInventoryWeapon(item)) {
            // This calls applyItemModifiers internally.
            applyWeaponModifiers(item, item, rules, resolvedCharacter, abilityMods, proficiencyModifier);
        } else {
            applyItemModifiers(item, resolvedCharacter, rules);
        }
    }

    for (let skill in skillChecks) {
        const ability = rules.skills[skill as Skill].modifier;
        addNonZeroModifier(skillChecks[skill], abilityMods[ability]);

        // Copy any adv/dis/fail from the appropriate ability modifier.
        const abilityValue = resolvedCharacter[ability];
        const skillValue = skillChecks[skill as Skill];
        skillValue.advantage = abilityValue.advantage;
        skillValue.disadvantage = abilityValue.disadvantage;
        skillValue.fail = abilityValue.fail;
    }

    addNonZeroModifier(resolvedCharacter.initiativeBonus, abilityMods.dexterity);

    return resolvedCharacter;
}

export function getUnarmedWeapon(
    character: ResolvedCharacter,
    rules: CharacterRuleSet,
    omitDamageAbilityModifier?: boolean
) {
    const unarmedWeapon: ResolvedInventoryWeapon & ResolvedInventoryItem = {
        id: "_",
        acquired: 0,
        name: "Unarmed Strike",
        dmgType: "bludgeoning",
        rarity: "none",
        source: "PHB",
        type: ItemType.Melee,
        weaponType: "simple",
        dmg1h: "1",
        hitBonus: modifiedValue(0),
        damageBonus: modifiedValue(0),
        ability: "strength",
        resolvedFrom: { name: "Unarmed Strike" },
    };

    applyItemModifiers(unarmedWeapon, character, rules);
    applyWeaponModifiers(
        unarmedWeapon,
        { damageBonus: unarmedWeapon.damageBonus, hitBonus: unarmedWeapon.hitBonus },
        rules,
        character,
        undefined,
        undefined,
        omitDamageAbilityModifier
    );

    return unarmedWeapon;
}

export function canPayResourceCosts(
    character: ResolvedCharacter,
    resourceCosts: { [resource: string]: number } | undefined
) {
    if (resourceCosts) {
        for (let resource in resourceCosts) {
            if (!canPayResourceCost(character, resource, resourceCosts[resource])) {
                return false;
            }
        }
    }

    return true;
}

export function canPayResourceCost(character: ResolvedCharacter, resource: string, amount: number) {
    const usage = character.resources?.[resource];
    return !!usage && usage.used + amount <= usage.max;
}

function abilityMatchesMultiAttackOption(option: MonsterMultiattackPart, ability: MonsterAbility) {
    if (option.type != null && ability.attack != null) {
        if (option.type.indexOf(ability.attack) >= 0 || ability.attack.indexOf(option.type) >= 0) {
            return true;
        }
    }

    if (option.abilities && option.abilities.indexOf(ability.name) >= 0) {
        return true;
    }

    return false;
}

export function getMultiattackOption(
    creature: ResolvedMonster,
    ability: Ability
):
    | {
          ability: MonsterAbility;
          optionsKeys: string[];
          options: KeyedList<MonsterMultiattackPart>;
          usedByOption: { [optionKey: string]: number };
          option?: MonsterMultiattackPart;
          amount?: number;
          used?: number;
      }
    | undefined {
    if (!creature.combatTurn?.attackAbility || !creature.combatTurn.attackOption) {
        return undefined;
    }

    // Check if this ability can be used as part of a monster multiattack action, then allow it.
    const attackAbility = creature.abilities?.[creature.combatTurn.attackAbility];
    if (!attackAbility) {
        return undefined;
    }

    const attackOptions = attackAbility.attackOptions?.[creature.combatTurn.attackOption];
    if (!attackOptions) {
        return undefined;
    }

    // Ok, got the option that was used for this multiattack. Now check whether the ability qualifies
    // for an unused portion of it. For that we need to first work out the used portion of it.
    const optionsKeys = keyedListToKeyArray(attackOptions);
    let usedByOption: {
        [optionKey: string]: number;
    } = {};

    // Work out what options have already been filled by assigning all of the attacks that have
    // already been made.
    for (let key in creature.combatTurn.attacks) {
        const usedAbility = creature.abilities?.[key];
        if (usedAbility) {
            for (let i = 0; i < optionsKeys.length; i++) {
                const option = attackOptions[optionsKeys[i]];
                if (abilityMatchesMultiAttackOption(option, usedAbility)) {
                    const u = usedByOption[optionsKeys[i]] ?? 0;
                    usedByOption[optionsKeys[i]] = u + creature.combatTurn.attacks[key];
                }
            }
        }
    }

    let finalOption: MonsterMultiattackPart | undefined;
    let finalAmount: number | undefined;
    let finalUsed: number | undefined;

    for (let i = 0; i < optionsKeys.length; i++) {
        const option = attackOptions[optionsKeys[i]];
        const amount = option.amount ?? 1;
        const used = usedByOption[optionsKeys[i]] ?? 0;
        if (amount > used) {
            // This option has available capacity. If it matches our current ability, we're good to go!
            if (abilityMatchesMultiAttackOption(option, ability)) {
                finalOption = option;
                finalAmount = amount;
                finalUsed = used;
                break;
            }
        }
    }

    return {
        ability: attackAbility,
        optionsKeys: optionsKeys,
        options: attackOptions,
        usedByOption: usedByOption,
        option: finalOption,
        amount: finalAmount,
        used: finalUsed,
    };
}

export function canUseCombatAbility(
    location: LocationSummary | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    creature: ResolvedCharacter | ResolvedMonster,
    ability: Ability,
    abilityKey: string
) {
    if (isTokenTemplate(token)) {
        return false;
    }

    // Can use any combat resource outside of combat.
    if (!isLocation(location) || !location.combat?.participants?.[token.id]) {
        // Still can't do it if you can't take actions.
        if (ability.time?.unit === "reaction" && !creature.canTakeReactions) {
            return false;
        } else if (ability.time?.unit !== "reaction") {
            return (
                creature.canTakeActions &&
                (!isCharacter(creature) || canPayResourceCosts(creature, ability.resourceCost))
            );
        }

        return creature.canTakeActions;
    }

    if (ability.time?.unit !== "legendary" && (ability.time?.amount ?? 0) > 1) {
        return false;
    }

    const maxUses = evaluateCreatureExpression(creature, ability.maxUsesExpr, ability.maxUses);
    if (maxUses != null) {
        const used = creature.abilityUsage?.[abilityKey]?.used ?? 0;
        if (used >= maxUses) {
            return false;
        }
    }

    if (isCharacter(creature)) {
        if (!canPayResourceCosts(creature, ability.resourceCost)) {
            return false;
        }

        // If this is part of an attack action, disallow it if all the attacks have already been made.
        // Otherwise, if attacks have been made and there are still some left, allow the attack disregarding
        // combat resources.
        if (ability.isAttackAction) {
            const attacksMade = creature.combatTurn?.attacks
                ? Object.values(creature.combatTurn.attacks).reduce((p, c) => p + c, 0)
                : 0;
            const attacksRemaining = creature.attacks - attacksMade;
            if (attacksRemaining === 0) {
                return false;
            } else if (creature.combatTurn?.attacks != null) {
                return true;
            }
        }
    } else {
        if (ability.isAttackAction) {
            // If this is an attack action ability, but no multiattack action has been chosen yet, don't allow it.
            // This forces the user to choose the multiattack option first - this way they have all the information.
            // Maybe revisit in the future to just find the first attack option that matches a chosen ability and
            // start it, but sometimes there are multiple multiattack options that match...
            if (!creature.combatTurn?.attackAbility || !creature.combatTurn.attackOption) {
                return false;
            }

            const multiattack = getMultiattackOption(creature, ability);
            if (multiattack) {
                return multiattack.option != null;
            }
        }

        // If it's recharging, you can't use it.
        if (creature.abilities?.[abilityKey]?.isRecharging) {
            return false;
        }
    }

    if (ability.time == null) {
        return creature.canTakeActions;
    }

    return canPayCombatTimeCost(creature, ability.time);
}

/**
 * Determines if the specified creature can afford to pay the specified time cost.
 * @param creature The creature to test.
 * @param time The time cost the creature needs to pay.
 */
export function canPayCombatTimeCost(creature: ResolvedMonster | ResolvedCharacter, time: AbilityTime) {
    switch (time.unit) {
        case "action":
            return creature.canTakeActions && !creature.combatTurn?.action;
        case "bonus":
            return creature.canTakeActions && !creature.combatTurn?.bonus;
        case "reaction":
            return creature.canTakeReactions && !creature.combatTurn?.reaction;
        case "legendary":
            if (!creature.canTakeActions) {
                return false;
            }

            if (isMonster(creature) && creature.legendaryActions != null) {
                const legendaryUsed = creature.combatTurn?.legendary ?? 0;
                return legendaryUsed + time.amount <= creature.legendaryActions;
            }

            break;
    }

    // If in doubt, allow it - the player/GM can make up their own minds.
    return creature.canTakeActions;
}

export function effectMatches(
    effect: AppliedAbilityEffect | ApplicableAbilityEffect,
    nameOrKey: string,
    rules: CharacterRuleSet
) {
    if (getRuleKey(effect) === nameOrKey) {
        return true;
    }

    let ext = effect.extends;
    if (!ext && isNamedRuleRef(effect)) {
        ext = rules.effects.get(effect)?.extends;
    }

    if (ext) {
        const extendedEffect = rules.effects.get(ext);
        if (extendedEffect && effectMatches(extendedEffect, nameOrKey, rules)) {
            return true;
        }
    }

    return effect.name === nameOrKey;
}

function hasEffect(creature: ResolvedCharacter | ResolvedMonster, effect: string, rules: CharacterRuleSet) {
    if (!creature.effects) {
        return false;
    }

    const effects = keyedListToArray(creature.effects);
    return effects.some(o => effectMatches(o, effect, rules));
}

export function getResolvedMovementSpeeds(
    creature: ResolvedCharacter | ResolvedMonster | undefined
): Partial<MovementSpeeds<number>> | undefined {
    if (!creature) {
        return undefined;
    }

    const movementSpeeds = getMovementSpeeds(creature);
    return movementSpeeds ? resolveMovementSpeeds(movementSpeeds) : undefined;
}

export function resolveMovementSpeeds(movementSpeeds: Partial<MovementSpeeds<ModifiedValue>>) {
    const resolvedMovementSpeeds: Partial<MovementSpeeds<number>> = {};
    for (let speed in movementSpeeds) {
        const movementType = speed as MovementType;
        if (movementSpeeds[movementType]) {
            const modifiedValue = movementSpeeds[movementType]!;
            const v = resolveModifiedValue(modifiedValue);
            if (modifiedValue.baseValue > 0 || resolveModifiedValue(modifiedValue, true) > 0) {
                resolvedMovementSpeeds[movementType] = v;
            }
        }
    }

    return resolvedMovementSpeeds;
}

export function getMovementSpeeds(
    creature: ResolvedCharacter | ResolvedMonster | undefined
): Partial<MovementSpeeds<ModifiedValue>> | undefined {
    if (!creature) {
        return undefined;
    }

    let movementSpeeds: Partial<MovementSpeeds<ModifiedValue>> | undefined;
    creature = creature.transformedInto ?? creature;
    movementSpeeds = creature.speed;

    return movementSpeeds;
}

export function abilityMeetsCondition(
    creature: ResolvedCharacter | ResolvedMonster,
    ability: Ability,
    rules: CharacterRuleSet
) {
    if (!ability.condition && !ability.weaponAttack) {
        return true;
    }

    let meetsCondition = true;
    if (meetsCondition && ability.condition?.hasEffect) {
        // The condition is met if the creature has the specified effect applied.
        meetsCondition = hasEffect(creature, ability.condition.hasEffect, rules);
    }

    if (meetsCondition && ability.condition?.notHasEffect) {
        meetsCondition = !hasEffect(creature, ability.condition.notHasEffect, rules);
    }

    let unarmedWeapon: (ResolvedInventoryWeapon & ResolvedInventoryItem) | undefined;
    if (meetsCondition && ability.condition?.attackAction != null) {
        const weaponFilter = ability.condition.attackAction.weapon;
        let attacksMade = 0;
        if (weaponFilter) {
            if (isCharacter(creature)) {
                const attackKeys = creature.combatTurn?.attacks ? Object.keys(creature.combatTurn.attacks) : [];
                for (let i = 0; i < attackKeys.length; i++) {
                    const weapon =
                        attackKeys[i] === "_"
                            ? (unarmedWeapon = getUnarmedWeapon(creature, rules))
                            : creature.inventory[attackKeys[i]];
                    if (weapon && filterItem(weapon, weaponFilter, rules.items)) {
                        attacksMade += creature.combatTurn!.attacks![attackKeys[i]];
                    }
                }
            }
        } else {
            attacksMade = creature.combatTurn?.attacks
                ? Object.values(creature.combatTurn.attacks).reduce((p, c) => p + c, 0)
                : 0;
        }

        meetsCondition = attacksMade > 0;
    }

    if (meetsCondition && ability.weaponAttack) {
        // If the weapon attack cannot be fulfilled, then the ability hasn't met the condition to be available.
        if (isCharacter(creature)) {
            const inventory = Object.values(creature.inventory);
            meetsCondition = inventory.some(
                o =>
                    isWeapon(o) &&
                    (!ability.weaponAttack!.weapon || filterItem(o, ability.weaponAttack!.weapon, rules.items))
            );
            if (!meetsCondition) {
                // Could still be the unarmed weapon.
                unarmedWeapon = unarmedWeapon ?? getUnarmedWeapon(creature, rules);
                meetsCondition =
                    !ability.weaponAttack!.weapon ||
                    filterItem(unarmedWeapon, ability.weaponAttack!.weapon, rules.items);
            }
        } else {
            meetsCondition = false;
        }
    }

    return meetsCondition;
}

export function getAttackModifiers(
    attacker: ResolvedCharacter | ResolvedMonster | undefined,
    target: ResolvedCharacter | ResolvedMonster | undefined,
    ability: Ability | ResolvedInventoryItem,
    feetToTarget?: number
): {
    advantages: { content: string; condition?: string }[];
    disadvantages: { content: string; condition?: string }[];
    extraRolls: { roll: string; reason: string; source?: AppliedAbilityEffect & AppliedAbilityEffectSource }[];
    autoCrit: boolean;
    advantage?: "adv" | "dis";
} {
    const advantages: { content: string; condition?: string }[] = [];
    const disadvantages: { content: string; condition?: string }[] = [];
    let extraRolls: {
        roll: string;
        reason: string;
        source?: AppliedAbilityEffect & AppliedAbilityEffectSource;
    }[] = [];
    let autoCrit = false;

    // Check for any effects on the source or target that could affect the attack.
    if (attacker) {
        if (target?.effects) {
            let effects = keyedListToArray(target.effects);
            for (let effect of effects) {
                if (effect.attacksOn) {
                    const attacksOn = keyedListToArray(effect.attacksOn);
                    for (let attackOn of attacksOn) {
                        if (attackOn && shouldApplyAttackModifiers(attackOn, attacker, ability, feetToTarget)) {
                            applyAttackModifiers(effect, attackOn, advantages, disadvantages, extraRolls);

                            if (attackOn.crit) {
                                autoCrit = true;
                            }
                        }
                    }
                }
            }
        }

        if (attacker?.effects) {
            let effects = keyedListToArray(attacker.effects);
            for (let effect of effects) {
                if (effect.attacksBy) {
                    const attacksBy = keyedListToArray(effect.attacksBy);
                    for (let attackBy of attacksBy) {
                        if (attackBy && shouldApplyAttackModifiers(attackBy, attacker, ability, feetToTarget)) {
                            applyAttackModifiers(effect, attackBy, advantages, disadvantages, extraRolls);

                            if (attackBy.crit) {
                                autoCrit = true;
                            }
                        }
                    }
                }
            }
        }
    }

    let advantage: "adv" | "dis" | undefined;
    if (!!advantages.length !== !!disadvantages.length) {
        advantage = advantages.length ? "adv" : "dis";
    }

    return {
        advantages,
        disadvantages,
        extraRolls,
        advantage,
        autoCrit,
    };
}

export function shouldApplyAttackModifiers(
    modifiers: AttackModifiers,
    attacker: ResolvedCharacter | ResolvedMonster,
    ability: Ability | ResolvedInventoryItem,
    feetToTarget?: number
) {
    let shouldApply = true;
    if (modifiers.condition) {
        if (modifiers.condition.ability) {
            if (isCharacter(attacker)) {
                const item: ResolvedInventoryItem | undefined = isInventoryItem(ability)
                    ? ability
                    : ability.item
                    ? attacker.inventory[ability.item]
                    : undefined;
                if (!isInventoryWeapon(item) || item.ability !== modifiers.condition.ability) {
                    shouldApply = false;
                }
            } else {
                shouldApply = false;
            }
        }

        if (modifiers.condition.type) {
            // TODO: This is probably dodgy, really we want the Ability to specify the exact attack type being used (i.e. SingleAttackType),
            // but Spell extends Ability and needs to account for spells that can be either ranged or touch (is that actually a thing?)
            const at = getSingleAttackType(ability);
            if (at == null || modifiers.condition.type.indexOf(at) < 0) {
                shouldApply = false;
            }
        }

        if (
            modifiers.condition.maxDistance != null &&
            !modifiers.condition.maxExclusive &&
            (feetToTarget == null || feetToTarget > modifiers.condition.maxDistance)
        ) {
            shouldApply = false;
        }

        if (
            modifiers.condition.maxDistance != null &&
            modifiers.condition.maxExclusive &&
            (feetToTarget == null || feetToTarget >= modifiers.condition.maxDistance)
        ) {
            shouldApply = false;
        }

        if (
            modifiers.condition.minDistance != null &&
            !modifiers.condition.minExclusive &&
            (feetToTarget == null || feetToTarget < modifiers.condition.minDistance)
        ) {
            shouldApply = false;
        }

        if (
            modifiers.condition.minDistance != null &&
            modifiers.condition.minExclusive &&
            (feetToTarget == null || feetToTarget <= modifiers.condition.minDistance)
        ) {
            shouldApply = false;
        }
    }

    return shouldApply;
}

export function canLearnSpell(spell: Spell, classLevels: ResolvedClassLevels) {
    if (spell.level === 0) {
        // Cantrips have different rules from other spells. Even for characters that know all spells (i.e. clerics,
        // druids) they have a limit on the number of cantrips they know. Cantrips don't count against known spells,
        // only known cantrips, etc.

        // Cantrips can only be learned if the maxCantripsKnown is not yet met.
        if ((classLevels.currentCantripsKnown ?? 0) >= (classLevels.maxCantripsKnown ?? 0)) {
            return false;
        }
    } else {
        if (classLevels.classData.spellsKnownProgression === "all") {
            return false;
        }

        // If there is a known spell limit, and the character already knows that many spells, then
        // don't let them learn any more.
        const maxKnownSpells = Array.isArray(classLevels.classData.spellsKnownProgression)
            ? classLevels.classData.spellsKnownProgression[classLevels.level - 1]
            : undefined;
        if (maxKnownSpells != null && (classLevels.currentSpellsKnown ?? 0) >= maxKnownSpells) {
            return false;
        }
    }

    // Can't learn spells we already know.
    const knownSpells = classLevels.knownSpells;
    if (knownSpells && knownSpells.findIndex(o => o.name === spell.name && o.source === spell.source) >= 0) {
        return false;
    }

    return true;
}

function applyAttackModifiers(
    effect: AppliedAbilityEffect & AppliedAbilityEffectSource,
    modifiers: AttackModifiers,
    advantages: { content: string; condition?: string }[],
    disadvantages: { content: string; condition?: string }[],
    extraRolls: { roll: string; reason: string; source?: AppliedAbilityEffect }[]
) {
    if (modifiers.advantage) {
        advantages.push({
            content: effect.name,
            condition: modifiers.condition?.special,
        });
    }

    if (modifiers.disadvantage) {
        disadvantages.push({
            content: effect.name,
            condition: modifiers.condition?.special,
        });
    }

    if (modifiers?.extraRoll) {
        extraRolls.push({ roll: modifiers.extraRoll, reason: effect.name, source: effect });
    }
}

export function creatureMatchesFilter(
    creature: Creature,
    filter: MonsterFilter,
    target?: ResolvedMonster | ResolvedCharacter
) {
    if (isMonster(creature)) {
        return monsterMatchesFilter(creature, filter, target);
    }

    // TODO: Characters can't be filtered as monsters currently.
    // Do we need to change MonsterFilter to CreatureFilter, and have it accept both characters and monsters?
    return (
        filter.creatureType != null &&
        (Array.isArray(filter.creatureType)
            ? filter.creatureType.includes("humanoid")
            : filter.creatureType === "humanoid")
    );
}

export function monsterMatchesFilter(
    monster: Monster,
    filter: MonsterFilter,
    target?: ResolvedMonster | ResolvedCharacter
) {
    if (filter.cr != null && monster.cr !== filter.cr) {
        return false;
    }

    if (filter.environment != null) {
        if (Array.isArray(filter.environment)) {
            if (filter.environment.every(o => !monster.environments?.[o])) {
                return false;
            }
        } else {
            if (!monster.environments?.[filter.environment]) {
                return false;
            }
        }
    }

    if (filter.creatureType != null) {
        if (Array.isArray(filter.creatureType)) {
            if (!monster.creatureType || !filter.creatureType.includes(monster.creatureType)) {
                return false;
            }
        } else if (filter.creatureType !== monster.creatureType) {
            return false;
        }
    }

    if (filter.maxCr != null) {
        // If the value is -1, that means use the target's CR/level.
        let maxCr: number | undefined;
        if (filter.maxCr === -1) {
            if (target) {
                maxCr = isMonster(target) ? target.cr : isCharacter(target) ? target.level : undefined;
            }
        } else {
            maxCr = filter.maxCr;
        }

        if (maxCr != null && (monster.cr == null || monster.cr > maxCr)) {
            return false;
        }
    }

    if (filter.minCr != null) {
        // If the value is -1, that means use the target's CR/level.
        let minCr: number | undefined;
        if (filter.minCr === -1) {
            if (target) {
                minCr = isMonster(target) ? target.cr : isCharacter(target) ? target.level : undefined;
            }
        } else {
            minCr = filter.minCr;
        }

        if (minCr != null && (monster.cr == null || monster.cr < minCr)) {
            return false;
        }
    }

    if (filter.maxSpeed && monster.speed) {
        for (let moveType in filter.maxSpeed) {
            const maxSpeed = filter.maxSpeed[moveType];
            for (let key in monster.speed) {
                const monsterSpeeds = monster.speed[key];
                const monsterSpeed = monsterSpeeds[moveType];
                if (monsterSpeed != null && monsterSpeed > maxSpeed) {
                    return false;
                }
            }
        }
    }

    if (filter.alignment && monster.alignment !== "Any") {
        if (Array.isArray(filter.alignment)) {
            if (!monster.alignment || !filter.alignment.includes(monster.alignment)) {
                return false;
            }
        } else {
            if (filter.alignment !== monster.alignment) {
                return false;
            }
        }
    }

    return true;
}

export function filterMonsters(
    allMonsters: Monster[],
    filter: MonsterFilter,
    target?: ResolvedMonster | ResolvedCharacter
) {
    return allMonsters.filter(o => monsterMatchesFilter(o, filter, target));
}

export function filterMonsterTemplates(
    allTokens: TokenTemplate[],
    filter: MonsterFilter,
    target?: ResolvedMonster | ResolvedCharacter
) {
    let monsters = allTokens.filter(o => {
        if (!isDnD5EMonsterTemplate(o)) {
            return false;
        }

        return monsterMatchesFilter(o.dnd5e, filter, target);
    }) as DnD5EMonsterTemplate[];
    return monsters;
}

import styled from "@emotion/styled";

import { TypographyProps, typographyStyles, typographySystem } from "./typographyProps";

import { focus } from "./utils/focus";

export interface ILinkProps {
    href?: string;
    clean?: boolean;
    active?: boolean;
    disabled?: boolean;
}

export type LinkProps = ILinkProps & TypographyProps;

export const Link = styled("a")<LinkProps>(
    (props: any) => ({
        textDecoration: props.clean ? "none" : "underline dashed",
        position: "relative",
        borderRadius: props.theme.radii[3],
        "&:hover": {
            color: props.theme.colors.accent[3],
            textDecoration: "underline",
        },
        "&:visited, &:focus": {
            textDecoration: "none",
        },
    }),
    typographyStyles,
    typographySystem,
    focus,
    (props: any) => ({
        display: "initial",
        cursor: "pointer",
        pointerEvents: props.disabled ? "none" : props.pointerEvents,
        color: props.disabled ? props.theme.colors.grayscale[4] : props.color,
        opacity: props.disabled ? 0.5 : props.opacity,
    })
);

Link.displayName = "Link";

Link.defaultProps = {
    color: "accent.4",
    fontSize: 1,
    textAlign: "left",
    active: false,
};

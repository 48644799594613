import { FunctionComponent, useCallback, useEffect } from "react";
import { LocalPixelPosition, Point } from "../../position";
import { localPoint, rotate, getCenterPoint, translate } from "../../grid";
import { AnnotationHandle } from "./AnnotationHandle";
import { DraggableLine, Line } from "./three2d/Line";
import { DraggableShape } from "./three2d/Shape";
import { HoverTracking, ZIndexes } from "./common";
import { ThreeEvent } from "@react-three/fiber";
import { DistanceMessage } from "./DistanceMessage";
import { theme } from "../../design";
import { useLocalGrid, useScale } from "../contexts";
import { interactiveLineWidth } from "../common";
import { AnimatePresence, usePresence } from "framer-motion";
import { UiLayer } from "./UiLayer";

function getSecondLastPoint(pos: LocalPixelPosition, line: Point[]): LocalPixelPosition {
    const point = line[line.length - 2];
    return localPoint(point.x + pos.x, point.y + pos.y);
}

interface EditablePolygonProps extends HoverTracking {
    pos: LocalPixelPosition;
    points: Point[];
    fillInitialColor?: string;
    fillColor: string;
    fillHoverColor: string;
    lineInitialColor?: string;
    lineColor: string;
    lineHoverColor: string;
    lineWidth: number;
    rotation?: number;
    closed?: boolean;
    filled?: boolean;
    activePoint?: LocalPixelPosition;
    showHandles?: boolean;
    fillOpacity?: number;
    zIndex?: ZIndexes;
    fillZIndex?: ZIndexes;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onPointerDown?: (evt: ThreeEvent<PointerEvent>) => void;
    onPointerUp?: (evt: ThreeEvent<PointerEvent>) => void;
    onClick?(evt: ThreeEvent<MouseEvent>): void;
    onSetPosition: (pos: LocalPixelPosition) => void;
    onOverridePosition: (pos: LocalPixelPosition) => void;
    onSetPoints: (pos: LocalPixelPosition, points: Point[]) => void;
    onOverridePoints: (pos: LocalPixelPosition, points: Point[]) => void;
    animateExit?: boolean;
}

export const EditablePolygon: FunctionComponent<EditablePolygonProps> = ({
    pos,
    points,
    rotation,
    fillColor,
    lineColor,
    fillHoverColor,
    lineHoverColor,
    fillInitialColor,
    lineInitialColor,
    lineWidth,
    fillOpacity,
    zIndex,
    closed,
    filled,
    showHandles,
    activePoint,
    snapPoint,
    onPointerDown,
    onPointerUp,
    onClick,
    onSetPosition,
    onOverridePosition,
    onOverridePoints,
    onSetPoints,
    isHovering,
    setHoverPart,
    animateExit,
    fillZIndex,
}) => {
    const grid = useLocalGrid();
    const scale = useScale();

    // We need to manage presence manually because of the AnnotationHandles being in their own AnimatePresence - they need
    // to be given a chance to animate out.
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        if (!isPresent && !animateExit && !showHandles) {
            safeToRemove?.();
        }
    }, [isPresent, safeToRemove, animateExit, showHandles]);

    let center: LocalPixelPosition | undefined;
    const originalPoints = points;
    if (rotation != null && rotation !== 0) {
        const cp = getCenterPoint(points);
        center = localPoint(pos.x + cp.x, pos.y + cp.y);
        center.x -= pos.x;
        center.y -= pos.y;
        points = points.map(o => ({
            x: o.x - (center as LocalPixelPosition).x,
            y: o.y - (center as LocalPixelPosition).y,
        }));
    } else {
        points = points.slice();
    }

    if (activePoint) {
        points.push({ x: activePoint.x - pos.x, y: activePoint.y - pos.y });
    }

    const onDragMove = useCallback(
        (e: ThreeEvent<PointerEvent>, p: LocalPixelPosition) => {
            if (center?.x != null && center?.y != null) {
                p.x -= center.x;
                p.y -= center.y;
            }

            onOverridePosition(p);
        },
        [center?.x, center?.y, onOverridePosition]
    );
    const onDragEnd = useCallback(() => {
        onSetPosition(pos);
    }, [onSetPosition, pos]);

    const isHover = onClick && (isHovering || !!activePoint);

    const onLinePointerDown: (e: ThreeEvent<PointerEvent>) => void = e => {
        const cancelEvent = onClick != null && e.nativeEvent.button !== 2;
        if (cancelEvent) {
            e.stopPropagation();
            e.nativeEvent.stopPropagation();
            e.nativeEvent.preventDefault();
        } else {
            onPointerDown?.(e);
        }
    };

    const disableDrag = onClick == null;
    const opacity = fillOpacity ?? 1;
    return (
        <>
            {(isPresent || animateExit) && (
                <>
                    <AnimatePresence>
                        {filled && isPresent && (
                            <DraggableShape
                                x={pos.x + (center ? center.x : 0)}
                                y={pos.y + (center ? center.y : 0)}
                                zIndex={fillZIndex ?? zIndex ?? ZIndexes.Annotations}
                                rotation={rotation}
                                points={points}
                                animateEnterExit="nolayout"
                                initial={
                                    fillInitialColor != null
                                        ? { color: fillInitialColor, opacity: opacity, scale: 1 }
                                        : undefined
                                }
                                exit={animateExit ? undefined : null}
                                color={isHover ? fillHoverColor : fillColor}
                                opacity={opacity}
                                dragBoundFunc={snapPoint}
                                onPointerOver={setHoverPart ? () => setHoverPart("fill", true) : undefined}
                                onPointerOut={setHoverPart ? () => setHoverPart("fill", false) : undefined}
                                onPointerDown={onPointerDown}
                                onPointerUp={onPointerUp}
                                cursor={disableDrag ? undefined : "pointer"}
                                disableDrag={disableDrag}
                                onDragMove={onDragMove}
                                onDragEnd={onDragEnd}
                                onClick={onClick}
                            />
                        )}
                    </AnimatePresence>
                    <DraggableLine
                        x={pos.x + (center ? center.x : 0)}
                        y={pos.y + (center ? center.y : 0)}
                        zIndex={zIndex ?? ZIndexes.Annotations}
                        rotation={rotation}
                        points={points}
                        closed={closed}
                        visible={false}
                        width={lineWidth + interactiveLineWidth}
                        onPointerOver={setHoverPart ? () => setHoverPart("line", true) : undefined}
                        onPointerOut={setHoverPart ? () => setHoverPart("line", false) : undefined}
                        onPointerDown={onLinePointerDown}
                        onPointerUp={onPointerUp}
                        cursor={disableDrag ? undefined : "pointer"}
                        dragBoundFunc={snapPoint}
                        disableDrag={disableDrag}
                        onDragMove={onDragMove}
                        onDragEnd={onDragEnd}
                        onClick={onClick}
                    />
                    <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                        {isPresent && (
                            <Line
                                x={pos.x + (center ? center.x : 0)}
                                y={pos.y + (center ? center.y : 0)}
                                zIndex={(zIndex ?? ZIndexes.Annotations) + 0.01}
                                rotation={rotation}
                                points={points}
                                closed={closed}
                                animateEnterExit="nolayout"
                                initial={
                                    lineInitialColor != null
                                        ? { color: lineInitialColor, opacity: 1, scale: 1 }
                                        : undefined
                                }
                                exit={animateExit ? undefined : null}
                                color={isHover ? lineHoverColor : lineColor}
                                width={lineWidth}
                            />
                        )}
                    </AnimatePresence>
                </>
            )}
            {activePoint && (
                <DistanceMessage
                    pos={translate(activePoint, { x: 0, y: -(theme.space[1] / scale) })}
                    distance={grid.gridDistanceBetween(getSecondLastPoint(pos, points), activePoint)}
                    horizontalAlignment="center"
                    verticalAlignment="bottom"
                />
            )}
            <UiLayer>
                <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                    {showHandles &&
                        isPresent &&
                        points.map((o, i) => (
                            <AnnotationHandle
                                key={i}
                                x={pos.x + (center ? center.x : 0)}
                                y={pos.y + (center ? center.y : 0)}
                                offsetX={o.x}
                                offsetY={o.y}
                                zIndex={ZIndexes.UserInterface}
                                color={lineColor}
                                snapPoint={snapPoint}
                                rotation={rotation}
                                disableDrag={disableDrag}
                                onDragMove={p => {
                                    let newPoints = originalPoints.slice();
                                    let point: LocalPixelPosition;
                                    let newPos = pos;
                                    if (rotation != null && rotation !== 0) {
                                        const c = getCenterPoint(originalPoints);
                                        const rotateAround = localPoint(c.x + pos.x, c.y + pos.y);
                                        point = rotate(p, rotateAround, -rotation);
                                        newPoints[i] = { x: point.x - pos.x, y: point.y - pos.y };

                                        const newCenter = getCenterPoint(newPoints);
                                        const requiredCenter = rotate(
                                            localPoint(pos.x + newCenter.x, pos.y + newCenter.y),
                                            rotateAround,
                                            rotation
                                        );
                                        newPos = localPoint(
                                            requiredCenter.x - newCenter.x,
                                            requiredCenter.y - newCenter.y
                                        );
                                    } else {
                                        point = localPoint(p.x - pos.x, p.y - pos.y);
                                        newPoints[i] = { x: point.x, y: point.y };
                                    }

                                    onOverridePoints(newPos, newPoints);
                                }}
                                onDragEnd={() => {
                                    onSetPoints(pos, originalPoints);
                                }}
                            />
                        ))}
                </AnimatePresence>
            </UiLayer>
        </>
    );
};

import React, { FunctionComponent, useMemo, useLayoutEffect, useRef, useEffect, MutableRefObject } from "react";
import { LocalPixelPosition, Point, Size } from "../../position";
import { getGameTime, msPerDay, PositionedLight, Token } from "../../store";
import { IGrid, localPoint, rotate } from "../../grid";
import {
    clipperPromise,
    useClipper,
    useValidatedLocation,
    useSession,
    useValidatedLocationLevel,
    useCamera,
    useScale,
} from "../contexts";
import { useFrame } from "@react-three/fiber";
import {
    Mesh,
    Vector3,
    WebGLRenderTarget,
    Scene,
    CustomBlending,
    Vector2,
    ShaderMaterial,
    FrontSide,
    MaxEquation,
    AddEquation,
    EllipseCurve,
    OrthographicCamera,
    Texture,
    Color,
    CircleGeometry,
    MeshBasicMaterial,
    IUniform,
    AmbientLight,
    DirectionalLight,
    Object3D,
    PointLight,
    ShaderChunk,
} from "three";
import {
    basicVertexShader,
    commonShaderFunctions,
    commonShaderUniforms,
    getPixel,
    LevelInfo,
    RenderOrder,
    segmentsToClipperPoly,
    SingleLevelInfo,
    useDistanceField,
    VttCameraLayers,
    ZIndexes,
} from "./common";
import { DeepPartial, softShadowsSetting } from "../../common";
import {
    Segment,
    lightTemplates,
    defaultLightShader,
    resolveLight,
    getSegmentsForSource,
    updateGeometryForSegments,
} from "./Lighting";
import { ClipperLibWrapper, ClipType, Paths, PolyFillType, ReadonlyPath, ReadonlyPaths } from "js-angusj-clipper";
import { SubjectInput } from "js-angusj-clipper/universal/clipFunctions";
import { AnimatePresence, useIsPresent, useMotionValue, animate, usePresence, MotionValue } from "framer-motion";
import { motion } from "framer-motion-3d";
import { useForceUpdate, useLocalSetting } from "../utils";
import { LocationLevelRenderInfo } from "./contexts";
import { UiLayer } from "./UiLayer";

const emptyPoint = localPoint(0, 0);
const defaultObject3D = new Object3D();
const sunDistance = 100000;

interface TimesOfDay {
    dawn: number;
    sunset: number;
}

const defaultDayTimes: TimesOfDay = {
    dawn: 0.3,
    sunset: 0.75,
};

interface DayGradientStop {
    /**
     * The time of day that this gradient stops position is relative to.
     * If not specified, the position is relative to midnight and must be positive.
     */
    relativeTo?: keyof TimesOfDay;
    amount: number;
    color: Color;
    lightLevel: number;
}

interface ColorGradientStop {
    /**
     * Value between 0 and 1 indicating where the stop is.
     */
    position: number;

    color: Color;

    lightLevel: number;
}

// TODO: Define these as relative to the dawn/sunset times, so that we can alter those easily.
// Consider having a max/min brightness as well, and use the light level here to interpolate between those.
const dayNightCycle: DayGradientStop[] = [
    { amount: 0, color: new Color(0x01104c), lightLevel: 0 },
    { relativeTo: "dawn", amount: -0.12, color: new Color(0x9189ff), lightLevel: 0.03 }, // 4:30, start getting a little brighter
    { relativeTo: "dawn", amount: -0.05, color: new Color(0x9189ff), lightLevel: 0.15 }, // 6am, start brightening even more
    { relativeTo: "dawn", amount: -0.03, color: new Color(0xba89ff), lightLevel: 0.75 }, // 6:30am, purple
    { relativeTo: "dawn", amount: 0.02, color: new Color(0xfbd2b2), lightLevel: 0.85 }, // 7:30am, orange
    { relativeTo: "dawn", amount: 0.06, color: new Color(0xfff4c1), lightLevel: 0.92 }, // 8:30am, yellowish
    { relativeTo: "dawn", amount: 0.1, color: new Color(0xffffff), lightLevel: 1 }, // 9:30pm, full sun
    { relativeTo: "sunset", amount: -0.05, color: new Color(0xffffff), lightLevel: 1 }, // 5pm, full sun
    { relativeTo: "sunset", amount: -0.025, color: new Color(0xfff4c1), lightLevel: 0.92 }, // 5:30pm, yellowish
    { relativeTo: "sunset", amount: 0, color: new Color(0xfbd2b2), lightLevel: 0.85 }, // 6pm, Orange
    { relativeTo: "sunset", amount: 0.04, color: new Color(0xba89ff), lightLevel: 0.75 }, // 7pm, Purple
    { relativeTo: "sunset", amount: 0.08, color: new Color(0x5986fd), lightLevel: 0.2 }, // 8pm, Blue
    { relativeTo: "sunset", amount: 0.125, color: new Color(0x01104c), lightLevel: 0.1 }, // 9pm, dark
    { amount: 1, color: new Color(0x01104c), lightLevel: 0 },
];

function generateDayNightCycle(times: TimesOfDay, stops: DayGradientStop[]) {
    const cycle: ColorGradientStop[] = [];
    for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];

        let time = (stop.relativeTo != null ? times[stop.relativeTo] : 0) + stop.amount;
        if (time >= 0 && (i === 0 || time > cycle[i - 1].position)) {
            cycle.push({
                position: time,
                color: stop.color,
                lightLevel: stop.lightLevel,
            });
        }
    }

    return cycle;
}

const dayNightCycleGradient: ColorGradientStop[] = generateDayNightCycle(defaultDayTimes, dayNightCycle);

const defaultDayNightColor: { color: Color; lightLevel: number } = {
    color: new Color(0xffffff),
    lightLevel: 1,
};

function getDayNightColor(gameTime: number): { color: Color; lightLevel: number } {
    // Get the current time of day as a percentage.
    const timeOfDay = (gameTime % msPerDay) / msPerDay;

    // Interpolate a colour using our day/night cycle gradient.
    for (let i = 0; i < dayNightCycleGradient.length; i++) {
        if (dayNightCycleGradient[i].position === timeOfDay) {
            return dayNightCycleGradient[i];
        } else if (dayNightCycleGradient[i].position > timeOfDay) {
            // The correct color is between the last one and this one.
            const from = dayNightCycleGradient[i - 1];
            const to = dayNightCycleGradient[i];

            const dist = timeOfDay - from.position;
            const total = to.position - from.position;

            return {
                color: from.color.clone().lerp(to.color, dist / total),
                lightLevel: from.lightLevel + (to.lightLevel - from.lightLevel) * (dist / total),
            };
        }
    }

    return defaultDayNightColor;
}

interface VisibilityMaskProps {
    grid: IGrid;
    canvasSize: Size;
    backgroundScene: WebGLRenderTarget;
    backgroundTileData: ImageData | undefined;
    mainScene: WebGLRenderTarget;
    tempFbo: WebGLRenderTarget;

    camera: OrthographicCamera;

    /**
     * Should be true if at least one vision source is present, on any level.
     */
    hasVisionSource: boolean;

    segments: Segment[] | undefined;

    levelInfo: LevelInfo;
    renderInfo: LocationLevelRenderInfo;

    /**
     * Lights for the current level.
     */
    lights: PositionedLight[];

    /**
     * Obstructions for the current level.
     */
    isVisible: boolean;
    levelKey: string;
    tokenOverrides?: { [id: string]: DeepPartial<Token> };
}

const parseColorMemo: { [input: string]: Vector3 | undefined } = {};

function parseColor(input: string) {
    let color = parseColorMemo[input];
    if (color) {
        return color;
    }

    let r: number;
    let g: number;
    let b: number;
    if (input.substr(0, 1) === "#") {
        var collen = (input.length - 1) / 3;
        var fact = [17, 1, 0.062272][collen - 1];
        r = Math.round(parseInt(input.substr(1, collen), 16) * fact);
        g = Math.round(parseInt(input.substr(1 + collen, collen), 16) * fact);
        b = Math.round(parseInt(input.substr(1 + 2 * collen, collen), 16) * fact);
    } else {
        const values = input
            .split("(")[1]
            .split(")")[0]
            .split(",")
            .map(x => +x);
        r = values[0];
        g = values[1];
        b = values[2];
    }

    color = new Vector3(r / 255, g / 255, b / 255);
    parseColorMemo[input] = color;
    return color;
}

function arePathsEqual(a: ReadonlyPath, b: ReadonlyPath) {
    if (a.length !== b.length) {
        return false;
    }

    // TODO: Here we're testing whether the two paths contain the same points, which isn't exactly correct
    // as they could have the same points in a different order, making a completely different polygon.
    // However, that seems pretty unlikely so we'll go with this for now.
    const points = new Set<string>();
    for (let i = 0; i < a.length; i++) {
        points.add(`${a[i].x},${a[i].y}`);
    }

    for (let i = 0; i < b.length; i++) {
        const pb = `${b[i].x},${b[i].y}`;
        if (!points.has(pb)) {
            return false;
        }
    }

    return true;
}

function mergeFow(
    fowRef: MutableRefObject<ReadonlyPaths | undefined>,
    visibleLightArea: Paths,
    clipper: ClipperLibWrapper
) {
    // Now we can unify this with the current FOW.
    let hasChanged = false;
    const fow = !fowRef.current?.length
        ? visibleLightArea
        : clipper.clipToPaths({
              clipType: ClipType.Union,
              subjectFillType: PolyFillType.NonZero,
              subjectInputs: [
                  {
                      data: fowRef.current,
                      closed: true,
                  },
                  {
                      data: visibleLightArea,
                      closed: true,
                  },
              ],
          })!;

    if (fow.length === fowRef.current?.length) {
        if (fow.length === 1) {
            hasChanged = !arePathsEqual(fow[0], fowRef.current[0]);
        } else {
            const pathsToMatch = fowRef.current.slice();

            for (let i = 0; i < fow.length; i++) {
                let foundMatch = false;
                for (let j = 0; !foundMatch && j < pathsToMatch.length; j++) {
                    if (arePathsEqual(fow[i], pathsToMatch[j])) {
                        pathsToMatch.splice(j, 1);
                        foundMatch = true;
                    }
                }

                if (!foundMatch) {
                    hasChanged = true;
                    break;
                }
            }
        }
    } else {
        hasChanged = true;
    }

    if (hasChanged) {
        fowRef.current = fow;
    }

    // Looking into the idea of an inner/outer mesh to allow us to fade out on the outer mesh
    // using barycentric coordinates - looks like we might have to manually triangulate the faces
    // though, so that we get inner and outer vertices on each face.
    // Stick with a single mesh for now...

    // TODO: Might return undefined?
    // const inner = clipper.offsetToPaths({
    //     delta: -30,
    //     offsetInputs: [{
    //         data: fow,
    //         joinType: JoinType.Square,
    //         endType: EndType.ClosedPolygon
    //     }]
    // })!;
    // const outer = fow;
    // // const inner = fow;

    // // const fowMeshInner = visCache.lightsScene.fowInner;
    // // const fowInnerMaterial = fowMeshInner.material as MeshBasicMaterial;
    // // fowInnerMaterial.opacity = 0.05;
    // fowInnerMaterial.opacity = 0.5;

    // const fowMeshOuter = visCache.lightsScene.fowOuter;
    // const fowOuterMaterial = fowMeshOuter.material as MeshBasicMaterial;
    // fowOuterMaterial.opacity = 0.5;

    // Convert the FOW polygon(s) to geometry.
    // // const fowInnerGeometry = clipperPathsToShapeGeometry(inner);
    // // fowMeshInner.geometry = fowInnerGeometry;

    // const fowOuterGeometry = clipperPathsToShapeGeometry(outer, inner);
    // fowMeshOuter.geometry = fowOuterGeometry;

    // // DEBUG, show fow wireframe
    // addDebugWireframe(fowMeshInner);
    // addDebugWireframe(fowMeshOuter);

    return hasChanged;
}

// export const ZoneMask: FunctionComponent<{ size: Size, scene: MutableRefObject<Scene | undefined>, particleSystems: ZoneParticleSystem[], camera: Camera, grid: IGrid, location: Location, zone: Zone }> = ({ size, scene, particleSystems, camera, grid, location, zone }) => {
//     const points = useMemo(() => zone.points.map(o => localPoint(zone.pos.x + o.x, zone.pos.y + o.y)), [zone]);

//     // Snow
//     useZoneParticleSystem(
//         createSnow,
//         size,
//         scene,
//         particleSystems,
//         camera,
//         grid,
//         location,
//         points,
//         undefined,
//         zone.snowAmount
//     );

//     // Rain
//     useZoneParticleSystem(
//         createRain,
//         size,
//         scene,
//         particleSystems,
//         camera,
//         grid,
//         location,
//         points,
//         undefined,
//         zone.rainAmount
//     );

//     return <React.Fragment />
// }

const defaultInitial = { opacity: 0 };
const defaultAnimate = { opacity: 1 };
const defaultExit = { opacity: 0 };
let isAnimatingGlobalVisibility = false;

// TODO: Memo, only change when segments/source changes.
const VisionSourceNode: FunctionComponent<{
    grid: IGrid;
    source: Token;
    polygons?: { pos: LocalPixelPosition; segments: [Point, Point][] };
}> = ({ grid, source, polygons }) => {
    const ref = useRef<Mesh>(undefined as any);

    useLayoutEffect(() => {
        if (polygons) {
            updateGeometryForSegments(ref.current, polygons.pos, polygons.segments);
        }
    }, [polygons]);

    // Ensure rerender on exiting.
    useIsPresent();

    // TODO: Tried animating the opacity here, but when switching between different sources that results in a flash
    // effect as the old source fades out and the new one fades in, which is a bit unsettling. Ideally the intersection
    // of the old and new would remain at opacity 1 and only the difference would animate out/in. That's far more complicated.
    // Could write to another buffer every time we actually have to render (or rather, every time the polgons change?) and then
    // use that buffer as an input to a shader that will use the animation opacity for pixels that are not set in the buffer, or
    // 1 for pixels that are. That way only the difference animates.
    return (
        <mesh ref={ref} layers={VttCameraLayers.DefaultNoRaycasting}>
            <motion.meshBasicMaterial
                attach="material"
                color={green}
                transition={isAnimatingGlobalVisibility ? { duration: 0.3, ease: "easeInOut" } : { duration: 0 }}
                initial={isAnimatingGlobalVisibility ? defaultAnimate : defaultInitial}
                animate={defaultAnimate}
                exit={isAnimatingGlobalVisibility ? defaultAnimate : defaultExit}
                transparent
            />
        </mesh>
    );
};

// TODO: Memo, only change when segments/source changes.
const DarkvisionNode: FunctionComponent<{
    grid: IGrid;
    source: Token;
    polygons: { pos: LocalPixelPosition; darkvision: number; segments: [Point, Point][]; clipperPolygons: Paths };
    backgroundWidth: number | undefined;
    backgroundHeight: number | undefined;
    backgroundPos: Point | undefined;
    canvasSize: Size;
}> = ({ grid, source, polygons, backgroundWidth, backgroundHeight, backgroundPos, canvasSize }) => {
    const ref = useRef<Mesh>(undefined as any);
    const shaderRef = useRef<ShaderMaterial>(undefined as any);
    //const canvasSize = useThree(state => state.size);

    const presenceOpacity = useMotionValue(0);
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        // Darkvision is meant to be seeing as if in dim light, so we don't have the brightness at full (1).
        animate(presenceOpacity, isPresent ? 0.5 : 0, {
            onComplete: safeToRemove ?? undefined,
            duration: 0.3,
            ease: "easeInOut",
        });
    }, [isPresent, safeToRemove, presenceOpacity]);

    const uniforms = useMemo(
        () => ({
            u_color: { value: new Vector3(0, 0, 1) }, // The colour of the light.
            u_brightness: { value: 0 }, // The brightness of the light, where 1 is full sunlight and 0 is off.
            u_near: { value: 0 }, // The point at which the light begins fading, in pixels from u_position.
            u_far: { value: 0 }, // The point at which the light ends completely, in pixels from u_position.
            u_position: { value: undefined as Vector2 | undefined }, // The position of the light in screen pixels.
            u_sdf: { type: "t", value: undefined as Texture | undefined },
            u_sdfSize: { value: 0 },
            u_useSdf: { value: false },
            u_backgroundWidth: { value: 0 },
            u_backgroundHeight: { value: 0 },
            u_backgroundX: { value: 0 },
            u_backgroundY: { value: 0 },
            u_canvasSize: { value: undefined as Vector2 | undefined }, // The width/height of the canvas we're drawing to.
            u_scale: { value: 1 },
            u_offset: { value: undefined as Vector2 | undefined },
        }),
        []
    );

    useLayoutEffect(() => {
        if (polygons) {
            updateGeometryForSegments(ref.current, polygons.pos, polygons.segments);
        }
    }, [polygons]);

    const isInitialisedRef = useRef<boolean>(false);
    useFrame(state => {
        if (!isInitialisedRef.current) {
            defaultLightShader.onInit?.(ref.current.material as ShaderMaterial);
            isInitialisedRef.current = true;
        }

        const near = Math.max(polygons.darkvision - 6, 0);
        const far = Math.max(near + 0.5, polygons.darkvision);

        uniforms.u_near.value = near * grid.tileSize.width;
        uniforms.u_far.value = far * grid.tileSize.width;
        uniforms.u_brightness.value = presenceOpacity.get();
        uniforms.u_position.value = new Vector2(polygons.pos.x, polygons.pos.y);

        uniforms.u_scale.value = grid.scale;
        uniforms.u_offset.value = new Vector2(grid.offset.x, grid.offset.y);

        uniforms.u_backgroundWidth.value = backgroundWidth ?? 0;
        uniforms.u_backgroundHeight.value = backgroundHeight ?? 0;
        uniforms.u_backgroundX.value = backgroundPos?.x ?? 0;
        uniforms.u_backgroundY.value = backgroundPos?.y ?? 0;

        uniforms.u_canvasSize.value = new Vector2(canvasSize.width, canvasSize.height);
    }, 0);

    return (
        <mesh ref={ref}>
            <shaderMaterial
                ref={shaderRef}
                attach="material"
                fragmentShader={defaultLightShader.shader}
                side={FrontSide}
                transparent
                uniforms={uniforms}
            />
        </mesh>
    );
};

interface LightPolygons {
    pos: LocalPixelPosition;
    light: PositionedLight;
    segments?: [Point, Point][];
    ellipse: EllipseCurve;
}

// TODO: Memo, only change when segments/light changes.
const LightNode: FunctionComponent<{
    grid: IGrid;
    light: PositionedLight;
    polygons: LightPolygons;
    segments?: Segment[];
    sdf: Texture | undefined;
    useSdf: boolean;
    backgroundWidth: number | undefined;
    backgroundHeight: number | undefined;
    backgroundPos: Point | undefined;
    canvasSize: Size;
}> = ({
    grid,
    light,
    polygons,
    segments,
    sdf,
    useSdf,
    backgroundWidth,
    backgroundHeight,
    backgroundPos,
    canvasSize,
}) => {
    const ref = useRef<Mesh>(undefined as any);
    const shaderRef = useRef<ShaderMaterial>(undefined as any);

    const { hasPerspectiveLighting, isOrthographic } = useCamera();
    const { system, location } = useValidatedLocation();

    const presenceOpacity = useMotionValue(0);
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        animate(presenceOpacity, isPresent ? 1 : 0, {
            onComplete: safeToRemove ?? undefined,
            duration: 0.3,
            ease: "easeInOut",
        });
    }, [isPresent, safeToRemove, presenceOpacity]);

    const uniforms = useMemo(
        () => ({
            u_color: { value: undefined as Vector3 | undefined }, // The colour of the light.
            u_brightness: { value: 0 }, // The brightness of the light, where 1 is full sunlight and 0 is off.
            u_near: { value: 0 }, // The point at which the light begins fading, in pixels from u_position.
            u_far: { value: 0 }, // The point at which the light ends completely, in pixels from u_position.
            u_position: { value: undefined as Vector2 | undefined }, // The position of the light in screen pixels.
            u_sdf: { type: "t", value: undefined as Texture | undefined },
            u_sdfSize: { value: 0 },
            u_useSdf: { value: false },
            u_backgroundWidth: { value: 0 },
            u_backgroundHeight: { value: 0 },
            u_backgroundX: { value: 0 },
            u_backgroundY: { value: 0 },
            u_canvasSize: { value: undefined as Vector2 | undefined }, // The width/height of the canvas we're drawing to.
            u_scale: { value: 1 },
            u_offset: { value: undefined as Vector2 | undefined },
        }),
        []
    );

    const template = lightTemplates[light.type] ?? defaultLightShader;
    const resolvedLight = resolveLight(system, light);

    const lastPolygons = useRef<LightPolygons>();
    useLayoutEffect(() => {
        if (polygons) {
            if (polygons.segments) {
                updateGeometryForSegments(ref.current, polygons.pos, polygons.segments);
            } else {
                if (
                    !lastPolygons.current ||
                    lastPolygons.current.segments ||
                    lastPolygons.current.ellipse.xRadius !== polygons.ellipse.xRadius
                ) {
                    ref.current.geometry = new CircleGeometry(polygons.ellipse.xRadius, 64);
                }
            }

            lastPolygons.current = polygons;
        }
    }, [polygons]);

    const near = resolvedLight.innerRadius;
    const far = Math.max(near + 0.5, resolvedLight.outerRadius);
    const color = parseColor(resolvedLight.color);

    const pointLightRef = useRef<PointLight>(null);

    const isInitialisedRef = useRef<boolean>(false);

    const scale = useScale();

    useFrame(state => {
        if (!isInitialisedRef.current) {
            template.onInit?.(ref.current.material as ShaderMaterial);
            isInitialisedRef.current = true;
        }

        uniforms.u_near.value = near * grid.tileSize.width;
        uniforms.u_far.value = far * grid.tileSize.width;
        uniforms.u_brightness.value = resolvedLight.brightness * presenceOpacity.get();
        uniforms.u_color.value = color;
        uniforms.u_position.value = new Vector2(polygons.pos.x, polygons.pos.y);

        // TODO: Pass the sdf size in with the sdf so we don't have to create all these Vector2s. Clean some other ones up while you're at it.
        const canvas = sdf ? ((sdf as any).source.data as HTMLCanvasElement) : undefined;
        uniforms.u_sdf.value = sdf;
        uniforms.u_useSdf.value = useSdf;
        uniforms.u_sdfSize.value = canvas ? canvas.width : 0;

        uniforms.u_scale.value = grid.scale;
        uniforms.u_offset.value = new Vector2(grid.offset.x, grid.offset.y);
        uniforms.u_backgroundWidth.value = backgroundWidth ?? 0;
        uniforms.u_backgroundHeight.value = backgroundHeight ?? 0;
        uniforms.u_backgroundX.value = backgroundPos?.x ?? 0;
        uniforms.u_backgroundY.value = backgroundPos?.y ?? 0;
        uniforms.u_canvasSize.value = new Vector2(canvasSize.width, canvasSize.height);

        const mesh = ref.current;
        template.onBeforeRender?.({
            context: state,
            material: shaderRef.current!,
            grid: grid,
            light: resolvedLight,
            mesh: mesh,
            pointLight: pointLightRef.current,
            setPosition: pos => {
                if (useSdf) {
                    // Soft shadows are on, we can specify the light position as a uniform.
                    uniforms.u_position.value = new Vector2(pos.x, pos.y);
                } else {
                    // No distance field, so soft shadows is off. We have to set the position of the light manually.
                    if (segments) {
                        const polys = getSegmentsForSource(pos, segments);
                        updateGeometryForSegments(mesh, pos, polys);
                    }
                }

                if (pointLightRef.current) {
                    const x = isOrthographic ? pos.x / scale : pos.x;
                    const y = isOrthographic ? -pos.y / scale : -pos.y;
                    pointLightRef.current.position.set(x, y, pointLightRef.current.position.z);
                }
            },
        });
    }, 0);

    // TODO: Not sure why but the position of lights is off by the scale factor in orthographic mode.
    const pos: [number, number, number] = isOrthographic
        ? [polygons.pos.x / scale, -polygons.pos.y / scale, location.tileSize.width * 2]
        : [polygons.pos.x, -polygons.pos.y, location.tileSize.width * 2];

    return (
        <React.Fragment>
            {hasPerspectiveLighting && (
                <UiLayer>
                    <pointLight
                        ref={pointLightRef}
                        layers={VttCameraLayers.PerspectiveLighting}
                        castShadow
                        position={pos}
                        distance={far * grid.tileSize.width}
                        intensity={light.brightness ?? 1}
                        color={new Color(color.x, color.y, color.z)}
                    />
                </UiLayer>
            )}

            <mesh
                layers={VttCameraLayers.DefaultNoRaycasting}
                ref={ref}
                position={polygons.segments ? [0, 0, 0] : [polygons.pos.x, -polygons.pos.y, 0]}>
                <shaderMaterial
                    ref={shaderRef}
                    attach="material"
                    fragmentShader={template.shader}
                    side={FrontSide}
                    transparent
                    uniforms={uniforms}
                />
            </mesh>
        </React.Fragment>
    );
};

const cutawayShader = `
uniform sampler2D u_visibility;
uniform sampler2D u_lighting;
uniform sampler2D u_darkvision;
uniform float u_opacity;

varying vec2 vUv;

void main() {
    // Calculate the lighting level at this pixel. Mostly we want to cut away the level if there's enough light
    // to see anything underneath, but taper off at the edges where there's almost no light to see by anyway.
    float lighting = smoothstep(0.0, 0.1, max(texture2D(u_lighting, vUv).a, texture2D(u_darkvision, vUv).a));

    // Intersect that lighting with the visibility. Where there is light to see (or within darkvision range) AND
    // it's not blocked by anything, then we cut away that pixel and reveal the level beneath.
    vec4 visibility = texture2D(u_visibility, vUv);
    gl_FragColor = vec4(0.0, 1.0, 0.0, visibility.a * lighting * u_opacity);
}`;

const CutawayNode: FunctionComponent<{
    grid: IGrid;
    uniforms: { [uniform: string]: IUniform<any> };
    opacity: MotionValue<number>;
    size: Size;
}> = ({ grid, uniforms, opacity, size }) => {
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        animate(opacity, isPresent ? 1 : 0, {
            onComplete: safeToRemove ?? undefined,
            duration: 0.3,
            ease: "easeInOut",
        });
    }, [isPresent, safeToRemove, opacity]);

    return (
        <mesh position={[-(grid.offset.x - size.width / 2), -(-grid.offset.y + size.height / 2), ZIndexes.Overlay]}>
            <planeGeometry attach="geometry" args={[size.width, size.height]} />
            <shaderMaterial
                attach="material"
                fragmentShader={cutawayShader}
                vertexShader={basicVertexShader}
                transparent
                uniforms={uniforms}
            />
        </mesh>
    );
};

const maskShader =
    ShaderChunk.logdepthbuf_pars_fragment +
    `
${commonShaderUniforms}
uniform sampler2D u_cutaway;
uniform bool u_useCutaway;

uniform sampler2D u_visibility;
uniform sampler2D u_lighting;
uniform sampler2D u_darkvision;
uniform sampler2D u_fowsdf;
uniform float u_fowsdfSize;
uniform sampler2D u_obstructionsdf;
uniform float u_obstructionsdfSize;
uniform sampler2D u_background;
uniform sampler2D u_main;
uniform float u_maskOpacity;
uniform float u_opacity;

uniform float u_tileSize;

uniform bool u_isPerspective;

varying vec2 vUv;

${commonShaderFunctions}

void main() {
    // if (u_useCutaway) {
    //     gl_FragColor = texture2D(u_cutaway, vUv);
    //     return;
    // }

    vec2 localFragCoord = u_isPerspective ? vec2((vUv.x * u_backgroundWidth) + u_backgroundX, ((1.0 - vUv.y) * u_backgroundHeight) + u_backgroundY) : toLocalPoint(fragCoordToScreenPoint(gl_FragCoord.xy));

    // The visibility texture has an alpha value of 1 where the character can see.
    vec4 visibility = texture2D(u_visibility, vUv);

    // The lighting texture has the colour of lighting, already adjusted for visibility.
    vec4 lighting = texture2D(u_lighting, vUv);

    // The darkvision texture has the area that can be seen using darkvision.
    vec4 darkvision = texture2D(u_darkvision, vUv);

    vec4 background = texture2D(u_background, vUv);

    // The main texture contains the actual unlit scene containing the background, tokens, etc.
    vec4 scene = texture2D(u_main, vUv);

    float la = lighting.a * max(1.0 - u_maskOpacity, visibility.a);

    // Get the distance to both the edge of the FOW and the nearest obstruction.
    // We want to fade out near the edge of the FOW, but only if it's not near an obstruction.
    float distToFowEdge = getDistanceValue(u_fowsdf, u_fowsdfSize, localFragCoord);
    float distToObstruction = getDistanceValue(u_obstructionsdf, u_obstructionsdfSize, localFragCoord);
    float fowcover = clamp(distToFowEdge, 0.0, 1.0);
    if (distToObstruction <= 0.0 || localFragCoord.x <= 0.0 || localFragCoord.y <= 0.0 || localFragCoord.x >= u_backgroundWidth || localFragCoord.y >= u_backgroundHeight) {
        fowcover = 0.0;
    }

    // FOW only starts kicking in at ~50% lighting (y = max(0, 1 - 2x)), where y = fow and x = lighting/darkvision.
    float lc = min(max(0.0, 1.0 - (2.0 * la)), fowcover);
    
    // 0 at FOW edge, 1 at X pixels or more away from edge
    float fowf = min(distToFowEdge / min(distToObstruction, (u_tileSize / 4.0)), 1.0);
    
    // Apply an easing to the FOW edge fade
    fowf = 1.0 - (1.0 - fowf) * (1.0 - fowf) * (1.0 - fowf) * (1.0 - fowf);

    // Calculate the final FOW alpha value.
    float fowa = lc * fowf * u_maskOpacity;

    // float ba = 1.0 - la - fowa;

    vec4 sceneBG = (background * (1.0 - scene.a)) + scene;

    // Add the lighting.
    gl_FragColor = mix(sceneBG, vec4(sceneBG.r * lighting.r, sceneBG.g * lighting.g, sceneBG.b * lighting.b, 1.0), u_maskOpacity);

    // Add the darkvision.
    float da = min(1.0 - la, darkvision.a) * u_maskOpacity;
    float dvgv = (0.21 * sceneBG.r + 0.71 * sceneBG.g + 0.07 * sceneBG.b) * da * 0.5;
    gl_FragColor = vec4(max(dvgv, gl_FragColor.r), max(dvgv, gl_FragColor.g), max(dvgv, gl_FragColor.b), gl_FragColor.a);

    // Add the FOW.
    float fowgv = 0.21 * background.r + 0.71 * background.g + 0.07 * background.b;
    vec4 fowGrey = vec4(fowgv, fowgv, fowgv, 1);
    vec4 fowv = vec4(fowGrey.rgb * 0.1, fowa);

    // If the area isn't visible, then we still want to show the FOW, but it shouldn't include any lighting,
    // so if it's not visible we use black instead of the current frag color.
    gl_FragColor = mix(vec4(0.0, 0.0, 0.0, 1.0), gl_FragColor, visibility.a) + ((fowv * fowv.a) * fowa);

    // Account for the opacity of the background or the main scene.
    gl_FragColor.a = gl_FragColor.a * max(background.a, scene.a) * u_opacity;

    if (u_useCutaway) {
        gl_FragColor.a = gl_FragColor.a * (1.0 - texture2D(u_cutaway, vUv).a);
    }

    // Apply visibility even outside the scene/background image bounds.
    gl_FragColor = mix(vec4(0.0, 0.0, 0.0, 1.0), gl_FragColor, max(visibility.a, fowa));

    // DEBUG: Show where the obstructions (red) and FOW (green) are.
    // gl_FragColor = distToObstruction <= 0.0 ? vec4(1.0, 0.0, 0.0, 1.0) : vec4(1.0, 1.0, 1.0, 1.0);
    // gl_FragColor = distToFowEdge <= 0.0 ? mix(gl_FragColor, vec4(0.0, 1.0, 0.0, 1.0), 0.5) : gl_FragColor;

    ` +
    ShaderChunk.logdepthbuf_fragment +
    `
}`;

const green = new Color(0, 1, 0);
const red = new Color(1, 0, 0);

export const VisibilityMask: FunctionComponent<VisibilityMaskProps> = ({
    canvasSize,
    backgroundScene,
    mainScene,
    grid,
    lights,
    isVisible,
    levelKey,
    backgroundTileData,
    levelInfo,
    renderInfo,
    segments,
    tempFbo,
    hasVisionSource,
    camera,
}) => {
    const { session } = useSession();

    const { campaign, location, api, system, ...currentLevel } = useValidatedLocationLevel();

    const level = location.levels[levelKey];
    const clipper = useClipper();

    const visibilityTarget = renderInfo.visibility;
    const lightingTarget = renderInfo.lighting;
    const darkvisionTarget = renderInfo.darkvision;

    const backgroundWidth = renderInfo.size?.width;
    const backgroundHeight = renderInfo.size?.height;
    const backgroundPosX = level?.backgroundImagePos?.x ?? 0;
    const backgroundPosY = level?.backgroundImagePos?.y ?? 0;

    const obstructionsChanged = useRef<boolean>();

    const outdoorLightMaterialRef = useRef<MeshBasicMaterial>(null);
    const dayNightOpacity = useMotionValue(0);
    const dayNightColor = useMotionValue("#FFFFFF");

    const [useSoftShadows] = useLocalSetting(softShadowsSetting, true);
    const clipLights = useRef<boolean>(!useSoftShadows);
    const visibilityPolys = useRef<Map<string, { pos: LocalPixelPosition; segments: [Point, Point][] }>>();
    const lightingPolys = useRef<
        Map<
            string,
            {
                pos: LocalPixelPosition;
                light: PositionedLight;
                segments?: [Point, Point][];
                clipperPolygons: Paths;
                ellipse: EllipseCurve;
            }
        >
    >();
    const darkvisionPolys = useRef<
        Map<
            string,
            {
                pos: LocalPixelPosition;
                token: Token;
                darkvision: number;
                segments: [Point, Point][];
                clipperPolygons: Paths;
            }
        >
    >();
    const visibleAreaRef = useRef<ReadonlyPaths>();
    const lightLevelRef = useRef<number>();
    const fowUpdateRef = useRef<number>();
    const fowRef = useRef<ReadonlyPaths>();
    const isFowUpdatedRef = useRef<boolean>(false);
    const obstructionSdfDeps = useRef(0);

    const { isPerspective, hasPerspectiveLighting } = useCamera();

    const forceUpdate = useForceUpdate();

    if (!fowRef.current) {
        fowRef.current = [];
    }

    useEffect(() => {
        const handler: (args: { locationId: string; levels: string[] }) => void = ({ locationId, levels }) => {
            if (locationId === location.id && levels.indexOf(levelKey) >= 0) {
                fowRef.current = [];
                isFowUpdatedRef.current = true;
                forceUpdate();
            }
        };

        api.fowReset.on(handler);
        (async () => {
            const clipper = await clipperPromise;

            // TODO: Deal with errors?
            const savedFow = await api.getFow(location.id, levelKey);
            if (savedFow) {
                // Merge the saved fow with the current one, in case that managed to load first.
                if (mergeFow(fowRef, savedFow, clipper)) {
                    isFowUpdatedRef.current = true;
                    forceUpdate();
                }
            }
        })();

        return () => {
            api.fowReset.off(handler);
        };
    }, [location.id, api]); // eslint-disable-line react-hooks/exhaustive-deps

    // Check whether the obstructions have changed at all. If they have, we'll have to update a bunch of the lighting and visibility geometry.
    const segmentsRef = useRef<Segment[]>();
    if (segmentsRef.current !== segments) {
        segmentsRef.current = segments;
        obstructionsChanged.current = true;
    }

    const sources = Array.from(levelInfo[levelKey].sources);

    // Whether or not to clip the light polygon to exclude areas that are not visible because of obstructions.
    // If the visibility clipping is done in the shader for the currently selected option then we don't want to do it here.
    // We still need to calculate the polys for the FOW, though.
    const oldClipLights = clipLights.current;
    clipLights.current = !useSoftShadows;
    const shouldApplyMask = !!(sources.length || location.showLightingInBuild);
    const applyMask = segmentsRef.current && shouldApplyMask;
    let visibilityChanged = false;
    if (applyMask && clipper) {
        const oldLightingPolys = lightingPolys.current;
        lightingPolys.current = new Map();
        let lightingChanged = false;
        for (let i = 0; i < lights.length; i++) {
            // If the old lighting already had a result for this source, and the light pos and segments haven't changed, then we can use it.
            const pos = lights[i].pos;
            const oldPoly =
                obstructionsChanged.current || oldClipLights !== clipLights.current
                    ? undefined
                    : oldLightingPolys?.get(lights[i].id);
            if (oldPoly?.light.pos.x === pos.x && oldPoly?.light.pos.y === pos.y) {
                // The light pos and obstructions haven't changed, so we can reuse the last polys.
                lightingPolys.current.set(lights[i].id, oldPoly);
            } else {
                const template = lightTemplates[lights[i].type];
                const radius =
                    (lights[i].outerRadius ?? template?.defaults?.outerRadius ?? system.defaultLight.outerRadius) *
                    grid.tileSize.width;
                if (radius > 0) {
                    const lp = grid.toLocalCenterPoint(pos);
                    const polys = getSegmentsForSource(lp, segmentsRef.current!);

                    let visibilityPolygon = segmentsToClipperPoly(polys);

                    // TODO: Might be more efficient to do this ourselves?
                    const ellipseCurve = new EllipseCurve(pos.x, pos.y, radius, radius, 0, Math.PI * 2, false, 0);
                    const outerRadiusPoints = ellipseCurve
                        .getPoints(64)
                        .map(o => ({ x: Math.round(o.x), y: Math.round(o.y) }));
                    const clipperPolygons = clipper.clipToPaths({
                        clipType: ClipType.Intersection,
                        subjectFillType: PolyFillType.NonZero,
                        subjectInputs: [{ data: visibilityPolygon, closed: true }],
                        clipInputs: [{ data: outerRadiusPoints }],
                    });

                    if (clipperPolygons) {
                        lightingPolys.current.set(lights[i].id, {
                            pos: lp,
                            light: lights[i],
                            segments: clipLights.current ? polys : undefined,
                            clipperPolygons: clipperPolygons,
                            ellipse: ellipseCurve,
                        });
                        lightingChanged = true;
                    }
                }
            }
        }

        if (oldLightingPolys?.size !== lightingPolys.current.size) {
            lightingChanged = true;
        }

        const oldDarkvisionPolys = darkvisionPolys.current;
        darkvisionPolys.current = new Map();
        const oldVisibilityPolys = visibilityPolys.current;
        visibilityPolys.current = new Map();

        for (let i = 0; i < sources.length; i++) {
            // If the old visibility already had a result for this source, and the segments haven't changed, then we can use it.
            const pos = grid.toLocalCenterPoint(sources[i].pos, sources[i].scale);
            const oldPoly =
                obstructionsChanged.current || oldClipLights !== clipLights.current
                    ? undefined
                    : oldVisibilityPolys?.get(sources[i].id);
            let polys: [Point, Point][];
            if (oldPoly?.pos.x === pos.x && oldPoly?.pos.y === pos.y) {
                polys = oldPoly.segments;
                visibilityPolys.current.set(sources[i].id, oldPoly);
            } else {
                // Otherwise, generate it anew and store it for future reference.
                polys = getSegmentsForSource(pos, segmentsRef.current!);
                visibilityPolys.current.set(sources[i].id, { pos: pos, segments: polys });
                visibilityChanged = true;
            }

            // If the token has dark vision, then we need to deal with that too.
            // Darkvision lets you see in the dark, so it's close to a light originating from the source's position.
            const darkvision = system.getTokenDarkvision(sources[i], campaign, location);
            const oldDarkvision = obstructionsChanged.current ? undefined : oldDarkvisionPolys?.get(sources[i].id);
            if (
                oldDarkvision?.pos.x === pos.x &&
                oldDarkvision?.pos.y === pos.y &&
                oldDarkvision?.token === sources[i] &&
                oldDarkvision?.darkvision === darkvision
            ) {
                darkvisionPolys.current.set(sources[i].id, oldDarkvision);
            } else if (darkvision > 0) {
                // Combine polys with an ellipse of the correct size (using darkvision), and store it.
                const radius = darkvision * grid.tileSize.width;
                let visibilityPolygon = segmentsToClipperPoly(polys);

                // TODO: Might be more efficient to do this ourselves?
                const ellipseCurve = new EllipseCurve(pos.x, pos.y, radius, radius, 0, Math.PI * 2, false, 0);
                const outerRadiusPoints = ellipseCurve
                    .getPoints(64)
                    .map(o => ({ x: Math.round(o.x), y: Math.round(o.y) }));
                const clipperPolygons = clipper.clipToPaths({
                    clipType: ClipType.Intersection,
                    subjectFillType: PolyFillType.NonZero,
                    subjectInputs: [{ data: visibilityPolygon, closed: true }],
                    clipInputs: [{ data: outerRadiusPoints }],
                });

                if (clipperPolygons) {
                    darkvisionPolys.current.set(sources[i].id, {
                        pos: pos,
                        token: sources[i],
                        darkvision: darkvision,
                        segments: polys,
                        clipperPolygons: clipperPolygons,
                    });
                    lightingChanged = true;
                }
            }
        }

        if (oldVisibilityPolys?.size !== visibilityPolys.current.size) {
            visibilityChanged = true;
        }

        if (oldDarkvisionPolys?.size !== darkvisionPolys.current.size) {
            lightingChanged = true;
        }

        // If the visibility has changed, recalculate the total area covered by visibility.
        if (visibilityChanged || !visibleAreaRef.current) {
            if (visibilityPolys.current.size > 0) {
                // Combine the visible areas for each vision source.
                const visPoly: SubjectInput[] = Array.from(visibilityPolys.current.values()).map(o => ({
                    data: segmentsToClipperPoly(o.segments),
                    closed: true,
                }));
                if (visPoly.length > 1) {
                    visibleAreaRef.current = clipper.clipToPaths({
                        clipType: ClipType.Union,
                        subjectFillType: PolyFillType.NonZero,
                        subjectInputs: visPoly,
                    });
                } else {
                    visibleAreaRef.current = [visPoly[0].data as ReadonlyPath];
                }
            } else {
                visibleAreaRef.current = undefined;
            }
        }

        // If location visibility has changed to or from 0 (i.e. players can now see everything in LOS even if not explicitly lit), or the visibility sources have changed, or any lights
        // have been updated... then we update the FOW.
        const lightLevel = level?.lightLevel ?? 1;
        if (
            (((lightLevel === 0 || lightLevelRef.current === 0) && lightLevel !== lightLevelRef.current) ||
                visibilityChanged ||
                lightingChanged ||
                isFowUpdatedRef.current) &&
            visibleAreaRef.current &&
            clipper
        ) {
            try {
                let visibleLitArea: Paths | undefined = visibleAreaRef.current as Paths;

                // Don't clip the visible area by the area visible to lights if the location
                // has a light level greater than 0.
                if (lightLevel === 0) {
                    const lightsById = lightingPolys.current;

                    const litAreas = [
                        ...lights
                            .filter(o => lightsById.get(o.id)?.clipperPolygons)
                            .map(o => lightsById.get(o.id)?.clipperPolygons!),
                        ...sources
                            .filter(o => darkvisionPolys.current?.get(o.id)?.clipperPolygons)
                            .map(o => darkvisionPolys.current!.get(o.id)!.clipperPolygons!),
                    ];
                    const litArea = clipper.clipToPaths({
                        clipType: ClipType.Union,
                        subjectFillType: PolyFillType.NonZero,
                        subjectInputs: litAreas.map(o => ({
                            data: o,
                            closed: true,
                        })),
                    });

                    visibleLitArea = clipper.clipToPaths({
                        clipType: ClipType.Intersection,
                        subjectFillType: PolyFillType.NonZero,
                        subjectInputs: [
                            {
                                data: visibleLitArea,
                                closed: true,
                            },
                        ],
                        clipInputs: [{ data: litArea! }],
                    });
                }

                lightLevelRef.current = lightLevel;

                if (visibleLitArea && visibleLitArea.length && mergeFow(fowRef, visibleLitArea, clipper)) {
                    isFowUpdatedRef.current = true;

                    // Don't save the FOW every time it changes, that's a lot - save it after it STOPS changing.
                    clearTimeout(fowUpdateRef.current);
                    const fow = fowRef.current;
                    if (fow) {
                        fowUpdateRef.current = setTimeout(() => {
                            api.updateFow(location.id, levelKey, fow as Paths);
                        }, 1000) as any;
                    }
                }
            } catch (error) {
                console.error(`Error updating FOW.`);
                console.error(error);
            }
        }

        if (obstructionsChanged.current) {
            obstructionSdfDeps.current++;
            obstructionsChanged.current = false;
        }
    }

    const cutawayUniforms = useMemo(
        () => ({
            u_visibility: { type: "t", value: undefined as Texture | undefined },
            u_lighting: { type: "t", value: undefined as Texture | undefined },
            u_darkvision: { type: "t", value: undefined as Texture | undefined },
            u_opacity: { value: 0 },
        }),
        []
    );

    const uniforms = useMemo(
        () => ({
            u_visibility: { type: "t", value: undefined as Texture | undefined },
            u_lighting: { type: "t", value: undefined as Texture | undefined },
            u_darkvision: { type: "t", value: undefined as Texture | undefined },
            u_fowsdf: { type: "t", value: undefined as Texture | undefined },
            u_fowsdfSize: { value: 0 },
            u_obstructionsdf: { type: "t", value: undefined as Texture | undefined },
            u_obstructionsdfSize: { value: 0 },
            u_background: { type: "t", value: undefined as Texture | undefined },
            u_main: { type: "t", value: undefined as Texture | undefined },
            u_maskOpacity: { value: 1 },
            u_opacity: { value: 1 },
            u_backgroundWidth: { value: 0 },
            u_backgroundHeight: { value: 0 },
            u_backgroundX: { value: 0 },
            u_backgroundY: { value: 0 },
            u_tileSize: { value: 0 },
            u_canvasSize: { value: undefined as Vector2 | undefined }, // The width/height of the canvas we're drawing to.
            u_scale: { value: 1 },
            u_offset: { value: undefined as Vector2 | undefined },
            u_cutaway: { type: "t", value: undefined as Texture | undefined },
            u_useCutaway: { value: false },
            u_isPerspective: { value: false },
        }),
        []
    );
    uniforms.u_visibility.value = visibilityTarget.texture;
    uniforms.u_lighting.value = lightingTarget.texture;
    uniforms.u_darkvision.value = darkvisionTarget.texture;
    uniforms.u_background.value = backgroundScene.texture;
    uniforms.u_cutaway.value = tempFbo.texture;
    uniforms.u_main.value = mainScene.texture;
    uniforms.u_backgroundWidth.value = backgroundWidth ?? 0;
    uniforms.u_backgroundHeight.value = backgroundHeight ?? 0;
    uniforms.u_tileSize.value = location.tileSize.width;
    uniforms.u_backgroundX.value = backgroundPosX;
    uniforms.u_backgroundY.value = backgroundPosY;
    uniforms.u_isPerspective.value = isPerspective;

    uniforms.u_canvasSize.value = new Vector2(canvasSize.width, canvasSize.height);
    uniforms.u_scale.value = grid.scale;
    uniforms.u_offset.value = new Vector2(grid.offset.x, grid.offset.y);

    const maskOpacity = useMotionValue(shouldApplyMask ? 1 : 0);
    useEffect(() => {
        animate(maskOpacity, shouldApplyMask ? 1 : 0, { duration: 0.3, ease: "easeInOut" });
    }, [shouldApplyMask, maskOpacity]);

    const occupiedOpacity = 0.2;
    const overallOpacity = useMotionValue(isVisible ? 1 : 0);

    // Check if there are any tokens from LOWER levels that are underneath a non-transparent part of this level.
    const levelHeight = levelInfo[levelKey].height;
    const isOccupied =
        level.occupiedOpacity != null &&
        level.occupiedOpacity < 1 &&
        backgroundTileData &&
        Object.values(levelInfo)
            .filter(o => o.height < levelHeight)
            .some(o => {
                return Array.from(o.sources).some(t => {
                    const pos = grid.toGridPoint(t.pos);

                    const p1 = getPixel(
                        backgroundTileData,
                        pos.x - Math.ceil(backgroundPosX / location.tileSize.width),
                        pos.y - Math.ceil(backgroundPosY / location.tileSize.height)
                    );
                    if (p1.a / 255 > occupiedOpacity) {
                        return true;
                    }

                    const p2 = getPixel(
                        backgroundTileData,
                        pos.x - Math.floor(backgroundPosX / location.tileSize.width),
                        pos.y - Math.floor(backgroundPosY / location.tileSize.height)
                    );
                    if (p2.a / 255 > occupiedOpacity) {
                        return true;
                    }

                    const p3 = getPixel(
                        backgroundTileData,
                        pos.x - Math.floor(backgroundPosX / location.tileSize.width),
                        pos.y - Math.ceil(backgroundPosY / location.tileSize.height)
                    );
                    if (p3.a / 255 > occupiedOpacity) {
                        return true;
                    }

                    const p4 = getPixel(
                        backgroundTileData,
                        pos.x - Math.ceil(backgroundPosX / location.tileSize.width),
                        pos.y - Math.floor(backgroundPosY / location.tileSize.height)
                    );
                    if (p4.a / 255 > occupiedOpacity) {
                        return true;
                    }

                    return false;
                });
            });
    useEffect(() => {
        let opacity = isVisible ? (isOccupied ? level.occupiedOpacity ?? 1 : 1) : 0;
        animate(overallOpacity, opacity, { duration: 0.3, ease: "easeInOut" });
    }, [overallOpacity, level.occupiedOpacity, isVisible, isOccupied]);

    // This generates a 2D SDF (signed distance field) canvas, where each pixel contains the distance to the nearest
    // obstruction. This can then be used to generate soft shadows for lighting, as per:
    // https://www.rykap.com/2020/09/23/distance-fields/
    // or
    // https://www.ronja-tutorials.com/post/037-2d-shadows/
    // TODO: It could be improved by removing the need for Canvases and the custom graphics implementations and just using r3f/three.
    // It could all be done in the render loop we already have.

    const obstructionSdf = useDistanceField(
        {
            width: backgroundWidth,
            height: backgroundHeight,
            onDraw: (ctx, size) => {
                if (segmentsRef.current) {
                    const xRatio = size.actual.width / size.desired.width;
                    const yRatio = size.actual.height / size.desired.height;
                    ctx.beginPath();
                    for (let i = 0; i < segmentsRef.current!.length; i++) {
                        const segment = segmentsRef.current![i];
                        ctx.moveTo(
                            (Math.round(segment.p1.x) - backgroundPosX) * xRatio,
                            (Math.round(segment.p1.y) - backgroundPosY) * yRatio
                        );
                        ctx.lineTo(
                            (Math.round(segment.p2.x) - backgroundPosX) * xRatio,
                            (Math.round(segment.p2.y) - backgroundPosY) * yRatio
                        );
                    }

                    ctx.stroke();
                    ctx.closePath();
                    return true;
                }

                return false;
            },
        },
        [obstructionSdfDeps.current]
    );
    uniforms.u_obstructionsdf.value = obstructionSdf;
    const obstructionCanvas = (obstructionSdf as any)?.source.data as HTMLCanvasElement;
    uniforms.u_obstructionsdfSize.value = obstructionCanvas ? obstructionCanvas.width : 0;

    const fowSdfDeps = useRef(0);
    if (isFowUpdatedRef.current) {
        fowSdfDeps.current++;
        isFowUpdatedRef.current = false;
    }

    const fowSdf = useDistanceField(
        {
            width: backgroundWidth,
            height: backgroundHeight,
            invert: true,
            onDraw: (ctx, size) => {
                if (fowRef.current) {
                    // Check if the FOW is everything or nothing - either way we can't produce a SDF.
                    if (fowRef.current.length === 0) {
                        // Nothing is revealed by FOW.
                        ctx.fillRect(0, 0, 1, 1);
                        return true;
                    } else {
                        // For some reason we can sometimes get multiple paths even if one path is entirely contained by another.
                        for (let j = 0; j < fowRef.current.length; j++) {
                            if (fowRef.current[j].length === 4) {
                                let corners = 4;
                                for (let i = 0; i < 4; i++) {
                                    if (
                                        (fowRef.current[j][i].x === 0 && fowRef.current[j][i].y === 0) ||
                                        (fowRef.current[j][i].x === backgroundWidth && fowRef.current[j][i].y === 0) ||
                                        (fowRef.current[j][i].x === backgroundWidth &&
                                            fowRef.current[j][i].y === backgroundHeight) ||
                                        (fowRef.current[j][i].x === 0 && fowRef.current[j][i].y === backgroundHeight)
                                    ) {
                                        corners--;
                                    }
                                }

                                if (corners === 0) {
                                    // Everything is revealed by FOW.
                                    ctx.fillRect(0, 0, size.actual.width, size.actual.height);
                                    ctx.clearRect(0, 0, 1, 1);
                                    return true;
                                }
                            }
                        }
                    }

                    const xRatio = size.actual.width / size.desired.width;
                    const yRatio = size.actual.height / size.desired.height;
                    for (let path of fowRef.current) {
                        ctx.beginPath();
                        ctx.moveTo(
                            (Math.round(path[0].x) - backgroundPosX) * xRatio,
                            (Math.round(path[0].y) - backgroundPosY) * yRatio
                        );

                        for (let i = 1; i < path.length; i++) {
                            ctx.lineTo(
                                (Math.round(path[i].x) - backgroundPosX) * xRatio,
                                (Math.round(path[i].y) - backgroundPosY) * yRatio
                            );
                        }

                        ctx.lineTo(
                            (Math.round(path[0].x) - backgroundPosX) * xRatio,
                            (Math.round(path[0].y) - backgroundPosY) * yRatio
                        );
                        ctx.fill();
                        ctx.closePath();
                    }

                    return true;
                }

                return false;
            },
        },
        [fowSdfDeps.current]
    );
    uniforms.u_fowsdf.value = fowSdf;
    const fowCanvas = (fowSdf as any)?.source.data as HTMLCanvasElement;
    uniforms.u_fowsdfSize.value = fowCanvas ? fowCanvas.width : 0;
    const lightsSceneRef = useRef<Scene>(undefined as any);
    const visSceneRef = useRef<Scene>(undefined as any);
    const darkvisionSceneRef = useRef<Scene>(undefined as any);
    const cutoutSceneRef = useRef<Scene>(undefined as any);

    // Work out which levels we should cut away visibility for.
    // Don't specify anything if there are no vision sources (i.e. GM with no vision source selected).
    const cutawayOpacity = useMotionValue(0);
    const cutawayLevelsRef = useRef<SingleLevelInfo[]>();
    let cutawayLevels: SingleLevelInfo[] | undefined;
    if (hasVisionSource && level.cutAway) {
        const levelHeight = levelInfo[levelKey].height;
        const levels = Object.values(levelInfo);
        cutawayLevels = levels.filter(o => o.height < levelHeight && o.renderInfo).sort(o => o.height);
        cutawayLevelsRef.current = cutawayLevels;
    } else {
        animate(cutawayOpacity, 0, { duration: 0.3, ease: "easeInOut" });
    }

    const ambientLightRef = useRef<AmbientLight>(null);
    const sunLightRef = useRef<DirectionalLight>(null);
    const sunLightTargetRef = useRef<Object3D>(null);
    // const sunLightHelperRef = useRef<DirectionalLightHelper | null>(null);

    const mainMeshRef = useRef<Mesh>(null);

    useFrame(state => {
        if (mainMeshRef.current) {
            mainMeshRef.current.position.set(
                -(grid.offset.x - canvasSize.width / 2),
                -(-grid.offset.y + canvasSize.height / 2),
                0
            );
        }

        if (!lightsSceneRef.current || !visSceneRef.current || !darkvisionSceneRef.current) {
            return;
        }

        if (outdoorLightMaterialRef.current) {
            if (!level?.disableDayNight) {
                const gameTime = getGameTime(session.time);
                const { color, lightLevel } = getDayNightColor(gameTime);

                if (!dayNightOpacity.isAnimating()) {
                    dayNightOpacity.set(lightLevel);
                }

                if (!dayNightColor.isAnimating()) {
                    dayNightColor.set("#" + color.getHexString());
                }

                if (sunLightRef.current) {
                    // The sun rotates around the middle of the map, around the y axis.
                    const p = localPoint(-sunDistance, 0);
                    let timeOfDay = (gameTime % msPerDay) / msPerDay;

                    // The speed of the rotation depends on the dawn/sunset times. If the day is short we rotate fast during the day
                    // and slowly at night (and vice versa).
                    const dayDuration = defaultDayTimes.sunset - defaultDayTimes.dawn;
                    if (timeOfDay < defaultDayTimes.dawn) {
                        // Linearly, dawn would be at 6am. Adjust timeOfDay so that the sun hits horizontal at actual dawn.
                        timeOfDay = (0.25 / defaultDayTimes.dawn) * timeOfDay;
                    } else if (timeOfDay > defaultDayTimes.sunset) {
                        // Linearly, sunset would be at 6pm. Adjust timeOfDay so that the sun hits horizontal at actual sunset.
                        timeOfDay = (timeOfDay - defaultDayTimes.sunset) * (0.25 / (1 - defaultDayTimes.sunset)) + 0.75;
                    } else {
                        // Adjust the time of day so that the sun reaches the horizon at the correct time for the current daytime duration.
                        timeOfDay = (timeOfDay - defaultDayTimes.dawn) * (0.5 / dayDuration) + 0.25;
                    }

                    const rotatedSunPos = rotate(p, emptyPoint, timeOfDay * 360);
                    sunLightRef.current.position.set(
                        -rotatedSunPos.y + (renderInfo.totalSize?.width ?? 0) / 2,
                        -(renderInfo.totalSize?.height ?? 0) / 2,
                        rotatedSunPos.x
                    );
                }
            } else {
                // No day/night cycle - assume the directional light comes from directly above.
                sunLightRef.current?.position.set(0, 0, sunDistance);
            }

            const ambientLightColor = new Color(dayNightColor.get());
            outdoorLightMaterialRef.current.opacity = dayNightOpacity.get();
            outdoorLightMaterialRef.current.color = ambientLightColor;
            if (ambientLightRef.current) {
                ambientLightRef.current.color.copy(ambientLightColor);
                ambientLightRef.current.intensity = dayNightOpacity.get() * 0.3;
            }

            if (sunLightRef.current) {
                sunLightRef.current.color.copy(ambientLightColor);
                sunLightRef.current.intensity = dayNightOpacity.get();
                sunLightRef.current.target = sunLightTargetRef.current ?? defaultObject3D;

                // if (!sunLightHelperRef.current) {
                //     sunLightHelperRef.current = new DirectionalLightHelper(sunLightRef.current, 200);
                //     state.scene.add(sunLightHelperRef.current);
                // } else {
                //     sunLightHelperRef.current.update();
                // }
            }
        }

        // Render the area that should be visible to the visibility FBO.
        state.gl.setRenderTarget(visibilityTarget);
        state.gl.render(visSceneRef.current, camera);

        // Render the lighting to the lighting FBO.
        state.gl.setRenderTarget(lightingTarget);
        state.gl.render(lightsSceneRef.current, camera);

        // Render darkvision area to the darkvision FBO.
        state.gl.setRenderTarget(darkvisionTarget);
        state.gl.render(darkvisionSceneRef.current, camera);
    }, RenderOrder.VisibilityAndLighting);

    useFrame((state, delta) => {
        // TODO: If the opacity is 0, don't draw at all.
        // At the moment if we enable this, then we don't get the correct result. Need to do a clear, but the clear doesn't
        // seem to actually clear with no alpha, even if we set the clear alpha.
        const opacity = overallOpacity.get();

        uniforms.u_opacity.value = opacity;

        // Render all the cutaways for levels below this one. We have to do this inline with the main render for this level, because we're
        // using a shared render target to reduce memory usage (so we can't do it in different useFrames, must be this one, otherwise it
        // will be overwritten before we've used it). This results in some slightly unusual sharing between here and CutawayNode.
        cutawayUniforms.u_opacity.value = cutawayOpacity.get();
        if (cutawayUniforms.u_opacity.value > 0 && cutawayLevelsRef.current && cutawayLevelsRef.current.length > 0) {
            state.gl.setRenderTarget(tempFbo);
            state.gl.autoClear = false;
            state.gl.clear();

            // Render a combination of the lighting and visibility for each level under this one, which will produce
            // a texture containing where we want to cut away the current level to see what's underneath.
            for (let i = 0; i < cutawayLevelsRef.current.length; i++) {
                const cutawayLevel = cutawayLevelsRef.current[i];

                // TODO: At the moment this will double the effect if there are two overlapping holes in levels - it should just take the max.
                if (cutawayLevel.sources.size > 0) {
                    cutawayUniforms.u_visibility.value = cutawayLevel.renderInfo!.visibility.texture;
                    cutawayUniforms.u_lighting.value = cutawayLevel.renderInfo!.lighting.texture;
                    cutawayUniforms.u_darkvision.value = cutawayLevel.renderInfo!.darkvision.texture;

                    state.gl.render(cutoutSceneRef.current, camera);
                }
            }

            state.gl.autoClear = true;
        }

        uniforms.u_useCutaway.value = cutawayUniforms.u_opacity.value > 0;
        uniforms.u_maskOpacity.value = maskOpacity.get();
    }, RenderOrder.BeforeFinalComposite);

    if (level) {
        if (level.disableDayNight) {
            animate(dayNightOpacity, level.lightLevel ?? 1, { duration: 0.3, ease: "easeInOut" });
            animate(dayNightColor, level.lightColor ?? defaultDayNightColor.color.getHexString(), {
                duration: 0.3,
                ease: "easeInOut",
            });
        } else {
            const { color, lightLevel } = getDayNightColor(getGameTime(session.time));
            animate(dayNightOpacity, lightLevel, { duration: 0.3, ease: "easeInOut" });
            animate(dayNightColor, "#" + color.getHexString(), { duration: 0.3, ease: "easeInOut" });
        }
    }

    const oldGlobalVisibility = useRef(0);
    const globalVisibility = applyMask && sources.length > 0 && !level?.revealAll ? 0 : 1;
    if (oldGlobalVisibility.current !== globalVisibility) {
        isAnimatingGlobalVisibility = true;
        oldGlobalVisibility.current = globalVisibility;
    }

    const locationPos: [x: number, y: number, z: number] = [
        (renderInfo.totalSize?.width ?? 0) / 2 + (renderInfo.totalSize?.x ?? 0),
        -((renderInfo.totalSize?.height ?? 0) / 2 + (renderInfo.totalSize?.y ?? 0)),
        0,
    ];

    return (
        <React.Fragment>
            {currentLevel.levelKey === levelKey && hasPerspectiveLighting && (
                <React.Fragment>
                    <object3D
                        ref={sunLightTargetRef}
                        position={
                            renderInfo.totalSize
                                ? [renderInfo.totalSize.width / 2, -renderInfo.totalSize.height / 2, 0]
                                : undefined
                        }
                    />
                    <ambientLight layers={VttCameraLayers.PerspectiveLighting} ref={ambientLightRef} />
                    <directionalLight layers={VttCameraLayers.PerspectiveLighting} ref={sunLightRef} />
                </React.Fragment>
            )}

            {segmentsRef.current && (
                <React.Fragment>
                    <group visible={false}>
                        <scene ref={visSceneRef}>
                            <mesh
                                layers={VttCameraLayers.DefaultNoRaycasting}
                                position={[
                                    -(grid.offset.x - canvasSize.width / 2),
                                    -(-grid.offset.y + canvasSize.height / 2),
                                    0,
                                ]}>
                                {/* <planeGeometry attach="geometry" args={[renderInfo.totalSize?.width ?? 0, renderInfo.totalSize?.height ?? 0]} /> */}
                                <planeGeometry attach="geometry" args={[canvasSize.width, canvasSize.height]} />
                                <motion.meshBasicMaterial
                                    attach="material"
                                    blending={CustomBlending}
                                    blendEquation={AddEquation}
                                    blendEquationAlpha={MaxEquation}
                                    transition={{ duration: 0.3, ease: "easeInOut" }}
                                    initial={defaultInitial}
                                    animate={{ opacity: globalVisibility }}
                                    exit={defaultExit}
                                    onAnimationComplete={() => (isAnimatingGlobalVisibility = false)}
                                    color={red}
                                />
                            </mesh>
                            <group scale={[grid.scale, grid.scale, 1]}>
                                <AnimatePresence initial={false}>
                                    {sources?.map((o, i) => (
                                        <VisionSourceNode
                                            key={o.id}
                                            source={o}
                                            grid={grid}
                                            polygons={visibilityPolys.current?.get(o.id)}
                                        />
                                    ))}
                                </AnimatePresence>
                            </group>
                        </scene>
                        <scene scale={[grid.scale, grid.scale, 1]} ref={lightsSceneRef}>
                            <AnimatePresence initial={false}>
                                {lightingPolys.current &&
                                    segmentsRef.current &&
                                    lights.map(o => {
                                        const polygons = lightingPolys.current!.get(o.id);
                                        return (
                                            polygons && (
                                                <LightNode
                                                    key={o.id + "_" + o.type}
                                                    light={o}
                                                    polygons={polygons}
                                                    grid={grid}
                                                    segments={segmentsRef.current!}
                                                    sdf={useSoftShadows ? obstructionSdf : undefined}
                                                    useSdf={useSoftShadows}
                                                    backgroundWidth={backgroundWidth}
                                                    backgroundHeight={backgroundHeight}
                                                    backgroundPos={level.backgroundImagePos}
                                                    canvasSize={canvasSize}
                                                />
                                            )
                                        );
                                    })}
                            </AnimatePresence>
                            {/*
                    I've been thinking about how to do maps where some of the map is inside, and some of it is outside - how do we
                    show the inside darker, but the ambient outside light coming in? Could try:
                    1. Have separate lighting levels for the location and zones within that location.
                    2. Draw the indoor zones to a black/white canvas so that we can create an sdf, then draw the windows that are
                       bordering the outside in a different colour - not sure if we can do this, or if we need to draw only the windows,
                       then sdf the whole thing, then mask for where the zone is... but the point is, we get the distance to the nearest
                       door/window.
                    // TODO: Still have to worry about obstructions. Have to combine this with another sdf for obstructions? Also need
                    // to know the "light" position (i.e. the closest point of a window) to do the marching ray, so we'd have to do this
                    // as if each window was a light, but with two points so that we could calculate the closest point in the shader.
                    // This might be getting a bit out of hand... once it's calculated though, it only needs to be redone if the obstructions
                    // change.
                    3. Draw the ambient light using the sdfs using a marching ray shader. This can be reused until the obstructions or ambient
                       light changes - but even if the ambient light changes you can reuse the sdfs.
                    4. Draw the zone's ambient light.
                    // TODO: Technically this could spill OUT as well, but not sure if we want to tackle that. We could limit zones to specifying
                    // that they either use the ambient light or they have NO light.
                    */}
                            <mesh position={locationPos} layers={VttCameraLayers.DefaultNoRaycasting}>
                                <planeGeometry
                                    attach="geometry"
                                    args={[renderInfo.totalSize?.width ?? 0, renderInfo.totalSize?.height ?? 0]}
                                />
                                <motion.meshBasicMaterial
                                    ref={outdoorLightMaterialRef}
                                    attach="material"
                                    blending={CustomBlending}
                                    blendEquation={AddEquation}
                                    blendEquationAlpha={MaxEquation}
                                    initial={{ opacity: level?.disableDayNight ? level?.lightLevel ?? 1 : undefined }}
                                    animate={{ opacity: level?.disableDayNight ? level?.lightLevel ?? 1 : undefined }}
                                    transparent
                                />
                            </mesh>
                        </scene>
                        <scene scale={[grid.scale, grid.scale, 1]} ref={darkvisionSceneRef}>
                            <AnimatePresence initial={false}>
                                {darkvisionPolys.current &&
                                    sources.map(o => {
                                        const polygons = darkvisionPolys.current!.get(o.id);
                                        return (
                                            polygons && (
                                                <DarkvisionNode
                                                    key={`${o.id}`}
                                                    source={o}
                                                    grid={grid}
                                                    polygons={polygons}
                                                    backgroundWidth={backgroundWidth}
                                                    backgroundHeight={backgroundHeight}
                                                    backgroundPos={level.backgroundImagePos}
                                                    canvasSize={canvasSize}
                                                />
                                            )
                                        );
                                    })}
                            </AnimatePresence>
                        </scene>
                        <scene ref={cutoutSceneRef}>
                            <AnimatePresence initial={false}>
                                {cutawayLevels != null && cutawayLevels.length > 0 && (
                                    <CutawayNode
                                        grid={grid}
                                        uniforms={cutawayUniforms}
                                        opacity={cutawayOpacity}
                                        size={canvasSize}
                                    />
                                )}
                            </AnimatePresence>
                        </scene>
                    </group>
                </React.Fragment>
            )}
            {/* If the mesh is on layer 1, then it is visible to the camera but NOT the raycaster. */}
            <mesh
                ref={mainMeshRef}
                layers={
                    isVisible && levelKey === currentLevel.levelKey
                        ? VttCameraLayers.Default
                        : VttCameraLayers.DefaultNoRaycasting
                }
                renderOrder={levelHeight}>
                <planeGeometry attach="geometry" args={[canvasSize.width, canvasSize.height]} />
                <shaderMaterial
                    fragmentShader={maskShader}
                    vertexShader={basicVertexShader}
                    transparent
                    uniforms={uniforms}
                />
            </mesh>
        </React.Fragment>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useCallback } from "react";
import { Form } from "../Form";
import { getTokenType, isLocation, Token } from "../../store";
import { useDispatch, useTokenOverrides, useLocation } from "../contexts";
import { modifyToken } from "../../actions/token";
import { useDebounce } from "../utils";
import { applyOverrides } from "../../reducers/common";
import { ZIndexField } from "../ZIndexSlider";
import { ZIndexes } from "../LocationStage/common";
import { PercentageSliderField, SliderField } from "../slider";
import { theme } from "../../design";
import { VttMode } from "../common";
import { NotesProperties } from "./NotesProperties";

export const TokenNotesProperties: FunctionComponent<{
    token: Token;
    mode: VttMode;
}> = ({ token, mode }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    const onChange = useCallback(
        delta => {
            dispatch(modifyToken(campaign.id, location!.id, token, delta));
        },
        [dispatch, campaign.id, location, token]
    );

    return <NotesProperties note={token} mode={mode} dispatch={onChange} />;
};

export const TokenCoreProperties: FunctionComponent<{ token?: Token }> = ({ token }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const { tokenOverrides, overrideToken } = useTokenOverrides();
    if (token) {
        token = applyOverrides(token, tokenOverrides);
    }

    const onZIndexChanged = useDebounce(
        (zindex: number) => {
            if (token) {
                dispatch(
                    modifyToken(campaign.id, location!.id, token, {
                        zIndex:
                            zindex === (token.type === "object" ? ZIndexes.Underlay : ZIndexes.Tokens)
                                ? undefined
                                : zindex,
                    })
                );
                overrideToken(token.id, undefined);
            }
        },
        500,
        (zindex: number) => {
            if (token) {
                overrideToken(token.id, { zIndex: zindex });
            }

            return [zindex];
        }
    );

    const onOccupiedOpacityChanged = useDebounce(
        (opacity: number) => {
            if (token) {
                dispatch(
                    modifyToken(campaign.id, location!.id, token, {
                        occupiedOpacity: opacity === 1 ? undefined : opacity,
                    })
                );
                overrideToken(token.id, undefined);
            }
        },
        500,
        (opacity: number) => {
            if (token) {
                overrideToken(token.id, { occupiedOpacity: opacity });
            }

            return [opacity];
        }
    );

    const onWindowOpacityChanged = useDebounce(
        (opacity: number) => {
            if (token) {
                dispatch(
                    modifyToken(campaign.id, location!.id, token, {
                        windowOpacity: opacity === 1 ? undefined : opacity,
                    })
                );
                overrideToken(token.id, undefined);
            }
        },
        500,
        (opacity: number) => {
            if (token) {
                overrideToken(token.id, { windowOpacity: opacity });
            }

            return [opacity];
        }
    );

    const onVisibleOpacityChanged = useDebounce(
        (opacity: number) => {
            if (token) {
                dispatch(
                    modifyToken(campaign.id, location!.id, token, {
                        visibleOpacity: opacity === 1 ? undefined : opacity,
                    })
                );
                overrideToken(token.id, undefined);
            }
        },
        500,
        (opacity: number) => {
            if (token) {
                overrideToken(token.id, { visibleOpacity: opacity });
            }

            return [opacity];
        }
    );

    const onRotationChanged = useDebounce(
        (rotation: number | undefined) => {
            if (token) {
                dispatch(modifyToken(campaign.id, location!.id, token, { rotation: rotation }));
                overrideToken(token.id, undefined);
            }
        },
        500,
        (rotation: number) => {
            if (token) {
                overrideToken(token.id, {
                    rotation: rotation === 0 ? undefined : rotation,
                });
            }

            return [rotation];
        }
    );

    const onScaleChanged = useDebounce(
        (scale: number | undefined) => {
            if (token) {
                dispatch(modifyToken(campaign.id, location!.id, token, { scale: scale }));
                overrideToken(token.id, undefined);
            }
        },
        500,
        (scale: number) => {
            if (token) {
                overrideToken(token.id, { scale: scale === 1 ? undefined : scale });
            }

            return [scale];
        }
    );

    const onRenderScaleChanged = useDebounce(
        (scale: number | undefined) => {
            if (token) {
                dispatch(modifyToken(campaign.id, location!.id, token, { renderScale: scale }));
                overrideToken(token.id, undefined);
            }
        },
        500,
        (scale: number) => {
            const s = scale === 1 ? undefined : scale;
            if (token) {
                overrideToken(token.id, { renderScale: s });
            }

            return [s];
        }
    );

    if (!token) {
        return <React.Fragment />;
    }

    // Convert whatever is set on the token into the -180<->180 range.
    let rotation = (token.rotation ?? 0) + 180;
    rotation = rotation % 360;
    rotation = (rotation < 0 ? rotation + 360 : rotation) - 180;

    const tokenType = getTokenType(campaign, token);
    return (
        <Form fullWidth>
            <SliderField
                label="Rotation"
                hint="Can also be changed by right click and dragging to measure."
                min={-180}
                max={180}
                style={{
                    paddingBottom: theme.space[4],
                    marginLeft: theme.space[3],
                    marginRight: theme.space[3],
                    width: "auto",
                }}
                disabled={!isLocation(location)}
                value={rotation}
                included={false}
                marks={{
                    "-180": "-180°",
                    "-90": "-90°",
                    0: "0°",
                    90: "90°",
                    180: "180°",
                }}
                onChange={onRotationChanged}
            />
            {tokenType === "creature" && (
                <SliderField
                    label="Render scale"
                    hint="The default scale to render the image at, relative to the size of the token."
                    min={0.2}
                    max={5}
                    style={{
                        paddingBottom: theme.space[4],
                        marginLeft: theme.space[3],
                        marginRight: theme.space[3],
                        width: "auto",
                    }}
                    disabled={!isLocation(location)}
                    value={token.renderScale ?? 1}
                    step={0.05}
                    included={false}
                    marks={{
                        "0.2": "",
                        "0.5": "50%",
                        1: "100%",
                        2: "200%",
                        3: "300%",
                        4: "400%",
                        5: "500%",
                    }}
                    onChange={onRenderScaleChanged}
                />
            )}
            {tokenType === "object" && (
                <SliderField
                    label="Scale"
                    min={0.2}
                    max={5}
                    style={{
                        paddingBottom: theme.space[4],
                        marginLeft: theme.space[3],
                        marginRight: theme.space[3],
                        width: "auto",
                    }}
                    disabled={!isLocation(location)}
                    value={token.scale ?? 1}
                    step={0.05}
                    included={false}
                    marks={{
                        "0.2": "",
                        "0.5": "50%",
                        1: "100%",
                        2: "200%",
                        3: "300%",
                        4: "400%",
                        5: "500%",
                    }}
                    onChange={onScaleChanged}
                />
            )}
            <ZIndexField
                label="Z index"
                disabled={!isLocation(location)}
                value={token.zIndex ?? (token.type === "object" ? ZIndexes.Underlay : ZIndexes.Tokens)}
                onChange={onZIndexChanged}
            />
            {tokenType === "object" && (
                <React.Fragment>
                    <PercentageSliderField
                        label="Occupied opacity (percent)"
                        hint="The opacity to apply when one or more creature tokens are below the image."
                        value={token.occupiedOpacity ?? 1}
                        disabled={token.zIndex == null || token.zIndex < ZIndexes.Tokens || !isLocation(location)}
                        onChange={onOccupiedOpacityChanged}
                    />

                    <PercentageSliderField
                        label="Window opacity (percent)"
                        hint="Fade out windows in the image for this object to the specified opacity to show creature tokens underneath."
                        value={token.windowOpacity ?? 1}
                        disabled={token.zIndex == null || token.zIndex < ZIndexes.Tokens || !isLocation(location)}
                        onChange={onWindowOpacityChanged}
                    />

                    <PercentageSliderField
                        label="Visible opacity (percent)"
                        hint="The opacity to apply when this object is visible to a player's token. This is mainly useful in combination with the reveal all option of the location."
                        value={token.visibleOpacity ?? 1}
                        disabled={token.zIndex == null || token.zIndex < ZIndexes.Tokens || !isLocation(location)}
                        onChange={onVisibleOpacityChanged}
                    />
                </React.Fragment>
            )}
        </Form>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useCallback, useState } from "react";
import { CheckboxField, Form, InputField, SelectField } from "../Form";
import { useAnnotationOverrides, useDispatch, useLocation, useRole } from "../contexts";
import { VttMode } from "../common";
import {
    Annotation,
    DoorAnnotation,
    DoorAnnotationType,
    isDoorAnnotation,
    isLineAnnotation,
    isObstructingAnnotation,
    isWindowAnnotation,
    LineAnnotation,
} from "../../annotations";
import { modifyAnnotation } from "../../actions/location";
import { NotesProperties } from "./NotesProperties";
import { useDebounce } from "../utils";
import { applyOverrides } from "../../reducers/common";
import { SliderField } from "../slider";
import { getLevelKeysInRenderOrder, getLevelLabel, isLocation } from "../../store";
import { theme } from "../../design";

export const AnnotationNotesProperties: FunctionComponent<{
    annotation: Annotation;
    mode: VttMode;
}> = ({ annotation, mode }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const [labelOverride, setLabelOverride] = useState<string>();
    const onLabelChanged = useDebounce(
        (label: string) => {
            dispatch(
                modifyAnnotation(campaign.id, location!.id, annotation.id, {
                    label: label,
                })
            );
            setLabelOverride(undefined);
        },
        500,
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setLabelOverride(e.target.value);
            return [e.target.value];
        }
    );

    const onChange = useCallback(
        delta => {
            dispatch(modifyAnnotation(campaign.id, location!.id, annotation.id, delta));
        },
        [dispatch, campaign.id, location, annotation.id]
    );

    return (
        <NotesProperties note={annotation} mode={mode} dispatch={onChange}>
            <InputField
                disabled={!isLocation(location)}
                variant="text"
                label="Label"
                value={labelOverride ?? annotation.label ?? ""}
                onChange={onLabelChanged}
            />
        </NotesProperties>
    );
};

export const AnnotationCoreProperties: FunctionComponent<{
    annotation?: Annotation;
}> = ({ annotation }) => {
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();
    const { annotationOverrides, overrideAnnotation } = useAnnotationOverrides();
    const role = useRole();
    if (annotation) {
        annotation = applyOverrides(annotation, annotationOverrides);
    }

    const onRotationChanged = useDebounce(
        (rotation: number | undefined) => {
            if (annotation) {
                dispatch(
                    modifyAnnotation(campaign.id, location!.id, annotation.id, {
                        rotation: rotation,
                    })
                );
                overrideAnnotation(annotation.id, undefined);
            }
        },
        500,
        (rotation: number) => {
            if (annotation) {
                overrideAnnotation(annotation.id, {
                    rotation: rotation === 0 ? undefined : rotation,
                });
            }

            return [rotation];
        }
    );

    const canFill =
        !(isLineAnnotation(annotation) && annotation.subtype === "wall") &&
        !isDoorAnnotation(annotation) &&
        !isWindowAnnotation(annotation);

    // Convert whatever is set on the annotation into the -180<->180 range.
    let rotation = (annotation?.rotation ?? 0) + 180;
    rotation = rotation % 360;
    rotation = (rotation < 0 ? rotation + 360 : rotation) - 180;

    return (
        <Form fullWidth>
            {isDoorAnnotation(annotation) && (
                <React.Fragment>
                    <SelectField
                        label="Door type"
                        value={annotation.subtype}
                        onChange={e => {
                            const doorType = e.target.value ? (e.target.value as DoorAnnotationType) : undefined;
                            dispatch(
                                modifyAnnotation<DoorAnnotation>(
                                    campaign.id,
                                    location!.id,
                                    (annotation as DoorAnnotation).id,
                                    {
                                        subtype: doorType,
                                        open: undefined,
                                        rotation: undefined,
                                    }
                                )
                            );
                        }}>
                        <option value="">Default</option>
                        <option value="slide">Sliding</option>
                    </SelectField>
                    <CheckboxField
                        id="isSecret"
                        label="Secret"
                        hint="A secret door appears as a regular wall to players, until it is opened."
                        checked={!!annotation?.isSecret}
                        disabled={!location || !annotation}
                        onChange={e => {
                            dispatch(
                                modifyAnnotation<DoorAnnotation>(campaign.id, location!.id, annotation!.id, {
                                    isSecret: e.target.checked ? true : undefined,
                                })
                            );
                        }}
                    />
                </React.Fragment>
            )}
            {((annotation?.type === "line" && (annotation as LineAnnotation).subtype == null) ||
                annotation?.type === "rect" ||
                annotation?.type === "ellipse") && (
                <SliderField
                    label="Rotation"
                    hint="Can also be changed by right click and dragging to measure."
                    min={-180}
                    max={180}
                    style={{
                        paddingBottom: theme.space[4],
                        marginLeft: theme.space[3],
                        marginRight: theme.space[3],
                    }}
                    disabled={!isLocation(location)}
                    value={rotation}
                    included={false}
                    marks={{
                        "-180": "-180°",
                        "-90": "-90°",
                        0: "0°",
                        90: "90°",
                        180: "180°",
                    }}
                    onChange={onRotationChanged}
                />
            )}

            {canFill && (
                <CheckboxField
                    id="isFilled"
                    label="Filled"
                    hint="Fill the shape with the owning user's colour."
                    checked={!!annotation?.isFilled}
                    disabled={!location || !annotation}
                    onChange={e => {
                        dispatch(
                            modifyAnnotation<LineAnnotation>(campaign.id, location!.id, annotation!.id, {
                                isFilled: e.target.checked ? true : undefined,
                            })
                        );
                    }}
                />
            )}
            {isLineAnnotation(annotation) && (
                <CheckboxField
                    id="isClosed"
                    label="Close polygon"
                    hint="Connect the last point back to the first point to form a complete polygon."
                    checked={!!annotation.isClosed}
                    disabled={!location || !annotation}
                    onChange={e => {
                        dispatch(
                            modifyAnnotation<LineAnnotation>(campaign.id, location!.id, annotation!.id, {
                                isClosed: e.target.checked ? true : undefined,
                            })
                        );
                    }}
                />
            )}
            {canFill && (
                <CheckboxField
                    id="showGridCoverage"
                    label="Show grid coverage"
                    hint="Show which grid squares are covered by this shape according to the campaign's coverage rules."
                    checked={!!annotation?.showGrid}
                    disabled={!location || !annotation}
                    onChange={e => {
                        dispatch(
                            modifyAnnotation<LineAnnotation>(campaign.id, location!.id, annotation!.id, {
                                showGrid: e.target.checked ? true : undefined,
                            })
                        );
                    }}
                />
            )}
            {isObstructingAnnotation(annotation) && (
                <React.Fragment>
                    <CheckboxField
                        id="obstructsMovement"
                        label="Obstructs movement"
                        hint="Prevent tokens from passing through the shape's boundaries."
                        checked={!!annotation?.obstructsMovement}
                        disabled={!location || !annotation}
                        onChange={e => {
                            dispatch(
                                modifyAnnotation<LineAnnotation>(campaign.id, location!.id, annotation!.id, {
                                    obstructsMovement: e.target.checked ? true : undefined,
                                })
                            );
                        }}
                    />
                    <CheckboxField
                        id="obstructsLight"
                        label="Obstructs light"
                        hint="Prevent light from light sources from passing through the shape's boundaries and block line of sight for tokens."
                        checked={!!annotation?.obstructsLight}
                        disabled={!location || !annotation}
                        onChange={e => {
                            dispatch(
                                modifyAnnotation<LineAnnotation>(campaign.id, location!.id, annotation!.id, {
                                    obstructsLight: e.target.checked ? true : undefined,
                                })
                            );
                        }}
                    />
                </React.Fragment>
            )}
            {role === "GM" && (
                <React.Fragment>
                    <CheckboxField
                        id="buildOnly"
                        label="Only visible in build mode"
                        hint="Prevent players from seeing the annotation."
                        checked={!!annotation?.buildOnly}
                        onChange={e => {
                            dispatch(
                                modifyAnnotation<LineAnnotation>(campaign.id, location!.id, annotation!.id, {
                                    buildOnly: e.target.checked ? true : undefined,
                                })
                            );
                        }}
                    />
                    <SelectField
                        id="goToLevel"
                        label="Send tokens to level"
                        disabled={!isLocation(location) || !annotation || !annotation.pos}
                        value={annotation?.goToLevel ?? ""}
                        onChange={e => {
                            dispatch(
                                modifyAnnotation<LineAnnotation>(campaign.id, location!.id, annotation!.id, {
                                    goToLevel:
                                        e.target.value == null || e.target.value === "" ? undefined : e.target.value,
                                })
                            );
                        }}
                        hint="Tokens entering or crossing the annotation area will be sent to the specified level.">
                        <option value="">None</option>
                        {isLocation(location) &&
                            getLevelKeysInRenderOrder(location).map((o, i) => {
                                if (o !== annotation?.goToLevel && o === annotation!.pos?.level) {
                                    return undefined;
                                }

                                const level = location.levels[o];
                                return (
                                    level && (
                                        <option key={o} value={o}>
                                            {getLevelLabel(level, i)}
                                        </option>
                                    )
                                );
                            })}
                    </SelectField>

                    {isDoorAnnotation(annotation) &&
                        (annotation.subtype == null || annotation.subtype === "default") && (
                            <React.Fragment>
                                <CheckboxField
                                    id="invertDoor"
                                    label="Flip opening direction"
                                    hint="Change the direction in which the door opens."
                                    checked={!!annotation?.invert}
                                    disabled={!location || !annotation}
                                    onChange={e => {
                                        dispatch(
                                            modifyAnnotation<DoorAnnotation>(
                                                campaign.id,
                                                location!.id,
                                                annotation!.id,
                                                {
                                                    invert: e.target.checked ? true : undefined,
                                                }
                                            )
                                        );
                                    }}
                                />
                            </React.Fragment>
                        )}
                </React.Fragment>
            )}
        </Form>
    );
};

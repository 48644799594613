/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { useState, useRef, useEffect, useImperativeHandle } from "react";
import { useLocalSetting } from "../utils";
import { LocalSetting } from "../../common";
import { MarkdownEditor, MarkdownEditorHandle } from "../markdown";
import { ExtractProps } from "../common";
import { Box } from "../primitives";

interface CommandInputProps {
    onExecuteCommand: (command: string) => void;
}

const logCommandHistorySetting = new LocalSetting<string[]>("log_command_history");

export const CommandInput = React.forwardRef<
    MarkdownEditorHandle,
    CommandInputProps & ExtractProps<typeof MarkdownEditor>
>(({ onExecuteCommand, ...props }, ref) => {
    // TODO: Change to uncontrolled, get rid of input?
    const [inputHistory, setInputHistory] = useLocalSetting(logCommandHistorySetting, []);
    const [inputHistoryPos, setInputHistoryPos] = useState(0);

    const markdownRef = useRef<MarkdownEditorHandle>(null);

    useImperativeHandle(
        ref,
        () => ({
            focus: () => markdownRef.current?.focus(),
            setMarkdown: (md: string) => markdownRef.current?.setMarkdown(md),
            getMarkdown: () => markdownRef.current?.getMarkdown() ?? "",
        }),
        []
    );

    const hostRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const element = hostRef.current;
        if (element) {
            const handler = (e: KeyboardEvent) => {
                if (!markdownRef.current) {
                    return;
                }

                if (e.key === "ArrowUp") {
                    const i = inputHistoryPos - 1;
                    if (inputHistory.length + i >= 0) {
                        setInputHistoryPos(i);
                        markdownRef.current.setMarkdown(inputHistory[inputHistory.length + i]);
                        e.preventDefault();
                        e.stopPropagation();
                    }
                } else if (e.key === "ArrowDown") {
                    const i = inputHistoryPos + 1;
                    if (i < 0) {
                        setInputHistoryPos(i);
                        markdownRef.current.setMarkdown(inputHistory[inputHistory.length + i]);
                        e.preventDefault();
                        e.stopPropagation();
                    }
                } else if (e.key === "Enter" && !e.shiftKey) {
                    const md = markdownRef.current.getMarkdown();
                    onExecuteCommand(md);

                    var history = inputHistory.slice();
                    history.push(md);
                    if (history.length > 100) {
                        history.shift();
                    }

                    markdownRef.current.setMarkdown("");
                    setInputHistory(history);
                    setInputHistoryPos(0);

                    e.preventDefault();
                    e.stopPropagation();

                    return false;
                }
            };
            element.addEventListener("keydown", handler, { capture: true });
            return () => {
                element.removeEventListener("keydown", handler, { capture: true });
            };
        }
    }, [inputHistory, inputHistoryPos, onExecuteCommand, setInputHistory]);

    return (
        <Box ref={hostRef} flexDirection="column" alignItems="stretch" fullWidth>
            <MarkdownEditor ref={markdownRef} {...props} editable />
        </Box>
    );
});

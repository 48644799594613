import { Annotation } from "../annotations";
import { CampaignAction, isAnnotationAction } from "../actions/common";
import { copyState } from "./common";
import { SessionConnection, Location } from "../store";

const annotationReducer = (
    state: Annotation | undefined,
    action: CampaignAction,
    location: Location,
    sessionConnection: SessionConnection,
    isTargetted: boolean
) => {
    if (!state) {
        return state;
    }

    if (isTargetted) {
        if (isAnnotationAction(action)) {
            if (action.type === "ModifyAnnotation") {
                return copyState(state, action.payload);
            }
        }

        if (state.centerOn && !location.tokens[state.centerOn]) {
            // If the token we're meant to be centered on doesn't exist, we can't either!
            return undefined;
        }
    }

    if (sessionConnection.system) {
        return sessionConnection.system.reduceAnnotation(
            state,
            action,
            sessionConnection.session!,
            location,
            isTargetted
        );
    }

    return state;
};

export default annotationReducer;

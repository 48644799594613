/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, {
    FunctionComponent,
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
    MutableRefObject,
    useContext,
    PropsWithChildren,
    ReactElement,
} from "react";
import {
    LineAnnotation,
    Annotation,
    RectAnnotation,
    EllipseAnnotation,
    isAnnotation,
    isObstructingAnnotation,
    getObstructionPolygon,
    getAnnotationCenter,
    DoorAnnotation,
    WindowAnnotation,
    isLineAnnotation,
    ObstructingAnnotation,
    isRectAnnotation,
    getObstructions,
    addEdges,
    isDoorAnnotation,
    AnnotationType,
    ConeAnnotation,
    LineAreaAnnotation,
    getAnnotationPos,
    canModifyAnnotationShape,
    TargettedAnnotation,
    isTargettedAnnotation,
    isWindowAnnotation,
    getAnnotationBounds,
    getAnnotationBoundsPoints,
    getCoveragePolygon,
} from "../../annotations";
import {
    LocalPixelPosition,
    PositionType,
    Position,
    ScreenPixelPosition,
    GridPosition,
    Size,
    Point,
    Rect,
    LocalRect,
} from "../../position";
import {
    Location,
    Token,
    isToken,
    Campaign,
    CampaignRole,
    PositionedLight,
    resolveToken,
    Zone,
    isZone,
    createZone,
    AudioType,
    ImageType,
    UserInfo,
    getTokenType,
    AnnotationPlacementTemplate,
    IGameSystem,
    isLocation,
    TokenAppearance,
    LocationLevel,
    getLevelKeysInRenderOrder,
    getLevelLabel,
    WithLevel,
    DragPreview,
    SessionConnection,
    DiceBag,
    ResolvedToken,
    getTokenOwner,
    defaultSoundRadius,
} from "../../store";
import {
    useDispatch,
    useUser,
    getRole,
    useNotifications,
    ScaleContext,
    useCampaign,
    useValidatedLocation,
    useAnnotationOverrides,
    useTokenOverrides,
    useZoneOverrides,
    useClipper,
    useAnnotationCache,
    useSelection,
    UiLayerContext,
    CampaignContext,
    useLocalGrid,
    useValidatedLocationLevel,
    useRole,
    PathFindingContext,
    useCamera,
    AnimationLightingContext,
    AnimationLightingProps,
    useAnimationLighting,
    SessionConnectionContext,
    SessionContext,
    useSessionConnection,
    DiceBagContext,
    useDiceBag,
} from "../contexts";
import {
    screenPoint,
    getNearestPoint,
    localPoint,
    getCenterPoint,
    IGrid,
    angle,
    translate,
    distanceBetween,
    ILocalGrid,
    getClosestPointOnLines,
    isPointOnLine,
    screenRect,
    getContainingPolygonFromPoint,
    simplifyEdges,
    pointAlongLine,
    lengthOfVector,
    angleOfVector,
    createGrid,
    localRect,
    GridType,
    ILocalGridWithRef,
} from "../../grid";
import { LibraryItem } from "../../library";
import {
    addToken,
    deleteItems,
    addAnnotation,
    modifyLocation,
    modifyAnnotation,
    convertToZone,
    addZone,
    addTrack,
    modifyLocationLevel,
    applyGridPlacement,
    addLevel,
} from "../../actions/location";
import { BackgroundLayer } from "./BackgroundLayer";
import { TokenNode } from "./TokenNode";
import { LineAnnotationNode } from "./LineAnnotationNode";
import { copyState, applyOverrides } from "../../reducers/common";
import { theme } from "../../design";
import { getSelectedItems, getSelectionByType, SelectionType } from "../selection";
import * as Clipboard from "../../clipboard";
import {
    filterKeyEvent,
    useDictionaryValues,
    useDropEvents,
    useErrorHandler,
    useKeyboardShortcut,
    useLocalSetting,
    usePreviousValue,
} from "../utils";
import { Help } from "../help";
import { DistanceMessage } from "./DistanceMessage";
import { VisibilityMask } from "./VisibilityMask";
import { modifyToken, modifyTokens } from "../../actions/token";
import {
    VttMode,
    ToolType,
    SubtoolType,
    VttBuildMode,
    Viewport,
    LobotomizedBox,
    getZonePolygon,
    resolveUri,
    useVttApp,
    dragStatus,
} from "../common";
import { Canvas, events, RootState, ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import { EffectComposer, SMAA } from "@react-three/postprocessing";
import { Line } from "./three2d/Line";
import { Circle } from "./three2d/Circle";
import {
    basicVertexShader,
    cameraDistanceToFit,
    clientPosToLocalPoint,
    HoverTracking,
    LevelInfo,
    pointerEventToLocalPoint,
    RenderOrder,
    SingleLevelInfo,
    SingleLevelInfoBasic,
    useHoverTracking,
    VttCameraLayers,
    ZIndexes,
} from "./common";
import { RectAnnotationNode } from "./RectAnnotationNode";
import { DoorAnnotationNode } from "./DoorAnnotationNode";
import { WindowAnnotationNode } from "./WindowAnnotationNode";
import { EllipseAnnotationNode } from "./EllipseAnnotationNode";
import { antialiasTypeSetting, DeepPartial, Event, LocalSetting, smaaQualitySetting } from "../../common";
import { useHistory } from "react-router-dom";
import { Dispatch, AnyAction } from "redux";
import * as H from "history";
import { dropFiles } from "../Sidebar/ArtLibrary";

import { defaultAnimate, defaultExit, defaultInitial, MotionBox, MotionToolbar } from "../motion";
import { Box, Image, Text, Truncate } from "../primitives";
import { ZoneAudioNode, ZoneNode } from "./ZoneNode";
import { getThemeColor } from "../../design/utils";
import { Active } from "@dnd-kit/core";
import { DragData, dragDropPalette, useTypedDroppable } from "../draggable";
import { PingLayer } from "./Ping";
import { DraggableShape, Shape } from "./three2d/Shape";
import { ClipperLibWrapper } from "js-angusj-clipper";
import { Button } from "../Button";
import { Message } from "../Message";
import { ConeAnnotationNode } from "./ConeAnnotation";
import { LineAreaAnnotationNode } from "./LineAreaAnnotation";
import { useMenuState, SubMenu, MenuItem, MenuDivider } from "@szhsin/react-menu";
import { ControlledMenu, renderContextMenuItem } from "../menus";
import { usePermanentNotification } from "../notification";
import { TargettedAnnotationNode } from "./TargettedAnnotationNode";
import { TokenImage } from "../TokenImage";
import {
    BasicShadowMap,
    Color,
    EllipseCurve,
    FrontSide,
    Group,
    Intersection,
    Object3D,
    OrthographicCamera,
    PerspectiveCamera,
    Scene,
    Texture,
    WebGLRenderTarget,
} from "three";
import { useFBO } from "@react-three/drei";
import { UiLayer } from "./UiLayer";
import { ToolbarButton } from "../Toolbar";

import FillShape from "../icons/FillShape";
import CloseLine from "../icons/ClosePolygon";
import ObstructsLight from "../icons/ObstructsLight";
import ObstructsMovement from "../icons/ObstructsMovement";
import GridIcon from "../icons/Grid";
import { HtmlAdorner } from "./HtmlAdorner";
import { nanoid } from "nanoid";
import { animate, AnimatePresence, useMotionValue, usePresence } from "framer-motion";
import { loadSegments } from "./Lighting/common";
import { LocationLevelContext, LocationLevelRenderInfo } from "./contexts";
import { ModalDialog } from "../modal";
import { ArrowRightIcon, PlusIcon } from "@radix-ui/react-icons";
import LayersIcon from "../icons/Layers";
import BuildModeIcon from "../icons/BuildMode";
import { PathFinder } from "../../pathfinder";
import StairsIcon from "../icons/Stairs";
import { SMAAPreset, EffectComposer as EffectComposerImpl, RenderPass } from "postprocessing";
import { cameraTransition, PerspectiveCameraControl } from "./PerspectiveCameraControl";
import { UiLayerObstructions } from "./UiLayerObstructions";
import { motion } from "framer-motion-3d";
import { useRain } from "./Particles/rain";
import { ZoneParticleSystem } from "./Particles/particlesystem";
import { useSnow } from "./Particles/snow";
import FlipHorizontalIcon from "../icons/FlipHorizontal";
import SecretDoorIcon from "../icons/SecretDoor";
import { VttEffect, VttEffectImpl } from "./VttEffect";
import { setPlayerLocation } from "../../actions/campaign";

const nonSystemDropTypes = [
    `LibraryItem/${AudioType.Ambient}`,
    `LibraryItem/${AudioType.Music}`,
    `LibraryItem/${ImageType.Background}`,
    `LibraryItem/${ImageType.Object}`,
    `LibraryItem/${ImageType.Token}`,
    "Token",
];

interface LocationStageProps {
    zonePreview?: Point[];
}

function getTargetCount(targetted: TargettedAnnotation) {
    const targetKeys = Object.keys(targetted.targets);
    const totalTargets = targetKeys.reduce((p, c) => p + (targetted.targets[c] ?? 0), 0);
    return totalTargets;
}

function isTargettedAnnotationComplete(targetted: TargettedAnnotation) {
    return getTargetCount(targetted) >= (targetted.maxTargets ?? 1);
}

function getObjectImageSize(objectItem: LibraryItem, tileSize: Size): Size | undefined {
    const width = objectItem.metadata.width;
    const height = objectItem.metadata.height;
    const tileWidth = objectItem.metadata.tilePxWidth;
    const tileHeight = objectItem.metadata.tilePxHeight;
    const finalWidth = width ? (tileWidth && tileHeight ? (width / tileWidth) * tileSize.width : width) : undefined;
    const finalHeight = height
        ? tileWidth && tileHeight
            ? (height / tileHeight) * tileSize.height
            : height
        : undefined;
    return finalWidth != null && finalHeight != null ? { width: finalWidth, height: finalHeight } : undefined;
}

const annotationTools: { [tool in AnnotationType]: AnnotationTool } = {
    line: {
        createNew: (pos, user, subtool) => {
            const line: LineAnnotation = {
                id: nanoid(),
                type: "line",
                userId: user.id,
                pos: pos,
                points: [{ x: 0, y: 0 }],
            };

            if (subtool === "wall") {
                line.subtype = "wall";
                line.buildOnly = true;
                line.obstructsLight = true;
                line.obstructsMovement = true;
            }

            return line;
        },
        addPoint: (annotation, campaign, location, grid, point) => {
            const lineAnnotation = annotation as LineAnnotation;
            const points = lineAnnotation.points.slice();
            const pos = getAnnotationPos(annotation, campaign, location, grid);
            point = isToken(point) ? grid.toLocalPoint(point.pos) : point;
            points.push({ x: point.x - pos.x, y: point.y - pos.y });
            return {
                isComplete: false,
                annotation: copyState(lineAnnotation, { points: points }),
            };
        },
        complete: annotation => {
            return (annotation as LineAnnotation).points.length < 2 ? undefined : annotation;
        },
    },
    rect: {
        createNew: (pos, user) => {
            return {
                id: nanoid(),
                type: "rect",
                userId: user.id,
                pos: pos,
                width: 0,
                height: 0,
            };
        },
        addPoint: (annotation, campaign, location, grid, point) => {
            var rectAnnotation = annotation as RectAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid);
            point = isToken(point) ? grid.toLocalPoint(point.pos) : point;

            let width = Math.abs(point.x - pos.x);
            if (rectAnnotation.minWidth != null) {
                width = Math.max(width, rectAnnotation.minWidth);
            }

            if (rectAnnotation.maxWidth != null) {
                width = Math.min(width, rectAnnotation.maxWidth);
            }

            let height = Math.abs(point.y - pos.y);
            if (rectAnnotation.minHeight != null) {
                height = Math.max(height, rectAnnotation.minHeight);
            }

            if (rectAnnotation.maxHeight != null) {
                height = Math.min(height, rectAnnotation.maxHeight);
            }

            rectAnnotation = copyState(rectAnnotation, {
                width: width,
                height: height,
            });
            return { isComplete: true, annotation: rectAnnotation };
        },
        complete: annotation => {
            return (annotation as RectAnnotation).width === 0 || (annotation as RectAnnotation).height === 0
                ? undefined
                : annotation;
        },
    },
    ellipse: {
        createNew: (pos, user) => {
            return {
                id: nanoid(),
                type: "ellipse",
                userId: user.id,
                pos: pos,
                radiusX: 0,
                radiusY: 0,
            };
        },
        addPoint: (annotation, campaign, location, grid, point) => {
            let ellipseAnnotation = annotation as EllipseAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid);
            point = isToken(point) ? grid.toLocalPoint(point.pos) : point;

            let radiusX = Math.abs(point.x - pos.x);
            if (ellipseAnnotation.minRadiusX != null) {
                radiusX = Math.max(radiusX, ellipseAnnotation.minRadiusX);
            }

            if (ellipseAnnotation.maxRadiusX != null) {
                radiusX = Math.min(radiusX, ellipseAnnotation.maxRadiusX);
            }

            let radiusY = Math.abs(point.y - pos.y);
            if (ellipseAnnotation.minRadiusY != null) {
                radiusY = Math.max(radiusY, ellipseAnnotation.minRadiusY);
            }

            if (ellipseAnnotation.maxRadiusY != null) {
                radiusY = Math.min(radiusY, ellipseAnnotation.maxRadiusY);
            }

            ellipseAnnotation = copyState(ellipseAnnotation, {
                radiusX: radiusX,
                radiusY: radiusY,
            });
            return { isComplete: true, annotation: ellipseAnnotation };
        },
        complete: annotation => {
            return (annotation as EllipseAnnotation).radiusX === 0 || (annotation as EllipseAnnotation).radiusY === 0
                ? undefined
                : annotation;
        },
    },
    door: {
        createNew: (pos, user) => {
            const door: DoorAnnotation = {
                id: nanoid(),
                type: "door",
                userId: user.id,
                pos: pos,
                obstructsLight: true,
                obstructsMovement: true,
                point: { x: 0, y: 0 },
            };
            return door;
        },
        addPoint: (annotation, campaign, location, grid, point) => {
            let doorAnnotation = annotation as DoorAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid);
            point = isToken(point) ? grid.toLocalPoint(point.pos) : point;
            doorAnnotation = copyState(doorAnnotation, {
                point: { x: point.x - pos.x, y: point.y - pos.y },
            });
            return {
                isComplete: true,
                annotation: doorAnnotation,
                breakWalls: { start: pos, end: point },
            };
        },
        complete: annotation => {
            return (annotation as DoorAnnotation).point.x === 0 && (annotation as DoorAnnotation).point.y === 0
                ? undefined
                : annotation;
        },
    },
    window: {
        createNew: (pos, user) => {
            const window: WindowAnnotation = {
                id: nanoid(),
                type: "window",
                userId: user.id,
                pos: pos,
                obstructsMovement: true,
                point: { x: 0, y: 0 },
            };
            return window;
        },
        addPoint: (annotation, campaign, location, grid, point) => {
            let windowAnnotation = annotation as WindowAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid);
            point = isToken(point) ? grid.toLocalPoint(point.pos) : point;
            windowAnnotation = copyState(windowAnnotation, {
                point: { x: point.x - pos.x, y: point.y - pos.y },
            });
            return {
                isComplete: true,
                annotation: windowAnnotation,
                breakWalls: { start: pos, end: point },
            };
        },
        complete: annotation => {
            return (annotation as WindowAnnotation).point.x === 0 && (annotation as WindowAnnotation).point.y === 0
                ? undefined
                : annotation;
        },
    },
    cone: {
        createNew: (pos, user) => {
            const cone: ConeAnnotation = {
                id: nanoid(),
                type: "cone",
                userId: user.id,
                pos: pos,
                radius: 0,
                spread: 90,
            };
            return cone;
        },
        addPoint: (annotation, campaign, location, grid, point) => {
            let coneAnnotation = annotation as ConeAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid);
            point = isToken(point) ? grid.toLocalPoint(point.pos) : point;

            const relativePoint = { x: point.x - pos.x, y: point.y - pos.y };
            coneAnnotation = copyState(coneAnnotation, {
                radius: lengthOfVector(relativePoint),
                rotation: angleOfVector(relativePoint) - 90,
            });
            return { isComplete: true, annotation: coneAnnotation };
        },
        complete: annotation => {
            return (annotation as ConeAnnotation).radius === 0 || annotation.rotation == null ? undefined : annotation;
        },
    },
    linearea: {
        createNew: (pos, user) => {
            const line: LineAreaAnnotation = {
                id: nanoid(),
                type: "linearea",
                userId: user.id,
                pos: pos,
                length: 0,
                width: 0,
            };
            return line;
        },
        addPoint: (annotation, campaign, location, grid, point) => {
            let line = annotation as LineAreaAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid);
            point = isToken(point) ? grid.toLocalPoint(point.pos) : point;

            const relativePoint = { x: point.x - pos.x, y: point.y - pos.y };
            line = copyState(line, {
                length: lengthOfVector(relativePoint),
                rotation: angleOfVector(relativePoint) - 90,
            });
            return { isComplete: true, annotation: line };
        },
        complete: annotation => {
            return (annotation as LineAreaAnnotation).length === 0 || annotation.rotation == null
                ? undefined
                : annotation;
        },
    },
    target: {
        createNew: (pos, user) => {
            // Targeted annotations don't have a tool - they're only used via templates as the result of a spell or attack etc.
            throw new Error("Target annotation should not be created via a tool.");
        },
        addPoint: (annotation, campaign, location, grid, point) => {
            if (isToken(point)) {
                let targetted = annotation as TargettedAnnotation;
                const newTargets = Object.assign({}, targetted.targets);

                const currentAmount = newTargets[point.id] ?? 0;
                newTargets[point.id] = currentAmount + 1;

                targetted = copyState(targetted, { targets: newTargets });
                return {
                    isComplete: isTargettedAnnotationComplete(targetted),
                    annotation: targetted,
                };
            } else {
                // Points don't get added to target annotations, only targets do.
                // TODO: This might not be true - what if the target is a point? Does that actually happen in any helpful way?
                throw new Error("Target annotation should not have points added.");
            }
        },
        complete: annotation => {
            const targetted = annotation as TargettedAnnotation;
            return getTargetCount(targetted) > 0 ? targetted : undefined;
        },
    },
};

interface AnnotationTool {
    createNew(pos: WithLevel<LocalPixelPosition>, user: UserInfo, subtool?: SubtoolType): Annotation;
    addPoint(
        annotation: Annotation,
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        point: LocalPixelPosition | Token
    ): {
        isComplete: boolean;
        breakWalls?: { start: LocalPixelPosition; end: LocalPixelPosition };
        annotation: Annotation;
    };
    complete(annotation: Annotation): Annotation | undefined;
}

function getGridTokenAt(
    system: IGameSystem,
    campaign: Campaign,
    location: Location,
    pos: Position,
    grid: IGrid,
    role: CampaignRole
): { token: Token; appearance: TokenAppearance } | undefined {
    const gridPos = (
        pos.type === PositionType.Grid ? pos : grid.toGridPoint(pos as LocalPixelPosition | ScreenPixelPosition)
    ) as GridPosition;

    for (let tokenId in location.tokens) {
        const token = location.tokens[tokenId];
        const appearance = system.getTokenAppearance?.(token, campaign, location) ?? token;
        if (
            token.pos.type === PositionType.Grid &&
            grid.contains(gridPos, token.pos, appearance.scale) &&
            (token.isPlayerVisible == null || token.isPlayerVisible || role === "GM")
        ) {
            return { token, appearance };
        }
    }

    return undefined;
}

/**
 * Gets a point, snapped to the nearest grid related point or annotation (if a location and level key are specified).
 * @param point The point.
 * @param campaign
 * @param location
 * @param levelKey
 * @param g
 * @param bounds
 * @param extraAnnotation
 * @param filter
 * @returns
 */
function getSnappedPoint(
    point: LocalPixelPosition,
    campaign: Campaign | undefined,
    location: Location | undefined,
    levelKey: string | undefined,
    g: ILocalGrid,
    bounds?: LocalRect,
    extraAnnotation?: Annotation,
    filter?: (o: Annotation) => boolean
) {
    let gridPoints = g.toLocalPoints(g.toGridPoint(point));
    gridPoints.push(getCenterPoint(gridPoints));

    if (campaign && location && levelKey) {
        if (
            extraAnnotation &&
            (!filter || filter(extraAnnotation)) &&
            extraAnnotation.pos &&
            isObstructingAnnotation(extraAnnotation)
        ) {
            const polygon = getObstructionPolygon(extraAnnotation, campaign, location, g);
            gridPoints.push(...polygon.points.map(p => localPoint(p.x + polygon.pos.x, p.y + polygon.pos.y)));
        }

        // Snap to annotation points - this makes drawing walls that join up perfectly much easier.
        Object.getOwnPropertyNames(location.annotations).reduce((p, o) => {
            const annotation = location.annotations[o];

            // TODO: We're ignoring annotations that are centered on tokens this way. They can't currently be obstructions, so not a big deal (yet).
            if (annotation.pos?.level === levelKey && (!filter || filter(annotation))) {
                if (annotation.pos && isObstructingAnnotation(annotation)) {
                    const polygon = getObstructionPolygon(annotation, campaign, location, g);
                    p.push(...polygon.points.map(p => localPoint(p.x + polygon.pos.x, p.y + polygon.pos.y)));
                }
            }

            return p;
        }, gridPoints);
    }

    // Get the closest point we've considered so far. If it's close, use it.
    // If it's not that close, consider the closest points on annotation lines.
    const snappedPoint = getNearestPoint(point, gridPoints);
    if (
        !(campaign && location && levelKey) ||
        distanceBetween(g.toScreenPoint(point), g.toScreenPoint(snappedPoint)) < 12
    ) {
        return snappedPoint;
    }

    // TODO: Optimise this, it's doing most of the stuff from the first pass again.
    Object.getOwnPropertyNames(location.annotations).reduce((p, o) => {
        const annotation = location.annotations[o];
        if (annotation.pos?.level === levelKey && (!filter || filter(annotation))) {
            if (annotation.pos && isObstructingAnnotation(annotation)) {
                const polygon = getObstructionPolygon(annotation, campaign, location, g);
                const lines = polygon.points.map(p => localPoint(p.x + polygon.pos.x, p.y + polygon.pos.y));
                if (polygon.isClosed) {
                    lines.push(localPoint(polygon.pos.x + polygon.points[0].x, polygon.pos.y + polygon.points[0].y));
                }

                const linePoint = getClosestPointOnLines(point, lines);
                gridPoints.push(localPoint(linePoint.x, linePoint.y));
            }
        }

        return p;
    }, gridPoints);

    // Also check the borders of the area.
    if (bounds != null) {
        const polygon = getZonePolygon(bounds.x, bounds.y, bounds.width, bounds.height);
        polygon.push(polygon[0]);
        const linePoint = getClosestPointOnLines(point, polygon);
        gridPoints.push(localPoint(linePoint.x, linePoint.y));
    }

    return getNearestPoint(point, gridPoints);
}

const GridCoverageSquare: FunctionComponent<{
    pos: GridPosition;
    annotation: Annotation;
    isSelected: SelectionType;
    hover: HoverTracking;
    grid: ILocalGrid;
    onClick: ((evt: ThreeEvent<MouseEvent>, o: Annotation | Token) => void) | undefined;
    onPointerDown: ((evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void) | undefined;
    onPointerUp: ((evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void) | undefined;
}> = ({ pos, annotation, onClick, onPointerDown, onPointerUp, hover, grid, isSelected }) => {
    const setHoverPart = hover.setHoverPart;
    useEffect(() => {
        return () => setHoverPart?.(`${pos.x}-${pos.y}`, false);
    }, [setHoverPart, pos.x, pos.y]);

    return (
        <DraggableShape
            onClick={onClick ? e => onClick(e, annotation) : undefined}
            onPointerDown={onPointerDown ? e => onPointerDown(e, annotation) : undefined}
            onPointerUp={onPointerUp ? e => onPointerUp(e, annotation) : undefined}
            cursor="pointer"
            disableDrag
            onPointerOver={() => {
                hover.setHoverPart?.(`${pos.x}-${pos.y}`, true);
            }}
            onPointerOut={() => {
                hover.setHoverPart?.(`${pos.x}-${pos.y}`, false);
            }}
            x={0}
            y={0}
            animateEnterExit="nolayout"
            transition={{ duration: 0.125 }}
            zIndex={ZIndexes.Background + 0.01}
            points={grid.toLocalPoints(pos)}
            color={theme.colors.guidance.focus}
            opacity={isSelected ? 0.5 : hover.isHovering ? 0.4 : 0.3}
        />
    );
};

interface AnnotationNodeProps {
    annotation: Annotation;
    activePoint?: LocalPixelPosition;
    grid: ILocalGrid;
    clipper: ClipperLibWrapper | undefined;
    tool: ToolType;
    isSelected: SelectionType;
    isInProgress?: boolean;
    mode: VttMode;
    buildMode: VttBuildMode;
    canSelect: boolean;
    onClick: ((evt: ThreeEvent<MouseEvent>, o: Annotation | Token) => void) | undefined;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onPointerDown: ((evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void) | undefined;
    onPointerUp: ((evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void) | undefined;
    onOverrideAnnotation: (id: string, override: Partial<Annotation> | undefined) => void;
}

const AnnotationNode: FunctionComponent<AnnotationNodeProps> = props => {
    // This exists just to render the annotation node at least one more time after isPresent is false, so that we can tell them that
    // they need to animate out properly this time.
    // Using usePresence prevents React.memo from preventing rerenders for some reason, so we wrap AnnotationNodeCore again and do it
    // outside.
    const [isPresent, safeToRemove] = usePresence();
    return <AnnotationNodeCoreMemo {...props} isPresent={isPresent} safeToRemove={safeToRemove} />;
};

// TODO: tokenOverrides only matters here if the annotation uses centerOn. A custom memo function could reduce renders here.
const AnnotationNodeCore: FunctionComponent<
    AnnotationNodeProps & {
        isPresent: boolean;
        safeToRemove: (() => void) | null | undefined;
    }
> = ({
    annotation,
    activePoint,
    grid,
    clipper,
    tool,
    isSelected,
    isInProgress,
    mode,
    buildMode,
    snapPoint,
    canSelect,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideAnnotation,
    isPresent,
    safeToRemove,
}) => {
    const isDisabled = (mode === "build" && buildMode !== "tokens") || tool === "grid";
    const { campaign, location, system } = useValidatedLocation();
    const { getGridPoints } = useAnnotationCache();
    const dispatch = useDispatch();
    const role = useRole();

    const { tokenOverrides } = useTokenOverrides();

    let systemAdorners: ReactElement[] | undefined;
    let labelPos: LocalPixelPosition[] | undefined;
    if (isPresent) {
        const localBounds = getAnnotationBounds(annotation, campaign, location, grid, tokenOverrides);

        labelPos =
            (annotation.label != null && annotation.label !== "") || annotation.goToLevel != null
                ? getCoveragePolygon(annotation, campaign, location, grid, tokenOverrides, false)
                : undefined;

        if (system.renderAnnotationAdorners) {
            const screenBounds = grid.toScreenRect(localBounds);
            systemAdorners = system.renderAnnotationAdorners({
                campaign,
                grid,
                isSelected,
                localBounds,
                screenBounds,
                mode,
                annotation,
            });
        }
    }

    const hoverProps = useHoverTracking(annotation.id, isDisabled);

    let gridPositionNodes: JSX.Element[] | undefined;
    if (isPresent && annotation.showGrid && !isDisabled && clipper) {
        // If there is an active annotation point, make sure that is included when generating the grid coverage.
        let gridAnnotation = annotation;
        if (activePoint != null) {
            const r = annotationTools[annotation.type].addPoint(annotation, campaign, location, grid, activePoint);
            if (r.isComplete) {
                gridAnnotation = r.annotation;
            }
        }

        const gridPositions = getGridPoints(gridAnnotation, campaign, location, grid, tokenOverrides, clipper!);
        if (gridPositions) {
            gridPositionNodes = gridPositions.map(o => (
                <GridCoverageSquare
                    key={`${o.x}-${o.y}`}
                    pos={o}
                    annotation={gridAnnotation}
                    grid={grid}
                    hover={hoverProps}
                    isSelected={isSelected}
                    onClick={onClick}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                />
            ));
        }
    }

    // Every time we switch from selected to not selected, we go to/from the UiLayer. We need to tailor the animations so that this
    // looks smooth, so we render in the existing layer an additional time but passing props that allow us to set up the correct
    // animations.
    const wasSelectedOld = usePreviousValue(isSelected);
    const [wasSelected, setWasSelected] = useState(false);
    const shouldRenderInUiLayer = isSelected || isInProgress;
    const [isInUiLayer, setIsInUiLayer] = useState(shouldRenderInUiLayer);
    useEffect(() => {
        setWasSelected(!!wasSelectedOld);
        setIsInUiLayer(shouldRenderInUiLayer);
    }, [shouldRenderInUiLayer, wasSelectedOld]);

    const isSwitchingLayers = shouldRenderInUiLayer !== isInUiLayer;
    const isCurrentlySelected = isSwitchingLayers ? wasSelectedOld ?? SelectionType.None : isSelected;

    let annotationNode: JSX.Element;
    switch (annotation.type) {
        case "line":
            annotationNode = (
                <LineAnnotationNode
                    key={annotation.id}
                    line={annotation as LineAnnotation}
                    activePoint={tool === "line" ? activePoint : undefined}
                    isSelected={isCurrentlySelected}
                    wasSelected={wasSelected} // wasSelected only matters on an initial render
                    disabled={isDisabled}
                    snapPoint={snapPoint}
                    onClick={canSelect ? onClick : undefined}
                    {...hoverProps}
                    animateExit={!isSwitchingLayers}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                    onOverrideAnnotation={onOverrideAnnotation}
                />
            );
            break;
        case "rect":
            annotationNode = (
                <RectAnnotationNode
                    key={annotation.id}
                    rect={annotation as RectAnnotation}
                    activePoint={tool === "rect" ? activePoint : undefined}
                    snapPoint={snapPoint}
                    isSelected={isCurrentlySelected}
                    wasSelected={wasSelected} // wasSelected only matters on an initial render
                    disabled={isDisabled}
                    onClick={canSelect ? onClick : undefined}
                    {...hoverProps}
                    animateExit={!isSwitchingLayers}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                    onOverrideAnnotation={onOverrideAnnotation}
                />
            );
            break;
        case "ellipse":
            annotationNode = (
                <EllipseAnnotationNode
                    key={annotation.id}
                    ellipse={annotation as EllipseAnnotation}
                    activePoint={tool === "ellipse" ? activePoint : undefined}
                    snapPoint={snapPoint}
                    isSelected={isCurrentlySelected}
                    wasSelected={wasSelected} // wasSelected only matters on an initial render
                    disabled={isDisabled}
                    onClick={canSelect ? onClick : undefined}
                    {...hoverProps}
                    animateExit={!isSwitchingLayers}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                    onOverrideAnnotation={onOverrideAnnotation}
                />
            );
            break;
        case "door":
            annotationNode = (
                <DoorAnnotationNode
                    key={annotation.id}
                    door={annotation as DoorAnnotation}
                    activePoint={tool === "door" ? activePoint : undefined}
                    snapPoint={snapPoint}
                    onOverrideAnnotation={onOverrideAnnotation}
                    isSelected={isCurrentlySelected}
                    wasSelected={wasSelected} // wasSelected only matters on an initial render
                    disabled={isDisabled}
                    {...hoverProps}
                    animateExit={!isSwitchingLayers}
                    onClick={canSelect && mode === "build" ? onClick : undefined}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                />
            );
            break;
        case "window":
            annotationNode = (
                <WindowAnnotationNode
                    key={annotation.id}
                    window={annotation as WindowAnnotation}
                    activePoint={tool === "window" ? activePoint : undefined}
                    snapPoint={snapPoint}
                    isSelected={isCurrentlySelected}
                    wasSelected={wasSelected} // wasSelected only matters on an initial render
                    disabled={isDisabled}
                    mode={mode}
                    onClick={canSelect ? onClick : undefined}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                    {...hoverProps}
                    animateExit={!isSwitchingLayers}
                    onOverrideAnnotation={onOverrideAnnotation}
                />
            );
            break;
        case "cone":
            annotationNode = (
                <ConeAnnotationNode
                    key={annotation.id}
                    cone={annotation as ConeAnnotation}
                    activePoint={activePoint} // TODO: Other annotations restrict this to when the tool is selected - what about placement templates?
                    snapPoint={snapPoint}
                    isSelected={isCurrentlySelected}
                    wasSelected={wasSelected} // wasSelected only matters on an initial render
                    disabled={isDisabled}
                    onClick={canSelect ? onClick : undefined}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                    {...hoverProps}
                    animateExit={!isSwitchingLayers}
                    onOverrideAnnotation={onOverrideAnnotation}
                />
            );
            break;
        case "linearea":
            annotationNode = (
                <LineAreaAnnotationNode
                    key={annotation.id}
                    line={annotation as LineAreaAnnotation}
                    activePoint={activePoint} // TODO: Other annotations restrict this to when the tool is selected - what about placement templates?
                    snapPoint={snapPoint}
                    isSelected={isCurrentlySelected}
                    wasSelected={wasSelected} // wasSelected only matters on an initial render
                    disabled={isDisabled}
                    onClick={canSelect ? onClick : undefined}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                    {...hoverProps}
                    animateExit={!isSwitchingLayers}
                    onOverrideAnnotation={onOverrideAnnotation}
                />
            );
            break;
        case "target":
            annotationNode = (
                <TargettedAnnotationNode
                    key={annotation.id}
                    targetted={annotation as TargettedAnnotation}
                    activePoint={activePoint} // TODO: Other annotations restrict this to when the tool is selected - what about placement templates?
                    snapPoint={snapPoint}
                    isSelected={isCurrentlySelected}
                    wasSelected={wasSelected} // wasSelected only matters on an initial render
                    disabled={isDisabled}
                    {...hoverProps}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                    onClick={canSelect ? onClick : undefined}
                    animateExit={!isSwitchingLayers}
                    onOverrideAnnotation={onOverrideAnnotation}
                />
            );
            break;
    }

    return (
        <React.Fragment>
            <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                {isPresent && isInUiLayer && <UiLayer key={annotation.id + "_uilayer"}>{annotationNode}</UiLayer>}
                {isPresent && !isInUiLayer && annotationNode}
                {systemAdorners}
                {gridPositionNodes}
            </AnimatePresence>
            <HtmlAdorner pos={labelPos} boundingPosHorizontal="c" boundingPosVertical="c" pointerEvents="none">
                {labelPos && (
                    <MotionBox
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        css={{ pointerEvents: "none" }}>
                        <Box
                            flexDirection="column"
                            color={isCurrentlySelected ? "guidance.focus" : "accent.3"}
                            css={{ pointerEvents: "none" }}>
                            {annotation.goToLevel != null && <StairsIcon />}
                            <Text style={{ whiteSpace: "pre" }}>{annotation.label}</Text>
                        </Box>
                    </MotionBox>
                )}
            </HtmlAdorner>
            <HtmlAdorner
                pos={
                    isSelected && isPresent && !annotation.disableEdit
                        ? labelPos ??
                          getCoveragePolygon(annotation, campaign, location, grid, tokenOverrides, false) ??
                          getAnnotationBoundsPoints(annotation, campaign, location, grid, tokenOverrides)
                        : undefined
                }
                pointerEvents="none"
                boundingPosVertical="to"
                boundingPosHorizontal="li">
                {isSelected && isPresent && !annotation.disableEdit && (
                    <Box paddingBottom={2} key={annotation.id + "_annotation_toolbar"}>
                        <MotionToolbar
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}>
                            {!(
                                (isLineAnnotation(annotation) && annotation.subtype === "wall") ||
                                isDoorAnnotation(annotation) ||
                                isWindowAnnotation(annotation)
                            ) && (
                                <ToolbarButton
                                    tooltip="Fill shape"
                                    tooltipDirection="up"
                                    isToggled={annotation.isFilled}
                                    onClick={() => {
                                        dispatch(
                                            modifyAnnotation(campaign.id, location.id, annotation.id, {
                                                isFilled: !annotation.isFilled,
                                            })
                                        );
                                    }}>
                                    <FillShape />
                                </ToolbarButton>
                            )}
                            {isLineAnnotation(annotation) && (
                                <ToolbarButton
                                    tooltip="Close polygon"
                                    tooltipDirection="up"
                                    isToggled={annotation.isClosed}
                                    onClick={() => {
                                        dispatch(
                                            modifyAnnotation<LineAnnotation>(campaign.id, location.id, annotation.id, {
                                                isClosed: !annotation.isClosed,
                                            })
                                        );
                                    }}>
                                    <CloseLine />
                                </ToolbarButton>
                            )}
                            {!(
                                (isLineAnnotation(annotation) && annotation.subtype === "wall") ||
                                isDoorAnnotation(annotation) ||
                                isWindowAnnotation(annotation)
                            ) && (
                                <ToolbarButton
                                    tooltip="Show grid coverage"
                                    tooltipDirection="up"
                                    isToggled={annotation.showGrid}
                                    onClick={() => {
                                        dispatch(
                                            modifyAnnotation(campaign.id, location.id, annotation.id, {
                                                showGrid: !annotation.showGrid,
                                            })
                                        );
                                    }}>
                                    <GridIcon />
                                </ToolbarButton>
                            )}
                            {isObstructingAnnotation(annotation) && (
                                <React.Fragment>
                                    <ToolbarButton
                                        tooltip="Obstructs movement"
                                        tooltipDirection="up"
                                        isToggled={annotation.obstructsMovement}
                                        onClick={() => {
                                            dispatch(
                                                modifyAnnotation<ObstructingAnnotation>(
                                                    campaign.id,
                                                    location.id,
                                                    annotation.id,
                                                    {
                                                        obstructsMovement: !annotation.obstructsMovement,
                                                    }
                                                )
                                            );
                                        }}>
                                        <ObstructsMovement />
                                    </ToolbarButton>
                                    <ToolbarButton
                                        tooltip="Obstructs light"
                                        tooltipDirection="up"
                                        isToggled={annotation.obstructsLight}
                                        onClick={() => {
                                            dispatch(
                                                modifyAnnotation<ObstructingAnnotation>(
                                                    campaign.id,
                                                    location.id,
                                                    annotation.id,
                                                    {
                                                        obstructsLight: !annotation.obstructsLight,
                                                    }
                                                )
                                            );
                                        }}>
                                        <ObstructsLight />
                                    </ToolbarButton>
                                </React.Fragment>
                            )}
                            {isDoorAnnotation(annotation) && (
                                <React.Fragment>
                                    {(annotation.subtype == null || annotation.subtype === "default") && (
                                        <ToolbarButton
                                            tooltip="Flip opening direction"
                                            tooltipDirection="up"
                                            isToggled={annotation.invert}
                                            onClick={() => {
                                                dispatch(
                                                    modifyAnnotation<DoorAnnotation>(
                                                        campaign.id,
                                                        location.id,
                                                        annotation.id,
                                                        {
                                                            invert: !annotation.invert,
                                                        }
                                                    )
                                                );
                                            }}>
                                            <FlipHorizontalIcon />
                                        </ToolbarButton>
                                    )}
                                    <ToolbarButton
                                        tooltip="Secret"
                                        tooltipDirection="up"
                                        isToggled={annotation.isSecret}
                                        onClick={() => {
                                            dispatch(
                                                modifyAnnotation<DoorAnnotation>(
                                                    campaign.id,
                                                    location.id,
                                                    annotation.id,
                                                    {
                                                        isSecret: !annotation.isSecret,
                                                    }
                                                )
                                            );
                                        }}>
                                        <SecretDoorIcon />
                                    </ToolbarButton>
                                </React.Fragment>
                            )}
                            {role === "GM" && (
                                <ToolbarButton
                                    tooltip="Only visible in build mode"
                                    tooltipDirection="up"
                                    isToggled={annotation.buildOnly}
                                    onClick={() => {
                                        dispatch(
                                            modifyAnnotation<ObstructingAnnotation>(
                                                campaign.id,
                                                location.id,
                                                annotation.id,
                                                {
                                                    buildOnly: !annotation.buildOnly,
                                                }
                                            )
                                        );
                                    }}>
                                    <BuildModeIcon />
                                </ToolbarButton>
                            )}
                        </MotionToolbar>
                    </Box>
                )}
            </HtmlAdorner>
        </React.Fragment>
    );
};

const AnnotationNodeCoreMemo = React.memo(AnnotationNodeCore);

interface LocationGroupProps extends LocationStageProps {
    history: H.History<H.LocationState>;

    grid: IGrid;
    localGrid: ILocalGridWithRef;

    camera: OrthographicCamera | PerspectiveCamera;
    threeRef: MutableRefObject<(() => RootState) | undefined>;

    isPointSnappingEnabled: boolean;

    annotation: Annotation | Zone | undefined;
    annotationPoint: LocalPixelPosition | undefined;
    addPointToAnnotation: (point: LocalPixelPosition | Token) => void;
    canSelect: boolean;
    onItemSelected: (item: Token | Annotation | Zone) => boolean;

    onStartMeasure(item: Token | Annotation);
    measureFrom: LocalPixelPosition | Token | GridPosition | undefined;
    measureTo: LocalPixelPosition | Token | GridPosition | undefined;
    measureFromLocal: LocalPixelPosition | undefined;
    measureToLocal: LocalPixelPosition | undefined;

    onContextMenu: (
        pos: { localPos: LocalPixelPosition; screenPos: ScreenPixelPosition; target?: Annotation | Token } | undefined
    ) => boolean;

    levelInfo: LevelInfo;
    hasVisionSource: boolean;

    totalSize: LocalRect | undefined;
    onSizeChanged: (width: number, height: number) => void;
    onLevelSizeChanged: (
        levelKey: string,
        url: string | null,
        texture: Texture | null,
        size: Size | null,
        error?: Error
    ) => void;

    dragPreviews?: DragPreview[];
    gridPlacementState?: {
        points?: LocalPixelPosition[];
        tileSize?: Size;
        pos?: LocalPixelPosition;
    };

    setPosition: (p: { x: number; y: number }) => void;
}

function saveCampaignThumbnail(campaignId: string, canvas: HTMLCanvasElement) {
    const resizedCanvas = document.createElement("canvas");
    const desiredWidth = 320;
    const scaleFactor = desiredWidth / canvas.width;
    resizedCanvas.width = desiredWidth;
    resizedCanvas.height = canvas.height * scaleFactor;

    const ctx = resizedCanvas.getContext("2d");
    if (ctx) {
        ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
        new LocalSetting(campaignId + ":screenshot").setValue(resizedCanvas.toDataURL());
    }
}

const NotificationStateContext = React.createContext(undefined as any);

function useNotificationState() {
    return useContext(NotificationStateContext);
}

const NotificationStateHost: FunctionComponent<PropsWithChildren<{ initialState: any; stateChanged: Event<any> }>> = ({
    initialState,
    stateChanged,
    children,
}) => {
    const [state, setState] = useState(initialState);
    useEffect(() => {
        const handler = (s: any) => setState(s);
        stateChanged.on(handler);
        return () => {
            stateChanged.off(handler);
        };
    });

    return <NotificationStateContext.Provider value={state}>{children}</NotificationStateContext.Provider>;
};

const GridPlacementNotificationContent: FunctionComponent<{
    onComplete: () => void;
    onCancel: () => void;
}> = ({ onComplete, onCancel }) => {
    const state = useNotificationState() as
        | {
              points?: LocalPixelPosition[];
              tileSize?: Size;
              pos?: LocalPixelPosition;
          }
        | undefined;

    return (
        <Message>
            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                Setting up the grid
            </Truncate>
            {(state?.points?.length == null || state?.points?.length === 0) && (
                <Text>
                    Click on a point where grid lines intersect. Pick a point near a corner of the map for best results.
                </Text>
            )}
            {state?.points?.length === 1 && (
                <Text>
                    Now click on the point where grid lines intersect on the opposite corner of the grid square.
                </Text>
            )}
            {state?.points?.length === 2 && (
                <Text>
                    Improve accuracy by panning to a point far away and clicking on a point where grid lines intersect.
                </Text>
            )}
            {state?.points?.length != null && state?.points?.length > 2 && (
                <Text>You can continue to add more points to further refine accuracy.</Text>
            )}
            <Box flexDirection="row" mt={3}>
                <Button disabled={state?.tileSize == null} onClick={onComplete}>
                    Complete
                </Button>
                <Button onClick={onCancel} ml={2}>
                    Cancel
                </Button>
            </Box>
        </Message>
    );
};

const TargettedAnnotationNotificationContent: FunctionComponent<{
    annotation: TargettedAnnotation | Omit<TargettedAnnotation, "id" | "userId">;
    completeAnnotation: (annotation: Annotation) => void;
}> = ({ annotation, completeAnnotation }) => {
    let targetCount = 0;
    for (let targetId in annotation.targets) {
        targetCount += annotation.targets[targetId];
    }

    return (
        <React.Fragment>
            <Text>
                Click to select targets{" "}
                <b>
                    ({targetCount}/{annotation.maxTargets ?? 1})
                </b>
            </Text>
            <Button
                mt={2}
                disabled={targetCount === 0}
                onClick={() => {
                    if (isAnnotation(annotation)) {
                        completeAnnotation(annotation);
                    }
                }}>
                Complete
            </Button>
        </React.Fragment>
    );
};

const AnnotationTemplateNotificationContent: FunctionComponent<{}> = () => {
    const state = useNotificationState() as {
        annotation?: Annotation | Omit<Annotation, "id" | "userId">;
        template?: AnnotationPlacementTemplate<Annotation>;
        campaign: Campaign;
        location: Location;
        session: Required<SessionConnection>;
        diceBag: { dice?: DiceBag; setDice: (dice: DiceBag) => void };
        completeAnnotation: (annotation: Annotation) => void;
    };
    if (!state || !state.annotation) {
        return <React.Fragment></React.Fragment>;
    }

    let token: ResolvedToken | undefined;
    if (state.annotation.tokenId) {
        const loc = state.campaign.locations[state.location.id];
        if (isLocation(loc)) {
            const rawToken = loc.tokens[state.annotation.tokenId];
            if (rawToken) {
                token = resolveToken(state.campaign, rawToken);
            }
        }
    }

    return (
        <SessionConnectionContext.Provider value={state.session}>
            <SessionContext.Provider value={state.session}>
                <CampaignContext.Provider
                    value={{
                        api: state.session.api,
                        campaign: state.session.session.campaign,
                        system: state.session.system,
                    }}>
                    <DiceBagContext.Provider value={state.diceBag}>
                        <Message>
                            {token && (
                                <Box flexDirection="row" pb={2} fullWidth justifyContent="flex-start">
                                    <TokenImage token={token} flexShrink={0} mr={2} />
                                    <Box
                                        flexDirection="column"
                                        justifyContent="flex-start"
                                        alignSelf="stretch"
                                        flexGrow={1}
                                        flexShrink={1}
                                        alignItems="flex-start">
                                        <Text fontSize={2}>
                                            <b>{state.session.system.getDisplayName(token, state.campaign)}</b>
                                        </Text>
                                        {state.template &&
                                            state.session.system?.renderAnnotationTemplateDetails?.(
                                                state.template,
                                                state.campaign,
                                                state.location
                                            )}
                                    </Box>
                                </Box>
                            )}
                            {state.annotation.type !== "target" && <Text>Click to apply annotation template.</Text>}
                            {state.annotation.type === "target" && (
                                <TargettedAnnotationNotificationContent
                                    annotation={
                                        state.annotation as
                                            | TargettedAnnotation
                                            | Omit<TargettedAnnotation, "id" | "userId">
                                    }
                                    completeAnnotation={state.completeAnnotation}
                                />
                            )}
                        </Message>
                    </DiceBagContext.Provider>
                </CampaignContext.Provider>
            </SessionContext.Provider>
        </SessionConnectionContext.Provider>
    );
};

const ZonesNode: FunctionComponent<{
    zones: Zone[];
    mode: VttMode;
    buildMode: VttBuildMode;
    canSelect: boolean;
    zone: Zone | undefined;
    zonePoint: LocalPixelPosition | undefined;
    zonePreview?: Point[];
    snapPoint: (point: LocalPixelPosition, filter?: ((o: Annotation) => boolean) | undefined) => LocalPixelPosition;
    onClick: ((evt: ThreeEvent<MouseEvent>, o: Token | Annotation | Zone) => void) | undefined;
    audioListenerPos?: WithLevel<LocalPixelPosition>;
}> = React.memo(
    ({ zones, mode, buildMode, zone, zonePoint, snapPoint, onClick, canSelect, zonePreview, audioListenerPos }) => {
        const { primary, secondary } = useSelection();
        const { zoneOverrides, overrideZone } = useZoneOverrides();

        return (
            <AnimatePresence>
                {mode === "build" &&
                    buildMode === "zones" &&
                    zones.map(o => {
                        const isSelected =
                            primary.indexOf(o.id) >= 0
                                ? SelectionType.Primary
                                : secondary.indexOf(o.id) >= 0
                                ? SelectionType.Secondary
                                : SelectionType.None;
                        return (
                            <ZoneNode
                                key={o.id}
                                zone={applyOverrides(o, zoneOverrides)}
                                snapPoint={snapPoint}
                                onOverrideZone={overrideZone}
                                onClick={canSelect ? onClick : undefined}
                                isSelected={isSelected}
                            />
                        );
                    })}
                {audioListenerPos &&
                    zones.map(o => {
                        if (!o.sound) {
                            return undefined;
                        }

                        return <ZoneAudioNode key={o.id + "_audio"} zone={o} audioListenerPos={audioListenerPos} />;
                    })}
                {zone && (
                    <ZoneNode
                        key={zone.id}
                        zone={zone}
                        activePoint={zonePoint}
                        snapPoint={snapPoint}
                        onOverrideZone={overrideZone}
                        onClick={canSelect ? onClick : undefined}
                        isSelected={SelectionType.None}
                    />
                )}
                {zonePreview && (
                    <Shape
                        x={0}
                        y={0}
                        points={zonePreview}
                        color={getThemeColor(theme.colors.accent[3])}
                        zIndex={ZIndexes.Annotations}
                        opacity={0.2}
                    />
                )}
            </AnimatePresence>
        );
    }
);

const AnnotationsNode: FunctionComponent<{
    annotations: Annotation[];
    mode: VttMode;
    buildMode: VttBuildMode;
    tool: ToolType;
    canSelect: boolean;
    annotation: Annotation | undefined;
    annotationPoint: LocalPixelPosition | undefined;
    snapPoint: (point: LocalPixelPosition, filter?: ((o: Annotation) => boolean) | undefined) => LocalPixelPosition;
    onPointerDown: ((evt: ThreeEvent<PointerEvent>, o: Token | Annotation) => void) | undefined;
    onPointerUp: ((evt: ThreeEvent<PointerEvent>, o: Token | Annotation) => void) | undefined;
    onClick: ((evt: ThreeEvent<MouseEvent>, o: Token | Annotation) => void) | undefined;
}> = React.memo(
    ({
        annotations,
        mode,
        buildMode,
        annotation,
        annotationPoint,
        tool,
        canSelect,
        snapPoint,
        onPointerDown,
        onPointerUp,
        onClick,
    }) => {
        const grid = useLocalGrid();
        const { primary, secondary } = useSelection();
        const clipper = useClipper();
        const { annotationOverrides, overrideAnnotation } = useAnnotationOverrides();

        return (
            <AnimatePresence>
                {(mode !== "build" || buildMode !== "base") &&
                    annotations.map(o => {
                        const isVisible = !o.buildOnly || mode === "build";
                        const selectionType =
                            primary.indexOf(o.id) >= 0
                                ? SelectionType.Primary
                                : secondary.indexOf(o.id) >= 0
                                ? SelectionType.Secondary
                                : SelectionType.None;
                        return (
                            isVisible && (
                                <AnnotationNode
                                    key={o.id}
                                    annotation={applyOverrides(o, annotationOverrides)}
                                    grid={grid}
                                    clipper={clipper}
                                    isSelected={selectionType}
                                    tool={tool}
                                    mode={mode}
                                    buildMode={buildMode}
                                    canSelect={canSelect}
                                    snapPoint={snapPoint}
                                    onClick={annotation == null ? onClick : undefined}
                                    onPointerDown={annotation == null ? onPointerDown : undefined}
                                    onPointerUp={annotation == null ? onPointerUp : undefined}
                                    onOverrideAnnotation={overrideAnnotation}
                                />
                            )
                        );
                    })}
                {annotation && (
                    <AnnotationNode
                        key={annotation.id}
                        annotation={annotation}
                        grid={grid}
                        clipper={clipper}
                        activePoint={annotationPoint}
                        isSelected={SelectionType.None}
                        isInProgress
                        tool={tool}
                        mode={mode}
                        buildMode={buildMode}
                        canSelect={canSelect}
                        snapPoint={snapPoint}
                        onClick={annotation == null ? onClick : undefined}
                        onPointerDown={annotation == null ? onPointerDown : undefined}
                        onPointerUp={annotation == null ? onPointerUp : undefined}
                        onOverrideAnnotation={overrideAnnotation}
                    />
                )}

                <UiLayer>
                    {annotations.map(
                        o => isObstructingAnnotation(o) && <UiLayerObstructions key={o.id} annotation={o} />
                    )}
                    {annotation && isObstructingAnnotation(annotation) && (
                        <UiLayerObstructions key={annotation.id} annotation={annotation} />
                    )}
                </UiLayer>
            </AnimatePresence>
        );
    }
);

const TokensNode: FunctionComponent<{
    tokens: Token[];
    levelKey: string;
    mode: VttMode;
    buildMode: VttBuildMode;
    annotation: Annotation | Zone | undefined;
    canSelect: boolean;
    audioListenerPos?: WithLevel<LocalPixelPosition>;
    snapPoint: (point: LocalPixelPosition, filter?: ((o: Annotation) => boolean) | undefined) => LocalPixelPosition;
    onPointerDown: ((evt: ThreeEvent<PointerEvent>, o: Token | Annotation) => void) | undefined;
    onPointerUp: ((evt: ThreeEvent<PointerEvent>, o: Token | Annotation) => void) | undefined;
    onClick: ((evt: ThreeEvent<PointerEvent>, o: Token | Annotation) => void) | undefined;
}> = React.memo(
    ({
        tokens,
        mode,
        annotation,
        snapPoint,
        canSelect,
        audioListenerPos,
        buildMode,
        onClick,
        onPointerDown,
        onPointerUp,
        levelKey,
    }) => {
        const { campaign, location, system } = useValidatedLocationLevel();
        const grid = useLocalGrid();
        const { primary, secondary } = useSelection();

        const { tokenOverrides, overrideToken } = useTokenOverrides();

        return (
            <AnimatePresence>
                {tokens.map(o => {
                    const token = o;

                    let isTarget = false;
                    let isWarningTarget = false;
                    if (isTargettedAnnotation(annotation)) {
                        // Check to make sure that this token is a valid target.
                        if (annotation.targetFilter?.self !== false || token.id !== annotation.tokenId) {
                            // Check to make sure the target is within the ability's radius.
                            if (annotation.radius != null) {
                                if (annotation.radius > 0) {
                                    // For targetted annotations, get the system to judge the distance between the source and target tokens.
                                    // The system may have its own range rules.
                                    const sourceToken = location.tokens[annotation.tokenId];
                                    if (sourceToken) {
                                        const range =
                                            system.getGridRange(campaign, location, grid, sourceToken, token) *
                                            location.tileSize.width;
                                        if (range <= annotation.radius) {
                                            isTarget = true;
                                        }

                                        if (annotation.warningRadius != null && range > annotation.warningRadius) {
                                            isWarningTarget = true;
                                        }
                                    }
                                }
                            } else {
                                isTarget = true;
                            }
                        }
                    }

                    const tokenWithOverrides = resolveToken(campaign, applyOverrides(token, tokenOverrides));

                    const selectionType =
                        primary.indexOf(o.id) >= 0
                            ? SelectionType.Primary
                            : secondary.indexOf(o.id) >= 0
                            ? SelectionType.Secondary
                            : SelectionType.None;
                    let adorners: ReactElement[] | undefined;
                    if (system.renderTokenAdorners && levelKey === tokenWithOverrides.pos.level) {
                        adorners = system.renderTokenAdorners({
                            isSelected: selectionType,
                            mode,
                            token: tokenWithOverrides,
                        });
                    }

                    return (
                        <React.Fragment key={token.id}>
                            <TokenNode
                                token={tokenWithOverrides}
                                levelKey={levelKey}
                                isSelected={selectionType}
                                isTarget={isTarget}
                                isWarningTarget={isWarningTarget}
                                onClick={
                                    canSelect && (annotation == null || (isTargettedAnnotation(annotation) && isTarget))
                                        ? onClick
                                        : undefined
                                }
                                onPointerDown={annotation == null ? onPointerDown : undefined}
                                onPointerUp={annotation == null ? onPointerUp : undefined}
                                onOverrideToken={overrideToken}
                                audioListenerPos={audioListenerPos}
                                snapPoint={snapPoint}
                                mode={mode}
                                buildMode={buildMode}
                            />
                            {adorners}
                        </React.Fragment>
                    );
                })}
            </AnimatePresence>
        );
    }
);

// The scale at which to draw the depth map for the level.
const DEPTH_SCALE = 0.25;

const depthShader = `
uniform sampler2D u_background;
uniform float u_height;
uniform float u_maxHeight;

varying vec2 vUv;

void main() {
    vec4 background = texture2D(u_background, vUv);
    if (background.a < 0.2) {
        discard;
    }

    float h = u_height / u_maxHeight;
    gl_FragColor = vec4(h, h, h, 1.0);
}`;

const DepthLevelZone: FunctionComponent<{
    zone: Zone;
    baseHeight: number;
    maxHeight: number;
}> = ({ zone, baseHeight, maxHeight }) => {
    const { location } = useValidatedLocation();
    const extraHeight = location.tileSize.width * 2;
    const height = baseHeight + extraHeight;

    const cv = height / maxHeight;
    const color = new Color(cv, cv, cv);

    // TODO: I don't know why the renderorder isn't enough here. We shouldn't need the extra group just to push
    // the zone shape high enough that it renders.
    return (
        <group position={[0, 0, extraHeight]}>
            <Shape
                scale={DEPTH_SCALE}
                x={zone.pos.x}
                y={zone.pos.y}
                points={zone.points}
                zIndex={ZIndexes.Overlay}
                renderOrder={height}
                noMaterial
                layers={VttCameraLayers.DefaultNoRaycasting}>
                <meshBasicMaterial color={color} />
            </Shape>
        </group>
    );
};

const DepthLevelMap: FunctionComponent<{
    level: LocationLevel;
    levelKey: string;
    info?: SingleLevelInfo;
    dirty: MutableRefObject<boolean>;
    totalSize: LocalRect;
    maxHeight: number;
}> = ({ level, levelKey, info, dirty, totalSize, maxHeight }) => {
    const { location } = useValidatedLocation();
    const uniforms = useMemo(
        () => ({
            u_background: {
                type: "t",
                value: undefined as Texture | undefined,
            },
            u_height: { value: 0 },
            u_maxHeight: { value: 0 },
        }),
        []
    );
    uniforms.u_background.value = info?.texture;
    uniforms.u_height.value = info?.height ?? 0;
    uniforms.u_maxHeight.value = maxHeight;

    const zones = useMemo(() => {
        return Object.values(location.zones).filter(o => o.pos.level === levelKey && o.isInside);
    }, [location.zones, levelKey]);

    return (
        <group position={[0, 0, info?.height ?? 0]}>
            {info && info.texture && info.size && (
                <mesh
                    layers={VttCameraLayers.DefaultNoRaycasting}
                    position={[
                        ((level.backgroundImagePos?.x ?? 0) + info.size.width / 2) * DEPTH_SCALE,
                        -((level?.backgroundImagePos?.y ?? 0) + info.size.height / 2) * DEPTH_SCALE,
                        0,
                    ]}
                    renderOrder={info.height}>
                    <planeGeometry args={[info.size.width * DEPTH_SCALE, info.size.height * DEPTH_SCALE]} />
                    <shaderMaterial
                        attach="material"
                        vertexShader={basicVertexShader}
                        fragmentShader={depthShader}
                        side={FrontSide}
                        transparent
                        uniforms={uniforms}
                    />
                </mesh>
            )}

            {zones.map(o => (
                <DepthLevelZone key={o.id} zone={o} baseHeight={info?.height ?? 0} maxHeight={maxHeight} />
            ))}
        </group>
    );
};

const DepthMap: FunctionComponent<{
    target: WebGLRenderTarget;
    totalSize: LocalRect;
    levelInfo: LevelInfo;
}> = ({ target, totalSize, levelInfo }) => {
    const { location } = useValidatedLocation();

    const depthCamera = useMemo(() => {
        const camera = new OrthographicCamera(undefined, undefined, undefined, undefined, 0.1, 10000);
        camera.layers.enable(VttCameraLayers.DefaultNoRaycasting);
        return camera;
    }, []);

    const sceneRef = useRef<Scene>(null);
    const dirty = useRef<boolean>(true);

    const maxHeight = ZoneParticleSystem.MAX_HEIGHT_GRID * location.tileSize.width;

    // The depth map needs to be updated when:
    // * The zones change - specifically when the isInside property of a zone changes.
    // * A level changes, specifically when the position, size, image, or height of a level changes.
    const zones = location.zones;
    const levels = location.levels;
    useEffect(() => {
        dirty.current = true;
    }, [zones, levels, totalSize]);

    const w = totalSize.width * DEPTH_SCALE;
    const h = totalSize.height * DEPTH_SCALE;
    depthCamera.left = -(w / 2);
    depthCamera.right = w / 2;
    depthCamera.top = h / 2;
    depthCamera.bottom = -(h / 2);
    depthCamera.position.set(totalSize.x * DEPTH_SCALE + w / 2, -totalSize.y * DEPTH_SCALE - h / 2, 10000);
    depthCamera.updateProjectionMatrix();
    depthCamera.updateMatrixWorld();

    useFrame(state => {
        if (dirty.current && sceneRef.current) {
            state.gl.setRenderTarget(target);
            state.gl.render(sceneRef.current, depthCamera);
            dirty.current = false;
        }
    }, 0);

    return (
        <group visible={false} position={[0, 0, 0]}>
            <scene ref={sceneRef}>
                {Object.keys(location.levels).map(o => (
                    <DepthLevelMap
                        key={o}
                        level={location.levels[o]}
                        levelKey={o}
                        info={levelInfo[o]}
                        dirty={dirty}
                        totalSize={totalSize}
                        maxHeight={maxHeight}
                    />
                ))}
            </scene>
        </group>
    );
};

const LocationGroup: FunctionComponent<LocationGroupProps> = ({
    grid,
    camera,
    threeRef,
    localGrid,
    levelInfo,
    annotation,
    annotationPoint,
    addPointToAnnotation,
    canSelect,
    onItemSelected,
    measureFrom,
    measureTo,
    measureFromLocal,
    measureToLocal,
    isPointSnappingEnabled,
    onStartMeasure,
    history,
    onSizeChanged,
    onContextMenu,
    onLevelSizeChanged,
    zonePreview,
    dragPreviews,
    gridPlacementState,
    hasVisionSource,
    totalSize,
    setPosition,
}) => {
    const size = useThree(state => state.size);
    const gl = useThree(state => state.gl);
    const scene = useThree(state => state.scene);
    const setThree = useThree(state => state.set);
    const cameraThree = useThree(state => state.camera);
    threeRef.current = useThree(state => state.get);
    const { isPerspective } = useCamera();
    const { mode, isSearchExpanded, isPropertiesExpanded } = useVttApp();

    // Make sure r3f is using the camera we want.
    // Would be great if we could just pass this to <Canvas>, but that just ignores new cameras.
    if (cameraThree !== camera) {
        setThree({ camera: camera });
    }

    if (camera.type === "OrthographicCamera") {
        const orthographicCamera = camera as OrthographicCamera;
        orthographicCamera.left = -(size.width / 2);
        orthographicCamera.right = size.width / 2;
        orthographicCamera.top = size.height / 2;
        orthographicCamera.bottom = -(size.height / 2);
        orthographicCamera.position.set(-(grid.offset.x - size.width / 2), -(-grid.offset.y + size.height / 2), 1000);
        camera.updateProjectionMatrix();
        camera.updateMatrixWorld();
    }

    const { campaign, location, levelKey } = useValidatedLocationLevel();

    let fboWidth: number | undefined;
    let fboHeight: number | undefined;
    if (isPerspective) {
        fboWidth = totalSize?.width;
        fboHeight = totalSize?.height;
    }

    // FBO that is used for rendering temp stuff while processing a frame.
    // For example, can be used to composite a single image from many to pass to a shader uniform, to get around the
    // limitation of max 16 samplers for a shader.
    const tempFbo = useFBO(fboWidth, fboHeight, {
        depthBuffer: false,
        generateMipmaps: false,
        stencilBuffer: false,
    });
    const depthMap = useFBO(
        totalSize ? totalSize.width * DEPTH_SCALE : undefined,
        totalSize ? totalSize.height * DEPTH_SCALE : undefined,
        {
            depthBuffer: false,
            stencilBuffer: false,
            multisample: false,
            generateMipmaps: false,
        }
    );

    useEffect(() => {
        onSizeChanged(size.width, size.height);
    }, [size.width, size.height, onSizeChanged]);

    // Save a picture of the location when the user navigates away or closes the tab/browser.
    useEffect(() => {
        const c = campaign.id;
        return history.block(() => {
            saveCampaignThumbnail(c, gl.domElement);
        });
    }, [campaign.id, history, gl]);
    useEffect(() => {
        const c = campaign.id;
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            saveCampaignThumbnail(c, gl.domElement);
        };
        window.addEventListener("beforeunload", onBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [campaign.id, history, gl]);

    const annotationRef = useRef(annotation);
    annotationRef.current = annotation;
    const addPointRef = useRef(addPointToAnnotation);
    addPointRef.current = addPointToAnnotation;
    const onClick = useCallback(
        (e: ThreeEvent<MouseEvent>, o: Annotation | Token | Zone) => {
            if (isTargettedAnnotation(annotationRef.current) && isToken(o)) {
                addPointRef.current(o);
                e.nativeEvent.stopPropagation();
                e.nativeEvent.preventDefault();
                e.stopPropagation();
            } else if (onItemSelected(o)) {
                e.nativeEvent.stopPropagation();
                e.nativeEvent.preventDefault();
                e.stopPropagation();
            }
        },
        [onItemSelected]
    );
    const onPointerDown = useCallback(
        (e: ThreeEvent<PointerEvent>, item: Token | Annotation | Zone) => {
            if (e.nativeEvent.button === 2 && !isZone(item)) {
                onStartMeasure(item);
            }

            // Can't preventDefault here because "Unable to preventDefault inside passive event listener invocation."
            e.nativeEvent.stopPropagation();
            e.stopPropagation();
        },
        [onStartMeasure]
    );
    const onContextMenuRef = useRef<
        (
            pos:
                | {
                      localPos: LocalPixelPosition;
                      screenPos: ScreenPixelPosition;
                      target?: Annotation | Token;
                  }
                | undefined
        ) => boolean
    >(onContextMenu);
    onContextMenuRef.current = onContextMenu;
    const onPointerUp = useCallback(
        (e: ThreeEvent<PointerEvent>, item: Token | Annotation | Zone) => {
            if (e.nativeEvent.button === 2) {
                var point = pointerEventToLocalPoint(e.nativeEvent, localGrid, threeRef.current);
                if (
                    onContextMenuRef.current({
                        screenPos: screenPoint(e.nativeEvent.clientX, e.nativeEvent.clientY),
                        localPos: point,
                        target: item,
                    })
                ) {
                    e.nativeEvent.stopPropagation();
                    e.stopPropagation();
                }
            }
        },
        [localGrid, threeRef]
    );

    // Set up any particle effects.
    const zone = useMemo(
        () => (totalSize ? getZonePolygon(totalSize.x, totalSize.y, totalSize.width, totalSize.height) : undefined),
        [totalSize]
    );
    const particlesSceneRef = useRef<Scene>(undefined as any);
    const particlesCamera = useMemo(() => {
        const c = new PerspectiveCamera(50, 1, 0.1, 20000);
        c.layers.disableAll();
        c.layers.enable(VttCameraLayers.PerspectiveLighting);
        c.up.set(0, 0, 1);
        return c;
    }, []);
    const distanceToFit = cameraDistanceToFit(particlesCamera, localGrid, size);
    const orthographicPoint = grid.toLocalPoint(screenPoint(size.width / 2, size.height / 2));
    particlesCamera.position.set(orthographicPoint.x, -orthographicPoint.y, distanceToFit);
    particlesCamera.aspect = size.width / size.height;
    particlesCamera.updateProjectionMatrix();
    particlesCamera.updateMatrixWorld();

    useRain(
        camera,
        levelInfo,
        levelKey,
        totalSize,
        depthMap.texture,
        size,
        particlesSceneRef,
        particlesCamera,
        grid,
        location,
        zone,
        location.rainAmount
    );

    useSnow(
        camera,
        levelInfo,
        levelKey,
        totalSize,
        depthMap.texture,
        size,
        particlesSceneRef,
        particlesCamera,
        grid,
        location,
        zone,
        location.snowAmount
    );

    const levelKeys = getLevelKeysInRenderOrder(location);
    const levelIndex = levelKeys.indexOf(levelKey);
    const [antialiasType] = useLocalSetting(antialiasTypeSetting, "SMAA");
    const [smaaQuality] = useLocalSetting(smaaQualitySetting, SMAAPreset.HIGH);

    const effectComposerRef = useRef<EffectComposerImpl>(null);

    const vignetteL = useMotionValue(0);
    const vignetteR = useMotionValue(0);
    const vignetteV = useMotionValue(0);
    const postProcessRef = useRef<VttEffectImpl>(null);
    const isPanelExpanded = isSearchExpanded || isPropertiesExpanded;
    useEffect(() => {
        animate(vignetteL, isSearchExpanded ? 0.15 : 0.05, {
            duration: 0.6,
            ease: "easeOut",
        });
    }, [isSearchExpanded, vignetteL]);
    useEffect(() => {
        animate(vignetteR, isPropertiesExpanded ? 0.15 : 0.05, {
            duration: 0.6,
            ease: "easeOut",
        });
    }, [isPropertiesExpanded, vignetteR]);
    useEffect(() => {
        animate(vignetteV, isPanelExpanded ? 0.15 : 0.05, {
            duration: 0.6,
            ease: "easeOut",
        });
    }, [isPanelExpanded, vignetteV]);

    useFrame(() => {
        if (postProcessRef.current) {
            postProcessRef.current.darknessL = vignetteL.get();
            postProcessRef.current.darknessR = vignetteR.get();
            postProcessRef.current.darknessT = vignetteV.get();
            postProcessRef.current.darknessB = vignetteV.get();
        }
    });

    // Insert a render pass for the particles into the effect composer.
    const particlesRenderPass = useRef<RenderPass>();
    useEffect(() => {
        if (effectComposerRef.current) {
            if (camera.type === "OrthographicCamera") {
                // With the orthographic camera, we need an extra render pass for particles, as they're rendered with a perspective camera.
                if (!particlesRenderPass.current) {
                    particlesRenderPass.current = new RenderPass(scene, particlesCamera);
                    particlesRenderPass.current.clearPass.setClearFlags(false, false, false);
                    particlesRenderPass.current.clearPass.enabled = false;
                    effectComposerRef.current.addPass(particlesRenderPass.current, 1);
                }
            } else if (particlesRenderPass.current) {
                // Perspective camera doesn't need the extra particles pass, the particles should be part of the scene already.
                effectComposerRef.current.removePass(particlesRenderPass.current);
                particlesRenderPass.current.dispose();
                particlesRenderPass.current = undefined;
            }
        }
    }, [camera, effectComposerRef, particlesCamera, scene]);

    // For some bizarre reason if we put autoClear back to true here, then we clear between frames. Not even sure what does it.
    // But if we don't have this, then clear never does anything and we just draw over the top of ourselves constantly.
    useFrame(state => {
        state.gl.autoClear = true;
    }, RenderOrder.Cleanup);

    const [animationLights, setAnimationLights] = useState<{ [id: string]: PositionedLight }>();
    const animationLighting = useMemo<AnimationLightingProps>(() => {
        return {
            lights: animationLights ?? {},
            setLight: light => {
                setAnimationLights(l => Object.assign({}, l, { [light.id]: light }));
            },
            removeLight: id => {
                setAnimationLights(l => {
                    const ln = Object.assign({}, l);
                    delete ln[id];
                    return ln;
                });
            },
        };
    }, [animationLights]);

    const { tokenOverrides } = useTokenOverrides();

    return (
        <React.Fragment>
            {camera.type === "PerspectiveCamera" && (
                <PerspectiveCameraControl
                    totalSize={totalSize}
                    levelInfo={levelInfo}
                    camera={camera as PerspectiveCamera}
                    setPosition={setPosition}
                />
            )}

            <group>
                <scene ref={particlesSceneRef} />
            </group>

            {/* <mesh position={[250, -250, 500]}>
            <planeGeometry args={[500, 500]} />
            <meshBasicMaterial map={depthMap.texture} />
        </mesh> */}

            {totalSize && <DepthMap target={depthMap} levelInfo={levelInfo} totalSize={totalSize} />}

            <AnimationLightingContext.Provider value={animationLighting}>
                {levelKeys.map((k, i) => {
                    const o = location.levels[k];
                    const isVisible =
                        (i <= levelIndex || o.visibility === "always") && (!o.hiddenInBuild || mode !== "build");
                    const annotationPos = isAnnotation(annotation)
                        ? getAnnotationPos(annotation, campaign, location, grid, tokenOverrides)
                        : undefined;
                    const levelAnnotation = annotationPos?.level === k ? annotation : undefined;
                    return (
                        <LocationLevelStage
                            key={k}
                            level={o}
                            levelKey={k}
                            isVisible={isVisible}
                            grid={grid}
                            localGrid={localGrid}
                            levelInfo={levelInfo}
                            hasVisionSource={hasVisionSource}
                            annotation={levelAnnotation}
                            annotationPoint={levelAnnotation ? annotationPoint : undefined}
                            canSelect={canSelect}
                            onClick={isVisible ? onClick : undefined}
                            onPointerDown={isVisible ? onPointerDown : undefined}
                            onPointerUp={isVisible ? onPointerUp : undefined}
                            isPointSnappingEnabled={isPointSnappingEnabled}
                            zonePreview={levelKey === k ? zonePreview : undefined}
                            dragPreviews={levelKey === k ? dragPreviews : undefined}
                            measureFrom={levelKey === k ? measureFrom : undefined}
                            measureTo={levelKey === k ? measureTo : undefined}
                            measureFromLocal={levelKey === k ? measureFromLocal : undefined}
                            measureToLocal={levelKey === k ? measureToLocal : undefined}
                            gridPlacementState={levelKey === k ? gridPlacementState : undefined}
                            tempFbo={tempFbo}
                            totalSize={totalSize}
                            onSizeChanged={onLevelSizeChanged}
                        />
                    );
                })}
            </AnimationLightingContext.Provider>

            <EffectComposer
                ref={effectComposerRef}
                multisampling={antialiasType === "MSAA" ? 4 : 0}
                renderPriority={RenderOrder.FinalComposite}
                autoClear={false}>
                <React.Fragment>
                    {antialiasType === "SMAA" && <SMAA preset={smaaQuality} />}
                    <VttEffect ref={postProcessRef} offsetL={0.3} offsetR={0.3} offsetT={0.05} offsetB={0.05} />
                </React.Fragment>
            </EffectComposer>
        </React.Fragment>
    );
};

function preventEvent(e: React.SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();
}

function translateItem(g: ILocalGrid, item: Token | Annotation | Zone, delta: LocalPixelPosition, levelKey?: string) {
    if (!item.pos) {
        return item;
    }

    let pos = item.pos;
    if (pos.type === PositionType.Grid) {
        let lp = g.toLocalPoint(pos);
        lp.x += delta.x;
        lp.y += delta.y;
        pos = { ...g.toGridPoint(lp), level: levelKey ?? pos.level };
    } else {
        pos = {
            ...localPoint(pos.x + delta.x, pos.y + delta.y),
            level: levelKey ?? pos.level,
        };
    }

    return Object.assign({}, item, { pos: pos });
}

function getRelativeSelection(location: Location, g: ILocalGrid, selection: string[]) {
    const items = getSelectedItems(selection, location);
    if (!items.length) {
        return;
    }

    // Get the top leftest item.
    let topLeft: LocalPixelPosition | undefined;
    items.forEach(o => {
        if (o.pos) {
            let p = o.pos.type === PositionType.LocalPixel ? o.pos : g.toLocalPoint(o.pos);
            if (!topLeft) {
                topLeft = Object.assign({}, p);
            } else {
                if (p.x < topLeft.x) {
                    topLeft.x = p.x;
                }

                if (p.y < topLeft.y) {
                    topLeft.y = p.y;
                }
            }
        }
    });

    if (!topLeft) {
        return;
    }

    // Adjust all the points to be relative to that top left.
    topLeft.x = -topLeft.x;
    topLeft.y = -topLeft.y;
    const delta = topLeft;
    return items.map(o => translateItem(g, o, delta));
}

function breakWalls(
    dispatch: Dispatch<AnyAction>,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    start: LocalPixelPosition,
    end: LocalPixelPosition
) {
    // If a door or window was made along a line segment, we may wish to split that into multiple lines.
    // Or, if it's a closed polygon, we might just want to modify the existing one.
    Object.getOwnPropertyNames(location.annotations).forEach(o => {
        const annotation = location.annotations[o];
        if (isLineAnnotation(annotation) && annotation.subtype === "wall") {
            // Get the polygon, with rotation etc taken into account.
            const poly = getObstructionPolygon(annotation, campaign, location, grid);
            if (poly.isClosed) {
                poly.points.push(poly.points[0]);
            }

            // Find the segment that this door is being created on, if any.
            for (let i = 1; i < poly.points.length; i++) {
                const a = localPoint(poly.pos.x + poly.points[i - 1].x, poly.pos.y + poly.points[i - 1].y);
                const b = localPoint(poly.pos.x + poly.points[i].x, poly.pos.y + poly.points[i].y);
                let gapStart = start;
                let gapEnd = end;
                if (isPointOnLine(start, a, b) && isPointOnLine(end, a, b)) {
                    // The door is being created within this segment of a wall. Split the wall.
                    const segmentsBefore = poly.points.slice(0, i);
                    const segmentsAfter = poly.points.slice(i);

                    // The order of the points depends on which one is closer to the the start of the segment.
                    if (distanceBetween(gapStart, a) > distanceBetween(gapEnd, a)) {
                        const temp = gapStart;
                        gapStart = gapEnd;
                        gapEnd = temp;
                    }

                    segmentsBefore.push({
                        x: gapStart.x - poly.pos.x,
                        y: gapStart.y - poly.pos.y,
                    });
                    segmentsAfter.unshift({
                        x: gapEnd.x - poly.pos.x,
                        y: gapEnd.y - poly.pos.y,
                    });

                    // TODO: Check what happens when the door points are exactly a point on the line.

                    // TODO: Unrotate the points around the old annotation's center to get the final points.

                    if (annotation.isClosed) {
                        // Instead of creating two lines, just move the start point to include the after points
                        // at the start of the original line.
                        segmentsBefore.unshift(...segmentsAfter);
                    }

                    // TODO: We're creating a new annotation here because the json patching doesn't seem to handle something
                    // when we just modify the existing points. Not sure if it's the client or server that's at fault.
                    const newWall1 = copyState(annotation, {
                        id: nanoid(),
                        points: segmentsBefore,
                        isClosed: false,
                    });
                    dispatch(addAnnotation(campaign.id, location.id, newWall1));

                    if (!annotation.isClosed && segmentsAfter.length > 1) {
                        const newWall2 = copyState(annotation, {
                            id: nanoid(),
                            points: segmentsAfter,
                            isClosed: false,
                        });
                        dispatch(addAnnotation(campaign.id, location.id, newWall2));
                    }

                    dispatch(deleteItems(campaign.id, location.id, [annotation]));
                }
            }
        }
    });
}

function clearMeasureOverride(
    id: string | undefined,
    location: Location,
    overrideToken: (id: string, override: DeepPartial<Token> | undefined) => void,
    overrideAnnotation: (id: string, override: DeepPartial<Annotation> | undefined) => void
) {
    if (id == null) {
        return;
    }

    if (location.tokens[id]) {
        overrideToken(id, undefined);
    } else {
        overrideAnnotation(id, undefined);
    }
}

function getDefaultScale(location: Location) {
    // Find a scale based on the tile size, ensuring we display at a consistent scale by default even if locations have
    // different tile sizes.
    return location.tileSize.width > 0 ? 75 / location.tileSize.width : 1;
}

function getDefaultPosition(
    user: UserInfo,
    campaign: Campaign,
    location: Location,
    canvasWidth: number | undefined,
    canvasHeight: number | undefined,
    locationRect: Rect | undefined,
    scale: number | undefined
) {
    if (canvasWidth != null && canvasHeight != null) {
        const tokens = Object.values(location.tokens);
        const ownedToken = tokens.find(o => getTokenOwner(campaign, o) === user.id);
        scale = scale ?? getDefaultScale(location);
        if (ownedToken) {
            // No position has been set, but we have a canvas with a width/height AND an owned token that
            // we can center the view on.
            const tempGrid = createGrid(campaign.gridType, location.tileSize, 1, {
                x: 0,
                y: 0,
            });

            const ownedTokenPos = tempGrid.toLocalCenterPoint(ownedToken.pos);

            return {
                x: -ownedTokenPos.x * scale + canvasWidth / 2,
                y: -ownedTokenPos.y * scale + canvasHeight / 2,
            };
        } else {
            // We have a canvas with a width & height, but no owned token.
            if (locationRect != null) {
                return {
                    x: -(locationRect.width / 2) * scale + canvasWidth / 2 + locationRect.x,
                    y: -(locationRect.width / 2) * scale + canvasHeight / 2 + locationRect.y,
                };
            } else {
                return { x: 0, y: 0 };
            }
        }
    } else {
        // The canvas is not yet initialised, so we have no width/height to work with.
        return { x: 0, y: 0 };
    }
}

function getImageData(image: HTMLImageElement, chunkX: number, chunkY: number) {
    var canvas = document.createElement("canvas");
    const dw = Math.ceil(image.width / chunkX);
    const dh = Math.ceil(image.height / chunkY);
    canvas.width = dw;
    canvas.height = dh;

    // canvas.style.position = "absolute";
    // canvas.style.top = "0px";
    // canvas.style.left = "0px";
    // canvas.style.zIndex = "9999999";
    // document.body.appendChild(canvas);

    var context = canvas.getContext("2d");
    context!.drawImage(image, 0, 0, dw, dh);
    return context!.getImageData(0, 0, dw, dh);
}

export const LocationLevelStage: FunctionComponent<{
    grid: IGrid;
    localGrid: ILocalGrid;
    level: LocationLevel;
    levelKey: string;
    isVisible: boolean;
    canSelect: boolean;
    levelInfo: LevelInfo;
    tempFbo: WebGLRenderTarget;
    annotation: Annotation | Zone | undefined;
    annotationPoint: LocalPixelPosition | undefined;
    zonePreview?: Point[];
    dragPreviews?: DragPreview[];
    isPointSnappingEnabled: boolean;
    onClick?: (e: ThreeEvent<MouseEvent>, o: Annotation | Token | Zone) => void;
    onPointerDown?: (e: ThreeEvent<PointerEvent>, item: Token | Annotation | Zone) => void;
    onPointerUp?: (e: ThreeEvent<PointerEvent>, item: Token | Annotation | Zone) => void;
    measureFrom: LocalPixelPosition | Token | GridPosition | undefined;
    measureTo: LocalPixelPosition | Token | GridPosition | undefined;
    measureFromLocal: LocalPixelPosition | undefined;
    measureToLocal: LocalPixelPosition | undefined;
    hasVisionSource: boolean;
    gridPlacementState?: {
        points?: LocalPixelPosition[];
        tileSize?: Size;
        pos?: LocalPixelPosition;
    };
    totalSize: LocalRect | undefined;
    onSizeChanged: (
        levelKey: string,
        url: string | null,
        texture: Texture | null,
        size: Size | null,
        error?: Error
    ) => void;
}> = ({
    grid,
    localGrid,
    level,
    levelKey,
    canSelect,
    levelInfo,
    annotation,
    annotationPoint,
    zonePreview,
    dragPreviews,
    isPointSnappingEnabled,
    onClick,
    onPointerDown,
    onPointerUp,
    measureFrom,
    measureTo,
    measureFromLocal,
    measureToLocal,
    gridPlacementState,
    totalSize,
    onSizeChanged,
    isVisible,
    tempFbo,
    hasVisionSource,
}) => {
    const locationData = useValidatedLocationLevel();
    const { campaign, location, system } = locationData;
    let canvasSize: { width: number; height: number } = useThree(state => state.size);
    const { cameraMode, mode, buildMode, tool } = useVttApp();

    const [backgroundUrl, setBackgroundUrl] = useState<string | undefined>(undefined);
    const [backgroundTexture, setBackgroundTexture] = useState<Texture | undefined>(undefined);
    const [backgroundSize, setBackgroundSize] = useState<Size>();
    // const [backgroundError, setBackgroundError] = useState<Error | undefined>();
    const onBackgroundSizeChanged = useCallback(
        (url: string | null, texture: Texture | null, bs: Size | null, error?: Error) => {
            if (texture != null && bs != null && url != null) {
                setBackgroundUrl(url);
                setBackgroundTexture(texture);
                setBackgroundSize(bs);
                // setBackgroundError(error);
            }

            onSizeChanged(levelKey, url, texture, bs, error);
        },
        [levelKey, onSizeChanged]
    );
    const onSizeChangedRef =
        useRef<(levelKey: string, url: string | null, texture: Texture | null, size: Size | null) => void>();
    onSizeChangedRef.current = onSizeChanged;
    useEffect(() => {
        return () => {
            onSizeChangedRef.current!(levelKey, null, null, null);
        };
    }, [levelKey]);

    const size = level.backgroundImageUrl === backgroundUrl ? backgroundSize : undefined;

    // Work out size we want the FBOs to be. If we're in orthographic mode then they're essentially screen buffers.
    // If we're in perspective, then we need to render the entire level, regardless of pan/zoom, so we have to make the
    // buffers (potentially) much larger.
    let fboWidth: number | undefined;
    let fboHeight: number | undefined;
    const { isPerspective } = useCamera();
    if (isPerspective) {
        fboWidth = totalSize?.width;
        fboHeight = totalSize?.height;

        canvasSize = {
            width: fboWidth ?? canvasSize.width,
            height: fboHeight ?? canvasSize.height,
        };

        // TODO: The type here should be the same as the existing grid.
        grid = createGrid(GridType.Square, grid.tileSize, 1, { x: 0, y: 0 });
    }

    // Each level is drawn with its own camera, so that we don't need to worry about z-index when drawing the main scene for each level
    // and can just use the standard set of z-indexes. At some point we may want to switch to having the tokens z-index accurately reflect
    // their height in the world, but I don't think we're there yet.
    // This is the camera that is used to draw each level.
    const levelCamera = useMemo(() => {
        const c = new OrthographicCamera(undefined, undefined, undefined, undefined, 0.1, 10000);
        c.layers.disableAll();
        c.layers.enable(VttCameraLayers.Default);
        c.layers.enable(VttCameraLayers.DefaultNoRaycasting);
        c.layers.enable(VttCameraLayers.OrthographicOnly);
        return c;
    }, []);
    levelCamera.left = -(canvasSize.width / 2);
    levelCamera.right = canvasSize.width / 2;
    levelCamera.top = canvasSize.height / 2;
    levelCamera.bottom = -(canvasSize.height / 2);
    levelCamera.position.set(-(grid.offset.x - canvasSize.width / 2), -(-grid.offset.y + canvasSize.height / 2), 1000);
    levelCamera.updateProjectionMatrix();
    levelCamera.updateMatrixWorld();

    // Get a downsized version of the background as raw pixel data that we can query for information about certain
    // tiles (1 pixel = 1 grid tile).
    const tileWidth = location.tileSize.width;
    const tileHeight = location.tileSize.height;
    const backgroundTileData = useMemo(() => {
        if (backgroundTexture) {
            // Get the raw pixel data for the image after downscaling it.
            // TODO: This assumes that the grid starts at 0,0 and the image is evenly divisible by the tile size.
            // If one or both of those things are not true, there will be some distortion.
            const imageData = getImageData(backgroundTexture.image, tileWidth, tileHeight);
            return imageData;
        }

        return undefined;
    }, [tileWidth, tileHeight, backgroundTexture]);

    const { tokenOverrides } = useTokenOverrides();
    const { annotationOverrides } = useAnnotationOverrides();

    // We want to always pass the same instances to the tokens where possible, to prevent unnecessary
    // rerenders. So, because snapPoint is a callback and shouldn't need to cause a rerender anyway,
    // we cache some stuff we need for it in a ref rather than recreating it.
    const isSnappingEnabledRef = useRef(isPointSnappingEnabled);
    isSnappingEnabledRef.current = isPointSnappingEnabled;
    const snapPoint = useCallback(
        (point: LocalPixelPosition, filter?: (o: Annotation) => boolean) => {
            return isSnappingEnabledRef.current
                ? getSnappedPoint(point, campaign, location, levelKey, localGrid, totalSize, undefined, filter)
                : point;
        },
        [localGrid, campaign, location, levelKey, totalSize]
    );

    const targetZ =
        cameraMode === "perspective"
            ? levelInfo[levelKey]?.height ?? 0
            : levelInfo[levelKey].height / (location.tileSize.width * 10);

    // Render the scene to a FBO. We then pass that into the vis mask, which uses it as an input to render the final scene.
    const mainFbo = useFBO(fboWidth, fboHeight, {
        depthBuffer: false,
        generateMipmaps: false,
    });
    const mainSceneRef = useRef<Scene>(undefined as any);
    const [uiGroup, setUiGroup] = useState<Group | null>(null);
    const backgroundFbo = useFBO(fboWidth, fboHeight, {
        depthBuffer: false,
        generateMipmaps: false,
    });
    const backgroundSceneRef = useRef<Scene>(undefined as any);

    useFrame(state => {
        if (!mainSceneRef.current || !backgroundSceneRef.current) {
            return;
        }

        state.gl.setRenderTarget(backgroundFbo);
        state.gl.render(backgroundSceneRef.current, levelCamera);

        state.gl.setRenderTarget(mainFbo);
        state.gl.render(mainSceneRef.current, levelCamera);
        state.gl.setRenderTarget(null);
    }, RenderOrder.BackgroundAndMain);

    const isLoadingBackground = level.backgroundImageUrl && size == null;

    // TODO: This should get the primary token if no token is selected. For players, it should always be either the selected owned token
    // or the player's primary token. For now we're just using the first vision source.
    // Also, for audio we only want any position if it's for THIS level.
    const visionSourcesForLevel = Array.from(levelInfo[levelKey].sources);
    const primaryToken = visionSourcesForLevel.length
        ? applyOverrides(visionSourcesForLevel[0] as Token, tokenOverrides)
        : undefined;
    const audioListenerPos = primaryToken
        ? (localGrid.toLocalPoint(primaryToken.pos) as WithLevel<LocalPixelPosition>)
        : undefined;
    if (audioListenerPos) {
        audioListenerPos.level = primaryToken!.pos.level;
    }

    const zones = useDictionaryValues(location.zones);

    const tokenLevelFilter = useCallback(
        (o: Token) => {
            // Include the token if it references the level either in raw state or in an override.
            // Overrides of the level can happen during dragging, at which point we want to show the
            // token in both places.
            return (
                o.pos.level === levelKey ||
                tokenOverrides?.[o.id]?.pos?.level === levelKey ||
                !!tokenOverrides?.[o.id]?.currentGridPath?.path?.some(o => o.level === levelKey) ||
                !!o.nextPath?.some(o => o.level === levelKey) ||
                !!o.prevPath?.some(o => o.level === levelKey)
            );
        },
        [levelKey, tokenOverrides]
    );
    const tokens = useDictionaryValues(location.tokens, tokenLevelFilter);

    // Only show annotations when:
    // * They are visible in the current build mode
    // * They are for this level
    // * This level is the current level
    const annotationLevelFilter = useCallback(
        (o: Annotation) => {
            const pos = getAnnotationPos(o, campaign, location, localGrid, tokenOverrides);
            return pos.level === levelKey && levelKey === locationData.levelKey;
        },
        [levelKey, locationData.levelKey, campaign, location, localGrid, tokenOverrides]
    );
    const annotations = useDictionaryValues(location.annotations, annotationLevelFilter);

    // Check whether the obstructions have changed at all. If they have, we'll have to update a bunch of the lighting and visibility geometry.
    const obstructions = useMemo(() => {
        return getObstructions(
            location.annotations,
            o => {
                const pos = getAnnotationPos(o, campaign, location, localGrid, tokenOverrides);
                return o.obstructsLight && pos.level === levelKey;
            },
            o => applyOverrides(o, annotationOverrides)
        );
    }, [annotationOverrides, levelKey, campaign, location, localGrid, tokenOverrides]);
    const segments = useMemo(() => {
        return totalSize && level
            ? loadSegments(campaign, location, level, localGrid, totalSize, obstructions, tokenOverrides)
            : undefined;
    }, [campaign, location, level, localGrid, totalSize, obstructions, tokenOverrides]);

    const visibilityTarget = useFBO(fboWidth, fboHeight, {
        depthBuffer: false,
        generateMipmaps: false,
    });
    const lightingTarget = useFBO(fboWidth, fboHeight, {
        depthBuffer: false,
        generateMipmaps: false,
    });
    const darkvisionTarget = useFBO(fboWidth, fboHeight, {
        depthBuffer: false,
        generateMipmaps: false,
    });
    const renderInfo = useMemo<LocationLevelRenderInfo>(
        () => ({
            segments: segments,
            size: size,
            totalSize: totalSize,
            hasVisionSource: hasVisionSource,
            background: backgroundFbo,
            visibility: visibilityTarget,
            lighting: lightingTarget,
            darkvision: darkvisionTarget,
        }),
        [segments, size, totalSize, visibilityTarget, lightingTarget, darkvisionTarget, hasVisionSource, backgroundFbo]
    );
    levelInfo[levelKey].renderInfo = renderInfo;

    const animationLighting = useAnimationLighting();

    return (
        <LocationLevelContext.Provider value={renderInfo}>
            <motion.group animate={{ z: targetZ }} transition={cameraTransition} renderOrder={targetZ}>
                <group
                    visible={!isLoadingBackground}
                    ref={setUiGroup}
                    scale={[grid.scale, grid.scale, 1]}
                    renderOrder={ZIndexes.UserInterface}
                    position={[0, 0, 1]}>
                    {/* Here is where we render all of our UI level components which must appear over the lighting etc. */}
                </group>
                <UiLayerContext.Provider value={uiGroup}>
                    <group visible={false}>
                        <scene ref={backgroundSceneRef} scale={[grid.scale, grid.scale, 1]}>
                            <group visible={!isLoadingBackground}>
                                <BackgroundLayer
                                    key={level.backgroundImageUrl}
                                    backgroundImageUrl={level.backgroundImageUrl}
                                    backgroundImagePos={gridPlacementState?.pos ?? level.backgroundImagePos}
                                    layer={
                                        isVisible && levelKey === locationData.levelKey
                                            ? VttCameraLayers.Default
                                            : VttCameraLayers.DefaultNoRaycasting
                                    }
                                    onSizeChanged={onBackgroundSizeChanged}
                                />
                            </group>
                        </scene>
                        <scene ref={mainSceneRef}>
                            {levelKey === locationData.levelKey &&
                                !isLoadingBackground &&
                                tool !== "grid" &&
                                grid.render(139, 146, 148, 0.4, canvasSize)}
                            {!isLoadingBackground &&
                                gridPlacementState?.tileSize != null &&
                                createGrid(
                                    campaign.gridType,
                                    gridPlacementState.tileSize,
                                    grid.scale,
                                    grid.offset
                                ).render(59, 152, 252, 0.8, canvasSize)}

                            <group visible={!isLoadingBackground} scale={[grid.scale, grid.scale, 1]}>
                                {!isLoadingBackground && (
                                    <React.Fragment>
                                        <TokensNode
                                            tokens={tokens}
                                            levelKey={levelKey}
                                            annotation={annotation}
                                            mode={mode}
                                            buildMode={buildMode}
                                            canSelect={canSelect}
                                            onClick={onClick}
                                            onPointerDown={onPointerDown}
                                            onPointerUp={onPointerUp}
                                            snapPoint={snapPoint}
                                            audioListenerPos={audioListenerPos}
                                        />
                                        <AnnotationsNode
                                            annotations={annotations}
                                            annotation={isAnnotation(annotation) ? annotation : undefined}
                                            annotationPoint={isAnnotation(annotation) ? annotationPoint : undefined}
                                            mode={mode}
                                            buildMode={buildMode}
                                            tool={tool}
                                            canSelect={canSelect}
                                            onClick={onClick}
                                            onPointerDown={onPointerDown}
                                            onPointerUp={onPointerUp}
                                            snapPoint={snapPoint}
                                        />
                                        <ZonesNode
                                            zones={zones}
                                            mode={mode}
                                            buildMode={buildMode}
                                            canSelect={canSelect}
                                            zone={isZone(annotation) ? annotation : undefined}
                                            zonePoint={isZone(annotation) ? annotationPoint : undefined}
                                            zonePreview={zonePreview}
                                            onClick={onClick}
                                            snapPoint={snapPoint}
                                            audioListenerPos={audioListenerPos}
                                        />
                                    </React.Fragment>
                                )}
                                {measureFrom && measureTo && measureFromLocal && measureToLocal && (
                                    <React.Fragment>
                                        <Line
                                            x={0}
                                            y={0}
                                            points={[measureFromLocal, measureToLocal]}
                                            color={theme.colors.guidance.focus}
                                            width={theme.space[1]}
                                            zIndex={ZIndexes.Underlay}
                                        />
                                        <Circle
                                            x={measureFromLocal.x}
                                            y={measureFromLocal.y}
                                            scale={1 / grid.scale}
                                            color={theme.colors.guidance.focus}
                                            radius={theme.space[2]}
                                            segments={12}
                                            zIndex={ZIndexes.Underlay}
                                        />
                                        <Circle
                                            x={measureToLocal.x}
                                            y={measureToLocal.y}
                                            scale={1 / grid.scale}
                                            color={theme.colors.guidance.focus}
                                            radius={theme.space[2]}
                                            segments={12}
                                            zIndex={ZIndexes.Underlay}
                                        />
                                        <DistanceMessage
                                            pos={translate(measureToLocal, {
                                                x: 0,
                                                y: -(theme.space[1] / grid.scale),
                                            })}
                                            distance={
                                                (isToken(measureFrom) || measureFrom.type === PositionType.Grid) &&
                                                (isToken(measureTo) || measureTo.type === PositionType.Grid)
                                                    ? system.getGridRange(
                                                          campaign,
                                                          location,
                                                          localGrid,
                                                          measureFrom,
                                                          measureTo
                                                      )
                                                    : localGrid.gridDistanceBetween(measureFromLocal, measureToLocal)
                                            }
                                            horizontalAlignment="center"
                                            verticalAlignment="bottom"
                                        />
                                    </React.Fragment>
                                )}
                                <AnimatePresence>
                                    {dragPreviews &&
                                        dragPreviews.map((dragPreview, i) => (
                                            <Shape
                                                key={i}
                                                x={0}
                                                y={0}
                                                animateEnterExit="nolayout"
                                                points={dragPreview.shape}
                                                zIndex={ZIndexes.Overlay}
                                                color={getThemeColor(dragDropPalette[dragPreview.hover ? 3 : 0])}
                                                opacity={0.5}
                                            />
                                        ))}
                                </AnimatePresence>
                                <PingLayer />
                                {gridPlacementState?.points != null && (
                                    <UiLayer>
                                        {gridPlacementState.points.map((o, i) => (
                                            <Circle
                                                key={i}
                                                x={o.x}
                                                y={o.y}
                                                scale={1 / grid.scale}
                                                color={getThemeColor(theme.colors.accent[3])}
                                                radius={theme.space[2]}
                                                segments={32}
                                                animateEnterExit
                                                zIndex={ZIndexes.UserInterface}
                                            />
                                        ))}
                                    </UiLayer>
                                )}
                            </group>
                        </scene>
                    </group>

                    <VisibilityMask
                        key={location.id} // Giving it a key of the location forces a new VisibilityMask instance when locations switch, preventing anything from carrying over. It would probably be better to fix the bugs in VisibilityMask though.
                        canvasSize={canvasSize}
                        isVisible={isVisible}
                        levelKey={levelKey}
                        camera={levelCamera}
                        backgroundScene={backgroundFbo}
                        mainScene={mainFbo}
                        backgroundTileData={backgroundTileData}
                        grid={grid}
                        levelInfo={levelInfo}
                        hasVisionSource={hasVisionSource}
                        tempFbo={tempFbo}
                        lights={Object.values(animationLighting.lights).reduce(
                            (filtered, o) => {
                                if (o.pos.level === levelKey) {
                                    filtered.push(o);
                                }

                                return filtered;
                            },
                            tokens.reduce((filtered, o) => {
                                const token = o;
                                const tokenLevelKey = tokenOverrides?.[token.id]?.pos?.level ?? token.pos.level;
                                if (tokenLevelKey === levelKey) {
                                    let overrideToken: Token | undefined;

                                    // Get light defined directly on the token.
                                    if (token.light) {
                                        overrideToken = applyOverrides(token, tokenOverrides);
                                        const light: PositionedLight = Object.assign(
                                            {
                                                id: overrideToken.id,
                                                pos: overrideToken.pos,
                                            },
                                            overrideToken.light
                                        );
                                        filtered.push(light);
                                    }

                                    // Get lights from equipment etc, from the system.
                                    const tokenLights = system.getLightsForToken(token, campaign);
                                    if (tokenLights && tokenLights.length) {
                                        if (!overrideToken) {
                                            overrideToken = applyOverrides(token, tokenOverrides);
                                        }

                                        for (let i = 0; i < tokenLights.length; i++) {
                                            const light: PositionedLight = Object.assign(
                                                {
                                                    id: overrideToken.id,
                                                    pos: overrideToken.pos,
                                                },
                                                tokenLights[i]
                                            );
                                            filtered.push(light);
                                        }
                                    }
                                }

                                return filtered;
                            }, [] as PositionedLight[])
                        )}
                        segments={segments}
                        renderInfo={renderInfo}
                    />
                </UiLayerContext.Provider>
            </motion.group>
        </LocationLevelContext.Provider>
    );
};

function raycastSort<TIntersected extends Object3D>(a: Intersection<TIntersected>, b: Intersection<TIntersected>) {
    const n = a.distance - b.distance;
    if (n !== 0) {
        return n;
    }

    return b.object.renderOrder - a.object.renderOrder;
}

export const LocationStage: FunctionComponent<
    LocationStageProps & {
        gridRef: MutableRefObject<IGrid | undefined>;
        localGrid: ILocalGridWithRef;
        onViewportChanged: (viewport: Viewport | undefined) => void;
        onLoading: (isLoading: boolean) => void;
    }
> = ({ onViewportChanged, localGrid, gridRef, onLoading }) => {
    const dispatch = useDispatch();
    const user = useUser();
    const addNotification = useNotifications();
    const history = useHistory();
    const sessionData = useSessionConnection();
    const campaignData = useCampaign();
    const locationData = useValidatedLocationLevel();
    const campaign = locationData.campaign;
    const location = locationData.location;
    const level = locationData.level;
    const currentLevel = locationData.levelKey;
    const role = getRole(user, campaign);
    const errorHandler = useErrorHandler();

    const selectionProps = useSelection();
    const primarySelection = selectionProps.primary;
    const secondarySelection = selectionProps.secondary;
    const setSelection = selectionProps.setSelection;

    // Keep a reference to the location - used by event handlers that want to be able to access the latest
    // location at any point without having to change the props of all descendants (causing unnecessary renders).
    const locationRef = useRef<Location>();
    locationRef.current = location;

    // const tokens = useDictionaryValues(location.tokens);

    const {
        cameraMode,
        setCameraMode,
        isCameraChanging,
        setIsCameraChanging,
        setPropertiesPage,
        setIsPropertiesExpanded,
        mode,
        setMode,
        buildMode,
        setBuildMode,
        tool,
        subtool,
        setTool,
        annotationPlacementTemplateChanged,
    } = useVttApp();

    const [userScale, setScale] = useState<number>();
    const scale = userScale ?? getDefaultScale(location);

    // React concurrency seems to schedule the setPositions from pointer events in a way where it doesn't actually
    // happen until multiple pointer events have fired, so we have to use a ref to keep track of relative movement.
    let positionRef = useRef<{ x: number; y: number }>();
    let [positionDoNotUse, setPositionDoNotUse] = useState<{
        x: number;
        y: number;
    }>();
    const setPosition = (p: { x: number; y: number }) => {
        positionRef.current = p;
        setPositionDoNotUse(p);
    };

    const [menuProps, toggleMenu] = useMenuState({ transition: true });

    let [canvasWidth, setCanvasWidth] = useState<number | undefined>();
    let [canvasHeight, setCanvasHeight] = useState<number | undefined>();
    const onSizeChanged = useCallback(
        (width: number, height: number) => {
            setCanvasWidth(width);
            setCanvasHeight(height);
        },
        [setCanvasWidth, setCanvasHeight]
    );

    const tokenOverrideInfo = useTokenOverrides();
    const tokenOverrides = tokenOverrideInfo.tokenOverrides;
    const overrideToken = tokenOverrideInfo.overrideToken;
    const annotationOverrideInfo = useAnnotationOverrides();
    const overrideAnnotation = annotationOverrideInfo.overrideAnnotation;

    // Keep track of the levels textures and sizes.
    const [totalSize, setTotalSize] = useState<LocalRect>();
    const [levels, setLevels] = useState<{
        [levelKey: string]: SingleLevelInfoBasic;
    }>({});
    const levelsRef = useRef<{ [levelKey: string]: SingleLevelInfoBasic }>();
    if (!levelsRef.current) {
        levelsRef.current = {};
    }

    const onLevelSizeChanged = useCallback(
        (levelKey: string, url: string | null, texture: Texture | null, size: Size | null, error?: Error) => {
            const newLevels = copyState(levelsRef.current!, {
                [levelKey]: texture && size ? { texture, size } : error ? { error: error } : undefined,
            });

            if (texture && size) {
                // Work out the new total size for these levels.
                let x1: number | undefined;
                let y1: number | undefined;
                let x2: number | undefined;
                let y2: number | undefined;
                for (let key of Object.keys(newLevels)) {
                    const level = location.levels[key];
                    const levelSize = newLevels[key];

                    if (levelSize.size) {
                        const x = level.backgroundImagePos?.x ?? 0;
                        const y = level.backgroundImagePos?.y ?? 0;
                        x1 = x1 == null ? x : Math.min(x, x1);
                        y1 = y1 == null ? y : Math.min(y, y1);
                        x2 = x2 == null ? x + levelSize.size.width : Math.max(x + levelSize.size.width, x2);
                        y2 = y2 == null ? y + levelSize.size.height : Math.max(y + levelSize.size.height, y2);
                    }
                }

                if (x1 != null && y1 != null && x2 != null && y2 != null) {
                    setTotalSize(localRect(x1, y1, x2 - x1, y2 - y1));
                }
            }

            levelsRef.current = newLevels;
            setLevels(newLevels);
        },
        [location]
    );

    // If the user hasn't already panned or otherwise specified what they are looking at, we need to decide
    // what they see by default.
    if (positionRef.current == null || positionDoNotUse == null) {
        positionRef.current = getDefaultPosition(user, campaign, location, canvasWidth, canvasHeight, totalSize, scale);
    }

    const g = createGrid(campaign.gridType, location.tileSize, scale, {
        x: positionRef.current.x,
        y: positionRef.current.y,
    });
    gridRef.current = g;

    const viewportRef = useRef<Viewport>();
    useEffect(() => {
        if (canvasWidth != null && canvasHeight != null) {
            const viewportPosition = localGrid.toLocalRect(screenRect(0, 0, canvasWidth, canvasHeight));
            viewportRef.current = {
                levels: levels,
                totalSize: totalSize,
                position: viewportPosition,
                moveTo: (pos: LocalPixelPosition) => {
                    setPosition({
                        x: -(pos.x * scale) + canvasWidth! / 2,
                        y: -(pos.y * scale) + canvasHeight! / 2,
                    });
                },
            };
            onViewportChanged(viewportRef.current);
        }
    }, [
        positionRef.current.x,
        positionRef.current.y,
        canvasWidth,
        canvasHeight,
        scale,
        localGrid,
        onViewportChanged,
        totalSize,
        levels,
    ]);

    const panMomentum = useRef({
        momentumX: 0,
        momentumY: 0,
        x: 0,
        y: 0,
        timestamp: 0,
        nextCallback: 0,
    });

    // Drag drop stuff doesn't seem to update the callbacks during the drag, so we have to keep a ref to get the latest value instead :(
    const [isShift, setIsShift] = useState(false);
    const isShiftRef = useRef(false);
    isShiftRef.current = isShift;

    const [isCtrl, setIsCtrl] = useState(false);
    const [isPanMode, setIsPanMode] = useState(false);
    const [panStart, setPanStart] = useState(undefined as ScreenPixelPosition | undefined);
    const [annotationDragStart, setAnnotationDragStart] = useState(undefined as ScreenPixelPosition | undefined);
    const [annotationDragPos, setAnnotationDragPos] = useState(undefined as LocalPixelPosition | undefined);
    const [isDraggingAnnotation, setIsDraggingAnnotation] = useState(false);
    const ignoreClick = useRef(false);
    const [contextMenuTarget, setContextMenuTarget] = useState<
        { localPos: LocalPixelPosition; screenPos: ScreenPixelPosition; target?: Annotation | Token | Zone } | undefined
    >(undefined);

    // Currently in progress annotation (before committing to the global store).
    const [annotation, setAnnotation] = useState<Annotation | Zone | undefined>(undefined);
    const [annotationPoint, setAnnotationPoint] = useState<LocalPixelPosition | undefined>();

    // Use a ref to keep track of mouse position so that we don't trigger unnecessary renders.
    // If a mouse movement should trigger a render (such as when you're creating an annotation),
    // then the movement should trigger a state update separately.
    const mousePosRef = useRef<LocalPixelPosition>();

    const [measureFrom, setMeasureFrom] = useState<LocalPixelPosition | Token | GridPosition | undefined>(undefined);
    const [measureTo, setMeasureTo] = useState<LocalPixelPosition | Token | GridPosition | undefined>(undefined);
    const [measureId, setMeasureId] = useState<string | undefined>(undefined);
    const measureToken =
        measureId && location.tokens[measureId] ? resolveToken(campaign, location.tokens[measureId]) : undefined;
    const measureTokenAppearance = measureToken
        ? campaignData.system.getTokenAppearance?.(measureToken, campaign, location) ?? measureToken
        : undefined;
    const measureAnnotation = measureId && !measureToken ? location.annotations[measureId] : undefined;

    // TODO: Should anyone be allowed to rotate an annotation? What rule do we enforce elsewhere?
    const measureFromLocal =
        measureFrom != null
            ? isToken(measureFrom)
                ? localGrid.toLocalCenterPoint(
                      measureFrom.pos,
                      (campaignData.system.getTokenAppearance?.(measureFrom, campaign, location) ?? measureFrom).scale
                  )
                : localGrid.toLocalCenterPoint(measureFrom)
            : undefined;
    const measureToLocal =
        measureTo != null
            ? isToken(measureTo)
                ? localGrid.toLocalCenterPoint(
                      measureTo.pos,
                      (campaignData.system.getTokenAppearance?.(measureTo, campaign, location) ?? measureTo).scale
                  )
                : localGrid.toLocalCenterPoint(measureTo)
            : undefined;
    const measureRotation =
        (measureAnnotation ||
            (measureTokenAppearance &&
                (measureTokenAppearance.canRotate ||
                    (measureTokenAppearance.canRotate == null && measureTokenAppearance.imageUri != null)) &&
                (measureToken!.owner === user.id || role === "GM"))) &&
        measureFromLocal &&
        measureToLocal
            ? angle(measureFromLocal, measureToLocal) - 90
            : undefined;
    const isMeasureToken = !!measureToken;
    useEffect(() => {
        if (measureId && measureRotation != null) {
            if (isMeasureToken) {
                overrideToken(measureId, { rotation: measureRotation });
            } else {
                overrideAnnotation(measureId, { rotation: measureRotation });
            }
        }
    }, [overrideToken, overrideAnnotation, measureId, isMeasureToken, measureRotation]);

    const onItemSelected = useCallback(
        (item: Token | Annotation | Zone) => {
            if (isCtrl) {
                const newSelection = primarySelection.slice();
                const index = primarySelection.indexOf(item.id);
                if (index >= 0) {
                    newSelection.splice(index, 1);
                } else {
                    newSelection.push(item.id);
                }

                setSelection(newSelection);
            } else {
                if (isAnnotation(item) && item.tokenId) {
                    setSelection([item.id], [item.tokenId]);
                } else {
                    // Find all annotations related to this token and select them too.
                    const annotations: string[] = [];
                    for (let annotationId in locationRef.current?.annotations) {
                        const annotation = locationRef.current!.annotations[annotationId];
                        if (annotation.tokenId === item.id) {
                            annotations.push(annotation.id);
                        }
                    }

                    setSelection([item.id], annotations);
                }
            }

            return true;
        },
        [isCtrl, setSelection, primarySelection]
    );

    const completeAnnotation = useCallback(
        (annotation: Annotation | Zone) => {
            setAnnotation(undefined);
            setAnnotationPoint(undefined);

            let finalAnnotation = annotation;

            // Check if the shape is valid. If it isn't then we just do nothing.
            if (isAnnotation(annotation)) {
                var annotationTool = annotationTools[annotation.type];
                var completed = annotationTool.complete(annotation);
                if (!completed) {
                    return undefined;
                }

                finalAnnotation = completed;
                dispatch(addAnnotation(campaign.id, location.id, completed));
            } else {
                if (annotation.points.length < 3) {
                    return undefined;
                }

                dispatch(addZone(campaign.id, location.id, annotation));
            }

            onItemSelected(finalAnnotation);
            return finalAnnotation;
        },
        [dispatch, location, campaign, onItemSelected]
    );

    // TODO: Might need to rethink this a little? Now that the campaign & location are in this, it triggers
    // a rerender every time the campaign changes.
    const [startMeasureItem, setStartMeasureItem] = useState<any>();
    const [startMeasurePos, setStartMeasurePos] = useState<LocalPixelPosition>();
    const onStartMeasuring = useCallback(item => {
        setStartMeasureItem(item);
        setStartMeasurePos(mousePosRef.current);
    }, []);

    // Show a prompt to configure the grid size, if it hasn't been done already.
    usePermanentNotification(
        role === "GM" && !location.tileSize.isConfigured && tool !== "grid" && level?.backgroundImageUrl != null,
        () => (
            <Message>
                <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                    Setting up the grid
                </Truncate>
                <Text>The grid size has not been configured. Configure it now?</Text>
                <LobotomizedBox mt={3}>
                    <Button
                        onClick={() => {
                            setTool("grid");
                        }}>
                        Yes
                    </Button>
                    <Button
                        onClick={() => {
                            dispatch(
                                modifyLocation(campaign.id, location.id, {
                                    tileSize: Object.assign({}, location.tileSize, {
                                        isConfigured: true,
                                    }),
                                })
                            );
                        }}>
                        Don't ask again
                    </Button>
                </LobotomizedBox>
            </Message>
        )
    );

    // Keep track of the points being used to set the grid width/height/pos.
    const [gridPlacementPromise, setGridPlacementPromise] = useState<{
        promise: Promise<void>;
        resolve: () => void;
    }>();
    const [gridPlacementState, setGridPlacementState] = useState<{
        points?: LocalPixelPosition[];
        tileSize?: Size;
        pos?: LocalPixelPosition;
    }>();
    const gridPlacementStateEvent = useMemo(
        () =>
            new Event<
                | {
                      points?: LocalPixelPosition[];
                      tileSize?: Size;
                      pos?: LocalPixelPosition;
                  }
                | undefined
            >(),
        []
    );
    useEffect(() => {
        gridPlacementStateEvent.trigger(gridPlacementState);
    }, [gridPlacementState, gridPlacementStateEvent]);
    const toolRef = useRef(tool);
    const onGridPlacementComplete = useRef<() => void>();
    onGridPlacementComplete.current = () => {
        dispatch(
            applyGridPlacement(campaign.id, location.id, currentLevel!, {
                tileSize: gridPlacementState!.tileSize,
                backgroundImagePos: gridPlacementState?.pos,
            })
        );
    };
    useEffect(() => {
        if (tool !== toolRef.current) {
            if (tool === "grid") {
                setGridPlacementState(Object.assign({}, gridPlacementState, { points: [] }));

                let r: () => void;
                var p = new Promise<void>(resolve => {
                    r = resolve;
                });
                setGridPlacementPromise({ promise: p, resolve: r! });
                addNotification({
                    content: (
                        <NotificationStateHost initialState={gridPlacementState} stateChanged={gridPlacementStateEvent}>
                            <GridPlacementNotificationContent
                                onComplete={() => {
                                    onGridPlacementComplete?.current?.();
                                    setTool("select");
                                }}
                                onCancel={() => {
                                    setTool("select");
                                }}
                            />
                        </NotificationStateHost>
                    ),
                    canDismiss: false,
                    showLife: false,
                    promise: () => p,
                });
            } else {
                gridPlacementPromise?.resolve();
                setGridPlacementState(undefined);
                setGridPlacementPromise(undefined);

                const oldPos = gridPlacementState?.pos ?? level?.backgroundImagePos;
                const newPos = level?.backgroundImagePos ?? { x: 0, y: 0 };
                const delta = oldPos ? { x: newPos.x - oldPos.x, y: newPos.y - oldPos.y } : { x: 0, y: 0 };
                setPosition({
                    x: (positionRef.current?.x ?? 0) - delta.x * scale,
                    y: (positionRef.current?.y ?? 0) - delta.y * scale,
                });
            }
        }

        toolRef.current = tool;
    }, [
        tool,
        addNotification,
        setTool,
        gridPlacementPromise,
        gridPlacementState,
        gridPlacementStateEvent,
        location,
        scale,
        level?.backgroundImagePos,
    ]);

    // Annotation placement templates.
    const [annotationTemplate, setAnnotationTemplate] = useState<AnnotationPlacementTemplate<Annotation>>();
    const [annotationTemplatePromise, setAnnotationTemplatePromise] = useState<{
        promise: Promise<void>;
        resolve: () => void;
    }>();
    const [annotationTemplateStateEvent, setAnnotationTemplateStateEvent] = useState<
        Event<{
            annotation?: Annotation;
            template?: AnnotationPlacementTemplate<Annotation>;
            campaign: Campaign;
            location: Location;
            session: Required<SessionConnection>;
            diceBag: { dice?: DiceBag; setDice: (dice: DiceBag) => void };
            completeAnnotation: (annotation: Annotation) => void;
        }>
    >();
    const annotationForTemplate = annotationTemplate && isAnnotation(annotation) ? annotation : undefined;
    const diceBag = useDiceBag();
    useEffect(() => {
        if (annotationForTemplate) {
            annotationTemplateStateEvent?.trigger({
                annotation: annotationForTemplate,
                template: annotationTemplate,
                campaign: locationData.campaign,
                location: locationData.location,
                session: sessionData,
                diceBag: diceBag,
                completeAnnotation: o => {
                    completeAnnotation(o);
                    annotationPlacementTemplateChanged.trigger(undefined);
                },
            });
        }
    }, [
        annotationTemplate,
        annotationTemplateStateEvent,
        annotationForTemplate,
        locationData.campaign,
        locationData.location,
        sessionData,
        diceBag,
        completeAnnotation,
        annotationPlacementTemplateChanged,
    ]);
    useEffect(() => {
        var handler = (template: AnnotationPlacementTemplate<Annotation> | undefined) => {
            if (annotationTemplatePromise) {
                setAnnotation(undefined);
                setAnnotationPoint(undefined);
                setMeasureFrom(undefined);
                setMeasureId(undefined);
                setAnnotationTemplateStateEvent(undefined);
                annotationTemplatePromise.resolve();
            }

            if (template?.annotation.centerOn || template?.annotation.pos) {
                // If the template has a set position, then if its shape is also fixed then we can just place it right away.
                let annotation: Annotation = Object.assign({}, template.annotation, {
                    id: nanoid(),
                    userId: user.id,
                });
                if (!canModifyAnnotationShape(annotation)) {
                    annotation = template.onPlacing?.(annotation, sessionData.session) ?? annotation;
                    dispatch(addAnnotation(locationData.campaign.id, locationData.location.id, annotation));
                    setSelection([annotation.id]);

                    template.onPlaced?.(annotation);
                    return;
                }
            }

            setAnnotationTemplate(template);

            // For targetted annotation templates, create the pending annotation right away so that you can see the range.
            if (template?.annotation.type === "target") {
                setAnnotation(
                    Object.assign({}, template.annotation, {
                        id: nanoid(),
                        pos: template.annotation.pos,
                        userId: user.id,
                    })
                );
            }

            if (template) {
                if (!template.annotation.pos && !template.annotation.centerOn) {
                    setMeasureFrom(template.origin);
                    setMeasureId(template.origin.id);
                }

                let r: () => void;
                var p = new Promise<void>(resolve => {
                    r = resolve;
                });
                setAnnotationTemplatePromise({ promise: p, resolve: r! });
                const evt = new Event<{
                    annotation?: Annotation;
                    template?: AnnotationPlacementTemplate<Annotation>;
                    campaign: Campaign;
                    location: Location;
                    session: Required<SessionConnection>;
                    diceBag: { dice?: DiceBag; setDice: (dice: DiceBag) => void };
                    completeAnnotation: (annotation: Annotation) => void;
                }>();
                setAnnotationTemplateStateEvent(evt);

                addNotification({
                    content: (
                        <NotificationStateHost
                            initialState={{
                                annotation: template.annotation,
                                template: template,
                                campaign: locationData.campaign,
                                location: locationData.location,
                                session: sessionData,
                                diceBag: diceBag,
                                completeAnnotation: completeAnnotation,
                            }}
                            stateChanged={evt}>
                            <AnnotationTemplateNotificationContent />
                        </NotificationStateHost>
                    ),
                    canDismiss: false,
                    showLife: false,
                    promise: () => p,
                });
            }
        };
        annotationPlacementTemplateChanged.on(handler);
        return () => {
            annotationPlacementTemplateChanged.off(handler);
        };
    }, [
        annotationPlacementTemplateChanged,
        sessionData,
        addNotification,
        localGrid,
        annotationTemplatePromise,
        user.id,
        locationData.campaign,
        dispatch,
        locationData.location,
        setSelection,
        completeAnnotation,
        locationData.system,
        currentLevel,
        diceBag,
    ]);

    useKeyboardShortcut(
        "Delete",
        () => {
            dispatch(deleteItems(campaign.id, location.id, primarySelection));
            setSelection(secondarySelection ?? []);
        },
        { isDisabled: !primarySelection.length }
    );
    useKeyboardShortcut(
        "Escape",
        ev => {
            if (gridPlacementState) {
                setTool("select");
            } else if (annotationTemplate) {
                setAnnotation(undefined);
                setAnnotationPoint(undefined);
                setAnnotationTemplate(undefined);
                setAnnotationTemplatePromise(undefined);
                annotationTemplatePromise?.resolve();
            } else if (annotation) {
                completeAnnotation(annotation);
            }

            if (measureFrom) {
                setMeasureFrom(undefined);
                setMeasureTo(undefined);
                clearMeasureOverride(measureId, location, overrideToken, overrideAnnotation);
                setMeasureId(undefined);
            }

            ev.preventDefault();
        },
        {
            priority: 100,
            isDisabled: !gridPlacementState && !annotation && !measureFrom && !annotationTemplate,
        }
    );
    useKeyboardShortcut(["Control", "c"], () => {
        Clipboard.copyToClipboard(getRelativeSelection(location, localGrid, primarySelection));
    });
    useKeyboardShortcut(["Control", "x"], () => {
        Clipboard.copyToClipboard(getRelativeSelection(location, localGrid, primarySelection));
        dispatch(deleteItems(campaign.id, location.id, primarySelection));
    });

    const paste = (pos: LocalPixelPosition | undefined) => {
        let data = Clipboard.getDataFromClipboard();
        if (Array.isArray(data)) {
            const pastedSelection: string[] = [];
            data.forEach(o => {
                if (isAnnotation(o)) {
                    const item = translateItem(
                        localGrid,
                        Object.assign({}, o, { id: nanoid() }),
                        pos ? pos : localPoint(0, 0),
                        currentLevel
                    );
                    pastedSelection.push(item.id);
                    dispatch(addAnnotation(campaign.id, location.id, item as Annotation));
                } else if (isToken(o)) {
                    const item = translateItem(
                        localGrid,
                        copyState(o, {
                            id: nanoid(),
                            prevPath: undefined,
                            nextPath: undefined,
                        }),
                        pos ? pos : localPoint(0, 0),
                        currentLevel
                    );
                    pastedSelection.push(item.id);
                    dispatch(addToken(campaign.id, location.id, item as Token));
                } else if (isZone(o)) {
                    const item = translateItem(
                        localGrid,
                        Object.assign({}, o, { id: nanoid() }),
                        pos ? pos : localPoint(0, 0),
                        currentLevel
                    );
                    pastedSelection.push(item.id);
                    dispatch(addZone(campaign.id, location.id, item as Zone));
                }
            });
            setSelection(pastedSelection);
        }
    };
    useKeyboardShortcut(["Control", "v"], () => {
        paste(mousePosRef.current);
    });
    useKeyboardShortcut(["o"], () => {
        // TODO: It would be nice to tidy this up so it's just calling setCameraMode. This also happens when the button is pressed in campaignhost.tsx.
        const targetMode = cameraMode === "orthographic" ? "perspective" : "orthographic";
        if (targetMode === "orthographic") {
            setIsCameraChanging(true);
        }

        setCameraMode(targetMode);
    });

    const panKeys = useMemo(() => {
        return {
            left: false,
            right: false,
            up: false,
            down: false,
        };
    }, []);

    // Subscribe to keyboard events on the document so that we're always listening, even if the focus was moved somewhere else.
    const shouldHavePerspectiveCamera = cameraMode === "perspective" || isCameraChanging;
    const shouldHavePerspectiveCameraRef = useRef(shouldHavePerspectiveCamera);
    shouldHavePerspectiveCameraRef.current = shouldHavePerspectiveCamera;
    useEffect(() => {
        const keydown = (e: KeyboardEvent) => {
            if (!filterKeyEvent(e)) {
                return;
            }

            if (e.key === " ") {
                // SPACE
                setIsPanMode(true);
            }

            if (!shouldHavePerspectiveCameraRef.current) {
                const wasPanning = panKeys.left || panKeys.right || panKeys.up || panKeys.down;
                if (e.key === "ArrowLeft" || e.key === "A" || e.key === "a") {
                    panKeys.left = true;
                } else if (e.key === "ArrowRight" || e.key === "D" || e.key === "d") {
                    panKeys.right = true;
                } else if (e.key === "ArrowUp" || e.key === "W" || e.key === "w") {
                    panKeys.up = true;
                } else if (e.key === "ArrowDown" || e.key === "S" || e.key === "s") {
                    panKeys.down = true;
                }

                const isPanning = panKeys.left || panKeys.right || panKeys.up || panKeys.down;
                if (!wasPanning && isPanning) {
                    let panStartTime: number | undefined;
                    const doKeyboardPan = (time: number) => {
                        const isPanning = panKeys.left || panKeys.right || panKeys.up || panKeys.down;
                        if (isPanning && positionRef.current && !shouldHavePerspectiveCameraRef.current) {
                            if (panStartTime === undefined) {
                                panStartTime = time;
                            }

                            // Calculate the elapsed time since the pan started, use this to calculate the pan velocity.
                            const delta = (time - panStartTime) / 1000;
                            const newPos = { x: positionRef.current.x, y: positionRef.current.y };
                            const panSpeed = 60;
                            if (panKeys.left) {
                                newPos.x = newPos.x + delta * panSpeed;
                            }

                            if (panKeys.right) {
                                newPos.x = newPos.x - delta * panSpeed;
                            }

                            if (panKeys.up) {
                                newPos.y = newPos.y + delta * panSpeed;
                            }

                            if (panKeys.down) {
                                newPos.y = newPos.y - delta * panSpeed;
                            }

                            setPosition(newPos);

                            // Ramp up to a max elapsed time of 1 second.
                            panStartTime = Math.max(panStartTime, time - 1000);
                            requestAnimationFrame(doKeyboardPan);
                        }
                    };

                    requestAnimationFrame(doKeyboardPan);
                }
            }

            setIsShift(e.shiftKey);
            setIsCtrl(e.ctrlKey);
        };

        const keyup = (e: KeyboardEvent) => {
            if (e.key === " ") {
                // SPACE
                setIsPanMode(false);
            }

            if (e.key === "ArrowLeft" || e.key === "A" || e.key === "a") {
                panKeys.left = false;
            } else if (e.key === "ArrowRight" || e.key === "D" || e.key === "d") {
                panKeys.right = false;
            } else if (e.key === "ArrowUp" || e.key === "W" || e.key === "w") {
                panKeys.up = false;
            } else if (e.key === "ArrowDown" || e.key === "S" || e.key === "s") {
                panKeys.down = false;
            }

            setIsShift(e.shiftKey);
            setIsCtrl(e.ctrlKey);
        };

        document.addEventListener("keydown", keydown);
        document.addEventListener("keyup", keyup);
        return () => {
            document.removeEventListener("keydown", keydown);
            document.removeEventListener("keyup", keyup);
        };
    }, [
        location,
        primarySelection,
        annotation,
        completeAnnotation,
        dispatch,
        measureFrom,
        setSelection,
        campaign,
        localGrid,
        panKeys,
    ]);

    // TODO: Do something with the drag preview (see non-html5 drag/drop below)? Can't know for sure what the drag preview should be, as
    // we can't even inspect the files being dropped until the drop completes.
    const { isDragOver, ...dragEvents } = useDropEvents(
        ["audio", "image"],
        async (data, e) => {
            const position = positionRef.current;
            if (!position) {
                return;
            }

            // Cache some values before we do any async stuff. If we don't, then react will
            // clear out the event object so it can be reused.
            // const rect = (e.nativeEvent.target as HTMLElement).getBoundingClientRect();
            const clientX = e.clientX;
            const clientY = e.clientY;

            setDragPreviews(undefined);

            if (e.dataTransfer.files && e.dataTransfer.files.length) {
                const libraryItems = await (
                    await dropFiles(
                        e.dataTransfer.files,
                        addNotification,
                        errorHandler,
                        undefined,
                        user,
                        campaign,
                        dispatch
                    )
                ).libraryItems;
                if (libraryItems?.length) {
                    let libraryItem = libraryItems[0];
                    if (libraryItem.metadata.type === "token") {
                        const g = createGrid(campaign.gridType, location.tileSize, scale, {
                            x: position.x,
                            y: position.y,
                        });
                        const localPoint = clientPosToLocalPoint(clientX, clientY, g, threeGetRef.current);
                        const gridPoint = g.toGridPoint(localPoint);
                        // console.log(
                        //     `Dropped library token ${libraryItem.name} at grid pos ${gridPoint.x}, ${gridPoint.y}.`
                        // );
                        const newToken: Token = {
                            id: nanoid(),
                            pos: { ...gridPoint, level: currentLevel! },
                            imageUri: libraryItem.uri,
                            canRotate: libraryItem.metadata.canRotate,
                            defaultRotation: libraryItem.metadata.rotation,
                            renderScale: libraryItem.metadata.renderScale,
                        };
                        dispatch(addToken(campaign.id, location.id, newToken));
                        setSelection([newToken.id]);
                    } else if (libraryItem.metadata.type === "object") {
                        const g = createGrid(campaign.gridType, location.tileSize, scale, {
                            x: position.x,
                            y: position.y,
                        });
                        const localPoint = clientPosToLocalPoint(clientX, clientY, g, threeGetRef.current);
                        // console.log(
                        //     `Dropped library object ${libraryItem.name} at local pos ${localPoint.x}, ${localPoint.y}.`
                        // );
                        const newToken: Token = {
                            id: nanoid(),
                            type: "object",
                            pos: { ...localPoint, level: currentLevel! },
                            imageUri: libraryItem.uri,
                            tileSize:
                                libraryItem.metadata.tilePxWidth && libraryItem.metadata.tilePxHeight
                                    ? {
                                          width: libraryItem.metadata.tilePxWidth!,
                                          height: libraryItem.metadata.tilePxHeight!,
                                      }
                                    : undefined,
                            zIndex: libraryItem.metadata.zIndex,
                        };
                        dispatch(addToken(campaign.id, location.id, newToken));
                        setSelection([newToken.id]);
                    } else if (libraryItem.metadata.type === "background") {
                        dispatch(
                            modifyLocationLevel(campaign.id, location.id, currentLevel!, {
                                backgroundImageUrl: libraryItem.uri,
                            })
                        );
                        dispatch(
                            modifyLocation(campaign.id, location.id, {
                                thumbnailUri: libraryItem.metadata.thumbnailUri
                                    ? libraryItem.metadata.thumbnailUri
                                    : undefined,
                            })
                        );
                    } else {
                        console.error("Unknown library item type dropped onto location: " + libraryItem.metadata.type);
                    }
                }
            }
        },
        (data, e) => {
            const x = level?.backgroundImagePos?.x ?? 0;
            const y = level?.backgroundImagePos?.y ?? 0;
            const w = levels[currentLevel!]?.size?.width ?? 0;
            const h = levels[currentLevel!]?.size?.height ?? 0;
            setDragPreviews([
                {
                    shape: [localPoint(x, y), localPoint(x + w, y), localPoint(x + w, y + h), localPoint(x, y + h)],
                    hover: true,
                },
            ]);
        },
        (data, e) => {
            setDragPreviews(undefined);
        }
    );

    // Drag/drop stuff for internal elements being dropped - not using HTML5 drag/drop API.
    const [dragPreviews, setDragPreviews] = useState<DragPreview[]>();
    const dragPreviewsRef = useRef<DragPreview[]>();
    dragPreviewsRef.current = dragPreviews;
    const { setNodeRef, active } = useTypedDroppable({
        id: "locationstage",
        accepts: [...nonSystemDropTypes, ...campaignData.system.dropTypes],
        onDragOver: (drag: DragData, active: Active, pointerPos?: Point) => {
            const localPos = pointerPos
                ? clientPosToLocalPoint(pointerPos.x, pointerPos.y, g, threeGetRef.current)
                : undefined;
            const dropPoint = localPos
                ? localPos
                : viewportRef.current
                ? localPoint(
                      viewportRef.current.position.x + viewportRef.current.position.width / 2,
                      viewportRef.current.position.y + viewportRef.current.position.height / 2
                  )
                : undefined;

            // TODO: Show a preview of what the drop would be.
            switch (drag.type) {
                case `LibraryItem/${AudioType.Ambient}`:
                    if (dropPoint) {
                        const ellipseCurve = new EllipseCurve(
                            dropPoint.x,
                            dropPoint.y,
                            theme.space[4],
                            theme.space[4],
                            0,
                            Math.PI * 2,
                            false,
                            0
                        );
                        const points = ellipseCurve.getPoints(64).map(o => localPoint(o.x, o.y));
                        setDragPreviews([{ shape: points, hover: true }]);
                    }
                    break;
                case `LibraryItem/${AudioType.Music}`:
                case `LibraryItem/${ImageType.Background}`:
                    {
                        const x = level?.backgroundImagePos?.x ?? 0;
                        const y = level?.backgroundImagePos?.y ?? 0;
                        const w = levels[currentLevel!]?.size?.width ?? 0;
                        const h = levels[currentLevel!]?.size?.height ?? 0;
                        setDragPreviews([
                            {
                                shape: [
                                    localPoint(x, y),
                                    localPoint(x + w, y),
                                    localPoint(x + w, y + h),
                                    localPoint(x, y + h),
                                ],
                                hover: true,
                            },
                        ]);
                    }

                    break;
                case `LibraryItem/${ImageType.Token}`:
                    if (dropPoint) {
                        setDragPreviews([{ shape: g.toLocalPoints(g.toGridPoint(dropPoint)), hover: true }]);
                    }

                    break;
                case `LibraryItem/${ImageType.Object}`:
                    if (dropPoint) {
                        const objectItem = drag.data as LibraryItem;
                        const objectSize = getObjectImageSize(objectItem, location.tileSize);
                        if (objectSize) {
                            if (objectItem.metadata.tilePxWidth && objectItem.metadata.tilePxHeight) {
                                // Snap using the top left corner.
                                dropPoint.x -= objectSize.width / 2;
                                dropPoint.y -= objectSize.height / 2;
                                const snappedDropPoint = getSnappedPointFromPoint(dropPoint, true);
                                setDragPreviews([
                                    {
                                        shape: [
                                            localPoint(snappedDropPoint.x, snappedDropPoint.y),
                                            localPoint(snappedDropPoint.x + objectSize.width, snappedDropPoint.y),
                                            localPoint(
                                                snappedDropPoint.x + objectSize.width,
                                                snappedDropPoint.y + objectSize.height
                                            ),
                                            localPoint(snappedDropPoint.x, snappedDropPoint.y + objectSize.height),
                                        ],
                                        hover: true,
                                    },
                                ]);
                            } else {
                                const snappedDropPoint = getSnappedPointFromPoint(dropPoint, true);
                                setDragPreviews([
                                    {
                                        shape: [
                                            localPoint(
                                                snappedDropPoint.x - objectSize.width / 2,
                                                snappedDropPoint.y - objectSize.height / 2
                                            ),
                                            localPoint(
                                                snappedDropPoint.x + objectSize.width / 2,
                                                snappedDropPoint.y - objectSize.height / 2
                                            ),
                                            localPoint(
                                                snappedDropPoint.x + objectSize.width / 2,
                                                snappedDropPoint.y + objectSize.height / 2
                                            ),
                                            localPoint(
                                                snappedDropPoint.x - objectSize.width / 2,
                                                snappedDropPoint.y + objectSize.height / 2
                                            ),
                                        ],
                                        hover: true,
                                    },
                                ]);
                            }
                        } else {
                            // Don't know the size of this image - snap using center?
                            const snappedDropPoint = getSnappedPointFromPoint(dropPoint, true);
                            const ellipseCurve = new EllipseCurve(
                                snappedDropPoint.x,
                                snappedDropPoint.y,
                                theme.space[4],
                                theme.space[4],
                                0,
                                Math.PI * 2,
                                false,
                                0
                            );
                            const points = ellipseCurve.getPoints(64).map(o => localPoint(o.x, o.y));
                            setDragPreviews([{ shape: points, hover: true }]);
                        }
                    }

                    break;
                case "Token":
                    if (dropPoint) {
                        const droppedToken = drag.data as Token;
                        const tokenType = getTokenType(campaign, droppedToken);
                        if (tokenType === "audio" || tokenType === "light") {
                            const snappedDropPoint = getSnappedPointFromPoint(dropPoint, true);

                            // TODO: Show a different drag preview for lights/audio? Based off what their radius is?
                            const ellipseCurve = new EllipseCurve(
                                snappedDropPoint.x,
                                snappedDropPoint.y,
                                theme.space[4],
                                theme.space[4],
                                0,
                                Math.PI * 2,
                                false,
                                0
                            );
                            const points = ellipseCurve.getPoints(64).map(o => localPoint(o.x, o.y));
                            setDragPreviews([{ shape: points, hover: true }]);
                        } else if (droppedToken.type === "object") {
                            // TODO: Show a different drag preview for an object image.
                            //setDragPreview(g.toLocalPoints(g.toGridPoint(dropPoint), droppedToken.scale ?? 1));
                        } else {
                            setDragPreviews([
                                {
                                    shape: g.toLocalPoints(g.toGridPoint(dropPoint), droppedToken.scale ?? 1),
                                    hover: true,
                                },
                            ]);
                        }
                    }

                    break;
                default:
                    // None of the default drops matched, could be system specific.
                    const previews = campaignData.system.getDragPreview?.(
                        campaign,
                        location,
                        localGrid,
                        drag.type,
                        drag.data,
                        dispatch,
                        dropPoint
                    );
                    if (previews) {
                        setDragPreviews(previews);
                    }

                    break;
            }
        },
        onDragLeave: (drag: DragData) => {
            if (drag && campaignData.system.dropTypes.indexOf(drag.type) >= 0) {
                setDragPreviews(
                    campaignData.system.getDragPreview?.(campaign, location, localGrid, drag.type, dispatch, drag.data)
                );
            } else {
                setDragPreviews(undefined);
            }
        },
        onDrop: (drag: DragData, active: Active, pointerPos?: Point) => {
            // Drop point is the current mouse pos, or the viewport center.
            const localPos = pointerPos
                ? clientPosToLocalPoint(pointerPos.x, pointerPos.y, g, threeGetRef.current)
                : undefined;
            const dropPoint = localPos
                ? localPos
                : viewportRef.current
                ? localPoint(
                      viewportRef.current.position.x + viewportRef.current.position.width / 2,
                      viewportRef.current.position.y + viewportRef.current.position.height / 2
                  )
                : undefined;
            switch (drag.type) {
                case `LibraryItem/${AudioType.Ambient}`:
                    if (dropPoint) {
                        const libraryItem = drag.data as LibraryItem;
                        const newToken: Token = {
                            id: nanoid(),
                            type: "audio",
                            pos: { ...dropPoint, level: currentLevel! },
                            sound: {
                                uri: libraryItem.uri,
                                name: libraryItem.name,
                                radius: defaultSoundRadius * 2,
                            },
                        };
                        dispatch(addToken(campaign.id, location.id, newToken));
                        setSelection([newToken.id]);
                    }
                    break;
                case `LibraryItem/${AudioType.Music}`:
                    {
                        const libraryItem = drag.data as LibraryItem;
                        dispatch(
                            addTrack(campaign.id, location.id, AudioType.Music, {
                                uri: libraryItem.uri,
                                name: libraryItem.name,
                            })
                        );
                    }
                    break;
                case `LibraryItem/${ImageType.Background}`:
                    {
                        const libraryItem = drag.data as LibraryItem;

                        if (level.backgroundImageUrl == null) {
                            dispatch(
                                modifyLocationLevel(campaign.id, location.id, currentLevel!, {
                                    backgroundImageUrl: libraryItem.uri,
                                })
                            );

                            if (currentLevel === location.defaultLevel) {
                                dispatch(
                                    modifyLocation(campaign.id, location.id, {
                                        thumbnailUri: libraryItem.metadata.thumbnailUri
                                            ? libraryItem.metadata.thumbnailUri
                                            : undefined,
                                    })
                                );
                            }
                        } else {
                            // Show modal to ask whether the user wants to set the image as the background or add a new level.
                            setPendingBackground(libraryItem);
                        }
                    }
                    break;
                case `LibraryItem/${ImageType.Token}`:
                    if (dropPoint) {
                        const libraryItem = drag.data as LibraryItem;
                        const newToken: Token = {
                            id: nanoid(),
                            pos: {
                                ...g.toGridPoint(dropPoint),
                                level: currentLevel!,
                            },
                            imageUri: libraryItem.uri,
                            canRotate: libraryItem.metadata.canRotate,
                            defaultRotation: libraryItem.metadata.rotation,
                            renderScale: libraryItem.metadata.renderScale,
                        };

                        setBuildMode("tokens");
                        setTool("select");

                        dispatch(addToken(campaign.id, location.id, newToken));
                        setSelection([newToken.id]);
                    }
                    break;
                case `LibraryItem/${ImageType.Object}`:
                    if (dropPoint) {
                        const objectItem = drag.data as LibraryItem;
                        const objectSize = getObjectImageSize(objectItem, location.tileSize);

                        let snappedDropPoint: LocalPixelPosition;
                        if (objectSize && objectItem.metadata.tilePxWidth && objectItem.metadata.tilePxHeight) {
                            // Snap using the top left corner.
                            dropPoint.x -= objectSize.width / 2;
                            dropPoint.y -= objectSize.height / 2;
                            snappedDropPoint = getSnappedPointFromPoint(dropPoint, true);
                            snappedDropPoint.x += objectSize.width / 2;
                            snappedDropPoint.y += objectSize.height / 2;
                        } else {
                            // Don't know the size of this image - snap using center?
                            snappedDropPoint = getSnappedPointFromPoint(dropPoint, true);
                        }

                        setMode("build");
                        setBuildMode("base");
                        setTool("select");

                        const newToken: Token = {
                            id: nanoid(),
                            type: "object",
                            pos: { ...snappedDropPoint, level: currentLevel! },
                            imageUri: objectItem.uri,
                            tileSize:
                                objectItem.metadata.tilePxWidth && objectItem.metadata.tilePxHeight
                                    ? {
                                          width: objectItem.metadata.tilePxWidth!,
                                          height: objectItem.metadata.tilePxHeight!,
                                      }
                                    : undefined,
                            zIndex: objectItem.metadata.zIndex,
                        };

                        dispatch(addToken(campaign.id, location.id, newToken));
                        setSelection([newToken.id]);
                    }
                    break;
                case "Token":
                    if (dropPoint) {
                        const droppedToken = drag.data as Omit<Token, "pos">;
                        let pos: GridPosition | LocalPixelPosition = dropPoint;

                        // Only creature tokens get snapped to the grid.
                        const tokenType = getTokenType(campaign, droppedToken);
                        if (tokenType === "creature") {
                            pos = g.toGridPoint(dropPoint);
                        } else {
                            pos = getSnappedPointFromPoint(dropPoint, true);
                        }

                        if (tokenType === "light" || tokenType === "audio") {
                            setMode("build");
                        }

                        setBuildMode("tokens");
                        setTool("select");

                        const token: Token = Object.assign({}, droppedToken, {
                            pos: { ...pos, level: currentLevel! },
                        });
                        dispatch(addToken(campaign.id, location.id, token));
                        setSelection([token.id]);
                    }
                    break;
                default: {
                    if (dropPoint) {
                        const onDrop = dragPreviewsRef.current?.find(o => o.hover && o.onDrop != null)?.onDrop;
                        if (onDrop != null) {
                            onDrop(dropPoint);
                        }
                    }
                }
            }
        },
        renderFeedback: (drag: DragData) => {
            switch (drag.type) {
                case `LibraryItem/${AudioType.Ambient}`: {
                    const libraryItem = drag.data as LibraryItem;
                    return <React.Fragment>Drop to add {libraryItem.name} to this location.</React.Fragment>;
                }
                case `LibraryItem/${AudioType.Music}`: {
                    const libraryItem = drag.data as LibraryItem;
                    return (
                        <React.Fragment>
                            Drop to add {libraryItem.name} to the music playlist for this location.
                        </React.Fragment>
                    );
                }
                case `LibraryItem/${ImageType.Background}`: {
                    const libraryItem = drag.data as LibraryItem;
                    if (currentLevel === location.defaultLevel) {
                        if (level.backgroundImageUrl == null) {
                            return (
                                <React.Fragment>
                                    Drop to set {libraryItem.name} as the new background image for this location.
                                </React.Fragment>
                            );
                        } else {
                            return (
                                <React.Fragment>
                                    Drop to set {libraryItem.name} as the new background image for this location or add
                                    a new level.
                                </React.Fragment>
                            );
                        }
                    }

                    if (level.backgroundImageUrl == null) {
                        return (
                            <React.Fragment>
                                Drop to set {libraryItem.name} as the new background image for this level.
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <React.Fragment>
                                Drop to set {libraryItem.name} as the new background image for this level or add a new
                                level.
                            </React.Fragment>
                        );
                    }
                }
                case `LibraryItem/${ImageType.Token}`: {
                    const libraryItem = drag.data as LibraryItem;
                    return <React.Fragment>Drop to add {libraryItem.name} to this location.</React.Fragment>;
                }
                case `LibraryItem/${ImageType.Object}`: {
                    const libraryItem = drag.data as LibraryItem;
                    return <React.Fragment>Drop to add {libraryItem.name} to this location.</React.Fragment>;
                }
                case "Token": {
                    const token = drag.data as Token;
                    return (
                        <React.Fragment>
                            Drop to add {sessionData.system.getDisplayName(token, campaign)} to this location.
                        </React.Fragment>
                    );
                }
            }

            const feedback = dragPreviewsRef.current?.find(o => o.hover && o.feedback != null)?.feedback;
            if (feedback != null) {
                return typeof feedback === "function" ? feedback() : feedback;
            }

            return undefined;
        },
    });

    // Sometimes we want to show possible drop locations even when the cursor isn't over the map - i.e. highlight then on drag start
    // and remove them on drag finish.
    useEffect(() => {
        const dragData = active?.data.current as DragData | undefined;
        if (dragData && campaignData.system.dropTypes.indexOf(dragData.type) >= 0) {
            setDragPreviews(
                campaignData.system.getDragPreview?.(
                    campaign,
                    location,
                    localGrid,
                    dragData.type,
                    dispatch,
                    dragData.data
                )
            );
        } else {
            setDragPreviews(undefined);
        }
    }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

    const currentLevelSize = levels[currentLevel!]?.size;
    const getSnappedPointFromPoint = useCallback(
        (point: LocalPixelPosition, gridOnly?: boolean) => {
            if (isShiftRef.current) {
                if (!gridOnly) {
                    return getSnappedPoint(
                        point,
                        campaign,
                        location,
                        currentLevel,
                        localGrid,
                        totalSize,
                        isAnnotation(annotation) ? annotation : undefined
                    );
                }

                return getSnappedPoint(point, undefined, undefined, undefined, localGrid, totalSize);
            }

            return point;
        },
        [campaign, localGrid, location, annotation, currentLevel, totalSize]
    );

    const threeGetRef = useRef<() => RootState>();
    const getSnappedPointFromEvent = useCallback(
        (e: React.MouseEvent | React.PointerEvent, gridOnly?: boolean) => {
            let point = pointerEventToLocalPoint(e, localGrid, threeGetRef.current);
            return getSnappedPointFromPoint(point, gridOnly);
        },
        [getSnappedPointFromPoint, localGrid]
    );

    // Calculate the vision sources. These are important for working out what the user should be able to interact with (what
    // at least one of their controlled tokens can see), where we should pan to initially (make sure at least one of their
    // tokens is visible), and finally for actually limiting their view of the map.
    let levelInfo: LevelInfo = {};
    const levelKeys = getLevelKeysInRenderOrder(location);
    for (let i = 0; i < levelKeys.length; i++) {
        levelInfo[levelKeys[i]] = Object.assign({}, levels?.[levelKeys[i]], {
            height: i * location.tileSize.width * 2,
            sources: new Set<Token>(),
        });
    }

    let hasVisionSource = false;
    if (mode === "build" && buildMode !== "tokens") {
        // visionSources = new Set<Token>();
    } else {
        for (let i = 0; i < locationData.relevantIds.length; i++) {
            // Relevant IDs can be either the selection or for players just all the tokens that they have ownership (and tokens for
            // other members of the party, depending on campaign settings).
            const id = locationData.relevantIds[i];
            const token = location.tokens[id];
            if (token) {
                const resolvedToken = resolveToken(campaign, token);

                // Check that the token is, in fact, a creature.
                // A token is a creature if it explicitly says so, or if it doesn't specify its type at all but has an image.
                if (
                    resolvedToken.type === "creature" ||
                    (resolvedToken.type == null && resolvedToken.imageUri != null)
                ) {
                    // If it's a creature, then we use it for a vision source!
                    const tokenWithOverrides = applyOverrides(token, tokenOverrides);
                    levelInfo[tokenWithOverrides.pos.level].sources.add(tokenWithOverrides);
                    hasVisionSource = true;
                }
            }
        }
    }

    // A location is visible to players without a token if:
    // 1) There is ambient light in the location.
    // 2) There are NO annotations that obstruct light.
    // OR
    // 1) They are the GM.
    let hideStageIfNotAssignedToken = !level?.revealAll;
    const annotationFilter = useCallback((o: Annotation) => !o.buildOnly || mode === "build", [mode]);
    const annotations = useDictionaryValues(location.annotations, annotationFilter);

    let isStageVisible = role === "GM" || levelKeys.some(o => levelInfo[o].sources.size > 0);
    if (!isStageVisible) {
        for (let i = 0; !hideStageIfNotAssignedToken && i < annotations.length; i++) {
            if (
                !hideStageIfNotAssignedToken &&
                isObstructingAnnotation(annotation) &&
                (annotation as ObstructingAnnotation).obstructsLight
            ) {
                hideStageIfNotAssignedToken = true;
            }
        }

        isStageVisible = !hideStageIfNotAssignedToken;
    }

    const isLoadingBackground =
        !!level?.backgroundImageUrl && currentLevelSize == null && levels[currentLevel!]?.error == null;
    useEffect(() => {
        onLoading(isLoadingBackground);
    }, [onLoading, isLoadingBackground]);

    const isZoneFillActive = mode === "build" && buildMode === "zones" && tool === "zonefill";
    const [zonePreview, setZonePreview] = useState<Point[]>();
    const zoneFillEdgesRef = useRef<{
        annotations: { [annotationId: string]: Annotation };
        edges: [Point, Point][];
    }>();
    const zoneFillEdges = useMemo(() => {
        if (isZoneFillActive && totalSize != null && zoneFillEdgesRef.current?.annotations !== location.annotations) {
            // Start with the bounds of the location.
            let edges: [Point, Point][] = [
                [
                    { x: totalSize.x, y: totalSize.y },
                    { x: totalSize.x + totalSize.width, y: totalSize.y },
                ],
                [
                    { x: totalSize.x + totalSize.width, y: totalSize.y },
                    { x: totalSize.x + totalSize.width, y: totalSize.y + totalSize.height },
                ],
                [
                    { x: totalSize.x + totalSize.width, y: totalSize.y + totalSize.height },
                    { x: totalSize.x, y: totalSize.y + totalSize.height },
                ],
                [
                    { x: totalSize.x, y: totalSize.y + totalSize.height },
                    { x: totalSize.x, y: totalSize.y },
                ],
            ];

            // Add all the obstructions.
            const obstructions = getObstructions(
                location.annotations,
                o => o.obstructsMovement || o.obstructsLight,
                o =>
                    isDoorAnnotation(o)
                        ? {
                              id: o.id,
                              type: "line",
                              pos: o.pos,
                              points: [{ x: 0, y: 0 }, o.point],
                              obstructsLight: true,
                              obstructsMovement: true,
                              userId: o.userId,
                          }
                        : o
            );
            for (let i = 0; i < obstructions.length; i++) {
                addEdges(obstructions[i], campaign, location, localGrid, undefined, edges);
            }

            edges = simplifyEdges(edges);

            zoneFillEdgesRef.current = {
                annotations: location.annotations,
                edges: edges,
            };
        }

        return zoneFillEdgesRef.current?.edges;
    }, [isZoneFillActive, campaign, location, localGrid, totalSize]);

    const selectionByType = getSelectionByType(primarySelection, campaign, location);
    const systemMenuItems = campaignData.system.getTokenContextMenuItems(
        isToken(contextMenuTarget?.target) ? resolveToken(campaign, contextMenuTarget!.target) : undefined,
        selectionByType.tokens,
        campaign,
        location
    );
    let contextMenuAnchor: Point | undefined;
    if (contextMenuTarget) {
        if (
            isToken(contextMenuTarget.target) &&
            contextMenuTarget.target.pos?.type === PositionType.Grid &&
            cameraMode === "orthographic"
        ) {
            const bounds = localGrid.toLocalBounds(contextMenuTarget.target.pos, contextMenuTarget.target.scale);
            contextMenuAnchor = localGrid.toScreenPoint(localPoint(bounds.x + bounds.width, bounds.y));
            // TODO: In perspective mode, this should be projected using the camera.
        } else {
            contextMenuAnchor = contextMenuTarget.screenPos;
        }

        // Strip off the type otherwise react complains because of prop types.
        contextMenuAnchor = { x: contextMenuAnchor.x, y: contextMenuAnchor.y };
    }

    const addPointToAnnotation = (p: LocalPixelPosition | Token) => {
        if (!annotationTemplate) {
            return;
        }

        if (annotationTemplate.annotation.pos || annotationTemplate.annotation.centerOn) {
            const aap = annotationTools[annotationTemplate.annotation.type].addPoint(
                annotation as Annotation,
                campaign,
                location,
                localGrid,
                p
            );
            if (aap.isComplete) {
                const preFinal = annotationTemplate.onPlacing?.(aap.annotation, sessionData.session) ?? aap.annotation;
                const final = completeAnnotation(preFinal);
                if (final && annotationTemplate.onPlaced) {
                    annotationTemplate.onPlaced(final as Annotation);
                }

                setAnnotationTemplate(undefined);
                setAnnotationTemplatePromise(undefined);
                setMeasureFrom(undefined);
                setMeasureTo(undefined);
                clearMeasureOverride(measureId, location, overrideToken, overrideAnnotation);
                setMeasureId(undefined);
                annotationTemplatePromise?.resolve();
            } else {
                setAnnotation(aap.annotation);
            }
        } else {
            const preFinal =
                annotationTemplate.onPlacing?.(annotation as Annotation, sessionData.session) ??
                (annotation as Annotation);
            const final = completeAnnotation(preFinal);
            if (final && annotationTemplate.onPlaced) {
                annotationTemplate.onPlaced(final as Annotation);
            }

            setAnnotationTemplate(undefined);
            setAnnotationTemplatePromise(undefined);
            setMeasureFrom(undefined);
            setMeasureTo(undefined);
            clearMeasureOverride(measureId, location, overrideToken, overrideAnnotation);
            setMeasureId(undefined);
            annotationTemplatePromise?.resolve();
        }
    };

    const [pendingBackground, setPendingBackground] = useState<LibraryItem>();

    const pathFinder = useMemo(() => {
        return totalSize ? new PathFinder(campaign, location, totalSize, localGrid, campaignData.system) : undefined;
    }, [campaign, location, totalSize, localGrid, campaignData.system]);

    const camera = useMemo(() => {
        if (shouldHavePerspectiveCamera) {
            const camera = new PerspectiveCamera(50, 1, 0.1, 20000);
            camera.layers.enable(VttCameraLayers.DefaultNoRaycasting);
            camera.layers.enable(VttCameraLayers.PerspectiveOnly);
            camera.layers.enable(VttCameraLayers.PerspectiveLighting);
            camera.up.set(0, 0, 1);
            return camera;
        } else {
            const camera = new OrthographicCamera(undefined, undefined, undefined, undefined, 0.1, 10000);
            camera.layers.enable(VttCameraLayers.DefaultNoRaycasting);
            camera.layers.enable(VttCameraLayers.OrthographicOnly);
            camera.up.set(0, 0, 1);
            return camera;
        }
    }, [shouldHavePerspectiveCamera]);
    const isPerspective = camera.type === "PerspectiveCamera";

    const canMeasure = !isPerspective;
    const canSelect = isStageVisible && tool === "select" && !isPanMode;
    const canPan = isStageVisible && ((tool === "select" && gridPlacementState == null) || isPanMode) && !isPerspective;

    return (
        <PathFindingContext.Provider value={pathFinder}>
            <div
                ref={setNodeRef}
                className="location-canvas"
                css={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    cursor: measureFrom ? "default" : canPan ? (panStart ? "grabbing" : "grab") : "auto",
                }}
                tabIndex={-1}
                onContextMenu={preventEvent}
                onClick={e => {
                    if (dragStatus.isDragging) {
                        return;
                    }

                    // Ignore left clicks when pan mode is enabled.
                    if (isPanMode && e.button === 0) {
                        return;
                    }

                    // Ignore clicks if we've panned more than a few pixels.
                    if (ignoreClick.current) {
                        return;
                    }

                    if (e.detail === 2) {
                        if (annotation) {
                            completeAnnotation(annotation);
                        } else {
                            sessionData.api.ping(location.id, getSnappedPointFromEvent(e));
                        }

                        return;
                    }

                    // If an annotation is being dragged rather than clicked, then isDraggingAnnotation will still be set here.
                    // A click and drag still counts as a click.
                    if (isDraggingAnnotation) {
                        setIsDraggingAnnotation(false);
                        return;
                    }

                    if (gridPlacementState?.points != null) {
                        // Clicking adds another point to the grid placements. Then we recalculate the best fit values for the grid.
                        const point = pointerEventToLocalPoint(e, localGrid, threeGetRef.current);
                        const gpp = gridPlacementState.points.slice();
                        gpp.push(point);

                        // Remove the influence of the background image position, so that we're dealing with the points relative to
                        // 0,0 of the background image.
                        const oldPos = gridPlacementState.pos ?? level?.backgroundImagePos;
                        if (oldPos) {
                            for (let i = 0; i < gpp.length; i++) {
                                gpp[i] = localPoint(gpp[i].x - oldPos.x, gpp[i].y - oldPos.y);
                            }
                        }

                        const newState = Object.assign({}, gridPlacementState);
                        newState.points = gpp;

                        const settings = localGrid.getSettingsFromPoints(gpp);
                        if (settings) {
                            newState.tileSize = settings.tileSize;
                            newState.pos = settings.pos;

                            // Position is in screen pixels.
                            const delta = oldPos
                                ? {
                                      x: newState.pos.x - oldPos.x,
                                      y: newState.pos.y - oldPos.y,
                                  }
                                : newState.pos;
                            setPosition({
                                x: (positionRef.current?.x ?? 0) - delta.x * scale,
                                y: (positionRef.current?.y ?? 0) - delta.y * scale,
                            });
                        }

                        // Restore the influence of the background position on the clicked points, so that they line up with the image correctly.
                        const newPos = settings?.pos ?? oldPos;
                        if (newPos) {
                            for (let i = 0; i < newState.points.length; i++) {
                                newState.points[i] = translate(newState.points[i], newPos);
                            }
                        }

                        setGridPlacementState(newState);
                    }

                    // Target annotations are handled by clicking on the targets, rather than on the canvas.
                    if (annotationTemplate && annotation && annotationTemplate.annotation.type !== "target") {
                        addPointToAnnotation(annotationPoint!);
                        return;
                    }

                    if (tool === "select") {
                        if (annotationTemplate?.annotation.type !== "target") {
                            setSelection([]);
                        }
                    } else if (tool === "zone" || tool === "zonefill") {
                        // TODO: Implement zone stuff in new annotation tool handlers instead.
                        switch (tool) {
                            case "zone": {
                                if (e.button === 0) {
                                    const point = getSnappedPointFromEvent(e);
                                    if (annotation == null) {
                                        const zone = createZone(
                                            location,
                                            {
                                                ...localPoint(0, 0),
                                                level: currentLevel!,
                                            },
                                            [point]
                                        );
                                        setAnnotation(zone);
                                    } else {
                                        const zone = annotation as Zone;
                                        const points = zone.points.slice();
                                        points.push({
                                            x: point.x - zone.pos.x,
                                            y: point.y - zone.pos.y,
                                        });
                                        setAnnotation(copyState(zone, { points: points }));
                                    }
                                }

                                break;
                            }
                            case "zonefill": {
                                if (e.button === 0) {
                                    const point = getSnappedPointFromEvent(e);

                                    const containingPoly = getContainingPolygonFromPoint(point, zoneFillEdges!);
                                    if (containingPoly) {
                                        dispatch(
                                            addZone(
                                                campaign.id,
                                                location.id,
                                                createZone(
                                                    location,
                                                    {
                                                        ...localPoint(0, 0),
                                                        level: currentLevel!,
                                                    },
                                                    containingPoly
                                                )
                                            )
                                        );
                                    } else {
                                        console.log("Could not create zone, no enclosing polygon was found.");
                                    }
                                }

                                break;
                            }
                        }
                    } else {
                        var annotationTool = annotationTools[tool] as AnnotationTool;
                        if (annotationTool) {
                            const point = getSnappedPointFromEvent(e);
                            if (annotation == null) {
                                setAnnotation(
                                    annotationTool.createNew({ ...point, level: currentLevel }, user, subtool)
                                );
                            } else {
                                const result = annotationTool.addPoint(
                                    annotation as Annotation,
                                    campaign,
                                    location,
                                    localGrid,
                                    point
                                );
                                if (result.breakWalls) {
                                    breakWalls(
                                        dispatch,
                                        campaign,
                                        location,
                                        localGrid,
                                        result.breakWalls.start,
                                        result.breakWalls.end
                                    );
                                }

                                if (result.isComplete) {
                                    completeAnnotation(result.annotation);
                                } else {
                                    setAnnotation(result.annotation);
                                }
                            }
                        }
                    }
                }}
                {...dragEvents}>
                {!level?.backgroundImageUrl && role === "GM" && (
                    <Help id="location_no_background">
                        This location doesn't have a background yet. Try dropping an image file onto the page, either
                        from your local file system or the art library.
                    </Help>
                )}

                {!isStageVisible && (
                    <Help id="location_stage_not_visible" canDismiss={false}>
                        This location requires you to have a token before you can see anything, and you do not currently
                        have one assigned to you. Your GM should assign you one shortly!
                    </Help>
                )}

                <Box
                    bg="black"
                    fullWidth
                    fullHeight
                    onPointerDown={e => {
                        (e.target as Element).setPointerCapture(e.pointerId);
                        if (e.button === 2) {
                            if (!measureId && canMeasure) {
                                // Right click and drag to measure
                                setMeasureFrom(getSnappedPointFromEvent(e));
                                clearMeasureOverride(measureId, location, overrideToken, overrideAnnotation);
                                setMeasureId(undefined);
                            }
                        } else if (e.button === 0) {
                            if (canPan && !isPerspective) {
                                const sp = screenPoint(e.clientX, e.clientY);
                                setPanStart(sp);
                                panMomentum.current.momentumX = 0;
                                panMomentum.current.momentumY = 0;
                                panMomentum.current.x = 0;
                                panMomentum.current.y = 0;
                                panMomentum.current.timestamp = 0;
                                if (panMomentum.current.nextCallback > 0) {
                                    cancelAnimationFrame(panMomentum.current.nextCallback);
                                    panMomentum.current.nextCallback = 0;
                                }
                            } else {
                                var annotationHandler = annotationTools[tool];
                                if (annotationHandler) {
                                    setAnnotationDragPos(getSnappedPointFromEvent(e));
                                    setAnnotationDragStart(screenPoint(e.clientX, e.clientY));
                                }
                            }
                        } else if (e.button === 1) {
                            setIsPanMode(true);
                        }
                    }}
                    onPointerMove={e => {
                        if (startMeasurePos) {
                            mousePosRef.current = getSnappedPointFromEvent(e);
                            if (
                                distanceBetween(
                                    localGrid.toScreenPoint(startMeasurePos),
                                    localGrid.toScreenPoint(mousePosRef.current)
                                ) > theme.space[2]
                            ) {
                                setStartMeasurePos(undefined);
                                setStartMeasureItem(undefined);

                                if (isToken(startMeasureItem)) {
                                    setMeasureFrom(startMeasureItem);
                                    setMeasureId(startMeasureItem.id);
                                } else if (isAnnotation(startMeasureItem)) {
                                    setMeasureFrom(
                                        getAnnotationCenter(startMeasureItem, campaign, location, localGrid)
                                    );
                                    setMeasureId(startMeasureItem.id);
                                }
                            }
                        } else if (panStart) {
                            const newX = (positionRef.current?.x ?? 0) + e.movementX;
                            const newY = (positionRef.current?.y ?? 0) + e.movementY;

                            setPosition({ x: newX, y: newY });
                            const timestamp = Date.now();
                            const msSinceLast = timestamp - panMomentum.current.timestamp;

                            // Set inertia in pixels/second.
                            panMomentum.current.x = newX;
                            panMomentum.current.y = newY;
                            panMomentum.current.momentumX = e.movementX / (msSinceLast / 1000);
                            panMomentum.current.momentumY = e.movementY / (msSinceLast / 1000);
                            panMomentum.current.timestamp = timestamp;
                        } else if (annotationTemplate) {
                            // Using an annotation tool, so check for snapping.
                            mousePosRef.current = getSnappedPointFromEvent(e);

                            if (!annotationTemplate.annotation.pos && !annotationTemplate.annotation.centerOn) {
                                let placementPos = mousePosRef.current;
                                const measureDistance = distanceBetween(measureFromLocal!, placementPos);
                                if (measureDistance > annotationTemplate.range) {
                                    placementPos = pointAlongLine(
                                        measureFromLocal!,
                                        placementPos,
                                        annotationTemplate.range
                                    );
                                }

                                // Set the placement point relative to the center point.
                                let finalAnnotation: Annotation;
                                if (!annotation) {
                                    finalAnnotation = Object.assign({}, annotationTemplate.annotation, {
                                        id: nanoid(),
                                        pos: { ...placementPos, level: currentLevel },
                                        userId: user.id,
                                    });
                                } else {
                                    finalAnnotation = Object.assign({}, annotation as Annotation, {
                                        pos: placementPos,
                                    });
                                }

                                // Move the annotation so that its center point is at the cursor.
                                // This has no effect for annotation types that originate at a specific point (i.e. cone, linearea), or use
                                // the center point as their pos (ellipse), but it does move others (rect) so that they are in the middle.
                                const center = getAnnotationCenter(
                                    finalAnnotation as Annotation,
                                    campaign,
                                    location,
                                    localGrid,
                                    tokenOverrides
                                );
                                finalAnnotation.pos = {
                                    ...localPoint(
                                        placementPos.x + (placementPos.x - center.x),
                                        placementPos.y + (placementPos.y - center.y)
                                    ),
                                    level: currentLevel!,
                                };
                                setAnnotation(finalAnnotation);
                                setMeasureTo(placementPos);
                            } else {
                                // The annotation template already has a set position, so we're altering the active point.
                                if (!annotation) {
                                    setAnnotation(
                                        Object.assign({}, annotationTemplate.annotation, {
                                            id: nanoid(),
                                            pos: { ...annotationTemplate.annotation.pos, level: currentLevel },
                                            userId: user.id,
                                        })
                                    );
                                }

                                setAnnotationPoint(mousePosRef.current);
                            }
                        } else if (annotation) {
                            // Using an annotation tool, so check for snapping.
                            mousePosRef.current = getSnappedPointFromEvent(e);
                            setAnnotationPoint(mousePosRef.current);
                        } else if (annotationDragStart) {
                            const mp = screenPoint(e.clientX, e.clientY);
                            if (distanceBetween(mp, annotationDragStart) > 4) {
                                // Dragged far enough, start an annotation.
                                // TODO: Use snapped point instead of drag start point.
                                var annotationTool = annotationTools[tool] as AnnotationTool;
                                setAnnotation(
                                    annotationTool.createNew(
                                        {
                                            ...annotationDragPos!,
                                            level: currentLevel!,
                                        },
                                        user,
                                        subtool
                                    )
                                );
                                setIsDraggingAnnotation(true);
                                mousePosRef.current = getSnappedPointFromEvent(e);
                                setAnnotationPoint(mousePosRef.current);
                            }
                        } else if (isZoneFillActive) {
                            mousePosRef.current = getSnappedPointFromEvent(e);
                            setZonePreview(getContainingPolygonFromPoint(mousePosRef.current, zoneFillEdges ?? []));
                        } else if (measureFromLocal) {
                            // Using the measure tool. If we're hovering over a token, snap to its center.
                            const token = getGridTokenAt(
                                campaignData.system,
                                campaign,
                                location,
                                pointerEventToLocalPoint(e, g, threeGetRef.current),
                                g,
                                role
                            );
                            if (token) {
                                mousePosRef.current = g.toLocalCenterPoint(
                                    token.token.pos as GridPosition,
                                    token.appearance.scale
                                );
                            } else {
                                mousePosRef.current = getSnappedPointFromEvent(e);
                            }

                            // Check if we're at least a few pixels away from where we started.
                            const diffX = Math.abs(measureFromLocal.x - mousePosRef.current.x);
                            const diffY = Math.abs(measureFromLocal.y - mousePosRef.current.y);
                            if (diffX > 8 || diffY > 8 || measureTo != null) {
                                setMeasureTo(token?.token ?? mousePosRef.current);
                            }
                        } else {
                            mousePosRef.current = pointerEventToLocalPoint(e, g, threeGetRef.current);
                        }
                    }}
                    onPointerUp={e => {
                        setStartMeasurePos(undefined);
                        setStartMeasureItem(undefined);

                        if (e.button === 2) {
                            if (
                                measureFromLocal &&
                                measureToLocal &&
                                distanceBetween(
                                    localGrid.toScreenPoint(measureFromLocal),
                                    localGrid.toScreenPoint(measureToLocal)
                                ) > 8
                            ) {
                                // If a measure is taking place, then whether or not we're rotating we're only finishing the measure.
                                if (measureId && measureRotation != null) {
                                    let measureToken = location.tokens[measureId];
                                    if (measureToken) {
                                        measureToken = resolveToken(campaign, measureToken);
                                        const measureTokenAppearance =
                                            campaignData.system.getTokenAppearance?.(
                                                measureToken,
                                                campaign,
                                                location
                                            ) ?? measureToken;
                                        if (
                                            (measureTokenAppearance.canRotate == null &&
                                                measureTokenAppearance.imageUri != null) ||
                                            measureTokenAppearance.canRotate
                                        ) {
                                            dispatch(
                                                modifyToken(campaign.id, location.id, location.tokens[measureId], {
                                                    rotation: measureRotation,
                                                })
                                            );
                                        }
                                    } else {
                                        dispatch(
                                            modifyAnnotation(campaign.id, location.id, measureId, {
                                                rotation: measureRotation,
                                            })
                                        );
                                    }
                                }
                            } else {
                                //     // Set the context menu target if any.
                                //     const screenPos = screenPoint(e.clientX, e.clientY);
                                //     const localPos = pointerEventToLocalPoint(e, g, threeGetRef.current);
                                //     const token = getGridTokenAt(
                                //         campaignData.system,
                                //         campaign,
                                //         location,
                                //         localPos,
                                //         g,
                                //         role
                                //     );
                                //     if (token) {
                                //         if (primarySelection.indexOf(token.token.id) < 0) {
                                //             onItemSelected(token.token);
                                //         }
                                //         setContextMenuTarget({
                                //             localPos: localPos,
                                //             screenPos: screenPos,
                                //             target: token.token,
                                //         });
                                //         toggleMenu(true);
                                //     } else {
                                //         // There's no matching token, maybe there's an annotation?
                                //         // TODO: Use the proper raycasting, just use the onPointerUp event of the relevant meshes.
                                //         // Shouldn't need to do this at all, for either tokens or annotations.
                                //         const annotationIds = Object.keys(location.annotations);
                                //         let targetAnnotation: Annotation | undefined;
                                //         for (let annotationId of annotationIds) {
                                //             const annotation = location.annotations[annotationId];
                                //             let points: Point[] | undefined = undefined;
                                //             if (isRectAnnotation(annotation)) {
                                //                 // TODO: Convert rect to points.
                                //             } else if (
                                //                 isLineAnnotation(annotation) &&
                                //                 (annotation.isClosed ||
                                //                     pointsEqual(
                                //                         annotation.points[0],
                                //                         annotation.points[annotation.points.length - 1]
                                //                     ))
                                //             ) {
                                //                 const polygon = getObstructionPolygon(
                                //                     annotation,
                                //                     campaign,
                                //                     location,
                                //                     localGrid,
                                //                     tokenOverrides
                                //                 );
                                //                 points = polygon.points.map(o => ({
                                //                     x: polygon.pos.x + o.x,
                                //                     y: polygon.pos.y + o.y,
                                //                 }));
                                //             }
                                //             if (points && isPointInPolygon(localPos, points)) {
                                //                 targetAnnotation = annotation;
                                //                 break;
                                //             }
                                //         }
                                //         setContextMenuTarget({
                                //             localPos: localPos,
                                //             screenPos: screenPos,
                                //             target: targetAnnotation,
                                //         });
                                //         toggleMenu(true);
                                //     }
                                // }
                                const screenPos = screenPoint(e.clientX, e.clientY);
                                const localPos = pointerEventToLocalPoint(e, g, threeGetRef.current);

                                setContextMenuTarget({
                                    localPos: localPos,
                                    screenPos: screenPos,
                                    target: undefined,
                                });
                                toggleMenu(true);
                            }

                            setMeasureFrom(undefined);
                            setMeasureTo(undefined);
                            clearMeasureOverride(measureId, location, overrideToken, overrideAnnotation);
                            setMeasureId(undefined);
                        } else if (e.button === 0) {
                            if (panStart) {
                                ignoreClick.current = distanceBetween(panStart, screenPoint(e.clientX, e.clientY)) > 4;
                                setPanStart(undefined);

                                if (ignoreClick.current) {
                                    const applyMomentum = () => {
                                        if (
                                            panMomentum.current.momentumX !== 0 ||
                                            panMomentum.current.momentumY !== 0
                                        ) {
                                            const timestamp = Date.now();
                                            const msDiff = timestamp - panMomentum.current.timestamp;

                                            // The higher this value, the faster the pan stops.
                                            const resistance = 5;
                                            const modifier = 1 - (msDiff / 1000) * resistance;

                                            panMomentum.current.x =
                                                panMomentum.current.x + panMomentum.current.momentumX * (msDiff / 1000);
                                            panMomentum.current.y =
                                                panMomentum.current.y + panMomentum.current.momentumY * (msDiff / 1000);
                                            panMomentum.current.momentumX = panMomentum.current.momentumX * modifier;
                                            panMomentum.current.momentumY = panMomentum.current.momentumY * modifier;
                                            panMomentum.current.momentumX =
                                                Math.abs(panMomentum.current.momentumX) < 1
                                                    ? 0
                                                    : panMomentum.current.momentumX;
                                            panMomentum.current.momentumY =
                                                Math.abs(panMomentum.current.momentumY) < 1
                                                    ? 0
                                                    : panMomentum.current.momentumY;
                                            panMomentum.current.timestamp = timestamp;

                                            setPosition({
                                                x: panMomentum.current.x,
                                                y: panMomentum.current.y,
                                            });
                                            panMomentum.current.nextCallback = requestAnimationFrame(
                                                applyMomentum
                                            ) as any; // TODO: Node types creeping in
                                        }
                                    };

                                    // If there is a pause between the last recorded momentum and the mouse up, then
                                    // the user stopped before releasing and we shouldn't do anything here.
                                    const timestamp = Date.now();
                                    if (timestamp - panMomentum.current.timestamp < 50) {
                                        // TODO: Might be smoother if this was integrated into a threejs render callback instead?
                                        requestAnimationFrame(applyMomentum);
                                    }
                                }
                            } else {
                                ignoreClick.current = false;
                            }

                            setAnnotationDragStart(undefined);
                            setAnnotationDragPos(undefined);
                            if (isDraggingAnnotation && annotation) {
                                // An annotation drag is finished.
                                // This will still trigger a click event, so we leave annotationStart around until the click
                                // event happens and clear it there, so we know it's not a real click.
                                const endPoint = getSnappedPointFromEvent(e);
                                const annotationTool = annotationTools[tool] as AnnotationTool;
                                const result = annotationTool.addPoint(
                                    annotation as Annotation,
                                    campaign,
                                    location,
                                    localGrid,
                                    endPoint
                                );
                                if (result.breakWalls) {
                                    breakWalls(
                                        dispatch,
                                        campaign,
                                        location,
                                        localGrid,
                                        result.breakWalls.start,
                                        result.breakWalls.end
                                    );
                                }

                                completeAnnotation(result.annotation);
                            }
                        } else if (e.button === 1) {
                            setIsPanMode(false);
                        }
                    }}
                    onWheel={e => {
                        if (isPerspective) {
                            return;
                        }

                        const scaleBy = 1.05;
                        const newScale = e.deltaY < 0 ? scale * scaleBy : scale / scaleBy;
                        setScale(newScale);

                        mousePosRef.current = g.toLocalPoint(screenPoint(e.clientX, e.clientY));
                        const pointerPos = mousePosRef.current;
                        if (pointerPos) {
                            // We have the local position that we want to appear at the same place on the screen.
                            // Work out where the point will appear with the new scale.
                            const x = positionRef.current?.x ?? 0;
                            const y = positionRef.current?.y ?? 0;
                            const ng = createGrid(campaign.gridType, location.tileSize, newScale, { x: x, y: y });
                            const newScreenPos = ng.toScreenPoint(pointerPos);

                            // Clear any inertial panning happening at the moment.
                            panMomentum.current.momentumX = 0;
                            panMomentum.current.momentumY = 0;
                            panMomentum.current.x = 0;
                            panMomentum.current.y = 0;
                            panMomentum.current.timestamp = 0;
                            if (panMomentum.current.nextCallback > 0) {
                                cancelAnimationFrame(panMomentum.current.nextCallback);
                                panMomentum.current.nextCallback = 0;
                            }

                            // Now adjust our current x and y so they put the pointer pos at the same place.
                            const newOffsetX = x + (e.clientX - newScreenPos.x);
                            const newOffsetY = y + (e.clientY - newScreenPos.y);
                            setPosition({
                                x: newOffsetX,
                                y: newOffsetY,
                            });
                        }
                    }}>
                    <Canvas
                        linear
                        flat
                        legacy
                        shadows={{ type: BasicShadowMap, autoUpdate: true }}
                        gl={{
                            preserveDrawingBuffer: true, // This enables us to take screenshots using toDataUrl/toBlob.
                            logarithmicDepthBuffer: true,
                        }}
                        events={store => {
                            const e = events(store);
                            e.filter = (items, state) => {
                                // Sort items by their render order if the distance is the same. Three (or r3f) should
                                // really be doing this themselves.
                                items.sort(raycastSort);
                                return items;
                            };
                            return e;
                        }}
                        camera={camera}
                        resize={{ scroll: false }}>
                        <ScaleContext.Provider value={scale}>
                            {isStageVisible && (
                                <LocationGroup
                                    history={history}
                                    grid={g}
                                    camera={camera}
                                    threeRef={threeGetRef}
                                    localGrid={localGrid}
                                    isPointSnappingEnabled={isShift}
                                    annotation={annotation}
                                    annotationPoint={annotationPoint}
                                    addPointToAnnotation={addPointToAnnotation}
                                    canSelect={canSelect}
                                    onItemSelected={onItemSelected}
                                    measureFrom={measureFrom}
                                    measureTo={measureTo}
                                    measureFromLocal={measureFromLocal}
                                    measureToLocal={measureToLocal}
                                    levelInfo={levelInfo}
                                    hasVisionSource={hasVisionSource}
                                    onStartMeasure={onStartMeasuring}
                                    totalSize={totalSize}
                                    onSizeChanged={onSizeChanged}
                                    onLevelSizeChanged={onLevelSizeChanged}
                                    onContextMenu={data => {
                                        if (!measureFrom) {
                                            setStartMeasurePos(undefined);
                                            setStartMeasureItem(undefined);

                                            if (data?.target && selectionProps.primary.indexOf(data.target.id) < 0) {
                                                selectionProps.setSelection([data.target.id]);
                                            }

                                            setContextMenuTarget(data);
                                            toggleMenu(true);

                                            return true;
                                        }

                                        return false;
                                    }}
                                    zonePreview={isZoneFillActive ? zonePreview : undefined}
                                    dragPreviews={dragPreviews}
                                    gridPlacementState={gridPlacementState}
                                    setPosition={setPosition}
                                />
                            )}
                        </ScaleContext.Provider>
                    </Canvas>
                </Box>

                <ControlledMenu
                    {...menuProps}
                    onClose={() => toggleMenu(false)}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onItemClick={e => {
                        e.syntheticEvent.stopPropagation();
                        e.syntheticEvent.preventDefault();
                    }}
                    anchorPoint={contextMenuAnchor}>
                    {isToken(contextMenuTarget?.target) && (
                        <React.Fragment>
                            {role === "GM" &&
                                getTokenType(campaign, contextMenuTarget?.target as Token) === "creature" && (
                                    <React.Fragment>
                                        <SubMenu label="Assign to">
                                            {Object.keys(campaign.players)
                                                .filter(
                                                    o =>
                                                        o !==
                                                            getTokenOwner(
                                                                campaign,
                                                                contextMenuTarget!.target as Token
                                                            ) &&
                                                        (!campaign.players[o].role ||
                                                            campaign.players[o].role === "Player")
                                                )
                                                .map(o => (
                                                    <MenuItem
                                                        key={campaign.players[o].userId}
                                                        onClick={() =>
                                                            dispatch(
                                                                modifyToken(
                                                                    campaign.id,
                                                                    location.id,
                                                                    location.tokens[contextMenuTarget!.target!.id],
                                                                    { owner: o },
                                                                    true
                                                                )
                                                            )
                                                        }>
                                                        {campaign.players[o].name}
                                                    </MenuItem>
                                                ))}
                                            {getTokenOwner(campaign, contextMenuTarget!.target as Token) != null && (
                                                <MenuItem
                                                    key="nobody"
                                                    onClick={() =>
                                                        dispatch(
                                                            modifyToken(
                                                                campaign.id,
                                                                location.id,
                                                                location.tokens[contextMenuTarget!.target!.id],
                                                                { owner: undefined },
                                                                true
                                                            )
                                                        )
                                                    }>
                                                    Nobody
                                                </MenuItem>
                                            )}
                                        </SubMenu>
                                        {((contextMenuTarget!.target as Token).isPlayerVisible == null ||
                                            (contextMenuTarget!.target as Token).isPlayerVisible) && (
                                            <MenuItem
                                                onClick={() =>
                                                    dispatch(
                                                        modifyTokens(campaign.id, location.id, selectionByType.tokens, {
                                                            isPlayerVisible: false,
                                                        })
                                                    )
                                                }>
                                                Hide from players
                                            </MenuItem>
                                        )}
                                        {(contextMenuTarget!.target as Token).isPlayerVisible != null &&
                                            !(contextMenuTarget!.target as Token).isPlayerVisible && (
                                                <MenuItem
                                                    onClick={() =>
                                                        dispatch(
                                                            modifyTokens(
                                                                campaign.id,
                                                                location.id,
                                                                selectionByType.tokens,
                                                                {
                                                                    isPlayerVisible: undefined,
                                                                }
                                                            )
                                                        )
                                                    }>
                                                    Show to players
                                                </MenuItem>
                                            )}
                                        <MenuDivider />
                                    </React.Fragment>
                                )}
                            {systemMenuItems.map(renderContextMenuItem)}
                            {!!systemMenuItems.length && <MenuDivider />}
                        </React.Fragment>
                    )}
                    {isAnnotation(contextMenuTarget?.target) && (
                        <React.Fragment>
                            {role === "GM" &&
                                mode === "build" &&
                                (isRectAnnotation(contextMenuTarget!.target) ||
                                    isLineAnnotation(contextMenuTarget!.target)) && (
                                    <React.Fragment>
                                        <MenuItem
                                            key="toZone"
                                            onClick={() => {
                                                setMode("build");
                                                setBuildMode("zones");
                                                setTool("select");
                                                dispatch(
                                                    convertToZone(
                                                        campaign.id,
                                                        location.id,
                                                        (contextMenuTarget!.target as Annotation).id,
                                                        localGrid
                                                    )
                                                );
                                            }}>
                                            Convert to zone
                                        </MenuItem>
                                        <MenuDivider />
                                    </React.Fragment>
                                )}
                        </React.Fragment>
                    )}

                    {role === "GM" &&
                        (isAnnotation(contextMenuTarget?.target) || isToken(contextMenuTarget?.target)) && (
                            <React.Fragment>
                                <MenuItem
                                    key="cut"
                                    onClick={() => {
                                        Clipboard.copyToClipboard(
                                            getRelativeSelection(location, localGrid, primarySelection)
                                        );
                                        dispatch(deleteItems(campaign.id, location.id, primarySelection));
                                    }}>
                                    Cut
                                </MenuItem>
                                <MenuItem
                                    key="copy"
                                    onClick={() => {
                                        Clipboard.copyToClipboard(
                                            getRelativeSelection(location, localGrid, primarySelection)
                                        );
                                    }}>
                                    Copy
                                </MenuItem>
                                <MenuDivider />
                            </React.Fragment>
                        )}
                    {role === "GM" && contextMenuTarget && !contextMenuTarget.target && (
                        <React.Fragment>
                            <MenuItem
                                key="paste"
                                onClick={() => {
                                    paste(contextMenuTarget.localPos);
                                }}>
                                Paste
                            </MenuItem>
                            <MenuDivider />
                        </React.Fragment>
                    )}

                    {contextMenuTarget?.target &&
                        campaignData.system.getPlayerSections?.(selectionProps, location).map(o => (
                            <MenuItem
                                key={"system_" + o.id}
                                onClick={() => {
                                    setPropertiesPage(o);
                                    setIsPropertiesExpanded(true);
                                }}>
                                {o.label}
                            </MenuItem>
                        ))}
                    <MenuItem
                        key="properties"
                        onClick={() => {
                            if (!contextMenuTarget?.target) {
                                setSelection([]);
                            }

                            setPropertiesPage("properties");
                            setIsPropertiesExpanded(true);
                        }}>
                        Properties
                    </MenuItem>
                    <MenuDivider />
                    {role === "GM" &&
                        (isToken(contextMenuTarget?.target) ||
                            (isAnnotation(contextMenuTarget?.target) && contextMenuTarget?.target.pos)) && (
                            <React.Fragment>
                                <SubMenu label="Send to level">
                                    {levelKeys.map((o, i) => {
                                        const level = location.levels[o];
                                        const targetLevel = contextMenuTarget!.target!.pos!.level;
                                        return (
                                            <MenuItem
                                                key={o}
                                                type="checkbox"
                                                checked={
                                                    o === targetLevel || (!targetLevel && o === location.defaultLevel)
                                                }
                                                onClick={() => {
                                                    if (isToken(contextMenuTarget?.target)) {
                                                        dispatch(
                                                            modifyToken(campaign, location, contextMenuTarget!.target, {
                                                                pos: {
                                                                    ...contextMenuTarget!.target!.pos!,
                                                                    level: o,
                                                                },
                                                            })
                                                        );
                                                    } else {
                                                        dispatch(
                                                            modifyAnnotation(
                                                                campaign.id,
                                                                location.id,
                                                                contextMenuTarget!.target!.id,
                                                                {
                                                                    pos: {
                                                                        ...contextMenuTarget!.target!.pos!,
                                                                        level: o,
                                                                    },
                                                                }
                                                            )
                                                        );
                                                    }
                                                }}>
                                                {getLevelLabel(level, i)}
                                            </MenuItem>
                                        );
                                    })}
                                </SubMenu>
                                <MenuDivider />
                            </React.Fragment>
                        )}
                    <MenuItem
                        onClick={() => {
                            setScale(undefined);
                            positionRef.current = getDefaultPosition(
                                user,
                                campaign,
                                location,
                                canvasWidth,
                                canvasHeight,
                                totalSize,
                                undefined
                            );
                        }}>
                        Reset view
                    </MenuItem>
                    {role === "GM" && (
                        <MenuItem
                            onClick={() => {
                                const players = Object.values(campaign.players).filter(o => o.role !== "GM");
                                for (let player of players) {
                                    dispatch(setPlayerLocation(campaign.id, player.userId, location.id));
                                }
                            }}>
                            Send players here
                        </MenuItem>
                    )}
                    {contextMenuTarget && (
                        <MenuItem onClick={() => campaignData.api.ping(location.id, contextMenuTarget!.localPos)}>
                            Ping here
                        </MenuItem>
                    )}
                </ControlledMenu>

                <ModalDialog
                    onRequestClose={() => {
                        setPendingBackground(undefined);
                    }}
                    isOpen={pendingBackground != null}>
                    {pendingBackground && (
                        <Box flexDirection="column" p={3} maxWidth={theme.space[13]}>
                            <Button
                                fullWidth
                                mb={2}
                                p={3}
                                css={{ height: "auto" }}
                                onClick={() => {
                                    dispatch(
                                        modifyLocationLevel(campaign.id, location.id, currentLevel!, {
                                            backgroundImageUrl: pendingBackground.uri,
                                        })
                                    );

                                    if (currentLevel === location.defaultLevel) {
                                        dispatch(
                                            modifyLocation(campaign.id, location.id, {
                                                thumbnailUri: pendingBackground.metadata.thumbnailUri
                                                    ? pendingBackground.metadata.thumbnailUri
                                                    : undefined,
                                            })
                                        );
                                    }

                                    setPendingBackground(undefined);
                                }}>
                                <Box flexDirection="column" fullWidth>
                                    <Box flexDirection="row" mb={2}>
                                        <Box flexDirection="column" mr={3}>
                                            <Image
                                                src={resolveUri(level.backgroundImageUrl)}
                                                borderRadius="4px 4px 0 0"
                                                maxWidth={theme.space[10]}
                                                height="12rem"
                                                css={{ objectFit: "cover" }}
                                                draggable={false}
                                            />
                                            <Text fontWeight="bold">Before</Text>
                                        </Box>
                                        <ArrowRightIcon />
                                        <Box flexDirection="column" ml={3}>
                                            <Image
                                                src={resolveUri(
                                                    pendingBackground.metadata.thumbnailUri ?? pendingBackground.uri
                                                )}
                                                borderRadius="4px 4px 0 0"
                                                maxWidth={theme.space[10]}
                                                height="12rem"
                                                css={{ objectFit: "cover" }}
                                                draggable={false}
                                            />
                                            <Text fontWeight="bold">After</Text>
                                        </Box>
                                    </Box>
                                    Set {pendingBackground.name} as the new background image for this{" "}
                                    {currentLevel === location.defaultLevel ? "location" : "level"}
                                </Box>
                            </Button>
                            <Button
                                fullWidth
                                p={3}
                                css={{ height: "auto" }}
                                onClick={() => {
                                    dispatch(
                                        addLevel(campaign, location, {
                                            backgroundImageUrl: pendingBackground.uri,
                                        })
                                    );
                                    setPendingBackground(undefined);
                                }}>
                                <Box flexDirection="column" fullWidth>
                                    <Box flexDirection="row" mb={2} css={{ gap: theme.space[2] }}>
                                        <LayersIcon />
                                        <PlusIcon />
                                        <Image
                                            src={resolveUri(
                                                pendingBackground.metadata.thumbnailUri ?? pendingBackground.uri
                                            )}
                                            borderRadius="4px 4px 0 0"
                                            maxWidth={theme.space[10]}
                                            height="12rem"
                                            css={{ objectFit: "cover" }}
                                            draggable={false}
                                        />
                                    </Box>
                                    Add a new level with {pendingBackground.name} as its background
                                </Box>
                            </Button>
                        </Box>
                    )}
                </ModalDialog>
            </div>
        </PathFindingContext.Provider>
    );
};

import React, { FunctionComponent, useMemo } from "react";
import { LocalPixelPosition } from "../../position";
import { getObstructionPolygon, ObstructingAnnotation } from "../../annotations";
import { useCamera, useLocalGrid, useValidatedLocation } from "../contexts";
import { angle, degToRad, distanceBetween, localPoint, pointAlongLine } from "../../grid";
import { DoubleSide } from "three";
import { VttCameraLayers } from "./common";

const ObstructionMesh: FunctionComponent<{
    from: LocalPixelPosition;
    to: LocalPixelPosition;
    visible?: boolean;
    height: number;
}> = ({ from, to, visible, height }) => {
    const segmentLength = distanceBetween(from, to);
    const segmentAngle = angle(from, to);
    const pos = pointAlongLine(from, to, segmentLength / 2);

    // NOTE: If we make these walls visible at some point you'll also want to reenable receiveShadows.
    return (
        <mesh
            layers={VttCameraLayers.PerspectiveLighting}
            castShadow
            receiveShadow={visible}
            position={[pos.x, -pos.y, height / 2]}
            rotation={[degToRad(90), -degToRad(segmentAngle), 0]}>
            <planeGeometry attach="geometry" args={[segmentLength, height]} />
            <meshPhongMaterial
                color="#FFFFFF"
                depthWrite={!!visible}
                colorWrite={!!visible}
                side={DoubleSide}
                shadowSide={DoubleSide}
            />
        </mesh>
    );
};

export const UiLayerObstructions: FunctionComponent<{ annotation: ObstructingAnnotation; visible?: boolean }> = ({
    annotation,
    visible,
}) => {
    const { campaign, location } = useValidatedLocation();
    const { hasPerspectiveLighting } = useCamera();
    const grid = useLocalGrid();

    const segments3D = useMemo(() => {
        if (annotation.obstructsLight && hasPerspectiveLighting) {
            // TODO: Do we actually need to provide the token overrides here? Annotations that have obstructions shouldn't really
            // be attached to tokens, should they? I guess they could - darkness spells etc.
            const polygon = getObstructionPolygon(annotation, campaign, location, grid);

            const segments: { from: LocalPixelPosition; to: LocalPixelPosition }[] = [];
            for (let i = 1; i < polygon.points.length; i++) {
                segments.push({
                    from: localPoint(polygon.pos.x + polygon.points[i - 1].x, polygon.pos.y + polygon.points[i - 1].y),
                    to: localPoint(polygon.pos.x + polygon.points[i].x, polygon.pos.y + polygon.points[i].y),
                });
            }

            return segments;
        }

        return undefined;
    }, [annotation, campaign, location, hasPerspectiveLighting, grid]);

    return (
        <React.Fragment>
            {segments3D?.map((o, i) => (
                <ObstructionMesh
                    key={i}
                    from={o.from}
                    to={o.to}
                    visible={visible}
                    height={location.tileSize.width * 2}
                />
            ))}
        </React.Fragment>
    );
};

import styled, { StyledComponent } from "@emotion/styled";
import { withTooltip } from "../Tooltip";
import { IWithBadgeProps } from "../Badge";
import { PropsWithChildren } from "react";
import { Theme } from "styled-system";

const toggled = props =>
    props.isToggled && {
        color: props.theme.colors.accent[5],
    };

const ToolbarButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colors.grayscale[2]};
    border-radius: ${props => props.theme.radii[3]}px;
    flex: none;
    border: 0;
    background: 0;
    cursor: pointer;
    padding: 0;
    pointer-events: all;
    position: relative;

    transition: opacity 90ms ease-out, color 90ms ease-out;

    &:hover {
        color: ${props => props.theme.colors.accent[3]};
    }

    ${toggled}

    &:focus-visible {
        outline: 0;

        &::after {
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            content: "";
            box-shadow: 0 0 0 2px ${props => props.theme.colors.guidance.focus};
            border-radius: ${props => props.theme.radii[3]}px;
            pointer-events: none;
            z-index: 1;
        }
    }

    &:disabled {
        opacity: 0.5;
        border: 0;
        color: ${props => props.theme.colors.grayscale[4]};
        fill: ${props => props.theme.colors.grayscale[4]};
        pointer-events: none;
    }
` as StyledComponent<
    {
        theme?: Theme | undefined;
        as?: React.ElementType<any> | undefined;
    },
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
        PropsWithChildren<{ isToggled?: boolean }> &
        IWithBadgeProps,
    {}
>;

export default withTooltip(ToolbarButton);

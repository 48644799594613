import React from 'react'
  
  const FillShapeIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 11.719,4.217c-0.482-0.482-1.263-0.482-1.745,0L2.993,11.2c-1.446,1.444-1.447,3.787-0.002,5.233 c0.001,0.001,0.002,0.002,0.002,0.002l3.49,3.491c1.444,1.446,3.787,1.447,5.233,0.003c0.001-0.001,0.002-0.002,0.003-0.003 l5.672-5.672l2.651-0.295c0.339-0.038,0.582-0.343,0.544-0.682c-0.016-0.139-0.078-0.268-0.176-0.367L11.719,4.217z  M 20.242,17.258 l-1.654,3.308c-0.356,0.712-0.216,1.572,0.347,2.134l0,0c0.72,0.722,1.89,0.723,2.612,0.003c0.001-0.001,0.002-0.002,0.003-0.003 l0,0c0.563-0.563,0.702-1.422,0.346-2.134L20.242,17.258z  M 4.841,9.349V3.758c0-1.657,1.343-3,3-3l0,0c1.657,0,3,1.343,3,3v8.25' />
    </svg>
  )
  
  FillShapeIcon.displayName = 'FillShapeIcon'
  
  FillShapeIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default FillShapeIcon
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Checkbox, Text, Box } from "../../../components/primitives";
import { Button } from "../../../components/Button";
import { theme } from "../../../design";
import { FunctionComponent, ReactElement, useState } from "react";
import { LobotomizedBox } from "../../../components/common";
import { useDiceBag, useDispatch, useLocation } from "../../../components/contexts";
import { DiceType, isLocation, isToken, parseDiceBag } from "../../../store";
import { applyHitDie } from "../actions/token";
import { DnD5EToken, DnD5ETokenTemplate, NamedRuleRef } from "../common";
import { modifierFromAbilityScore } from "../creature";

export const HitDiceUsage: FunctionComponent<{
    token: DnD5EToken | DnD5ETokenTemplate;
    spent?: number;
    total: number;
    name?: string;
    hitDieType: DiceType;
    constitution: number;
    hitDieRef: NamedRuleRef | DiceType;
}> = ({ token, spent, total, name, hitDieType, constitution, hitDieRef }) => {
    const [pending, setPending] = useState(0);
    const [pendingSpent, setPendingSpent] = useState(0);
    const { setDice } = useDiceBag();
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();
    const pspent = (spent ?? 0) + pendingSpent;

    const remaining = total - pspent - pending;
    const hitDice: ReactElement[] = [];

    // Show the hit dice that have already been rolled as disabled checked boxes.
    for (let i = 0; i < pspent; i++) {
        hitDice.push(<Checkbox checked disabled></Checkbox>);
    }

    // The remaining checkboxes can be interacted with.
    for (let i = 0; i < total - pspent; i++) {
        hitDice.push(
            <Checkbox
                key={i}
                disabled={!isLocation(location)}
                id={(name ?? hitDieType) + "_" + i}
                checked={i < pending}
                onChange={e => {
                    if (e.target.checked) {
                        setPending(pending + 1);
                    } else {
                        setPending(pending - 1);
                    }
                }}
            />
        );
    }

    const modifier = modifierFromAbilityScore(constitution) * Math.max(1, pending);
    const hitDieRoll = `${pending < 1 ? 1 : pending}${hitDieType}${
        modifier < 0 ? modifier : modifier === 0 ? "" : "+" + modifier
    }`;

    return (
        <Box flexDirection="column" alignItems="flex-start">
            {name && (
                <Text>
                    {name} hit dice (1{hitDieType}) {remaining}/{total} remaining
                </Text>
            )}
            {!name && (
                <Text>
                    Hit dice (1{hitDieType}) {remaining}/{total} remaining
                </Text>
            )}
            <LobotomizedBox mt={1} mb={1}>
                <Box
                    flexDirection="row"
                    flexWrap="wrap"
                    flexShrink={1}
                    justifyContent="flex-start"
                    css={{ gap: theme.space[2] }}>
                    {hitDice}
                </Box>
                <Button
                    disabled={pending === 0 || !isLocation(location)}
                    onClick={() => {
                        const bag = parseDiceBag(hitDieRoll, {
                            token: isToken(token) ? token.id : token.templateId,
                            location: location!.id,
                            notify: { toast: false, token: false },
                        });
                        bag.onRolled = async roll => {
                            setPending(0);
                            setPendingSpent(pending);

                            // Set the results to the annotation.
                            var result = await roll.confirmed;

                            setPendingSpent(0);
                            dispatch(applyHitDie(campaign, location, [token], hitDieRef, result));
                        };
                        setDice(bag);
                    }}>
                    Roll {hitDieRoll}
                </Button>
            </LobotomizedBox>
        </Box>
    );
};

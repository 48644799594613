/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { Select } from "../../../../components/primitives";
import { Choice } from "../../creature";

interface ChoiceEditorProps {
    choice: Choice<string>;
    exclude?: string[];
    selections?: (string | undefined | null)[];
    hint: string;
    onChoiceMade: (value: string | undefined, i: number) => void;
    optionToLabel?: (option: string) => string;
    all?: string[];
}

export const ChoiceEditor: FunctionComponent<ChoiceEditorProps> = ({
    choice,
    exclude,
    selections,
    hint,
    onChoiceMade,
    optionToLabel,
    all,
}) => {
    const choices: string[][] = [];

    // Exclude everything on the exclude list... unless it's already a selection, in which case it should be in the list so that it
    // can be picked! The exclude list is for things like skills that have already been picked by another feature and can't be
    // repeated, etc.
    const exc: string[] = exclude ? exclude.filter(o => !selections || selections.indexOf(o) === -1) : [];

    for (let i = 0; i < choice.count; i++) {
        let opts = (choice.from ?? all)!;
        if (exc.length) {
            opts = opts.filter(o => exc.indexOf(o) === -1);
        }

        choices.push(opts);

        // If a selection has been made, add it to the exclude list.
        if (!choice.allowDuplicates && selections && selections.length > i) {
            const selection = selections[i];
            if (selection) {
                exc.push(selection);
            }
        }
    }

    return (
        <React.Fragment>
            {choices.map((c, i) => (
                <Select
                    key={i}
                    minWidth="250px"
                    value={selections ? selections[i] ?? undefined : undefined}
                    onChange={e => {
                        const value = e.target.value;
                        onChoiceMade(value === "" ? undefined : value, i);
                    }}>
                    <option value="">- {hint} -</option>
                    {c.map(o => (
                        <option key={o} value={o}>
                            {o != null ? (optionToLabel ? optionToLabel(o) : o[0].toUpperCase() + o.substr(1)) : o}
                        </option>
                    ))}
                </Select>
            ))}
        </React.Fragment>
    );
};

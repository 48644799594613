/** @jsxRuntime classic */
/** @jsx jsx */
import { Interpolation, jsx, Theme } from "@emotion/react";
import { Active, Modifiers, useDraggable, useDroppable } from "@dnd-kit/core";
import React, { FunctionComponent, useCallback, useMemo, useContext, useEffect, useRef, ReactNode } from "react";
import { MotionBox, MotionGrid } from "./motion";
import { useSortable } from "@dnd-kit/sortable";
import { theme } from "../design";
import { Point } from "../position";
import { MotionProps } from "framer-motion";
import { ExtractProps } from "./common";
import { Box } from "./primitives";

export const dragDropPalette = theme.colors.transparency[2].purples;

interface DragDataBase {
    modifiers?: Modifiers;
    type: string;
    data: any | (() => any);
}

export interface DragData extends DragDataBase {
    renderDragOverlay?: () => ReactNode;
    isEnded?: boolean;
}

export interface DropData {
    accepts?: string[];
    disabled?: boolean;
    onDrop(drag: DragData, active: Active, pointerPos?: Point);
    onDragOver?(drag: DragData, active: Active, pointerPos?: Point);
    onDragLeave?(drag: DragData);
    canDrop?(drag: DragData);
    renderFeedback?(drag: DragData): ReactNode | undefined;
}

interface DraggableProps extends DragDataBase {
    draggableId: string;
    dragOverlay?: boolean | "nobackground";
    dragDisabled?: boolean;
    dragChildren?: ReactNode;
}

interface TypedDroppableContextProps {
    isOver: boolean;
    active: Active | null;
    updateDropHandler(id: string, data: DropData | undefined);
}

const TypedDroppableContext = React.createContext(undefined as any as TypedDroppableContextProps);

export const TypedDroppableArea: FunctionComponent<{ droppableId: string } & ExtractProps<typeof MotionBox>> = ({
    droppableId,
    children,
    ...boxProps
}) => {
    const handlers = useMemo(() => new Map<string, DropData>(), []);
    const updateDropHandler = useCallback(
        (id: string, data: DropData | undefined) => {
            if (data) {
                handlers.set(id, data);
            } else {
                handlers.delete(id);
            }
        },
        [handlers]
    );

    const { isOver, active, setNodeRef } = useTypedDroppable({
        id: droppableId,
        onDragOver: (drag, active, pointerPos) => {
            handlers.forEach(o => {
                if (!o.disabled && (!o?.accepts || o?.accepts?.indexOf(drag.type) >= 0)) {
                    o.onDragOver?.(drag, active, pointerPos);
                }
            });
        },
        onDrop: (drag, active, pointerPos) => {
            handlers.forEach(o => {
                if (!o.disabled && (!o?.accepts || o?.accepts?.indexOf(drag.type) >= 0)) {
                    o.onDrop(drag, active, pointerPos);
                }
            });
        },
        onDragLeave: drag => {
            handlers.forEach(o => {
                if (!o.disabled && (!o?.accepts || o?.accepts?.indexOf(drag.type) >= 0)) {
                    o.onDragLeave?.(drag);
                }
            });
        },
        renderFeedback: drag => {
            const feedback: ReactNode[] = [];
            handlers.forEach(o => {
                if (!o.disabled && (!o?.accepts || o?.accepts?.indexOf(drag.type) >= 0)) {
                    const el = o.renderFeedback?.(drag);
                    if (el) {
                        feedback.push(el);
                    }
                }
            });

            if (feedback.length === 0) {
                return undefined;
            } else if (feedback.length === 1) {
                return feedback[0];
            } else {
                return (
                    <Box flexDirection="column" css={{ gap: theme.space[2] }}>
                        {feedback}
                    </Box>
                );
            }
        },
    });

    const contextValue = useMemo<TypedDroppableContextProps>(() => {
        return {
            updateDropHandler: updateDropHandler,
            active: active,
            isOver: !!isOver,
        };
    }, [isOver, active, updateDropHandler]);

    // TODO: Need to use useImperativeHandle or whatever to merge refs, and make this component forwardref?
    return (
        <MotionBox ref={setNodeRef} {...boxProps}>
            <TypedDroppableContext.Provider value={contextValue}>{children}</TypedDroppableContext.Provider>
        </MotionBox>
    );
};

/**
 * Similar to useTypedDroppable, but uses a drop target set up by an ancestor. This enables deeply nested components to use
 * their ancestor to have larger drop areas.
 * @param dropData The drop data.
 */
export function useTypedDroppableArea(dropData: { id: string } & DropData) {
    const idRef = useRef(dropData.id);
    idRef.current = dropData.id;

    const { active, isOver, updateDropHandler } = useContext(TypedDroppableContext);
    updateDropHandler(dropData.id, dropData);
    useEffect(() => {
        return () => updateDropHandler(idRef.current, undefined);
    }, [updateDropHandler]);
    updateDropHandler(dropData.id, dropData);

    let canDrop = true;
    if (dropData.canDrop && active) {
        const dragData = getDragData(active);
        canDrop = dragData && dropData.canDrop(dragData);
    }

    const isDropActive =
        active && (!dropData.accepts || dropData.accepts.indexOf(active.data.current?.type) >= 0) && canDrop;
    return {
        isOver: isDropActive && isOver,
        active: isDropActive ? active : null,
    };
}

export function getDragData(active: Active) {
    let dragData = active.data.current as DragData | undefined;
    if (dragData && typeof dragData.data === "function") {
        dragData = Object.assign({}, dragData, {
            data: dragData.data(),
        });
    }

    return dragData;
}

/**
 * Wraps useDroppable to provide a layer of type checking. isOver and active will only be populated if
 * the type of the drop matches the type of the droppable.
 * @param dropData The drop data.
 */
export function useTypedDroppable(dropData: { id: string } & DropData) {
    const { isOver, active, ...props } = useDroppable({
        id: dropData.id,
        data: dropData,
        disabled: dropData.disabled,
    });

    let canDrop = false;
    if (active) {
        canDrop = !dropData.accepts || dropData.accepts.indexOf(active.data.current?.type) >= 0;
        if (canDrop && dropData.canDrop) {
            const dragData = getDragData(active);
            canDrop = dragData && dropData.canDrop(dragData);
        }
    }

    return {
        isOver: canDrop && isOver,
        active: canDrop ? active : null,
        ...props,
    };
}

export function getDraggableProps(
    transform: { x: number; y: number } | null,
    isDragging: boolean,
    dragOverlay?: boolean,
    motionProps?: MotionProps
) {
    return {
        animate: Object.assign(
            {},
            motionProps?.animate,
            transform || dragOverlay
                ? {
                      x: dragOverlay ? undefined : transform!.x,
                      y: dragOverlay ? undefined : transform!.y,
                      scale: isDragging ? 1.02 : 1,
                      zIndex: isDragging ? 1 : 0,
                      boxShadow: isDragging ? "0 3px 6px hsl(0deg 0% 0% / 10%)" : undefined,
                  }
                : {
                      x: 0,
                      y: 0,
                      scale: 1,
                      zIndex: 0,
                      boxShadow: undefined,
                  }
        ),
        transition: {
            duration: isDragging ? 0 : 0.25,
            easings: {
                type: "spring",
            },
            scale: {
                duration: 0.25,
            },
            zIndex: {
                delay: isDragging ? 0 : 0.25,
            },
        },
    };
}

function recursiveCloneChildren(children) {
    return React.Children.map(children, child => {
        if (typeof child !== "object" || child == null) {
            return child;
        }

        var childProps: any = {
            children: recursiveCloneChildren(child.props.children),
        };
        if (child.props.layout) {
            childProps.layout = false;
        }

        return React.cloneElement(child, childProps);
    });
}

export const DraggableBox: FunctionComponent<
    DraggableProps & ExtractProps<typeof MotionBox> & { css?: Interpolation<Theme> }
> = ({ draggableId, type, data, modifiers, dragOverlay, children, css, dragDisabled, dragChildren, ...props }) => {
    const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({
        id: draggableId,
        disabled: dragDisabled,
        data: {
            type: type,
            data: data,
            modifiers: modifiers,
            renderDragOverlay: dragOverlay
                ? () => {
                      return (
                          <MotionBox
                              {...getDraggableProps(null, true, true)}
                              opacity={0.25}
                              bg={dragOverlay === "nobackground" ? undefined : props.background ?? "background"}
                              borderRadius={4}
                              p={2}
                              css={{ margin: -theme.space[2], cursor: "grabbing" }}>
                              <MotionBox {...props} m={0} mb={0} mt={0} ml={0} mr={0}>
                                  {dragChildren ?? recursiveCloneChildren(children)}
                              </MotionBox>
                          </MotionBox>
                      );
                  }
                : undefined,
        } as DragData,
    });

    css = Object.assign({}, css, {
        touchAction: "none",
        cursor: isDragging ? "grabbing" : (css as any)?.cursor ?? "grab",
    });
    return (
        <MotionBox
            opacity={isDragging && dragOverlay ? 0.25 : 1}
            ref={setNodeRef}
            {...props}
            {...(dragOverlay ? {} : getDraggableProps(transform, isDragging, false, props))}
            {...listeners}
            {...attributes}
            tabIndex={undefined}
            css={css}>
            {children}
        </MotionBox>
    );
};

export const DraggableGrid: FunctionComponent<
    DraggableProps & ExtractProps<typeof MotionGrid> & { css?: Interpolation<Theme> }
> = ({ draggableId, type, data, modifiers, dragOverlay, children, css, dragDisabled, dragChildren, ...props }) => {
    const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({
        id: draggableId,
        disabled: dragDisabled,
        data: {
            type: type,
            data: data,
            modifiers: modifiers,
            renderDragOverlay: dragOverlay
                ? () => {
                      return (
                          <MotionBox
                              {...getDraggableProps(null, true, true)}
                              opacity={0.25}
                              bg={dragOverlay === "nobackground" ? undefined : props.background ?? "background"}
                              borderRadius={4}
                              p={2}
                              css={{ margin: -theme.space[2], cursor: "grabbing" }}>
                              <MotionBox {...props} m={0} mb={0} mt={0} ml={0} mr={0}>
                                  {dragChildren ?? recursiveCloneChildren(children)}
                              </MotionBox>
                          </MotionBox>
                      );
                  }
                : undefined,
        } as DragData,
    });

    css = Object.assign({}, css, {
        touchAction: "none",
        cursor: isDragging ? "grabbing" : (css as any)?.cursor ?? "grab",
    });
    return (
        <MotionGrid
            opacity={isDragging && dragOverlay ? 0.25 : 1}
            ref={setNodeRef}
            {...props}
            {...(dragOverlay ? {} : getDraggableProps(transform, isDragging, false, props))}
            {...listeners}
            {...attributes}
            tabIndex={undefined}
            css={css}>
            {children}
        </MotionGrid>
    );
};

export const SortableBox: FunctionComponent<DraggableProps & ExtractProps<typeof MotionBox>> = ({
    draggableId,
    children,
    ...props
}) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, active } = useSortable({ id: draggableId });

    return (
        <MotionBox
            layout={!active}
            ref={setNodeRef}
            {...getDraggableProps(transform, isDragging)}
            {...listeners}
            {...attributes}
            {...props}>
            {children}
        </MotionBox>
    );
};

export const SortableGrid: FunctionComponent<DraggableProps & ExtractProps<typeof MotionGrid>> = ({
    draggableId,
    children,
    ...props
}) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, active } = useSortable({ id: draggableId });

    return (
        <MotionGrid
            layout={!active}
            ref={setNodeRef}
            {...getDraggableProps(transform, isDragging)}
            {...listeners}
            {...attributes}
            {...props}>
            {children}
        </MotionGrid>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import {
    Ability,
    AppliedAbilityEffect,
    AttackModifiers,
    CoreAbility,
    CreatureConditionName,
    DnD5EDuration,
    DnD5EToken,
    DnD5ETokenTemplate,
    durationToString,
    getTimeUntil,
} from "../common";
import {
    attackTypeToString,
    conditionToString,
    CreatureCondition,
    damageTypesToMarkdown,
    Feature,
    getCoreAbilityLabel,
    isCharacter,
    ResolvedCharacter,
    ResolvedMonster,
} from "../creature";
import { useDispatch, useLocation, useSession } from "../../../components/contexts";
import { Box, Text } from "../../../components/primitives";
import { removeEffect } from "../actions/token";
import { AdvantageBox } from "./AdvantageBox";
import { Markdown } from "../../../components/markdown";
import { Button } from "../../../components/Button";
import { Message } from "../../../components/Message";
import { MotionBox } from "../../../components/motion";
import { renderIconForEffect } from "./common";
import { Spell } from "../spells";
import { useGameTime } from "../../../components/common";
import { mapKeyedList } from "../../../common";
import { Campaign, IGameSystem, isLocation } from "../../../store";

function getAttackCondition(attack: AttackModifiers, onOrBy: "on" | "by") {
    let condition = "attacks";
    let conditionPost = "";
    if (attack.condition) {
        if (attack.condition.type) {
            condition = `${attackTypeToString(attack.condition.type).toLocaleLowerCase()}s`;
        }

        if (attack.condition.ability) {
            condition += ` using ${getCoreAbilityLabel(attack.condition.ability).toLocaleLowerCase()}`;
        }

        if (attack.condition.maxDistance) {
            conditionPost +=
                onOrBy === "on"
                    ? ` where the attacker is within ${attack.condition.maxDistance} feet`
                    : ` where the target is within ${attack.condition.maxDistance} feet`;
        }

        if (attack.condition.maxDistanceExclusive) {
            conditionPost +=
                onOrBy === "on"
                    ? ` where the attacker is less than ${attack.condition.maxDistanceExclusive} feet away`
                    : ` where the target is less than ${attack.condition.maxDistanceExclusive} feet away`;
        }

        if (attack.condition.minDistance) {
            conditionPost +=
                onOrBy === "on"
                    ? ` where the attacker is at least ${attack.condition.minDistance} feet away`
                    : ` where the target is at least ${attack.condition.minDistance} feet away`;
        }

        if (attack.condition.minDistanceExclusive) {
            conditionPost +=
                onOrBy === "on"
                    ? ` where the attacker is more than ${attack.condition.minDistanceExclusive} feet away`
                    : ` where the target is more than ${attack.condition.minDistanceExclusive} feet away`;
        }
    }

    if (onOrBy === "on") {
        condition = condition + " targetting this creature";
    }

    condition += conditionPost;

    return condition;
}

const AttackModifiersNode: FunctionComponent<{
    resolvedCreature: ResolvedCharacter | ResolvedMonster;
    attack: AttackModifiers;
    onOrBy: "on" | "by";
}> = ({ resolvedCreature, attack, onOrBy }) => {
    let condition = getAttackCondition(attack, onOrBy);
    let damageMarkdown: string | undefined;
    if (attack.damage) {
        const damageTypes = Object.keys(attack.damage);
        for (let damageType of damageTypes) {
            let damage = attack.damage[damageType] as string;
            if (isCharacter(resolvedCreature)) {
                const lookup = resolvedCreature.lookups?.[damage]?.value?.toString();
                if (lookup) {
                    damage = lookup;
                }
            }

            let dmd: string;
            if (damageType === "weapon") {
                // We can't provide the actual type because we don't have a weapon here.
                dmd = `${damage.startsWith("-") ? "" : "+"}${damage} weapon damage`;
            } else {
                dmd = `${damage.startsWith("-") ? "" : "+"}:damage[${damage}]{type=${damageType}} ${damageType} damage`;
            }

            damageMarkdown = damageMarkdown ? `${damageMarkdown}, ${dmd}` : dmd;
        }

        if (damageMarkdown) {
            damageMarkdown = damageMarkdown + " on " + condition;
        }
    }

    return (
        <React.Fragment>
            {attack.advantage && (
                <li>
                    <AdvantageBox advantage="adv" reason={`Advantage on ${condition}`} allowWrap />
                </li>
            )}
            {attack.disadvantage && (
                <li>
                    <AdvantageBox advantage="dis" reason={`Disadvantage on ${condition}`} allowWrap />
                </li>
            )}
            {attack.crit && <li>Successful {condition} are automatically a critical hit</li>}
            {damageMarkdown && (
                <li>
                    <Markdown>{damageMarkdown}</Markdown>
                </li>
            )}
            {attack.extraRoll && (
                <li>
                    <Text>{(attack.extraRoll.startsWith("-") ? "" : "+") + attack.extraRoll} to all attack rolls</Text>
                </li>
            )}
        </React.Fragment>
    );
};

const MultiplierItem: FunctionComponent<{
    item: string;
    multiplier: number;
}> = ({ item, multiplier }) => {
    return (
        <li>
            {multiplier === 0 && <Text>{item} becomes 0</Text>}
            {multiplier === 0.5 && <Text>{item} is halved</Text>}
            {multiplier !== 0 && multiplier !== 0.5 && (
                <Text>
                    {item} modified to {Math.trunc(multiplier * 100)}%
                </Text>
            )}
        </li>
    );
};

const DiscList = styled.ul`
    list-style-type: disc;
    margin-left: ${props => props.theme.space[4]}px;
    list-style-position: outside;
`;

function getAppliedByName(
    system: IGameSystem,
    campaign: Campaign,
    appliedBy: string | undefined,
    appliedAt: string | undefined
) {
    if (appliedBy == null || appliedAt == null) {
        return undefined;
    }

    const appliedAtLocation = campaign.locations[appliedAt];
    if (isLocation(appliedAtLocation)) {
        const appliedByToken = appliedAtLocation.tokens[appliedBy];
        if (appliedByToken) {
            return system.getDisplayName(appliedByToken, campaign);
        }
    }

    return undefined;
}

function durationTriggerToString(
    system: IGameSystem,
    campaign: Campaign,
    duration: DnD5EDuration & { trigger?: "sot" | "eot" | "source_sot" | "source_eot" },
    appliedBy: string | undefined,
    appliedAt: string | undefined
) {
    if (duration.unit === "permanent" || duration.unit === "special") {
        return undefined;
    }

    const trigger = duration.trigger ?? "sot";
    switch (trigger) {
        case "sot":
            return "expires at start of turn";
        case "eot":
            return "expires at end of turn";
        case "source_sot": {
            const appliedByName = getAppliedByName(system, campaign, appliedBy, appliedAt);
            return appliedByName ? `expires at the start of ${appliedByName}'s turn` : "expires at start of turn";
        }
        case "source_eot": {
            const appliedByName = getAppliedByName(system, campaign, appliedBy, appliedAt);
            return appliedByName ? `expires at the end of ${appliedByName}'s turn` : "expires at end of turn";
        }
    }

    return "expires at end of turn";
}

export const AppliedAbilityEffectItem: FunctionComponent<{
    token: DnD5EToken | DnD5ETokenTemplate;
    resolvedCreature: ResolvedCharacter | ResolvedMonster;
    effect: AppliedAbilityEffect & {
        source?: string;
        feature?: Feature;
        ability?: Ability;
        spell?: Spell;
        condition?: CreatureCondition;
    };
    effectKey: string;
}> = ({ token, resolvedCreature, effect, effectKey }) => {
    const dispatch = useDispatch();
    const { campaign, location, system } = useLocation();
    const { session } = useSession();

    const abilityCheckAdv: string[] = [];
    const abilityCheckDis: string[] = [];
    const abilityCheckFail: string[] = [];
    const abilityCheckExtraRoll: string[] = [];
    if (effect.abilityChecks) {
        if (effect.abilityChecks.all) {
            const condition = effect.abilityChecks.all.condition;
            if (effect.abilityChecks.all.advantage) {
                abilityCheckAdv.push(`Advantage on all ability checks${condition ? ` (${condition})` : ""}`);
            }

            if (effect.abilityChecks.all.disadvantage) {
                abilityCheckAdv.push(`Disadvantage on all ability checks${condition ? ` (${condition})` : ""}`);
            }

            if (effect.abilityChecks.all.fail) {
                abilityCheckFail.push(`Automatically fail all ability checks${condition ? ` (${condition})` : ""}`);
            }

            const extraRoll = effect.abilityChecks.all.extraRoll;
            if (extraRoll) {
                abilityCheckExtraRoll.push(`${extraRoll.startsWith("-") ? "" : "+"}${extraRoll} to all ability checks`);
            }
        } else {
            const abilityCheckKeys = Object.keys(effect.abilityChecks) as CoreAbility[];
            for (let key of abilityCheckKeys) {
                const abilityCheck = effect.abilityChecks[key];
                if (abilityCheck) {
                    const condition = abilityCheck.condition;
                    if (abilityCheck.advantage) {
                        abilityCheckAdv.push(
                            `Advantage on ${getCoreAbilityLabel(key).toLocaleLowerCase()} checks${
                                condition ? ` (${condition})` : ""
                            }`
                        );
                    }

                    if (abilityCheck.disadvantage) {
                        abilityCheckDis.push(
                            `Disadvantage on ${getCoreAbilityLabel(key).toLocaleLowerCase()} checks${
                                condition ? ` (${condition})` : ""
                            }`
                        );
                    }

                    if (abilityCheck.fail) {
                        abilityCheckFail.push(
                            `Automatically fail ${getCoreAbilityLabel(key).toLocaleLowerCase()} checks${
                                condition ? ` (${condition})` : ""
                            }`
                        );
                    }

                    if (abilityCheck.extraRoll) {
                        abilityCheckExtraRoll.push(
                            `${abilityCheck.extraRoll.startsWith("-") ? "" : "+"}${
                                abilityCheck.extraRoll
                            } to ${getCoreAbilityLabel(key).toLocaleLowerCase()} checks${
                                condition ? ` (${condition})` : ""
                            }`
                        );
                    }
                }
            }
        }
    }

    const savingThrowAdv: string[] = [];
    const savingThrowDis: string[] = [];
    const savingThrowFail: string[] = [];
    const savingThrowExtraRoll: string[] = [];
    if (effect.savingThrows) {
        if (effect.savingThrows.all) {
            const condition = effect.savingThrows.all.condition;
            if (effect.savingThrows.all.advantage) {
                savingThrowAdv.push(`Advantage on all saving throws${condition ? ` (${condition})` : ""}`);
            }

            if (effect.savingThrows.all.disadvantage) {
                savingThrowAdv.push(`Disadvantage on all saving throws${condition ? ` (${condition})` : ""}`);
            }

            if (effect.savingThrows.all.fail) {
                savingThrowFail.push(`Automatically fail all saving throws${condition ? ` (${condition})` : ""}`);
            }

            const extraRoll = effect.savingThrows.all.extraRoll;
            if (extraRoll) {
                savingThrowExtraRoll.push(`${extraRoll.startsWith("-") ? "" : "+"}${extraRoll} to all saving throws`);
            }
        } else {
            const savingThrowKeys = Object.keys(effect.savingThrows) as CoreAbility[];
            for (let key of savingThrowKeys) {
                const savingThrow = effect.savingThrows[key];
                if (savingThrow) {
                    const condition = savingThrow.condition;
                    if (savingThrow.advantage) {
                        savingThrowAdv.push(
                            `Advantage on ${getCoreAbilityLabel(key).toLocaleLowerCase()} saving throws${
                                condition ? ` (${condition})` : ""
                            }`
                        );
                    }

                    if (savingThrow.disadvantage) {
                        savingThrowDis.push(
                            `Disadvantage on ${getCoreAbilityLabel(key).toLocaleLowerCase()} saving throws${
                                condition ? ` (${condition})` : ""
                            }`
                        );
                    }

                    if (savingThrow.fail) {
                        savingThrowFail.push(
                            `Automatically fail ${getCoreAbilityLabel(key).toLocaleLowerCase()} saving throws${
                                condition ? ` (${condition})` : ""
                            }`
                        );
                    }

                    if (savingThrow.extraRoll) {
                        savingThrowExtraRoll.push(
                            `${savingThrow.extraRoll.startsWith("-") ? "" : "+"}${
                                savingThrow.extraRoll
                            } to to ${getCoreAbilityLabel(key).toLocaleLowerCase()} saving throws${
                                condition ? ` (${condition})` : ""
                            }`
                        );
                    }
                }
            }
        }
    }

    // TODO: Damage? (this doesn't do anything for now anyway)
    // TODO: savingThrow (not implemented yet anyway, until we focus on an effect that has a saving throw every turn)
    // TODO: spell/feature (thing that caused this effect)

    // TODO: Duration may be linked to the effect's source (i.e. a concentration spell that is tracked on the caster).
    let trigger = effect.duration
        ? durationTriggerToString(system, campaign, effect.duration, effect.appliedBy, effect.appliedAt)
        : undefined;

    useGameTime();

    let durationAsString: string | undefined;
    if (effect.duration) {
        if (effect.duration.expires) {
            const duration = getTimeUntil(session, effect.duration.expires, token, effect.duration.trigger);
            if (duration === 0) {
                // The effect is expiring this round, so just show the trigger.
                durationAsString = trigger;
                if (durationAsString) {
                    durationAsString = durationAsString[0].toLocaleUpperCase() + durationAsString?.slice(1);
                }

                trigger = undefined;
            } else {
                durationAsString = `${durationToString(duration)} remaining`;
            }
        } else {
            durationAsString = `${durationToString(effect.duration)} remaining`;
        }
    }

    trigger = trigger != null ? ` (${trigger})` : "";

    const icon = renderIconForEffect(effect, 20);
    return (
        <MotionBox layout flexDirection="column" alignItems="flex-start" mt={3}>
            <Box flexDirection="row">
                {icon}
                <Text ml={icon ? 2 : 0} color="grayscale.2">
                    {effect.name.toLocaleUpperCase()}
                </Text>
            </Box>
            <Box flexDirection="row">
                {durationAsString && (
                    <Text fontStyle="italic">
                        {durationAsString}
                        {trigger}
                    </Text>
                )}
                {!effect.isReadOnly && (
                    <Button
                        size="s"
                        variant="link"
                        onClick={() => {
                            dispatch(removeEffect(campaign, location, token, effectKey));
                        }}>
                        (end now)
                    </Button>
                )}
            </Box>
            {effect.endTriggers?.damage && (
                <Box>
                    <Text fontStyle="italic">Ends if any damage is taken.</Text>
                </Box>
            )}
            <DiscList>
                {abilityCheckAdv.length > 0 &&
                    abilityCheckAdv.map(o => (
                        <li key={o}>
                            <AdvantageBox advantage="adv" reason={o} allowWrap />
                        </li>
                    ))}
                {abilityCheckDis.length > 0 &&
                    abilityCheckDis.map(o => (
                        <li key={o}>
                            <AdvantageBox advantage="dis" reason={o} allowWrap />
                        </li>
                    ))}
                {abilityCheckFail.length > 0 &&
                    abilityCheckFail.map(o => (
                        <li key={o}>
                            <AdvantageBox advantage="fail" reason={o} allowWrap />
                        </li>
                    ))}
                {abilityCheckExtraRoll.length > 0 &&
                    abilityCheckExtraRoll.map(o => (
                        <li key={o}>
                            <Text>{o}</Text>
                        </li>
                    ))}
                {savingThrowAdv.length > 0 &&
                    savingThrowAdv.map(o => (
                        <li key={o}>
                            <AdvantageBox advantage="adv" reason={o} allowWrap />
                        </li>
                    ))}
                {savingThrowDis.length > 0 &&
                    savingThrowDis.map(o => (
                        <li key={o}>
                            <AdvantageBox advantage="dis" reason={o} allowWrap />
                        </li>
                    ))}
                {savingThrowFail.length > 0 &&
                    savingThrowFail.map(o => (
                        <li key={o}>
                            <AdvantageBox advantage="fail" reason={o} allowWrap />
                        </li>
                    ))}
                {savingThrowExtraRoll.length > 0 &&
                    savingThrowExtraRoll.map(o => (
                        <li key={o}>
                            <Text>{o}</Text>
                        </li>
                    ))}
                {effect.immunities && (
                    <li>
                        <Markdown>{damageTypesToMarkdown("Immune", effect.immunities)}</Markdown>
                    </li>
                )}
                {effect.resistances && (
                    <li>
                        <Markdown>{damageTypesToMarkdown("Resistant", effect.resistances)}</Markdown>
                    </li>
                )}
                {effect.vulnerabilities && (
                    <li>
                        <Markdown>{damageTypesToMarkdown("Vulnerable", effect.vulnerabilities)}</Markdown>
                    </li>
                )}
                {effect.transform && (
                    <li>
                        <Text>Transformed into {effect.transform.creature?.name}</Text>
                    </li>
                )}
                {effect.speedMultiplier != null && (
                    <MultiplierItem item="Movement speed" multiplier={effect.speedMultiplier} />
                )}
                {effect.moveCost != null && (
                    <li>
                        <Text>
                            Each foot of movement requires an additional{" "}
                            {effect.moveCost === 1 ? "1 foot" : `${effect.moveCost} feet`}.
                        </Text>
                    </li>
                )}
                {effect.maxHpMultiplier != null && <MultiplierItem item="Max HP" multiplier={effect.maxHpMultiplier} />}
                {mapKeyedList(effect.attacksBy, (o, i, k) => (
                    <AttackModifiersNode key={k} resolvedCreature={resolvedCreature} attack={o} onOrBy="by" />
                ))}
                {mapKeyedList(effect.attacksOn, (o, i, k) => (
                    <AttackModifiersNode key={k} resolvedCreature={resolvedCreature} attack={o} onOrBy="on" />
                ))}
                {effect.canTakeActions === false && <li>Cannot take actions.</li>}
                {effect.canTakeReactions === false && <li>Cannot take reactions.</li>}
                {effect.ac != null && (
                    <li>
                        <Text>{effect.ac >= 0 ? "+" + effect.ac : effect.ac} to armor class</Text>
                    </li>
                )}
                {effect.conditions && (
                    <li>
                        <Text>
                            The creature suffers the following conditions:{" "}
                            {Object.keys(effect.conditions)
                                .map(o => conditionToString(o as CreatureConditionName))
                                .join(", ")}
                        </Text>
                    </li>
                )}
            </DiscList>
            {effect.unhandled && (
                <Message mt={2} variant="warning">
                    <Markdown>{"**Not handled automatically**\n\n" + effect.unhandled}</Markdown>
                </Message>
            )}
        </MotionBox>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, useState } from "react";
import { asField, Form, IAsFieldProps, InputField } from "../../../../components/Form";
import { Button } from "../../../../components/Button";
import { ResolvedCharacter } from "../../creature";
import { Box, Grid } from "../../../../components/primitives";
import { DnD5ECharacterTemplate, DnD5ECharacterToken } from "../../common";
import { ImageType, ModelType, Token, TokenTemplate } from "../../../../store";
import { LibraryItem } from "../../../../library";
import { useAppState, useDispatch, useLocation } from "../../../../components/contexts";
import { modifyCharacter } from "../../actions/token";
import { DragData, dragDropPalette, useTypedDroppable } from "../../../../components/draggable";
import { Active } from "@dnd-kit/core";
import { TokenImageField } from "../../../../components/TokenImage";
import { ScrollableTest } from "../../../../components/ScrollableTest";
import { setTokenImage, setPortraitImage, modifyToken } from "../../../../actions/token";
import { PortraitImageField } from "../../../../components/PortraitImage";
import { theme } from "../../../../design";
import { useDebounce, useLocalSetting } from "../../../../components/utils";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { TokenModel } from "../../../../components/LocationStage/TokenNode";
import { ExtractProps, useVttApp } from "../../../../components/common";
import { MotionBox, MotionLobotomizedBox } from "../../../../components/motion";
import { artFilterSetting } from "../../../../components/Sidebar/ArtLibrary";
import { defaultSections, Pages } from "../../../../components/Sidebar";

const artSection = defaultSections.find(o => o.id === Pages.Art)!;

const ModelViewer: FunctionComponent<{
    token: Token | TokenTemplate;
    isDragOver: boolean;
    isDragActive: boolean;
}> = ({ token, isDragOver, isDragActive }) => {
    const { system, campaign, location } = useLocation();
    const { searchPropertiesSection, setSearchPropertiesSection } = useAppState();
    const { isSearchExpanded, setIsSearchExpanded } = useVttApp();
    const [artFilter, setArtFilter] = useLocalSetting(artFilterSetting);
    const dispatch = useDispatch();

    const isFindingModels =
        isSearchExpanded && searchPropertiesSection === artSection && artFilter?.type === ModelType.Token;

    const bg = isDragActive ? (isDragOver ? dragDropPalette[1] : dragDropPalette[0]) : theme.colors.grayscale[9];
    return (
        <MotionBox layout fullWidth justifyContent="flex-start" alignItems="flex-start">
            <MotionBox
                layout
                borderRadius={4}
                animate={{
                    background: bg,
                }}
                width={268}
                height={384}
                onPointerDown={e => {
                    (e.target as Element).setPointerCapture(e.pointerId);
                    e.preventDefault();
                }}>
                <Canvas linear flat legacy camera={{ up: [0, 0, 1], position: [0, -3, 1] }}>
                    <ambientLight intensity={0.2} />
                    <pointLight position={[10, -10, 10]} />
                    <OrbitControls />

                    {token.modelUri && (
                        <TokenModel
                            key={token.modelUri}
                            modelUri={token.modelUri}
                            z={-1.5}
                            errorMessage={() =>
                                `Failed to download model for ${system.getDisplayName(token, campaign)}.`
                            }
                        />
                    )}
                </Canvas>
            </MotionBox>

            <MotionLobotomizedBox layout="position" flexDirection="column" alignItems="stretch" ml={2}>
                <Button
                    disabled={isFindingModels}
                    onClick={() => {
                        setArtFilter({ type: ModelType.Token });
                        setSearchPropertiesSection(artSection);
                        setIsSearchExpanded(true);
                    }}>
                    Find a model…
                </Button>
                <Button
                    disabled={!token.modelUri}
                    onClick={() => {
                        dispatch(modifyToken(campaign, location, token, { modelUri: undefined }));
                    }}>
                    Remove model
                </Button>
            </MotionLobotomizedBox>
        </MotionBox>
    );
};

const ModelViewerField = asField<HTMLDivElement, ExtractProps<typeof ModelViewer> & IAsFieldProps>(ModelViewer);

export const InitialPage: FunctionComponent<{
    character: DnD5ECharacterTemplate | DnD5ECharacterToken;
    resolvedCharacter: ResolvedCharacter;
}> = ({ character, resolvedCharacter }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const [nameOverride, setNameOverride] = useState<string>();
    const onNameChanged = useDebounce(
        (name: string) => {
            dispatch(modifyCharacter(campaign, location, [character], { name: name }));
            setNameOverride(undefined);
        },
        1500,
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setNameOverride(e.target.value);
            return [e.target.value];
        }
    );

    const { setNodeRef, active, isOver } = useTypedDroppable({
        id: "characterImages",
        accepts: [
            `LibraryItem/${ImageType.Token}`,
            `LibraryItem/${ImageType.Portrait}`,
            `LibraryItem/${ModelType.Token}`,
        ],
        onDrop: (drag: DragData, active: Active) => {
            const libraryItem = drag.data as LibraryItem;
            if (drag.type === `LibraryItem/${ImageType.Token}`) {
                dispatch(setTokenImage(campaign, location, [character], libraryItem));
            } else if (drag.type === `LibraryItem/${ImageType.Portrait}`) {
                dispatch(setPortraitImage(campaign, location, [character], libraryItem));
            } else if (drag.type === `LibraryItem/${ModelType.Token}`) {
                dispatch(
                    modifyToken(
                        campaign,
                        location,
                        character,
                        {
                            modelUri: libraryItem.uri,
                        },
                        true
                    )
                );
            }
        },
    });

    return (
        <Box
            px={3}
            pb={3}
            fullHeight
            ref={setNodeRef}
            marginX={-2}
            css={{ width: `calc(100% + ${2 * theme.space[2]}px)` }}>
            <ScrollableTest fullWidth fullHeight minimal paddingX={2}>
                <Form>
                    <InputField
                        fullWidth
                        label="Character name"
                        variant="text"
                        required
                        value={nameOverride ?? character.dnd5e.name}
                        onChange={onNameChanged}
                    />
                    <Grid
                        fullWidth
                        css={{ alignItems: "flex-start", gap: theme.space[4] }}
                        gridTemplateColumns="1fr 2fr"
                        gridTemplateRows="auto 1fr">
                        <PortraitImageField
                            label="Portrait image"
                            hint="A portrait of the character, shown on their character sheet. Drag and drop an image from your art library to change it."
                            token={character}
                            isDragOver={!!isOver && active?.data.current?.type === `LibraryItem/${ImageType.Portrait}`}
                            isDragActive={
                                active != null && active?.data.current?.type === `LibraryItem/${ImageType.Portrait}`
                            }
                        />
                        <TokenImageField
                            label="Token image"
                            hint="The image used to represent the character on the map. You can add multiple images here and switch between them at any time. Drag and drop an image from your art library to add it."
                            token={character}
                            isDragOver={!!isOver && active?.data.current?.type === `LibraryItem/${ImageType.Token}`}
                            isDragActive={
                                active != null && active?.data.current?.type === `LibraryItem/${ImageType.Token}`
                            }
                            allowEdit
                        />

                        <Box flexDirection="column" gridRow="1/3" gridColumn="2">
                            <ModelViewerField
                                label="Model"
                                hint="A 3D model of the character, used in 3D mode. Drag and drop a model from your art library to change it."
                                token={character}
                                isDragOver={!!isOver && active?.data.current?.type === `LibraryItem/${ModelType.Token}`}
                                isDragActive={
                                    active != null && active?.data.current?.type === `LibraryItem/${ModelType.Token}`
                                }
                            />
                        </Box>
                    </Grid>
                </Form>
            </ScrollableTest>
        </Box>
    );
};

import React from 'react'
  
  const ObstructsLightIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 17.25,11.25c3.314,0,6,2.686,6,6s-2.686,6-6,6s-6-2.686-6-6S13.936,11.25,17.25,11.25z  M 19.5,14.999l-4.5,4.5  M 15,14.999l4.5,4.5  M 21.75,9.7c0.382-0.361,0.741-0.727,1.073-1.093c0.57-0.632,0.57-1.594,0-2.226 C20.2,3.5,16.031,0.684,12,0.751C7.969,0.684,3.8,3.5,1.179,6.385c-0.57,0.633-0.57,1.593,0,2.226 c1.945,2.178,4.369,3.874,7.082,4.955  M 10.5,10.937C8.603,10.108,7.737,7.898,8.566,6s3.039-2.763,4.937-1.934 C15.399,4.895,16.265,7.103,15.438,9' />
    </svg>
  )
  
  ObstructsLightIcon.displayName = 'ObstructsLightIcon'
  
  ObstructsLightIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default ObstructsLightIcon
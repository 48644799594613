import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { LocalPixelPosition } from "../../position";
import { EllipseAnnotation, Annotation, getAnnotationPos } from "../../annotations";
import { useDispatch, useLocalGrid, useTokenOverrides, useScale, useValidatedLocationLevel } from "../contexts";
import { getPlayerColorPalette, getThemeColorPalette } from "../../design/utils";
import { theme } from "../../design";
import { localPoint, rotate, distanceBetween } from "../../grid";
import { AnnotationHandle } from "./AnnotationHandle";
import { modifyAnnotation } from "../../actions/location";
import { DraggableEllipse } from "./three2d/Ellipse";
import { HoverTracking, ZIndexes } from "./common";
import { DraggableLine, Line } from "./three2d/Line";
import { EllipseCurve } from "three";
import { Token } from "../../store";
import { ThreeEvent } from "@react-three/fiber";
import { DistanceMessage } from "./DistanceMessage";
import { defaultLineWidth, interactiveLineWidth } from "../common";
import { usePreviousValue } from "../utils";
import { AnimatePresence, usePresence } from "framer-motion";
import { UiLayer } from "./UiLayer";
import { SelectionType } from "../selection";

interface EllipseAnnotationNodeProps extends HoverTracking {
    ellipse: EllipseAnnotation;
    activePoint?: LocalPixelPosition;
    isSelected: SelectionType;
    wasSelected: boolean;
    disabled?: boolean;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onPointerDown?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onPointerUp?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onOverrideAnnotation: (id: string, override: Partial<EllipseAnnotation> | undefined) => void;
    animateExit?: boolean;
}

const EllipseAnnotationNodeCore: FunctionComponent<EllipseAnnotationNodeProps> = ({
    ellipse,
    activePoint,
    snapPoint,
    isSelected,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideAnnotation,
    disabled,
    isHovering,
    setHoverPart,
    wasSelected,
    animateExit,
}) => {
    const dispatch = useDispatch();
    const { campaign, location, levelKey } = useValidatedLocationLevel();
    const grid = useLocalGrid();
    const { tokenOverrides } = useTokenOverrides();
    const [isResizing, setIsResizing] = useState(false);
    const scale = useScale();

    const showHandles = isSelected && !disabled && !ellipse.disableEdit;

    // We need to manage presence manually because of the AnnotationHandles being in their own AnimatePresence - they need
    // to be given a chance to animate out.
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        if (!isPresent && !animateExit && !showHandles) {
            safeToRemove?.();
        }
    }, [isPresent, safeToRemove, animateExit, showHandles]);

    let colours = disabled ? getThemeColorPalette("grayscale") : getPlayerColorPalette(campaign, ellipse.userId);

    const onDragMove = useCallback(
        (e: ThreeEvent<PointerEvent>, p: LocalPixelPosition) => {
            onOverrideAnnotation(ellipse.id, {
                pos: { ...p, level: ellipse.pos?.level ?? levelKey },
            });
        },
        [ellipse.id, ellipse.pos?.level, levelKey, onOverrideAnnotation]
    );
    const onDragEnd = useCallback(() => {
        dispatch(modifyAnnotation<EllipseAnnotation>(campaign.id, location.id, ellipse.id, { pos: ellipse.pos }));
        onOverrideAnnotation(ellipse.id, undefined);
    }, [dispatch, location, ellipse, campaign, onOverrideAnnotation]);

    const pos = getAnnotationPos(ellipse, campaign, location, grid, tokenOverrides);
    let radiusX = ellipse.radiusX;
    let radiusY = ellipse.radiusY;
    if (activePoint) {
        radiusX = Math.abs(activePoint.x - pos.x);
        radiusY = Math.abs(activePoint.y - pos.y);
    }

    const ellipseCurve = new EllipseCurve(0, 0, radiusX, radiusY, 0, Math.PI * 2, false, 0);
    const points = ellipseCurve.getPoints(64).map(o => localPoint(o.x, o.y));

    const onAnnotationClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, ellipse);
            }
        },
        [ellipse, onClick]
    );

    const isHover = onClick && (isHovering || !!activePoint);
    const wasFilled = usePreviousValue(!!ellipse.isFilled) ?? ellipse.isFilled;

    const r = ellipse.rotation;
    const disableDrag = onClick == null || disabled || !!ellipse.centerOn;
    const unselectedLineColor = isHover ? colours[4] : colours[3];
    const opacity = disabled ? 0.25 : 0.5;
    return (
        <>
            {(isPresent || animateExit) && (
                <>
                    <AnimatePresence>
                        {ellipse.isFilled && isPresent && (
                            <DraggableEllipse
                                x={pos.x}
                                y={pos.y}
                                animateEnterExit="nolayout"
                                initial={
                                    !wasFilled
                                        ? undefined
                                        : isSelected
                                        ? {
                                              color: colours[isHover ? 1 : 0],
                                              opacity,
                                              scale: 1,
                                          }
                                        : wasSelected
                                        ? {
                                              color: colours[1],
                                              opacity,
                                              scale: 1,
                                          }
                                        : undefined
                                }
                                exit={animateExit ? undefined : null}
                                color={colours[isHover || isSelected ? 1 : 0]}
                                xRadius={radiusX}
                                yRadius={radiusY}
                                rotation={r}
                                opacity={opacity}
                                zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Tokens - 0.01}
                                dragBoundFunc={v => snapPoint(v)}
                                onPointerOver={() => setHoverPart?.("fill", true)}
                                onPointerOut={() => setHoverPart?.("fill", false)}
                                onPointerDown={onPointerDown ? e => onPointerDown(e, ellipse) : undefined}
                                onPointerUp={onPointerUp ? e => onPointerUp(e, ellipse) : undefined}
                                cursor={disableDrag ? undefined : "pointer"}
                                disableDrag={disableDrag}
                                onDragMove={onDragMove}
                                onDragEnd={onDragEnd}
                                onClick={disabled ? undefined : onAnnotationClick}
                            />
                        )}
                    </AnimatePresence>
                    <DraggableLine
                        x={pos.x}
                        y={pos.y}
                        zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                        rotation={r}
                        opacity={0}
                        width={defaultLineWidth + interactiveLineWidth}
                        points={points}
                        cursor={disableDrag ? undefined : "pointer"}
                        onPointerOver={() => setHoverPart?.("line", true)}
                        onPointerOut={() => setHoverPart?.("line", false)}
                        disableDrag={disableDrag}
                        onDragMove={onDragMove}
                        onDragEnd={onDragEnd}
                        onClick={disabled ? undefined : onAnnotationClick}
                        onPointerDown={onPointerDown ? e => onPointerDown(e, ellipse) : undefined}
                        onPointerUp={onPointerUp ? e => onPointerUp(e, ellipse) : undefined}
                    />
                    <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                        {isPresent && (
                            <Line
                                x={pos.x}
                                y={pos.y}
                                zIndex={
                                    (isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations) + 0.01
                                }
                                rotation={r}
                                animateEnterExit="nolayout"
                                initial={
                                    isSelected || activePoint
                                        ? {
                                              color: unselectedLineColor,
                                              opacity: 1,
                                              scale: 1,
                                          }
                                        : wasSelected
                                        ? {
                                              color: theme.colors.guidance.focus,
                                              opacity: 1,
                                              scale: 1,
                                          }
                                        : undefined
                                }
                                exit={animateExit ? undefined : null}
                                color={
                                    isSelected === SelectionType.Primary
                                        ? theme.colors.guidance.focus
                                        : unselectedLineColor
                                }
                                width={defaultLineWidth}
                                points={points}
                            />
                        )}
                    </AnimatePresence>
                </>
            )}
            <UiLayer>
                <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                    {showHandles && isPresent && (
                        <>
                            <AnnotationHandle
                                x={pos.x}
                                y={pos.y}
                                offsetX={-radiusX}
                                rotation={r}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPoint}
                                dragBoundFunc={(current, next) => dragBoundY(current, next, pos, r)}
                                disableDrag={onClick == null}
                                onDragMove={point => {
                                    onOverrideAnnotation(ellipse.id, {
                                        radiusX: distanceBetween(point, pos),
                                    });
                                    setIsResizing(true);
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<EllipseAnnotation>(campaign.id, location.id, ellipse.id, {
                                            radiusX: ellipse.radiusX,
                                        })
                                    );
                                    onOverrideAnnotation(ellipse.id, undefined);
                                    setIsResizing(false);
                                }}
                            />
                            <AnnotationHandle
                                x={pos.x}
                                y={pos.y}
                                offsetY={-radiusY}
                                rotation={r}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPoint}
                                dragBoundFunc={(current, next) => dragBoundX(current, next, pos, r)}
                                disableDrag={onClick == null}
                                onDragMove={point => {
                                    onOverrideAnnotation(ellipse.id, {
                                        radiusY: distanceBetween(point, pos),
                                    });
                                    setIsResizing(true);
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<EllipseAnnotation>(campaign.id, location.id, ellipse.id, {
                                            radiusY: ellipse.radiusY,
                                        })
                                    );
                                    onOverrideAnnotation(ellipse.id, undefined);
                                    setIsResizing(false);
                                }}
                            />
                            <AnnotationHandle
                                x={pos.x}
                                y={pos.y}
                                offsetX={radiusX}
                                rotation={r}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPoint}
                                dragBoundFunc={(current, next) => dragBoundY(current, next, pos, r)}
                                disableDrag={onClick == null}
                                onDragMove={point => {
                                    onOverrideAnnotation(ellipse.id, {
                                        radiusX: distanceBetween(point, pos),
                                    });
                                    setIsResizing(true);
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<EllipseAnnotation>(campaign.id, location.id, ellipse.id, {
                                            radiusX: ellipse.radiusX,
                                        })
                                    );
                                    onOverrideAnnotation(ellipse.id, undefined);
                                    setIsResizing(false);
                                }}
                            />
                            <AnnotationHandle
                                x={pos.x}
                                y={pos.y}
                                offsetY={radiusY}
                                rotation={r}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPoint}
                                dragBoundFunc={(current, next) => dragBoundX(current, next, pos, r)}
                                disableDrag={onClick == null}
                                onDragMove={point => {
                                    onOverrideAnnotation(ellipse.id, {
                                        radiusY: distanceBetween(point, pos),
                                    });
                                    setIsResizing(true);
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<EllipseAnnotation>(campaign.id, location.id, ellipse.id, {
                                            radiusY: ellipse.radiusY,
                                        })
                                    );
                                    onOverrideAnnotation(ellipse.id, undefined);
                                    setIsResizing(false);
                                }}
                            />
                        </>
                    )}
                </AnimatePresence>
            </UiLayer>
            {(activePoint || isResizing) && (
                <>
                    <Line
                        x={pos.x}
                        y={pos.y}
                        zIndex={ZIndexes.UserInterface}
                        points={[localPoint(0, 0), rotate(localPoint(radiusX, 0), localPoint(0, 0), r != null ? r : 0)]}
                        color={theme.colors.guidance.focus}
                    />
                    <DistanceMessage
                        distance={radiusX / location.tileSize.width}
                        pos={rotate(
                            localPoint(pos.x + radiusX / 2, pos.y - theme.space[1] / scale),
                            pos,
                            r != null ? r : 0
                        )}
                        horizontalAlignment="center"
                        verticalAlignment="bottom"
                    />
                </>
            )}
            {(activePoint || isResizing) && (
                <>
                    <Line
                        x={pos.x}
                        y={pos.y}
                        zIndex={ZIndexes.UserInterface}
                        points={[localPoint(0, 0), rotate(localPoint(0, radiusY), localPoint(0, 0), r != null ? r : 0)]}
                        color={theme.colors.guidance.focus}
                    />
                    <DistanceMessage
                        distance={radiusY / location.tileSize.height}
                        pos={rotate(
                            localPoint(pos.x + theme.space[1] / scale, pos.y + radiusY / 2),
                            pos,
                            r != null ? r : 0
                        )}
                        horizontalAlignment="left"
                        verticalAlignment="center"
                    />
                </>
            )}
        </>
    );
};

function dragBoundY(current: LocalPixelPosition, next: LocalPixelPosition, center: LocalPixelPosition, r?: number) {
    if (r == null || r === 0) {
        return localPoint(next.x, current.y);
    }

    const cr = rotate(current, center, -r);
    const nr = rotate(next, center, -r);
    const ur = localPoint(nr.x, cr.y);
    return rotate(ur, center, r);
}

function dragBoundX(current: LocalPixelPosition, next: LocalPixelPosition, center: LocalPixelPosition, r?: number) {
    if (r == null || r === 0) {
        return localPoint(current.x, next.y);
    }

    const cr = rotate(current, center, -r);
    const nr = rotate(next, center, -r);
    const ur = localPoint(cr.x, nr.y);
    return rotate(ur, center, r);
}

export const EllipseAnnotationNode = React.memo(EllipseAnnotationNodeCore);

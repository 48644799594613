/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent } from "react";

import { Vector } from "../primitives";

import { SpaceProps, WidthProps, HeightProps } from "styled-system";

export interface IIdentityProps {
    colour?: string;
}

type IdentityProps = IIdentityProps & SpaceProps & WidthProps & HeightProps;

const Identity: FunctionComponent<IdentityProps> = ({ width, height, colour, ...props }) => {
    colour = colour ? colour : "accent";
    return (
        <Vector
            width={width} // TODO: Type error
            height={height} // TODO: Type error
            viewBox="0 0 32 32"
            css={(theme: any) => ({
                path: {
                    fill: theme.colors[colour!][6],
                },
                "path:last-child": {
                    fill: theme.colors[colour!][4],
                },
            })}
            {...props}>
            <g>
                <path d="M22.8453 2.14355C23.5598 2.14355 24.2201 2.52475 24.5773 3.14355L31.4226 15C31.7799 15.6188 31.7799 16.3812 31.4226 17L24.5773 28.8564C24.2201 29.4752 23.5598 29.8564 22.8453 29.8564L9.1547 29.8564C8.44017 29.8564 7.77991 29.4752 7.42265 28.8564L0.57735 17C0.220084 16.3812 0.220084 15.6188 0.57735 15L7.42265 3.14355C7.77991 2.52475 8.44017 2.14355 9.1547 2.14355L22.8453 2.14355Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.8757 2.42944C23.5697 2.24547 23.2148 2.14355 22.8453 2.14355H9.1547C8.44017 2.14355 7.77992 2.52475 7.42265 3.14355L0.577352 15C0.220086 15.6188 0.220086 16.3812 0.577352 17L7.42265 28.8564C7.77992 29.4752 8.44017 29.8564 9.1547 29.8564H22.8453C23.2148 29.8564 23.5697 29.7545 23.8758 29.5705L16.6182 16.9999C16.2609 16.3811 16.2609 15.6187 16.6182 14.9999L23.8757 2.42944Z"
                />
            </g>
        </Vector>
    );
};

Identity.defaultProps = {
    colour: "accent",
    width: 32,
    height: 32,
};

export default Identity;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import styled from "@emotion/styled";
import { Card, Box } from "../primitives";
import { theme } from "../../design";
import { overlayStyles } from "../primitives/overlay";

interface IToolbarProps {
    direction: "horizontal" | "vertical";
    size?: number;
}

type ToolbarProps = IToolbarProps & any;

const toolbarDirection = props => {
    const { space } = props;
    const toolbarDirections = {
        horizontal: {
            "> *:not(span)": {
                marginRight: props.theme.space[space],
                "&:last-child": {
                    marginRight: 0,
                },
            },
        },
        vertical: {
            flexDirection: "column",
            "> *:not(span)": {
                marginBottom: props.theme.space[space],
                "&:last-child": {
                    marginBottom: 0,
                },
            },
        },
    };

    return toolbarDirections[props.direction];
};

const SpacedBox = styled(Box)({}, toolbarDirection);

const Toolbar = React.forwardRef<HTMLElement, ToolbarProps>(
    ({ direction, children, boxShadowSize, prominent, space, size, ...props }, ref) => {
        let hasChildren = Array.isArray(children) ? children.findIndex(o => !!o) >= 0 : !!children;
        return (
            <Card
                ref={ref}
                css={(theme: any) => ({
                    background: 0,
                    zIndex: 1,
                    "&::after": {
                        position: "absolute",
                        content: "''",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: theme.colors.background,
                        opacity: prominent ? 1 : 0.95,
                        borderRadius: "inherit",
                        zIndex: -1,
                    },
                    "&:empty": {
                        display: "none",
                    },
                })}
                height={direction === "horizontal" ? size ?? theme.space[6] : "auto"}
                width={direction === "vertical" ? size ?? theme.space[6] : "auto"}
                px={direction === "horizontal" ? 2 : 2}
                py={direction === "vertical" ? 3 : 2}
                boxShadowIntensity={prominent ? 1 : 0}
                boxShadowSize={boxShadowSize}
                prominent={prominent}
                {...props}>
                {hasChildren && (
                    <SpacedBox direction={direction} fullWidth fullHeight space={space}>
                        {children}
                    </SpacedBox>
                )}
            </Card>
        );
    }
);

Toolbar.defaultProps = {
    direction: "horizontal",
    boxShadowSize: "m",
    prominent: false,
    space: 3,
    borderRadius: 4,
};

export const OverlayToolbar = styled(Toolbar)`
    &::after {
        ${props => overlayStyles(props)}
    }
`;

export default Toolbar;

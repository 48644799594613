import styled from "@emotion/styled";
import { compose, grid, system, GridProps as SystemGridProps } from "styled-system";

import { shouldForwardProp } from "./utils/shouldForwardProp";

import { containerSystem, ContainerProps, containerStyles } from "./containerProps";

// Casting this as any to get around styled-system TS definitions not being correct
const placeItems: any = {
    property: "placeItems",
};
const gridPlaceItems = system({ placeItems });
export const gridSystem = compose(gridPlaceItems, containerSystem);

export interface IGridProps {
    placeItems?: any;
}

export type GridProps = IGridProps & ContainerProps & SystemGridProps;

export const Grid = styled("div", { shouldForwardProp })<GridProps>(
    {
        display: "grid",
        boxSizing: "border-box",
    },
    containerStyles,
    compose(gridSystem, grid)
);

Grid.displayName = "Grid";

Grid.defaultProps = {
    color: "foreground",
};

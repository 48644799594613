/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import {
    CaretLeftIcon,
    CaretRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
    PauseIcon,
    PlayIcon,
} from "@radix-ui/react-icons";
import { Box, Heading } from "../primitives";
import { theme } from "../../design";
import { AnimatePresence } from "framer-motion";
import React, { FunctionComponent, useCallback, useState } from "react";
import { addGameTime, setGameTimeSpeed, tick } from "../../actions/session";
import { Campaign, msPerMinute, msPerHour } from "../../store";
import { resolveTime, useGameTime } from "../common";
import { useDispatch, useSession } from "../contexts";
import { defaultInitial, defaultAnimate, defaultExit, MotionBox, MotionToolbar, MotionText } from "../motion";
import { ToolbarButton } from "../Toolbar";

export const TimeTracker: FunctionComponent<{ campaign: Campaign }> = ({ campaign }) => {
    const { session } = useSession();
    const [isPointerOver, setIsPointerOver] = useState(false);
    const dispatch = useDispatch();

    // Send a tick action through the tree every time we tick.
    // This is the pulse that enables time based events to be handled.
    const campaignId = session.campaign.id;
    const onTick = useCallback(() => {
        dispatch(tick(campaignId));
    }, [campaignId, dispatch]);

    const gameTime = useGameTime(onTick);
    const { days, hours, minutes, seconds } = resolveTime(gameTime);
    return (
        <MotionBox
            layout
            initial={defaultInitial}
            animate={defaultAnimate}
            exit={defaultExit}
            onPointerEnter={() => setIsPointerOver(true)}
            onPointerLeave={() => setIsPointerOver(false)}
            flexDirection="column"
            alignItems="flex-start">
            <Box justifyContent="flex-start">
                <Heading as="h4">Day {days}</Heading>
                <Heading as="h6" ml={2}>
                    {hours}:{(minutes < 10 ? "0" : "") + minutes}:{(seconds < 10 ? "0" : "") + seconds}
                </Heading>
            </Box>
            <Box position="relative" mt={1}>
                <MotionBox
                    position="absolute"
                    top={0}
                    left={0}
                    animate={
                        isPointerOver && session.combatLocation == null
                            ? { y: -theme.space[2], opacity: 0 }
                            : { y: 0, opacity: 1 }
                    }>
                    <AnimatePresence mode="popLayout">
                        {session.time.speed === 0 && session.combatLocation == null && (
                            <MotionText
                                key="paused"
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                PAUSED
                            </MotionText>
                        )}
                        {session.combatLocation != null && (
                            <MotionText
                                key="combat"
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                COMBAT
                            </MotionText>
                        )}
                    </AnimatePresence>
                </MotionBox>
                <MotionToolbar
                    direction="horizontal"
                    size={theme.space[4]}
                    animate={
                        isPointerOver && session.combatLocation == null
                            ? { y: 0, opacity: 1 }
                            : { y: -theme.space[2], opacity: 0 }
                    }>
                    <ToolbarButton
                        tooltip="-1 hour"
                        tooltipDirection="down"
                        onClick={() => {
                            dispatch(addGameTime(campaign, -msPerHour));
                            dispatch(tick(campaign));
                        }}>
                        <DoubleArrowLeftIcon />
                    </ToolbarButton>
                    <ToolbarButton
                        tooltip="-10 minutes"
                        tooltipDirection="down"
                        onClick={() => {
                            dispatch(addGameTime(campaign, -msPerMinute * 10));
                            dispatch(tick(campaign));
                        }}>
                        <ChevronLeftIcon />
                    </ToolbarButton>
                    <ToolbarButton
                        tooltip="-1 minute"
                        tooltipDirection="down"
                        onClick={() => {
                            dispatch(addGameTime(campaign, -msPerMinute));
                            dispatch(tick(campaign));
                        }}>
                        <CaretLeftIcon />
                    </ToolbarButton>
                    {session.time.speed !== 0 && (
                        <ToolbarButton
                            tooltip="Pause"
                            tooltipDirection="down"
                            onClick={() => dispatch(setGameTimeSpeed(campaign, 0))}>
                            <PauseIcon />
                        </ToolbarButton>
                    )}
                    {session.time.speed === 0 && (
                        <ToolbarButton
                            tooltip="Play"
                            tooltipDirection="down"
                            onClick={() => dispatch(setGameTimeSpeed(campaign, 1))}>
                            <PlayIcon />
                        </ToolbarButton>
                    )}
                    <ToolbarButton
                        tooltip="+1 minute"
                        tooltipDirection="down"
                        onClick={() => {
                            dispatch(addGameTime(campaign, msPerMinute));
                            dispatch(tick(campaign));
                        }}>
                        <CaretRightIcon />
                    </ToolbarButton>
                    <ToolbarButton
                        tooltip="+10 minutes"
                        tooltipDirection="down"
                        onClick={() => {
                            dispatch(addGameTime(campaign, msPerMinute * 10));
                            dispatch(tick(campaign));
                        }}>
                        <ChevronRightIcon />
                    </ToolbarButton>
                    <ToolbarButton
                        tooltip="+1 hour"
                        tooltipDirection="down"
                        onClick={() => {
                            dispatch(addGameTime(campaign, msPerHour));
                            dispatch(tick(campaign));
                        }}>
                        <DoubleArrowRightIcon />
                    </ToolbarButton>
                </MotionToolbar>
            </Box>
        </MotionBox>
    );
};

import { PayloadAction } from "../actions/common";
import { AnyAction, Action } from "redux";
import { DeepPartial, WithOverride } from "../common";

type KeysOfType<T, TProp> = { [P in keyof T]: T[P] extends TProp ? P : never }[keyof T];

export function reduceAsyncAction<U = any, T = any>(
    type: any,
    state: T,
    action: PayloadAction<U>,
    progressMessage: KeysOfType<T, boolean>,
    success: (action: PayloadAction<U>) => Partial<T>,
    error?: (action: PayloadAction<U>) => Partial<T>,
    start?: (action: PayloadAction<U>) => Partial<T>
) {
    if (action.type === type + ":start") {
        let newState: Partial<T> = start ? start(action) : {};
        newState[progressMessage] = true as any;
        return copyState(state, newState);
    } else if (action.type === type + ":success") {
        let newState = success(action);
        newState[progressMessage] = false as any;
        return copyState(state, newState);
    } else if (action.type === type + ":error") {
        // TODO: More error handling. Throw? Error callback?
        if (action.error) {
            console.error(action.error);
        }

        let newState: Partial<T> = error ? error(action) : {};
        newState[progressMessage] = false as any;
        return copyState(state, newState);
    }

    return state;
}

export function applyOverrides<T extends { id: string }, U extends T>(
    item: T,
    overrides: { [id: string]: DeepPartial<U> } | undefined
): WithOverride<T & DeepPartial<U>> {
    let override: DeepPartial<U>;
    if (!overrides || !(override = overrides[item.id])) {
        return item as T & DeepPartial<U>;
    }

    return mergeState<WithOverride<T & DeepPartial<U>>>(item as T & DeepPartial<U>, override as any, {
        withoutOverride: item,
        overrideDelta: override,
    });
}

// TODO: Should exclude arrays as well as non-objects here.
export type MergePlan<T> = Partial<{
    [P in keyof Required<T> as Required<T>[P] extends object ? P : never]: boolean | MergePlan<Required<T>[P]>;
}>;

export function mergeState<T>(state: T, delta: DeepPartial<T>, initial?: object, mergePlan?: MergePlan<T>) {
    if (state == null) {
        console.warn("mergeState - state is null or undefined.");
        throw new Error("State cannot be null or undefined.");
    } else if (delta == null) {
        console.warn("mergeState - delta is null or undefined.");
        return state;
    }

    const finalDelta: Partial<T> = initial ?? {};

    for (let k in delta) {
        const key = k as string;
        if (state[key] !== delta[key]) {
            if (mergePlan && mergePlan[k as any] != null && !mergePlan[k as any]) {
                // This key is turned off in the merge plan, so we just copy over the old value no matter what.
                finalDelta[key] = delta[k] as any;
            } else {
                // This key isn't turned off in the merge plan, but it might still
                const s = state[key];
                const d = delta[key];

                if (
                    s &&
                    d &&
                    typeof s === "object" &&
                    typeof d === "object" &&
                    !Array.isArray(s) &&
                    !Array.isArray(d)
                ) {
                    var keyMergePlan = mergePlan ? mergePlan[k as any] : undefined;
                    finalDelta[key] = mergeState(
                        state[key],
                        d!,
                        undefined,
                        typeof keyMergePlan === "object" ? keyMergePlan : undefined
                    );
                } else {
                    finalDelta[key] = delta[k] as any;
                }
            }
        }
    }

    return copyState(state, finalDelta);
}

export function copyState<T>(state: T, delta: Partial<T>) {
    if (state == null) {
        console.warn("copyState - state is null or undefined.");
    } else if (delta == null) {
        console.warn("copyState - delta is null or undefined.");
    }

    // If the properties on the delta aren't actually different (shallow check), then just return the same state.
    let isChange = false;
    let toRemove: string[] | undefined;
    for (let k in delta) {
        if (state[k] !== delta[k] || state[k] === undefined) {
            isChange = true;

            if (delta[k] === undefined) {
                if (!toRemove) {
                    toRemove = [k];
                } else {
                    toRemove.push(k);
                }

                delete delta[k];
            }
        }
    }

    if (!isChange) {
        return state;
    }

    var r = Object.assign({}, state, delta);

    if (toRemove) {
        for (let i = 0; i < toRemove.length; i++) {
            delete r[toRemove[i]];
        }
    }

    return r;
}

export function reduceDictionary<T, A extends Action = AnyAction>(
    dictionary: { [id: string]: T } | undefined,
    reducer: (state: T | undefined, action: A) => T | undefined,
    action: A,
    keys?: string[]
): typeof dictionary {
    let newDictionary: typeof dictionary | undefined = undefined;
    if (keys) {
        for (let i = 0; i < keys.length; i++) {
            let oldState = dictionary ? dictionary[keys[i]] : undefined;
            let state = reducer(oldState, action);
            if (state !== oldState) {
                if (!newDictionary) {
                    newDictionary = Object.assign({}, dictionary);
                }

                if (state !== undefined) {
                    newDictionary[keys[i]] = state;
                } else {
                    delete newDictionary[keys[i]];
                }
            }
        }
    } else if (dictionary) {
        for (const key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {
                let oldState = dictionary[key];
                let state = reducer(oldState, action);
                if (state !== oldState) {
                    if (!newDictionary) {
                        newDictionary = Object.assign({}, dictionary);
                    }

                    if (state !== undefined) {
                        newDictionary[key] = state;
                    } else {
                        delete newDictionary[key];
                    }
                }
            }
        }
    }

    return newDictionary ? newDictionary : dictionary;
}

import lunr from "lunr";

export interface SearchResult<T, K> {
    id: string;
    ranking: number;
    originalItem: T;
    item: K;
}

export interface LocalSearchableFieldConfig<T> {
    name: string;
    boost?: number;
    extractor?: (doc: T) => string | object | object[];
}

export interface LocalSearchableConfig<T, K> {
    /**
     * The field whose value uniquely identifies an object in the collection.
     */
    idField: keyof T & string;

    /**
     * Specifies the fields of the objects that can be searched.
     */
    searchableFields: ((keyof T & string) | LocalSearchableFieldConfig<T>)[];

    /**
     * Converts an ID (the value of the idField field) to a search result.
     */
    toResult: (object: T) => K;

    getObjectConfig?: (o: T) => { boost?: number } | undefined;
}

function addWildcards(token: lunr.Token) {
    return token.update(str => "*" + str + "*");
}

lunr.Pipeline.registerFunction(addWildcards, "Wildcards before & after");

/**
 * Allows full text search of a collection of JSON objects.
 */
export class LocalSearchable<T extends object, K = T> {
    private readonly _index: lunr.Index;
    private readonly _config: LocalSearchableConfig<T, K>;
    private readonly _items: { [id: string]: T };

    /**
     * Creates a new local searchable.
     * @param objects The collection of objects to search.
     * @param config The search configuration specifying how the objects can be searched.
     */
    constructor(objects: T[], config: LocalSearchableConfig<T, K>) {
        this._config = config;
        this._items = {};
        this._index = lunr(o => {
            o.ref(config.idField);

            // Include wildcards before and after any search term.
            o.searchPipeline.add(addWildcards);

            for (let i = 0; i < config.searchableFields.length; i++) {
                const field = config.searchableFields[i];
                if (typeof field === "string") {
                    o.field(field);
                } else {
                    const fieldConfig = field as LocalSearchableFieldConfig<T>;
                    o.field(fieldConfig.name, { boost: fieldConfig.boost, extractor: fieldConfig.extractor as any });
                }
            }

            for (let i = 0; i < objects.length; i++) {
                o.add(objects[i], config.getObjectConfig ? config.getObjectConfig(objects[i]) : undefined);
                this._items[objects[i][config.idField] as unknown as string] = objects[i];
            }
        });
    }

    search(query: string): SearchResult<T, K>[] {
        const results = this._index.search(query);
        return results.map(o => {
            return {
                id: o.ref,
                ranking: o.score,
                originalItem: this._items[o.ref],
                item: this._config.toResult(this._items[o.ref]),
            };
        });
    }

    searchAsync(query: string) {
        try {
            return Promise.resolve(this.search(query));
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

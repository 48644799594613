/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Box, Text, Select, Checkbox, Heading, InOverlayCard } from "../../../../components/primitives";
import {
    ResolvedCharacter,
    ItemChoiceOption,
    ItemChoice,
    ItemChoiceOptionItem,
    ItemChoiceOptionCurrency,
} from "../../creature";
import { LobotomizedBox } from "../../../../components/common";
import {
    ResolvedItem,
    resolveItem,
    ItemType,
    NamedItem,
    ItemStore,
    filterItems,
    ItemFilter,
    isPack,
    unresolveItem,
    getItemsInInventory,
} from "../../items";
import { Button } from "../../../../components/Button";
import { DnD5ECharacterTemplate, DnD5ECharacterToken, NamedRuleRef } from "../../common";
import { ItemList } from "../ItemInfo";
import { useRules } from "../hooks";
import { useDispatch, useLocation } from "../../../../components/contexts";
import { DiceRollLogEntry } from "../../../../store";
import {
    AnimatedListItem,
    defaultInitial,
    defaultAnimate,
    defaultExit,
    MotionLobotomizedBox,
    MotionBox,
} from "../../../../components/motion";
import { AnimatePresence } from "framer-motion";
import { addItems, modifyCurrency } from "../../actions/inventory";
import { Markdown } from "../../../../components/markdown";
import { ScrollableTest } from "../../../../components/ScrollableTest";
import { keyedListToArray } from "../../../../common";

function getFilterLabel(itemFilter: ItemFilter) {
    if (Array.isArray(itemFilter)) {
        return itemFilter.map(o => getFilterLabel(o)).join(", ");
    }

    if (itemFilter.weaponType === "martial" && itemFilter.type?.length === 1 && itemFilter.type[0] === ItemType.Melee) {
        return "Martial melee weapon";
    } else if (
        itemFilter.weaponType === "simple" &&
        itemFilter.type?.length === 1 &&
        itemFilter.type[0] === ItemType.Melee
    ) {
        return "Simple melee weapon";
    } else if (itemFilter.weaponType === "martial") {
        return "Martial weapon";
    } else if (itemFilter.weaponType === "simple") {
        return "Simple weapon";
    }

    return "An item from the following list";
}

function getOptions(choice: ItemChoiceOptionItem, items: ItemStore): { label: string; options: ResolvedItem[] } {
    if (choice["source"]) {
        // This is a NamedRuleRef.
        // If this reference is to an item group, resolve that into individual item options.
        const itemGroup = items.groups.get(choice as NamedRuleRef);
        if (itemGroup) {
            return {
                label: itemGroup.name,
                options: keyedListToArray(itemGroup.items).map(o => resolveItem(o, items)),
            };
        }
    }

    if (choice["baseItem"]) {
        // This is a NamedItem with a baseItem.
        const namedItem = choice as NamedItem;
        const result = getOptions(namedItem.baseItem!, items);
        result.label = namedItem.name;
        return result;
    }

    if (choice["name"]) {
        // This is a NamedItem with no baseItem.
        const item = resolveItem(choice as NamedRuleRef, items);
        return {
            label: item.name,
            options: [item],
        };
    }

    // This must be an item filter.
    const options = filterItems(items.items.allResolved, choice as ItemFilter, items);
    return {
        label: getFilterLabel(choice as ItemFilter),
        options: options,
    };
}

type ItemWithQuantity = (NamedItem | NamedRuleRef) & { quantity?: number };

export const ItemChoiceOptionItemContent: FunctionComponent<{
    choice: ItemChoiceOptionItem;
    onValueChanged: (item: ItemWithQuantity[] | undefined) => void;
}> = ({ choice, onValueChanged }) => {
    const { items } = useRules();
    const options = getOptions(choice, items);

    const [selectedItems, setSelectedItems] = useState<(number | undefined)[]>([]);
    const getSelectedItemList = (newItems: (number | undefined)[]) => {
        // TODO: Detect duplicated items, return them as ItemWithQuantity with quantity specified.
        return newItems
            .map(a => (a == null ? undefined : unresolveItem(options.options[a])))
            .filter(a => a != null) as ItemWithQuantity[];
    };

    const quantity = choice.quantity != null ? choice.quantity : 1;

    // If there is only a single option, then it's auto selected and we call onValueChanged.
    useEffect(() => {
        if (options.options.length === 1) {
            onValueChanged(
                options.options.map(o =>
                    choice.quantity != null && choice.quantity > 1
                        ? Object.assign(unresolveItem(o), { quantity: choice.quantity })
                        : unresolveItem(o)
                )
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choice, items, onValueChanged]); // If this generates warnings about options not being referenced, they can be ignored - options is deterministically generated from the other values.

    return (
        <Box flexDirection="column" alignItems="flex-start">
            <Text>
                {options.label}
                {quantity > 1 && ` (x${quantity})`}
            </Text>
            {options.options.length === 1 && isPack(options.options[0]) && (
                <Markdown>{options.options[0].content}</Markdown>
            )}
            {options.options.length > 1 &&
                [...Array(quantity)].map((o, i) => (
                    <Select
                        key={i}
                        minWidth="200px"
                        value={selectedItems[i]}
                        onChange={e => {
                            if (e.target.value) {
                                const value = parseInt(e.target.value, 10);
                                const newItems = selectedItems.slice();
                                newItems[i] = value;
                                setSelectedItems(newItems);
                                onValueChanged(getSelectedItemList(newItems));
                            } else {
                                const newItems = selectedItems.slice();
                                newItems[i] = undefined;
                                setSelectedItems(newItems);
                                onValueChanged(getSelectedItemList(newItems));
                            }
                        }}>
                        <option value="">- Choose an item -</option>
                        {options.options.map((o, i) => (
                            <option key={o.name} value={i}>
                                {o.name}
                            </option>
                        ))}
                    </Select>
                ))}
        </Box>
    );
};

export const ItemChoiceOptionContent: FunctionComponent<{
    choice: ItemChoiceOption;
    onValueChanged: (item: ItemWithQuantity[] | undefined) => void;
}> = ({ choice, onValueChanged }) => {
    const currentItemsRef = useMemo<{
        current: {
            items: ItemWithQuantity[] | undefined;
            onValueChanged: (item: ItemWithQuantity[] | undefined) => void;
        }[];
    }>(() => {
        return {
            current: choice.map((o, i) => ({
                items: undefined,
                onValueChanged: item => {
                    currentItemsRef.current[i].items = item;

                    const finalItems = (
                        currentItemsRef.current.map(o => o.items).filter(o => !!o) as ItemWithQuantity[][]
                    ).flat();
                    onValueChanged(finalItems.length ? finalItems : undefined);
                },
            })),
        };
    }, [choice, onValueChanged]);

    return (
        <Box flexDirection="column" alignItems="flex-start">
            {choice.map((o, i) => (
                <React.Fragment key={i}>
                    {typeof o === "number" && <Text>{o}gp.</Text>}
                    {typeof o !== "number" && (
                        <ItemChoiceOptionItemContent
                            choice={o}
                            onValueChanged={currentItemsRef.current[i].onValueChanged}
                        />
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

let itemChoiceCount = 0;
export const ItemChoiceOptionElement: FunctionComponent<{
    choice: ItemChoiceOption;
    isChecked: boolean;
    onCheckedChanged: (e: HTMLInputElement) => void;
    onValueChanged: (item: ItemWithQuantity[] | undefined) => void;
}> = ({ choice, isChecked, onCheckedChanged, onValueChanged }) => {
    return (
        <Box flexDirection="row" alignItems="flex-start">
            <Checkbox
                id={`ItemChoice${itemChoiceCount++}`}
                mr={2}
                onChange={e => onCheckedChanged(e.target)}
                checked={isChecked}
            />
            <ItemChoiceOptionContent choice={choice} onValueChanged={onValueChanged} />
        </Box>
    );
};

export const ItemChoiceElement: FunctionComponent<{
    choice: ItemChoice;
    onValueChanged: (item: ItemWithQuantity[] | undefined) => void;
}> = ({ choice, onValueChanged }) => {
    const [checkedIndex, setCheckedIndex] = useState<number>();
    const valuesRef = useMemo<{
        current: {
            value: ItemWithQuantity[] | undefined;
            onCheckedChanged: (e: HTMLInputElement) => void;
            onValueChanged: (item: ItemWithQuantity[] | undefined) => void;
        }[];
        onValueChanged?: (item: ItemWithQuantity[] | undefined) => void;
        checkedIndex?: number;
    }>(
        () => ({
            current: choice.map((o, i) => ({
                value: undefined,
                onCheckedChanged: e => {
                    valuesRef.checkedIndex = e.checked ? i : undefined;
                    setCheckedIndex(valuesRef.checkedIndex);
                    valuesRef.onValueChanged!(e.checked ? valuesRef.current[i].value : undefined);
                },
                onValueChanged: items => {
                    valuesRef.current[i].value = items;
                    if (i === valuesRef.checkedIndex) {
                        valuesRef.onValueChanged!(items);
                    }
                },
            })),
        }),
        [choice]
    );
    valuesRef.onValueChanged = onValueChanged;

    return (
        <InOverlayCard p={2} borderRadius={4} flexDirection="column" alignItems="flex-start" fullWidth>
            {choice.map((o, i) => (
                <React.Fragment key={i}>
                    <ItemChoiceOptionElement
                        choice={o}
                        isChecked={checkedIndex === i}
                        onCheckedChanged={valuesRef.current[i].onCheckedChanged}
                        onValueChanged={valuesRef.current[i].onValueChanged}
                    />
                    {i < choice.length - 1 && <Box>OR</Box>}
                </React.Fragment>
            ))}
        </InOverlayCard>
    );
};

export const StartingItems: FunctionComponent<{
    title: string;
    items: ItemChoice[];
    onItemsChanged: (items: ItemWithQuantity[]) => void;
}> = ({ title, items, onItemsChanged }) => {
    const [currentItems, setCurrentItems] = useState<ItemWithQuantity[][]>([]);
    return (
        <LobotomizedBox fullWidth flexDirection="column" alignItems="stretch">
            <Heading as="h6">{title}</Heading>
            {items.map((o, i) => (
                <AnimatedListItem key={i} index={i} fullWidth>
                    <ItemChoiceElement
                        choice={o}
                        onValueChanged={a => {
                            const newItems = currentItems.slice();
                            if (a) {
                                newItems[i] = a;
                            } else {
                                delete newItems[i];
                            }

                            setCurrentItems(newItems);
                            const finalItems = newItems.filter(o => !!o).flatMap(o => o);
                            onItemsChanged(finalItems);
                        }}
                    />
                </AnimatedListItem>
            ))}
        </LobotomizedBox>
    );
};

export const EquipmentPage: FunctionComponent<{
    token: DnD5ECharacterTemplate | DnD5ECharacterToken;
    resolvedCharacter: ResolvedCharacter;
}> = ({ token, resolvedCharacter }) => {
    const dispatch = useDispatch();
    const { campaign, location, api } = useLocation();

    // TODO: Do starting items for background/class separately.
    const rules = useRules();
    const initialClass = Object.values(resolvedCharacter.classes).find(o => o.isInitial);
    const classItems = initialClass?.classData.startingItems;
    const backgroundItems = resolvedCharacter.background?.items;

    const [currentClassItems, setCurrentClassItems] = useState<(ItemWithQuantity | ItemChoiceOptionCurrency)[]>([]);
    const [currentBackgroundItems, setCurrentBackgroundItems] = useState<
        (ItemWithQuantity | ItemChoiceOptionCurrency)[]
    >([]);

    const [startingChoice, setStartingChoice] = useState<"equipment" | "gold">();
    const [startingGoldRoll, setStartingGoldRoll] = useState<DiceRollLogEntry>();

    const inventoryItems = getItemsInInventory(resolvedCharacter.inventory);
    const isEmpty = !inventoryItems.length && resolvedCharacter.currency.total === 0;
    return (
        <ScrollableTest fullWidth fullHeight minimal px={3}>
            <Box mb={3} flexDirection="column" fullWidth>
                <AnimatePresence mode="wait">
                    {isEmpty && (
                        <React.Fragment>
                            {startingChoice == null && (
                                <MotionLobotomizedBox
                                    key="startingchoice"
                                    flexDirection="row"
                                    justifyContent="flex-start"
                                    initial={defaultInitial}
                                    animate={defaultAnimate}
                                    exit={defaultExit}
                                    mt={2}
                                    pr={3}>
                                    <Button onClick={() => setStartingChoice("equipment")}>EQUIPMENT</Button>
                                    <Text>or</Text>
                                    <Button onClick={() => setStartingChoice("gold")}>GOLD</Button>
                                </MotionLobotomizedBox>
                            )}
                            {startingChoice === "equipment" && (
                                <LobotomizedBox
                                    key="equipment"
                                    fullWidth
                                    flexDirection="column"
                                    alignItems="stretch"
                                    pr={3}>
                                    {classItems && (
                                        <StartingItems
                                            title={`${initialClass?.classData.name} Starting Equipment`}
                                            items={classItems}
                                            onItemsChanged={setCurrentClassItems}
                                        />
                                    )}
                                    {backgroundItems && (
                                        <StartingItems
                                            title={`${resolvedCharacter.background!.name} Starting Equipment`}
                                            items={backgroundItems}
                                            onItemsChanged={setCurrentBackgroundItems}
                                        />
                                    )}
                                    <Button
                                        disabled={currentClassItems.length + currentBackgroundItems.length === 0}
                                        alignSelf="flex-start"
                                        variant="primary"
                                        onClick={e => {
                                            const itemsToAdd = [...currentClassItems, ...currentBackgroundItems].map(
                                                o => {
                                                    if (typeof o === "number") {
                                                        return o;
                                                    }

                                                    const item = resolveItem(o, rules.items);
                                                    return item;
                                                }
                                            );

                                            dispatch(addItems(campaign, location, [token], { items: itemsToAdd }));
                                        }}>
                                        Add starting equipment
                                    </Button>
                                </LobotomizedBox>
                            )}
                            {startingChoice === "gold" && initialClass && (
                                <LobotomizedBox key="gold" flexDirection="column">
                                    <Text>{initialClass.classData.name} starting gold</Text>
                                    {initialClass.classData.startingGold.replace("*", "×")}
                                    {startingGoldRoll && <Text fontSize={4}>{startingGoldRoll.result} GP</Text>}
                                    <LobotomizedBox flexDirection="row">
                                        <Button
                                            onClick={async () => {
                                                const roll = await api.roll(initialClass.classData.startingGold);
                                                setStartingGoldRoll(await roll.confirmed);
                                            }}>
                                            ROLL
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                dispatch(
                                                    modifyCurrency(campaign, location, [token], {
                                                        GP: startingGoldRoll!.result!,
                                                    })
                                                );
                                            }}>
                                            ADD
                                        </Button>
                                    </LobotomizedBox>
                                </LobotomizedBox>
                            )}
                        </React.Fragment>
                    )}
                    {!isEmpty && (
                        <MotionBox fullWidth initial={defaultInitial} animate={defaultAnimate} exit={defaultExit}>
                            <ItemList items={inventoryItems} token={token} character={resolvedCharacter} />
                        </MotionBox>
                    )}
                </AnimatePresence>
            </Box>
        </ScrollableTest>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { useLocation, useDispatch } from "../../../components/contexts";
import { PercentageBar } from "../../../components/PercentageBar";
import { Toggle } from "../../../components/Toggle";
import { Text } from "../../../components/primitives";
import { Box } from "../../../components/primitives";
import { isCharacter, isMonster, MonsterAbility, ResolvedCharacter, ResolvedMonster } from "../creature";
import { DnD5EToken } from "../common";
import { defaultInitial, defaultAnimate, defaultExit, MotionBox } from "../../../components/motion";
import { isLocation } from "../../../store";
import { clearCombatResource, markCombatResource, setSurprised } from "../actions/token";
import { AnimatePresence } from "framer-motion";
import { theme } from "../../../design";
import { keyedListToArray } from "../../../common";
import SurprisedIcon from "../../../components/icons/Surprised";
import { Button } from "../../../components/Button";

function getAttackInfo(creature: ResolvedCharacter | ResolvedMonster): {
    maxAttacks?: number;
    currentAttacks: number;
} {
    const current = creature.combatTurn?.attacks
        ? Object.values(creature.combatTurn.attacks).reduce((p, c) => p + c, 0)
        : 0;
    if (isCharacter(creature)) {
        return { maxAttacks: creature.attacks, currentAttacks: current };
    } else {
        const multiattackOptionKey = creature.combatTurn?.attackOption;
        if (multiattackOptionKey) {
            const multiattackAbility = keyedListToArray(creature.abilities)?.find(
                o => o.attackOptions?.[multiattackOptionKey] != null
            ) as MonsterAbility;
            if (multiattackAbility) {
                const multiattackOption = multiattackAbility.attackOptions![multiattackOptionKey]!;

                const attacks = keyedListToArray(multiattackOption);
                let attackCount = 0;
                for (let attack of attacks) {
                    attackCount += attack.amount ?? 1;
                }

                return { maxAttacks: attackCount, currentAttacks: current };
            }
        }

        return { currentAttacks: current };
    }
}

export const CombatResources: FunctionComponent<{
    token: DnD5EToken;
    creature: ResolvedCharacter | ResolvedMonster;
}> = ({ token, creature }) => {
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();

    let renderCombatResourceSlot = (resource: "action" | "bonus" | "reaction", label: string) => {
        const isUsed = creature?.combatTurn?.[resource];
        return (
            <MotionBox key={resource} layout flexDirection="column" alignItems="flex-start">
                <Text>{label}</Text>
                <Toggle
                    mr={2}
                    toggled={isUsed}
                    disabled={
                        !isLocation(location) ||
                        (resource === "reaction" && !creature.canTakeReactions) ||
                        (resource !== "reaction" && !creature.canTakeActions)
                    }
                    onClick={() => {
                        if (isUsed) {
                            dispatch(clearCombatResource(campaign, location!, [token], resource));
                        } else {
                            dispatch(markCombatResource(campaign, location!, [token], resource));
                        }
                    }}
                />
            </MotionBox>
        );
    };

    const { maxAttacks, currentAttacks } = getAttackInfo(creature);
    const maxLegendary = isMonster(creature) ? creature.legendaryActions : undefined;
    return (
        <React.Fragment>
            {renderCombatResourceSlot("action", "Action")}
            {renderCombatResourceSlot("bonus", "Bonus")}
            {renderCombatResourceSlot("reaction", "Reaction")}

            <AnimatePresence>
                {maxAttacks != null && creature.combatTurn?.attacks != null && (
                    <MotionBox
                        key="attacks"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        flexDirection="column"
                        pl={2}
                        alignItems="flex-start">
                        <Text>Attacks</Text>
                        <PercentageBar
                            size="s"
                            total={maxAttacks}
                            complete={currentAttacks}
                            dangerThreshold={0}
                            warningThreshold={100}
                            heading={
                                <Box>
                                    <Text as="span" fontWeight="bold" css={{ lineHeight: theme.lineHeights[3] }}>
                                        {maxAttacks - currentAttacks} / {maxAttacks}
                                    </Text>
                                    <Text as="span" ml={1} css={{ lineHeight: theme.lineHeights[3] }}>
                                        remaining
                                    </Text>
                                </Box>
                            }
                        />
                    </MotionBox>
                )}

                {maxLegendary != null && (
                    <MotionBox
                        key="legendary"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        flexDirection="column"
                        pl={2}
                        alignItems="flex-start">
                        <Text>Legendary actions</Text>
                        <PercentageBar
                            size="s"
                            total={maxLegendary}
                            complete={creature.combatTurn?.legendary ?? 0}
                            dangerThreshold={0}
                            warningThreshold={100}
                            heading={
                                <Box>
                                    <Text as="span" fontWeight="bold" css={{ lineHeight: theme.lineHeights[3] }}>
                                        {maxLegendary - (creature.combatTurn?.legendary ?? 0)} / {maxLegendary}
                                    </Text>
                                    <Text as="span" ml={1} css={{ lineHeight: theme.lineHeights[3] }}>
                                        remaining
                                    </Text>
                                </Box>
                            }
                        />
                    </MotionBox>
                )}

                {(creature.combatTurn?.surprised ||
                    (isLocation(location) &&
                        (location.combat?.round === 0 || (location.combat && location.combat.round == null)))) && (
                    <MotionBox layout>
                        <Button
                            ml={2}
                            tooltip="Surprised"
                            tooltipDirection="right"
                            toggled={creature.combatTurn?.surprised}
                            shape="square"
                            onClick={() => {
                                dispatch(setSurprised(campaign, location, [token], !creature.combatTurn?.surprised));
                            }}>
                            <SurprisedIcon />
                        </Button>
                    </MotionBox>
                )}
            </AnimatePresence>
        </React.Fragment>
    );
};

import { ZoneAction } from "../actions/common";
import { Zone } from "../store";
import { copyState } from "./common";

const zoneReducer = (state: Zone | undefined, action: ZoneAction) => {
    if (action.type === "ModifyZone") {
        return copyState(state, action.payload);
    }

    return state;
};

export default zoneReducer;

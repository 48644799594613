import React, { FunctionComponent, useState, useCallback } from "react";
import { localPoint } from "../../grid";
import { theme } from "../../design";
import { LocalPixelPosition } from "../../position";
import { DraggableRect } from "./three2d/Rect";
import { useScale } from "../contexts";
import { ThreeEvent } from "@react-three/fiber";
import { ZIndexes } from "./common";
import { Mesh } from "three";

const handleSize = theme.space[3];

interface AnnotationHandleProps {
    x: number;
    y: number;
    offsetX?: number;
    offsetY?: number;
    rotation?: number;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onDragMove: (point: LocalPixelPosition) => void;
    onDragEnd: () => void;
    dragBoundFunc?: (current: LocalPixelPosition, next: LocalPixelPosition) => LocalPixelPosition;
    disableDrag?: boolean;
    color: string;
    cursor?: string;
    zIndex?: ZIndexes;
    noAnimateEnter?: boolean;
    noAnimateExit?: boolean;
}

export const AnnotationHandle: FunctionComponent<AnnotationHandleProps> = React.forwardRef<Mesh, AnnotationHandleProps>(
    (
        {
            x,
            y,
            offsetX,
            offsetY,
            rotation,
            snapPoint,
            onDragMove,
            onDragEnd,
            dragBoundFunc,
            disableDrag,
            color,
            cursor,
            zIndex,
            noAnimateEnter,
            noAnimateExit,
        },
        ref
    ) => {
        const [lastDragPos, setLastDragPos] = useState(undefined as LocalPixelPosition | undefined);
        const scale = useScale();

        const dragEnd = useCallback(() => {
            setLastDragPos(undefined);
            onDragEnd();
        }, [onDragEnd]);

        const dragMove = useCallback(
            (e: ThreeEvent<PointerEvent>, pos: LocalPixelPosition) => {
                pos = snapPoint(pos);
                const dragPoint = dragBoundFunc
                    ? dragBoundFunc(
                          lastDragPos
                              ? lastDragPos
                              : localPoint(x + (offsetX == null ? 0 : offsetX), y + (offsetY == null ? 0 : offsetY)),
                          pos
                      )
                    : pos;
                setLastDragPos(dragPoint);
                onDragMove(dragPoint);
            },
            [dragBoundFunc, snapPoint, onDragMove, lastDragPos, x, y, offsetX, offsetY]
        );

        return (
            <DraggableRect
                name="AnnotationHandle"
                ref={ref}
                x={x}
                y={y}
                offsetX={offsetX}
                offsetY={offsetY}
                width={handleSize}
                height={handleSize}
                color={color}
                rotation={rotation}
                cursor={disableDrag ? undefined : cursor ?? "pointer"}
                scale={1 / scale}
                zIndex={zIndex ?? ZIndexes.PriorityInterface}
                disableDrag={disableDrag}
                animateEnterExit
                initial={noAnimateEnter ? null : { scale: 0, opacity: 1 }}
                exit={noAnimateExit ? null : { scale: 0, opacity: 1 }}
                onDragEnd={dragEnd}
                onDragMove={dragMove}
            />
        );
    }
);

import React, {
    FunctionComponent,
    useState,
    useRef,
    useCallback,
    useEffect,
    Suspense,
    useMemo,
    useLayoutEffect,
} from "react";
import { PositionType, LocalPixelPosition, GridPosition, LocalRect } from "../../position";
import { Annotation } from "../../annotations";
import {
    Token,
    Location,
    IGameSystem,
    LogEntry,
    shouldShowNotification,
    DiceRollLogEntry,
    getTokenType,
    WithLevel,
    ResolvedToken,
    defaultSoundRadius,
} from "../../store";
import {
    useDispatch,
    useValidatedLocation,
    useLocalGrid,
    useRole,
    useCampaign,
    useTokenOverrides,
    useSession,
    useClipper,
    useValidatedLocationLevel,
    usePathFinding,
    useScale,
    useCamera,
} from "../contexts";
import {
    localPoint,
    distanceBetween,
    intersectPath,
    angle,
    ILocalGrid,
    getCenterPoint,
    degToRad,
    pathsEqual,
    pointsEqual,
    getBounds,
    intersectRect,
    gridPoint,
} from "../../grid";
import { theme } from "../../design";
import { getThemeColor, getPlayerColorPalette, getThemeColorVector, getThemeColorPalette } from "../../design/utils";
import { modifyToken, moveToken } from "../../actions/token";
import { endCanvasDrag, startCanvasDrag, hasDropOffer, useKeyboardShortcut, useFileUrlWithProgress } from "../utils";
import { Line } from "./three2d/Line";
import { Circle, DraggableCircle } from "./three2d/Circle";
import { withDragEvents } from "./three2d/dragevents";
import {
    commonShaderFunctions,
    commonShaderUniforms,
    RenderOrder,
    segmentsToClipperPoly,
    TokenWithLocalState,
    VttCameraLayers,
    ZIndexes,
} from "./common";
import { DistanceMessage } from "./DistanceMessage";
import { Vector2, Vector4, TextureLoader, Mesh, Texture, EllipseCurve, Box3, Material } from "three";
import { VttBuildMode, VttMode, useVttApp } from "../common";
import { ThreeEvent, useFrame, useLoader, useThree } from "@react-three/fiber";
import { PointerEventsProps, PositionProps } from "./three2d/common";
import { applyOverrides } from "../../reducers/common";
import tinycolor from "tinycolor2";
import { PathResult } from "../../astar";
import { UiLayer } from "./UiLayer";
import { HtmlAdorner } from "./HtmlAdorner";
import { Box, Text } from "../primitives";
import {
    defaultInitial,
    defaultAnimate,
    defaultExit,
    defaultMotionScale,
    MotionBox,
    MotionToolbar,
    AnimatePresenceRepeater,
    animateSequence,
} from "../motion";
import { ToolbarButton } from "../Toolbar";
import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
import {
    motion,
    AnimatePresence,
    useMotionValue,
    usePresence,
    animate,
    useIsPresent,
    Transition,
    MotionValue,
} from "framer-motion";
import { motion as motion3d } from "framer-motion-3d";
import styled from "@emotion/styled";
import { getSegmentsForSource, lightTemplates } from "./Lighting";
import { useRenderedLocationLevel } from "./contexts";
import { ClipType, Path, PolyFillType } from "js-angusj-clipper";
import { Line2, STLLoader, ThreeMFLoader } from "three-stdlib";
import { cameraTransition } from "./PerspectiveCameraControl";
import { WithOverride } from "../../common";
import { Line as DreiLine } from "@react-three/drei";
import { RelativeAudioNode } from "./RelativeAudioNode";
import { flushSync } from "react-dom";
import { SelectionType } from "../selection";

const LOG_ENTRY_TIMEOUT = 5000;

// Outline width as a percentage of location tile width.
const OUTLINE_WIDTH_PCT = 0.03;

const SpeechBubble = motion(styled(Box)`
    position: relative;
    margin-bottom: ${props => props.theme.space[3]}px;
    margin-left: -${props => props.theme.space[3]}px;
    box-shadow: 0 10px 20px hsl(0deg 0% 0% / 15%), 0 3px 6px hsl(0deg 0% 0% / 10%);
    &:after {
        content: "";
        position: absolute;
        bottom: -${props => props.theme.space[3]}px;
        left: ${props => props.theme.space[3]}px;
        width: 0;
        border-width: ${props => props.theme.space[3]}px ${props => props.theme.space[3]}px 0 0;
        border-style: solid;
        border-color: var(--c-grayscale-8, #f6f6f7) transparent;
    }
`);

const SpeechLogEntry: FunctionComponent<{
    token: Token;
    logEntry: LogEntry;
    system: IGameSystem;
}> = ({ token, logEntry, system }) => {
    if (logEntry.type === "roll") {
        const roll = logEntry as DiceRollLogEntry;
        return (
            <Box flexDirection="column" p={2} fullWidth alignItems="flex-start" minWidth="20rem">
                {system.renderLogHeader && system.renderLogHeader(roll, token)}
                <Text fontWeight="bold">{roll.expression}</Text>
                <Box alignItems="flex-start" flexDirection="row">
                    <Text mr={3} mt={2} fontSize={5}>
                        {roll.result}
                    </Text>
                    <Box flexDirection="row" justifyContent="flex-start" css={{ flexWrap: "wrap", flexShrink: 1 }}>
                        {roll.terms.map((t, j) => {
                            return (
                                <Box
                                    minWidth="5rem"
                                    minHeight="5rem"
                                    key={j}
                                    css={{ opacity: t.isExcluded ? 0.3 : 1 }}
                                    bg="grayscale.6"
                                    borderRadius={3}
                                    mt={2}
                                    mr={2}>
                                    {t.result}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
        );
    }

    // TODO: Other message types.
    return <React.Fragment></React.Fragment>;
};

interface TokenAudioNodeProps {
    token: ResolvedToken;
    audioListenerPos: WithLevel<LocalPixelPosition>;
}

const TokenAudioNode: FunctionComponent<TokenAudioNodeProps> = ({ token, audioListenerPos }) => {
    const grid = useLocalGrid();
    const sound = token.sound!;
    const pathFinder = usePathFinding();

    const audioListenerPosGrid = grid.toGridPoint(audioListenerPos) as WithLevel<GridPosition>;
    audioListenerPosGrid.level = audioListenerPos.level;
    const gridPoint = grid.toGridPoint(token.pos);
    const radius = sound.radius ?? defaultSoundRadius;
    if (!pathFinder || distanceBetween(gridPoint, audioListenerPosGrid) > radius + 1) {
        return null;
    }

    // The listener is within our radius, which means we should at least work out how far away they are.
    // TODO: Modify path finding so that if there are no obstructions between any grid point and the target, we just go
    // straight to the target.
    // TODO: Modify path finding algorithm so that it fully explores the explorable area and then returns the results for
    // the entire area, so that we can display the audible area for a sound.
    const path = pathFinder.findSoundPath(
        radius,
        Object.assign(gridPoint, { level: token.pos.level }),
        audioListenerPosGrid
    );
    if (path && path.path.length) {
        // We have the best path for audio, this will help determine the location of the sound.
        if (path.cost <= radius) {
            const volume = sound.volume ?? 100;

            // TODO: Should we use the last two points as the angle, instead of the from and to? Would be more accurate in some cases, but
            // because we're using grid paths it may be misleading in others (it's always from one of exactly 8 directions, for a square grid,
            // so even very close sounds horizontally/vertically might sound like they're coming from 45deg).
            const relativePos = new Vector2(
                gridPoint.x - audioListenerPosGrid.x,
                gridPoint.y - audioListenerPosGrid.y
            ).setLength(path.cost);
            return <RelativeAudioNode uri={sound.uri} volume={volume} relativePos={relativePos} radius={radius} />;
        }
    }

    return <></>;
};

interface TokenNodeProps {
    token: ResolvedToken<WithOverride<TokenWithLocalState>>;
    levelKey: string;
    isSelected: SelectionType;
    isTarget: boolean;
    isWarningTarget: boolean;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    onPointerDown?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onPointerUp?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    mode: VttMode;
    buildMode: VttBuildMode;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onOverrideToken: (id: string, override: Partial<TokenWithLocalState> | undefined) => void;
    audioListenerPos?: WithLevel<LocalPixelPosition>;
}

const TokenNodeCore: FunctionComponent<TokenNodeProps> = props => {
    const role = useRole();
    const { levelKey } = useValidatedLocationLevel();

    let tokenContent: JSX.Element | undefined;

    const token = props.token;
    if (token.type !== "creature" && (!token.imageUri || token.type === "audio" || token.type === "light")) {
        tokenContent = (
            <Suspense fallback={<></>}>
                <AnimatePresenceRepeater>
                    {role === "GM" &&
                        props.mode === "build" &&
                        props.buildMode === "tokens" &&
                        token.pos.level === levelKey && <NonImageTokenNode {...props} token={token} />}
                </AnimatePresenceRepeater>
            </Suspense>
        );
    } else if (token.type === "object") {
        tokenContent = <ObjectImageTokenNode {...props} token={token} />;
    } else {
        tokenContent = <ImageTokenNode {...props} token={token} />;
    }

    let audioContent: JSX.Element | undefined;
    if (token.sound && props.audioListenerPos != null) {
        audioContent = <TokenAudioNode token={token} audioListenerPos={props.audioListenerPos} />;
    }

    return (
        <>
            {audioContent}
            {tokenContent}
        </>
    );
};

const NonImageTokenNode: FunctionComponent<TokenNodeProps> = ({
    token,
    isSelected,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideToken,
    snapPoint,
    ...props
}) => {
    const grid = useLocalGrid();
    const { campaign, location, system } = useValidatedLocation();
    const dispatch = useDispatch();

    const texture = useLoader(
        TextureLoader,
        token.light || token.type === "light" ? "defaultlight.png" : "defaultsound.png"
    );

    const onTokenClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, token);
            }
        },
        [token, onClick]
    );

    const [isHover, setIsHover] = useState(false);

    const pos = grid.toLocalPoint(token.pos);
    const color = getThemeColor(
        isSelected === SelectionType.Primary
            ? theme.colors.guidance.focus
            : isHover
            ? theme.colors.grayscale[5]
            : theme.colors.grayscale[6]
    );
    const disableDrag = onClick == null;

    // If this token has a light, then show the exact extents of the light by working out its poly in the
    // same way that we do when rendering lighting.
    const levelInfo = useRenderedLocationLevel();
    const clipper = useClipper();
    let lightPolygon: Path | undefined = useMemo(() => {
        if (token.light && isSelected && levelInfo.segments && clipper) {
            const template = lightTemplates[token.light.type];
            const radius =
                (token.light.outerRadius ?? template?.defaults?.outerRadius ?? system.defaultLight.outerRadius) *
                location.tileSize.width;
            if (radius > 0) {
                const visibilityPolygon = segmentsToClipperPoly(getSegmentsForSource(pos, levelInfo.segments));

                const ellipseCurve = new EllipseCurve(pos.x, pos.y, radius, radius, 0, Math.PI * 2, false, 0);
                const outerRadiusPoints = ellipseCurve
                    .getPoints(64)
                    .map(o => ({ x: Math.round(o.x), y: Math.round(o.y) }));
                const clipperPolygons = clipper.clipToPaths({
                    clipType: ClipType.Intersection,
                    subjectFillType: PolyFillType.NonZero,
                    subjectInputs: [{ data: visibilityPolygon, closed: true }],
                    clipInputs: [{ data: outerRadiusPoints }],
                });
                const lp = clipperPolygons?.[0];
                if (lp) {
                    const first = lp[0];
                    const last = lp[lp.length - 1];
                    if (first.x !== last.x || first.y !== last.y) {
                        lp?.push(first);
                    }
                }

                return lp;
            }

            return undefined;
        }
    }, [
        token.light,
        isSelected,
        levelInfo.segments,
        clipper,
        pos,
        location.tileSize.width,
        system.defaultLight.outerRadius,
    ]);

    const radius = (theme.space[4] / 100) * location.tileSize.width;
    return (
        <UiLayer>
            {lightPolygon && (
                <Line
                    x={0}
                    y={0}
                    width={2}
                    points={lightPolygon}
                    color={getThemeColor(theme.colors.grayscale[5])}
                    zIndex={ZIndexes.Overlay}
                    opacity={0.2}
                    animateEnterExit
                />
            )}
            <DraggableCircle
                layers={token.isDragging ? VttCameraLayers.DefaultNoRaycasting : VttCameraLayers.Default}
                x={pos.x}
                y={pos.y}
                color={color}
                radius={radius}
                segments={24}
                zIndex={ZIndexes.UserInterface}
                onClick={onTokenClick}
                animateEnterExit
                cursor={disableDrag ? undefined : "pointer"}
                disableDrag={disableDrag}
                dragBoundFunc={v => snapPoint(v)}
                onPointerDown={onPointerDown ? e => onPointerDown(e, token) : undefined}
                onPointerUp={onPointerUp ? e => onPointerUp(e, token) : undefined}
                onPointerOver={() => setIsHover(true)}
                onPointerOut={() => setIsHover(false)}
                onDragStart={() => {
                    onOverrideToken(token.id, {
                        isDragging: true,
                    });
                }}
                onDragMove={(e, pos) => {
                    onOverrideToken(token.id, {
                        pos: Object.assign({}, pos, { level: token.pos.level }),
                        isDragging: true,
                    });
                }}
                onDragEnd={(e, pos) => {
                    dispatch(
                        modifyToken(campaign, location, token, {
                            pos: Object.assign({}, pos, { level: token.pos.level }),
                        })
                    );
                    onOverrideToken(token.id, undefined);
                }}
            />
            <motion3d.mesh
                position={[pos.x, -pos.y, ZIndexes.UserInterface / 1000 + 0.1]}
                renderOrder={ZIndexes.UserInterface + 0.1}
                layers={VttCameraLayers.DefaultNoRaycasting}
                initial={{
                    scaleX: defaultMotionScale,
                    scaleY: defaultMotionScale,
                }}
                animate={{
                    scaleX: 1,
                    scaleY: 1,
                }}
                exit={{
                    scaleX: defaultMotionScale,
                    scaleY: defaultMotionScale,
                }}>
                <motion3d.meshBasicMaterial
                    attach="material"
                    map={texture}
                    transparent
                    transition={{
                        color: { duration: 0.1 },
                    }}
                    depthWrite={false}
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        color: isSelected ? getThemeColor(theme.colors.background) : "#ffffff",
                    }}
                    exit={{ opacity: 0 }}
                />
                <planeGeometry attach="geometry" args={[radius * 1.5, radius * 1.5]} />
            </motion3d.mesh>
        </UiLayer>
    );
};

interface ObjectImageProps extends PointerEventsProps, PositionProps {
    opacity?: number;
    imageUri: string;
    scale?: number;
    zIndex?: number;
    windowOpacity?: number;
    occupiedOpacity?: number;
    visibleOpacity?: number;
    errorMessage: string | ((e: any) => string);
    rotation?: number;
    isSelected?: SelectionType;
}

const tokenImageShaderFunctions = `
vec4 outline(in sampler2D tex, in vec2 textureSize, in vec4 color, in float opacity, in float thickness, in float thicknessMax, in vec2 uv) {
    vec2 totalSize = textureSize + (thicknessMax * 2.0);

    // Convert the pixel coords to the texture coords - they're not the same as we are allowing extra space
    // around the edges for the line to expand into.
    vec2 textureUv = ((totalSize / textureSize) * uv) - (vec2(thicknessMax) / textureSize);

    vec4 base = texture2D(tex, textureUv);

    if (u_line_thickness > 0.0) {
        vec2 size = vec2(1.0 / totalSize.x, 1.0 / totalSize.y) * thickness;

        float outline = texture2D(tex, textureUv + vec2(-size.x, 0.0)).a;
        outline += texture2D(tex, textureUv + vec2(0.0, size.y)).a;
        outline += texture2D(tex, textureUv + vec2(size.x, 0.0)).a;
        outline += texture2D(tex, textureUv + vec2(0.0, -size.y)).a;
        outline += texture2D(tex, textureUv + vec2(-size.x, size.y)).a;
        outline += texture2D(tex, textureUv + vec2(size.x, size.y)).a;
        outline += texture2D(tex, textureUv + vec2(-size.x, -size.y)).a;
        outline += texture2D(tex, textureUv + vec2(size.x, -size.y)).a;
        outline = min(outline, 1.0);
        
        vec4 lineColor = vec4(color.rgb, color.a * (thickness / thicknessMax));
        vec4 mixedColor = mix(base, lineColor, max(0.0, (outline - base.a - (1.0 - lineColor.a))));
        return vec4(mixedColor.rgb, mixedColor.a * opacity);
    } else {
        return vec4(base.rgb, base.a * opacity);
    }
}
`;

const basicImageVertexShader = `
varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}`;

const maxWindows = 10;
const objectImageFragmentShader = `
${commonShaderUniforms}

uniform sampler2D u_texture;
uniform float u_texture_width;
uniform float u_texture_height;
uniform float u_opacity;
uniform float u_windowOpacity;
uniform int u_windowCount;
uniform float u_windowX[${maxWindows}];
uniform float u_windowY[${maxWindows}];
uniform float u_windowR1[${maxWindows}];
uniform float u_windowR2[${maxWindows}];

uniform bool u_hasVisionSource;
uniform float u_cutawayOpacity;
uniform sampler2D u_lighting;
uniform sampler2D u_darkvision;
uniform sampler2D u_visibility;

uniform vec4 u_line_color;
uniform float u_line_thickness;
uniform float u_line_thickness_max;
varying vec2 vUv;

${commonShaderFunctions}

${tokenImageShaderFunctions}

void main() {
    vec2 textureSize = vec2(u_texture_width, u_texture_height);
    vec4 base = outline(u_texture, textureSize, u_line_color, u_opacity, u_line_thickness, u_line_thickness_max, vUv);

    // Convert to the position relative to the entire canvas (that's what we need to read the lighting maps, which
    // are the same size as the canvas).
    float cutawayOpacity = 1.0;
    vec2 screenPoint = fragCoordToScreenPoint(gl_FragCoord.xy);
    vec2 texturePos = screenPointToTexturePoint(screenPoint);

    // Calculate the lighting level at this pixel. Mostly we want to cut away the image if there's enough light
    // to see anything underneath, but taper off at the edges where there's almost no light to see by anyway.
    float lighting = smoothstep(0.0, 0.1, max(texture2D(u_lighting, texturePos).a, texture2D(u_darkvision, texturePos).a));

    // Intersect that lighting with the visibility. Where there is light to see (or within darkvision range) AND
    // it's not blocked by anything, then we cut away that pixel and reveal the level beneath.
    vec4 visibility = texture2D(u_visibility, texturePos);
    
    // Areas where the player can see are green, areas where the player can't see but are revealed anyway are red.
    float a = 1.0;
    if (!u_hasVisionSource || visibility.g > 0.0) {
        cutawayOpacity = visibility.a * lighting * u_cutawayOpacity;

        vec2 pixelPos = fragCoordToLocalPoint(gl_FragCoord.xy);
        for (int i = 0; i < u_windowCount; i++) {
            vec2 windowPos = vec2(u_windowX[i], u_windowY[i]);
            float localDist = distance(windowPos, pixelPos);
            float near = u_windowR1[i];
            float far = u_windowR2[i];
            a = min(a, smoothstep(near, far, localDist));
        }
    }

    gl_FragColor = vec4(base.rgb, base.a * u_opacity * cutawayOpacity * min(u_opacity, max(u_windowOpacity, a)));
}`;

const ComplexObjectImageInternal: FunctionComponent<
    ObjectImageProps & {
        width?: number;
        height?: number;
        onLoaded?: (width, height) => void;
        windows?: (LocalPixelPosition & { radius: number })[];
        outlineWidth: number;
    }
> = ({
    x,
    y,
    width,
    height,
    zIndex,
    imageUri,
    rotation,
    opacity,
    scale,
    isSelected,
    onLoaded,
    windows,
    windowOpacity,
    occupiedOpacity,
    visibleOpacity,
    outlineWidth,
    ...props
}) => {
    const canvasSize = useThree(state => state.size);
    const { lighting, darkvision, visibility, hasVisionSource } = useRenderedLocationLevel();

    // TODO: Move the texture loader to a common component, so that it doesn't need to reload when we go from simple->complex image.
    // This means reimplenting Image AGAIN.
    const texture = useLoader(TextureLoader, imageUri);
    const textureRef = useRef<Texture>();
    const grid = useLocalGrid();

    if (texture !== textureRef.current) {
        textureRef.current = texture;
        if (texture && onLoaded) {
            requestAnimationFrame(() => {
                onLoaded(texture.image.width, texture.image.height);
            });
        }
    }

    const cutawayOpacity = useMotionValue(visibleOpacity ?? 1);
    useEffect(() => {
        animate(cutawayOpacity, hasVisionSource ? visibleOpacity ?? 1 : 1, {
            duration: 0.3,
        });
    }, [cutawayOpacity, hasVisionSource, visibleOpacity]);

    const uniforms = useMemo(
        () => ({
            u_texture: { type: "t", value: undefined as Texture | undefined },
            u_canvasSize: { value: undefined as Vector2 | undefined }, // The width/height of the canvas we're drawing to.
            u_scale: { value: 1 },
            u_offset: { value: undefined as Vector2 | undefined },
            u_opacity: { value: -1 },
            u_windowOpacity: { value: 1 },
            u_windowCount: { value: 0 },
            u_windowX: { value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
            u_windowY: { value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
            u_windowR1: { value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
            u_windowR2: { value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
            u_lighting: { type: "t", value: undefined as Texture | undefined },
            u_darkvision: { type: "t", value: undefined as Texture | undefined },
            u_visibility: { type: "t", value: undefined as Texture | undefined },
            u_cutawayOpacity: { value: 1.0 },
            u_hasVisionSource: { value: true },
            u_line_color: { value: undefined as Vector4 | undefined },
            u_line_thickness: { value: 0 },
            u_line_thickness_max: { value: 0 },
            u_texture_width: { value: 0 },
            u_texture_height: { value: 0 },
        }),
        []
    );

    const [isPresent, safeToRemove] = usePresence();
    const desiredOpacity = isPresent ? ((windows?.length ?? 0) > 0 ? occupiedOpacity : opacity) ?? 1 : 0;
    const opacityMotionValue = useMotionValue(0);
    useEffect(() => {
        animate(opacityMotionValue, desiredOpacity, {
            onComplete: () => !isPresent && safeToRemove?.(),
        });
    }, [desiredOpacity, isPresent, safeToRemove, opacityMotionValue]);

    uniforms.u_texture.value = texture;
    uniforms.u_windowCount.value = Math.min(maxWindows, windows?.length ?? 0);
    uniforms.u_windowOpacity.value = windowOpacity ?? 1;

    uniforms.u_lighting.value = lighting.texture;
    uniforms.u_darkvision.value = darkvision.texture;
    uniforms.u_visibility.value = visibility.texture;
    uniforms.u_canvasSize.value = new Vector2(canvasSize.width, canvasSize.height);
    uniforms.u_hasVisionSource.value = hasVisionSource;

    if (windows) {
        let hasChanged = false;
        for (let i = 0; i < uniforms.u_windowCount.value; i++) {
            const sp = grid.toLocalPoint(windows[i]);
            const r = windows[i].radius;
            if (
                uniforms.u_windowX.value[i] !== sp.x ||
                uniforms.u_windowY.value[i] !== y ||
                uniforms.u_windowR1.value[i] !== r
            ) {
                uniforms.u_windowX.value[i] = sp.x;
                uniforms.u_windowY.value[i] = sp.y;
                uniforms.u_windowR1.value[i] = r;
                uniforms.u_windowR2.value[i] = windows[i].radius * 3;
                hasChanged = true;
            }
        }

        if (hasChanged) {
            uniforms.u_windowX.value = uniforms.u_windowX.value.slice();
            uniforms.u_windowY.value = uniforms.u_windowY.value.slice();
            uniforms.u_windowR1.value = uniforms.u_windowR1.value.slice();
            uniforms.u_windowR2.value = uniforms.u_windowR2.value.slice();
        }
    }

    const outlineThickness = useMotionValue(0);
    useEffect(() => {
        animate(outlineThickness, isSelected ? outlineWidth : 0, {
            type: "tween",
            ease: "easeOut",
        });
    }, [isSelected, outlineThickness, outlineWidth]);

    // We have to update the window uniforms every frame, as we need to get the latest screen position for the
    // windows without causing the react element to rerender every time the user pans.
    useFrame(() => {
        uniforms.u_opacity.value = opacityMotionValue.get();

        const g = grid.ref.current!;
        uniforms.u_scale.value = g.scale;
        uniforms.u_offset.value = new Vector2(g.offset.x, g.offset.y);
        uniforms.u_cutawayOpacity.value = cutawayOpacity.get();

        uniforms.u_line_color.value = selectedColorVector;
        uniforms.u_texture_width.value = width ?? texture.image.width;
        uniforms.u_texture_height.value = height ?? texture.image.height;
        uniforms.u_line_thickness.value = outlineThickness.get();
        uniforms.u_line_thickness_max.value = outlineWidth;
    }, RenderOrder.BeforeMain);

    const scaleValue = scale == null ? 1 : scale;

    return (
        <motion3d.mesh
            position={[x, -y, (zIndex ?? ZIndexes.Underlay) / 1000]}
            rotation={[0, 0, rotation == null ? 0 : -degToRad(rotation)]}
            initial={{ scaleX: defaultMotionScale, scaleY: defaultMotionScale }}
            animate={{ scaleX: 1, scaleY: 1 }}
            exit={{ scaleX: defaultMotionScale, scaleY: defaultMotionScale }}>
            <mesh
                raycast={opacity == null || opacity > 0 ? Mesh.prototype.raycast : () => {}}
                scale={scaleValue}
                onPointerDown={props.onPointerDown}
                onPointerUp={props.onPointerUp}
                onPointerMove={props.onPointerMove}
                onPointerOver={props.onPointerOver}
                onPointerOut={props.onPointerOut}
                onClick={props.onClick}>
                <planeGeometry
                    attach="geometry"
                    args={[
                        (width ?? texture.image.width) + outlineWidth * 2,
                        (height ?? texture.image.height) + outlineWidth * 2,
                    ]}
                />
                <shaderMaterial
                    attach="material"
                    fragmentShader={objectImageFragmentShader}
                    vertexShader={basicImageVertexShader}
                    transparent
                    uniforms={uniforms}
                    depthWrite={false}
                />
            </mesh>
        </motion3d.mesh>
    );
};

function addWindow(
    grid: ILocalGrid,
    location: Location,
    pos: LocalPixelPosition | GridPosition,
    scale: number | undefined,
    bounds: LocalRect,
    windows: (LocalPixelPosition & { radius: number })[]
) {
    let cp: LocalPixelPosition;
    let tokenBounds: LocalRect;
    if (pos.type === PositionType.Grid) {
        cp = grid.toLocalCenterPoint(pos, scale);
        tokenBounds = getBounds(grid.toLocalPoints(pos, scale));
    } else {
        cp = pos;
        const w = location.tileSize.width * (scale ?? 1);
        const h = location.tileSize.height * (scale ?? 1);
        tokenBounds = {
            type: PositionType.LocalPixel,
            x: cp.x - w / 2,
            y: cp.y - h / 2,
            width: w,
            height: h,
        };
    }

    if (intersectRect(bounds, tokenBounds)) {
        windows.push(
            Object.assign(cp, {
                radius: (location.tileSize.width / 2) * (scale ?? 1),
            })
        );
    }
}

const ObjectImageBroken: FunctionComponent<
    Omit<ObjectImageProps, "imageUri"> & { width?: number; height?: number }
> = ({ onPointerDown, onPointerUp, onClick, ...props }) => {
    const { location } = useValidatedLocation();
    const mode = useVttApp(state => state.mode);
    const buildMode = useVttApp(state => state.buildMode);
    const texture = useLoader(TextureLoader, "brokenlink.png");
    const radius = (theme.space[4] / 100) * location.tileSize.width;
    return (
        <UiLayer>
            <AnimatePresence>
                {mode === "build" && buildMode === "base" && (
                    <React.Fragment>
                        <DraggableCircle
                            layers={VttCameraLayers.Default}
                            x={props.x}
                            y={props.y}
                            color={getThemeColor(
                                props.isSelected === SelectionType.Primary
                                    ? theme.colors.guidance.focus
                                    : theme.colors.guidance.error[1]
                            )}
                            radius={radius}
                            segments={24}
                            zIndex={ZIndexes.UserInterface}
                            disableDrag
                            cursor={onClick == null ? undefined : "pointer"}
                            onPointerDown={onPointerDown}
                            onPointerUp={onPointerUp}
                            onClick={onClick}
                            animateEnterExit
                        />
                        <motion3d.mesh
                            position={[props.x, -props.y, ZIndexes.UserInterface / 1000 + 0.1]}
                            renderOrder={ZIndexes.UserInterface + 0.1}
                            layers={VttCameraLayers.DefaultNoRaycasting}
                            initial={{
                                scaleX: defaultMotionScale,
                                scaleY: defaultMotionScale,
                            }}
                            animate={{
                                scaleX: 1,
                                scaleY: 1,
                            }}
                            exit={{
                                scaleX: defaultMotionScale,
                                scaleY: defaultMotionScale,
                            }}>
                            <motion3d.meshBasicMaterial
                                attach="material"
                                map={texture}
                                transparent
                                transition={{
                                    color: { duration: 0.1 },
                                }}
                                depthWrite={false}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    color: props.isSelected
                                        ? getThemeColor(theme.colors.background)
                                        : theme.colors.guidance.error[0],
                                }}
                                exit={{ opacity: 0 }}
                            />
                            <planeGeometry attach="geometry" args={[theme.space[4] * 1.5, theme.space[4] * 1.5]} />
                        </motion3d.mesh>
                    </React.Fragment>
                )}
            </AnimatePresence>
        </UiLayer>
    );
};

const ComplexObjectImage: FunctionComponent<
    ObjectImageProps & {
        width?: number;
        height?: number;
        onLoaded?: (width, height) => void;
    }
> = ({ imageUri, errorMessage, ...props }) => {
    const [image] = useFileUrlWithProgress(imageUri, errorMessage);
    const { campaign } = useCampaign();

    const role = useRole();
    const grid = useLocalGrid();
    const { location } = useValidatedLocation();
    const { tokenOverrides } = useTokenOverrides();
    let windows: (LocalPixelPosition & { radius: number })[] | undefined;
    if (props.zIndex != null && props.zIndex >= ZIndexes.Tokens && props.width != null && props.height != null) {
        windows = [];

        // TODO: Take into account rotation as well?

        // Shrink the bounds for overlap detection by a bit to avoid triggering it too aggressively
        // (i.e. stop it triggering when a token is in an adjacent square, or only just intersects by a few pixels).
        const finalWidthForBounds = props.width - 24;
        const finalHeightForBounds = props.height - 24;
        const bounds: LocalRect = {
            type: PositionType.LocalPixel,
            x: props.x - finalWidthForBounds / 2,
            y: props.y - finalHeightForBounds / 2,
            width: finalWidthForBounds,
            height: finalHeightForBounds,
        };

        // Check if there are tokens beneath the image. If there are, then we should reduce the opacity so that they can be seen.
        const keys = Object.keys(location.tokens);
        for (let i = 0; i < keys.length; i++) {
            let t: WithOverride<Token> = location.tokens[keys[i]];
            if (
                getTokenType(campaign, t) === "creature" &&
                (t.zIndex == null || t.zIndex <= props.zIndex) &&
                (t.isPlayerVisible == null || t.isPlayerVisible || role === "GM")
            ) {
                t = applyOverrides(t, tokenOverrides);

                addWindow(grid, location, t.pos, t.scale, bounds, windows);

                // If the position of the token is overridden (i.e. it's being dragged around), then everything looks smoother
                // if you keep the window for that position as well.
                if (t.overrideDelta?.pos) {
                    addWindow(grid, location, t.withoutOverride!.pos, t.scale, bounds, windows);
                }
            }
        }
    }

    if (!image) {
        return <ObjectImageBroken errorMessage={errorMessage} {...props} />;
    }

    const outlineWidth = OUTLINE_WIDTH_PCT * location.tileSize.width;

    // TODO: Do we need a fallback here? The actual load should be pretty much instant as we've already loaded it into memory.
    return (
        <Suspense fallback={<></>}>
            <ComplexObjectImageInternal
                imageUri={image}
                errorMessage={errorMessage}
                {...props}
                windows={windows}
                outlineWidth={outlineWidth}
            />
        </Suspense>
    );
};

const DraggableComplexObjectImage = withDragEvents(ComplexObjectImage);

const ObjectImageTokenNode: FunctionComponent<TokenNodeProps> = ({
    token,
    isSelected,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideToken,
    snapPoint,
    mode,
    buildMode,
    ...props
}) => {
    const grid = useLocalGrid();
    const dispatch = useDispatch();
    const { campaign, location } = useValidatedLocationLevel();

    const isPresent = useIsPresent();

    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();

    const onTokenClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, token);
            }
        },
        [token, onClick]
    );

    const finalWidth = width
        ? (token.tileSize ? (width / token.tileSize.width) * location.tileSize.width : width) * (token.scale ?? 1)
        : undefined;
    const finalHeight = height
        ? (token.tileSize ? (height / token.tileSize.height) * location.tileSize.height : height) * (token.scale ?? 1)
        : undefined;

    const isBuild = mode === "build" && buildMode === "base";
    const disableDrag = onClick == null || !isBuild;

    const [isHoverImage, setIsHoverImage] = useState(false);
    const isHovering = !disableDrag && isHoverImage;

    const pos = grid.toLocalPoint(token.pos);
    let color: string;
    if (isSelected) {
        color = selectedColor;
    } else {
        color = isHovering ? accentColorHover : accentColor;
    }

    const snapTokenPoint = (dropPoint: LocalPixelPosition) => {
        // If the token has tile sizing information then it was designed to fit onto a grid, so we
        // snap using the top left corner.
        if (finalWidth && finalHeight && token.tileSize) {
            // Snap using the top left corner.
            dropPoint.x -= finalWidth / 2;
            dropPoint.y -= finalHeight / 2;

            const snappedPoint = snapPoint(dropPoint);
            snappedPoint.x += finalWidth / 2;
            snappedPoint.y += finalHeight / 2;
            return snappedPoint;
        }

        // Otherwise, use the center for snapping, which just means snap the point we're given directly.
        return snapPoint(dropPoint);
    };

    return (
        <>
            <AnimatePresence>
                {isPresent && isBuild && finalWidth != null && finalHeight != null && (
                    <Line
                        x={pos.x}
                        y={pos.y}
                        color={color}
                        rotation={token.rotation}
                        closed
                        animateEnterExit
                        width={2}
                        points={[
                            { x: -finalWidth / 2, y: -finalHeight / 2 },
                            { x: finalWidth / 2, y: -finalHeight / 2 },
                            { x: finalWidth / 2, y: finalHeight / 2 },
                            { x: -finalWidth / 2, y: finalHeight / 2 },
                        ]}
                        zIndex={ZIndexes.Underlay + (isSelected || isHovering ? 0.01 : 0)}
                    />
                )}
            </AnimatePresence>
            {/* {(token.zIndex == null || token.zIndex < ZIndexes.Tokens) && (
                <DraggableImage
                    x={pos.x}
                    y={pos.y}
                    width={finalWidth}
                    height={finalHeight}
                    cursor={disableDrag ? undefined : "pointer"}
                    imageUri={token.imageUri!}
                    zIndex={token.zIndex ?? ZIndexes.Underlay}
                    opacity={finalWidth && finalHeight ? 1 : 0}
                    rotation={token.rotation}
                    animateEnterExit
                    onClick={isBuild ? onTokenClick : undefined}
                    onPointerOver={() => setIsHoverImage(true)}
                    onPointerOut={() => setIsHoverImage(false)}
                    onPointerDown={isBuild && onPointerDown ? e => onPointerDown(e, token) : undefined}
                    errorMessage="Failed to download object image."
                    onLoaded={(w, h) => {
                        setWidth(w);
                        setHeight(h);
                    }}
                    disableDrag={disableDrag}
                    dragBoundFunc={v => snapTokenPoint(v)}
                    onDragStart={() => {
                        onOverrideToken(token.id, {
                            isDragging: true,
                        });
                    }}
                    onDragMove={(e, pos) => {
                        onOverrideToken(token.id, {
                            pos: Object.assign({}, pos, { level: token.pos.level }),
                            isDragging: true,
                        });
                    }}
                    onDragEnd={(e, pos) => {
                        dispatch(
                            modifyToken(campaign, location, token, {
                                pos: Object.assign({}, pos, { level: token.pos.level }),
                            })
                        );
                        onOverrideToken(token.id, undefined);
                    }}
                />
            )} */}
            <DraggableComplexObjectImage
                x={pos.x}
                y={pos.y}
                width={finalWidth}
                height={finalHeight}
                isSelected={isSelected}
                cursor={disableDrag ? undefined : "pointer"}
                imageUri={token.imageUri!}
                zIndex={token.zIndex}
                opacity={finalWidth && finalHeight ? 1 : 0}
                rotation={token.rotation}
                windowOpacity={token.windowOpacity}
                occupiedOpacity={token.occupiedOpacity}
                visibleOpacity={token.visibleOpacity}
                onClick={isBuild ? onTokenClick : undefined}
                onPointerOver={() => setIsHoverImage(true)}
                onPointerOut={() => setIsHoverImage(false)}
                onPointerDown={isBuild && onPointerDown ? e => onPointerDown(e, token) : undefined}
                onPointerUp={isBuild && onPointerUp ? e => onPointerUp(e, token) : undefined}
                errorMessage="Failed to download object image."
                onLoaded={(w, h) => {
                    setWidth(w);
                    setHeight(h);
                }}
                disableDrag={disableDrag}
                dragBoundFunc={v => snapTokenPoint(v)}
                onDragStart={() => {
                    onOverrideToken(token.id, {
                        isDragging: true,
                    });
                }}
                onDragMove={(e, pos) => {
                    onOverrideToken(token.id, {
                        pos: Object.assign({}, pos, { level: token.pos.level }),
                        isDragging: true,
                    });
                }}
                onDragEnd={(e, pos) => {
                    dispatch(
                        modifyToken(campaign, location, token, {
                            pos: Object.assign({}, pos, { level: token.pos.level }),
                        })
                    );
                    onOverrideToken(token.id, undefined);
                }}
            />
        </>
    );
};

interface TokenImageProps extends PointerEventsProps, PositionProps {
    opacity?: number;
    path?: WithLevel<GridPosition>[];
    onOverrideToken: (override: Partial<Token> | undefined) => void;
    imageUri: string;
    scale?: number;
    zIndex?: number;
    rotation?: number;
    renderScale?: number;

    // The current actual level that the token is on, or is being dragged to - the current override position's level.
    tokenLevel: string;

    // The level that this token is being rendered on.
    renderLevel: string;

    isDragging: boolean;
    isSelected: SelectionType;
    isTarget: boolean;
    isWarningTarget: boolean;

    ownerPalette: string[] | undefined;
    ownerColor: string;

    // The position of the actual token, disregarding drag position.
    // Only populated when isDragging is true.
    preDragPos?: LocalPixelPosition | GridPosition;
    dragRotate?: number;

    overrideRotation?: number | undefined;
    defaultRotation: number | undefined;
    canRotate: boolean;

    errorMessage: string | ((e: any) => string);

    prevPath: WithLevel<GridPosition>[] | null | undefined;
}

const tokenImageFragmentShader = `
uniform sampler2D u_texture;
uniform float u_opacity;
uniform vec4 u_line_color;
uniform float u_line_thickness;
uniform float u_line_thickness_max;
uniform float u_texture_width;
uniform float u_texture_height;
varying vec2 vUv;

${tokenImageShaderFunctions}

void main() {
    vec2 textureSize = vec2(u_texture_width, u_texture_height);
    gl_FragColor = outline(u_texture, textureSize, u_line_color, u_opacity, u_line_thickness, u_line_thickness_max, vUv);
}`;

const accentColor = getThemeColor(theme.colors.accent[3]);
const accentColorHover = getThemeColor(theme.colors.accent[4]);
const selectedColor = getThemeColor(theme.colors.guidance.focus);
const selectedColorVector = getThemeColorVector(theme.colors.guidance.focus);
const targetColor = getThemeColor(theme.colors.greens[3]);
const targetColorHover = getThemeColor(theme.colors.greens[4]);
const targetWarningColor = getThemeColor(theme.colors.oranges[3]);
const targetWarningColorHover = getThemeColor(theme.colors.oranges[4]);
const targetColorVector = getThemeColorVector(theme.colors.greens[3]);
const targetWarningColorVector = getThemeColorVector(theme.colors.oranges[3]);

const modelColor = getThemeColor(theme.colors.grayscale[3]);

const pathColorValid = getThemeColor(theme.colors.greens[3]);
const pathColorInvalid = getThemeColor(theme.colors.reds[3]);

const twoPi = 2 * Math.PI;

function normaliseAngle(rad: number) {
    let normalised = rad % twoPi;
    return normalised < 0 ? twoPi + normalised : normalised;
}

function getRotationTarget(target: number, prev: number | undefined) {
    if (prev != null) {
        // Get the baseline for both the target and prev - reduce them to the range 0-2pi
        const targetNormalised = normaliseAngle(target);
        const prevNormalised = normaliseAngle(prev);

        if (targetNormalised !== prevNormalised) {
            const distPlus =
                targetNormalised > prevNormalised
                    ? targetNormalised - prevNormalised
                    : twoPi + targetNormalised - prevNormalised;
            const distMinus =
                targetNormalised > prevNormalised
                    ? twoPi - targetNormalised + prevNormalised
                    : prevNormalised - targetNormalised;

            return distPlus < distMinus ? prev + distPlus : prev - distMinus;
        }

        return prev;
    }

    return target;
}

interface TokenAnimProps {
    xAnim: MotionValue<number>;
    yAnim: MotionValue<number>;
    rotationAnim: MotionValue<number>;
    scaleAnim: MotionValue<number>;
}

const tokenMoveAnimationOptions: Transition = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.3,
};

const TokenImageInternal: FunctionComponent<TokenImageProps & TokenAnimProps> = ({
    opacity,
    zIndex,
    imageUri,
    isDragging,
    scale,
    x,
    y,
    path,
    prevPath,
    canRotate,
    defaultRotation,
    rotation,
    overrideRotation,
    dragRotate,
    onOverrideToken,
    preDragPos,
    tokenLevel,
    renderLevel,
    xAnim,
    yAnim,
    rotationAnim,
    scaleAnim,
    renderScale,
    ...props
}) => {
    const grid = useLocalGrid();
    const texture = useLoader(TextureLoader, imageUri);
    const [isPresent, safeToRemove] = usePresence();
    const { location } = useValidatedLocation();

    const initialTargetRotation = -degToRad(
        (canRotate ? overrideRotation ?? rotation ?? 0 : 0) + (defaultRotation ?? 0)
    );
    if (rotationAnim.get() === Number.MAX_VALUE) {
        rotationAnim.jump(initialTargetRotation);
    }

    const mainMeshRef = useRef<Mesh>(null);
    const renderMeshRef = useRef<Mesh>(null);
    const pointerMeshRef = useRef<Mesh>(null);

    const isTransparent = !!texture || (opacity != null && opacity < 1);

    const scaleValue = scale ?? 1;
    const renderScaleValue = renderScale ?? 1;

    let finalMainRotation = getRotationTarget(initialTargetRotation, rotationAnim.get());

    const opacityRef = useRef<number>();
    opacityRef.current = opacity;
    const presenceOpacity = useMotionValue(0);
    useEffect(() => {
        animate(presenceOpacity, isPresent ? opacity ?? 1 : 0, {
            onComplete: safeToRemove ?? undefined,
            type: "tween",
            ease: "easeOut",
        });
    }, [isPresent, safeToRemove, presenceOpacity, opacity]);

    const hasPath = prevPath !== undefined && !!prevPath && !!prevPath.length;

    const z = zIndex ?? ZIndexes.Tokens;
    const animationLevel = useRef<string>();

    const moveAnimation = useRef<number | undefined>();
    if (hasPath && moveAnimation.current == null) {
        // For cases where a token is animating a path that involves multiple levels, we have an instance of the token
        // render on each level, and animate them all over the same path - but set the opacity based on whether the token
        // should currently be on this level. This way they all stay in sync and we keep it relatively simple.
        animationLevel.current = prevPath![0].level;
        animate(presenceOpacity, animationLevel.current === renderLevel ? opacityRef.current ?? 1 : 0);

        let pathRotation = finalMainRotation;
        animateSequence(
            moveAnimation,
            prevPath.map((o, i) => {
                const nextPos = grid.toLocalCenterPoint(o, scale);
                const motionValues = [
                    {
                        motionValue: xAnim,
                        value: nextPos.x,
                        options: tokenMoveAnimationOptions,
                    },
                    {
                        motionValue: yAnim,
                        value: nextPos.y,
                        options: tokenMoveAnimationOptions,
                    },
                ];

                if (canRotate && i > 0) {
                    const localRotate = angle(prevPath![i - 1], o) - 90;
                    pathRotation = getRotationTarget(-degToRad(localRotate), pathRotation);
                    motionValues.push({
                        motionValue: rotationAnim,
                        value: pathRotation,
                        options: { type: "tween", duration: 0.2 },
                    });
                }

                return {
                    values: motionValues,
                    onStarting: () => {
                        animationLevel.current = o.level;
                        animate(presenceOpacity, animationLevel.current === renderLevel ? opacityRef.current ?? 1 : 0);
                    },
                };
            })
        ).finally(() => {
            onOverrideToken(undefined);
        });
    } else if (!isDragging && moveAnimation.current == null) {
        animate(xAnim, x, tokenMoveAnimationOptions);
        animate(yAnim, y, tokenMoveAnimationOptions);
        if (overrideRotation != null) {
            rotationAnim.jump(-degToRad(overrideRotation + (defaultRotation ?? 0)));
        } else {
            animate(rotationAnim, finalMainRotation);
        }
    }

    // Ensure that if we're starting a new animation, an override is in place before we next render.
    useLayoutEffect(() => {
        if (prevPath) {
            flushSync(() => {
                onOverrideToken({ pos: prevPath[0] });
            });
        }
    }, [prevPath, onOverrideToken]);

    const lastDragPos = useRef<{ x: number; y: number; rotation: number }>();
    if (isDragging) {
        let finalDragRotation =
            dragRotate != null && canRotate
                ? getRotationTarget(
                      -degToRad(dragRotate + (defaultRotation ?? 0)),
                      lastDragPos.current?.rotation ?? rotationAnim.get()
                  )
                : finalMainRotation;
        lastDragPos.current = { x: x, y: y, rotation: finalDragRotation };
    } else {
        lastDragPos.current = undefined;
    }

    const selectedColor = useMemo(() => {
        if (!props.ownerPalette) {
            return selectedColorVector;
        }

        const color = tinycolor(props.ownerPalette[6]).toRgb();
        return new Vector4(color.r / 255, color.g / 255, color.b / 255, color.a);
    }, [props.ownerPalette]);

    const outlineWidth = OUTLINE_WIDTH_PCT * location.tileSize.width;
    const outlineThickness = useMotionValue(0);
    useEffect(() => {
        animate(outlineThickness, props.isSelected || props.isTarget ? outlineWidth : 0, {
            type: "tween",
            ease: "easeOut",
        });
    }, [props.isSelected, props.isTarget, outlineThickness, outlineWidth]);

    const uniforms = useMemo(
        () => ({
            u_texture: { type: "t", value: undefined as Texture | undefined },
            u_opacity: { value: 1 },
            u_line_color: { value: undefined as Vector4 | undefined },
            u_line_thickness: { value: 0 },
            u_line_thickness_max: { value: 0 },
            u_texture_width: { value: 0 },
            u_texture_height: { value: 0 },
        }),
        []
    );
    uniforms.u_texture.value = texture;

    useFrame(() => {
        // TODO: We've already worked this colour out in a parent component, only that includes hover colours too. We should pass that in instead.
        uniforms.u_line_color.value = props.isTarget
            ? props.isWarningTarget
                ? targetWarningColorVector
                : targetColorVector
            : selectedColor;
        uniforms.u_opacity.value = presenceOpacity.get();
        uniforms.u_texture_width.value = location.tileSize.width * scaleValue; // texture.image.width;
        uniforms.u_texture_height.value = location.tileSize.height * scaleValue; // texture.image.height;
        uniforms.u_line_thickness.value = outlineThickness.get();
        uniforms.u_line_thickness_max.value = outlineWidth;

        if (mainMeshRef.current) {
            mainMeshRef.current.position.set(xAnim.get(), -yAnim.get(), z / 1000);
            renderMeshRef.current!.rotation.set(0, 0, rotationAnim.get());
            renderMeshRef.current!.scale.set(scaleAnim.get() * renderScaleValue, scaleAnim.get() * renderScaleValue, 1);
            pointerMeshRef.current!.scale.set(scaleAnim.get(), scaleAnim.get(), 1);

            // If a move animation is in progress, then set an override for the token so that it appears in the correct spot
            // with regards to vision & lighting etc.
            if (moveAnimation.current != null) {
                let pos: WithLevel<LocalPixelPosition> = {
                    type: PositionType.LocalPixel,
                    x: mainMeshRef.current.position.x,
                    y: -mainMeshRef.current.position.y,
                    level: animationLevel.current!,
                };
                onOverrideToken({ pos: pos });
            }
        }
    });

    // console.log(`Rendering token for level ${levelKey}, hasPath: ${hasPath}, prevPath: ${prevPath === undefined ? "undefined" : (prevPath === null ? "null" : prevPath.length)}, preDragPos: ${preDragPos ? `${preDragPos.x},${preDragPos.y}` : "undefined"}, lastDragPos: ${lastDragPos.current ? `${lastDragPos.current.x},${lastDragPos.current.y}` : "none"}`);
    // console.log(`Rendering token for level ${levelKey}, isDragging: ${isDragging} level: ${level} levelKey: ${levelKey}`);

    // The outer mesh is essentially a pivot point, so that you can rotate the inner mesh around on offset
    // point rather than its own position.
    return (
        <>
            <AnimatePresence>
                {isPresent && isDragging && tokenLevel === renderLevel && (
                    <motion3d.mesh
                        position={[lastDragPos.current!.x, -lastDragPos.current!.y, ZIndexes.UserInterface]}
                        initial={{
                            scaleX: scaleValue * renderScaleValue,
                            scaleY: scaleValue * renderScaleValue,
                            rotateZ: lastDragPos.current?.rotation ?? 0,
                        }}
                        animate={{
                            scaleX: scaleValue * renderScaleValue * 1.2,
                            scaleY: scaleValue * renderScaleValue * 1.2,
                            rotateZ: lastDragPos.current?.rotation ?? 0,
                        }}
                        exit={{
                            scaleX: scaleValue * renderScaleValue * defaultMotionScale,
                            scaleY: scaleValue * renderScaleValue * defaultMotionScale,
                        }}>
                        <planeGeometry attach="geometry" args={[location.tileSize.width, location.tileSize.height]} />
                        <motion3d.meshBasicMaterial
                            attach="material"
                            map={texture}
                            transparent={isTransparent}
                            depthWrite={false}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.75 }}
                            exit={{ opacity: 0 }}
                        />
                    </motion3d.mesh>
                )}
            </AnimatePresence>
            {(!isDragging || preDragPos != null) && (
                <mesh ref={mainMeshRef}>
                    <mesh ref={renderMeshRef}>
                        <planeGeometry
                            attach="geometry"
                            args={[
                                location.tileSize.width + outlineWidth * 2,
                                location.tileSize.height + outlineWidth * 2,
                            ]}
                        />
                        <shaderMaterial
                            attach="material"
                            fragmentShader={tokenImageFragmentShader}
                            vertexShader={basicImageVertexShader}
                            transparent
                            uniforms={uniforms}
                            depthWrite={false}
                        />
                    </mesh>
                    <mesh
                        ref={pointerMeshRef}
                        visible={false}
                        raycast={opacity == null || opacity > 0 ? Mesh.prototype.raycast : () => {}}
                        onPointerDown={props.onPointerDown}
                        onPointerUp={props.onPointerUp}
                        onPointerMove={props.onPointerMove}
                        onPointerOver={props.onPointerOver}
                        onPointerOut={props.onPointerOut}
                        onClick={props.onClick}>
                        <planeGeometry attach="geometry" args={[location.tileSize.width, location.tileSize.height]} />
                    </mesh>
                </mesh>
            )}
        </>
    );
};

const TokenImage: FunctionComponent<TokenImageProps & TokenAnimProps> = ({
    imageUri,
    errorMessage,
    rotation,
    overrideRotation,
    defaultRotation,
    canRotate,
    renderScale,
    ...props
}) => {
    // eslint-disable-next-line
    const [image, current, total, error] = useFileUrlWithProgress(imageUri, errorMessage);

    let imageToUse = image;
    if (error) {
        imageToUse = "defaulttoken.png";
        rotation = 0;
        overrideRotation = 0;
        defaultRotation = 0;
        canRotate = false;
        renderScale = 1;
    }

    if (!imageToUse) {
        // TODO: Actual fallback should go here.
        return <></>;
    }

    // TODO: Do we need a fallback here? The actual load should be pretty much instant as we've already loaded it into memory.
    return (
        <Suspense fallback={<></>}>
            <TokenImageInternal
                imageUri={imageToUse}
                errorMessage={errorMessage}
                canRotate={canRotate}
                rotation={rotation}
                overrideRotation={overrideRotation}
                defaultRotation={defaultRotation}
                renderScale={renderScale}
                {...props}
            />
        </Suspense>
    );
};

const DraggableTokenImage = withDragEvents(TokenImage);

const modelEnterExit = cameraTransition;

interface TokenModelProps extends TokenAnimProps {
    modelUri: string;
    tileSize: number;
    zAnim: MotionValue<number>;
    opacityAnim: MotionValue<number>;
    layer: number;
}

const ThreeMFTokenModel: FunctionComponent<TokenModelProps> = ({
    modelUri,
    tileSize,
    layer,
    xAnim,
    yAnim,
    zAnim,
    rotationAnim,
    scaleAnim,
    opacityAnim,
}) => {
    const group = useLoader(ThreeMFLoader, modelUri);
    const boundsRef = useRef<Box3>();

    if (!group.parent && !boundsRef.current) {
        const box = new Box3();
        boundsRef.current = box;
        box.setFromObject(group);

        // Layer 1 makes it visible to the camera but invisible to the raycaster.
        // The models, often being intended for 3d printing, sometimes contain many thousands of faces, and
        // make raycasting very very slow.
        group.traverse(o => {
            const mesh = o as Mesh;
            if (mesh.isMesh) {
                mesh.layers.set(layer);
            }
        });
    }

    const [baseScale, setBaseScale] = useState(1);
    useLayoutEffect(() => {
        if (boundsRef.current) {
            const box = boundsRef.current;

            // TODO: Calculate both width & height, then do aspect ratio etc.
            const tileWidth = (box.max.x - box.min.x) / tileSize;
            // const tileHeight = geometry.boundingBox!.max.y / location.tileSize.height;

            setBaseScale(1 / tileWidth);
        }
    }, [group, tileSize]);

    useFrame(() => {
        group.position.set(xAnim.get(), -yAnim.get(), zAnim.get());
        group.rotation.set(0, 0, rotationAnim.get());
        const s = scaleAnim.get() * baseScale;
        group.scale.set(s, s, s);

        const opacity = opacityAnim.get();
        group.visible = opacity !== 0;
        group.traverse(o => {
            const mesh = o as Mesh;
            if (mesh.isMesh && mesh.material) {
                if (Array.isArray(mesh.material)) {
                    for (let i = 0; i < mesh.material.length; i++) {
                        mesh.material[i].opacity = opacity;
                        mesh.material[i].transparent = true;
                    }
                } else {
                    mesh.material.opacity = opacity;
                    mesh.material.transparent = true;
                }
            }
        });
    });

    return <primitive object={group} />;
};

const STLTokenModel: FunctionComponent<TokenModelProps> = ({
    modelUri,
    tileSize,
    layer,
    xAnim,
    yAnim,
    zAnim,
    rotationAnim,
    scaleAnim,
    opacityAnim,
}) => {
    const geometry = useLoader(STLLoader, modelUri);
    const [baseScale, setBaseScale] = useState(1);

    useLayoutEffect(() => {
        if (geometry) {
            geometry.computeBoundingBox();

            // TODO: Calculate both width & height, then do aspect ratio etc.
            const tileWidth = (geometry.boundingBox!.max.x - geometry.boundingBox!.min.x) / tileSize;
            // const tileHeight = geometry.boundingBox!.max.y / location.tileSize.height;

            setBaseScale(1 / tileWidth);
        }
    }, [geometry, tileSize]);

    const meshRef = useRef<Mesh>(null);
    useFrame(() => {
        if (meshRef.current) {
            meshRef.current.position.set(xAnim.get(), -yAnim.get(), zAnim.get());
            meshRef.current.rotation.set(0, 0, rotationAnim.get());
            const s = scaleAnim.get() * baseScale;
            meshRef.current.scale.set(s, s, s);

            const opacity = opacityAnim.get();
            meshRef.current.visible = opacity !== 0;
            (meshRef.current.material as Material).opacity = opacity;
            (meshRef.current.material as Material).transparent = true;
        }
    });

    // Layer 1 makes it visible to the camera but invisible to the raycaster.
    return (
        <mesh receiveShadow ref={meshRef} layers={layer} geometry={geometry}>
            <meshPhongMaterial color={modelColor} />
        </mesh>
    );
};

const TokenModelHost: FunctionComponent<
    TokenAnimProps & {
        z: number;
        animation?: "drop";
        hidden?: boolean;
        layer?: number;
        tileSize: number;
        modelUri: string | undefined;
        extension: string | undefined;
        isPlayerVisible: boolean;
        isOwnerOrGM: boolean;
    }
> = ({ modelUri, layer, z, animation, hidden, extension, isPlayerVisible, tileSize, isOwnerOrGM, ...props }) => {
    const isLoadedRef = useRef(false);
    isLoadedRef.current = isLoadedRef.current || !hidden;

    const opacity = useMotionValue(0);
    const zAnim = useMotionValue(animation === "drop" ? z + tileSize * 4 : z);

    const [isPresent, safeToRemove] = usePresence();

    useLayoutEffect(() => {
        // TODO: It would be nice to reduce the opacity of a token when it's hidden, but that causes problems, threejs/webgl
        // really doesn't work very nicely with transparent models.
        // animate(opacity, isModelVisible ? (isPlayerVisible ? 1 : (isOwnerOrGM ? 0.1 : 0)) : 0, modelEnterExit);
        let opacityTransition = { onComplete: safeToRemove ?? undefined };
        if (isPresent) {
            opacityTransition = Object.assign(opacityTransition, modelEnterExit);
        }

        animate(opacity, !hidden && isPresent ? 1 : 0, opacityTransition);

        if (animation === "drop") {
            animate(zAnim, !hidden ? z : z + tileSize * 4, modelEnterExit);
        } else {
            animate(zAnim, z, modelEnterExit);
        }
    }, [hidden, isPlayerVisible, isOwnerOrGM, z, zAnim, tileSize, opacity, animation, isPresent, safeToRemove]);

    return (
        <>
            {modelUri && extension === ".stl" && (
                <STLTokenModel
                    modelUri={modelUri}
                    layer={layer ?? 0}
                    tileSize={tileSize}
                    zAnim={zAnim}
                    opacityAnim={opacity}
                    {...props}
                />
            )}
            {modelUri && extension === ".3mf" && (
                <ThreeMFTokenModel
                    modelUri={modelUri}
                    layer={layer ?? 0}
                    tileSize={tileSize}
                    zAnim={zAnim}
                    opacityAnim={opacity}
                    {...props}
                />
            )}
        </>
    );
};

export const TokenModel: FunctionComponent<{
    modelUri: string;
    z?: number;
    errorMessage: string | ((e: any) => string);
}> = ({ modelUri, z, errorMessage }) => {
    const [image] = useFileUrlWithProgress(modelUri, errorMessage);

    const i = modelUri?.lastIndexOf(".");
    const extension = i != null ? modelUri?.slice(i).toLowerCase() : undefined;

    const xAnim = useMotionValue(0);
    const yAnim = useMotionValue(0);
    const scaleAnim = useMotionValue(1);
    const rotationAnim = useMotionValue(0);

    return (
        <Suspense fallback={<></>}>
            <TokenModelHost
                modelUri={image}
                tileSize={2}
                z={z ?? 0}
                extension={extension}
                isPlayerVisible
                isOwnerOrGM
                xAnim={xAnim}
                yAnim={yAnim}
                scaleAnim={scaleAnim}
                rotationAnim={rotationAnim}
            />
        </Suspense>
    );
};

const TokenModelInternal: FunctionComponent<
    {
        modelUri: string;
        isPlayerVisible: boolean;
        isOwnerOrGM: boolean;
        pos: LocalPixelPosition;
        errorMessage: string | ((e: any) => string);
    } & TokenAnimProps
> = ({ modelUri, pos, isPlayerVisible, isOwnerOrGM, errorMessage, ...animProps }) => {
    const [image] = useFileUrlWithProgress(modelUri, errorMessage);
    const cameraMode = useVttApp(state => state.cameraMode);
    const { location } = useValidatedLocation();

    const i = modelUri?.lastIndexOf(".");
    const extension = i != null ? modelUri?.slice(i).toLowerCase() : undefined;

    return (
        <Suspense fallback={<></>}>
            <TokenModelHost
                modelUri={image}
                tileSize={location.tileSize.width}
                layer={VttCameraLayers.DefaultNoRaycasting}
                z={0}
                animation="drop"
                hidden={cameraMode !== "perspective"}
                extension={extension}
                isPlayerVisible={isPlayerVisible}
                isOwnerOrGM={isOwnerOrGM}
                {...animProps}
            />
        </Suspense>
    );
};

function buildPath(waypoints: PathResult<WithLevel<GridPosition>>[]) {
    if (waypoints.length === 1) {
        return waypoints[0].path;
    }

    const points: WithLevel<GridPosition>[] = [];
    for (let i = 0; i < waypoints.length; i++) {
        let j = 0;
        if (points.length > 0 && pointsEqual(points[points.length - 1], waypoints[i].path[0])) {
            j = 1;
        }

        for (; j < waypoints[i].path.length; j++) {
            points.push(waypoints[i].path[j]);
        }
    }

    return points;
}

const testGridPos = gridPoint(0, 0);

function getGridDragPos(grid: ILocalGrid, scale: number, pos: GridPosition | LocalPixelPosition) {
    if (pos.type === PositionType.Grid) {
        return pos;
    }

    const oldCenterPoint = grid.toLocalCenterPoint(testGridPos, scale);
    const oldGridCenterPoint = grid.toLocalCenterPoint(testGridPos, 1);
    const oldDiff = localPoint(oldCenterPoint.x - oldGridCenterPoint.x, oldCenterPoint.y - oldGridCenterPoint.y);

    const gridTestPos = localPoint(pos.x - oldDiff.x, pos.y - oldDiff.y);
    const gridPos = grid.toGridPoint(gridTestPos);
    return gridPos;
}

const initialTokenOutline = {
    scaleX: 0.95,
    scaleY: 0.95,
};
const defaultTokenOutline = {
    scaleX: 1,
    scaleY: 1,
};
const combatTurnTokenOutline = {
    scaleX: 1.1,
    scaleY: 1.1,
};

const TokenOutline: FunctionComponent<{
    grid: ILocalGrid;
    pos: WithLevel<GridPosition>;
    scale: number;
    isSelected: SelectionType;
    isHovering: boolean;
    color: string;
    isPlayerVisible: boolean;
    isCombatTurn: boolean;
}> = ({ grid, pos, scale, isSelected, isHovering, color, isPlayerVisible, isCombatTurn }) => {
    // TODO: Put all this in a useMemo, should only be done when the pos/scale changes.
    let gridPoints = grid.toLocalPoints(pos, scale);
    let gridPointsThree: [number, number, number][] = [];
    let gridCenterPoint = getCenterPoint(gridPoints);
    for (let i = 0; i < gridPoints.length; i++) {
        gridPoints[i].x = gridPoints[i].x - gridCenterPoint.x;
        gridPoints[i].y = gridPoints[i].y - gridCenterPoint.y;
        gridPointsThree.push([gridPoints[i].x, -gridPoints[i].y, 0]);
    }

    gridPointsThree.push(gridPointsThree[0]);

    const colorMotionValue = useMotionValue(color);
    useEffect(() => {
        animate(colorMotionValue, color);
    }, [color, colorMotionValue]);
    const ref2 = useRef<Line2>(null);
    useFrame(() => {
        if (ref2.current) {
            const color = colorMotionValue.get();
            ref2.current.material.color.set(color);
        }
    });

    return (
        <motion3d.mesh
            position={[gridCenterPoint.x, -gridCenterPoint.y, 0]}
            initial={initialTokenOutline}
            animate={isCombatTurn ? combatTurnTokenOutline : defaultTokenOutline}
            exit={initialTokenOutline}
            transition={isCombatTurn ? { repeat: Infinity, repeatType: "reverse", mass: 100 } : undefined}>
            <DreiLine
                ref={ref2}
                points={gridPointsThree}
                color={color}
                dashed={!isPlayerVisible}
                dashSize={5}
                gapSize={5}
                lineWidth={3}
                renderOrder={ZIndexes.Underlay + (isSelected || isHovering ? 0.01 : 0)}
            />
        </motion3d.mesh>
    );
};

const ImageTokenNode: FunctionComponent<TokenNodeProps> = ({
    token,
    isSelected,
    isTarget,
    isWarningTarget,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideToken,
    mode,
    buildMode,
    levelKey,
    ...props
}) => {
    const dispatch = useDispatch();
    const { session } = useSession();
    const { system, location, campaign, user } = useValidatedLocationLevel();
    const grid = useLocalGrid();
    const mapScale = useScale();
    const role = useRole();
    const pathFinder = usePathFinding();
    const { hasBeenPerspective } = useCamera();

    const tokenAppearance = system.useTokenAppearance(token, campaign, location);
    const scale = tokenAppearance.scale != null ? tokenAppearance.scale : 1;

    const isDragging = token.isDragging;
    const [oldPos, setOldPos] = useState(undefined as WithLevel<GridPosition | LocalPixelPosition> | undefined);

    // Keep track of some previously calculated values for performance reasons.
    const previousGridPath = useRef(undefined as PathResult<WithLevel<GridPosition>> | undefined);
    const previousPath = useRef(undefined as WithLevel<LocalPixelPosition>[] | undefined | null);

    // prevPath is undefined if it's never been run (in which case it shouldn't animate, it's the current state),
    // null if there is a path but it hasn't changed since last render (so it shouldn't start a new animation),
    // or the actual path if it has changed and should be animated.
    const prevPathRef = useRef<WithLevel<GridPosition | LocalPixelPosition>[]>();
    const prevPath =
        prevPathRef.current === undefined
            ? undefined
            : pathsEqual(prevPathRef.current, token.prevPath)
            ? null
            : token.prevPath;
    prevPathRef.current = token.prevPath;

    // If there is no path, then set the current to an empty array so that we know we should animate the next change
    // to prevPath rather than ignoring it.
    if (!prevPathRef.current) {
        prevPathRef.current = [];
    }

    const cancelDragRef = useRef<() => void>();
    useKeyboardShortcut(
        "Escape",
        ev => {
            onOverrideToken(token.id, undefined);
            setOldPos(undefined);
            cancelDragRef.current?.();
            cancelDragRef.current = undefined;
            ev.preventDefault();
        },
        { isDisabled: !isDragging, priority: 200, isDragDrop: true }
    );

    // If the image comes from the token, respect the setting - if it's the default image, then force to not rotate because that's the kind of image it is.
    const canRotate =
        tokenAppearance.imageUri != null ? tokenAppearance.canRotate == null || tokenAppearance.canRotate : false;

    const isPlayerVisible = token.isPlayerVisible == null || token.isPlayerVisible;
    const opacity = isPlayerVisible ? 1 : role === "GM" ? 0.5 : 0;
    const isOwnerOrGM = role === "GM" || token.owner === user.id;

    const isAnimationOnly = levelKey !== (token.withoutOverride ?? token).pos.level;
    const isAnimationOnlyAndHidden = isAnimationOnly && !isDragging && !prevPath;

    const isInteractive = !isAnimationOnly && (mode === "play" || (mode === "build" && buildMode === "tokens"));

    // gridPos is used in pathfinding.
    // pos is for the center of the image as local pixels.
    const startPos = isDragging && oldPos != null ? oldPos : token.pos;
    const gridPos = startPos.type === PositionType.LocalPixel ? getGridDragPos(grid, scale, startPos) : startPos;
    const gridCenterPos = grid.toLocalCenterPoint(gridPos, tokenAppearance.scale);
    const overridePos = token.dragPos;
    const pos =
        overridePos != null
            ? overridePos
            : token.pos.type === "grid"
            ? grid.toLocalCenterPoint(token.pos as GridPosition)
            : (token.pos as LocalPixelPosition);
    const currentPos = isDragging ? pos : gridCenterPos;

    let currentGridPoint = (
        isDragging ? getGridDragPos(grid, scale, currentPos) : getGridDragPos(grid, scale, token.pos)
    ) as WithLevel<GridPosition>;
    currentGridPoint.level = overridePos?.level ?? token.pos.level;

    // let gridPoints = grid.toLocalPoints(currentGridPoint, tokenAppearance.scale);
    // let gridPointsThree: [number, number, number][] = [];
    // let gridCenterPoint = getCenterPoint(gridPoints);
    // for (let i = 0; i < gridPoints.length; i++) {
    //     gridPoints[i].x = gridPoints[i].x - gridCenterPoint.x;
    //     gridPoints[i].y = gridPoints[i].y - gridCenterPoint.y;
    //     gridPointsThree.push([gridPoints[i].x, -gridPoints[i].y, 0]);
    // }

    let dragRotate: number | undefined;

    // If dragging, show the optimal path that can be taken to get to the specified grid point.
    let draggedPath: WithLevel<LocalPixelPosition>[] | undefined | null;
    if (isDragging) {
        if (token.currentGridPath !== previousGridPath.current) {
            const gridPath = token.currentGridPath;
            if (gridPath && gridPath.path.length) {
                previousGridPath.current = gridPath;

                draggedPath = gridPath.path.reduce<WithLevel<LocalPixelPosition>[]>((p, o, i) => {
                    const centerPoint = grid.toLocalCenterPoint(o, tokenAppearance.scale);
                    if (i > 0 && i === gridPath.path.length - 1) {
                        // Replace the final point with the point that it would enter the grid space, rather than the center of it.
                        // The thing we're dragging will probably be covering up the square we're going to drop on, so the arrow would look
                        // better if it just went up to the cell rather than right into it.
                        const previousPoint = p[i - 1];
                        if (previousPoint) {
                            const intersectionPoints = intersectPath(
                                previousPoint,
                                centerPoint,
                                false,
                                grid.toLocalPoints(o, tokenAppearance.scale),
                                true
                            );
                            const pwl = (
                                intersectionPoints.length ? intersectionPoints[0] : centerPoint
                            ) as WithLevel<LocalPixelPosition>;
                            pwl.level = o.level;
                            p.push(pwl);
                        }
                    } else {
                        const pwl = centerPoint as WithLevel<LocalPixelPosition>;
                        pwl.level = o.level;
                        p.push(pwl);
                    }

                    return p;
                }, []);
            } else {
                draggedPath = null;
                previousGridPath.current = undefined;
            }

            previousPath.current = draggedPath;
        } else {
            draggedPath = previousPath.current;
        }

        if (draggedPath && draggedPath.length > 1 && canRotate) {
            // Get the angle of the last two points, and give that to our token if nothing else is overriding it.
            dragRotate = angle(draggedPath[draggedPath.length - 2], draggedPath[draggedPath.length - 1]) - 90;
        }
    } else {
        previousPath.current = undefined;
    }

    // TODO: If this causes problems when dragging between levels, it could be because this is triggering on multiple levels
    // at the same time. The disable here should probably disable if this isn't the original dragged token.
    useKeyboardShortcut(
        " ",
        ev => {
            if (previousGridPath.current) {
                const newWaypoints = token.waypoints ? token.waypoints.slice() : [];
                newWaypoints.push(previousGridPath.current);
                previousGridPath.current = undefined;
                onOverrideToken(token.id, {
                    isDragging: true,
                    pos: token.pos,
                    currentGridPath: undefined,
                    currentLevel: token.currentLevel,
                    waypoints: newWaypoints,
                });
                previousPath.current = undefined;
                ev.preventDefault();
            }
        },
        { isDisabled: !isDragging, priority: 100 }
    );

    const isCombatTurn = location.combat?.turn === token.id;

    const onTokenClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, token);
            }
        },
        [token, onClick]
    );

    const ownerPalette = token.owner ? getPlayerColorPalette(campaign, token.owner) : undefined;
    const ownerColor = ownerPalette ? ownerPalette[3] : accentColor;

    let shownPath: WithLevel<LocalPixelPosition>[] | undefined;
    let shownPathCosts: number[] | undefined;
    let shownPathCost = 0;
    let pathColor: string | undefined;
    if (draggedPath || token.waypoints) {
        shownPathCosts = [];
        if (token.waypoints) {
            const waypointsPath = buildPath(token.waypoints).map(o => {
                const p = grid.toLocalCenterPoint(o, scale) as WithLevel<LocalPixelPosition>;
                p.level = o.level;
                return p;
            });

            if (draggedPath) {
                waypointsPath.push(...draggedPath.slice(1));
            }
            shownPath = waypointsPath;

            for (let i = 0; i < token.waypoints.length; i++) {
                // eslint-disable-next-line no-loop-func
                shownPathCosts.push(...token.waypoints[i].pathCost.map(o => shownPathCost + o));
                shownPathCost += token.waypoints[i].cost;
            }
        } else if (draggedPath) {
            shownPath = draggedPath;
        }

        if (draggedPath) {
            if (previousGridPath.current) {
                shownPathCosts.push(...previousGridPath.current.pathCost.map(o => shownPathCost + o));
                shownPathCost += previousGridPath.current.cost;
            }
        }

        pathColor = ownerColor;
    } else if (prevPath) {
        shownPath = prevPath.map(o => {
            const p = grid.toLocalCenterPoint(o, scale) as WithLevel<LocalPixelPosition>;
            p.level = o.level;
            return p;
        });
        pathColor = ownerColor;
    } else if (token.nextPath) {
        shownPath = token.nextPath.map(o => {
            const p = grid.toLocalCenterPoint(o, scale) as WithLevel<LocalPixelPosition>;
            p.level = o.level;
            return p;
        });
        pathColor = ownerColor;

        const result = pathFinder?.evaluateMovementPath(token, token.nextPath);
        if (result) {
            shownPathCost = result.cost;
            shownPathCosts = result.pathCost;
        }
    }

    const showPathAdorner = role === "GM" && token.nextPath && token.nextPath.length > 0;

    // Find all log entries that are from the last few seconds, we might want to display them
    // if they are attached to tokens.
    let logPos: LocalPixelPosition[] | undefined;

    const [tick, setTick] = useState(0);
    const forceUpdate = useCallback(() => {
        setTick(tick => tick + 1);
    }, []);

    const logTimeoutRef = useRef<number | undefined>();
    const logEntriesRef = useRef<LogEntry[]>();
    const logEntries = useMemo(() => {
        const logEntriesToShow: LogEntry[] = [];
        const allLogEntries = Object.values(session.log);
        const time = Date.now();

        for (let i = 0; i < allLogEntries.length; i++) {
            const logEntry = allLogEntries[i];

            if (
                time - logEntry.time < LOG_ENTRY_TIMEOUT &&
                logEntry.token === token.id &&
                logEntry.location === location.id &&
                shouldShowNotification("token", user.id, logEntry, isSelected !== SelectionType.None, true)
            ) {
                logEntriesToShow.push(logEntry);
            }
        }

        logEntriesToShow.sort((a, b) => a.time - b.time);
        return logEntriesToShow;

        // We don't care if isSelected changes, only matters at the time of render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session.log, tick, location.id, token.id, user.id]);

    if (logEntries.length) {
        // We have at least one log entry that we should be displaying, so calculate where to show it.
        const tokenAppearance = system.getTokenAppearance?.(token, campaign, location) ?? token;
        logPos = grid.toLocalPoints(gridPos, tokenAppearance.scale);

        // Only refresh the timeout if the log entries have actually changed.
        if (logEntriesRef.current !== logEntries) {
            if (logTimeoutRef.current != null) {
                clearTimeout(logTimeoutRef.current);
            }

            logTimeoutRef.current = setTimeout(forceUpdate, LOG_ENTRY_TIMEOUT) as any; // Have to do this because node types are coming in from somewhere.
        }
    }

    logEntriesRef.current = logEntries;

    const disableDrag = !isDragging && (onClick == null || !isOwnerOrGM || !isInteractive);

    const [isHoverImage, setIsHoverImage] = useState(false);
    const isHovering = !disableDrag && isHoverImage;

    let color: string;
    if (mode === "build" && buildMode !== "tokens") {
        color = getThemeColorPalette("grayscale")[3];
    } else if (isTarget) {
        if (isWarningTarget) {
            color = isHovering ? targetWarningColorHover : targetWarningColor;
        } else {
            color = isHovering ? targetColorHover : targetColor;
        }
    } else {
        if (isSelected === SelectionType.Primary) {
            if (ownerPalette) {
                color = ownerPalette[6];
            } else {
                color = selectedColor;
            }
        } else {
            color = isHovering ? (ownerPalette ? ownerPalette[4] : getThemeColor(theme.colors.accent[4])) : ownerColor;
        }
    }

    let lastInRangeIndex: number | undefined;
    if (shownPathCosts && shownPathCosts.length > 0) {
        const range = system.getTokenRange?.(token, campaign, location);
        if (range != null) {
            for (let i = 0; i < shownPathCosts.length; i++) {
                if (shownPathCosts[i] > range) {
                    lastInRangeIndex = i;
                    break;
                }
            }

            if (lastInRangeIndex == null) {
                lastInRangeIndex = shownPathCosts.length;
            }
        }
    }

    // Only show the parts of the path that are on this level.
    const shownPaths: {
        startIndex: number;
        points: WithLevel<LocalPixelPosition>[];
    }[] = [];
    if (shownPath) {
        let currentPath: { startIndex: number; points: WithLevel<LocalPixelPosition>[] } | undefined;
        for (let i = 0; i < shownPath.length; i++) {
            if (shownPath[i].level === levelKey) {
                if (!currentPath) {
                    currentPath = { startIndex: i, points: [] };
                    shownPaths.push(currentPath);
                }

                currentPath.points.push(shownPath[i]);
            } else {
                if (currentPath) {
                    currentPath.points.push(Object.assign({}, shownPath[i], { level: levelKey }));
                }

                currentPath = undefined;
            }
        }
    }

    const xAnim = useMotionValue(currentPos.x);
    const yAnim = useMotionValue(currentPos.y);
    const rotationAnim = useMotionValue(Number.MAX_VALUE);
    const scaleAnim = useMotionValue(scale ?? 1);
    animate(scaleAnim, scale ?? 1, tokenMoveAnimationOptions);

    return (
        <>
            <AnimatePresenceRepeater>
                {opacity > 0 && !isAnimationOnlyAndHidden && currentGridPoint.level === levelKey && (
                    <TokenOutline
                        grid={grid}
                        pos={currentGridPoint}
                        scale={scale}
                        isSelected={isSelected}
                        isHovering={isHovering}
                        isPlayerVisible={isPlayerVisible}
                        isCombatTurn={isCombatTurn}
                        color={
                            shownPath && lastInRangeIndex != null
                                ? lastInRangeIndex < shownPath.length - 1
                                    ? pathColorInvalid
                                    : pathColorValid
                                : color
                        }
                    />
                )}
                {shownPaths &&
                    shownPaths.length &&
                    shownPaths.map((o, i) => {
                        const lastInRangeIndexForPath =
                            lastInRangeIndex == null ? undefined : lastInRangeIndex - o.startIndex;
                        return (
                            o.points &&
                            o.points.length > 1 && (
                                <React.Fragment key={i}>
                                    <Circle
                                        x={o.points[0].x}
                                        y={o.points[0].y}
                                        color={pathColor}
                                        radius={theme.space[2] / mapScale}
                                        segments={24}
                                        animateEnterExit
                                        zIndex={ZIndexes.Underlay}
                                    />
                                    {lastInRangeIndexForPath == null && (
                                        <Line
                                            x={0}
                                            y={0}
                                            points={o.points}
                                            width={theme.space[2]}
                                            color={pathColor}
                                            animateEnterExit
                                            zIndex={ZIndexes.Underlay}
                                        />
                                    )}
                                    {lastInRangeIndexForPath != null && lastInRangeIndexForPath > 0 && (
                                        <Line
                                            x={0}
                                            y={0}
                                            points={o.points.slice(0, lastInRangeIndexForPath + 1)}
                                            width={theme.space[2]}
                                            color={pathColorValid}
                                            animateEnterExit
                                            zIndex={ZIndexes.Underlay}
                                        />
                                    )}
                                    {lastInRangeIndexForPath != null &&
                                        lastInRangeIndexForPath < o.points.length - 1 && (
                                            <Line
                                                x={0}
                                                y={0}
                                                points={o.points.slice(lastInRangeIndexForPath)}
                                                width={theme.space[2]}
                                                color={pathColorInvalid}
                                                animateEnterExit
                                                zIndex={ZIndexes.Underlay}
                                            />
                                        )}
                                    <Circle
                                        x={o.points[o.points.length - 1].x}
                                        y={o.points[o.points.length - 1].y}
                                        color={pathColor}
                                        radius={theme.space[2] / mapScale}
                                        segments={24}
                                        animateEnterExit
                                        zIndex={ZIndexes.Underlay}
                                    />
                                    {token.waypoints &&
                                        token.waypoints.map((o, i) => {
                                            const finalGridPoint = o.path[o.path.length - 1];
                                            if (finalGridPoint.level !== levelKey) {
                                                return undefined;
                                            }

                                            const finalLocalPoint = grid.toLocalCenterPoint(finalGridPoint);
                                            return (
                                                <Circle
                                                    key={i}
                                                    x={finalLocalPoint.x}
                                                    y={finalLocalPoint.y}
                                                    color={pathColor}
                                                    radius={theme.space[2] / mapScale}
                                                    segments={24}
                                                    animateEnterExit
                                                    zIndex={ZIndexes.Underlay}
                                                />
                                            );
                                        })}
                                    {!showPathAdorner &&
                                        o.points[o.points.length - 1].level === levelKey &&
                                        shownPathCost > 0 &&
                                        o.startIndex + o.points.length === shownPath!.length && (
                                            <DistanceMessage
                                                pos={localPoint(
                                                    o.points[o.points.length - 1].x,
                                                    o.points[o.points.length - 1].y
                                                )}
                                                distance={shownPathCost}
                                                horizontalAlignment="center"
                                                verticalAlignment="center"
                                            />
                                        )}
                                </React.Fragment>
                            )
                        );
                    })}
            </AnimatePresenceRepeater>
            <DraggableTokenImage
                imageUri={tokenAppearance.imageUri ?? "defaulttoken.png"}
                x={currentPos.x}
                y={currentPos.y}
                tokenLevel={token.pos.level}
                renderLevel={levelKey}
                xAnim={xAnim}
                yAnim={yAnim}
                scaleAnim={scaleAnim}
                rotationAnim={rotationAnim}
                zIndex={
                    token.zIndex != null
                        ? token.zIndex
                        : token.renderScale != null && token.renderScale > 1
                        ? ZIndexes.Tokens + 1
                        : undefined
                }
                path={token.prevPath}
                prevPath={prevPath}
                onOverrideToken={override => onOverrideToken(token.id, override)}
                isDragging={!!isDragging}
                isSelected={isSelected}
                isTarget={isTarget}
                isWarningTarget={isWarningTarget}
                ownerPalette={ownerPalette}
                ownerColor={ownerColor}
                preDragPos={oldPos}
                onClick={isInteractive ? onTokenClick : undefined}
                onPointerDown={isInteractive ? (onPointerDown ? e => onPointerDown(e, token) : undefined) : undefined}
                onPointerUp={isInteractive ? (onPointerUp ? e => onPointerUp(e, token) : undefined) : undefined}
                scale={scale}
                defaultRotation={tokenAppearance.defaultRotation}
                rotation={token.withoutOverride ? token.withoutOverride.rotation : token.rotation}
                renderScale={token.renderScale}
                overrideRotation={token.overrideDelta?.rotation}
                dragRotate={dragRotate}
                canRotate={canRotate}
                cursor={disableDrag ? undefined : "pointer"}
                opacity={isAnimationOnlyAndHidden ? 0 : opacity}
                onPointerOver={() => setIsHoverImage(true)}
                onPointerOut={() => setIsHoverImage(false)}
                disableDrag={disableDrag}
                errorMessage={() => {
                    const name = system.getDisplayName(token, campaign) ?? "token";
                    return `Failed to download image for ${name}.`;
                }}
                onDragStart={(e, cancelDrag) => {
                    onOverrideToken(token.id, {
                        isDragging: true,
                        currentLevel: token.pos.level,
                    });
                    setOldPos(token.pos);
                    e.nativeEvent.preventDefault();
                    e.nativeEvent.stopPropagation();
                    cancelDragRef.current = cancelDrag;

                    startCanvasDrag(token);
                }}
                onDragMove={(e, pos) => {
                    // Sometimes because of scheduling these events sneak in before the things we set in onDragStart
                    // make it back to us.
                    if (!oldPos || !token.currentLevel) {
                        return;
                    }

                    // Get the old position bounds, and the center of the old position. The difference is the same offset we want to apply here.
                    const dragGridPos = getGridDragPos(grid, scale, pos);

                    // Only recalculate the path to the destination grid point when the grid point actually changes.
                    let level = token.currentLevel!;
                    const previousGridPoint =
                        previousGridPath.current && previousGridPath.current.path.length
                            ? previousGridPath.current.path[previousGridPath.current.path.length - 1]
                            : undefined;
                    let currentGridPath = token.currentGridPath;
                    if (previousGridPoint == null || !pointsEqual(previousGridPoint, dragGridPos)) {
                        const finalWaypoint = token.waypoints?.length
                            ? token.waypoints[token.waypoints.length - 1]
                            : undefined;
                        const startPoint =
                            finalWaypoint?.path[finalWaypoint.path.length - 1] ??
                            Object.assign({}, gridPos, { level: oldPos.level });

                        if (pathFinder && previousGridPoint) {
                            // Check to see if we've changed levels by moving to the current point.
                            level = pathFinder.getLevel(previousGridPoint, dragGridPos);
                        }

                        // Find the path from the token's current actual position to the position that we're hovering over.
                        const gridPath = pathFinder?.findMovementPath(
                            token,
                            startPoint,
                            { ...dragGridPos, level: level },
                            finalWaypoint
                        );
                        currentGridPath = gridPath;
                    }

                    if (hasDropOffer()) {
                        // The token is being dropped onto some other (non-map) target.
                        onOverrideToken(token.id, {
                            isDragging: true,
                            currentLevel: level,
                            dragPos: undefined,
                        });
                    } else {
                        // If this is the GM, OR if the player is dragging their token within the same square
                        // that they are already in, then override the token so that the change affects vision
                        // LOS and lighting, etc. If not, then avoid showing the player something they shouldn't
                        // see yet by overriding the position locally so that it doesn't affect vision.
                        const dragPos = Object.assign({}, pos, { level: level });
                        if (
                            role === "GM" ||
                            (oldPos &&
                                dragGridPos.x === (oldPos as GridPosition | LocalPixelPosition).x &&
                                dragGridPos.y === (oldPos as GridPosition | LocalPixelPosition).y)
                        ) {
                            onOverrideToken(token.id, {
                                pos: dragPos,
                                isDragging: true,
                                waypoints: token.waypoints,
                                currentGridPath: currentGridPath,
                                currentLevel: level,
                                dragPos: dragPos,
                            });
                        } else {
                            onOverrideToken(token.id, {
                                isDragging: true,
                                currentGridPath: currentGridPath,
                                waypoints: token.waypoints,
                                currentLevel: level,
                                dragPos: dragPos,
                            });
                        }
                    }
                }}
                onDragEnd={(e, pos) => {
                    // Sometimes because of scheduling these events sneak in before the things we set in onDragStart
                    // make it back to us.
                    if (!oldPos || !token.currentLevel) {
                        onOverrideToken(token.id, undefined);
                        setOldPos(undefined);
                        cancelDragRef.current = undefined;
                        return;
                    }

                    const gridPoint = getGridDragPos(grid, scale, pos) as WithLevel<GridPosition>;
                    gridPoint.level = token.currentLevel!;

                    if (!hasDropOffer()) {
                        const finalWaypoint = token.waypoints?.length
                            ? token.waypoints[token.waypoints.length - 1]
                            : undefined;
                        const startPoint = finalWaypoint
                            ? finalWaypoint.path[finalWaypoint.path.length - 1]
                            : Object.assign({}, gridPos, { level: oldPos!.level });
                        const gridPath = pathFinder?.findMovementPath(token, startPoint, gridPoint, finalWaypoint);

                        // You can only drop onto a point that doesn't have a path to it if you're the GM.
                        let canMove = false;
                        if (role === "GM") {
                            canMove = true;
                        } else {
                            canMove = !!(gridPath && gridPath.path.length);
                        }

                        if (canMove) {
                            const finalPath =
                                gridPath && gridPath.path.length
                                    ? token.waypoints
                                        ? buildPath([...token.waypoints, gridPath])
                                        : gridPath.path
                                    : undefined;
                            dispatch(
                                moveToken(
                                    campaign,
                                    location,
                                    token,
                                    finalPath ?? [gridPoint],
                                    shownPathCost,
                                    role !== "GM",
                                    dragRotate ?? 0
                                )
                            );
                        }

                        endCanvasDrag(true);
                    } else {
                        endCanvasDrag();
                    }

                    onOverrideToken(token.id, undefined);
                    setOldPos(undefined);
                    cancelDragRef.current = undefined;
                }}
            />
            {token.modelUri && hasBeenPerspective && !isAnimationOnly && (
                <UiLayer>
                    <TokenModelInternal
                        xAnim={xAnim}
                        yAnim={yAnim}
                        rotationAnim={rotationAnim}
                        scaleAnim={scaleAnim}
                        isPlayerVisible={isPlayerVisible}
                        isOwnerOrGM={isOwnerOrGM}
                        modelUri={token.modelUri}
                        errorMessage={() => {
                            const name = system.getDisplayName(token, campaign) ?? "token";
                            return `Failed to download model for ${name}.`;
                        }}
                        pos={gridCenterPos}
                    />
                </UiLayer>
            )}
            <HtmlAdorner
                pos={showPathAdorner ? grid.toLocalPoints(token.nextPath![token.nextPath!.length - 1]) : undefined}
                boundingPosHorizontal="c"
                boundingPosVertical="c">
                {showPathAdorner && (
                    <Box>
                        <MotionToolbar
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}>
                            {shownPathCosts != null && (
                                <ToolbarButton
                                    tooltip="Accept move"
                                    tooltipDirection="up"
                                    onClick={() => {
                                        if (token.nextPath) {
                                            // TODO: Rotation from the previous path doesn't get passed through. Is this even needed?
                                            dispatch(
                                                moveToken(
                                                    campaign,
                                                    location,
                                                    token,
                                                    token.nextPath,
                                                    shownPathCost ?? 0,
                                                    false,
                                                    undefined
                                                )
                                            );
                                        }
                                    }}>
                                    <CheckIcon />
                                </ToolbarButton>
                            )}
                            <ToolbarButton
                                tooltip="Deny move"
                                tooltipDirection="up"
                                onClick={() => {
                                    dispatch(
                                        modifyToken(campaign, location, token, {
                                            nextPath: undefined,
                                        })
                                    );
                                }}>
                                <Cross1Icon />
                            </ToolbarButton>
                        </MotionToolbar>
                    </Box>
                )}
            </HtmlAdorner>
            <HtmlAdorner pos={logPos} boundingPosHorizontal="ro" boundingPosVertical="to">
                {logPos && (
                    <Box style={{ transform: "translateY(-100%)" }} position="absolute">
                        <SpeechBubble
                            bg="grayscale.8"
                            p={2}
                            borderRadius={3}
                            flexDirection="column"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20, transition: { ease: "easeOut" } }}>
                            <AnimatePresence>
                                {logEntries.map(logEntry => (
                                    <MotionBox
                                        key={`${logEntry.userId}_${logEntry.location ?? ""}_${logEntry.token ?? ""}_${
                                            logEntry.time
                                        }_${logEntry.type}`}
                                        initial={defaultInitial}
                                        animate={defaultAnimate}
                                        exit={defaultExit}
                                        fullWidth>
                                        <SpeechLogEntry token={token} logEntry={logEntry} system={system} />
                                    </MotionBox>
                                ))}
                            </AnimatePresence>
                        </SpeechBubble>
                    </Box>
                )}
            </HtmlAdorner>
        </>
    );
};

export const TokenNode = React.memo(TokenNodeCore);

import React from 'react'

const SecretDoorIcon = ({
    size = undefined,
    color = undefined,
    ...props
}) => (
    <svg
        {...props}
        viewBox='0 0 512 512'
        width={size}
        height={size}
        stroke={color}
    >
        <path d="M225.134 37.867l3.771 17.598c11.024-1.968 22.743-3.02 33.1-2.486a485.577 485.577 0 0 0 1.467-17.938c-12.817-.293-26.905.58-38.338 2.826zm57.02-.681l-2.77 17.783c10.998 2.103 22.317 5.312 31.799 9.513a485.513 485.513 0 0 0 7.783-16.226c-12.217-5.255-25.14-9.101-36.813-11.07zm-75.272 5.586c-12.608 4.229-24.804 9.981-34.817 16.296l9.793 15.1c9.6-5.765 20.174-10.92 30.041-14.111-1.594-5.892-3.403-12.125-5.017-17.285zm129.492 13.8l-10.033 14.942c9.075 6.56 17.974 14.255 24.787 22.074a485.505 485.505 0 0 0 13.918-11.41c-8.846-9.93-18.929-18.882-28.672-25.606zM156.558 71.488c-9.127 9.673-17.171 20.493-23.026 30.782l15.754 8.703c5.75-9.61 12.645-19.141 19.844-26.606a485.614 485.614 0 0 0-12.572-12.879zm219.795 27.143l-15.643 8.898c5.198 9.918 9.731 20.775 12.344 30.81 5.974-1.25 12.3-2.693 17.545-4.005-3.49-12.833-8.523-25.34-14.246-35.703zm-250.516 21.625c-3.667 12.605-6.046 25.205-6.711 37.851l17.984.682c.822-10.971 2.71-22.746 5.75-32.69a485.658 485.658 0 0 0-17.023-5.843zm266.8 33.322l-17.96 1.145c.6 11.646.324 23.322.324 35.416h18c-.156-12.022.357-25.143-.363-36.56zm-273.636 22.865v35.998h18v-35.998zm256 31.694v35.996h18v-35.996zm-256 22.302v35.997h18v-35.997zm256 31.692v35.996h18v-35.996zm-256 22.303v35.996h18v-35.996zm185 14.58v18h48v-18zm71 17.111v35.996h18v-35.996zm-256 22.303v35.996h18v-35.996zm256 31.693v35.996h18v-35.996zm-256 22.3v35.997h18v-35.996zm256 31.694v35.996h18v-35.996zm-256 22.301c-.021 10.262-.068 20.336 0 30.598 12.952-.334 25.972 0 38.928 0v-18H137v-12.598zm56.926 12.598v18h35.996v-18zm53.994 0v18h35.996v-18zm53.994 0v18h35.996v-18zm53.994 0v18h35.996v-18z" fill={color}></path>
    </svg>
)

SecretDoorIcon.displayName = 'SecretDoorIcon'

SecretDoorIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
}

export default SecretDoorIcon 
import React from 'react'
  
  const BuildModeIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 512 512'
      width={size}
      height={size}
      stroke={color}
    >
      <path d="M172.416 41.021c-39.47.351-78.748 5.972-114.732 14.827 10.094 15.264 27.17 26.95 46.898 34.865 23.65 9.488 50.72 13.333 70.959 12.299 20.826-1.065 47.765-9.524 68.764-21.008 10.5-5.742 19.542-12.245 25.652-18.5 3.24-3.317 5.517-6.486 6.99-9.316-30.874-8.778-63.696-12.865-96.635-13.167a438.026 438.026 0 0 0-7.896 0zm111.541 33.877c-.372.396-.743.793-1.125 1.184-7.888 8.075-18.231 15.34-29.889 21.715-23.314 12.75-51.772 21.928-76.484 23.191-23.227 1.188-52.158-2.967-78.58-13.568-18.094-7.26-35.189-17.651-47.762-31.873C33.802 206.86 19.325 353.169 39.992 473.012c19.598 6.163 40.992 10.825 63.008 13.95V423h64v-64h109.957c-.024-3.013.152-6.295.486-9.97.96-10.546 3.217-24.018 6.338-39.007 5.408-25.967 13.412-56.318 21.948-82.152-4.95-49.133-12.133-100.876-21.772-152.973zm152.682.59c-.909.002-1.93.107-2.87.137l-56.949 71.28c7.692 2.471 14.598 7.387 19.639 14.052l55.268-66.045c.901-2.06.935-7.813-2.174-12.293-2.433-3.505-5.967-6.446-12.051-7.092-.27-.028-.56-.04-.863-.039zm-69.506 87.742a31.857 31.857 0 0 0-4.278 6.745c-3.85 8.26-4.52 17.07-3.816 24.61l.022.224 3.507 24.408a201.33 201.33 0 0 0-5.46.586c-11.149 1.362-23.392 3.885-31.805 6.601-9.124 26.31-18.124 59.552-23.9 87.29-3.034 14.562-5.182 27.607-6.034 36.966-.268 2.945-.188 4.657-.203 6.729 2.338-1.204 5.048-2.731 8.326-4.871 9.674-6.317 22.502-16.22 36.13-27.565 25.535-21.259 53.706-47.399 71.997-65.816-2.982-7.624-8.213-16.243-13.951-23.516-6.682-8.469-14.49-15.144-16.53-16.408-.057.008-.262-.026-.392-.04l-3.783-26.323c-.448-4.91.237-11.044 2.207-15.27.975-2.092 2.012-3.727 3.46-4.951-3.847-5.382-9.725-8.894-15.497-9.399zM377 313v46h110v-46H377zm-192 64v46h110v-46H185zm128 0v46h110v-46H313zm128 0v46h46v-46h-46zm-320 64v46h110v-46H121zm128 0v46h110v-46H249zm128 0v46h110v-46H377z" fill={color} fillOpacity="1"></path>
    </svg>
  )
  
  BuildModeIcon.displayName = 'BuildModeIcon'
  
  BuildModeIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default BuildModeIcon
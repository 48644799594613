/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, {
    useMemo,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
    PropsWithChildren,
    ReactNode,
    useCallback,
    useId,
    useImperativeHandle,
} from "react";
import styled from "@emotion/styled";
import { Box, Card, Heading, Image } from "../primitives";
import ReactMarkdown from "react-markdown";
import { visit, Visitor, Node as UnistNode } from "unist-util-visit";
import remarkDirective from "remark-directive";
import remarkGfm from "remark-gfm";
import { useCampaign } from "../contexts";
import { CustomMarkdownNode, IGameSystem, ImageType, Profile } from "../../store";
import { Milkdown, MilkdownProvider, useEditor } from "@milkdown/react";
import { Editor, rootCtx, defaultValueCtx, editorViewCtx, serializerCtx } from "@milkdown/core";
import { $Node, $node, $prose, $remark, $view, replaceAll } from "@milkdown/utils";
import { listener, listenerCtx } from "@milkdown/plugin-listener";
import { EditorView } from "@milkdown/prose/view";
import { asField, IAsFieldProps } from "../Form";
import { BaseTheme, theme } from "../../design";
import { ExtractProps, resolveUri } from "../common";
import { Button } from "../Button";
import { Node } from "@milkdown/prose/model";
import { ProsemirrorAdapterProvider, useNodeViewContext, useNodeViewFactory } from "@prosemirror-adapter/react";
import { NodeSchema } from "@milkdown/transformer";
import { gfm } from "@milkdown/preset-gfm";
import { commonmark, headingSchema, imageSchema } from "@milkdown/preset-commonmark";
import { MarkdownMenu } from "./menu";
import { Plugin } from "@milkdown/prose/state";
import { Event } from "../../common";
import { dragDropPalette, useTypedDroppable } from "../draggable";
import { LibraryItem } from "../../library";
import { MotionBox } from "../motion";
import { mergeRefs } from "react-merge-refs";

import "prosemirror-view/style/prosemirror.css";
import "prosemirror-tables/style/tables.css";
import { TextPopup, defaultPopupDelay } from "../TextPopup";
import { loadProfiles } from "../../userprofiles";
import { useErrorHandler } from "../utils";
import { Tag } from "../Tag";
import { motion } from "framer-motion";

// import { Plugin } from '@milkdown/prose/state';
// import { chainCommands } from "prosemirror-commands";
// import { keymap } from "prosemirror-keymap";
// import { chainCommands } from "@milkdown/prose/commands";

type DivProps = React.HTMLAttributes<HTMLDivElement> & {
    theme: BaseTheme;
    as?: React.ElementType<any> | undefined;
};

const footnoteStyling = (props: DivProps) => `
.footnote-definition {
    border: 2px solid ${props.theme.colors.grayscale[7]};
    border-radius: ${props.theme.radii[3]};
    background-color: ${props.theme.colors.background};

    padding: 16px;
    display: flex;
    flex-direction: row;
    & > .footnote-definition_content {
        flex: 1;
        width: calc(100% - 16px);
        & > dd {
            margin-inline-start: 16px;
        }
        & > dt {
            color: ${props.theme.colors.grayscale[7]};
            font-weight: 500;
        }
    }
    & > .footnote-definition_anchor {
        width: 16px;
    }
}
`;

const inlineStyling = (props: DivProps) => `
.code-inline {
    background-color: ${props.theme.colors.foreground};
    color: ${props.theme.colors.background};
    border-radius: ${props.theme.radii[2]}px;
    font-weight: 500;
    font-family: ${props.theme.fonts["monospace"]};
    padding: 0 ${props.theme.space[1]}px;
}

.strong {
    font-weight: 600;
}

.strike-through {
    text-decoration-color: ${props.theme.colors.background};
}
`;

const imageStyling = (props: DivProps) => `
.image {
    display: inline-block;
    margin: 0 auto;
    object-fit: contain;
    width: 100%;
    position: relative;
    height: auto;
    text-align: center;
}
`;

const codeStyling = (props: DivProps) => `
.code-fence {
    pre {
        font-family: "${props.theme.fonts["monospace"]};
        margin: 0 ${props.theme.space[3]}px;

        background-color: ${props.theme.colors.background};
        color: ${props.theme.colors.foreground};
        font-size: ${props.theme.fontSizes[1]}px;
        border-radius: ${props.theme.radii[3]};

        code {
            line-height: 1.5;
            font-family: "${props.theme.fonts["monospace"]};
        }
    }
}
`;

const hrStyling = (props: DivProps) => `
hr {
    height: 2px;
    background-color: ${props.theme.colors.grayscale[7]};
    border-width: 0;
}
`;

const blockquoteStyling = (props: DivProps) => `
blockquote {
    padding-left: 30px;
    line-height: 28px;
    border-left: 4px solid ${props.theme.colors.accent[4]};
    margin-left: 0;
    margin-right: 0;
    * {
        font-size: 16px;
        line-height: 24px;
    }
}
`;

const listStyling = (props: DivProps) => `
ul,
ol {
    list-style-position: inside;

    p {
        display: inline;
    }
}

ol ol, ul ul {
    margin-inline-start: 1em;
}

ul>li {
    list-style-type: disc;
}
`;

const linkStyling = (props: DivProps) => `
a {
    color: ${props.theme.colors.accent[4]};

    &:hover {
        color: ${props.theme.colors.accent[3]};
        text-decoration: underline;
    }
}
`;

const tableStyling = (props: DivProps) => `
table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    box-sizing: border-box;
    border: 2px solid ${props.theme.colors.transparency[0].grayscale[7]};
    border-radius: ${props.theme.radii[3]}px;
    table-layout: fixed;

    th, td {
        vertical-align: top;
        box-sizing: border-box;

        &:last-child {
            padding-right: 0;
        }

        padding: 0 ${props.theme.space[2]}px;
        vertical-align: inherit;
        box-sizing: inherit;
        min-width: auto;
        line-height: inherit;
        height: auto;
        vertical-align: middle;
    }

    th {
        padding-top: ${props.theme.space[2]}px;
        padding-bottom: ${props.theme.space[2]}px;

        font-size: ${props.theme.fontSizes[1]}px;
        color: ${props.theme.colors.grayscale[2]};
        font-weight: ${props.theme.fontWeights.normal};
        background-color: ${props.theme.colors.transparency[2].grayscale[8]};
        padding-right: ${props.theme.space[3]}px;
        border-bottom: 2px solid ${props.theme.colors.grayscale[7]};

        &:first-of-type {
            padding-left: ${props.theme.space[3]}px;
            border-radius: ${props.theme.radii[3]}px 0 0 0;
        }

        &:last-child {
            padding-right: ${props.theme.space[3]}px;
            border-radius: 0 ${props.theme.radii[3]}px 0 0;
        }
    }

    tbody {
        tr {
            td {
                padding-top: ${props.theme.space[2]}px;
                padding-bottom: ${props.theme.space[2]}px;
                padding-right: ${props.theme.space[3]}px;
    
                border-bottom: 1px solid ${props.theme.colors.grayscale[7]};

                &:first-of-type {
                    padding-left: ${props.theme.space[3]}px;
                }

                &:last-child {
                    padding-right: ${props.theme.space[3]}px;
                }
            }

            &:first-of-type {
                td {
                    padding-top: ${props.theme.space[2]}px;
                }
            }

            &:last-child {
                td {
                    border-bottom: 0;
                    padding-bottom: ${props.theme.space[2]}px;
                }
            }
        }
    }
}
`;

// ul {
//     list-style-type: disc;
//     margin-left: ${props => props.theme.space[4]}px;
//     list-style-position: outside;
// }

// ol {
//     margin-left: ${props => props.theme.space[4]}px;
//     list-style-position: outside;
// }

const MarkdownBox = styled(Box)`
    display: block;
    text-align: start;
    flex-shrink: 1;
    color: inherit;

    p,
    ul,
    ol,
    table {
        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    ${props => tableStyling(props)}
    ${props => listStyling(props)}
    ${props => blockquoteStyling(props)}
    ${props => hrStyling(props)}
    ${props => codeStyling(props)}
    ${props => imageStyling(props)}
    ${props => inlineStyling(props)}
    ${props => footnoteStyling(props)}
    ${props => linkStyling(props)}
`;

function isMatch(node: UnistNode, customNode: CustomMarkdownNode) {
    if (node.type === customNode.name) {
        return true;
    }

    if (node["name"] !== customNode.name) {
        return false;
    }

    if (customNode.type === "container" && node.type !== "containerDirective") {
        return false;
    }

    if (customNode.type === "block" && node.type !== "leafDirective") {
        return false;
    }

    if (customNode.type === "inline" && node.type !== "textDirective") {
        return false;
    }

    return true;
}

const Note: FunctionComponent<PropsWithChildren<{ label?: ReactNode }>> = props => {
    const children = React.Children.toArray(props.children);
    return (
        <Card mb="1em" borderRadius={4} bg="transparency.2.grayscale.9" boxShadowSize="none" flexDirection="column">
            {children.length > 1 && (
                <Heading as="h6" px={3} py={2} bg="transparency.1.grayscale.8">
                    {children[0]}
                </Heading>
            )}
            <Box px={3} py={2} flexDirection="column" alignItems="flex-start">
                {children.length > 1 ? children.slice(1) : children}
            </Box>
        </Card>
    );
};

const EditableNote = React.forwardRef<HTMLElement, PropsWithChildren<{ node?: Node }>>(({ children, node }, ref) => {
    const childrenCount = node?.childCount ?? React.Children.count(children);
    return (
        <Card
            ref={ref as any}
            css={
                childrenCount > 1
                    ? {
                          ">div[data-node-view-content=true]>p:first-child": {
                              fontWeight: theme.fontWeights.light,
                              lineHeight: theme.lineHeights[4],
                              fontSize: theme.fontSizes[3],
                              background: theme.colors.grayscale[8],
                              paddingTop: theme.space[2],
                              paddingLeft: theme.space[3],
                              paddingRight: theme.space[3],
                              paddingBottom: theme.space[2],
                              marginBottom: theme.space[2],
                          },
                          ">div[data-node-view-content=true]>*:not(:first-child)": {
                              paddingLeft: theme.space[3],
                              paddingRight: theme.space[3],
                          },
                          textShadow: "none",
                      }
                    : {
                          padding: theme.space[2],
                          textShadow: "none",
                      }
            }
            mb="1em"
            borderRadius={4}
            border={2}
            borderStyle="solid"
            borderColor="grayscale.8"
            bg="grayscale.9"
            boxShadowSize="m"
            paddingBottom={2}
            flexDirection="column">
            {children}
        </Card>
    );
});

const HandoutPopup = React.forwardRef<HTMLElement, PropsWithChildren<{ id?: string }>>(({ id, children }, ref) => {
    const { campaign } = useCampaign();
    const handout = id != null ? campaign.handouts[id] : undefined;

    if (handout) {
        return (
            <TextPopup ref={ref} text={children} key={id} delay={defaultPopupDelay}>
                <Box flexDirection="column" alignItems="flex-start">
                    <Heading as="h6">{handout.label}</Heading>
                    <Markdown>{handout.content}</Markdown>
                </Box>
            </TextPopup>
        );
    }

    return <strong ref={ref}>{children}</strong>;
});

const UserPopup = React.forwardRef<HTMLElement, PropsWithChildren<{ id?: string }>>(({ id, children }, ref) => {
    const errorHandler = useErrorHandler();
    const [profile, setProfile] = useState<Profile>();
    useEffect(() => {
        if (id) {
            (async () => {
                const profiles = await loadProfiles([id], errorHandler);
                setProfile(profiles[0]);
            })();
        }
    }, [id, errorHandler]);

    const { campaign } = useCampaign();
    const campaignPlayer = profile ? campaign.players[profile.userId] : undefined;

    if (profile) {
        return (
            <TextPopup ref={ref} text={children} key={id} delay={defaultPopupDelay}>
                <Box flexDirection="column" alignItems="flex-start">
                    <Heading as="h6" mb={2}>
                        {profile.name}
                    </Heading>
                    <Tag
                        bg={
                            (campaignPlayer?.colour ? theme.colors[campaignPlayer.colour]?.[7] : undefined) ??
                            theme.colors.accent[7]
                        }
                        color={
                            (campaignPlayer?.colour ? theme.colors[campaignPlayer.colour]?.[0] : undefined) ??
                            theme.colors.accent[0]
                        }>
                        {campaignPlayer?.role === "GM" ? "GM" : "Player"}
                    </Tag>
                </Box>
            </TextPopup>
        );
    }

    return <strong ref={ref}>{children}</strong>;
});

function getMarkdownNodes(system: IGameSystem | undefined, editable: boolean) {
    const nodes = system?.getMarkdownNodes ? system.getMarkdownNodes() : [];

    // :::note[note label]
    // This stuff is the note
    // :::
    nodes.push({
        name: "note",
        type: "container",
        attributes: {},
        render: editable ? props => <EditableNote {...props} /> : props => <Note {...props} />,
    });

    // :handout[label]{id="fjdfkj2l_j32l4"}
    nodes.push({
        name: "handout",
        type: "inline",
        attributes: {
            id: { default: undefined },
        },
        updateAttributes: attributes => {
            // TODO: Need to access the latest campaign from here somehow if we want to be able to add a default label.
            // Perhaps it's best we just enforce that handouts must specify a label, since in theory some people might not
            // have read access to the referenced handout anyway.

            // If we do implement this at some point, should just need to set the label attribute if it's not already set.
            return attributes;
        },
        render: props => <HandoutPopup {...props} />,
    });

    nodes.push({
        name: "user",
        type: "inline",
        attributes: {
            id: { default: undefined },
        },
        updateAttributes: attributes => {
            // TODO: Need to get the profile to provide a default label here, but that's async.
            return attributes;
        },
        render: props => <UserPopup {...props} />,
    });

    return nodes;
}

function getNodeAttributes(node: UnistNode) {
    let properties = Object.assign({}, node["attributes"] as any);
    return properties;
}

/**
 * Lightweight markdown renderer, use for displaying markdown where possible.
 */
export const Markdown = React.forwardRef<HTMLDivElement, ExtractProps<typeof Box> & { noSystem?: boolean }>(
    ({ children, fullWidth, noSystem, ...props }, ref) => {
        const campaignData = useCampaign();

        const system = noSystem ? undefined : campaignData?.system;
        const customNodes = useMemo(() => getMarkdownNodes(system, false), [system]);
        const plugin = useMemo(() => {
            return () => {
                return function transformer(tree: UnistNode) {
                    const visitor: Visitor = node => {
                        for (let i = 0; i < customNodes.length; i++) {
                            if (isMatch(node, customNodes[i])) {
                                const data = node.data || (node.data = {});
                                data.hName = customNodes[i].name;

                                let properties = node["attributes"] as any;
                                let labelHost: { value: string } | undefined;
                                const children = node["children"] as any[] | undefined;
                                if (
                                    (node.type === "textDirective" || node.type === "leafDirective") &&
                                    children &&
                                    children.length
                                ) {
                                    labelHost = children[0];
                                    properties.label = labelHost?.value;
                                } else if (node.type === "containerDirective" && children && children.length) {
                                    const labelIndex = children?.findIndex(o => o.data?.directiveLabel);
                                    if (labelIndex != null && labelIndex >= 0) {
                                        labelHost = children[labelIndex].children[0];
                                        properties.label = labelHost?.value;
                                    }
                                }

                                properties = customNodes[i].updateAttributes?.(properties) ?? properties;
                                if (labelHost) {
                                    labelHost.value = properties.label;
                                }

                                node["attributes"] = properties;
                                data.hProperties = properties;
                                break;
                            }
                        }
                    };

                    visit(tree, ["containerDirective", "textDirective", "leafDirective"], visitor);
                };
            };
        }, [customNodes]);
        const components = useMemo(() => {
            const c: any = {};

            for (let i = 0; i < customNodes.length; i++) {
                c[customNodes[i].name] = props => {
                    // If there is no children, but there is a label, use that by default.
                    if (!props.children && props.label) {
                        props = { ...props, children: [props.label] };
                    }

                    return customNodes[i].render(props);
                };
            }

            c["h1"] = ({ node, ...props }) => <Heading as="h3" {...props} />;
            c["h2"] = ({ node, ...props }) => <Heading as="h4" {...props} />;
            c["h3"] = ({ node, ...props }) => <Heading as="h6" {...props} />;

            c["li"] = ({ node, ordered, index, ...props }) => {
                return (
                    <li
                        data-label={ordered ? undefined : "•"}
                        data-list-type={ordered ? undefined : "bullet"}
                        {...props}
                    />
                );
            };

            c["img"] = ({ node, alt, title, src }) => {
                return (
                    <Image
                        title={title ?? alt}
                        alt={alt ?? title}
                        src={resolveUri(src)}
                        css={{ maxWidth: "100%", height: "auto", objectFit: "contain" }}></Image>
                );
            };

            return c;
        }, [customNodes]);

        return (
            <MarkdownBox ref={ref} {...props} css={{ width: fullWidth ? "100%" : undefined }}>
                {React.Children.map(children, (child, i) => {
                    // Replace all strings with markdown elements, but leave other children so that you can (for e.g.) add
                    // floated divs that the markdown content will flow around.
                    if (typeof child === "string") {
                        return (
                            <ReactMarkdown
                                key={i}
                                remarkPlugins={[remarkGfm, remarkDirective, plugin]}
                                components={components}>
                                {child}
                            </ReactMarkdown>
                        );
                    }

                    return child;
                })}
            </MarkdownBox>
        );
    }
);

const CustomEditorNode = () => {
    const context = useNodeViewContext();
    const customNode = context.node.attrs.customNode as CustomMarkdownNode;
    return customNode.render(Object.assign({}, context.node.attrs, { ref: context.contentRef, node: context.node }));
};

function customMarkdownNodeToMilkdownNode(customNode: CustomMarkdownNode): $Node {
    const node: $Node = $node(customNode.name, ctx => {
        const schema: NodeSchema = {
            inline: customNode.type === "inline",
            attrs: Object.assign({}, customNode.attributes, {
                customNode: {},
                label: { default: undefined },
            }),
            group: customNode.type === "inline" ? "inline" : "block",
            marks: "",
            atom: customNode.selectable && customNode.type !== "container",
            code: false,
            defining: customNode.selectable,
            isolating: customNode.selectable,
            content: customNode.type === "inline" ? "text*" : "paragraph+",
            parseMarkdown: {
                match: node => isMatch(node, customNode),
                runner: (state, node, type) => {
                    let attrs = getNodeAttributes(node);

                    let children: any[] | undefined;

                    if (node.children && node.children.length) {
                        children = node.children?.slice();
                        if (children) {
                            const i = children.findIndex(o => o.type === "text");
                            if (i >= 0) {
                                const label = children[i].value;
                                attrs["label"] = label;
                                children.splice(i, 1);
                            }
                        }
                    }

                    if (customNode.updateAttributes) {
                        attrs = customNode.updateAttributes(attrs);
                    }

                    attrs["customNode"] = customNode;

                    if (children && children.length) {
                        state.openNode(type, attrs).next(children).closeNode();
                    } else {
                        // For inline ones, need to add a child even if one isn't provided...
                        // i.e. if we have :item{id=longsword~phb}, then even there's no label (no
                        // child node), then we have to add one in, because otherwise we can't edit it.
                        if ((customNode.type === "inline" || customNode.type === "block") && attrs.label) {
                            state.openNode(type, attrs);
                            state.addText(attrs.label);
                            state.closeNode();
                        } else {
                            state.addNode(type, attrs);
                        }
                    }
                },
            },
            toMarkdown: {
                match: node => node.type.name === customNode.name,
                runner: (state, node) => {
                    let attrs = Object.assign({}, node.attrs) as any;
                    delete attrs["customNode"];
                    delete attrs["label"];

                    if (customNode.type === "container") {
                        let s = state.openNode("containerDirective", undefined, {
                            name: customNode.name,
                            attributes: attrs,
                        });

                        // The label will be the first paragraph node, if there are more than 1 children.
                        let childIndex = 0;
                        if (node.childCount > 1) {
                            childIndex = 1;
                            const labelNode = node.child(0);
                            s = s.openNode("paragraph", undefined, {
                                data: { directiveLabel: true },
                            });
                            s = s.addNode("text", undefined, labelNode.textContent);
                            s = s.closeNode();
                        }

                        for (let n = childIndex; n < node.childCount; n++) {
                            s = s.next(node.child(n));
                        }

                        s = s.closeNode();
                    } else {
                        const nodeType = customNode.type === "inline" ? "textDirective" : "leafDirective";
                        const nodeProps = {
                            name: customNode.name,
                            attributes: attrs,
                        };
                        if (node.childCount > 0) {
                            let s = state.openNode(nodeType, undefined, nodeProps);

                            const child = node.firstChild;
                            const text = child!.textContent;

                            // Get the default label, so that we can tell if the label is actually required.
                            let defaultLabel: string | undefined;
                            if (customNode.updateAttributes) {
                                let test = { ...attrs };
                                delete test.label;
                                test = customNode.updateAttributes(test);
                                defaultLabel = test.label;
                            }

                            if (defaultLabel !== text) {
                                s = s.openNode("paragraph", undefined, {
                                    data: { directiveLabel: true },
                                });
                                s = s.addNode("text", undefined, text);
                                s = s.closeNode();
                            }

                            s.closeNode();
                        } else {
                            state.addNode(nodeType, undefined, undefined, nodeProps);
                        }
                    }
                },
            },
        };
        return schema;
    });

    // const node = createNode(() => ({
    //     id: customNode.name,
    //     schema: () => {
    //         return {
    //             inline: customNode.type === "inline",
    //             attrs: Object.assign({}, customNode.attributes, {
    //                 customNode: {},
    //                 label: { default: undefined },
    //             }),
    //             group: customNode.type === "inline" ? "inline" : "block",
    //             marks: "",
    //             atom: customNode.selectable && customNode.type !== "container",
    //             code: false,
    //             defining: customNode.selectable,
    //             isolating: customNode.selectable,
    //             content: customNode.type === "inline" ? "text*" : "paragraph+",
    //             parseMarkdown: {
    //                 match: node => isMatch(node, customNode),
    //                 runner: (state, node, type) => {
    //                     let attrs = getNodeAttributes(node);
    //                     attrs["customNode"] = customNode;

    //                     let children: MarkdownNode[] | undefined;

    //                     if (node.children && node.children.length) {
    //                         children = node.children?.slice();
    //                         if (children) {
    //                             const i = children.findIndex(o => o.type === "text");
    //                             if (i >= 0) {
    //                                 const label = children[i].value;
    //                                 attrs["label"] = label;
    //                                 children.splice(i, 1);
    //                             }
    //                         }
    //                     }

    //                     attrs.label = attrs.label ?? customNode.getDefaultLabel?.(attrs);

    //                     if (children && children.length) {
    //                         state.openNode(type, attrs).next(children).closeNode();
    //                     } else {
    //                         // For inline ones, need to add a child even if one isn't provided...
    //                         // i.e. if we have :item{id=longsword~phb}, then even there's no label (no
    //                         // child node), then we have to add one in, because otherwise we can't edit it.
    //                         if ((customNode.type === "inline" || customNode.type === "block") && attrs.label) {
    //                             state.openNode(type, attrs);
    //                             state.addText(attrs.label);
    //                             state.closeNode();
    //                         } else {
    //                             state.addNode(type, attrs);
    //                         }
    //                     }
    //                 },
    //             },
    //             toMarkdown: {
    //                 match: node => node.type.name === customNode.name,
    //                 runner: (state, node) => {
    //                     let attrs = Object.assign({}, node.attrs) as any;
    //                     delete attrs["customNode"];
    //                     delete attrs["label"];

    //                     if (customNode.type === "container") {
    //                         let s = state.openNode("containerDirective", undefined, {
    //                             name: customNode.name,
    //                             attributes: attrs,
    //                         });

    //                         if (node.attrs.label) {
    //                             s = s.openNode("paragraph", undefined, {
    //                                 data: { directiveLabel: true },
    //                             });
    //                             s = s.addNode("text", undefined, node.attrs.label);
    //                             s = s.closeNode();
    //                         }

    //                         for (let n = 0; n < node.childCount; n++) {
    //                             s = s.next(node.child(n));
    //                         }

    //                         s = s.closeNode();
    //                     } else {
    //                         const nodeType = customNode.type === "inline" ? "textDirective" : "leafDirective";
    //                         const nodeProps = {
    //                             name: customNode.name,
    //                             attributes: attrs,
    //                         };
    //                         if (node.childCount > 0) {
    //                             let s = state.openNode(nodeType, undefined, nodeProps);

    //                             const child = node.firstChild;
    //                             const text = child!.textContent;

    //                             const defaultText = customNode.getDefaultLabel?.(attrs);
    //                             if (defaultText !== text) {
    //                                 s = s.openNode("paragraph", undefined, {
    //                                     data: { directiveLabel: true },
    //                                 });
    //                                 s = s.addNode("text", undefined, text);
    //                                 s = s.closeNode();
    //                             }

    //                             s.closeNode();
    //                         } else {
    //                             state.addNode(nodeType, undefined, undefined, nodeProps);
    //                         }
    //                     }
    //                 },
    //             },
    //         };
    //     },
    //     inputRules:
    //         customNode.type === "container"
    //             ? nodeType => [
    //                   new InputRule(new RegExp(`:::${customNode.name}`), (state, match, start, end) => {
    //                       const { tr } = state;
    //                       const tempText = state.schema.text("Enter note content");
    //                       tr.replaceWith(
    //                           start - 1,
    //                           end,
    //                           nodeType.create({ customNode: customNode }, [
    //                               state.schema.nodes.paragraph.create(undefined, tempText),
    //                           ])
    //                       );

    //                       const pos = tr.doc.resolve(start);
    //                       tr.setSelection(new TextSelection(pos, tr.doc.resolve(start + tempText.nodeSize + 1)));

    //                       return tr;
    //                   }),
    //               ]
    //             : undefined,
    //     remarkPlugins: () => [remarkDirective as RemarkPlugin],
    //     view: renderReact(CustomEditorNode),
    // }));
    return node;
}

const MarkdownEditorBox = styled.div`
    text-shadow: none;

    .milkdown {
        .editor {
            ${props => tableStyling(props)}
            ${props => listStyling(props)}
            ${props => blockquoteStyling(props)}
            ${props => hrStyling(props)}
            ${props => codeStyling(props)}
            ${props => imageStyling(props)}
            ${props => inlineStyling(props)}
        }

        .selectedCell::after {
            background: ${props => props.theme.colors.guidance.focus};
            opacity: 0.4;
        }

        .tableWrapper table {
            width: 100% !important;
            margin: inherit !important;
        }

        p,
        ul,
        ol,
        table {
            &:not(:last-child) {
                margin-bottom: 1em;
            }
        }
    }

    &.editable {
        color: ${props => props.theme.colors.foreground};
        border: 2px solid ${props => props.theme.colors.grayscale[7]};
        border-radius: ${props => props.theme.radii[3]}px;
        transition: border-color 90ms ease-out;

        :focus-within {
            border-color: ${props => props.theme.colors.guidance.focus};
        }

        .milkdown {
            padding: ${props => props.theme.space[2]}px;

            .editor {
                outline: 0;
                min-height: var(--markdown-min-height);
            }

            .tableWrapper table {
                width: calc(100% - 32px) !important;
                margin: 16px 0 16px 16px !important;

                th,
                td {
                    > p {
                        margin-bottom: 0 !important;
                    }
                }
            }

            .table-tooltip {
                border-radius: ${props => props.theme.radii[3]}px;
            }

            .icon {
                font-size: ${props => props.theme.fontSizes[5]}px;
                width: auto;
                height: auto;
                line-height: 1;
                padding: ${props => props.theme.space[1]}px;
                border-radius: ${props => props.theme.radii[3]}px;
            }

            .milkdown-cell-point {
                .icon {
                    top: -${props => props.theme.space[1]}px;
                    left: -${props => props.theme.space[1]}px;
                    font-size: ${props => props.theme.fontSizes[2]}px;
                    z-index: 1;
                    pointer-events: none;
                }

                &::after {
                    opacity: 0.4;
                }
            }

            .milkdown-cell-left,
            .milkdown-cell-top {
                &::after {
                    opacity: 0.4;
                }

                &:hover {
                    &::after {
                        opacity: 0.9;
                    }
                }
            }
        }
    }
`;

interface MarkdownEditorProps {
    editable?: boolean;
    defaultMarkdown: string;
    onMarkdownChange?: (markdown: string) => void;
    onInit?: () => void;
    noSystem?: boolean;
    minLines?: number;
}

export interface MarkdownProps extends MarkdownEditorProps {
    debounceChange?: number;
}

export const CustomImage: FunctionComponent<PropsWithChildren<any>> = props => {
    const context = useNodeViewContext();

    const node = context.node;
    return (
        <Box
            css={{
                position: "relative",
                ":after": {
                    content: `""`,
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    opacity: context.selected ? 0.3 : 0,
                    transition: "all .2s ease-out",
                    background: context.selected ? theme.colors.guidance.focus : "none",
                },
            }}>
            <Image
                ref={context.contentRef}
                title={node.attrs.title ?? node.attrs.alt}
                alt={node.attrs.alt ?? node.attrs.title}
                src={resolveUri(node.attrs.src)}
                css={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                }}></Image>
        </Box>
    );
};

export const CustomHeading: FunctionComponent<PropsWithChildren<any>> = ({ children }) => {
    const { contentRef, node } = useNodeViewContext();
    const level = node.attrs.level as number;
    let h: "h3" | "h4" | "h6";
    if (level >= 3) {
        h = "h6";
    } else if (level >= 2) {
        h = "h4";
    } else if (level >= 1) {
        h = "h3";
    } else {
        h = "h6";
    }

    return (
        <Heading ref={contentRef} as={h}>
            {children}
        </Heading>
    );
};

type MarkdownAndDivProps = MarkdownProps & Omit<React.HTMLProps<HTMLDivElement>, "as" | "ref" | "children">;

const PlainTextEditor = React.forwardRef<MarkdownEditorHandle, MarkdownProps>(
    ({ editable, onMarkdownChange, defaultMarkdown, minLines, onInit }, ref) => {
        const hostRef = useRef<HTMLDivElement>(null);

        useImperativeHandle(
            ref,
            () => ({
                focus: () => {
                    hostRef.current?.focus();
                },
                setMarkdown: md => {
                    if (hostRef.current) {
                        hostRef.current.innerText = md;
                    }
                },
                getMarkdown: () => {
                    return hostRef.current?.innerText ?? "";
                },
            }),
            []
        );

        const id = useId();
        const { setNodeRef, active, isOver } = useTypedDroppable({
            id: id,
            accepts: [
                `LibraryItem/${ImageType.Background}`,
                `LibraryItem/${ImageType.Object}`,
                `LibraryItem/${ImageType.Portrait}`,
                `LibraryItem/${ImageType.Token}`,
            ],
            onDrop: (drag, active) => {
                const item = drag.data as LibraryItem;

                // First, get the actual markdown that we want to drop.
                const md = `![${item.name}](${item.uri})`;
                hostRef.current?.focus();
                document.execCommand("insertHTML", false, md);
            },
        });

        useEffect(() => {
            if (hostRef.current) {
                hostRef.current.innerText = defaultMarkdown ?? "";
            }

            onInit?.();

            // We deliberately only do this the first time, because this is an uncontrolled input.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <MotionBox
                initial={{
                    background: theme.colors.grayscale[9],
                }}
                animate={{
                    background: active ? (isOver ? dragDropPalette[1] : dragDropPalette[0]) : theme.colors.grayscale[9],
                }}
                ref={mergeRefs([hostRef, setNodeRef])}
                minHeight={minLines != null ? `${minLines * 1.43}em` : minLines}
                fullWidth
                flexDirection="column"
                alignItems="stretch"
                justifyContent="flex-start"
                p={2}
                contentEditable={editable}
                spellCheck={false}
                onInput={() => {
                    onMarkdownChange?.(hostRef.current!.innerText);
                }}
                onPaste={e => {
                    e.preventDefault();
                    const text = e.clipboardData.getData("text/plain");
                    document.execCommand("insertHTML", false, text);
                }}
                css={{ whiteSpace: "pre-wrap", fontFamily: "monospace", outline: 0 }}></MotionBox>
        );
    }
);

const MilkdownEditor = React.forwardRef<MarkdownEditorHandle, MarkdownEditorProps>((props, ref) => {
    return (
        <MilkdownProvider>
            <ProsemirrorAdapterProvider>
                <MilkdownEditorInner ref={ref} {...props} />
            </ProsemirrorAdapterProvider>
        </MilkdownProvider>
    );
});

export interface MarkdownEditorHandle {
    focus();
    setMarkdown(md: string);
    getMarkdown(): string;
}

const MilkdownEditorInner = React.forwardRef<MarkdownEditorHandle, MarkdownEditorProps>(
    ({ editable, defaultMarkdown, onMarkdownChange, onInit, noSystem, minLines }, ref) => {
        const campaignData = useCampaign();
        const system = noSystem ? undefined : campaignData?.system;
        const onMarkdownChangeRef = useRef<(markdown: string, prevMarkdown: string | null) => void>();
        onMarkdownChangeRef.current = onMarkdownChange;

        const nodeViewFactory = useNodeViewFactory();
        const onChangeEvent = useMemo(() => new Event<EditorView>(), []);

        const [isMounted, setIsMounted] = useState(false);
        useEffect(() => {
            if (isMounted) {
                onInit?.();
            }

            // We deliberately only do this the first time, because this is an uncontrolled input.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isMounted]);

        const { get } = useEditor(
            root => {
                const customNodes: $Node[] = getMarkdownNodes(system, true).map(o =>
                    customMarkdownNodeToMilkdownNode(o)
                );
                const editor = Editor.make()
                    .config(ctx => {
                        ctx.set(rootCtx, root);
                        if (defaultMarkdown != null) {
                            ctx.set(defaultValueCtx, defaultMarkdown);
                        }

                        const listener = ctx.get(listenerCtx);
                        listener.markdownUpdated((ctx, markdown, prevMarkdown) => {
                            onMarkdownChangeRef.current?.(markdown, prevMarkdown);
                        });
                        listener.mounted(ctx => {
                            setIsMounted(true);
                        });
                    })
                    .use(listener)
                    .use($remark(() => remarkDirective))
                    .use(commonmark)
                    .use(gfm);

                const onChange = $prose(ctx => {
                    return new Plugin({
                        view: () => ({
                            update: (view, prevState) => {
                                onChangeEvent.trigger(view);
                            },
                        }),
                    });
                });
                editor.use(onChange);

                for (let customNode of customNodes) {
                    editor.use(customNode);
                    editor.use($view(customNode, () => nodeViewFactory({ component: CustomEditorNode })));
                }

                editor.use($view(headingSchema.node, () => nodeViewFactory({ component: CustomHeading })));
                editor.use($view(imageSchema.node, () => nodeViewFactory({ component: CustomImage })));

                return editor;
            },
            [editable]
        );

        useImperativeHandle(
            ref,
            () => ({
                focus: () => {
                    const editor = get();
                    if (editor) {
                        const editorView = editor.ctx.get(editorViewCtx);
                        editorView.focus();
                    }
                },
                setMarkdown: (md: string) => {
                    const editor = get();
                    if (editor) {
                        replaceAll(md, false)(editor.ctx);
                    }
                },
                getMarkdown: () => {
                    const editor = get();
                    if (editor) {
                        const editorView = editor.ctx.get(editorViewCtx);
                        const serializer = editor.ctx.get(serializerCtx);
                        return serializer(editorView.state.doc);
                    }

                    return "";
                },
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        // // Update the document when the markdown being passed in changes.
        // useEffect(() => {
        //     if (markdown !== newestMarkdownRef.current && newestMarkdownRef.current != null) {
        //         const ctx = get?.()?.ctx;
        //         if (ctx) {
        //             const editorView = ctx.get(editorViewCtx);
        //             const serializer = ctx.get(serializerCtx);
        //             const currentMarkdown = serializer(editorView.state.doc);
        //             if (currentMarkdown !== markdown) {
        //                 replaceAll(markdown, false)(ctx);
        //             }
        //         }
        //     }

        //     prevMarkdownRef.current = markdown;
        //     newestMarkdownRef.current = markdown;
        // }, [markdown, get?.()]);

        const id = useId();
        const { setNodeRef, active, isOver } = useTypedDroppable({
            id: id,
            accepts: [
                `LibraryItem/${ImageType.Background}`,
                `LibraryItem/${ImageType.Object}`,
                `LibraryItem/${ImageType.Portrait}`,
                `LibraryItem/${ImageType.Token}`,
            ],
            onDrop: (drag, active) => {
                const item = drag.data as LibraryItem;
                // TODO: Insert the item into the document at the current cursor position.
                const editor = get?.();
                if (editor) {
                    const view = editor.ctx.get(editorViewCtx);

                    // TODO: Get the actual position of the drag and show a cursor there, see:
                    // https://github.com/ProseMirror/prosemirror-dropcursor/blob/master/src/dropcursor.ts

                    // Drop the markdown into the correct position.
                    const imageSchema = view.state.schema.nodes.image;
                    const imageNode = imageSchema.create({ src: item.uri, alt: item.name });
                    const tr = view.state.tr.replaceSelectionWith(imageNode);
                    tr.scrollIntoView();
                    view.dispatch(tr);
                }
            },
        });

        return (
            <React.Fragment>
                {editable && <MarkdownMenu onChange={onChangeEvent} />}
                <MotionBox
                    initial={{
                        background: theme.colors.grayscale[9],
                    }}
                    animate={{
                        background: active
                            ? isOver
                                ? dragDropPalette[1]
                                : dragDropPalette[0]
                            : theme.colors.grayscale[9],
                    }}
                    fullWidth
                    ref={setNodeRef}
                    style={{ ["--markdown-min-height" as any]: minLines != null ? `${minLines * 1.43}em` : minLines }}
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="stretch">
                    <Milkdown />
                </MotionBox>
            </React.Fragment>
        );
    }
);

export const MarkdownEditor = React.forwardRef<MarkdownEditorHandle, MarkdownAndDivProps>(
    (
        {
            editable,
            onMarkdownChange,
            debounceChange,
            defaultMarkdown,
            onKeyDown,
            onInit,
            noSystem,
            minLines,
            ...props
        },
        ref
    ) => {
        const [mode, setMode] = useState<"wysiwyg" | "text">("wysiwyg");

        const prevMarkdownRef = useRef<string>();
        const newestMarkdownRef = useRef<string>();
        const timeoutRef = useRef<number>();
        const onMarkdownChangeRef = useRef<(markdown: string) => void>();
        onMarkdownChangeRef.current = onMarkdownChange;
        const onMarkdownChanged = useCallback(
            (markdown: string) => {
                if (timeoutRef.current != null) {
                    clearTimeout(timeoutRef.current);
                }

                newestMarkdownRef.current = markdown;
                if (debounceChange != null) {
                    timeoutRef.current = setTimeout(() => {
                        timeoutRef.current = undefined;
                        prevMarkdownRef.current = newestMarkdownRef.current!;
                        onMarkdownChangeRef.current?.(prevMarkdownRef.current);
                    }, debounceChange) as unknown as number;
                } else {
                    timeoutRef.current = undefined;
                    prevMarkdownRef.current = newestMarkdownRef.current!;
                    onMarkdownChangeRef.current?.(prevMarkdownRef.current);
                }
            },
            [debounceChange]
        );

        // Make sure we submit the onChange before we're unmounted, if one is pending.
        useEffect(() => {
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                    timeoutRef.current = undefined;

                    if (newestMarkdownRef.current != null && newestMarkdownRef.current !== prevMarkdownRef.current) {
                        prevMarkdownRef.current = newestMarkdownRef.current;
                        onMarkdownChangeRef.current?.(prevMarkdownRef.current);
                    }
                }
            };
        }, [mode]);

        return (
            <MarkdownEditorBox
                {...props}
                onKeyDown={e => {
                    onKeyDown?.(e);
                    if (e.key === "Delete") {
                        // e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                css={{ width: "100%" }}
                className={editable ? "editable" : undefined}>
                {mode === "wysiwyg" && (
                    <MilkdownEditor
                        ref={ref}
                        editable={editable}
                        defaultMarkdown={newestMarkdownRef.current ?? defaultMarkdown}
                        onMarkdownChange={onMarkdownChanged}
                        onInit={onInit}
                        noSystem={noSystem}
                        minLines={minLines}
                    />
                )}
                {mode === "text" && (
                    <PlainTextEditor
                        ref={ref}
                        editable={editable}
                        defaultMarkdown={newestMarkdownRef.current ?? defaultMarkdown}
                        onMarkdownChange={onMarkdownChanged}
                        onInit={onInit}
                        noSystem={noSystem}
                        minLines={minLines}
                    />
                )}
                <Box fullWidth bg="background" justifyContent="flex-end">
                    <Button
                        variant="clean"
                        size="s"
                        fontSize={0}
                        onClick={() => {
                            setMode(mode === "wysiwyg" ? "text" : "wysiwyg");
                        }}
                        css={{ "::after": { display: "none" }, ":focus": { color: theme.colors.guidance.focus } }}>
                        {mode === "wysiwyg" ? "plain text" : "markdown"}
                    </Button>
                </Box>
            </MarkdownEditorBox>
        );
    }
);

export const MotionMarkdown = motion(Markdown);

export const MarkdownEditorField = asField<HTMLDivElement, PropsWithChildren<MarkdownAndDivProps> & IAsFieldProps>(
    MarkdownEditor
);

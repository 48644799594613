/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, PropsWithChildren, useRef, useState } from "react";
import { Box, Grid, Heading, Input, Radio, Select, Text } from "../../../../components/primitives";
import { Character, ResolvedCharacter, resolveModifiedValue } from "../../creature";
import { LobotomizedBox } from "../../../../components/common";
import { coreAbilities, CoreAbility, DnD5ECharacterTemplate, DnD5ECharacterToken } from "../../common";
import { theme } from "../../../../design";
import { AnimatePresence } from "framer-motion";
import {
    MotionBox,
    defaultInitial,
    defaultAnimate,
    defaultExit,
    MotionLobotomizedBox,
} from "../../../../components/motion";
import { useDispatch, useCampaign, useLocation } from "../../../../components/contexts";
import { Button } from "../../../../components/Button";
import { RollingObscured } from "./common";
import { DiceRollLogEntry, isLocation } from "../../../../store";
import { modifyCharacter } from "../../actions/token";
import { ScrollableTest } from "../../../../components/ScrollableTest";

const AbilityScoreEditor: FunctionComponent<
    PropsWithChildren<{
        ability: CoreAbility;
        character: Character;
        resolvedCharacter: ResolvedCharacter;
    }>
> = ({ ability, character, resolvedCharacter, children }) => {
    return (
        <MotionLobotomizedBox layout borderRadius={3} bg="transparency.2.grayscale.8" flexDirection="column" p={3}>
            {ability.toLocaleUpperCase()}
            {children}
            <Grid css={{ gap: theme.space[2] }}>
                <Text gridColumn={1}>Base score</Text>
                <Text gridColumn={2}>{character[ability] != null ? resolvedCharacter[ability].baseValue : "--"}</Text>
                {resolvedCharacter[ability].modifiers.map((o, i) => (
                    <React.Fragment key={i}>
                        <Text gridColumn={1}>{o.name}</Text>
                        <Text gridColumn={2}>
                            {o.value > 0 && "+"}
                            {o.value}
                        </Text>
                    </React.Fragment>
                ))}
                <Text gridColumn={1}>Total</Text>
                <Text gridColumn={2}>
                    {character[ability] != null ? resolveModifiedValue(resolvedCharacter[ability]) : "--"}
                </Text>
            </Grid>
        </MotionLobotomizedBox>
    );
};

enum AbilityScoreStrategy {
    Manual,
    StandardArray,
    PointBuy,
    Roll,
}

const stdArray = [8, 10, 12, 13, 14, 15];
const pointBuyOptions = [8, 9, 10, 11, 12, 13, 14, 15];
const pointBuyCosts = [0, 1, 2, 3, 4, 5, 7, 9];

function resetAbilityScores(value?: number): Partial<Character> {
    return {
        strength: value,
        dexterity: value,
        constitution: value,
        intelligence: value,
        wisdom: value,
        charisma: value,
    };
}

// TODO: Server side needs to remember the strategy that was chosen.

const AbilityScoreRoll: FunctionComponent<{
    roll: string;
    value?: DiceRollLogEntry;
    onRollCompleted: (value: DiceRollLogEntry, state: any) => void;
    state: any;
}> = ({ roll, value, onRollCompleted, state }) => {
    const [isRolling, setIsRolling] = useState(false);
    const { api } = useCampaign();

    const ref = useRef<(value: DiceRollLogEntry, state: any) => void>();
    ref.current = onRollCompleted;

    return (
        <RollingObscured isRolling={isRolling}>
            <Box minHeight={theme.space[7]}>
                {value != null && (
                    <Box width="10rem" height="8rem" flexDirection="column" alignItems="center">
                        <Box flexGrow={1}>
                            <Text fontSize={4}>{value.result}</Text>
                        </Box>
                        <Box flexDirection="row" flexGrow={0} fullWidth css={{ gap: theme.space[1] }}>
                            {value.terms.map(term => (
                                <Box borderRadius={3} bg={!term.isExcluded ? "blues.2" : "grayscale.8"} flexGrow={1}>
                                    {term.result}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
                {value == null && (
                    <Button
                        width="10rem"
                        height="8rem"
                        onClick={async () => {
                            const result = await api.roll(roll, { notify: { owner: false } });
                            setIsRolling(true);
                            const confirmedResult = await result.confirmed;
                            setIsRolling(false);
                            ref.current!(confirmedResult, state);
                        }}>
                        Roll
                    </Button>
                )}
            </Box>
        </RollingObscured>
    );
};

function getAvailableRolls(character: Character, ability: CoreAbility, rolls: (undefined | number)[]) {
    const availableRolls = rolls.filter(o => o != null);
    for (let a of coreAbilities) {
        if (a !== ability) {
            const existingValue = character[a];
            if (existingValue != null) {
                const i = availableRolls.indexOf(existingValue);
                if (i >= 0) {
                    availableRolls.splice(i, 1);
                }
            }
        }
    }

    return availableRolls;
}

const unsetRolls = [undefined, undefined, undefined, undefined, undefined, undefined];

export const AbilityScoresPage: FunctionComponent<{
    token: DnD5ECharacterTemplate | DnD5ECharacterToken;
    resolvedCharacter: ResolvedCharacter;
}> = ({ token, resolvedCharacter }) => {
    const [strategy, setStrategy] = useState(AbilityScoreStrategy.Manual);

    const [pointBuyAbilityCosts, setPointBuyAbilityCosts] = useState([0, 0, 0, 0, 0, 0]);
    const pointBuyRemaining = 27 - pointBuyAbilityCosts.reduce((p, c) => p + c, 0);

    const [rollResetCount, setRollResetCount] = useState(0);
    const [rolls, setRolls] = useState<(DiceRollLogEntry | undefined)[]>(unsetRolls);

    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const character = token.dnd5e as Character;

    return (
        <ScrollableTest fullWidth fullHeight minimal p={3}>
            <LobotomizedBox fullWidth flexDirection="column" justifyContent="flex-start">
                <LobotomizedBox flexDirection="row" flexWrap="wrap" css={{ gap: theme.space[2] }} mb={3}>
                    <Radio
                        name="abilityscore_strategy"
                        label="Manual"
                        id="abilityscore_strategy_manual"
                        disabled={!isLocation(location)}
                        checked={strategy === AbilityScoreStrategy.Manual}
                        onChange={e => {
                            if (e.target.checked) {
                                dispatch(modifyCharacter(campaign, location, [token], resetAbilityScores()));
                                setStrategy(AbilityScoreStrategy.Manual);
                            }
                        }}
                    />
                    <Radio
                        name="abilityscore_strategy"
                        label="Standard array"
                        id="abilityscore_strategy_stdarray"
                        disabled={!isLocation(location)}
                        checked={strategy === AbilityScoreStrategy.StandardArray}
                        onChange={e => {
                            if (e.target.checked) {
                                dispatch(modifyCharacter(campaign, location, [token], resetAbilityScores()));
                                setStrategy(AbilityScoreStrategy.StandardArray);
                            }
                        }}
                    />
                    <Radio
                        name="abilityscore_strategy"
                        label="Point buy"
                        id="abilityscore_strategy_ptbuy"
                        disabled={!isLocation(location)}
                        checked={strategy === AbilityScoreStrategy.PointBuy}
                        onChange={e => {
                            if (e.target.checked) {
                                dispatch(modifyCharacter(campaign, location, [token], resetAbilityScores(8)));
                                setPointBuyAbilityCosts([0, 0, 0, 0, 0, 0]);
                                setStrategy(AbilityScoreStrategy.PointBuy);
                            }
                        }}
                    />
                    <Radio
                        name="abilityscore_strategy"
                        label="Roll"
                        id="abilityscore_strategy_roll"
                        disabled={!isLocation(location)}
                        checked={strategy === AbilityScoreStrategy.Roll}
                        onChange={e => {
                            if (e.target.checked) {
                                dispatch(modifyCharacter(campaign, location, [token], resetAbilityScores(8)));
                                setStrategy(AbilityScoreStrategy.Roll);
                            }
                        }}
                    />
                </LobotomizedBox>
                <AnimatePresence mode="wait">
                    {strategy === AbilityScoreStrategy.PointBuy && (
                        <MotionBox
                            key={AbilityScoreStrategy.PointBuy}
                            flexDirection="column"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <Heading as="h5">POINTS REMAINING</Heading>
                            <Box flexDirection="row">
                                <Text fontSize={3}>{pointBuyRemaining}</Text>
                                <Text color="grayscale.2" fontSize={3}>
                                    /
                                </Text>
                                <Text fontSize={3}>27</Text>
                            </Box>
                        </MotionBox>
                    )}
                    {strategy === AbilityScoreStrategy.Roll && (
                        <MotionBox
                            key={AbilityScoreStrategy.Roll + "_" + rollResetCount}
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}
                            flexDirection="column">
                            <Box flexDirection="row" flexWrap="wrap">
                                {rolls.map((v, i) => (
                                    <AbilityScoreRoll
                                        roll="4d6k3"
                                        value={v}
                                        state={rollResetCount}
                                        onRollCompleted={(result, state) => {
                                            if (rollResetCount === state) {
                                                const newRolls = rolls.slice();
                                                newRolls[i] = result;
                                                setRolls(newRolls);
                                            }
                                        }}
                                    />
                                ))}
                            </Box>
                            <Box flexDirection="row" mt={3} mb={3} alignItems="center">
                                <Text>Total</Text>
                                <Text ml={2} mr={2} fontSize={5}>
                                    {rolls.reduce((p, c) => (c != null ? p + c.result : p), 0)}
                                </Text>
                                <Button
                                    disabled={!isLocation(location)}
                                    variant="danger"
                                    size="s"
                                    onClick={() => {
                                        dispatch(modifyCharacter(campaign, location, [token], resetAbilityScores(8)));
                                        setRolls(unsetRolls);
                                        setRollResetCount(rollResetCount + 1);
                                    }}>
                                    RESET
                                </Button>
                            </Box>
                        </MotionBox>
                    )}
                </AnimatePresence>
                <Box
                    fullWidth
                    flexDirection="row"
                    alignItems="flex-start"
                    alignSelf="flex-start"
                    flexWrap="wrap"
                    css={{ gap: theme.space[2] }}>
                    {coreAbilities.map((o, abilityIndex) => (
                        <AbilityScoreEditor
                            key={o}
                            ability={o}
                            character={character}
                            resolvedCharacter={resolvedCharacter}>
                            {strategy === AbilityScoreStrategy.StandardArray && (
                                <Select
                                    css={{
                                        width: theme.space[8],
                                        minWidth: theme.space[8],
                                        lineHeight: theme.fontSizes[2] + "px",
                                    }}
                                    fontSize={1}
                                    value={character[o]}
                                    onChange={e => {
                                        const value = e.target.value;
                                        dispatch(
                                            modifyCharacter(campaign, location, [token], {
                                                [o]: value === "" ? undefined : parseInt(value),
                                            })
                                        );
                                    }}>
                                    <option key="--" value="">
                                        --
                                    </option>
                                    {stdArray
                                        .filter(
                                            v => character[o] === v || !coreAbilities.some(ca => character[ca] === v)
                                        )
                                        .map(v => (
                                            <option key={v} value={v}>
                                                {v}
                                            </option>
                                        ))}
                                </Select>
                            )}

                            {strategy === AbilityScoreStrategy.PointBuy && (
                                <Select
                                    css={{
                                        width: theme.space[8],
                                        minWidth: theme.space[8],
                                        lineHeight: theme.fontSizes[2] + "px",
                                    }}
                                    fontSize={1}
                                    value={character[o]}
                                    onChange={e => {
                                        const value = e.target.value === "" ? undefined : parseInt(e.target.value);
                                        const cost = value == null ? 0 : pointBuyCosts[pointBuyOptions.indexOf(value)];
                                        const newCosts = pointBuyAbilityCosts.slice();
                                        newCosts[abilityIndex] = cost;
                                        setPointBuyAbilityCosts(newCosts);
                                        dispatch(
                                            modifyCharacter(campaign, location, [token], {
                                                [o]: value,
                                            })
                                        );
                                    }}>
                                    {pointBuyOptions
                                        .filter(
                                            (v, vi) =>
                                                pointBuyCosts[vi] <=
                                                pointBuyRemaining + pointBuyAbilityCosts[abilityIndex]
                                        )
                                        .map((v, vi) => (
                                            <option key={vi} value={v}>
                                                {v} (-{pointBuyCosts[pointBuyOptions.indexOf(v)]})
                                            </option>
                                        ))}
                                </Select>
                            )}

                            {strategy === AbilityScoreStrategy.Roll && (
                                <Select
                                    css={{
                                        width: theme.space[8],
                                        minWidth: theme.space[8],
                                        lineHeight: theme.fontSizes[2] + "px",
                                    }}
                                    fontSize={1}
                                    value={character[o]}
                                    onChange={e => {
                                        const value = e.target.value;
                                        dispatch(
                                            modifyCharacter(campaign, location, [token], {
                                                [o]: value === "" ? undefined : parseInt(value),
                                            })
                                        );
                                    }}>
                                    <option key="--" value="">
                                        --
                                    </option>
                                    {getAvailableRolls(
                                        character,
                                        o,
                                        rolls.map(r => (r ? r.result : undefined))
                                    ).map((v, vi) => (
                                        <option key={vi} value={v}>
                                            {v}
                                        </option>
                                    ))}
                                </Select>
                            )}

                            {strategy === AbilityScoreStrategy.Manual && (
                                <Input
                                    css={{ width: theme.space[8], minWidth: theme.space[8] }}
                                    value={character[o] ?? ""}
                                    variant="number"
                                    min={3}
                                    max={18}
                                    step={1}
                                    onChange={e => {
                                        const n = isNaN(e.target.valueAsNumber)
                                            ? undefined
                                            : Math.round(e.target.valueAsNumber);
                                        dispatch(modifyCharacter(campaign, location, [token], { [o]: n }));
                                    }}
                                />
                            )}
                        </AbilityScoreEditor>
                    ))}
                </Box>
            </LobotomizedBox>
        </ScrollableTest>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box } from "./primitives";
import { asField, IAsFieldProps } from "./Form";
import { FunctionComponent } from "react";
import { Slider } from "./slider";

interface ZIndexSliderProps {
    value: number | undefined;
    onChange: (value: number) => void;
    disabled?: boolean;
}

export const ZIndexSlider: FunctionComponent<ZIndexSliderProps> = ({ value, disabled, onChange }) => {
    return (
        <Box px={3} pb={4} fullWidth>
            <Slider
                value={value}
                disabled={disabled}
                included={false}
                min={-1}
                max={2}
                step={0.1}
                marks={{
                    "-1": "Low",
                    0: "Floor",
                    1: "Tokens",
                    2: "High",
                }}
                onChange={onChange}
            />
        </Box>
    );
};

export const ZIndexField = asField<HTMLDivElement, ZIndexSliderProps & IAsFieldProps>(ZIndexSlider);

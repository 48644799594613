import React from 'react'
  
  const GridIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
        <line x1="1.5" y1="12" x2="22.5" y2="12"/><line x1="12" y1="1.5" x2="12" y2="22.5"/><line x1="6" y1="1.5" x2="6" y2="22.5"/><line x1="18" y1="1.5" x2="18" y2="22.5"/><line x1="1.5" y1="6" x2="22.5" y2="6"/><line x1="1.5" y1="18" x2="22.5" y2="18"/>
    </svg>
  )
  
  GridIcon.displayName = 'GridIcon'

  GridIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default GridIcon
import { Campaign } from "../store";
import { ActionScope, CampaignAction } from "./common";

export function initSession(campaignId: string): CampaignAction {
    return { type: "InitSession", props: { campaignId: campaignId } };
}

export function joinSession(campaignId: string): CampaignAction {
    return { type: "JoinSession", props: { campaignId: campaignId } };
}

export function leaveSession(campaignId: string): CampaignAction {
    return { type: "LeaveSession", props: { campaignId: campaignId } };
}

export function addGameTime(campaign: Campaign | string, amount: number): CampaignAction {
    const campaignId = typeof campaign === "string" ? campaign : campaign.id;
    return {
        type: "AddGameTime",
        props: {
            campaignId: campaignId,
        },
        payload: amount,
    };
}

export function setGameTimeSpeed(campaign: Campaign | string, speed: number): CampaignAction {
    const campaignId = typeof campaign === "string" ? campaign : campaign.id;
    return {
        type: "SetGameTimeSpeed",
        props: {
            campaignId: campaignId,
        },
        payload: speed,
    };
}

export function tick(campaign: Campaign | string): CampaignAction {
    const campaignId = typeof campaign === "string" ? campaign : campaign.id;
    return {
        type: "GameTimeTick",
        props: {
            campaignId: campaignId,
            scope: ActionScope.Tokens | ActionScope.Annotations | ActionScope.Locations,
        },
    };
}

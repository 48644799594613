/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Cross1Icon, EyeClosedIcon, EyeOpenIcon, PlusIcon } from "@radix-ui/react-icons";
import { Box, Heading, Image, Text } from "../primitives";
import { Button } from "../Button";
import { theme } from "../../design";
import FocusTrap from "focus-trap-react";
import { AnimatePresence, Reorder } from "framer-motion";
import React, { FunctionComponent, MutableRefObject, ReactNode, useRef, useState } from "react";
import { setPlayerLocation } from "../../actions/campaign";
import { addLevel, deleteLevel, modifyLocationLevel, reorderLevels } from "../../actions/location";
import { getLevelKeysInRenderOrder, getLevelLabel, isLocation, LocationLevel, Location } from "../../store";
import { useDispatch, useLocation, useLocationLevel, useSelection } from "../contexts";
import { ModalDialog } from "../modal";
import {
    defaultAnimate,
    defaultExit,
    defaultInitial,
    MotionBox,
    MotionCard,
    MotionText,
    tinyAnimate,
    tinyExit,
    tinyInitial,
} from "../motion";
import { resolveUri, useVttApp } from "../common";

const LevelItem: FunctionComponent<{
    levelKey: string;
    level: LocationLevel;
    isSelected: boolean;
    index: number;
    dragConstraints: MutableRefObject<any>;
    onDelete: () => void;
}> = ({ levelKey, level, isSelected, index, dragConstraints, onDelete }) => {
    const { campaign, location, user } = useLocationLevel();
    const dispatch = useDispatch();
    const mode = useVttApp(state => state.mode);
    const { setSelection } = useSelection();

    return (
        <Reorder.Item
            key={levelKey}
            value={levelKey}
            id={levelKey}
            drag={mode === "build" ? "y" : false}
            whileDrag={{
                boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
            }}
            dragConstraints={dragConstraints}>
            <MotionCard
                layout
                fullWidth
                borderRadius={3}
                bg={isSelected ? "accent.2" : "grayscale.8"}
                css={{
                    "&:hover": {
                        background: isSelected ? theme.colors.accent[2] : theme.colors.grayscale[7],
                    },
                }}
                boxShadowSize="none"
                boxShadowIntensity={0}
                flexDirection="row"
                alignItems="stretch"
                initial={defaultInitial}
                animate={defaultAnimate}
                exit={defaultExit}>
                {mode === "build" && (
                    <Box
                        bg={isSelected ? "accent.3" : "grayscale.7"}
                        css={{ cursor: "grab" }}
                        px={2}
                        borderTopLeftRadius={3}
                        borderBottomLeftRadius={3}>
                        ⋮
                    </Box>
                )}
                <Button
                    css={{ flexGrow: 1 }}
                    variant="clean"
                    justifyContent="flex-start"
                    disabled={!location}
                    onClick={() => {
                        setSelection([]);
                        dispatch(setPlayerLocation(campaign.id, user.id, location!.id, levelKey));
                    }}
                    pr={4}>
                    <MotionText layout="position">{getLevelLabel(level, index)}</MotionText>
                </Button>
                <AnimatePresence>
                    {mode === "build" && (
                        <MotionBox
                            layout
                            initial={tinyInitial}
                            animate={tinyAnimate}
                            exit={tinyExit}
                            flexDirection="row">
                            <Button
                                shape="square"
                                variant="tertiary"
                                disabled={!location}
                                onClick={() => {
                                    dispatch(
                                        modifyLocationLevel(campaign.id, location!.id, levelKey, {
                                            hiddenInBuild: level.hiddenInBuild ? undefined : true,
                                        })
                                    );
                                }}>
                                {level.hiddenInBuild ? <EyeClosedIcon /> : <EyeOpenIcon />}
                            </Button>
                            <Button
                                shape="square"
                                variant="tertiary"
                                disabled={!location || !isLocation(location) || levelKey === location.defaultLevel}
                                onClick={() => {
                                    onDelete();
                                }}>
                                <Cross1Icon />
                            </Button>
                        </MotionBox>
                    )}
                </AnimatePresence>
            </MotionCard>
        </Reorder.Item>
    );
};

const DeleteLevelDialog: FunctionComponent<{
    pendingDelete: string | undefined;
    setPendingDelete: (key: string | undefined) => void;
}> = ({ pendingDelete, setPendingDelete }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    const pendingDeleteLevel =
        pendingDelete != null && isLocation(location) ? location.levels[pendingDelete] : undefined;

    let content: ReactNode;
    if (pendingDeleteLevel) {
        const pendingDeleteLabel = pendingDeleteLevel
            ? getLevelLabel(pendingDeleteLevel, getLevelKeysInRenderOrder(location as Location).indexOf(pendingDelete!))
            : undefined;

        // Work out what tokens/annotations would be deleted and list those.
        const isDefaultLevel = (location as Location).defaultLevel === pendingDelete;
        const tokens = Object.values((location as Location).tokens).filter(
            o => o.pos.level === pendingDelete || (o.pos.level == null && isDefaultLevel)
        );
        const annotations = Object.values((location as Location).annotations).filter(
            o => o.pos?.level === pendingDelete || (o.pos?.level == null && isDefaultLevel)
        );
        const zones = Object.values((location as Location).zones).filter(
            o => o.pos.level === pendingDelete || (o.pos.level == null && isDefaultLevel)
        );

        content = (
            <FocusTrap>
                <Box
                    flexDirection="column"
                    p={3}
                    maxWidth={theme.space[13]}
                    alignItems="flex-start"
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            setPendingDelete(undefined);
                        }
                    }}>
                    <Heading as="h4" mb={3} css={{ textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden" }}>
                        Delete {pendingDeleteLabel}?
                    </Heading>
                    Are you sure you want to delete {pendingDeleteLabel}?
                    <Box mt={2} borderRadius={3} bg="grayscale.9">
                        <Image
                            src={resolveUri(pendingDeleteLevel.backgroundImageUrl)}
                            borderRadius={3}
                            maxWidth={theme.space[10]}
                            height="12rem"
                            css={{ objectFit: "cover" }}
                            draggable={false}
                        />
                    </Box>
                    {tokens.length > 0 && (
                        <Text mt={2}>
                            <strong>{tokens.length}</strong> {tokens.length === 1 ? "token" : "tokens"} will be deleted.
                        </Text>
                    )}
                    {annotations.length > 0 && (
                        <Text mt={2}>
                            <strong>{annotations.length}</strong>{" "}
                            {annotations.length === 1 ? "annotation" : "annotations"} will be deleted.
                        </Text>
                    )}
                    {zones.length > 0 && (
                        <Text mt={2}>
                            <strong>{zones.length}</strong> {zones.length === 1 ? "zone" : "zones"} will be deleted.
                        </Text>
                    )}
                    <Box mt={3} flexDirection="row" fullWidth justifyContent="flex-end" css={{ gap: theme.space[2] }}>
                        <Button variant="primary" onClick={() => setPendingDelete(undefined)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                dispatch(deleteLevel(campaign, location!, pendingDelete!));
                            }}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </FocusTrap>
        );
    }

    return (
        <ModalDialog
            onRequestClose={() => {
                setPendingDelete(undefined);
            }}
            isOpen={pendingDeleteLevel != null}>
            {content}
        </ModalDialog>
    );
};

export const LayerManager: FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const { campaign, location, levelKey } = useLocationLevel();

    const mode = useVttApp(state => state.mode);

    const [pendingDelete, setPendingDelete] = useState<string>();

    // Debounce the reordering a bit to reduce change spam and visual glitches.
    const [tempLevelKeys, setTempLevelKeys] = useState<string[]>();
    const [reorderTimeout, setReorderTimeout] = useState<number>();

    const ref = useRef<any>();

    let finalLevelKeys =
        tempLevelKeys ?? (isLocation(location) ? getLevelKeysInRenderOrder(location).reverse() : undefined);
    return (
        <Box flexDirection="column" p={2}>
            {finalLevelKeys && (
                <Reorder.Group
                    axis="y"
                    ref={ref}
                    values={finalLevelKeys}
                    css={{
                        ">li": {
                            position: "relative",
                            marginBottom: theme.space[2],
                            "&:last-of-type": {
                                marginBottom: 0,
                            },
                        },
                    }}
                    onReorder={levels => {
                        setTempLevelKeys(levels);
                        if (reorderTimeout != null) {
                            clearTimeout(reorderTimeout);
                        }

                        setReorderTimeout(
                            setTimeout(() => {
                                dispatch(reorderLevels(campaign, location!, levels.slice().reverse()));
                                setTempLevelKeys(undefined);
                            }, 500) as unknown as number
                        );
                    }}>
                    <AnimatePresence>
                        {isLocation(location) &&
                            finalLevelKeys?.map((k, i) => {
                                const isSelected = levelKey === k;
                                const o = location.levels[k];
                                return (
                                    <LevelItem
                                        key={k}
                                        levelKey={k}
                                        level={o}
                                        isSelected={isSelected}
                                        index={finalLevelKeys!.length - (i + 1)}
                                        dragConstraints={ref}
                                        onDelete={() => setPendingDelete(k)}
                                    />
                                );
                            })}
                    </AnimatePresence>
                </Reorder.Group>
            )}
            <AnimatePresence>
                {mode === "build" && (
                    <MotionBox
                        layout
                        flexDirection="row"
                        mt={2}
                        initial={tinyInitial}
                        animate={tinyAnimate}
                        exit={tinyExit}>
                        <Button
                            shape="square"
                            tooltip="Create a new level"
                            alignSelf="flex-end"
                            variant="tertiary"
                            disabled={!isLocation(location)}
                            onClick={
                                location
                                    ? () => {
                                          dispatch(addLevel(campaign, location, {}));
                                      }
                                    : undefined
                            }>
                            <PlusIcon fill="currentcolor" />
                        </Button>
                    </MotionBox>
                )}
            </AnimatePresence>

            <DeleteLevelDialog pendingDelete={pendingDelete} setPendingDelete={setPendingDelete} />
        </Box>
    );
};

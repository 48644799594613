import React, { PropsWithChildren } from "react";
import { Mesh } from "three";
import { MeshProps, MeshBase } from "./common";
import { withCursor, withDragEvents } from "./dragevents";

interface CircleProps extends MeshProps {
    radius: number;
    segments: number;
}

export const Circle = React.forwardRef<Mesh, PropsWithChildren<CircleProps>>(
    ({ radius, segments, children, ...props }, ref) => {
        return (
            <MeshBase ref={ref} {...props}>
                {children}
                <circleGeometry attach="geometry" args={[radius, segments]} />
            </MeshBase>
        );
    }
);

export const DraggableCircle = withDragEvents(Circle);
export const CircleWithCursor = withCursor(Circle);

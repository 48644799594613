/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent } from "react";
import { isToken, isTokenTemplate, Token, TokenTemplate } from "../store";
import { Image } from "./primitives";
import { theme } from "../design";
import { useCampaign, useSelection } from "./contexts";
import { Button } from "./Button";
import { resolveUri } from "./common";

const TokenBox: FunctionComponent<{
    token: Token | TokenTemplate;
    background: string;
    hoverBackground?: string;
    mr?: number;
}> = ({ token, background, hoverBackground, mr }) => {
    const { campaign } = useCampaign();
    const { setSelection } = useSelection();
    let imageUri = token.imageUri;
    if (!imageUri && !isTokenTemplate(token) && token.templateId && !token.ignoreTemplate) {
        imageUri = campaign.tokens[token.templateId]?.imageUri;
    }

    return (
        <Button
            borderRadius="50%"
            position="relative"
            width={theme.space[5]}
            height={theme.space[5]}
            mr={mr!}
            onClick={() => {
                if (isToken(token)) {
                    // TODO: Change locations if necessary?
                    setSelection([token.id]);
                } else {
                    // TODO: Open token library and select token template?
                }
            }}
            css={{ background: background, ":hover": { background: hoverBackground ?? background } }}>
            <Image
                src={resolveUri(imageUri) ?? "defaulttoken.png"}
                width={theme.space[5] - theme.space[1]}
                height={theme.space[5] - theme.space[1]}
            />
        </Button>
    );
};

export default TokenBox;

import {
    Annotation,
    ConeAnnotation,
    EllipseAnnotation,
    LineAreaAnnotation,
    RectAnnotation,
    TargettedAnnotation,
} from "../../annotations";
import { ILocalGrid } from "../../grid";
import { Campaign, getToken, Location, AnnotationPlacementTemplate, Session, getGameTime } from "../../store";
import {
    Ability,
    AreaOfEffect,
    AttackType,
    ConeAreaOfEffect,
    CoreAbility,
    CreatureAreaOfEffect,
    defaultUnitsPerGrid,
    DnD5EAnnotation,
    DnD5EDuration,
    DnD5EExpressionDuration,
    DnD5EToken,
    durationToMs,
    evaluateCharacterExpression,
    evaluateMonsterExpression,
    fromRuleKey,
    isDnD5EToken,
    LineAreaOfEffect,
    modifiedValue,
    SphereAreaOfEffect,
    SquareAreaOfEffect,
} from "./common";
import {
    applyWeaponModifiers,
    CharacterRuleSet,
    fullResolveTokenCreature,
    getUnarmedWeapon,
    isCharacter,
    isMonster,
    ResolvedCharacter,
    ResolvedMonster,
    resolveModifiedValue,
} from "./creature";
import { isInventoryWeapon, ItemType, ResolvedInventoryItem, ResolvedInventoryWeapon } from "./items";
import { isSpell, Spell } from "./spells";

export function getWeaponAttack(
    weapon: ResolvedInventoryWeapon & ResolvedInventoryItem,
    attacks: number = 1,
    criticalRange?: number,
    handed?: 1 | 2,
    isAttackAction?: boolean
): Ability {
    let attackType: AttackType;
    let rangeNear: number | undefined;
    let rangeFar: number | undefined;
    let reach: number | undefined;

    // TODO: If the character/creature has the sharpshooter feat then they should ignore the near range.
    if (weapon.type === ItemType.Ranged) {
        attackType = "rw";
        rangeNear = weapon.range?.near;
        rangeFar = weapon.range?.far;
    } else if (weapon.range && weapon.properties?.some(o => o.abbreviation === "T")) {
        attackType = "mw,rw";
        rangeNear = weapon.range.near;
        rangeFar = weapon.range.far;
    } else {
        attackType = "mw";
        reach = weapon.properties?.some(o => o.abbreviation === "R") ? 10 : 5;
    }

    // Some weapons can be used 2h or 1h (versatile), we need to know which.
    const isTwoHanded = weapon.properties?.some(o => o.abbreviation === "2H");
    handed = handed ?? (isTwoHanded ? 2 : 1);
    const damageBonus = resolveModifiedValue(weapon.damageBonus);
    const hitBonus = resolveModifiedValue(weapon.hitBonus);

    const baseDamage = (isTwoHanded ? weapon.dmg2h : weapon.dmg1h) ?? "0";
    const damageRoll =
        damageBonus !== 0
            ? baseDamage === "1"
                ? (damageBonus + 1).toString(10)
                : `${baseDamage}${damageBonus > 0 ? "+" : ""}${damageBonus}`
            : baseDamage;

    const isVersatile = weapon.properties?.some(o => o.abbreviation === "V");
    const versatileDamageRoll = isVersatile
        ? damageBonus !== 0
            ? `${weapon.dmg2h}${damageBonus > 0 ? "+" : ""}${damageBonus}`
            : weapon.dmg2h
        : undefined;

    // TODO: Maybe this shouldn't be able to be null for weapons?!
    const weaponDamageType = weapon.dmgType ?? "piercing";

    let caoe: CreatureAreaOfEffect = { type: "creature", amount: attacks };
    return {
        name: `${weapon.name}`,
        content: weapon.content,
        time: { unit: "action", amount: 1 },
        isAttackAction: isAttackAction,
        aoe: caoe,
        attack: attackType,
        attackModifier: hitBonus,
        criticalRange: criticalRange,
        range: rangeFar,
        rangeNear: rangeNear,
        reach: reach,
        item: weapon.id,
        effects: {
            "1": {
                damage: {
                    [weaponDamageType]: {
                        base: isVersatile ? (handed === 2 ? versatileDamageRoll : damageRoll) : damageRoll,
                    },
                },
            },
        },
    };
}

function getWeaponAttackForAnnotation(
    annotation: DnD5EAnnotation | Omit<DnD5EAnnotation, "id" | "userId">,
    source: ResolvedCharacter,
    rules: CharacterRuleSet,
    omitDamageAbilityModifier?: boolean
) {
    const itemId = annotation.dnd5e.item;
    if (!itemId) {
        return undefined;
    }

    let ability: Ability | undefined;
    let item = source.inventory[itemId];
    if (isInventoryWeapon(item)) {
        // If the ability modifier is meant to be omitted, we might have to reresolve the inventory item.
        if (omitDamageAbilityModifier) {
            item = Object.assign({}, item, { hitBonus: modifiedValue(0), damageBonus: modifiedValue(0) });
            applyWeaponModifiers(
                item as ResolvedInventoryWeapon & ResolvedInventoryItem,
                item as ResolvedInventoryWeapon & ResolvedInventoryItem,
                rules,
                source,
                undefined,
                undefined,
                omitDamageAbilityModifier
            );
        }
    } else {
        item = getUnarmedWeapon(source, rules, omitDamageAbilityModifier);
    }

    const criticalRange = source.criticalRange?.[item.type === ItemType.Melee ? "mw" : "rw"];
    if (isInventoryWeapon(item)) {
        // Everything checks out, this is a weapon attack.
        ability = getWeaponAttack(
            item,
            source.attacks,
            criticalRange,
            annotation.dnd5e.handed,
            annotation.dnd5e.isAttackAction
        );
    } else {
        ability = getWeaponAttack(
            getUnarmedWeapon(source, rules),
            source.attacks,
            criticalRange,
            annotation.dnd5e.handed,
            annotation.dnd5e.isAttackAction
        );
    }

    return ability;
}

export function getAbilityForCreature(
    annotation: DnD5EAnnotation | Omit<DnD5EAnnotation, "id" | "userId">,
    source: ResolvedMonster | ResolvedCharacter | undefined,
    rules: CharacterRuleSet
) {
    let ability: Ability | undefined;
    if (annotation.dnd5e.spell) {
        ability = rules.spells.get(annotation.dnd5e.spell);
    } else if (annotation.dnd5e.ability && annotation.tokenId) {
        if (isMonster(source)) {
            ability = source?.abilities?.[annotation.dnd5e.ability];
        } else if (isCharacter(source)) {
            ability = source?.abilities?.[annotation.dnd5e.ability];
        }

        if (!ability) {
            const ref = fromRuleKey(annotation.dnd5e.ability);
            if (ref) {
                ability = rules.actions.get(ref);
            }
        }
    } else if (annotation.dnd5e.item && annotation.tokenId) {
        if (isCharacter(source)) {
            ability = getWeaponAttackForAnnotation(annotation, source, rules);
        }
    }

    // If this is a weapon attack proxy ability (i.e. Two-Weapon Fighting, Frenzy, etc), then we add the weapon attack to the effects.
    if (ability?.weaponAttack && annotation.dnd5e.item && isCharacter(source)) {
        let weaponAbility = getWeaponAttackForAnnotation(
            annotation,
            source,
            rules,
            ability.weaponAttack.noDamageAbilityModifier
        );
        if (weaponAbility) {
            ability = Object.assign({}, ability, {
                name: `${ability.name} (${weaponAbility.name})`,
                effects: Object.assign({}, ability.effects, {
                    [`${annotation.dnd5e.item}~1`]: weaponAbility.effects!["1"],
                }),
            });
        }
    }

    if (!ability && source?.transformedInto) {
        ability = getAbilityForCreature(annotation, source.transformedInto, rules);
    }

    return ability;
}

export function getAbility(
    annotation: DnD5EAnnotation | Omit<DnD5EAnnotation, "id" | "userId">,
    campaign: Campaign,
    location: Location,
    rules: CharacterRuleSet
): Ability | undefined {
    let ability: Ability | undefined;
    if (annotation.dnd5e.spell) {
        ability = rules.spells.get(annotation.dnd5e.spell);
    } else {
        const token = getToken(campaign, location.id, annotation.tokenId);
        if (isDnD5EToken(token)) {
            const creature = fullResolveTokenCreature(token, campaign, rules);
            if (creature) {
                return getAbilityForCreature(annotation, creature, rules);
            }
        }
    }

    return ability;
}

export function evaluateAbilityDuration(
    creature: ResolvedCharacter | ResolvedMonster,
    duration: DnD5EExpressionDuration & {
        trigger?: "sot" | "eot" | "source_sot" | "source_eot" | undefined;
    }
): DnD5EDuration & {
    trigger?: "sot" | "eot" | "source_sot" | "source_eot" | undefined;
} {
    let amount: number | undefined;
    if (duration.amountExpr) {
        amount = isCharacter(creature)
            ? evaluateCharacterExpression(creature, duration.amountExpr)
            : evaluateMonsterExpression(creature, duration.amountExpr);
    }

    if (amount == null) {
        amount = duration.amount;
    }

    return { unit: duration.unit, amount: amount, trigger: duration.trigger };
}

/**
 * Gets an annotation template for casting the specified spell.
 * @param caster The token that is casting the spell.
 * @param spell The spell that is being cast.
 * @param level The level that the spell is being cast at.
 * @param dc The DC for the spell.
 * @param tileSize The tile size for the location at which the spell is being cast.
 * @returns
 */
export function getAnnotationTemplate(
    location: Location,
    levelKey: string,
    caster: ResolvedCharacter | ResolvedMonster,
    casterToken: DnD5EToken,
    grid: ILocalGrid,
    weapon: ResolvedInventoryWeapon & ResolvedInventoryItem,
    attacks?: number,
    criticalRange?: number,
    handed?: 1 | 2,
    ignoreNearRange?: boolean,
    isAttackAction?: boolean
): AnnotationPlacementTemplate<Annotation> | undefined;
export function getAnnotationTemplate(
    location: Location,
    levelKey: string,
    caster: ResolvedCharacter | ResolvedMonster,
    casterToken: DnD5EToken,
    grid: ILocalGrid,
    ability: Ability,
    key: string
): AnnotationPlacementTemplate<Annotation> | undefined;
export function getAnnotationTemplate(
    location: Location,
    levelKey: string,
    caster: ResolvedCharacter | ResolvedMonster,
    casterToken: DnD5EToken,
    grid: ILocalGrid,
    spell: Spell,
    level: number,
    dc: number,
    attackModifier: number,
    castingAbility: CoreAbility | undefined
): AnnotationPlacementTemplate<Annotation> | undefined;
export function getAnnotationTemplate(
    location: Location,
    levelKey: string,
    caster: ResolvedCharacter | ResolvedMonster,
    casterToken: DnD5EToken,
    grid: ILocalGrid,
    ability: Ability | (ResolvedInventoryWeapon & ResolvedInventoryItem),
    levelOrKeyOrAttacks?: number | string,
    dcOrCriticalRange?: number,
    attackModifierOrHanded?: number,
    ignoreNearRangeOrCastingAbility?: boolean | CoreAbility,
    isAttackAction?: boolean
): AnnotationPlacementTemplate<Annotation> | undefined {
    let aoe: AreaOfEffect | undefined;
    let levelOrKey: number | string | undefined;
    let itemRef: string | undefined;
    let handed: 1 | 2 | undefined;
    let dc: number | undefined;
    let spellAttackModifier: number | undefined;

    if (isInventoryWeapon(ability)) {
        itemRef = ability.id;
        handed = attackModifierOrHanded as 1 | 2 | undefined;
        ability = getWeaponAttack(
            ability,
            levelOrKeyOrAttacks as number | undefined,
            dcOrCriticalRange,
            handed,
            isAttackAction
        );
        aoe = ability.aoe!;
    } else {
        aoe = ability.aoe;
        levelOrKey = levelOrKeyOrAttacks;
        dc = dcOrCriticalRange;
        spellAttackModifier = attackModifierOrHanded;
        if (!aoe) {
            // If there's no AOE, then check for a melee/ranged attack or melee/ranged spell attack.
            // If there is one, then we effectively have a single target creature aoe.
            if (ability.attack) {
                aoe = { type: "creature", amount: 1 } as CreatureAreaOfEffect;
            }

            if (!aoe) {
                return undefined;
            }
        }
    }

    let rangeInFeet: number | undefined;
    if (typeof ability.range === "number") {
        rangeInFeet = ability.range;
    } else if (ability.range === "touch" || (ability.range == null && ability.attack)) {
        rangeInFeet = ability.reach ?? 5;
    }

    const ignoreNearRange =
        typeof ignoreNearRangeOrCastingAbility === "string" ? undefined : ignoreNearRangeOrCastingAbility;
    const castingAbility =
        typeof ignoreNearRangeOrCastingAbility === "string" ? ignoreNearRangeOrCastingAbility : undefined;

    const warningRangeInFeet = ignoreNearRange ? undefined : ability.rangeNear;
    const warningRange =
        warningRangeInFeet != null
            ? ((warningRangeInFeet + 2.5) / defaultUnitsPerGrid) * location.tileSize.width
            : undefined;
    const range = rangeInFeet != null ? ((rangeInFeet + 2.5) / defaultUnitsPerGrid) * location.tileSize.width : 0;
    const level = typeof levelOrKey === "number" ? levelOrKey : undefined;
    const spellRef = isSpell(ability) ? { name: ability.name, source: ability.source } : undefined;
    const abilityRef = typeof levelOrKey === "string" ? levelOrKey : undefined;

    // Melee and spell attacks should not target self.
    const targetFilter =
        ability.attack == null ? ability.targetFilter : Object.assign({ self: false }, ability.targetFilter);

    const duration = ability.duration ? evaluateAbilityDuration(caster, ability.duration) : undefined;
    const onPlacing = (annotation: Annotation, session: Session) => {
        const a = annotation as DnD5EAnnotation;
        if (a.dnd5e.duration) {
            const durationInMs = durationToMs(a.dnd5e.duration);
            if (durationInMs != null) {
                a.dnd5e.duration.expires = getGameTime(session.time) + durationInMs;
            }
        }

        return a;
    };

    // "cone" | "cube" | "cylinder" | "line" | "sphere" | "creature"
    switch (aoe.type) {
        case "creature":
            const creatureAoe = aoe as CreatureAreaOfEffect;
            let maxTargets = creatureAoe.amount;
            if (isSpell(ability) && level != null && creatureAoe.perLevel != null) {
                const upcast = level - ability.level;
                if (upcast > 0) {
                    maxTargets += creatureAoe.perLevel * upcast;
                }
            }

            const targettedTemplate: AnnotationPlacementTemplate<TargettedAnnotation & DnD5EAnnotation> = {
                annotation: {
                    type: "target",
                    tokenId: casterToken.id,
                    centerOn: casterToken.id,
                    targets: {},
                    maxTargets: maxTargets,
                    disableEdit: true,
                    warningRadius: warningRange,
                    radius: range > 0 ? range : undefined,
                    targetFilter: targetFilter,
                    dnd5e: {
                        spell: spellRef,
                        ability: abilityRef,
                        item: itemRef,
                        handed: handed,
                        savingThrowDc: dc,
                        attackModifier: spellAttackModifier,
                        castAt: level,
                        castAbility: castingAbility,
                        duration: duration,
                        isAttackAction: ability.isAttackAction,
                    },
                },
                origin: casterToken,
                range: 0,
                onPlacing: onPlacing,
            };
            return targettedTemplate;
        case "cone":
            const coneRadius = ((aoe as ConeAreaOfEffect).length / defaultUnitsPerGrid) * location.tileSize.width;
            const coneTemplate: AnnotationPlacementTemplate<ConeAnnotation & DnD5EAnnotation> = {
                annotation: {
                    type: "cone",
                    tokenId: casterToken.id,
                    pos: { ...grid.toLocalCenterPoint(casterToken.pos, casterToken.scale), level: levelKey },
                    radius: coneRadius,
                    spread: 90,
                    minRadius: coneRadius,
                    maxRadius: coneRadius,
                    showGrid: true,
                    disableEdit: true,
                    targetFilter: targetFilter,
                    dnd5e: {
                        spell: spellRef,
                        ability: abilityRef,
                        item: itemRef,
                        handed: handed,
                        savingThrowDc: dc,
                        castAt: level,
                        castAbility: castingAbility,
                        duration: duration,
                        isAttackAction: ability.isAttackAction,
                    },
                },
                origin: casterToken,
                range: 0,
                onPlacing: onPlacing,
            };
            return coneTemplate;
        case "cube":
        case "square":
            // TODO: Locked into cube shape? Max width/height? Is the width/height variable?
            // TODO: Game rules say that the point of origin is on the face of the cube - do we need to worry about that?
            const cubeAoe = aoe as SquareAreaOfEffect;
            const width = (cubeAoe.length / defaultUnitsPerGrid) * location.tileSize.width;
            const height = (cubeAoe.length / defaultUnitsPerGrid) * location.tileSize.height;
            const cubeTemplate: AnnotationPlacementTemplate<RectAnnotation & DnD5EAnnotation> = {
                annotation: {
                    type: "rect",
                    tokenId: casterToken.id,
                    width: width,
                    height: height,
                    minWidth: width,
                    maxWidth: width,
                    minHeight: height,
                    maxHeight: height,
                    showGrid: true,
                    disableEdit: true,
                    targetFilter: targetFilter,
                    dnd5e: {
                        spell: spellRef,
                        ability: abilityRef,
                        item: itemRef,
                        handed: handed,
                        savingThrowDc: dc,
                        castAt: level,
                        castAbility: castingAbility,
                        duration: duration,
                        isAttackAction: ability.isAttackAction,
                    },
                },
                origin: casterToken,
                range: range,
                onPlacing: onPlacing,
            };

            if (ability.range === "self") {
                cubeTemplate.annotation.centerOn = casterToken.id;
            }

            return cubeTemplate;
        case "cylinder":
        case "sphere":
            const sphereAoe = aoe as SphereAreaOfEffect;
            const sphereRadiusX = (sphereAoe.radius / defaultUnitsPerGrid) * location.tileSize.width;
            const sphereRadiusY = (sphereAoe.radius / defaultUnitsPerGrid) * location.tileSize.height;
            const sphereTemplate: AnnotationPlacementTemplate<EllipseAnnotation & DnD5EAnnotation> = {
                annotation: {
                    type: "ellipse",
                    tokenId: casterToken.id,
                    radiusX: sphereRadiusX,
                    radiusY: sphereRadiusY,
                    minRadiusX: sphereRadiusX,
                    maxRadiusX: sphereRadiusX,
                    minRadiusY: sphereRadiusY,
                    maxRadiusY: sphereRadiusY,
                    showGrid: true,
                    disableEdit: true,
                    targetFilter: targetFilter,
                    dnd5e: {
                        spell: spellRef,
                        ability: abilityRef,
                        item: itemRef,
                        handed: handed,
                        savingThrowDc: dc,
                        castAt: level,
                        castAbility: castingAbility,
                        duration: duration,
                        isAttackAction: ability.isAttackAction,
                    },
                },
                origin: casterToken,
                range: range,
                onPlacing: onPlacing,
            };

            if (ability.range === "self") {
                sphereTemplate.annotation.centerOn = casterToken.id;
            }

            return sphereTemplate;
        case "line":
            // TODO: A line is basically a rect that is locked in one dimension and is rotated to go from one point
            // to another. Needs a new annotation type?
            const lineAoe = aoe as LineAreaOfEffect;
            const lineLength = (lineAoe.length / defaultUnitsPerGrid) * location.tileSize.width;
            const lineTemplate: AnnotationPlacementTemplate<LineAreaAnnotation & DnD5EAnnotation> = {
                annotation: {
                    type: "linearea",
                    tokenId: casterToken.id,
                    pos: { ...grid.toLocalCenterPoint(casterToken.pos, casterToken.scale), level: levelKey },
                    length: lineLength,
                    width: (lineAoe.width / defaultUnitsPerGrid) * location.tileSize.width,
                    minLength: lineLength,
                    maxLength: lineLength,
                    showGrid: true,
                    disableEdit: true,
                    targetFilter: targetFilter,
                    dnd5e: {
                        spell: spellRef,
                        ability: abilityRef,
                        item: itemRef,
                        handed: handed,
                        savingThrowDc: dc,
                        castAt: level,
                        castAbility: castingAbility,
                        duration: duration,
                        isAttackAction: ability.isAttackAction,
                    },
                },
                origin: casterToken,
                range: 0,
                onPlacing: onPlacing,
            };
            return lineTemplate;
    }
}

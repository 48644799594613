/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { Button, ButtonVariant } from "./Button";
import { ExtractProps, useVttApp } from "./common";
import { useCampaign } from "./contexts";
import { CopyIcon } from "@radix-ui/react-icons";
import ChatIcon from "./icons/Chat";
import { MotionBox } from "./motion";

export const MarkdownActions: FunctionComponent<
    { markdown: string | (() => string); variant?: ButtonVariant; gap?: number } & ExtractProps<typeof MotionBox>
> = ({ markdown, variant, gap, ...props }) => {
    const { api } = useCampaign();
    const setPropertiesPage = useVttApp(state => state.setPropertiesPage);
    const setIsPropertiesExpanded = useVttApp(state => state.setIsPropertiesExpanded);

    return (
        <MotionBox {...props}>
            <Button
                mr={gap ?? 2}
                size="s"
                variant={variant}
                tooltip="Copy Markdown"
                tooltipDirection="down"
                tooltipAlignment="start"
                onClick={() => {
                    const md = typeof markdown === "string" ? markdown : markdown();
                    navigator.clipboard.writeText(md);
                }}>
                <CopyIcon />
            </Button>
            <Button
                size="s"
                variant={variant}
                tooltip="Send to chat"
                tooltipDirection="down"
                onClick={() => {
                    const md = typeof markdown === "string" ? markdown : markdown();
                    api.sendMessage(md);

                    setPropertiesPage("log");
                    setIsPropertiesExpanded(true);
                }}>
                <ChatIcon size={20 as any} />
            </Button>
        </MotionBox>
    );
};

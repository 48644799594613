/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, useState } from "react";
import {
    Token,
    AudioType,
    tracksToArray,
    AudioTrackWithId,
    SessionPlaylist,
    Location,
    Campaign,
    isLocation,
    Zone,
    isToken,
    defaultSoundRadius,
} from "../../store";
import { useDispatch, useSession, useLocation, useAppState } from "../contexts";
import { Box, Grid, Heading, Link, Text } from "../primitives";
import { asField, IAsFieldProps, InputField } from "../Form";
import { Button } from "../Button";
import { theme } from "../../design";
import { LibraryItem } from "../../library";
import { addTrack, modifyTrack, modifyZone, playTrack, removeTrack, reorderTracks } from "../../actions/location";
import { useDebounce, useLocalSetting } from "../utils";
import { Cross1Icon } from "@radix-ui/react-icons";
import { VolumeSlider, VolumeSliderProps } from "../volumeslider";
import {
    defaultInitial,
    defaultAnimate,
    defaultExit,
    MotionBox,
    MotionMessage,
    MotionForm,
    useDragShadow,
} from "../motion";
import React from "react";
import { AnyAction, Dispatch } from "redux";
import { modifyToken } from "../../actions/token";
import { AnimatePresence, Reorder, useDragControls, useMotionValue } from "framer-motion";
import { DragData, dragDropPalette, useTypedDroppableArea } from "../draggable";
import { artFilterSetting } from "./ArtLibrary";
import { defaultSections, Pages } from "./Sidebar";
import { useVttApp } from "../common";
import { getThemeColor } from "../../design/utils";

interface PlaylistTrackProps {
    dispatch: Dispatch<AnyAction>;
    index: number;
    campaign: Campaign;
    location: Location;
    sessionPlaylist?: SessionPlaylist;
    type: AudioType;
    track: AudioTrackWithId;
}

const PlaylistTrack = React.forwardRef<HTMLElement, PlaylistTrackProps>(
    ({ campaign, location, sessionPlaylist, type, track, dispatch }, ref) => {
        const [volumeOverride, setVolumeOverride] = useState<number>();
        const onVolumeChanged = useDebounce((volume: number) => {
            dispatch(
                modifyTrack(campaign.id, location.id, type, track.id, {
                    volume: volume,
                })
            );
            setVolumeOverride(undefined);
        }, 500);

        const controls = useDragControls();
        const y = useMotionValue(0);
        const { isDragging, boxShadow } = useDragShadow(y);

        return (
            <Reorder.Item
                value={track}
                id={track.id}
                dragListener={false}
                dragControls={controls}
                style={{ boxShadow, y, position: "relative" }}
                initial={defaultInitial}
                animate={defaultAnimate}
                exit={defaultExit}>
                {sessionPlaylist && (
                    <Button
                        variant="secondary"
                        ref={ref as any}
                        toggled={track.id === sessionPlaylist?.id}
                        fullWidth
                        mb={2}
                        justifyContent="stretch"
                        alignItems="stretch"
                        onClick={() =>
                            dispatch(
                                playTrack(campaign.id, location.id, type, track.id, sessionPlaylist?.trackCount ?? 0)
                            )
                        }>
                        <Grid
                            gridTemplateColumns={`auto 1fr`}
                            borderRadius={3}
                            mx={-3}
                            my="-1px"
                            fullWidth
                            css={{ gap: theme.space[2] }}
                            color="inherit"
                            overflow="hidden">
                            <Box
                                color="inherit"
                                bg={track.id === sessionPlaylist?.id ? "grayscale.3" : "grayscale.7"}
                                px={2}
                                gridColumn={1}
                                fullHeight
                                onPointerDown={e => {
                                    controls.start(e);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                css={{
                                    fontSize: 20,
                                    cursor: isDragging ? "grabbing" : "grab",
                                    userSelect: "none",
                                }}>
                                ⋮
                            </Box>
                            <Box flexDirection="column" alignItems="flex-start" gridColumn={2} color="inherit">
                                {track.name}
                            </Box>
                        </Grid>
                    </Button>
                )}
                {!sessionPlaylist && (
                    <Grid
                        ref={ref as any}
                        gridTemplateColumns={`auto 1fr ${theme.space[5]}px`}
                        gridTemplateRows={`${theme.space[5]}px ${theme.space[5]}px`}
                        borderRadius={3}
                        bg="grayscale.8"
                        mb={2}
                        overflow="hidden"
                        fullWidth>
                        <Box
                            bg="grayscale.7"
                            px={2}
                            gridColumn={1}
                            gridRow="1 / 3"
                            fullHeight
                            onPointerDown={e => {
                                controls.start(e);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            css={{
                                fontSize: 20,
                                cursor: isDragging ? "grabbing" : "grab",
                                userSelect: "none",
                            }}>
                            ⋮
                        </Box>
                        <Box
                            flexDirection="column"
                            alignItems="flex-start"
                            gridColumn={2}
                            gridRow={1}
                            color="inherit"
                            px={2}>
                            {track.name}
                        </Box>
                        <VolumeSlider
                            px={2}
                            gridColumn={2}
                            gridRow={2}
                            volume={volumeOverride ?? track.volume ?? 100}
                            onVolumeChanged={v => {
                                setVolumeOverride(v);
                                onVolumeChanged(v);
                            }}
                        />
                    </Grid>
                )}
                <Button
                    position="absolute"
                    right={0}
                    top={0}
                    variant="tertiary"
                    size="s"
                    shape="square"
                    m={1}
                    css={{
                        color: track.id === sessionPlaylist?.id ? theme.colors.background : theme.colors.foreground,
                        "&:hover": {
                            color: "inherit",
                        },
                    }}
                    onClick={() => {
                        dispatch(removeTrack(campaign.id, location.id, type, track.id));
                    }}>
                    <Cross1Icon />
                </Button>
            </Reorder.Item>
        );
    }
);
var droppableActive = Object.assign({}, defaultAnimate, {
    background: dragDropPalette[0],
    opacity: 1,
});
var droppableOver = Object.assign({}, defaultAnimate, {
    background: dragDropPalette[1],
    opacity: 1,
});

const PlaylistEditor: FunctionComponent<{
    type: AudioType;
    sessionPlaylist?: SessionPlaylist;
    tracks: AudioTrackWithId[];
    emptyMessage: string;
    findPrompt: string;
    dropPrompt: string;
}> = ({ type, sessionPlaylist, tracks, emptyMessage, findPrompt, dropPrompt }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const { isSearchExpanded, setIsSearchExpanded } = useVttApp();
    const { searchPropertiesSection, setSearchPropertiesSection } = useAppState();
    const [artFilter, setArtFilter] = useLocalSetting(artFilterSetting);
    const isFindingSound = isSearchExpanded && searchPropertiesSection === artSection && artFilter?.type === type;

    // TODO: Proper drag/drop stuff.
    const dropType = `LibraryItem/${type}`;
    const disabled = !isLocation(location);
    const { isOver, active } = useTypedDroppableArea({
        id: "playlist_" + type,
        accepts: [dropType],
        disabled: disabled,
        onDrop: drag => {
            if (isLocation(location)) {
                const libraryItem = drag.data as LibraryItem;
                dispatch(
                    addTrack(campaign.id, location.id, type, {
                        uri: libraryItem.uri,
                        name: libraryItem.name,
                    })
                );
            }
        },
        renderFeedback: drag => {
            const libraryItem = drag.data as LibraryItem;
            return <React.Fragment>{dropPrompt.replaceAll("{name}", libraryItem.name)}</React.Fragment>;
        },
    });

    // Debounce the reordering a bit to reduce change spam and visual glitches.
    const [tempTracks, setTempTracks] = useState<AudioTrackWithId[]>();
    const [reorderTimeout, setReorderTimeout] = useState<number>();

    let finalTracks = tempTracks ?? tracks;

    return (
        <MotionBox flexDirection="column" fullWidth alignItems="flex-start">
            <Reorder.Group
                axis="y"
                values={finalTracks}
                onReorder={tracks => {
                    setTempTracks(tracks);
                    if (reorderTimeout != null) {
                        clearTimeout(reorderTimeout);
                    }

                    setReorderTimeout(
                        setTimeout(() => {
                            dispatch(
                                reorderTracks(
                                    campaign.id,
                                    location!.id,
                                    type,
                                    tracks.map(o => o.id)
                                )
                            );
                            setTempTracks(undefined);
                        }, 1000) as unknown as number
                    );
                }}
                style={{ width: "100%" }}>
                <AnimatePresence mode="popLayout">
                    {isLocation(location) &&
                        finalTracks.map((o, i) => (
                            <PlaylistTrack
                                key={o.id}
                                index={i}
                                campaign={campaign}
                                location={location}
                                sessionPlaylist={sessionPlaylist}
                                type={type}
                                track={o}
                                dispatch={dispatch}
                            />
                        ))}
                </AnimatePresence>
            </Reorder.Group>

            <AnimatePresence mode="popLayout">
                {active && (
                    <MotionBox
                        key="dropghost"
                        mb={2}
                        fullWidth
                        height={sessionPlaylist ? theme.space[5] : theme.space[7]}
                        borderRadius={3}
                        initial={defaultInitial}
                        animate={Object.assign({}, defaultAnimate, {
                            background: isOver ? dragDropPalette[1] : dragDropPalette[0],
                        })}
                        exit={defaultExit}></MotionBox>
                )}

                {finalTracks.length > 0 && (
                    <MotionBox layout="position" key="findPrompt">
                        <Link
                            disabled={isFindingSound}
                            onClick={() => {
                                setArtFilter({ type: type });
                                setSearchPropertiesSection(artSection);
                                setIsSearchExpanded(true);
                            }}>
                            {findPrompt}
                        </Link>
                    </MotionBox>
                )}

                {finalTracks.length === 0 && (
                    <MotionBox
                        flexDirection="column"
                        alignItems="flex-start"
                        key="emptyFindPrompt"
                        layout
                        fullWidth
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <Text fontStyle="italic">{emptyMessage}</Text>
                        <Link
                            mt={3}
                            disabled={isFindingSound}
                            css={{
                                alignSelf: "flex-start",
                            }}
                            onClick={() => {
                                setArtFilter({ type: type });
                                setSearchPropertiesSection(artSection);
                                setIsSearchExpanded(true);
                            }}>
                            {findPrompt}
                        </Link>
                    </MotionBox>
                )}
            </AnimatePresence>
        </MotionBox>
    );
};

const VolumeField = asField<HTMLDivElement, VolumeSliderProps & IAsFieldProps>(VolumeSlider);

const artSection = defaultSections.find(o => o.id === Pages.Art)!;

export const LocationSoundProperties: FunctionComponent<{}> = () => {
    const { location } = useLocation();
    const { session } = useSession();

    const sessionLocation = isLocation(location) && session.locations ? session.locations[location.id] : undefined;
    return (
        <Box fullWidth fullHeight flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <MotionBox layout="position" flex="0 1" fullWidth flexDirection="column" alignItems="flex-start">
                <Heading as="h6" mb={2}>
                    Music
                </Heading>
                <PlaylistEditor
                    type={AudioType.Music}
                    tracks={isLocation(location) ? tracksToArray(location.music?.tracks) : []}
                    sessionPlaylist={sessionLocation?.music}
                    emptyMessage="No music has been added for this location."
                    dropPrompt="Drop to add {name} to the ambient audio for this location."
                    findPrompt="Find music…"
                />
            </MotionBox>
            <MotionBox mt={3} layout="position" flex="0 1" fullWidth flexDirection="column" alignItems="flex-start">
                <Heading as="h6" mb={2}>
                    Ambience
                </Heading>
                <PlaylistEditor
                    type={AudioType.Ambient}
                    tracks={isLocation(location) ? tracksToArray(location.ambientAudio?.tracks) : []}
                    emptyMessage="No ambient sound has been added for this location."
                    dropPrompt="Drop to add {name} to the music playlist for this location."
                    findPrompt="Find ambience…"
                />
            </MotionBox>
        </Box>
    );
};

export const TokenSoundProperties: FunctionComponent<{
    token: Token | Zone;
}> = ({ token }) => {
    const dispatch = useDispatch();
    const { campaign, location, system } = useLocation();
    const [volumeOverride, setVolumeOverride] = useState<number>();
    const onVolumeChanged = useDebounce((volume: number) => {
        if (isLocation(location)) {
            if (isToken(token)) {
                dispatch(
                    modifyToken(campaign, location, token, {
                        sound: Object.assign({}, token.sound, { volume: volume }),
                    })
                );
            } else {
                dispatch(
                    modifyZone(campaign, location, token, {
                        sound: Object.assign({}, token.sound, { volume: volume }),
                    })
                );
            }

            setVolumeOverride(undefined);
        }
    }, 500);

    const disabled = !isLocation(location);
    const { isOver, active } = useTypedDroppableArea({
        id: token.id + "_sounds",
        accepts: ["LibraryItem/ambience"],
        disabled: disabled,
        onDrop: drag => {
            if (isLocation(location)) {
                const libraryItem = drag.data as LibraryItem;
                const delta = {
                    sound: {
                        uri: libraryItem.uri,
                        name: libraryItem.name,
                    },
                };

                if (isToken(token)) {
                    dispatch(modifyToken(campaign, location, token, delta));
                } else {
                    dispatch(modifyZone(campaign, location, token, delta));
                }
            }
        },
        renderFeedback: (drag: DragData) => {
            const libraryItem = drag.data as LibraryItem;
            return (
                <React.Fragment>
                    Drop to set {libraryItem.name} as the ambient audio for {system.getDisplayName(token, campaign)}.
                </React.Fragment>
            );
        },
    });

    const { isSearchExpanded, setIsSearchExpanded } = useVttApp();
    const { searchPropertiesSection, setSearchPropertiesSection } = useAppState();
    const [artFilter, setArtFilter] = useLocalSetting(artFilterSetting);
    const isFindingAmbientSound =
        isSearchExpanded && searchPropertiesSection === artSection && artFilter?.type === AudioType.Ambient;

    const sound = token.sound;
    const hasValidSound = sound && sound.uri;
    return (
        <AnimatePresence mode="wait" initial={false}>
            {hasValidSound && (
                <MotionForm
                    key={sound!.uri}
                    p={2} // TODO: Drag still isn't shown properly, the top corners are clipped
                    m={-2}
                    css={{ borderRadius: theme.radii[4], width: "initial" }}
                    animate={{
                        backgroundColor: active
                            ? getThemeColor(isOver ? dragDropPalette[1] : dragDropPalette[0])
                            : undefined,
                    }}
                    layout>
                    <Heading as="h4">{sound!.name}</Heading>
                    <VolumeField
                        label="Volume"
                        required
                        disabled={!isLocation(location)}
                        volume={volumeOverride ?? sound!.volume ?? 100}
                        onVolumeChanged={v => {
                            setVolumeOverride(v);
                            onVolumeChanged(v);
                        }}
                    />
                    <InputField
                        fullWidth
                        label={`Radius (${system.defaultUnit})`}
                        hint="The maximum distance from the sound at which it will still be audible (assuming no obstructions)."
                        variant="number"
                        placeholder={(defaultSoundRadius * system.defaultUnitsPerGrid).toString()}
                        required
                        disabled={!isLocation(location)}
                        value={sound.radius != null ? Math.round(sound!.radius * system.defaultUnitsPerGrid) : ""}
                        onChange={e => {
                            const soundRadius = e.target.valueAsNumber / system.defaultUnitsPerGrid;
                            const delta = {
                                sound: Object.assign({}, token.sound, {
                                    radius: isNaN(soundRadius) ? undefined : soundRadius,
                                }),
                            };
                            if (isToken(token)) {
                                dispatch(modifyToken(campaign, location!, token, delta));
                            } else {
                                dispatch(modifyZone(campaign, location!, token, delta));
                            }
                        }}
                    />
                    <Button
                        disabled={!isLocation(location)}
                        variant="danger"
                        mr={2}
                        onClick={() => {
                            if (isToken(token)) {
                                dispatch(
                                    modifyToken(campaign, location!, token, {
                                        sound: undefined,
                                    })
                                );
                            } else {
                                dispatch(modifyZone(campaign, location!, token, { sound: undefined }));
                            }
                        }}>
                        Remove sound
                    </Button>
                    <Link
                        mt={3}
                        disabled={isFindingAmbientSound}
                        css={{
                            alignSelf: "flex-start",
                        }}
                        onClick={() => {
                            setArtFilter({ type: AudioType.Ambient });
                            setSearchPropertiesSection(artSection);
                            setIsSearchExpanded(true);
                        }}>
                        Find a sound…
                    </Link>
                </MotionForm>
            )}
            {!hasValidSound && !active && (
                <MotionBox key={"nosound"} layout initial={defaultInitial} animate={defaultAnimate} exit={defaultExit}>
                    <MotionBox
                        flexDirection="column"
                        alignItems="flex-start"
                        layout
                        fullWidth
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <Text fontStyle="italic">{`This ${
                            isToken(token) ? "token" : "zone"
                        } does not have an associated sound.`}</Text>
                        <Link
                            mt={3}
                            disabled={isFindingAmbientSound}
                            css={{
                                alignSelf: "flex-start",
                            }}
                            onClick={() => {
                                setArtFilter({ type: AudioType.Ambient });
                                setSearchPropertiesSection(artSection);
                                setIsSearchExpanded(true);
                            }}>
                            Find a sound…
                        </Link>
                    </MotionBox>
                </MotionBox>
            )}
            {!hasValidSound && active && (
                <MotionBox
                    key={"dropsound"}
                    layout
                    initial={defaultInitial}
                    animate={defaultAnimate}
                    exit={defaultExit}>
                    <MotionMessage
                        mt={2}
                        layout
                        fullWidth
                        initial={droppableActive}
                        animate={isOver ? droppableOver : droppableActive}
                        exit={defaultExit}>
                        {`Drop here to set the ambient noise for this ${isToken(token) ? "token" : "zone"}.`}
                    </MotionMessage>
                </MotionBox>
            )}
        </AnimatePresence>
    );
};

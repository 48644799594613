import React from 'react'

const CrossbowIcon = ({
    size = undefined,
    color = undefined,
    ...props
}) => (
    <svg
        {...props}
        viewBox='0 0 512 512'
        width={size}
        height={size}
        stroke={color}
    >
        <path d="M89.594 18.094l-10.75 10.75.03.03 27.532 333.563-83.03 92.938 33.25 33.25 90.155-80.563 336.19 24.907c.06.062.124.124.186.186l.156-.156h.032v-.03l10.562-10.564c-1.676-1.676-3.122-3.437-4.687-5.156-21.332-25.55-25.416-63.24-35.47-109.125-8.323-37.99-21.225-81.042-53.094-125.03l-38.062 50.81c.005.008-.005.026 0 .032 28.988 36.074 46.027 67.766 59.72 96.25 15.017 31.247 26.122 59 44.467 83.688L165.314 391.5 337.53 237.594l64.376-85.97-41.53-41.53-85.907 64.312L122.81 344.094 98.156 45.25c24.68 18.33 52.425 29.426 83.656 44.438 28.49 13.693 60.2 30.72 96.282 59.718l50.812-38.062c-43.99-31.86-87.04-44.736-125.03-53.063C157.987 48.224 120.3 44.113 94.75 22.78c-1.72-1.564-3.48-3.01-5.156-4.686zm317.03.312c-3.385.028-6.862.406-10.28.97-4.558.75-8.992 1.837-12.813 3.093-3.82 1.254-6.776 2.302-9.717 4.624a7.184 7.184 0 0 0-2.72 6.187l5.032 62.345a7.184 7.184 0 0 0 2.063 4.53l33.656 33.626a7.184 7.184 0 0 0 4.5 2.095l62.344 5.03a7.184 7.184 0 0 0 6.218-2.718c2.335-2.944 3.367-5.895 4.625-9.718 1.26-3.824 2.343-8.255 3.095-12.814.752-4.56 1.18-9.198.875-13.625-.305-4.425-1.012-8.99-4.844-12.81L422.78 23.343c-3.822-3.824-8.384-4.54-12.81-4.844-1.108-.076-2.216-.103-3.345-.094zm.126 14.375c.8-.006 1.563.016 2.25.064 2.404.165 3.74.915 3.72.78l65.655 65.657c-.138-.023.616 1.318.78 3.72.19 2.746-.062 6.526-.686 10.313-.626 3.786-1.595 7.62-2.595 10.656-.412 1.25-.524 1.272-.938 2.186l-54.78-4.375-29.938-29.936-4.376-54.813c.913-.41.94-.495 2.187-.905 3.037-.998 6.872-1.97 10.658-2.594 2.84-.466 5.662-.728 8.062-.75zm-47.97 120.44l-18.936 31.593-204.5 204.468-8.844-.655-1.188-14.563 201.875-201.906 31.594-18.937z" fill={color}></path>
    </svg>
)

CrossbowIcon.displayName = 'CrossbowIcon'

CrossbowIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
}

export default CrossbowIcon 
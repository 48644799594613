import { Effect } from "postprocessing";
import React, { useMemo } from "react";
import { Uniform } from "three";

const fragmentShader = `
uniform float offsetL;
uniform float offsetR;
uniform float offsetT;
uniform float offsetB;
uniform float darknessL;
uniform float darknessR;
uniform float darknessT;
uniform float darknessB;

float easeOutExpo(in float x) {
    return x == 1.0 ? 1.0 : 1.0 - pow(2.0, -10.0 * x);
}

void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {

	const vec2 center = vec2(0.5);
	vec3 color = inputColor.rgb;

    float dx = 0.0;
    float offsetX = 0.0;
    float darknessX = 0.0;
    if (uv.x < center.x) {
        offsetX = offsetL;
        darknessX = darknessL;
        dx = (center.x - uv.x);
    } else {
        offsetX = offsetR;
        darknessX = darknessR;
        dx = (uv.x - center.x);
    }

    float dy = 0.0;
    float offsetY = 0.0;
    float darknessY = 0.0;
    if (uv.y < center.y) {
        offsetY = offsetB;
        darknessY = darknessB;
        dy = (center.y - uv.y);
    } else {
        offsetY = offsetT;
        darknessY = darknessT;
        dy = (uv.y - center.y);
    }

    float d = distance(uv, center);
    float offset = mix(offsetX, offsetY, dy / (dy + dx));
    float darkness = mix(darknessX, darknessY, dy / (dy + dx));
    color *= smoothstep(0.3, offset * 0.299, d * (darkness + offset));

    // color = vec3(d * (darkness + offset));
    // color = vec3(offset);

	outputColor = vec4(color, inputColor.a);
}
`;

interface VttEffectOptions {
    offsetL?: number;
    offsetR?: number;
    offsetT?: number;
    offsetB?: number;
    darknessL?: number;
    darknessR?: number;
    darknessT?: number;
    darknessB?: number;
}

export class VttEffectImpl extends Effect {
    constructor(options: VttEffectOptions) {
        super("VttEffect", fragmentShader, {
            uniforms: new Map([
                ["offsetL", new Uniform(options.offsetL ?? 1)],
                ["offsetR", new Uniform(options.offsetR ?? 1)],
                ["offsetT", new Uniform(options.offsetT ?? 1)],
                ["offsetB", new Uniform(options.offsetB ?? 1)],
                ["darknessL", new Uniform(options.darknessL ?? 0)],
                ["darknessR", new Uniform(options.darknessR ?? 0)],
                ["darknessT", new Uniform(options.darknessT ?? 0)],
                ["darknessB", new Uniform(options.darknessB ?? 0)],
            ]),
        });
    }

    get offsetL() {
        return this.uniforms.get("offsetL")!.value;
    }

    set offsetL(value) {
        this.uniforms.get("offsetL")!.value = value;
    }

    get offsetR() {
        return this.uniforms.get("offsetR")!.value;
    }

    set offsetR(value) {
        this.uniforms.get("offsetR")!.value = value;
    }

    get offsetT() {
        return this.uniforms.get("offsetT")!.value;
    }

    set offsetT(value) {
        this.uniforms.get("offsetT")!.value = value;
    }

    get offsetB() {
        return this.uniforms.get("offsetB")!.value;
    }

    set offsetB(value) {
        this.uniforms.get("offsetB")!.value = value;
    }

    get darknessL() {
        return this.uniforms.get("darknessL")!.value;
    }

    set darknessL(value) {
        this.uniforms.get("darknessL")!.value = value;
    }

    get darknessR() {
        return this.uniforms.get("darknessR")!.value;
    }

    set darknessR(value) {
        this.uniforms.get("darknessR")!.value = value;
    }

    get darknessT() {
        return this.uniforms.get("darknessT")!.value;
    }

    set darknessT(value) {
        this.uniforms.get("darknessT")!.value = value;
    }

    get darknessB() {
        return this.uniforms.get("darknessB")!.value;
    }

    set darknessB(value) {
        this.uniforms.get("darknessB")!.value = value;
    }
}

export const VttEffect = React.forwardRef<VttEffectImpl, VttEffectOptions>((options, ref) => {
    // TODO: When the properties change, how does that get recognised? Do we really want to create a new VttEffectImpl every time? Need to
    // destructure the options and put them in the array individually.
    const effect = useMemo(() => new VttEffectImpl(options), []); // eslint-disable-line react-hooks/exhaustive-deps
    return <primitive ref={ref} object={effect} dispose={null} />;
});

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Box } from "./primitives";
import { FunctionComponent, PropsWithChildren } from "react";
import { ScrollableTest } from "./ScrollableTest";

// TODO: Shouldn't really need this element, it only exists to get the Scrollable to behave correctly.
const ScrollableHostHack = styled(Box)`
    flex: 0 1;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    > * {
        flex: 0 1;
        justify-content: start;
    }
`;

const HorizontalHostHack = styled(Box)`
    width: 100%;
    > * {
        &::-webkit-scrollbar {
            height: ${props => props.theme.space[1]}px;
        }
    }
`;

export const RelativeScrollable = styled(ScrollableTest)`
    position: relative !important;
    width: 100%;
}`;

export const InlineScrollable: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <ScrollableHostHack>
            <RelativeScrollable minimal>{children}</RelativeScrollable>
        </ScrollableHostHack>
    );
};

export const HorizontalScrollable: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <HorizontalHostHack>
            <RelativeScrollable minimal scrollDirection="horizontal">
                {children}
            </RelativeScrollable>
        </HorizontalHostHack>
    );
};

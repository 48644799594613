import React from 'react'
  
  const CloseIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 0.75,23.249l22.5-22.5  M 23.25,23.249l-22.5-22.5' />
    </svg>
  )
  
  CloseIcon.displayName = 'CloseIcon'
  
  CloseIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default CloseIcon
import React from 'react'
  
  const KeyboardIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 3.75,11.25h16.5c1.657,0,3,1.343,3,3v6c0,1.657-1.343,3-3,3H3.75c-1.657,0-3-1.343-3-3v-6 C0.75,12.593,2.093,11.25,3.75,11.25z  M 6.75,14.25h1.5  M 15.75,14.25h1.5  M 11.25,14.25h1.5  M 4.5,17.25H6  M 9,17.25h1.5  M 13.5,17.25 H15  M 18,17.25h1.5  M 6.75,20.25h10.5  M 6.75,11.25v-1.5c0-2.485,2.015-4.5,4.5-4.5H15c2.485,0,4.5-2.015,4.5-4.5l0,0' />
    </svg>
  )
  
  KeyboardIcon.displayName = 'KeyboardIcon'
  
  KeyboardIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default KeyboardIcon
import {
    ActionScope,
    createTokensOrTokenTemplatesAction,
    TokensAction,
    TokenTemplatesAction,
} from "../../../actions/common";
import { Campaign, DiceRoll, DiceRollLogEntry, DiceType, LocationSummary } from "../../../store";
import {
    AppliedAbilityEffectChoices,
    CreatureConditions,
    DnD5ECharacterTemplate,
    DnD5ECharacterToken,
    DnD5EMonsterTemplate,
    DnD5EMonsterToken,
    DnD5EToken,
    DnD5ETokenTemplate,
    NamedRuleRef,
} from "../common";
import { Character, Monster, MonsterAbility, MovementType } from "../creature";

export function modifyCharacter(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5ECharacterToken | DnD5ECharacterTemplate)[],
    delta: Partial<Character>
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_ModifyCharacter",
        delta,
        campaignId,
        location,
        tokens,
        ActionScope.Annotations | ActionScope.Tokens
    );
}

export function modifyMonster(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EMonsterToken | DnD5EMonsterTemplate)[],
    delta: Partial<Monster>
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_ModifyMonster",
        delta,
        campaignId,
        location,
        tokens,
        ActionScope.Annotations | ActionScope.Tokens
    );
}

export function applyConditions(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[],
    conditions: Partial<CreatureConditions<boolean>>
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_ApplyConditions", conditions, campaignId, location, tokens);
}

export function longRest(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[]
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_LongRest",
        undefined,
        campaignId,
        location,
        tokens,
        ActionScope.Annotations
    );
}

export function shortRest(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[]
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_ShortRest",
        undefined,
        campaignId,
        location,
        tokens,
        ActionScope.Annotations
    );
}

export function removeEffect(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    effectKey: string
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_RemoveEffect", effectKey, campaignId, location, [token]);
}

export function markResource(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[],
    resourceName: string
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_MarkResource", resourceName, campaign, location, tokens);
}

export function clearResource(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[],
    resourceName: string
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_ClearResource", resourceName, campaign, location, tokens);
}

export function markCombatResource(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[],
    resourceName: "action" | "bonus" | "reaction"
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_MarkCombatResource",
        resourceName,
        campaign,
        location,
        tokens,
        undefined,
        true
    );
}

export function clearCombatResource(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[],
    resourceName: "action" | "bonus" | "reaction"
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_ClearCombatResource",
        resourceName,
        campaign,
        location,
        tokens,
        undefined,
        true
    );
}

export function markSavingThrowRoll(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    savingThrows: { [id: string]: boolean }
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_MarkSavingThrow",
        savingThrows,
        campaign,
        location,
        [token],
        undefined
    );
}

export function setSurprised(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[],
    surprised: boolean
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_SetSurprised",
        surprised,
        campaign,
        location,
        tokens,
        undefined,
        true
    );
}

export function markAbilityUse(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[],
    abilityKey: string
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_MarkAbilityUse", abilityKey, campaign, location, tokens);
}

export function clearAbilityUse(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[],
    abilityKey: string
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_ClearAbilityUse", abilityKey, campaign, location, tokens);
}

export function markLegendaryResistance(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[]
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_MarkLegendaryResistance", undefined, campaign, location, tokens);
}

export function clearLegendaryResistance(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[]
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_ClearLegendaryResistance", undefined, campaign, location, tokens);
}

export function markDeathSavingThrow(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    success: boolean,
    amount?: number
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_MarkDeathSavingThrow",
        { isSuccess: success, amount: amount },
        campaign,
        location,
        [token]
    );
}

export function clearDeathSavingThrow(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    success: boolean
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_ClearDeathSavingThrow", success, campaign, location, [token]);
}

export function applyHitDie(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (DnD5EToken | DnD5ETokenTemplate)[],
    hitDieRef: NamedRuleRef | DiceType,
    hitDieRoll: DiceRollLogEntry
): TokensAction | TokenTemplatesAction {
    if (typeof hitDieRef === "string") {
        return createTokensOrTokenTemplatesAction(
            "DnD5E_ApplyHitDie",
            { dice: hitDieRef, roll: hitDieRoll },
            campaignId,
            location,
            tokens
        );
    }

    return createTokensOrTokenTemplatesAction(
        "DnD5E_ApplyHitDie",
        { classRef: hitDieRef, roll: hitDieRoll },
        campaignId,
        location,
        tokens
    );
}

export function setMultiattackOption(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    attackAbility: string,
    attackOption: string
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_SetMultiattackOption",
        { ability: attackAbility, option: attackOption },
        campaignId,
        location,
        [token],
        undefined,
        true
    );
}

export function performAbility(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    abilityKey: string,
    choices?: { [effectKey: string]: AppliedAbilityEffectChoices }
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_UseAbility",
        { abilityKey: abilityKey, choices: choices },
        campaignId,
        location,
        [token],
        undefined,
        false,
        true
    );
}

export function changeMovementForTurn(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken,
    movementType: MovementType | undefined,
    amount: number
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_ChangeMovementForTurn",
        { type: movementType, amount: amount },
        campaignId,
        location,
        [token],
        undefined,
        true
    );
}

export function applyDeathSave(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    deathSave: DiceRoll
) {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_ApplyDeathSave",
        deathSave,
        campaignId,
        location,
        [token],
        undefined,
        false,
        true
    );
}

export function rechargeAbility(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    abilityKey: string
) {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_RechargeAbility",
        abilityKey,
        campaignId,
        location,
        [token],
        undefined,
        false,
        true
    );
}

export function applyRechargeRoll(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    token: DnD5EToken | DnD5ETokenTemplate,
    abilityKey: string,
    ability: MonsterAbility,
    roll: DiceRollLogEntry
) {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_ApplyRechargeRoll",
        { abilityKey: abilityKey, ability: ability, roll: roll },
        campaignId,
        location,
        [token],
        undefined,
        false,
        true
    );
}

import React, { FunctionComponent, useMemo, MutableRefObject } from "react";
import {
    Position,
    GridPosition,
    LocalPixelPosition,
    ScreenPixelPosition,
    Point,
    PositionType,
    Size,
    LocalRect,
    ScreenRect,
    PositionRect,
    Rect,
} from "./position";
import { PathResult } from "./astar";
import { useFrame } from "@react-three/fiber";
import { VttCameraLayers, ZIndexes } from "./components/LocationStage/common";
import { Vector3 } from "three";

export const DEFAULT_TILE_SIZE = 100;

/**
 * The type of grid system used on a map.
 * In the future this might support hex, for example.
 */
export enum GridType {
    Square = "square",
}

/**
 * Gets a point in pixels relative to the top left corner of the stage as it appears on screen
 * (i.e. including scaling and offset).
 */
export function screenPoint(x: number, y: number): ScreenPixelPosition {
    return { type: PositionType.ScreenPixel, x: x, y: y };
}

export const localOrigin = localPoint(0, 0);

/**
 * Gets a point in pixels relative to the map origin (not the screen origin), disregarding any
 * scaling or offset applied to the system.
 */
export function localPoint(x: number, y: number): LocalPixelPosition {
    return { type: PositionType.LocalPixel, x: x, y: y };
}

/**
 * Gets a position relative to the map origin in number of grid cells.
 * Scaling and offset applied to the system are not relevant.
 */
export function gridPoint(x: number, y: number): GridPosition {
    return { type: PositionType.Grid, x: x, y: y };
}

/**
 * Gets a rect in pixels relative to the top left corner of the stage as it appears on screen
 * (i.e. including scaling and offset).
 */
export function screenRect(x: number, y: number, width: number, height: number): ScreenRect {
    return { type: PositionType.ScreenPixel, x: x, y: y, width: width, height: height };
}

export function localRect(x: number, y: number, width: number, height: number): LocalRect {
    return { type: PositionType.LocalPixel, x: x, y: y, width: width, height: height };
}

export function pointsEqual(
    a: GridPosition | LocalPixelPosition | ScreenPixelPosition | Point,
    b: GridPosition | LocalPixelPosition | ScreenPixelPosition | Point
) {
    return a["type"] === b["type"] && a.x === b.x && a.y === b.y;
}

export function pathsEqual(
    a: (GridPosition | LocalPixelPosition | ScreenPixelPosition)[] | null | undefined,
    b: (GridPosition | LocalPixelPosition | ScreenPixelPosition)[] | null | undefined
) {
    if ((a != null) !== (b != null)) {
        return false;
    }

    return !a || a === b || (a.length === b!.length && a.every((o, i) => pointsEqual(o, b![i])));
}

/**
 * Gets the distance between two points.
 */
export function distanceBetween<T extends Point>(p1: T, p2: T) {
    let a = p1.x - p2.x;
    let b = p1.y - p2.y;
    return Math.sqrt(a * a + b * b);
}

export function lengthOfVector(p: Point) {
    return Math.sqrt(p.x * p.x + p.y * p.y);
}

/**
 * Translates the specified position.
 * @param point The point to translate. This point is not modified.
 * @param delta The amount to translate.
 */
export function translate<T extends Position>(point: T, delta: Point): T {
    return {
        type: point.type,
        x: point.x + delta.x,
        y: point.y + delta.y,
    } as T;
}

export function angle<T extends Position | Point>(p1: T, p2: T) {
    var dy = p2.y - p1.y;
    var dx = p2.x - p1.x;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
}

export function angleOfVector(p: Point) {
    var theta = Math.atan2(p.y, p.x); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
}

export function degToRad(deg: number) {
    return (deg * Math.PI) / 180;
}

export function rotate<T extends Position | Point>(point: T, around: T, deg: number) {
    if (deg === 0) {
        return point;
    }

    const rad = degToRad(deg);
    const x = around.x + (Math.cos(rad) * (point.x - around.x) - Math.sin(rad) * (point.y - around.y));
    const y = around.y + (Math.sin(rad) * (point.x - around.x) + Math.cos(rad) * (point.y - around.y));
    return ((point as Position).type ? { type: (point as Position).type, x: x, y: y } : { x: x, y: y }) as T;
}

export function intersectRect<T extends PositionRect<PositionType>>(r1: T, r2: T): boolean {
    return !(r2.x > r1.x + r1.width || r2.x + r2.width < r1.x || r2.y > r1.y + r1.height || r2.y + r2.height < r1.y);
}

/**
 * Gets the point at which two lines intersect.
 * Note that the intersection point may be outside the points specified.
 * If the intersection point lies within the x/y bounds of one of the line pairs, then it meets inside the points specified.
 * @param point1 The start of the first line.
 * @param point2 The end of the first line.
 * @param point3 The start of the second line.
 * @param point4 The end of the second line.
 */
export function intersect<T extends Position | Point>(
    point1: T,
    point2: T,
    isLine1Ray: boolean,
    point3: T,
    point4: T,
    isLine2Ray: boolean
): T | undefined {
    const s =
        ((point4.x - point3.x) * (point1.y - point3.y) - (point4.y - point3.y) * (point1.x - point3.x)) /
        ((point4.y - point3.y) * (point2.x - point1.x) - (point4.x - point3.x) * (point2.y - point1.y));

    if (!isFinite(s)) {
        return undefined;
    }

    const x = point1.x + s * (point2.x - point1.x);
    const y = point1.y + s * (point2.y - point1.y);

    if (!isLine1Ray) {
        const minX = Math.min(point1.x, point2.x);
        const minY = Math.min(point1.y, point2.y);
        const maxX = Math.max(point1.x, point2.x);
        const maxY = Math.max(point1.y, point2.y);
        if (x < minX || x > maxX || y < minY || y > maxY) {
            return undefined;
        }
    }

    if (!isLine2Ray) {
        const minX = Math.min(point3.x, point4.x);
        const minY = Math.min(point3.y, point4.y);
        const maxX = Math.max(point3.x, point4.x);
        const maxY = Math.max(point3.y, point4.y);
        if (x < minX || x > maxX || y < minY || y > maxY) {
            return undefined;
        }
    }

    return { type: point1["type"], x: x, y: y } as T;
}

/**
 * Gets the points at which the specified line intersects with the specified path.
 * Note that the intersection point may be outside the points specified.
 * If the intersection point lies within the x/y bounds of one of the line pairs, then it meets inside the points specified.
 * @param point1 The start of the line.
 * @param point2 The end of the line.
 * @param isRay Indicates whether the line from point1 to point2 should be extended infinitely in either direction.
 * @param path The points of the path.
 * @param isClosed If true, the path will be considered closed (a line between the final and initial points of the path will be added).
 */
export function intersectPath<T extends Position>(
    point1: T,
    point2: T,
    isRay: boolean,
    path: T[],
    isClosed?: boolean
): T[] {
    const intersections: T[] = [];
    if (path.length < 2) {
        return intersections;
    }

    for (let i = 0; i < path.length - 1; i++) {
        const intersection = intersect(point1, point2, isRay, path[i], path[i + 1], false);
        if (intersection) {
            intersections.push(intersection);
        }
    }

    if (isClosed) {
        const intersection = intersect(point1, point2, isRay, path[path.length - 1], path[0], false);
        if (intersection) {
            intersections.push(intersection);
        }
    }

    return intersections;
}

export interface RelativePolygon<T extends Point> {
    pos: T;
    points: Point[];
}

export function isInRect<T extends PositionType>(pos: Position<T>, rect: PositionRect<T>) {
    var x1 = rect.x;
    var x2 = rect.x + rect.width;
    var y1 = rect.y;
    var y2 = rect.y + rect.height;
    return x1 <= pos.x && pos.x <= x2 && y1 <= pos.y && pos.y <= y2;
}

/**
 * Flattens a graph of edges, splitting existing edges so that no edges intersect.
 * @param edges The edges to simplify.
 * @returns The simplified set of edges.
 */
export function simplifyEdges(edges: [Point, Point][]): [Point, Point][] {
    edges = edges.slice();

    for (let i = 0; i < edges.length; i++) {
        const edge1 = edges[i];
        for (let j = i + 1; j < edges.length; j++) {
            const edge2 = edges[j];

            // Check if edge2 is the same or reverse of edge1, and if so remove it.
            if (
                (pointsEqual(edge1[0], edge2[0]) && pointsEqual(edge1[1], edge2[1])) ||
                (pointsEqual(edge1[0], edge2[1]) && pointsEqual(edge1[1], edge2[0]))
            ) {
                edges.splice(j, 1);
                j--;
            } else {
                // Check if the two edges intersect. If they do, we'll need to split them up.
                const intersection = intersect(edge1[0], edge1[1], false, edge2[0], edge2[1], false);
                if (intersection) {
                    const isEndOfEdge1 = pointsEqual(intersection, edge1[0]) || pointsEqual(intersection, edge1[1]);
                    const isEndOfEdge2 = pointsEqual(intersection, edge2[0]) || pointsEqual(intersection, edge2[1]);

                    // For each of the edges involved, check if it's just the end that intersects. If so, we do nothing for
                    // that edge. Otherwise, split the edge at the intersection point and add the two new edges to the end.
                    if (!isEndOfEdge2) {
                        edges.splice(j, 1);
                        j--;
                        edges.push([edge2[0], intersection], [intersection, edge2[1]]);
                    }

                    if (!isEndOfEdge1) {
                        edges.splice(i, 1);
                        i--;
                        edges.push([edge1[0], intersection], [intersection, edge1[1]]);
                        break;
                    }
                }
            }
        }
    }

    return edges;
}

/**
 * Gets the smallest containing polygon for a point, if any.
 * @param p The point to find the smallest containing polygon for.
 * @param edges The edges of the graph. Edges should not intersect (use simplifyEdges first, not done internally as
 *              it could be expensive every time).
 */
export function getContainingPolygonFromPoint(p: Point, edges: [Point, Point][]): Point[] | undefined {
    // Make a copy of the edges, as we may be making changes to it as we cull irrelevant edges.
    edges = edges.slice();

    // Keep trying so long as the edge count has changed, we might get a different result.
    let edgeCount = -1;
    while (edges.length !== edgeCount) {
        edgeCount = edges.length;

        // Remove any dangling edges (edges where either end of the edge don't connect with another edge), as they can't
        // possibly form a polygon.
        let requiresCheck = true;
        while (requiresCheck) {
            requiresCheck = false;
            for (let i = 0; i < edges.length; i++) {
                const edge = edges[i];

                let isConnected = false;
                for (let j = 0; !isConnected && j < edges.length; j++) {
                    // Check if this edge connects with the one we're inspecting.
                    if (
                        pointsEqual(edge[0], edges[j][0]) ||
                        pointsEqual(edge[0], edges[j][1]) ||
                        pointsEqual(edge[1], edges[j][0]) ||
                        pointsEqual(edge[1], edges[j][1])
                    ) {
                        isConnected = true;
                    }
                }

                if (!isConnected) {
                    requiresCheck = true;
                    edges.splice(i, 1);
                    i--;
                }
            }
        }

        // Find the closest edge that intersects with a ray cast out from the point.
        let intersectDist: number | undefined;
        let intersectIndex: number | undefined;
        for (let i = 0; i < edges.length; i++) {
            let ip = intersect(p, { x: p.x + 1, y: p.y }, true, edges[i][0], edges[i][1], false);
            if (ip) {
                // Since the ray is on the x plane, the distance to the intersection point is just the difference in x.
                let id = Math.abs(ip.x - p.x);
                if (intersectDist == null || id < intersectDist) {
                    intersectDist = id;
                    intersectIndex = i;
                }
            }
        }

        // From the closest edge, make all the polygons that we can that involve that edge.
        // The smallest enclosing polygon MUST include that edge (unless it's an island, in which case we'll
        // end up removing it and starting again).
        if (intersectIndex != null) {
            const polygons: Point[][] = [];
            buildPolygons(edges[intersectIndex], edges, polygons);

            // Get the smallest polygon out of all the ones we found.
            let smallestPolygon: Point[] | undefined;
            let smallestArea = 0;
            for (let i = 0; i < polygons.length; i++) {
                // Check that the polygon actually contains the point.
                if (isPointInPolygon(p, polygons[i])) {
                    const area = getArea(polygons[i]);
                    if (smallestPolygon == null || area < smallestArea) {
                        smallestPolygon = polygons[i];
                        smallestArea = area;
                    }
                }
            }

            if (smallestPolygon) {
                // Found the smallest polygon that encloses the given point! Yay!
                return smallestPolygon;
            } else {
                // The edge that we hit isn't involved in any way with a polygon that contains the point.
                // We can safely remove it and try again.
                edges.splice(intersectIndex, 1);
            }
        }
    }

    return undefined;
}

function buildPolygons(polygon: Point[], edges: [Point, Point][], polygons: Point[][]) {
    const currentPoint = polygon[polygon.length - 1];

    for (let i = 0; i < edges.length; i++) {
        let nextPoint: Point | undefined;
        if (pointsEqual(currentPoint, edges[i][0])) {
            nextPoint = edges[i][1];
        } else if (pointsEqual(currentPoint, edges[i][1])) {
            nextPoint = edges[i][0];
        }

        if (nextPoint) {
            if (pointsEqual(nextPoint, polygon[0]) && polygon.length > 2) {
                // This completes a polygon!
                polygons.push([...polygon, nextPoint]);
            } else if (polygon.find(o => pointsEqual(o, nextPoint!))) {
                // This point intersects with another part of the polygon that we've already found.
                // This branch can be discarded.
            } else {
                buildPolygons([...polygon, nextPoint], edges, polygons);
            }
        }
    }
}

/**
 * Returns a value indicating whether the specified point is inside the specified polygon.
 * @param p The point to test.
 * @param polygon The points making up the polygon.
 */
export function isPointInPolygon<T extends Point>(p: T, polygon: T[] | RelativePolygon<T>) {
    var isInside = false;

    const isArray = Array.isArray(polygon);
    const points: Point[] = (isArray ? polygon : (polygon as RelativePolygon<T>).points) as Point[];
    var minX = isArray ? points[0].x : points[0].x + (polygon as RelativePolygon<T>).pos.x;
    var maxX = minX;
    var minY = isArray ? points[0].y : points[0].y + (polygon as RelativePolygon<T>).pos.y;
    var maxY = minY;

    for (var n = 1; n < points.length; n++) {
        var q = points[n];
        var qx = isArray ? q.x : q.x + (polygon as RelativePolygon<T>).pos.x;
        var qy = isArray ? q.y : q.y + (polygon as RelativePolygon<T>).pos.y;

        minX = Math.min(qx, minX);
        maxX = Math.max(qx, maxX);
        minY = Math.min(qy, minY);
        maxY = Math.max(qy, maxY);
    }

    if (p.x < minX || p.x > maxX || p.y < minY || p.y > maxY) {
        return false;
    }

    let j = points.length - 1;
    for (let i = 0; i < points.length; j = i++) {
        const ix = isArray ? points[i].x : points[i].x + (polygon as RelativePolygon<T>).pos.x;
        const iy = isArray ? points[i].y : points[i].y + (polygon as RelativePolygon<T>).pos.y;
        const jx = isArray ? points[j].x : points[j].x + (polygon as RelativePolygon<T>).pos.x;
        const jy = isArray ? points[j].y : points[j].y + (polygon as RelativePolygon<T>).pos.y;

        const r = iy > p.y;
        const w = jy > p.y;
        if (r !== w && p.x < ((jx - ix) * (p.y - iy)) / (jy - iy) + ix) {
            isInside = !isInside;
        }
    }

    return isInside;
}

/**
 * From a list of points, gets the point that is the center of the resulting polygon.
 */
export function getCenterPoint<T extends Position | Point>(points: T[]): T {
    let off = points[0];
    let twicearea = 0;
    let x = 0;
    let y = 0;
    let f;
    for (var i = 0, j = points.length - 1; i < points.length; j = i++) {
        const p1 = points[i];
        const p2 = points[j];
        f = (p1.x - off.x) * (p2.y - off.y) - (p2.x - off.x) * (p1.y - off.y);
        twicearea += f;
        x += (p1.x + p2.x - 2 * off.x) * f;
        y += (p1.y + p2.y - 2 * off.y) * f;
    }

    f = twicearea * 3;
    x = x / f + off.x;
    y = y / f + off.y;
    return ((off as Position).type ? { type: (off as Position).type, x: x, y: y } : { x: x, y: y }) as T;
}

/**
 * Returns a value indicating whether the point p is on the line between a and b.
 * @param p The point to check.
 * @param a The start of the line.
 * @param b The end of the line.
 */
export function isPointOnLine<T extends Point>(p: T, a: T, b: T) {
    return Math.abs(distanceBetween(a, p) + distanceBetween(b, p) - distanceBetween(a, b)) <= 0.001;
}

export function lineToUnitVector<T extends Point>(p1: T, p2: T) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    var d = distanceBetween(p1, p2);
    if (d === 0) {
        return { x: 0, y: 0 };
    }

    return { x: dx / d, y: dy / d };
}

/**
 * Returns the point on the same line as p1->p2, but as if the length of the line was d.
 */
export function pointAlongLine<T extends Point>(p1: T, p2: T, d: number) {
    var unitVector = lineToUnitVector(p1, p2);
    return Object.assign({}, p1, { x: p1.x + unitVector.x * d, y: p1.y + unitVector.y * d });
}

export function getClosestPointOnLines<T extends Point>(pXy: T, aXys: T[]) {
    var minDist: number | undefined;
    var fTo = 0;
    var fFrom = 0;
    var x = 0;
    var y = 0;
    var i = -1;
    var dist: number;

    if (aXys.length > 1) {
        for (var n = 1; n < aXys.length; n++) {
            if (aXys[n].x !== aXys[n - 1].x) {
                var a = (aXys[n].y - aXys[n - 1].y) / (aXys[n].x - aXys[n - 1].x);
                var b = aXys[n].y - a * aXys[n].x;
                dist = Math.abs(a * pXy.x + b - pXy.y) / Math.sqrt(a * a + 1);
            } else {
                dist = Math.abs(pXy.x - aXys[n].x);
            }

            // length^2 of line segment
            var rl2 = Math.pow(aXys[n].y - aXys[n - 1].y, 2) + Math.pow(aXys[n].x - aXys[n - 1].x, 2);

            // distance^2 of pt to end line segment
            var ln2 = Math.pow(aXys[n].y - pXy.y, 2) + Math.pow(aXys[n].x - pXy.x, 2);

            // distance^2 of pt to begin line segment
            var lnm12 = Math.pow(aXys[n - 1].y - pXy.y, 2) + Math.pow(aXys[n - 1].x - pXy.x, 2);

            // minimum distance^2 of pt to infinite line
            var dist2 = Math.pow(dist, 2);

            // calculated length^2 of line segment
            var calcrl2 = ln2 - dist2 + lnm12 - dist2;

            // redefine minimum distance to line segment (not infinite line) if necessary
            if (calcrl2 > rl2) {
                dist = Math.sqrt(Math.min(ln2, lnm12));
            }

            if (minDist == null || minDist > dist) {
                if (calcrl2 > rl2) {
                    if (lnm12 < ln2) {
                        fTo = 0; //nearer to previous point
                        fFrom = 1;
                    } else {
                        fFrom = 0; //nearer to current point
                        fTo = 1;
                    }
                } else {
                    // perpendicular from point intersects line segment
                    fTo = Math.sqrt(lnm12 - dist2) / Math.sqrt(rl2);
                    fFrom = Math.sqrt(ln2 - dist2) / Math.sqrt(rl2);
                }

                minDist = dist;
                i = n;
            }
        }

        if (i >= 0) {
            var dx = aXys[i - 1].x - aXys[i].x;
            var dy = aXys[i - 1].y - aXys[i].y;
            x = aXys[i - 1].x - dx * fTo;
            y = aXys[i - 1].y - dy * fTo;
        }
    }

    return { x: x, y: y, i: i, fTo: fTo, fFrom: fFrom, dist: minDist! };
}

/**
 * From a list of points, gets the point that is nearest to the specified point.
 * @param point The point to get the nearest point to.
 * @param points An array of candidate points.
 */
export function getNearestPoint<T extends Position>(point: T, points: T[]) {
    if (!points.length) {
        throw new Error("At least one point must be specified.");
    }

    let minIndex = 0;
    let minDistance = Math.hypot(point.x - points[0].x, point.y - points[0].y);
    for (let i = 1; i < points.length; i++) {
        const distance = Math.hypot(point.x - points[i].x, point.y - points[i].y);
        if (distance < minDistance) {
            minIndex = i;
            minDistance = distance;
        }
    }

    return points[minIndex];
}

export function getPointsBounds(pos: LocalPixelPosition, points: Point[]): LocalRect {
    let minX = points[0].x;
    let maxX = minX;
    let minY = points[0].y;
    let maxY = minY;
    for (let i = 1; i < points.length; i++) {
        minX = Math.min(minX, points[i].x);
        maxX = Math.max(maxX, points[i].x);
        minY = Math.min(minY, points[i].y);
        maxY = Math.max(maxY, points[i].y);
    }

    return {
        type: pos.type,
        x: pos.x + minX,
        y: pos.y + minY,
        width: maxX - minX,
        height: maxY - minY,
    };
}

type PositionTypeFor<T extends Position> = T extends { type: infer U } ? U : never;

export function getBounds<T extends Position>(points: T[]): PositionRect<PositionTypeFor<T>>;
export function getBounds(points: Point[]): Rect;
export function getBounds<T extends Position>(points: T[] | Point[]): PositionRect<PositionTypeFor<T>> | Rect {
    let minX = points[0].x;
    let maxX = minX;
    let minY = points[0].y;
    let maxY = minY;
    for (let i = 1; i < points.length; i++) {
        minX = Math.min(minX, points[i].x);
        maxX = Math.max(maxX, points[i].x);
        minY = Math.min(minY, points[i].y);
        maxY = Math.max(maxY, points[i].y);
    }

    const type = points[0]["type"];
    return {
        type: type as PositionTypeFor<T> | undefined,
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
    };
}

export function getArea<T extends Point>(points: T[]) {
    var total = 0;

    for (var i = 0, l = points.length; i < l; i++) {
        var addX = points[i].x;
        var addY = points[i === points.length - 1 ? 0 : i + 1].y;
        var subX = points[i === points.length - 1 ? 0 : i + 1].x;
        var subY = points[i].y;

        total += addX * addY * 0.5;
        total -= subX * subY * 0.5;
    }

    return Math.abs(total);
}

/**
 * Gets a grid with the specified characteristics.
 * @param type The type of grid (square, hex, etc).
 * @param tileSize The size of a single grid tile.
 * @param scale The current scale applied to the system.
 * @param offset The current offset (pan) applied to the system.
 */
export function createGrid(type: GridType | undefined, tileSize: Size, scale: number, offset: Point): IGrid {
    if (!type || type === GridType.Square) {
        return new SquareGrid(tileSize, scale, offset);
    }

    throw new Error(`Unknown grid type "${type}".`);
}

export interface GlobalPathState<T extends GridPosition> {
    previousPath?: PathResult<T>;
    [id: string]: any;
}

export interface ILocalGridWithRef extends ILocalGrid {
    /**
     * Gets the mutable ref that holds the latest IGrid. Do not use unless it's for something outside React (i.e. in the
     * WebGL frame render loop).
     */
    ref: MutableRefObject<IGrid | undefined>;
}

export interface ILocalGrid {
    /**
     * Converts the specified local position to a screen position relative to the stage.
     */
    toScreenPoint(pos: LocalPixelPosition): ScreenPixelPosition;

    /**
     * Converts the specified local rect to a screen rect relative to the stage.
     */
    toScreenRect(rect: LocalRect): ScreenRect;

    /**
     * Converts the specified screen rect into a local pixel position rect;
     */
    toLocalRect(rect: ScreenRect): LocalRect;

    /**
     * Converts the specified position to the top left corner of the bounding box (i.e. the offset
     * position of) the shape that represents the grid position, or to the local position of the
     * screen position. Note that if the point is a grid point and the grid shape is not square
     * that this point may actually be outside the bounds of the shape.
     * @param pos The grid position.
     */
    toLocalPoint(pos: GridPosition | LocalPixelPosition | ScreenPixelPosition): LocalPixelPosition;

    /**
     * Converts the specified grid position to the center point of that position as a local position.
     * @param pos The grid position.
     */
    toLocalCenterPoint(pos: GridPosition | LocalPixelPosition, scale?: number): LocalPixelPosition;

    /**
     * Converts the specified grid position to a bounding box that encloses that grid shape.
     * @param pos The grid position.
     */
    toLocalBounds(pos: GridPosition | LocalPixelPosition, scale?: number): LocalRect;

    /**
     * Converts the specified grid position into a series of points that describe the grid shape
     * that surrounds that point in local pixel positioning.
     */
    toLocalPoints(pos: GridPosition | LocalPixelPosition, scale?: number): LocalPixelPosition[];

    /**
     * Converts the specified grid position into a series of points that describe the grid shape
     * that surrounds that point in screen pixel positioning.
     */
    toScreenPoints(pos: GridPosition, scale?: number): ScreenPixelPosition[];

    /**
     * Converts the specified position to grid coordinates.
     * @param pos The local pixel position.
     */
    toGridPoint(pos: LocalPixelPosition | ScreenPixelPosition | GridPosition, scale?: number): GridPosition;

    /**
     * Gets the distance between the two specified points in grid units, taking into account
     * grid tile sizes etc.
     */
    gridDistanceBetween(p1: LocalPixelPosition | GridPosition, p2: LocalPixelPosition | GridPosition): number;

    /**
     * Gets a value indicating whether the specified position is within the specified grid & scale.
     * The position of an token within the grid is determined by its position and scale, so this can
     * be used to find whether a particular point is occupied by a token.
     * @param pos The position to test.
     * @param gridPos The grid position.
     * @param scale The scale of the area to test, in grid units.
     */
    contains(pos: GridPosition | LocalPixelPosition, gridPos: GridPosition, scale?: number): boolean;

    /**
     * Calls the specified callback for each grid position that intersects with the specified bounds.
     * @param bounds The bounds to get the intersecting grid positions for.
     * @param callback The function that will be called for each intersecting grid position.
     */
    forEachGridPointIntersecting(bounds: LocalRect, callback: (pos: GridPosition) => void);

    /**
     * Gets the estimated tile settings given a set of points that should lie roughly on the intersection of grid positions.
     * @param points The points to estimate the settings from.
     */
    getSettingsFromPoints(points: LocalPixelPosition[]): { tileSize: Size; pos: LocalPixelPosition } | undefined;

    /**
     * Gets the grid positions that are neighbours to the specified grid position.
     * @param pos The position to get the neighbours for.
     */
    getNeighbours(pos: GridPosition): GridPosition[];

    /**
     * Gets the traversal cost from the specified grid position to the specified neighbouring position.
     * By default should be 0, or a small number to slightly shift preference for certain neighbours.
     * This gives the grid the opportunity to slightly favour certain neighbours where all else is equal.
     * @param a The first grid position.
     * @param b The neighbouring grid position.
     */
    getTraversalCostModifier(a: GridPosition, b: GridPosition): number;
}

export class LocalGrid implements ILocalGridWithRef {
    private _grid: MutableRefObject<IGrid | undefined>;

    constructor(grid: MutableRefObject<IGrid | undefined>) {
        this._grid = grid;
    }

    get ref() {
        return this._grid;
    }

    getNeighbours(pos: GridPosition): GridPosition[] {
        return this._grid.current?.getNeighbours(pos) ?? [];
    }

    getTraversalCostModifier(a: GridPosition, b: GridPosition): number {
        return this._grid.current?.getTraversalCostModifier(a, b) ?? 1;
    }

    toScreenPoint(pos: LocalPixelPosition): ScreenPixelPosition {
        return this._grid.current?.toScreenPoint(pos) ?? screenPoint(0, 0);
    }

    toScreenRect(rect: LocalRect): ScreenRect {
        return this._grid.current?.toScreenRect(rect) ?? screenRect(0, 0, 0, 0);
    }

    toLocalRect(rect: ScreenRect): LocalRect {
        return this._grid.current?.toLocalRect(rect) ?? localRect(0, 0, 0, 0);
    }

    toLocalPoint(pos: ScreenPixelPosition | LocalPixelPosition | GridPosition): LocalPixelPosition {
        return this._grid.current?.toLocalPoint(pos) ?? localPoint(0, 0);
    }

    toLocalCenterPoint(pos: LocalPixelPosition | GridPosition, scale?: number): LocalPixelPosition {
        return this._grid.current?.toLocalCenterPoint(pos, scale) ?? localPoint(0, 0);
    }

    toLocalBounds(pos: GridPosition | LocalPixelPosition, scale?: number): LocalRect {
        return this._grid.current?.toLocalBounds(pos, scale) ?? localRect(0, 0, 0, 0);
    }

    toLocalPoints(pos: GridPosition | LocalPixelPosition, scale?: number): LocalPixelPosition[] {
        return this._grid.current?.toLocalPoints(pos, scale) ?? [];
    }

    toScreenPoints(pos: GridPosition, scale?: number): ScreenPixelPosition[] {
        return this._grid.current?.toScreenPoints(pos, scale) ?? [];
    }

    toGridPoint(pos: ScreenPixelPosition | LocalPixelPosition | GridPosition, scale?: number): GridPosition {
        return this._grid.current?.toGridPoint(pos, scale) ?? gridPoint(0, 0);
    }

    contains(pos: GridPosition | LocalPixelPosition, gridPos: GridPosition, scale?: number): boolean {
        return !!this._grid.current?.contains(pos, gridPos, scale);
    }

    gridDistanceBetween(p1: LocalPixelPosition | GridPosition, p2: LocalPixelPosition | GridPosition): number {
        return this._grid.current?.gridDistanceBetween(p1, p2) ?? -1;
    }

    forEachGridPointIntersecting(bounds: LocalRect, callback: (pos: GridPosition) => void) {
        return this._grid.current?.forEachGridPointIntersecting(bounds, callback);
    }

    getSettingsFromPoints(points: LocalPixelPosition[]) {
        return this._grid.current?.getSettingsFromPoints(points);
    }
}

export interface IGrid extends ILocalGrid {
    readonly scale: number;
    readonly offset: Point;
    readonly tileSize: Size;

    /**
     * Draws the grid lines onto the specified context, as part of the draw operation of the specified shape.
     */
    render(r: number, g: number, b: number, opacity: number, size: Size);
}

/**
 * Base class for grid implementations, includes some utility functions.
 */
abstract class GridBase {
    private _scale: number;
    private _offset: Point;
    private _tileSize: Size;

    constructor(tileSize: Size, scale: number, offset: Point) {
        this._scale = scale;
        this._offset = offset;
        this._tileSize = tileSize;
    }

    get scale() {
        return this._scale;
    }

    get offset() {
        return this._offset;
    }

    get tileSize() {
        return this._tileSize;
    }

    toScreenPoint(pos: LocalPixelPosition): ScreenPixelPosition {
        return {
            type: PositionType.ScreenPixel,
            x: this._offset.x + pos.x * this._scale,
            y: this._offset.y + pos.y * this._scale,
        };
    }

    toScreenRect(rect: LocalRect): ScreenRect {
        return {
            type: PositionType.ScreenPixel,
            x: this._offset.x + rect.x * this._scale,
            y: this._offset.y + rect.y * this._scale,
            width: rect.width * this._scale,
            height: rect.height * this._scale,
        };
    }

    toLocalRect(rect: ScreenRect): LocalRect {
        return {
            type: PositionType.LocalPixel,
            x: (rect.x - this._offset.x) / this._scale,
            y: (rect.y - this._offset.y) / this._scale,
            width: rect.width / this._scale,
            height: rect.height / this._scale,
        };
    }

    toLocalPoint(pos: GridPosition | LocalPixelPosition | ScreenPixelPosition): LocalPixelPosition {
        if (pos.type === PositionType.LocalPixel) {
            return pos;
        }

        return {
            type: PositionType.LocalPixel,
            x: (pos.x - this._offset.x) / this._scale,
            y: (pos.y - this._offset.y) / this._scale,
        };
    }

    toLocalCenterPoint(pos: GridPosition, scale?: number) {
        return getCenterPoint(this.toLocalPoints(pos, scale));
    }

    toScreenPoints(pos: GridPosition, scale?: number) {
        return this.toLocalPoints(pos, scale).map(o => this.toScreenPoint(o));
    }

    abstract toLocalPoints(pos: GridPosition | LocalPixelPosition, scale?: number): LocalPixelPosition[];

    abstract toLocalBounds(pos: GridPosition | LocalPixelPosition): LocalRect;

    abstract toGridPoint(pos: LocalPixelPosition | ScreenPixelPosition, scale: number): GridPosition;

    abstract render(r: number, g: number, b: number, opacity: number, size: Size): React.ReactNode;

    contains(pos: GridPosition | LocalPixelPosition, gridPos: GridPosition, scale?: number) {
        scale = scale != null ? scale : 1;
        if (scale === 1) {
            if (pos.type === PositionType.LocalPixel) {
                pos = this.toGridPoint(pos, scale);
            }

            return pos.x === gridPos.x && pos.y === gridPos.y;
        }

        const points = this.toLocalPoints(gridPos, scale);
        const localPos = this.toLocalPoint(pos);
        return isPointInPolygon(localPos, points);
    }

    gridDistanceBetween(p1: LocalPixelPosition | GridPosition, p2: LocalPixelPosition | GridPosition): number {
        if (p1.type === PositionType.Grid) {
            p1 = this.toLocalPoint(p1);
        }

        if (p2.type === PositionType.Grid) {
            p2 = this.toLocalPoint(p2);
        }

        let a = p1.x - p2.x;
        let b = p1.y - p2.y;
        a = a / this._tileSize.width;
        b = b / this._tileSize.height;

        return Math.sqrt(a * a + b * b);
    }

    abstract getNeighbours(pos: GridPosition): GridPosition[];

    // findGridPath(from: GridPosition, to: GridPosition, traversalCost?: (a: GridPosition, b: GridPosition, from: GridPosition, to: GridPosition, state: { previousPath?: PathResult<GridPosition>, [id: string]: any }, nodeState?: any) => number | { cost: number, compareCost?: number, nodeState?: any }, previousPath?: PathResult<GridPosition>): PathResult<GridPosition> | undefined {
    //     return findPathAStar(from, to, {
    //         state: {
    //             previousPath: previousPath
    //         },
    //         identity: node => {
    //             // Use a pairing function to combine the x and y into a single unique number. See:
    //             // https://en.wikipedia.org/wiki/Pairing_function
    //             return cantorPairSigned(node.x, node.y);
    //         },
    //         heuristic: (a, b) => {
    //             var d1 = Math.abs(a.x - b.x);
    //             var d2 = Math.abs(a.y - b.y);
    //             return d1 + d2;
    //         },
    //         traversalCost,
    //         getNeighbours: node => this.getNeighbours(node)
    //     });
    // }

    // evaluateGridPath(path: GridPosition[], traversalCost?: (a: GridPosition, b: GridPosition, from: GridPosition, to: GridPosition, state: { previousPath?: PathResult<GridPosition>, [id: string]: any }, nodeState?: any) => number | { cost: number, compareCost?: number, nodeState?: any }): PathResult<GridPosition> | undefined {
    //     const from = path[0];
    //     const to = path[path.length - 1];
    //     let totalCost = 0;
    //     let states: any[] | undefined;
    //     let costs: number[] = [];

    //     // Follow the path and build up the same kind of result that the astar path finding would.
    //     for (let i = 1; i < path.length; i++) {
    //         const a = path[i - 1];
    //         const b = path[i];

    //         const cost = traversalCost ? traversalCost(a, b, from, to, {}, states?.[i - 2]) : 1;
    //         if (typeof cost === "object") {
    //             if ((cost.compareCost ?? cost.cost) < 1) {
    //                 return undefined;
    //             }

    //             if (cost.nodeState) {
    //                 if (!states) {
    //                     states = [];
    //                 }

    //                 states[i - 1] = cost.nodeState;
    //             }

    //             totalCost += cost.cost;
    //         } else {
    //             if (cost < 1) {
    //                 return undefined;
    //             }

    //             totalCost += cost;
    //         }

    //         costs.push(totalCost);
    //     }

    //     return {
    //         cost: totalCost,
    //         path: path,
    //         pathCost: costs,
    //         pathState: states
    //     };
    // }
}

// TODO: This uses a lot of uniforms where it should probably just use a vec2 or vec4.
// Need to learn more about how to mix/multiply etc vectors in shaders.
const squareGridLinesShader = `
uniform float u_tileWidth;
uniform float u_tileHeight;
uniform float u_offsetX;
uniform float u_offsetY;
uniform float u_height;
uniform float u_opacity;
uniform vec3 u_color;

void main() {
    float py = mod(gl_FragCoord.y - mod(u_height, u_tileHeight) + u_offsetY, u_tileHeight);
    float px = mod(gl_FragCoord.x - u_offsetX, u_tileWidth);
    gl_FragColor = vec4(u_color, max(step(-1.0, -py), step(-1.0, -px)) * u_opacity);
}
`;

interface GridLinesProps {
    scale: number;
    offset: Point;
    tileSize: Size;
    r: number;
    g: number;
    b: number;
    opacity: number;
    size: Size;
}

const SquareGridLines: FunctionComponent<GridLinesProps> = ({ scale, offset, tileSize, r, g, b, opacity, size }) => {
    const uniforms = useMemo(
        () => ({
            u_tileWidth: { value: 0 },
            u_tileHeight: { value: 0 },
            u_offsetX: { value: 0 },
            u_offsetY: { value: 0 },
            u_height: { value: 0 },
            u_opacity: { value: 0 },
            u_color: { value: new Vector3(0, 0, 0) },
        }),
        []
    );
    useFrame(() => {
        uniforms.u_tileWidth.value = tileSize.width * scale;
        uniforms.u_tileHeight.value = tileSize.height * scale;
        uniforms.u_offsetX.value = offset.x;
        uniforms.u_offsetY.value = offset.y;
        uniforms.u_height.value = size.height;
        uniforms.u_opacity.value = opacity;
        uniforms.u_color.value = new Vector3(r / 255, g / 255, b / 255);
    });

    return (
        <mesh
            layers={VttCameraLayers.DefaultNoRaycasting}
            position={[-(offset.x - size.width / 2), -(-offset.y + size.height / 2), ZIndexes.Underlay]}>
            <planeGeometry attach="geometry" args={[size.width, size.height]} />
            <shaderMaterial
                attach="material"
                fragmentShader={squareGridLinesShader}
                transparent
                uniforms={uniforms}
                depthWrite={false}
            />
        </mesh>
    );
};

class SquareGrid extends GridBase implements IGrid {
    toLocalPoint(pos: ScreenPixelPosition | GridPosition | LocalPixelPosition): LocalPixelPosition {
        if (pos.type === PositionType.Grid) {
            return {
                type: PositionType.LocalPixel,
                x: pos.x * this.tileSize.width,
                y: pos.y * this.tileSize.height,
            };
        }

        return super.toLocalPoint(pos);
    }

    toLocalCenterPoint(pos: GridPosition | LocalPixelPosition, scale?: number): LocalPixelPosition {
        if (pos.type === PositionType.LocalPixel) {
            return pos;
        }

        // Override the base implementation to provide an equivalent but far more efficient one.
        scale = scale != null ? scale : 1;
        pos = this.getTopLeft(pos, scale);
        return {
            type: PositionType.LocalPixel,
            x: pos.x * this.tileSize.width + (this.tileSize.width * scale) / 2,
            y: pos.y * this.tileSize.height + (this.tileSize.height * scale) / 2,
        };
    }

    toLocalPoints(pos: GridPosition | LocalPixelPosition, scale?: number) {
        scale = scale != null ? scale : 1;

        if (pos.type === PositionType.LocalPixel) {
            // Local pixel position is the center, so the bounds are just the center +- half the tilewidth * scale.
            const halfScale = scale / 2;
            const left = pos.x - halfScale * this.tileSize.width;
            const top = pos.y - halfScale * this.tileSize.height;
            const right = pos.x + halfScale * this.tileSize.width;
            const bottom = pos.y + halfScale * this.tileSize.height;
            const points: LocalPixelPosition[] = [
                { type: PositionType.LocalPixel, x: left, y: top },
                { type: PositionType.LocalPixel, x: right, y: top },
                { type: PositionType.LocalPixel, x: right, y: bottom },
                { type: PositionType.LocalPixel, x: left, y: bottom },
            ];
            return points;
        }

        pos = this.getTopLeft(pos, scale);
        const left = pos.x * this.tileSize.width;
        const top = pos.y * this.tileSize.height;
        const points: LocalPixelPosition[] = [
            { type: PositionType.LocalPixel, x: left, y: top },
            { type: PositionType.LocalPixel, x: left + this.tileSize.width * scale, y: top },
            {
                type: PositionType.LocalPixel,
                x: left + this.tileSize.width * scale,
                y: top + this.tileSize.height * scale,
            },
            { type: PositionType.LocalPixel, x: left, y: top + this.tileSize.height * scale },
        ];
        return points;
    }

    /**
     * Gets the top left corner of the grid tiles bound by the specified "center" position
     * and scale. Note that pos can't actually be a center if the scale is even - in that case
     * the top leftmost of the center 4 tiles is considered the center.
     */
    private getTopLeft(pos: GridPosition, scale: number): GridPosition {
        if (scale <= 2) {
            return pos;
        }

        const translate = Math.trunc((scale - 1) / 2);
        return gridPoint(pos.x - translate, pos.y - translate);
    }

    toLocalBounds(pos: GridPosition | LocalPixelPosition, scale?: number): LocalRect {
        scale = scale != null ? scale : 1;

        if (pos.type === PositionType.LocalPixel) {
            // Local pixel position is the center, so the bounds are just the center +- half the tilewidth * scale.
            const halfScale = scale / 2;
            return {
                type: PositionType.LocalPixel,
                x: pos.x - halfScale * this.tileSize.width,
                y: pos.y - halfScale * this.tileSize.height,
                width: this.tileSize.width * scale,
                height: this.tileSize.height * scale,
            };
        }

        pos = this.getTopLeft(pos, scale);
        const topLeft = this.toLocalPoint(pos);
        return {
            type: PositionType.LocalPixel,
            x: topLeft.x,
            y: topLeft.y,
            width: this.tileSize.width * scale,
            height: this.tileSize.height * scale,
        };
    }

    toGridPoint(pos: LocalPixelPosition | ScreenPixelPosition | GridPosition, scale?: number): GridPosition {
        if (pos.type === PositionType.Grid) {
            return pos;
        } else if (pos.type === PositionType.ScreenPixel) {
            return this.toGridPoint(this.toLocalPoint(pos), scale);
        }

        // TODO: Currently this returns the top left grid point. Really I think this should return the center pos.
        // If we do that though, we have to fix the drag positioning because the withDrag stuff seems to use the top
        // left corner?
        // return {
        //     type: PositionType.Grid,
        //     x: Math.floor((pos.x - ((scale ?? 1) - 1) * (this.tileSize.width / 2)) / this.tileSize.width),
        //     y: Math.floor((pos.y - ((scale ?? 1) - 1) * (this.tileSize.height / 2)) / this.tileSize.height)
        // };
        return {
            type: PositionType.Grid,
            x: Math.floor(pos.x / this.tileSize.width),
            y: Math.floor(pos.y / this.tileSize.height),
        };
    }

    forEachGridPointIntersecting(bounds: LocalRect, callback: (pos: GridPosition) => void) {
        const topLeft = this.toGridPoint(bounds);
        const bottomRight = this.toGridPoint(localPoint(bounds.x + bounds.width, bounds.y + bounds.height));
        const width = bottomRight.x - topLeft.x + 1;
        const height = bottomRight.y - topLeft.y + 1;

        for (let i = 0; i < width; i++) {
            for (let j = 0; j < height; j++) {
                const gridPos = gridPoint(i + topLeft.x, j + topLeft.y);
                callback(gridPos);
            }
        }
    }

    contains(pos: GridPosition | LocalPixelPosition, gridPos: GridPosition, scale?: number) {
        scale = scale != null ? Math.trunc(scale) : 1;
        pos = this.toGridPoint(pos, scale);
        gridPos = this.getTopLeft(gridPos, scale);
        return (
            pos.x >= gridPos.x &&
            pos.x <= gridPos.x + (scale - 1) &&
            pos.y >= gridPos.y &&
            pos.y <= gridPos.y + (scale - 1)
        );
    }

    render(r: number, g: number, b: number, opacity: number, size: Size) {
        return (
            <SquareGridLines
                scale={this.scale}
                offset={this.offset}
                tileSize={this.tileSize}
                r={r}
                g={g}
                b={b}
                opacity={opacity}
                size={size}
            />
        );
    }

    getNeighbours(pos: GridPosition): GridPosition[] {
        return [
            gridPoint(pos.x - 1, pos.y - 1),
            gridPoint(pos.x, pos.y - 1),
            gridPoint(pos.x + 1, pos.y - 1),
            gridPoint(pos.x - 1, pos.y),
            gridPoint(pos.x + 1, pos.y),
            gridPoint(pos.x - 1, pos.y + 1),
            gridPoint(pos.x, pos.y + 1),
            gridPoint(pos.x + 1, pos.y + 1),
        ];
    }

    getTraversalCostModifier(a: GridPosition, b: GridPosition): number {
        // Slightly favour non-diagonals, as they're closer by local pixel distance.
        if (a.x !== b.x || a.y !== b.y) {
            return 0.001;
        }

        return 0;
    }

    // findGridPath(from: GridPosition, to: GridPosition, traversalCost?: (a: GridPosition, b: GridPosition, from: GridPosition, to: GridPosition, state: GlobalPathState, nodeState?: any) => number | { cost: number, compareCost?: number, nodeState?: any }, previousPath?: PathResult<GridPosition>): PathResult<GridPosition> | undefined {
    //     return super.findGridPath(from, to, (a, b, from, to, state, nodeState) => {
    //         let cost: number | { cost: number, compareCost?: number, nodeState?: any } = traversalCost?.(a, b, from, to, state, nodeState) ?? 1;

    //         if (a.x !== b.x || a.y !== b.y) {
    //             if (typeof cost === "object") {
    //                 cost.compareCost = (cost.compareCost ?? cost.cost) + 0.001;
    //             } else {
    //                 return { cost: cost, compareCost: cost + 0.001 };
    //             }
    //         }

    //         return cost;
    //     }, previousPath);
    // }

    getSettingsFromPoints(points: LocalPixelPosition[]) {
        // Need at least 2 points to make any sort of judgement.
        if (points.length < 2) {
            return undefined;
        }

        let x = this.getTileSize(points.map(o => o.x));
        let y = this.getTileSize(points.map(o => o.y));

        if (x == null && y == null) {
            return undefined;
        }

        // If either one couldn't be determined, use the other. Most grids will have the same value for x as y.
        // TODO: Also round to the same value if they're really close?
        if (x == null && y != null) {
            x = y;
        } else if (y == null && x != null) {
            y = x;
        }

        // Round to 2 decimal places, more precision is overkill.
        x!.size = Math.round(x!.size * 100) / 100;
        y!.size = Math.round(y!.size * 100) / 100;

        // Pos can round to the nearest pixel. It doesn't scale with the size or position on the map the way that tile size does,
        // so nearest pixel works out fine.
        // Also, if both values are < 1 then it's almost certainly mean to be 0,0.
        if (Math.abs(x!.pos) < 1 && Math.abs(y!.pos) < 1) {
            x!.pos = 0;
            y!.pos = 0;
        } else {
            x!.pos = Math.round(x!.pos);
            y!.pos = Math.round(y!.pos);
        }

        // If the values are really close after rounding to 2 decimal places, then they're almost certainly meant to be the same.
        if (Math.abs(x!.size - y!.size) <= 0.1) {
            y!.size = x!.size;
        }

        if (Math.abs(x!.pos - y!.pos) <= 0.1) {
            y!.pos = x!.pos;
        }

        return { tileSize: { width: x!.size, height: y!.size }, pos: localPoint(x!.pos, y!.pos) };
    }

    private getTileSize(values: number[]) {
        const valuesCopy = values.slice();
        valuesCopy.sort((a, b) => a - b);

        // Find the smallest gap between 2 points - that will be our initial guess at tile width.
        let smallestGap: number | undefined;
        let smallestGapIndex: number;
        for (let i = 1; i < valuesCopy.length; i++) {
            const gap = valuesCopy[i] - valuesCopy[i - 1];

            // If the gap is very small, then it's probably on the same line - just disregard this one.
            if (gap < 20) {
                valuesCopy.splice(i, 1);
                i--;
            } else if (smallestGap == null || gap < smallestGap) {
                smallestGap = gap;
                smallestGapIndex = i - 1;
            }
        }

        if (smallestGap == null) {
            return undefined;
        }

        // Remove the indexes that give the smallest gap. Then we can use the rest of the points to help
        // smooth that gap.
        const start = valuesCopy[smallestGapIndex!];
        valuesCopy.splice(smallestGapIndex!, 2);

        const guesses = valuesCopy.map(o => {
            // First, convert the position to a guess of the number of tiles away from the start point.
            const dist = Math.abs(o - start);
            const tileCount = Math.round(dist / smallestGap!);

            // This should give us an estimate of the tile size for this position.
            return { guess: dist / tileCount, weight: tileCount };
        });

        // The smallest gap also counts as an estimate.
        guesses.push({ guess: smallestGap!, weight: 1 });

        // The final result is the average of all those points, weighted by how many tiles they represent.
        let guessTotal = 0;
        let weightTotal = 0;
        for (let i = 0; i < guesses.length; i++) {
            guessTotal += guesses[i].guess * guesses[i].weight;
            weightTotal += guesses[i].weight;
        }

        const average = guessTotal / weightTotal;

        // We also need to work out whether we need to fudge the background location.
        const totalPos = values.reduce((p, c) => {
            let diff = c % average;
            if (diff > average / 2) {
                diff -= average;
            }

            return p + diff;
        }, 0);
        const pos = -(totalPos / values.length);

        return { size: average, pos: pos };
    }
}

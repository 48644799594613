/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { PropsWithChildren, RefObject, useCallback, useContext, useMemo, useRef, useState } from "react";
import { FunctionComponent } from "react";
import { Box, Heading, Text } from "../primitives";
import { Button } from "../Button";

import { useDispatch, useUser, useCampaign } from "../contexts";
import { removeLocation, setPlayerLocation } from "../../actions/campaign";
import { CampaignPlayer, LocationSummary } from "../../store";
import ProfileAvatar from "../ProfileAvatar";
import { dragDropPalette, useTypedDroppable } from "../draggable";
import { MotionImage } from "../motion";
import { theme } from "../../design";
import { motion } from "framer-motion";
import { ControlledMenu } from "../menus";
import { MenuItem, useMenuState } from "@szhsin/react-menu";
import { getThemeColor } from "../../design/utils";
import { exportLocation } from "../../export";
import { LobotomizedBox, resolveUri } from "../common";
import { ModalDialog } from "../modal";
import FocusTrap from "focus-trap-react";

// Button has issues with framer-motion because its ref returns an inner element instead of the outer one,
// but that's ok here because we're not using it to animate layout.
const MotionButton = motion(Button);

export interface LocationSummaryProps {
    location: LocationSummary;
}

const LocationSummaryContext = React.createContext(
    undefined as any as {
        showContextMenu: (location: LocationSummary, anchorRef: RefObject<Element>) => void;
        current?: LocationSummary;
    }
);

export const LocationSummaryHost: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [location, setLocation] = useState<LocationSummary>();
    const [anchorRef, setAnchorRef] = useState<RefObject<Element>>();

    const { campaign } = useCampaign();
    const dispatch = useDispatch();

    const [menuProps, toggleMenu] = useMenuState({ transition: true });

    const showContextMenu = useCallback(
        (location: LocationSummary, anchorRef: RefObject<Element>) => {
            setLocation(location);
            setAnchorRef(anchorRef);
            toggleMenu(true);
        },
        [setLocation, setAnchorRef, toggleMenu]
    );

    const contextValue = useMemo(
        () => ({
            showContextMenu: showContextMenu,
            current: location,
        }),
        [showContextMenu, location]
    );

    const [pendingDelete, setPendingDelete] = useState<LocationSummary>();
    const lastPendingDelete = useRef<LocationSummary>();
    if (pendingDelete != null) {
        lastPendingDelete.current = pendingDelete;
    }

    return (
        <React.Fragment>
            <LocationSummaryContext.Provider value={contextValue}>{children}</LocationSummaryContext.Provider>
            <ControlledMenu
                direction="bottom"
                align="center"
                {...menuProps}
                onClose={() => {
                    toggleMenu(false);
                    setLocation(undefined);
                }}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onItemClick={e => {
                    e.syntheticEvent.stopPropagation();
                    e.syntheticEvent.preventDefault();
                }}
                anchorRef={anchorRef}>
                {location && (
                    <React.Fragment>
                        <MenuItem
                            onClick={() => {
                                const players = Object.values(campaign.players).filter(o => o.role !== "GM");
                                for (let player of players) {
                                    dispatch(setPlayerLocation(campaign.id, player.userId, location.id));
                                }
                            }}>
                            Send players here
                        </MenuItem>
                        <MenuItem
                            onClick={async () => {
                                const pkg = await exportLocation(location.id, campaign, {
                                    includeMedia: "currentUser",
                                });
                                const blobUrl = URL.createObjectURL(pkg);
                                var link = document.createElement("a"); // Or maybe get it from the current document
                                link.href = blobUrl;
                                link.download = `${location.label ?? "New location"}.zip`;
                                link.click();
                            }}>
                            Export
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setPendingDelete(location);
                            }}>
                            Delete
                        </MenuItem>
                    </React.Fragment>
                )}
            </ControlledMenu>

            <ModalDialog
                onRequestClose={() => {
                    setPendingDelete(undefined);
                }}
                isOpen={pendingDelete != null}
                style={{
                    content: {
                        width: theme.space[12],
                    },
                }}>
                <FocusTrap focusTrapOptions={{ initialFocus: "#modalDefault", allowOutsideClick: true }}>
                    <Box flexDirection="column" p={3} maxWidth={theme.space[13]} alignItems="flex-start">
                        <Heading
                            as="h3"
                            mb={3}
                            css={{ textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden" }}>
                            Delete {lastPendingDelete.current?.label}?
                        </Heading>
                        <Text>Are you sure you want to delete this location?</Text>
                        <LobotomizedBox justifyContent="flex-end" mt={3} fullWidth>
                            <Button
                                disabled={pendingDelete == null}
                                id="modalDefault"
                                variant="primary"
                                onClick={async () => {
                                    if (pendingDelete) {
                                        dispatch(removeLocation(campaign.id, pendingDelete));
                                    }
                                }}>
                                Delete
                            </Button>
                            <Button
                                disabled={pendingDelete == null}
                                variant="secondary"
                                onClick={() => setPendingDelete(undefined)}>
                                Keep
                            </Button>
                        </LobotomizedBox>
                    </Box>
                </FocusTrap>
            </ModalDialog>
        </React.Fragment>
    );
};

export const LocationSummaryNode: FunctionComponent<LocationSummaryProps> = ({ location }) => {
    const dispatch = useDispatch();
    const user = useUser();
    const { campaign } = useCampaign();
    const ref = useRef<HTMLDivElement>(null);
    const { showContextMenu, current } = useContext(LocationSummaryContext);

    const { setNodeRef, active, isOver } = useTypedDroppable({
        id: location.id,
        accepts: ["CampaignPlayer"],
        onDrop: (drag, active) => {
            if (Array.isArray(drag.data)) {
                for (let player of drag.data as CampaignPlayer[]) {
                    dispatch(setPlayerLocation(campaign.id, player.userId, location.id));
                }
            } else {
                const player = drag.data as CampaignPlayer;
                dispatch(setPlayerLocation(campaign.id, player.userId, location.id));
            }
        },
        renderFeedback: drag => {
            let name: string;
            if (Array.isArray(drag.data)) {
                name = "party";
            } else {
                const player = drag.data as CampaignPlayer;
                name = player.name;
            }

            return (
                <React.Fragment>
                    Drop to send {name} to {location.label}.
                </React.Fragment>
            );
        },
    });

    const usersAtLocation: CampaignPlayer[] = [];
    for (let userId in campaign.players) {
        if (userId !== user.id) {
            const campaignPlayer = campaign.players[userId];
            if (campaignPlayer.location === location.id) {
                usersAtLocation.push(campaignPlayer);
            }
        }
    }

    const { thumbnailUri, label } = location;
    const { location: playerLocation } = campaign.players[user.id];
    return (
        <React.Fragment>
            <MotionButton
                ref={setNodeRef}
                css={{
                    padding: 0,
                    cursor: "pointer",
                    height: "auto",
                    outlineOffset: "2px",
                    outline: current?.id === location.id ? `2px solid ${theme.colors.guidance.focus}` : undefined,
                }}
                px={0}
                py={0}
                variant="tertiary"
                toggled={playerLocation === location.id}
                fullWidth
                animate={{
                    backgroundColor: active
                        ? getThemeColor(isOver ? dragDropPalette[1] : dragDropPalette[0])
                        : undefined,
                    color: active ? getThemeColor(theme.colors.foreground) : undefined,
                }}
                onClick={() => {
                    dispatch(setPlayerLocation(campaign.id, user.id, location.id));
                }}
                onContextMenu={e => {
                    showContextMenu(location, ref);
                    e.preventDefault();
                }}>
                <Box ref={ref} fullWidth fullHeight color="inherit" flexDirection="column" alignItems="flex-start">
                    {thumbnailUri && (
                        <MotionImage
                            src={resolveUri(thumbnailUri)}
                            fullWidth
                            borderRadius="4px 4px 0 0"
                            height="12rem"
                            css={{ objectFit: "cover" }}
                            draggable={false}
                            animate={{ opacity: active ? 0.5 : 1 }}
                        />
                    )}
                    <Box fullWidth color="inherit" justifyContent="space-between" px={2} py={2}>
                        <Text color="inherit">{label}</Text>
                    </Box>
                    {usersAtLocation.length > 0 && (
                        <Box fullWidth color="inherit" justifyContent="flex-start" px={2} py={2}>
                            {usersAtLocation.map(o => (
                                <ProfileAvatar
                                    key={o.userId}
                                    profile={campaign.players[o.userId]}
                                    campaignProfile={o}
                                    pr={2}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
            </MotionButton>
        </React.Fragment>
    );
};

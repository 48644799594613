/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box, Heading, Text } from "../primitives";
import React, { PropsWithChildren, RefObject, useCallback, useContext, useMemo, useRef, useState } from "react";
import { FunctionComponent } from "react";
import { deleteTokenTemplate, modifyTokenTemplate } from "../../actions/campaign";
import { PositionType } from "../../position";
import { TokenTemplate } from "../../store";
import { ControlledMenu, renderContextMenuItem } from "../menus";
import { useCampaign, useDispatch, useRole } from "../contexts";
import { DraggableBox } from "../draggable";
import LanternIcon from "../icons/Lantern";
import { LightShaderTemplate, lightTemplates } from "../LocationStage/Lighting";
import { MenuItem, SubMenu, useMenuState } from "@szhsin/react-menu";
import { nanoid } from "nanoid";
import { LobotomizedBox, tokenImageSize } from "../common";
import { ListItem } from "../ListBox";
import { ModalDialog } from "../modal";
import { theme } from "../../design";
import FocusTrap from "focus-trap-react";
import { Button } from "../Button";

const LightTemplateListItem: FunctionComponent<{
    tokenTemplate: TokenTemplate;
}> = ({ tokenTemplate }) => {
    const lightTemplate = Object.keys(lightTemplates)
        .map(o => lightTemplates[o] as LightShaderTemplate)
        .find(o => o.id === tokenTemplate!.light!.type)!;

    return (
        <DraggableBox
            draggableId={tokenTemplate.templateId}
            dragOverlay
            fullWidth
            data={() => {
                return Object.assign({}, tokenTemplate, {
                    id: nanoid(),
                    templateId: undefined,
                    pos: { x: Number.NaN, y: Number.NaN, type: PositionType.LocalPixel },
                });
            }}
            type="Token"
            justifyContent="flex-start">
            <Box
                mr={3}
                width={tokenImageSize}
                height={tokenImageSize}
                bg="grayscale.7"
                borderRadius={3}
                css={{ position: "relative" }}>
                <LanternIcon size={40 as any} />
            </Box>
            {lightTemplate.label}
        </DraggableBox>
    );
};

const TokenTemplateListItemContent: FunctionComponent<{ tokenTemplate: TokenTemplate }> = ({ tokenTemplate }) => {
    const { system } = useCampaign();
    return (
        system.renderTokenTemplate(tokenTemplate!) ?? (
            <React.Fragment>
                {tokenTemplate!.light && <LightTemplateListItem tokenTemplate={tokenTemplate} />}
            </React.Fragment>
        )
    );
};

export const TokenTemplateSearchResult: FunctionComponent<{
    tokenTemplate: TokenTemplate;
}> = ({ tokenTemplate }) => {
    const { showContextMenu } = useContext(TokenTemplateContext);
    const ref = useRef<HTMLDivElement>(null);

    return (
        <Box
            fullWidth
            ref={ref}
            onContextMenu={e => {
                showContextMenu(tokenTemplate, ref);
                e.preventDefault();
            }}>
            <TokenTemplateListItemContent tokenTemplate={tokenTemplate} />
        </Box>
    );
};

export const TokenTemplateListItem: FunctionComponent<{
    tokenTemplate: TokenTemplate;
    isSelected?: boolean;
    isActive?: boolean;
    isFocused?: boolean;
}> = ({ tokenTemplate, isSelected, isActive, isFocused }) => {
    const { showContextMenu, current } = useContext(TokenTemplateContext);
    const ref = useRef<HTMLDivElement>(null);

    return (
        <ListItem
            ref={ref}
            selected={isSelected}
            active={isActive}
            focused={isFocused}
            isContextMenuTarget={current?.templateId === tokenTemplate.templateId}
            onContextMenu={e => {
                showContextMenu(tokenTemplate, ref);
                e.preventDefault();
            }}>
            <TokenTemplateListItemContent tokenTemplate={tokenTemplate} />
        </ListItem>
    );
    // return (
    //     <Box
    //         borderRadius={3}
    //         bg={isSelected ? "accent.2" : isActive ? (isFocused ? "grayscale.7" : "grayscale.8") : undefined}
    //         css={{
    //             outlineOffset: "2px",
    //             outline:
    //                 current?.templateId === tokenTemplate.templateId
    //                     ? `2px solid ${theme.colors.guidance.focus}`
    //                     : undefined,
    //             textShadow: isSelected || isActive ? "none" : "inherit",
    //             ":hover": {
    //                 background: canSelect
    //                     ? isSelected
    //                         ? theme.colors.accent[2]
    //                         : theme.colors.grayscale[8]
    //                     : "initial",
    //                 textShadow: canSelect ? "none" : "inherit",
    //                 zIndex: 1,
    //             },
    //         }}
    //         px={canSelect ? 3 : undefined}
    //         my={canSelect ? -2 : undefined}
    //         py={canSelect ? 2 : undefined}
    //         mb={canSelect ? 1 : 2}
    //         ref={ref}
    //         fullWidth
    //         onContextMenu={e => {
    //             showContextMenu(tokenTemplate, ref);
    //             e.preventDefault();
    //         }}>
    //         {system.renderTokenTemplate(tokenTemplate!) ?? (
    //             <React.Fragment>
    //                 {tokenTemplate!.light && <LightTemplateListItem tokenTemplate={tokenTemplate} />}
    //             </React.Fragment>
    //         )}
    //     </Box>
    // );
};

const TokenTemplateContext = React.createContext(
    undefined as any as {
        showContextMenu: (token: TokenTemplate, anchorRef: RefObject<Element>) => void;
        current?: TokenTemplate;
    }
);

export const TokenTemplateHost: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [token, setToken] = useState<TokenTemplate>();
    const [anchorRef, setAnchorRef] = useState<RefObject<Element>>();

    const { campaign, system } = useCampaign();
    const dispatch = useDispatch();
    const role = useRole();

    const [menuProps, toggleMenu] = useMenuState({ transition: true });
    const menuItems = system.getTokenContextMenuItems(token, [], campaign).map(renderContextMenuItem);

    const showContextMenu = useCallback(
        (token: TokenTemplate, anchorRef: RefObject<Element>) => {
            setToken(token);
            setAnchorRef(anchorRef);
            toggleMenu(true);
        },
        [setToken, setAnchorRef, toggleMenu]
    );

    const contextValue = useMemo(
        () => ({
            showContextMenu: showContextMenu,
            current: token,
        }),
        [showContextMenu, token]
    );

    const [pendingDelete, setPendingDelete] = useState<TokenTemplate>();
    const lastPendingDelete = useRef<TokenTemplate>();
    if (pendingDelete != null) {
        lastPendingDelete.current = pendingDelete;
    }

    return (
        <React.Fragment>
            <TokenTemplateContext.Provider value={contextValue}>{children}</TokenTemplateContext.Provider>
            <ControlledMenu
                align="center"
                {...menuProps}
                onClose={() => {
                    toggleMenu(false);
                    setToken(undefined);
                }}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onItemClick={e => {
                    e.syntheticEvent.stopPropagation();
                    e.syntheticEvent.preventDefault();
                }}
                anchorRef={anchorRef}>
                {token && (
                    <React.Fragment>
                        {campaign.tokens[token.templateId] && (
                            <React.Fragment>
                                {role === "GM" && (
                                    <SubMenu label="Assign to">
                                        {Object.keys(campaign.players)
                                            .filter(
                                                o =>
                                                    o !== token.owner &&
                                                    (!campaign.players[o].role || campaign.players[o].role === "Player")
                                            )
                                            .map(o => (
                                                <MenuItem
                                                    key={campaign.players[o].userId}
                                                    onClick={() =>
                                                        dispatch(
                                                            modifyTokenTemplate(campaign.id, [token], {
                                                                owner: o,
                                                            })
                                                        )
                                                    }>
                                                    {campaign.players[o].name}
                                                </MenuItem>
                                            ))}
                                        {token.owner != null && (
                                            <MenuItem
                                                key="nobody"
                                                onClick={() =>
                                                    dispatch(
                                                        modifyTokenTemplate(campaign.id, [token], {
                                                            owner: undefined,
                                                        })
                                                    )
                                                }>
                                                Nobody
                                            </MenuItem>
                                        )}
                                    </SubMenu>
                                )}
                                <MenuItem
                                    onClick={() => {
                                        setPendingDelete(token);
                                    }}>
                                    Delete
                                </MenuItem>
                            </React.Fragment>
                        )}
                        {menuItems}
                    </React.Fragment>
                )}
            </ControlledMenu>

            <ModalDialog
                onRequestClose={() => {
                    setPendingDelete(undefined);
                }}
                isOpen={pendingDelete != null}
                style={{
                    content: {
                        width: theme.space[12],
                    },
                }}>
                <FocusTrap focusTrapOptions={{ initialFocus: "#modalDefault", allowOutsideClick: true }}>
                    <Box flexDirection="column" p={3} maxWidth={theme.space[13]} alignItems="flex-start">
                        <Heading
                            as="h3"
                            mb={3}
                            css={{ textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden" }}>
                            Delete{" "}
                            {lastPendingDelete.current
                                ? system.getDisplayName(lastPendingDelete.current, campaign)
                                : undefined}
                            ?
                        </Heading>
                        <Text>
                            Are you sure you want to delete{" "}
                            {lastPendingDelete.current
                                ? system.getDisplayName(lastPendingDelete.current, campaign)
                                : undefined}
                            ?
                        </Text>
                        <LobotomizedBox justifyContent="flex-end" mt={3} fullWidth>
                            <Button
                                disabled={pendingDelete == null}
                                id="modalDefault"
                                variant="primary"
                                onClick={async () => {
                                    if (pendingDelete) {
                                        dispatch(deleteTokenTemplate(campaign.id, [pendingDelete]));
                                        setPendingDelete(undefined);
                                    }
                                }}>
                                Delete
                            </Button>
                            <Button
                                disabled={pendingDelete == null}
                                variant="secondary"
                                onClick={() => setPendingDelete(undefined)}>
                                Keep
                            </Button>
                        </LobotomizedBox>
                    </Box>
                </FocusTrap>
            </ModalDialog>
        </React.Fragment>
    );
};

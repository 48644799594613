import React from 'react'
  
  const LocationIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 512 512'
      width={size}
      height={size}
      stroke={color}
    >
      <path d="M256 17.108c-75.73 0-137.122 61.392-137.122 137.122.055 23.25 6.022 46.107 11.58 56.262L256 494.892l119.982-274.244h-.063c11.27-20.324 17.188-43.18 17.202-66.418C393.122 78.5 331.73 17.108 256 17.108zm0 68.56a68.56 68.56 0 0 1 68.56 68.562A68.56 68.56 0 0 1 256 222.79a68.56 68.56 0 0 1-68.56-68.56A68.56 68.56 0 0 1 256 85.67z" fill={color} fillOpacity="1"></path>
    </svg>
  )
  
  LocationIcon.displayName = 'LocationIcon'
  
  LocationIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default LocationIcon
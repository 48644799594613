import { AvatarGroup } from "../AvatarGroup";
import { Tag } from "../Tag";
import { Box } from "../primitives";

export const CampaignSummaryFooter = ({ avatarData }) => {
    return (
        <Box as="footer" fullWidth justifyContent="space-between">
            <AvatarGroup data={avatarData} />
            <Tag bg="grayscale.8">{`${avatarData.length} ${avatarData.length > 1 ? "players" : "player"}`}</Tag>
        </Box>
    );
};

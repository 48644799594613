import React from 'react'
  
  const ImportIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 512 512'
      width={size}
      height={size}
      stroke={color}
    >
        <g transform="translate(14,-8)"><path d="M224 30v256h-64l96 128 96-128h-64V30h-64zM32 434v48h448v-48H32z" fill={color} fillOpacity="1"></path></g>
    </svg>
  )
  
  ImportIcon.displayName = 'ImportIcon'
  
  ImportIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default ImportIcon
import { parse, eval as evalExpr } from "expression-eval";
import { Annotation, isAnnotation, TargetFilter } from "../../annotations";
import { DeepPartial, KeyedList, LocalSetting, Overwrite } from "../../common";
import { resolveTime, resolveUri } from "../../components/common";
import { distanceBetween } from "../../grid";
import { GridPosition, LocalPixelPosition, Point, Rect } from "../../position";
import { compareInitiatives } from "../../reducers/location";
import {
    Campaign,
    isToken,
    isTokenTemplate,
    msPerDay,
    msPerHour,
    msPerMinute,
    Token,
    TokenAppearance,
    TokenTemplate,
    Session,
    getGameTime,
    isLocation,
    AnimationSequence,
    DiceRoll,
    TokenImageMetadata,
    WithLevel,
    evaluateDiceBagLocal,
} from "../../store";
import {
    ActorType,
    AdvantageOrDisadvantage,
    Alignment,
    Character,
    CharacterRuleSet,
    CharacterRuleStore,
    CharacterRuleStoreSummary,
    CoreAbilities,
    Creature,
    CreatureSize,
    CreatureType,
    DamageTypes,
    Feature,
    isCharacter,
    isMonster,
    Monster,
    MovementSpeeds,
    NamedContent,
    ResolvedCharacter,
    ResolvedMonster,
    resolveModifiedValue,
    resolveStoredMonster,
    TerrainType,
} from "./creature";
import { ItemFilter } from "./items";
import { mergeState } from "../../reducers/common";
import { Spell } from "./spells";
import { downloadFile } from "../../components/utils";
import { UserArtLibrary } from "../../library";
import { nanoid } from "nanoid";

export const defaultUnitsPerGrid = 5;
export const msPerRound = 6000;

export type AreaOfEffectType = "cone" | "cube" | "cylinder" | "line" | "sphere" | "creature" | "square";

export interface AreaOfEffect<T extends AreaOfEffectType = AreaOfEffectType> {
    type: T;
}

export interface ConeAreaOfEffect extends AreaOfEffect<"cone"> {
    length: number;
}

export interface CubeAreaOfEffect extends AreaOfEffect<"cube"> {
    length: number;
}

export interface SquareAreaOfEffect extends AreaOfEffect<"square"> {
    length: number;
}

export interface CylinderAreaOfEffect extends AreaOfEffect<"cylinder"> {
    radius: number;
    height?: number;
}

export interface LineAreaOfEffect extends AreaOfEffect<"line"> {
    length: number;
    width: number;
}

export interface SphereAreaOfEffect extends AreaOfEffect<"sphere"> {
    radius: number;
}

export interface CreatureAreaOfEffect extends AreaOfEffect<"creature"> {
    amount: number;

    // The number of targets that should be added for each spell level if cast with a higher level spell slot.
    // i.e. Magic Missile, Scorching Ray, Eldritch Blast
    perLevel?: number;
}

export type StoredMonster = Partial<Monster> & NamedRuleRef;
export type StoredCharacter = Partial<Character> & { type: ActorType.Character };
export type StoredCreature = Creature | StoredMonster | StoredCharacter;

const defaultRuleSets = ["srd"];
export function resolveCampaignSources(sources: string[] | undefined) {
    // If no sources are specified, that means just SRD.
    if (!sources || !sources.length) {
        return defaultRuleSets;
    }

    sources = sources.map(o => o.toLowerCase());

    // If any of PHB, MM, or DMG is not included, then we'll need the SRD.
    if (sources.indexOf("phb") < 0 || sources.indexOf("mm") < 0 || sources.indexOf("dmg") < 0) {
        if (sources.indexOf("srd") < 0) {
            return ["srd", ...sources];
        }
    }

    return sources;
}

export const roundsPerMinute = 10;
export const roundsPerHour = roundsPerMinute * 60;
export const roundsPerDay = roundsPerHour * 24;

const defaultExpressionContext = {
    roundDown: (x: number) => Math.floor(x),
    roundUp: (x: number) => Math.ceil(x),
    max: (a: number, b: number) => Math.max(a, b),
    min: (a: number, b: number) => Math.min(a, b),
};

export type ExpressionableValue<T extends string | number> =
    | T
    | {
          value?: T;
          expression?: string;
      };

export function evaluateCharacterValue<T extends string | number>(
    character: ResolvedCharacter | undefined,
    value: ExpressionableValue<T>,
    params?: { [name: string]: any }
) {
    if (typeof value !== "object") {
        return value;
    }

    if (value.value) {
        return value;
    }

    if (value.expression && character) {
        return evaluateCharacterExpression(character, value.expression, undefined, params);
    }

    return undefined;
}

export function evaluateMonsterValue<T extends string | number>(
    monster: ResolvedMonster | undefined,
    value: ExpressionableValue<T>,
    params?: { [name: string]: any }
) {
    if (typeof value !== "object") {
        return value;
    }

    if (value.value) {
        return value;
    }

    if (value.expression && monster) {
        return evaluateMonsterExpression(monster, value.expression, undefined, params);
    }

    return undefined;
}

export function evaluateCreatureValue<T extends string | number>(
    creature: ResolvedMonster | ResolvedCharacter | undefined,
    value: ExpressionableValue<T>,
    params?: { [name: string]: any }
) {
    if (typeof value !== "object") {
        return value;
    }

    if (value.value) {
        return value;
    }

    if (value.expression && creature) {
        return evaluateCreatureExpression(creature, value.expression, undefined, params);
    }

    return undefined;
}

export function evaluateCharacterExpression(
    character: ResolvedCharacter,
    expression: string | undefined,
    value?: any,
    params?: { [name: string]: any }
) {
    if (value !== undefined || expression == null) {
        return value;
    }

    const expr = parse(expression);

    const context = Object.assign({}, defaultExpressionContext, character, params);
    return evalExpr(expr, context);
}

export function evaluateMonsterExpression(
    monster: ResolvedMonster,
    expression: string | undefined,
    value?: any,
    params?: { [name: string]: any }
) {
    if (value !== undefined || expression == null) {
        return value;
    }

    const expr = parse(expression);

    const context = Object.assign({}, defaultExpressionContext, monster, params);
    return evalExpr(expr, context);
}

export function evaluateCreatureExpression(
    creature: ResolvedMonster | ResolvedCharacter,
    expression: string | undefined,
    value?: any,
    params?: { [name: string]: any }
) {
    if (isMonster(creature)) {
        return evaluateMonsterExpression(creature, expression, value, params);
    }

    return evaluateCharacterExpression(creature, expression, value, params);
}

function durationToStringCore(unit: "round" | "minute" | "hour" | "day" | "permanent" | "special", amount: number) {
    switch (unit) {
        case "permanent":
            return "Permanent";
        case "special":
            return "Special";
        case "day":
            return amount === 1 ? "1 day" : `${amount} days`;
        case "hour":
            return amount === 1 ? "1 hour" : `${amount} hours`;
        case "minute":
            return amount === 1 ? "1 minute" : `${amount} minutes`;
        case "round":
            let rounds = amount;
            const days = Math.floor(rounds / roundsPerDay);
            rounds = rounds % roundsPerDay;
            const hours = Math.floor(rounds / roundsPerHour);
            rounds = rounds % roundsPerHour;
            const minutes = Math.floor(rounds / roundsPerMinute);
            rounds = rounds % roundsPerMinute;

            let r = "";
            if (days > 0) {
                r += durationToStringCore("day", days);
            }

            if (hours > 0) {
                if (r.length > 0) {
                    r += ", ";
                }

                r += durationToStringCore("hour", hours);
            }

            if (minutes > 0) {
                if (r.length > 0) {
                    r += ", ";
                }

                r += durationToStringCore("minute", minutes);
            }

            if (rounds > 0) {
                if (r.length > 0) {
                    r += ", ";
                }

                r += rounds === 1 ? "1 round" : `${rounds} rounds`;
            }

            return r;
    }
}

function timeDurationToString(ms: number) {
    if (ms < msPerMinute * 5) {
        // Display time in rounds remaining.
        const roundsRemaining = Math.ceil(ms / msPerRound);
        return roundsRemaining === 1 ? "1 round" : `${roundsRemaining} rounds`;
    }

    const time = resolveTime(ms);

    let s = "";
    if (time.days > 0) {
        s += time.days === 1 ? "1 day" : `${time.days} days`;
    }

    if (time.hours > 0) {
        if (s.length > 0) {
            s += ", ";
        }

        s += time.hours === 1 ? "1 hour" : `${time.hours} hours`;
    }

    if (time.minutes > 0) {
        if (s.length > 0) {
            s += ", ";
        }

        s += time.minutes === 1 ? "1 minute" : `${time.minutes} minutes`;
    }

    if (time.seconds > 0) {
        if (s.length > 0) {
            s += ", ";
        }

        s += time.seconds === 1 ? "1 second" : `${time.seconds} seconds`;
    }

    return s;
}

export function durationToString(duration: DnD5EDuration | number) {
    if (typeof duration === "number") {
        return timeDurationToString(duration);
    }

    return durationToStringCore(duration.unit, duration.amount ?? 1);
}

export function abilityTimeUnitToString(time: AbilityTimeUnit) {
    switch (time) {
        case "action":
            return "action";
        case "bonus":
            return "bonus action";
        case "day":
            return "day";
        case "free":
            return "free action";
        case "hour":
            return "hour";
        case "legendary":
            return "legendary action";
        case "minute":
            return "minute";
        case "reaction":
            return "reaction";
        case "year":
            return "year";
    }
}

export function abilityTimeToString(time: AbilityTime) {
    return time.amount > 1
        ? `${time.amount} ${abilityTimeUnitToString(time.unit)}s`
        : `${time.amount} ${abilityTimeUnitToString(time.unit)}`;
}

export function getTimeUntil(
    session: Session,
    time: number,
    token?: Token | TokenTemplate,
    trigger?: "sot" | "eot" | "source_sot" | "source_eot"
) {
    const timeToGo = time - getGameTime(session.time);
    if (token == null || isTokenTemplate(token) || session.combatLocation == null) {
        return timeToGo;
    }

    // Combat complicates things, because the duration until an expiry depends on the order of combat.
    // Game time only gets updated at the end of each round, so we have to manually tweak it until then.
    const location = session.campaign.locations[session.combatLocation];
    if (!isLocation(location) || !location.combat || !location.combat.turn) {
        // Couldn't find the location information we need, so fall back to default behaviour.
        return timeToGo;
    }

    const participant = location.combat.participants[token.id];
    const currentParticipant = location.combat.participants[location.combat.turn];
    if (!participant || !currentParticipant) {
        return timeToGo;
    }

    // The token in question has already had its turn if its initiative is less than the current one.
    if (participant === currentParticipant || compareInitiatives(participant, currentParticipant) < 0) {
        // It's currently this token's turn.
        return timeToGo;
    } else {
        // Not this token's turn yet, so as far as it's concerned the time hasn't passed.
        return timeToGo + msPerRound;
    }
}

export interface DnD5EExpressionDuration extends DnD5EDuration {
    amountExpr?: string;
}

export type DurationUnit = "round" | "minute" | "hour" | "day" | "permanent" | "special";
export const durationUnits: DurationUnit[] = ["round", "minute", "hour", "day", "permanent", "special"];

export interface DnD5EDuration {
    unit: DurationUnit;
    amount?: number;
}

export function durationToMs(duration: DnD5EDuration): number | undefined {
    switch (duration.unit) {
        case "special":
        case "permanent":
            return undefined;
        case "day":
            return (duration.amount ?? 1) * msPerDay;
        case "hour":
            return (duration.amount ?? 1) * msPerHour;
        case "minute":
            return (duration.amount ?? 1) * msPerMinute;
        case "round":
            return (duration.amount ?? 1) * msPerRound;
    }
}

export interface CampaignProperties {
    sources?: string[];

    criticalHitType?: "double_result" | "double_dice";

    diagonals?: boolean;

    rollMonsterHp?: boolean;

    ruleset?: CharacterRuleStore;
}

export interface DnD5ECampaign extends Campaign {
    system: "dnd5e";
    dnd5e?: CampaignProperties;
}

export interface SpellEffectInstance {
    // The chosen damage type for this instance. If the spell does not require a choice (most do not)
    // then all damage types with values apply.
    damageType?: DamageType;

    // If the per level additional damage has a choice, then the choice that is made is stored here.
    perLevelType?: DamageType;

    // The actual damage roll for each damage type.
    damage?: Partial<DamageTypes<DiceRoll>>;

    // The actual healing roll.
    healing?: DiceRoll;
}

export interface AbilityEffectResultBase {
    /**
     * The saving throw that was rolled for this spell effect.
     */
    savingThrow?: DiceRoll;

    /**
     * Choices that are made regarding the applied effect of this AbilityEffect.
     */
    appliedChoices?: AppliedAbilityEffectChoices;
}

export interface AbilityEffectResult extends AbilityEffectResultBase {
    /**
     * A value indicating whether this target is excluded from this particular effect.
     */
    isExcluded?: boolean;

    /**
     * The damage resistance to apply to the spell effect, per damage type.
     * If not specified then the default resistance for the target is applied.
     */
    resistances?: Partial<DamageTypes<number>>;

    /**
     * If the damage is rolled per target, then this contains the damage roll results for this target.
     */
    damage?: Partial<DamageTypes<DiceRoll>>;

    /**
     * If the healing is rolled per target, then this contains the healing roll results for this target.
     */
    healing?: DiceRoll;

    applied?: boolean;
}

export interface AttackOptionResult extends AbilityEffectResultBase {
    spellSlotCost?: { level: number; name?: string };
}

export interface AbilityInstanceResult {
    /**
     * The attack roll that was made for this spell effect.
     */
    attack?: DiceRoll;

    /**
     * The number that will be added to the target's AC when determining if the attack succeeds.
     */
    acModifier?: number;

    /**
     * Options that have been enabled for this instance, keyed first by feature and then by key within that feature.
     */
    options?: {
        [featureKey: string]: {
            [id: string]: AttackOptionResult;
        };
    };

    /**
     * When set, the GM has confirmed whether the attack succeeds or not.
     */
    hit?: "crit_hit" | "hit" | "miss" | "crit_miss";

    effects: { [effectId: string]: AbilityEffectResult };
}

type BoundsCorner = "tl" | "tr" | "br" | "bl";
const boundsCorners: BoundsCorner[] = ["tl", "tr", "br", "bl"];

export function getBoundsPoint(bounds: Rect, corner: BoundsCorner) {
    switch (corner) {
        case "tl":
            return { x: bounds.x, y: bounds.y };
        case "tr":
            return { x: bounds.x + bounds.width, y: bounds.y };
        case "br":
            return { x: bounds.x + bounds.width, y: bounds.y + bounds.height };
        case "bl":
            return { x: bounds.x, y: bounds.y + bounds.height };
    }
}

export function getClosestBoundsPoints(boundsA: Rect, boundsB: Rect) {
    const results: { pa: Point; pb: Point; ca: BoundsCorner; cb: BoundsCorner; distance: number }[] = [];
    for (let ca of boundsCorners) {
        for (let cb of boundsCorners) {
            const pa = getBoundsPoint(boundsA, ca);
            const pb = getBoundsPoint(boundsB, cb);
            results.push({
                ca: ca,
                cb: cb,
                pa: pa,
                pb: pb,
                distance: distanceBetween(pa, pb),
            });
        }
    }

    let closestPoint: { pa: Point; pb: Point; ca: BoundsCorner; cb: BoundsCorner; distance: number } | undefined;
    for (let r of results) {
        if (!closestPoint || r.distance < closestPoint.distance) {
            closestPoint = r;
        }
    }

    return closestPoint!;
}

export interface RelativeToBoundsPoint extends Point {
    /**
     * The part of the bounds that this point is relative to, defaulting to top left.
     */
    relativeTo?: "tl" | "tr" | "br" | "bl";

    /**
     * The part of the item to position with this point.
     */
    anchor?: "tl" | "tr" | "br" | "bl";
}

export interface DnD5EAnnotationDetails {
    // Key to the item being used to attack within the character's inventory.
    item?: string;

    // Key to the abilities keyed list on the token to the ability that this annotation represents.
    // If this is specified, then spell should not be.
    ability?: string;

    // Reference to the spell that this annotation represents. If this is specified, then ability should not be.
    spell?: NamedRuleRef;

    // If spell is specified, this is the level that the spell was cast at if different to the natural spell level.
    castAt?: number;

    // If spell is specified, this is the spellcasting ability applicable to the spell.
    castAbility?: CoreAbility;

    // If an item (weapon) is specified, and the weapon is versatile, then this specifies whether the weapon is being wielded
    // with 1 or 2 hands.
    handed?: 1 | 2;

    // A value indicating whether this annotation represents an attack that is part of the creature's attack action.
    isAttackAction?: boolean;

    // The original duration and expiry for the annotation. If none is specified, the annotation remains until it is deleted.
    duration?: DnD5EDuration & { expires?: number };

    // The DC for the saving throw, if any.
    savingThrowDc?: number;

    // Attack modifier to use for attack effects.
    attackModifier?: number;

    // The instance of the ability effects, using the same key as the ability.effects KeyedList.
    // The effect instance contains the rolled damage result etc.
    effects?: { [id: string]: SpellEffectInstance };

    // Information about how the effects are applied to individual targets, split by target and then
    // the same key as the ability.effects KeyedList.
    targetEffects?: { [tokenId: string]: { [instanceId: string]: AbilityInstanceResult } };

    // The position of the adorner, relative to the top left of the annotation bounds.
    adornerPos?: { [userId: string]: RelativeToBoundsPoint };
}

// TODO: Do we just need a reference to the spell in all this? And the casting level?
// We probably do want to have this kind of thing for traps/random environmental hazards etc, but it would be good
// to know the source spell as well?
export interface DnD5EAnnotation extends Annotation {
    dnd5e: DnD5EAnnotationDetails;
}

export interface DnD5EToken extends Token {
    dnd5e: StoredCreature;
}

export interface DnD5ECharacterToken extends Token {
    dnd5e: StoredCharacter;
}

export interface DnD5EMonsterToken extends Token {
    dnd5e: StoredMonster;
}

export interface DnD5ETokenTemplate extends TokenTemplate {
    dnd5e: Creature;
}

export interface DnD5ECharacterTemplate extends DnD5ETokenTemplate {
    dnd5e: Character;
}

export interface DnD5EMonsterTemplate extends DnD5ETokenTemplate {
    dnd5e: Omit<Monster, "label">;
}

export function isDnD5EAnnotation(annotation: any): annotation is DnD5EAnnotation {
    return isAnnotation(annotation) && !!(annotation as any).dnd5e;
}

export function isDnD5EToken(token: any): token is DnD5EToken {
    return isToken(token) && !!(token as any).dnd5e;
}

export function isDnD5ETokenTemplate(tokenTemplate: any): tokenTemplate is DnD5ETokenTemplate {
    return isTokenTemplate(tokenTemplate) && !!(tokenTemplate as any).dnd5e;
}

export function isDnD5ECharacterTemplate(tokenTemplate: any): tokenTemplate is DnD5ECharacterTemplate {
    return isDnD5ETokenTemplate(tokenTemplate) && isCharacter(tokenTemplate.dnd5e);
}

export function isDnD5EMonsterTemplate(tokenTemplate: any): tokenTemplate is DnD5EMonsterTemplate {
    return isDnD5ETokenTemplate(tokenTemplate) && isMonster(tokenTemplate.dnd5e);
}

export function isDnD5ECharacterToken(token: any): token is DnD5ECharacterToken {
    return isDnD5EToken(token) && token.dnd5e["type"] === ActorType.Character;
}

export function isDnD5EMonsterToken(token: any): token is DnD5EMonsterToken {
    return isDnD5EToken(token) && !!token.dnd5e["source"];
}

export function monsterToTokenTemplate(monster: Monster) {
    const tokenTemplate: DnD5EMonsterTemplate = {
        templateId: "dnd5e_" + monster.source + "_" + monster.name.replaceAll(" ", "_"),
        scale: sizeToScale(monster.size),
        dnd5e: monster,
        images: monster.images,
    };
    return tokenTemplate;
}

export function createMonsterToken(
    monsterTemplate: DnD5EMonsterTemplate,
    campaign: Campaign,
    rules: CharacterRuleSet
): Omit<DnD5EMonsterToken, "pos">;
export function createMonsterToken(
    monsterTemplate: DnD5EMonsterTemplate,
    campaign: Campaign,
    rules: CharacterRuleSet,
    pos: WithLevel<GridPosition | LocalPixelPosition>
): DnD5EMonsterToken;
export function createMonsterToken(
    monsterTemplate: DnD5EMonsterTemplate,
    campaign: Campaign,
    rules: CharacterRuleSet,
    pos?: WithLevel<GridPosition | LocalPixelPosition>
) {
    // Pick an image from the template's images at random.
    let metadata: TokenImageMetadata | undefined;
    if (monsterTemplate.images && monsterTemplate.images.length) {
        metadata = monsterTemplate.images[Math.floor(Math.random() * monsterTemplate.images.length)];
    }

    const monster = resolveStoredMonster(monsterTemplate.dnd5e, monsterTemplate.templateId, campaign, rules);
    const monsterScale = sizeToScale(monster?.size);

    // Generate a random max HP for the monster, if that setting has not been disabled.
    let maxHp: number | undefined;
    if ((campaign as DnD5ECampaign).dnd5e?.rollMonsterHp !== false) {
        if (monster?.maxHpInfo?.dice && !monster?.maxHpInfo?.dice?.special) {
            try {
                maxHp = evaluateDiceBagLocal(monster.maxHpInfo.dice);
            } catch {
                // The dice bag did not support local evaluation, just use default.
            }
        }
    }

    const monsterToken: DnD5EMonsterToken | Omit<DnD5EMonsterToken, "pos"> = {
        id: nanoid(),
        templateId: monsterTemplate.templateId,
        type: "creature",
        ignoreTemplate: true,
        pos: pos,
        scale: monsterScale,
        imageUri: metadata ? metadata.uri : undefined,
        canRotate: metadata ? metadata.canRotate : undefined,
        defaultRotation: metadata ? metadata.rotation : undefined,
        renderScale: metadata ? metadata.renderScale : undefined,
        dnd5e: {
            name: monsterTemplate.dnd5e.name,
            source: monsterTemplate.dnd5e.source,
            maxHp: maxHp,
        },
    };

    return monsterToken;
}

export type CoreAbility = "strength" | "dexterity" | "constitution" | "intelligence" | "wisdom" | "charisma";
export const coreAbilities: CoreAbility[] = [
    "strength",
    "dexterity",
    "constitution",
    "intelligence",
    "wisdom",
    "charisma",
];

export type DamageType =
    | "slashing"
    | "piercing"
    | "bludgeoning"
    | "poison"
    | "acid"
    | "fire"
    | "cold"
    | "radiant"
    | "necrotic"
    | "lightning"
    | "thunder"
    | "force"
    | "psychic";
export const damageTypes: DamageType[] = [
    "slashing",
    "piercing",
    "bludgeoning",
    "poison",
    "acid",
    "fire",
    "cold",
    "radiant",
    "necrotic",
    "lightning",
    "thunder",
    "force",
    "psychic",
];

export function damageTypeToString(dt: DamageType) {
    switch (dt) {
        case "acid":
            return "Acid";
        case "bludgeoning":
            return "Bludgeoning";
        case "cold":
            return "Cold";
        case "fire":
            return "Fire";
        case "force":
            return "Force";
        case "lightning":
            return "Lightning";
        case "necrotic":
            return "Necrotic";
        case "piercing":
            return "Piercing";
        case "poison":
            return "Poison";
        case "psychic":
            return "Psychic";
        case "radiant":
            return "Radiant";
        case "slashing":
            return "Slashing";
        case "thunder":
            return "Thunder";
    }
}

export type WeaponType = "simple" | "martial";

export interface NamedRuleRef {
    name: string;
    source: string;
}

export type MergeRule<T> = DeepPartial<T> & NamedRuleRef & { ruleAction: "merge" | "shallow" };

export type MergeableRule<T extends NamedRuleRef> = T | MergeRule<T>;

export type MergedRule<T extends NamedRuleRef> = T & { contributingSources?: string[] };

export function isMergeRule<T extends NamedRuleRef>(r: any): r is MergeRule<T> {
    return r["ruleAction"] === "merge" || r["ruleAction"] === "shallow";
}

export type AbilityTimeUnit =
    | "free"
    | "bonus"
    | "reaction"
    | "action"
    | "minute"
    | "hour"
    | "day"
    | "year"
    | "legendary";
export const abilityTimeUnits: AbilityTimeUnit[] = [
    "free",
    "bonus",
    "reaction",
    "action",
    "minute",
    "hour",
    "day",
    "year",
    "legendary",
];

export interface AbilityTime {
    unit: AbilityTimeUnit;
    amount: number;
}

/**
 * A modifier for a single value.
 */
export interface ValueModifier {
    /**
     * String describing the source of the modifier, used so that you can see what is modifying the value.
     */
    name: string;

    /**
     * The value for the modifier.
     */
    value: number;
}

/**
 * How the modifiers for a value are combined to create the final value.
 * Sum adds all the modifiers together.
 * Max takes the maximum modifier value and ignores the others.
 */
export type ModifierType = "sum" | "max";

/**
 * Represents a value that can have modifiers applied to it.
 */
export interface ModifiedValue {
    /**
     * The base value of the modifier (i.e. 10 for an ability score, 0 for a skill check).
     */
    baseValue: number;

    /**
     * The modifiers that are currently applied to this value.
     */
    modifiers: ValueModifier[];

    /**
     * The multipliers that are currently applied to this value.
     */
    multipliers?: ValueModifier[];

    /**
     * The to combine the modifiers to arrive at a final value.
     * If undefined, sum is used.
     */
    type?: ModifierType;

    /**
     * Reasons that advantage should be applied to any related d20 roll.
     */
    advantage?: AdvantageOrDisadvantage[];

    /**
     * Reasons that disadvantage should be applied to any related d20 roll.
     */
    disadvantage?: AdvantageOrDisadvantage[];

    /**
     * Reasons that a related check or saving throw will automatically fail.
     */
    fail?: AdvantageOrDisadvantage[];

    /**
     * Extra rolls that should be applied to the any related d20 roll (i.e. bless, inspiration, etc).
     */
    extraRolls?: { roll: string; reason: string; source?: AppliedAbilityEffectSource }[];
}

class ModifiedValueImpl implements ModifiedValue {
    constructor(baseValue: number, type?: ModifierType) {
        this.baseValue = baseValue;
        this.type = type;
        this.modifiers = [];
    }

    baseValue: number;
    modifiers: ValueModifier[];
    multipliers?: ValueModifier[] | undefined;
    type?: ModifierType | undefined;
    advantage?: AdvantageOrDisadvantage[] | undefined;
    disadvantage?: AdvantageOrDisadvantage[] | undefined;
    fail?: AdvantageOrDisadvantage[] | undefined;

    valueOf() {
        return resolveModifiedValue(this);
    }
}

export function modifiedValue(baseValue: number, type?: ModifierType): ModifiedValue {
    return new ModifiedValueImpl(baseValue, type);
    //    return { baseValue: baseValue, modifiers: [], type: type };
}

export function formatModifier(modifier: number) {
    return (modifier < 0 ? "" : "+") + modifier;
}

export function isNamedRuleRef(ref: any): ref is NamedRuleRef {
    return ref != null && ref["name"] != null && ref["source"] != null;
}

export interface NamedRuleStore<T> {
    get: (ref: NamedRuleRef) => (T & { key: string }) | undefined;
    all: (T & { key: string })[];
    getByName: (name: string) => (T & { key: string }) | undefined;
}

export function getRuleKey(ref: Overwrite<NamedRuleRef, { source?: string }>) {
    if (!ref.source) {
        return ref.name.toLowerCase().replaceAll(" ", "_");
    }

    return ref.name.toLowerCase() + "~" + ref.source.toLowerCase();
}

export function fromRuleKey(ruleKey: string): NamedRuleRef | undefined {
    if (!ruleKey) {
        return undefined;
    }

    const parts = ruleKey.split("~");
    if (parts.length < 2) {
        return undefined;
    }

    return { name: parts[0].replaceAll("_", " "), source: parts[1] };
}

export function mergeRules<T extends NamedRuleRef>(existingRule: T, rule: MergeRule<T>): T;
export function mergeRules<T extends NamedRuleRef>(
    existingRule: T,
    rule: MergeRule<T>,
    contributingSource?: string
): MergedRule<T>;
export function mergeRules<T extends NamedRuleRef>(
    existingRule: T,
    rule: MergeRule<T>,
    contributingSource?: string
): MergedRule<T> {
    const action = rule.ruleAction;
    let mergedRule: MergedRule<T>;
    if (action === "merge") {
        mergedRule = mergeState(existingRule, rule as any) as T;
    } else {
        mergedRule = Object.assign({}, existingRule, rule);
    }

    if (contributingSource) {
        if (!mergedRule.contributingSources) {
            mergedRule.contributingSources = [contributingSource];
        } else if (mergedRule.contributingSources.indexOf(contributingSource) < 0) {
            mergedRule.contributingSources = [...mergedRule.contributingSources, contributingSource];
        }
    }

    delete mergedRule["ruleAction"];
    return mergedRule;
}

export function createNamedRuleStore<T extends NamedRuleRef>(rules: MergeableRule<T>[]);
export function createNamedRuleStore<
    T extends NamedRuleRef,
    R extends Partial<CharacterRuleStore> = CharacterRuleStore
>(stores: R[], ruleGetter: (store: R) => MergeableRule<T>[] | undefined): NamedRuleStore<T & { key: string }>;
export function createNamedRuleStore<
    T extends NamedRuleRef,
    R extends Partial<CharacterRuleStore> = CharacterRuleStore
>(
    storesOrRules: R[] | MergeableRule<T>[],
    ruleGetter?: (store: R) => MergeableRule<T>[] | undefined
): NamedRuleStore<T & { key: string }> {
    const byNameAndSource = new Map<string, T & { key: string }>();
    const byName = new Map<string, T & { key: string }>();

    if (ruleGetter) {
        const stores = storesOrRules as R[];
        for (let store of stores) {
            const rules = ruleGetter(store);
            if (rules) {
                applyRules(rules, byNameAndSource, byName, store.id);
            }
        }
    } else {
        applyRules(storesOrRules as MergeableRule<T>[], byNameAndSource, byName);
    }

    const all = Array.from(byNameAndSource.values()).sort((a, b) => a.name.localeCompare(b.name));
    return {
        get: (ref: NamedRuleRef) => (ref ? byNameAndSource.get(getRuleKey(ref)) : undefined),
        all: all,
        getByName: (name: string) => (name ? byName.get(name.toLowerCase()) : undefined),
    };
}

function applyRules<T extends NamedRuleRef>(
    rules: MergeableRule<T>[],
    byNameAndSource: Map<string, T & { key: string }>,
    byName: Map<string, T & { key: string }>,
    storeId?: string
) {
    for (let i = 0; i < rules.length; i++) {
        const key = getRuleKey(rules[i]);

        // If this is a partial merge, then we need to get the existing rule and modify it.
        // Otherwise we can just put it in the map.
        const rule = rules[i];
        if (isMergeRule(rule)) {
            const existingRule = byNameAndSource.get(key);
            if (existingRule) {
                const mergedRule = mergeRules(existingRule, rule, storeId) as T & { key: string };

                delete mergedRule["ruleAction"];
                byNameAndSource.set(key, mergedRule);
                byName.set(rules[i].name.toLowerCase(), mergedRule);
            }
        } else {
            const ruleWithKey = Object.assign({ key: key }, rule as T);
            byNameAndSource.set(key, ruleWithKey);
            byName.set(rules[i].name.toLowerCase(), ruleWithKey);
        }
    }
}

/**
 * Convert a creature size into the number of tiles it should take up in each dimension.
 * Each tile is 5x5ft in 5e, so a scale of 2 is 2x2 tiles or 10x10ft.
 */
export function sizeToScale(size: CreatureSize = "Medium") {
    switch (size) {
        case "Large":
            return 2;
        case "Huge":
            return 3;
        case "Gargantuan":
            return 4;
        case "Tiny":
        case "Small":
        case "Medium":
            return 1;
        default:
            return undefined;
    }
}

export type CreatureConditionName =
    | "blinded"
    | "charmed"
    | "deafened"
    | "frightened"
    | "grappled"
    | "incapacitated"
    | "invisible"
    | "paralyzed"
    | "petrified"
    | "poisoned"
    | "prone"
    | "restrained"
    | "stunned"
    | "unconscious"
    | "exhaustion";
export const creatureConditions: CreatureConditionName[] = [
    "blinded",
    "charmed",
    "deafened",
    "frightened",
    "grappled",
    "incapacitated",
    "invisible",
    "paralyzed",
    "petrified",
    "poisoned",
    "prone",
    "restrained",
    "stunned",
    "unconscious",
    "exhaustion",
];

export type CreatureConditions<T> = {
    [condition in CreatureConditionName]: T;
};

export type AbilityRange = number | "touch" | "self" | "sight" | "unlimited" | "special";

/**
 * Default damage info for an ability.
 */
export interface AbilityDamage {
    // The roll for the amount of damage the spell/ability does.
    base: string;

    // The multiplier for damage on successful save - usually 0.5 or 0.
    onSave?: number;
}

export interface RollModifiersBase {
    advantage?: boolean;
    disadvantage?: boolean;

    extraRoll?: string;
}

export interface RollModifiers extends RollModifiersBase {
    fail?: boolean;
    condition?: string;
}

export interface AttackModifiersCondition {
    type?: AttackType;
    ability?: "strength" | "dexterity";
    special?: string;

    /**
     * The distance to the target in ft must be less than or equal to this number to meet the condition.
     */
    maxDistance?: number;

    /**
     * A value indicating whether the max distance is exclusive (i.e. whether it's < rather than <=).
     */
    maxExclusive?: boolean;

    /**
     * The distance to the target in ft must be greater than or equal to this number to meet the condition.
     */
    minDistance?: number;

    /**
     * A value indicating whether the min distance is exclusive (i.e. whether it's > rather than >=).
     */
    minExclusive?: boolean;
}

export interface AttackModifiers extends RollModifiersBase {
    /**
     * The modifier for damage made with this option active.
     * Applies to the first damage type used in the effect.
     * The damage value can either be a single value (i.e. "4"), a dice value ("1d6"), or a reference to a
     * lookup (i.e. "Sneak Attack"). If a lookup is used, then it must be a dice or value lookup.
     */
    damage?: Partial<{ [damageType in DamageType | "weapon"]: string }>;

    /**
     * If true, attacks are automatically counted as critical hits.
     */
    crit?: boolean;

    condition?: AttackModifiersCondition;
}

export interface AbilityEffectTriggers {
    cast?: boolean;
    casterStartOfTurn?: boolean;
    casterEndOfTurn?: boolean;
    targetStartOfTurn?: boolean;
    targetEndOfTurn?: boolean;
}

export const abilityEffectTriggers: (keyof AbilityEffectTriggers)[] = [
    "cast",
    "casterStartOfTurn",
    "casterEndOfTurn",
    "targetStartOfTurn",
    "targetEndOfTurn",
];

export function abilityEffectTriggerToString(trigger: keyof AbilityEffectTriggers, isSpell?: boolean) {
    switch (trigger) {
        case "cast":
            return isSpell ? "On cast" : "On use";
        case "casterEndOfTurn":
            return isSpell ? "End of caster's turn" : "End of turn";
        case "targetEndOfTurn":
            return "End of target's turn";
        case "casterStartOfTurn":
            return isSpell ? "Start of caster's turn" : "Start of turn";
        case "targetStartOfTurn":
            return "Start of target's turn";
    }
}

export interface AbilityEffectBase<T, DT extends string = DamageType> {
    // If not specified, assume instant (on cast/use).
    trigger?: AbilityEffectTriggers;

    // The saving throw that can mitigate the damage or negate the effect, if any.
    savingThrow?: CoreAbility;

    /**
     * The core ability that provides the modifier for calculating the saving throw DC.
     */
    savingThrowDcAbility?: CoreAbility;

    /**
     * The timing for triggering the saving throw.
     * If not specified, assume "cast".
     */
    savingThrowTrigger?: AbilityEffectTriggers;

    damage?: Partial<DamageTypes<T, DT>> & { choice?: DamageType[] };

    heal?: T;

    /**
     * If true, the target is immediately reduced to 0 HP.
     */
    destroy?: boolean;

    /**
     * If specified, then this indicates whether or not damage/healing should be rolled per target where possible.
     * If NOT specified, then default to rolling per target for targetted spells and per effect for AOE.
     * NOTE: This may not be properly implemented for damage - it's really important for mass healing spells, where
     * you want to roll once and everyone heals that amount.
     */
    rollPerTarget?: boolean;
}

/**
 * An effect associated with an ability.
 */
export interface AbilityEffect<T = AbilityDamage, DT extends string = DamageType> extends AbilityEffectBase<T, DT> {
    /**
     * Effects that apply to the target.
     */
    applied?: ApplicableAbilityEffect | NamedRuleRef;

    /**
     * Ends the specified applied effect on the target.
     */
    endApplied?: NamedRuleRef;

    // Adds movement equal to the creature's normal movement multiplied by the specified number.
    addMovement?: number;

    // Adds or removes the specified number of exhaustion levels.
    exhaustion?: number;

    /**
     * If different effects should apply to different targets, then the targets for this particular effect can be filtered
     * by specifying the filter here. Note that filters at this level should be complementary - between all the effects, all
     * valid targets (as determined by Ability.targetFilter) should be covered.
     */
    targetFilter?: TargetFilter;
}

export interface CreatureTransformationBase {
    /**
     * By default the affected creature will use the ability scores of the creature that it has been transformed into.
     * The abilities specified here will be preserved instead.
     */
    coreAbilities?: Partial<CoreAbilities<boolean>>;

    /**
     * By default abilities (provided by class & race features) are not carried over to the transformed form. If this
     * flag is checked then they will - it will be up to the DM to decide which ones are possible with the new form
     * and enforce that.
     */
    abilities?: boolean;

    /**
     * By default the new form will have its own HP pool. If the HP of the old form should carry over, this should be
     * set to true.
     */
    hp?: boolean;

    /**
     * If max, the skill proficiencies of the original creature and the transformed creature are combined, taking the
     * higher bonus of the two where they are both proficient.
     */
    skillProficiencies?: "max";

    /**
     * If max, the saving throw proficiencies of the original creature and the transformed creature are combined, taking
     * the higher bonus of the two where they are both proficient.
     */
    savingThrowProficiencies?: "max";

    /**
     * The token appearance to apply.
     */
    appearance?: TokenAppearance;
}

export interface CreatureTransformationChoice extends CreatureTransformationBase {
    filter?: MonsterFilter;

    /**
     * The monster to transform into. If the name/source are not specified, then the monster is a partial modification to the
     * transforming monster (i.e. a wererat's giant rat form).
     */
    creature?: NamedRuleRef;
}

export interface CreatureTransformation extends CreatureTransformationBase {
    /**
     * The ID of the template that this transformation is based on. This must be a token template from the current campaign.
     */
    templateId: string;

    /**
     * The stored information for the current monster.
     */
    creature: StoredMonster;
}

export interface AppliedEffectEndTriggers {
    /**
     * If true, any damage ends the effect.
     */
    damage?: boolean;
}

export interface AppliedAbilityEffectBase<T = AbilityDamage, DT extends string = DamageType>
    extends AbilityEffectBase<T, DT> {
    /**
     * Display name for the effect.
     */
    name: string;

    /**
     * If this effect extends and/or overrides another effect, that can be specified here.
     * References to the applied effect extended here will affect instances of this effect (i.e. endApplied effect,
     * multiple instance rules, etc.)
     */
    extends?: NamedRuleRef;

    /**
     * The animation to apply to the target while this effect is applied.
     */
    animation?: AnimationSequence;

    /**
     * If true, then multiple instances of this effect can be applied at once.
     */
    allowMultiple?: boolean;

    /**
     * The original duration and expiry of the effect. If the duration is not specified, then the effect's duration may be
     * tracked at the source instead (i.e. a concentration spell).
     */
    duration?: DnD5EDuration & { expires?: number; trigger?: "sot" | "eot" | "source_sot" | "source_eot" };

    /**
     * Modifiers for attacks made by the subject while this effect is applied.
     */
    attacksBy?: KeyedList<AttackModifiers>;

    /**
     * Modifiers for attacks made on the subject by others while this effect is applied.
     */
    attacksOn?: KeyedList<AttackModifiers>;

    // TODO: Link back to the spell instance, so we can remove the effect if the spell drops?

    /**
     * Modifiers for saving throws made while this effect is applied.
     */
    savingThrows?: Partial<CoreAbilities<RollModifiers>> & { all?: RollModifiers };

    /**
     * Modifiers for ability checks made while this effect is applied.
     */
    abilityChecks?: Partial<CoreAbilities<RollModifiers>> & { initiative?: RollModifiers; all?: RollModifiers };

    resistances?: DamageTypes<boolean>;
    immunities?: DamageTypes<boolean>;
    vulnerabilities?: DamageTypes<boolean>;

    /**
     * The creature's base speed will be multiplied by this number.
     */
    speedMultiplier?: number;

    /**
     * Every 1ft of movement will cost this amount more. For example, if the value is 2, then every 1ft of movement costs 3ft.
     */
    moveCost?: number;

    /**
     * The creature's max HP will be multiplied by this number.
     */
    maxHpMultiplier?: number;

    /**
     * Effects or conditions that are part of the effect but are not handled by the vtt should be specified here.
     */
    unhandled?: string;

    /**
     * Effects that are applied when this effect ends (e.g. barbarian frenzied rage applying exhaustion when it ends).
     */
    endEffects?: KeyedList<AbilityEffect>;

    /**
     * If false, the creature cannot take actions while this effect is applied.
     */
    canTakeActions?: boolean;

    /**
     * If false, the creature cannot take reactions while this effect is applied.
     */
    canTakeReactions?: boolean;

    /**
     * If false, the creature cannot cast spells while this effect is applied.
     */
    canCastSpells?: boolean;

    /**
     * Modifier to armor class.
     */
    ac?: number;

    /**
     * The conditions applied by this effect. This will add the same effect as the condition.
     */
    conditions?: Partial<CreatureConditions<boolean>>;

    /**
     * Triggers that can end this effect early.
     */
    endTriggers?: AppliedEffectEndTriggers;
}

export function applicableEffectHasChoices(
    effect: ApplicableAbilityEffect | NamedRuleRef | undefined,
    rules: CharacterRuleSet
) {
    const applicableEffect = isNamedRuleRef(effect) ? rules.effects.get(effect) : effect;
    if (!applicableEffect) {
        return false;
    }

    if (applicableEffect.transform?.filter) {
        return true;
    }

    return false;
}

export function applicableEffectHasUnmadeChoices(
    effect: ApplicableAbilityEffect | NamedRuleRef | undefined,
    choices: AppliedAbilityEffectChoices | undefined,
    rules: CharacterRuleSet
) {
    const applicableEffect = isNamedRuleRef(effect) ? rules.effects.get(effect) : effect;
    if (!applicableEffect) {
        return false;
    }

    if (applicableEffect.transform?.filter && !choices?.transform) {
        return true;
    }

    return false;
}

export interface ApplicableAbilityEffect<T = AbilityDamage, DT extends string = DamageType>
    extends AppliedAbilityEffectBase<T, DT> {
    /**
     * The remaining duration of the effect. If the duration is not specified, then the effect's duration may be
     * tracked at the source instead (i.e. a concentration spell).
     */
    duration?: DnD5EExpressionDuration & { trigger?: "sot" | "eot" | "source_sot" | "source_eot" };

    transform?: CreatureTransformationChoice;
}

export interface AppliedAbilityEffectChoices {
    transform?: { templateId: string; creature: StoredMonster; appearance?: TokenAppearance };
}

interface AppliedAbilityEffectCommon {
    /**
     * A value indicating whether this effect can be removed. This cannot be stored, and is only used when  lving a
     * creature to apply effects that are derived from other states (i.e. a condition that has been applied).
     */
    isReadOnly?: boolean;

    /**
     * Token ID (or token template ID) of the token that applied this effect.
     */
    appliedBy?: string;
    appliedAt?: string;

    /**
     * Instance ID of the spell, if one was used to apply this effect.
     * This is specified for concentration spells - both the appliedBy and instanceId must be specified, but if they are
     * then when the appliedBy token is no longer concentrating on this specific instanceId, then the effect drops.
     */
    instanceId?: string;

    // The DC for any saving throws against this effect.
    savingThrowDc?: number;
}

export interface AppliedAbilityEffectRef extends AppliedAbilityEffectCommon, NamedRuleRef, AppliedAbilityEffectChoices {
    /**
     * The original duration and expiry time of the effect. If the duration is not specified, then the effect's duration may be
     * tracked at the source instead (i.e. a concentration spell).
     */
    duration?: DnD5EDuration & { expires?: number; trigger?: "sot" | "eot" | "source_sot" | "source_eot" };
}

export interface AppliedAbilityEffectSource {
    feature?: Feature;
    ability?: Ability;
    spell?: Spell;
}

export interface AppliedAbilityEffect<T = AbilityDamage, DT extends string = DamageType>
    extends AppliedAbilityEffectCommon,
        AppliedAbilityEffectBase<T, DT> {
    /**
     * The effect transforms the affected creature into another creature.
     */
    transform?: CreatureTransformation;
}

export interface AbilityDuration extends DnD5EExpressionDuration {
    ends?: ("dispel" | "trigger")[];
    isConcentration?: boolean;
}

export type RestType = "shortrest" | "longrest";

/**
 * shortrest: Ability resets after a short rest.
 * longrest: Ability resets after a long rest.
 * will: Immediately - this ability can be used as many times as required.
 */
export type AbilityReset = RestType | "will";

export interface AbilityUse {
    /**
     * The maximum number of times an ability can be used before a reset is required.
     */
    maxUses?: number;

    /**
     * The maximum number of times an ability can be used before a reset is required.
     */
    maxUsesExpr?: string;

    /**
     * When the maximum uses of this spell are reset:
     * shortrest: After a short rest.
     * longrest: After a long rest.
     * will: Immediately - this spell can be used as many times as required.
     */
    reset: AbilityReset;
}

// Melee Weapon attack, Ranged Weapon attack, Melee Spell attack, Ranged spell attack, combinations
export type SingleAttackType = "mw" | "rw" | "ms" | "rs";
export type AttackType = SingleAttackType | "mw,rw" | "ms,rs";

export type SingleAttackTypes<T> = {
    [type in SingleAttackType]: T;
};

// /**
//  * Groups together multiple abilites to be executed as a single ability.
//  */
// export interface AggregateAbility {
//     amount: number;

//     /**
//      * The abilities that are aggregated.
//      */
//     attackOption: KeyedList<{
//         /**
//          * Any of these actions can be taken for this slot.
//          * Values are keys to abilities in the same dictionary that this aggregate ability is found.
//          */
//         names: string[];

//         /**
//          * The maximum number of times this action can be taken.
//          */
//         max: number;

//         /**
//          * The minimum number of times this action can be taken.
//          */
//         min: number;

//         /**
//          * If set, this action should be taken before the others.
//          */
//         first?: boolean;

//         /**
//          * If set, this action should be taken after the others.
//          */
//         last?: boolean;
//     }>;
// }

export interface AbilityCondition {
    /**
     * The condition is met if the user of the ability has the specified effect applied. Can match either the name or the ID.
     */
    hasEffect?: string;

    /**
     * The condition is met if the user of the ability does NOT have the specified effect applied. Can match either the name or the ID.
     */
    notHasEffect?: string;

    /**
     * If specified, then the condition is met if the value matches whether the creature has taken the attack action this turn.
     */
    attackAction?: {
        /**
         * The condition is met if an attack action was made with a weapon matching this filter.
         */
        weapon?: ItemFilter;
    };
}

export interface AbilityAnimation {
    onUse?: AnimationSequence;
    onApply?: AnimationSequence;
    onPlaced?: AnimationSequence;
}

/**
 * An ability that can be used by a character or monster.
 */
export interface Ability<T extends AbilityEffect<any> = AbilityEffect<AbilityDamage>>
    extends NamedContent,
        Partial<AbilityUse> {
    range?: AbilityRange;
    aoe?: AreaOfEffect;

    effects?: KeyedList<T>;
    duration?: AbilityDuration;
    time?: AbilityTime;

    /**
     * A value indicating whether or not this ability counts as part of an attack action - an attack action can consist of multiple
     * attacks, of which this ability counts for one for each instance. If the number of instances of this ability is less than the
     * creature's number of attacks per attack action, then other abilities can be used for the remaining attacks.
     * This is set for character abilities that take one of their attack action attacks, or for monster abilities that are used in
     * a multiattack action.
     */
    isAttackAction?: boolean;

    /**
     * String containing an svg to use as the icon for this ability.
     */
    icon?: string;

    /**
     * The resource cost to activate this ability, if any.
     */
    resourceCost?: {
        [resource: string]: number;
    };

    /**
     * The type of attack, if any, that must succeed before the effects can be applied.
     */
    attack?: AttackType;

    /**
     * For attacks that have disadvantage outside of a certain range.
     */
    rangeNear?: number;

    /**
     * The bonus to apply for the attack.
     */
    attackModifier?: number;

    /**
     * The critical range for the attack. Undefined for the default of 20.
     */
    criticalRange?: number;

    /**
     * The reach in ft for the attack, only valid for melee weapon or spell attacks with a range of touch.
     */
    reach?: number;

    /**
     * The filter for targets of this ability.
     */
    targetFilter?: TargetFilter;

    /**
     * If an item is the source of the ability (i.e. weapon attack), then the item is referenced here as an inventory key.
     */
    item?: string;

    condition?: AbilityCondition;

    /**
     * This ability is replaced with a weapon attack that matches the specified filter. The weapon attack does NOT count
     * as an attack action, and should not increment the attacks counter for the turn.
     * Only the attack itself is replaced, the time/resource cost etc are taken from this ability.
     */
    weaponAttack?: {
        /**
         * If specified, then only attacks with weapons matching this filter can be used to make the attack.
         */
        weapon?: ItemFilter;

        /**
         * If true, any weapon used during the attack action cannot be used for this action.
         */
        excludeAttackWeapon?: boolean;

        /**
         * If true, the damage for the attack will only have the ability modifier applied if it is negative.
         */
        noDamageAbilityModifier?: boolean;
    };

    /**
     * Replaces the ability with the specified key. Ability keys are generated in the format:
     * "<feature rule key>~<ability key within feature>"
     * i.e.
     * "rage~phb~1"
     */
    replaces?: string;

    /**
     * Merges the content of this ability into the existing ability with specified key. The name and content will not be merged.
     * Ability keys are generated in the format:
     * "<feature rule key>~<ability key within feature>"
     * i.e.
     * "rage~phb~1"
     */
    mergesWith?: string;

    animation?: AbilityAnimation;
}

export interface MonsterFilter {
    environment?: TerrainType | TerrainType[];
    creatureType?: CreatureType | CreatureType[];

    alignment?: Alignment | Alignment[];

    cr?: number;
    maxCr?: number; // -1 for target's CR?
    minCr?: number;

    maxSpeed?: Partial<MovementSpeeds<number>>;
}

export interface CharacterFilter {}

/**
 * Setting any of the types here (monster, pc, npc) to a non-null value will at a minimum filter down to that type.
 * Only one should be specified in any filter, others may be ignored.
 */
export interface TokenTemplateFilter {
    monster?: MonsterFilter;

    pc?: CharacterFilter;

    npc?: CharacterFilter;

    light?: {};
}

export const tokenTemplateFilterSetting = new LocalSetting<TokenTemplateFilter>("dnd5e_tokentemplatefilter");

export function advantageOrDisadvantage(
    advantages: AdvantageOrDisadvantage[] | undefined,
    disadvantages: AdvantageOrDisadvantage[] | undefined
) {
    advantages = advantages?.filter(o => o.condition == null);
    disadvantages = disadvantages?.filter(o => o.condition == null);

    // Can only include non-conditionals for the purposes of working out whether we should be at adv/dis by default,
    // because we can't evaluate most conditions automatically.
    let adv: "adv" | "dis" | undefined;
    if (!!(advantages?.length ?? 0) !== !!(disadvantages?.length ?? 0)) {
        adv = advantages?.length ? "adv" : "dis";
    }

    return adv;
}

export function getSingleAttackType(ability: Ability) {
    if (ability.attack === "ms,rs") {
        return "rs";
    } else if (ability.attack === "mw,rw") {
        return "rw";
    } else if (ability.attack == null) {
        return undefined;
    }

    return ability.attack;
}

function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

const cachedRuleStores: { [id: string]: CharacterRuleStore | undefined } = {};
const ruleStores: { [id: string]: Promise<CharacterRuleStore> | undefined } = {};

async function downloadRuleset(idOrUrl: string) {
    let blob: Blob;
    const resolvedUrl = resolveUri(idOrUrl);
    let isUrl = false;
    if (isValidHttpUrl(resolvedUrl)) {
        isUrl = true;
        blob = await downloadFile(resolvedUrl);
    } else {
        blob = await downloadFile(`api/systems/dnd5e/rulesets/download/${idOrUrl}`);
    }

    const ruleStore = JSON.parse(await blob.text()) as CharacterRuleStore;
    ruleStore.uri = isUrl ? idOrUrl : undefined;

    if (!ruleStores[ruleStore.id]) {
        ruleStores[ruleStore.id] = Promise.resolve(ruleStore);
        cachedRuleStores[ruleStore.id.toLowerCase()] = ruleStore;
    }

    return ruleStore;
}

export function getCachedRuleStore(idOrUrl: string): CharacterRuleStore | undefined {
    return cachedRuleStores[idOrUrl];
}

export async function getRuleStore(idOrUrl: string): Promise<CharacterRuleStore> {
    const r = ruleStores[idOrUrl] ?? ruleStores[idOrUrl.toLowerCase()];
    if (r) {
        return r;
    }

    const promise = downloadRuleset(idOrUrl);
    ruleStores[idOrUrl] = promise;

    const ruleStore = await promise;
    cachedRuleStores[idOrUrl] = ruleStore;
    return ruleStore;
}

export async function getAvailableRuleSets(): Promise<CharacterRuleStoreSummary[]> {
    const ruleStores: CharacterRuleStoreSummary[] = [];

    // Add default rulesets that are always available.
    ruleStores.push({
        id: "SRD",
        name: "System Reference Document",
        description: "The base rules for D&D 5th Edition.",
        attribution:
            "This work includes material taken from the System Reference Document 5.1 (“SRD 5.1”) by Wizards of \nthe Coast LLC and available at https://dnd.wizards.com/resources/systems-reference-document. The \nSRD 5.1 is licensed under the Creative Commons Attribution 4.0 International License available at \nhttps://creativecommons.org/licenses/by/4.0/legalcode",
    });

    // TODO: Caching?
    const rulesetBlobs = await UserArtLibrary.current.getCustomFilesAsync("dnd5e/rulesets");
    ruleStores.push(
        ...(rulesetBlobs
            .map(o => {
                const id = o.blob.metadata?.["id"];
                const name = o.blob.metadata?.["name"];
                return id != null && name != null
                    ? ({
                          uri: o.uri,
                          id: id,
                          name: name,
                          description: o.blob.metadata?.["description"],
                      } as CharacterRuleStoreSummary)
                    : undefined;
            })
            .filter(o => !!o) as CharacterRuleStoreSummary[])
    );

    return ruleStores;
}

import styled from "@emotion/styled";
import { compose, system } from "styled-system";

import { BoxProps, boxSystem } from "./Box";

import { containerStyles } from "./containerProps";

// Casting this as any to get around styled-system TS definitions not being correct
const gap: any = {
    property: "gap",
    scale: "space",
};
const stackGap = system({ gap });

export const stackSystem = compose(stackGap, boxSystem);

export interface IStackProps {
    gap?: any;
}

export type StackProps = IStackProps & BoxProps;

export const Stack = styled("div")<StackProps>(
    {
        display: "flex",
        flexDirection: "column",
    },
    containerStyles,
    stackSystem
);

Stack.displayName = "Stack";

Stack.defaultProps = {
    gap: 0,
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import CANNON from "cannon";
import {
    Vector3,
    Vector2,
    Texture,
    MeshPhongMaterial,
    MeshPhongMaterialParameters,
    Mesh,
    Scene,
    WebGLRenderer,
    PCFShadowMap,
    AmbientLight,
    PerspectiveCamera,
    SpotLight,
    PlaneGeometry,
    Quaternion,
    ShadowMaterial,
    Material,
    BufferGeometry,
    Float32BufferAttribute,
} from "three";
import {
    DiceType,
    DiceTerm,
    DiceRollLogEntry,
    DiceBag,
    getToken,
    diceBagToExpression,
    shouldShowNotification,
    visitDiceBagTerms,
} from "../store";
import { useCampaign, useNotifications, useSelection, useUser } from "./contexts";
import { Box, Text } from "./primitives";
import useMeasure from "react-use-measure";
import { Point } from "../position";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import { defaultAnimate, defaultExit, defaultInitial, MotionBox, MotionCard, MotionMessage } from "./motion";
import { getPlayerColorPalette } from "../design/utils";
import { DiceRollLogResult } from "./DiceRollResult";
import { Message } from "./Message";
import { useKeyboardShortcut, useLocalSetting } from "./utils";
import { diceColors, diceColorSetting } from "../common";
import { ExtractProps } from "./common";
import tinycolor from "tinycolor2";
import { Button } from "./Button";

// const normalTexture1 = new TextureLoader().load('/NormalMap1.jpg');
// const normalTexture2 = new TextureLoader().load('/NormalMap2.png');

// If this is more than 0, for a more subtle exit, then the shadows stick around for long enough to look a bit odd.
// The shadows don't change with the opacity, so when the dice have a very low opacity it looks suboptimal.
// Arguably it still looks better than scaling to 0...
const MIN_SCALE = 0.0;
const MIN_SCALE_EXCLUDED = 0.5;

function easeInOutQuart(x: number): number {
    return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
}

function randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
}

function copy(obj: any): any {
    if (!obj) {
        return obj;
    }

    return copyTo(obj, new obj.constructor());
}

function copyTo(obj: any, res: any) {
    if (obj == null || typeof obj !== "object") {
        return obj;
    }

    if (obj instanceof Array) {
        for (let i = obj.length - 1; i >= 0; --i) {
            res[i] = copy(obj[i]);
        }
    } else {
        for (let i in obj) {
            if (obj.hasOwnProperty(i)) {
                res[i] = copy(obj[i]);
            }
        }
    }

    return res;
}

function createShape(vertices: CANNON.Vec3[], faces: number[][], radius: number) {
    const cv: CANNON.Vec3[] = new Array(vertices.length);
    const cf: number[][] = new Array(faces.length);
    for (let i = 0; i < vertices.length; ++i) {
        var v = vertices[i];
        cv[i] = new CANNON.Vec3(v.x * radius, v.y * radius, v.z * radius);
    }

    for (let i = 0; i < faces.length; ++i) {
        cf[i] = faces[i].slice(0, faces[i].length - 1);
    }

    return new CANNON.ConvexPolyhedron(cv, cf as any);
}

function computeFaceNormal(a: Vector3, b: Vector3, c: Vector3) {
    var normal = new Vector3().crossVectors(new Vector3().subVectors(b, a), new Vector3().subVectors(c, a)).normalize();
    return normal;
}

function makeGeom(vertices: Vector3[], faces: number[][], radius: number, tab: number, af: number): DiceGeometry {
    var geom = new BufferGeometry() as DiceGeometry;
    const points: Vector3[] = [];
    const uvs: number[] = [];
    const normals: Vector3[] = [];
    const vertexes = vertices.map(o => o.multiplyScalar(radius));
    for (let i = 0; i < faces.length; i++) {
        var ii = faces[i],
            fl = ii.length - 1;
        var aa = (Math.PI * 2) / fl;

        for (let j = 0; j < fl - 2; j++) {
            geom.addGroup(points.length, 3, ii[fl] + 1);
            const a = vertexes[ii[0]];
            const b = vertexes[ii[j + 1]];
            const c = vertexes[ii[j + 2]];
            points.push(a, b, c);
            normals.push(computeFaceNormal(a, b, c));
            uvs.push(
                (Math.cos(af) + 1 + tab) / 2 / (1 + tab),
                (Math.sin(af) + 1 + tab) / 2 / (1 + tab),
                (Math.cos(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab),
                (Math.sin(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab),
                (Math.cos(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab),
                (Math.sin(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab)
            );
        }
    }

    geom.normals = normals;
    geom.setFromPoints(points);
    geom.setAttribute("uv", new Float32BufferAttribute(uvs, 2));
    geom.computeVertexNormals();

    return geom;
}

function chamferGeom(vectors: Vector3[], faces: number[][], chamfer: number) {
    const chamfer_vectors: Vector3[] = [];
    const chamfer_faces: number[][] = [];
    const corner_faces: number[][] = new Array(vectors.length);

    for (let i = 0; i < vectors.length; ++i) {
        corner_faces[i] = [];
    }

    for (let i = 0; i < faces.length; ++i) {
        const ii = faces[i];
        const fl = ii.length - 1;
        const center_point = new Vector3();
        const face: number[] = new Array(fl);
        for (let j = 0; j < fl; ++j) {
            let vv = vectors[ii[j]].clone();
            center_point.add(vv);
            corner_faces[ii[j]].push((face[j] = chamfer_vectors.push(vv) - 1));
        }

        center_point.divideScalar(fl);
        for (let j = 0; j < fl; ++j) {
            let vv = chamfer_vectors[face[j]];
            vv.subVectors(vv, center_point).multiplyScalar(chamfer).addVectors(vv, center_point);
        }

        face.push(ii[fl]);
        chamfer_faces.push(face);
    }

    for (let i = 0; i < faces.length - 1; ++i) {
        for (let j = i + 1; j < faces.length; ++j) {
            const pairs: number[][] = [];
            let lastm = -1;
            for (let m = 0; m < faces[i].length - 1; ++m) {
                let n = faces[j].indexOf(faces[i][m]);
                if (n >= 0 && n < faces[j].length - 1) {
                    if (lastm >= 0 && m !== lastm + 1) {
                        pairs.unshift([i, m], [j, n]);
                    } else {
                        pairs.push([i, m], [j, n]);
                    }

                    lastm = m;
                }
            }

            if (pairs.length !== 4) {
                continue;
            }

            chamfer_faces.push([
                chamfer_faces[pairs[0][0]][pairs[0][1]],
                chamfer_faces[pairs[1][0]][pairs[1][1]],
                chamfer_faces[pairs[3][0]][pairs[3][1]],
                chamfer_faces[pairs[2][0]][pairs[2][1]],
                -1,
            ]);
        }
    }

    for (let i = 0; i < corner_faces.length; ++i) {
        const cf = corner_faces[i];
        const face = [cf[0]];
        let count = cf.length - 1;
        while (count) {
            for (let m = faces.length; m < chamfer_faces.length; ++m) {
                var index = chamfer_faces[m].indexOf(face[face.length - 1]);
                if (index >= 0 && index < 4) {
                    if (--index === -1) index = 3;
                    var next_vertex = chamfer_faces[m][index];
                    if (cf.indexOf(next_vertex) >= 0) {
                        face.push(next_vertex);
                        break;
                    }
                }
            }

            --count;
        }

        face.push(-1);
        chamfer_faces.push(face);
    }

    return { vectors: chamfer_vectors, faces: chamfer_faces };
}

function createGeom(vertices: number[][], faces: number[][], radius: number, tab: number, af: number, chamfer: number) {
    var vectors = new Array(vertices.length);
    for (var i = 0; i < vertices.length; ++i) {
        vectors[i] = new Vector3().fromArray(vertices[i]).normalize();
    }
    var cg = chamferGeom(vectors, faces, chamfer);
    var geom = makeGeom(cg.vectors, cg.faces, radius, tab, af);
    //var geom = makeGeom(vectors, faces, radius, tab, af); // Without chamfer
    geom.shape = createShape(vectors, faces, radius);
    return geom;
}

const standart_d20_dice_face_labels = [
    " ",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
];
const standart_d100_dice_face_labels = [" ", "00", "10", "20", "30", "40", "50", "60", "70", "80", "90"];

function calcTextureSize(approx) {
    return Math.pow(2, Math.floor(Math.log(approx) / Math.log(2)));
}

function createTextTexture(
    text: string,
    size: number,
    margin: number,
    color: string | CanvasGradient | CanvasPattern,
    back_color: string | CanvasGradient | CanvasPattern
) {
    if (text == null) {
        return null;
    }

    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    if (context == null) {
        return null;
    }

    var ts = calcTextureSize(size + size * 2 * margin) * 2;
    canvas.width = canvas.height = ts;
    context.font = ts / (1 + 2 * margin) + "pt Arial";
    context.fillStyle = back_color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillStyle = color;
    context.fillText(text, canvas.width / 2, canvas.height / 2);
    if (text === "6" || text === "9") {
        context.fillText("  .", canvas.width / 2, canvas.height / 2);
    }

    var texture = new Texture(canvas);
    texture.needsUpdate = true;
    return texture;
}

function makeRandomVector(vector: Vector2) {
    var random_angle = (Math.random() * Math.PI) / 5 - Math.PI / 5 / 2;
    var vec = {
        x: vector.x * Math.cos(random_angle) - vector.y * Math.sin(random_angle),
        y: vector.x * Math.sin(random_angle) + vector.y * Math.cos(random_angle),
    };

    if (vec.x === 0) {
        vec.x = 0.01;
    }

    if (vec.y === 0) {
        vec.y = 0.01;
    }

    return vec;
}

const d4_labels = [
    [[], [0, 0, 0], [2, 4, 3], [1, 3, 4], [2, 1, 4], [1, 2, 3]],
    [[], [0, 0, 0], [2, 3, 4], [3, 1, 4], [2, 4, 1], [3, 2, 1]],
    [[], [0, 0, 0], [4, 3, 2], [3, 4, 1], [4, 2, 1], [3, 1, 2]],
    [[], [0, 0, 0], [4, 2, 3], [1, 4, 3], [4, 1, 2], [1, 3, 2]],
];

interface Axis {
    x: number;
    y: number;
    z: number;
    a: number;
}

interface DieVector {
    set: DiceType;
    pos: Vector3;
    velocity: Vector3;
    angle: Vector3;
    axis: Axis;
}

const frameRate = 1 / 60;
const excludedOpacity = 0.3;

type DiceGeometry = BufferGeometry & {
    shape?: CANNON.Shape;
    normals: Vector3[];
};

type DiceMesh = Mesh & {
    diceType?: DiceType;
    body?: CANNON.Body;
    isStopped?: boolean | number;
    geometry: DiceGeometry;
    isExcluded?: boolean;
    diceColor: string;
};

export interface DiceThrow {
    dice: DiceTerm[];
    isFinished: boolean;
    isActive: boolean;
    timeout?: number;
}

interface DiceThrowInternal extends DiceThrow {
    iteration: number;
    meshes: DiceMesh[];
    hideAfter?: number;
    finishTime?: number;
    onResult?: () => void;
    onFinished?: () => void;
    onUnfinished?: () => void;
}

interface DiceThrowOptions {
    vector?: Vector2;
    dist?: number;
    boost?: number;
    timeout?: number;
    onResult?: () => void;
    onFinished?: () => void;
    onUnfinished?: () => void;
}

interface DiceAnimatorOptions {
    size: { width: number; height: number };
    colors: { dice: string; label: string }[];
}

interface DiceMaterialByColor {
    [diceColor: string]: Material[];
}

export class DiceAnimator {
    private _colors: { dice: string; label: string }[];
    private _ambientLightColor = 0xf0f5fb;
    private _spotLightColor = 0xefdfd5;
    private _diceFaceRange = {
        d4: [1, 4],
        d6: [1, 6],
        d8: [1, 8],
        d10: [0, 9],
        d12: [1, 12],
        d20: [1, 20],
        d100: [0, 9],
    };
    private _diceMass = {
        d4: 300,
        d6: 300,
        d8: 340,
        d10: 350,
        d12: 350,
        d20: 400,
        d100: 350,
    };
    private _diceInertia = {
        d4: 5,
        d6: 13,
        d8: 10,
        d10: 9,
        d12: 8,
        d20: 6,
        d100: 9,
    };

    private _d4Geometry: DiceGeometry | undefined;
    private _d6Geometry: DiceGeometry | undefined;
    private _d8Geometry: DiceGeometry | undefined;
    private _d10Geometry: DiceGeometry | undefined;
    private _d12Geometry: DiceGeometry | undefined;
    private _d20Geometry: DiceGeometry | undefined;
    private _d4Material: DiceMaterialByColor | undefined;
    private _d100Material: DiceMaterialByColor | undefined;
    private _diceMaterial: DiceMaterialByColor | undefined;

    private _scene: Scene;
    private _renderer: WebGLRenderer;
    private _camera!: PerspectiveCamera;
    private _light!: SpotLight;
    private _desk!: Mesh;

    private _world: CANNON.World;
    private _diceBodyMaterial: CANNON.Material;

    private _cw = 0;
    private _ch = 0;
    private _w = 0;
    private _h = 0;
    private _aspect = 0;
    private _wh = 0;
    private _scale = 50;
    private _lastTime = 0;
    private _requestFrameHandle: number | undefined;

    private _throws: DiceThrowInternal[] = [];

    private _use_adapvite_timestep = true;

    private _container: HTMLElement;

    private _barrier1: CANNON.Body;
    private _barrier2: CANNON.Body;
    private _barrier3: CANNON.Body;
    private _barrier4: CANNON.Body;

    private _materialOptions: MeshPhongMaterialParameters = {
        specular: 0x172022,
        color: 0xf0f0f0,
        shininess: 40,
        flatShading: true,
        transparent: true,
    };

    constructor(container: HTMLElement, options: DiceAnimatorOptions) {
        this._container = container;
        this._scene = new Scene();
        this._world = new CANNON.World();

        this._colors = options.colors ?? [{ dice: "#202020", label: "#aaaaaa" }];

        this._renderer = new WebGLRenderer({ antialias: true, alpha: true });
        container.appendChild(this._renderer.domElement);
        this._renderer.shadowMap.enabled = true;
        this._renderer.shadowMap.type = PCFShadowMap;

        this._world.gravity.set(0, 0, -9.8 * 800);
        this._world.broadphase = new CANNON.NaiveBroadphase();
        this._world.solver.iterations = 16;

        const ambientLight = new AmbientLight(this._ambientLightColor);
        this._scene.add(ambientLight);

        this._diceBodyMaterial = new CANNON.Material("");
        const deskBodyMaterial = new CANNON.Material("");
        const barrierBodyMaterial = new CANNON.Material("");
        this._world.addContactMaterial(
            new CANNON.ContactMaterial(deskBodyMaterial, this._diceBodyMaterial, {
                friction: 0.01,
                restitution: 0.5,
            })
        );
        this._world.addContactMaterial(
            new CANNON.ContactMaterial(barrierBodyMaterial, this._diceBodyMaterial, {
                friction: 0,
                restitution: 1.0,
            })
        );
        this._world.addContactMaterial(
            new CANNON.ContactMaterial(this._diceBodyMaterial, this._diceBodyMaterial, {
                friction: 0,
                restitution: 0.5,
            })
        );

        this._world.addBody(
            new CANNON.Body({
                mass: 0,
                shape: new CANNON.Plane(),
                material: deskBodyMaterial,
            })
        );

        this._barrier1 = new CANNON.Body({
            mass: 0,
            shape: new CANNON.Plane(),
            material: barrierBodyMaterial,
        });
        this._barrier1.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), Math.PI / 2);
        this._world.addBody(this._barrier1);

        this._barrier2 = new CANNON.Body({
            mass: 0,
            shape: new CANNON.Plane(),
            material: barrierBodyMaterial,
        });
        this._barrier2.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
        this._world.addBody(this._barrier2);

        this._barrier3 = new CANNON.Body({
            mass: 0,
            shape: new CANNON.Plane(),
            material: barrierBodyMaterial,
        });
        this._barrier3.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), -Math.PI / 2);
        this._world.addBody(this._barrier3);

        this._barrier4 = new CANNON.Body({
            mass: 0,
            shape: new CANNON.Plane(),
            material: barrierBodyMaterial,
        });
        this._barrier4.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), Math.PI / 2);
        this._world.addBody(this._barrier4);

        this.reinit(container, options.size);
    }

    remove() {
        this._renderer.domElement.remove();
    }

    resize(dimensions?: { width: number; height: number }) {
        const cw = this._container.clientWidth / 2;
        const ch = this._container.clientHeight / 2;
        const w = dimensions?.width ?? cw;
        const h = dimensions?.height ?? ch;
        if (cw !== this._cw || ch !== this._ch || this._w !== w || this._h !== h) {
            this.reinit(this._container, dimensions);
        }
    }

    throw(terms: DiceTerm[], options?: DiceThrowOptions): Readonly<DiceThrow> {
        options = options ? options : {};

        if (!options.vector) {
            options.vector = new Vector2((Math.random() * 2 - 1) * this._w, -(Math.random() * 2 - 1) * this._h);
        }

        if (!options.dist) {
            options.dist = Math.sqrt(options.vector.x * options.vector.x + options.vector.y * options.vector.y);
        }

        if (!options.boost) {
            options.boost = (Math.random() + 3) * options.dist;
        }

        options.vector.x /= options.dist;
        options.vector.y /= options.dist;

        if (terms.length === 0) {
            return {
                dice: terms,
                isFinished: true,
                isActive: false,
            };
        }

        const addTerm = (term: DiceTerm, set: DiceType[], excluded: boolean[], results: number[]) => {
            set.push(term.type);
            if (term.type === "d100") {
                const d10result = term.result % 10;
                const percentileResult = term.result === 100 ? 0 : (term.result - d10result) / 10;
                results.push(percentileResult);
                set.push("d10");
                results.push(d10result);

                excluded.push(!!term.isExcluded, !!term.isExcluded);
            } else {
                results.push(term.result);
                excluded.push(!!term.isExcluded);
            }
        };

        const set: DiceType[] = [];
        const excluded: boolean[] = [];
        const results: number[] = [];
        for (let i = 0; i < terms.length; i++) {
            if (terms[i].scalar == null) {
                addTerm(terms[i], set, excluded, results);
            } else {
                for (let j = 0; j < terms[i].scalar!; j++) {
                    addTerm(terms[i], set, excluded, results);
                }
            }
        }

        var vectors = this.generateVectors(set, options.vector, options.boost);

        const diceThrow: DiceThrowInternal = {
            iteration: 0,
            dice: terms,
            timeout: options.timeout,
            meshes: [],
            isFinished: false,
            isActive: true,
            onResult: options.onResult,
            onFinished: options.onFinished,
            onUnfinished: options.onUnfinished,
        };

        diceThrow.meshes.splice(0, diceThrow.dice.length, ...this.prepareDiceForRoll(vectors));

        if (results !== undefined && results.length) {
            this._use_adapvite_timestep = false;

            // Emulate the throw, then use the results to adjust the faces on the dice so that
            // we get the desired results.
            while (!this.checkIfThrowFinished(diceThrow)) {
                ++diceThrow.iteration;
                this._world.step(frameRate);
            }

            const res = this.getDiceValues(diceThrow.meshes);

            this.clearDice(diceThrow);
            diceThrow.iteration = 0;
            diceThrow.meshes.splice(0, diceThrow.meshes.length, ...this.prepareDiceForRoll(vectors));
            for (var i in res) {
                this.shiftDiceFaces(diceThrow.meshes[i], results[i], res[i]);
            }
        }

        diceThrow.meshes.forEach((o, i) => {
            o.isExcluded = excluded[i];
        });

        const time = new Date().getTime();
        this._throws.forEach(o => {
            if (o.hideAfter == null || o.hideAfter > time) {
                o.hideAfter = time;
            }

            if (o.isActive) {
                o.isActive = false;

                if (!o.isFinished && o.onResult) {
                    o.onResult();
                }

                if (o.onUnfinished) {
                    o.onUnfinished();
                }
            }
        });
        this._throws.push(diceThrow);

        this._lastTime = 0;

        if (this._requestFrameHandle == null) {
            this.animate();
        }

        return diceThrow;
    }

    private generateVectors(set: DiceType[], vector: Vector2, boost: number) {
        var vectors: DieVector[] = [];
        for (var i in set) {
            var vec = makeRandomVector(vector);
            var pos = new Vector3(
                this._w * (vec.x > 0 ? -1 : 1) * 0.9,
                this._h * (vec.y > 0 ? -1 : 1) * 0.9,
                Math.random() * 200 + 200
            );

            var projector = Math.abs(vec.x / vec.y);
            if (projector > 1.0) {
                pos.y /= projector;
            } else {
                pos.x *= projector;
            }

            var velvec = makeRandomVector(vector);
            var velocity = new Vector3(velvec.x * boost, velvec.y * boost, -10);
            var inertia = this._diceInertia[set[i]];
            var angle = new Vector3(
                -(Math.random() * vec.y * 5 + inertia * vec.y),
                Math.random() * vec.x * 5 + inertia * vec.x,
                0
            );
            var axis = {
                x: Math.random(),
                y: Math.random(),
                z: Math.random(),
                a: Math.random(),
            };
            vectors.push({
                set: set[i],
                pos: pos,
                velocity: velocity,
                angle: angle,
                axis: axis,
            });
        }

        return vectors;
    }

    private reinit(container: HTMLElement, dimensions?: { width: number; height: number }) {
        this._cw = container.clientWidth / 2;
        this._ch = container.clientHeight / 2;
        if (dimensions) {
            this._w = dimensions.width;
            this._h = dimensions.height;
        } else {
            this._w = this._cw;
            this._h = this._ch;
        }

        if (this._w === 0 || this._h === 0) {
            return;
        }

        this._aspect = Math.min(this._cw / this._w, this._ch / this._h);
        this._scale = Math.sqrt(this._w * this._w + this._h * this._h) / 13;

        this._renderer.setSize(this._cw * 2, this._ch * 2);

        this._wh = this._ch / this._aspect / Math.tan((10 * Math.PI) / 180);
        if (this._camera) {
            this._scene.remove(this._camera);
        }

        this._camera = new PerspectiveCamera(20, this._cw / this._ch, 1, this._wh * 1.3);
        this._camera.position.z = this._wh;

        var mw = Math.max(this._w, this._h);
        if (this._light) {
            this._scene.remove(this._light);
        }

        this._light = new SpotLight(this._spotLightColor, 2.0);
        // this._light.position.set(-mw / 2, mw / 2, mw * 2);
        this._light.position.set(-mw / 1.5, mw / 1.5, mw * 1.5);
        this._light.target.position.set(0, 0, 0);
        this._light.distance = mw * 5;
        this._light.castShadow = true;
        this._light.shadow.camera.near = mw / 10;
        this._light.shadow.camera.far = mw * 5;
        this._light.shadow.camera.fov = 50;
        this._light.shadow.bias = 0.001;
        this._light.shadow.mapSize.width = 1024;
        this._light.shadow.mapSize.height = 1024;
        this._scene.add(this._light);

        if (this._desk) {
            this._scene.remove(this._desk);
        }

        this._desk = new Mesh(new PlaneGeometry(this._w * 2, this._h * 2, 1, 1), new ShadowMaterial({ opacity: 0.3 }));
        this._desk.receiveShadow = true;
        this._scene.add(this._desk);

        this._barrier1.position.set(0, this._h, 0);
        this._barrier2.position.set(0, -this._h, 0);
        this._barrier3.position.set(this._w, 0, 0);
        this._barrier4.position.set(-this._w, 0, 0);

        this._renderer.render(this._scene, this._camera);
    }

    private animate() {
        var time = new Date().getTime();
        var time_diff = (time - this._lastTime) / 1000;
        if (time_diff > 3) {
            time_diff = frameRate;
        }

        // Only one throw can be active at a time.
        const activeThrow = this._throws.find(o => o.isActive);
        if (activeThrow) {
            activeThrow.iteration++;
            if (this._use_adapvite_timestep) {
                while (time_diff > frameRate * 1.1) {
                    this._world.step(frameRate);
                    time_diff -= frameRate;
                }

                this._world.step(time_diff);
            } else {
                this._world.step(frameRate);
            }
        }

        for (var i in this._scene.children) {
            var interact = this._scene.children[i] as DiceMesh;
            if (interact.body !== undefined) {
                interact.position.copy(interact.body.position as unknown as Vector3);
                interact.quaternion.copy(interact.body.quaternion as unknown as Quaternion);
            }
        }

        for (let i = 0; i < this._throws.length; i++) {
            // If it's after the timeout period for the throw, we should be starting to hide the dice.
            const diceThrow = this._throws[i];
            if (diceThrow.hideAfter != null && diceThrow.hideAfter < time) {
                let animTime = (time - diceThrow.hideAfter) / 600;
                if (animTime >= 1) {
                    // This dice throw is completely done for, shouldn't be visible any more.
                    diceThrow.meshes.forEach(o => {
                        this._scene.remove(o);
                        if (o.body) {
                            this._world.remove(o.body);
                        }
                    });
                    this._throws.splice(i, 1);
                    i--;
                } else {
                    animTime = easeInOutQuart(animTime);
                    let opacity = 1 - animTime;
                    diceThrow.meshes.forEach(o => {
                        (o.material as Material[]).forEach(m => {
                            m.opacity = o.isExcluded ? excludedOpacity * opacity : opacity;
                        });

                        const meshScale = o.isExcluded
                            ? MIN_SCALE +
                              (1 - MIN_SCALE) *
                                  opacity *
                                  (MIN_SCALE_EXCLUDED + (1 - MIN_SCALE_EXCLUDED) * excludedOpacity)
                            : MIN_SCALE + (1 - MIN_SCALE) * opacity;
                        o.scale.set(meshScale, meshScale, meshScale);
                    });
                }
            } else if (diceThrow.finishTime != null && diceThrow.finishTime < time) {
                let animTime = easeInOutQuart(Math.min((time - diceThrow.finishTime) / 600, 1));
                let opacity = 1 - animTime * (1 - excludedOpacity);
                const scale = MIN_SCALE_EXCLUDED + (1 - MIN_SCALE_EXCLUDED) * opacity;
                diceThrow.meshes
                    .filter(o => o.isExcluded)
                    .forEach(o => {
                        (o.material as Material[]).forEach(m => (m.opacity = opacity));
                        o.scale.set(scale, scale, scale);
                    });
            }
        }

        this._renderer.render(this._scene, this._camera);

        this._lastTime = this._lastTime ? time : new Date().getTime();
        if (activeThrow && this.checkIfThrowFinished(activeThrow)) {
            activeThrow.isFinished = true;

            if (activeThrow.onResult) {
                activeThrow.onResult();
            }

            // The throw is finished, so we can remove it from the world (so it won't affect any future throws).
            // We leave the mesh for now so that we can animate it away etc.
            activeThrow.meshes.forEach(dice => {
                if (dice.body) {
                    this._world.remove(dice.body);
                }

                // If the dice is excluded, change its opacity to make that clear.
                // TODO: Animate this as well.
                if (dice.isExcluded) {
                    (dice.material as Material[]).forEach(m => (m.opacity = excludedOpacity));
                }
            });

            activeThrow.isActive = false;
            activeThrow.finishTime = time;
            if (activeThrow.timeout != null) {
                activeThrow.hideAfter = time + activeThrow.timeout;
            }

            if (activeThrow.onFinished) {
                activeThrow.onFinished();
            }
        }

        if (this._throws.length) {
            (function (t: DiceAnimator, uat: boolean) {
                if (!uat && time_diff < frameRate) {
                    setTimeout(function () {
                        t._requestFrameHandle = requestAnimationFrame(function () {
                            t.animate();
                        });
                    }, (frameRate - time_diff) * 1000);
                } else {
                    t._requestFrameHandle = requestAnimationFrame(function () {
                        t.animate();
                    });
                }
            })(this, this._use_adapvite_timestep);
        } else {
            this._requestFrameHandle = undefined;
        }
    }

    private shiftDiceFaces(dice: DiceMesh, value: number, res: number) {
        let r = this._diceFaceRange[dice.diceType!];
        if (dice.diceType! === "d10" || dice.diceType! === "d100") {
            if (value === 10) {
                value = 0;
            }

            if (res === 10) {
                res = 0;
            }
        }

        if (!(value >= r[0] && value <= r[1])) {
            return;
        }

        let num = value - res;
        let geom = dice.geometry.clone() as DiceGeometry;
        for (var i = 0, l = geom.groups.length; i < l; ++i) {
            var matindex = geom.groups[i].materialIndex!;
            if (matindex === 0) {
                continue;
            }

            matindex += num - 1;
            while (matindex > r[1]) {
                matindex -= r[1];
            }

            while (matindex < r[0]) {
                matindex += r[1];
            }

            geom.groups[i].materialIndex = matindex + 1;
        }

        if (dice.diceType === "d4" && num !== 0) {
            if (num < 0) {
                num += 4;
            }

            var materialsByColor = this.createD4Materials(this._scale / 2, this._scale * 2, d4_labels[num]);
            dice.material = materialsByColor[dice.diceColor];
        }

        dice.geometry = geom;
    }

    private getDiceValue(dice: DiceMesh) {
        var vector = new Vector3(0, 0, dice.diceType === "d4" ? -1 : 1);
        var closest_face,
            closest_angle = Math.PI * 2;
        for (var i = 0, l = dice.geometry.groups.length; i < l; ++i) {
            const face = dice.geometry.groups[i];
            if (face.materialIndex === 0) {
                continue;
            }

            const normal = dice.geometry.normals[i];

            const q = dice.body!.quaternion;
            var angle = normal.clone().applyQuaternion(new Quaternion(q.x, q.y, q.z, q.w)).angleTo(vector);
            if (angle < closest_angle) {
                closest_angle = angle;
                closest_face = face;
            }
        }

        var matindex = closest_face.materialIndex - 1;
        if (dice.diceType === "d10" && matindex === 0) {
            matindex = 10;
        }

        return matindex;
    }

    private getDiceValues(dices: DiceMesh[]) {
        var values: number[] = [];
        for (var i = 0, l = dices.length; i < l; ++i) {
            values.push(this.getDiceValue(dices[i]));
        }

        return values;
    }

    private checkIfThrowFinished(diceThrow: DiceThrowInternal) {
        var res = true;
        var e = 6;
        if (diceThrow.iteration < 10 / frameRate) {
            for (var i = 0; i < diceThrow.dice.length; ++i) {
                var dice = diceThrow.meshes[i];

                if (dice.isStopped === true) {
                    continue;
                }

                var a = dice.body!.angularVelocity,
                    v = dice.body!.velocity;
                if (
                    Math.abs(a.x) < e &&
                    Math.abs(a.y) < e &&
                    Math.abs(a.z) < e &&
                    Math.abs(v.x) < e &&
                    Math.abs(v.y) < e &&
                    Math.abs(v.z) < e
                ) {
                    if (dice.isStopped) {
                        if (diceThrow.iteration - dice.isStopped > 3) {
                            dice.isStopped = true;
                            continue;
                        }
                    } else {
                        dice.isStopped = diceThrow.iteration;
                    }

                    res = false;
                } else {
                    dice.isStopped = undefined;
                    res = false;
                }
            }
        }

        return res;
    }

    private prepareDiceForRoll(vectors: DieVector[]) {
        return vectors.map(o => this.createDice(o.set, o.pos, o.velocity, o.angle, o.axis));
    }

    clear() {
        this._throws.forEach(o => this.clearDice(o));
        this._renderer.render(this._scene, this._camera);
        this._throws = [];
        return true;
    }

    private clearDice(diceThrow: DiceThrowInternal) {
        var dice: DiceMesh | undefined;
        // https://github.com/airbnb/javascript/issues/1439
        // eslint-disable-next-line no-cond-assign
        while ((dice = diceThrow.meshes.pop())) {
            this._scene.remove(dice);
            if (dice.body) {
                this._world.remove(dice.body);
            }
        }
    }

    private createDice(type: DiceType, pos: Vector3, velocity: Vector3, angle: Vector3, axis: Axis): DiceMesh {
        let dice: DiceMesh;
        switch (type) {
            case "d4":
                dice = this.createD4();
                break;
            case "d6":
                dice = this.createD6();
                break;
            case "d8":
                dice = this.createD8();
                break;
            case "d10":
                dice = this.createD10();
                break;
            case "d12":
                dice = this.createD12();
                break;
            case "d20":
                dice = this.createD20();
                break;
            case "d100":
                dice = this.createD100();
                break;
            default:
                throw new Error("Unknown dice type: " + type);
        }

        dice.castShadow = true;
        dice.diceType = type;

        const body = new CANNON.Body({
            mass: this._diceMass[type],
            shape: dice.geometry.shape,
            material: this._diceBodyMaterial,
        });
        dice.body = body;
        body.position.set(pos.x, pos.y, pos.z);
        body.quaternion.setFromAxisAngle(new CANNON.Vec3(axis.x, axis.y, axis.z), axis.a * Math.PI * 2);
        body.angularVelocity.set(angle.x, angle.y, angle.z);
        body.velocity.set(velocity.x, velocity.y, velocity.z);
        body.linearDamping = 0.1;
        body.angularDamping = 0.1;
        this._scene.add(dice);
        this._world.addBody(body);
        return dice;
    }

    private createDiceMaterials(faceLabels: string[], size: number, margin: number): DiceMaterialByColor {
        var materialsByColor: DiceMaterialByColor = {};
        for (var c of this._colors) {
            var materials: MeshPhongMaterial[] = [];
            for (var i = 0; i < faceLabels.length; ++i) {
                // , normalMap: normalTexture2
                materials.push(
                    new MeshPhongMaterial(
                        copyTo(this._materialOptions, {
                            map: createTextTexture(faceLabels[i], size, margin, c.label, c.dice),
                        })
                    )
                );
            }

            materialsByColor[c.dice] = materials;
        }

        return materialsByColor;
    }

    private createD4Materials(size: number, margin: number, labels: number[][]): DiceMaterialByColor {
        var materialsByColor: DiceMaterialByColor = {};
        for (var c of this._colors) {
            const createD4Text = (text: number[], color, back_color) => {
                var canvas = document.createElement("canvas");
                var context = canvas.getContext("2d");
                if (context == null) {
                    return null;
                }

                var ts = calcTextureSize(size + margin) * 2;
                canvas.width = canvas.height = ts;
                context.font = (ts - margin) / 1.5 + "pt Arial";
                context.fillStyle = back_color;
                context.fillRect(0, 0, canvas.width, canvas.height);
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillStyle = color;
                for (var i in text) {
                    context.fillText(text[i].toString(), canvas.width / 2, canvas.height / 2 - ts * 0.3);
                    context.translate(canvas.width / 2, canvas.height / 2);
                    context.rotate((Math.PI * 2) / 3);
                    context.translate(-canvas.width / 2, -canvas.height / 2);
                }

                var texture = new Texture(canvas);
                texture.needsUpdate = true;
                return texture;
            };

            var materials: MeshPhongMaterial[] = [];
            for (var i = 0; i < labels.length; ++i) {
                materials.push(
                    new MeshPhongMaterial(
                        copyTo(this._materialOptions, {
                            map: createD4Text(labels[i], c.label, c.dice),
                        })
                    )
                );
            }

            materialsByColor[c.dice] = materials;
        }

        return materialsByColor;
    }

    private createD4Geometry(radius: number) {
        var vertices = [
            [1, 1, 1],
            [-1, -1, 1],
            [-1, 1, -1],
            [1, -1, -1],
        ];
        var faces = [
            [1, 0, 2, 1],
            [0, 1, 3, 2],
            [0, 3, 2, 3],
            [1, 2, 3, 4],
        ];
        return createGeom(vertices, faces, radius, -0.1, (Math.PI * 7) / 6, 0.96);
    }

    private createD6Geometry(radius: number) {
        var vertices = [
            [-1, -1, -1],
            [1, -1, -1],
            [1, 1, -1],
            [-1, 1, -1],
            [-1, -1, 1],
            [1, -1, 1],
            [1, 1, 1],
            [-1, 1, 1],
        ];
        var faces = [
            [0, 3, 2, 1, 1],
            [1, 2, 6, 5, 2],
            [0, 1, 5, 4, 3],
            [3, 7, 6, 2, 4],
            [0, 4, 7, 3, 5],
            [4, 5, 6, 7, 6],
        ];
        return createGeom(vertices, faces, radius, 0.1, Math.PI / 4, 0.96);
    }

    private createD8Geometry(radius: number) {
        var vertices = [
            [1, 0, 0],
            [-1, 0, 0],
            [0, 1, 0],
            [0, -1, 0],
            [0, 0, 1],
            [0, 0, -1],
        ];
        var faces = [
            [0, 2, 4, 1],
            [0, 4, 3, 2],
            [0, 3, 5, 3],
            [0, 5, 2, 4],
            [1, 3, 4, 5],
            [1, 4, 2, 6],
            [1, 2, 5, 7],
            [1, 5, 3, 8],
        ];
        return createGeom(vertices, faces, radius, 0, -Math.PI / 4 / 2, 0.965);
    }

    private createD10Geometry(radius: number) {
        const a = (Math.PI * 2) / 10;
        // const k = Math.cos(a);
        const h = 0.105;
        const v = -1;
        const vertices: number[][] = [];

        for (var i = 0, b = 0; i < 10; ++i, b += a) {
            vertices.push([Math.cos(b), Math.sin(b), h * (i % 2 ? 1 : -1)]);
        }

        vertices.push([0, 0, -1]);
        vertices.push([0, 0, 1]);
        const faces = [
            [5, 7, 11, 0],
            [4, 2, 10, 1],
            [1, 3, 11, 2],
            [0, 8, 10, 3],
            [7, 9, 11, 4],
            [8, 6, 10, 5],
            [9, 1, 11, 6],
            [2, 0, 10, 7],
            [3, 5, 11, 8],
            [6, 4, 10, 9],
            [1, 0, 2, v],
            [1, 2, 3, v],
            [3, 2, 4, v],
            [3, 4, 5, v],
            [5, 4, 6, v],
            [5, 6, 7, v],
            [7, 6, 8, v],
            [7, 8, 9, v],
            [9, 8, 0, v],
            [9, 0, 1, v],
        ];
        return createGeom(vertices, faces, radius, 0, (Math.PI * 6) / 5, 0.945);
    }

    private createD12Geometry(radius: number) {
        var p = (1 + Math.sqrt(5)) / 2,
            q = 1 / p;
        var vertices = [
            [0, q, p],
            [0, q, -p],
            [0, -q, p],
            [0, -q, -p],
            [p, 0, q],
            [p, 0, -q],
            [-p, 0, q],
            [-p, 0, -q],
            [q, p, 0],
            [q, -p, 0],
            [-q, p, 0],
            [-q, -p, 0],
            [1, 1, 1],
            [1, 1, -1],
            [1, -1, 1],
            [1, -1, -1],
            [-1, 1, 1],
            [-1, 1, -1],
            [-1, -1, 1],
            [-1, -1, -1],
        ];
        var faces = [
            [2, 14, 4, 12, 0, 1],
            [15, 9, 11, 19, 3, 2],
            [16, 10, 17, 7, 6, 3],
            [6, 7, 19, 11, 18, 4],
            [6, 18, 2, 0, 16, 5],
            [18, 11, 9, 14, 2, 6],
            [1, 17, 10, 8, 13, 7],
            [1, 13, 5, 15, 3, 8],
            [13, 8, 12, 4, 5, 9],
            [5, 4, 14, 9, 15, 10],
            [0, 12, 8, 10, 16, 11],
            [3, 19, 7, 17, 1, 12],
        ];
        return createGeom(vertices, faces, radius, 0.2, -Math.PI / 4 / 2, 0.968);
    }

    private createD20Geometry(radius: number) {
        var t = (1 + Math.sqrt(5)) / 2;
        var vertices = [
            [-1, t, 0],
            [1, t, 0],
            [-1, -t, 0],
            [1, -t, 0],
            [0, -1, t],
            [0, 1, t],
            [0, -1, -t],
            [0, 1, -t],
            [t, 0, -1],
            [t, 0, 1],
            [-t, 0, -1],
            [-t, 0, 1],
        ];
        var faces = [
            [0, 11, 5, 1],
            [0, 5, 1, 2],
            [0, 1, 7, 3],
            [0, 7, 10, 4],
            [0, 10, 11, 5],
            [1, 5, 9, 6],
            [5, 11, 4, 7],
            [11, 10, 2, 8],
            [10, 7, 6, 9],
            [7, 1, 8, 10],
            [3, 9, 4, 11],
            [3, 4, 2, 12],
            [3, 2, 6, 13],
            [3, 6, 8, 14],
            [3, 8, 9, 15],
            [4, 9, 5, 16],
            [2, 4, 11, 17],
            [6, 2, 10, 18],
            [8, 6, 7, 19],
            [9, 8, 1, 20],
        ];
        return createGeom(vertices, faces, radius, -0.2, -Math.PI / 4 / 2, 0.955);
    }

    private createD4() {
        if (!this._d4Geometry) {
            this._d4Geometry = this.createD4Geometry(this._scale * 1.2);
        }

        if (!this._d4Material) {
            this._d4Material = this.createD4Materials(this._scale / 2, this._scale * 2, d4_labels[0]);
        }

        var color =
            this._colors.length === 1
                ? this._colors[0].dice
                : this._colors[randomNumber(0, this._colors.length - 1)].dice;
        var materials = this._d4Material[color]!;
        var mesh = new Mesh(
            this._d4Geometry,
            materials.map(o => o.clone())
        ) as DiceMesh;
        mesh.diceColor = color;
        return mesh;
    }

    private createDiceMaterial() {
        if (!this._diceMaterial) {
            this._diceMaterial = this.createDiceMaterials(standart_d20_dice_face_labels, this._scale / 2, 1.0);
        }
    }

    private createD6() {
        if (!this._d6Geometry) {
            this._d6Geometry = this.createD6Geometry(this._scale * 0.9);
        }

        this.createDiceMaterial();

        var color =
            this._colors.length === 1
                ? this._colors[0].dice
                : this._colors[randomNumber(0, this._colors.length - 1)].dice;
        var materials = this._diceMaterial![color]!;
        var mesh = new Mesh(
            this._d6Geometry,
            materials.map(o => o.clone())
        ) as DiceMesh;
        mesh.diceColor = color;
        return mesh;
    }

    private createD8() {
        if (!this._d8Geometry) {
            this._d8Geometry = this.createD8Geometry(this._scale);
        }

        this.createDiceMaterial();

        var color =
            this._colors.length === 1
                ? this._colors[0].dice
                : this._colors[randomNumber(0, this._colors.length - 1)].dice;
        var materials = this._diceMaterial![color]!;
        var mesh = new Mesh(
            this._d8Geometry,
            materials.map(o => o.clone())
        ) as DiceMesh;
        mesh.diceColor = color;
        return mesh;
    }

    private createD10() {
        if (!this._d10Geometry) {
            this._d10Geometry = this.createD10Geometry(this._scale * 0.9);
        }

        this.createDiceMaterial();

        var color =
            this._colors.length === 1
                ? this._colors[0].dice
                : this._colors[randomNumber(0, this._colors.length - 1)].dice;
        var materials = this._diceMaterial![color]!;
        var mesh = new Mesh(
            this._d10Geometry,
            materials.map(o => o.clone())
        ) as DiceMesh;
        mesh.diceColor = color;
        return mesh;
    }

    private createD12() {
        if (!this._d12Geometry) {
            this._d12Geometry = this.createD12Geometry(this._scale * 0.9);
        }

        this.createDiceMaterial();

        var color =
            this._colors.length === 1
                ? this._colors[0].dice
                : this._colors[randomNumber(0, this._colors.length - 1)].dice;
        var materials = this._diceMaterial![color]!;
        var mesh = new Mesh(
            this._d12Geometry,
            materials.map(o => o.clone())
        ) as DiceMesh;
        mesh.diceColor = color;
        return mesh;
    }

    private createD20() {
        if (!this._d20Geometry) {
            this._d20Geometry = this.createD20Geometry(this._scale);
        }

        this.createDiceMaterial();

        var color =
            this._colors.length === 1
                ? this._colors[0].dice
                : this._colors[randomNumber(0, this._colors.length - 1)].dice;
        var materials = this._diceMaterial![color]!;
        var mesh = new Mesh(
            this._d20Geometry,
            materials.map(o => o.clone())
        ) as DiceMesh;
        mesh.diceColor = color;
        return mesh;
    }

    private createD100() {
        if (!this._d10Geometry) {
            this._d10Geometry = this.createD10Geometry(this._scale * 0.9);
        }

        if (!this._d100Material) {
            this._d100Material = this.createDiceMaterials(standart_d100_dice_face_labels, this._scale / 2, 1.5);
        }

        var color =
            this._colors.length === 1
                ? this._colors[0].dice
                : this._colors[randomNumber(0, this._colors.length - 1)].dice;
        var materials = this._d100Material[color]!;
        var mesh = new Mesh(
            this._d10Geometry,
            materials.map(o => o.clone())
        ) as DiceMesh;
        mesh.diceColor = color;
        return mesh;
    }
}

interface DiceBoxProps {
    diceToRoll?: DiceBag;
    setDiceToRoll: (diceToRoll?: DiceBag) => void;
    selectedItems?: string[];
    bridge: ({ children }: { children: React.ReactNode }) => JSX.Element;
}

function colorToDiceColors(color: string) {
    return {
        dice: tinycolor(color).darken(30).toHexString(),
        label: tinycolor(color).lighten(40).toHexString(),
    };
}

const randomColors = diceColors.map(o => colorToDiceColors(o));

function changeModifier(diceBag: DiceBag, amt: number) {
    return {
        ...diceBag,
        modifier: (diceBag.modifier ?? 0) + amt,
    };
}

export const DiceBox: FunctionComponent<DiceBoxProps & ExtractProps<typeof Box>> = React.memo(
    ({ diceToRoll, setDiceToRoll, zIndex, bridge, ...props }) => {
        const [ref, bounds] = useMeasure();

        const [pointerStart, setPointerStart] = useState<(Point & { time: number }) | undefined>();

        const hostRef = useRef<HTMLDivElement>(null);
        const [diceAnimator, setDiceAnimator] = useState<DiceAnimator>();

        const campaignData = useCampaign();
        const api = campaignData.api;
        const system = campaignData.system;
        const addNotification = useNotifications();

        const { primary, secondary } = useSelection();

        const user = useUser();
        var cp = getPlayerColorPalette(campaignData.campaign, user.id);

        var [settingColor] = useLocalSetting(diceColorSetting);

        var colors: { dice: string; label: string }[];
        var key: string;
        if (settingColor == null) {
            // No setting, use campaign color.
            colors = [{ dice: cp[0], label: cp[9] }];
            key = cp[0] + cp[9];
        } else if (settingColor === "random") {
            colors = randomColors;
            key = "random";
        } else {
            colors = [colorToDiceColors(settingColor)];
            key = colors[0].dice + colors[0].label;
        }

        if (diceAnimator) {
            diceAnimator.resize(bounds);
        }

        const diceToRollRef = useRef<DiceBag>();
        if (diceToRollRef.current !== diceToRoll) {
            diceToRollRef.current?.onCancelled?.();
            diceToRollRef.current = diceToRoll;
        }

        const Bridge = bridge;

        useEffect(() => {
            return () => {
                diceAnimator?.remove();
            };
        }, [diceAnimator]);
        useEffect(() => {
            if (hostRef.current) {
                setDiceAnimator(new DiceAnimator(hostRef.current, { size: bounds, colors: colors }));
            }

            // This is all handled by key, it takes into account all the colours.
            // Bounds can be ignored because it's handled every render.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [key]);
        useEffect(() => {
            const handler = (roll: {
                unconfirmed: DiceRollLogEntry & { state?: any };
                confirm: () => Promise<void>;
            }) => {
                let boost: number | undefined;
                let vector: Vector2 | undefined;
                const state = roll.unconfirmed.state;
                if (state) {
                    boost = state.boost;
                    vector = new Vector2(state.vector.x, state.vector.y);
                }

                diceAnimator?.throw(roll.unconfirmed.terms, {
                    timeout: 5000,
                    onResult: () => {
                        if (
                            shouldShowNotification("toast", user.id, roll.unconfirmed, [...primary, ...secondary], true)
                        ) {
                            addNotification({
                                content: (
                                    <Message>
                                        <Bridge>
                                            <DiceRollLogResult roll={roll.unconfirmed} />
                                        </Bridge>
                                    </Message>
                                ),
                                canDismiss: true,
                                showLife: true,
                                timeout: 5000,
                            });
                        }

                        roll.confirm();
                    },
                    boost: boost,
                    vector: vector,
                });
            };
            api.rolled.on(handler);
            return () => {
                api.rolled.off(handler);
            };
        }, [api, diceAnimator, addNotification, campaignData, primary, secondary, user.id, Bridge]);

        useKeyboardShortcut(
            "Escape",
            ev => {
                diceToRoll?.onCancelled?.();
                diceToRollRef.current = undefined;
                setDiceToRoll(undefined);
                ev.preventDefault();
            },
            { priority: 10, isDisabled: !diceToRoll }
        );

        // A dice bag must have at least one non-negative dice roll to be valid. This is to avoid trying to roll expressions
        // like "-1d4", which throw an error server side, whereas expressions like "1d20 - 1d4" are fine.
        let isBagValid = false;
        if (diceToRoll) {
            visitDiceBagTerms(diceToRoll, o => {
                if ((o.op == null || o.op === "+") && o.amount > 0) {
                    isBagValid = true;
                }

                return o;
            });
        }

        return (
            <React.Fragment>
                <Box
                    {...props}
                    zIndex={zIndex}
                    fullWidth
                    fullHeight
                    css={{
                        pointerEvents: diceToRoll ? "all" : "none",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        opacity: diceToRoll ? 1 : 0,
                        transition: "opacity 200ms ease-in-out",
                    }}
                    onPointerDown={e => {
                        e.preventDefault();
                        setPointerStart({ x: e.clientX, y: e.clientY, time: Date.now() });
                    }}
                    onPointerUp={e => {
                        if (pointerStart == null) {
                            return;
                        }

                        e.stopPropagation();

                        var vector = {
                            x: e.clientX - pointerStart.x,
                            y: -(e.clientY - pointerStart.y),
                        };
                        setPointerStart(undefined);

                        var dist = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
                        if (dist < Math.sqrt(bounds.width * bounds.height * 0.01)) {
                            // Didn't move far enough to count as a throw. This is basically a click - count it as cancelling the roll.
                            diceToRoll?.onCancelled?.();
                            diceToRollRef.current = undefined;
                            setDiceToRoll(undefined);
                            return;
                        }

                        if (isBagValid) {
                            var time_int = Date.now() - pointerStart.time;
                            if (time_int > 2000) {
                                time_int = 2000;
                            }

                            var boost = Math.sqrt((2500 - time_int) / 2500) * dist * 8;

                            if (diceToRoll) {
                                diceToRollRef.current = undefined;
                                setDiceToRoll(undefined);
                                api.roll(
                                    diceBagToExpression(diceToRoll),
                                    Object.assign({}, diceToRoll.options, {
                                        state: { vector: vector, boost: boost },
                                    })
                                ).then(
                                    o => {
                                        diceToRoll.onRolled?.(o);
                                        return o;
                                    },
                                    () => {
                                        diceToRoll.onCancelled?.();
                                    }
                                );
                            }
                        }
                    }}>
                    <LayoutGroup>
                        <AnimatePresence>
                            {diceToRoll && (
                                <MotionCard
                                    layout
                                    initial={defaultInitial}
                                    animate={Object.assign({}, defaultAnimate, {
                                        opacity: pointerStart ? 0.5 : 1,
                                    })}
                                    exit={defaultExit}
                                    p={3}
                                    onPointerDown={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    flexDirection="column"
                                    borderRadius={4}
                                    bg="grayscale.9"
                                    boxShadowSize="m">
                                    <MotionBox flexDirection="column" layout>
                                        {diceToRoll.options?.data &&
                                            system.renderLogHeader &&
                                            system.renderLogHeader(
                                                Object.assign({}, diceToRoll.options, {
                                                    userId: user.id,
                                                }),
                                                getToken(
                                                    campaignData.campaign,
                                                    diceToRoll.options.location,
                                                    diceToRoll.options.token
                                                )
                                            )}
                                        <Text mt={diceToRoll.options?.data ? 2 : 0} fontSize={2}>
                                            Click, drag, and release to roll:
                                        </Text>
                                        <Text fontSize={2} fontWeight="bold">
                                            {diceBagToExpression(diceToRoll)}
                                        </Text>
                                        <Box mt={2}>
                                            <Button
                                                size="s"
                                                onClick={() => setDiceToRoll(changeModifier(diceToRoll, 4))}
                                                css={{
                                                    borderTopRightRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                }}>
                                                +4
                                            </Button>
                                            <Button
                                                size="s"
                                                borderRadius={0}
                                                onClick={() => setDiceToRoll(changeModifier(diceToRoll, 3))}>
                                                +3
                                            </Button>
                                            <Button
                                                size="s"
                                                borderRadius={0}
                                                onClick={() => setDiceToRoll(changeModifier(diceToRoll, 2))}>
                                                +2
                                            </Button>
                                            <Button
                                                size="s"
                                                borderRadius={0}
                                                onClick={() => setDiceToRoll(changeModifier(diceToRoll, 1))}>
                                                +1
                                            </Button>
                                            <Button
                                                size="s"
                                                borderRadius={0}
                                                onClick={() => setDiceToRoll(changeModifier(diceToRoll, -1))}>
                                                -1
                                            </Button>
                                            <Button
                                                size="s"
                                                borderRadius={0}
                                                onClick={() => setDiceToRoll(changeModifier(diceToRoll, -2))}>
                                                -2
                                            </Button>
                                            <Button
                                                size="s"
                                                borderRadius={0}
                                                onClick={() => setDiceToRoll(changeModifier(diceToRoll, -3))}>
                                                -3
                                            </Button>
                                            <Button
                                                size="s"
                                                onClick={() => setDiceToRoll(changeModifier(diceToRoll, -4))}
                                                css={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                                                -4
                                            </Button>
                                        </Box>
                                    </MotionBox>
                                    {system.renderDiceTools?.({
                                        dice: diceToRoll,
                                        setDice: setDiceToRoll,
                                    })}
                                    <AnimatePresence>
                                        {!isBagValid && (
                                            <MotionMessage
                                                layout
                                                mt={2}
                                                variant="error"
                                                initial={defaultInitial}
                                                animate={defaultAnimate}
                                                exit={defaultExit}>
                                                You must have at least one positive dice roll.
                                            </MotionMessage>
                                        )}
                                    </AnimatePresence>
                                </MotionCard>
                            )}
                        </AnimatePresence>
                    </LayoutGroup>
                </Box>
                <Box ref={ref} {...props} css={{ position: "absolute", pointerEvents: "none", zIndex: 2000 }}>
                    <Box fullWidth fullHeight ref={hostRef} />
                </Box>
            </React.Fragment>
        );
    }
);

import { Box } from "../primitives";

export const CampaignSummaryThumbnail = ({ screenshot, campaign }) => {
    return (
        <Box
            fullWidth
            height="auto"
            borderTopLeftRadius={4}
            borderTopRightRadius={4}
            backgroundImage={`url('${screenshot ?? campaign.coverImageUrl}')`}
            backgroundSize="cover"
            backgroundPosition="center"
            style={{
                aspectRatio: "1.2 / 1",
            }}
        />
    );
};

import React from 'react'
  
  const ObstructsMovementIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 7.5,20.75h-3c-0.828,0-1.5-0.672-1.5-1.5V5.75l1.607-2.678c0.213-0.355,0.674-0.47,1.029-0.257 C5.741,2.878,5.83,2.967,5.893,3.072L7.5,5.75V20.75z  M 12,20.75H7.5v-15l1.607-2.678c0.213-0.355,0.674-0.47,1.029-0.257 c0.105,0.063,0.194,0.152,0.257,0.257L12,5.75V20.75z  M 16.5,20.75H12v-15l1.607-2.678c0.213-0.355,0.674-0.47,1.029-0.257 c0.105,0.063,0.194,0.152,0.257,0.257L16.5,5.75V20.75z  M 21,19.25c0,0.828-0.672,1.5-1.5,1.5h-3v-15l1.607-2.678 c0.213-0.355,0.674-0.47,1.029-0.257c0.105,0.063,0.194,0.152,0.257,0.257L21,5.75V19.25z  M 0.75,8.75h22.5  M 0.75,16.25h22.5' />
    </svg>
  )
  
  ObstructsMovementIcon.displayName = 'ObstructsMovementIcon'
  
  ObstructsMovementIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default ObstructsMovementIcon
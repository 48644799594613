import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { useCampaign, useLocalGrid, useValidatedLocation, useScale, useViewport } from "../contexts";
import { Rect } from "./three2d/Rect";
import tinycolor from "tinycolor2";
import { Html } from "@react-three/drei";
import { Vector3, Vector2, Shape } from "three";
import { getPlayerColorPalette } from "../../design/utils";
import { LocalPixelPosition, Position } from "../../position";
import { useFrame, useThree } from "@react-three/fiber";
import { distanceBetween, intersect, isInRect, localPoint, localRect } from "../../grid";
import { useProfiles } from "../utils";
import { theme } from "../../design";
import { ZIndexes } from "./common";
import { UiLayer } from "./UiLayer";
import { nanoid } from "nanoid";
import { motion as motion3d } from "framer-motion-3d";
import { motion, useMotionValue } from "framer-motion";
import { animateSequence } from "../motion";

const pingShader = `
uniform float u_startTime;
uniform float u_time;
uniform float u_duration;
uniform vec3 u_color;
uniform vec2 u_position;
uniform float u_maxRadius;
uniform float u_thickness;

void main() {
    float dist = distance(u_position, gl_FragCoord.xy);
    float radius = abs(sin((u_time - u_startTime) * 3.14159 * (2.0 / u_duration))) * u_maxRadius;
    gl_FragColor = vec4(u_color, 1.0 - smoothstep(0.0, u_thickness, abs(dist - radius)));
    if (gl_FragColor.a == 0.0) {
        discard;
    }
}
`;

const pingRadius = 50;
const pingDuration = 2; // In seconds
const pingThickness = 2;
const pingEnterExitDuration = 0.2;

const Ping: FunctionComponent<{
    player: string;
    pos: Position;
    onComplete: () => void;
}> = ({ player, pos, onComplete }) => {
    var { campaign } = useCampaign();
    var localGrid = useLocalGrid();
    var scale = useScale();
    let three = useThree();

    var localPos = localGrid.toLocalPoint(pos);

    var viewport = useViewport()?.position;
    var isInViewport = true;
    var intersectPoint: LocalPixelPosition | undefined;
    var viewportCenter: LocalPixelPosition | undefined;
    if (viewport) {
        isInViewport = isInRect(localPos, viewport);

        if (!isInViewport) {
            var tl = localPoint(viewport.x, viewport.y);
            var tr = localPoint(viewport.x + viewport.width, viewport.y);
            var br = localPoint(viewport.x + viewport.width, viewport.y + viewport.height);
            var bl = localPoint(viewport.x, viewport.y + viewport.height);

            // Find the nearest point on the edge of the viewport.
            viewportCenter = localPoint(viewport.x + viewport.width / 2, viewport.y + viewport.height / 2);

            var lines = [
                [tl, tr],
                [tr, br],
                [br, bl],
                [bl, tl],
            ];
            for (let i = 0; i < lines.length && !intersectPoint; i++) {
                intersectPoint = intersect(lines[i][0], lines[i][1], false, viewportCenter, localPos, false);
            }

            if (intersectPoint) {
                // Found the closest point on the edge of the viewport that should point to where the ping is.
                console.log(
                    `Ping not on screen, but closest point on viewport edge is ${intersectPoint.x},${intersectPoint.y}`
                );
            }
        }
    }

    const uniforms = useMemo(() => {
        var color = tinycolor(getPlayerColorPalette(campaign, player)[3]).toRgb();
        return {
            u_startTime: { value: 0 },
            u_time: { value: 0 },
            u_duration: { value: pingDuration },
            u_position: { value: undefined as Vector2 | undefined },
            u_color: {
                value: new Vector3(color.r / 255, color.g / 255, color.b / 255),
            },
            u_maxRadius: { value: pingRadius },
            u_thickness: { value: pingThickness },
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { profiles } = useProfiles([player]);

    let size = (pingRadius * 2 + pingThickness) / scale;

    // Work out where the avatar should be, making sure it doesn't overlap with the edge of the viewport.
    let circlePos = isInViewport ? localPos : intersectPoint ?? localPos;
    let circleRadius = theme.space[3] / scale;
    let callout: LocalPixelPosition[] | undefined;
    let color = getPlayerColorPalette(campaign, player)[3];
    if (viewport) {
        const margin = theme.space[5] / scale;

        // Find the actual usable area of the viewport (i.e. subtract the margin)
        var vwm = localRect(
            viewport.x + margin,
            viewport.y + margin,
            viewport.width - margin * 2,
            viewport.height - margin * 2
        );
        if (!isInRect(circlePos, vwm) && intersectPoint) {
            // Move the circle so that it is within the margin, following the vector from the
            // intersection point to the center of the viewport.
            var t = intersect(
                localPoint(vwm.x, vwm.y),
                localPoint(vwm.x + vwm.width, vwm.y),
                false,
                intersectPoint,
                viewportCenter!,
                false
            );
            var r = intersect(
                localPoint(vwm.x + vwm.width, vwm.y),
                localPoint(vwm.x + vwm.width, vwm.y + vwm.height),
                false,
                intersectPoint,
                viewportCenter!,
                false
            );
            var b = intersect(
                localPoint(vwm.x + vwm.width, vwm.y + vwm.height),
                localPoint(vwm.x, vwm.y + vwm.height),
                false,
                intersectPoint,
                viewportCenter!,
                false
            );
            var l = intersect(
                localPoint(vwm.x, vwm.y + vwm.height),
                localPoint(vwm.x, vwm.y),
                false,
                intersectPoint,
                viewportCenter!,
                false
            );
            var p = t ?? r ?? b ?? l;
            if (p) {
                circlePos = p;
            }
        }

        if (distanceBetween(circlePos, localPos) > circleRadius) {
            // Work out the points for a triangle callout.
            let p1 = intersectPoint ?? localPos;

            // Normalise the vector from the pos to the circle.
            let magnitude = distanceBetween(p1, circlePos);
            let vx = (circlePos.x - p1.x) / magnitude;
            let vy = (circlePos.y - p1.y) / magnitude;

            const offset = theme.space[2];
            let p2 = localPoint(circlePos.x + vy * offset, circlePos.y - vx * offset);
            let p3 = localPoint(circlePos.x - vy * offset, circlePos.y + vx * offset);
            callout = [p1, p2, p3];
        }
    }

    useFrame(state => {
        if (uniforms.u_startTime.value === 0) {
            uniforms.u_startTime.value = state.clock.elapsedTime;
        }

        uniforms.u_time.value = state.clock.elapsedTime;
        const screenPos = localGrid.toScreenPoint(intersectPoint ? circlePos : localPos);
        uniforms.u_position.value = new Vector2(screenPos.x, three.size.height - screenPos.y);

        if (uniforms.u_time.value - uniforms.u_startTime.value > pingDuration) {
            onComplete();
        }
    });

    const scaleValue = useMotionValue(0);
    const opacityValue = useMotionValue(0);
    const animId = useRef<number>();
    useEffect(() => {
        animateSequence(animId, [
            {
                values: [
                    {
                        motionValue: scaleValue,
                        value: 0.5,
                    },
                    {
                        motionValue: opacityValue,
                        value: 0,
                    },
                ],
            },
            {
                values: [
                    {
                        motionValue: scaleValue,
                        value: 1,
                        options: { duration: pingEnterExitDuration },
                    },
                    {
                        motionValue: opacityValue,
                        value: 1,
                        options: { duration: pingEnterExitDuration },
                    },
                ],
            },
            {
                values: [
                    {
                        motionValue: scaleValue,
                        value: 0.5,
                        options: {
                            duration: pingEnterExitDuration,
                            delay: pingDuration - pingEnterExitDuration * 2,
                        },
                    },
                    {
                        motionValue: opacityValue,
                        value: 0,
                        options: {
                            duration: pingEnterExitDuration,
                            delay: pingDuration - pingEnterExitDuration * 2,
                        },
                    },
                ],
            },
        ]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const c1x = callout?.[0].x;
    const c1y = callout?.[0].y;
    const c2x = callout?.[1].x;
    const c2y = callout?.[1].y;
    const c3x = callout?.[2].x;
    const c3y = callout?.[2].y;
    var callbackShape = useMemo(() => {
        return callout ? new Shape(callout.map(o => new Vector2(o.x - circlePos.x, -(o.y - circlePos.y)))) : undefined;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [c1x, c1y, c2x, c2y, c3x, c3y, circlePos.x, circlePos.y]);

    return (
        <UiLayer>
            <Rect
                x={intersectPoint ? circlePos.x : localPos.x}
                y={intersectPoint ? circlePos.y : localPos.y}
                zIndex={ZIndexes.Overlay}
                width={size}
                height={size}
                noMaterial>
                <shaderMaterial attach="material" fragmentShader={pingShader} transparent uniforms={uniforms} />
            </Rect>
            {profiles[player] && (
                <React.Fragment>
                    {callout && (
                        <motion3d.mesh
                            position={[circlePos.x, -circlePos.y, ZIndexes.UserInterface]}
                            scale={[scaleValue, scaleValue, 1]}>
                            <shapeGeometry attach="geometry" args={[callbackShape]} />
                            <motion3d.meshBasicMaterial
                                transparent
                                attach="material"
                                color={color}
                                opacity={opacityValue as any}
                            />
                        </motion3d.mesh>
                    )}
                    <motion3d.mesh
                        position={[circlePos.x, -circlePos.y, ZIndexes.UserInterface]}
                        scale={[scaleValue, scaleValue, 1]}>
                        <circleGeometry attach="geometry" args={[circleRadius, 48]} />
                        <motion3d.meshBasicMaterial
                            transparent
                            attach="material"
                            color={color}
                            opacity={opacityValue as any}
                        />
                        <Html center zIndexRange={[1, 0]} style={{ pointerEvents: "none", userSelect: "none" }}>
                            <motion.span
                                style={{
                                    fontSize: theme.fontSizes[2],
                                    userSelect: "none",
                                    opacity: opacityValue,
                                }}>
                                !
                            </motion.span>
                        </Html>
                    </motion3d.mesh>
                </React.Fragment>
            )}
        </UiLayer>
    );
};

export const PingLayer: FunctionComponent<{}> = () => {
    var { api, location } = useValidatedLocation();

    var [pings, setPings] = useState<{
        [id: string]: { player: string; pos: Position };
    }>({});

    useEffect(() => {
        var handler: (ping: { player: string; location: string; pos: Position }) => void = ping => {
            if (ping.location === location.id) {
                setPings(Object.assign({}, pings, { [nanoid()]: ping }));
            }
        };
        api.pinged.on(handler);
        return () => {
            api.pinged.off(handler);
        };
    }, [api, pings, location.id]);

    var pingKeys = Object.keys(pings);
    return (
        <React.Fragment>
            {pingKeys.map(o => (
                <Ping
                    key={o}
                    {...pings[o]}
                    onComplete={() => {
                        var newPings = Object.assign({}, pings);
                        delete newPings[o];
                        setPings(newPings);
                    }}
                />
            ))}
        </React.Fragment>
    );
};

import {
    Session,
    UserInfo,
    CampaignRole,
    Campaign,
    SessionConnection,
    Token,
    Zone,
    DiceBag,
    ISessionApi,
    IGameSystem,
    diceBagToExpression,
    Location,
    LocationSummary,
    isLocation,
    CampaignPlayer,
    GmSection,
    LocationLevel,
    getLevelKeysInRenderOrder,
    PositionedLight,
    getTokenOwner,
} from "../store";
import React, { MutableRefObject, useContext, useEffect, useState } from "react";
import { Dispatch } from "redux";
import { ILocalGrid, ILocalGridWithRef } from "../grid";
import { Annotation } from "../annotations";
import { Viewport } from "./common";
import {
    ClipperLibWrapper,
    loadNativeClipperLibInstanceAsync,
    NativeClipperLibRequestedFormat,
} from "js-angusj-clipper";
import { GridPosition } from "../position";
import { DeepPartial } from "../common";
import { Group } from "three";
import { PathFinder } from "../pathfinder";
import { applyOverrides } from "../reducers/common";
import { TokenWithLocalState } from "./LocationStage/common";
import { INotificationProps } from "./Notifications";

export const DispatchContext = React.createContext(undefined as any as Dispatch);
export const SessionConnectionContext = React.createContext(undefined as any as Required<SessionConnection>);
export const CampaignContext = React.createContext(
    undefined as any as { api: ISessionApi; system: IGameSystem; campaign: Campaign }
);
export const SessionContext = React.createContext(
    undefined as any as { api: ISessionApi; system: IGameSystem; session: Session }
);
export const UserContext = React.createContext<UserInfo>(undefined as any as UserInfo);
export const NotificationContext = React.createContext<(o: INotificationProps) => Promise<any>>(undefined as any);
export const LocalGridContext = React.createContext<ILocalGridWithRef>(undefined as any as ILocalGridWithRef);
export const ScaleContext = React.createContext<number>(1);
export const DiceBagContext = React.createContext(
    undefined as any as { dice?: DiceBag; setDice: (dice: DiceBag) => void }
);
export const ViewportContext = React.createContext(undefined as any as Viewport | undefined);
export const UiLayerContext = React.createContext<Group | null>(null);
export const LightingLayerContext = React.createContext<Group | null>(null);
export const PathFindingContext = React.createContext<PathFinder | undefined>(undefined);

export interface AnimationLightingProps {
    lights: { [id: string]: PositionedLight };
    setLight: (light: PositionedLight) => void;
    removeLight: (id: string) => void;
}

export const AnimationLightingContext = React.createContext<AnimationLightingProps>(undefined as any);

// interface AppState {
//     mode: VttMode;
//     setMode: (mode: VttMode) => void;
//     buildMode: VttBuildMode;
//     setBuildMode: (mode: VttBuildMode) => void;

//     tool: ToolType;
//     setTool: (tool: ToolType) => void;
//     subtool: SubtoolType | undefined;
//     setSubtool: (subtool: SubtoolType) => void;

//     query: string | undefined;
//     setQuery: (query: string | undefined) => void;

//     // Whether or not the search bar has been expanded to show extended properties etc.
//     isSearchExpanded: boolean;
//     setIsSearchExpanded: (isExpanded: boolean) => void;

//     // Whether the current query has search results. If isSearchExpanded is false then these results will be shown.
//     hasSearchResults: boolean;
//     isSearchPropertiesOpen: boolean;
// }

// export const useAppState2 = create<AppState>(set => ({
//     mode: "play",
//     setMode: mode => set({ mode: mode }),
//     buildMode: "tokens",
//     setBuildMode: mode => set({ buildMode: mode }),
//     tool: "select",
//     setTool: tool => set({ tool: tool }),
//     subtool: undefined,
//     setSubtool: subtool => set({ subtool: subtool }),
//     query: undefined,
//     setQuery: query => set({ query: query }),
//     isSearchExpanded: false,
//     setIsSearchExpanded: isExpanded => set({ isSearchExpanded: isExpanded })
// }));

export interface AppStateProps {
    searchPropertiesSection: GmSection;
    setSearchPropertiesSection: (section: GmSection) => void;
    searchPropertiesSections: MutableRefObject<GmSection[]>;

    /**
     * Sets the focused token or token template. If undefined, then the focused token will instead be derived from the current selection.
     */
    setFocusedToken: (token: string | undefined) => void;

    /**
     * Gets the focused token or token template ID.
     */
    focusedToken?: string;
}

export const AppStateContext = React.createContext(undefined as any as AppStateProps);

export interface CameraContextProps {
    isPerspective: boolean;
    isOrthographic: boolean;

    hasPerspectiveLighting: boolean;
    setRequiresPerspectiveLighting(key: string, requiresLighting: boolean);

    // True if the camera has at any point been in perspective mode. Use this to decide if you should keep 3D resources that
    // may be slow to load (i.e. meshes, etc) around even if the camera is currently in orthographic mode.
    hasBeenPerspective: boolean;
}

export const CameraContext = React.createContext(undefined as any as CameraContextProps);

export var clipper: ClipperLibWrapper;
export const clipperPromise = loadNativeClipperLibInstanceAsync(
    NativeClipperLibRequestedFormat.WasmWithAsmJsFallback
).then(o => (clipper = o));

interface TokenOverrideProps {
    overrideToken: (id: string, override: DeepPartial<TokenWithLocalState> | undefined) => void;
    tokenOverrides?: { [id: string]: DeepPartial<TokenWithLocalState> };
}

export const TokenOverrideContext = React.createContext(undefined as any as TokenOverrideProps);

interface AnnotationOverrideProps {
    overrideAnnotation: (id: string, override: DeepPartial<Annotation> | undefined) => void;
    annotationOverrides?: { [id: string]: DeepPartial<Annotation> };
}

export const AnnotationOverrideContext = React.createContext(undefined as any as AnnotationOverrideProps);

interface ZoneOverrideProps {
    overrideZone: (id: string, override: DeepPartial<Zone> | undefined) => void;
    zoneOverrides?: { [id: string]: DeepPartial<Zone> };
}

export const ZoneOverrideContext = React.createContext(undefined as any as ZoneOverrideProps);

interface AnnotationCacheProps {
    getGridPoints(
        annotation: Annotation,
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        tokenOverrides: { [id: string]: DeepPartial<Token> } | undefined,
        clipper: ClipperLibWrapper
    ): GridPosition[] | undefined;
}

export const AnnotationCacheContext = React.createContext(undefined as any as AnnotationCacheProps);

interface SelectionProps {
    primary: string[];
    secondary: string[];
    setSelection(primary: string[], secondary?: string[]);
}

export const SelectionContext = React.createContext(undefined as any as SelectionProps);

export function useAppState() {
    return useContext(AppStateContext);
}

export function useCamera() {
    return useContext(CameraContext);
}

export function useDispatch() {
    return useContext(DispatchContext);
}

export function useSessionConnection() {
    return useContext(SessionConnectionContext);
}

export function useSession() {
    return useContext(SessionContext);
}

export function useCampaign() {
    return useContext(CampaignContext);
}

export function useUser() {
    return useContext(UserContext);
}

export function useNotifications() {
    return useContext(NotificationContext);
}

export function usePlayer() {
    const user = useUser();
    const { campaign } = useCampaign();
    return campaign.players[user.id];
}

export function useRole(): CampaignRole {
    const user = useUser();
    const { campaign } = useCampaign();
    return getRole(user, campaign);
}

export function getRole(user: UserInfo, campaign?: Campaign) {
    const campaignPlayer = campaign?.players[user.id];
    return campaignPlayer && campaignPlayer.role ? campaignPlayer.role : "Player";
}

export function useLocation() {
    const user = useUser();
    const campaign = useCampaign();
    const player = campaign.campaign.players[user.id];
    const location = campaign.campaign.locations[player.location];

    return Object.assign({}, campaign, {
        location: location as Location | LocationSummary | undefined,
        user: user,
        player: player,
    });
}

export function useLocationLevel() {
    const user = useUser();
    const campaign = useCampaign();
    const player = campaign.campaign.players[user.id];
    const location = campaign.campaign.locations[player.location];
    const selection = useSelection();
    const role = useRole();
    const { tokenOverrides } = useTokenOverrides();

    let ids: string[] | undefined;
    let level: LocationLevel | undefined;
    let levelKey: string | undefined;
    let levelKeys: string[] | undefined;
    if (isLocation(location)) {
        levelKeys = getLevelKeysInRenderOrder(location);

        // First try to identify the items relevant to level selection (and also vision source selection, so make the
        // results available from the return value).
        const sharedVision = campaign.campaign.sharedVision == null ? "party" : campaign.campaign.sharedVision;
        let addPlayerTokens = false;
        if (role === "GM") {
            if (selection) {
                ids = selection.primary;

                for (let i = 0; i < ids.length; i++) {
                    const token = location.tokens[i];
                    if (token) {
                        const owner = getTokenOwner(campaign.campaign, token);
                        if (owner === user.id || (owner != null && sharedVision === "party")) {
                            addPlayerTokens = true;
                            ids = ids.slice();
                            break;
                        }
                    }
                }
            } else {
                ids = [];
            }
        } else {
            addPlayerTokens = true;
            ids = [];
        }

        // If we're interested in player tokens, either because we're a player or because we're a DM with a player
        // token selected, then add all the player tokens.
        if (addPlayerTokens) {
            const tokens = Object.values(location.tokens);
            for (let i = 0; i < tokens.length; i++) {
                const token = tokens[i];
                if (token) {
                    const owner = getTokenOwner(campaign.campaign, token);
                    if (owner === user.id || (owner != null && sharedVision === "party")) {
                        if (ids.indexOf(token.id) < 0) {
                            ids.push(token.id);
                        }
                    }
                }
            }
        }

        // Find the levels associated with the items we've identified, if any.
        let highestLevel: LocationLevel | undefined;
        let highestLevelKey: string | undefined;
        let highestLevelIndex: number | undefined;
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];

            let levelKey: string | undefined;
            if (location.tokens[id]) {
                levelKey = applyOverrides(location.tokens[id], tokenOverrides).pos.level;
            } else if (location.annotations[id]) {
                // TODO: This doesn't work if the annotation is centered on a token.
                // We can't use getAnnotationPos because it requires a grid, which we don't have at this point, do we?
                // TODO: Also ignoring annotation overrides for now as that just introduces more ways to invalidate everything, and
                // there doesn't seem to be a good reason to do it.
                levelKey = location.annotations[id]?.pos?.level;
            } else if (location.zones[id]) {
                levelKey = location.zones[id].pos.level;
            }

            //const levelKey = location.tokens[id]?.pos.level ?? location.annotations[id]?.pos?.level ?? location.zones[id]?.pos.level;
            if (levelKey != null && highestLevelKey !== levelKey) {
                const i = levelKeys.indexOf(levelKey);
                if (highestLevelKey == null || i > highestLevelIndex!) {
                    highestLevel = location.levels[levelKey];
                    highestLevelKey = levelKey;
                    highestLevelIndex = i;
                }
            }
        }

        levelKey = highestLevelKey;
        level = highestLevel;

        // If we haven't found anything via selection, then fall back to the last configured level for this user (GM).
        if (!level && player.level) {
            levelKey = player.level;
            level = location.levels[levelKey];
        }

        // As a last resort, default to the default level for the location.
        if (!level) {
            levelKey = location.defaultLevel;
            level = location.levels[levelKey];
        }
    }

    return Object.assign({}, campaign, {
        location: location as Location | LocationSummary | undefined,
        level: level,
        levelKey: levelKey,
        levelKeys: levelKeys,
        relevantIds: ids,
        user: user,
        player: player,
    });
}

/**
 * Gets the location and other related information.
 * Use when the component should only be mounted when the fully loaded location is available.
 */
export function useValidatedLocation() {
    const data = useLocation();
    if (!isLocation(data.location)) {
        throw new Error("This component must only be included when the location has been fully loaded.");
    }

    return data as {
        api: ISessionApi;
        system: IGameSystem;
        campaign: Campaign;
        location: Location;
        user: UserInfo;
        player: CampaignPlayer;
    };
}

export function useValidatedLocationLevel() {
    const data = useLocationLevel();
    if (!isLocation(data.location)) {
        throw new Error("This component must only be included when the location has been fully loaded.");
    }

    return data as {
        api: ISessionApi;
        system: IGameSystem;
        campaign: Campaign;
        location: Location;
        user: UserInfo;
        player: CampaignPlayer;
        level: LocationLevel;
        levelKey: string;
        relevantIds: string[];
    };
}

export function useLocalGrid() {
    return useContext(LocalGridContext);
}

export function useScale() {
    return useContext(ScaleContext);
}

export function useTokenOverrides() {
    return useContext(TokenOverrideContext);
}

export function useAnnotationOverrides() {
    return useContext(AnnotationOverrideContext);
}

export function useZoneOverrides() {
    return useContext(ZoneOverrideContext);
}

export function useDiceBag() {
    // TODO: Disconnect api from SessionConnectionContext so that we don't rerender for everything when all we want is the api.
    var { api } = useSessionConnection();
    var context = useContext(DiceBagContext);

    // If the dice bag isn't available for some reason (dialog, popup, etc) then fall back to rolling directly.
    return context ?? { setDice: (diceBag, options) => api.roll(diceBagToExpression(diceBag), options) };
}

export function useViewport() {
    return useContext(ViewportContext);
}

export function useClipper() {
    const [c, setClipper] = useState<ClipperLibWrapper | undefined>(clipper);
    useEffect(() => {
        if (!c) {
            (async () => {
                setClipper(await clipperPromise);
            })();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return c;
}

export function useAnnotationCache() {
    return useContext(AnnotationCacheContext);
}

export function useSelection() {
    return useContext(SelectionContext);
}

export function usePathFinding() {
    return useContext(PathFindingContext);
}

export function useAnimationLighting() {
    return useContext(AnimationLightingContext);
}

import React from 'react'
  
  const ListIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 8.25,3.748h15  M 8.25,12.748h15  M 8.25,21.748h15  M 1.5,0.748h3c0.414,0,0.75,0.336,0.75,0.75v3 c0,0.414-0.336,0.75-0.75,0.75h-3c-0.414,0-0.75-0.336-0.75-0.75v-3C0.75,1.084,1.086,0.748,1.5,0.748z  M 1.5,9.748h3 c0.414,0,0.75,0.336,0.75,0.75v3c0,0.414-0.336,0.75-0.75,0.75h-3c-0.414,0-0.75-0.336-0.75-0.75v-3 C0.75,10.084,1.086,9.748,1.5,9.748z  M 1.5,18.748h3c0.414,0,0.75,0.336,0.75,0.75v3c0,0.414-0.336,0.75-0.75,0.75h-3 c-0.414,0-0.75-0.336-0.75-0.75v-3C0.75,19.084,1.086,18.748,1.5,18.748z' />
    </svg>
  )
  
  ListIcon.displayName = 'ListIcon'
  
  ListIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default ListIcon
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import {
    Box,
    Checkbox,
    Grid,
    Heading,
    Input,
    Label,
    Link,
    Radio,
    Select,
    Text,
} from "../../../../components/primitives";
import { InputField, SelectField, asField, IAsFieldProps, Form, CheckboxField } from "../../../../components/Form";
import { useNotifications } from "../../../../components/Notifications";
import { Button } from "../../../../components/Button";
import { theme } from "../../../../design";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import React, {
    ChangeEvent,
    FunctionComponent,
    MouseEvent,
    PropsWithChildren,
    useEffect,
    useId,
    useState,
} from "react";
import { ExtractProps, LobotomizedBox, SidebarButton, useVttApp } from "../../../../components/common";
import { useAppState, useCampaign, useDispatch, useLocation } from "../../../../components/contexts";
import Add from "../../../../components/icons/Add";
import Close from "../../../../components/icons/Close";
import {
    MotionTag,
    defaultInitial,
    defaultAnimate,
    defaultExit,
    MotionBox,
    MotionForm,
    sectionInitial,
    sectionAnimate,
    sectionExit,
    MotionLobotomizedBox,
    MotionInOverlayCard,
    AnimatedListItem,
    AnimatedList,
    MotionSelect,
} from "../../../../components/motion";
import { useDropEvents, useErrorHandler, useForceUpdate } from "../../../../components/utils";
import { copyState } from "../../../../reducers/common";
import {
    StoredDiceBag,
    diceBagToExpression,
    ImageType,
    parseDiceBag,
    resolveToken,
    isTokenTemplate,
    ResolvedToken,
    isToken,
} from "../../../../store";
import { modifyMonster } from "../../actions/token";
import {
    coreAbilities,
    CoreAbility,
    DnD5EMonsterTemplate,
    DnD5EMonsterToken,
    isDnD5EMonsterToken,
    isDnD5EMonsterTemplate,
    damageTypes as damageTypesList,
    getRuleKey,
    fromRuleKey,
    Ability,
    AbilityTimeUnit,
    AbilityTime,
    abilityTimeUnits,
    abilityTimeUnitToString,
    AttackType,
    AbilityEffect,
    AreaOfEffect,
    AreaOfEffectType,
    ConeAreaOfEffect,
    CubeAreaOfEffect,
    SquareAreaOfEffect,
    CylinderAreaOfEffect,
    LineAreaOfEffect,
    SphereAreaOfEffect,
    CreatureAreaOfEffect,
    AbilityDuration,
    durationUnits,
    DurationUnit,
    DamageType,
    AbilityDamage,
    damageTypes,
    AbilityRange,
    ApplicableAbilityEffect,
    NamedRuleRef,
    isNamedRuleRef,
    DnD5EExpressionDuration,
    damageTypeToString,
    creatureConditions,
    CreatureConditionName,
    RollModifiers,
    AttackModifiers,
    RollModifiersBase,
    AttackModifiersCondition,
    AppliedEffectEndTriggers,
    abilityEffectTriggers,
    abilityEffectTriggerToString,
    AbilityEffectTriggers,
    SingleAttackType,
    MonsterFilter,
} from "../../common";
import {
    Alignment,
    alignments,
    alignmentToString,
    CoreAbilities,
    CreatureSize,
    creatureSizes,
    CreatureType,
    creatureTypes,
    creatureTypeToString,
    getCoreAbilityLabel,
    TerrainType,
    terrainTypes,
    TerrainTypes,
    terrainTypeToString,
    modifierFromAbilityScore,
    defaultStandardLanguages,
    defaultExoticLanguages,
    Skill,
    skills as skillList,
    skillToString,
    Monster,
    resolveTokenCreature,
    challengeRatings,
    crToString,
    DamageTypes,
    damageTypesToMarkdown,
    MonsterSpellcasting,
    MonsterSpell,
    NamedContent,
    Senses,
    senseTypes,
    senseTypeToString,
    MovementSpeeds,
    movementTypes,
    movementTypeToString,
    conditionToString,
    MonsterMultiattackPart,
    MonsterAbility,
    attackTypeToString,
    Character,
    isMonster,
} from "../../creature";
import { useRules } from "../hooks";
import { DragData, dragDropPalette, useTypedDroppable } from "../../../../components/draggable";
import { Active } from "@dnd-kit/core";
import { LibraryItem } from "../../../../library";
import { TokenImage, TokenImageField } from "../../../../components/TokenImage";
import { dropFiles } from "../../../../components/Sidebar/ArtLibrary";
import CharacterSheetIcon from "../../../../components/icons/CharacterSheet";
import CombatIcon from "../../../../components/icons/Combat";
import SpellbookIcon from "../../../../components/icons/Spellbook";
import BookPileIcon from "../../../../components/icons/BookPile";
import AuraIcon from "../../../../components/icons/Aura";
import styled from "@emotion/styled";
import { ScrollableTest } from "../../../../components/ScrollableTest";
import { setTokenImage } from "../../../../actions/token";
import {
    DeepPartial,
    KeyedList,
    addToKeyedList,
    keyedListToArray,
    keyedListToKeyArray,
    mapKeyedList,
} from "../../../../common";
import { ModalContent } from "../../../../components/modal";
import { MonsterTags } from "./MonsterSheetContent";
import { Markdown, MarkdownEditor, MarkdownEditorField } from "../../../../components/markdown";
import { Spell } from "../../spells";
import { SpellInfo } from "../SpellInfo";
import { CompendiumPage, useCompendium } from "../common";
import { getThemeColor } from "../../../../design/utils";
import { FeatureExpander } from "../FeatureExpander";
import CloseIcon from "../../../../components/icons/Close";

const abilityCheckValues: (CoreAbility | "initiative" | "all")[] = [...coreAbilities, "initiative", "all"];
const savingThrowValues: (CoreAbility | "all")[] = [...coreAbilities, "all"];

const creatureAlignments: (Alignment | "Any")[] = ["Any", ...alignments];

const FormIndent = React.forwardRef<HTMLElement, { onRemove?: () => void }>((props, ref) => {
    if (props.onRemove) {
        return (
            <MotionBox layout alignSelf="stretch">
                <Button
                    ref={ref as any}
                    mr={2}
                    alignSelf="stretch"
                    onClick={props.onRemove}
                    css={{
                        height: "auto",
                        minWidth: "auto",
                        width: theme.space[3],
                        padding: theme.space[1],
                    }}>
                    <CloseIcon />
                </Button>
            </MotionBox>
        );
    }

    return <Box ref={ref as any} alignSelf="stretch" width={theme.space[3]} borderRadius={3} bg="grayscale.8" mr={2} />;
});

const FormIndentSection = React.forwardRef<HTMLDivElement, PropsWithChildren<{ onRemove?: () => void }>>(
    (props, ref) => {
        return (
            <MotionBox layout ref={ref} initial={defaultInitial} animate={defaultAnimate} exit={defaultExit} fullWidth>
                <FormIndent onRemove={props.onRemove} />
                <Box flexGrow={1} flexShrink={1} justifyContent="flex-start">
                    {props.children}
                </Box>
            </MotionBox>
        );
    }
);

const TagSelect: FunctionComponent<{
    values?: string[];
    options?: string[];
    selectHint?: string;
    valueToLabel?: (value: string) => string;
    valueToContent?: (value: string) => React.ReactNode;
    onValuesChanged: (values: string[]) => void;
}> = ({ values, options, selectHint, valueToLabel, valueToContent, onValuesChanged }) => {
    return (
        <Box css={{ gap: theme.space[2], flexWrap: "wrap", justifyContent: "flex-start" }}>
            <AnimatePresence>
                {values?.map(o => (
                    <MotionTag
                        key={o}
                        layout
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        fontSize={1}
                        bg="grayscale.8"
                        css={{
                            minHeight: theme.space[5],
                            maxWidth: "30rem",
                            textShadow: "none",
                            display: "flex",
                            alignItems: valueToContent ? "flex-start" : "center",
                            paddingRight: 0,
                        }}>
                        <Box flexDirection="column" pb={valueToContent ? 2 : 0}>
                            <span>{valueToLabel ? valueToLabel(o) : o}</span>
                            {valueToContent?.(o)}
                        </Box>
                        <Button
                            size="s"
                            variant="clean"
                            onClick={e => {
                                const i = values.indexOf(o);
                                if (i >= 0) {
                                    const newValues = values.slice();
                                    newValues.splice(i, 1);
                                    onValuesChanged(newValues);
                                }
                            }}>
                            <CloseIcon size={8 as any} />
                        </Button>
                    </MotionTag>
                ))}
            </AnimatePresence>
            <MotionBox layout>
                <Select
                    onChange={e => {
                        if (values) {
                            const newValues = values.slice();
                            newValues.push(e.target.value);
                            onValuesChanged(newValues);
                        } else {
                            onValuesChanged([e.target.value]);
                        }
                    }}>
                    <option key="__" value="">
                        {selectHint ? `- ${selectHint} -` : "--"}
                    </option>
                    {options
                        ?.filter(o => values == null || values.indexOf(o) < 0)
                        .map(o => (
                            <option key={o} value={o}>
                                {valueToLabel ? valueToLabel(o) : o}
                            </option>
                        ))}
                </Select>
            </MotionBox>
        </Box>
    );
};

const TagSelectField = asField<HTMLDivElement, ExtractProps<typeof TagSelect> & IAsFieldProps>(TagSelect);

const RollModifiersBaseEditor = React.forwardRef<
    HTMLDivElement,
    {
        rollModifier: RollModifiersBase | RollModifiers;
        onRollModifiersChanged: (e: RollModifiersBase) => void;
        canFail?: boolean;
    } & ExtractProps<typeof MotionBox>
>(({ rollModifier, onRollModifiersChanged, canFail, ...props }, ref) => {
    let rollWith =
        rollModifier.advantage && rollModifier.disadvantage
            ? ""
            : rollModifier.advantage
            ? "adv"
            : rollModifier.disadvantage
            ? "dis"
            : "";
    if (canFail && (rollModifier as RollModifiers).fail) {
        rollWith = "fail";
    }

    return (
        <MotionBox layout flexDirection="column" ref={ref} alignItems="flex-start" {...props}>
            <SelectField
                label="Roll with"
                mb={2}
                value={rollWith}
                onChange={e => {
                    if (e.target.value === "") {
                        onRollModifiersChanged(
                            copyState(rollModifier, { advantage: undefined, disadvantage: undefined, fail: undefined })
                        );
                    } else if (e.target.value === "adv") {
                        onRollModifiersChanged(
                            copyState(rollModifier, { advantage: true, disadvantage: undefined, fail: undefined })
                        );
                    } else if (e.target.value === "dis") {
                        onRollModifiersChanged(
                            copyState(rollModifier, { advantage: undefined, disadvantage: true, fail: undefined })
                        );
                    } else if (e.target.value === "fail") {
                        onRollModifiersChanged(
                            copyState(rollModifier, { advantage: undefined, disadvantage: undefined, fail: true })
                        );
                    }
                }}>
                <option key="__" value="">
                    --
                </option>
                <option key="adv" value="adv">
                    Advantage
                </option>
                <option key="dis" value="dis">
                    Disadvantage
                </option>
                <option key="fail" value="fail">
                    Automatic failure
                </option>
            </SelectField>

            <InputField
                label="Extra roll"
                mb={2}
                value={rollModifier.extraRoll ?? ""}
                disabled={(rollModifier as RollModifiers).fail}
                onChange={e => {
                    if (e.target.value === "") {
                        onRollModifiersChanged(copyState(rollModifier, { extraRoll: undefined }));
                    } else {
                        onRollModifiersChanged(copyState(rollModifier, { extraRoll: e.target.value }));
                    }
                }}
            />
        </MotionBox>
    );
});

const RollModifiersEditor = React.forwardRef<
    HTMLDivElement,
    { rollModifier: RollModifiers; onRollModifiersChanged: (e: RollModifiers) => void }
>(({ rollModifier, onRollModifiersChanged }, ref) => {
    return (
        <Box flexDirection="column" ref={ref} alignItems="flex-start">
            <RollModifiersBaseEditor
                rollModifier={rollModifier}
                onRollModifiersChanged={onRollModifiersChanged}
                canFail
                mb={2}
            />

            <InputField
                label="Condition"
                value={rollModifier.condition ?? ""}
                onChange={e => {
                    if (e.target.value === "") {
                        onRollModifiersChanged(copyState(rollModifier, { condition: undefined }));
                    } else {
                        onRollModifiersChanged(copyState(rollModifier, { condition: e.target.value }));
                    }
                }}
            />
        </Box>
    );
});

const ExpressionDurationEditor = React.forwardRef<
    HTMLDivElement,
    { duration?: DnD5EExpressionDuration; onDurationChanged: (duration: DnD5EExpressionDuration | undefined) => void }
>(({ duration, onDurationChanged }, ref) => {
    return (
        <Box flexDirection="row" ref={ref} justifyContent="flex-start">
            <Select
                value={duration?.unit ?? ""}
                onChange={e => {
                    const unit = e.target.value ? (e.target.value as DurationUnit) : undefined;
                    if (unit == null) {
                        // Without a unit, there can be no duration.
                        onDurationChanged(undefined);
                    } else {
                        const newDuration: DnD5EExpressionDuration = Object.assign({}, duration, {
                            unit: unit,
                        });

                        if (unit === "permanent" || unit === "special") {
                            delete newDuration.amount;
                            delete newDuration.amountExpr;
                        }

                        onDurationChanged(newDuration);
                    }
                }}>
                <option value="">- Select unit -</option>
                {durationUnits.map(o => (
                    <option key={o} value={o}>
                        {o}
                    </option>
                ))}
            </Select>
            <Input
                type="number"
                value={duration?.amount ?? duration?.amountExpr ?? ""}
                ml={1}
                disabled={duration?.unit === "permanent" || duration?.unit === "special"}
                onChange={e => {
                    let amount: number | undefined;
                    let amountExpr: string | undefined;
                    if (e.target.value.trim() !== "") {
                        if (isNaN(e.target.valueAsNumber)) {
                            amountExpr = e.target.value;
                        } else {
                            amount = e.target.valueAsNumber;
                        }
                    }
                    const newDuration: DnD5EExpressionDuration = Object.assign({}, duration, {
                        unit: duration?.unit ?? "round",
                    });
                    delete newDuration.amount;
                    delete newDuration.amountExpr;

                    if (amount != null) {
                        newDuration.amount = amount;
                    }

                    if (amountExpr != null) {
                        newDuration.amountExpr = amountExpr;
                    }

                    onDurationChanged(newDuration);
                }}
            />
        </Box>
    );
});

const ExpressionDurationField = asField<HTMLDivElement, ExtractProps<typeof ExpressionDurationEditor> & IAsFieldProps>(
    ExpressionDurationEditor
);

const AbilityDurationEditor = React.forwardRef<
    HTMLDivElement,
    { duration?: AbilityDuration; onDurationChanged: (duration: AbilityDuration | undefined) => void }
>(({ duration, onDurationChanged }, ref) => {
    return (
        <Box ref={ref} flexDirection="column" alignItems="stretch">
            <ExpressionDurationEditor duration={duration} onDurationChanged={onDurationChanged} />
            {/* TODO: Add concentration checkbox */}
        </Box>
    );
});

const AbilityDurationField = asField<HTMLDivElement, ExtractProps<typeof AbilityDurationEditor> & IAsFieldProps>(
    AbilityDurationEditor
);

const AbilityTimeEditor = React.forwardRef<
    HTMLDivElement,
    { ability: Ability; onAbilityChanged: (ability: Ability) => void }
>(({ ability, onAbilityChanged }, ref) => {
    return (
        <Box flexDirection="row" ref={ref} justifyContent="flex-start">
            <Select
                value={ability.time?.unit ?? ""}
                onChange={e => {
                    const unit = e.target.value ? (e.target.value as AbilityTimeUnit) : undefined;
                    if (unit == null) {
                        // Without a unit, there can be no time cost.
                        onAbilityChanged(copyState(ability, { time: undefined }));
                    } else {
                        const newTime: AbilityTime = {
                            unit: unit,
                            amount: ability.time?.amount ?? 1,
                        };
                        onAbilityChanged(copyState(ability, { time: newTime, isAttackAction: undefined }));
                    }
                }}>
                <option value="">--</option>
                {abilityTimeUnits.map(o => (
                    <option key={o} value={o}>
                        {abilityTimeUnitToString(o)}
                    </option>
                ))}
            </Select>
            <Input
                type="number"
                value={ability.time?.amount ?? ""}
                ml={1}
                onChange={e => {
                    if (isNaN(e.target.valueAsNumber)) {
                        // Without an amount, there can be no time cost.
                        onAbilityChanged(copyState(ability, { time: undefined }));
                    } else {
                        const newTime: AbilityTime = {
                            unit: ability.time?.unit ?? "action",
                            amount: e.target.valueAsNumber,
                        };
                        onAbilityChanged(copyState(ability, { time: newTime, isAttackAction: undefined }));
                    }
                }}
            />
        </Box>
    );
});

const AbilityTimeField = asField<HTMLDivElement, ExtractProps<typeof AbilityTimeEditor> & IAsFieldProps>(
    AbilityTimeEditor
);

const EffectDamageEditor: FunctionComponent<{
    damage?: Partial<DamageTypes<AbilityDamage, DamageType>> & { choice?: DamageType[] };
    onDamageChanged: (damage: Partial<DamageTypes<AbilityDamage, DamageType>> & { choice?: DamageType[] }) => void;
    savingThrow?: CoreAbility;
}> = ({ damage, onDamageChanged, savingThrow }) => {
    // TODO: Remember damage choices - i.e. spirit guardians
    const populatedDamageTypes = damageTypes.filter(o => damage?.[o] != null);

    return (
        <LobotomizedBox flexDirection="column" alignItems="flex-start">
            <AnimatePresence>
                {populatedDamageTypes.map(o => {
                    const label = o.substring(0, 1).toUpperCase() + o.substring(1);

                    return (
                        <MotionBox
                            key={o}
                            layout
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}
                            justifyContent="flex-start">
                            <Box flexDirection="column" alignItems="flex-start">
                                <Label>{label} damage</Label>
                                <Input
                                    value={damage?.[o]?.base ?? ""}
                                    onChange={e => {
                                        const newDamageType = damage?.[o]
                                            ? copyState(damage[o], { base: e.target.value })
                                            : { base: e.target.value };
                                        onDamageChanged(
                                            damage ? copyState(damage, { [o]: newDamageType }) : { [o]: newDamageType }
                                        );
                                    }}
                                />
                            </Box>

                            <Box flexDirection="column" alignItems="flex-start" ml={2}>
                                <Label>On save multiplier</Label>
                                <Input
                                    type="number"
                                    value={damage?.[o]?.onSave ?? ""}
                                    min={0}
                                    max={1}
                                    disabled={savingThrow == null}
                                    onChange={e => {
                                        const value = isNaN(e.target.valueAsNumber)
                                            ? undefined
                                            : Math.max(0, e.target.valueAsNumber);
                                        const newDamageType = damage?.[o]
                                            ? copyState(damage[o], { onSave: value })
                                            : { onSave: value };
                                        onDamageChanged(
                                            damage ? copyState(damage, { [o]: newDamageType }) : { [o]: newDamageType }
                                        );
                                    }}
                                />
                            </Box>
                        </MotionBox>
                    );
                })}
            </AnimatePresence>

            <MotionSelect
                layout
                onChange={e => {
                    if (e.target.value) {
                        const damageType = e.target.value as DamageType;
                        onDamageChanged(
                            damage ? copyState(damage, { [damageType]: { base: "" } }) : { [damageType]: { base: "" } }
                        );
                    }
                }}>
                <option key="none" value="">
                    - Select a damage type -
                </option>
                {damageTypes
                    .filter(o => populatedDamageTypes.indexOf(o) < 0)
                    .map(o => {
                        const label = o.substring(0, 1).toUpperCase() + o.substring(1);
                        return (
                            <option key={o} value={o}>
                                {label}
                            </option>
                        );
                    })}
            </MotionSelect>
        </LobotomizedBox>
    );
};

const MonsterFilterEditor: FunctionComponent<{
    filter: MonsterFilter;
    onFilterChanged: (filter: MonsterFilter) => void;
    hasTarget?: boolean;
}> = ({ filter, onFilterChanged, hasTarget }) => {
    return (
        <Box flexGrow={1} flexShrink={1} flexDirection="column" alignItems="stretch" css={{ gap: theme.space[3] }}>
            {filter.alignment && (
                <FormIndentSection onRemove={() => onFilterChanged(copyState(filter, { alignment: undefined }))}>
                    <TagSelectField
                        label="Alignment"
                        hint="The creature's alignment must match one of these values."
                        required
                        values={Array.isArray(filter.alignment) ? filter.alignment : [filter.alignment]}
                        options={alignments}
                        valueToLabel={v => alignmentToString(v as Alignment)}
                        onValuesChanged={e => {
                            onFilterChanged(copyState(filter, { alignment: e as Alignment[] }));
                        }}
                    />
                </FormIndentSection>
            )}

            {filter.creatureType && (
                <FormIndentSection onRemove={() => onFilterChanged(copyState(filter, { creatureType: undefined }))}>
                    <TagSelectField
                        label="Creature type"
                        hint="The creature's type must match one of these values."
                        required
                        values={Array.isArray(filter.creatureType) ? filter.creatureType : [filter.creatureType]}
                        options={creatureTypes}
                        valueToLabel={v => creatureTypeToString(v as CreatureType)}
                        onValuesChanged={e => {
                            onFilterChanged(copyState(filter, { creatureType: e as CreatureType[] }));
                        }}
                    />
                </FormIndentSection>
            )}

            {filter.cr != null && (
                <FormIndentSection onRemove={() => onFilterChanged(copyState(filter, { cr: undefined }))}>
                    <SelectField
                        label="CR"
                        hint="The creature's CR must be exactly this value."
                        required
                        value={filter.cr === 0 ? "" : filter.cr}
                        onChange={e => {
                            const cr = parseFloat(e.target.value);
                            if (isNaN(cr)) {
                                onFilterChanged(copyState(filter, { cr: 0 }));
                            } else {
                                onFilterChanged(copyState(filter, { cr: cr }));
                            }
                        }}>
                        <option key="__" value="">
                            - Select a CR -
                        </option>
                        {challengeRatings.map(o => (
                            <option key={o} value={o}>
                                {crToString(o)}
                            </option>
                        ))}
                    </SelectField>
                </FormIndentSection>
            )}

            {filter.maxCr != null && (
                <FormIndentSection onRemove={() => onFilterChanged(copyState(filter, { maxCr: undefined }))}>
                    <SelectField
                        label="Max CR"
                        hint="The creature's CR must be less than or equal to this value."
                        required
                        value={filter.maxCr === 0 ? "" : filter.maxCr}
                        onChange={e => {
                            const cr = parseFloat(e.target.value);
                            if (isNaN(cr)) {
                                onFilterChanged(copyState(filter, { maxCr: 0 }));
                            } else {
                                onFilterChanged(copyState(filter, { maxCr: cr }));
                            }
                        }}>
                        <option key="__" value="">
                            - Select a CR -
                        </option>
                        {hasTarget && (
                            <option key="target" value="-1">
                                Target CR
                            </option>
                        )}
                        {challengeRatings.map(o => (
                            <option key={o} value={o}>
                                {crToString(o)}
                            </option>
                        ))}
                    </SelectField>
                </FormIndentSection>
            )}

            {filter.minCr != null && (
                <FormIndentSection onRemove={() => onFilterChanged(copyState(filter, { minCr: undefined }))}>
                    <SelectField
                        label="Min CR"
                        hint="The creature's CR must be greater than or equal to this value."
                        required
                        value={filter.minCr === 0 ? "" : filter.minCr}
                        onChange={e => {
                            const cr = parseFloat(e.target.value);
                            if (isNaN(cr)) {
                                onFilterChanged(copyState(filter, { minCr: 0 }));
                            } else {
                                onFilterChanged(copyState(filter, { minCr: cr }));
                            }
                        }}>
                        <option key="__" value="">
                            - Select a CR -
                        </option>
                        <option key="target" value="-1">
                            Target CR
                        </option>
                        {challengeRatings.map(o => (
                            <option key={o} value={o}>
                                {crToString(o)}
                            </option>
                        ))}
                    </SelectField>
                </FormIndentSection>
            )}

            {filter.environment && (
                <FormIndentSection onRemove={() => onFilterChanged(copyState(filter, { environment: undefined }))}>
                    <TagSelectField
                        label="Environment"
                        hint="One of the creature's environments must match one of these values."
                        required
                        values={Array.isArray(filter.environment) ? filter.environment : [filter.environment]}
                        options={terrainTypes}
                        valueToLabel={v => terrainTypeToString(v as TerrainType)}
                        onValuesChanged={e => {
                            onFilterChanged(copyState(filter, { environment: e as TerrainType[] }));
                        }}
                    />
                </FormIndentSection>
            )}

            {filter.maxSpeed && (
                <FormIndentSection onRemove={() => onFilterChanged(copyState(filter, { maxSpeed: undefined }))}>
                    <SpeedField
                        label="Max speed (ft)"
                        required
                        speed={filter.maxSpeed}
                        onSpeedChanged={e => {
                            onFilterChanged(copyState(filter, { maxSpeed: e }));
                        }}
                    />
                </FormIndentSection>
            )}

            <MotionBox layout justifyContent="flex-start">
                <Select
                    value=""
                    onChange={e => {
                        if (e.target.value) {
                            if (e.target.value === "alignment") {
                                onFilterChanged(copyState(filter, { alignment: [] }));
                            } else if (e.target.value === "creatureType") {
                                onFilterChanged(copyState(filter, { creatureType: [] }));
                            } else if (e.target.value === "environment") {
                                onFilterChanged(copyState(filter, { environment: [] }));
                            } else if (e.target.value === "cr") {
                                onFilterChanged(copyState(filter, { cr: 0 }));
                            } else if (e.target.value === "maxCr") {
                                onFilterChanged(copyState(filter, { maxCr: 0 }));
                            } else if (e.target.value === "minCr") {
                                onFilterChanged(copyState(filter, { minCr: 0 }));
                            } else if (e.target.value === "maxSpeed") {
                                onFilterChanged(copyState(filter, { maxSpeed: {} }));
                            }
                        }
                    }}>
                    <option key="none" value="">
                        - Select a condition -
                    </option>
                    {filter.alignment == null && (
                        <option key="alignment" value="alignment">
                            Alignment
                        </option>
                    )}
                    {filter.creatureType == null && (
                        <option key="creatureType" value="creatureType">
                            Creature type
                        </option>
                    )}
                    {filter.cr == null && (
                        <option key="cr" value="cr">
                            CR
                        </option>
                    )}
                    {filter.maxCr == null && (
                        <option key="maxCr" value="maxCr">
                            Max CR
                        </option>
                    )}
                    {filter.minCr == null && (
                        <option key="minCr" value="minCr">
                            Min CR
                        </option>
                    )}
                    {filter.environment == null && (
                        <option key="environment" value="environment">
                            Environment
                        </option>
                    )}
                    {filter.maxSpeed == null && (
                        <option key="maxSpeed" value="maxSpeed">
                            Max speed
                        </option>
                    )}
                </Select>
            </MotionBox>
        </Box>
    );
};

const endTriggerKeys: (keyof AppliedEffectEndTriggers)[] = ["damage"];

const ApplicableEffectEditor: FunctionComponent<{
    applied: ApplicableAbilityEffect | NamedRuleRef;
    onAppliedChanged: (applied: ApplicableAbilityEffect | NamedRuleRef) => void;
}> = ({ applied, onAppliedChanged }) => {
    const id = useId();

    if (isNamedRuleRef(applied)) {
        // TODO: Implement support for choosing existing applied effects.
        return <Box>Not yet implemented.</Box>;
    }

    return (
        <Box flexGrow={1} flexShrink={1} flexDirection="column" alignItems="stretch" css={{ gap: theme.space[3] }}>
            <MotionBox layout>
                <InputField
                    label="Name"
                    required
                    value={applied.name}
                    onChange={e => {
                        onAppliedChanged(copyState(applied, { name: e.target.value }));
                    }}
                />
            </MotionBox>

            <MotionBox layout>
                <ExpressionDurationField
                    label="Duration"
                    required
                    duration={applied.duration}
                    onDurationChanged={e => {
                        onAppliedChanged(copyState(applied, { duration: e }));
                    }}
                />
            </MotionBox>

            <MotionBox layout>
                <CheckboxField
                    id={id + "_allowMultiple"}
                    required
                    label="Allow multiple"
                    hint="Can more than one instance of this effect apply at once?"
                    checked={!!applied.allowMultiple}
                    onChange={e => {
                        onAppliedChanged(copyState(applied, { allowMultiple: e.target.checked ? true : undefined }));
                    }}
                />
            </MotionBox>

            <MotionBox layout>
                <TagSelectField
                    label="Triggers on"
                    hint="When the effect triggers or when the duration is reduced during combat. If not specified defaults to on use/cast."
                    values={abilityEffectTriggers.filter(o => applied.trigger?.[o])}
                    options={abilityEffectTriggers}
                    placeholder="Select a trigger"
                    valueToLabel={v => {
                        // TODO: Pass in somewhere whether this is a spell or not.
                        return abilityEffectTriggerToString(v as keyof AbilityEffectTriggers, false);
                    }}
                    onValuesChanged={e => {
                        const triggers = Object.assign({}, applied.trigger);
                        for (let trigger of abilityEffectTriggers) {
                            if (e.indexOf(trigger) < 0) {
                                delete triggers[trigger];
                            } else if (!triggers[trigger]) {
                                triggers[trigger] = true;
                            }
                        }

                        onAppliedChanged(copyState(applied, { trigger: triggers }));
                    }}
                />
            </MotionBox>

            {applied.abilityChecks != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { abilityChecks: undefined }))}>
                    <TagSelectField
                        label="Ability checks"
                        hint="Applies the specified modifiers to the specified ability check rolls for the affected creature."
                        selectHint="Select an ability"
                        values={abilityCheckValues.filter(o => applied.abilityChecks![o])}
                        options={abilityCheckValues}
                        valueToLabel={v => {
                            if (v === "initiative") {
                                return "Initiative";
                            } else if (v === "all") {
                                return "All";
                            }

                            return getCoreAbilityLabel(v as CoreAbility);
                        }}
                        valueToContent={v => {
                            return (
                                <RollModifiersEditor
                                    rollModifier={applied.abilityChecks![v]}
                                    onRollModifiersChanged={e => {
                                        const rms = copyState(applied.abilityChecks!, { [v]: e });
                                        onAppliedChanged(copyState(applied, { abilityChecks: rms }));
                                    }}
                                />
                            );
                        }}
                        onValuesChanged={e => {
                            const abilities = Object.assign({}, applied.abilityChecks);
                            for (let coreAbility of abilityCheckValues) {
                                if (e.indexOf(coreAbility) < 0) {
                                    delete abilities[coreAbility];
                                } else if (!abilities[coreAbility]) {
                                    abilities[coreAbility] = {};
                                }
                            }

                            onAppliedChanged(copyState(applied, { abilityChecks: abilities }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.maxHpMultiplier != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { ac: undefined }))}>
                    <InputField
                        label="AC modifier"
                        hint="Increases (or decreases) the creature's AC by the specified amount."
                        type="number"
                        required
                        value={applied.ac ?? ""}
                        onChange={e => {
                            if (isNaN(e.target.valueAsNumber)) {
                                onAppliedChanged(copyState(applied, { ac: 0 }));
                            } else {
                                onAppliedChanged(copyState(applied, { ac: e.target.valueAsNumber }));
                            }
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.attacksBy != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { attacksBy: undefined }))}>
                    <AttackModifiersField
                        label="Attacks by"
                        hint="Modifiers for attacks made by this creature."
                        required
                        attackModifiers={applied.attacksBy}
                        onAttackModifiersChanged={e => {
                            onAppliedChanged(copyState(applied, { attacksBy: e }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.attacksOn != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { attacksOn: undefined }))}>
                    <AttackModifiersField
                        label="Attacks on"
                        hint="Modifiers for attacks made against this creature."
                        required
                        attackModifiers={applied.attacksOn}
                        onAttackModifiersChanged={e => {
                            onAppliedChanged(copyState(applied, { attacksOn: e }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.canTakeActions != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { canTakeActions: undefined }))}>
                    <CheckboxField
                        id={id + "_canTakeActions"}
                        label="Can take actions"
                        required
                        hint="Indicates whether the creature can take actions while this effect is applied."
                        checked={applied.canTakeActions}
                        onChange={e => {
                            onAppliedChanged(copyState(applied, { canTakeActions: e.target.checked }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.canTakeReactions != null && (
                <FormIndentSection
                    onRemove={() => onAppliedChanged(copyState(applied, { canTakeReactions: undefined }))}>
                    <CheckboxField
                        id={id + "_canTakeReactions"}
                        label="Can take reactions"
                        required
                        hint="Indicates whether the creature can take reactions while this effect is applied."
                        checked={applied.canTakeReactions}
                        onChange={e => {
                            onAppliedChanged(copyState(applied, { canTakeReactions: e.target.checked }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.canCastSpells != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { canCastSpells: undefined }))}>
                    <CheckboxField
                        id={id + "_canCastSpells"}
                        label="Can cast spells"
                        required
                        hint="Indicates whether the creature can cast spells while this effect is applied."
                        checked={applied.canCastSpells}
                        onChange={e => {
                            onAppliedChanged(copyState(applied, { canCastSpells: e.target.checked }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.conditions != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { conditions: undefined }))}>
                    <TagSelectField
                        label="Conditions"
                        hint="Applies the specified conditions to the affected creature."
                        selectHint="Select a condition"
                        values={creatureConditions.filter(o => applied.conditions![o])}
                        options={creatureConditions}
                        valueToLabel={v => {
                            return conditionToString(v as CreatureConditionName);
                        }}
                        onValuesChanged={e => {
                            const conditions = Object.assign({}, applied.conditions);
                            for (let condition of creatureConditions) {
                                if (e.indexOf(condition) < 0) {
                                    delete conditions[condition];
                                } else {
                                    conditions[condition] = true;
                                }
                            }

                            onAppliedChanged(copyState(applied, { conditions: conditions }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.endEffects != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { endEffects: undefined }))}>
                    <EffectsField
                        label="Effects executed when applied effect ends"
                        effects={applied.endEffects}
                        onEffectsChanged={e => {
                            onAppliedChanged(copyState(applied, { endEffects: e }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.maxHpMultiplier != null && (
                <FormIndentSection
                    onRemove={() => onAppliedChanged(copyState(applied, { maxHpMultiplier: undefined }))}>
                    <InputField
                        label="Maximum HP (multiplier)"
                        hint="The creature's maximum HP will be multiplied by this number."
                        type="number"
                        min={0}
                        required
                        value={applied.maxHpMultiplier ?? ""}
                        onChange={e => {
                            if (isNaN(e.target.valueAsNumber)) {
                                onAppliedChanged(copyState(applied, { maxHpMultiplier: 0 }));
                            } else {
                                onAppliedChanged(copyState(applied, { maxHpMultiplier: e.target.valueAsNumber }));
                            }
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.resistances != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { resistances: undefined }))}>
                    <TagSelectField
                        label="Resistances"
                        selectHint="Select a damage type"
                        hint="Makes the affected creature resistant to the specified damage types."
                        values={damageTypes.filter(o => applied.resistances![o])}
                        options={damageTypes}
                        valueToLabel={v => {
                            return damageTypeToString(v as DamageType);
                        }}
                        onValuesChanged={e => {
                            const resistances = Object.assign({}, applied.resistances);
                            for (let dt of damageTypes) {
                                if (e.indexOf(dt) < 0) {
                                    delete resistances[dt];
                                } else {
                                    resistances[dt] = true;
                                }
                            }

                            onAppliedChanged(copyState(applied, { resistances: resistances }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.immunities != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { immunities: undefined }))}>
                    <TagSelectField
                        label="Immunities"
                        hint="Makes the affected creature immune to the specified damage types."
                        selectHint="Select a damage type"
                        values={damageTypes.filter(o => applied.immunities![o])}
                        options={damageTypes}
                        valueToLabel={v => {
                            return damageTypeToString(v as DamageType);
                        }}
                        onValuesChanged={e => {
                            const immunities = Object.assign({}, applied.immunities);
                            for (let dt of damageTypes) {
                                if (e.indexOf(dt) < 0) {
                                    delete immunities[dt];
                                } else {
                                    immunities[dt] = true;
                                }
                            }

                            onAppliedChanged(copyState(applied, { immunities: immunities }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.vulnerabilities != null && (
                <FormIndentSection
                    onRemove={() => onAppliedChanged(copyState(applied, { vulnerabilities: undefined }))}>
                    <TagSelectField
                        label="Vulnerabilities"
                        hint="Makes the affected creature vulnerable to the specified damage types."
                        selectHint="Select a damage type"
                        values={damageTypes.filter(o => applied.vulnerabilities![o])}
                        options={damageTypes}
                        valueToLabel={v => {
                            return damageTypeToString(v as DamageType);
                        }}
                        onValuesChanged={e => {
                            const vulnerabilities = Object.assign({}, applied.vulnerabilities);
                            for (let dt of damageTypes) {
                                if (e.indexOf(dt) < 0) {
                                    delete vulnerabilities[dt];
                                } else {
                                    vulnerabilities[dt] = true;
                                }
                            }

                            onAppliedChanged(copyState(applied, { vulnerabilities: vulnerabilities }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.savingThrows != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { savingThrows: undefined }))}>
                    <TagSelectField
                        label="Saving throws"
                        hint="Applies the specified modifiers to the specified saving throw rolls for the affected creature."
                        selectHint="Select an ability"
                        values={savingThrowValues.filter(o => applied.savingThrows![o])}
                        options={savingThrowValues}
                        valueToLabel={v => {
                            if (v === "all") {
                                return "All";
                            }

                            return getCoreAbilityLabel(v as CoreAbility);
                        }}
                        valueToContent={v => {
                            return (
                                <RollModifiersEditor
                                    rollModifier={applied.savingThrows![v]}
                                    onRollModifiersChanged={e => {
                                        const rms = copyState(applied.savingThrows!, { [v]: e });
                                        onAppliedChanged(copyState(applied, { savingThrows: rms }));
                                    }}
                                />
                            );
                        }}
                        onValuesChanged={e => {
                            const abilities = Object.assign({}, applied.savingThrows);
                            for (let coreAbility of savingThrowValues) {
                                if (e.indexOf(coreAbility) < 0) {
                                    delete abilities[coreAbility];
                                } else if (!abilities[coreAbility]) {
                                    abilities[coreAbility] = {};
                                }
                            }

                            onAppliedChanged(copyState(applied, { savingThrows: abilities }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.speedMultiplier != null && (
                <FormIndentSection
                    onRemove={() => onAppliedChanged(copyState(applied, { speedMultiplier: undefined }))}>
                    <InputField
                        label="Speed (multiplier)"
                        hint="The creature's base speed will be multiplied by this number."
                        type="number"
                        min={0}
                        required
                        value={applied.speedMultiplier ?? ""}
                        onChange={e => {
                            if (isNaN(e.target.valueAsNumber)) {
                                onAppliedChanged(copyState(applied, { speedMultiplier: 0 }));
                            } else {
                                onAppliedChanged(copyState(applied, { speedMultiplier: e.target.valueAsNumber }));
                            }
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.moveCost != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { moveCost: undefined }))}>
                    <InputField
                        label="Movement cost"
                        hint="Every 1ft of movement will cost this amount more. For example, if the value is 2, then every 1ft of movement costs 3ft."
                        type="number"
                        required
                        min={0}
                        value={applied.moveCost ?? ""}
                        onChange={e => {
                            if (isNaN(e.target.valueAsNumber)) {
                                onAppliedChanged(copyState(applied, { moveCost: 0 }));
                            } else {
                                onAppliedChanged(copyState(applied, { moveCost: e.target.valueAsNumber }));
                            }
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.unhandled != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { unhandled: undefined }))}>
                    <InputField
                        label="Unhandled"
                        hint="If there are effects that do not fall into any other category, they can be described here. This will have no in game effect other acting as a reminder to players."
                        required
                        min={0}
                        value={applied.unhandled ?? ""}
                        onChange={e => {
                            onAppliedChanged(copyState(applied, { unhandled: e.target.value }));
                        }}
                    />
                </FormIndentSection>
            )}
            {applied.endTriggers != null && (
                <FormIndentSection onRemove={() => onAppliedChanged(copyState(applied, { endTriggers: undefined }))}>
                    <TagSelectField
                        label="End triggers"
                        required
                        values={endTriggerKeys.filter(o => applied.endTriggers![o])}
                        options={endTriggerKeys}
                        valueToLabel={v => {
                            switch (v as keyof AppliedEffectEndTriggers) {
                                case "damage":
                                    return "Damage";
                            }
                        }}
                        onValuesChanged={e => {
                            const endTriggers = Object.assign({}, applied.endTriggers);
                            for (let key of endTriggerKeys) {
                                if (e.indexOf(key) < 0) {
                                    delete endTriggers[key];
                                } else if (!endTriggers[key]) {
                                    endTriggers[key] = true;
                                }
                            }

                            onAppliedChanged(copyState(applied, { endTriggers: endTriggers }));
                        }}
                    />
                </FormIndentSection>
            )}

            <MotionSelect
                layout
                value=""
                onChange={e => {
                    if (e.target.value) {
                        if (e.target.value === "speedMultiplier") {
                            onAppliedChanged(copyState(applied, { speedMultiplier: 1 }));
                        } else if (e.target.value === "moveCost") {
                            onAppliedChanged(copyState(applied, { moveCost: 0 }));
                        } else if (e.target.value === "maxHpMultiplier") {
                            onAppliedChanged(copyState(applied, { maxHpMultiplier: 1 }));
                        } else if (e.target.value === "unhandled") {
                            onAppliedChanged(copyState(applied, { unhandled: "" }));
                        } else if (e.target.value === "canTakeActions") {
                            onAppliedChanged(copyState(applied, { canTakeActions: false }));
                        } else if (e.target.value === "canTakeReactions") {
                            onAppliedChanged(copyState(applied, { canTakeReactions: false }));
                        } else if (e.target.value === "canCastSpells") {
                            onAppliedChanged(copyState(applied, { canCastSpells: false }));
                        } else if (e.target.value === "ac") {
                            onAppliedChanged(copyState(applied, { ac: 0 }));
                        } else if (e.target.value === "endEffects") {
                            onAppliedChanged(copyState(applied, { endEffects: {} }));
                        } else if (e.target.value === "resistances") {
                            onAppliedChanged(copyState(applied, { resistances: {} }));
                        } else if (e.target.value === "immunities") {
                            onAppliedChanged(copyState(applied, { immunities: {} }));
                        } else if (e.target.value === "vulnerabilities") {
                            onAppliedChanged(copyState(applied, { vulnerabilities: {} }));
                        } else if (e.target.value === "conditions") {
                            onAppliedChanged(copyState(applied, { conditions: {} }));
                        } else if (e.target.value === "abilityChecks") {
                            onAppliedChanged(copyState(applied, { abilityChecks: {} }));
                        } else if (e.target.value === "savingThrows") {
                            onAppliedChanged(copyState(applied, { savingThrows: {} }));
                        } else if (e.target.value === "attacksBy") {
                            onAppliedChanged(copyState(applied, { attacksBy: {} }));
                        } else if (e.target.value === "attacksOn") {
                            onAppliedChanged(copyState(applied, { attacksOn: {} }));
                        } else if (e.target.value === "endTriggers") {
                            onAppliedChanged(copyState(applied, { endTriggers: {} }));
                        }
                    }
                }}>
                <option key="none" value="">
                    - Select an effect -
                </option>
                {applied.abilityChecks == null && (
                    <option key="abilityChecks" value="abilityChecks">
                        Ability checks
                    </option>
                )}
                {applied.ac == null && (
                    <option key="ac" value="ac">
                        AC
                    </option>
                )}
                {applied.attacksBy == null && (
                    <option key="attacksBy" value="attacksBy">
                        Attacks by
                    </option>
                )}
                {applied.attacksOn == null && (
                    <option key="attacksOn" value="attacksOn">
                        Attacks on
                    </option>
                )}
                {applied.canTakeActions == null && (
                    <option key="canTakeActions" value="canTakeActions">
                        Can/can't take actions
                    </option>
                )}
                {applied.canTakeReactions == null && (
                    <option key="canTakeReactions" value="canTakeReactions">
                        Can/can't take reactions
                    </option>
                )}
                {applied.canCastSpells == null && (
                    <option key="canCastSpells" value="canCastSpells">
                        Can/can't cast spells
                    </option>
                )}
                {applied.conditions == null && (
                    <option key="conditions" value="conditions">
                        Conditions
                    </option>
                )}
                {applied.endEffects == null && (
                    <option key="endEffects" value="endEffects">
                        End effects
                    </option>
                )}
                {applied.endTriggers == null && (
                    <option key="endTriggers" value="endTriggers">
                        End triggers
                    </option>
                )}
                {applied.maxHpMultiplier == null && (
                    <option key="maxHpMultiplier" value="maxHpMultiplier">
                        Maximum HP (multiplier)
                    </option>
                )}
                {applied.moveCost == null && (
                    <option key="moveCost" value="moveCost">
                        Movement cost
                    </option>
                )}
                {applied.resistances == null && (
                    <option key="resistances" value="resistances">
                        Resistances
                    </option>
                )}
                {applied.immunities == null && (
                    <option key="immunities" value="immunities">
                        Immunities
                    </option>
                )}
                {applied.vulnerabilities == null && (
                    <option key="vulnerabilities" value="vulnerabilities">
                        Vulnerabilities
                    </option>
                )}
                {applied.savingThrows == null && (
                    <option key="savingThrows" value="savingThrows">
                        Saving throws
                    </option>
                )}
                {applied.speedMultiplier == null && (
                    <option key="speedMultiplier" value="speedMultiplier">
                        Speed (multiplier)
                    </option>
                )}
                {applied.unhandled == null && (
                    <option key="unhandled" value="unhandled">
                        Unhandled
                    </option>
                )}
            </MotionSelect>
        </Box>
    );
};

const AppliedEffectField = asField<HTMLDivElement, ExtractProps<typeof ApplicableEffectEditor> & IAsFieldProps>(
    ApplicableEffectEditor
);

const EffectEditor: FunctionComponent<{ effect: AbilityEffect; onEffectChanged: (effect: AbilityEffect) => void }> = ({
    effect,
    onEffectChanged,
}) => {
    const id = useId();

    // TODO: Support setting the targets filter for an effect.
    return (
        <Box flexGrow={1} flexShrink={1} flexDirection="column" alignItems="stretch" css={{ gap: theme.space[3] }}>
            <MotionBox layout>
                <SelectField
                    label="Saving throw"
                    required
                    value={effect.savingThrow ?? ""}
                    onChange={e => {
                        let coreAbility = e.target.value === "" ? undefined : (e.target.value as CoreAbility);
                        onEffectChanged(copyState(effect, { savingThrow: coreAbility }));
                    }}>
                    <option key="none" value="">
                        None
                    </option>
                    {coreAbilities.map(o => {
                        return (
                            <option key={o} value={o}>
                                {getCoreAbilityLabel(o)}
                            </option>
                        );
                    })}
                </SelectField>
            </MotionBox>

            <TargetFilterField
                label="Target filter"
                hint="This effect on applies to creatures matching this filter."
                ability={effect}
                onAbilityChanged={e => onEffectChanged(e as AbilityEffect)}
            />

            {effect.damage && (
                <FormIndentSection onRemove={() => onEffectChanged(copyState(effect, { damage: undefined }))}>
                    <EffectDamageEditor
                        damage={effect.damage}
                        savingThrow={effect.savingThrow}
                        onDamageChanged={damage => {
                            onEffectChanged(copyState(effect, { damage: damage }));
                        }}
                    />
                </FormIndentSection>
            )}
            {effect.heal && (
                <FormIndentSection onRemove={() => onEffectChanged(copyState(effect, { heal: undefined }))}>
                    <InputField
                        label="Healing"
                        required
                        value={effect.heal?.base ?? ""}
                        onChange={e => {
                            if (e.target.value) {
                                onEffectChanged(copyState(effect, { heal: { base: e.target.value } }));
                            } else {
                                onEffectChanged(copyState(effect, { heal: undefined }));
                            }
                        }}
                    />
                </FormIndentSection>
            )}

            {/* TODO: If the ability is targetting creatures, then this becomes irrelevant - in that case damage/healing is ALWAYS rolled per target */}
            {(effect.heal || effect.damage) && (
                <MotionBox layout>
                    <CheckboxField
                        id={id + "_rollPerTarget"}
                        label="Roll per target"
                        required
                        hint="Should damage/healing be rolled separately for each target?"
                        checked={effect.rollPerTarget}
                        onChange={e => {
                            onEffectChanged(copyState(effect, { rollPerTarget: e.target.checked }));
                        }}
                    />
                </MotionBox>
            )}
            {effect.destroy != null && (
                <FormIndentSection onRemove={() => onEffectChanged(copyState(effect, { destroy: undefined }))}>
                    {/* TODO: This doesn't need to be a checkbox - just some text saying that it will destroy. */}
                    <CheckboxField
                        id={id + "_destroy"}
                        label="Destroy"
                        required
                        hint="Immediately reduce the target's HP to 0."
                        checked={effect.destroy}
                        onChange={e => {
                            onEffectChanged(copyState(effect, { destroy: e.target.checked ? true : undefined }));
                        }}
                    />
                </FormIndentSection>
            )}
            {effect.exhaustion != null && (
                <FormIndentSection onRemove={() => onEffectChanged(copyState(effect, { exhaustion: undefined }))}>
                    <InputField
                        type="number"
                        label="Exhaustion"
                        required
                        hint="Add the specified levels of exhaustion."
                        value={effect.exhaustion ?? ""}
                        onChange={e => {
                            if (isNaN(e.target.valueAsNumber)) {
                                onEffectChanged(copyState(effect, { exhaustion: 0 }));
                            } else {
                                onEffectChanged(copyState(effect, { exhaustion: Math.round(e.target.valueAsNumber) }));
                            }
                        }}
                    />
                </FormIndentSection>
            )}
            {effect.addMovement != null && (
                <FormIndentSection onRemove={() => onEffectChanged(copyState(effect, { addMovement: undefined }))}>
                    <InputField
                        type="number"
                        label="Movement (multiplier)"
                        required
                        hint="Add the specified amount of movement to the creature's current turn as a multipler. For example, 0.5 will add half the creature's normal movement speed."
                        value={effect.addMovement ?? ""}
                        onChange={e => {
                            if (isNaN(e.target.valueAsNumber)) {
                                onEffectChanged(copyState(effect, { addMovement: 0 }));
                            } else {
                                onEffectChanged(copyState(effect, { addMovement: e.target.valueAsNumber }));
                            }
                        }}
                    />
                </FormIndentSection>
            )}
            {effect.applied != null && (
                <FormIndentSection onRemove={() => onEffectChanged(copyState(effect, { applied: undefined }))}>
                    <AppliedEffectField
                        label="Applied effect"
                        applied={effect.applied}
                        onAppliedChanged={e => {
                            onEffectChanged(copyState(effect, { applied: e }));
                        }}
                    />
                </FormIndentSection>
            )}

            <MotionSelect
                layout
                onChange={e => {
                    if (e.target.value) {
                        if (e.target.value === "damage") {
                            onEffectChanged(copyState(effect, { damage: {} }));
                        } else if (e.target.value === "healing") {
                            onEffectChanged(copyState(effect, { heal: { base: "" } }));
                        } else if (e.target.value === "destroy") {
                            onEffectChanged(copyState(effect, { destroy: true }));
                        } else if (e.target.value === "exhaustion") {
                            onEffectChanged(copyState(effect, { exhaustion: 1 }));
                        } else if (e.target.value === "addMovement") {
                            onEffectChanged(copyState(effect, { addMovement: 1 }));
                        } else if (e.target.value === "applied") {
                            onEffectChanged(copyState(effect, { applied: { name: "New effect" } }));
                        }
                    }
                }}>
                <option key="none" value="">
                    - Select an effect -
                </option>
                {effect.damage == null && effect.heal == null && (
                    <option key="damage" value="damage">
                        Damage
                    </option>
                )}
                {effect.damage == null && effect.heal == null && (
                    <option key="healing" value="healing">
                        Healing
                    </option>
                )}
                {effect.destroy == null && (
                    <option key="destroy" value="destroy">
                        Destroy
                    </option>
                )}
                {effect.exhaustion == null && (
                    <option key="exhaustion" value="exhaustion">
                        Exhaustion
                    </option>
                )}
                {effect.addMovement == null && (
                    <option key="addMovement" value="addMovement">
                        Movement (multiplier)
                    </option>
                )}
                {effect.applied == null && (
                    <option key="applied" value="applied">
                        Apply effect(s)
                    </option>
                )}
            </MotionSelect>
        </Box>
    );
};

const EffectsEditor = React.forwardRef<
    HTMLDivElement,
    { effects?: KeyedList<AbilityEffect>; onEffectsChanged: (effects: KeyedList<AbilityEffect>) => void }
>(({ effects, onEffectsChanged }, ref) => {
    const effectKeys = keyedListToKeyArray(effects) ?? [];

    return (
        <AnimatedList ref={ref} alignItems="stretch" p={0}>
            {effectKeys.length === 0 && (
                <AnimatedListItem key="noeffects" justifyContent="flex-start">
                    <Text fontStyle="italic" color="grayscale.2">
                        The ability does not have any effects.
                    </Text>
                </AnimatedListItem>
            )}

            {effectKeys.map(o => {
                const effect = effects![o];
                return (
                    <AnimatedListItem key={o} alignItems="flex-start">
                        <FormIndent
                            onRemove={() => {
                                const newEffects = Object.assign({}, effects);
                                delete newEffects[o];
                                onEffectsChanged(newEffects);
                            }}
                        />
                        <EffectEditor
                            effect={effect}
                            onEffectChanged={e => {
                                const newEffects = copyState(effects ?? {}, { [o]: e });
                                onEffectsChanged(newEffects);
                            }}
                        />
                    </AnimatedListItem>
                );
            })}

            <MotionBox key="addeffect" layout alignSelf="flex-start">
                <Button
                    onClick={() => {
                        const newEffects = addToKeyedList(effects, {});
                        onEffectsChanged(newEffects);
                    }}>
                    Add an effect
                </Button>
            </MotionBox>
        </AnimatedList>
    );
});

const EffectsField = asField<HTMLDivElement, ExtractProps<typeof EffectsEditor> & IAsFieldProps>(EffectsEditor);

const AttackModifierConditionDistanceEditor: FunctionComponent<{
    condition: AttackModifiersCondition;
    onConditionChanged: (condition: AttackModifiersCondition) => void;
}> = ({ condition, onConditionChanged }) => {
    return (
        <Grid gridTemplateColumns="auto auto" gridTemplateRows="auto auto">
            <Select
                value={condition.maxExclusive ? "maxExclusive" : "max"}
                onChange={e => {
                    const isExclusive = e.target.value === "max" ? undefined : true;
                    onConditionChanged(copyState(condition, { maxExclusive: isExclusive }));
                }}>
                <option value="max">Less than or equal to</option>
                <option value="maxExclusive">Less than</option>
            </Select>
            <Input
                type="number"
                value={condition.maxDistance ?? ""}
                onChange={e => {
                    const maxDistance = isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber;
                    onConditionChanged(copyState(condition, { maxDistance: maxDistance }));
                }}
            />
            <Select
                value={condition.minExclusive ? "minExclusive" : "min"}
                onChange={e => {
                    const isExclusive = e.target.value === "min" ? undefined : true;
                    onConditionChanged(copyState(condition, { minExclusive: isExclusive }));
                }}>
                <option value="min">Greater than or equal to</option>
                <option value="minExclusive">Greater than</option>
            </Select>
            <Input
                type="number"
                value={condition.minDistance ?? ""}
                onChange={e => {
                    const minDistance = isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber;
                    onConditionChanged(copyState(condition, { minDistance: minDistance }));
                }}
            />
        </Grid>
    );
};

const AttackModifierConditionDistanceField = asField<
    HTMLDivElement,
    ExtractProps<typeof AttackModifierConditionDistanceEditor> & IAsFieldProps
>(AttackModifierConditionDistanceEditor);

const AttackModifierConditionEditor: FunctionComponent<{
    condition: AttackModifiersCondition;
    onConditionChanged: (condition: AttackModifiersCondition) => void;
}> = ({ condition, onConditionChanged }) => {
    return (
        <Box flexDirection="column" alignItems="stretch">
            <InputField
                mb={2}
                label="Special"
                value={condition.special ?? ""}
                onChange={e => {
                    const special = e.target.value ? e.target.value : undefined;
                    onConditionChanged(copyState(condition, { special: special }));
                }}
            />

            <SelectField
                mb={2}
                label="Attack type"
                value={condition.type ?? ""}
                onChange={e => {
                    const attackType = e.target.value ? (e.target.value as AttackType) : undefined;
                    onConditionChanged(copyState(condition, { type: attackType }));
                }}>
                <option value="">- Select an attack type -</option>
                <option value="mw">Melee weapon attack</option>
                <option value="rw">Ranged weapon attack</option>
                <option value="ms">Melee spell attack</option>
                <option value="rs">Ranged spell attack</option>
                <option value="mw,rw">Melee or ranged weapon attack</option>
                <option value="ms,rs">Melee or ranged spell attack</option>
            </SelectField>

            <SelectField
                mb={2}
                label="Ability"
                value={condition.ability ?? ""}
                onChange={e => {
                    const ability = e.target.value ? (e.target.value as "strength" | "dexterity") : undefined;
                    onConditionChanged(copyState(condition, { ability: ability }));
                }}>
                <option value="">- Select an ability -</option>
                <option value="strength">Strength</option>
                <option value="dexterity">Dexterity</option>
            </SelectField>

            <AttackModifierConditionDistanceField
                label="Distance (ft)"
                condition={condition}
                onConditionChanged={onConditionChanged}
            />
        </Box>
    );
};

const AttackModifierConditionEditorEditor: FunctionComponent<{
    attackModifier: AttackModifiers;
    onAttackModifierChanged: (attackModifier: AttackModifiers) => void;
}> = ({ attackModifier, onAttackModifierChanged }) => {
    return (
        <AnimatePresence>
            {attackModifier.condition && (
                <FormIndentSection
                    onRemove={() => {
                        onAttackModifierChanged(copyState(attackModifier, { condition: undefined }));
                    }}>
                    <AttackModifierConditionEditor
                        condition={attackModifier.condition}
                        onConditionChanged={e => {
                            onAttackModifierChanged(copyState(attackModifier, { condition: e }));
                        }}
                    />
                </FormIndentSection>
            )}
            {!attackModifier.condition && (
                <MotionBox
                    initial={defaultInitial}
                    animate={defaultAnimate}
                    exit={defaultExit}
                    justifyContent="flex-start">
                    <Button
                        onClick={e => {
                            onAttackModifierChanged(copyState(attackModifier, { condition: {} }));
                        }}>
                        Add a condition
                    </Button>
                </MotionBox>
            )}
        </AnimatePresence>
    );
};

const AttackModifierConditionField = asField<
    HTMLDivElement,
    ExtractProps<typeof AttackModifierConditionEditorEditor> & IAsFieldProps
>(AttackModifierConditionEditorEditor);

const attackModifierDamageTypes = [...damageTypes, "weapon"];
const AttackModifierEditor: FunctionComponent<{
    attackModifier: AttackModifiers;
    onAttackModifierChanged: (attackModifier: AttackModifiers) => void;
}> = ({ attackModifier, onAttackModifierChanged }) => {
    const id = useId();

    const damageKeys = attackModifierDamageTypes.filter(o => attackModifier.damage?.[o] != null);

    return (
        <Box flexDirection="column" alignItems="stretch" css={{ gap: theme.space[2] }} flexGrow={1}>
            <AnimatePresence>
                {damageKeys.map(o => (
                    <FormIndentSection
                        key={o}
                        onRemove={() => {
                            const newDamage = copyState(attackModifier.damage ?? {}, { [o]: undefined });
                            onAttackModifierChanged(copyState(attackModifier, { damage: newDamage }));
                        }}>
                        <InputField
                            label={o === "weapon" ? "Weapon" : damageTypeToString(o as DamageType)}
                            value={attackModifier.damage?.[o]}
                            onChange={e => {
                                const newDamage = copyState(attackModifier.damage!, { [o]: e.target.value });
                                onAttackModifierChanged(copyState(attackModifier, { damage: newDamage }));
                            }}
                        />
                    </FormIndentSection>
                ))}
            </AnimatePresence>
            <MotionBox layout justifyContent="flex-start">
                <Select
                    value=""
                    onChange={e => {
                        if (e.target.value) {
                            if (e.target.value === "weapon") {
                                const newDamage = copyState(attackModifier.damage ?? {}, { weapon: "" });
                                onAttackModifierChanged(copyState(attackModifier, { damage: newDamage }));
                            } else {
                                const newDamage = copyState(attackModifier.damage ?? {}, { [e.target.value]: "" });
                                onAttackModifierChanged(copyState(attackModifier, { damage: newDamage }));
                            }
                        }
                    }}>
                    <option key="__" value="">
                        - Select a damage type -
                    </option>
                    <option key="weapon" value="weapon">
                        Weapon
                    </option>
                    {damageTypes.map(o => (
                        <option key={o} value={o}>
                            {damageTypeToString(o)}
                        </option>
                    ))}
                </Select>
            </MotionBox>

            <MotionBox layout justifyContent="flex-start">
                <CheckboxField
                    id={id + "_isAutoCrit"}
                    label="Auto crit"
                    hint="If checked, attacks will be an automatic critical hit."
                    checked={!!attackModifier.crit}
                    onChange={e => {
                        onAttackModifierChanged(
                            copyState(attackModifier, { crit: e.target.checked ? true : undefined })
                        );
                    }}
                />
            </MotionBox>

            <RollModifiersBaseEditor
                rollModifier={attackModifier}
                onRollModifiersChanged={e => onAttackModifierChanged(e)}
            />

            <MotionBox layout="position">
                <AttackModifierConditionField
                    label="Condition"
                    attackModifier={attackModifier}
                    onAttackModifierChanged={onAttackModifierChanged}
                />
            </MotionBox>
        </Box>
    );
};

const AttackModifiersEditor = React.forwardRef<
    HTMLDivElement,
    {
        attackModifiers?: KeyedList<AttackModifiers>;
        onAttackModifiersChanged: (attackModifiers: KeyedList<AttackModifiers>) => void;
    }
>(({ attackModifiers, onAttackModifiersChanged }, ref) => {
    const keys = keyedListToKeyArray(attackModifiers) ?? [];

    return (
        <AnimatedList ref={ref} alignItems="stretch" p={0}>
            {keys.length === 0 && (
                <AnimatedListItem key="noeffects" justifyContent="flex-start">
                    <Text fontStyle="italic" color="grayscale.2">
                        The effect does not have any attack modifiers.
                    </Text>
                </AnimatedListItem>
            )}

            {keys.map(o => {
                const attackModifier = attackModifiers![o];
                return (
                    <AnimatedListItem key={o} alignItems="flex-start" justifyContent="stretch">
                        <FormIndent
                            onRemove={() => {
                                const newAttackModifiers = Object.assign({}, attackModifiers);
                                delete newAttackModifiers[o];
                                onAttackModifiersChanged(newAttackModifiers);
                            }}
                        />
                        <AttackModifierEditor
                            attackModifier={attackModifier}
                            onAttackModifierChanged={e => {
                                const newAttackModifiers = copyState(attackModifiers ?? {}, { [o]: e });
                                onAttackModifiersChanged(newAttackModifiers);
                            }}
                        />
                    </AnimatedListItem>
                );
            })}

            <MotionBox key="addattackmodifier" layout alignSelf="flex-start">
                <Button
                    onClick={() => {
                        const newAttackModifiers = addToKeyedList(attackModifiers, {});
                        onAttackModifiersChanged(newAttackModifiers);
                    }}>
                    Add an attack modifier
                </Button>
            </MotionBox>
        </AnimatedList>
    );
});

const AttackModifiersField = asField<HTMLDivElement, ExtractProps<typeof AttackModifiersEditor> & IAsFieldProps>(
    AttackModifiersEditor
);

const AreaOfEffectEditor = React.forwardRef<
    HTMLDivElement,
    { aoe?: AreaOfEffect; onAoeChanged: (aoe: AreaOfEffect | undefined) => void }
>(({ aoe, onAoeChanged }, ref) => {
    return (
        <Box flexDirection="column" alignItems="stretch">
            <Select
                value={aoe?.type}
                onChange={e => {
                    if (e.target.value) {
                        const aoeType = e.target.value as AreaOfEffectType;
                        // TODO: Really if we're switching types here we should just create a brand new AOE rather than
                        // copying the old one, with the appropriate properties set. (i.e. switching to sphere then supply
                        // a default radius, possibly based on old length or whatever)
                        onAoeChanged(aoe ? copyState(aoe, { type: aoeType }) : { type: aoeType });
                    } else {
                        onAoeChanged(undefined);
                    }
                }}>
                <option value="">- Select area of effect -</option>
                <option value="creature">Creature</option>
                <option value="cone">Cone</option>
                <option value="cube">Cube</option>
                <option value="cylinder">Cylinder</option>
                <option value="line">Line</option>
                <option value="sphere">Sphere</option>
                <option value="square">Square</option>
            </Select>

            <Box flexDirection="row" alignItems="stretch" mt={2}>
                <FormIndent />
                <Box flexDirection="column" flexGrow={1}>
                    {aoe?.type === "cone" && (
                        <React.Fragment>
                            <InputField
                                type="number"
                                label="Length (ft)"
                                required
                                value={(aoe as ConeAreaOfEffect).length ?? ""}
                                onChange={e => {
                                    const length = isNaN(e.target.valueAsNumber) ? 5 : e.target.valueAsNumber;
                                    onAoeChanged(copyState(aoe as ConeAreaOfEffect, { length: length }));
                                }}
                            />
                        </React.Fragment>
                    )}

                    {aoe?.type === "cube" && (
                        <React.Fragment>
                            <InputField
                                type="number"
                                label="Length (ft)"
                                required
                                value={(aoe as CubeAreaOfEffect).length ?? ""}
                                onChange={e => {
                                    const length = isNaN(e.target.valueAsNumber) ? 5 : e.target.valueAsNumber;
                                    onAoeChanged(copyState(aoe as CubeAreaOfEffect, { length: length }));
                                }}
                            />
                        </React.Fragment>
                    )}

                    {aoe?.type === "square" && (
                        <React.Fragment>
                            <InputField
                                type="number"
                                label="Length (ft)"
                                required
                                value={(aoe as SquareAreaOfEffect).length ?? ""}
                                onChange={e => {
                                    const length = isNaN(e.target.valueAsNumber) ? 5 : e.target.valueAsNumber;
                                    onAoeChanged(copyState(aoe as SquareAreaOfEffect, { length: length }));
                                }}
                            />
                        </React.Fragment>
                    )}

                    {aoe?.type === "cylinder" && (
                        <React.Fragment>
                            <InputField
                                type="number"
                                label="Radius (ft)"
                                required
                                value={(aoe as CylinderAreaOfEffect).radius ?? ""}
                                onChange={e => {
                                    const radius = isNaN(e.target.valueAsNumber) ? 5 : e.target.valueAsNumber;
                                    onAoeChanged(copyState(aoe as CylinderAreaOfEffect, { radius: radius }));
                                }}
                            />
                            <InputField
                                type="number"
                                label="Height (ft)"
                                value={(aoe as CylinderAreaOfEffect).height ?? ""}
                                onChange={e => {
                                    const height = isNaN(e.target.valueAsNumber) ? 5 : e.target.valueAsNumber;
                                    onAoeChanged(copyState(aoe as CylinderAreaOfEffect, { height: height }));
                                }}
                            />
                        </React.Fragment>
                    )}

                    {aoe?.type === "line" && (
                        <React.Fragment>
                            <InputField
                                type="number"
                                label="Length (ft)"
                                required
                                value={(aoe as LineAreaOfEffect).length ?? ""}
                                onChange={e => {
                                    const length = isNaN(e.target.valueAsNumber) ? 5 : e.target.valueAsNumber;
                                    onAoeChanged(copyState(aoe as LineAreaOfEffect, { length: length }));
                                }}
                            />
                            <InputField
                                type="number"
                                label="Width (ft)"
                                required
                                value={(aoe as LineAreaOfEffect).width ?? ""}
                                onChange={e => {
                                    const width = isNaN(e.target.valueAsNumber) ? 5 : e.target.valueAsNumber;
                                    onAoeChanged(copyState(aoe as LineAreaOfEffect, { width: width }));
                                }}
                            />
                        </React.Fragment>
                    )}

                    {aoe?.type === "sphere" && (
                        <React.Fragment>
                            <InputField
                                type="number"
                                label="Radius (ft)"
                                required
                                value={(aoe as SphereAreaOfEffect).radius ?? ""}
                                onChange={e => {
                                    const radius = isNaN(e.target.valueAsNumber) ? 5 : e.target.valueAsNumber;
                                    onAoeChanged(copyState(aoe as SphereAreaOfEffect, { radius: radius }));
                                }}
                            />
                        </React.Fragment>
                    )}

                    {aoe?.type === "creature" && (
                        <React.Fragment>
                            <InputField
                                type="number"
                                label="Targets"
                                required
                                value={(aoe as CreatureAreaOfEffect).amount ?? ""}
                                onChange={e => {
                                    const amount = isNaN(e.target.valueAsNumber) ? 1 : e.target.valueAsNumber;
                                    onAoeChanged(copyState(aoe as CreatureAreaOfEffect, { amount: amount }));
                                }}
                            />
                            {/* TODO: Extra targets when cast at a higher level */}
                        </React.Fragment>
                    )}
                </Box>
            </Box>
        </Box>
    );
});

const AreaOfEffectField = asField<HTMLDivElement, ExtractProps<typeof AreaOfEffectEditor> & IAsFieldProps>(
    AreaOfEffectEditor
);

const AbilityRangeEditor = React.forwardRef<
    HTMLDivElement,
    { ability: Ability; onAbilityChanged: (ability: Ability) => void }
>(({ ability, onAbilityChanged }, ref) => {
    return (
        <Box flexDirection="row" ref={ref} justifyContent="flex-start">
            <Select
                value={typeof ability.range === "number" ? "ft" : ability.range ?? ""}
                onChange={e => {
                    if (e.target.value === "ft") {
                        onAbilityChanged(copyState(ability, { range: 5 }));
                    } else if (e.target.value === "") {
                        onAbilityChanged(copyState(ability, { range: undefined }));
                    } else {
                        onAbilityChanged(copyState(ability, { range: e.target.value as AbilityRange }));
                    }
                }}>
                <option value="">- Select range -</option>
                <option value="ft">Distance in ft.</option>
                <option value="touch">Touch</option>
                <option value="self">Self</option>
                <option value="sight">Sight</option>
                <option value="unlimited">Unlimited</option>
                <option value="special">Special</option>
            </Select>
            <Input
                type="number"
                value={typeof ability.range === "number" ? ability.range : ""}
                min={0}
                ml={1}
                disabled={typeof ability.range !== "number"}
                onChange={e => {
                    const value = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
                    onAbilityChanged(copyState(ability, { range: value }));
                }}
            />
        </Box>
    );
});

const AbilityRangeField = asField<HTMLDivElement, ExtractProps<typeof AbilityRangeEditor> & IAsFieldProps>(
    AbilityRangeEditor
);

const MonsterMultiattackPartAbilityPicker = React.forwardRef<
    HTMLDivElement,
    {
        attackPart: MonsterMultiattackPart;
        onAttackPartChanged: (attackPart: MonsterMultiattackPart) => void;
        monster: Monster;
    }
>(({ attackPart, onAttackPartChanged, monster }, ref) => {
    const option: "byAbility" | "byType" = attackPart.abilities
        ? "byAbility"
        : attackPart.type != null
        ? "byType"
        : "byAbility";
    const abilities = keyedListToArray(monster.abilities);
    return (
        <MotionBox layout="position" flexDirection="column" alignItems="stretch" ref={ref}>
            <Select
                mb={1}
                value={option}
                onChange={e => {
                    if (e.target.value === "byAbility") {
                        onAttackPartChanged(copyState(attackPart, { abilities: [], type: undefined }));
                    } else if (e.target.value === "byType") {
                        onAttackPartChanged(copyState(attackPart, { abilities: undefined, type: "mw" }));
                    }
                }}>
                <option value="byAbility">Select specific abilities</option>
                <option value="byType">Select an attack type</option>
            </Select>

            {option === "byType" && (
                <Box justifyContent="flex-start">
                    <Select
                        value={attackPart.type ?? "mw"}
                        onChange={e => {
                            onAttackPartChanged(copyState(attackPart, { type: e.target.value as SingleAttackType }));
                        }}>
                        <option value="mw">{attackTypeToString("mw")}</option>
                        <option value="rw">{attackTypeToString("rw")}</option>
                        <option value="ms">{attackTypeToString("ms")}</option>
                        <option value="rs">{attackTypeToString("rs")}</option>
                    </Select>
                </Box>
            )}
            {option === "byAbility" && (
                <Box justifyContent="flex-start">
                    <TagSelect
                        values={attackPart.abilities ?? []}
                        options={abilities
                            ?.filter(ability => {
                                return !ability.attackOptions && ability.isAttackAction;
                            })
                            .map(o => o.name)}
                        onValuesChanged={e => {
                            onAttackPartChanged(copyState(attackPart, { abilities: e }));
                        }}
                    />
                </Box>
            )}
        </MotionBox>
    );
});
const MonsterMultiattackPartEditor = React.forwardRef<
    HTMLDivElement,
    {
        attackPart: MonsterMultiattackPart;
        onAttackPartChanged: (attackPart: MonsterMultiattackPart) => void;
        monster: Monster;
    }
>(({ attackPart, onAttackPartChanged, monster }, ref) => {
    return (
        <Box flexDirection="column" alignItems="stretch" ref={ref}>
            <MonsterMultiattackPartAbilityPicker
                attackPart={attackPart}
                onAttackPartChanged={onAttackPartChanged}
                monster={monster}
            />

            <InputField
                label="Amount"
                placeholder="1"
                type="number"
                value={attackPart.amount ?? ""}
                onChange={e => {
                    const amount = isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber;
                    onAttackPartChanged(copyState(attackPart, { amount: amount }));
                }}
            />
        </Box>
    );
});

const AttackOptionEditor = React.forwardRef<
    HTMLDivElement,
    {
        attackOption: KeyedList<MonsterMultiattackPart>;
        onAttackOptionChanged: (attackOptionGroup: KeyedList<MonsterMultiattackPart>) => void;
        monster: Monster;
    }
>(({ attackOption, onAttackOptionChanged, monster }, ref) => {
    return (
        <LobotomizedBox ref={ref} flexDirection="column" fullWidth alignItems="flex-start">
            <AnimatePresence>
                {mapKeyedList(attackOption, (o, i, k) => (
                    <FormIndentSection
                        key={k}
                        onRemove={() => onAttackOptionChanged(copyState(attackOption, { [k]: undefined }))}>
                        <MonsterMultiattackPartEditor
                            attackPart={o}
                            onAttackPartChanged={ap => {
                                var newList = copyState(attackOption ?? {}, { [k]: ap });
                                onAttackOptionChanged(newList);
                            }}
                            monster={monster}
                        />
                    </FormIndentSection>
                ))}
            </AnimatePresence>

            <MotionBox layout>
                <Button
                    onClick={() => {
                        var newList = addToKeyedList(attackOption ?? {}, {});
                        onAttackOptionChanged(newList);
                    }}>
                    Add an attack part
                </Button>
            </MotionBox>
        </LobotomizedBox>
    );
});

const AttackOptionsEditor = React.forwardRef<
    HTMLDivElement,
    {
        attackOptions?: KeyedList<KeyedList<MonsterMultiattackPart>>;
        onAttackOptionsChanged: (attackOptions?: KeyedList<KeyedList<MonsterMultiattackPart>>) => void;
        monster: Monster;
    }
>(({ attackOptions, onAttackOptionsChanged, monster }, ref) => {
    return (
        <LobotomizedBox ref={ref} flexDirection="column" fullWidth alignItems="flex-start">
            <AnimatePresence>
                {mapKeyedList(attackOptions, (o, i, k) => (
                    <FormIndentSection
                        key={k}
                        onRemove={() => {
                            const newAttackOptions = copyState(attackOptions ?? {}, { [k]: undefined });
                            if (Object.keys(newAttackOptions).length === 0) {
                                onAttackOptionsChanged();
                            } else {
                                onAttackOptionsChanged(newAttackOptions);
                            }
                        }}>
                        <AttackOptionEditor
                            attackOption={o}
                            onAttackOptionChanged={ao => {
                                var newList = copyState(attackOptions ?? {}, { [k]: ao });
                                onAttackOptionsChanged(newList);
                            }}
                            monster={monster}
                        />
                    </FormIndentSection>
                ))}
            </AnimatePresence>

            <MotionBox layout>
                <Button
                    onClick={() => {
                        var newList = addToKeyedList(attackOptions ?? {}, {});
                        onAttackOptionsChanged(newList);
                    }}>
                    Add an attack option
                </Button>
            </MotionBox>
        </LobotomizedBox>
    );
});

const AttackOptionsField = asField<HTMLDivElement, ExtractProps<typeof AttackOptionsEditor> & IAsFieldProps>(
    AttackOptionsEditor
);

const TargetFilterEditor = React.forwardRef<
    HTMLDivElement,
    {
        ability: Ability | AbilityEffect;
        onAbilityChanged: (ability: Ability | AbilityEffect) => void;
    }
>(({ ability, onAbilityChanged }, ref) => {
    return (
        <Box ref={ref} justifyContent="flex-start">
            <AnimatePresence>
                {ability.targetFilter && (
                    <FormIndentSection
                        onRemove={() => onAbilityChanged(copyState(ability, { targetFilter: undefined }))}>
                        <MonsterFilterEditor
                            filter={ability.targetFilter}
                            onFilterChanged={filter => {
                                onAbilityChanged(copyState(ability, { targetFilter: filter }));
                            }}
                        />
                    </FormIndentSection>
                )}
                {!ability.targetFilter && (
                    <MotionBox
                        layout
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        justifyContent="flex-start">
                        <Button onClick={() => onAbilityChanged(copyState(ability, { targetFilter: {} }))}>
                            Add a filter
                        </Button>
                    </MotionBox>
                )}
            </AnimatePresence>
        </Box>
    );
});

const TargetFilterField = asField<HTMLDivElement, ExtractProps<typeof TargetFilterEditor> & IAsFieldProps>(
    TargetFilterEditor
);

const AbilityEditor = React.forwardRef<
    HTMLFormElement,
    {
        ability: Ability;
        onAbilityChanged: (ability: Ability) => void;
        onInit?: () => void;
        creature: Monster | Character;
    }
>(({ ability, onAbilityChanged, onInit, creature }, ref) => {
    const id = useId();

    return (
        <Form ref={ref} flexDirection="column" alignItems="flex-start" flexShrink={1} flexGrow={1} density="compact">
            <NamedContentEditor
                fullWidth
                name={ability.name}
                content={ability.content}
                onNameChanged={name => {
                    onAbilityChanged({ ...ability, name: name });
                }}
                onContentChanged={content => {
                    onAbilityChanged({ ...ability, content: content });
                }}
                onInit={onInit}
            />

            <AbilityTimeField label="Time cost" ability={ability} onAbilityChanged={onAbilityChanged} />

            <SelectField
                label="Attack type"
                hint="The type of attack (if any) that must succeed before any damage or other effects are applied."
                value={ability.attack ?? ""}
                onChange={e => {
                    const attackType = e.target.value ? (e.target.value as AttackType) : undefined;
                    onAbilityChanged(copyState(ability, { attack: attackType }));
                }}>
                <option value="">None</option>
                <option value="mw">Melee weapon attack</option>
                <option value="rw">Ranged weapon attack</option>
                <option value="ms">Melee spell attack</option>
                <option value="rs">Ranged spell attack</option>
                <option value="mw,rw">Melee or ranged weapon attack</option>
                <option value="ms,rs">Melee or ranged spell attack</option>
            </SelectField>

            {isMonster(creature) && (
                <React.Fragment>
                    {/* TODO: Should we be hiding a bunch of other stuff if this is chosen? Do we still allow other effects, etc? Other costs, duration? */}
                    <AttackOptionsField
                        label="Multiattack options"
                        attackOptions={(ability as MonsterAbility).attackOptions}
                        onAttackOptionsChanged={attackOptions => {
                            onAbilityChanged(copyState(ability as MonsterAbility, { attackOptions: attackOptions }));
                        }}
                        monster={creature}
                    />

                    <InputField
                        type="number"
                        label="Recharge on"
                        hint="If specified, once this ability is used the creature must roll this value or above on a 1d6 at the start of their turn before they can use it again."
                        min={1}
                        max={6}
                        value={(ability as MonsterAbility).rechargeOn}
                        onChange={e => {
                            const value = isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber;
                            onAbilityChanged(copyState(ability as MonsterAbility, { rechargeOn: value }));
                        }}
                    />
                </React.Fragment>
            )}

            {/* Only abilities without a set time cost can be part of an attack action. */}
            {!ability.time && !(ability as MonsterAbility).attackOptions && (
                <CheckboxField
                    css={{ marginTop: theme.space[2] }}
                    id={id + "_isAttackAction"}
                    label="Attack action"
                    hint="If checked, this ability can be included in a multi-attack action."
                    checked={!!ability.isAttackAction}
                    onChange={e => {
                        onAbilityChanged(copyState(ability, { isAttackAction: e.target.checked ? true : undefined }));
                    }}
                />
            )}

            {/* TODO: Resource cost (probably not relevant for monsters?) */}

            <AreaOfEffectField
                label="Area of effect"
                aoe={ability.aoe}
                onAoeChanged={aoe => {
                    onAbilityChanged(copyState(ability, { aoe: aoe }));
                }}
            />

            <AbilityDurationField
                label="Duration"
                duration={ability.duration}
                onDurationChanged={duration => {
                    onAbilityChanged(copyState(ability, { duration: duration }));
                }}
            />

            {ability.attack != null && (
                <InputField
                    type="number"
                    label="Attack modifier"
                    hint="The bonus to add to attack rolls for this ability."
                    value={ability.attackModifier ?? ""}
                    onChange={e => {
                        const attackModifier = isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber;
                        onAbilityChanged(copyState(ability, { attackModifier: attackModifier }));
                    }}
                />
            )}

            <AbilityRangeField label="Range" ability={ability} onAbilityChanged={onAbilityChanged} />

            {(ability.attack === "mw" ||
                ability.attack === "ms" ||
                ability.attack === "mw,rw" ||
                ability.attack === "ms,rs") && (
                <InputField
                    type="number"
                    label="Reach (ft)"
                    hint="The maximum distance this attack can reach, in feet."
                    value={ability.reach ?? ""}
                    onChange={e => {
                        const reach = isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber;
                        onAbilityChanged(copyState(ability, { reach: reach }));
                    }}
                />
            )}

            {(ability.attack === "rw" || ability.attack === "mw,rw") && (
                <InputField
                    type="number"
                    label="Near range (ft)"
                    hint="The range beyond which attacks are made at disadvantage, in feet."
                    value={ability.rangeNear ?? ""}
                    onChange={e => {
                        const rangeNear = isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber;
                        onAbilityChanged(copyState(ability, { rangeNear: rangeNear }));
                    }}
                />
            )}

            <TargetFilterField
                label="Target filter"
                hint="Only creatures matching the specified filter may be targetted. If the ability affects an area, only creatures matching the filter will be affected."
                ability={ability}
                onAbilityChanged={e => onAbilityChanged(e as Ability)}
            />

            <EffectsField
                label="Effects"
                effects={ability.effects}
                onEffectsChanged={effects => {
                    onAbilityChanged(copyState(ability, { effects: effects }));
                }}
            />
        </Form>
    );
});

const AbilityCollectionEditor = React.forwardRef<
    HTMLDivElement,
    {
        abilities?: KeyedList<Ability>;
        onAbilitiesChanged: (dt: KeyedList<Ability>) => void;
        creature: Monster | Character;
    }
>(({ abilities, onAbilitiesChanged, creature }, ref) => {
    const forceUpdate = useForceUpdate();
    return (
        <LobotomizedBox ref={ref} flexDirection="column" fullWidth alignItems="flex-start">
            <AnimatePresence>
                {mapKeyedList<Ability, JSX.Element>(abilities, (o, i, k) => (
                    <AnimatedListItem key={k} index={i}>
                        <FeatureExpander
                            isInOverlay
                            layout="position" // Markdown editors cause scale layouts to glitch because they update the UI outside react renders.
                            title={o.name}
                            header={
                                <Button
                                    mr={2}
                                    size="s"
                                    shape="square"
                                    variant="tertiary"
                                    onClick={e => {
                                        var newList = Object.assign({}, abilities);
                                        delete newList[k];
                                        onAbilitiesChanged(newList);
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}>
                                    <Close size={8 as any} />
                                </Button>
                            }>
                            <AbilityEditor
                                ability={o}
                                onAbilityChanged={ab => {
                                    var newList = copyState(abilities ?? {}, { [k]: ab });
                                    onAbilitiesChanged(newList);
                                }}
                                onInit={() => forceUpdate()}
                                creature={creature}
                            />
                        </FeatureExpander>
                    </AnimatedListItem>
                ))}
            </AnimatePresence>

            <MotionBox layout>
                <Button
                    onClick={() => {
                        var newList = addToKeyedList<Ability>(abilities ?? {}, { name: "New ability" });
                        onAbilitiesChanged(newList);
                    }}>
                    <Add size={12 as any} />
                </Button>
            </MotionBox>
        </LobotomizedBox>
    );
});

const AbilityCollectionField = asField<HTMLDivElement, ExtractProps<typeof AbilityCollectionEditor> & IAsFieldProps>(
    AbilityCollectionEditor
);

const AbilityScoresEditor = React.forwardRef<
    HTMLDivElement,
    {
        abilities?: Partial<CoreAbilities<number>> & { condition?: string };
        onAbilityChanged: (ability: CoreAbility, value?: number) => void;
        calculateDefault?: (ability: CoreAbility) => number;
    }
>(({ abilities, onAbilityChanged, calculateDefault }, ref) => {
    return (
        <Box
            ref={ref}
            fullWidth
            flexDirection="row"
            justifyContent="flex-start"
            flexWrap="wrap"
            css={{ gap: theme.space[2] }}>
            {coreAbilities.map(o => (
                <Box key={o} flexDirection="column">
                    <Text color="grayscale.2">{getCoreAbilityLabel(o)}</Text>
                    <Input
                        min={1}
                        max={30}
                        css={{ maxWidth: theme.space[8] }}
                        variant="number"
                        placeholder={calculateDefault ? calculateDefault(o).toString(10) : undefined}
                        value={(abilities ? abilities[o] : undefined) ?? ""}
                        onChange={e => {
                            return onAbilityChanged(
                                o,
                                isNaN(e.target.valueAsNumber)
                                    ? undefined
                                    : Math.max(0, Math.min(30, e.target.valueAsNumber))
                            );
                        }}
                    />
                </Box>
            ))}
        </Box>
    );
});

const AbilityScoresField = asField<HTMLDivElement, ExtractProps<typeof AbilityScoresEditor> & IAsFieldProps>(
    AbilityScoresEditor
);

const TerrainSelector = React.forwardRef<
    HTMLDivElement,
    {
        terrain: Partial<TerrainTypes<boolean>>;
        onTerrainChanged: (terrain: TerrainType, value: boolean) => void;
    }
>(({ terrain, onTerrainChanged }, ref) => {
    return (
        <LobotomizedBox flexDirection="column" alignItems="flex-start" fullWidth ref={ref}>
            {terrainTypes.map(o => (
                <Checkbox
                    key={o}
                    label={terrainTypeToString(o)}
                    checked={!!terrain[o]}
                    onClick={(e: MouseEvent<HTMLInputElement>) => {
                        onTerrainChanged(o, !terrain[o]);
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onTerrainChanged(o, !terrain[o]);
                    }}
                />
            ))}
        </LobotomizedBox>
    );
});

const TerrainField = asField<HTMLDivElement, ExtractProps<typeof TerrainSelector> & IAsFieldProps>(TerrainSelector);

const SpeedEditor = React.forwardRef<
    HTMLDivElement,
    {
        speed?: Partial<MovementSpeeds<number>>;
        onSpeedChanged: (speed: Partial<MovementSpeeds<number>>) => void;
    }
>(({ speed, onSpeedChanged }, ref) => {
    return (
        <Box
            ref={ref}
            fullWidth
            flexDirection="row"
            justifyContent="flex-start"
            flexWrap="wrap"
            css={{ gap: theme.space[2] }}>
            {movementTypes.map(mt => {
                return (
                    <Box key={mt} flexDirection="column" alignItems="flex-start">
                        <Text color="grayscale.2">{movementTypeToString(mt)}</Text>
                        <Input
                            min={0}
                            placeholder="0"
                            css={{ maxWidth: theme.space[8] }}
                            variant="number"
                            value={(speed ? speed[mt] : undefined) ?? ""}
                            onChange={e => {
                                const newSpeed = {
                                    ...speed,
                                    [mt]: isNaN(e.target.valueAsNumber)
                                        ? undefined
                                        : Math.max(0, e.target.valueAsNumber),
                                };
                                onSpeedChanged(newSpeed);
                            }}
                        />
                    </Box>
                );
            })}
        </Box>
    );
});

const SpeedField = asField<HTMLDivElement, ExtractProps<typeof SpeedEditor> & IAsFieldProps>(SpeedEditor);

const LanguageEditor = React.forwardRef<
    HTMLDivElement,
    {
        languages?: (string | undefined)[];
        onLanguagesChanged: (languages: string[]) => void;
    }
>(({ languages, onLanguagesChanged }, ref) => {
    var [language, setLanguage] = useState<string>();

    var addLanguage = () => {
        var l = language?.trim();
        if (!l) {
            return;
        }

        if (!languages?.includes(l)) {
            var ls = languages ? languages.slice() : [];
            ls.push(l);
            onLanguagesChanged(ls.filter(o => !!o) as string[]);
        }

        setLanguage("");
    };

    return (
        <LobotomizedBox ref={ref} fullWidth flexDirection="column" alignItems="flex-start">
            <Box flexDirection="row">
                <Input
                    variant="text"
                    list="language_editor_options"
                    value={language}
                    onChange={e => setLanguage(e.target.value)}
                    mr={2}
                    onKeyPress={e => {
                        if (e.key === "Enter") {
                            addLanguage();
                        }
                    }}
                />
                <Button disabled={!language?.trim()} onClick={addLanguage}>
                    <Add size={12 as any} />
                </Button>
            </Box>
            <datalist id="language_editor_options">
                {defaultStandardLanguages.map(o => (
                    <option key={o}>{o}</option>
                ))}
                {defaultExoticLanguages.map(o => (
                    <option key={o}>{o}</option>
                ))}
            </datalist>
            <Box flexDirection="row" justifyContent="flex-start" flexWrap="wrap" css={{ gap: theme.space[2] }}>
                <AnimatePresence>
                    {languages?.map(o => (
                        <MotionTag
                            key={o}
                            css={{ maxWidth: theme.space[13] }}
                            layout
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}
                            bg="grayscale.8">
                            <Box flexDirection="row" p={1}>
                                {o}
                                <Button
                                    ml={2}
                                    mr={-2}
                                    size="s"
                                    shape="square"
                                    variant="tertiary"
                                    onClick={() => {
                                        var i = languages.indexOf(o);
                                        var ls = languages.slice();
                                        ls.splice(i, 1);
                                        onLanguagesChanged(ls as string[]);
                                    }}>
                                    <Close size={8 as any} />
                                </Button>
                            </Box>
                        </MotionTag>
                    ))}
                </AnimatePresence>
            </Box>
        </LobotomizedBox>
    );
});

const LanguageField = asField<HTMLDivElement, ExtractProps<typeof LanguageEditor> & IAsFieldProps>(LanguageEditor);

const MaxHpEditor = React.forwardRef<
    HTMLDivElement,
    {
        average?: number;
        dice?: StoredDiceBag;
        onAverageChanged: (average?: number) => void;
        onDiceChanged: (dice: StoredDiceBag) => void;
    }
>(({ average, dice, onAverageChanged, onDiceChanged }, ref) => {
    return (
        <Box
            ref={ref}
            fullWidth
            flexDirection="row"
            justifyContent="flex-start"
            flexWrap="wrap"
            css={{ gap: theme.space[2] }}>
            <Box flexDirection="column" alignItems="flex-start">
                <Text color="grayscale.2">Average</Text>
                <Input
                    min={1}
                    placeholder="1"
                    css={{ maxWidth: theme.space[8] }}
                    variant="number"
                    value={average}
                    onChange={e => {
                        onAverageChanged(isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber);
                    }}
                />
            </Box>
            <Box flexDirection="column" alignItems="flex-start">
                <Text color="grayscale.2">Formula</Text>
                <Input
                    css={{ maxWidth: theme.space[8] }}
                    variant="text"
                    value={dice ? diceBagToExpression(dice) : undefined}
                    onChange={e => {
                        const diceBag = parseDiceBag(e.target.value);
                        onDiceChanged(diceBag);
                    }}
                />
            </Box>
        </Box>
    );
});

const MaxHpField = asField<HTMLDivElement, ExtractProps<typeof MaxHpEditor> & IAsFieldProps>(MaxHpEditor);

const NamedContentEditor = React.forwardRef<
    HTMLDivElement,
    ExtractProps<typeof Box> & {
        name?: string;
        content?: string;
        onNameChanged: (name: string) => void;
        onContentChanged: (content?: string) => void;
        onInit?: () => void;
    }
>(({ name, content, onNameChanged, onContentChanged, onInit, ...props }, ref) => {
    const nameId = useId();
    const contentId = useId();
    return (
        <Box ref={ref} flexDirection="column" flexGrow={1} alignItems="stretch" {...props}>
            <Label htmlFor={nameId}>Name</Label>
            <Input id={nameId} fullWidth variant="text" value={name} onChange={e => onNameChanged(e.target.value)} />

            <Label htmlFor={contentId} mt={2}>
                Content
            </Label>
            <MarkdownEditor
                id={contentId}
                editable
                label="Header content"
                defaultMarkdown={content ?? ""}
                onMarkdownChange={content => {
                    onContentChanged(content);
                }}
                onInit={onInit}
            />
        </Box>
    );
});

const NamedContentCollectionEditor = React.forwardRef<
    HTMLDivElement,
    {
        content?: KeyedList<NamedContent>;
        onContentChanged: (content: KeyedList<NamedContent>) => void;
    }
>(({ content, onContentChanged }, ref) => {
    return (
        <LobotomizedBox ref={ref} flexDirection="column" fullWidth alignItems="flex-start">
            <AnimatePresence>
                {mapKeyedList<NamedContent, JSX.Element>(content, (o, i, k) => (
                    <MotionInOverlayCard
                        key={k}
                        fullWidth
                        p={2}
                        layout
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        borderRadius={3}>
                        <Box flexDirection="row" alignItems="flex-start">
                            <NamedContentEditor
                                name={o?.name}
                                content={o?.content}
                                onNameChanged={name => {
                                    var c = copyState(o, { name: name });
                                    var newContent = copyState(content ?? {}, { [k]: c });
                                    onContentChanged(newContent);
                                }}
                                onContentChanged={s => {
                                    var c = copyState(o, { content: s });
                                    var newContent = copyState(content ?? {}, { [k]: c });
                                    onContentChanged(newContent);
                                }}
                            />
                            <Button
                                ml={2}
                                size="s"
                                shape="square"
                                variant="tertiary"
                                onClick={() => {
                                    var newContent = Object.assign({}, content);
                                    delete newContent[k];
                                    onContentChanged(newContent);
                                }}>
                                <Close size={8 as any} />
                            </Button>
                        </Box>
                    </MotionInOverlayCard>
                ))}
            </AnimatePresence>

            <MotionBox layout>
                <Button
                    onClick={() => {
                        var newContent = addToKeyedList<NamedContent>(content ?? {}, {
                            name: "",
                        });
                        onContentChanged(newContent);
                    }}>
                    <Add size={12 as any} />
                </Button>
            </MotionBox>
        </LobotomizedBox>
    );
});

const NamedContentCollectionField = asField<
    HTMLDivElement,
    ExtractProps<typeof NamedContentCollectionEditor> & IAsFieldProps
>(NamedContentCollectionEditor);

const SensesEditor = React.forwardRef<
    HTMLDivElement,
    {
        senses?: Partial<Senses<number>>;
        onSensesChanged: (senses: Partial<Senses<number>>) => void;
    }
>(({ senses, onSensesChanged }, ref) => {
    return (
        <Box ref={ref} flexDirection="row" justifyContent="flex-start" flexWrap="wrap" css={{ gap: theme.space[2] }}>
            {senseTypes.map(st => {
                return (
                    <Box key={st} flexDirection="column" alignItems="flex-start">
                        <Text color="grayscale.2">{senseTypeToString(st)}</Text>
                        <Input
                            min={0}
                            placeholder="0"
                            css={{ maxWidth: theme.space[8] }}
                            variant="number"
                            value={senses ? senses[st] : undefined}
                            onChange={e => {
                                const newSenses = {
                                    ...senses,
                                    [st]: isNaN(e.target.valueAsNumber)
                                        ? undefined
                                        : Math.max(0, e.target.valueAsNumber),
                                };
                                onSensesChanged(newSenses);
                            }}
                        />
                    </Box>
                );
            })}
        </Box>
    );
});

const SensesField = asField<HTMLDivElement, ExtractProps<typeof SensesEditor> & IAsFieldProps>(SensesEditor);

const MonsterArmorClassEditor = React.forwardRef<
    HTMLDivElement,
    {
        ac?: number;
        from?: string;
        onAcChanged: (ac: number) => void;
        onFromChanged: (from?: string) => void;
    }
>(({ ac, from, onAcChanged, onFromChanged }, ref) => {
    return (
        <Box ref={ref} flexGrow={1} flexDirection="column" alignItems="flex-start">
            <Box
                flexDirection="row"
                justifyContent="flex-start"
                flexWrap="wrap"
                css={{ gap: theme.space[2] }}
                fullWidth>
                <Box flexDirection="column" alignItems="flex-start">
                    <Text color="grayscale.2">AC</Text>
                    <Input
                        min={0}
                        placeholder="10"
                        css={{ maxWidth: theme.space[8] }}
                        variant="number"
                        value={ac ?? 10}
                        onChange={e => {
                            onAcChanged(isNaN(e.target.valueAsNumber) ? 10 : Math.max(0, e.target.valueAsNumber));
                        }}
                    />
                </Box>
                <Box flexDirection="column" alignItems="flex-start" flexGrow={1}>
                    <Text color="grayscale.2">From</Text>
                    <Input
                        fullWidth
                        variant="text"
                        value={from ?? ""}
                        onChange={e => {
                            onFromChanged(e.target.value === "" ? undefined : e.target.value);
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
});

const MonsterArmorClassField = asField<HTMLDivElement, ExtractProps<typeof MonsterArmorClassEditor> & IAsFieldProps>(
    MonsterArmorClassEditor
);

const SkillsEditor = React.forwardRef<
    HTMLDivElement,
    {
        monster: DeepPartial<Monster>;
        onSkillChanged: (skill: Skill, value?: number) => void;
    }
>(({ monster, onSkillChanged }, ref) => {
    var rules = useRules();
    return (
        <Box
            ref={ref}
            fullWidth
            flexDirection="row"
            justifyContent="flex-start"
            flexWrap="wrap"
            css={{ gap: theme.space[2] }}>
            {skillList.map(o => (
                <Box key={o} flexDirection="column" alignItems="flex-start">
                    <Text color="grayscale.2">{skillToString(o)}</Text>
                    <Input
                        min={0}
                        placeholder={modifierFromAbilityScore(monster[rules.skills[o].modifier] ?? 10)?.toString(10)}
                        css={{ maxWidth: theme.space[8] }}
                        variant="number"
                        value={monster.skills ? monster.skills[o] : ""}
                        onChange={e => {
                            onSkillChanged(o, isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber);
                        }}
                    />
                </Box>
            ))}
        </Box>
    );
});

const SkillsField = asField<HTMLDivElement, ExtractProps<typeof SkillsEditor> & IAsFieldProps>(SkillsEditor);

const spellLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const SpellSlotsEditor = React.forwardRef<
    HTMLDivElement,
    {
        spellSlots?: (number | undefined)[];
        onSpellSlotsChanged: (spellSlots: number[]) => void;
    }
>(({ spellSlots, onSpellSlotsChanged }, ref) => {
    return (
        <Box
            ref={ref}
            fullWidth
            flexDirection="row"
            justifyContent="flex-start"
            flexWrap="wrap"
            css={{ gap: theme.space[2] }}>
            {spellLevels.map(o => (
                <Box key={o} flexDirection="column" alignItems="flex-start">
                    <Text color="grayscale.2">{o}</Text>
                    <Input
                        min={0}
                        css={{ maxWidth: theme.space[7] }}
                        placeholder="0"
                        variant="number"
                        value={spellSlots && spellSlots[o - 1] ? spellSlots[o - 1] : ""}
                        onChange={e => {
                            var newNum =
                                isNaN(e.target.valueAsNumber) || e.target.valueAsNumber < 0
                                    ? 0
                                    : e.target.valueAsNumber;
                            var newSpellSlots = spellSlots ? spellSlots.slice() : [];
                            newSpellSlots[o - 1] = newNum;

                            while (newSpellSlots.length && !newSpellSlots[newSpellSlots.length - 1]) {
                                newSpellSlots.pop();
                            }

                            for (let i = 0; i < newSpellSlots.length; i++) {
                                if (!newSpellSlots[i]) {
                                    newSpellSlots[i] = 0;
                                }
                            }

                            onSpellSlotsChanged(newSpellSlots as number[]);
                        }}
                    />
                </Box>
            ))}
        </Box>
    );
});

const SpellSlotsField = asField<HTMLDivElement, ExtractProps<typeof SpellSlotsEditor> & IAsFieldProps>(
    SpellSlotsEditor
);

const SpellEditor: FunctionComponent<{
    spell: Spell;
    usage: MonsterSpell;
    used?: number;
    onUsageChanged: (usage: MonsterSpell) => void;
    onRemove: () => void;
}> = ({ spell, usage, used, onUsageChanged, onRemove }) => {
    const spellKey = getRuleKey(spell);

    // TODO: Probably have to put this definition of innateness somewhere more common.
    var isInnate = usage.level == null && (usage.maxUses != null || usage.reset === "will");
    return (
        <LobotomizedBox flexGrow={1} flexDirection="column" alignItems="flex-start">
            <SpellInfo
                spell={spell}
                actions={[{ label: "Remove", onClick: onRemove }]}
                reset={usage.reset}
                maxUses={usage.maxUses}
                used={used}
                isInOverlay
            />

            <MotionLobotomizedBox layout flexDirection="column" alignItems="flex-start" px={2} pb={2}>
                <Radio
                    name={spellKey}
                    label="Cast using spell slots"
                    id={spellKey + "_spellslots"}
                    checked={!isInnate}
                    onChange={e => {
                        if (e.target.checked) {
                            onUsageChanged(
                                copyState(usage, {
                                    level: spell.level,
                                    reset: undefined,
                                    maxUses: undefined,
                                })
                            );
                        }
                    }}
                />
                <Radio
                    name={spellKey}
                    label="Cast using innate ability"
                    id={spellKey + "_innate"}
                    checked={isInnate}
                    onChange={e => {
                        if (e.target.checked) {
                            onUsageChanged(
                                copyState(usage, {
                                    level: undefined,
                                    reset: usage.reset ?? "longrest",
                                    maxUses: usage.maxUses ?? 1,
                                })
                            );
                        }
                    }}
                />

                <LobotomizedBox fullWidth flexDirection="row" justifyContent="flex-start">
                    <Select
                        css={{ minWidth: theme.space[10] }}
                        disabled={!isInnate}
                        value={usage.reset ?? "longrest"}
                        onChange={e => {
                            onUsageChanged(copyState(usage, { reset: e.target.value as any }));
                        }}>
                        <option value="longrest">Limited uses per day</option>
                        <option value="will">At will</option>
                    </Select>
                    <Input
                        variant="number"
                        disabled={!isInnate || usage.reset === "will"}
                        value={usage.maxUses ?? ""}
                        onChange={e => {
                            onUsageChanged(
                                copyState(usage, {
                                    maxUses: isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber,
                                })
                            );
                        }}
                    />
                </LobotomizedBox>
            </MotionLobotomizedBox>
        </LobotomizedBox>
    );
};

const SpellCollectionEditor = React.forwardRef<
    HTMLDivElement,
    {
        spells?: { [key: string]: MonsterSpell };
        onSpellsChanged: (spells: { [key: string]: MonsterSpell }) => void;
    }
>(({ spells, onSpellsChanged }, ref) => {
    var rules = useRules();
    const { searchPropertiesSection, searchPropertiesSections, setSearchPropertiesSection } = useAppState();
    const isSearchExpanded = useVttApp(state => state.isSearchExpanded);
    const setIsSearchExpanded = useVttApp(state => state.setIsSearchExpanded);
    const { page, setPage, setSpellFilter } = useCompendium();

    var spellList: { spell: Spell; usage: MonsterSpell }[] = [];
    if (spells) {
        var spellKeys = Object.keys(spells);
        for (let i = 0; i < spellKeys.length; i++) {
            var usage = spells[spellKeys[i]];
            if (usage) {
                var spellRef = fromRuleKey(spellKeys[i]);
                if (spellRef) {
                    var spell = rules.spells.get(spellRef);
                    if (spell) {
                        spellList.push({
                            spell: spell,
                            usage: usage,
                        });
                    }
                }
            }
        }

        spellList.sort((a, b) => {
            if (a.spell.level !== b.spell.level) {
                return a.spell.level - b.spell.level;
            }

            return a.spell.name.localeCompare(b.spell.name);
        });
    }

    const id = useId();
    const { setNodeRef, active, isOver } = useTypedDroppable({
        id: "MonsterSpellList_" + id,
        accepts: ["DnD5E_Spell"],
        canDrop: drag => {
            // const spell = drag.data as Spell;
            return true;
        },
        onDrop: drag => {
            const spell = drag.data as Spell;
            const newSpells = copyState(spells ?? {}, {
                [getRuleKey(spell)]: { level: spell.level },
            });
            onSpellsChanged(newSpells);
        },
        renderFeedback: drag => {
            const spell = drag.data as Spell;
            return <React.Fragment>Drop to add {spell.name}.</React.Fragment>;
        },
    });

    return (
        <LobotomizedBox ref={ref} flexDirection="column" fullWidth alignItems="stretch">
            {/* <SpellsPopup
                spells={rules.spells.all.filter(o => !(spells && spells[getRuleKey(o)]))}
                actions={() => {
                    return [
                        {
                            label: "Add",
                            closesPopup: true,
                            onClick: s => {
                                const newSpells = copyState(spells ?? {}, {
                                    [getRuleKey(s)]: { level: s.level },
                                });
                                onSpellsChanged(newSpells);
                            },
                        },
                    ];
                }}
            /> */}

            <MotionBox
                ref={setNodeRef}
                flexDirection="column"
                css={{
                    gap: theme.space[2],
                    marginLeft: -theme.space[2],
                    marginRight: -theme.space[2],
                    marginBottom: -theme.space[2],
                    marginTop: theme.space[1],
                    padding: theme.space[2],
                }}
                animate={{
                    backgroundColor: active
                        ? getThemeColor(isOver ? dragDropPalette[1] : dragDropPalette[0])
                        : undefined,
                }}
                borderTopRightRadius={4}
                borderBottomRightRadius={4}>
                <AnimatePresence>
                    {(spellList == null || spellList.length === 0) && (
                        <AnimatedListItem justifyContent="flex-start">
                            <Text fontStyle="italic" color="grayscale.2">
                                No spells have been added
                            </Text>
                        </AnimatedListItem>
                    )}
                    {spellList?.map((o, i) => (
                        <MotionInOverlayCard
                            key={getRuleKey(o.spell)}
                            fullWidth
                            layout
                            initial={defaultInitial}
                            animate={Object.assign(
                                {
                                    backgroundColor: active
                                        ? getThemeColor(isOver ? dragDropPalette[1] : dragDropPalette[0])
                                        : undefined,
                                },
                                defaultAnimate
                            )}
                            exit={defaultExit}
                            borderRadius={3}>
                            <SpellEditor
                                spell={o.spell}
                                usage={o.usage}
                                onUsageChanged={usage => {
                                    const newSpells = copyState(spells ?? {}, {
                                        [getRuleKey(o.spell)]: usage,
                                    });
                                    onSpellsChanged(newSpells);
                                }}
                                onRemove={() => {
                                    if (spells) {
                                        const newSpells = copyState(spells, {
                                            [getRuleKey(o.spell)]: undefined,
                                        });
                                        onSpellsChanged(newSpells);
                                    }
                                }}
                            />
                        </MotionInOverlayCard>
                    ))}
                </AnimatePresence>
            </MotionBox>

            <Link
                disabled={isSearchExpanded && page === CompendiumPage.Spells && searchPropertiesSection.id === "dnd5e"}
                css={{
                    alignSelf: "flex-start",
                }}
                onClick={() => {
                    const searchPage = searchPropertiesSections.current.find(o => o.id === "dnd5e");
                    if (searchPage) {
                        setSearchPropertiesSection(searchPage);
                        setPage(CompendiumPage.Spells);
                        setIsSearchExpanded(true);

                        setSpellFilter(undefined);
                    }
                }}>
                Find more spells…
            </Link>
        </LobotomizedBox>
    );
});

const SpellCollectionField = asField<HTMLDivElement, ExtractProps<typeof SpellCollectionEditor> & IAsFieldProps>(
    SpellCollectionEditor
);

// const SpellsPopup: FunctionComponent<{
//     spells: Spell[];
//     actions?: (spell: Spell) => (IMenuItem & { closesPopup?: boolean })[];
// }> = ({ spells, actions }) => {
//     const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
//     const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
//     const { styles, attributes } = useMaxSizePopper(referenceElement, popperElement);
//     const [isSpellsOpen, setIsSpellsOpen] = useState(false);
//     const [isSpellsClosing, setIsSpellsClosing] = useState(false);
//     const [isShowing, setIsShowing] = useState(false);

//     // This basically just delays loading the main content of the popup by a render, which allows popper to have all the layout
//     // sorted out before framer motion starts measuring things. Stops everything moving around.
//     useEffect(() => {
//         setIsShowing(isSpellsOpen || isSpellsClosing);
//     }, [isSpellsOpen, isSpellsClosing]);

//     return (
//         <React.Fragment>
//             <MotionBox layout>
//                 <Button
//                     toggled={isSpellsOpen}
//                     ref={setReferenceElement as any}
//                     onClick={() => setIsSpellsOpen(!isSpellsClosing && !isSpellsOpen)}>
//                     <span>Add a spell</span>
//                     <span css={{ marginLeft: theme.space[3] }}>{isSpellsOpen ? "▲" : "▼"}</span>
//                 </Button>
//             </MotionBox>
//             {ReactDOM.createPortal(
//                 <AnimatePresence>
//                     {isSpellsOpen && (
//                         <Box zIndex={1000} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
//                             <FocusTrap
//                                 focusTrapOptions={{
//                                     onDeactivate: () => {
//                                         setIsSpellsClosing(true);
//                                         setIsSpellsOpen(false);
//                                     },
//                                     onPostDeactivate: () => {
//                                         setIsSpellsClosing(false);
//                                         setIsShowing(false);
//                                     },
//                                     escapeDeactivates: e => {
//                                         e.preventDefault();
//                                         e.stopPropagation();
//                                         return true;
//                                     },
//                                     clickOutsideDeactivates: true,
//                                 }}>
//                                 <MotionCard
//                                     layout="size"
//                                     width={600}
//                                     fullHeight
//                                     initial={defaultInitial}
//                                     animate={defaultAnimate}
//                                     exit={defaultExit}
//                                     bg="background"
//                                     borderRadius={3}>
//                                     <SpellList
//                                         scroll
//                                         animateEnterExit
//                                         hideContent={!isShowing}
//                                         spells={spells}
//                                         actions={
//                                             actions
//                                                 ? spell => {
//                                                       var spellActions = actions(spell);
//                                                       return spellActions.map(o => ({
//                                                           label: o.label,
//                                                           onClick: (spell: Spell) => {
//                                                               if (o.closesPopup) {
//                                                                   setIsSpellsOpen(false);
//                                                                   setTimeout(() => o.onClick?.(spell), 250);
//                                                               } else {
//                                                                   o.onClick?.(spell);
//                                                               }
//                                                           },
//                                                       }));
//                                                   }
//                                                 : undefined
//                                         }
//                                     />
//                                 </MotionCard>
//                             </FocusTrap>
//                         </Box>
//                     )}
//                 </AnimatePresence>,
//                 document.querySelector("#vtt_main")!
//             )}
//         </React.Fragment>
//     );
// };

const SpellcastingEditor = React.forwardRef<
    HTMLDivElement,
    {
        spellcasting?: MonsterSpellcasting;
        onSpellcastingChanged: (spellcasting: MonsterSpellcasting) => void;
    }
>(({ spellcasting, onSpellcastingChanged }, ref) => {
    return (
        <React.Fragment>
            <MotionBox fullWidth layout="position">
                <MarkdownEditorField
                    editable
                    label="Header content"
                    defaultMarkdown={spellcasting?.headerContent ?? ""}
                    onMarkdownChange={content => {
                        onSpellcastingChanged(
                            copyState(spellcasting ?? {}, {
                                headerContent: content,
                            }) as MonsterSpellcasting
                        );
                    }}
                />
            </MotionBox>
            <MotionBox fullWidth layout="position">
                <SelectField
                    label="Spellcasting ability"
                    value={spellcasting?.ability}
                    required
                    onChange={e => {
                        const ability = e.target.value ? (e.target.value as CoreAbility) : undefined;
                        onSpellcastingChanged(copyState(spellcasting ?? { spells: {} }, { ability: ability }));
                    }}>
                    <option value="">None</option>
                    {coreAbilities.map(o => (
                        <option key={o} value={o}>
                            {getCoreAbilityLabel(o)}
                        </option>
                    ))}
                </SelectField>
            </MotionBox>
            <MotionBox fullWidth layout="position">
                <SpellSlotsField
                    label="Spell slots"
                    spellSlots={spellcasting?.spellSlots}
                    onSpellSlotsChanged={spellSlots => {
                        onSpellcastingChanged(
                            copyState(spellcasting ?? { spells: {} }, {
                                spellSlots: spellSlots,
                            })
                        );
                    }}
                />
            </MotionBox>
            <MotionBox fullWidth layout="position">
                <SpellCollectionField
                    label="Spells"
                    required
                    spells={spellcasting?.spells}
                    onSpellsChanged={spells => {
                        onSpellcastingChanged(copyState(spellcasting ?? { spells: {} }, { spells: spells }));
                    }}
                />
            </MotionBox>
            <MotionBox fullWidth layout="position">
                <MarkdownEditorField
                    editable
                    label="Footer content"
                    defaultMarkdown={spellcasting?.footerContent ?? ""}
                    onMarkdownChange={content => {
                        onSpellcastingChanged(
                            copyState(spellcasting ?? { spells: {} }, {
                                footerContent: content,
                            })
                        );
                    }}
                />
            </MotionBox>
        </React.Fragment>
    );
});

const SpellcastingCollectionEditor = React.forwardRef<
    HTMLDivElement,
    {
        spellcasting?: KeyedList<MonsterSpellcasting>;
        onSpellcastingChanged: (ac: KeyedList<MonsterSpellcasting>) => void;
    }
>(({ spellcasting, onSpellcastingChanged }, ref) => {
    return (
        <LobotomizedBox ref={ref} flexDirection="column" fullWidth alignItems="flex-start">
            <AnimatePresence>
                {mapKeyedList<MonsterSpellcasting, JSX.Element>(spellcasting, (o, i, k) => (
                    <MotionBox
                        key={k}
                        fullWidth
                        layout
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <Form flexDirection="column" alignItems="flex-start" fullWidth>
                            <SpellcastingEditor
                                spellcasting={o}
                                onSpellcastingChanged={nsc => {
                                    var newList = copyState(spellcasting ?? {}, { [k]: nsc });
                                    onSpellcastingChanged(newList);
                                }}
                            />
                            <Button
                                ml={2}
                                size="s"
                                shape="square"
                                variant="tertiary"
                                onClick={() => {
                                    var newList = Object.assign({}, spellcasting);
                                    delete newList[k];
                                    onSpellcastingChanged(newList);
                                }}>
                                <Close size={8 as any} />
                            </Button>
                        </Form>
                    </MotionBox>
                ))}
            </AnimatePresence>

            <MotionBox layout>
                <Button
                    onClick={() => {
                        var newList = addToKeyedList<MonsterSpellcasting>(spellcasting ?? {}, {
                            ability: "intelligence",
                            spells: {},
                        });
                        onSpellcastingChanged(newList);
                    }}>
                    <Add size={12 as any} />
                </Button>
            </MotionBox>
        </LobotomizedBox>
    );
});

const SpellcastingCollectionField = asField<
    HTMLDivElement,
    ExtractProps<typeof SpellcastingCollectionEditor> & IAsFieldProps
>(SpellcastingCollectionEditor);

const DamageTypesEditor = React.forwardRef<
    HTMLDivElement,
    {
        damageTypes?: DamageTypes<boolean>;
        onDamageTypesChanged: (dt: DamageTypes<boolean>) => void;
        allowCondition: boolean;
    }
>(({ damageTypes, onDamageTypesChanged, allowCondition }, ref) => {
    return (
        <LobotomizedBox flexGrow={1} alignItems="flex-start" flexDirection="column" ref={ref}>
            <Box flexDirection="row" fullWidth alignItems="stretch">
                <LobotomizedBox
                    flexDirection="column"
                    flexShrink={1}
                    justifyContent="flex-start"
                    alignItems="flex-start">
                    {damageTypesList.map(o => (
                        <Checkbox
                            key={o}
                            label={o.charAt(0).toUpperCase() + o.slice(1)}
                            checked={damageTypes ? !!damageTypes[o] : false}
                            onClick={(e: MouseEvent<HTMLInputElement>) => {
                                onDamageTypesChanged(
                                    copyState(damageTypes ?? {}, {
                                        [o]: damageTypes ? !damageTypes[o] : true,
                                    })
                                );
                            }}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                onDamageTypesChanged(
                                    copyState(damageTypes ?? {}, {
                                        [o]: damageTypes ? !damageTypes[o] : true,
                                    })
                                );
                            }}
                        />
                    ))}
                    <Input
                        placeholder="special / other"
                        fullWidth
                        value={damageTypes?.special ?? ""}
                        onChange={e => {
                            onDamageTypesChanged(
                                copyState(damageTypes ?? {}, {
                                    special: e.target.value ? e.target.value : undefined,
                                })
                            );
                        }}
                    />
                </LobotomizedBox>

                <LobotomizedBox flexDirection="column" flexGrow={1} justifyContent="flex-start" alignItems="flex-start">
                    <Box fullWidth flexDirection="column" alignItems="flex-start">
                        <Text color="grayscale.2" mt={2}>
                            Pre-text
                        </Text>
                        <Input
                            variant="text"
                            fullWidth
                            required={allowCondition}
                            value={damageTypes?.preContent ?? ""}
                            onChange={e => {
                                onDamageTypesChanged(
                                    copyState(damageTypes ?? {}, {
                                        preContent: e.target.value ? e.target.value : undefined,
                                    })
                                );
                            }}
                        />
                    </Box>

                    <Box fullWidth flexDirection="column" alignItems="flex-start">
                        <Text color="grayscale.2" mt={2}>
                            Post-text
                        </Text>
                        <Input
                            variant="text"
                            fullWidth
                            required={allowCondition}
                            value={damageTypes?.postContent ?? ""}
                            onChange={e => {
                                onDamageTypesChanged(
                                    copyState(damageTypes ?? {}, {
                                        postContent: e.target.value ? e.target.value : undefined,
                                    })
                                );
                            }}
                        />
                    </Box>

                    <Box fullWidth flexDirection="column" alignItems="flex-start">
                        <Text color="grayscale.2" mt={2}>
                            Preview
                        </Text>
                        {damageTypes && <Markdown>{damageTypesToMarkdown("Resistant", damageTypes)}</Markdown>}
                        {!damageTypes && <Text>None</Text>}
                    </Box>
                </LobotomizedBox>
            </Box>

            {allowCondition && (
                <React.Fragment>
                    <Text color="grayscale.2" mt={2}>
                        Condition
                    </Text>
                    <Input
                        variant="text"
                        fullWidth
                        required={allowCondition}
                        value={damageTypes?.condition ?? ""}
                        onChange={e => {
                            onDamageTypesChanged(
                                copyState(damageTypes ?? {}, {
                                    condition: e.target.value ? e.target.value : undefined,
                                })
                            );
                        }}
                    />
                </React.Fragment>
            )}
        </LobotomizedBox>
    );
});

const DamageTypesCollectionEditor = React.forwardRef<
    HTMLDivElement,
    {
        damageTypes?: KeyedList<DamageTypes<boolean>>;
        onDamageTypesChanged: (dt: KeyedList<DamageTypes<boolean>>) => void;
    }
>(({ damageTypes, onDamageTypesChanged }, ref) => {
    return (
        <LobotomizedBox ref={ref} flexDirection="column" fullWidth alignItems="flex-start">
            <AnimatePresence>
                {mapKeyedList<DamageTypes<boolean>, JSX.Element>(damageTypes, (o, i, k) => (
                    <MotionInOverlayCard
                        key={k}
                        fullWidth
                        p={2}
                        layout
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        borderRadius={3}>
                        <Box flexDirection="row" alignItems="flex-start">
                            <DamageTypesEditor
                                damageTypes={o}
                                onDamageTypesChanged={dt => {
                                    var newList = copyState(damageTypes ?? {}, { [k]: dt });
                                    onDamageTypesChanged(newList);
                                }}
                                allowCondition={i > 0}
                            />
                            <Button
                                ml={2}
                                size="s"
                                shape="square"
                                variant="tertiary"
                                onClick={() => {
                                    var newList = Object.assign({}, damageTypes);
                                    delete newList[k];
                                    onDamageTypesChanged(newList);
                                }}>
                                <Close size={8 as any} />
                            </Button>
                        </Box>
                    </MotionInOverlayCard>
                ))}
            </AnimatePresence>

            <MotionBox layout>
                <Button
                    onClick={() => {
                        var newList = addToKeyedList<DamageTypes<boolean>>(damageTypes ?? {}, {});
                        onDamageTypesChanged(newList);
                    }}>
                    <Add size={12 as any} />
                </Button>
            </MotionBox>
        </LobotomizedBox>
    );
});

const DamageTypesCollectionField = asField<
    HTMLDivElement,
    ExtractProps<typeof DamageTypesCollectionEditor> & IAsFieldProps
>(DamageTypesCollectionEditor);

// const CreatureConditionsEditor = React.forwardRef<
//     HTMLDivElement,
//     {
//         conditions?: ConditionalCreatureConditions;
//         onConditionsChanged: (dt: ConditionalCreatureConditions) => void;
//         allowCondition: boolean;
//     }
// >(({ conditions, onConditionsChanged, allowCondition }, ref) => {
//     return (
//         <LobotomizedBox flexGrow={1} alignItems="flex-start" flexDirection="column" ref={ref}>
//             {creatureConditions.map(o => (
//                 <Checkbox
//                     key={o}
//                     label={o.charAt(0).toUpperCase() + o.slice(1)}
//                     checked={conditions ? !!conditions[o] : false}
//                     onClick={(e: MouseEvent<HTMLInputElement>) => {
//                         onConditionsChanged(
//                             copyState(conditions ?? {}, {
//                                 [o]: conditions ? !conditions[o] : true,
//                             })
//                         );
//                     }}
//                     onChange={(e: ChangeEvent<HTMLInputElement>) => {
//                         onConditionsChanged(
//                             copyState(conditions ?? {}, {
//                                 [o]: conditions ? !conditions[o] : true,
//                             })
//                         );
//                     }}
//                 />
//             ))}

//             {allowCondition && (
//                 <Box fullWidth alignItems="flex-start" flexDirection="column">
//                     <Text color="grayscale.2" mt={2}>
//                         Condition
//                     </Text>
//                     <Input
//                         variant="text"
//                         fullWidth
//                         required={allowCondition}
//                         value={conditions?.condition ?? ""}
//                         onChange={e => {
//                             onConditionsChanged(
//                                 copyState(conditions ?? {}, {
//                                     condition: e.target.value ? e.target.value : undefined,
//                                 })
//                             );
//                         }}
//                     />
//                 </Box>
//             )}
//         </LobotomizedBox>
//     );
// });

// const CreatureConditionsCollectionEditor = React.forwardRef<
//     HTMLDivElement,
//     {
//         conditions?: KeyedList<ConditionalCreatureConditions>;
//         onConditionsChanged: (dt: KeyedList<ConditionalCreatureConditions>) => void;
//     }
// >(({ conditions, onConditionsChanged }, ref) => {
//     return (
//         <LobotomizedBox ref={ref} flexDirection="column" fullWidth alignItems="flex-start">
//             <AnimatePresence>
//                 {mapKeyedList<ConditionalCreatureConditions, JSX.Element>(conditions, (o, i, k) => (
//                     <MotionCard
//                         key={k}
//                         fullWidth
//                         p={2}
//                         boxShadowSize="s"
//                         layout
//                         initial={defaultInitial}
//                         animate={defaultAnimate}
//                         exit={defaultExit}
//                         bg="grayscale.8"
//                         borderRadius={3}>
//                         <Box flexDirection="row" alignItems="flex-start">
//                             <CreatureConditionsEditor
//                                 conditions={o}
//                                 onConditionsChanged={dt => {
//                                     var newList = copyState(conditions ?? {}, { [k]: dt });
//                                     onConditionsChanged(newList);
//                                 }}
//                                 allowCondition={i > 0}
//                             />
//                             <Button
//                                 ml={2}
//                                 size="s"
//                                 shape="square"
//                                 variant="tertiary"
//                                 onClick={() => {
//                                     var newList = Object.assign({}, conditions);
//                                     delete newList[k];
//                                     onConditionsChanged(newList);
//                                 }}>
//                                 <Close size={8 as any} />
//                             </Button>
//                         </Box>
//                     </MotionCard>
//                 ))}
//             </AnimatePresence>

//             <MotionBox layout>
//                 <Button
//                     onClick={() => {
//                         var newList = addToKeyedList<ConditionalCreatureConditions>(conditions ?? {}, {});
//                         onConditionsChanged(newList);
//                     }}>
//                     <Add size={12 as any} />
//                 </Button>
//             </MotionBox>
//         </LobotomizedBox>
//     );
// });

// const CreatureConditionsCollectionField = asField<
//     HTMLDivElement,
//     ExtractProps<typeof CreatureConditionsCollectionEditor> & IAsFieldProps
// >(CreatureConditionsCollectionEditor);

const CoreAbilitiesPage: FunctionComponent<{
    token: DnD5EMonsterTemplate | DnD5EMonsterToken;
    monster: Monster;
}> = ({ token, monster }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    const [addNotification] = useNotifications();
    const errorHandler = useErrorHandler();

    const { setNodeRef, active, isOver } = useTypedDroppable({
        id: "monsterImages",
        accepts: [`LibraryItem/${ImageType.Token}`, `LibraryItem/${ImageType.Portrait}`],
        onDrop: (drag: DragData, active: Active) => {
            const libraryItem = drag.data as LibraryItem;
            if (drag.type === `LibraryItem/${ImageType.Token}`) {
                dispatch(setTokenImage(campaign, location, [token], libraryItem));
            }
        },
    });
    const { isDragOver, ...dropEvents } = useDropEvents("image", async (data, e) => {
        var libraryItems = await (
            await dropFiles(
                data.filter(o => o.kind === "file").map(o => o.getAsFile()!),
                addNotification,
                errorHandler,
                ImageType.Token
            )
        ).libraryItems;
        var tokenImage = libraryItems?.find(o => !o.metadata.type || o.metadata.type === ImageType.Token);
        if (tokenImage) {
            dispatch(setTokenImage(campaign, location, [token], tokenImage));
        }
    });

    return (
        <MotionForm
            {...dropEvents}
            ref={setNodeRef}
            initial={sectionInitial}
            animate={sectionAnimate}
            exit={sectionExit}>
            <InheritedSection
                token={token}
                isOverridden={isDnD5EMonsterToken(token) && token.dnd5e?.label != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { label: undefined }))}>
                <InputField
                    label="Monster name"
                    variant="text"
                    required
                    value={monster.label ?? monster.name}
                    onChange={e => {
                        if (isDnD5EMonsterTemplate(token)) {
                            dispatch(
                                modifyMonster(campaign, location, [token], {
                                    name: e.target.value,
                                })
                            );
                        } else {
                            dispatch(
                                modifyMonster(campaign, location, [token], {
                                    label: e.target.value,
                                })
                            );
                        }
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.imageUri != null}
                revert={() => dispatch(setTokenImage(campaign, location, [token], undefined))}>
                <TokenImageField
                    label="Token image"
                    hint="The image used to represent the monster on the map. Drag and drop an image from your art library to change it."
                    token={token}
                    useFallback
                    allowEdit
                    isDragOver={
                        (!!isOver && active?.data.current?.type === `LibraryItem/${ImageType.Token}`) || !!isDragOver
                    }
                    isDragActive={active != null && active?.data.current?.type === `LibraryItem/${ImageType.Token}`}
                />
            </InheritedSection>
            {/* Setting the max HP average and formula doesn't make sense for individual token instances, so 
                only show it if we're editing a token template. For token instances, we want to edit the maxHp 
                field instead of the maxHpInfo. */}
            {isTokenTemplate(token) && (
                <InheritedSection
                    token={token}
                    isOverridden={token?.dnd5e?.maxHpInfo != null}
                    revert={() => dispatch(modifyMonster(campaign, location, [token], { maxHpInfo: undefined }))}>
                    <MaxHpField
                        label="Max HP"
                        required
                        average={monster.maxHpInfo?.average}
                        dice={monster.maxHpInfo?.dice}
                        onAverageChanged={average => {
                            const newMaxHpInfo = copyState(monster.maxHpInfo ?? {}, {
                                average: average,
                            });
                            dispatch(
                                modifyMonster(campaign, location, [token], {
                                    maxHpInfo: newMaxHpInfo,
                                })
                            );
                        }}
                        onDiceChanged={dice => {
                            const newMaxHpInfo = copyState(monster.maxHpInfo ?? {}, {
                                dice: dice,
                            });
                            dispatch(
                                modifyMonster(campaign, location, [token], {
                                    maxHpInfo: newMaxHpInfo,
                                })
                            );
                        }}
                    />
                </InheritedSection>
            )}
            {isToken(token) && (
                <InheritedSection
                    token={token}
                    isOverridden={token && token.dnd5e.maxHp != null}
                    revert={() => {
                        dispatch(modifyMonster(campaign, location, [token], { maxHp: undefined }));
                    }}>
                    <InputField
                        label="Max HP"
                        variant="number"
                        placeholder={(monster.maxHpInfo?.average ?? 1).toString(10)}
                        value={monster.maxHp ?? ""}
                        onChange={e => {
                            dispatch(
                                modifyMonster(campaign, location, [token], {
                                    maxHp: isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber,
                                })
                            );
                        }}
                    />
                </InheritedSection>
            )}
            <InheritedSection
                token={token}
                isOverridden={token && !!coreAbilities.find(o => token.dnd5e[o] != null)}
                revert={() => {
                    var ca = {};
                    coreAbilities.forEach(o => (ca[o] = undefined));
                    dispatch(modifyMonster(campaign, location, [token], ca));
                }}>
                <AbilityScoresField
                    label="Ability scores"
                    hint="Any ability score that is not specified will default to 10."
                    required
                    abilities={monster}
                    calculateDefault={() => 10}
                    onAbilityChanged={(ability, value) => {
                        dispatch(modifyMonster(campaign, location, [token], { [ability]: value }));
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.ac != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { ac: undefined }))}>
                <MonsterArmorClassField
                    label="AC"
                    ac={monster.ac?.value}
                    from={monster.ac?.from?.join(", ")}
                    onAcChanged={v => {
                        var newAc = copyState(token?.dnd5e?.ac ?? { value: 10 }, { value: v });
                        dispatch(modifyMonster(campaign, location, [token], { ac: newAc }));
                    }}
                    onFromChanged={v => {
                        var newAc = copyState(token?.dnd5e?.ac ?? { value: 10 }, {
                            from: v?.split(","),
                        });
                        dispatch(modifyMonster(campaign, location, [token], { ac: newAc }));
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.alignment != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { alignment: undefined }))}>
                <SelectField
                    label="Alignment"
                    required
                    value={monster.alignment ?? ""}
                    onChange={e => {
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                alignment: e.target.value === "" ? undefined : (e.target.value as Alignment | "Any"),
                            })
                        );
                    }}>
                    <option value="">None</option>
                    {creatureAlignments.map(o => (
                        <option key={o} value={o}>
                            {alignmentToString(o)}
                        </option>
                    ))}
                </SelectField>
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.size != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { size: undefined }))}>
                <SelectField
                    label="Size"
                    required
                    value={monster.size ?? ("Medium" as CreatureSize)}
                    onChange={e => {
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                size: e.target.value as CreatureSize,
                            })
                        );
                    }}>
                    {creatureSizes.map(o => (
                        <option key={o} value={o}>
                            {o}
                        </option>
                    ))}
                </SelectField>
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.creatureType != null}
                revert={() =>
                    dispatch(
                        modifyMonster(campaign, location, [token], {
                            creatureType: undefined,
                        })
                    )
                }>
                <SelectField
                    label="Type"
                    required
                    value={monster.creatureType}
                    onChange={e => {
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                creatureType: e.target.value as CreatureType,
                            })
                        );
                    }}>
                    {creatureTypes.map(o => (
                        <option key={o} value={o}>
                            {creatureTypeToString(o)}
                        </option>
                    ))}
                </SelectField>
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.cr != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { cr: undefined }))}>
                <SelectField
                    label="CR"
                    value={monster.cr == null ? "" : monster.cr.toString(10)}
                    onChange={e => {
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                cr: e.target.value === "" ? undefined : parseFloat(e.target.value),
                            })
                        );
                    }}>
                    <option value="">Not set</option>
                    {challengeRatings.map(o => (
                        <option key={o.toString(10)} value={o.toString(10)}>
                            {crToString(o)}
                        </option>
                    ))}
                </SelectField>
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.savingThrows != null}
                revert={() =>
                    dispatch(
                        modifyMonster(campaign, location, [token], {
                            savingThrows: undefined,
                        })
                    )
                }>
                <AbilityScoresField
                    label="Saving throws"
                    hint="Any saving throw that is not specified will default to the ability score modifier."
                    abilities={monster.savingThrows}
                    calculateDefault={o => modifierFromAbilityScore(monster[o] ?? 10)}
                    onAbilityChanged={(ability, value) => {
                        const newSavingThrows = copyState(monster.savingThrows ?? {}, {
                            [ability]: value == null || isNaN(value) ? undefined : value,
                        });
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                savingThrows: newSavingThrows,
                            })
                        );
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.skills != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { skills: undefined }))}>
                <SkillsField
                    label="Skill bonuses"
                    hint="Any skill bonus that is not specified will default to the relevant ability score modifier."
                    monster={monster}
                    onSkillChanged={(o, v) => {
                        const newSkills = copyState(monster.skills ?? {}, { [o]: v });
                        dispatch(modifyMonster(campaign, location, [token], { skills: newSkills }));
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.passivePerception != null}
                revert={() =>
                    dispatch(
                        modifyMonster(campaign, location, [token], {
                            passivePerception: undefined,
                        })
                    )
                }>
                <InputField
                    label="Passive perception"
                    hint="If not specified, defaults to 10 + Wisdom modifier."
                    variant="number"
                    placeholder={(10 + modifierFromAbilityScore(monster.wisdom ?? 10)).toString(10)}
                    value={monster.passivePerception ?? ""}
                    onChange={e => {
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                passivePerception: isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber,
                            })
                        );
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.senses != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { senses: undefined }))}>
                <SensesField
                    label="Senses (ft)"
                    senses={monster.senses}
                    onSensesChanged={senses => {
                        dispatch(modifyMonster(campaign, location, [token], { senses: senses }));
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.speed != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { speed: undefined }))}>
                <SpeedField
                    label="Movement speed (ft)"
                    required
                    speed={monster.speed}
                    onSpeedChanged={speed => {
                        dispatch(modifyMonster(campaign, location, [token], { speed: speed }));
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.languages != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { languages: undefined }))}>
                <LanguageField
                    label="Languages"
                    languages={monster.languages}
                    onLanguagesChanged={languages => {
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                languages: languages,
                            })
                        );
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.environments != null}
                revert={() =>
                    dispatch(
                        modifyMonster(campaign, location, [token], {
                            environments: undefined,
                        })
                    )
                }>
                <TerrainField
                    label="Environments"
                    terrain={monster.environments ?? {}}
                    onTerrainChanged={(terrain, value) => {
                        const environmentDelta = { [terrain]: value ? true : undefined };
                        const newEnvironments = monster.environments
                            ? copyState(monster.environments, environmentDelta)
                            : environmentDelta;
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                environments: newEnvironments,
                            })
                        );
                    }}
                />
            </InheritedSection>
        </MotionForm>
    );
};

const ActionsPage: FunctionComponent<{
    monster: Monster;
    token: DnD5EMonsterTemplate | DnD5EMonsterToken;
}> = ({ monster, token }) => {
    // return (
    //     <MotionForm initial={sectionInitial} animate={sectionAnimate} exit={sectionExit}>
    //         <Message variant="info">Coming soon…</Message>
    //     </MotionForm>
    // );

    // return (
    //     <LobotomizedBox flexDirection="column" fullWidth>
    //         {mapKeyedList(monster.abilities, (o, i, k) => {
    //             return (
    //                 <FeatureExpander isInOverlay title={o.name}>
    //                     THE CONTENT
    //                 </FeatureExpander>
    //             );
    //         })}
    //     </LobotomizedBox>
    // );

    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    return (
        <InheritedSection
            token={token}
            isOverridden={token?.dnd5e?.abilities != null}
            revert={() => dispatch(modifyMonster(campaign, location, [token], { abilities: undefined }))}>
            <AbilityCollectionField
                abilities={monster.abilities}
                onAbilitiesChanged={abilities => {
                    dispatch(modifyMonster(campaign, location, [token], { abilities: abilities }));
                }}
                creature={monster}
            />
        </InheritedSection>
    );

    // const dispatch = useDispatch();
    // const { campaign, location } = useLocation();
    // return (
    //     <MotionForm initial={sectionInitial} animate={sectionAnimate} exit={sectionExit}>
    //         <InheritedSection token={token} isOverridden={token?.dnd5e?.actions != null} revert={() => dispatch(modifyMonster(campaign, location, [token], { actions: undefined }))}>
    //         <NamedContentCollectionField
    //             label="Actions"
    //             content={monster.actions}
    //             onContentChanged={content => {
    //                 dispatch(modifyMonster(campaign, location, [token], { actions: content }));
    //             }} />
    //     </InheritedSection>
    //     <InheritedSection token={token} isOverridden={token?.dnd5e?.reactions != null} revert={() => dispatch(modifyMonster(campaign, location, [token], { reactions: undefined }))}>
    //         <NamedContentCollectionField
    //             label="Reactions"
    //             content={monster.reactions}
    //             onContentChanged={content => {
    //                 dispatch(modifyMonster(campaign, location, [token], { reactions: content }));
    //             }} />
    //     </InheritedSection>
    //     <InheritedSection token={token} isOverridden={token?.dnd5e?.legendaryActions != null} revert={() => dispatch(modifyMonster(campaign, location, [token], { legendaryActions: undefined }))}>
    //         <NamedContentCollectionField
    //             label="Legendary actions"
    //             content={monster.legendaryActions}
    //             onContentChanged={content => {
    //                 dispatch(modifyMonster(campaign, location, [token], { legendaryActions: content }));
    //             }} />
    //     </InheritedSection>
    //     </MotionForm>
    // );
};

const SpellsPage: FunctionComponent<{
    monster: Monster;
    token: DnD5EMonsterTemplate | DnD5EMonsterToken;
}> = ({ monster, token }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    return (
        <MotionBox fullWidth initial={defaultInitial} animate={defaultAnimate} exit={defaultExit}>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.spellcasting != null}
                revert={() =>
                    dispatch(
                        modifyMonster(campaign, location, [token], {
                            spellcasting: undefined,
                        })
                    )
                }>
                <SpellcastingCollectionField
                    spellcasting={monster.spellcasting}
                    onSpellcastingChanged={spellcasting => {
                        // TODO: Really this should have more defined actions for editing these things, but this is probably good enough.
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                spellcasting: spellcasting,
                            })
                        );
                    }}
                />
            </InheritedSection>
        </MotionBox>
    );
};

const FeaturesPage: FunctionComponent<{
    monster: Monster;
    token: DnD5EMonsterTemplate | DnD5EMonsterToken;
}> = ({ monster, token }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    return (
        <MotionForm initial={sectionInitial} animate={sectionAnimate} exit={sectionExit}>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.traits != null}
                revert={() => dispatch(modifyMonster(campaign, location, [token], { traits: undefined }))}>
                <NamedContentCollectionField
                    label="Traits"
                    content={monster.traits}
                    onContentChanged={content => {
                        dispatch(modifyMonster(campaign, location, [token], { traits: content }));
                    }}
                />
            </InheritedSection>
        </MotionForm>
    );
};

const ImmunitiesPage: FunctionComponent<{
    monster: Monster;
    token: DnD5EMonsterTemplate | DnD5EMonsterToken;
}> = ({ monster, token }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    return (
        <MotionForm initial={sectionInitial} animate={sectionAnimate} exit={sectionExit}>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.damageImmunities != null}
                revert={() =>
                    dispatch(
                        modifyMonster(campaign, location, [token], {
                            damageImmunities: undefined,
                        })
                    )
                }>
                <DamageTypesCollectionField
                    label="Immunities"
                    damageTypes={monster.damageImmunities}
                    onDamageTypesChanged={dt => {
                        dispatch(modifyMonster(campaign, location, [token], { damageImmunities: dt }));
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.resistances != null}
                revert={() =>
                    dispatch(
                        modifyMonster(campaign, location, [token], {
                            resistances: undefined,
                        })
                    )
                }>
                <DamageTypesCollectionField
                    label="Resistances"
                    damageTypes={monster.resistances}
                    onDamageTypesChanged={dt => {
                        dispatch(modifyMonster(campaign, location, [token], { resistances: dt }));
                    }}
                />
            </InheritedSection>
            <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.vulnerabilities != null}
                revert={() =>
                    dispatch(
                        modifyMonster(campaign, location, [token], {
                            vulnerabilities: undefined,
                        })
                    )
                }>
                <DamageTypesCollectionField
                    label="Vulnerabilities"
                    damageTypes={monster.vulnerabilities}
                    onDamageTypesChanged={dt => {
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                vulnerabilities: dt,
                            })
                        );
                    }}
                />
            </InheritedSection>
            {/* <InheritedSection
                token={token}
                isOverridden={token?.dnd5e?.conditionImmunities != null}
                revert={() =>
                    dispatch(
                        modifyMonster(campaign, location, [token], {
                            conditionImmunities: undefined,
                        })
                    )
                }>
                <CreatureConditionsCollectionField
                    label="Condition immunities"
                    conditions={monster.conditionImmunities}
                    onConditionsChanged={ci => {
                        dispatch(
                            modifyMonster(campaign, location, [token], {
                                conditionImmunities: ci,
                            })
                        );
                    }}
                />
            </InheritedSection> */}
        </MotionForm>
    );
};

enum MonsterCreatorPage {
    CoreAbilities,
    Immunities,
    Actions,
    Spells,
    Features,
}

const SideButton = styled(Button)`
    height: 100%;
    background: ${theme.colors.blues[2]};
    &:hover {
        background: ${theme.colors.blues[3]};
    }
`;

const InheritedSection: FunctionComponent<
    PropsWithChildren<{
        token?: DnD5EMonsterTemplate | DnD5EMonsterToken;
        isOverridden: boolean;
        revert: () => void;
    }>
> = ({ token, isOverridden, revert, children }) => {
    return (
        <MotionBox fullWidth layout="position" flexDirection="row" alignItems="stretch">
            {isDnD5EMonsterToken(token) && (
                <MotionBox
                    title={isOverridden ? "Revert to template" : "Inherited from template"}
                    layout
                    mr={2}
                    flexShrink={1}
                    width={theme.space[4]}
                    borderRadius={3}
                    bg="greens.2"
                    css={{ textAlign: "center" }}>
                    {!isOverridden && <MotionBox layout>⭳</MotionBox>}
                    {isOverridden && (
                        <SideButton fullHeight shape="square" size="s" variant="clean" onClick={revert}>
                            <MotionBox layout>⭯</MotionBox>
                        </SideButton>
                    )}
                </MotionBox>
            )}
            <Box flexGrow={1} flexBasis={0}>
                {children}
            </Box>
        </MotionBox>
    );
};

export const MonsterCreator: FunctionComponent<{
    token: DnD5EMonsterTemplate | ResolvedToken<DnD5EMonsterToken>;
    onComplete: (monster: DnD5EMonsterTemplate | DnD5EMonsterToken) => void;
}> = ({ token, onComplete }) => {
    const [page, setPage] = useState(MonsterCreatorPage.CoreAbilities);
    const { campaign } = useCampaign();
    const rules = useRules();

    let monster: Monster | undefined;
    if (isDnD5EMonsterToken(token)) {
        const dndtoken = resolveToken(campaign, token);
        monster = resolveTokenCreature(dndtoken, campaign, rules) as Monster | undefined;
    } else {
        monster = token.dnd5e;
    }

    // The focused token is the one being edited while the character creator is mounted.
    const { setFocusedToken } = useAppState();
    useEffect(() => {
        setFocusedToken(isTokenTemplate(token) ? token.templateId : token.id);
        return () => setFocusedToken(undefined);
    });

    if (!monster) {
        return <React.Fragment>Could not resolve monster token.</React.Fragment>;
    }

    const header = (
        <Grid
            fullWidth
            gridTemplateColumns="min-content 1fr"
            gridTemplateRows="min-content min-content"
            borderTopLeftRadius={4}
            borderTopRightRadius={4}>
            <AnimatePresence mode="wait">
                <TokenImage key={token.imageUri} token={token} gridColumn={1} gridRow={1} mr={3} />
            </AnimatePresence>
            <Box
                gridRow={1}
                gridColumn={2}
                alignSelf="center"
                alignItems="flex-end"
                justifyContent="flex-start"
                css={{ gap: theme.space[3] }}>
                <Heading as="h3">{monster.name}</Heading>
                <MonsterTags monster={monster} />
            </Box>
            <MotionBox
                mt={3}
                gridRow={2}
                gridColumn="1 / 3"
                alignItems="flex-end"
                layout
                flexWrap="wrap"
                justifyContent="flex-start"
                css={{ gap: theme.space[2], boxSizing: "content-box" }}>
                <SidebarButton
                    tooltip="Core Abilities"
                    toggled={page === MonsterCreatorPage.CoreAbilities}
                    onClick={() => setPage(MonsterCreatorPage.CoreAbilities)}>
                    <CharacterSheetIcon />
                </SidebarButton>
                <SidebarButton
                    tooltip="Immunities, resistances and vulnerabilities"
                    toggled={page === MonsterCreatorPage.Immunities}
                    onClick={() => setPage(MonsterCreatorPage.Immunities)}>
                    <AuraIcon />
                </SidebarButton>
                <SidebarButton
                    tooltip="Actions"
                    toggled={page === MonsterCreatorPage.Actions}
                    onClick={() => setPage(MonsterCreatorPage.Actions)}>
                    <CombatIcon />
                </SidebarButton>
                <SidebarButton
                    tooltip="Spells"
                    toggled={page === MonsterCreatorPage.Spells}
                    onClick={() => setPage(MonsterCreatorPage.Spells)}>
                    <SpellbookIcon />
                </SidebarButton>
                <SidebarButton
                    tooltip="Features"
                    toggled={page === MonsterCreatorPage.Features}
                    onClick={() => setPage(MonsterCreatorPage.Features)}>
                    <BookPileIcon />
                </SidebarButton>
            </MotionBox>
        </Grid>
    );

    const footer = (
        <Box fullWidth flexDirection="row" justifyContent="flex-end">
            <Button variant="primary" ml={2} onClick={() => onComplete(token)}>
                Close
            </Button>
        </Box>
    );

    return (
        <LayoutGroup>
            <ModalContent header={header} footer={footer}>
                <AnimatePresence mode="wait" initial={false}>
                    <ScrollableTest key={page} fullWidth fullHeight minimal px={3}>
                        {page === MonsterCreatorPage.CoreAbilities && (
                            <CoreAbilitiesPage key="CoreAbilities" monster={monster} token={token} />
                        )}
                        {page === MonsterCreatorPage.Immunities && (
                            <ImmunitiesPage key="Immunities" monster={monster} token={token} />
                        )}
                        {page === MonsterCreatorPage.Actions && (
                            <ActionsPage key="Actions" monster={monster} token={token} />
                        )}
                        {page === MonsterCreatorPage.Spells && (
                            <SpellsPage key="Spells" monster={monster} token={token} />
                        )}
                        {page === MonsterCreatorPage.Features && (
                            <FeaturesPage key="Features" monster={monster} token={token} />
                        )}
                    </ScrollableTest>
                </AnimatePresence>
            </ModalContent>
        </LayoutGroup>
    );
};

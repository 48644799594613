import { DeepPartial } from "../common";
import { Campaign, Handout, Location, LocationSummary, TokenTemplate, UserInfo } from "../store";
import { CampaignAction, LocationAction, CampaignPlayerAction, TokenTemplatesAction } from "./common";

export function setCampaignLabel(campaignId: string, label: string): CampaignAction {
    return {
        type: "SetCampaignLabel",
        props: { campaignId: campaignId },
        payload: label,
    };
}

export function setCampaignDescription(campaignId: string, description: string | undefined): CampaignAction {
    return {
        type: "SetCampaignDescription",
        props: { campaignId: campaignId },
        payload: description,
    };
}

export function setCampaignSharedVision(
    campaignId: string,
    sharedVision: "party" | "none" | undefined
): CampaignAction {
    return {
        type: "SetCampaignSharedVision",
        props: { campaignId: campaignId },
        payload: sharedVision,
    };
}

export function addLocation(campaignId: string, location: Location, user: UserInfo | string): LocationAction {
    return {
        type: "AddLocation",
        props: {
            campaignId: campaignId,
            locationId: location.id,
            userId: typeof user === "string" ? user : user.id,
        },
        payload: { location: location },
    };
}

export function removeLocation(campaignId: string, location: LocationSummary | string): LocationAction {
    const locationId = typeof location === "string" ? location : location.id;
    return {
        type: "RemoveLocation",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
    };
}

export function addHandout(campaign: Campaign | string, handout: Handout): CampaignAction {
    const campaignId = typeof campaign === "string" ? campaign : campaign.id;
    return {
        type: "AddHandout",
        props: {
            campaignId: campaignId,
        },
        payload: handout,
    };
}

export function removeHandout(campaign: Campaign | string, handout: Handout): CampaignAction {
    const campaignId = typeof campaign === "string" ? campaign : campaign.id;
    return {
        type: "RemoveHandout",
        props: {
            campaignId: campaignId,
        },
        payload: handout,
    };
}

export function setPlayerLocation(
    campaignId: string,
    userId: string,
    locationId: string,
    level?: string
): CampaignPlayerAction {
    return {
        type: "SetPlayerLocation",
        props: {
            campaignId: campaignId,
            userId: userId,
        },
        payload: { location: locationId, level: level },
    };
}

export function setPlayerColour(campaignId: string, userId: string, colour: string): CampaignPlayerAction {
    return {
        type: "SetPlayerColour",
        props: {
            campaignId: campaignId,
            userId: userId,
        },
        payload: colour,
    };
}

export function addTokenTemplate(campaignId: string, token: TokenTemplate): CampaignAction {
    return {
        type: "AddTokenTemplate",
        props: {
            campaignId: campaignId,
        },
        payload: token,
    };
}

export function deleteTokenTemplate(campaignId: string, tokens: (TokenTemplate | string)[]): TokenTemplatesAction {
    const templateIds = tokens.map(o => (typeof o === "string" ? o : o.templateId));
    return {
        type: "DeleteTokenTemplate",
        props: {
            campaignId: campaignId,
            templates: templateIds,
        },
    };
}

export function modifyTokenTemplate(
    campaignid: string,
    tokens: (string | TokenTemplate)[],
    props: DeepPartial<TokenTemplate>
): TokenTemplatesAction {
    const templateIds = tokens.map(o => (typeof o === "string" ? o : o.templateId));
    return {
        type: "ModifyTokenTemplate",
        props: {
            campaignId: campaignid,
            templates: templateIds,
        },
        payload: props,
    };
}

export function importCampaign(
    campaignId: string,
    data: {
        locations: Location[];
        tokenTemplates: TokenTemplate[];
    }
): CampaignAction {
    return {
        type: "ImportCampaign",
        props: {
            campaignId: campaignId,
        },
        payload: data,
    };
}

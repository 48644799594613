import React from 'react'
  
  const UnconsciousIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={size} height={size} stroke={color}>
        <g transform="translate(14,-8)"><path d="M264.438 17.094c-65.792 0-122.042 41.78-145.625 93.406-20.03 34.082-31.844 74.992-31.844 118 0 73.852 20.417 140.625 52.81 188.406 32.395 47.78 76.155 76.28 123.845 76.28 47.69.002 91.45-28.5 123.844-76.28 32.393-47.78 52.81-114.555 52.81-188.406 0-41.823-10.428-80.8-28.31-113.53-22.305-53.66-79.854-97.876-147.533-97.876zm0 18.687c72.458 0 132.256 60.305 138.25 117.564H267.063l-6.22-22.625-9-32.782-9 32.78-10.5 38.22-16.843-61.282-9.03-32.78-9 32.78L185 152.97h-58.813c6.22-57.147 65.95-117.19 138.25-117.19zm-57.97 109.657l16.845 61.25 9.03 32.782 9-32.782 10.5-38.25 1 3.593h149.812c-5.967 55.655-64.63 101.032-138.22 101.032-73.752 0-132.567-45.58-138.31-101.406h73.124l1.906-6.875 5.313-19.343zm-71.78 139.407c18.355 18.096 37.527 26.734 55.718 27.53 18.192.798 35.928-6.095 52.125-21.5l12.876 13.563c-19.213 18.273-42.28 27.657-65.844 26.625-23.562-1.03-47.1-12.333-68-32.937l13.126-13.28zm264.75 0l13.125 13.28c-20.898 20.605-44.438 31.907-68 32.938-23.563 1.032-46.63-8.352-65.844-26.625l12.874-13.562c16.198 15.404 33.965 22.297 52.156 21.5 18.19-.797 37.333-9.435 55.688-27.53zM266.53 419.594c26.456-.068 52.92 3.186 79.345 10.03l-4.688 18.095c-49.808-12.902-99.402-12.4-149.375.03l-4.53-18.125c26.343-6.552 52.795-9.964 79.25-10.03z"></path></g>
    </svg>
  )
  
  UnconsciousIcon.displayName = 'UnconsciousIcon'
  
  UnconsciousIcon.defaultProps = {
    size: 24,
    color: 'none',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
  }
  
  export default UnconsciousIcon
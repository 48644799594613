import React, { FunctionComponent, useCallback, useLayoutEffect } from "react";
import { LocalPixelPosition, Point } from "../../position";
import { DoorAnnotation, Annotation, getAnnotationPos } from "../../annotations";
import { useDispatch, useLocalGrid, useRole, useValidatedLocationLevel } from "../contexts";
import { getThemeColor } from "../../design/utils";
import { theme } from "../../design";
import {
    localPoint,
    angle,
    distanceBetween,
    degToRad,
    getClosestPointOnLines,
    lengthOfVector,
    pointAlongLine,
} from "../../grid";
import { AnnotationHandle } from "./AnnotationHandle";
import { modifyAnnotation } from "../../actions/location";
import { DraggableLine, Line } from "./three2d/Line";
import { DraggableEllipse } from "./three2d/Ellipse";
import { HoverTracking, ZIndexes } from "./common";
import { Token } from "../../store";
import { ThreeEvent } from "@react-three/fiber";
import { animate, useMotionValue } from "framer-motion";
import { radToDeg } from "three/src/math/MathUtils";
import { SelectionType } from "../selection";
import { interactiveLineWidth } from "../common";
import { AnimatePresenceRepeater } from "../motion";

interface DoorAnnotationNodeProps extends HoverTracking {
    door: DoorAnnotation;
    activePoint?: LocalPixelPosition;
    isSelected: SelectionType;
    wasSelected: boolean;
    disabled?: boolean;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    onPointerDown?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    onPointerUp?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onOverrideAnnotation: (id: string, override: Partial<DoorAnnotation> | undefined) => void;
    animateExit?: boolean;
}

export const DoorAnnotationNodeCore: FunctionComponent<DoorAnnotationNodeProps> = ({
    door,
    activePoint,
    snapPoint,
    isSelected,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideAnnotation,
    disabled,
    isHovering,
    setHoverPart,
    wasSelected,
    animateExit,
}) => {
    const dispatch = useDispatch();
    const { campaign, location, levelKey } = useValidatedLocationLevel();
    const grid = useLocalGrid();
    const role = useRole();

    const pos = getAnnotationPos(door, campaign, location, grid);
    const point = activePoint ? { x: activePoint.x - pos.x, y: activePoint.y - pos.y } : door.point;
    const doorAngle = angle({ x: 0, y: 0 }, point);
    const r = door.rotation != null ? (!door.invert ? -door.rotation : door.rotation) : 0;
    const doorLength = distanceBetween({ x: 0, y: 0 }, point);

    const handleRotate = r + doorAngle;

    const nonOverrideDoor = location.annotations[door.id] as DoorAnnotation | undefined;
    const nonOverrideRotation = nonOverrideDoor && nonOverrideDoor.rotation != null ? nonOverrideDoor.rotation : 0;
    const rotateAnim = useMotionValue(degToRad(door.invert ? -nonOverrideRotation : nonOverrideRotation));
    useLayoutEffect(() => {
        animate(rotateAnim, degToRad(door.invert ? -nonOverrideRotation : nonOverrideRotation), {
            type: "tween",
            ease: "easeInOut",
            duration: 0.5,
            onUpdate: v => {
                onOverrideAnnotation(door.id, {
                    rotation: radToDeg(door.invert ? -v : v),
                });
            },
        });
    }, [nonOverrideRotation, door.invert, door.id, onOverrideAnnotation, rotateAnim]);

    const nonOverrideOpen = nonOverrideDoor?.open ?? 0;
    const openAnim = useMotionValue(nonOverrideOpen);
    useLayoutEffect(() => {
        animate(openAnim, nonOverrideOpen, {
            type: "tween",
            ease: "easeInOut",
            duration: 0.5,
            onUpdate: v => {
                onOverrideAnnotation(door.id, {
                    open: v,
                });
            },
        });
    }, [openAnim, nonOverrideOpen, door.id, onOverrideAnnotation]);

    let offset: Point | undefined;
    if (door.open != null) {
        offset = pointAlongLine(point, { x: 0, y: 0 }, door.open * lengthOfVector(point));
        offset.x -= point.x;
        offset.y -= point.y;
    }

    const onDragMove = useCallback(
        (e: ThreeEvent<PointerEvent>, p: LocalPixelPosition) => {
            onOverrideAnnotation(door.id, {
                pos: { ...p, level: door.pos?.level ?? levelKey },
            });
        },
        [door.id, door.pos?.level, levelKey, onOverrideAnnotation]
    );

    const onAnnotationClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, door);
            }
        },
        [door, onClick]
    );

    const isHover = onClick && (isHovering || !!activePoint);

    const radius = Math.sqrt(point.x * point.x + point.y * point.y);
    const opacity = disabled ? 0.15 : 0.3;
    const disableDrag = onClick == null || disabled;
    const unselectedColor = disabled
        ? getThemeColor(theme.colors.grayscale[1])
        : getThemeColor(theme.colors.accent[isHover ? 5 : 1]);
    const unselectedLineColor = disabled
        ? getThemeColor(theme.colors.grayscale[3])
        : getThemeColor(theme.colors.accent[isHover ? 4 : 3]);

    return (
        <AnimatePresenceRepeater>
            {animateExit &&
                (door.subtype == null || door.subtype === "default") &&
                (!door.isSecret || role === "GM") && (
                    <DraggableEllipse
                        key="a"
                        x={pos.x}
                        y={pos.y}
                        xRadius={radius}
                        yRadius={radius}
                        angle={90}
                        rotation={door.invert ? doorAngle : 270 + doorAngle}
                        zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Underlay}
                        opacity={opacity}
                        animateEnterExit="nolayout"
                        initial={
                            isSelected
                                ? {
                                      color: theme.colors.guidance.focus,
                                      opacity: opacity,
                                      scale: 1,
                                  }
                                : wasSelected
                                ? { color: unselectedColor, opacity: opacity, scale: 1 }
                                : undefined
                        }
                        color={isSelected ? theme.colors.guidance.focus : unselectedColor}
                        dragBoundFunc={v => snapPoint(v)}
                        cursor={disableDrag ? undefined : "pointer"}
                        onPointerOver={() => setHoverPart?.("line", true)}
                        onPointerOut={() => setHoverPart?.("line", false)}
                        disableDrag={disableDrag}
                        onDragMove={onDragMove}
                        onDragEnd={() => {
                            dispatch(
                                modifyAnnotation<DoorAnnotation>(campaign.id, location.id, door.id, { pos: door.pos })
                            );
                            onOverrideAnnotation(door.id, undefined);
                        }}
                        onClick={disabled ? undefined : onAnnotationClick}
                        onPointerDown={onPointerDown ? e => onPointerDown(e, door) : undefined}
                        onPointerUp={onPointerUp ? e => onPointerUp(e, door) : undefined}
                    />
                )}

            {(!door.isSecret || role === "GM") && (
                <React.Fragment key="b">
                    <DraggableLine
                        x={pos.x}
                        y={pos.y}
                        offsetX={offset?.x}
                        offsetY={offset?.y}
                        zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                        rotation={rotateAnim}
                        points={[{ x: 0, y: 0 }, point]}
                        visible={false}
                        width={4 + interactiveLineWidth}
                        onPointerOver={() => setHoverPart?.("line", true)}
                        onPointerOut={() => setHoverPart?.("line", false)}
                        cursor={disableDrag ? undefined : "pointer"}
                        dragBoundFunc={snapPoint}
                        disableDrag={disableDrag}
                        onDragMove={onDragMove}
                        onDragEnd={() => {
                            dispatch(
                                modifyAnnotation<DoorAnnotation>(campaign.id, location.id, door.id, {
                                    pos: door.pos,
                                })
                            );
                            onOverrideAnnotation(door.id, undefined);
                        }}
                        onClick={disabled ? undefined : onAnnotationClick}
                        onPointerDown={onPointerDown ? e => onPointerDown(e, door) : undefined}
                        onPointerUp={onPointerUp ? e => onPointerUp(e, door) : undefined}
                    />

                    <Line
                        x={pos.x}
                        y={pos.y}
                        offsetX={offset?.x}
                        offsetY={offset?.y}
                        points={[{ x: 0, y: 0 }, point]}
                        animateEnterExit="nolayout"
                        dashSize={3}
                        gapSize={2}
                        dashed={door.isSecret}
                        initial={
                            isSelected
                                ? { color: unselectedLineColor, opacity: 1, scale: 1 }
                                : wasSelected
                                ? { color: theme.colors.guidance.focus, opacity: 1, scale: 1 }
                                : undefined
                        }
                        exit={animateExit ? undefined : null}
                        color={isSelected === SelectionType.Primary ? theme.colors.guidance.focus : unselectedLineColor}
                        width={4}
                        rotation={rotateAnim}
                        zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                    />
                </React.Fragment>
            )}

            {!disabled && (!door.isSecret || role === "GM") && (
                <AnnotationHandle
                    key="c"
                    x={pos.x}
                    y={pos.y}
                    offsetX={doorLength - (door.open ?? 0) * doorLength}
                    offsetY={0}
                    rotation={handleRotate}
                    snapPoint={snapPoint}
                    color={theme.colors.guidance.focus}
                    noAnimateEnter={!!isSelected || wasSelected}
                    noAnimateExit={!animateExit}
                    dragBoundFunc={(current, next) => {
                        if (!door.subtype || door.subtype === "default") {
                            let v = localPoint(next.x - pos.x, next.y - pos.y);
                            const length = Math.sqrt(v.x * v.x + v.y * v.y);
                            v = localPoint(v.x / length, v.y / length);
                            const radius = Math.sqrt(point.x * point.x + point.y * point.y);
                            return localPoint(v.x * radius + pos.x, v.y * radius + pos.y);
                        }

                        return next;
                    }}
                    onDragMove={pr => {
                        if (!door.subtype || door.subtype === "default") {
                            // The door swings open hinged at one side.
                            let closedAngle = angle({ x: 0, y: 0 }, point);
                            let a = angle(pos, pr) - closedAngle;
                            if (a < 0) {
                                a += 360;
                            }

                            // a is 0 <= a <= 360.
                            if (!door.invert) {
                                a = 360 - a;
                            }

                            // The allowed range for the door is 0 <= a <= 180.
                            if (a > 180) {
                                a = 0;
                            }

                            rotateAnim.set(degToRad(door.invert ? -a : a));
                            onOverrideAnnotation(door.id, { rotation: a });
                        } else if (door.subtype === "slide") {
                            // The door slides open, towards the hinge side.
                            const closestPos = getClosestPointOnLines(pr, [
                                pos,
                                localPoint(pos.x + point.x, pos.y + point.y),
                            ]);
                            const currentDist = distanceBetween<Point>(pos, closestPos);
                            const closedDist = lengthOfVector(point);
                            const open = 1 - currentDist / closedDist;
                            openAnim.set(open);
                            onOverrideAnnotation(door.id, {
                                open: open,
                            });
                        }
                    }}
                    onDragEnd={() => {
                        dispatch(
                            modifyAnnotation<DoorAnnotation>(campaign.id, location.id, door.id, {
                                rotation: door.rotation,
                                open: door.open,
                            })
                        );
                        onOverrideAnnotation(door.id, undefined);
                    }}
                />
            )}
        </AnimatePresenceRepeater>
    );
};

export const DoorAnnotationNode = React.memo(DoorAnnotationNodeCore);

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useState, useEffect, useMemo } from "react";
import { Box } from "../primitives";
import { useCampaign, useRole, useUser } from "../contexts";
import { AnimatedListItem } from "../motion";
import { TokenTemplate } from "../../store";
import { AnimatePresence } from "framer-motion";
import { Button } from "../Button";
import { Message } from "../Message";
import { TokenTemplateHost, TokenTemplateListItem } from "./TokenTemplateListItem";
import { lightTokenTemplates } from "../LocationStage/Lighting";
import { ScrollableTest } from "../ScrollableTest";
import { ListBox } from "../ListBox";
import { theme } from "../../design";
import { Pages } from "./Sidebar";
import { useVttApp } from "../common";

const defaultLoadedCount = 30;

export const TokenTemplateLibraryTools: FunctionComponent<{}> = () => {
    const { system } = useCampaign();
    return <Box fullWidth>{system.renderTokenTools?.()}</Box>;
};

export const TokenTemplateLibrary: FunctionComponent<{}> = React.memo(() => {
    const { campaign, system } = useCampaign();
    const user = useUser();
    const role = useRole();
    const { searchTerm, searchResults, addPanel, clearPanels } = useVttApp();
    const search = searchResults.find(o => o.categoryId === Pages.Tokens)?.results;

    const systemTokenTemplatesDictionary = system.getTokenTemplates();
    const systemTokens = useMemo(() => Object.values(systemTokenTemplatesDictionary), [systemTokenTemplatesDictionary]);

    const [loadedCount, setLoadedCount] = useState(defaultLoadedCount);
    useEffect(() => {
        setLoadedCount(defaultLoadedCount);
    }, [searchResults]);

    let campaignTokens = Object.keys(campaign.tokens).map(o => campaign.tokens[o]);
    if (role === "Player") {
        campaignTokens = campaignTokens.filter(o => o.owner === user.id);
    }

    const originalAllTokens: TokenTemplate[] = search
        ? search.map(o => o.originalItem)
        : role === "Player"
        ? campaignTokens
        : [...campaignTokens, ...lightTokenTemplates, ...systemTokens];
    const systemFilteredResults = system.filterTokenTemplates?.(originalAllTokens, campaign, searchTerm);
    const allTokens = systemFilteredResults?.tokenTemplates ?? originalAllTokens;

    const items = allTokens.slice(0, loadedCount);

    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex = selectedItem == null ? -1 : items.findIndex(o => o.templateId === selectedItem);

    return (
        <Box flexDirection="column" fullHeight fullWidth>
            {system.renderTokenTemplates?.()}
            <AnimatePresence>
                {(searchTerm !== "" || allTokens !== originalAllTokens) && (
                    <AnimatedListItem fullWidth>
                        <Message alignSelf="stretch" mx={3} my={2} variant="info" flex="0 1 auto" fullWidth>
                            {systemFilteredResults?.filterMessage && <p>{systemFilteredResults?.filterMessage}</p>}
                            {!systemFilteredResults?.filterMessage && searchTerm !== "" && (
                                <p>Showing only tokens matching "{searchTerm}"</p>
                            )}
                            {!systemFilteredResults?.filterMessage && searchTerm === "" && (
                                <p>This list has been filtered</p>
                            )}
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>
            <TokenTemplateHost>
                <ScrollableTest fullWidth minimal py={1} px={3}>
                    <ListBox<TokenTemplate>
                        selectActive
                        css={{
                            position: "relative",
                            flexDirection: "column",
                            alignItems: "stretch",
                            gap: theme.space[2],
                        }}
                        paddingY={3}
                        fullWidth
                        items={items}
                        selectedItems={selectedIndex < 0 ? undefined : [items[selectedIndex]]}
                        itemKey={o => o.templateId}
                        onSelectionChanged={o => {
                            setSelectedItem(o && o.length ? o[0].templateId : undefined);
                            const panel = o.length ? system.getTokenTemplatePanel?.(o[0]) : undefined;
                            if (panel) {
                                addPanel(panel);
                            } else {
                                clearPanels();
                            }
                        }}>
                        {({ item, index, selected, active, focused }) => {
                            return (
                                <AnimatedListItem
                                    key={item.templateId}
                                    index={index % defaultLoadedCount}
                                    fullWidth
                                    borderRadius={3}>
                                    <TokenTemplateListItem
                                        tokenTemplate={item}
                                        isSelected={selected}
                                        isActive={active}
                                        isFocused={focused}
                                    />
                                </AnimatedListItem>
                            );
                        }}
                    </ListBox>
                    {items.length < allTokens.length && (
                        <Button
                            key="loadmore"
                            variant="tertiary"
                            onClick={() => setLoadedCount(loadedCount + defaultLoadedCount)}>
                            {allTokens.length - loadedCount} more…
                        </Button>
                    )}
                </ScrollableTest>
            </TokenTemplateHost>
        </Box>
    );
});

import { DiceRollLogEntry, LogHeader } from "../../store";
import { CoreAbility, DamageType, NamedRuleRef, AttackType } from "./common";
import { Skill } from "./creature";

export function isSavingThrow(logEntry: LogHeader): logEntry is SavingThrowLogEntry {
    return logEntry.data && !!logEntry.data["savingThrow"];
}

export interface SavingThrowLogEntry extends DiceRollLogEntry {
    data: {
        savingThrow: CoreAbility | "death";
        effect?: string;
        dc?: number;
    };
}

export function isAbilityCheck(logEntry: LogHeader): logEntry is AbilityCheckLogEntry {
    return logEntry.data && !!logEntry.data["ability"];
}

export interface AbilityCheckLogEntry extends DiceRollLogEntry {
    data: {
        ability: CoreAbility;
    };
}

export function isSkillCheck(logEntry: LogHeader): logEntry is SkillCheckLogEntry {
    return logEntry.data && !!logEntry.data["skill"];
}

export interface SkillCheckLogEntry extends DiceRollLogEntry {
    data: {
        skill: Skill;
    };
}

export function isAttackRoll(logEntry: LogHeader): logEntry is AttackRollLogEntry {
    return logEntry.data && !!logEntry.data["attack"];
}

export interface AttackRollLogEntry extends DiceRollLogEntry {
    data: {
        attack: AttackType;
    };
}

export function isRechargeRoll(logEntry: LogHeader): logEntry is RechargeRollLogEntry {
    return logEntry.data && !!logEntry.data["rechargeOn"];
}

export interface RechargeRollLogEntry extends DiceRollLogEntry {
    data: {
        abilityKey?: string;
        rechargeOn: number;
    };
}

export function isDamageRoll(logEntry: LogHeader): logEntry is DamageRollLogEntry {
    return logEntry.data && !!logEntry.data["dmgType"];
}

export interface HealingRollLogEntry extends DiceRollLogEntry {
    data: {
        isHeal: true;
    };
}

export function isHealingRoll(logEntry: LogHeader): logEntry is HealingRollLogEntry {
    return logEntry.data && logEntry.data["isHeal"];
}

export interface DamageRollLogEntry extends DiceRollLogEntry {
    data: {
        dmgType: DamageType;
    };
}

export function isHitPoints(logEntry: LogHeader): logEntry is HitPointRollLogEntry {
    return logEntry.data && !!logEntry.data["level"] && !!logEntry.data["class"];
}

export interface HitPointRollLogEntry extends DiceRollLogEntry {
    data: {
        level: number;
        class: NamedRuleRef;
    };
}

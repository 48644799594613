import { isPromise } from "./common";
import { Profile, ErrorHandler } from "./store";

let userProfiles: { [id: string]: Profile | Promise<Profile> | undefined } = {};

/**
 * Gets the user profile for the specified user, if it has already been fetched.
 * You probably want to use loadProfiles instead.
 */
export function getProfile(userId: string) {
    return userProfiles[userId];
}

export function setProfile(userId: string, profile: Profile | Promise<Profile>) {
    userProfiles[userId] = profile;
}

export function loadProfilesSync(userIds: string[]) {
    let profiles: Profile[] = [];
    for (let i = 0; i < userIds.length; i++) {
        var p = userProfiles[userIds[i]];
        if (!p || isPromise(p)) {
            return undefined;
        }

        profiles.push(p);
    }

    return profiles;
}

/**
 * Loads the specified profiles.
 * @param userIds The user IDs to load the profiles for.
 * @param errorHandler The error handler for any request necessary to load the profiles.
 */
export async function loadProfiles(userIds: string[], errorHandler: ErrorHandler): Promise<Profile[]> {
    const missing = userIds.filter(o => !userProfiles[o]);

    if (missing.length) {
        const missingDistinct = Array.from(new Set(missing));
        const promise = fetch("api/users/profiles?" + missingDistinct.map(o => `users=${o}`).join("&")).then(
            async o => {
                if (errorHandler.handleResponse(o, () => `Failed to load user profiles:\n${o.statusText}`)) {
                    const r = (await o.json()) as Profile[];

                    for (let i = 0; i < r.length; i++) {
                        userProfiles[r[i].userId] = r[i];
                    }

                    return r;
                } else {
                    throw o;
                }
            }
        );
        for (let i = 0; i < missingDistinct.length; i++) {
            userProfiles[missingDistinct[i]] = fromArray(promise, i);
        }
    }

    return await Promise.all(userIds.map(o => userProfiles[o]!));
}

function fromArray(p: Promise<Profile[]>, i: number): Promise<Profile> {
    return p.then(o => o[i]);
}

import { createContext, useContext } from "react";
import { CharacterRuleSet } from "../creature";

export interface DnD5eCampaignContextInfo {
    rules: CharacterRuleSet;
}

export const DnD5eCampaignContext = createContext<DnD5eCampaignContextInfo>(
    undefined as any as DnD5eCampaignContextInfo
);

export function useRules() {
    return useContext(DnD5eCampaignContext).rules;
}

import { Colours, theme } from ".";
import { Campaign } from "../store";
import { Vector4 } from "three";
import tinycolor from "tinycolor2";

export function getRandomPalette(exclude?: (keyof Colours | undefined)[]) {
    const palettes = Object.keys(theme.colors).filter(
        k =>
            Array.isArray(theme.colors[k]) &&
            !k.startsWith("transpar") &&
            (!exclude || exclude.indexOf(k as keyof Colours) < 0)
    );
    const r = Math.floor(Math.random() * palettes.length);
    return palettes[r];
}

export function withOpacity(color: string, opacity: number) {
    if (color.startsWith("hsl(")) {
        return `hsla(${color.substring(4, color.lastIndexOf(")"))}, ${opacity})`;
    }

    return color;
}

export function getPlayerColorPalette(campaign: Campaign, userId: string) {
    if (!userId) {
        return getThemeColorPalette();
    }

    let player = campaign.players[userId];
    if (!player) {
        return getThemeColorPalette();
    }

    return getThemeColorPalette(player.colour);
}

const paletteCache: { [id: string]: string[] } = {};

export function getThemeColorPalette(palette?: string) {
    const cachedPalette = paletteCache[palette ?? "accent"];
    if (cachedPalette) {
        return cachedPalette;
    }

    let colours: string[];
    if (palette == null) {
        colours = theme.colors.accent;
    } else {
        colours = theme.colors[palette];
        if (!colours) {
            colours = theme.colors.accent;
        }
    }

    const p = colours.map(o => getThemeColor(o));
    paletteCache[palette ?? "accent"] = p;
    return p;
}

// function toHex(n: number) {
//     return n.toString(16).padStart(2, "0");
// }

function getThemeColorInternal(themeColorString: string) {
    // TODO: This seems like a lot of work to do to get the current accent. Doing this hundreds of times a frame (especially getComputedStyle)
    // has got to be slower than necessary.
    let color = themeColorString;
    if (themeColorString.startsWith("var(")) {
        // CSS custom property.
        const varSplit = themeColorString.indexOf(",", 4);
        const varText = themeColorString
            .substr(4, (varSplit >= 0 ? varSplit : themeColorString.indexOf(")", 4)) - 4)
            .trim();
        color = window.getComputedStyle(document.querySelector(":root") as any).getPropertyValue(varText);
        if (!color && varSplit >= 0) {
            // Use the fallback color if specified.
            color = themeColorString
                .substr(varSplit + 1, themeColorString.indexOf(")", varSplit + 1) - (varSplit + 1))
                .trim();
        }
    }

    return color;
}

export function getThemeColorVector(themeColorString: string): Vector4 {
    const color = tinycolor(getThemeColorInternal(themeColorString)).toRgb();
    return new Vector4(color.r / 255, color.g / 255, color.b / 255, color.a);
}

export function getThemeColor(themeColorString: string) {
    const color = tinycolor(getThemeColorInternal(themeColorString));
    return color.toHexString();
}

export function getThemeColorTiny(themeColorString: string) {
    const c = getThemeColorInternal(themeColorString);
    return tinycolor(c);
}

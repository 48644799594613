import React, { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { Point, LocalPixelPosition } from "../../../position";
import { Mesh } from "three";
import { PointerEventsProps, PositionProps, RotationProps } from "./common";
import { ILocalGrid, rotate } from "../../../grid";
import { useLocalGrid } from "../../contexts";
import { RootState, ThreeEvent, useThree } from "@react-three/fiber";
import { pointerEventToLocalPoint } from "../common";
import { dragStatus } from "../../common";

var nextId: number = 0;

function removeCursor(canvas: HTMLCanvasElement, id: string) {
    let cursors = canvas["__vtt_cursors"] as { [id: string]: { cursor: string } } | undefined;
    if (cursors && cursors[id]) {
        delete cursors[id];

        const ids = Object.keys(cursors);
        if (!ids.length) {
            canvas.removeEventListener("pointerleave", canvas["__vtt_pointerleave"]);
            canvas.style.cursor = "";
        }
    }
}

function updateCursor(e: ThreeEvent<PointerEvent>, id: string, cursor: string | undefined): HTMLCanvasElement {
    const canvas = e?.nativeEvent.target as HTMLCanvasElement;
    let cursors = canvas["__vtt_cursors"] as { [id: string]: { cursor: string } } | undefined;
    if (!cursors) {
        cursors = {};
        canvas["__vtt_cursors"] = cursors;
        let onLeave = () => {
            delete canvas["__vtt_cursors"];
        };
        canvas["__vtt_pointerleave"] = onLeave;
        canvas.addEventListener("pointerleave", onLeave);
    }

    if (cursor == null) {
        removeCursor(canvas, id);
        return canvas;
    }

    cursors[id] = { cursor: cursor };

    const ids = Object.keys(cursors);
    const cursorToApply = ids.length ? cursors[ids[0]].cursor : "inherit";
    canvas.style.cursor = cursorToApply;
    return canvas;
}

export interface WithDragEventsProps {
    /**
     * Called when a drag on this element begins.
     */
    onDragStart?: (e: ThreeEvent<PointerEvent>, cancelDrag: () => void) => void;

    /**
     * Called when the mouse moves during a drag on this element.
     * @param e The PointerEvent for the mouse move.
     * @param dragOffset The distance that the cursor is from the position of the element
     *                   during dragging in local coordinates, or undefined if grid has not
     *                   been specified.
     */
    onDragMove?: (e: ThreeEvent<PointerEvent>, pos: LocalPixelPosition) => void;

    /**
     * Called when the mouse is released after dragging this element.
     */
    onDragEnd?: (e: ThreeEvent<PointerEvent>, pos: LocalPixelPosition) => void;

    dragBoundFunc?: (e: LocalPixelPosition) => LocalPixelPosition;

    disableDrag?: boolean;

    /**
     * The cursor for when the mouse is over the draggable element, defaults to "grab".
     */
    cursor?: string;
}

function dragBound(
    grid: ILocalGrid,
    e: PointerEvent,
    threeGet: () => RootState,
    dragOffset?: Point,
    dragBoundFunc?: (p: LocalPixelPosition) => LocalPixelPosition
) {
    let localDragPoint = pointerEventToLocalPoint(e, grid, threeGet);
    if (dragOffset) {
        localDragPoint.x -= dragOffset.x;
        localDragPoint.y -= dragOffset.y;
    }

    if (dragBoundFunc) {
        localDragPoint = dragBoundFunc(localDragPoint);
    }

    return localDragPoint;
}

export const withCursor = <P extends PositionProps & PointerEventsProps>(Component: React.ComponentType<P>) =>
    React.forwardRef<Mesh, P & { cursor?: string }>(({ onPointerOver, onPointerOut, cursor, ...props }, ref) => {
        const C = Component as unknown as any;
        const id = useMemo(() => (nextId++).toString(), []);
        const canvasRef = useRef<HTMLCanvasElement>();
        const pe = useCallback(
            (e: ThreeEvent<PointerEvent>) => {
                canvasRef.current = updateCursor(e, id, cursor);

                if (onPointerOver) {
                    onPointerOver(e);
                }
            },
            [onPointerOver, cursor, id]
        );
        const pl = useCallback(
            (e: ThreeEvent<PointerEvent>) => {
                canvasRef.current = updateCursor(e, id, undefined);

                if (onPointerOut) {
                    onPointerOut(e);
                }
            },
            [onPointerOut, id]
        );
        useEffect(() => {
            return () => {
                if (canvasRef.current) {
                    removeCursor(canvasRef.current, id);
                }
            };
        }, [id]);

        return <C ref={ref} {...(props as P)} onPointerOver={pe} onPointerOut={pl} />;
    });

/**
 * Extends a Mesh based component with drag events.
 * The component should return the Mesh object as its ref.
 * @param Component The component to extend.
 */
export const withDragEvents = <P extends PositionProps & RotationProps & PointerEventsProps>(
    Component: React.ComponentType<P>
) =>
    React.forwardRef<Mesh, P & WithDragEventsProps>(
        (
            {
                dragBoundFunc,
                onDragStart,
                onDragMove,
                onDragEnd,
                onPointerDown,
                onPointerMove,
                onPointerUp,
                onPointerOver,
                onPointerOut,
                x,
                y,
                offsetX,
                offsetY,
                disableDrag,
                onClick,
                cursor,
                rotation,
                ...props
            },
            ref
        ) => {
            const [isDragging, setIsDragging] = useState(false);
            const [dragPoint, setDragPoint] = useState<Point | undefined>(undefined);
            const [dragOffset, setDragOffset] = useState<Point | undefined>(undefined);
            const grid = useLocalGrid();
            const threeGet = useThree(state => state.get);

            const id = useMemo(() => (nextId++).toString(), []);
            const canvasRef = useRef<HTMLCanvasElement>();
            const pe = useCallback(
                (e: ThreeEvent<PointerEvent>) => {
                    canvasRef.current = updateCursor(e, id, cursor);

                    if (onPointerOver) {
                        onPointerOver(e);
                    }
                },
                [onPointerOver, cursor, id]
            );
            const pl = useCallback(
                (e: ThreeEvent<PointerEvent>) => {
                    canvasRef.current = updateCursor(e, id, undefined);

                    if (onPointerOut) {
                        onPointerOut(e);
                    }
                },
                [onPointerOut, id]
            );
            useEffect(() => {
                return () => {
                    if (canvasRef.current) {
                        removeCursor(canvasRef.current, id);
                    }
                };
            }, [id]);

            let effectiveX = x;
            let effectiveY = y;
            if (offsetX != null || offsetY != null) {
                if (rotation != null) {
                    const rotatedPoint = rotate(
                        { x: offsetX != null ? offsetX : 0, y: offsetY != null ? offsetY : 0 },
                        { x: 0, y: 0 },
                        typeof rotation === "number" ? rotation : rotation.get()
                    );
                    effectiveX = x + rotatedPoint.x;
                    effectiveY = y + rotatedPoint.y;
                } else {
                    effectiveX += offsetX != null ? offsetX : 0;
                    effectiveY += offsetY != null ? offsetY : 0;
                }
            }

            const pd = useCallback(
                (e: ThreeEvent<PointerEvent>) => {
                    if (e.nativeEvent.button === 0) {
                        setDragPoint({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
                        (e as any).target.setPointerCapture((e as any).pointerId);
                        e.stopPropagation();
                        e.nativeEvent.stopPropagation();

                        // Can't preventDefault here because "Unable to preventDefault inside passive event listener invocation."
                        // e.nativeEvent.preventDefault();

                        // Record the offset from the position clicked to the x,y of the mesh.
                        const localPoint = pointerEventToLocalPoint(e.nativeEvent, grid, threeGet);
                        setDragOffset({ x: localPoint.x - effectiveX, y: localPoint.y - effectiveY });
                    }

                    if (onPointerDown) {
                        onPointerDown(e);
                    }
                },
                [onPointerDown, grid, effectiveX, effectiveY, threeGet]
            );

            const pm = useCallback(
                (e: ThreeEvent<PointerEvent>) => {
                    if (dragPoint && (e.nativeEvent.buttons & 1) === 1) {
                        if (!isDragging) {
                            if (
                                Math.abs(e.nativeEvent.clientX - dragPoint.x) > 5 ||
                                Math.abs(e.nativeEvent.clientY - dragPoint.y) > 5
                            ) {
                                e.stopPropagation();
                                e.nativeEvent.stopPropagation();

                                // Can't preventDefault here because "Unable to preventDefault inside passive event listener invocation."
                                // e.nativeEvent.preventDefault();

                                // Moved far enough, start dragging!
                                if (onDragStart) {
                                    onDragStart(e, () => {
                                        dragStatus.isDragging = false;
                                        setIsDragging(false);
                                        setDragPoint(undefined);
                                        setDragOffset(undefined);
                                    });
                                }

                                if (onDragMove) {
                                    const dp = dragBound(grid, e.nativeEvent, threeGet, dragOffset, dragBoundFunc);
                                    onDragMove(e, dp);
                                }

                                setDragPoint({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
                                setIsDragging(true);
                                dragStatus.isDragging = true;

                                updateCursor(e, id, "grabbing");
                            }
                        } else {
                            e.stopPropagation();
                            e.nativeEvent.stopPropagation();

                            if (onDragMove) {
                                const dp = dragBound(grid, e.nativeEvent, threeGet, dragOffset, dragBoundFunc);
                                onDragMove(e, dp);
                            }

                            setDragPoint({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
                        }
                    }

                    if (onPointerMove) {
                        onPointerMove(e);
                    }
                },
                [
                    onPointerMove,
                    onDragStart,
                    onDragMove,
                    dragPoint,
                    isDragging,
                    dragOffset,
                    dragBoundFunc,
                    grid,
                    id,
                    threeGet,
                ]
            );

            const pu = useCallback(
                (e: ThreeEvent<PointerEvent>) => {
                    if (e.nativeEvent.button === 0) {
                        (e as any).target.releasePointerCapture((e as any).pointerId);

                        if (isDragging) {
                            updateCursor(e, id, cursor);
                            e.stopPropagation();
                            e.nativeEvent.stopPropagation();
                            if (onDragEnd) {
                                const dp = dragBound(grid, e.nativeEvent, threeGet, dragOffset, dragBoundFunc);
                                onDragEnd(e, dp);
                            }
                        }

                        setTimeout(() => {
                            dragStatus.isDragging = false;
                            setIsDragging(false);
                            setDragPoint(undefined);
                            setDragOffset(undefined);
                        }, 0);
                    }

                    if (onPointerUp) {
                        onPointerUp(e);
                    }
                },
                [onPointerUp, onDragEnd, isDragging, dragOffset, dragBoundFunc, grid, cursor, id, threeGet]
            );

            const pc = useCallback((e: ThreeEvent<MouseEvent>) => {
                // Click event always seems to happen even if we cancel the pointerup event,
                // so delay clearing the flags until it happens and cancel the click too if
                // they are set.
                e.stopPropagation();
                e.nativeEvent.stopPropagation();
                e.nativeEvent.preventDefault();
            }, []);

            // If drag is currently disabled, just pass everything straight through.
            if (disableDrag) {
                const C = Component as unknown as any;
                return (
                    <C
                        ref={ref}
                        {...(props as P)}
                        x={x}
                        y={y}
                        offsetX={offsetX}
                        offsetY={offsetY}
                        rotation={rotation}
                        onClick={onClick}
                        onPointerDown={onPointerDown}
                        onPointerMove={onPointerMove}
                        onPointerUp={onPointerUp}
                        onPointerOver={cursor ? pe : onPointerOver}
                        onPointerOut={cursor ? pl : onPointerOut}
                    />
                );
            }

            // let dx = x;
            // let dy = y;
            // if (moveWithDrag && dragOffset && dragPoint && grid) {
            //     const localDragPoint = grid.toLocalPoint(screenPoint(dragPoint.x, dragPoint.y))
            //     dx = localDragPoint.x - dragOffset.x;
            //     dy = localDragPoint.y - dragOffset.y;
            // }

            const C = Component as unknown as any;
            return (
                <C
                    {...(props as P)}
                    x={x}
                    y={y}
                    offsetX={offsetX}
                    offsetY={offsetY}
                    rotation={rotation}
                    ref={ref}
                    onClick={isDragging ? pc : onClick}
                    onPointerDown={pd}
                    onPointerMove={pm}
                    onPointerUp={pu}
                    onPointerOver={pe}
                    onPointerOut={pl}
                />
            );
        }
    );

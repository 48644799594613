import React from 'react'

const MediumArmorIcon = ({
    size = undefined,
    color = undefined,
    ...props
}) => (
    <svg
        {...props}
        viewBox='0 0 512 512'
        width={size}
        height={size}
        stroke={color}
    >
        <path d="M174.688 61.094L144.125 78.75 43.53 136.813l-10.124 5.843-10.718 6.188-5.47 3.156 65.407 113.375 2.03-1.156 4.97-2.876 9.188-5.313V256l55.874-32.438-8.625 195.938-.156 3.22-1.125 24.03h222.126l-.125-2.688-.31-7.343-.783-17.22-8.593-195.53 55.22 32.06-.033.033 13.283 7.656.843.5 2.063 1.186L493.874 152l-5.47-3.156-10.717-6.188-94.438-54.5-16.28-9.406-22.408-12.938-8.156-4.687c-11.456 23.492-43.573 40.594-81.156 40.594-37.564 0-69.075-17.103-80.53-40.595l-.032-.03zm-17.313 31.562c38.33 35.412 91.103 46.482 137.03 34.563l-7.717 14.25 13.125 24.25 13.125-24.25-9.22-17.032c18.97-6.366 36.472-16.805 50.876-31.25l113.75 65.656-46.72 81L392 222.624l12.156-22.468-13.125-24.25-13.124 24.25L389.186 221l-19.31-11.22-33.563-19.874.5 8.5-12.938-23.906-13.156 24.25L323.874 223l12.97-23.97 1.467 24.626h.032l2.187 49.406-10.655-19.656-13.125 24.25L326.688 296h-60.22l9-16.625-13.124-24.25-13.125 24.25 9 16.625h-88l3.405-77.25h-.03l1.155-28.875-33.53 19.875-51.75 30.063-46.72-80.97 114.625-66.187zm4.563 16.78l-13.125 24.25 13.125 24.25 13.125-24.25-13.125-24.25zm270.093 39.626l-13.124 24.25 13.125 24.25 13.126-24.25-13.125-24.25zM128.69 166.72l-13.125 24.25 13.124 24.25 13.125-24.25-13.125-24.25zm113.843 38.186l-13.124 24.25 13.125 24.25 13.126-24.25-13.125-24.25zm55.876 19.813l-13.125 24.25 13.126 24.25 13.125-24.25-13.124-24.25zm-99.72 9.186l-13.124 24.25 13.125 24.25 13.157-24.25-13.156-24.25zm142.22 47.625l.625 14.47h-8.467l7.843-14.47zM169.47 312.97h172.81l.876 19.967H168.594l.875-19.968zm-1.626 36.936H207l-10.844 19.97 13.156 24.25 13.125-24.25-10.812-19.97h120.563l-11.563 21.375 13.125 24.25L345 374.75l1.97 44.75h-53.44l8.095-14.97-13.125-24.25-13.125 24.25 8.094 14.97H164.78l3.064-69.594zm167.47 0h8.592l.75 17.28-9.344-17.28z" fill={color}></path>
    </svg>
)

MediumArmorIcon.displayName = 'MediumArmorIcon'

MediumArmorIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
}

export default MediumArmorIcon 
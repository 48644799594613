import { theme } from "../../design";
import { ThreeEvent } from "@react-three/fiber";
import React from "react";
import { FunctionComponent } from "react";
import { modifyZone } from "../../actions/location";
import { GridPosition, LocalPixelPosition } from "../../position";
import { WithLevel, Zone, defaultSoundRadius } from "../../store";
import { useDispatch, useLocalGrid, usePathFinding, useValidatedLocation } from "../contexts";
import { getThemeColor } from "../../design/utils";
import { useHoverTracking, ZIndexes } from "./common";
import { EditablePolygon } from "./EditablePolygon";
import { UiLayer } from "./UiLayer";
import { Vector2 } from "three";
import { RelativeAudioNode } from "./RelativeAudioNode";
import { SelectionType } from "../selection";

interface ZoneNodeProps {
    zone: Zone;
    activePoint?: LocalPixelPosition;
    isSelected: SelectionType;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onOverrideZone: (id: string, override: Partial<Zone> | undefined) => void;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Zone): void;
    audioListenerPos?: WithLevel<LocalPixelPosition>;
}

export const ZoneAudioNode: FunctionComponent<{
    zone: Zone;
    audioListenerPos: WithLevel<LocalPixelPosition>;
}> = ({ zone, audioListenerPos }) => {
    const grid = useLocalGrid();
    const pathFinder = usePathFinding();
    const sound = zone.sound;
    if (!pathFinder || !sound) {
        return null;
    }

    const audioListenerPosGrid = grid.toGridPoint(audioListenerPos) as WithLevel<GridPosition>;
    audioListenerPosGrid.level = audioListenerPos.level;

    const radius = sound.radius ?? defaultSoundRadius;
    const path = pathFinder.findSoundPath(radius, audioListenerPosGrid, zone);
    if (!path) {
        return null;
    }

    if (path && path.path.length) {
        // We have the best path for audio, this will help determine the location of the sound.
        if (path.cost <= radius) {
            const volume = sound.volume ?? 100;

            const gridPoint = path.path[path.path.length - 1];
            const relativePos = new Vector2(
                gridPoint.x - audioListenerPosGrid.x,
                gridPoint.y - audioListenerPosGrid.y
            ).setLength(path.cost);
            return <RelativeAudioNode uri={sound.uri} volume={volume} relativePos={relativePos} radius={radius} />;
        }
    }

    return null;
};

export const ZoneNode: FunctionComponent<ZoneNodeProps> = React.memo(
    ({ zone, activePoint, snapPoint, onOverrideZone, onClick, isSelected, audioListenerPos }) => {
        const dispatch = useDispatch();
        const { campaign, location } = useValidatedLocation();

        const hoverProps = useHoverTracking(zone.id);

        return (
            <UiLayer>
                <EditablePolygon
                    pos={zone.pos}
                    points={zone.points}
                    activePoint={activePoint}
                    lineWidth={2}
                    fillColor={getThemeColor(theme.colors.accent[0])}
                    fillHoverColor={getThemeColor(theme.colors.accent[1])}
                    lineColor={
                        isSelected === SelectionType.Primary
                            ? theme.colors.guidance.focus
                            : getThemeColor(theme.colors.accent[3])
                    }
                    lineHoverColor={isSelected ? theme.colors.guidance.focus : getThemeColor(theme.colors.accent[4])}
                    fillOpacity={0.2}
                    zIndex={ZIndexes.UserInterface}
                    {...hoverProps}
                    closed
                    filled
                    animateExit
                    showHandles={!!isSelected}
                    onClick={
                        onClick
                            ? e => {
                                  onClick(e, zone);
                              }
                            : undefined
                    }
                    onOverridePosition={pos => onOverrideZone(zone.id, { pos: { ...pos, level: zone.pos.level } })}
                    onSetPosition={pos => {
                        dispatch(
                            modifyZone(campaign.id, location.id, zone.id, {
                                pos: { ...pos, level: zone.pos.level },
                            })
                        );
                        onOverrideZone(zone.id, undefined);
                    }}
                    onOverridePoints={(pos, points) =>
                        onOverrideZone(zone.id, {
                            pos: { ...pos, level: zone.pos.level },
                            points: points,
                        })
                    }
                    onSetPoints={(pos, points) => {
                        dispatch(
                            modifyZone(campaign.id, location.id, zone.id, {
                                pos: { ...pos, level: zone.pos.level },
                                points: points,
                            })
                        );
                        onOverrideZone(zone.id, undefined);
                    }}
                    snapPoint={snapPoint}
                />
            </UiLayer>
        );
    }
);

import React from 'react'
  
  const AddIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 0.75,12h22.5  M 12,0.75v22.5' />
    </svg>
  )
  
  AddIcon.displayName = 'AddIcon'
  
  AddIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default AddIcon
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import { Box, BoxProps, Checkbox, Heading, InOverlayCard, Text, Truncate } from "../../../components/primitives";
import { MAX_ATTUNED_ITEMS, ResolvedCharacter, getWeaponDamage } from "../creature";
import { ExtractProps, LobotomizedBox } from "../../../components/common";
import {
    ResolvedItem,
    ItemType,
    isWeapon,
    isArmor,
    isInventoryItem,
    ResolvedWeapon,
    ResolvedInventoryItem,
    isShield,
    DraggedItems,
} from "../items";
import { Button } from "../../../components/Button";
import { DnD5ECharacterTemplate, DnD5ECharacterToken, getRuleKey, isDnD5ECharacterToken } from "../common";
import { useRules } from "./hooks";
import { escapeRegExp } from "../../../common";
import {
    defaultInitial,
    defaultAnimate,
    defaultExit,
    MotionInput,
    MotionBox,
    MotionButton,
} from "../../../components/motion";
import { Markdown } from "../../../components/markdown";
import { renderIconForItem } from "./common";
import { theme } from "../../../design";
import { TextPopup } from "../../../components/TextPopup";
import { Placement } from "@popperjs/core";
import { DraggableBox, DraggableGrid } from "../../../components/draggable";
import { FeatureExpander } from "./FeatureExpander";
import { MarkdownActions } from "../../../components/MarkdownActions";
import { AnimatePresence, motion } from "framer-motion";
import { useAppState, useDispatch, useLocation, useNotifications } from "../../../components/contexts";
import { TokenTemplate, getToken, isToken } from "../../../store";
import { addItems, attuneItems, equipItems, removeItems } from "../actions/inventory";
import { Message } from "../../../components/Message";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import InventoryIcon from "../../../components/icons/Inventory";
import { ListBox } from "../../../components/ListBox";
import FocusTrap from "focus-trap-react";
import { ModalDialog } from "../../../components/modal";
import { ControlledMenu } from "../../../components/menus";
import { MenuItem, SubMenu, useMenuState } from "@szhsin/react-menu";

export function getItemSubtitle(item: ResolvedItem) {
    let properties: string[];

    switch (item.type) {
        case ItemType.Ammunition:
            properties = ["Ammunition"];
            break;
        case ItemType.Melee:
        case ItemType.Ranged:
            const weapon = item as ResolvedWeapon & ResolvedItem;
            properties = [
                weapon.type === ItemType.Melee ? "Melee" : "Ranged",
                weapon.weaponType === "simple" ? "Simple" : "Martial",
            ];
            break;
        case ItemType.Shield:
            properties = ["Shield"];
            break;
        case ItemType.LightArmor:
            properties = ["Light armor"];
            break;
        case ItemType.MediumArmor:
            properties = ["Medium armor"];
            break;
        case ItemType.HeavyArmor:
            properties = ["Heavy armor"];
            break;
        case ItemType.Ring:
            properties = ["Ring"];
            break;
        case ItemType.Wand:
            properties = ["Wand"];
            break;
        case ItemType.Vehicle:
            properties = ["Vehicle"];
            break;
        case ItemType.SpellCastingFocus:
            properties = ["Spellcasting focus"];
            break;
        case ItemType.Tool:
            properties = ["Tool"];
            break;
        case ItemType.ArtisanTool:
            properties = ["Artisan tool"];
            break;
        case ItemType.Instrument:
            properties = ["Instrument"];
            break;
        case ItemType.FoodAndDrink:
            properties = ["Food and drink"];
            break;
        case ItemType.Mount:
            properties = ["Mount"];
            break;
        case ItemType.Other:
            properties = ["Other"];
            break;
        case ItemType.Scroll:
            properties = ["Scroll"];
            break;
        case ItemType.Potion:
            properties = ["Potion"];
            break;
        case ItemType.TradeGood:
            properties = ["Trade good"];
            break;
        case ItemType.Currency:
            properties = ["Currency"];
            break;
        case ItemType.Ship:
            properties = ["Ship"];
            break;
        case ItemType.Airship:
            properties = ["Airship"];
            break;
        case ItemType.Rod:
            properties = ["Rod"];
            break;
        case ItemType.GamingSet:
            properties = ["Gaming set"];
            break;
        case ItemType.TackAndHarness:
            properties = ["Tack and harness"];
            break;
        default:
            properties = ["Miscellaneous"];
            break;
    }

    if (item.properties) {
        properties.push(...item.properties.map(p => p.name));
    }

    return properties.join(", ");
}

export const ItemDetails: FunctionComponent<{
    item: ResolvedItem;
    character?: ResolvedCharacter;
    handed?: 1 | 2;
}> = ({ item, character, handed }) => {
    const rules = useRules();
    return (
        <Box flexDirection="column" alignItems="flex-start">
            {item.requiresAttunement && <Text fontStyle="italic">Requires attunement</Text>}
            {item.baseItem && (
                <Box>
                    <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                        Base Item:{" "}
                    </Text>
                    <Text>{item.baseItem.name}</Text>
                </Box>
            )}
            {isWeapon(item) && (
                <React.Fragment>
                    <Box>
                        <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                            Attack Type:{" "}
                        </Text>
                        <Text>{item.type === ItemType.Ranged ? "Ranged" : "Melee"}</Text>
                    </Box>
                    {item.type === ItemType.Melee && (
                        <Box>
                            <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                                Reach:{" "}
                            </Text>
                            <Text>
                                {item.properties && item.properties.some(o => o.abbreviation === "R")
                                    ? "10ft."
                                    : "5ft."}
                            </Text>
                        </Box>
                    )}
                    {item.range && (
                        <Box>
                            <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                                Range:{" "}
                            </Text>
                            <Text>
                                {`${item.range.near}ft.`}/{`${item.range.far}ft.`}
                            </Text>
                        </Box>
                    )}
                    <Box>
                        <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                            Damage:{" "}
                        </Text>
                        <Text>{getWeaponDamage(item, rules, character, handed)}</Text>
                    </Box>
                    {item.dmgType != null && (
                        <Box>
                            <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                                Damage Type:{" "}
                            </Text>
                            <Text>{item.dmgType}</Text>
                        </Box>
                    )}
                </React.Fragment>
            )}
            {isArmor(item) && (
                <React.Fragment>
                    <Box>
                        <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                            Armor Class:{" "}
                        </Text>
                        <Text>{item.ac + (item.acModifier ?? 0)}</Text>
                    </Box>
                </React.Fragment>
            )}
            {/* {item.acModifier != null && (
                <Box>
                    <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                        Armor Class:{" "}
                    </Text>
                    <Text>
                        {item.acModifier < 0 ? "-" : "+"}
                        {item.acModifier}
                    </Text>
                </Box>
            )} */}
            {item.weight != null && (
                <Box>
                    <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                        Weight:{" "}
                    </Text>
                    <Text>{item.weight > 0 ? `${item.weight}lb.` : "--"}</Text>
                </Box>
            )}
            {item.value != null && (
                <Box>
                    <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                        Cost:{" "}
                    </Text>
                    <Text>{`${item.value} gp`}</Text>
                </Box>
            )}
            {item.properties && (
                <Box>
                    <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                        Properties:{" "}
                    </Text>
                    <Text>{item.properties.map(o => o.name).join(", ")}</Text>
                </Box>
            )}

            {item.content && item.content.length && (
                <Box mt={2} flexDirection="column" alignItems="flex-start">
                    <Markdown>{item.content}</Markdown>
                </Box>
            )}
        </Box>
    );
};

function itemToString(item: ResolvedItem) {
    return item.quantity != null && item.quantity > 1 ? `${item.name} (x${item.quantity})` : item.name;
}

const ItemInfoContent: FunctionComponent<{
    item: ResolvedItem;
    token?: DnD5ECharacterToken | DnD5ECharacterTemplate;
    handed?: 1 | 2;
    character?: ResolvedCharacter;
    popupPlacement?: Placement;
    popupOffset?: number;
    weight?: boolean;
    actions?: (
        | {
              label: string | ((item: ResolvedItem) => string);
              action: (item: ResolvedItem) => void;
          }
        | ((item: ResolvedItem) => JSX.Element)
    )[];
}> = ({ item, token, handed, weight, character, popupPlacement, popupOffset, actions }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    const canEquip = canEquipItem(item);
    return (
        <React.Fragment>
            {renderIconForItem(item)}
            <TextPopup
                text={itemToString(item)}
                placement={popupPlacement}
                noWrap
                offset={popupOffset}
                css={{ lineHeight: theme.space[4] + "px" }}>
                <Box flexDirection="column" alignItems="flex-start">
                    <Heading mb={2} as="h6">
                        {item.name}
                    </Heading>
                    <ItemDetails item={item} character={character} handed={handed} />
                </Box>
            </TextPopup>
            {weight && (
                <React.Fragment>
                    {item.weight != null && <Text>{item.weight} lb</Text>}
                    {item.weight == null && <Text>--</Text>}
                </React.Fragment>
            )}
            <Box justifyContent="flex-end" css={{ gap: theme.space[2] }}>
                {actions?.map(o => {
                    if (typeof o === "function") {
                        return o(item);
                    }

                    const label = typeof o.label === "string" ? o.label : o.label(item);
                    return (
                        <Button
                            key={label}
                            size="s"
                            mr={1}
                            onClick={e => {
                                o.action(item);
                                e.stopPropagation();
                                e.preventDefault();
                            }}>
                            {label}
                        </Button>
                    );
                })}
                {token && !canEquip && (
                    <Box width={20} height={20}>
                        --
                    </Box>
                )}
                {token && canEquip && (
                    <Checkbox
                        id={"equipped_" + (item as ResolvedInventoryItem).id}
                        checked={!!(item as ResolvedInventoryItem).active}
                        onChange={e => {
                            dispatch(
                                equipItems(campaign, location, [token], {
                                    ids: [(item as ResolvedInventoryItem).id],
                                    active: e.target.checked,
                                })
                            );
                        }}
                    />
                )}
            </Box>
        </React.Fragment>
    );
};

export function canEquipItem(item: ResolvedItem) {
    return isArmor(item) || isWeapon(item) || isShield(item) || item.requiresAttunement || item.lights;
}

export const ItemListItem: FunctionComponent<{
    layout?: boolean;
    item: ResolvedItem;
    token?: DnD5ECharacterToken | DnD5ECharacterTemplate;
    handed?: 1 | 2;
    character?: ResolvedCharacter;
    popupPlacement?: Placement;
    popupOffset?: number;
    canSelect?: boolean;
    isSelected?: boolean;
    isNextSelected?: boolean;
    isPrevSelected?: boolean;
    isActive?: boolean;
    isFocused?: boolean;
    weight?: boolean;
    isDynamicWidth?: boolean;
    isContextMenuTarget?: boolean;
    onContextMenu?: React.MouseEventHandler;
    actions?: (
        | {
              label: string | ((item: ResolvedItem) => string);
              action: (item: ResolvedItem) => void;
          }
        | ((item: ResolvedItem) => JSX.Element)
    )[];
}> = ({
    layout,
    item,
    token,
    handed,
    character,
    popupPlacement,
    popupOffset,
    canSelect,
    isSelected,
    isNextSelected,
    isPrevSelected,
    isActive,
    isFocused,
    isContextMenuTarget,
    weight,
    actions,
    isDynamicWidth,
    onContextMenu,
}) => {
    const showFocused = isFocused || (document.hasFocus() && isContextMenuTarget);
    const bg = canSelect
        ? isSelected
            ? showFocused
                ? "grayscale.6"
                : "grayscale.8"
            : isContextMenuTarget
            ? "grayscale.8"
            : undefined
        : undefined;
    return (
        <DraggableGrid
            draggableId={`${isToken(token) ? token.id : token?.templateId}_${getRuleKey(item)}`}
            type="DnD5E_Item"
            data={{ items: [item], token: token }}
            layout={layout}
            dragOverlay
            dragChildren={
                <MotionBox layout="position">
                    {renderIconForItem(item)}
                    <Box pl={3}>
                        <Truncate lines={1}>{item.name}</Truncate>
                    </Box>
                </MotionBox>
            }
            gridTemplateColumns={`max-content ${
                isDynamicWidth ? "max-content" : "minmax(0px, 1fr)"
            } max-content max-content`}
            fullWidth
            p={2}
            borderTopLeftRadius={isSelected && isPrevSelected ? 0 : 3}
            borderTopRightRadius={isSelected && isPrevSelected ? 0 : 3}
            borderBottomLeftRadius={isSelected && isNextSelected ? 0 : 3}
            borderBottomRightRadius={isSelected && isNextSelected ? 0 : 3}
            bg={bg}
            onContextMenu={onContextMenu}
            css={{
                gap: theme.space[2],
                cursor: canSelect ? "pointer" : undefined,
                outline: bg == null && isActive ? `2px solid ${theme.colors.grayscale[6]}` : undefined,
                textShadow: bg != null ? "none" : "inherit",
                ":hover": {
                    background: canSelect
                        ? isSelected && showFocused
                            ? theme.colors.grayscale[6]
                            : theme.colors.grayscale[7]
                        : "initial",
                    textShadow: "none",
                    zIndex: 1,
                },
            }}
            initial={defaultInitial}
            animate={defaultAnimate}
            exit={defaultExit}>
            <ItemInfoContent
                item={item}
                token={token}
                handed={handed}
                character={character}
                popupPlacement={popupPlacement}
                popupOffset={popupOffset}
                weight={weight}
                actions={actions}
            />
        </DraggableGrid>
    );
};

export const ItemActions: FunctionComponent<{ item: ResolvedItem } & ExtractProps<typeof Box>> = ({
    item,
    ...props
}) => {
    const { system, campaign, location } = useLocation();
    const { focusedToken } = useAppState();
    const token = getToken(campaign, location?.id, focusedToken);
    const dispatch = useDispatch();
    const addNotification = useNotifications();

    return (
        <Box {...props}>
            <MarkdownActions layout markdown={() => `:item{id="${getRuleKey(item)}"}`} mr={2} />
            <AnimatePresence>
                {isDnD5ECharacterToken(token) && (
                    <MotionButton
                        onClick={() => {
                            dispatch(addItems(campaign, location, [token], { items: [item] }));
                            addNotification({
                                content: (
                                    <Message
                                        variant="success"
                                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                                        Added {item.name} to {system.getDisplayName(token, campaign)}'s inventory
                                    </Message>
                                ),
                                canDismiss: true,
                                timeout: 2000,
                                showLife: true,
                            });
                        }}
                        size="s"
                        tooltip={`Send to ${system.getDisplayName(token, campaign)}\\0027s inventory`}
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <ArrowRightIcon />
                        <InventoryIcon size={20 as any} />
                    </MotionButton>
                )}
            </AnimatePresence>
        </Box>
    );
};

export const ItemInfo: FunctionComponent<{
    item: ResolvedItem;
    handed?: 1 | 2;
    character?: ResolvedCharacter;
    popupPlacement?: Placement;
    popupOffset?: number;
    isInOverlay?: boolean;
    canSelect?: boolean;
    onDragStart?: (e: React.DragEvent) => DraggedItems;
    actions?:
        | (
              | {
                    label: string | ((item: ResolvedItem) => string);
                    action: (item: ResolvedItem) => void;
                }
              | ((item: ResolvedItem) => JSX.Element)
          )[]
        | undefined;
}> = ({ item, actions, isInOverlay, onDragStart }) => {
    const header: (string | React.ReactElement)[] =
        actions?.map(o =>
            typeof o === "function" ? (
                o(item)
            ) : (
                <Button
                    key={typeof o.label === "string" ? o.label : o.label(item)}
                    size="s"
                    onClick={() => o.action(item)}>
                    {typeof o.label === "string" ? o.label : o.label(item)}
                </Button>
            )
        ) ?? [];

    const title = item.quantity != null && item.quantity > 1 ? `${item.name} (x${item.quantity})` : item.name;
    return (
        <DraggableBox
            fullWidth
            draggableId={getRuleKey(item)}
            dragOverlay
            dragChildren={
                onDragStart ? (
                    <InOverlayCard
                        flexDirection="row"
                        css={{ gap: theme.space[2] }}
                        borderRadius={3}
                        fullWidth
                        bg={isInOverlay ? undefined : "grayscale.9"}>
                        {renderIconForItem(item)}
                        <span>{itemToString(item)}</span>
                    </InOverlayCard>
                ) : undefined
            }
            type="DnD5E_Item"
            data={onDragStart}
            dragDisabled={!onDragStart}>
            <FeatureExpander title={title} subtitle={getItemSubtitle(item)} header={header} isInOverlay={isInOverlay}>
                <ItemActions item={item} mb={2} />
                <ItemDetails item={item} />
            </FeatureExpander>
        </DraggableBox>
    );
};

const DeleteItemsDialog: FunctionComponent<{
    token: DnD5ECharacterToken | DnD5ECharacterTemplate;
    pendingDelete: ResolvedInventoryItem[] | undefined;
    onClose: () => void;
}> = ({ token, pendingDelete, onClose }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    let content: React.ReactNode;
    if (pendingDelete && pendingDelete.length > 0) {
        const pendingDeleteLabel = pendingDelete.map(o => o.name).join(", ");

        content = (
            <FocusTrap focusTrapOptions={{ initialFocus: "#modalDefault", allowOutsideClick: true }}>
                <Box flexDirection="column" p={3} maxWidth={theme.space[13]} alignItems="flex-start">
                    <Heading as="h4" mb={3} css={{ textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden" }}>
                        Delete {pendingDelete.length === 1 ? pendingDeleteLabel : "items"}?
                    </Heading>
                    <Text>Are you sure you want to delete {pendingDeleteLabel}?</Text>
                    <Box mt={3} flexDirection="row" fullWidth justifyContent="flex-end" css={{ gap: theme.space[2] }}>
                        <Button
                            id="modalDefault"
                            variant="primary"
                            onClick={() => {
                                // dispatch(deleteLevel(campaign, location!, pendingDelete!));
                                dispatch(removeItems(campaign, location, [token], pendingDelete));
                                onClose();
                            }}>
                            Delete
                        </Button>
                        <Button variant="secondary" onClick={() => onClose()}>
                            Keep
                        </Button>
                    </Box>
                </Box>
            </FocusTrap>
        );
    }

    return (
        <ModalDialog
            onRequestClose={() => {
                onClose();
            }}
            isOpen={pendingDelete != null && pendingDelete.length > 0}>
            {content}
        </ModalDialog>
    );
};

// interface ItemMenuContextValue {
//     showContextMenu: (items: ResolvedItem[], anchorRef: RefObject<Element>) => void;
//     current?: ResolvedItem[];
// }

// const ItemMenuContext = React.createContext(undefined as any as ItemMenuContextValue);

export const ItemList: FunctionComponent<
    {
        items?: ResolvedItem[];
        token?: DnD5ECharacterToken | DnD5ECharacterTemplate;
        character?: ResolvedCharacter;
        noSearch?: boolean;
        popupPlacement?: Placement;
        popupOffset?: number;
        multiSelect?: boolean;
        actions?: (item: ResolvedItem) => (
            | {
                  label: string | ((item: ResolvedItem) => string);
                  action: (item: ResolvedItem) => void;
              }
            | ((item: ResolvedItem) => JSX.Element)
        )[];
    } & BoxProps
> = ({ items, token, character, noSearch, popupPlacement, popupOffset, actions, multiSelect, ...boxProps }) => {
    const [maxItems, setMaxItems] = useState(20);
    const [nameFilter, setNameFilter] = useState<string | undefined>(undefined);
    const rules = useRules();
    const dispatch = useDispatch();
    const { system, campaign, location } = useLocation();

    if (!items) {
        items = rules.items.items.allResolved;
    }

    const regx = nameFilter ? new RegExp(escapeRegExp(nameFilter), "i") : undefined;
    const filter = (o: ResolvedItem) => {
        if (regx != null && o.name.search(regx) < 0) {
            return false;
        }

        return true;
    };

    const filteredItems = items.filter(filter);

    // TODO: Might have to delegate this to users, pass through selectedItems/setSelectedItems?
    const [selectedItems, setSelectedItems] = useState<ResolvedItem[]>([]);

    const [pendingDelete, setPendingDelete] = useState<ResolvedInventoryItem[]>();
    const [menuProps, toggleMenu] = useMenuState({ transition: true });
    const anchorRef = useRef<Element>();

    // const context = useMemo<ItemMenuContextValue>(() => {
    //     return {
    //         showContextMenu: (items, anchorRef) => {
    //             // TODO: Implement showing context menu
    //         },
    //         current: selectedItems,
    //     };
    // }, [selectedItems]);

    const itemKey = (item: ResolvedItem) => {
        return isInventoryItem(item) ? item.id : getRuleKey(item);
    };

    const updatedSelectedItems = selectedItems
        .map(o => {
            const key = itemKey(o);
            return items?.find(p => itemKey(p) === key);
        })
        .filter(o => !!o) as ResolvedItem[];

    const equippableItems = character
        ? (updatedSelectedItems.filter(
              o => isInventoryItem(o) && canEquipItem(o) && !o.active
          ) as ResolvedInventoryItem[])
        : [];
    const unequippableItems = character
        ? (updatedSelectedItems.filter(
              o => isInventoryItem(o) && canEquipItem(o) && o.active
          ) as ResolvedInventoryItem[])
        : [];
    const attunableItems =
        character && (character.attunedItems.length ?? 0) < MAX_ATTUNED_ITEMS
            ? (updatedSelectedItems.filter(
                  o => isInventoryItem(o) && o.requiresAttunement && !o.attuned
              ) as ResolvedInventoryItem[])
            : [];
    const unattunableItems = character
        ? (updatedSelectedItems.filter(
              o => isInventoryItem(o) && o.requiresAttunement && o.attuned
          ) as ResolvedInventoryItem[])
        : [];

    // Find all the available player tokens, if we have a token.
    const playerTokens = useMemo(() => {
        if (token) {
            const playerTokens: TokenTemplate[] = [];
            for (let tokenTemplate of Object.values(campaign.tokens)) {
                if (
                    tokenTemplate.owner &&
                    tokenTemplate.templateId !== token.templateId &&
                    (campaign.players[tokenTemplate.owner]?.role ?? "Player") === "Player"
                ) {
                    playerTokens.push(tokenTemplate);
                }
            }

            return playerTokens;
        }

        return undefined;
    }, [campaign.tokens, campaign.players, token]);

    return (
        <LobotomizedBox fullWidth flexDirection="column" alignItems="stretch" {...(boxProps as any)}>
            {!noSearch && (
                <MotionInput
                    layout
                    initial={defaultInitial}
                    animate={defaultAnimate}
                    exit={defaultExit}
                    placeholder="Filter items by name"
                    onChange={e => setNameFilter(e.target.value ? e.target.value : undefined)}
                />
            )}
            <AnimatePresence>
                {filteredItems.length === 0 && (
                    <motion.div layout initial={defaultInitial} animate={defaultAnimate} exit={defaultExit}>
                        <Text fontStyle="italic">{nameFilter ? `No items matching "${nameFilter}"` : `No items`}</Text>
                    </motion.div>
                )}
            </AnimatePresence>
            <ListBox
                flexDirection="column"
                alignItems="stretch"
                selectActive
                multiSelect={multiSelect}
                items={filteredItems!.slice(0, maxItems)}
                itemKey={item => {
                    return isInventoryItem(item) ? item.id : getRuleKey(item);
                }}
                selectedItems={selectedItems}
                onKeyDown={e => {
                    if (token && e.key === "Delete") {
                        setPendingDelete(selectedItems.filter(o => isInventoryItem(o)) as ResolvedInventoryItem[]);
                        e.preventDefault();
                    }
                }}
                onSelectionChanged={items => {
                    setSelectedItems(items);
                }}>
                {({ item, key, active, focused, selected, nextSelected, prevSelected }) => (
                    <ItemListItem
                        key={key}
                        layout
                        item={item}
                        canSelect
                        token={token}
                        character={character}
                        weight
                        isActive={active}
                        isFocused={focused}
                        isSelected={selected}
                        isNextSelected={nextSelected}
                        isPrevSelected={prevSelected}
                        isContextMenuTarget={(menuProps.state === "opening" || menuProps.state === "open") && selected}
                        popupPlacement={popupPlacement}
                        popupOffset={popupOffset}
                        onContextMenu={e => {
                            e.preventDefault();
                            anchorRef.current = e.currentTarget;
                            toggleMenu(true);
                        }}
                        actions={actions ? actions(item) : undefined}
                    />
                )}
            </ListBox>
            {/* <Grid
                position="relative"
                css={{ gap: theme.space[2], alignItems: "center" }}
                gridTemplateColumns="max-content 1fr max-content max-content">
                <Text></Text>
                <Text>Name</Text>
                <Text>Wt</Text>
                <Text></Text>
                {filteredItems.slice(0, maxItems).map(item => (
                    <ItemInfoContent
                        key={isInventoryItem(item) ? item.id : getRuleKey(item)}
                        item={item}
                        weight
                        actions={actions ? actions(item) : undefined}
                        character={character}
                        popupPlacement={popupPlacement}
                        popupOffset={popupOffset}
                    />
                ))}
            </Grid> */}
            {maxItems < filteredItems.length && (
                <MotionBox
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0 }}>
                    <Button onClick={() => setMaxItems(maxItems + 20)}>Load more…</Button>
                </MotionBox>
            )}

            {token && (
                <DeleteItemsDialog
                    pendingDelete={pendingDelete}
                    token={token}
                    onClose={() => setPendingDelete(undefined)}
                />
            )}

            <ControlledMenu
                align="center"
                {...menuProps}
                onClose={() => {
                    toggleMenu(false);
                }}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onItemClick={e => {
                    e.syntheticEvent.stopPropagation();
                    e.syntheticEvent.preventDefault();
                }}
                anchorRef={anchorRef as any}>
                {token && (
                    <React.Fragment>
                        {playerTokens && (
                            <SubMenu label="Send to">
                                {playerTokens.map(o => (
                                    <MenuItem
                                        key={o.templateId}
                                        onClick={() =>
                                            dispatch(
                                                addItems(campaign, location, [], {
                                                    items: selectedItems,
                                                    from: token,
                                                    to: o,
                                                })
                                            )
                                        }>
                                        {system.getDisplayName(o, campaign)}
                                    </MenuItem>
                                ))}
                            </SubMenu>
                        )}

                        <MenuItem
                            onClick={() => {
                                const markdown = updatedSelectedItems
                                    .map(o => `:item{id="${getRuleKey(o)}"}`)
                                    .join(", ");
                                navigator.clipboard.writeText(markdown);
                            }}>
                            Copy markdown
                        </MenuItem>

                        {equippableItems.length > 0 && (
                            <MenuItem
                                onClick={() => {
                                    dispatch(
                                        equipItems(campaign, location, [token], {
                                            ids: equippableItems.map(o => o.id),
                                            active: true,
                                        })
                                    );
                                }}>
                                Equip
                            </MenuItem>
                        )}
                        {unequippableItems.length > 0 && (
                            <MenuItem
                                onClick={() => {
                                    dispatch(
                                        equipItems(campaign, location, [token], {
                                            ids: unequippableItems.map(o => o.id),
                                            active: false,
                                        })
                                    );
                                }}>
                                Unequip
                            </MenuItem>
                        )}

                        {attunableItems.length > 0 && (
                            <MenuItem
                                onClick={() => {
                                    dispatch(
                                        attuneItems(campaign, location, [token], {
                                            ids: attunableItems.map(o => o.id),
                                            attuned: true,
                                        })
                                    );
                                }}>
                                Attune
                            </MenuItem>
                        )}
                        {unattunableItems.length > 0 && (
                            <MenuItem
                                onClick={() => {
                                    dispatch(
                                        attuneItems(campaign, location, [token], {
                                            ids: unattunableItems.map(o => o.id),
                                            attuned: false,
                                        })
                                    );
                                }}>
                                Unattune
                            </MenuItem>
                        )}

                        <MenuItem
                            onClick={() => {
                                setPendingDelete(
                                    selectedItems.filter(o => isInventoryItem(o)) as ResolvedInventoryItem[]
                                );
                            }}>
                            Delete
                        </MenuItem>
                    </React.Fragment>
                )}
            </ControlledMenu>
        </LobotomizedBox>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { Box, Grid } from "./primitives";
import { asField, IAsFieldProps } from "./Form";
import { Button } from "./Button";
import { theme } from "../design";
import { defaultAnimate, defaultExit, defaultInitial, MotionBox, MotionImage, MotionLobotomizedBox } from "./motion";
import { ImageType, Token, TokenImageMetadata, TokenTemplate } from "../store";
import { ExtractProps, resolveUri, tokenImageSize, useVttApp } from "./common";
import { useAppState, useDispatch, useLocation } from "./contexts";
import { useLocalSetting } from "./utils";
import { artFilterSetting } from "./Sidebar/ArtLibrary";
import { defaultSections, Pages } from "./Sidebar";
import { modifyToken, removeTokenImage } from "../actions/token";
import { ListBox } from "./ListBox";
import { dragDropPalette } from "./draggable";

export interface TokenImageProps extends ExtractProps<typeof Box> {
    token: TokenTemplate | Token;
    isDragOver?: boolean;
    isDragActive?: boolean;
    useFallback?: boolean;
    allowEdit?: boolean;
}

const artSection = defaultSections.find(o => o.id === Pages.Art)!;

const TokenImageEditing: FunctionComponent<
    { token: TokenTemplate | Token } & ExtractProps<typeof MotionLobotomizedBox>
> = ({ token, ...boxProps }) => {
    const { searchPropertiesSection, setSearchPropertiesSection } = useAppState();
    const { isSearchExpanded, setIsSearchExpanded } = useVttApp();
    const [artFilter, setArtFilter] = useLocalSetting(artFilterSetting);
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const isFindingTokens =
        isSearchExpanded && searchPropertiesSection === artSection && artFilter?.type === ImageType.Token;

    return (
        <MotionLobotomizedBox layout="position" flexDirection="column" alignItems="stretch" {...boxProps}>
            <Button
                disabled={isFindingTokens}
                onClick={() => {
                    setArtFilter({ type: ImageType.Token });
                    setSearchPropertiesSection(artSection);
                    setIsSearchExpanded(true);
                }}>
                Find an image…
            </Button>
            <Button
                disabled={!token.imageUri}
                onClick={() => {
                    dispatch(removeTokenImage(campaign, location, [token], token.imageUri!));
                }}>
                Remove image
            </Button>
        </MotionLobotomizedBox>
    );
};

const SingleTokenImage: FunctionComponent<
    {
        uri?: string;
        selected?: boolean;
        isDragOver?: boolean;
        isDragActive?: boolean;
        hover?: boolean;
    } & ExtractProps<typeof MotionBox>
> = ({ isDragActive, isDragOver, uri, selected, hover, ...props }) => {
    return (
        <MotionBox
            layout
            width={tokenImageSize}
            height={tokenImageSize}
            initial={defaultInitial}
            animate={defaultAnimate}
            exit={defaultExit}
            borderRadius={3}
            {...props}
            css={{
                overflow: "hidden",
                background: isDragActive
                    ? isDragOver
                        ? dragDropPalette[1]
                        : dragDropPalette[0]
                    : selected
                    ? theme.colors.accent[2]
                    : theme.colors.grayscale[9],
                ":hover": hover
                    ? {
                          background: selected ? theme.colors.accent[2] : theme.colors.grayscale[7],
                      }
                    : undefined,
            }}>
            <MotionImage
                src={resolveUri(uri)}
                responsive
                fullWidth
                fullHeight
                draggable={false}
                animate={{ opacity: isDragActive ? 0.5 : 1 }}
            />
        </MotionBox>
    );
};

export const TokenImage: FunctionComponent<
    {
        token: Token | TokenTemplate;
        selected?: boolean;
        isDragOver?: boolean;
        isDragActive?: boolean;
        hover?: boolean;
    } & ExtractProps<typeof MotionBox>
> = ({ token, ...props }) => {
    let metadata = token.images?.find(o => o.uri === token.imageUri);
    const uri = metadata?.thumbnailUri ?? metadata?.uri ?? token.imageUri;
    return <SingleTokenImage uri={resolveUri(uri)} {...props} />;
};

export const TokenImageEditor: FunctionComponent<TokenImageProps> = ({
    token,
    useFallback,
    isDragOver,
    isDragActive,
    allowEdit,
    ...boxProps
}) => {
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();

    let metadata = token.images?.find(o => o.uri === token.imageUri);
    if (!metadata && useFallback && token.images?.length) {
        metadata = token.images[0];
    }

    let items = token.images ?? [];
    if (isDragActive) {
        items = items.slice();
        items.push({ uri: "" });
    }

    return (
        <Grid
            gridTemplateColumns="minmax(0, 1fr) auto"
            justifyContent="flex-start"
            alignItems="flex-start"
            {...boxProps}>
            <ListBox<TokenImageMetadata>
                items={items}
                selectedItems={metadata ? [metadata] : undefined}
                selectActive
                itemKey={o => o.uri}
                css={{ gap: theme.space[2], flexWrap: "wrap" }}
                onSelectionChanged={o => {
                    dispatch(modifyToken(campaign, location, token, { imageUri: o[0]?.uri }, true));
                }}>
                {({ item, selected }) => (
                    <SingleTokenImage
                        uri={item.thumbnailUri ?? item.uri}
                        isDragActive={isDragActive}
                        isDragOver={isDragOver}
                        selected={selected}
                        hover
                    />
                )}
            </ListBox>
            {allowEdit && <TokenImageEditing token={token} ml={items.length ? 2 : 0} />}
        </Grid>
    );
};

// Simple wrapper to strip the fullWidth prop out when creating a token image field.
const TokenImageFieldWrapper: FunctionComponent<TokenImageProps> = props => {
    const { fullWidth, ...rest } = props;
    return (
        <Box fullWidth={fullWidth} justifyContent="flex-start">
            <TokenImageEditor {...rest} />
        </Box>
    );
};

export const TokenImageField = asField<HTMLDivElement, TokenImageProps & IAsFieldProps>(TokenImageFieldWrapper);

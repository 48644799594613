import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import { Howl } from "howler";
import { Vector2 } from "three";
import { useLocalSetting } from "../utils";
import { ambienceMutedSetting, ambienceVolumeSetting } from "../../common";
import { resolveUri } from "../common";

// TODO: This is very similar to AmbientTrack from campaignhost.tsx.
export const RelativeAudioNode: FunctionComponent<{
    uri: string;
    relativePos: Vector2;
    volume: number;
    radius: number;
}> = ({ uri, relativePos, volume, radius }) => {
    const [howl, setHowl] = useState<Howl>();
    const howlRef = useRef<Howl>();

    const [isMutedSetting] = useLocalSetting(ambienceMutedSetting, false);
    const [volumeSetting] = useLocalSetting(ambienceVolumeSetting, 100);

    const volumeRef = useRef<number>();
    volumeRef.current = (volume / 100) * (volumeSetting / 100);

    // const refDistance = 1;
    // const rolloffFactor = 1;
    // const maxDistance = radius;
    // const distance = relativePos.length();
    // // const volumeInverse = refDistance / (refDistance + rolloffFactor * (Math.max(distance, refDistance) - refDistance));
    // const volumeLinear = 1 - rolloffFactor * (distance - refDistance) / (maxDistance - refDistance)
    // console.log(`Volume at ${distance}/${maxDistance}: ${volumeLinear}`);

    useEffect(() => {
        const resolvedUri = resolveUri(uri);
        let finalUri = resolvedUri;

        // If the url is for an http address (which the azurite emulator will be), then howler will disable webaudio api
        // because of mixed content warnings, and switch back to basic html5 audio, which means no relative positioning/pannernode
        // stuff. So, we proxy it so that it comes from same origin.
        let format: string[] | undefined;
        if (resolvedUri.startsWith("http://")) {
            let rx = /\.([^.]+)$/.exec(resolvedUri.split("?", 1)[0]);
            if (rx) {
                format = [rx[1].toLowerCase()];
            }

            finalUri = `api/storage/proxy?uri=${encodeURIComponent(resolvedUri)}`;
        }

        const h = new Howl({
            src: [finalUri],
            autoplay: false,
            loop: true,
            format: format,
            volume: 0,
            mute: isMutedSetting,
            onload: () => {
                h.play();
                h.fade(0, volumeRef.current!, 500);
            },
        });

        // Have to do this twice because it doesn't take the first time, seems like a bug in howler.
        h.pannerAttr({});
        h.pannerAttr({
            maxDistance: radius,
            distanceModel: "linear",
            panningModel: "HRTF",
            refDistance: 1,
            rolloffFactor: 1,
        });
        h.pos(relativePos.x, -relativePos.y, 0);

        howlRef.current = h;
        setHowl(h);

        return () => {
            h.on("fade", () => h.unload());
            h.fade(volumeRef.current!, 0, 500);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uri]);

    useEffect(() => {
        if (howl) {
            howl.mute(isMutedSetting);
        }
    }, [isMutedSetting, howl]);

    // When the volume changes, update the howl volume if it is running.
    useEffect(() => {
        if (howl && howl.playing()) {
            howl.fade(howl.volume(), (volume / 100) * (volumeSetting / 100), 500);
        }
    }, [howl, volume, volumeSetting]);

    // When the relative position of the listener changes, update the audio position.
    useEffect(() => {
        if (howl) {
            howl.pos(relativePos.x, -relativePos.y, 0);
        }
    }, [howl, relativePos]);

    // Fade out the old howl when it changes/is unmounted.
    useEffect(() => {
        return () => {
            const h = howlRef.current;
            if (h) {
                h.once("fade", () => h.stop());
                h.fade(h.volume(), 0, 1000);
            }
        };
    }, []);

    return <React.Fragment />;
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Box, Stack, Heading, Text } from "../primitives";

import { VTTStore, UserInfo } from "../../store";

import SocialButton, { SocialNetworkType } from "./components";
import { ColumnGrid } from "../primitives/ColumnGrid";

interface LoginProps {
    userInfo: UserInfo;
}

const Login: FunctionComponent<LoginProps> = ({ userInfo }) => {
    return (
        <ColumnGrid css={{ gridArea: "main" }}>
            <Stack
                flexDirection="column"
                gap={3}
                css={{
                    gridColumn: "6 / span 3",
                }}>
                <Heading as="h2" fontWeight="bold" mb={5}>
                    Sign in to get started
                </Heading>
                <Text fontSize={2} color="grayscale.3" mb={5}>
                    A world of virtual gaming is at your fingertips. Sign in below to start or join a campaign.
                </Text>
                <Stack as="ul" mb={5} gap={3}>
                    {Object.keys(SocialNetworkType)
                        .filter(key => isNaN(Number(key)))
                        .map(s => (
                            <Box as="li" key={s}>
                                <SocialButton network={s} />
                            </Box>
                        ))}
                </Stack>
                <Text fontSize={0} color="grayscale.2">
                    By using VTT, you agree to our Code of Conduct, Privacy Policy and Terms of Service
                </Text>
            </Stack>
        </ColumnGrid>
    );
};

export default connect((state: VTTStore) => {
    return {
        userInfo: state.userInfo,
    };
})(Login);

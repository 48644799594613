import { LocalPixelPosition, Point, LocalRect, GridPosition, PositionType } from "./position";
import {
    getPointsBounds,
    rotate,
    localOrigin,
    localPoint,
    getCenterPoint,
    pointsEqual,
    getArea,
    degToRad,
    ILocalGrid,
    getBounds,
    angleOfVector,
    lengthOfVector,
    pointAlongLine,
} from "./grid";
import { EllipseCurve } from "three";
import { ClipperLibWrapper, ClipType, PolyFillType } from "js-angusj-clipper";
import { Campaign, Location, NoteBase, resolveToken, Token, WithLevel } from "./store";
import { applyOverrides } from "./reducers/common";
import { DeepPartial } from "./common";
import { MonsterFilter } from "./systems/dnd5e/common";

export type LineAnnotationType = "wall";
export type DoorAnnotationType = "default" | "slide";

export type AnnotationType = "line" | "ellipse" | "rect" | "door" | "window" | "cone" | "linearea" | "target";

export interface Annotation extends NoteBase {
    id: string;
    label?: string;
    type: AnnotationType;
    userId: string;
    pos?: WithLevel<LocalPixelPosition>;
    rotation?: number;
    isFilled?: boolean;
    buildOnly?: boolean;
    showGrid?: boolean;

    /**
     * The ID of the token that this annotation should be centered on.
     */
    centerOn?: string;

    /**
     * If true, then the UI will not enable editing the basic properties of the annotation other than its
     * position. Any system specific behaviour may still be edited at the system's discretion.
     * This can be used to create templates for things with set sizes that the user cannot then modify (e.g. spell effects).
     */
    disableEdit?: boolean;

    /**
     * If the annotation is related to a particular token (i.e. it's a spell cast by a particular
     * character) then that link can be specified here.
     */
    tokenId?: string;

    /**
     * Filters the valid targets for the annotation.
     */
    targetFilter?: TargetFilter;

    /**
     * A token that enters this annotation will be transported to the specified level.
     */
    goToLevel?: string;
}

export interface AnnotationOperations {
    getBounds(
        annotation: Annotation,
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        tokenOverrides?: { [id: string]: DeepPartial<Token> }
    ): LocalRect | undefined;
    getCenter?: (
        annotation: Annotation,
        pos: LocalPixelPosition | undefined,
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        tokenOverrides?: { [id: string]: DeepPartial<Token> }
    ) => LocalPixelPosition;
    getObstructionPolygon(
        annotation: ObstructingAnnotation,
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        tokenOverrides?: { [id: string]: DeepPartial<Token> }
    ): { pos: LocalPixelPosition; points: Point[]; isClosed?: boolean } | undefined;
    getCoveragePolygon(
        annotation: Annotation,
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        tokenOverrides?: { [id: string]: DeepPartial<Token> },
        onlyIfArea?: boolean
    ): LocalPixelPosition[] | undefined;
    canBeModified(annotation: Annotation): boolean;
}

export function getAnnotationPos(
    annotation: Annotation,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    tokenOverrides?: { [id: string]: DeepPartial<Token> }
): WithLevel<LocalPixelPosition> {
    if (annotation.pos) {
        return annotation.pos;
    }

    if (annotation.centerOn) {
        let token = location.tokens[annotation.centerOn];
        if (!token) {
            console.error(`The annotation ${annotation.id} is centered on a token that does not exist.`);
            return { type: PositionType.LocalPixel, x: 0, y: 0, level: location.defaultLevel };
        }

        token = applyOverrides(token, tokenOverrides);

        // Need to make sure we get the scale from the template if there is one.
        let scale = token.scale;
        if (scale == null && token.templateId) {
            const template = campaign.tokens[token.templateId];
            if (template) {
                scale = template.scale;
            }
        }

        // We should have everything we need to find the pos now.
        const tokenCenterPos = grid.toLocalCenterPoint(token.pos, scale);

        // Work out the center of the annotation relative to its pos, then adjust the token pos by that to get the final annotation pos.
        // If the annotation doesn't implement getCenter it means that the pos of the annotation IS the center.
        const annotationCenter = annotationOperations[annotation.type]?.getCenter?.(
            annotation,
            localOrigin,
            campaign,
            location,
            grid,
            tokenOverrides
        );
        if (annotationCenter) {
            tokenCenterPos.x -= annotationCenter.x;
            tokenCenterPos.y -= annotationCenter.y;
        }

        return { ...tokenCenterPos, level: token.pos.level };
    }

    throw new Error(`The annotation ${annotation.id} does not have a pos or a target token.`);
}

const annotationOperations: { [type in AnnotationType]: AnnotationOperations } = {
    line: {
        getBounds: (annotation, campaign, location, grid, tokenOverrides) => {
            return getPointsBounds(
                getAnnotationPos(annotation, campaign, location, grid, tokenOverrides),
                getLinePoints(annotation as LineAnnotation)
            );
        },
        getCenter: (annotation, pos, campaign, location, grid, tokenOverrides) => {
            const line = annotation as LineAnnotation;
            pos = pos ?? getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return getCenterPoint(line.points.map(o => localPoint(pos!.x + o.x, pos!.y + o.y)));
        },
        getObstructionPolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            const line = annotation as LineAnnotation;
            return {
                pos: getAnnotationPos(annotation, campaign, location, grid, tokenOverrides),
                points: getLinePoints(line),
                isClosed: line.isClosed,
            };
        },
        getCoveragePolygon: (annotation, campaign, location, grid, tokenOverrides, onlyIfArea) => {
            const line = annotation as LineAnnotation;
            if (onlyIfArea) {
                if (
                    line.points.length < 3 ||
                    line.subtype === "wall" ||
                    (!line.isClosed && !pointsEqual(line.points[0], line.points[line.points.length - 1]))
                ) {
                    return undefined;
                }

                var area = getArea(line.points);
                if (area === 0) {
                    return undefined;
                }
            }

            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            var points = getLinePoints(line);
            return points.map(o => localPoint(pos.x + o.x, pos.y + o.y));
        },
        canBeModified: annotation => {
            return true;
        },
    },
    rect: {
        getBounds: (annotation, campaign, location, grid, tokenOverrides) => {
            const rect = annotation as RectAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            if (annotation.rotation == null || annotation.rotation === 0) {
                return {
                    type: pos.type,
                    x: rect.width < 0 ? pos.x + rect.width : pos.x,
                    y: rect.height < 0 ? pos.y + rect.height : pos.y,
                    width: Math.abs(rect.width),
                    height: Math.abs(rect.height),
                };
            }

            let points = getRectPoints(rect);
            return getPointsBounds(pos, points);
        },
        getCenter: (annotation, pos, campaign, location, grid, tokenOverrides) => {
            const rect = annotation as RectAnnotation;
            pos = pos ?? getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return localPoint(pos.x + rect.width / 2, pos.y + rect.height / 2);
        },
        getObstructionPolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            const rect = annotation as RectAnnotation;
            return {
                pos: getAnnotationPos(annotation, campaign, location, grid, tokenOverrides),
                points: getRectPoints(rect),
                isClosed: true,
            };
        },
        getCoveragePolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            const rect = annotation as RectAnnotation;
            if (rect.width === 0 && rect.height === 0) {
                return undefined;
            }

            // TODO: All these points get created multiple times just so we can convert them to local points.
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return getRectPoints(rect, pos).map(o => localPoint(o.x, o.y));
        },
        canBeModified: annotation => {
            const rect = annotation as RectAnnotation;

            const isXFixed = rect.minWidth != null && rect.maxWidth != null && rect.minWidth === rect.maxWidth;
            const isYFixed = rect.minHeight != null && rect.maxHeight != null && rect.minHeight === rect.maxHeight;
            return !(isXFixed && isYFixed);
        },
    },
    ellipse: {
        getBounds: (annotation, campaign, location, grid, tokenOverrides) => {
            const ellipse = annotation as EllipseAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return {
                type: pos.type,
                x: pos.x - ellipse.radiusX,
                y: pos.y - ellipse.radiusY,
                width: 2 * ellipse.radiusX,
                height: 2 * ellipse.radiusY,
            };
        },
        getObstructionPolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            throw new Error("Ellipses do not support obstruction.");
        },
        getCoveragePolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            const ellipse = annotation as EllipseAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            const ellipseCurve = new EllipseCurve(
                pos.x,
                pos.y,
                ellipse.radiusX,
                ellipse.radiusY,
                0,
                Math.PI * 2,
                false,
                ellipse.rotation != null ? degToRad(ellipse.rotation) : 0
            );
            const points = ellipseCurve.getPoints(64).map(o => localPoint(o.x, o.y));
            return points;
        },
        canBeModified: annotation => {
            const ellipse = annotation as EllipseAnnotation;
            const isXFixed =
                ellipse.minRadiusX != null && ellipse.maxRadiusX != null && ellipse.minRadiusX === ellipse.maxRadiusX;
            const isYFixed =
                ellipse.minRadiusY != null && ellipse.maxRadiusY != null && ellipse.minRadiusY === ellipse.maxRadiusY;
            return !(isXFixed && isYFixed);
        },
    },
    door: {
        getBounds: (annotation, campaign, location, grid, tokenOverrides) => {
            const door = annotation as DoorAnnotation;
            const origin = { x: 0, y: 0 };

            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            if (door.subtype === "slide") {
                // Not sure if we need to report the opened bounds or just stick with the doorway bounds.
                // const offset = pointAlongLine(door.point, origin, (door.open ?? 0) * lengthOfVector(door.point));
                // offset.x -= door.point.x;
                // offset.y -= door.point.y;
                return getPointsBounds(pos, [door.point]);
            }

            return getPointsBounds(pos, [origin, door.point, rotate(door.point, origin, door.invert ? 90 : 270)]);
        },
        getObstructionPolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            const door = annotation as DoorAnnotation;
            const origin = { x: 0, y: 0 };

            if (door.subtype === "slide") {
                const offset = pointAlongLine(door.point, origin, (door.open ?? 0) * lengthOfVector(door.point));
                offset.x -= door.point.x;
                offset.y -= door.point.y;

                return {
                    pos: getAnnotationPos(annotation, campaign, location, grid, tokenOverrides),
                    points: [offset, { x: door.point.x + offset.x, y: door.point.y + offset.y }],
                    isClosed: false,
                };
            }

            return {
                pos: getAnnotationPos(annotation, campaign, location, grid, tokenOverrides),
                points: [
                    origin,
                    door.rotation != null
                        ? rotate(door.point, origin, door.invert ? door.rotation : 360 - door.rotation)
                        : door.point,
                ],
                isClosed: false,
            };
        },
        getCoveragePolygon: (annotation, campaign, location, grid, tokenOverrides, onlyIfClosed) => {
            if (onlyIfClosed) {
                return undefined;
            }

            // TODO: Implement - so far we only use this for finding covered grid squares, so we don't need it yet.
            return undefined;
        },
        canBeModified: annotation => {
            return true;
        },
    },
    window: {
        getBounds: (annotation, campaign, location, grid, tokenOverrides) => {
            const window = annotation as WindowAnnotation;
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return getPointsBounds(pos, [{ x: 0, y: 0 }, window.point]);
        },
        getObstructionPolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            const window = annotation as WindowAnnotation;
            return {
                pos: getAnnotationPos(annotation, campaign, location, grid, tokenOverrides),
                points: [{ x: 0, y: 0 }, window.point],
                isClosed: false,
            };
        },
        getCoveragePolygon: (annotation, campaign, location, grid, tokenOverrides, onlyIfClosed) => {
            if (onlyIfClosed) {
                return undefined;
            }

            // TODO: Implement - so far we only use this for finding covered grid squares, so we don't need it yet.
            return undefined;
        },
        canBeModified: annotation => {
            return true;
        },
    },
    cone: {
        getBounds: (annotation, campaign, location, grid, tokenOverrides) => {
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return getPointsBounds(pos, getConePoints(annotation as ConeAnnotation, pos));
        },
        getCenter: (annotation, pos, campaign, location, grid, tokenOverrides) => {
            return pos ?? getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
        },
        getObstructionPolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return {
                points: getConePoints(annotation as ConeAnnotation, pos),
                pos: pos,
            };
        },
        getCoveragePolygon: (annotation, campaign, location, grid, tokenOverrides, onlyIfArea) => {
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return getConePoints(annotation as ConeAnnotation, pos).map(o => localPoint(o.x + pos.x, o.y + pos.y));
        },
        canBeModified: annotation => {
            // Changing the rotation counts here, so it's always true.
            return true;

            // const cone = annotation as ConeAnnotation;

            // // TODO: This will need to be updated if we allow setting the cone spread as well.
            // const isConeFixed = cone.minRadius != null && cone.maxRadius != null && cone.minRadius === cone.maxRadius;
            // return !isConeFixed;
        },
    },
    linearea: {
        getBounds: (annotation, campaign, location, grid, tokenOverrides) => {
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return getPointsBounds(pos, getLineAreaPoints(annotation as LineAreaAnnotation, pos));
        },
        getCenter: (annotation, pos, campaign, location, grid, tokenOverrides) => {
            return pos ?? getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
        },
        getObstructionPolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return {
                points: getLineAreaPoints(annotation as LineAreaAnnotation, pos),
                pos: pos,
            };
        },
        getCoveragePolygon: (annotation, campaign, location, grid, tokenOverrides, onlyIfArea) => {
            const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            return getLineAreaPoints(annotation as LineAreaAnnotation, pos).map(o =>
                localPoint(o.x + pos.x, o.y + pos.y)
            );
        },
        canBeModified: annotation => {
            // Can always change the angle, even if the length is fixed.
            return true;
        },
    },
    target: {
        getBounds: (annotation, campaign, location, grid, tokenOverrides) => {
            // The bounds of the annotation are the bounds of the source token.
            const targetted = annotation as TargettedAnnotation;

            let token = location.tokens[targetted.tokenId];
            if (!token) {
                return undefined;
            }

            token = resolveToken(campaign, token);
            return grid.toLocalBounds(token.pos, token.scale);
        },
        getObstructionPolygon: (annotation, campaign, location, grid, tokenOverrides) => {
            return undefined;
        },
        getCoveragePolygon: (annotation, campaign, location, grid, tokenOverrides, onlyIfArea) => {
            return undefined;
        },
        canBeModified: annotation => {
            // True because the targets can be modified, they are never set in a template.
            return true;
        },
    },
};

export function isAnnotation(item: any): item is Annotation {
    return item && item.id && item.type && item.userId;
}

export function getCoveragePolygon(
    annotation: Annotation,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    tokenOverrides: { [id: string]: DeepPartial<Token> } | undefined,
    onlyIfClosed: boolean
): LocalPixelPosition[] | undefined {
    return annotationOperations[annotation.type]?.getCoveragePolygon(
        annotation,
        campaign,
        location,
        grid,
        tokenOverrides,
        onlyIfClosed
    );
}

export function getAnnotationBoundsPoints(
    annotation: Annotation,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    tokenOverrides?: { [id: string]: DeepPartial<Token> }
): LocalPixelPosition[] | LocalPixelPosition {
    let bounds = annotationOperations[annotation.type]?.getBounds(annotation, campaign, location, grid, tokenOverrides);

    if (!bounds) {
        const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
        return pos;
    }

    return [
        localPoint(bounds.x, bounds.y),
        localPoint(bounds.x + bounds.width, bounds.y),
        localPoint(bounds.x + bounds.width, bounds.y + bounds.height),
        localPoint(bounds.x, bounds.y + bounds.height),
    ];
}

export function getAnnotationBounds(
    annotation: Annotation,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    tokenOverrides?: { [id: string]: DeepPartial<Token> }
): LocalRect {
    let bounds = annotationOperations[annotation.type]?.getBounds(annotation, campaign, location, grid, tokenOverrides);

    if (!bounds) {
        const pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
        bounds = {
            type: pos.type,
            x: pos.x,
            y: pos.y,
            width: 0,
            height: 0,
        };
    }

    return bounds;
}

export function getAnnotationCenter(
    annotation: Annotation,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    tokenOverrides?: { [id: string]: DeepPartial<Token> }
) {
    let center = annotationOperations[annotation.type]?.getCenter?.(
        annotation,
        undefined,
        campaign,
        location,
        grid,
        tokenOverrides
    );
    if (!center) {
        center = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
    }

    return center;
}

export function canModifyAnnotationShape(annotation: Annotation) {
    return annotationOperations[annotation.type]?.canBeModified?.(annotation);
}

export interface ObstructingAnnotation extends Annotation {
    obstructsLight?: boolean;
    obstructsMovement?: boolean;
}

export interface LineAnnotation extends ObstructingAnnotation {
    type: "line";
    subtype?: LineAnnotationType;
    points: Point[];
    isClosed?: boolean;
}

export function isLineAnnotation(item: any): item is LineAnnotation {
    return isAnnotation(item) && item.type === "line";
}

export interface RectAnnotation extends ObstructingAnnotation {
    type: "rect";
    width: number;
    height: number;
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
}

export function isRectAnnotation(item: any): item is RectAnnotation {
    return isAnnotation(item) && item.type === "rect";
}

export interface EllipseAnnotation extends Annotation {
    type: "ellipse";
    radiusX: number;
    radiusY: number;

    minRadiusX?: number;
    maxRadiusX?: number;
    minRadiusY?: number;
    maxRadiusY?: number;
}

export function isEllipseAnnotation(item: any): item is EllipseAnnotation {
    return isAnnotation(item) && item.type === "ellipse";
}

export interface ConeAnnotation extends Annotation {
    type: "cone";

    /**
     * The current radius for the cone.
     */
    radius: number;

    /**
     * The size of the cone spread, in degrees.
     */
    spread: number;

    /**
     * The minimum radius for the cone.
     */
    minRadius?: number;

    /**
     * The maximum radius for the cone.
     */
    maxRadius?: number;
}

export function isConeAnnotation(item: any): item is ConeAnnotation {
    return isAnnotation(item) && item.type === "cone";
}

export interface LineAreaAnnotation extends Annotation {
    type: "linearea";
    length: number;
    width: number;
    minLength?: number;
    maxLength?: number;
}

export function isLineAreaAnnotation(item: any): item is LineAreaAnnotation {
    return isAnnotation(item) && item.type === "linearea";
}

export type TargetFilter = MonsterFilter & {
    self?: boolean;
};

export interface TargettedAnnotation extends Annotation {
    type: "target";

    // The source token ID is required for a targetted annotation - if there's no source, then there's nothing to show.
    tokenId: string;

    /**
     * A dictionary where the key is the ID of the token being targetted, and the value is the number of times that token
     * was targetted.
     */
    targets: { [tokenId: string]: number };

    /**
     * If there is a penalty after a certain range.
     */
    warningRadius?: number;

    /**
     * If specified, only creatures within the specified radius (local pixel unit) can be targetted.
     */
    radius?: number;

    /**
     * The maximum number of targets. If undefined, defaults to 1.
     */
    maxTargets?: number;

    /**
     * The maximum number of times an individual target can be targetted. If undefined, defaults to 1.
     */
    maxPerTarget?: number;
}

export function isTargettedAnnotation(item: any): item is TargettedAnnotation {
    return isAnnotation(item) && item.type === "target";
}

export interface DoorAnnotation extends ObstructingAnnotation {
    type: "door";
    subtype?: DoorAnnotationType;
    point: Point;
    open?: number;
    invert?: boolean;
    isSecret?: boolean;
}

export function isDoorAnnotation(item: any): item is DoorAnnotation {
    return isAnnotation(item) && item.type === "door";
}

export interface WindowAnnotation extends ObstructingAnnotation {
    type: "window";
    point: Point;
}

export function isWindowAnnotation(item: any): item is WindowAnnotation {
    return isAnnotation(item) && item.type === "window";
}

export function isObstructingAnnotation(item: any): item is ObstructingAnnotation {
    return isLineAnnotation(item) || isRectAnnotation(item) || isDoorAnnotation(item) || isWindowAnnotation(item);
}

export function addEdges(
    obstruction: ObstructingAnnotation,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    tokenOverrides: { [id: string]: DeepPartial<Token> } | undefined,
    edges: [Point, Point][]
) {
    const poly = getObstructionPolygon(obstruction, campaign, location, grid, tokenOverrides);

    for (let i = 1; i < poly.points.length; i++) {
        edges.push([
            {
                x: poly.points[i - 1].x + poly.pos.x,
                y: poly.points[i - 1].y + poly.pos.y,
            },
            {
                x: poly.points[i].x + poly.pos.x,
                y: poly.points[i].y + poly.pos.y,
            },
        ]);
    }

    if (poly.isClosed && !pointsEqual(poly.points[0], poly.points[poly.points.length - 1])) {
        edges.push([
            {
                x: poly.points[poly.points.length - 1].x + poly.pos.x,
                y: poly.points[poly.points.length - 1].y + poly.pos.y,
            },
            {
                x: poly.points[0].x + poly.pos.x,
                y: poly.points[0].y + poly.pos.y,
            },
        ]);
    }
}

export function getObstructionPolygon(
    obstruction: ObstructingAnnotation,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    tokenOverrides?: { [id: string]: DeepPartial<Token> }
): { pos: LocalPixelPosition; points: Point[]; isClosed?: boolean } {
    const poly = annotationOperations[obstruction.type]?.getObstructionPolygon(
        obstruction,
        campaign,
        location,
        grid,
        tokenOverrides
    );
    if (!poly) {
        throw new Error("Unrecognised obstruction annotation.");
    }

    return poly;
}

export function getObstructions(
    annotations: { [id: string]: Annotation },
    filter: (annotation: ObstructingAnnotation) => boolean | undefined,
    map?: (annotation: Annotation) => Annotation
) {
    return Object.getOwnPropertyNames(annotations).reduce<ObstructingAnnotation[]>((p, o) => {
        const annotation = annotations[o] as ObstructingAnnotation;
        if (filter(annotation)) {
            p.push(map ? map(annotation) : annotation);
        }

        return p;
    }, []);
}

function pointInEllipse(h: number, k: number, x: number, y: number, a: number, b: number) {
    // checking the equation of
    // ellipse with the given point
    var p = Math.pow(x - h, 2) / Math.pow(a, 2) + Math.pow(y - k, 2) / Math.pow(b, 2);

    return p;
}

export function isInEllipse(ellipse: EllipseAnnotation, pos: LocalPixelPosition, p: LocalPixelPosition) {
    if (ellipse.rotation != null) {
        p = rotate(p, pos, -ellipse.rotation);
    }

    var i = pointInEllipse(pos.x, pos.y, p.x, p.y, ellipse.radiusX, ellipse.radiusY);
    return i <= 1;
}

export function getLineAreaPoints(line: LineAreaAnnotation, linePos: LocalPixelPosition, activePoint?: Point): Point[] {
    let length: number;
    let angle: number;
    if (activePoint) {
        const point: Point = { x: activePoint.x - linePos.x, y: activePoint.y - linePos.y };
        angle = angleOfVector(point) + 90;
        length = lengthOfVector(point);
    } else {
        length = line.length;
        angle = (line.rotation ?? 0) + 180;
    }

    if (line.minLength != null && length < line.minLength) {
        length = line.minLength;
    } else if (line.maxLength != null && length > line.maxLength) {
        length = line.maxLength;
    }

    const hw = line.width / 2;
    const origin: Point = { x: 0, y: 0 };
    var fp = rotate({ x: -hw, y: 0 }, origin, angle);
    return [
        fp,
        rotate({ x: -hw, y: -length }, origin, angle),
        rotate({ x: hw, y: -length }, origin, angle),
        rotate({ x: hw, y: 0 }, origin, angle),
        fp,
    ];
}

export function getConePoints(cone: ConeAnnotation, conePos: LocalPixelPosition, activePoint?: Point): Point[] {
    let point: Point;
    let angle: number;
    let length: number;
    if (activePoint) {
        point = { x: activePoint.x - conePos.x, y: activePoint.y - conePos.y };
        angle = angleOfVector(point);
        length = lengthOfVector(point);
    } else {
        angle = cone.rotation != null ? cone.rotation : 0;
        point = rotate({ x: 0, y: -cone.radius }, { x: 0, y: 0 }, angle);
        length = cone.radius;
        angle += 90;
    }

    if (cone.minRadius != null && length < cone.minRadius) {
        length = cone.minRadius;
    } else if (cone.maxRadius != null && length > cone.maxRadius) {
        length = cone.maxRadius;
    }

    const ellipseCurve = new EllipseCurve(
        0,
        0,
        length,
        length,
        degToRad(angle - cone.spread / 2),
        degToRad(angle + cone.spread / 2),
        false,
        0
    );
    const points = ellipseCurve.getPoints(16).map(o => localPoint(o.x, o.y)) as Point[];
    points.push({ x: 0, y: 0 });
    points.unshift(points[points.length - 1]);

    return points;
}

function getRectPoints(rect: RectAnnotation, origin?: LocalPixelPosition) {
    const r = rect.rotation ? rect.rotation : 0;
    const left = origin?.x ?? 0;
    const top = origin?.y ?? 0;
    let center = { x: left + rect.width / 2, y: top + rect.height / 2 };
    return [
        rotate({ x: left, y: top }, center, r),
        rotate({ x: left + rect.width, y: top }, center, r),
        rotate({ x: left + rect.width, y: top + rect.height }, center, r),
        rotate({ x: left, y: top + rect.height }, center, r),
    ];
}

function getLinePoints(line: LineAnnotation) {
    if (line.rotation == null || line.rotation === 0) {
        return line.points;
    }

    const center = getCenterPoint(line.points);
    return line.points.map(o => rotate(o, center, line.rotation as number));
}

export function isGridPosInArea(
    pos: GridPosition | LocalPixelPosition[],
    shape: LocalPixelPosition[],
    grid: ILocalGrid,
    clipper: ClipperLibWrapper,
    areaThreshold?: number
) {
    var gridPosPoints = Array.isArray(pos) ? pos : grid.toLocalPoints(pos);
    if (areaThreshold == null) {
        areaThreshold = clipper.area(gridPosPoints) / 2;
    }

    var clipped = clipper.clipToPaths({
        clipType: ClipType.Intersection,
        subjectFillType: PolyFillType.EvenOdd,
        subjectInputs: [{ data: gridPosPoints, closed: true }],
        clipInputs: [{ data: shape }],
    });

    let area = 0;
    if (clipped && clipped.length) {
        for (let i = 0; i < clipped.length; i++) {
            area += clipper.area(clipped[i]);
        }
    }

    return area >= areaThreshold;
}

export function getGridPoints(
    annotation: Annotation,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    tokenOverrides: { [id: string]: DeepPartial<Token> } | undefined,
    clipper: ClipperLibWrapper
) {
    var shape = getCoveragePolygon(annotation, campaign, location, grid, tokenOverrides, true);
    if (!shape || shape.length < 3) {
        return undefined;
    }

    var bounds = getBounds(shape);
    const gridPoints: GridPosition[] = [];
    let areaThreshold: number | undefined;

    grid.forEachGridPointIntersecting(bounds, o => {
        var gridPosPoints = grid.toLocalPoints(o);
        if (areaThreshold == null) {
            areaThreshold = clipper.area(gridPosPoints) / 2;
        }

        try {
            if (isGridPosInArea(gridPosPoints, shape!, grid, clipper, areaThreshold)) {
                gridPoints.push(o);
            }
        } catch (e) {
            console.error(
                `Could not get overlapped grid points for invalid annotation ${annotation.id}: ${shape!
                    .map(o => `${o.x},${o.y}`)
                    .join("->")}`
            );
        }
    });

    return gridPoints;
}

import { forwardRef } from "react";
import { Grid } from "./Grid";

export type ColumnGridProps = {
    columns?: number;
} & any;

export const ColumnGrid = forwardRef<HTMLDivElement, React.PropsWithChildren<ColumnGridProps>>(
    ({ columns = 12, children, ...props }, ref) => {
        const gridTemplateColumns = `repeat(${columns}, 1fr)`;
        return (
            <Grid
                gridTemplateColumns={gridTemplateColumns}
                gridTemplateRows="1fr"
                fullWidth
                fullHeight
                placeItems="center"
                {...props}
                ref={ref}>
                {children}
            </Grid>
        );
    }
);

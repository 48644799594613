/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import AttackIcon from "./Attack";
import BlindedIcon from "./Blinded";
import CharmedIcon from "./Charmed";
import CombatIcon from "./Combat";
import DeafenedIcon from "./Deafened";
import ExhaustionIcon from "./Exhaustion";
import FrightenedIcon from "./Frightened";
import GrappledIcon from "./Grappled";
import IncapacitatedIcon from "./Incapacitated";
import InvisibleIcon from "./Invisible";
import ParalyzedIcon from "./Paralyzed";
import PetrifiedIcon from "./Petrified";
import PoisonedIcon from "./Poisoned";
import Prone from "./Prone";
import RestrainedIcon from "./Restrained";
import Stunned from "./Stunned";
import UnconsciousIcon from "./Unconscious";
import SurprisedIcon from "./Surprised";
import DashIcon from "./Dash";
import DodgeIcon from "./Dodge";
import FistIcon from "./weapons/Fist";
import DetectIcon from "./Detect";

export const icons: { [id: string]: (props: any) => JSX.Element } = {
    Blinded: props => <BlindedIcon {...props} />,
    Charmed: props => <CharmedIcon {...props} />,
    Deafened: props => <DeafenedIcon {...props} />,
    Exhausted: props => <ExhaustionIcon {...props} />,
    Frightened: props => <FrightenedIcon {...props} />,
    Grappled: props => <GrappledIcon {...props} />,
    Incapacitated: props => <IncapacitatedIcon {...props} />,
    Invisible: props => <InvisibleIcon {...props} />,
    Paralyzed: props => <ParalyzedIcon {...props} />,
    Petrified: props => <PetrifiedIcon {...props} />,
    Poisoned: props => <PoisonedIcon {...props} />,
    Prone: props => <Prone {...props} />,
    Restrained: props => <RestrainedIcon {...props} />,
    Stunned: props => <Stunned {...props} />,
    Unconscious: props => <UnconsciousIcon {...props} />,
    Surprised: props => <SurprisedIcon {...props} />,

    Combat: props => <CombatIcon {...props} />,
    Attack: props => <AttackIcon {...props} />,
    Dash: props => <DashIcon {...props} />,
    Dodge: props => <DodgeIcon {...props} />,
    Fist: props => <FistIcon {...props} />,
    Detect: props => <DetectIcon {...props} />,
};

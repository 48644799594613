import { createLightShader, defaultLightTags, LightShaderTemplate } from "./common";

const flickerLightShaderText = createLightShader(
    `
    float distToP = distance(localFragCoord, u_position);
    float flicker = fract(sin((u_time + u_timeOffset) / 4.0) + (sin((u_time + u_timeOffset) * 1.5) * 0.75));
    gl_FragColor = vec4(u_color, (1.0 - smoothstep(u_near / u_far, 1.0, distToP / u_far)) * ((step(u_threshold, flicker) * (u_brightness - u_minBrightness)) + u_minBrightness));
`,
    `
uniform float u_time;
uniform float u_timeOffset;
uniform float u_minBrightness;
uniform float u_threshold;
`
);
export const flickerLightShader: LightShaderTemplate = {
    id: "flicker",
    label: "Flickering light",
    tags: defaultLightTags,
    shader: flickerLightShaderText,
    defaults: {
        brightness: 0.6,
    },
    onInit: material => {
        material.uniforms.u_time = { value: 0 };
        material.uniforms.u_timeOffset = { value: Math.random() * 20 };
        material.uniforms.u_minBrightness = { value: 0.2 }; // TODO Make this configurable.
        material.uniforms.u_threshold = { value: 0.08 }; // TODO: Make this configurable.
    },
    onBeforeRender: ({ context, material, pointLight }) => {
        material.uniforms.u_time.value = context.clock.elapsedTime;

        if (pointLight) {
            const uniforms = material.uniforms;
            const f = Math.abs(
                Math.sin((uniforms.u_time.value + uniforms.u_timeOffset.value) / 4.0) +
                    Math.sin((uniforms.u_time.value + uniforms.u_timeOffset.value) * 1.5) * 0.75
            );
            const flicker = f - Math.trunc(f);
            const brightness =
                (flicker < uniforms.u_threshold.value ? 0 : 1) *
                    (uniforms.u_brightness.value - uniforms.u_minBrightness.value) +
                uniforms.u_minBrightness.value;
            pointLight.intensity = brightness;
        }
    },
};

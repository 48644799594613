import { SessionConnection, Token, TokenTemplate, Location, WithLevel } from "../store";
import { isTokensAction, isTokenTemplatesAction, PayloadAction } from "../actions/common";
import { copyState, mergeState } from "./common";
import { Action } from "redux";
import { LibraryItem } from "../library";
import { GridPosition } from "../position";

export const reduceToken = (
    state: Token | undefined,
    action: Action<any>,
    location: Location,
    sessionConnection: SessionConnection,
    isTargetted: boolean
) => {
    if (!state) {
        return state;
    }

    if (isTargetted && isTokensAction(action)) {
        if (action.type === "ModifyToken") {
            return mergeState(state, action.payload);
        } else if (action.type === "SetTokenImage") {
            return reduceSetTokenImage(state, action) as Token;
        } else if (action.type === "RemoveTokenImage") {
            return reduceRemoveTokenImage(state, action) as Token;
        } else if (action.type === "SetPortraitImage") {
            return reduceSetPortraitImage(state, action) as Token;
        } else if (action.type === "MoveToken") {
            const payload = action.payload as {
                path: WithLevel<GridPosition>[];
                cost: number;
                rotation: number;
                requiresApproval: boolean;
            };
            if (!payload.requiresApproval) {
                const pt: Partial<Token> = {
                    pos: payload.path[payload.path.length - 1],
                    prevPath: payload.path,
                    nextPath: undefined,
                };

                if (pt.canRotate == null || pt.canRotate) {
                    pt.rotation = payload.rotation ?? 0;
                }

                state = mergeState(state, pt);
            } else {
                state = mergeState(state, { nextPath: payload.path });
            }
        }
    }

    if (sessionConnection.system) {
        return sessionConnection.system.reduceToken(
            state,
            action,
            sessionConnection.session!,
            location,
            isTargetted
        ) as Token;
    }

    return state;
};

export const reduceTokenTemplate = (
    state: TokenTemplate | undefined,
    action: Action<any>,
    sessionConnection: SessionConnection,
    isTargetted: boolean
) => {
    if (!state) {
        return state;
    }

    if (isTargetted && isTokenTemplatesAction(action)) {
        if (action.type === "ModifyToken" || action.type === "ModifyTokenTemplate") {
            return mergeState(state, action.payload);
        } else if (action.type === "SetTokenImage") {
            return reduceSetTokenImage(state, action) as TokenTemplate;
        } else if (action.type === "RemoveTokenImage") {
            return reduceRemoveTokenImage(state, action) as TokenTemplate;
        } else if (action.type === "SetPortraitImage") {
            return reduceSetPortraitImage(state, action) as TokenTemplate;
        }
    }

    if (sessionConnection.system) {
        return sessionConnection.system.reduceToken(
            state,
            action,
            sessionConnection.session!,
            undefined,
            isTargetted
        ) as TokenTemplate;
    }

    return state;
};

function reduceRemoveTokenImage(token: Token | TokenTemplate, action: Action<any>) {
    const uri = (action as PayloadAction).payload as string;
    if (uri) {
        const images = token.images?.slice() ?? [];
        const i = images.findIndex(o => o.uri === uri);
        if (i >= 0) {
            images.splice(i, 1);
        }

        const replacesCurrent = token.imageUri === uri;
        const replacement = replacesCurrent ? images[0] : undefined;
        return copyState(token, {
            images: images,
            imageUri: replacesCurrent ? replacement?.uri : token.imageUri,
            canRotate: replacesCurrent ? replacement?.canRotate : token.canRotate,
            renderScale: replacesCurrent ? replacement?.renderScale : token.renderScale,
        });
    }
}

function reduceSetTokenImage(token: Token | TokenTemplate, action: Action<any>) {
    const libraryItem = (action as PayloadAction).payload as LibraryItem | undefined;
    if (libraryItem) {
        const images = token.images?.slice() ?? [];
        const i = images.findIndex(o => o.uri === libraryItem.uri);
        const image = {
            uri: libraryItem.uri,
            canRotate: libraryItem.metadata.canRotate,
            rotation: libraryItem.metadata.rotation,
            thumbnailUri: libraryItem.metadata.thumbnailUri,
            renderScale: libraryItem.metadata.renderScale,
        };
        if (i >= 0) {
            images[i] = image;
        } else {
            images.push(image);
        }

        return copyState(token, {
            images: images,
            imageUri: libraryItem.uri,
            canRotate: libraryItem.metadata.canRotate == null || libraryItem.metadata.canRotate ? undefined : false,
            defaultRotation: libraryItem.metadata.rotation,
            renderScale: libraryItem.metadata.renderScale,
        });
    } else {
        return copyState(token, { imageUri: undefined, canRotate: undefined, defaultRotation: undefined });
    }
}

function reduceSetPortraitImage(token: Token | TokenTemplate, action: Action<any>) {
    const payload = (action as PayloadAction).payload as LibraryItem | undefined;
    return copyState(token, { portraitImageUri: payload?.uri });
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { InputField, CheckboxField, SelectField, Form } from "../Form";
import { useDispatch, useLocationLevel } from "../contexts";
import { modifyLocationLevel } from "../../actions/location";
import { PositionType } from "../../position";
import { SearchableSetting, LocationLevelVisibility } from "../../store";
import { useDebounce } from "../utils";
import { PercentageSliderField } from "../slider";
import { AnimatePresence } from "framer-motion";
import { AnimatedListItem } from "../motion";

const tags = ["location", "level", "layer"];

const backgroundPosXSetting: SearchableSetting = {
    id: "backgroundPosX",
    label: "Background horizontal position (pixels)",
    tags: ["background", "map", ...tags],
    render: () => <BackgroundPosXSetting />,
};
const backgroundPosYSetting: SearchableSetting = {
    id: "backgroundPosY",
    label: "Background vertical position (pixels)",
    tags: ["background", "map", ...tags],
    render: () => <BackgroundPosYSetting />,
};

const revealAllSetting: SearchableSetting = {
    id: "revealAll",
    label: "Reveal location",
    tags: ["reveal", "hide", ...tags],
    render: () => <RevealAllSetting />,
};
const lightLevelSetting: SearchableSetting = {
    id: "lightLevel",
    label: "Light level",
    tags: ["light", "lighting", "dark", ...tags],
    render: () => <LightLevelSetting />,
};
const dayNightCycleSetting: SearchableSetting = {
    id: "dayNightCycle",
    label: "Day/night cycle",
    tags: ["light", "lighting", "day", "night", "dawn", "dusk", ...tags],
    render: () => <DayNightCycleSetting />,
};
const lightColorSetting: SearchableSetting = {
    id: "lightColor",
    label: "Light color",
    tags: ["light", "lighting", ...tags],
    render: () => <LightColorSetting />,
};

const DayNightCycleSetting = () => {
    const { location, campaign, level, levelKey } = useLocationLevel();
    const dispatch = useDispatch();

    return (
        <CheckboxField
            id={dayNightCycleSetting.id}
            label={dayNightCycleSetting.label}
            hint="If enabled, the lighting is set according to the current in game time. This overrides other ambient lighting settings."
            disabled={!level || !levelKey}
            checked={level ? !level.disableDayNight : undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(
                    modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                        disableDayNight: !e.target.checked,
                    })
                );
            }}
        />
    );
};

const levelLabelSetting: SearchableSetting = {
    id: "levelLabel",
    label: "Level label",
    tags: [...tags],
    render: () => <LevelLabelSetting />,
};
const levelVisibilitySetting: SearchableSetting = {
    id: "levelVisibility",
    label: "Level visibility",
    tags: [...tags],
    render: () => <LevelVisibilitySetting />,
};
const levelOccupiedOpacitySetting: SearchableSetting = {
    id: "levelOccupiedOpacity",
    label: "Level occupied opacity",
    tags: ["visibility", "visible", ...tags],
    render: () => <LevelOccupiedOpacitySetting />,
};
const levelCutAwaySetting: SearchableSetting = {
    id: "levelCutAway",
    label: "Show visible areas below",
    tags: ["visibility", "visible", "vision", ...tags],
    render: () => <LevelCutAwaySetting />,
};

const isInsideSetting: SearchableSetting = {
    id: "levelIsInside",
    label: "Inside",
    tags: ["inside", "outside", "indoors", "outdoors", ...tags],
    render: () => <IsInsideSetting />,
};

export const levelSettings: SearchableSetting[] = [
    levelLabelSetting,
    levelVisibilitySetting,
    backgroundPosXSetting,
    backgroundPosYSetting,
    revealAllSetting,
    isInsideSetting,
    dayNightCycleSetting,
    lightLevelSetting,
    lightColorSetting,
    levelOccupiedOpacitySetting,
    levelCutAwaySetting,
];

const BackgroundPosXSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location, level, levelKey } = useLocationLevel();
    const backgroundPosY = level ? level.backgroundImagePos?.y ?? 0 : 0;
    return (
        <InputField
            variant="number"
            label={backgroundPosXSetting.label}
            disabled={!level || !levelKey}
            value={level?.backgroundImagePos?.x ?? ""}
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!isNaN(e.target.valueAsNumber)) {
                    dispatch(
                        modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                            backgroundImagePos: {
                                type: PositionType.LocalPixel,
                                x: e.target.valueAsNumber,
                                y: backgroundPosY,
                            },
                        })
                    );
                }
            }}
        />
    );
};

const BackgroundPosYSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location, level, levelKey } = useLocationLevel();
    const backgroundPosX = level ? level.backgroundImagePos?.x ?? 0 : 0;
    return (
        <InputField
            variant="number"
            label={backgroundPosYSetting.label}
            disabled={!level || !levelKey}
            value={level?.backgroundImagePos?.y ?? ""}
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!isNaN(e.target.valueAsNumber)) {
                    dispatch(
                        modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                            backgroundImagePos: {
                                type: PositionType.LocalPixel,
                                x: backgroundPosX,
                                y: e.target.valueAsNumber,
                            },
                        })
                    );
                }
            }}
        />
    );
};

const RevealAllSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location, level, levelKey } = useLocationLevel();
    return (
        <CheckboxField
            id={revealAllSetting.id}
            label={revealAllSetting.label}
            hint="Reveal the entire location to players, even if their character(s) cannot see it. Light level still applies, and lights can still be obscured."
            disabled={!level || !levelKey}
            checked={level ? !!level.revealAll : undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(
                    modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                        revealAll: e.target.checked,
                    })
                );
            }}
        />
    );
};

const LightLevelSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location, level, levelKey } = useLocationLevel();

    const [overrideValue, setOverrideValue] = useState<number>();
    const onOccupiedOpacityChanged = useDebounce(
        (opacity: number) => {
            if (level) {
                dispatch(
                    modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                        lightLevel: opacity === 1 ? undefined : opacity,
                    })
                );
                setOverrideValue(undefined);
            }
        },
        500,
        (opacity: number) => {
            setOverrideValue(opacity);
            return [opacity];
        }
    );

    return (
        <PercentageSliderField
            label={lightLevelSetting.label}
            hint={
                level && !level.disableDayNight
                    ? "Specify how light or dark it is at this location. This setting has been disabled because the day/night cycle is enabled at this location."
                    : "Specify how light or dark it is at this location. For outside locations this generally represents the brightness of the sun. For inside or underground locations this is often 0 unless there is an ambient light source."
            }
            value={overrideValue ?? level?.lightLevel ?? 1}
            disabled={!level || !levelKey || !level.disableDayNight}
            onChange={onOccupiedOpacityChanged}
        />
    );
};

const LevelLabelSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location, level, levelKey } = useLocationLevel();
    return (
        <InputField
            label={levelLabelSetting.label}
            disabled={!level}
            value={level?.label ?? ""}
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                dispatch(
                    modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                        label: e.target.value == null ? undefined : e.target.value,
                    })
                )
            }
        />
    );
};

const LevelVisibilitySetting = () => {
    const dispatch = useDispatch();
    const { campaign, location, level, levelKey } = useLocationLevel();
    return (
        <SelectField
            label="Level visibility"
            required
            value={level?.visibility ?? "default"}
            disabled={!level}
            onChange={e => {
                const visibility = (e.target.value ?? "default") as LocationLevelVisibility;
                dispatch(
                    modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                        visibility: visibility === "default" ? undefined : visibility,
                    })
                );
            }}>
            <option value="default">Default</option>
            <option value="always">Always (roof)</option>
        </SelectField>
    );
};

const LevelOccupiedOpacitySetting = () => {
    const dispatch = useDispatch();
    const { campaign, location, level, levelKey } = useLocationLevel();
    const [overrideValue, setOverrideValue] = useState<number>();

    const onOccupiedOpacityChanged = useDebounce(
        (opacity: number) => {
            if (level) {
                dispatch(
                    modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                        occupiedOpacity: opacity === 1 ? undefined : opacity,
                    })
                );
                setOverrideValue(undefined);
            }
        },
        500,
        (opacity: number) => {
            setOverrideValue(opacity);
            return [opacity];
        }
    );

    return (
        <PercentageSliderField
            label="Occupied opacity (percent)"
            hint="The opacity to apply when one or more creature tokens are below the level."
            value={overrideValue ?? level?.occupiedOpacity ?? 1}
            disabled={!level}
            onChange={onOccupiedOpacityChanged}
        />
    );
};

const LevelCutAwaySetting = () => {
    const { location, campaign, level, levelKey } = useLocationLevel();
    const dispatch = useDispatch();

    return (
        <CheckboxField
            id={levelCutAwaySetting.id}
            label={levelCutAwaySetting.label}
            hint="If enabled, this level will be cut away to show areas of levels beneath that can be seen (are not blocked by obstructions and are within the range of light or darkvision)."
            disabled={!level || !levelKey}
            checked={level ? level.cutAway : undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(
                    modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                        cutAway: e.target.checked,
                    })
                );
            }}
        />
    );
};

const LightColorSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location, level, levelKey, system } = useLocationLevel();

    const onColorChanged = useDebounce(
        (color: string) => {
            dispatch(
                modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                    lightColor: color.toLowerCase() === system.defaultLight.color.toLowerCase() ? undefined : color,
                })
            );
        },
        500,
        (e: React.ChangeEvent<HTMLInputElement>) => [e.target.value]
    );

    return (
        <InputField
            label={lightColorSetting.label}
            variant={"color" as any}
            disabled={!level || !levelKey || !level.disableDayNight}
            hint={
                level && !level.disableDayNight
                    ? "Specify the color of the ambient light at this location. This setting has been disabled because the day/night cycle is enabled at this location."
                    : "Specify the color of the ambient light at this location."
            }
            padding={1}
            css={{ height: "2.5em" }}
            value={level?.lightColor ?? system.defaultLight.color}
            onChange={onColorChanged}
        />
    );
};

const IsInsideSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location, level, levelKey } = useLocationLevel();

    return (
        <CheckboxField
            id={isInsideSetting.id}
            label={isInsideSetting.label}
            hint="If enabled, this level is considered to be inside (as opposed to outside). An inside level will not use day/night cycle lighting and weather effects will not apply within the level. Parts of a level can be designated as inside areas by adding a zone."
            disabled={!level || !levelKey}
            checked={level ? !!level.isInside : undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(
                    modifyLocationLevel(campaign.id, location!.id, levelKey!, {
                        isInside: e.target.checked,
                    })
                );
            }}
        />
    );
};

export const PlayerBarLevelProperties: FunctionComponent<{}> = () => {
    return (
        <Form fullWidth pb={3}>
            <AnimatePresence>
                {levelSettings.map((o, i) => (
                    <AnimatedListItem layout="position" key={o.id} index={i} fullWidth className="form__field">
                        {o.render()}
                    </AnimatedListItem>
                ))}
            </AnimatePresence>
        </Form>
    );
};

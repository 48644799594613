/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, PropsWithChildren, useCallback } from "react";
import { NoteBase } from "../../store";
import { VttMode, useVttApp } from "../common";
import { AnimatePresence } from "framer-motion";
import { defaultAnimate, defaultExit, defaultInitial, MotionForm } from "../motion";
import { Markdown, MarkdownEditorField } from "../markdown";
import { Link, Text } from "../primitives";
import { theme } from "../../design";
import { useRole } from "../contexts";

export const NotesProperties: FunctionComponent<
    PropsWithChildren<{ note: NoteBase; mode: VttMode; dispatch: (delta: Partial<NoteBase>) => void }>
> = ({ note, mode, dispatch, children }) => {
    const role = useRole();
    const onChange = useCallback((md: string) => dispatch({ gmNotes: md }), [dispatch]);
    const setMode = useVttApp(state => state.setMode);

    if (role !== "GM") {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <AnimatePresence mode="wait">
            <MotionForm key={mode} fullWidth initial={defaultInitial} animate={defaultAnimate} exit={defaultExit}>
                {children}
                {mode === "play" && (
                    <React.Fragment>
                        {!note.gmNotes && <Text fontStyle="italic">No notes have been made for this token.</Text>}
                        {note.gmNotes && <Markdown fullWidth>{note.gmNotes ?? ""}</Markdown>}
                        <div css={{ marginTop: theme.space[3] }}>
                            <Link onClick={() => setMode("build")}>Switch to build mode</Link> to edit the notes for
                            this token.
                        </div>
                    </React.Fragment>
                )}
                {mode !== "play" && (
                    <MarkdownEditorField
                        label="GM Notes"
                        onMarkdownChange={onChange}
                        editable
                        minLines={6}
                        debounceChange={2000}
                        defaultMarkdown={note.gmNotes ?? ""}
                    />
                )}
            </MotionForm>
        </AnimatePresence>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box, Heading, Stack, Text } from "../primitives";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Markdown } from "../markdown";
import { InlineScrollable } from "../InlineScrollable";
import { theme } from "../../design";

dayjs.extend(relativeTime);

export const CampaignSummaryHeader = ({ campaign, lastActive, full = false }) => {
    const descriptionMd = (
        <Markdown color="grayscale.4" noSystem>
            {campaign.description ?? "*No description yet…*"}
        </Markdown>
    );

    return (
        <Stack as="header" fullWidth>
            {lastActive && (
                <Text color="grayscale.4" fontSize={full ? 1 : 0} lineHeight={0}>
                    {`Last played ${dayjs().to(lastActive)}`}
                </Text>
            )}
            <Heading as={full ? "h2" : "h3"} fontWeight="bold" color={full ? "grayscale.3" : "grayscale.4"} my={2}>
                {campaign.label ? campaign.label : campaign.id}
            </Heading>
            <Box
                maxHeight={theme.space[full ? 11 : 8]}
                overflow="hidden"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start">
                {full && <InlineScrollable>{descriptionMd}</InlineScrollable>}
                {!full && descriptionMd}
            </Box>
        </Stack>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { ThemeContext, jsx } from "@emotion/react";
import React, { FunctionComponent, useEffect, useState, useRef, useCallback, useMemo, PropsWithChildren } from "react";
import { Box, Text, Grid, Truncate, Link } from "./primitives";
import { Colours, theme } from "../design";
import { Message } from "./Message";
import { PercentageBar } from "./PercentageBar";
import { withTooltip } from "./Tooltip";
import { Avatar } from "./Avatar";
import { DiceBagTerm, MessageLogEntry, addDiceBags, getToken, getTokenOwner, shouldShowNotification } from "../store";
import styled from "@emotion/styled";

import {
    useDispatch,
    useUser,
    getRole,
    useNotifications,
    useLocation,
    useRole,
    useSessionConnection,
    AnnotationOverrideContext,
    ZoneOverrideContext,
    DiceBagContext,
    ViewportContext,
    LocalGridContext,
    AnnotationCacheContext,
    useDiceBag,
    useAppState,
    useSelection,
    useSession,
    CampaignContext,
    useCampaign,
    DispatchContext,
    UserContext,
    SessionConnectionContext,
    SessionContext,
    AppStateContext,
    SelectionContext,
    ScaleContext,
} from "./contexts";
import { LocationStage } from "./LocationStage";
import {
    ambienceMutedSetting,
    ambienceVolumeSetting,
    DeepPartial,
    LocalSetting,
    musicMutedSetting,
    musicVolumeSetting,
    title,
} from "../common";
import { Pages, Playerbar, SearchBar, defaultSections } from "./Sidebar";

import SelectTool from "./icons/SelectTool";
import LineTool from "./icons/LineTool";
import RectTool from "./icons/RectTool";
import EllipseTool from "./icons/EllipseTool";
import BuildMode from "./icons/BuildMode";
import PlayMode from "./icons/PlayMode";
import WallTool from "./icons/WallTool";
import DoorTool from "./icons/DoorTool";
import WindowTool from "./icons/WindowTool";
import FillTool from "./icons/FillTool";

import { ToolbarButton } from "./Toolbar";
import { VttMode, Viewport, Overlay, Loading, useVttApp, resolveUri } from "./common";
import { setPlayerColour } from "../actions/campaign";
import { getRandomPalette, getThemeColorPalette } from "../design/utils";
import { Help } from "./help";
import {
    useDownloadProgress,
    useDownloadPromise,
    getDownloadPromise,
    useForceUpdate,
    offerAcceptDrop,
    getCanvasDragData,
    useErrorHandler,
    useLocalSetting,
    useKeyboardShortcut,
    useProfiles,
} from "./utils";
import { useUploadPromise, getUploadPromise, useUploadProgress } from "../library";
import {
    MotionBox,
    sectionInitial,
    sectionAnimate,
    sectionExit,
    defaultInitial,
    defaultAnimate,
    defaultExit,
    MotionCard,
    MotionOverlayToolbar,
} from "./motion";
import TokenBox from "./TokenBox";
import {
    SessionPlayer,
    isToken,
    Token,
    CampaignPlayer,
    IRtcUser,
    Location,
    Campaign,
    CampaignRole,
    Playlist,
    SessionPlaylist,
    AudioType,
    AudioTrack,
    tracksToArray,
    Zone,
    DiceBag,
    DiceBagDice,
    getTokenType,
    TokenTemplate,
    isLocation,
    LocationSummary,
    LogEntry,
} from "../store";
import { modifyToken } from "../actions/token";
import { DiceBox } from "./DiceBox";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { PlayerRtc } from "./WebRtc/PlayerRtc";
import { Annotation, getGridPoints, isLineAnnotation } from "../annotations";
import { TokenOverrideContext } from "./contexts";
import { modifyLocation, playNextTrack } from "../actions/location";
import { Howl } from "howler";
import { CombatTracker } from "./CombatTracker";
import { getCombatParticipants } from "../reducers/location";
import D4Icon from "./icons/d4";
import D6Icon from "./icons/d6";
import D8Icon from "./icons/d8";
import D10Icon from "./icons/d10";
import D12Icon from "./icons/d12";
import D20Icon from "./icons/d20";
import { MotionBadge } from "../systems/dnd5e/components/FeatureExpander";
import DragonIcon from "./icons/Dragon";
import MapIcon from "./icons/Map";
import NotebookIcon from "./icons/Notebook";
import { getSelectionByType } from "./selection";
import LanternIcon from "./icons/Lantern";
import { DraggableBox } from "./draggable";
import { IGrid, ILocalGrid, ILocalGridWithRef, LocalGrid } from "../grid";
import { MenuItem, useMenuState } from "@szhsin/react-menu";
import { ControlledMenu } from "./menus";
import { ClipperLibWrapper } from "js-angusj-clipper";
import { GridPosition } from "../position";
import LayersIcon from "./icons/Layers";
import { LayerManager } from "./Sidebar/LayerManager";
import Mouse from "./icons/Mouse";
import Keyboard from "./icons/Keyboard";
import ProfileAvatar from "./ProfileAvatar";
import { Markdown } from "./markdown";
import { useContextBridge } from "@react-three/drei";
import { Button } from "./Button";
import PartyIcon from "./icons/Party";

const DownloadProgress: FunctionComponent<{}> = () => {
    const downloadProgress = useDownloadProgress();
    const percent =
        downloadProgress.size > 0 ? Math.round((downloadProgress.received / downloadProgress.size) * 100) : 0;

    return (
        <React.Fragment>
            {downloadProgress.total > 0 && (
                <Box css={{ gridArea: "bottom" }} mr={2} flexDirection="column" fullWidth>
                    <Text>
                        {downloadProgress.completed} of {downloadProgress.total} files downloaded ({percent}%)
                    </Text>
                    {downloadProgress.failed > 0 && <Text>{downloadProgress.failed} files failed to download</Text>}
                    <PercentageBar fullWidth total={100} complete={percent} mt={2} />
                </Box>
            )}
        </React.Fragment>
    );
};

const UploadProgress: FunctionComponent<{}> = () => {
    const uploadProgress = useUploadProgress();
    const percent = uploadProgress.size > 0 ? Math.round((uploadProgress.sent / uploadProgress.size) * 100) : 0;

    return (
        <React.Fragment>
            {uploadProgress.total > 0 && (
                <Box css={{ gridArea: "bottom" }} mr={2} flexDirection="column" fullWidth>
                    <Text>
                        {uploadProgress.completed} of {uploadProgress.total} files uploaded ({percent}%)
                    </Text>
                    {uploadProgress.failed > 0 && <Text>{uploadProgress.failed} files failed to upload</Text>}
                    <PercentageBar fullWidth total={100} complete={percent} mt={2} />
                </Box>
            )}
        </React.Fragment>
    );
};

const AvatarHostBox = styled(Grid)`
    position: relative;
    align-items: start;
    transition: transform 180ms ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
`;

const TooltipAvatar = withTooltip(motion(Avatar));

interface PlayerAvatarProps {
    sessionPlayer?: SessionPlayer;
    campaignPlayer: CampaignPlayer;
    rtc?: IRtcUser;
}

const PlayerAvatar: FunctionComponent<PlayerAvatarProps> = ({ sessionPlayer, campaignPlayer, rtc }) => {
    const [tooltip, setTooltip] = useState(undefined as string | undefined);
    const playerColours = getThemeColorPalette(campaignPlayer.colour);
    const dispatch = useDispatch();
    const { campaign, location, api } = useLocation();
    const role = useRole();
    const addNotification = useNotifications();
    const user = useUser();
    const { handleError } = useErrorHandler();
    const { setSelection } = useSelection();

    // Force render when app state changes so that we animate the avatar locations correctly.
    useAppState();

    const forceUpdate = useForceUpdate();
    useEffect(() => {
        if (rtc) {
            const rtcUpdated = () => forceUpdate();
            rtc.updated.on(rtcUpdated);
            return () => {
                rtc.updated.off(rtcUpdated);
            };
        }
    }, [rtc, forceUpdate]);

    const [menuProps, toggleMenu] = useMenuState({ transition: true });

    const rtcSession = api.rtc;

    // Check if the user is talking while they are muted and show a friendly reminder if they are.
    const isSpeakingWhileMuted = rtc && rtc.session.self === rtc && rtc.isSpeaking && rtc.session.self.isAudioMuted;
    useEffect(() => {
        if (isSpeakingWhileMuted) {
            let resolve: (value: unknown) => void;
            const promise = new Promise(o => (resolve = o));
            addNotification({
                content: (
                    <Message>
                        <Text>Your microphone is currently muted.</Text>
                    </Message>
                ),
                canDismiss: false,
                showLife: false,
                promise: () => promise,
            });
            return () => resolve(undefined);
        }
    }, [isSpeakingWhileMuted, addNotification]);

    let ownedTokens: Token[] = [];
    if (campaignPlayer.role !== "GM" && isLocation(location)) {
        for (let tokenId in location.tokens) {
            const token = location.tokens[tokenId];
            if (getTokenOwner(campaign, token) === campaignPlayer.userId) {
                ownedTokens.push(token);
            }
        }
    }

    const avatarBg = tooltip
        ? theme.colors.guidance.focus
        : rtc && rtc.isSpeaking
        ? playerColours[7]
        : playerColours[5];
    const avatarHoverBg = tooltip
        ? theme.colors.guidance.focus
        : rtc && rtc.isSpeaking
        ? playerColours[7]
        : playerColours[6];
    const tokenBg = tooltip ? theme.colors.guidance.focus : playerColours[4];
    const tokenHoverBg = tooltip ? theme.colors.guidance.focus : playerColours[6];

    const ref = useRef<HTMLDivElement>(null);

    return (
        <React.Fragment>
            <AvatarHostBox ml={3} css={{ flexDirection: "column" }} gridTemplateAreas={'"a"'}>
                <Box css={{ gridArea: "a" }}>
                    <AnimatePresence>
                        {rtc?.stream && (
                            <MotionBox
                                style={{ bottom: 0, right: 0 }}
                                layout
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <PlayerRtc player={campaignPlayer} rtc={rtc} />
                            </MotionBox>
                        )}
                    </AnimatePresence>
                </Box>
                <DraggableBox
                    draggableId={campaignPlayer.userId}
                    data={campaignPlayer}
                    dragOverlay="nobackground"
                    type="CampaignPlayer"
                    css={{
                        opacity: sessionPlayer ? 1 : 0.5,
                        gridArea: "a",
                        alignSelf: "end",
                    }}>
                    <TooltipAvatar
                        layout
                        ref={ref}
                        name={campaignPlayer.name}
                        size="l"
                        tooltip={tooltip}
                        tooltipDirection="up"
                        zIndex={10}
                        presence={sessionPlayer != null ? "available" : "unavailable"}
                        onClick={() => {
                            // Select all tokens belonging to this player.
                            if (isLocation(location)) {
                                const playerTokens = Object.values(location.tokens).filter(
                                    o => getTokenOwner(campaign, o) === campaignPlayer.userId
                                );
                                setSelection(playerTokens.map(o => o.id));
                            }
                        }}
                        onContextMenu={e => {
                            toggleMenu(true);
                            e.preventDefault();
                        }}
                        onMouseEnter={() => {
                            const dragData = getCanvasDragData();
                            if (location && isToken(dragData) && role === "GM" && campaignPlayer.role !== "GM") {
                                const message =
                                    dragData.owner === campaignPlayer.userId
                                        ? `Already assigned to ${campaignPlayer.name}`
                                        : `Assign to ${campaignPlayer.name}`;
                                setTooltip(message);
                                offerAcceptDrop(token => {
                                    dispatch(
                                        modifyToken(
                                            campaign.id,
                                            location.id,
                                            token as Token,
                                            {
                                                owner: campaignPlayer.userId,
                                            },
                                            true
                                        )
                                    );
                                    setTooltip(undefined);
                                });
                            }
                        }}
                        onMouseLeave={() => {
                            setTooltip(undefined);
                            offerAcceptDrop(undefined);
                        }}
                        bg={avatarBg}
                        hoverBg={avatarHoverBg}
                        color={tooltip ? theme.colors.background : playerColours[0]}
                    />
                </DraggableBox>
                <MotionBox
                    animate
                    layout
                    flexDirection="row"
                    height={theme.space[5]}
                    pl={2}
                    css={{
                        gridArea: "a",
                        alignSelf: "start",
                        marginTop: -theme.space[4],
                        justifyContent: "flex-start",
                        zIndex: 10,
                    }}>
                    <AnimatePresence>
                        {ownedTokens.map(o => (
                            <MotionBox
                                layout
                                key={o.id}
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <TokenBox token={o} background={tokenBg} hoverBackground={tokenHoverBg} mr={1} />
                            </MotionBox>
                        ))}
                    </AnimatePresence>
                </MotionBox>
            </AvatarHostBox>
            <ControlledMenu
                direction="top"
                align="center"
                {...menuProps}
                onClose={() => toggleMenu(false)}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onItemClick={e => {
                    e.syntheticEvent.stopPropagation();
                    e.syntheticEvent.preventDefault();
                }}
                anchorRef={ref}>
                {campaignPlayer.userId === user.id && (
                    <React.Fragment>
                        {rtcSession && !(rtcSession.isJoined || rtcSession.isJoining) && (
                            <MenuItem
                                onClick={async () => {
                                    try {
                                        await rtcSession.join(true);
                                    } catch (e) {
                                        console.error(`Error joining call! ${e}`);
                                        handleError(e);
                                    }
                                }}>
                                Join call
                            </MenuItem>
                        )}
                        {rtcSession && !(rtcSession.isJoined || rtcSession.isJoining) && (
                            <MenuItem
                                onClick={async () => {
                                    try {
                                        await rtcSession.join(false);
                                    } catch (e) {
                                        console.error(`Error joining call! ${e}`);
                                        handleError(e);
                                    }
                                }}>
                                Join call (audio only)
                            </MenuItem>
                        )}
                        {rtcSession && rtcSession.isJoined && (
                            <MenuItem
                                onClick={async () => {
                                    const self = rtcSession.self;
                                    if (self) {
                                        self.isAudioMuted = !self.isAudioMuted;
                                    }
                                }}>
                                {rtcSession?.self?.isAudioMuted ? "Unmute audio" : "Mute audio"}
                            </MenuItem>
                        )}
                        {rtcSession && rtcSession.isJoined && (
                            <MenuItem
                                onClick={async () => {
                                    const self = rtcSession.self;
                                    if (self) {
                                        self.isVideoEnabled = !self.isVideoEnabled;
                                    }
                                }}>
                                {rtcSession?.self?.isVideoEnabled ? "Mute video" : "Unmute video"}
                            </MenuItem>
                        )}
                        {rtcSession && rtcSession.isJoined && (
                            <MenuItem
                                onClick={async () => {
                                    try {
                                        await rtcSession.leave();
                                    } catch (e) {
                                        handleError(e);
                                    }
                                }}>
                                Leave call
                            </MenuItem>
                        )}
                    </React.Fragment>
                )}
                {location && api && role === "GM" && (
                    <MenuItem onClick={() => api.resetFow(location.id, undefined, campaignPlayer.userId)}>
                        Reset fog of war
                    </MenuItem>
                )}
            </ControlledMenu>
        </React.Fragment>
    );
};

interface LocationPlaylistProps {
    type: AudioType;
    playlist: Playlist | undefined;
    sessionPlaylist: SessionPlaylist | undefined;
    isMutedSetting: LocalSetting<boolean>;
    volumeSetting: LocalSetting<number>;
}

const AmbientTrack: FunctionComponent<{ track: AudioTrack }> = ({ track }) => {
    const [isMuted] = useLocalSetting(ambienceMutedSetting, false);
    const [volume] = useLocalSetting(ambienceVolumeSetting, 100);

    const [howl, setHowl] = useState<Howl>();

    const effectiveVolume = Math.round((track.volume ?? 100) * (volume / 100));

    const volumeRef = useRef<number>();
    volumeRef.current = effectiveVolume;

    // When the track changes, create the howl instance.
    useEffect(() => {
        const h = new Howl({
            src: [resolveUri(track.uri)],
            autoplay: false,
            loop: true,
            volume: volumeRef.current! / 100,
            mute: isMuted,
            onload: () => {
                h.play();
                // h.fade(0, volumeRef.current! / 100, 1000);
            },
        });
        setHowl(h);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [track.uri]);

    useEffect(() => {
        if (howl) {
            howl.mute(isMuted);
        }
    }, [isMuted, howl]);

    // When the volume changes, update the howl volume if it is running.
    useEffect(() => {
        if (howl && howl.playing()) {
            howl.fade(howl.volume(), effectiveVolume / 100, 500);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effectiveVolume]);

    // Fade out the old howl when it changes/is unmounted.
    useEffect(() => {
        return () => {
            if (howl) {
                howl.once("fade", () => howl.stop());
                howl.fade(howl.volume(), 0, 1000);
            }
        };
    }, [howl]);

    return <React.Fragment />;
};

const LocationPlaylist: FunctionComponent<LocationPlaylistProps> = ({
    type,
    playlist,
    sessionPlaylist,
    isMutedSetting,
    volumeSetting,
}) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const campaignId = campaign.id;
    const locationId = location!.id;

    const [isMuted] = useLocalSetting(isMutedSetting, false);
    const [volume] = useLocalSetting(volumeSetting, 100);

    const [howl, setHowl] = useState<Howl>();

    useEffect(() => {
        if (!sessionPlaylist && playlist && Object.keys(playlist.tracks).length) {
            // Nothing playing yet, make it happen.
            dispatch(playNextTrack(campaignId, locationId, type, 0));
        }
    }, [dispatch, campaignId, type, locationId, sessionPlaylist, playlist]);

    // Fade out the old track when it is changed, or when the location is changed etc.
    useEffect(() => {
        return () => {
            if (howl) {
                howl.once("fade", () => howl.stop());
                howl.fade(howl.volume(), 0, 1000);
            }
        };
    }, [howl, locationId]);

    useEffect(() => {
        if (sessionPlaylist?.id) {
            const track = playlist?.tracks[sessionPlaylist?.id];
            if (track) {
                const h = new Howl({
                    src: [resolveUri(track.uri)],
                    autoplay: false,
                    volume: volume / 100,
                    mute: isMuted,
                    onload: () => {
                        const msFromStartTime = Date.now() - sessionPlaylist.startTime!;
                        h.seek(msFromStartTime / 1000);
                        h.play();
                    },
                    onend: () => {
                        dispatch(playNextTrack(campaignId, locationId, type, sessionPlaylist.trackCount));
                    },
                });
                setHowl(h);
            } else {
                setHowl(undefined);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionPlaylist?.id, sessionPlaylist?.trackCount]);

    useEffect(() => {
        if (howl && sessionPlaylist?.startTime && howl.playing()) {
            const msFromStartTime = Date.now() - sessionPlaylist.startTime!;
            howl.seek(msFromStartTime / 1000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionPlaylist?.startTime]);

    useEffect(() => {
        if (howl) {
            howl.volume(volume / 100);
            howl.mute(isMuted);
        }
    }, [howl, isMuted, volume]);

    return <React.Fragment />;
};

type AnnotationGridPointsCache = {
    [id: string]: {
        annotation: Annotation;
        token?: Token;
        tokenTemplate?: TokenTemplate;
        tokenOverride?: DeepPartial<Token>;
        results?: GridPosition[];
    };
};

function getGridPointsForAnnotation(
    cache: AnnotationGridPointsCache,
    annotation: Annotation,
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    tokenOverrides: { [id: string]: DeepPartial<Token> } | undefined,
    clipper: ClipperLibWrapper
) {
    const cached = cache[annotation.id];

    // To be valid, there must not have been relevant changes to the cached info.
    if (cached && annotation === cached.annotation && !annotation.pos && annotation.centerOn) {
        // If the annotation is centered on a token, then we need to worry about tokens, token templates, tokenOverrides.
        const centeredOn = location.tokens[annotation.centerOn];
        if (centeredOn && centeredOn === cached.token) {
            // The token is the same, but the template could have changed.
            if (!centeredOn.templateId || campaign.tokens[centeredOn.templateId] === cached.tokenTemplate) {
                // The token is the same, the template is the same, the annotation is the same... only the overrides could be different now.
                if (tokenOverrides?.[centeredOn.id] === cached.tokenOverride) {
                    // The grid point coverage for this annotation should be identical to the one that was previously calculated!
                    return cached.results;
                }
            }
        }
    }

    // The results weren't cached, get them now.
    const results = getGridPoints(annotation, campaign, location, grid, tokenOverrides, clipper);
    const token = !annotation.pos && annotation.centerOn ? location.tokens[annotation.centerOn] : undefined;
    const tokenTemplate = token && token.templateId ? campaign.tokens[token.templateId] : undefined;
    cache[annotation.id] = {
        annotation: annotation,
        token: token,
        tokenTemplate: tokenTemplate,
        tokenOverride: token ? tokenOverrides?.[token.id] : undefined,
        results: results,
    };

    return results;
}

// getCoveragePolygon(annotation: Annotation, campaign: Campaign, location: Location, grid: ILocalGrid, tokenOverrides?: { [id: string]: DeepPartial<Token> }, onlyIfArea?: boolean): LocalPixelPosition[] | undefined;
// const annotationCoverageCache: { [id: string]: }

const LocationHost: FunctionComponent<{
    mode: VttMode;
    location: Location | LocationSummary | undefined;
    role: CampaignRole;
    campaignPlayer: CampaignPlayer;
    gridRef: React.MutableRefObject<IGrid | undefined>;
    localGrid: ILocalGridWithRef;
    onViewportChanged: (viewport: Viewport | undefined) => void;
}> = ({ location, role, campaignPlayer, gridRef, localGrid, onViewportChanged, mode }) => {
    const [isStageLoading, setIsStageLoading] = useState(false);
    const {
        isSearchExpanded,
        setIsSearchExpanded,
        closePanel,
        overlay,
        isPropertiesExpanded,
        setIsPropertiesExpanded,
        propertiesPage,
        setPropertiesPage,
    } = useVttApp();
    const { setSearchPropertiesSection } = useAppState();

    useKeyboardShortcut(
        "Escape",
        ev => {
            setIsSearchExpanded(false);
            ev.preventDefault();
        },
        { priority: 1, isDisabled: !isSearchExpanded }
    );

    useKeyboardShortcut(
        "Escape",
        ev => {
            if (overlay) {
                closePanel(overlay.id);
                ev.preventDefault();
            }
        },
        { priority: 5, isDisabled: !overlay }
    );

    const openLog = () => {
        if (isPropertiesExpanded && propertiesPage === "log") {
            setIsPropertiesExpanded(false);
        } else {
            setIsPropertiesExpanded(true);
            setPropertiesPage("log");
        }
    };
    useKeyboardShortcut("`", openLog);
    useKeyboardShortcut("l", openLog);

    useKeyboardShortcut("p", () => {
        if (isPropertiesExpanded && propertiesPage === "properties") {
            setIsPropertiesExpanded(false);
        } else {
            setIsPropertiesExpanded(true);
            setPropertiesPage("properties");
        }
    });

    return (
        <React.Fragment>
            <Box
                css={{
                    gridArea: "1 / 1 / span 3 / span 5",
                    background: "hsl(219, 12%, 4%)",
                }}
                fullHeight
                fullWidth>
                {isLocation(location) && (
                    <LocationStage
                        // key={cameraMode} // If we don't do this, the transition between 2d and 3d ends up all messed up. It would be much better to remove this and have it happen with a simple camera switch.
                        gridRef={gridRef}
                        localGrid={localGrid}
                        onViewportChanged={onViewportChanged}
                        onLoading={setIsStageLoading}
                    />
                )}

                <Overlay>
                    <AnimatePresence>
                        {(isStageLoading || (location && !isLocation(location))) && (
                            <MotionBox
                                key="loading"
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <Loading size="l" />
                            </MotionBox>
                        )}
                    </AnimatePresence>
                </Overlay>

                {!location && (
                    <Help id="location_not_set" canDismiss={false}>
                        {role === "Player" && (
                            <Text>
                                The GM hasn't decided what location to show you yet. They should get to you soon!
                            </Text>
                        )}
                        {role === "GM" && !campaignPlayer?.location && (
                            <Text>
                                The campaign doesn't have any locations yet. Create one from the{" "}
                                <Link
                                    onClick={() => {
                                        setSearchPropertiesSection(
                                            defaultSections.find(o => o.id === Pages.Locations)!
                                        );
                                        setIsSearchExpanded(true);
                                    }}>
                                    Locations list
                                </Link>
                                .
                            </Text>
                        )}
                        {role === "GM" && campaignPlayer?.location && (
                            <span>
                                The previous location no longer exists. Select or create one from the{" "}
                                <Link
                                    onClick={() => {
                                        setSearchPropertiesSection(
                                            defaultSections.find(o => o.id === Pages.Locations)!
                                        );
                                        setIsSearchExpanded(true);
                                    }}>
                                    Locations list
                                </Link>
                                .
                            </span>
                        )}
                    </Help>
                )}
            </Box>

            <SearchBar />
            <Playerbar mode={mode} />
        </React.Fragment>
    );
};

function isCombatParticipant(campaign: Campaign, location: Location, player: CampaignPlayer) {
    if (!location.combat) {
        return false;
    }

    const participants = getCombatParticipants(location.combat, campaign, location);
    return participants.some(o => o.token?.owner === player.userId);
}

function addDieToBag(d: keyof DiceBagDice, bag?: DiceBag, op?: "+" | "-"): DiceBag {
    return addDiceBags(bag ?? {}, { [d]: { amount: 1, op: op } });
}

const DiceButton: FunctionComponent<
    PropsWithChildren<{ terms?: DiceBagTerm[]; tooltip: string; onClick: React.MouseEventHandler }>
> = ({ terms, tooltip, onClick, children }) => {
    const plusAmount = terms
        ? terms.reduce((p, c) => {
              return c.op == null || c.op === "+" ? p + c.amount : p;
          }, 0)
        : 0;

    // TODO: Finish implementing minuses.
    const minusAmount = terms
        ? terms.reduce((p, c) => {
              return c.op === "-" ? p + c.amount : p;
          }, 0)
        : 0;

    return (
        <MotionOverlayToolbar maxHeight={48} minHeight={48} p={1} direction="vertical" mb={2} prominent layout>
            <ToolbarButton
                tooltip={tooltip}
                tooltipDirection="left"
                onClick={onClick}
                badge={plusAmount?.toString()}
                css={{ width: 40, height: 40 }}>
                <AnimatePresence>
                    {plusAmount > 0 && (
                        <MotionBadge
                            layout="position"
                            key="+"
                            initial={sectionInitial}
                            animate={sectionAnimate}
                            exit={sectionExit}
                            bg="accent.1"
                            color="accent.9"
                            css={{
                                position: "absolute",
                                right: -theme.space[2],
                                top: -theme.space[2],
                                zIndex: 10,
                            }}>
                            {plusAmount}
                        </MotionBadge>
                    )}
                    {minusAmount > 0 && (
                        <MotionBadge
                            layout="position"
                            key="-"
                            initial={sectionInitial}
                            animate={sectionAnimate}
                            exit={sectionExit}
                            bg="reds.1"
                            color="reds.9"
                            css={{
                                position: "absolute",
                                left: -theme.space[2],
                                top: -theme.space[2],
                                zIndex: 10,
                            }}>
                            {minusAmount}
                        </MotionBadge>
                    )}
                </AnimatePresence>
                {children}
            </ToolbarButton>
        </MotionOverlayToolbar>
    );
};

const RightInnerBottomHost: FunctionComponent<{}> = React.memo(() => {
    const { session, api } = useSession();
    const { campaign, location } = useLocation();
    const user = useUser();
    const { setSelection } = useSelection();
    const { handleError } = useErrorHandler();

    // Force a render when the RHS panel expands/contracts, to ensure the layout animations happen properly.
    useVttApp(state => state.isPropertiesExpanded);

    // RTC STUFF
    const forceUpdate = useForceUpdate();
    const rtc = api?.rtc;
    useEffect(() => {
        if (rtc) {
            const handler = () => forceUpdate();
            rtc.updated.on(handler);
            return () => {
                if (rtc.isJoined && !rtc.isLeaving) {
                    rtc.leave();
                }

                rtc.updated.off(handler);
            };
        }
    }, [rtc, forceUpdate]);

    // export interface IAvatarProps {
    //     image?: string;
    //     name?: string;
    //     size?: "s" | "m" | "l";
    //     status?: "none" | "error" | "warning" | "success";
    //     presence?: "none" | "unknown" | "unavailable" | "busy" | "available";
    //     variant?: "initials" | "glyph";
    //     onClick?: () => void;
    // }
    const playerIds = Object.getOwnPropertyNames(session.campaign.players);

    let playersInCall = playerIds.filter(o => session.players[o]?.isRtcEnabled);

    return (
        <div
            css={{
                gridArea: "rightinnerbottom",
                position: "relative",
                justifySelf: "flex-end",
                alignSelf: "flex-end",
            }}>
            <Box
                zIndex={3}
                pr={4}
                pb={4}
                alignItems="flex-end"
                justifyContent="flex-end"
                position="absolute"
                right="100%"
                bottom="100%"
                width="max-content">
                <LayoutGroup>
                    <AnimatePresence mode="popLayout">
                        {playersInCall.length > 0 && !rtc?.isJoined && !rtc?.isJoining && (
                            <MotionCard
                                layout
                                css={{
                                    position: "absolute",
                                    right: 0,
                                    bottom: "100%",
                                }}
                                mr={2}
                                mb={5}
                                p={3}
                                borderRadius={3}
                                flexDirection="column"
                                bg="guidance.info.1"
                                color="guidance.info.0"
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                {playersInCall.length} {playersInCall.length === 1 ? "person" : "people"} in call
                                {rtc && (
                                    <Link
                                        clean
                                        onClick={async () => {
                                            try {
                                                await rtc.join(true);
                                            } catch (e) {
                                                console.error(`Error joining call! ${e}`);
                                                handleError(e);
                                            }
                                        }}>
                                        Join call
                                    </Link>
                                )}
                            </MotionCard>
                        )}
                    </AnimatePresence>
                    <AvatarHostBox ml={3} css={{ flexDirection: "column" }} gridTemplateAreas={'"a"'}>
                        <DraggableBox
                            layout
                            draggableId="party"
                            data={Object.values(session.campaign.players).filter(o => o.role !== "GM")}
                            dragOverlay="nobackground"
                            type="CampaignPlayer">
                            <Button
                                tooltip="Party"
                                tooltipDirection="up"
                                onClick={() => {
                                    // Select all the tokens belonging to party members.
                                    if (isLocation(location)) {
                                        const partyTokens = Object.values(location.tokens).filter(o => {
                                            const owner = getTokenOwner(campaign, o);
                                            return (
                                                owner != null &&
                                                playerIds.indexOf(owner) >= 0 &&
                                                session.campaign.players[owner]?.role !== "GM"
                                            );
                                        });
                                        setSelection(partyTokens.map(o => o.id));
                                    }
                                }}
                                css={{
                                    background: theme.colors.accent[4],
                                    borderRadius: "50%",
                                    width: theme.space[5],
                                    height: theme.space[5],
                                    ":hover": {
                                        background: theme.colors.accent[5],
                                    },
                                }}>
                                <PartyIcon css={{ position: "absolute" }} />
                            </Button>
                        </DraggableBox>
                    </AvatarHostBox>

                    {playerIds.map(o => {
                        const rtcUser = user.id === o ? rtc?.self : rtc?.peers[o];
                        return (
                            <PlayerAvatar
                                key={o}
                                sessionPlayer={session.players[o]}
                                campaignPlayer={session.campaign.players[o]}
                                rtc={rtcUser}
                            />
                        );
                    })}
                </LayoutGroup>
            </Box>
        </div>
    );
});

const RightInnerHost: FunctionComponent<{}> = React.memo(() => {
    const locationData = useLocation();
    const campaign = locationData.campaign;
    const player = campaign.players[locationData.user.id];
    const role = useRole();
    const dicebag = useDiceBag();
    const {
        cameraMode,
        setCameraMode,
        setIsCameraChanging,
        isPropertiesExpanded,
        mode,
        tool,
        subtool,
        buildMode,
        setMode,
        setTool,
        setBuildMode,
    } = useVttApp();
    const { primary, secondary, setSelection } = useSelection();

    const dispatch = useDispatch();

    const location = isLocation(locationData.location) ? locationData.location : undefined;

    const [isLayerManagerOpen, setIsLayerManagerOpen] = useState(false);
    useKeyboardShortcut(
        "Escape",
        ev => {
            setIsLayerManagerOpen(false);
            ev.preventDefault();
        },
        { priority: 5, isDisabled: !isLayerManagerOpen }
    );

    return (
        <Box
            flexDirection="row"
            css={{
                gridArea: "rightinner",
                justifySelf: "end",
                alignItems: "flex-start",
            }}>
            <LayoutGroup>
                <AnimatePresence>
                    {location &&
                        location.combat &&
                        mode === "play" &&
                        (role === "GM" ||
                            (location.combat.round != null && location.combat.turn != null) ||
                            isCombatParticipant(campaign, location, player)) && (
                            <Box zIndex={3} fullHeight css={{ pointerEvents: "none", position: "relative" }}>
                                <MotionBox
                                    animate={{
                                        opacity: isLayerManagerOpen ? 0.4 : 1,
                                        scale: isLayerManagerOpen ? 0.9 : 1,
                                        x: isLayerManagerOpen ? theme.space[9] : 0,
                                    }}
                                    transition={{
                                        x: {
                                            type: "tween",
                                            ease: "easeOut",
                                            delay: isLayerManagerOpen ? 0.1 : 0,
                                        },
                                        default: { delay: isLayerManagerOpen ? 0 : 0.15 },
                                    }}
                                    position="absolute"
                                    fullHeight
                                    pt={isPropertiesExpanded ? 3 : 7}
                                    pr={3}
                                    pb={3}
                                    alignItems="flex-start"
                                    width="max-content"
                                    css={{ right: 0 }}>
                                    <CombatTracker combat={location.combat} />
                                </MotionBox>
                            </Box>
                        )}
                </AnimatePresence>
                <Box flexDirection="column" pt={isPropertiesExpanded ? 3 : 7} position="relative">
                    <MotionBox
                        initial={{ opacity: 0, x: "100%" }}
                        animate={{ opacity: 1, x: 0 }}
                        mr={2}
                        mb={4}
                        zIndex={10}
                        flexDirection="column">
                        <MotionOverlayToolbar direction="vertical" prominent layout>
                            {role === "GM" && (
                                <ToolbarButton
                                    tooltip="Levels"
                                    tooltipDirection="up"
                                    isToggled={isLayerManagerOpen}
                                    onClick={() => {
                                        setIsLayerManagerOpen(!isLayerManagerOpen);
                                    }}>
                                    <LayersIcon />
                                </ToolbarButton>
                            )}
                            <ToolbarButton
                                isToggled={cameraMode === "perspective"}
                                onClick={() => {
                                    const targetMode = cameraMode === "orthographic" ? "perspective" : "orthographic";
                                    if (targetMode === "orthographic") {
                                        setIsCameraChanging(true);
                                    }

                                    setCameraMode(targetMode);
                                }}>
                                <Text fontWeight="bold" fontSize={2} color="inherit">
                                    3D
                                </Text>
                            </ToolbarButton>
                        </MotionOverlayToolbar>

                        <AnimatePresence>
                            {isLayerManagerOpen && (
                                <MotionCard
                                    layout
                                    css={{ position: "absolute", right: "100%", top: 0 }}
                                    initial={{ opacity: 0, x: theme.space[3] }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: theme.space[3] }}
                                    transition={{ x: { type: "tween", ease: "easeOut" } }}
                                    mr={2}
                                    flexDirection="column"
                                    borderRadius={4}
                                    bg="background"
                                    boxShadowIntensity={1}
                                    boxShadowSize="xl">
                                    <LayerManager />
                                </MotionCard>
                            )}
                        </AnimatePresence>
                    </MotionBox>

                    <MotionBox
                        initial={{ opacity: 0, x: "100%" }}
                        animate={{ opacity: 1, x: 0 }}
                        mr={2}
                        zIndex={10}
                        flexDirection="column">
                        <DiceButton
                            tooltip="d4"
                            terms={dicebag?.dice?.d4}
                            onClick={e =>
                                dicebag.setDice(addDieToBag("d4", dicebag?.dice, e.ctrlKey ? "-" : undefined))
                            }>
                            <D4Icon size={40 as any} />
                        </DiceButton>
                        <DiceButton
                            tooltip="d6"
                            terms={dicebag?.dice?.d6}
                            onClick={e =>
                                dicebag.setDice(addDieToBag("d6", dicebag?.dice, e.ctrlKey ? "-" : undefined))
                            }>
                            <D6Icon size={40 as any} />
                        </DiceButton>
                        <DiceButton
                            tooltip="d8"
                            terms={dicebag?.dice?.d8}
                            onClick={e =>
                                dicebag.setDice(addDieToBag("d8", dicebag?.dice, e.ctrlKey ? "-" : undefined))
                            }>
                            <D8Icon size={40 as any} />
                        </DiceButton>
                        <DiceButton
                            tooltip="d10"
                            terms={dicebag?.dice?.d10}
                            onClick={e =>
                                dicebag.setDice(addDieToBag("d10", dicebag?.dice, e.ctrlKey ? "-" : undefined))
                            }>
                            <D10Icon size={40 as any} />
                        </DiceButton>
                        <DiceButton
                            tooltip="d12"
                            terms={dicebag?.dice?.d12}
                            onClick={e =>
                                dicebag.setDice(addDieToBag("d12", dicebag?.dice, e.ctrlKey ? "-" : undefined))
                            }>
                            <D12Icon size={40 as any} />
                        </DiceButton>
                        <DiceButton
                            tooltip="d20"
                            terms={dicebag?.dice?.d20}
                            onClick={e =>
                                dicebag.setDice(addDieToBag("d20", dicebag?.dice, e.ctrlKey ? "-" : undefined))
                            }>
                            <D20Icon size={40 as any} />
                        </DiceButton>
                        <DiceButton
                            tooltip="d100"
                            terms={dicebag?.dice?.d100}
                            onClick={e =>
                                dicebag.setDice(addDieToBag("d100", dicebag?.dice, e.ctrlKey ? "-" : undefined))
                            }>
                            <D10Icon
                                size={28 as any}
                                style={{
                                    position: "absolute",
                                    top: -2,
                                    left: -2,
                                    transform: "rotateZ(-10deg)",
                                }}
                            />
                            <D10Icon
                                size={28 as any}
                                style={{
                                    position: "absolute",
                                    bottom: -2,
                                    right: -2,
                                    transform: "rotateZ(15deg)",
                                }}
                            />
                        </DiceButton>
                    </MotionBox>
                    <MotionBox
                        initial={{ opacity: 0, x: "100%" }}
                        animate={{ opacity: 1, x: 0 }}
                        mr={2}
                        mt={4}
                        flexGrow={1}
                        zIndex={10}
                        justifyContent="flex-start"
                        position="relative"
                        flexDirection="column">
                        <AnimatePresence>
                            {role === "GM" && mode === "build" && (
                                <MotionOverlayToolbar
                                    direction="vertical"
                                    prominent
                                    layout
                                    css={{ position: "absolute" }}
                                    initial={{ opacity: 0, left: 0 }}
                                    animate={{
                                        opacity: 1,
                                        left: `calc(-100% - ${theme.space[1]}px)`,
                                    }}
                                    exit={{ opacity: 0, left: 0 }}>
                                    {/* <Box bg="background" css={{ height: theme.space[6], width: theme.space[3], position: "absolute", left: "100%", top: "50%", transform: `translate(0, -50%) translate(0, -${theme.space[1]}px)` }} /> */}
                                    <ToolbarButton
                                        tooltip="Tokens"
                                        tooltipDirection="left"
                                        isToggled={buildMode === "tokens"}
                                        onClick={() => {
                                            setBuildMode("tokens");
                                            if (location) {
                                                const p = getSelectionByType(primary, campaign, location);
                                                const s = getSelectionByType(secondary, campaign, location);
                                                setSelection(
                                                    [
                                                        ...p.annotations.map(o => o.id),
                                                        ...p.tokens.filter(o => o.type !== "object").map(o => o.id),
                                                    ],
                                                    [
                                                        ...s.annotations.map(o => o.id),
                                                        ...s.tokens.filter(o => o.type !== "object").map(o => o.id),
                                                    ]
                                                );
                                            }
                                        }}>
                                        <DragonIcon />
                                    </ToolbarButton>
                                    <ToolbarButton
                                        tooltip="Zones"
                                        tooltipDirection="left"
                                        isToggled={buildMode === "zones"}
                                        onClick={() => {
                                            setBuildMode("zones");
                                            if (location) {
                                                const p = getSelectionByType(primary, campaign, location);
                                                const s = getSelectionByType(secondary, campaign, location);
                                                setSelection(
                                                    p.zones.map(o => o.id),
                                                    s.zones.map(o => o.id)
                                                );
                                            }
                                        }}>
                                        <NotebookIcon />
                                    </ToolbarButton>
                                    <ToolbarButton
                                        tooltip="Environment"
                                        tooltipDirection="left"
                                        isToggled={buildMode === "base"}
                                        onClick={() => {
                                            setBuildMode("base");
                                            if (location) {
                                                const p = getSelectionByType(primary, campaign, location);
                                                const s = getSelectionByType(secondary, campaign, location);
                                                setSelection(
                                                    p.tokens.filter(o => o.type === "object").map(o => o.id),
                                                    s.tokens.filter(o => o.type === "object").map(o => o.id)
                                                );
                                            }
                                        }}>
                                        <MapIcon />
                                    </ToolbarButton>
                                </MotionOverlayToolbar>
                            )}
                        </AnimatePresence>
                        {role === "GM" && (
                            <MotionOverlayToolbar direction="vertical" mb={2} prominent layout>
                                <ToolbarButton
                                    tooltip="Build mode"
                                    tooltipDirection="left"
                                    isToggled={mode === "build"}
                                    onClick={() => {
                                        setMode("build");

                                        const p = getSelectionByType(primary, campaign, location);
                                        const s = getSelectionByType(secondary, campaign, location);
                                        switch (buildMode) {
                                            case "base":
                                                setSelection(
                                                    p.tokens.filter(o => o.type === "object").map(o => o.id),
                                                    s.tokens.filter(o => o.type === "object").map(o => o.id)
                                                );
                                                break;
                                            case "zones":
                                                setSelection(
                                                    p.zones.map(o => o.id),
                                                    s.zones.map(o => o.id)
                                                );
                                                break;
                                            case "tokens":
                                                if (location) {
                                                    setSelection(
                                                        [
                                                            ...p.annotations.map(o => o.id),
                                                            ...p.tokens.filter(o => o.type !== "object").map(o => o.id),
                                                        ],
                                                        [
                                                            ...s.annotations.map(o => o.id),
                                                            ...s.tokens.filter(o => o.type !== "object").map(o => o.id),
                                                        ]
                                                    );
                                                }
                                                break;
                                        }
                                    }}>
                                    <BuildMode />
                                </ToolbarButton>
                                <ToolbarButton
                                    tooltip="Play mode"
                                    tooltipDirection="left"
                                    isToggled={mode === "play"}
                                    onClick={() => {
                                        setMode("play");
                                        if (location) {
                                            const p = getSelectionByType(primary, campaign, location);
                                            const s = getSelectionByType(secondary, campaign, location);
                                            setSelection(
                                                [
                                                    ...p.annotations
                                                        .filter(o => !isLineAnnotation(o) || o.subtype !== "wall")
                                                        .map(o => o.id),
                                                    ...p.tokens
                                                        .filter(o => getTokenType(campaign, o) === "creature")
                                                        .map(o => o.id),
                                                ],
                                                [
                                                    ...s.annotations
                                                        .filter(o => !isLineAnnotation(o) || o.subtype !== "wall")
                                                        .map(o => o.id),
                                                    ...s.tokens
                                                        .filter(o => getTokenType(campaign, o) === "creature")
                                                        .map(o => o.id),
                                                ]
                                            );
                                        }
                                    }}>
                                    <PlayMode style={{ transform: "scale(1.1)" }} />
                                </ToolbarButton>
                            </MotionOverlayToolbar>
                        )}

                        {location && role === "GM" && (
                            <MotionOverlayToolbar direction="vertical" mb={2} prominent layout>
                                <ToolbarButton
                                    tooltip={
                                        buildMode === "tokens"
                                            ? "Toggle lighting (when no token is selected)"
                                            : "Toggle lighting"
                                    }
                                    tooltipDirection="left"
                                    isToggled={location.showLightingInBuild}
                                    onClick={() => {
                                        dispatch(
                                            modifyLocation(campaign.id, location.id, {
                                                showLightingInBuild: !location.showLightingInBuild,
                                            })
                                        );
                                    }}>
                                    <LanternIcon />
                                </ToolbarButton>
                            </MotionOverlayToolbar>
                        )}

                        <MotionOverlayToolbar direction="vertical" prominent layout>
                            <ToolbarButton
                                tooltip="Pan &amp; select"
                                tooltipDirection="left"
                                isToggled={tool === "select"}
                                onClick={() => {
                                    setTool("select");
                                }}>
                                <SelectTool />
                            </ToolbarButton>

                            {(mode === "play" || buildMode === "tokens") && (
                                <React.Fragment>
                                    <ToolbarButton
                                        tooltip="Line"
                                        tooltipDirection="left"
                                        isToggled={tool === "line" && !subtool}
                                        onClick={() => {
                                            setTool("line");
                                        }}>
                                        <LineTool />
                                    </ToolbarButton>
                                    <ToolbarButton
                                        tooltip="Rectangle"
                                        tooltipDirection="left"
                                        isToggled={tool === "rect"}
                                        onClick={() => {
                                            setTool("rect");
                                        }}>
                                        <RectTool />
                                    </ToolbarButton>
                                    <ToolbarButton
                                        tooltip="Ellipse"
                                        tooltipDirection="left"
                                        isToggled={tool === "ellipse"}
                                        onClick={() => {
                                            setTool("ellipse");
                                        }}>
                                        <EllipseTool />
                                    </ToolbarButton>
                                </React.Fragment>
                            )}

                            {mode === "build" && buildMode === "zones" && (
                                <React.Fragment>
                                    <ToolbarButton
                                        tooltip="Zone"
                                        tooltipDirection="left"
                                        isToggled={tool === "zone" && !subtool}
                                        onClick={() => {
                                            setTool("zone");
                                        }}>
                                        <LineTool />
                                    </ToolbarButton>
                                    <ToolbarButton
                                        tooltip="Fill zone"
                                        tooltipDirection="left"
                                        isToggled={tool === "zonefill"}
                                        onClick={() => {
                                            setTool("zonefill");
                                        }}>
                                        <FillTool />
                                    </ToolbarButton>
                                </React.Fragment>
                            )}

                            {mode === "build" && buildMode === "tokens" && (
                                <React.Fragment>
                                    <ToolbarButton
                                        tooltip="Wall"
                                        tooltipDirection="left"
                                        isToggled={tool === "line" && subtool === "wall"}
                                        onClick={() => {
                                            setTool("line", "wall");
                                        }}>
                                        <WallTool />
                                    </ToolbarButton>
                                    <ToolbarButton
                                        tooltip="Door"
                                        tooltipDirection="left"
                                        isToggled={tool === "door"}
                                        onClick={() => {
                                            setTool("door");
                                        }}>
                                        <DoorTool />
                                    </ToolbarButton>
                                    <ToolbarButton
                                        tooltip="Window"
                                        tooltipDirection="left"
                                        isToggled={tool === "window"}
                                        onClick={() => {
                                            setTool("window");
                                        }}>
                                        <WindowTool />
                                    </ToolbarButton>
                                </React.Fragment>
                            )}
                        </MotionOverlayToolbar>
                    </MotionBox>
                </Box>
            </LayoutGroup>
        </Box>
    );
});

async function noError<T>(promise: Promise<T>) {
    try {
        await promise;
    } catch (e) {
        // Deliberately swallow error.
        console.log(e);
    }
}

const LogMessageNotification: FunctionComponent<{ logEntry: LogEntry }> = ({ logEntry }) => {
    const { profiles } = useProfiles([logEntry.userId]);
    const { system, campaign } = useCampaign();

    const profile = profiles?.[logEntry.userId];

    return (
        <Box flexDirection="row" alignItems="start" alignSelf="stretch" p={3} borderColor="grayscale.6">
            <ProfileAvatar profile={profile} campaignProfile={campaign.players[logEntry.userId]} mr={2} />
            <Box flex={1} flexDirection="column" alignItems="start">
                <Box>
                    {profile && (
                        <Text fontWeight="bold" mr={1}>
                            {profile.name}
                        </Text>
                    )}
                </Box>
                <React.Fragment>
                    {logEntry.data &&
                        system.renderLogHeader &&
                        system.renderLogHeader(logEntry, getToken(campaign, logEntry.location, logEntry.token))}
                    <Markdown>{(logEntry as MessageLogEntry).message}</Markdown>
                </React.Fragment>
            </Box>
        </Box>
    );
};

const LOG_NOTIFICATION_TIMEOUT = 5000;

const CampaignHost: FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const sessionConnection = useSessionConnection();
    const session = sessionConnection.session;
    const campaignData = useCampaign();
    const campaign = session.campaign;
    const { location, user } = useLocation();
    const player = campaign.players[user.id];

    const addNotification = useNotifications();
    const Bridge = useContextBridge(
        DispatchContext,
        ThemeContext,
        UserContext,
        SessionConnectionContext,
        SessionContext,
        CampaignContext,
        AppStateContext,
        SelectionContext,
        DiceBagContext,
        TokenOverrideContext,
        AnnotationOverrideContext,
        ZoneOverrideContext,
        AnnotationCacheContext,
        LocalGridContext,
        ViewportContext,
        ScaleContext,
        ...campaignData.system.getContexts()
    );

    const selection = useSelection();

    const selectionRef = useRef<string[]>();
    selectionRef.current = selection.primary;

    const logEntriesToIgnore = useMemo<{ [id: string]: LogEntry }>(() => ({}), []);
    useEffect(() => {
        const logEntries = Object.values(session.log);
        const time = Date.now();

        for (let i = 0; i < logEntries.length; i++) {
            const logEntry = logEntries[i];
            if (
                (!logEntry.type || logEntry.type === "message") && // Only show messages, dice rolls are handled in DiceBox - maybe we should move that and handle it all here?
                time - logEntry.time < LOG_NOTIFICATION_TIMEOUT &&
                logEntriesToIgnore[logEntry.id] == null &&
                shouldShowNotification("token", user.id, logEntry, selectionRef.current, true)
            ) {
                // Found a message that we should show as a notification.
                addNotification({
                    content: (
                        <Message>
                            <Bridge>
                                <LogMessageNotification logEntry={logEntry} />
                            </Bridge>
                        </Message>
                    ),
                    canDismiss: true,
                    showLife: true,
                    timeout: LOG_NOTIFICATION_TIMEOUT,
                });

                // We don't want to show the notification again if we rerender, so store the notification in a list of notifications
                // that we are ignoring.
                logEntriesToIgnore[logEntry.id] = logEntry;
            }
        }

        // Forget about any log entries that are too old to care about any more.
        for (let id in logEntriesToIgnore) {
            const logEntry = logEntriesToIgnore[id];
            if (time - logEntry.time > LOG_NOTIFICATION_TIMEOUT) {
                delete logEntriesToIgnore[id];
            }
        }

        logEntries.sort((a, b) => a.time - b.time);
    }, [session.log, logEntriesToIgnore, Bridge, addNotification, user.id]);

    const [diceToRoll, setDiceToRoll] = useState<DiceBag | undefined>();

    const downloadPromise = useDownloadPromise();
    useEffect(() => {
        if (downloadPromise) {
            setTimeout(() => {
                if (getDownloadPromise() === downloadPromise) {
                    addNotification({
                        content: (
                            <Message>
                                <DownloadProgress />
                            </Message>
                        ),
                        canDismiss: false,
                        showLife: false,
                        promise: () => noError(downloadPromise),
                    });
                }
            }, 200);
        }
    }, [downloadPromise, addNotification]);

    const uploadPromise = useUploadPromise();
    useEffect(() => {
        if (uploadPromise) {
            setTimeout(() => {
                if (getUploadPromise() === uploadPromise) {
                    addNotification({
                        content: (
                            <Message>
                                <UploadProgress />
                            </Message>
                        ),
                        canDismiss: false,
                        showLife: false,
                        promise: () => noError(uploadPromise),
                    });
                }
            }, 200);
        }
    }, [uploadPromise, addNotification]);

    useEffect(() => {
        document.title = `${campaign.label} | ${title}`;
        return () => {
            document.title = title;
        };
    }, [campaign.label]);

    const loc: Location | LocationSummary | undefined = campaign.locations[player.location];
    const currentLocation: Location | undefined = isLocation(loc) ? loc : undefined;

    useEffect(() => {
        if (!player.colour) {
            // Set up the player with a default colour.
            const usedColours: (keyof Colours)[] = Object.keys(campaign.players).map(
                o => campaign.players[o].colour as keyof Colours
            );
            usedColours.push("grayscale", "blues", "accent");
            const colour = getRandomPalette(usedColours);
            dispatch(setPlayerColour(campaign.id, user.id, colour));
        }
    }, [player, campaign.id, user.id, dispatch, campaign.players]);

    const dicebag = useMemo(() => ({ dice: diceToRoll, setDice: setDiceToRoll }), [diceToRoll, setDiceToRoll]);

    // Create a local grid instance which does NOT change when the user pans or zooms (i.e. it will not cause a rerender for things
    // that aren't relevant to the local coordinate system).
    const gridRef = useRef<IGrid>();
    const localGrid = useMemo(() => new LocalGrid(gridRef), [gridRef]);

    // Allow override of annotation properties, without actually affecting the campaign state.
    // Refs are used to avoid recreating the callback function when the overrides change.
    // This helps keep updates on child components to a minimum.
    const [annotationOverrides, setAnnotationOverrides] = useState(
        undefined as { [id: string]: DeepPartial<Annotation> } | undefined
    );
    const annotationOverridesRef = useRef(annotationOverrides);
    annotationOverridesRef.current = annotationOverrides;
    const overrideAnnotation = useCallback(
        (id: string, override: DeepPartial<Annotation> | undefined) => {
            const annotationOverrides = annotationOverridesRef.current;
            if (override) {
                // Use Object.assign rather than mergeState so that we preserve undefined values (i.e. override by removing the value).
                let overrides = annotationOverrides
                    ? Object.assign({}, annotationOverrides, { [id]: override })
                    : { [id]: override };
                setAnnotationOverrides(overrides);
            } else if (annotationOverrides && annotationOverrides[id]) {
                let overrides = Object.assign({}, annotationOverrides);
                delete overrides[id];

                setAnnotationOverrides(overrides);
            }
        },
        [annotationOverridesRef]
    );
    const [tokenOverrides, setTokenOverrides] = useState(undefined as { [id: string]: DeepPartial<Token> } | undefined);
    const tokenOverridesRef = useRef(tokenOverrides);
    tokenOverridesRef.current = tokenOverrides;
    const overrideToken = useCallback(
        (id: string, override: DeepPartial<Token> | undefined) => {
            const tokenOverrides = tokenOverridesRef.current;
            if (override) {
                // Use Object.assign rather than mergeState so that we preserve undefined values (i.e. override by removing the value).
                setTokenOverrides(
                    tokenOverrides ? Object.assign({}, tokenOverrides, { [id]: override }) : { [id]: override }
                );
            } else if (tokenOverrides && tokenOverrides[id]) {
                let overrides = Object.assign({}, tokenOverrides);
                delete overrides[id];
                setTokenOverrides(overrides);
            }
        },
        [tokenOverridesRef]
    );
    const [zoneOverrides, setZoneOverrides] = useState(undefined as { [id: string]: DeepPartial<Zone> } | undefined);
    const zoneOverridesRef = useRef(zoneOverrides);
    zoneOverridesRef.current = zoneOverrides;
    const overrideZone = useCallback(
        (id: string, override: DeepPartial<Zone> | undefined) => {
            const zoneOverrides = zoneOverridesRef.current;
            if (override) {
                // Use Object.assign rather than mergeState so that we preserve undefined values (i.e. override by removing the value).
                setZoneOverrides(
                    zoneOverrides ? Object.assign({}, zoneOverrides, { [id]: override }) : { [id]: override }
                );
                // setZoneOverrides(zoneOverrides ? mergeState(zoneOverrides, { [id]: override }) : { [id]: override });
            } else if (zoneOverrides && zoneOverrides[id]) {
                let overrides = Object.assign({}, zoneOverrides);
                delete overrides[id];
                setZoneOverrides(overrides);
            }
        },
        [zoneOverridesRef]
    );

    const tokenOverrideContextProps = useMemo(
        () => ({
            tokenOverrides: tokenOverrides,
            overrideToken: overrideToken,
        }),
        [tokenOverrides, overrideToken]
    );
    const annotationOverrideContextProps = useMemo(
        () => ({
            annotationOverrides: annotationOverrides,
            overrideAnnotation: overrideAnnotation,
        }),
        [annotationOverrides, overrideAnnotation]
    );
    const zoneOverrideContextProps = useMemo(
        () => ({
            zoneOverrides: zoneOverrides,
            overrideZone: overrideZone,
        }),
        [zoneOverrides, overrideZone]
    );

    const [viewport, setViewport] = useState<Viewport>();

    const annotationCache = useMemo(() => {
        const cache: AnnotationGridPointsCache = {};
        return {
            getGridPoints: (
                annotation: Annotation,
                campaign: Campaign,
                location: Location,
                grid: ILocalGrid,
                tokenOverrides: { [id: string]: DeepPartial<Token> } | undefined,
                clipper: ClipperLibWrapper
            ) => {
                return getGridPointsForAnnotation(cache, annotation, campaign, location, grid, tokenOverrides, clipper);
            },
        };

        // The location.id dependency isn't necessary, but we want the cache to be reset when the location changes.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.id]);

    const role = getRole(user, campaign);
    const { mode, buildMode, tool } = useVttApp();

    const sessionLocation = session.locations && currentLocation ? session.locations[currentLocation.id] : undefined;
    return (
        <DiceBagContext.Provider value={dicebag}>
            <TokenOverrideContext.Provider value={tokenOverrideContextProps}>
                <AnnotationOverrideContext.Provider value={annotationOverrideContextProps}>
                    <ZoneOverrideContext.Provider value={zoneOverrideContextProps}>
                        <AnnotationCacheContext.Provider value={annotationCache}>
                            <LocalGridContext.Provider value={localGrid}>
                                <ViewportContext.Provider value={viewport}>
                                    <LocationHost
                                        mode={mode}
                                        location={loc}
                                        role={role}
                                        campaignPlayer={player}
                                        gridRef={gridRef}
                                        localGrid={localGrid}
                                        onViewportChanged={setViewport}
                                    />

                                    <DiceBox
                                        bridge={Bridge}
                                        css={{ gridArea: "1 / 1 / 4 / 6", position: "relative" }}
                                        zIndex={3}
                                        diceToRoll={diceToRoll}
                                        setDiceToRoll={setDiceToRoll}
                                        fullHeight
                                        fullWidth
                                    />

                                    {currentLocation && (
                                        <React.Fragment>
                                            <LocationPlaylist
                                                type={AudioType.Music}
                                                playlist={currentLocation.music}
                                                sessionPlaylist={sessionLocation?.music}
                                                isMutedSetting={musicMutedSetting}
                                                volumeSetting={musicVolumeSetting}
                                            />
                                            {tracksToArray(currentLocation.ambientAudio?.tracks).map(o => (
                                                <AmbientTrack key={o.id} track={o} />
                                            ))}
                                        </React.Fragment>
                                    )}

                                    <RightInnerBottomHost />

                                    {mode === "build" && buildMode === "tokens" && role === "GM" && (
                                        <Help id="mode_build" key="mode_build">
                                            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                Build → Tokens
                                            </Truncate>
                                            <Text>
                                                You are currently in tokens build mode. In this mode you can add, remove
                                                or manipulate tokens on the map.
                                            </Text>
                                        </Help>
                                    )}

                                    {mode === "build" && buildMode === "zones" && (
                                        <Help id="mode_build_zones" key="mode_build_zones">
                                            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                Build → Zones
                                            </Truncate>
                                            <Text>
                                                <p>
                                                    You are currently in zone build mode. Zones allow you to specify a
                                                    name and notes for an area of the map. You can also set other
                                                    properties for the area, including weather effects.
                                                </p>
                                                <p css={{ marginTop: theme.space[2] }}>
                                                    Zones are only visible on the map in this mode, but are always
                                                    visible in the mini map. The notes for the currently focused zone
                                                    are shown in the location properties in play mode.
                                                </p>
                                            </Text>
                                        </Help>
                                    )}

                                    {mode === "build" && buildMode === "base" && (
                                        <Help id="mode_build_environment" key="mode_build_environment">
                                            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                Build → Environment
                                            </Truncate>
                                            <Text>
                                                This mode allows you to add images to the map to add detail. The images
                                                you add here are not treated as tokens, so they become part of the
                                                environment in play mode.
                                            </Text>
                                        </Help>
                                    )}

                                    {mode === "play" && role === "GM" && (
                                        <Help id="mode_play" key="mode_play">
                                            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                Play mode
                                            </Truncate>
                                            <Text>
                                                You are currently in play mode. Play mode is a more streamlined
                                                experience allowing you to focus on the action during a session.
                                            </Text>
                                        </Help>
                                    )}

                                    {tool === "select" && (
                                        <Help id="tool_select" key="tool_select">
                                            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                Basic controls
                                            </Truncate>
                                            <Grid
                                                gridGap={2}
                                                gridTemplateRows="repeat(4, auto)"
                                                gridTemplateColumns="repeat(3, auto)"
                                                color="grayscale.1">
                                                <Mouse css={{ gridArea: "1 / 1 / 2 / 2" }} />
                                                <Text css={{ gridArea: "1 / 2 / 1 / 3" }} color="grayscale.2">
                                                    Left click and drag
                                                </Text>
                                                <Text css={{ gridArea: "1 / 3 / 1 / 4" }}>Pan map or move token</Text>
                                                <Keyboard css={{ gridArea: "2 / 1 / 3 / 2" }} />
                                                <Text css={{ gridArea: "2 / 2 / 3 / 3" }} color="grayscale.2">
                                                    Space
                                                </Text>
                                                <Text css={{ gridArea: "2 / 3 / 3 / 4" }}>
                                                    Add waypoint (when moving token)
                                                </Text>
                                                <Mouse css={{ gridArea: "3 / 1 / 4 / 2" }} />
                                                <Text css={{ gridArea: "3 / 2 / 4 / 3" }} color="grayscale.2">
                                                    Right click and drag
                                                </Text>
                                                <Text css={{ gridArea: "3 / 3 / 4 / 4" }}>
                                                    Measure distance/rotate token
                                                </Text>
                                                <Keyboard css={{ gridArea: "4 / 1 / 5 / 2" }} />
                                                <Text css={{ gridArea: "4 / 2 / 5 / 3" }} color="grayscale.2">
                                                    Shift
                                                </Text>
                                                <Text css={{ gridArea: "4 / 3 / 5 / 4" }}>Snap to grid</Text>
                                            </Grid>
                                        </Help>
                                    )}

                                    {(mode === "play" || buildMode === "tokens") && tool === "line" && (
                                        <Help id="tool_line" key="tool_line">
                                            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                How to draw a line
                                            </Truncate>
                                            <Grid
                                                gridGap={2}
                                                gridTemplateRows="repeat(4, auto)"
                                                gridTemplateColumns="repeat(3, auto)"
                                                color="grayscale.1">
                                                <Mouse css={{ gridArea: "1 / 1 / 2 / 2" }} />
                                                <Text css={{ gridArea: "1 / 2 / 1 / 3" }} color="grayscale.2">
                                                    Left click
                                                </Text>
                                                <Text css={{ gridArea: "1 / 3 / 1 / 4" }}>Add point</Text>
                                                <Mouse css={{ gridArea: "2 / 1 / 3 / 2" }} />
                                                <Text css={{ gridArea: "2 / 2 / 3 / 3" }} color="grayscale.2">
                                                    Double left click
                                                </Text>
                                                <Text css={{ gridArea: "2 / 3 / 3 / 4" }}>Add point and finish</Text>
                                                <Mouse css={{ gridArea: "3 / 1 / 4 / 2" }} />
                                                <Text css={{ gridArea: "3 / 2 / 4 / 3" }} color="grayscale.2">
                                                    Left click and drag
                                                </Text>
                                                <Text css={{ gridArea: "3 / 3 / 4 / 4" }}>Create simple line</Text>
                                                <Keyboard css={{ gridArea: "4 / 1 / 5 / 2" }} />
                                                <Text css={{ gridArea: "4 / 2 / 5 / 3" }} color="grayscale.2">
                                                    Shift
                                                </Text>
                                                <Text css={{ gridArea: "4 / 3 / 5 / 4" }}>Snap to grid</Text>
                                                <Keyboard css={{ gridArea: "5 / 1 / 6 / 2" }} />
                                                <Text css={{ gridArea: "5 / 2 / 6 / 3" }} color="grayscale.2">
                                                    Escape
                                                </Text>
                                                <Text css={{ gridArea: "5 / 3 / 6 / 4" }}>Finish line</Text>
                                            </Grid>
                                        </Help>
                                    )}

                                    {(mode === "play" || buildMode === "tokens") && tool === "rect" && (
                                        <Help id="tool_rect" key="tool_rect">
                                            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                How to draw a rectangle
                                            </Truncate>
                                            <Grid
                                                gridGap={2}
                                                gridTemplateRows="repeat(3, auto)"
                                                gridTemplateColumns="repeat(3, auto)"
                                                color="grayscale.1">
                                                <Mouse css={{ gridArea: "1 / 1 / 2 / 2" }} />
                                                <Text css={{ gridArea: "1 / 2 / 1 / 3" }} color="grayscale.2">
                                                    Left click
                                                </Text>
                                                <Text css={{ gridArea: "1 / 3 / 1 / 4" }}>Start/finish rectangle</Text>
                                                <Mouse css={{ gridArea: "2 / 1 / 3 / 2" }} />
                                                <Text css={{ gridArea: "2 / 2 / 3 / 3" }} color="grayscale.2">
                                                    Left click and drag
                                                </Text>
                                                <Text css={{ gridArea: "2 / 3 / 3 / 4" }}>Create rectangle</Text>
                                                <Keyboard css={{ gridArea: "3 / 1 / 4 / 2" }} />
                                                <Text css={{ gridArea: "3 / 2 / 4 / 3" }} color="grayscale.2">
                                                    Shift
                                                </Text>
                                                <Text css={{ gridArea: "3 / 3 / 4 / 4" }}>Snap to grid</Text>
                                                <Keyboard css={{ gridArea: "4 / 1 / 5 / 2" }} />
                                                <Text css={{ gridArea: "4 / 2 / 5 / 3" }} color="grayscale.2">
                                                    Escape
                                                </Text>
                                                <Text css={{ gridArea: "4 / 3 / 5 / 4" }}>Cancel rectangle</Text>
                                            </Grid>
                                        </Help>
                                    )}

                                    {(mode === "play" || buildMode === "tokens") && tool === "ellipse" && (
                                        <Help id="tool_ellipse" key="tool_ellipse">
                                            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                How to draw an ellipse
                                            </Truncate>
                                            <Grid
                                                gridGap={2}
                                                gridTemplateRows="repeat(3, auto)"
                                                gridTemplateColumns="repeat(3, auto)"
                                                color="grayscale.1">
                                                <Mouse css={{ gridArea: "1 / 1 / 2 / 2" }} />
                                                <Text css={{ gridArea: "1 / 2 / 1 / 3" }} color="grayscale.2">
                                                    Left click
                                                </Text>
                                                <Text css={{ gridArea: "1 / 3 / 1 / 4" }}>Start/finish ellipse</Text>
                                                <Mouse css={{ gridArea: "2 / 1 / 3 / 2" }} />
                                                <Text css={{ gridArea: "2 / 2 / 3 / 3" }} color="grayscale.2">
                                                    Left click and drag
                                                </Text>
                                                <Text css={{ gridArea: "2 / 3 / 3 / 4" }}>Create ellipse</Text>
                                                <Keyboard css={{ gridArea: "3 / 1 / 4 / 2" }} />
                                                <Text css={{ gridArea: "3 / 2 / 4 / 3" }} color="grayscale.2">
                                                    Shift
                                                </Text>
                                                <Text css={{ gridArea: "3 / 3 / 4 / 4" }}>Snap to grid</Text>
                                                <Keyboard css={{ gridArea: "4 / 1 / 5 / 2" }} />
                                                <Text css={{ gridArea: "4 / 2 / 5 / 3" }} color="grayscale.2">
                                                    Escape
                                                </Text>
                                                <Text css={{ gridArea: "4 / 3 / 5 / 4" }}>Cancel ellipse</Text>
                                            </Grid>
                                        </Help>
                                    )}

                                    {mode === "build" && buildMode === "zones" && tool === "zone" && role === "GM" && (
                                        <Help id="tool_zone" key="tool_zone">
                                            <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                How to draw a zone
                                            </Truncate>
                                            <Grid
                                                gridGap={2}
                                                gridTemplateRows="repeat(4, auto)"
                                                gridTemplateColumns="repeat(3, auto)"
                                                color="grayscale.1">
                                                <Mouse css={{ gridArea: "1 / 1 / 2 / 2" }} />
                                                <Text css={{ gridArea: "1 / 2 / 1 / 3" }} color="grayscale.2">
                                                    Left click
                                                </Text>
                                                <Text css={{ gridArea: "1 / 3 / 1 / 4" }}>Add point</Text>
                                                <Mouse css={{ gridArea: "2 / 1 / 3 / 2" }} />
                                                <Text css={{ gridArea: "2 / 2 / 3 / 3" }} color="grayscale.2">
                                                    Double left click
                                                </Text>
                                                <Text css={{ gridArea: "2 / 3 / 3 / 4" }}>Add point and finish</Text>
                                                <Keyboard css={{ gridArea: "3 / 1 / 4 / 2" }} />
                                                <Text css={{ gridArea: "3 / 2 / 4 / 3" }} color="grayscale.2">
                                                    Shift
                                                </Text>
                                                <Text css={{ gridArea: "3 / 3 / 4 / 4" }}>Snap to grid</Text>
                                                <Keyboard css={{ gridArea: "4 / 1 / 5 / 2" }} />
                                                <Text css={{ gridArea: "4 / 2 / 5 / 3" }} color="grayscale.2">
                                                    Escape
                                                </Text>
                                                <Text css={{ gridArea: "4 / 3 / 5 / 4" }}>Finish line</Text>
                                            </Grid>
                                        </Help>
                                    )}

                                    {mode === "build" &&
                                        buildMode === "zones" &&
                                        tool === "zonefill" &&
                                        role === "GM" && (
                                            <Help id="tool_zonefill" key="tool_zonefill">
                                                <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                    How to fill a zone
                                                </Truncate>
                                                <Grid
                                                    gridGap={2}
                                                    gridTemplateRows="repeat(1, auto)"
                                                    gridTemplateColumns="repeat(3, auto)"
                                                    color="grayscale.1">
                                                    <Mouse css={{ gridArea: "1 / 1 / 2 / 2" }} />
                                                    <Text css={{ gridArea: "1 / 2 / 1 / 3" }} color="grayscale.2">
                                                        Left click
                                                    </Text>
                                                    <Text css={{ gridArea: "1 / 3 / 1 / 4" }}>Fill zone</Text>
                                                    <Text
                                                        color="grayscale.2"
                                                        fontSize={0}
                                                        css={{ gridArea: "2 / 1 / 2 / 4" }}>
                                                        Fill an area created by intersecting walls, doors and windows to
                                                        create a zone.
                                                    </Text>
                                                </Grid>
                                            </Help>
                                        )}

                                    {mode === "build" &&
                                        buildMode === "tokens" &&
                                        (tool === "door" || tool === "window") &&
                                        role === "GM" && (
                                            <Help id="tool_door" key="tool_door">
                                                <Truncate fontWeight="bold" fontSize={2} mb={2} color="grayscale.1">
                                                    How to draw a door or window
                                                </Truncate>
                                                <Grid
                                                    gridGap={2}
                                                    gridTemplateRows="repeat(4, auto)"
                                                    gridTemplateColumns="repeat(3, auto)"
                                                    color="grayscale.1">
                                                    <Mouse css={{ gridArea: "1 / 1 / 2 / 2" }} />
                                                    <Text css={{ gridArea: "1 / 2 / 1 / 3" }} color="grayscale.2">
                                                        Left click
                                                    </Text>
                                                    <Text css={{ gridArea: "1 / 3 / 1 / 4" }}>Add point</Text>
                                                    <Mouse css={{ gridArea: "2 / 1 / 3 / 2" }} />
                                                    <Text css={{ gridArea: "2 / 2 / 3 / 3" }} color="grayscale.2">
                                                        Left click and drag
                                                    </Text>
                                                    <Text css={{ gridArea: "2 / 3 / 3 / 4" }}>Create door/window</Text>
                                                    <Keyboard css={{ gridArea: "3 / 1 / 4 / 2" }} />
                                                    <Text css={{ gridArea: "3 / 2 / 4 / 3" }} color="grayscale.2">
                                                        Shift
                                                    </Text>
                                                    <Text css={{ gridArea: "3 / 3 / 4 / 4" }}>Snap to grid</Text>
                                                </Grid>
                                            </Help>
                                        )}

                                    <RightInnerHost />

                                    {sessionConnection.system.renderCampaignContent?.()}

                                    <Box
                                        id="dialog"
                                        css={{
                                            gridArea: "1 / 1 / 4 / 6",
                                            position: "relative",
                                            pointerEvents: "none",
                                        }}
                                    />
                                </ViewportContext.Provider>
                            </LocalGridContext.Provider>
                        </AnnotationCacheContext.Provider>
                    </ZoneOverrideContext.Provider>
                </AnnotationOverrideContext.Provider>
            </TokenOverrideContext.Provider>
        </DiceBagContext.Provider>
    );
};

CampaignHost.displayName = "CampaignHost";

export default CampaignHost;

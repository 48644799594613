import { nanoid } from "nanoid";
import type { SMAAPreset } from "postprocessing";

export const title = "VTT";

export type Overwrite<T1, T2> = {
    [P in Exclude<keyof T1, keyof T2>]: T1[P];
} & T2;

export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
    [Property in Key]-?: Type[Property];
};

export class Deferred<T> {
    promise: Promise<T>;
    private _resolve: ((value: T) => void) | undefined;
    private _reject: ((reason: any) => void) | undefined;
    private _isResolved: boolean | undefined;
    private _resolveValue: T | undefined;
    private _isRejected: boolean | undefined;
    private _rejectReason: any | undefined;

    constructor() {
        this._resolve = undefined;
        this._reject = undefined;
        this.promise = new Promise<T>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;

            if (this._isResolved) {
                resolve(this._resolveValue!);
            } else if (this._isRejected) {
                reject(this._rejectReason);
            }
        });
    }

    resolve(value: T) {
        if (this._resolve) {
            this._resolve(value);
        } else {
            this._isResolved = true;
            this._resolveValue = value;
        }
    }

    reject(reason: any) {
        if (this._reject) {
            this._reject(reason);
        } else {
            this._isRejected = true;
            this._rejectReason = reason;
        }
    }
}

export class Event<T = void> {
    private _handlers: ((args: T) => void)[] | undefined;

    on(handler: (args: T) => void) {
        if (!this._handlers) {
            this._handlers = [handler];
        } else {
            this._handlers.push(handler);
        }
    }

    off(handler: (args: T) => void): boolean {
        if (!this._handlers) {
            return false;
        }

        const index = this._handlers.indexOf(handler);
        if (index >= 0) {
            if (this._handlers.length === 1) {
                delete this._handlers;
            } else {
                this._handlers.splice(index, 1);
            }

            return true;
        }

        return false;
    }

    trigger(args: T) {
        var handlers = this._handlers ? this._handlers.slice() : undefined;
        if (handlers) {
            for (let i = 0; i < handlers.length; i++) {
                handlers[i](args);
            }
        }
    }
}

// export type AnyJson =  boolean | number | string | null | JsonArray | JsonMap;
// export interface JsonMap {  [key: string]: AnyJson; }
// export interface JsonArray extends Array<AnyJson> {}

// export type LocalSettingValue = Exclude<AnyJson, null>;

export class LocalSetting<T> {
    private _name: string;
    private _cachedValue: T | undefined;
    private _isCached: boolean;

    constructor(name: string) {
        this._name = name;
        this._isCached = false;
    }

    get name() {
        return this._name;
    }

    private get settingKey() {
        return `setting:${this._name}:value`;
    }

    private get settingTypeKey() {
        return `setting:${this._name}:type`;
    }

    getValue() {
        if (this._cachedValue) {
            return this._cachedValue;
        }

        const value = localStorage.getItem(this.settingKey);
        const type = localStorage.getItem(this.settingTypeKey);

        if (value === null || type === null) {
            this._isCached = true;
            return undefined;
        }

        let finalValue: T | undefined;
        if (type === "string") {
            finalValue = value as unknown as T;
        } else {
            finalValue = JSON.parse(value) as T;
        }

        this._cachedValue = finalValue;
        this._isCached = true;
        return finalValue;
    }

    setValue(value: T | undefined) {
        if (!this._isCached) {
            this.getValue();
        }

        if (value === undefined) {
            if (this._cachedValue) {
                this._cachedValue = value;
                localStorage.removeItem(this.settingKey);
                localStorage.removeItem(this.settingTypeKey);
                localSettingChanged.trigger({ setting: this, value: value });
            }
        } else {
            const settingType = typeof value;
            localStorage.setItem(this.settingTypeKey, settingType);

            if (settingType === "string") {
                if (this._cachedValue == null || this._cachedValue !== value) {
                    this._cachedValue = value;
                    localStorage.setItem(this.settingKey, value as unknown as string);
                    localSettingChanged.trigger({ setting: this, value: value });
                }
            } else {
                const jsonValue = JSON.stringify(value);
                if (this._cachedValue == null || JSON.stringify(this._cachedValue) !== jsonValue) {
                    this._cachedValue = value;
                    localStorage.setItem(this.settingKey, jsonValue);
                    localSettingChanged.trigger({ setting: this, value: value });
                }
            }
        }
    }
}

export interface LocalSettingChangedEvent {
    setting: LocalSetting<any>;
    value: any;
}

export const localSettingChanged = new Event<LocalSettingChangedEvent>();

export const audioInputDeviceSetting = new LocalSetting<string>("audioInputDevice");
export const videoInputDeviceSetting = new LocalSetting<string>("videoInputDevice");

export const musicMutedSetting = new LocalSetting<boolean>("musicMuted");
export const musicVolumeSetting = new LocalSetting<number>("musicVolume");
export const ambienceMutedSetting = new LocalSetting<boolean>("ambienceMuted");
export const ambienceVolumeSetting = new LocalSetting<number>("ambienceVolume");

export const diceColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#00baa9",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffe81c",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
];
export const diceColorSetting = new LocalSetting<string>("diceColor");

export const softShadowsSetting = new LocalSetting<boolean>("softShadows");
export const antialiasTypeSetting = new LocalSetting<"SMAA" | "MSAA" | "None">("MSAA");
export const smaaQualitySetting = new LocalSetting<SMAAPreset>("SMAAQuality");

/**
 * Checks whether two arrays are equal. By default a shallow === comparison is used.
 * @param a The first array.
 * @param b The second array.
 * @param equalityCheck The function to use to compare items in the arrays, or falsy to use a shallow === comparison.
 */
export function areArraysEqual<T>(a: T[] | undefined, b: T[] | undefined, equalityCheck?: (a: T, b: T) => boolean) {
    if (a == null && b == null) {
        return true;
    }

    if (a == null || b == null || a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; i++) {
        if (!(equalityCheck ? equalityCheck(a[i], b[i]) : a[i] === b[i])) {
            return false;
        }
    }

    return true;
}

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

// input: h in [0,360] and s,v in [0,1] - output: r,g,b in [0,1]
export function hsl2rgb(h: number, s: number, l: number): [number, number, number] {
    let a = s * Math.min(l, 1 - l);
    let f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return [f(0), f(8), f(4)];
}

export function camelCase(str: string) {
    return (str.slice(0, 1).toLowerCase() + str.slice(1))
        .replace(/([-_ ]){1,}/g, " ")
        .split(/[-_ ]/)
        .reduce((cur, acc) => {
            return cur + acc[0].toUpperCase() + acc.substring(1);
        });
}

export type DeepPartial<T> = T extends (infer E)[]
    ? DeepPartial<E>[]
    : { [P in keyof T]?: DeepPartial<T[P]> | undefined };

export type WithOverride<T> = T & { withoutOverride?: T; overrideDelta?: DeepPartial<T> };

export function keyedListToKeyArray<T extends {}>(kl: KeyedList<T>): string[];
export function keyedListToKeyArray(kl: undefined): undefined;
export function keyedListToKeyArray<T extends {}>(kl: DeepPartial<KeyedList<T>>): string[];
export function keyedListToKeyArray<T extends {}>(
    kl: KeyedList<T> | DeepPartial<KeyedList<T>> | undefined
): string[] | undefined;
export function keyedListToKeyArray<T extends {}>(
    kl: KeyedList<T> | DeepPartial<KeyedList<T>> | undefined
): string[] | undefined {
    if (!kl) {
        return kl;
    }

    var keys = Object.keys(kl);

    // Order by the order number if possible, else by the key - but favour the order if supplied.
    keys.sort((a, b) => {
        var ao = (kl[a] as any)?.order as number | undefined;
        var bo = (kl[b] as any)?.order as number | undefined;

        // One order is undefined and the other isn't, prefer the one with a defined order.
        if (ao != null && bo == null) {
            return 1;
        }

        if (bo != null && ao == null) {
            return -1;
        }

        // Both orders are defined, so use that.
        if (ao != null && bo != null) {
            return ao - bo;
        }

        // Both order numbers are undefined, so go off the keys.
        return a.localeCompare(b);
    });

    return keys;
}

export function mapKeyedList<T extends {}, U>(
    kl: KeyedList<T> | undefined,
    callback: (o: KeyedListItem<T>, i: number, key: string) => U
): U[] | undefined;
export function mapKeyedList<T extends {}, U>(
    kl: DeepPartial<KeyedList<T>> | undefined,
    callback: (o: DeepPartial<KeyedListItem<T>>, i: number, key: string) => U
): U[] | undefined;
export function mapKeyedList<T extends {}, U>(
    kl: KeyedList<T> | DeepPartial<KeyedList<T>> | undefined,
    callback: (o: KeyedListItem<T> | DeepPartial<KeyedListItem<T>>, i: number, key: string) => U
): U[] | undefined {
    const keys = keyedListToKeyArray(kl);
    if (!keys) {
        return undefined;
    }

    var items: U[] = [];
    for (let i = 0; i < keys.length; i++) {
        var item = kl![keys[i]];
        if (item) {
            items.push(callback(item as KeyedListItem<T> | DeepPartial<KeyedListItem<T>>, i, keys[i]));
        }
    }

    return items;
}

/**
 * Adds an item to the specified keyed list, modifying the order to appear at the end
 * of the list if necessary.
 * A copy of the list is returned, the original list is not mutated.
 * @param kl The list to add to.
 * @param item The item to add to the list. The item will be mutated to set the order if not already specified.
 */
export function addToKeyedList<T extends {}>(kl: KeyedList<T> | undefined, item: KeyedListItem<T>): KeyedList<T>;
export function addToKeyedList<T extends {}>(
    kl: DeepPartial<KeyedList<T>> | undefined,
    item: DeepPartial<KeyedListItem<T>>
): DeepPartial<KeyedList<T>>;
export function addToKeyedList<T extends {}>(
    kl: KeyedList<T> | DeepPartial<KeyedList<T>> | undefined,
    item: KeyedListItem<T> | DeepPartial<KeyedListItem<T>>
): KeyedList<T> | DeepPartial<KeyedList<T>> {
    if (item.order == null) {
        var maxOrder = -1;
        if (kl) {
            const keys = Object.keys(kl);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const order = kl[key]?.order;
                if (order != null) {
                    maxOrder = Math.max(maxOrder, order);
                }
            }
        }

        item.order = maxOrder + 1;
    }

    var delta = { [nanoid()]: item };
    return kl ? Object.assign({}, kl, delta) : (delta as KeyedList<T> | DeepPartial<KeyedList<T>>);
}

/**
 * Converts a KeyedList<T> to an array, ensuring the order is correct.
 * @param kl The keyed list to convert.
 * @returns The converted array.
 */
export function keyedListToArray<T extends {}>(kl: KeyedList<T>): KeyedListItem<T>[];
export function keyedListToArray<T extends {}>(kl: KeyedList<T> | undefined): KeyedListItem<T>[] | undefined;
export function keyedListToArray<T extends {}>(kl: DeepPartial<KeyedList<T>>): DeepPartial<KeyedListItem<T>>[];
export function keyedListToArray<T extends {}>(
    kl: DeepPartial<KeyedList<T>> | undefined
): DeepPartial<KeyedListItem<T>>[] | undefined;
export function keyedListToArray(kl: undefined): undefined;
export function keyedListToArray<T extends {}>(
    kl: KeyedList<T> | DeepPartial<KeyedList<T>> | undefined
): (KeyedListItem<T> | DeepPartial<KeyedListItem<T>>)[] | undefined;
export function keyedListToArray<T extends {}>(
    kl: KeyedList<T> | DeepPartial<KeyedList<T>> | undefined
): (KeyedListItem<T> | DeepPartial<KeyedListItem<T>>)[] | undefined {
    const keys = keyedListToKeyArray(kl);
    if (!keys) {
        return undefined;
    }

    return keys?.map(o => kl![o]!);
}

export type KeyedListItem<T> = T & { order?: number };

export interface KeyedList<T extends {}> {
    [id: string]: KeyedListItem<T>;
}

export function wrapForSuspense<T>(promise: Promise<T>) {
    let status = 0;
    let result: any;
    let suspender = promise.then(
        data => {
            status = 1;
            result = data;
        },
        error => {
            status = 2;
            result = error;
        }
    );

    return {
        read() {
            if (status === 0) {
                throw suspender;
            } else if (status === 2) {
                throw result;
            } else if (status === 1) {
                return result as T;
            }
        },
    };
}

export function isPromise(val: any): val is Promise<any> {
    return val && typeof val["then"] === "function";
}

export var cloudStorageProps: {
    baseUri?: string;
} = {};

import React, { FunctionComponent, useEffect, useState, Suspense, useRef } from "react";
import { Point, Size } from "../../position";
import { useFileWithProgress } from "../utils";
import { Texture, TextureLoader } from "three";
import { useLoader } from "@react-three/fiber";

interface BackgroundProps {
    backgroundImageUrl?: string;
    backgroundImagePos?: Point;
    onSizeChanged: (url: string, texture: Texture | null, size: Size | null, error?: Error) => void;
    layer: number;
}

// const VideoBackground: FunctionComponent<BackgroundProps> = props => {
//     let [video, status] = useVideo(props.backgroundImageUrl);

//     if (video) {
//         props.onSizeChanged({ width: video.width, height: video.height });
//     }

//     let ref = useRef(null as (Konva.Image | null));
//     useEffect(() => {
//         if (video) {
//             video.play();
//             const drawFrame = () => {
//                 if (ref.current) {
//                     ref.current.getLayer().draw();
//                 }

//                 requestAnimationFrame(drawFrame);
//             };

//             requestAnimationFrame(drawFrame);
//         }
//     }, [video]);

//     return <Image
//         ref={ref}
//         image={video as any}
//         width={video ? video.videoWidth : 0}
//         height={video ? video.videoHeight : 0}
//         x={props.backgroundImagePos != null ? props.backgroundImagePos.x : 0}
//         y={props.backgroundImagePos != null ? props.backgroundImagePos.y : 0} />
// }

// const AnimatedImage = animated(Image);

// const ImageBackground: FunctionComponent<BackgroundProps> = props => {
//     // TODO: Show this download progress in some way.
//     let [image, progress, size, error] = useImageWithProgress(props.backgroundImageUrl);

//     const imageProps = useSpring({ opacity: image ? 1 : 0 });

//     useEffect(() => {
//         if (image) {
//             props.onSizeChanged({ width: image.width, height: image.height });
//         }
//     }, [image]);

//     return <>
//         {image && <AnimatedImage
//             image={image}
//             {...imageProps}
//             x={props.backgroundImagePos != null ? props.backgroundImagePos.x : 0}
//             y={props.backgroundImagePos != null ? props.backgroundImagePos.y : 0} />}
//         {/* {!image && progress != null && size != null && <>
//             <Rect
//                 scaleX={1 / props.grid.scale}
//                 scaleY={1 / props.grid.scale}
//                 x={-props.x / props.grid.scale}
//                 y={-props.y / props.grid.scale}
//                 width={(progress / size) * props.width}
//                 height={props.height}
//                 fill={color}/>
//         </>} */}
//     </>
// }

export const BackgroundImage: FunctionComponent<{
    url: string;
    originalUrl: string;
    x: number;
    y: number;
    layer: number;
    onSizeChanged: (url: string, texture: Texture | null, size: Size | null) => void;
}> = React.memo(({ url, originalUrl, x, y, layer, onSizeChanged }) => {
    // const texture = useTexture(url, t => {
    //     (t as Texture).generateMipmaps = false;
    // });
    const texture = useLoader(TextureLoader, url);

    useEffect(() => {
        onSizeChanged(originalUrl, texture, { width: texture.image.width, height: texture.image.height });
        return () => {
            onSizeChanged(originalUrl, null, null);
        };
    }, [texture, onSizeChanged, originalUrl]);

    return (
        <mesh
            layers={layer}
            position={[texture.image.width / 2 + x, -(texture.image.height / 2 + y), 0]}
            rotation={[0, 0, 0]}>
            <planeGeometry attach="geometry" args={[texture.image.width, texture.image.height]} />
            <meshBasicMaterial attach="material" map={texture} transparent depthWrite={false} />
        </mesh>
    );
});

export const BackgroundLayer: FunctionComponent<BackgroundProps> = ({
    onSizeChanged,
    backgroundImagePos,
    backgroundImageUrl,
    layer,
}) => {
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    let [image, , , error] = useFileWithProgress(backgroundImageUrl, "Failed to download background image.");
    const onSizeChangedRef =
        useRef<(url: string, texture: Texture | null, size: Size | null, error?: Error) => void>(onSizeChanged);
    onSizeChangedRef.current = onSizeChanged;
    useEffect(() => {
        let generatedUrl: string | undefined;
        if (image) {
            generatedUrl = URL.createObjectURL(image);
            setImageUrl(generatedUrl);
        } else if (backgroundImageUrl != null && error != null) {
            onSizeChangedRef.current(backgroundImageUrl, null, null, error);
        }

        return () => {
            if (generatedUrl) {
                URL.revokeObjectURL(generatedUrl);
            }
        };
    }, [image, error, backgroundImageUrl]);

    if (!backgroundImageUrl || !imageUrl) {
        return <></>;
    }

    return (
        <Suspense fallback={<></>}>
            <BackgroundImage
                url={imageUrl}
                originalUrl={backgroundImageUrl}
                x={backgroundImagePos ? backgroundImagePos.x : 0}
                y={backgroundImagePos ? backgroundImagePos.y : 0}
                layer={layer}
                onSizeChanged={onSizeChanged}
            />
        </Suspense>
    );
    // const isVideo = props.backgroundImageUrl.endsWith(".mp4");
    // return <Layer>
    //     {!isVideo && <ImageBackground {...props} />}
    //     {isVideo && <VideoBackground {...props} />}
    //     <Shape stroke="hsl(223, 16%, 82%)" opacity={0.5} strokeWidth={0.5} sceneFunc={(context: Context, shape: Konva.Shape) => {
    //         props.grid.draw(context, shape);
    //     }}/>
    // </Layer>;
};

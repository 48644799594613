import React, { useEffect, useRef } from "react";
import { Point } from "../../../position";
import { MeshProps } from "./common";
import { withCursor, withDragEvents } from "./dragevents";
import { Line as DreiLine } from "@react-three/drei";
import { degToRad } from "../../../grid";
import { MotionValue, animate, useMotionValue, usePresence } from "framer-motion";
import { Line2 } from "three-stdlib";
import { useFrame } from "@react-three/fiber";
import { Mesh } from "three";
import { motion } from "framer-motion-3d";

interface LineProps extends MeshProps {
    name?: string;
    points: Point[];
    width?: number;
    hitWidth?: number;
    closed?: boolean;
    dashed?: boolean;
    dashSize?: number;
    gapSize?: number;
    dashOffset?: number;
    visible?: boolean;
}

export const Line = React.forwardRef<Mesh, LineProps>(
    (
        {
            x,
            y,
            visible,
            offsetX,
            offsetY,
            scale,
            rotation,
            points,
            width,
            hitWidth,
            closed,
            dashed,
            color,
            opacity,
            zIndex,
            name,
            onPointerDown,
            onPointerMove,
            onPointerUp,
            onPointerOut,
            onPointerOver,
            onClick,
            animateEnterExit,
            initial,
            exit,
            transition,
            renderOrder,
            layers,
            ...props
        },
        ref
    ) => {
        const vertices: [number, number, number][] = [];
        for (let i = 0; i < points.length; i++) {
            // Two consecutive points with the same x and y causes an error, so make sure we strip those out
            // while we're translating to the expected point type.
            if (i < 1 || points[i - 1].x !== points[i].x || points[i - 1].y !== points[i].y) {
                vertices.push([points[i].x, -points[i].y, 0]);
            }
        }

        if (closed && (points[0].x !== points[points.length - 1].x || points[0].y !== points[points.length - 1].y)) {
            vertices.push([points[0].x, -points[0].y, 0]);
        }

        if (transition) {
            console.warn("The transition property is not supported for Line.");
        }

        // Animate opacity & color.
        const [isPresent, safeToRemove] = usePresence();
        const opacityMotionValue = useMotionValue(
            animateEnterExit && initial !== null ? initial?.opacity ?? 0 : opacity ?? 1
        );
        const colorMotionValue = useMotionValue(
            animateEnterExit && initial !== null ? initial?.color ?? color ?? "#000000" : color ?? "#000000"
        );
        const ref2 = useRef<Line2>(null);
        useEffect(() => {
            if (animateEnterExit && exit !== null) {
                animate(opacityMotionValue, isPresent ? opacity ?? 1 : exit?.opacity ?? 0, {
                    onComplete: safeToRemove ?? undefined,
                });
                animate(colorMotionValue, exit?.color ?? color ?? "#000000");
            } else {
                if (!isPresent) {
                    safeToRemove?.();
                }

                animate(opacityMotionValue, opacity ?? 1);
                animate(colorMotionValue, color ?? "#000000");
            }
        }, [isPresent, opacity, color, animateEnterExit, colorMotionValue, opacityMotionValue, exit, safeToRemove]);
        const colorRef = useRef<string>();
        useFrame(() => {
            if (ref2.current) {
                ref2.current.material.opacity = opacityMotionValue.get();
                ref2.current.renderOrder = renderOrder ?? zIndex;
                ref2.current.material.depthWrite = false;

                const color = colorMotionValue.get();
                if (color !== colorRef.current) {
                    ref2.current.material.color.set(color);
                    colorRef.current = color;
                }
            }
        });

        let r: number | MotionValue | undefined;
        if (rotation != null) {
            if (typeof rotation === "number") {
                r = rotation == null ? 0 : -degToRad(rotation);
            } else {
                r = rotation;
            }
        }

        const scaleValue = scale == null ? 1 : scale;
        return (
            <motion.mesh ref={ref as any} visible={visible} position={[x, -y, 0]} rotation={[0, 0, r ?? 0]}>
                <DreiLine
                    name={name}
                    ref={ref2}
                    position={[offsetX != null ? offsetX : 0, offsetY != null ? -offsetY : 0, 0]}
                    points={vertices}
                    lineWidth={width != null ? width : 1}
                    dashed={dashed}
                    scale={[scaleValue, scaleValue, 1]}
                    layers={layers}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                    onPointerMove={onPointerMove}
                    onPointerOver={onPointerOver}
                    onPointerOut={onPointerOut}
                    onClick={onClick}
                    transparent
                    {...props}
                />
            </motion.mesh>
        );
    }
);

export const LineWithCursor = withCursor(Line);
export const DraggableLine = withDragEvents(Line);

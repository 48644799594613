import React, { FunctionComponent } from "react";
import { Avatar } from "../Avatar";
import { SpaceProps } from "styled-system";

import { Profile, CampaignPlayer } from "../../store";
import { getThemeColorPalette } from "../../design/utils";

interface IProfileAvatarProps {
    profile?: Profile;
    campaignProfile?: CampaignPlayer;
}

type ProfileAvatarProps = IProfileAvatarProps & SpaceProps;

const ProfileAvatar: FunctionComponent<ProfileAvatarProps> = ({ profile, campaignProfile, ...props }) => {
    const playerColours = campaignProfile ? getThemeColorPalette(campaignProfile.colour) : undefined;
    return (
        <Avatar
            name={profile ? profile.name : ""}
            bg={playerColours ? playerColours[5] : undefined}
            color={playerColours ? playerColours[0] : undefined}
            {...props}
        />
    );
};

export default ProfileAvatar;

import { AnyAction, Dispatch } from "redux";
import {
    ActionScope,
    createTokensOrTokenTemplatesAction,
    TokensAction,
    TokenTemplatesAction,
} from "../../../actions/common";
import { Annotation } from "../../../annotations";
import { Overwrite } from "../../../common";
import { humanize } from "../../../components/utils";
import { ILocalGrid } from "../../../grid";
import {
    Token,
    TokenTemplate,
    Campaign,
    Location,
    IMenuItem,
    AnnotationPlacementTemplate,
    LocationSummary,
    isToken,
} from "../../../store";
import { CoreAbility, DnD5EToken, DnD5ETokenTemplate, NamedRuleRef } from "../common";
import {
    canPayCombatTimeCost,
    getFreeSpellSlot,
    isMonster,
    NamedSpellSlots,
    ResolvedCharacter,
    ResolvedMonster,
} from "../creature";
import { Spell } from "../spells";
import { getAnnotationTemplate } from "../abilities";
import { Event } from "../../../common";
import { nanoid } from "nanoid";

export function markTokenSpellSlot(
    campaign: Campaign | string,
    location: LocationSummary | string,
    tokens: (Token | TokenTemplate)[],
    level: number,
    name: string | undefined
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_UseSpellSlot",
        name == null ? level : { name: name, level: level },
        campaign,
        location,
        tokens
    );
}

export function castSpell(
    campaign: Campaign | string,
    location: LocationSummary | string,
    tokens: (Token | TokenTemplate)[],
    spell: Spell,
    level: number | { name: string; level: number },
    instanceId: string,
    annotation?: Annotation
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_CastSpell",
        { spell: spell, level: level, annotation: annotation?.id, instanceId: instanceId },
        campaign,
        location,
        tokens,
        ActionScope.Annotations | ActionScope.Tokens
    );
}

export function clearTokenSpellSlot(
    campaign: Campaign | string,
    location: LocationSummary | string,
    tokens: (Token | TokenTemplate)[],
    level: number,
    name: string | undefined
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_ClearSpellSlot",
        name == null ? level : { name: name, level: level },
        campaign,
        location,
        tokens
    );
}

export function markInnateSpellUse(
    campaign: Campaign | string,
    location: LocationSummary | string,
    tokens: (Token | TokenTemplate)[],
    source: Overwrite<NamedRuleRef, { source?: string }> | string,
    spell: Spell
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_UseInnateSpell",
        { source: source, spell: spell },
        campaign,
        location,
        tokens
    );
}

export function clearInnateSpellUse(
    campaign: Campaign | string,
    location: LocationSummary | string,
    tokens: (Token | TokenTemplate)[],
    source: Overwrite<NamedRuleRef, { source?: string }> | string,
    spell: Spell
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "DnD5E_ClearInnateSpellUse",
        { source: source, spell: spell },
        campaign,
        location,
        tokens
    );
}

function getCastAtLevelMenuItems(
    character: ResolvedCharacter,
    spell: Spell,
    ss: number[] | NamedSpellSlots,
    slotLevel: number,
    slotName: string | undefined,
    dc: number,
    attackModifier: number,
    castingAbility: CoreAbility | undefined,
    dispatch: Dispatch<AnyAction>,
    evt: Event<AnnotationPlacementTemplate<Annotation> | undefined>,
    campaign: Campaign,
    location: Location,
    levelKey: string,
    grid: ILocalGrid,
    token: DnD5EToken | DnD5ETokenTemplate,
    caster: ResolvedCharacter | ResolvedMonster,
    canCast: boolean
) {
    const spellSlots = Array.isArray(ss) ? ss : ss.spellSlots;
    const usedSpellSlots = Array.isArray(ss) ? character.usedSpellSlots?.default : character.usedSpellSlots?.[ss.name];

    const castAtLevelItems: IMenuItem[] = [];
    for (let i = slotLevel; i <= spellSlots.length; i++) {
        const slots = spellSlots[i - 1] ?? 0;
        const used = usedSpellSlots?.[i - 1] ?? 0;
        if (used < slots) {
            castAtLevelItems.push({
                label: `Cast at ${humanize(i)} level${i === spell.level ? " (default)" : ""}${
                    slotName != null ? ` (${slotName})` : ""
                }`,
                disabled: !canCast,
                onClick: () => {
                    dispatchCastSpell(
                        slotName != null ? { name: slotName, level: i } : i,
                        dispatch,
                        evt,
                        campaign,
                        location,
                        levelKey,
                        grid,
                        token,
                        caster,
                        spell,
                        dc,
                        attackModifier,
                        castingAbility
                    );
                },
            });
        }
    }

    return castAtLevelItems;
}

export function getCastMenuItems(
    character: ResolvedCharacter | ResolvedMonster,
    spell: Spell,
    dc: number,
    attackModifier: number,
    castingAbility: CoreAbility | undefined,
    dispatch: Dispatch<AnyAction>,
    evt: Event<AnnotationPlacementTemplate<Annotation> | undefined>,
    campaign: Campaign,
    location: Location,
    levelKey: string,
    grid: ILocalGrid,
    token: DnD5EToken | DnD5ETokenTemplate
) {
    const canCast = character.canCastSpells && isToken(token) && canPayCombatTimeCost(character, spell.time);

    if (isMonster(character)) {
        const slot = getFreeSpellSlot(character, spell.level);
        if (slot != null) {
            // Add options for casting the spell at any available level from this one up.
            const castAtLevelItems: IMenuItem[] = [];
            for (let i = slot; i <= character.spellSlots.length; i++) {
                const slots = character.spellSlots[i - 1] ?? 0;
                const used = character.usedSpellSlots[i - 1] ?? 0;
                if (used < slots) {
                    castAtLevelItems.push({
                        label: `Cast at ${humanize(i)} level${i === spell.level ? " (default)" : ""}`,
                        disabled: !canCast,
                        onClick: () => {
                            dispatchCastSpell(
                                i,
                                dispatch,
                                evt,
                                campaign,
                                location,
                                levelKey,
                                grid,
                                token as DnD5EToken,
                                character,
                                spell,
                                dc,
                                attackModifier,
                                castingAbility
                            );
                        },
                    });
                }
            }

            return [
                {
                    id: "cast",
                    label: slot === spell.level ? "Cast" : `Cast (${humanize(slot)})`,
                    disabled: !canCast,
                    onClick: () => {
                        dispatchCastSpell(
                            slot,
                            dispatch,
                            evt,
                            campaign,
                            location,
                            levelKey,
                            grid,
                            token as DnD5EToken,
                            character,
                            spell,
                            dc,
                            attackModifier,
                            castingAbility
                        );
                    },
                    subitems: castAtLevelItems,
                },
            ];
        }
    } else {
        const freeSlots = character.spellSlots
            .flatMap(ss => {
                const slot = getFreeSpellSlot(character, spell.level, Array.isArray(ss) ? null : ss.name);
                return slot != null ? { slot: slot, source: ss } : undefined;
            })
            .filter(o => o != null) as {
            slot: number | { name: string; level: number };
            source: number[] | NamedSpellSlots;
        }[];

        if (freeSlots.length === 0) {
            return undefined;
        }

        freeSlots.sort((a, b) => {
            const na = typeof a.slot === "number" ? a.slot : a.slot.level;
            const nb = typeof b.slot === "number" ? b.slot : b.slot.level;

            if (na !== nb) {
                return na - nb;
            }

            // Prefer slots with a short rest reset.
            const ra = Array.isArray(a.source) || a.source.reset !== "shortrest" ? 1 : 0;
            const rb = Array.isArray(b.source) || b.source.reset !== "shortrest" ? 1 : 0;
            return ra - rb;
        });

        const slotRoot = freeSlots[0];
        const slotLevelRoot = typeof slotRoot.slot === "number" ? slotRoot.slot : slotRoot.slot.level;
        const slotNameRoot = typeof slotRoot.slot === "number" ? undefined : slotRoot.slot.name;
        const castAtLevelItems = getCastAtLevelMenuItems(
            character,
            spell,
            slotRoot.source,
            slotLevelRoot,
            slotNameRoot,
            dc,
            attackModifier,
            castingAbility,
            dispatch,
            evt,
            campaign,
            location,
            levelKey,
            grid,
            token,
            character,
            canCast
        );

        for (let i = 1; i < freeSlots.length; i++) {
            const freeSlot = freeSlots[i];
            const slotLevel = typeof freeSlot.slot === "number" ? freeSlot.slot : freeSlot.slot.level;
            const slotName = typeof freeSlot.slot === "number" ? undefined : freeSlot.slot.name;
            castAtLevelItems.push(
                ...getCastAtLevelMenuItems(
                    character,
                    spell,
                    freeSlot.source,
                    slotLevel,
                    slotName,
                    dc,
                    attackModifier,
                    castingAbility,
                    dispatch,
                    evt,
                    campaign,
                    location,
                    levelKey,
                    grid,
                    token,
                    character,
                    canCast
                )
            );
        }

        const rootMenu: IMenuItem = {
            id: "cast",
            label:
                slotLevelRoot === spell.level
                    ? `Cast${slotNameRoot != null ? ` (${slotNameRoot})` : ""}`
                    : `Cast (${slotNameRoot != null ? `${slotNameRoot} ` : ""}${humanize(slotLevelRoot)})`,
            disabled: !canCast,
            onClick: () => {
                dispatchCastSpell(
                    slotRoot.slot,
                    dispatch,
                    evt,
                    campaign,
                    location,
                    levelKey,
                    grid,
                    token,
                    character,
                    spell,
                    dc,
                    attackModifier,
                    castingAbility
                );
            },
            subitems: castAtLevelItems,
        };
        return [rootMenu];
    }
}

function dispatchCastSpell(
    slot: number | { name: string; level: number },
    dispatch: Dispatch<AnyAction>,
    evt: Event<AnnotationPlacementTemplate<Annotation> | undefined>,
    campaign: Campaign,
    location: Location,
    levelKey: string,
    grid: ILocalGrid,
    token: DnD5EToken | DnD5ETokenTemplate,
    caster: ResolvedCharacter | ResolvedMonster,
    spell: Spell,
    dc: number,
    attackModifier: number,
    castingAbility: CoreAbility | undefined
) {
    const slotLevel = typeof slot === "number" ? slot : slot.level;
    const template = isToken(token)
        ? getAnnotationTemplate(
              location,
              levelKey,
              caster,
              token,
              grid,
              spell,
              slotLevel,
              dc,
              attackModifier,
              castingAbility
          )
        : undefined;
    if (template) {
        template.onPlaced = a => {
            // Concentration does not depend on the annotation existing for targetted annotations.
            dispatch(castSpell(campaign, location, [token], spell, slot, a.id, a.type !== "target" ? a : undefined));
        };

        evt.trigger(template);
    } else {
        evt.trigger(undefined);
        dispatch(castSpell(campaign, location, [token], spell, slot, nanoid()));
    }
}

import React, { FunctionComponent } from "react";

import { Box } from "../primitives";
import { Button } from "../Button";
import { Message } from "../Message";
import { INotificationProps, useNotifications } from "../Notifications";

import { PlusIcon } from "@radix-ui/react-icons";

import { useDispatch, useCampaign, useUser } from "../contexts";
import { LocationSummaryHost, LocationSummaryNode } from "./LocationSummary";
import { addLocation } from "../../actions/campaign";
import { DEFAULT_TILE_SIZE } from "../../grid";
import EmptyState from "../EmptyState";
import styled from "@emotion/styled";
import { AnimatedListItem, AnimatedList, DropOverlay } from "../motion";
import { AnimatePresence } from "framer-motion";
import ImportIcon from "../icons/Import";
import { isUniversalVttFile } from "../../universalvtt";
import { dropFiles } from "./ArtLibrary";
import { Campaign, ErrorHandler, IGameSystem, UserInfo, Location } from "../../store";
import { Dispatch } from "redux";
import { useDropEvents, useErrorHandler } from "../utils";
import { LobotomizedBox, useVttApp } from "../common";
import { ScrollableTest } from "../ScrollableTest";
import { nanoid } from "nanoid";
import { Pages } from "./Sidebar";

// TODO: Remove when Scrollable is fixed so that we can put the flex prop on that (or until someone tells me what I'm doing wrong).
const ScrollableHack = styled(Box)`
    > :first-of-type {
        flex: 1 1 auto;
    }
`;

function createNewLocation(system: IGameSystem, campaign: Campaign, user: UserInfo, dispatch: Dispatch) {
    let newLocation: Location = {
        id: nanoid(),
        label: "New location",
        tileSize: { width: DEFAULT_TILE_SIZE, height: DEFAULT_TILE_SIZE },
        levels: {
            "0": {},
        },
        defaultLevel: "0",
        tokens: {},
        annotations: {},
        zones: {},
    };
    if (system.onNewLocation) {
        newLocation = system.onNewLocation(newLocation);
    }

    dispatch(addLocation(campaign.id, newLocation, user));
}

async function dropUniversalVttFiles(
    files: FileList,
    addNotification: (data: INotificationProps) => Promise<void>,
    errorHandler: ErrorHandler,
    user: UserInfo,
    campaign: Campaign,
    dispatch: Dispatch
) {
    const universalVttFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
        if (isUniversalVttFile(files[i])) {
            universalVttFiles.push(files[i]);
        }
    }

    if (universalVttFiles.length > 0) {
        await dropFiles(universalVttFiles, addNotification, errorHandler, undefined, user, campaign, dispatch);
    } else if (files.length > 0) {
        errorHandler.handleError(undefined, "No universal VTT files found.");
    }
}

export const LocationListTools: FunctionComponent<{}> = () => {
    const isVisible = useVttApp(state => state.searchTerm === "");
    const dispatch = useDispatch();
    const { system, campaign } = useCampaign();
    const user = useUser();

    const errorHandler = useErrorHandler();
    const [addNotification] = useNotifications();

    const newLocationAction = () => createNewLocation(system, campaign, user, dispatch);

    if (!isVisible) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <LobotomizedBox key="tools" flexDirection="row" fullWidth justifyContent="flex-end" px={3}>
            <Button
                shape="square"
                tooltip="Import a universal VTT file"
                alignSelf="flex-end"
                variant="tertiary"
                onClick={() => {
                    var fileInput = document.createElement("input");
                    fileInput.setAttribute("type", "file");
                    fileInput.addEventListener("change", async () => {
                        if (fileInput.files) {
                            await dropUniversalVttFiles(
                                fileInput.files,
                                addNotification,
                                errorHandler,
                                user,
                                campaign,
                                dispatch
                            );
                        }
                    });
                    fileInput.click();
                }}>
                <ImportIcon fill="currentcolor" />
            </Button>
            <Button
                shape="square"
                tooltip="Create new location"
                alignSelf="flex-end"
                variant="tertiary"
                onClick={newLocationAction}>
                <PlusIcon fill="currentcolor" />
            </Button>
        </LobotomizedBox>
    );
};

export const LocationList: FunctionComponent<{}> = () => {
    const user = useUser();
    const dispatch = useDispatch();
    const { system, campaign } = useCampaign();
    const [addNotification] = useNotifications();
    const errorHandler = useErrorHandler();

    // TODO: Can't seem to filter out file drops, DataTransferItem returns null from getAsFile and has no type for .uvtt files.
    const { isDragOver, ...dropEvents } = useDropEvents("", async (data, e) => {
        if (e.dataTransfer.files.length) {
            await dropUniversalVttFiles(e.dataTransfer.files, addNotification, errorHandler, user, campaign, dispatch);
        }
    });

    const { searchTerm, searchResults } = useVttApp();
    const search = searchResults?.find(o => o.categoryId === Pages.Locations)?.results;
    const locations = search ? search.map(o => o.originalItem) : Object.values(campaign.locations);
    const locationItems = locations.map((o, i) => {
        return (
            <AnimatedListItem key={o.id} index={i} fullWidth>
                <LocationSummaryHost key={o.id}>
                    <LocationSummaryNode location={o} />
                </LocationSummaryHost>
            </AnimatedListItem>
        );
    });

    return search || locationItems.length > 0 ? (
        <Box flexDirection="column" fullWidth flex="1 1 auto" {...dropEvents} position="relative">
            <AnimatePresence initial={false}>
                {searchTerm && (
                    <AnimatedListItem key="filtermsg" fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only locations matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>
            <ScrollableHack flexDirection="column" fullWidth flex="1 1 auto">
                <ScrollableTest minimal pb={1} px={3}>
                    <AnimatedList>{locationItems}</AnimatedList>
                </ScrollableTest>
            </ScrollableHack>
            <DropOverlay isDragOver={!!isDragOver} borderBottomLeftRadius={4} borderBottomRightRadius={4} />
        </Box>
    ) : (
        <Box flex={1} fullWidth {...dropEvents} position="relative">
            <EmptyState
                onClick={() => createNewLocation(system, campaign, user, dispatch)}
                label="Create Location"
                fullWidth
            />
            <DropOverlay isDragOver={!!isDragOver} borderBottomLeftRadius={4} borderBottomRightRadius={4} />
        </Box>
    );
};

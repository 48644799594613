/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import {
    CoreAbility,
    NamedRuleRef,
    getRuleKey,
    NamedRuleStore,
    fromRuleKey,
    damageTypes,
    DamageType,
} from "../../common";
import {
    Choice,
    FeatureChoice,
    Feature,
    Skill,
    defaultExoticLanguages,
    defaultStandardLanguages,
    CreatureType,
    TerrainType,
    creatureTypes,
    terrainTypes,
    skillToString,
    ResolvedCharacterClass,
    CharacterRuleSet,
    AdditionalSpellBase,
    SpellFilter,
    filterSpells,
    ResolvedCharacter,
    skills,
    OptionalFeature,
    meetsPrerequisites,
} from "../../creature";
import { ExtractProps, LobotomizedBox } from "../../../../components/common";
import { ChoiceEditor } from "./ChoiceEditor";
import { FeatureExpander } from "../FeatureExpander";
import { useRules } from "../hooks";
import { Box } from "../../../../components/primitives";
import { Markdown } from "../../../../components/markdown";
import { filterItems, ItemFilter } from "../../items";
import { KeyedList, keyedListToArray, mapKeyedList } from "../../../../common";

function isChoiceComplete<T, U>(choice: Choice<T, any> | undefined, selection: U[] | undefined) {
    if (!choice) {
        return true;
    }

    if (
        choice &&
        choice.count > 0 &&
        (!selection || // No ability selected at all
            selection.length < choice.count || // Fewer than the required selected
            selection.slice(0, choice.count).some(o => o == null)) // At least one selected as undefined
    ) {
        return false;
    }

    return true;
}

function hasUnmadeFeatureChoice(
    choice: Choice<NamedRuleRef | Feature, KeyedList<NamedRuleRef | Feature> | string> | undefined,
    selections: (({ name: string; source?: string } & FeatureChoice) | null)[] | undefined,
    features: NamedRuleStore<NamedRuleRef & Feature>,
    rules: CharacterRuleSet
) {
    if (!choice) {
        return false;
    }

    if (choice.count <= 0) {
        return false;
    }

    if (!selections) {
        return true;
    }

    for (let i = 0; i < selections.length; i++) {
        // Get the feature in question.
        const featureSelections = selections[i]!;

        let from: (NamedRuleRef | Feature)[] | undefined;
        if (typeof choice.from === "string") {
            // This is a filter by tag on the optional features.
            from = rules.optionalFeatures.all.filter(o => o.tags && o.tags.includes(choice.from as string));
        } else {
            from = keyedListToArray<NamedRuleRef | Feature>(choice.from);
        }

        let f = from?.find(o => o.name === featureSelections.name) as Feature | undefined;
        if (!f && featureSelections.source) {
            f = features.get(featureSelections as NamedRuleRef);
        }

        if (f && hasUnmadeChoice(f, featureSelections, rules)) {
            return true;
        }
    }

    return false;
}

function hasUnmadeChoice(feature: Feature, selections: FeatureChoice | undefined, rules: CharacterRuleSet) {
    if (
        !isChoiceComplete(feature.abilityChoice, selections?.abilities) ||
        !isChoiceComplete(feature.languageChoice, selections?.languages) ||
        !isChoiceComplete(feature.skillProficiencyChoice, selections?.skillProficiencies) ||
        !isChoiceComplete(feature.creatureTypeChoice, selections?.creatureTypes) ||
        !isChoiceComplete(feature.terrainTypeChoice, selections?.terrainTypes)
    ) {
        return true;
    }

    if (
        !isChoiceComplete(feature.featureChoice, selections?.features) ||
        hasUnmadeFeatureChoice(feature?.featureChoice, selections?.features, rules.optionalFeatures, rules)
    ) {
        return true;
    }

    if (
        !isChoiceComplete(feature.featChoice, selections?.feats) ||
        hasUnmadeFeatureChoice(feature?.featChoice, selections?.feats, rules.feats, rules)
    ) {
        return true;
    }

    if (!isChoiceComplete(feature.toolChoice, selections?.tools)) {
        return true;
    }

    return false;
}

function getFeatureByName(
    name: string,
    source: string | undefined,
    classData: ResolvedCharacterClass | undefined,
    optionalFeatures: NamedRuleStore<NamedRuleRef & Feature>
) {
    // These might be references to subclass features, or even just normal features.
    let feature: OptionalFeature | undefined;
    if (classData) {
        feature = classData.features.find(f => (source ? f.name === name && f.source === source : f.name === name));
    }

    // They could also be a reference to an optional feature.
    if (!feature && source) {
        feature = optionalFeatures.get({ name: name, source: source });
    }

    return feature;
}

function flattenItems(items: (string | NamedRuleRef | ItemFilter)[] | undefined, rules: CharacterRuleSet) {
    return items?.flatMap(o => {
        if (typeof o === "string") {
            return o;
        } else if (o["source"]) {
            // If this is an item group rather than an item, then expand out to all the items in that group.
            const ref = o as NamedRuleRef;
            const group = rules.items.groups.get(ref);
            if (group) {
                return keyedListToArray(group.items);
            }

            return ref;
        }

        var itemFilter = o as ItemFilter;
        var filteredItems = filterItems(rules.items.items.allResolved, itemFilter, rules.items);
        return filteredItems.map(o => ({ name: o.name, source: o.source }));
    });
}

interface FeatureChoicesProps {
    feature: Feature;
    character: ResolvedCharacter;
    selections?: FeatureChoice;
    onSelectionsChanged?: (selections: FeatureChoice) => void;
    classData?: ResolvedCharacterClass;
}

export const FeatureChoices: FunctionComponent<FeatureChoicesProps> = ({
    feature,
    character,
    selections,
    onSelectionsChanged,
    classData,
}) => {
    const rules = useRules();

    return (
        <React.Fragment>
            {feature.content && <Markdown>{feature.content}</Markdown>}
            {onSelectionsChanged && (
                <React.Fragment>
                    {feature.abilityChoice && (
                        <ChoiceEditor
                            choice={Object.assign({}, feature.abilityChoice, {
                                from:
                                    feature.abilityChoice.from ??
                                    ([
                                        "strength",
                                        "dexterity",
                                        "constitution",
                                        "intelligence",
                                        "wisdom",
                                        "charisma",
                                    ] as CoreAbility[]),
                            })}
                            selections={selections?.abilities}
                            onChoiceMade={(value, i) => {
                                const abilities = selections?.abilities?.slice() || [];
                                abilities[i] = value == null ? null : (value as CoreAbility);
                                onSelectionsChanged(Object.assign({}, selections, { abilities: abilities }));
                            }}
                            hint="Choose an ability score"
                        />
                    )}
                    {feature.skillProficiencyChoice && (
                        <ChoiceEditor
                            choice={feature.skillProficiencyChoice}
                            exclude={(() => {
                                const v = feature.skillProficiencyChoice.value ?? 1;
                                const skillKeys = Object.keys(character.skillProficiencies);
                                const proficienciesToExclude = skillKeys.filter(
                                    o => (character.skillProficiencies[o] ?? 0) >= v
                                );

                                // If the proficiency has a current value requirement, also exclude all the proficiencies that aren't at that value.
                                if (feature.skillProficiencyChoice.current != null) {
                                    for (let skillKey of skills.filter(
                                        o => character.skillProficiencies[o] !== feature.skillProficiencyChoice!.current
                                    )) {
                                        if (proficienciesToExclude.indexOf(skillKey) < 0) {
                                            proficienciesToExclude.push(skillKey);
                                        }
                                    }
                                }

                                return proficienciesToExclude;
                            })()}
                            selections={selections?.skillProficiencies}
                            onChoiceMade={(value, i) => {
                                const skills = selections?.skillProficiencies?.slice() || [];
                                skills[i] = value == null ? null : (value as Skill);
                                onSelectionsChanged(Object.assign({}, selections, { skillProficiencies: skills }));
                            }}
                            optionToLabel={option => skillToString(option as Skill)}
                            hint="Choose a skill"
                            all={skills}
                        />
                    )}
                    {feature.languageChoice && (
                        <ChoiceEditor
                            choice={Object.assign({}, feature.languageChoice, {
                                from: (feature.languageChoice.type === "exotic"
                                    ? defaultExoticLanguages
                                    : defaultStandardLanguages
                                ).filter(o => !feature.languages || feature.languages.indexOf(o) < 0),
                            })}
                            selections={selections?.languages}
                            onChoiceMade={(value, i) => {
                                const languages = selections?.languages?.slice() || [];
                                while (languages.length < feature.languageChoice!.count) {
                                    languages.push(null);
                                }

                                languages[i] = value ?? null;
                                onSelectionsChanged(Object.assign({}, selections, { languages: languages }));
                            }}
                            hint="Choose a language"
                        />
                    )}
                    {feature.creatureTypeChoice && (
                        <ChoiceEditor
                            choice={
                                feature.creatureTypeChoice.from
                                    ? feature.creatureTypeChoice
                                    : Object.assign({}, feature.creatureTypeChoice, {
                                          from: creatureTypes,
                                      })
                            }
                            selections={selections?.creatureTypes}
                            onChoiceMade={(value, i) => {
                                const ct = selections?.creatureTypes?.slice() || [];
                                ct[i] = value == null ? null : (value as CreatureType);
                                onSelectionsChanged(Object.assign({}, selections, { creatureTypes: ct }));
                            }}
                            hint="Choose a creature type"
                        />
                    )}
                    {feature.terrainTypeChoice && (
                        <ChoiceEditor
                            choice={
                                feature.terrainTypeChoice.from
                                    ? feature.terrainTypeChoice
                                    : Object.assign({}, feature.terrainTypeChoice, {
                                          from: terrainTypes,
                                      })
                            }
                            selections={selections?.terrainTypes}
                            onChoiceMade={(value, i) => {
                                const tt = selections?.terrainTypes?.slice() || [];
                                tt[i] = value == null ? null : (value as TerrainType);
                                onSelectionsChanged(Object.assign({}, selections, { terrainTypes: tt }));
                            }}
                            hint="Choose a terrain type"
                        />
                    )}
                    {feature.damageImmunitiesChoice && (
                        <ChoiceEditor
                            choice={
                                feature.damageImmunitiesChoice.from
                                    ? feature.damageImmunitiesChoice
                                    : Object.assign({}, feature.damageImmunitiesChoice, { from: damageTypes })
                            }
                            selections={selections?.damageImmunities}
                            onChoiceMade={(value, i) => {
                                const it = selections?.damageImmunities?.slice() || [];
                                it[i] = value == null ? null : (value as DamageType);
                                onSelectionsChanged(Object.assign({}, selections, { damageImmunities: it }));
                            }}
                            hint="Choose a damage type"
                        />
                    )}
                    {feature.featureChoice && (
                        <React.Fragment>
                            <ChoiceEditor
                                choice={{
                                    count: feature.featureChoice.count,
                                    from:
                                        typeof feature!.featureChoice!.from! === "string"
                                            ? rules.optionalFeatures.all
                                                  .filter(
                                                      o =>
                                                          meetsPrerequisites(character, o.prerequisite) &&
                                                          o.tags &&
                                                          o.tags.includes(feature!.featureChoice!.from! as string)
                                                  )
                                                  .map(o => JSON.stringify({ name: o.name, source: o.source }))
                                            : (mapKeyedList<NamedRuleRef | Feature, string>(
                                                  feature!.featureChoice!.from!,
                                                  o => {
                                                      const feature = getFeatureByName(
                                                          o.name,
                                                          o.source,
                                                          classData,
                                                          rules.optionalFeatures
                                                      );

                                                      // If the feature couldn't be found, AND it has a source, then it's for something that's not included in the campaign.
                                                      // If there is no source, then it's a built-in feature and should be allowed.
                                                      return JSON.stringify(
                                                          feature
                                                              ? meetsPrerequisites(character, feature.prerequisite)
                                                                  ? { name: feature.name, source: feature.source }
                                                                  : undefined
                                                              : o.source
                                                              ? undefined
                                                              : { name: o.name, source: o.source }
                                                      );
                                                  }
                                              )!.filter(o => !!o) as string[]),
                                }}
                                optionToLabel={option => {
                                    const ruleRef = JSON.parse(option) as NamedRuleRef;
                                    return ruleRef.name;
                                }}
                                selections={selections?.features?.map(o =>
                                    o ? JSON.stringify({ name: o.name, source: o.source }) : undefined
                                )}
                                onChoiceMade={(value, i) => {
                                    const fs = selections?.features?.slice() || [];
                                    while (fs.length < feature.featureChoice!.count) {
                                        fs.push(null);
                                    }

                                    fs[i] = value == null ? null : (JSON.parse(value) as NamedRuleRef);
                                    onSelectionsChanged(Object.assign({}, selections, { features: fs }));
                                }}
                                hint="Choose a feature"
                            />
                            {selections?.features?.map((o, i) => {
                                // Get the selected features and show some entries for them.
                                if (!o) {
                                    return undefined;
                                }

                                const from = feature?.featureChoice?.from;
                                let selectedFeature = (
                                    from ? Object.values(from).find(f => f.name === o.name) : undefined
                                ) as Feature | undefined;

                                // Check to see if it's just a ref... if it is, then look for a matching optional feature.
                                if (
                                    !selectedFeature ||
                                    (Object.keys(selectedFeature).length === 2 && selectedFeature.source)
                                ) {
                                    selectedFeature =
                                        getFeatureByName(o.name, o.source, classData, rules.optionalFeatures) ||
                                        selectedFeature;
                                }

                                if (!selectedFeature) {
                                    return undefined;
                                }

                                // The chosen feature can have its own feature choices.
                                return (
                                    <FeatureChoices
                                        key={selectedFeature.name}
                                        feature={selectedFeature}
                                        character={character}
                                        classData={classData}
                                        selections={o}
                                        onSelectionsChanged={s => {
                                            const fs = selections?.features?.slice() || [];
                                            fs[i] = Object.assign({ name: o.name }, s);
                                            onSelectionsChanged(Object.assign({}, selections, { features: fs }));
                                        }}
                                    />
                                );
                            })}
                        </React.Fragment>
                    )}
                    {feature.featChoice && (
                        <React.Fragment>
                            <ChoiceEditor
                                choice={{
                                    count: feature.featChoice.count,
                                    from: (
                                        keyedListToArray(feature.featChoice.from) ??
                                        rules.feats.all.filter(o => meetsPrerequisites(character, o.prerequisite))
                                    ).map(o => {
                                        return JSON.stringify({ name: o.name, source: o.source });
                                    }),
                                }}
                                optionToLabel={option => {
                                    const ruleRef = JSON.parse(option) as NamedRuleRef;
                                    return ruleRef.name;
                                }}
                                selections={selections?.feats?.map(o =>
                                    o ? JSON.stringify({ name: o.name, source: o.source }) : undefined
                                )}
                                onChoiceMade={(value, i) => {
                                    const fts = selections?.feats?.slice() || [];
                                    while (fts.length < feature.featChoice!.count) {
                                        fts.push(null);
                                    }

                                    fts[i] = value == null ? null : (JSON.parse(value) as NamedRuleRef);
                                    onSelectionsChanged(Object.assign({}, selections, { feats: fts }));
                                }}
                                hint="Choose a feat"
                            />
                            {selections?.feats?.map((o, i) => {
                                // Get the selected feat and show it.
                                if (!o) {
                                    return undefined;
                                }

                                const feat = rules.feats.get(o);
                                if (!feat) {
                                    return undefined;
                                }

                                // The chosen feat can have its own feature choices.
                                return (
                                    <FeatureChoices
                                        key={getRuleKey(feat)}
                                        feature={feat}
                                        character={character}
                                        classData={classData}
                                        selections={o}
                                        onSelectionsChanged={s => {
                                            const fs = selections?.feats?.slice() || [];
                                            fs[i] = Object.assign({ name: o.name, source: o.source }, s);
                                            onSelectionsChanged(Object.assign({}, selections, { feats: fs }));
                                        }}
                                    />
                                );
                            })}
                        </React.Fragment>
                    )}
                    {feature.additionalSpells?.spells.map((o, i) => {
                        // If a specific spell is referenced, there is no choice to be made.
                        if (o["name"] && o["source"]) {
                            return undefined;
                        }

                        const spellChoice = o as Choice<NamedRuleRef> & AdditionalSpellBase & { filter?: SpellFilter };
                        const from = (
                            spellChoice.from
                                ? keyedListToArray(spellChoice.from)
                                : filterSpells(spellChoice.filter, rules)
                        ).map(o => JSON.stringify({ name: o.name, source: o.source }));

                        const spellSelections = selections?.additionalSpells
                            ? selections.additionalSpells[i]
                            : undefined;

                        // TODO: Prevent choosing any spell already chosen? (add to except property)
                        return (
                            <ChoiceEditor
                                key={i}
                                choice={Object.assign({}, spellChoice, {
                                    from: from,
                                    except: undefined,
                                })}
                                selections={spellSelections?.map(o => (o != null ? JSON.stringify(o) : undefined))}
                                onChoiceMade={(value, j) => {
                                    const ct = spellSelections?.slice() || [];
                                    while (ct.length < spellChoice.count) {
                                        ct.push(null);
                                    }

                                    ct[j] = value != null ? (JSON.parse(value) as NamedRuleRef) : null;
                                    const ads = selections?.additionalSpells?.slice() ?? [];
                                    ads[i] = ct;
                                    onSelectionsChanged(Object.assign({}, selections, { additionalSpells: ads }));
                                }}
                                optionToLabel={option => {
                                    const ruleRef = JSON.parse(option) as NamedRuleRef;
                                    return ruleRef.name;
                                }}
                                hint="Choose a spell"
                            />
                        );
                    })}
                    {feature.toolChoice &&
                        (() => {
                            // First, flatten out any item filters in the options.
                            const tcs = feature.toolChoice;
                            var options = flattenItems(keyedListToArray(tcs.from), rules);
                            var except = flattenItems(keyedListToArray(tcs.except), rules);

                            const all = options?.map(o => (typeof o === "string" ? o : getRuleKey(o)));
                            var choice: Choice<string> = Object.assign({}, tcs, {
                                except: except?.map(o => (typeof o === "string" ? o : getRuleKey(o))),
                                from: all,
                            });
                            return (
                                <ChoiceEditor
                                    choice={choice}
                                    selections={selections?.tools}
                                    exclude={(() => {
                                        const v = tcs.value ?? 1;
                                        const toolKeys = Object.keys(character.toolProficiencies);
                                        const proficienciesToExclude = toolKeys.filter(
                                            o => (character.skillProficiencies[o] ?? 0) >= v
                                        );

                                        // If the proficiency has a current value requirement, also exclude all the proficiencies that aren't at that value.
                                        if (tcs.current != null) {
                                            for (let toolKey of (all ?? []).filter(
                                                o => character.toolProficiencies[o] !== tcs.current
                                            )) {
                                                if (proficienciesToExclude.indexOf(toolKey) < 0) {
                                                    proficienciesToExclude.push(toolKey);
                                                }
                                            }
                                        }

                                        return proficienciesToExclude;
                                    })()}
                                    onChoiceMade={(value, i) => {
                                        const tcSelections = selections?.tools?.slice() || [];
                                        while (tcSelections.length < choice.count) {
                                            tcSelections.push(null);
                                        }

                                        tcSelections[i] = value ?? null;
                                        onSelectionsChanged(Object.assign({}, selections, { tools: tcSelections }));
                                    }}
                                    optionToLabel={option => {
                                        const key = fromRuleKey(option);
                                        if (key) {
                                            const item = rules.items.items.get(key);
                                            if (item) {
                                                return item.name;
                                            }
                                        }

                                        return option;
                                    }}
                                    hint="Choose a tool"
                                    all={all}
                                />
                            );
                        })()}
                    {/* {feature.toolChoice && <ChoiceEditor
                choice={feature.toolChoice.from ? feature.toolChoice : Object.assign({}, feature.toolChoice, { from: rules.items.items.byType[ItemType.Tool]?.map(o => JSON.stringify({ name: o.name, source: o.source })) })}
                selections={selections?.tools?.map(o => o != null ? JSON.stringify(o) : undefined)}
                onChoiceMade={(value, i) => {
                    const ct = selections?.tools?.slice() || [];
                    ct[i] = value != null ? JSON.parse(value) as NamedRuleRef : undefined;
                    onSelectionsChanged(Object.assign({}, selections, { tools: ct }));
                }}
                optionToLabel={option => {
                    const ruleRef = JSON.parse(option) as NamedRuleRef;
                    return ruleRef.name;
                }}
                hint="Choose a tool" />}
            {feature.artisanToolChoice && <ChoiceEditor
                choice={feature.artisanToolChoice.from ? feature.artisanToolChoice : Object.assign({}, feature.artisanToolChoice, { from: rules.items.items.byType[ItemType.ArtisanTool]?.map(o => JSON.stringify({ name: o.name, source: o.source })) })}
                selections={selections?.artisanTools?.map(o => o != null ? JSON.stringify(o) : undefined)}
                onChoiceMade={(value, i) => {
                    const ct = selections?.artisanTools?.slice() || [];
                    ct[i] = value != null ? JSON.parse(value) as NamedRuleRef : undefined;
                    onSelectionsChanged(Object.assign({}, selections, { artisanTools: ct }));
                }}
                optionToLabel={option => {
                    const ruleRef = JSON.parse(option) as NamedRuleRef;
                    return ruleRef.name;
                }}
                hint="Choose an artisan tool" />} */}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export const FeatureEditor: FunctionComponent<
    React.PropsWithChildren<
        FeatureChoicesProps & {
            subtitle?: string;
            expandedByDefault?: boolean;
            isActionRequired?: boolean;
            character: ResolvedCharacter;
            isInOverlay?: boolean;
        } & ExtractProps<typeof Box>
    >
> = ({
    feature,
    subtitle,
    selections,
    onSelectionsChanged,
    children,
    classData,
    expandedByDefault,
    isActionRequired,
    character,
    isInOverlay,
    ...boxProps
}) => {
    const rules = useRules();
    const actionRequired =
        isActionRequired != null
            ? isActionRequired
            : !!onSelectionsChanged && hasUnmadeChoice(feature, selections, rules);
    return (
        <FeatureExpander
            isInOverlay={isInOverlay}
            title={feature.name}
            subtitle={subtitle}
            expandedByDefault={expandedByDefault}
            badge={actionRequired ? "!" : undefined}
            {...boxProps}>
            <LobotomizedBox fullWidth flexDirection="column" alignItems="flex-start">
                <FeatureChoices
                    feature={feature}
                    character={character}
                    selections={selections}
                    onSelectionsChanged={onSelectionsChanged}
                    classData={classData}
                />
                {children}
            </LobotomizedBox>
        </FeatureExpander>
    );
};

import React from 'react'
  
  const StunnedIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={size} height={size} stroke={color}>
        <g transform="translate(14,-8)"><path d="M358.5 283.23c-22.89 3.1-52 5.23-88.72 6.48-23.3.79-49.43 1.19-77.68 1.19-35.57 0-67.27-.63-86.89-1.09a208.69 208.69 0 0 0 8.9 58.51c22.42 74.88 81.29 125.55 139.88 125.55a99 99 0 0 0 28.48-4.16c65-19.46 98.09-101.96 76.03-186.48zm-162.38 87.28l-13.58-8.25-6 10.53-15.74-9 6.27-10.93-14-8.5 9.42-15.5 13.58 8.25 6-10.53 15.74 9-6.27 10.93 14 8.5zm98.3-25.82l-13.58-8.25-6 10.53-15.74-9 6.24-10.97-14-8.49 9.45-15.51 13.58 8.25 6-10.53 15.74 9-6.27 10.93 14 8.5zm121.79-227.27l22 18.33 24.32-15.08-10.64 26.57 21.86 18.47-28.55-1.91-10.84 26.5-7-27.75-28.54-2.1 24.17-15.23zm-.22-78.84l2.08 17.88 17.62 3.67-16.36 7.5 2 17.89-12.21-13.24-16.41 7.39L401.53 64l-12.1-13.33 17.65 3.55zm-353.07-.45L81.35 60l26.59-10.58-15.13 24.32 18.28 22-27.78-6.87-15.32 24.19-2-28.54-27.74-7.07 26.52-10.76zm353.07 205.31c-4.56 12.66-25.56 26.15-146.72 30.27-25.88.88-52.47 1.18-77.14 1.18-41.91 0-121.2-1.21-121.2-1.21v-16s79.47 1.21 121.21 1.21c24.14 0 50.12-.29 75.43-1.14 38.77-1.29 69.93-3.69 92.62-7.11 34.07-5.15 39.81-11.23 40.63-12.44-.24-.57-1.22-2.35-4.86-5.23-10.14-8-28.53-16-53.3-23.44a202.41 202.41 0 0 0-16.56-21.22c2 .51 4 1 5.88 1.53 35.17 9.36 60 19.64 73.88 30.56 6.51 5.18 13.58 13.36 10.13 23.04zm-304.81-1.51c1.5-7.33 8.84-26.5 12.41-31.92 56.35 3.86 150.85-15.72 176.38-25.16 15.21 13.25 32.71 35.84 40.61 52.19-57.31 6.52-159.43 6.65-229.43 4.9zm19.4-72.09c-10.08-.6-33.73-2.07-42.65 2 11.87 11.21 75 12.46 128.23 4.92 57.06-8.08 110-21.46 141.07-42.63 12.94-8.82 19.78-21.71 18.54-27.43-6.3-29.16-174.12-39.46-174.12-39.46s178.29 3.69 179.61 39.45c1.42 38.36-82.14 67.8-162.44 80.33-76.27 11.9-149.39 12.73-145.6-18.73 2.2-18.28 51.33-14.87 72.59-12.45-4.22 2.91-11.95 10.56-15.26 14.01zm75.88-19.13a106.28 106.28 0 0 1 42.58 4.6c-12.73 3.12-58.29 9.31-85.16 10 21.21-12.93 38.79-14.14 42.55-14.59z"></path></g>
    </svg>
  )
  
  StunnedIcon.displayName = 'StunnedIcon'
  
  StunnedIcon.defaultProps = {
    size: 24,
    color: 'none',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
  }
  
  export default StunnedIcon
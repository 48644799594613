/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Image, Text } from "../primitives";
import { theme } from "../../design";
import { OverlayButton } from "../Button";

import { useLocation, useViewport, useCampaign, useSelection, useLocationLevel, useRole, useUser } from "../contexts";
import {
    PlayerSection,
    resolveToken,
    isLocation,
    Location,
    Token,
    Zone,
    isToken,
    isZone,
    getTokenType,
} from "../../store";

import Log from "./Log";
import { getSelectionByType, SelectionByType } from "../selection";
import { resolveUri, SidebarButton, tokenImageSize, useVttApp, VttMode } from "../common";
import {
    MotionBox,
    sectionInitial,
    sectionAnimate,
    sectionExit,
    defaultInitial,
    defaultAnimate,
    defaultExit,
    AnimatedListItem,
    MotionSpacer,
    MotionOverlayCard,
} from "../motion";
import { AnimatePresence, LayoutGroup, usePresence } from "framer-motion";
import { LightingProperties } from "./LightingProperties";
import { LocationSoundProperties, TokenSoundProperties } from "./SoundProperties";
import ChatIcon from "../icons/Chat";
import LanternIcon from "../icons/Lantern";
import SoundIcon from "../icons/Sound";
import List from "../icons/List";
import { HorizontalScrollable } from "../InlineScrollable";
import { TokenCoreProperties, TokenNotesProperties } from "./TokenProperties";
import { PlayerBarLocationNotes, PlayerBarLocationProperties } from "./LocationProperties";
import { ZoneCoreProperties, ZoneNotesProperties } from "./ZoneProperties";
import { getCenterPoint, createGrid, isPointInPolygon, localPoint, screenPoint } from "../../grid";
import { getPlayerColorPalette, getThemeColor } from "../../design/utils";
import NotebookIcon from "../icons/Notebook";
import { ScrollableTest } from "../ScrollableTest";
import { TypedDroppableArea } from "../draggable";
import { ListBox } from "../ListBox";
import {
    Annotation,
    isAnnotation,
    isConeAnnotation,
    isDoorAnnotation,
    isEllipseAnnotation,
    isLineAnnotation,
    isLineAreaAnnotation,
    isRectAnnotation,
    isTargettedAnnotation,
    isWindowAnnotation,
    LineAnnotation,
} from "../../annotations";

import LineTool from "../icons/LineTool";
import RectTool from "../icons/RectTool";
import EllipseTool from "../icons/EllipseTool";
import WallTool from "../icons/WallTool";
import DoorTool from "../icons/DoorTool";
import WindowTool from "../icons/WindowTool";
import { AnnotationCoreProperties, AnnotationNotesProperties } from "./AnnotationProperties";
import TargetIcon from "../icons/Target";
import ConeIcon from "../icons/Cone";
import LayersIcon from "../icons/Layers";
import { PlayerBarLevelProperties } from "./LevelProperties";

enum PropertiesPage {
    Core = "Core",
    Notes = "Notes",
    Sound = "Sound",
    Lighting = "Lighting",
    Levels = "Levels",
}

const maxMiniMapHeight = 320;

const MiniMap: FunctionComponent<{}> = () => {
    const viewport = useViewport();
    const { campaign, location, level, levelKey, levelKeys } = useLocationLevel();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [clientWidth, setClientWidth] = useState<number>();
    const [clientHeight, setClientHeight] = useState<number>(maxMiniMapHeight);
    const [scale, setScale] = useState<number>();

    // let image = levelKey ? viewport?.levels?.[levelKey]?.texture?.image as HTMLImageElement | undefined : undefined;
    // const naturalWidth = image?.naturalWidth;
    // const naturalHeight = image?.naturalHeight;

    useEffect(() => {
        if (containerRef.current && viewport?.totalSize) {
            const naturalWidth = viewport.totalSize.width;
            const naturalHeight = viewport.totalSize.height;

            // Work out the correct client side for this background image.
            const ratio = naturalWidth / naturalHeight;
            let width = containerRef.current.clientWidth;
            let height = containerRef.current.clientWidth / ratio;
            if (height > maxMiniMapHeight) {
                const heightRatio = height / maxMiniMapHeight;
                width = width / heightRatio;
                height = maxMiniMapHeight;
            }

            const s = width / naturalWidth;
            setClientWidth(width);
            setClientHeight(height);
            setScale(s);
        } else {
            setClientWidth(undefined);
            setScale(undefined);
        }
    }, [viewport?.totalSize]);

    const tileSize = isLocation(location) ? location.tileSize : undefined;
    const createMiniMapGrid = useCallback(() => {
        if (viewport?.totalSize && clientWidth != null && clientHeight != null && scale != null && tileSize) {
            // Find the x and y that represent 0,0 in local map coords.
            const x0 = (clientWidth - viewport.totalSize.width * scale) / 2;
            const y0 = (clientHeight - viewport.totalSize.height * scale) / 2;

            // Create a grid we can use to do calculations, panned so that screen 0,0 is at the top left of of the location.
            const g = createGrid(campaign.gridType, tileSize, scale, {
                x: x0 - viewport.totalSize.x * scale,
                y: y0 - viewport.totalSize.y * scale,
            });
            return g;
        }

        return undefined;
    }, [viewport?.totalSize, clientWidth, clientHeight, scale, campaign.gridType, tileSize]);

    const levelKeysRef = useRef<string[]>();
    levelKeysRef.current = levelKeys;
    useEffect(() => {
        const g = createMiniMapGrid();
        if (g) {
            const loc = location as Location;
            const context = canvasRef.current?.getContext("2d");
            if (context) {
                const fontSize = 10;
                context.font = `${fontSize}px -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Fira Sans','Droid Sans','Helvetica Neue',sans-serif`;
                context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);

                if (viewport?.totalSize && levelKeysRef.current) {
                    for (let i = 0; i < levelKeysRef.current?.length; i++) {
                        const currentLevelKey = levelKeysRef.current[i];
                        const level = loc.levels[currentLevelKey];
                        const image = viewport.levels[currentLevelKey]?.texture?.image as HTMLImageElement | undefined;

                        if (image && loc != null) {
                            const topLeft = g.toScreenPoint(
                                localPoint(level.backgroundImagePos?.x ?? 0, level.backgroundImagePos?.y ?? 0)
                            );

                            const widthRatio = viewport.levels[currentLevelKey].size!.width / viewport.totalSize.width;
                            const heightRatio =
                                viewport.levels[currentLevelKey].size!.height / viewport.totalSize.height;
                            context.drawImage(
                                image,
                                topLeft.x,
                                topLeft.y,
                                clientWidth! * widthRatio,
                                clientHeight! * heightRatio
                            );
                        }

                        const viewportCenter = viewport
                            ? localPoint(
                                  viewport.position.x + viewport.position.width / 2,
                                  viewport.position.y + viewport.position.height / 2
                              )
                            : undefined;

                        for (let zoneId in loc.zones) {
                            const zone = loc.zones[zoneId];
                            if (zone.pos.level !== currentLevelKey) {
                                continue;
                            }

                            const isFocused = viewport && isPointInPolygon(viewportCenter!, zone);
                            context.fillStyle = theme.colors.guidance.info[0];
                            context.globalAlpha = isFocused ? 0.7 : 0.3;

                            // Draw the zone.
                            context.beginPath();

                            for (let i = 0; i < zone.points.length; i++) {
                                const x = zone.pos.x + zone.points[i].x;
                                const y = zone.pos.y + zone.points[i].y;

                                const p = g.toScreenPoint(localPoint(x, y));
                                if (i === 0) {
                                    context.moveTo(p.x, p.y);
                                } else {
                                    context.lineTo(p.x, p.y);
                                }
                            }

                            context.closePath();
                            context.fill();

                            // TODO: Do the codes in a separate pass over the top, so that we don't have to draw zones on top of previous code text?
                            // Also don't have to change styles all the time.
                            if (zone.code) {
                                const center = getCenterPoint(zone.points);

                                const x = zone.pos.x + center.x;
                                const y = zone.pos.y + center.y;

                                const p = g.toScreenPoint(localPoint(x, y));
                                context.strokeStyle = theme.colors.guidance.info[1];
                                context.globalAlpha = 1;
                                const metrics = context.measureText(zone.code);
                                context.strokeText(zone.code, p.x - metrics.width / 2, p.y + fontSize / 2);
                            }
                        }

                        context.globalAlpha = 1;

                        for (let tokenId in loc.tokens) {
                            if (
                                loc.tokens[tokenId].pos.level !== currentLevelKey ||
                                getTokenType(campaign, loc.tokens[tokenId]) !== "creature"
                            ) {
                                continue;
                            }

                            const token = resolveToken(campaign, loc.tokens[tokenId]);

                            if (
                                (token.isPlayerVisible == null || token.isPlayerVisible) &&
                                (token.imageUri || token.type == null || token.type === "creature")
                            ) {
                                context.fillStyle = token.owner
                                    ? getPlayerColorPalette(campaign, token.owner)[5]
                                    : getThemeColor(theme.colors.accent[5]);
                                const points = g.toScreenPoints(g.toGridPoint(token.pos, token.scale), token.scale);

                                context.beginPath();
                                for (let i = 0; i < points.length; i++) {
                                    if (i === 0) {
                                        context.moveTo(points[i].x, points[i].y);
                                    } else {
                                        context.lineTo(points[i].x, points[i].y);
                                    }
                                }

                                context.closePath();
                                context.fill();
                            }
                        }

                        if (levelKey === currentLevelKey) {
                            break;
                        }
                    }
                }

                if (viewport) {
                    context.strokeStyle = theme.colors.guidance.focus;
                    const screenViewport = g.toScreenRect(viewport.position);
                    context.strokeRect(screenViewport.x, screenViewport.y, screenViewport.width, screenViewport.height);
                }
            }
        }
    }, [clientWidth, clientHeight, location, level, scale, viewport, campaign, createMiniMapGrid, levelKey]);

    const moveView = (e: React.PointerEvent<HTMLCanvasElement>) => {
        const g = createMiniMapGrid();
        if (g) {
            const bounds = canvasRef.current!.getBoundingClientRect();
            const clickPoint = g.toLocalPoint(screenPoint(e.clientX - bounds.x, e.clientY - bounds.y));
            viewport?.moveTo(clickPoint);
        }
    };
    return (
        <MotionBox
            layout={clientWidth != null}
            fullWidth
            position="relative"
            borderRadius={3}
            bg="transparency.1.grayscale.9"
            css={{ height: clientHeight }}
            ref={containerRef}
            overflow="hidden">
            <canvas
                ref={canvasRef}
                css={{ position: "absolute", width: clientWidth, height: clientHeight }}
                width={clientWidth}
                height={clientHeight}
                onPointerDown={e => {
                    if (viewport) {
                        moveView(e);
                        canvasRef.current!.setPointerCapture(e.pointerId);
                    }
                }}
                onPointerMove={e => {
                    if (canvasRef.current!.hasPointerCapture(e.pointerId)) {
                        moveView(e);
                    }
                }}
                onPointerUp={e => {
                    canvasRef.current!.releasePointerCapture(e.pointerId);
                }}
            />
        </MotionBox>
    );
};

const SelectionProperties: FunctionComponent<{
    primarySelection: SelectionByType;
    secondarySelection: SelectionByType;
    mode: VttMode;
}> = ({ primarySelection, secondarySelection, mode }) => {
    let [page, setPage] = useState<PropertiesPage | undefined>(PropertiesPage.Notes);
    const { system, campaign, location } = useLocation();
    const role = useRole();
    const user = useUser();

    const [selectedId, setSelectedId] = useState<string | undefined>(primarySelection.all[0]?.id);
    const selectedItem =
        primarySelection.all.find(o => o.id === selectedId) ??
        secondarySelection.all.find(o => o.id === selectedId) ??
        (primarySelection.all.length ? primarySelection.all[0] : undefined);

    let selectedName = "";
    const validPages: PropertiesPage[] = [];
    if (selectedItem == null) {
        if (role === "GM") {
            validPages.push(PropertiesPage.Notes, PropertiesPage.Core, PropertiesPage.Levels, PropertiesPage.Sound);
        }
    } else if (isToken(selectedItem)) {
        switch (getTokenType(campaign, selectedItem)) {
            case "creature":
                selectedName = system.getDisplayName(selectedItem, campaign) ?? "Unnamed";
                break;
            case "light":
                selectedName = "Light";
                break;
            case "audio":
                selectedName = "Sound";
                break;
            case "object":
                selectedName = "Object";
                break;
            default:
                selectedName = "Unnamed";
                break;
        }

        if (role === "GM" || selectedItem.owner === user.id) {
            validPages.push(PropertiesPage.Notes, PropertiesPage.Core, PropertiesPage.Sound, PropertiesPage.Lighting);
        }
    } else if (isZone(selectedItem)) {
        selectedName = selectedItem.label ?? selectedItem.code ?? "";
        if (selectedItem.code && selectedItem.label) {
            selectedName += ` (${selectedItem.code})`;
        }

        validPages.push(PropertiesPage.Notes, PropertiesPage.Core, PropertiesPage.Sound);
    } else if (isAnnotation(selectedItem)) {
        if (role === "GM" || selectedItem.userId === user.id) {
            switch (selectedItem.type) {
                case "line":
                    if ((selectedItem as LineAnnotation).subtype === "wall") {
                        selectedName = "Wall";
                    } else {
                        selectedName = "Line";
                    }

                    break;
                case "door":
                    selectedName = "Door";
                    break;
                case "cone":
                    selectedName = "Cone";
                    break;
                case "ellipse":
                    selectedName = "Ellipse";
                    break;
                case "linearea":
                    selectedName = "Line (area)";
                    break;
                case "rect":
                    selectedName = "Rectangle";
                    break;
                case "window":
                    selectedName = "Window";
                    break;
                case "target":
                    selectedName = "Targetted";
                    break;
            }

            validPages.push(PropertiesPage.Notes, PropertiesPage.Core);
        }
    }

    if (page != null && validPages.indexOf(page) < 0) {
        page = validPages[0];
    }

    const key = page ? page + selectedItem?.id : "nothing";

    return (
        <LayoutGroup>
            <Box flexDirection="column" alignItems="flex-start" fullWidth fullHeight>
                <AnimatePresence mode="popLayout">
                    {primarySelection.all.length === 0 && isLocation(location) && role === "GM" && (
                        <MotionBox
                            key="location"
                            flexDirection="column"
                            alignItems="flex-start"
                            layout
                            fullWidth
                            px={3}
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <MiniMap />
                            <Text mt={2}>{location?.label}</Text>
                        </MotionBox>
                    )}
                    {primarySelection.all.length > 0 && (
                        <MotionBox
                            key="tokens"
                            flexDirection="column"
                            alignItems="flex-start"
                            layout
                            fullWidth
                            px={3}
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <HorizontalScrollable>
                                <ListBox<Token | Annotation | Zone>
                                    css={{
                                        position: "relative",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: theme.space[2],
                                    }}
                                    paddingY={3}
                                    fullWidth
                                    items={[...primarySelection.all, ...secondarySelection.all]}
                                    selectedItems={selectedItem == null ? undefined : [selectedItem]}
                                    itemKey={o => o.id}
                                    selectActive
                                    onSelectionChanged={o => {
                                        setSelectedId(o && o.length ? o[0].id : undefined);
                                    }}>
                                    {({ item, index, selected }) => {
                                        const tokenType = isToken(item) ? getTokenType(campaign, item) : undefined;
                                        return (
                                            <Box key={item.id}>
                                                {secondarySelection.all.indexOf(item) === 0 && (
                                                    <MotionSpacer
                                                        minHeight={tokenImageSize}
                                                        direction="vertical"
                                                        mr={2}
                                                        initial={defaultInitial}
                                                        animate={defaultAnimate}
                                                        exit={defaultExit}
                                                    />
                                                )}
                                                <AnimatedListItem
                                                    key={item.id}
                                                    index={index}
                                                    fullWidth
                                                    width={tokenImageSize}
                                                    height={tokenImageSize}
                                                    animate={{
                                                        background: selected
                                                            ? theme.colors.accent[2]
                                                            : theme.colors.grayscale[7],
                                                    }}
                                                    borderRadius={3}>
                                                    {(tokenType === "creature" || tokenType === "object") && (
                                                        <Image
                                                            src={resolveUri(
                                                                isLocation(location)
                                                                    ? system.getTokenAppearance?.(
                                                                          item as Token,
                                                                          campaign,
                                                                          location
                                                                      )?.imageUri ?? (item as Token).imageUri
                                                                    : (item as Token).imageUri
                                                            )}
                                                            responsive
                                                            fullWidth
                                                            fullHeight
                                                            draggable={false}
                                                        />
                                                    )}
                                                    {tokenType === "light" && <LanternIcon />}
                                                    {tokenType === "audio" && <SoundIcon />}
                                                    {isLineAnnotation(item) && item.subtype == null && <LineTool />}
                                                    {isLineAnnotation(item) && item.subtype === "wall" && <WallTool />}
                                                    {isDoorAnnotation(item) && <DoorTool />}
                                                    {isWindowAnnotation(item) && <WindowTool />}
                                                    {isRectAnnotation(item) && <RectTool />}
                                                    {isEllipseAnnotation(item) && <EllipseTool />}
                                                    {isTargettedAnnotation(item) && <TargetIcon />}
                                                    {isConeAnnotation(item) && <ConeIcon />}
                                                    {isLineAreaAnnotation(item) && <LineTool />}
                                                </AnimatedListItem>
                                            </Box>
                                        );
                                    }}
                                </ListBox>
                            </HorizontalScrollable>
                            <Text>{selectedName}</Text>
                        </MotionBox>
                    )}
                </AnimatePresence>
                <MotionBox
                    layout
                    fullWidth
                    px={3}
                    py={2}
                    mt={4}
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    css={{ gap: theme.space[2], boxSizing: "content-box" }}>
                    <AnimatePresence initial={false}>
                        {validPages.length === 0 && (
                            <MotionBox
                                key="__noPermissions"
                                layout="position"
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <Text fontStyle="italic">You do not have permission to view or edit this item.</Text>
                            </MotionBox>
                        )}

                        {validPages.indexOf(PropertiesPage.Notes) >= 0 && (
                            <MotionBox
                                key="notes"
                                layout
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <SidebarButton
                                    tooltip="Notes"
                                    toggled={page === PropertiesPage.Notes}
                                    onClick={() => setPage(PropertiesPage.Notes)}>
                                    <NotebookIcon />
                                </SidebarButton>
                            </MotionBox>
                        )}
                        {validPages.indexOf(PropertiesPage.Core) >= 0 && (
                            <MotionBox
                                key="properties"
                                layout
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <SidebarButton
                                    tooltip="Properties"
                                    toggled={page === PropertiesPage.Core}
                                    onClick={() => setPage(PropertiesPage.Core)}>
                                    <List />
                                </SidebarButton>
                            </MotionBox>
                        )}
                        {validPages.indexOf(PropertiesPage.Levels) >= 0 && (
                            <MotionBox
                                key="levels"
                                layout
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <SidebarButton
                                    tooltip="Level properties"
                                    toggled={page === PropertiesPage.Levels}
                                    onClick={() => setPage(PropertiesPage.Levels)}>
                                    <LayersIcon />
                                </SidebarButton>
                            </MotionBox>
                        )}
                        {validPages.indexOf(PropertiesPage.Sound) >= 0 && (
                            <MotionBox
                                key="sound"
                                layout
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <SidebarButton
                                    tooltip="Sound"
                                    toggled={page === PropertiesPage.Sound}
                                    onClick={() => setPage(PropertiesPage.Sound)}>
                                    <SoundIcon />
                                </SidebarButton>
                            </MotionBox>
                        )}
                        {validPages.indexOf(PropertiesPage.Lighting) >= 0 && (
                            <MotionBox
                                key="lighting"
                                layout
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <SidebarButton
                                    tooltip="Lighting"
                                    toggled={page === PropertiesPage.Lighting}
                                    onClick={() => setPage(PropertiesPage.Lighting)}>
                                    <LanternIcon />
                                </SidebarButton>
                            </MotionBox>
                        )}
                    </AnimatePresence>
                </MotionBox>
                <MotionBox layout flexDirection="column" flexGrow={1} fullWidth>
                    <AnimatePresence mode="wait" initial={false}>
                        <MotionBox
                            key={key}
                            layout
                            flexDirection="column"
                            fullWidth
                            fullHeight
                            initial={sectionInitial}
                            animate={sectionAnimate}
                            exit={sectionExit}>
                            <ScrollableTest fullWidth fullHeight minimal px={3} pb={3} pt={4}>
                                {page === PropertiesPage.Notes && isToken(selectedItem) && (
                                    <TokenNotesProperties token={selectedItem} mode={mode} />
                                )}
                                {page === PropertiesPage.Notes && isZone(selectedItem) && (
                                    <ZoneNotesProperties zone={selectedItem} mode={mode} />
                                )}
                                {page === PropertiesPage.Notes && isAnnotation(selectedItem) && (
                                    <AnnotationNotesProperties annotation={selectedItem} mode={mode} />
                                )}
                                {page === PropertiesPage.Notes && selectedItem == null && (
                                    <PlayerBarLocationNotes mode={mode} />
                                )}
                                {page === PropertiesPage.Core && isToken(selectedItem) && (
                                    <TokenCoreProperties token={selectedItem} />
                                )}
                                {page === PropertiesPage.Core && isZone(selectedItem) && (
                                    <ZoneCoreProperties zone={selectedItem} />
                                )}
                                {page === PropertiesPage.Core &&
                                    isAnnotation(selectedItem) &&
                                    !selectedItem.disableEdit && <AnnotationCoreProperties annotation={selectedItem} />}
                                {page === PropertiesPage.Core && selectedItem == null && (
                                    <PlayerBarLocationProperties />
                                )}
                                {page === PropertiesPage.Sound && (isToken(selectedItem) || isZone(selectedItem)) && (
                                    <TokenSoundProperties token={selectedItem} />
                                )}
                                {page === PropertiesPage.Sound && selectedItem == null && <LocationSoundProperties />}
                                {page === PropertiesPage.Lighting && isToken(selectedItem) && (
                                    <LightingProperties token={selectedItem} />
                                )}
                                {page === PropertiesPage.Levels && <PlayerBarLevelProperties />}
                            </ScrollableTest>
                        </MotionBox>
                    </AnimatePresence>
                </MotionBox>
            </Box>
        </LayoutGroup>
    );
};

const getSectionId = (section: "log" | "properties" | PlayerSection) => {
    return typeof section === "object" ? "system_" + section.id : section;
};

const PlayerBarContent: FunctionComponent<{
    selection: { primary: string[]; secondary: string[] };
    mode: VttMode;
    playerSections: PlayerSection[] | undefined;
}> = ({ selection, mode, playerSections }) => {
    const { campaign, location } = useLocation();
    const primarySelectionByType = getSelectionByType(selection.primary, campaign, location);
    const secondarySelectionByType = getSelectionByType(selection.secondary, campaign, location);

    let section = useVttApp(state => state.propertiesPage);

    if (typeof section === "object" && !playerSections?.some(o => o.id === (section as PlayerSection).id)) {
        section = "properties";
    }

    const [isPresent, safeToRemove] = usePresence();
    if (!isPresent && safeToRemove) {
        safeToRemove();
    }

    const css: any = isPresent ? { gridArea: "rightdrawer" } : { position: "absolute", right: 0 };
    return (
        <Box fullHeight py={3} flexDirection="column" css={css}>
            <MotionOverlayCard
                flex="1 1 auto"
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ ease: "easeOut" }}
                css={{ width: 400 }}
                borderTopRightRadius={0}
                borderBottomRightRadius={0}
                borderTopLeftRadius={4}
                borderBottomLeftRadius={4}
                zIndex={10}
                justifyContent="flex-start">
                <AnimatePresence mode="wait" initial={false}>
                    <TypedDroppableArea
                        droppableId="playerbar"
                        key={getSectionId(section)}
                        initial={sectionInitial}
                        animate={sectionAnimate}
                        exit={sectionExit}
                        flex={1}
                        mt={7}
                        pt={2}
                        fullHeight
                        fullWidth
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                        {section === "log" && <Log />}
                        {section === "properties" && (
                            <SelectionProperties
                                mode={mode}
                                primarySelection={primarySelectionByType}
                                secondarySelection={secondarySelectionByType}
                            />
                        )}
                        {typeof section === "object" && (
                            <PlayerBarSectionContent
                                section={section}
                                selection={primarySelectionByType}
                                playerSections={playerSections}
                            />
                        )}
                    </TypedDroppableArea>
                </AnimatePresence>
            </MotionOverlayCard>
        </Box>
    );
};

const PlayerBarSectionContent: FunctionComponent<{
    selection: SelectionByType;
    section: PlayerSection;
    playerSections: PlayerSection[] | undefined;
}> = React.memo(({ selection, section, playerSections }) => {
    let sectionKey = getSectionId(section);
    const playerSection = playerSections?.find(o => o.id === section.id);
    return <React.Fragment key={sectionKey}>{playerSection && playerSection.render()}</React.Fragment>;
});

const PlayerBarButtons: FunctionComponent<{
    playerSections?: PlayerSection[];
}> = React.memo(({ playerSections }) => {
    const { system } = useCampaign();

    const { isPropertiesExpanded, setIsPropertiesExpanded, propertiesPage, setPropertiesPage } = useVttApp();

    const goToSection = useCallback(
        (name: "log" | "properties" | PlayerSection) => {
            if (getSectionId(propertiesPage) === getSectionId(name)) {
                setIsPropertiesExpanded(!isPropertiesExpanded);
            } else {
                setPropertiesPage(name);
                setIsPropertiesExpanded(true);
            }
        },
        [propertiesPage, isPropertiesExpanded, setPropertiesPage, setIsPropertiesExpanded]
    );

    return (
        <MotionBox
            flexDirection="row"
            layout="position"
            zIndex={10}
            css={{
                gridArea: "rightdrawer",
                position: "absolute",
                right: theme.space[7] + "px",
                top: isPropertiesExpanded ? theme.space[3] + "px" : 0,
            }}>
            <AnimatePresence>
                {playerSections &&
                    playerSections.map(o => {
                        const sectionId = getSectionId(o);
                        return (
                            <MotionBox
                                key={"glyph_" + system.id + "_" + o.id}
                                layout
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <OverlayButton
                                    variant="secondary"
                                    size="l"
                                    mt={2}
                                    mr={2}
                                    toggled={isPropertiesExpanded && getSectionId(propertiesPage) === sectionId}
                                    onClick={() => goToSection(o)}>
                                    {o.renderGlyph()}
                                </OverlayButton>
                            </MotionBox>
                        );
                    })}
            </AnimatePresence>
            <MotionBox layout>
                <OverlayButton
                    variant="secondary"
                    size="l"
                    mt={2}
                    mr={2}
                    toggled={isPropertiesExpanded && propertiesPage === "properties"}
                    onClick={() => goToSection("properties")}>
                    <List />
                </OverlayButton>
            </MotionBox>
            <MotionBox layout>
                <OverlayButton
                    variant="secondary"
                    size="l"
                    mt={2}
                    mr={2}
                    toggled={isPropertiesExpanded && propertiesPage === "log"}
                    onClick={() => goToSection("log")}>
                    <ChatIcon style={{ transform: "scale(1.5)" }} />
                </OverlayButton>
            </MotionBox>
        </MotionBox>
    );
});

const Playerbar: FunctionComponent<{ mode: VttMode }> = React.memo(({ mode }) => {
    const { system, location } = useLocation();
    const selection = useSelection();
    const isPropertiesExpanded = useVttApp(state => state.isPropertiesExpanded);
    const playerSections = useMemo(
        () =>
            isLocation(location) && system.getPlayerSections
                ? system.getPlayerSections(selection, location)
                : undefined,
        [system, selection, location]
    );

    return (
        <React.Fragment>
            <LayoutGroup>
                <AnimatePresence>
                    {isPropertiesExpanded && (
                        <PlayerBarContent selection={selection} playerSections={playerSections} mode={mode} />
                    )}
                </AnimatePresence>
            </LayoutGroup>
            <PlayerBarButtons playerSections={playerSections} />
        </React.Fragment>
    );
});

export default Playerbar;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { Form, InputField, SelectField } from "../Form";
import { isLocation, Light, LightType, PulseLight, Token } from "../../store";
import { useDispatch, useTokenOverrides, useLocation } from "../contexts";
import { modifyToken } from "../../actions/token";
import { resolveLight, lightTemplates } from "../LocationStage/Lighting";
import { useDebounce } from "../utils";
import { applyOverrides } from "../../reducers/common";
import { PercentageSliderField } from "../slider";

export const LightingProperties: FunctionComponent<{
    token: Token | undefined;
}> = ({ token }) => {
    const dispatch = useDispatch();
    const { campaign, location, system } = useLocation();

    const { tokenOverrides, overrideToken } = useTokenOverrides();

    const originalToken = token;
    if (token) {
        token = applyOverrides(token, tokenOverrides);
    }

    const light = resolveLight(system, token?.light);

    const effectiveInnerRadius = Math.round(light.innerRadius * system.defaultUnitsPerGrid);
    const effectiveOuterRadius = Math.round(light.outerRadius * system.defaultUnitsPerGrid);

    const onColorChanged = useDebounce(
        (color: string) => {
            if (originalToken) {
                dispatch(
                    modifyToken(campaign.id, location!.id, originalToken, {
                        light: Object.assign({}, originalToken.light, { color: color }),
                    })
                );
                overrideToken(originalToken.id, undefined);
            }
        },
        500,
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (originalToken) {
                overrideToken(originalToken.id, { light: { color: e.target.value } });
            }

            return [e.target.value];
        }
    );

    const onBrightnessChanged = useDebounce(
        (brightness: number) => {
            if (originalToken) {
                dispatch(
                    modifyToken(campaign.id, location!.id, token!, {
                        light: Object.assign({}, token?.light, { brightness: brightness }),
                    })
                );
                overrideToken(originalToken.id, undefined);
            }
        },
        500,
        (brightness: number) => {
            if (originalToken) {
                overrideToken(originalToken.id, { light: { brightness: brightness } });
            }

            return [brightness];
        }
    );

    const onMinBrightnessChanged = useDebounce(
        (brightness: number) => {
            if (originalToken) {
                dispatch(
                    modifyToken(campaign.id, location!.id, token!, {
                        light: Object.assign({}, token?.light, { minBrightness: brightness }),
                    })
                );
                overrideToken(originalToken.id, undefined);
            }
        },
        500,
        (brightness: number) => {
            if (originalToken) {
                overrideToken(originalToken.id, { light: { minBrightness: brightness } as any });
            }

            return [brightness];
        }
    );

    if (!token) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <Form fullWidth>
            <SelectField
                label="Lighting type"
                required
                fullWidth
                disabled={!isLocation(location)}
                value={light?.type == null ? "" : light.type}
                onChange={e => {
                    // TODO: Merge other existing settings?
                    dispatch(
                        modifyToken(campaign.id, location!.id, token!, {
                            light: e.target.value ? { type: e.target.value as LightType } : undefined,
                        })
                    );
                }}>
                <option value="">None</option>
                <option value="default">{lightTemplates.default.label}</option>
                <option value="torch">{lightTemplates.torch.label}</option>
                <option value="pulse">{lightTemplates.pulse.label}</option>
                <option value="flicker">{lightTemplates.flicker.label}</option>
            </SelectField>
            <InputField
                fullWidth
                label={`Inner radius (${system.defaultUnit})`}
                hint="The distance from the light that will be visible at full brightness."
                variant="number"
                placeholder={(system.defaultLight.innerRadius * system.defaultUnitsPerGrid).toString()}
                min={0}
                disabled={light?.type == null || !isLocation(location)}
                value={
                    token.light?.innerRadius != null
                        ? Math.round(token.light.innerRadius * system.defaultUnitsPerGrid)
                        : ""
                }
                onChange={e => {
                    const innerRadius = e.target.valueAsNumber / system.defaultUnitsPerGrid;
                    dispatch(
                        modifyToken(campaign.id, location!.id, token!, {
                            light: Object.assign({}, token?.light, {
                                innerRadius: isNaN(innerRadius) ? undefined : innerRadius,
                            }),
                        })
                    );
                }}
            />
            <InputField
                label={`Outer radius (${system.defaultUnit})`}
                hint="The furthest distance that the light reaches."
                variant="number"
                placeholder={(system.defaultLight.outerRadius * system.defaultUnitsPerGrid).toString()}
                disabled={light?.type == null || !isLocation(location)}
                invalid={
                    effectiveOuterRadius < effectiveInnerRadius ? (
                        <React.Fragment>The value must be greater than the inner radius.</React.Fragment>
                    ) : undefined
                }
                value={
                    token.light?.outerRadius != null
                        ? Math.round(token.light.outerRadius * system.defaultUnitsPerGrid)
                        : ""
                }
                onChange={e => {
                    const outerRadius = e.target.valueAsNumber / system.defaultUnitsPerGrid;
                    dispatch(
                        modifyToken(campaign.id, location!.id, token!, {
                            light: Object.assign({}, token?.light, {
                                outerRadius: isNaN(outerRadius) ? undefined : outerRadius,
                            }),
                        })
                    );
                }}
            />
            <InputField
                label="Colour"
                variant={"color" as any}
                disabled={light?.type == null || !isLocation(location)}
                padding={1}
                css={{ height: "2.5em" }}
                value={light.color}
                onChange={onColorChanged}
            />
            <PercentageSliderField
                label={`Brightness (percent)`}
                disabled={light?.type == null || !isLocation(location)}
                value={light.brightness}
                onChange={onBrightnessChanged}
            />
            {light.type === "pulse" && (
                <React.Fragment>
                    <PercentageSliderField
                        label={`Min brightness (percent)`}
                        disabled={!isLocation(location)}
                        value={(light as Required<PulseLight & Light>).minBrightness}
                        onChange={onMinBrightnessChanged}
                    />
                    <InputField
                        label={`Pulse duration (seconds)`}
                        variant="number"
                        placeholder={(light as PulseLight).pulseDuration?.toString() ?? ""}
                        min={0}
                        disabled={!isLocation(location)}
                        value={(token.light as PulseLight | undefined)?.pulseDuration ?? ""}
                        onChange={e => {
                            dispatch(
                                modifyToken(campaign.id, location!.id, token!, {
                                    light: Object.assign({}, token?.light, {
                                        pulseDuration: isNaN(e.target.valueAsNumber)
                                            ? undefined
                                            : e.target.valueAsNumber,
                                    }),
                                })
                            );
                        }}
                    />
                </React.Fragment>
            )}
        </Form>
    );
};

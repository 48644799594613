/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent } from "react";
import { Box, Card } from "../primitives";
import { theme } from "../../design";

import { SpeakerOffIcon, SpeakerLoudIcon, EyeOpenIcon, EyeClosedIcon, Cross1Icon } from "@radix-ui/react-icons";

import { ToolbarButton } from "../Toolbar";
import { getThemeColorPalette } from "../../design/utils";
import { CampaignPlayer, IRtcUser } from "../../store";
import { PlayerMedia } from "./PlayerMedia";
import { MotionBox } from "../motion";

export const PlayerRtc: FunctionComponent<{
    player: CampaignPlayer;
    rtc: IRtcUser;
}> = ({ player, rtc }) => {
    const playerColours = getThemeColorPalette(player.colour);

    const rtcSelf = rtc.session.self === rtc ? rtc.session.self : undefined;

    const hasVideo = !!rtc.stream!.getVideoTracks().length;
    return (
        <Card
            bg="background"
            borderRadius={4}
            css={{
                flexDirection: "column",
                outlineColor: playerColours[4],
                outlineWidth: rtc.isSpeaking ? theme.space[1] : 0,
                outlineStyle: "solid",
                transition: "outline-width 0.1s ease-out",
                marginBottom: hasVideo ? theme.space[5] : theme.space[5] + theme.space[2],
            }}>
            {rtcSelf != null && (
                <Box flexDirection="row" justifyContent="space-between">
                    <MotionBox layout pl={3} py={3}>
                        <ToolbarButton
                            mr={3}
                            tooltip={rtcSelf.isAudioMuted ? "Unmute microphone" : "Mute microphone"}
                            tooltipDirection="up"
                            isToggled={rtcSelf.isAudioMuted}
                            onClick={() => {
                                rtcSelf.isAudioMuted = !rtcSelf.isAudioMuted;
                            }}>
                            {rtcSelf.isAudioMuted && <SpeakerOffIcon />}
                            {!rtcSelf.isAudioMuted && <SpeakerLoudIcon />}
                        </ToolbarButton>
                        <ToolbarButton
                            mr={3}
                            tooltip={rtcSelf.isVideoEnabled ? "Mute video" : "Unmute video"}
                            tooltipDirection="up"
                            isToggled={!rtcSelf.isVideoEnabled}
                            onClick={() => {
                                rtcSelf.isVideoEnabled = !rtcSelf.isVideoEnabled;
                            }}>
                            {!rtcSelf.isVideoEnabled && <EyeClosedIcon />}
                            {rtcSelf.isVideoEnabled && <EyeOpenIcon />}
                        </ToolbarButton>
                    </MotionBox>
                    <MotionBox layout pr={3} py={3}>
                        <ToolbarButton
                            tooltip={"Leave call"}
                            tooltipDirection="up"
                            onClick={() => {
                                rtc.session.leave();
                            }}>
                            <Cross1Icon />
                        </ToolbarButton>
                    </MotionBox>
                </Box>
            )}
            <Box
                borderTopLeftRadius={rtcSelf ? 0 : 4}
                borderTopRightRadius={rtcSelf ? 0 : 4}
                borderBottomLeftRadius={4}
                borderBottomRightRadius={4}
                overflow="hidden">
                <PlayerMedia player={player} stream={rtc.stream!} />
            </Box>
        </Card>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, PropsWithChildren, RefAttributes } from "react";
import { BaseTheme, theme } from "../design";
import { Button } from "./Button";

export const TagButton: FunctionComponent<
    PropsWithChildren<
        {
            palette: keyof BaseTheme["colors"];
            toggled?: boolean;
            onHover?: (hovered: boolean) => void;
            onClick?: () => void;
        } & RefAttributes<HTMLElement>
    >
> = React.forwardRef(({ children, palette, toggled, onHover, onClick }, ref) => {
    const colors = theme.colors[palette] as string[];
    return (
        <Button
            onMouseLeave={() => onHover?.(false)}
            onMouseEnter={() => onHover?.(true)}
            ref={ref as unknown as any}
            onClick={onClick}
            size="s"
            css={{
                height: theme.space[5],
                opacity: toggled ? 1 : 0.3,
                background: theme.colors[palette][7],
                color: theme.colors[palette][1],
                ":hover": {
                    background: colors[5],
                    opacity: toggled ? 1 : 0.5,
                },
            }}>
            {children}
        </Button>
    );
});

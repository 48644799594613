/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useState } from "react";
import { Box } from "../../../components/primitives";
import { theme } from "../../../design";
import { MotionBox } from "../../../components/motion";
import { Button } from "../../../components/Button";
import { ExtractProps } from "../../../components/common";

export const ShowMore: FunctionComponent<ExtractProps<typeof Box>> = ({ children, ...props }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <Box {...props} maxHeight={isExpanded ? undefined : 200} fullWidth flexDirection="column">
            <MotionBox
                layout
                fullWidth
                overflow="hidden"
                flexShrink={1}
                alignItems="flex-start"
                css={{
                    maskImage: isExpanded ? undefined : "linear-gradient(to bottom, black 60%, transparent 100%)",
                }}>
                <MotionBox fullWidth layout="position" flexDirection="column">
                    {children}
                </MotionBox>
            </MotionBox>
            {/* TODO: Do this as a styled element to get rid of the style attribute */}
            <MotionBox layout>
                <Button
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        height: "auto",
                        marginTop: theme.space[1],
                        marginBottom: theme.space[1],
                    }}
                    my={1}
                    alignSelf="flex-start"
                    onClick={() => setIsExpanded(!isExpanded)}
                    variant="link">
                    {isExpanded ? "Show less" : "Show more"}…
                </Button>
            </MotionBox>
        </Box>
    );
};

import React, { PropsWithChildren } from "react";
import { Mesh } from "three";
import { MeshProps, MeshBase, MaterialProps } from "./common";
import { withDragEvents } from "./dragevents";

interface RectProps extends MeshProps, MaterialProps {
    width: number;
    height: number;
}

export const Rect = React.forwardRef<Mesh, PropsWithChildren<RectProps>>(
    ({ width, height, children, ...props }, ref) => {
        return (
            <MeshBase ref={ref} {...props}>
                <planeGeometry attach="geometry" args={[Math.abs(width), Math.abs(height)]} />
                {children}
            </MeshBase>
        );
    }
);

export const DraggableRect = withDragEvents(Rect);

import * as THREE from "three";
import { BufferGeometry, Vector3, Matrix4, Vector4, InstancedBufferAttribute, Texture } from "three";
import { ParticleMaterial, ZoneParticleSystem, useZoneParticleSystem } from "./particlesystem";
import { LocalPixelPosition, LocalRect, Size } from "../../../position";
import { MutableRefObject } from "react";
import { IGrid } from "../../../grid";
import { Location } from "../../../store";
import { LevelInfo, VttCameraLayers } from "../common";

const MAX_PARTICLES = 30000;

const snowflakeCount = 5;
const tempPoint = new Vector3();
const tempMatrix = new Matrix4();
const tempMatrix2 = new Matrix4();
const positionTempPoint = new Vector3();
const angleRange = 0.1;
const HALF_PI = Math.PI / 2;

class SnowParticleSystem extends ZoneParticleSystem {
    private _minVelocity: number | undefined;
    private _maxVelocity: number | undefined;
    private _particleCount: number = 0;
    private _maxHeight: number = 0;

    private _snowMeshes: THREE.InstancedMesh[] | undefined;
    private _snowGeometries: BufferGeometry[] | undefined;
    private _snowMaterials: ParticleMaterial[] | undefined;
    private _velocities: Vector4[][] = [];
    private _angles: number[][] = [];

    get particleCount() {
        return this._particleCount;
    }

    get maxParticleHeight() {
        return this._maxHeight;
    }

    protected override async startCore() {
        this._snowGeometries = [];
        this._snowMaterials = [];
        this._snowMeshes = [];
        this._velocities = [];
        this._angles = [];

        const loader = new THREE.TextureLoader();

        for (let i = 0; i < snowflakeCount; i++) {
            const snowflake = await loader.loadAsync(`snowflake${i + 1}.png`);

            const geometry = this.recreateGeometry();
            const material = new ParticleMaterial({
                color: 0xaaaaaa,
                alphaMap: snowflake,
                opacity: 0.9,
                transparent: true,
                depthWrite: false,
            });

            const velocities: Vector4[] = [];
            const angles: number[] = [];
            this._snowGeometries.push(geometry);
            this._snowMaterials.push(material);
            this._velocities.push(velocities);
            this._angles.push(angles);

            const mesh = new THREE.InstancedMesh(geometry, material, MAX_PARTICLES);
            mesh.layers.set(VttCameraLayers.PerspectiveLighting);
            this.updateGeometry(mesh, geometry, velocities, angles);
            mesh.renderOrder = 9999;
            this._snowMeshes.push(mesh);
        }

        for (let i = 0; i < this._snowMeshes.length; i++) {
            this.scene.add(this._snowMeshes[i]);
        }
    }

    protected override stopCore() {
        if (this._snowMeshes) {
            for (let i = 0; i < this._snowMeshes.length; i++) {
                this.scene.remove(this._snowMeshes[i]);
            }
        }

        this._velocities.length = 0;
        this._angles.length = 0;
        delete this._snowMeshes;
        delete this._snowGeometries;
        delete this._snowMaterials;
    }

    protected updateCore(delta: number) {
        if (this._snowMeshes) {
            for (let i = 0; i < this._snowMeshes.length; i++) {
                this.updateSingle(
                    this._snowMeshes[i],
                    this._snowMaterials![i],
                    this._snowGeometries![i],
                    this._velocities[i],
                    this._angles[i],
                    delta
                );
            }
        }
    }

    private updateSingle(
        mesh: THREE.InstancedMesh,
        material: ParticleMaterial,
        geometry: BufferGeometry,
        velocities: Vector4[],
        angles: number[],
        delta: number
    ) {
        const maxHeight = this.maxParticleHeight;
        const count = mesh!.count;
        const bounds = this.bounds!;

        this.updateParticleMaterialUniforms(material);

        const fadeInHeight = this.tileWidth / 2;
        const fullOpacityHeight = this.maxParticleHeight - fadeInHeight;
        const opacities = geometry.getAttribute("instanceOpacity") as InstancedBufferAttribute;

        for (let i = 0; i < count; i++) {
            const velocity = velocities[i];
            mesh!.getMatrixAt(i, tempMatrix);
            positionTempPoint.setFromMatrixPosition(tempMatrix);

            // The velocity x and y are actually the angle in radians.
            const inc = 40 + this.windLocal.length(); // TODO: This should be random per flake?
            positionTempPoint.setX(positionTempPoint.x + delta * inc * Math.cos(velocity.x));
            positionTempPoint.setY(positionTempPoint.y + delta * inc * Math.sin(velocity.y));
            positionTempPoint.setZ(positionTempPoint.z + delta * velocity.z);
            velocity.setX(velocity.x + delta * (Math.random() * 2 - 1));
            velocity.setY(velocity.y + delta * (Math.random() * 2 - 1));
            angles[i] = angles[i] + delta * velocity.w;

            // Apply wind velocity
            positionTempPoint.setX(positionTempPoint.x + delta * this.windLocal.x);
            positionTempPoint.setY(positionTempPoint.y + delta * this.windLocal.y);

            // If the particle has gone out of bounds, wrap it around.
            if (positionTempPoint.x > bounds.x + bounds.width) {
                positionTempPoint.setX(positionTempPoint.x - bounds.width);
            } else if (positionTempPoint.x < bounds.x) {
                positionTempPoint.setX(positionTempPoint.x + bounds.width);
            }

            if (-positionTempPoint.y > bounds.y + bounds.height) {
                positionTempPoint.setY(positionTempPoint.y + bounds.height);
            } else if (-positionTempPoint.y < bounds.y) {
                positionTempPoint.setY(positionTempPoint.y - bounds.height);
            }

            // Rotate around the Z axis to spin the snowflake, THEN apply the billboard effect by rotating to face the camera.
            tempMatrix.makeRotationX(0);
            tempMatrix.makeRotationY(0);
            tempMatrix.makeRotationZ(angles[i]);
            tempMatrix2.makeRotationFromQuaternion(this.mainCamera!.quaternion);
            tempMatrix.premultiply(tempMatrix2);

            if (positionTempPoint.z < 0) {
                // This particle has hit the ground, respawn it.
                const point = this.getPointInBounds(maxHeight, maxHeight, positionTempPoint);
                this.setSnowVelocity(velocities[i]);
                tempMatrix.setPosition(point.x, -point.y, point.z);
                mesh.setMatrixAt(i, tempMatrix);
            } else {
                tempMatrix.setPosition(positionTempPoint.x, positionTempPoint.y, positionTempPoint.z);
                mesh.setMatrixAt(i, tempMatrix);

                if (positionTempPoint.z > fullOpacityHeight) {
                    opacities.setX(i, 1 - (positionTempPoint.z - fullOpacityHeight) / fadeInHeight);
                } else if (positionTempPoint.z < fadeInHeight) {
                    opacities.setX(i, positionTempPoint.z / fadeInHeight);
                } else {
                    opacities.setX(i, 1);
                }
            }
        }

        opacities.needsUpdate = true;
        mesh.instanceMatrix.needsUpdate = true;
    }

    protected updateGeometry(
        mesh: THREE.InstancedMesh,
        geometry: BufferGeometry,
        velocities: Vector4[],
        angles: number[]
    ) {
        const particleCount = Math.floor(this.particleCount / snowflakeCount);

        if (velocities.length < this.particleCount) {
            mesh.count = particleCount;

            const maxHeight = this.maxParticleHeight;
            for (let i = this._velocities.length; i < this.particleCount; i++) {
                const point = this.getPointInBounds(0, maxHeight, tempPoint);
                tempMatrix.setPosition(point.x, -point.y, point.z);
                mesh.setMatrixAt(i, tempMatrix);

                const v = new Vector4();
                this.setSnowVelocity(v);
                velocities.push(v);
                angles.push(0); // TODO: Random angle.
            }
        } else {
            velocities.length = particleCount;
            angles.length = particleCount;
            mesh.count = particleCount;
        }
    }

    protected onTileWidthChanged(tileWidth: number) {
        this._maxHeight = tileWidth * ZoneParticleSystem.MAX_HEIGHT_GRID;

        if (this._snowMeshes != null) {
            for (let i = 0; i < this._snowMeshes.length; i++) {
                this._snowMeshes[i].geometry = this.recreateGeometry();
            }
        }
    }

    protected override onRateChanged(): void {
        this._minVelocity = this.tileWidth * 0.8;
        this._maxVelocity = this.tileWidth * 1.2;

        if (this.zoneArea) {
            const particlesPerGridPerMinute = this.particlesPerGridUnitPerMinute;
            const particlesPerGridPerSecond = particlesPerGridPerMinute / 60;

            this._particleCount = Math.min(
                Math.round(this.zoneArea * particlesPerGridPerSecond * (ZoneParticleSystem.MAX_HEIGHT_GRID / 10)),
                MAX_PARTICLES
            );

            if (this._snowMeshes) {
                for (let i = 0; i < this._snowMeshes.length; i++) {
                    this.updateGeometry(
                        this._snowMeshes[i],
                        this._snowGeometries![i],
                        this._velocities[i],
                        this._angles[i]
                    );
                }
            }
        }
    }

    protected recreateGeometry(): BufferGeometry {
        let width = this.tileWidth / 100;
        const geometry = new THREE.PlaneGeometry(width * 10, width * 10);
        geometry.setAttribute("instanceOpacity", new InstancedBufferAttribute(new Float32Array(MAX_PARTICLES), 1));
        return geometry;
    }

    protected setSnowVelocity(v: Vector4) {
        const range = this._maxVelocity! - this._minVelocity!;
        v.set(
            Math.random() * angleRange + HALF_PI - angleRange / 2,
            0,
            -(Math.random() * range + this._minVelocity!),
            Math.random() * 4 - 2
        );
    }
}

const createSnow: (scene: THREE.Scene) => ZoneParticleSystem = o => new SnowParticleSystem(o);

export function useSnow(
    mainCamera: THREE.OrthographicCamera | THREE.PerspectiveCamera,
    levelInfo: LevelInfo,
    levelKey: string,
    totalSize: LocalRect | undefined,
    depthMap: Texture,
    size: Size,
    scene: MutableRefObject<THREE.Scene | undefined>,
    particlesCamera: THREE.PerspectiveCamera,
    grid: IGrid,
    location: Location,
    zone: LocalPixelPosition[] | undefined,
    amt: number | undefined
) {
    return useZoneParticleSystem(
        createSnow,
        mainCamera,
        levelInfo,
        levelKey,
        totalSize,
        depthMap,
        size,
        scene,
        particlesCamera,
        grid,
        location,
        zone,
        amt
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent } from "react";
import { DnD5EToken, DnD5ETokenTemplate, ModifiedValue } from "../common";
import { getCoreAbilityAbbr, resolveModifiedValue, Skill, Skills } from "../creature";
import { Box, Grid, Text } from "../../../components/primitives";
import { theme } from "../../../design";
import { getModifiedValueTooltip } from "./common";
import { RollButtonHorizontal } from "./RollButton";
import { useRules } from "./hooks";
import { AdvantageBox } from "./AdvantageBox";
import { MotionInOverlayCard } from "../../../components/motion";
import { AnimatePresence } from "framer-motion";

export const SkillsBox: FunctionComponent<{
    token: DnD5EToken | DnD5ETokenTemplate;
    skills: Required<Skills<ModifiedValue>>;
    proficiencies?: Partial<Skills<number>>;
}> = ({ token, skills, proficiencies }) => {
    const rules = useRules();
    return (
        <MotionInOverlayCard layout p={2} flexDirection="column" fullWidth mb={2} borderRadius={3} boxShadowSize="none">
            <Text textAlign="center" fontSize={1}>
                SKILLS
            </Text>
            {(Object.keys(skills) as Skill[]).map((o: Skill) => {
                const bonus = skills[o] as ModifiedValue;
                const proficiency = proficiencies?.[o] ?? 0;
                const details = rules.skills[o];

                const bonusValue = resolveModifiedValue(bonus);
                return (
                    <RollButtonHorizontal
                        key={o}
                        variant="inOverlayTransparent"
                        tooltip={getModifiedValueTooltip(bonus)}
                        token={token}
                        modifier={bonusValue}
                        extraRolls={skills[o].extraRolls}
                        data={{ skill: o }}>
                        <Grid
                            fullWidth
                            css={{
                                gap: theme.space[2],
                                alignItems: "center",
                                justifyItems: "flex-start",
                            }}
                            gridTemplateColumns={`auto ${theme.space[4] + theme.space[2]}px 1fr auto auto`}
                            gridAutoRows="auto">
                            <Box
                                width={theme.space[2]}
                                height={theme.space[2]}
                                borderRadius="100%"
                                bg={
                                    proficiency >= 2
                                        ? "purples.4"
                                        : proficiency >= 1
                                        ? "greens.4"
                                        : proficiency >= 0.5
                                        ? "oranges.4"
                                        : "grayscale.2"
                                }
                                mr={2}
                            />
                            <Text color="grayscale.2">{getCoreAbilityAbbr(details.modifier)}</Text>
                            <Text>{details.label}</Text>
                            <Box flexDirection="row">
                                <AnimatePresence initial={false}>
                                    {bonus.advantage?.map((o, i) => (
                                        <AdvantageBox key={"adv" + i} advantage="adv" ml={1} compact {...o} />
                                    ))}
                                    {bonus.disadvantage?.map((o, i) => (
                                        <AdvantageBox key={"dis" + i} advantage="dis" ml={1} compact {...o} />
                                    ))}
                                    {bonus.fail?.map((o, i) => (
                                        <AdvantageBox key={"fail" + i} advantage="fail" ml={1} compact {...o} />
                                    ))}
                                </AnimatePresence>
                            </Box>
                            <Text fontSize={2}>{bonusValue >= 0 ? "+" + bonusValue : bonusValue}</Text>
                        </Grid>
                    </RollButtonHorizontal>
                );
            })}
        </MotionInOverlayCard>
    );
};

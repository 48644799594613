import React from 'react'
  
  const EllipseToolIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 10.5,0.75h3c0.414,0,0.75,0.336,0.75,0.75v3c0,0.414-0.336,0.75-0.75,0.75h-3c-0.414,0-0.75-0.336-0.75-0.75 v-3C9.75,1.086,10.086,0.75,10.5,0.75z  M 10.5,18.75h3c0.414,0,0.75,0.336,0.75,0.75v3c0,0.414-0.336,0.75-0.75,0.75h-3 c-0.414,0-0.75-0.336-0.75-0.75v-3C9.75,19.086,10.086,18.75,10.5,18.75z  M 19.5,9.75h3c0.414,0,0.75,0.336,0.75,0.75v3 c0,0.414-0.336,0.75-0.75,0.75h-3c-0.414,0-0.75-0.336-0.75-0.75v-3C18.75,10.086,19.086,9.75,19.5,9.75z  M 1.5,9.75h3 c0.414,0,0.75,0.336,0.75,0.75v3c0,0.414-0.336,0.75-0.75,0.75h-3c-0.414,0-0.75-0.336-0.75-0.75v-3 C0.75,10.086,1.086,9.75,1.5,9.75z  M 3.284,9.75C4.105,6.58,6.58,4.105,9.75,3.284  M 9.744,20.715 c-3.167-0.823-5.639-3.296-6.46-6.463  M 20.716,14.251c-0.821,3.169-3.297,5.644-6.466,6.465  M 14.263,3.287 c3.164,0.824,5.633,3.297,6.453,6.462' />
    </svg>
  )
  
  EllipseToolIcon.displayName = 'EllipseToolIcon'
  
  EllipseToolIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default EllipseToolIcon
/** @jsxRuntime classic */
/** @jsx jsx */
import { Interpolation, jsx, Theme } from "@emotion/react";
import { FunctionComponent, PropsWithChildren, useState } from "react";
import { AppliedAbilityEffectSource, DnD5EToken, DnD5ETokenTemplate, isDnD5ETokenTemplate } from "../common";
import { useDiceBag, useLocation } from "../../../components/contexts";
import {
    addDiceBags,
    DiceBagTerm,
    DiceRollLogEntry,
    LogEntryNotification,
    parseDiceBag,
    reduceDiceBags,
    RollOptions,
} from "../../../store";
import { ExtractProps } from "../../../components/common";
import { Button, ButtonVariant } from "../../../components/Button";

export const RollButtonHorizontal: FunctionComponent<
    PropsWithChildren<
        Omit<RollOptions, "token" | "location"> & { css2?: Interpolation<Theme> } & {
            tooltip?: string;
            onIsRolling?: (isRolling: boolean) => void;
            size?: "s" | "m" | "l";
            modifier: number;
            variant?: ButtonVariant;
            token?: DnD5EToken | DnD5ETokenTemplate;
            onRoll?: (roll: { unconfirmed: DiceRollLogEntry; confirmed: Promise<DiceRollLogEntry> }) => void;
            extraRolls?: { roll: string; reason: string; source?: AppliedAbilityEffectSource }[];
            advantage?: "adv" | "dis";
        }
    >
> = ({
    tooltip,
    size,
    modifier,
    token,
    children,
    onRoll,
    css2,
    advantage,
    onIsRolling,
    variant,
    extraRolls,
    ...rollOptions
}) => {
    const { location } = useLocation();
    const { setDice } = useDiceBag();
    const [isRolling, setIsRolling] = useState(false);

    if (css2) {
        if (!css2["width"]) {
            css2 = Object.assign({}, css2, { width: "100%" });
        }
    } else {
        css2 = { width: "100%" };
    }

    let term: DiceBagTerm;
    if (advantage === "adv") {
        term = { amount: 2, keep: 1 };
    } else if (advantage === "dis") {
        term = { amount: 2, keep: -1 };
    } else {
        term = { amount: 1 };
    }

    return (
        <Button
            size={size}
            tooltip={tooltip}
            css={css2}
            alignItems="center"
            flexDirection="row"
            variant={variant}
            disabled={isRolling}
            onClick={() => {
                setIsRolling(true);
                onIsRolling?.(true);

                setDice(
                    addDiceBags(
                        {
                            d20: [term],
                            modifier: modifier,
                            options: {
                                location: isDnD5ETokenTemplate(token) ? undefined : location?.id,
                                token: isDnD5ETokenTemplate(token) ? token.templateId : token?.id,
                                ...rollOptions,
                            },
                            onRolled: roll => {
                                onRoll?.(roll);
                                roll.confirmed.finally(() => {
                                    setIsRolling(false);
                                    onIsRolling?.(false);
                                });
                            },
                            onCancelled: () => {
                                setIsRolling(false);
                                onIsRolling?.(false);
                            },
                        },
                        reduceDiceBags(extraRolls?.map(o => parseDiceBag(o.roll)))
                    )
                );
            }}>
            {children}
        </Button>
    );
};

// DiceBag.onRolled?: ((roll: {
//     unconfirmed: DiceRollLogEntry & {
//         state?: any;
//     };
//     confirmed: Promise<DiceRollLogEntry>;
// }) => void) | undefined

// onRoll: ((roll: Promise<{
//     unconfirmed: DiceRollLogEntry;
//     confirmed: Promise<DiceRollLogEntry>;
// }>) => void) | undefined
export const RollButton: FunctionComponent<
    ExtractProps<typeof Button> &
        PropsWithChildren<{
            tooltip?: string;
            modifier: number;
            token?: DnD5EToken | DnD5ETokenTemplate;
            advantage?: "adv" | "dis";
            data: any;
            variant?: ButtonVariant;
            onRolled?: (roll: {
                unconfirmed: DiceRollLogEntry & { state?: any };
                confirmed: Promise<DiceRollLogEntry>;
            }) => void;
            extraRolls?: { roll: string; reason: string; source?: AppliedAbilityEffectSource }[];
            notify?: LogEntryNotification;
        }>
> = ({ tooltip, modifier, token, advantage, data, children, onRolled, notify, variant, extraRolls, ...props }) => {
    const { location } = useLocation();
    const { setDice } = useDiceBag();

    let term: DiceBagTerm;
    if (advantage === "adv") {
        term = { amount: 2, keep: 1 };
    } else if (advantage === "dis") {
        term = { amount: 2, keep: -1 };
    } else {
        term = { amount: 1 };
    }

    return (
        <Button
            tooltip={tooltip}
            alignItems="center"
            flexDirection="column"
            variant={variant}
            {...props}
            onClick={() => {
                setDice(
                    addDiceBags(
                        {
                            d20: [term],
                            modifier: modifier,
                            onRolled: onRolled,
                            options: {
                                location: isDnD5ETokenTemplate(token) ? undefined : location?.id,
                                token: isDnD5ETokenTemplate(token) ? token.templateId : token?.id,
                                notify: notify,
                                data: data,
                            },
                        },
                        reduceDiceBags(extraRolls?.map(o => parseDiceBag(o.roll)))
                    )
                );
            }}>
            {children}
        </Button>
    );
};

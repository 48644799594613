/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Grid, Text } from "../../../../components/primitives";
import { theme } from "../../../../design";
import { AnimatePresence } from "framer-motion";
import { FunctionComponent, PropsWithChildren } from "react";
import { Loading } from "../../../../components/common";
import { MotionBox } from "../../../../components/motion";

export const RollingObscured: FunctionComponent<PropsWithChildren<{ isRolling: boolean; justifyLoading?: string }>> = ({
    isRolling,
    children,
    justifyLoading,
}) => {
    return (
        <Grid>
            <AnimatePresence>
                {isRolling && (
                    <MotionBox
                        key="loading"
                        css={{
                            flexDirection: "column",
                            justifyContent: justifyLoading ?? "center",
                            gridRow: "1 / 1",
                            gridColumn: "1 / 1",
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <Text color="grayscale.2">Rolling…</Text>
                        <Loading />
                    </MotionBox>
                )}
            </AnimatePresence>
            <MotionBox
                css={{
                    minWidth: theme.space[9],
                    gridRow: "1 / 1",
                    gridColumn: "1 / 1",
                }}
                initial={{ opacity: 1 }}
                animate={{ opacity: isRolling ? 0 : 1 }}>
                {children}
            </MotionBox>
        </Grid>
    );
};

/* eslint-disable react/default-props-match-prop-types */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, { forwardRef } from "react";
import { jsx } from "@emotion/react";
import { Box, BoxProps } from "../primitives";

import { Avatar, AvatarProps } from "../Avatar";
import { GridProps } from "styled-system";
import { motion } from "framer-motion";

const extents = ({ theme, size }) => {
    const sizes = {
        s: theme.space[4],
        m: theme.space[5],
        l: theme.space[6],
    };

    return {
        width: sizes[size],
        height: sizes[size],
    };
};

const appearance = ({ variant, size, theme, dataLength }) => {
    const sizes = {
        s: theme.space[4],
        m: theme.space[5],
        l: theme.space[6],
    };

    const s = sizes[size];

    // This width calculation is here to handle overflow and layout correctly
    const width = dataLength * s - dataLength * 6;

    const appearances = {
        stack: {
            display: "flex",
            height: s,
            width,
        },
        grid: {
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, minmax(${s}px, 1fr))`,
            gridAutoRows: "auto",
        },
    };

    return appearances[variant];
};

const childPositions = ({ length, index, size, groupVariant, theme }) => {
    const sizes = {
        s: theme.space[4],
        m: theme.space[5],
        l: theme.space[6],
    };

    const props: any = {
        position: groupVariant === "stack" ? "absolute" : "relative",
    };
    if (groupVariant === "stack") {
        props["left"] = (sizes[size] - 8) * index;
        props["zIndex"] = length - index;
    }

    return props;
};

export interface IAvatarGroupProps {
    variant?: "stack" | "grid";
    size?: "s" | "m" | "l";
    maxCount?: number;
    data: AvatarProps[];
}

export type AvatarGroupProps = IAvatarGroupProps & BoxProps & GridProps & React.HTMLAttributes<HTMLDivElement>;

export const AvatarGroup: React.FunctionComponent<AvatarGroupProps> = forwardRef<HTMLDivElement, AvatarGroupProps>(
    ({ size, maxCount = 5, data, variant, gridGap, ...props }, ref) => {
        const remainder = data.length - maxCount;
        const avatarData = remainder > 0 ? data.slice(0, maxCount) : data;

        return (
            <Box
                position="relative"
                css={theme => ({
                    ...appearance({
                        theme,
                        variant,
                        size,
                        dataLength: avatarData.length + (remainder > 0 ? 1 : 0),
                    }),
                    gridGap: theme.space[gridGap as number],
                })}
                ref={ref}
                {...props}>
                {avatarData.map(({ name, variant: avatarVariant, ...rest }, index) => (
                    <Avatar
                        key={index}
                        name={name}
                        variant={avatarVariant}
                        size={size}
                        css={(theme: any) => ({
                            ...childPositions({
                                length: data.length,
                                index,
                                size,
                                groupVariant: variant,
                                theme,
                            }),
                        })}
                        {...rest}
                    />
                ))}
                {remainder > 0 && (
                    <Box
                        bg="grayscale.8"
                        borderRadius={6}
                        border="3px solid white"
                        css={(theme: any) => ({
                            zIndex: -1,
                            ...extents({ theme, size }),
                            ...childPositions({
                                length: data.length,
                                index: 0,
                                size,
                                groupVariant: variant,
                                theme,
                            }),
                        })}>
                        {`+${remainder}`}
                    </Box>
                )}
            </Box>
        );
    }
);

AvatarGroup.displayName = "AvatarGroup";

AvatarGroup.defaultProps = {
    variant: "stack",
    size: "m",
};

export const MotionAvatarGroup = motion(AvatarGroup);

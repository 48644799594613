import { useFrame, useThree } from "@react-three/fiber";
import { animate, ValueAnimationOptions, useMotionValue } from "framer-motion";
import React, { FunctionComponent, useEffect, useRef, useMemo } from "react";
import { PerspectiveCamera } from "three";
import { localPoint, rotate, screenPoint } from "../../grid";
import { LocalPixelPosition, LocalRect, PositionType } from "../../position";
import { useLocalGrid, useSelection, useTokenOverrides, useValidatedLocationLevel } from "../contexts";
import { getLastSelectedToken } from "../selection";
import { filterKeyEvent } from "../utils";
import { LevelInfo, TokenWithLocalState, cameraDistanceToFit } from "./common";
import { Token } from "../../store";
import { applyOverrides } from "../../reducers/common";
import { WithOverride } from "../../common";
import { useVttApp } from "../common";

// TODO: A spring transition looks better here, but it does weird things when trying to animate the initial position from the orthographic
// pos to the perspective pos. It seems to fly way off where it's meant to go before bringing it back, I can't see why.
// const cameraTransition: AnimationOptions<number> = {
//     type: "spring",
//     damping: 70,
//     mass: 8,
//     stiffness: 150
// };
export const cameraTransition: ValueAnimationOptions<number> = {
    type: "tween",
    ease: "easeInOut",
    duration: 1.2,
} as ValueAnimationOptions<number>;

const perspectiveCameraMinZoom = 0.2;
const perspectiveCameraMaxZoom = 2;

// The default height for the camera when viewing the entire location, in tiles.
const locationDefaultHeight = 20;

// The default height for the camera when viewing a token, in tiles.
const tokenDefaultHeight = 10;

// The movement speed of the camera when controlled by the keyboard, in tiles/second.
const moveSpeed = 10;

export const PerspectiveCameraControl: FunctionComponent<{
    totalSize: LocalRect | undefined;
    levelInfo: LevelInfo;
    camera: PerspectiveCamera;
    setPosition: (p: { x: number; y: number }) => void;
}> = ({ totalSize, levelInfo, camera, setPosition }) => {
    const { location, levelKey } = useValidatedLocationLevel();
    const size = useThree(state => state.size);
    const domElement = useThree(state => state.gl.domElement);
    const grid = useLocalGrid();

    const { tokenOverrides } = useTokenOverrides();

    const { isCameraChanging, setIsCameraChanging } = useVttApp();

    // Handle the camera position for perspective mode.
    const { primary } = useSelection();

    const perspectivePos = useRef<LocalPixelPosition>();
    const lastSelectedToken = useRef<Token>();

    const t = getLastSelectedToken(primary, location);
    const lastSelected = t ? (applyOverrides(t, tokenOverrides) as WithOverride<TokenWithLocalState>) : undefined;

    // If the last selected token has changed, remove any user position so that we move to look at the selected token.
    if (lastSelected && lastSelected.id !== lastSelectedToken.current?.id) {
        perspectivePos.current = undefined;
    }

    // The desired focused camera location is:
    // 1) The user defined position, if any
    // 2) The position of the last selected token, if any
    // 3) The center of the map
    let perspectiveCenter: LocalPixelPosition | undefined =
        perspectivePos.current ??
        (lastSelected
            ? grid.toLocalCenterPoint(lastSelected.pos, lastSelected.scale)
            : grid.toLocalPoint(screenPoint(size.width / 2, size.height / 2)));

    const baseZ = levelInfo[levelKey]?.height ?? 0;

    const posX = useMotionValue(perspectiveCenter?.x ?? 0);
    const posY = useMotionValue(perspectiveCenter?.y ?? 0);
    const posZ = useMotionValue(baseZ);

    const distance = useMotionValue(0);
    const height = useMotionValue(cameraDistanceToFit(camera, grid, size));
    const rotation = useMotionValue(0);
    const zoom = useMotionValue(1);

    const levels = Object.values(levelInfo);
    const allLoaded = levels.every(o => o.size != null);
    const initialisedRef = useRef(false);

    camera.aspect = size.width / size.height;

    const keys = useMemo(
        () => ({
            left: false,
            right: false,
            up: false,
            down: false,
            rotateLeft: false,
            rotateRight: false,
            zoomIn: false,
            zoomOut: false,
        }),
        []
    );

    const lastBaseHeight = useRef<number>();
    const hackRef = useRef(0);

    // Handle mouse wheel to zoom in and out.
    useEffect(() => {
        if (domElement && !isCameraChanging) {
            const wheelHandler = (ev: WheelEvent) => {
                const newZoom =
                    ev.deltaY < 0
                        ? Math.max(zoom.get() - 0.2, perspectiveCameraMinZoom)
                        : Math.min(zoom.get() + 0.2, perspectiveCameraMaxZoom);
                animate(zoom, newZoom);
            };
            domElement.addEventListener("wheel", wheelHandler);
            return () => {
                domElement.removeEventListener("wheel", wheelHandler);
            };
        }
    }, [domElement, zoom, isCameraChanging]);

    // Handle various keypresses that control the camera.
    useEffect(() => {
        const keyHandler = (ev: KeyboardEvent, value: boolean) => {
            if (filterKeyEvent(ev)) {
                if (ev.key === "ArrowLeft" || ev.key === "A" || ev.key === "a") {
                    keys.left = value;
                } else if (ev.key === "ArrowRight" || ev.key === "D" || ev.key === "d") {
                    keys.right = value;
                } else if (ev.key === "ArrowUp" || ev.key === "W" || ev.key === "w") {
                    keys.up = value;
                } else if (ev.key === "ArrowDown" || ev.key === "S" || ev.key === "s") {
                    keys.down = value;
                } else if (ev.key === "Q" || ev.key === "q") {
                    keys.rotateLeft = value;
                } else if (ev.key === "E" || ev.key === "e") {
                    keys.rotateRight = value;
                }
            }
        };
        const keydownHandler = (ev: KeyboardEvent) => keyHandler(ev, true);
        document.addEventListener("keydown", keydownHandler);

        const keyupHandler = (ev: KeyboardEvent) => keyHandler(ev, false);
        document.addEventListener("keyup", keyupHandler);

        return () => {
            document.removeEventListener("keydown", keydownHandler);
            document.removeEventListener("keyup", keyupHandler);
        };
    }, [keys]);

    const updateCamera = () => {
        // Adjust the main camera position, if we're in perspective mode.
        if (perspectiveCenter) {
            const focalPos = localPoint(posX.get(), posY.get());
            const actualPos = localPoint(focalPos.x, focalPos.y + distance.get() * zoom.get());
            const finalPos = rotate(actualPos, focalPos, rotation.get());

            camera.position.set(finalPos.x, -finalPos.y, height.get() * zoom.get());
            camera.lookAt(posX.get(), -posY.get(), posZ.get());

            // For some reason I'm not quite sure of, the first frame or two with the new camera sometimes has a strange rotation.
            // Probably because it's more or less directly above what's being looked at, so it's not sure how to best rotate.
            // If we just reset it to 0 (what it should be) the first time, everything seems ok?
            if (hackRef.current < 2) {
                hackRef.current++;
                camera.rotation.set(0, 0, 0);
            } else {
                camera.lookAt(posX.get(), -posY.get(), posZ.get());
            }

            camera.updateProjectionMatrix();
            camera.updateMatrixWorld();
        }
    };

    const animCount = useRef<number>();
    const updateMotionValues = () => {
        // When the camera is transitioning back to orthographic, move the camera to where the orthographic camera will be.
        if (isCameraChanging && animCount.current == null) {
            animCount.current = 5;
            const animComplete = () => {
                if (animCount.current != null) {
                    animCount.current!--;
                    if (animCount.current === 0) {
                        animCount.current = undefined;
                        posZ.clearListeners();
                        distance.clearListeners();
                        height.clearListeners();
                        rotation.clearListeners();
                        zoom.clearListeners();

                        const currentFocusPos = localPoint(posX.get(), posY.get());
                        const sp = grid.toScreenPoint(currentFocusPos);
                        sp.x -= size.width / 2;
                        sp.y -= size.height / 2;
                        const pos = grid.toLocalPoint(sp);
                        setPosition(localPoint(-pos.x * grid.ref.current!.scale, -pos.y * grid.ref.current!.scale));

                        setIsCameraChanging(false);
                    }
                }
            };
            // posX.on("animationComplete", animComplete);
            // posY.on("animationComplete", animComplete);
            posZ.on("animationComplete", animComplete);
            distance.on("animationComplete", animComplete);
            height.on("animationComplete", animComplete);
            rotation.on("animationComplete", animComplete);
            zoom.on("animationComplete", animComplete);

            const distanceToFit = cameraDistanceToFit(camera, grid, size);

            // const orthographicPoint = grid.toLocalPoint(screenPoint(size.width / 2, size.height / 2));
            // animate(posX, orthographicPoint.x, cameraTransition);
            // animate(posY, orthographicPoint.y, cameraTransition);
            animate(posZ, 0, cameraTransition);

            animate(distance, 0.05 * location.tileSize.width, cameraTransition);
            animate(height, distanceToFit, cameraTransition);
            animate(rotation, 0, cameraTransition);
            animate(zoom, 1, cameraTransition);
            return;
        }

        if (!allLoaded || !initialisedRef.current) {
            // Calculate the camera distance
            const distanceToFit = cameraDistanceToFit(camera, grid, size);

            // Still loading and changing the total size.
            const orthographicPoint = grid.toLocalPoint(screenPoint(size.width / 2, size.height / 2));
            posX.set(orthographicPoint.x);
            posY.set(orthographicPoint.y);
            posZ.set(baseZ);
            distance.set(0);
            height.set(distanceToFit);
            initialisedRef.current = allLoaded;

            lastBaseHeight.current = baseZ;
            lastSelectedToken.current = lastSelected;
        }

        if (perspectiveCenter?.x != null && totalSize && allLoaded) {
            if (lastSelected?.isDragging) {
                // If the selected token is being dragged, then we have to be careful not to animate anything.
                // Animating the camera while the token is dragging causes problems because changing levels etc can
                // cause the mouse pointer to be in the wrong position, causing jittering.
                if (
                    lastSelectedToken.current &&
                    lastSelected &&
                    lastSelectedToken.current.id === lastSelected.id &&
                    lastBaseHeight.current !== baseZ
                ) {
                    height.set(baseZ + location.tileSize.width * tokenDefaultHeight);
                    posZ.set(baseZ);
                    updateCamera();
                }
            } else {
                animate(posX, perspectiveCenter.x, cameraTransition);
                animate(posY, perspectiveCenter.y, cameraTransition);
                animate(distance, lastSelected ? location.tileSize.width * 20 : totalSize.height, cameraTransition);
                animate(
                    height,
                    baseZ +
                        (lastSelected
                            ? location.tileSize.width * tokenDefaultHeight
                            : location.tileSize.width * locationDefaultHeight),
                    cameraTransition
                );
                animate(posZ, baseZ, cameraTransition);
            }
        }

        lastSelectedToken.current = lastSelected;
        lastBaseHeight.current = baseZ;
    };

    useFrame((state, delta) => {
        if (!isCameraChanging) {
            // Constant is rotation speed in deg/sec.
            if (keys.rotateLeft || keys.rotateRight) {
                const rotateDelta = delta * 60;
                let r: number = rotation.get();
                if (keys.rotateLeft && !keys.rotateRight) {
                    r += rotateDelta;
                    if (r > 180) {
                        r -= 360;
                    }
                } else if (keys.rotateRight && !keys.rotateLeft) {
                    r -= rotateDelta;
                    if (r < -180) {
                        r += 360;
                    }
                }

                rotation.set(r);
            }

            if (grid.ref.current && (keys.left || keys.right || keys.up || keys.down)) {
                let pos = localPoint(posX.getPrevious(), posY.getPrevious());

                if (keys.left) {
                    const x = pos.x - delta * (location.tileSize.width * moveSpeed);
                    const p = rotate(
                        { x: x, y: pos.y, type: PositionType.LocalPixel },
                        { x: pos.x, y: pos.y, type: PositionType.LocalPixel },
                        rotation.get()
                    );
                    posX.set(p.x);
                    posY.set(p.y);

                    pos = p;
                }

                if (keys.right) {
                    const x = pos.x + delta * (location.tileSize.width * moveSpeed);
                    const p = rotate(
                        { x: x, y: pos.y, type: PositionType.LocalPixel },
                        { x: pos.x, y: pos.y, type: PositionType.LocalPixel },
                        rotation.get()
                    );
                    posX.set(p.x);
                    posY.set(p.y);

                    pos = p;
                }

                if (keys.up) {
                    const y = pos.y - delta * (location.tileSize.width * moveSpeed);
                    const p = rotate(
                        { x: pos.x, y: y, type: PositionType.LocalPixel },
                        { x: pos.x, y: pos.y, type: PositionType.LocalPixel },
                        rotation.get()
                    );
                    posX.set(p.x);
                    posY.set(p.y);

                    pos = p;
                }

                if (keys.down) {
                    const y = pos.y + delta * (location.tileSize.width * moveSpeed);
                    const p = rotate(
                        { x: pos.x, y: y, type: PositionType.LocalPixel },
                        { x: pos.x, y: pos.y, type: PositionType.LocalPixel },
                        rotation.get()
                    );
                    posX.set(p.x);
                    posY.set(p.y);

                    pos = p;
                }

                perspectivePos.current = pos;
            }

            if (keys.zoomIn || keys.zoomOut) {
                const zoomDelta = delta * 0.6;
                if (keys.zoomIn && !keys.zoomOut) {
                    zoom.set(Math.max(zoom.get() - zoomDelta, perspectiveCameraMinZoom));
                } else if (keys.zoomOut && !keys.zoomIn) {
                    zoom.set(Math.min(zoom.get() + zoomDelta, perspectiveCameraMaxZoom));
                }
            }
        }

        updateCamera();
    });

    updateMotionValues();

    return <React.Fragment />;
};

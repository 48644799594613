/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useRef, useState } from "react";
import { Box, Heading, Link, Select, Text } from "../../../../components/primitives";
import { theme } from "../../../../design";
import { Button } from "../../../../components/Button";
import { Spacer } from "../../../../components/Spacer";
import { Form, InputField } from "../../../../components/Form";
import {
    Character,
    ResolvedCharacter,
    CharacterClass,
    resolveClassSpellList,
    filterClassSpellList,
    ResolvedClassLevels,
    getSpellDc,
    armorProficienciesToString,
    weaponProficienciesToString,
    coreAbilitiesToString,
    skillProficienciesToString,
    itemProficienciesToString,
    getMaxSpellLevel,
    canLearnSpell,
    AdditionalSpell,
} from "../../creature";
import { copyState } from "../../../../reducers/common";
import { FeatureEditor } from "./FeatureEditor";
import { ChoiceEditor } from "./ChoiceEditor";
import { DnD5ECharacterTemplate, DnD5ECharacterToken, getRuleKey, NamedRuleRef, StoredCharacter } from "../../common";
import { SpellList } from "../SpellInfo";
import { BasicExpander } from "../BasicExpander";
import { useRules } from "../hooks";
import {
    defaultInitial,
    defaultAnimate,
    defaultExit,
    MotionBox,
    AnimatedList,
    AnimatedListItem,
} from "../../../../components/motion";
import { AnimatePresence } from "framer-motion";
import CloseIcon from "../../../../components/icons/Close";
import { useAppState, useDispatch, useLocation } from "../../../../components/contexts";
import { diceTypeToMax, IMenuItem, isToken } from "../../../../store";
import { RollingObscured } from "./common";
import { TabBox, Tab } from "./TabBox";
import { ShowMore } from "../ShowMore";
import { modifyCharacter } from "../../actions/token";
import { Markdown } from "../../../../components/markdown";
import { ScrollableTest } from "../../../../components/ScrollableTest";
import { CustomSvg, LobotomizedBox, useVttApp } from "../../../../components/common";
import { CompendiumPage, useCompendium } from "../common";
import { ModalDialog } from "../../../../components/modal";
import FocusTrap from "focus-trap-react";
import { Spell } from "../../spells";
import { dragDropPalette, useTypedDroppable } from "../../../../components/draggable";
import { getThemeColor } from "../../../../design/utils";

const ClassList: FunctionComponent<{
    resolvedCharacter: ResolvedCharacter;
    onSelected: (c: CharacterClass) => void;
}> = ({ resolvedCharacter, onSelected }) => {
    const rules = useRules();

    return (
        <ScrollableTest fullWidth fullHeight minimal>
            <AnimatedList alignItems="flex-start">
                {rules.classes.all.map((o, i) => (
                    <AnimatedListItem key={o.key} fullWidth index={i}>
                        <Box fullWidth mx={2} justifyContent="flex-start" px={3} flexDirection="column">
                            {o.fluff && (
                                <ShowMore>
                                    <Markdown>
                                        {o.icon && (
                                            <CustomSvg
                                                css={{
                                                    fill: "currentColor",
                                                    float: "left",
                                                    marginTop: "1em",
                                                }}
                                                pr={2}
                                                pb={2}
                                                width={theme.space[7]}
                                                svg={o.icon}
                                            />
                                        )}
                                        {o.fluff}
                                    </Markdown>
                                </ShowMore>
                            )}
                            <MotionBox layout>
                                <Button
                                    mt={2}
                                    alignSelf="flex-start"
                                    disabled={!!resolvedCharacter.classes[o.name]}
                                    onClick={() => onSelected(o)}>
                                    Choose {o.name}
                                </Button>
                            </MotionBox>
                            <Spacer mt={5} />
                        </Box>
                    </AnimatedListItem>
                ))}
            </AnimatedList>
        </ScrollableTest>
    );
};

const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

const HitPointBox: FunctionComponent<{
    level: number;
    character: Character | StoredCharacter;
    token: DnD5ECharacterTemplate | DnD5ECharacterToken;
    data: ResolvedClassLevels;
    classKey: string;
}> = ({ level, character, token, data, classKey }) => {
    const [isRolling, setIsRolling] = useState(false);
    const dispatch = useDispatch();
    const { campaign, location, api } = useLocation();

    const setHp = (hp: number | undefined) => {
        const hpPerLevel = copyState(data.hpPerLevel, { [level]: hp });
        const oldClassInfo = character.classes![classKey];
        const newClassInfo = copyState(oldClassInfo, { hpPerLevel: hpPerLevel });
        const newClasses = copyState(character.classes, { [classKey]: newClassInfo });

        dispatch(modifyCharacter(campaign, location, [token], { classes: newClasses }));
    };

    return (
        <Box flexDirection="row" justifyContent="flex-start" fullWidth>
            <RollingObscured isRolling={isRolling} justifyLoading="flex-end">
                <InputField
                    required
                    variant="number"
                    min={1}
                    max={data.classData.hitDie}
                    label={`Level ${level}`}
                    value={data.hpPerLevel[level] ?? ""}
                    onChange={e => {
                        if (!isNaN(e.target.valueAsNumber)) {
                            setHp(e.target.valueAsNumber);
                        } else {
                            setHp(undefined);
                        }
                    }}
                />
            </RollingObscured>
            <Button
                mb={`${theme.space[1] / 2}px`}
                ml={2}
                alignSelf="flex-end"
                onClick={async () => {
                    const roll = await api.roll(`1${data.classData.hitDie}`, {
                        token: isToken(token) ? token.id : token.templateId,
                        data: { class: character.classes![classKey].class, level: level },
                    });
                    setIsRolling(true);
                    try {
                        const result = await roll.confirmed;
                        setHp(result.result);
                    } finally {
                        setIsRolling(false);
                    }
                }}>
                Roll
            </Button>
        </Box>
    );
};

const ClassSpellList: FunctionComponent<{
    knownSpells?: Spell[];
    resolvedCharacter: ResolvedCharacter;
    levels: ResolvedClassLevels;
    token: DnD5ECharacterTemplate | DnD5ECharacterToken;
}> = ({ knownSpells, resolvedCharacter, levels, token }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const character = token.dnd5e;
    const classKey = levels.classData.originalClass.name;

    const knownCantripCount = levels.currentCantripsKnown ?? 0;

    const { searchPropertiesSection, searchPropertiesSections, setSearchPropertiesSection } = useAppState();
    const isSearchExpanded = useVttApp(state => state.isSearchExpanded);
    const setIsSearchExpanded = useVttApp(state => state.setIsSearchExpanded);
    const { page, setPage, spellFilter, setSpellFilter } = useCompendium();

    const maxKnownSpells = Array.isArray(levels.classData.spellsKnownProgression)
        ? levels.classData.spellsKnownProgression[levels.level - 1]
        : undefined;

    const { setNodeRef, active, isOver } = useTypedDroppable({
        id: "ClassSpellList_" + getRuleKey(levels.classData),
        accepts: ["DnD5E_Spell"],
        canDrop: drag => {
            const spell = drag.data as Spell;
            return canLearnSpell(spell, levels);
        },
        onDrop: drag => {
            const spell = drag.data as Spell;
            const oldClassInfo = levels!.resolvedFrom;
            const newSpells = oldClassInfo.knownSpells ? oldClassInfo.knownSpells.slice() : [];
            newSpells.push({
                name: spell.name,
                source: spell.source,
            });

            const newClassInfo = copyState(oldClassInfo, {
                knownSpells: newSpells,
            });
            const newClasses = copyState(resolvedCharacter.resolvedFrom.classes, {
                [levels!.resolvedFrom.class.name]: newClassInfo,
            });
            dispatch(
                modifyCharacter(campaign, location, [token as DnD5ECharacterToken | DnD5ECharacterTemplate], {
                    classes: newClasses,
                })
            );
        },
        renderFeedback: drag => {
            const spell = drag.data as Spell;
            return (
                <React.Fragment>
                    Drop to add {spell.name} to {resolvedCharacter.name} as a {levels.classData.name} spell.
                </React.Fragment>
            );
        },
    });

    return (
        <MotionBox
            css={{
                marginLeft: -theme.space[2],
                marginRight: -theme.space[2],
                marginBottom: -theme.space[2],
                marginTop: theme.space[1],
                padding: theme.space[2],
            }}
            borderRadius={4}
            ref={setNodeRef}
            flexDirection="column"
            alignItems="flex-start"
            alignSelf="stretch"
            animate={{
                backgroundColor: active ? getThemeColor(isOver ? dragDropPalette[1] : dragDropPalette[0]) : undefined,
            }}>
            <AnimatePresence>
                {levels.maxCantripsKnown != null && knownCantripCount < levels.maxCantripsKnown && (
                    <MotionBox
                        key="knownCantrips"
                        layout
                        mb={2}
                        flexDirection="column"
                        alignItems="flex-start"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <span>
                            {knownCantripCount} of {levels.maxCantripsKnown} cantrips known
                        </span>
                        <Link
                            mt={2}
                            disabled={
                                isSearchExpanded &&
                                page === CompendiumPage.Spells &&
                                searchPropertiesSection.id === "dnd5e" &&
                                spellFilter?.characterClass === levels.classData.originalClass &&
                                spellFilter?.characterSubclass === levels.classData.subclass &&
                                spellFilter.level === 0 &&
                                (spellFilter.levelMode ?? "equal") === "equal"
                            }
                            onClick={() => {
                                const searchPage = searchPropertiesSections.current.find(o => o.id === "dnd5e");
                                if (searchPage) {
                                    setSearchPropertiesSection(searchPage);
                                    setPage(CompendiumPage.Spells);
                                    setIsSearchExpanded(true);

                                    setSpellFilter({
                                        characterClass: levels.classData.originalClass,
                                        characterSubclass: levels.classData.subclass,
                                        level: 0,
                                        levelMode: undefined,
                                    });
                                }
                            }}>
                            Find more cantrips…
                        </Link>
                    </MotionBox>
                )}
                {(knownSpells?.length ?? 0) > 0 && (
                    <MotionBox
                        key="knownSpells"
                        layout="position"
                        mb={1}
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        justifyContent="flex-start">
                        {`${(knownSpells?.length ?? 0) - (knownCantripCount ?? 0)}${
                            maxKnownSpells != null && (knownSpells?.length ?? 0) < maxKnownSpells
                                ? ` of ${maxKnownSpells}`
                                : ""
                        } spells known${
                            levels.maxSpellsPrepared != null
                                ? ` (${levels.currentSpellsPrepared ?? 0} of ${levels.maxSpellsPrepared ?? 0} prepared)`
                                : ""
                        }`}
                    </MotionBox>
                )}
            </AnimatePresence>

            <SpellList
                spells={knownSpells ?? []}
                dc={getSpellDc(resolvedCharacter, levels)}
                highlightSpells={levels.preparedSpells}
                placeholder={`${resolvedCharacter.name} does not know any ${levels.classData.name} spells`}
                afterDetails={spell => {
                    // If all spells are always known, then you shouldn't be able to remove
                    // them - except for cantrips, they're always chosen so you must be able
                    // to remove them.
                    if (levels.classData.spellsKnownProgression === "all" && spell.level !== 0) {
                        return undefined;
                    }

                    const oldClassInfo = character.classes![classKey];
                    const i = oldClassInfo.knownSpells
                        ? oldClassInfo.knownSpells.findIndex(o => o.name === spell.name && o.source === spell.source)
                        : -1;
                    if (i < 0) {
                        return undefined;
                    }

                    return (
                        <Button
                            mt={2}
                            variant="danger"
                            onClick={() => {
                                const newSpells = oldClassInfo.knownSpells ? oldClassInfo.knownSpells.slice() : [];
                                newSpells.splice(i, 1);

                                const newClassInfo = copyState(oldClassInfo, {
                                    knownSpells: newSpells,
                                });
                                const newClasses = copyState(character.classes, {
                                    [classKey]: newClassInfo,
                                });
                                dispatch(modifyCharacter(campaign, location, [token], { classes: newClasses }));
                            }}>
                            Remove
                        </Button>
                    );
                }}
                actions={spell => {
                    const maxPreparedSpells = levels.maxSpellsPrepared;
                    const preparedSpells = levels.preparedSpells;

                    const preparedSpell = preparedSpells?.find(o => o.name === spell.name && o.source === spell.source);
                    const canPrepare = !preparedSpell && (levels.currentSpellsPrepared ?? 0) < (maxPreparedSpells ?? 0);
                    const canUnprepare =
                        preparedSpell != null &&
                        spell.level !== 0 &&
                        !(preparedSpell as unknown as AdditionalSpell).prepared;

                    const actions: IMenuItem[] = canPrepare
                        ? [
                              {
                                  id: "prepare",
                                  label: "Prepare",
                                  onClick: () => {
                                      const oldClassInfo = character.classes![classKey];
                                      const newSpells = oldClassInfo.preparedSpells
                                          ? oldClassInfo.preparedSpells.slice()
                                          : [];
                                      newSpells.push({
                                          name: spell.name,
                                          source: spell.source,
                                      });

                                      const newClassInfo = copyState(oldClassInfo, { preparedSpells: newSpells });
                                      const newClasses = copyState(character.classes, {
                                          [classKey]: newClassInfo,
                                      });
                                      dispatch(modifyCharacter(campaign, location, [token], { classes: newClasses }));
                                  },
                              },
                          ]
                        : canUnprepare
                        ? [
                              {
                                  id: "prepare",
                                  label: "Unprepare",
                                  toggled: true,
                                  onClick: () => {
                                      const oldClassInfo = character.classes![classKey];
                                      const newSpells = oldClassInfo.preparedSpells
                                          ? oldClassInfo.preparedSpells.slice()
                                          : [];
                                      newSpells.splice(
                                          newSpells.findIndex(o => o.name === spell.name && o.source === spell.source),
                                          1
                                      );

                                      const newClassInfo = copyState(oldClassInfo, { preparedSpells: newSpells });
                                      const newClasses = copyState(character.classes, {
                                          [classKey]: newClassInfo,
                                      });
                                      dispatch(modifyCharacter(campaign, location, [token], { classes: newClasses }));
                                  },
                              },
                          ]
                        : [];

                    return actions;
                }}
            />
        </MotionBox>
    );
};

export const ClassPage: FunctionComponent<{
    token: DnD5ECharacterTemplate | DnD5ECharacterToken;
    resolvedCharacter: ResolvedCharacter;
}> = ({ token, resolvedCharacter }) => {
    const rules = useRules();
    const [isAddingClass, setIsAddingClass] = useState(false);
    const dispatch = useDispatch();
    const { system, campaign, location } = useLocation();

    const { searchPropertiesSection, searchPropertiesSections, setSearchPropertiesSection } = useAppState();
    const isSearchExpanded = useVttApp(state => state.isSearchExpanded);
    const setIsSearchExpanded = useVttApp(state => state.setIsSearchExpanded);
    const { page, setPage, spellFilter, setSpellFilter } = useCompendium();

    const character = token.dnd5e;

    const [pendingDelete, setPendingDelete] = useState<ResolvedClassLevels>();
    const lastPendingDelete = useRef<ResolvedClassLevels>();
    if (pendingDelete != null) {
        lastPendingDelete.current = pendingDelete;
    }

    const allClasses = Object.values(resolvedCharacter.classes);
    return (
        <Box fullWidth fullHeight>
            <AnimatePresence>
                {(!allClasses.length || isAddingClass) && (
                    <ClassList
                        resolvedCharacter={resolvedCharacter}
                        onSelected={o => {
                            const isInitial = allClasses.length === 0;
                            const newClasses = copyState(character.classes ?? {}, {
                                [o.name]: {
                                    class: { name: o.name, source: o.source },
                                    level: 1,
                                    isInitial: isInitial,
                                    hpPerLevel: isInitial ? { "1": diceTypeToMax(o.hitDie) } : {},
                                    choices: {},
                                },
                            });

                            dispatch(
                                modifyCharacter(campaign, location, [token], {
                                    classes: newClasses,
                                })
                            );
                            setIsAddingClass(false);
                        }}
                    />
                )}
                {!!allClasses.length && !isAddingClass && (
                    <ScrollableTest fullWidth fullHeight minimal pb={3} px={3}>
                        {allClasses.map((o, i) => {
                            let classList = resolveClassSpellList(o.classData, rules);

                            // Need to add in additional spells that add the spell to the known list.
                            if (o.additionalSpells) {
                                const classListByKey: { [id: string]: Spell } = {};
                                if (classList) {
                                    for (let spell of classList) {
                                        classListByKey[getRuleKey(spell)] = spell;
                                    }
                                }

                                for (let additionalSpells of o.additionalSpells) {
                                    for (let additionalSpell of additionalSpells.spells) {
                                        if (!classList) {
                                            classList = [];
                                        }

                                        if (!classListByKey[getRuleKey(additionalSpell)]) {
                                            classList?.push(additionalSpell);
                                            classListByKey[getRuleKey(additionalSpell)] = additionalSpell;
                                        }
                                    }
                                }
                            }

                            const spellList = classList ? filterClassSpellList(o, classList, true) : undefined;

                            const knownSpells =
                                o.classData.spellsKnownProgression === "all"
                                    ? o.knownSpells && spellList
                                        ? [...o.knownSpells, ...spellList]
                                        : o.knownSpells ?? spellList
                                    : o.knownSpells;

                            const maxKnownSpells = Array.isArray(o.classData.spellsKnownProgression)
                                ? o.classData.spellsKnownProgression[o.level - 1]
                                : undefined;

                            const toolsStr = itemProficienciesToString(
                                o.classData.toolProficiencies,
                                o.classData.toolProficiencyChoice,
                                rules
                            );

                            const classKey = o.classData.originalClass.name;
                            return (
                                <MotionBox
                                    layout
                                    key={getRuleKey(o.classData)}
                                    fullWidth
                                    flexDirection="column"
                                    alignItems="stretch"
                                    mt={i > 0 ? 3 : 0}>
                                    <MotionBox
                                        fullWidth
                                        layout
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        mb={2}
                                        pb={1}
                                        borderBottomWidth={4}
                                        borderBottomColor="grayscale.7"
                                        borderBottomStyle="solid">
                                        <MotionBox layout layoutId={getRuleKey(o.classData)}>
                                            <Heading as="h4">{o.classData.name}</Heading>
                                        </MotionBox>
                                        <Box flexDirection="row">
                                            <Text>Level</Text>
                                            <Select
                                                ml={2}
                                                value={o.level}
                                                onChange={e => {
                                                    // Change the level for the specified class to the chosen level.
                                                    const newLevel = parseInt(e.target.value, 10);
                                                    const oldClassInfo = character.classes![classKey];
                                                    const newClassInfo = copyState(oldClassInfo, {
                                                        level: newLevel,
                                                    });
                                                    const newClasses = copyState(character.classes, {
                                                        [classKey]: newClassInfo,
                                                    });
                                                    dispatch(
                                                        modifyCharacter(campaign, location, [token], {
                                                            classes: newClasses,
                                                        })
                                                    );
                                                }}>
                                                {levels.map(o => (
                                                    <option key={o} value={o}>
                                                        {o}
                                                    </option>
                                                ))}
                                            </Select>
                                            <Button
                                                ml={2}
                                                variant="tertiary"
                                                size="s"
                                                onClick={() => setPendingDelete(o)}>
                                                <CloseIcon size={8 as any} />
                                            </Button>
                                        </Box>
                                    </MotionBox>

                                    <TabBox>
                                        <Tab
                                            id="features"
                                            label={
                                                <React.Fragment>
                                                    Class features
                                                    {/* TODO: Show a badge if there are choices that need to be made for any class feature.
                                <MotionBadge
                                    layout="position"
                                    initial={defaultInitial}
                                    animate={defaultAnimate}
                                    exit={defaultExit}
                                    ml={2}
                                    
                                    bg="guidance.info.1"
                                    color="guidance.info.0">
                                    !
                                </MotionBadge> */}
                                                </React.Fragment>
                                            }>
                                            <AnimatePresence>
                                                <AnimatedListItem key="Proficiencies" index={0} fullWidth>
                                                    <FeatureEditor
                                                        isInOverlay
                                                        feature={{
                                                            name: "Proficiencies",
                                                            source: "PHB",
                                                            content: `**Armor:** ${armorProficienciesToString(
                                                                o.classData.armorProficiencies
                                                            )}\n\n**Weapons:** ${weaponProficienciesToString(
                                                                o.classData.weaponProficiencies
                                                            )}${
                                                                toolsStr ? `\n\n**Tools:** ${toolsStr}` : ""
                                                            }\n\n**Saving throws:** ${coreAbilitiesToString(
                                                                o.classData.savingThrowProficiencies
                                                            )}\n\n**Skills:** ${skillProficienciesToString(
                                                                o.isInitial
                                                                    ? o.classData.skillProficiencies
                                                                    : o.classData.multiclassing.skillProficiencies,
                                                                o.isInitial
                                                                    ? o.classData.skillProficiencyChoice
                                                                    : o.classData.multiclassing.skillProficiencyChoice
                                                            )}`,
                                                            skillProficiencyChoice: o.isInitial
                                                                ? o.classData.skillProficiencyChoice
                                                                : o.classData.multiclassing.skillProficiencyChoice,
                                                            toolChoice: o.isInitial
                                                                ? o.classData.toolProficiencyChoice
                                                                : o.classData.multiclassing.toolProficiencyChoice,
                                                        }}
                                                        selections={{
                                                            skillProficiencies: o.skillProficiencies,
                                                            tools: o.tools,
                                                        }}
                                                        onSelectionsChanged={s => {
                                                            const oldClassInfo = character.classes![classKey];
                                                            const newClassInfo = copyState(oldClassInfo, {
                                                                skillProficiencies: s.skillProficiencies,
                                                                tools: s.tools,
                                                            });
                                                            const newClasses = copyState(character.classes, {
                                                                [classKey]: newClassInfo,
                                                            });
                                                            dispatch(
                                                                modifyCharacter(campaign, location, [token], {
                                                                    classes: newClasses,
                                                                })
                                                            );
                                                        }}
                                                        subtitle="Level 1"
                                                        mt={2}
                                                        character={resolvedCharacter}
                                                    />
                                                </AnimatedListItem>
                                                {o.classData.features.map((f, fi) => {
                                                    if (f.level == null || f.level > o.level) {
                                                        return undefined;
                                                    }

                                                    return (
                                                        <AnimatedListItem key={fi} index={fi + 1} fullWidth>
                                                            <FeatureEditor
                                                                isInOverlay
                                                                mt={2}
                                                                classData={o.classData}
                                                                feature={f}
                                                                character={resolvedCharacter}
                                                                subtitle={`Level ${f.level}`}
                                                                selections={
                                                                    o.choices[f.level!]
                                                                        ? o.choices[f.level!][getRuleKey(f)]
                                                                        : undefined
                                                                }
                                                                isActionRequired={
                                                                    f.isSubclassFeature &&
                                                                    f.name === o.classData.subclassTitle &&
                                                                    !character.classes![classKey].subclass
                                                                        ? true
                                                                        : undefined
                                                                }
                                                                onSelectionsChanged={s => {
                                                                    const oldClassInfo = character.classes![classKey];
                                                                    const oldChoicesForLevel =
                                                                        oldClassInfo.choices[f.level!] ?? {};
                                                                    const newChoicesForLevel = copyState(
                                                                        oldChoicesForLevel,
                                                                        { [getRuleKey(f)]: s }
                                                                    );
                                                                    const newChoicesForClass = copyState(
                                                                        oldClassInfo.choices,
                                                                        { [f.level!]: newChoicesForLevel }
                                                                    );

                                                                    const newClassInfo = copyState(oldClassInfo, {
                                                                        choices: newChoicesForClass,
                                                                    });
                                                                    const newClasses = copyState(character.classes, {
                                                                        [classKey]: newClassInfo,
                                                                    });
                                                                    dispatch(
                                                                        modifyCharacter(campaign, location, [token], {
                                                                            classes: newClasses,
                                                                        })
                                                                    );
                                                                }}>
                                                                {f.isSubclassFeature &&
                                                                    f.name === o.classData.subclassTitle && (
                                                                        <ChoiceEditor
                                                                            choice={{
                                                                                count: 1,
                                                                                from: Object.values(
                                                                                    o.classData.subclasses
                                                                                ).map(o =>
                                                                                    JSON.stringify({
                                                                                        name: o.name,
                                                                                        source: o.source,
                                                                                    })
                                                                                ),
                                                                            }}
                                                                            selections={[
                                                                                JSON.stringify(
                                                                                    character.classes![classKey]
                                                                                        .subclass
                                                                                ),
                                                                            ]}
                                                                            hint={`Choose a ${o.classData.subclassTitle}`}
                                                                            optionToLabel={o => {
                                                                                const subclass = JSON.parse(
                                                                                    o
                                                                                ) as NamedRuleRef;
                                                                                return subclass.name;
                                                                            }}
                                                                            onChoiceMade={s => {
                                                                                const subclass = s
                                                                                    ? (JSON.parse(s) as NamedRuleRef)
                                                                                    : undefined;
                                                                                const oldClassInfo =
                                                                                    character.classes![classKey];
                                                                                const newClassInfo = copyState(
                                                                                    oldClassInfo,
                                                                                    { subclass: subclass }
                                                                                );
                                                                                const newClasses = copyState(
                                                                                    character.classes,
                                                                                    {
                                                                                        [classKey]: newClassInfo,
                                                                                    }
                                                                                );
                                                                                dispatch(
                                                                                    modifyCharacter(
                                                                                        campaign,
                                                                                        location,
                                                                                        [token],
                                                                                        { classes: newClasses }
                                                                                    )
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                            </FeatureEditor>
                                                        </AnimatedListItem>
                                                    );
                                                })}
                                                <BasicExpander title="Available at higher levels" mt={2}>
                                                    {o.classData.features.map((f, fi) => {
                                                        if (f.level != null && f.level <= o.level) {
                                                            return undefined;
                                                        }

                                                        return (
                                                            <AnimatedListItem key={fi} index={fi} fullWidth>
                                                                <FeatureEditor
                                                                    isInOverlay
                                                                    mt={2}
                                                                    feature={f}
                                                                    character={resolvedCharacter}
                                                                    classData={o.classData}
                                                                    subtitle={`Level ${f.level}`}
                                                                />
                                                            </AnimatedListItem>
                                                        );
                                                    })}
                                                </BasicExpander>
                                            </AnimatePresence>
                                        </Tab>
                                        {(!!o.classData.spellcastingProgression ||
                                            o.maxSpellsPrepared != null ||
                                            o.classData.spellsKnownProgression != null) && (
                                            <Tab id="spells" label="Spells">
                                                {!!o.classData.spellcastingProgression != null && (
                                                    <ClassSpellList
                                                        levels={o}
                                                        resolvedCharacter={resolvedCharacter}
                                                        token={token}
                                                        knownSpells={knownSpells}
                                                    />
                                                )}
                                                {o.classData.spellsKnownProgression !== "all" &&
                                                    (maxKnownSpells == null ||
                                                        (o.currentSpellsKnown ?? 0) < maxKnownSpells) && (
                                                        <Link
                                                            mt={(knownSpells?.length ?? 0) > 0 ? 3 : 0}
                                                            disabled={
                                                                isSearchExpanded &&
                                                                page === CompendiumPage.Spells &&
                                                                searchPropertiesSection.id === "dnd5e" &&
                                                                spellFilter?.characterClass ===
                                                                    o.classData.originalClass &&
                                                                spellFilter?.characterSubclass ===
                                                                    o.classData.subclass &&
                                                                spellFilter.level === getMaxSpellLevel(o) &&
                                                                spellFilter.levelMode === "max"
                                                            }
                                                            css={{
                                                                alignSelf: "flex-start",
                                                            }}
                                                            onClick={() => {
                                                                const searchPage =
                                                                    searchPropertiesSections.current.find(
                                                                        o => o.id === "dnd5e"
                                                                    );
                                                                if (searchPage) {
                                                                    setSearchPropertiesSection(searchPage);
                                                                    setPage(CompendiumPage.Spells);
                                                                    setIsSearchExpanded(true);

                                                                    setSpellFilter({
                                                                        characterClass: o.classData.originalClass,
                                                                        characterSubclass: o.classData.subclass,
                                                                        level: getMaxSpellLevel(o),
                                                                        levelMode: "max",
                                                                    });
                                                                }
                                                            }}>
                                                            Find more spells…
                                                        </Link>
                                                    )}
                                            </Tab>
                                        )}
                                        <Tab id="hp" label="Hit Points">
                                            <Form fullWidth>
                                                <Text mt={2}>
                                                    Every level you gain 1{o.classData.hitDie} hit points.
                                                </Text>
                                                <AnimatedList>
                                                    {levels.slice(0, o.level).map(level => (
                                                        <AnimatedListItem key={level} index={level - 1} fullWidth>
                                                            <HitPointBox
                                                                level={level}
                                                                character={character}
                                                                token={token}
                                                                data={o}
                                                                classKey={classKey}
                                                            />
                                                        </AnimatedListItem>
                                                    ))}
                                                </AnimatedList>
                                            </Form>
                                        </Tab>
                                    </TabBox>
                                </MotionBox>
                            );
                        })}
                        <MotionBox layout>
                            <Button mt={3} onClick={() => setIsAddingClass(true)}>
                                Add another class
                            </Button>
                        </MotionBox>
                    </ScrollableTest>
                )}
            </AnimatePresence>
            <ModalDialog
                onRequestClose={() => {
                    setPendingDelete(undefined);
                }}
                isOpen={pendingDelete != null}
                style={{
                    content: {
                        width: theme.space[12],
                    },
                }}>
                <FocusTrap focusTrapOptions={{ initialFocus: "#modalDefault", allowOutsideClick: true }}>
                    <Box flexDirection="column" p={3} maxWidth={theme.space[13]} alignItems="flex-start">
                        <Heading
                            as="h3"
                            mb={3}
                            css={{ textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden" }}>
                            Remove {lastPendingDelete.current?.classData.name}?
                        </Heading>
                        <Text>
                            Are you sure you want to remove all levels of {lastPendingDelete.current?.classData.name}{" "}
                            from {system.getDisplayName(token, campaign)}?
                        </Text>
                        <LobotomizedBox justifyContent="flex-end" mt={3} fullWidth>
                            <Button
                                disabled={pendingDelete == null}
                                id="modalDefault"
                                variant="primary"
                                onClick={async () => {
                                    if (pendingDelete) {
                                        const classKey = pendingDelete.classData.originalClass.name;
                                        const newClasses = Object.assign({}, character.classes);
                                        delete newClasses[classKey];
                                        dispatch(
                                            modifyCharacter(campaign, location, [token], {
                                                classes: newClasses,
                                            })
                                        );
                                        setPendingDelete(undefined);
                                    }
                                }}>
                                Remove
                            </Button>
                            <Button
                                disabled={pendingDelete == null}
                                variant="secondary"
                                onClick={() => setPendingDelete(undefined)}>
                                Keep
                            </Button>
                        </LobotomizedBox>
                    </Box>
                </FocusTrap>
            </ModalDialog>{" "}
        </Box>
    );
};

import React from 'react'
  
  const RectToolIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 1.5,0.75h3c0.414,0,0.75,0.336,0.75,0.75v3c0,0.414-0.336,0.75-0.75,0.75h-3c-0.414,0-0.75-0.336-0.75-0.75 v-3C0.75,1.086,1.086,0.75,1.5,0.75z  M 1.5,18.75h3c0.414,0,0.75,0.336,0.75,0.75v3c0,0.414-0.336,0.75-0.75,0.75h-3 c-0.414,0-0.75-0.336-0.75-0.75v-3C0.75,19.086,1.086,18.75,1.5,18.75z  M 19.5,18.75h3c0.414,0,0.75,0.336,0.75,0.75v3 c0,0.414-0.336,0.75-0.75,0.75h-3c-0.414,0-0.75-0.336-0.75-0.75v-3C18.75,19.086,19.086,18.75,19.5,18.75z  M 19.5,0.75h3 c0.414,0,0.75,0.336,0.75,0.75v3c0,0.414-0.336,0.75-0.75,0.75h-3c-0.414,0-0.75-0.336-0.75-0.75v-3 C18.75,1.086,19.086,0.75,19.5,0.75z  M 5.25,2.25h13.5  M 2.25,5.25v13.5  M 21.75,5.25v13.5  M 5.25,21.75h13.5' />
    </svg>
  )
  
  RectToolIcon.displayName = 'RectToolIcon'
  
  RectToolIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default RectToolIcon
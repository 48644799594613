import { localPoint } from "../../../grid";
import { createLightShader, defaultLightTags, fract, LightShaderTemplate } from "./common";

const torchLightShaderText = createLightShader(
    `
    float distToP = distance(localFragCoord, u_position);
    float flicker = fract(sin((u_time + u_timeOffset) * 2.0) + sin((u_time + u_timeOffset) * 4.0));
    float near = u_near * ((flicker * 0.02) + 0.99);
    float far = u_far * ((flicker * 0.02) + 0.99);
    float brightness = u_brightness * ((flicker * 0.02) + 0.99);
    gl_FragColor = vec4(u_color, (1.0 - smoothstep(near / far, 1.0, distToP / far)) * brightness);
`,
    `
uniform float u_time;
uniform float u_timeOffset;
`
);

export const torchLightShader: LightShaderTemplate = {
    id: "torch",
    label: "Torchlight",
    tags: [...defaultLightTags, "fire"],
    shader: torchLightShaderText,
    defaults: {
        brightness: 0.75,
        color: "#FAC8B4",
    },
    onInit: material => {
        material.uniforms.u_time = { value: 0 };
        material.uniforms.u_timeOffset = { value: Math.random() * 20 };
    },
    onBeforeRender: ({ context, setPosition, grid, light, material, pointLight }) => {
        // Simulate firelight flickering by moving the light source each frame, using the same formula that
        // the shader uses to flicker the brightness and reach.
        let pos = grid.toLocalCenterPoint(light.pos);
        const time = context.clock.elapsedTime;
        const timeOffset = material.uniforms.u_timeOffset.value;
        const flickerX = fract(Math.sin((time + timeOffset) * 2.0) + Math.sin((time + timeOffset) * 4.0));
        const flickerY = fract(Math.sin((time + timeOffset) * 5.0) + Math.sin((time + timeOffset) * 1.0));

        if (pointLight) {
            const far = material.uniforms.u_far.value * (flickerX * 0.02 + 0.99);
            const brightness = material.uniforms.u_brightness.value * (flickerX * 0.02 + 0.99);
            pointLight.distance = far;
            pointLight.intensity = brightness;
        }

        pos = localPoint(pos.x + flickerX * 2, pos.y + flickerY * 2);
        setPosition(pos);

        material.uniforms.u_time.value = time;
    },
    sound: { uri: "/fire-small.mp3", name: "Torch", radius: 3, volume: 20 },
};

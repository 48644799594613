import { Campaign, isLocation, Location, LocationSummary, resolveToken, Token, Zone } from "../store";
import { Annotation } from "../annotations";

export enum SelectionType {
    None = 0,
    Primary = 1,
    Secondary = 2,
}

export function getSelectedItems(selection: string[], location: Location): (Annotation | Token | Zone)[] {
    return selection.map(o => getItem(o, location));
}

export function getSelectedItem(selection: string[], location: Location): Annotation | Token | Zone | undefined {
    if (selection.length >= 1) {
        return getItem(selection[0], location);
    }
}

export function getLastSelectedToken(selection: string[], location: Location): Token | undefined {
    for (let i = selection.length - 1; i >= 0; i--) {
        const token = location.tokens[selection[i]];
        if (token) {
            return token;
        }
    }

    return undefined;
}

export function getSelectedTokens(selection: string[], location: Location, secondary?: string[]): Token[] {
    const tokens: Token[] = [];
    for (let i = 0; i < selection.length; i++) {
        const token = location.tokens[selection[i]];
        if (token) {
            tokens.push(token);
        }
    }

    if (secondary) {
        for (let i = 0; i < secondary.length; i++) {
            const token = location.tokens[secondary[i]];
            if (token) {
                tokens.push(token);
            }
        }
    }

    return tokens;
}

export function getSelectedAnnotations(selection: string[], location: Location): Annotation[] {
    const annotations: Annotation[] = [];
    for (let i = 0; i < selection.length; i++) {
        const annotation = location.annotations[selection[i]];
        if (annotation) {
            annotations.push(annotation);
        }
    }

    return annotations;
}

export function getSelectedZones(selection: string[], location: Location): Zone[] {
    const zones: Zone[] = [];
    for (let i = 0; i < selection.length; i++) {
        const zone = location.zones[selection[i]];
        if (zone) {
            zones.push(zone);
        }
    }

    return zones;
}

export interface SelectionByType {
    all: (Token | Annotation | Zone)[];
    tokens: Token[];
    annotations: Annotation[];
    zones: Zone[];
}

export function getSelectionByType(
    selection: string[],
    campaign: Campaign,
    location: Location | LocationSummary | undefined
): SelectionByType {
    const all: (Token | Annotation | Zone)[] = [];
    const tokens: Token[] = [];
    const annotations: Annotation[] = [];
    const zones: Zone[] = [];

    if (isLocation(location)) {
        for (let i = 0; i < selection.length; i++) {
            const token = location.tokens[selection[i]];
            if (token) {
                const resolvedToken = resolveToken(campaign, token);
                tokens.push(resolvedToken);
                all.push(resolvedToken);
            } else {
                const annotation = location.annotations[selection[i]];
                if (annotation) {
                    annotations.push(annotation);
                    all.push(annotation);
                } else {
                    const zone = location.zones[selection[i]];
                    if (zone) {
                        zones.push(zone);
                        all.push(zone);
                    }
                }
            }
        }
    }

    return {
        all: all,
        tokens: tokens,
        annotations: annotations,
        zones: zones,
    };
}

function getItem(id: string, location: Location): Annotation | Token | Zone {
    let item: Token | Annotation | Zone | undefined = location.tokens[id];
    if (!item) {
        item = location.annotations[id];
    }

    if (!item) {
        item = location.zones[id];
    }

    return item;
}

import { nanoid } from "nanoid";
import { Dispatch } from "redux";
import { addLocation } from "./actions/campaign";
import { Annotation, LineAnnotation } from "./annotations";
import { localPoint } from "./grid";
import { UserArtLibrary } from "./library";
import { Point, PositionType, Size } from "./position";
import { Campaign, ErrorHandler, ImageType, Location, Token, UserInfo } from "./store";

export interface UniversalVtt {
    software?: string;
    creator?: string;
    format: number;
    resolution: {
        /**
         * The top left of the map, in grid squares.
         */
        map_origin: Point;

        /**
         * The size of the map, in grid squares.
         */
        map_size: Point;

        /**
         * The size of a single grid square in pixels.
         */
        pixels_per_grid: number;
    };

    /**
     * Array of shapes that affect line of sight.
     */
    line_of_sight: Point[][];

    /**
     * Array of object shapes that affect line of sight.
     */
    objects_line_of_sight: Point[][];

    /**
     * Array of doors?
     */
    portals: {
        position: Point;
        bounds: Point[]; // TODO: Not clear on what this should actually be, but this makes sense.

        /**
         * Rotation of the shape in radians.
         */
        rotation?: number;
        closed?: boolean;
        freestanding?: boolean;
    }[];

    environment: {
        /**
         * Is lighting baked into this image?
         */
        baked_lighting?: boolean;

        /**
         * Colour code hex for ambient lighting to apply over the entire scene.
         */
        ambient_light?: string;
    };

    lights: {
        position: Point;
        range: number;
        intensity: number;
        color: string;
        shadows?: boolean;
    }[];

    /**
     * Base64 encoded PNG or WEBP.
     */
    image: string;
}

function convertColor(color: string | undefined): string {
    if (!color || color.length < 6) {
        return "#FFFFFF";
    }

    let finalColor =
        "#" + (color.length >= 8 && color.startsWith("ff") ? color.substring(2, 8) : color.substring(0, 6));
    return finalColor;
}

export function isUniversalVttFile(file: File) {
    return file.name && file.name.trimRight().toLowerCase().endsWith(".uvtt");
}

export async function tryImportUniversalVtt(
    user: UserInfo,
    campaign: Campaign,
    file: File,
    dispatch: Dispatch,
    errorHandler: ErrorHandler
): Promise<Location | undefined> {
    if (!isUniversalVttFile(file)) {
        return undefined;
    }

    const universalVtt = JSON.parse(await file.text()) as UniversalVtt;

    // TODO: Some error handling here?
    const imageUri = `data:application/octet-stream;base64,${universalVtt.image}`;
    const imageBlob = await (await fetch(imageUri)).blob();
    const imageItem = await (
        await UserArtLibrary.current.uploadFile(
            new File([imageBlob], file.name, { type: "image" }),
            errorHandler,
            ImageType.Background
        )
    ).libraryItem;

    var tileSize: Size = {
        width: universalVtt.resolution.pixels_per_grid,
        height: universalVtt.resolution.pixels_per_grid,
    };

    var annotations: { [id: string]: Annotation } = {};
    var tokens: { [id: string]: Token } = {};

    universalVtt.line_of_sight?.forEach(o => {
        const first = o[0];
        const wall: LineAnnotation = {
            id: nanoid(),
            userId: user.id,
            type: "line",
            pos: { ...localPoint(first.x * tileSize.width, first.y * tileSize.height), level: "0" },
            points: o.map(p => ({ x: (p.x - first.x) * tileSize.width, y: (p.y - first.y) * tileSize.height })),
            subtype: "wall",
            buildOnly: true,
            obstructsLight: true,
            obstructsMovement: true,
        };
        annotations[wall.id] = wall;
    });

    universalVtt.objects_line_of_sight?.forEach(o => {
        const first = o[0];
        const object: LineAnnotation = {
            id: nanoid(),
            userId: user.id,
            type: "line",
            pos: { ...localPoint(first.x * tileSize.width, first.y * tileSize.height), level: "0" },
            points: o.map(p => ({ x: (p.x - first.x) * tileSize.width, y: (p.y - first.y) * tileSize.height })),
            buildOnly: true,
            obstructsLight: true,
        };
        annotations[object.id] = object;
    });

    universalVtt.lights.forEach(o => {
        var lightColor = convertColor(o.color);
        let light: Token = {
            id: nanoid(),
            type: "light",
            pos: { ...localPoint(o.position.x * tileSize.width, o.position.y * tileSize.height), level: "0" },
            light: {
                type: "default",
                brightness: o.intensity != null ? o.intensity / 100 : 1,
                color: lightColor,
                innerRadius: o.range / 2,
                outerRadius: o.range,
            },
        };
        tokens[light.id] = light;
    });

    let label = file.name;
    if (label.toLowerCase().endsWith(".uvtt")) {
        label = label.substring(0, label.length - 5);
    }

    let location: Location = {
        id: nanoid(),
        label: label,
        tileSize: tileSize,
        levels: {
            "0": {
                backgroundImageUrl: imageItem.uri,
                backgroundImagePos: {
                    type: PositionType.LocalPixel,
                    x: universalVtt.resolution.map_origin.x,
                    y: universalVtt.resolution.map_origin.y,
                },
            },
        },
        defaultLevel: "0",
        thumbnailUri: imageItem.metadata.thumbnailUri,
        annotations: annotations,
        tokens: tokens,
        zones: {},
    };

    dispatch(addLocation(campaign.id, location, user));
    return location;
}

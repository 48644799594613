import { createTokensOrTokenTemplatesAction, TokensAction, TokenTemplatesAction } from "../../../actions/common";
import { Token, Campaign, LocationSummary, TokenTemplate } from "../../../store";

export function healTokens(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    amount: number
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_Heal", amount, campaign, location, tokens);
}

export function damageTokens(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    amount: number | { [tokenId: string]: number }
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_Damage", amount, campaignId, location, tokens);
}

export function setTokensTempHp(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    amount: number | undefined
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_SetTempHP", amount, campaignId, location, tokens);
}

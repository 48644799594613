/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import { FunctionComponent } from "react";
import { Box, Text } from "./primitives";
import { DiceRoll, DiceRollLogEntry, getToken } from "../store";
import { useCampaign } from "./contexts";

export const DiceRollResultContent: FunctionComponent<{ roll: DiceRoll; compact?: boolean }> = ({ roll, compact }) => {
    return (
        <React.Fragment>
            {!compact && (
                <React.Fragment>
                    <Text fontWeight="bold">{roll.expression}</Text>
                    <Box alignItems="flex-start" flexDirection="row">
                        <Text mr={3} mt={2} fontSize={5}>
                            {roll.result}
                        </Text>
                        {/* TODO: Couldn't get flexWrap property to work, putting it in css does though :( */}
                        <Box flexDirection="row" justifyContent="flex-start" css={{ flexWrap: "wrap", flexShrink: 1 }}>
                            {roll.terms.map((t, j) => {
                                return (
                                    <Box
                                        minWidth="5rem"
                                        minHeight="5rem"
                                        key={j}
                                        css={{ opacity: t.isExcluded ? 0.3 : 1 }}
                                        bg="grayscale.6"
                                        borderRadius={3}
                                        mt={2}
                                        mr={2}>
                                        {t.result}
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </React.Fragment>
            )}
            {compact && (
                <Box flexDirection="row">
                    <Text fontSize={5}>{roll.result} </Text>
                    <Text ml={2} fontSize={1}>
                        ({roll.expression})
                    </Text>
                </Box>
            )}
        </React.Fragment>
    );
};

export const DiceRollResult: FunctionComponent<{ roll: DiceRoll; compact?: boolean }> = props => {
    return (
        <Box flexDirection="column" alignItems="start" fullWidth>
            <DiceRollResultContent {...props} />
        </Box>
    );
};

export const DiceRollLogResult: FunctionComponent<{
    roll: DiceRollLogEntry;
    compact?: boolean;
}> = ({ roll, compact }) => {
    const { system, campaign } = useCampaign();
    return (
        <Box flexDirection="column" alignItems="start" fullWidth>
            {roll.data &&
                system.renderLogHeader &&
                system.renderLogHeader(roll, getToken(campaign, roll.location, roll.token))}
            <DiceRollResultContent roll={roll} compact={compact} />
        </Box>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useState, useCallback, useRef, useLayoutEffect } from "react";
import { Box, Text, Card, Grid } from "../primitives";
import { Button } from "../Button";

import { useSessionConnection } from "../contexts";
import { Session, MessageLogEntry, Profile, LogEntry, CampaignPlayer, DiceRollLogEntry, getToken } from "../../store";

import ProfileAvatar from "../ProfileAvatar";
import { useProfiles, useErrorHandler } from "../utils";
import { CommandInput } from "./CommandInput";
import { DiceRollLogResult } from "../DiceRollResult";
import { Markdown, MarkdownEditorHandle } from "../markdown";
import { ScrollableTest } from "../ScrollableTest";

const timeFormatter = new Intl.DateTimeFormat(undefined, {
    hour: "numeric",
    minute: "numeric",
});
const dateFormatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
});

const LogEntryGroup: FunctionComponent<{
    profile: Profile | undefined;
    campaignProfile: CampaignPlayer | undefined;
    entries: LogEntry[];
}> = ({ profile, entries, campaignProfile }) => {
    const { system, session } = useSessionConnection();
    const campaign = session.campaign;

    let timeString: string;
    const time = new Date(entries[0].time);
    const now = new Date(Date.now());
    if (
        time.getDay() === now.getDay() &&
        time.getMonth() === now.getMonth() &&
        time.getFullYear() === now.getFullYear()
    ) {
        // This message is from today.
        timeString = `Today at ${timeFormatter.format(time)}`;
    } else {
        timeString = `${dateFormatter.format(time)} at ${timeFormatter.format(time)}`;
    }

    return (
        <Box
            flexDirection="row"
            alignItems="start"
            alignSelf="stretch"
            py={3}
            mx={3}
            borderTop="1px solid"
            borderColor="grayscale.6">
            <ProfileAvatar profile={profile} campaignProfile={campaignProfile} mr={2} />
            <Box flex={1} flexDirection="column" alignItems="start">
                <Box>
                    {profile && <Markdown>{`:user[${profile.name}]{id="${profile.userId}"}`}</Markdown>}
                    <Text ml={1} fontSize={0} css={{ opacity: 0.5 }}>
                        {timeString}
                    </Text>
                </Box>
                {entries.map((o, i) => {
                    if (!o.type || o.type === "message") {
                        return (
                            <React.Fragment key={i}>
                                {o.data &&
                                    system.renderLogHeader &&
                                    system.renderLogHeader(o, getToken(campaign, o.location, o.token))}
                                <Markdown>{(o as MessageLogEntry).message}</Markdown>
                            </React.Fragment>
                        );
                    } else if (o.type === "roll") {
                        return (
                            <Card
                                key={i}
                                fullWidth
                                p={3}
                                my={3}
                                borderRadius={4}
                                bg="grayscale.8"
                                css={{ textShadow: "none" }}>
                                <DiceRollLogResult roll={o as DiceRollLogEntry} />
                            </Card>
                        );
                    }

                    return <React.Fragment></React.Fragment>;
                })}
            </Box>
        </Box>
    );
};

const Log: FunctionComponent<{}> = React.memo(() => {
    const connection = useSessionConnection();
    const session = connection.session as Session;
    const { handleError } = useErrorHandler();
    const api = connection.api;
    const bottomRef = useRef<HTMLDivElement>(null);
    const isAtBottomRef = useRef<boolean>(true);
    const scrollableRef = useRef<HTMLDivElement>(null);
    const [isLoadingSessions, setIsLoadingSessions] = useState(false);

    // Catch errors from api calls.
    const handleApiCall = useCallback(
        async (promise: Promise<any>, message: string) => {
            try {
                await promise;
            } catch (e) {
                handleError(e, message);
            }
        },
        [handleError]
    );

    // Check if we're scrolled right down to the bottom (or near enough).
    // If we're scrolled up, then we don't want to scroll to the bottom on render (they're probably trying to look
    // at older messages, and scrolling them down to the bottom is going to be annoying).
    // TODO: Show a message to make sure they know they're scrolled up, like discord.
    if (bottomRef.current != null && scrollableRef.current != null) {
        const scrollable = scrollableRef.current.firstElementChild as HTMLDivElement;
        isAtBottomRef.current = scrollable.scrollHeight - scrollable.scrollTop - scrollable.clientHeight < 8;
    }

    // Scroll to the bottom of the log whenever we render.
    useLayoutEffect(() => {
        if (bottomRef.current && isAtBottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: "smooth" });
            isAtBottomRef.current = true;
        }
    });

    // Group the log entries so that consecutive entries from the same user are grouped together.
    const log = Object.values(session.log);
    log.sort((a, b) => a.time - b.time);

    const { profiles } = useProfiles(log.map(o => o.userId));

    const groups: React.ReactElement[] = [];
    let currentUser: string | undefined;
    let currentGroup: LogEntry[] = [];
    for (let i = 0; i < log.length; i++) {
        if (!currentUser || currentUser !== log[i].userId || log[i].time - log[i - 1].time > 60000) {
            if (currentGroup.length) {
                groups.push(
                    <LogEntryGroup
                        key={i}
                        profile={currentUser ? profiles[currentUser] : undefined}
                        campaignProfile={currentUser ? session.campaign.players[currentUser] : undefined}
                        entries={currentGroup}
                    />
                );
            }

            currentGroup = [];
            currentUser = log[i].userId;
        }

        currentGroup.push(log[i]);
    }

    if (currentGroup.length) {
        groups.push(
            <LogEntryGroup
                key={log.length}
                profile={currentUser ? profiles[currentUser] : undefined}
                campaignProfile={currentUser ? session.campaign.players[currentUser] : undefined}
                entries={currentGroup}
            />
        );
    }

    const onExecuteCommand = useCallback<(command: string) => void>(
        command => {
            if (command.startsWith("/")) {
                const cmdEndIndex = command.indexOf(" ", 1);
                let cmd = command.substr(1, cmdEndIndex - 1);
                if (cmd === "roll") {
                    const expr = command.substr(cmdEndIndex + 1);
                    handleApiCall(api.roll(expr), `The expression "${expr}" is not a valid dice roll.`);
                }
            } else {
                handleApiCall(api.sendMessage(command), "Failed to send message.");
            }

            // If we're typing a message ourselves, we must want to be at the bottom to see it, right?
            setTimeout(() => {
                if (bottomRef.current) {
                    bottomRef.current.scrollIntoView({ behavior: "smooth" });
                }
            }, 0);
        },
        [handleApiCall, api]
    );

    const inputRef = useRef<MarkdownEditorHandle>(null);
    return (
        <Grid fullHeight fullWidth gridTemplateColumns="1fr" gridTemplateRows="1fr auto" ref={scrollableRef}>
            <ScrollableTest fullWidth fullHeight minimal>
                {!session.allPreviousSessionsLoaded && (
                    <Button
                        m={3}
                        alignSelf="center"
                        disabled={isLoadingSessions}
                        onClick={() => {
                            setIsLoadingSessions(true);
                            handleApiCall(
                                api.loadOlderSessions(
                                    Math.min(...Object.values(session.previousSessions).map(o => o.endTime))
                                ),
                                "Failed to load older sessions."
                            ).finally(() => setIsLoadingSessions(false));
                        }}>
                        Load more…
                    </Button>
                )}
                {groups}
                <div ref={bottomRef} />
            </ScrollableTest>
            <Box borderTop="1px solid" borderColor="grayscale.6" alignSelf="stretch" mx={3} py={3}>
                <CommandInput
                    ref={inputRef}
                    defaultMarkdown=""
                    onInit={() => {
                        setTimeout(() => {
                            if (bottomRef.current) {
                                bottomRef.current.scrollIntoView({ behavior: "auto" as any, block: "end" });
                                isAtBottomRef.current = true;
                            }

                            inputRef.current?.focus();
                        }, 0);
                    }}
                    onExecuteCommand={onExecuteCommand}
                />
            </Box>
        </Grid>
    );
});

export default Log;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { Box, Heading, InOverlayCard } from "../../../../components/primitives";
import { theme } from "../../../../design";
import { Button } from "../../../../components/Button";
import { Character, Race, ResolvedCharacter, Subrace, getRaceDisplayName } from "../../creature";
import { copyState } from "../../../../reducers/common";
import { FeatureEditor } from "./FeatureEditor";
import { DnD5ECharacterTemplate, DnD5ECharacterToken, NamedRuleRef } from "../../common";
import { useRules } from "../hooks";
import { AnimatedList, AnimatedListItem } from "../../../../components/motion";
import { ShowMore } from "../ShowMore";
import { useDispatch, useLocation } from "../../../../components/contexts";
import { modifyCharacter } from "../../actions/token";
import { Markdown } from "../../../../components/markdown";
import { ScrollableTest } from "../../../../components/ScrollableTest";
import { keyedListToArray } from "../../../../common";

export const RaceList: FunctionComponent<{
    selectedRace?: NamedRuleRef;
    selectedSubrace?: NamedRuleRef;
    onSelected: (race: Race, subrace: Subrace | undefined) => void;
}> = ({ selectedRace, selectedSubrace, onSelected }) => {
    const rules = useRules();

    return (
        <ScrollableTest fullWidth fullHeight minimal>
            {rules.races.all.map(o => {
                const subraces = keyedListToArray(o.subraces);
                return (
                    <React.Fragment key={o.name}>
                        <Button
                            variant="tertiary"
                            m={2}
                            justifyContent="flex-start"
                            p={3}
                            toggled={selectedRace && selectedRace.name === o.name && selectedRace.source === o.source}
                            onClick={() => {
                                if (onSelected) {
                                    onSelected(o, undefined);
                                }
                            }}>
                            {o.name.toLocaleUpperCase()}
                        </Button>
                        {subraces && subraces.length > 0 && (
                            <Box fullWidth pl={4} flexDirection="column" alignItems="flex-start">
                                {subraces.map(sr => (
                                    <Button
                                        key={sr.name}
                                        variant="tertiary"
                                        m={2}
                                        justifyContent="flex-start"
                                        p={3}
                                        toggled={
                                            selectedSubrace &&
                                            selectedSubrace.name === sr.name &&
                                            selectedSubrace.source === sr.source
                                        }
                                        onClick={() => {
                                            if (onSelected) {
                                                onSelected(o, sr);
                                            }
                                        }}>
                                        {sr.name.toLocaleUpperCase()}
                                    </Button>
                                ))}
                            </Box>
                        )}
                    </React.Fragment>
                );
            })}
        </ScrollableTest>
    );
};

export const RacePage: FunctionComponent<{
    token: DnD5ECharacterToken | DnD5ECharacterTemplate;
    resolvedCharacter: ResolvedCharacter;
}> = ({ token, resolvedCharacter }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    // TODO: This isn't necessarily valid - the character could only be partial, if only overriding parts of the template.
    // How does this need to work? Do we need to merge the character with the template? Since we're moving the actual modification to the
    // reducers, that should be fine?
    const character = token.dnd5e as Character;

    return (
        <Box pl={3} flexDirection="row" fullWidth fullHeight pt={2}>
            <Box flexGrow={1} fullHeight>
                <InOverlayCard
                    fullWidth
                    fullHeight
                    flexDirection="column"
                    borderRadius={4}
                    overflow="hidden"
                    boxShadowSize="none">
                    <Heading as="h6" textAlign="center" bg="transparency.2.grayscale.8" p={2}>
                        SPECIES &amp; SUBSPECIES
                    </Heading>
                    <Box flexGrow={1}>
                        <RaceList
                            selectedRace={character.race}
                            selectedSubrace={character.subrace}
                            onSelected={(race, subrace) => {
                                dispatch(
                                    modifyCharacter(campaign, location, [token], {
                                        race: { name: race.name, source: race.source },
                                        subrace: subrace ? { name: subrace.name, source: subrace.source } : undefined,
                                    })
                                );
                            }}
                        />
                    </Box>
                </InOverlayCard>
            </Box>
            <Box flexGrow={3} fullHeight>
                <ScrollableTest pr={3} pb={3} fullWidth fullHeight minimal>
                    {resolvedCharacter.race && (
                        <React.Fragment>
                            {character.race && (
                                <Heading as="h4" px={3}>
                                    {getRaceDisplayName(character.race, character.subrace)}
                                </Heading>
                            )}
                            {resolvedCharacter.race.fluff && (
                                <div
                                    css={{
                                        paddingLeft: theme.space[3],
                                        paddingRight: theme.space[3],
                                    }}>
                                    <ShowMore>
                                        <Markdown>{resolvedCharacter.race.fluff}</Markdown>
                                    </ShowMore>
                                </div>
                            )}
                            <AnimatedList alignItems="stretch" px={3}>
                                {resolvedCharacter.race.traits.map((trait, i) => (
                                    <AnimatedListItem
                                        key={trait.name === "Ability Score Increase" ? trait.name + i : trait.name}
                                        fullWidth
                                        index={i}>
                                        <FeatureEditor
                                            isInOverlay
                                            feature={trait}
                                            character={resolvedCharacter}
                                            selections={resolvedCharacter.raceChoices[trait.name]}
                                            onSelectionsChanged={o => {
                                                const newRaceChoices = copyState(character.raceChoices ?? {}, {
                                                    [trait.name]: o,
                                                });
                                                dispatch(
                                                    modifyCharacter(campaign, location, [token], {
                                                        raceChoices: newRaceChoices,
                                                    })
                                                );
                                            }}
                                        />
                                    </AnimatedListItem>
                                ))}
                            </AnimatedList>
                        </React.Fragment>
                    )}
                </ScrollableTest>
            </Box>
        </Box>
    );
};

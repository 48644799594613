import {
    Color,
    ColorRepresentation,
    ExtrudeGeometry,
    Mesh,
    MeshBasicMaterial,
    MultiplyOperation,
    OrthographicCamera,
    PerspectiveCamera,
    Scene,
    ShaderMaterial,
    ShaderMaterialParameters,
    Shape,
    Texture,
    UniformsLib,
    UniformsUtils,
    Vector2,
    Vector3,
} from "three";
import { getArea, getBounds, IGrid, isPointInPolygon } from "../../../grid";
import { LocalPixelPosition, LocalRect, Point, Rect, Size } from "../../../position";

import { addDebugWireframe } from "../../utils";
import { LevelInfo, SingleLevelInfo, VttCameraLayers, commonShaderFunctions, commonShaderUniforms } from "../common";
import { MutableRefObject, useEffect, useId, useState } from "react";
import { Location } from "../../../store";
import { useFrame } from "@react-three/fiber";
import { useCamera } from "../../contexts";

const DEBUG = false;

export interface ZoneUpdate {
    zone: LocalPixelPosition[];
    grid: IGrid;
    canvasSize: Size;
    particlesCamera: PerspectiveCamera;
    mainCamera: OrthographicCamera | PerspectiveCamera;
    levelInfo: SingleLevelInfo;
    totalSize: LocalRect | undefined;
    wind: Point | undefined;
    depthMap: Texture;
}

export abstract class ZoneParticleSystem {
    public static readonly MAX_HEIGHT_GRID = 16;

    private _scene: Scene;
    private _shape: Point[] | undefined;
    private _zoneArea: number | undefined;
    private _bounds: Rect | undefined;

    private _mainCamera: OrthographicCamera | PerspectiveCamera | undefined;
    private _particlesCamera: PerspectiveCamera | undefined;

    private _isStarted: boolean | undefined;

    private _zoneUpdate: ZoneUpdate | undefined;

    private _debugMesh: Mesh | undefined;

    private _tileWidth: number = 0;
    private _particlesPerGridUnitPerMinute = 0;
    private _lastArea: number = 0;

    private _levelInfo: SingleLevelInfo | undefined;
    private _totalSize: LocalRect | undefined;
    private _depthMap: Texture | undefined;

    private _canvasSize: Size | undefined;
    private _grid: IGrid | undefined;

    // Wind in grid units.
    private _windGrid: Vector2 = new Vector2();

    // Wind in local units.
    private _windLocal: Vector2 = new Vector2();

    constructor(scene: Scene) {
        this._scene = scene;

        if (DEBUG) {
            this._debugMesh = new Mesh();
            this._debugMesh.layers.set(VttCameraLayers.PerspectiveLighting);
        }
    }

    get scene() {
        return this._scene;
    }

    get mainCamera() {
        return this._mainCamera;
    }

    get particlesCamera() {
        return this._particlesCamera;
    }

    get levelInfo() {
        return this._levelInfo;
    }

    get isStarted() {
        return this._isStarted;
    }

    get shape() {
        return this._shape;
    }

    get canvasSize() {
        return this._canvasSize;
    }

    get grid() {
        return this._grid;
    }

    get bounds() {
        return this._bounds;
    }

    /**
     * Gets the area of the current zone in grid units squared.
     */
    get zoneArea(): number | undefined {
        return this._zoneArea;
    }

    get totalSize(): LocalRect | undefined {
        return this._totalSize;
    }

    get tileWidth(): number {
        return this._tileWidth;
    }

    /**
     * Gets the wind velocity in local units.
     */
    get windLocal(): Vector2 {
        return this._windLocal;
    }

    /**
     * Gets the wind velocity in grid units.
     */
    get windGrid(): Vector2 {
        return this._windGrid;
    }

    get particlesPerGridUnitPerMinute() {
        return this._particlesPerGridUnitPerMinute;
    }

    set particlesPerGridUnitPerMinute(value: number) {
        if (this._particlesPerGridUnitPerMinute !== value) {
            this._particlesPerGridUnitPerMinute = value;
            this.onRateChanged();
        }
    }

    async start() {
        if (!this._isStarted) {
            this._isStarted = true;
            if (this._debugMesh) {
                this._scene.add(this._debugMesh);
            }

            await this.startCore();
        }
    }

    stop() {
        if (this._isStarted) {
            if (this._debugMesh) {
                this._scene.remove(this._debugMesh);
            }

            delete this._isStarted;

            this.stopCore();
        }
    }

    protected abstract startCore(): Promise<void>;

    protected abstract stopCore();

    update(delta: number) {
        if (this._zoneUpdate) {
            this.setZone(this._zoneUpdate);
            delete this._zoneUpdate;
        }

        this.updateCore(delta);
    }

    protected abstract updateCore(delta: number);

    protected getPointInBounds(minZ: number, maxZ: number, point?: Vector3): Vector3 {
        while (this._bounds && this._isStarted) {
            const x = Math.random() * this._bounds.width + this._bounds.x;
            const y = Math.random() * this._bounds.height + this._bounds.y;
            const z = minZ === maxZ ? maxZ : Math.random() * (maxZ - minZ) + minZ;

            const p: Point = { x: x, y: y };
            if (isPointInPolygon(p, this._shape!)) {
                if (point) {
                    point.set(x, y, z);
                    return point;
                } else {
                    return new Vector3(x, y, z);
                }
            }
        }

        if (point) {
            point.set(0, 0, 0);
            return point;
        } else {
            return new Vector3(0, 0, 0);
        }
    }

    scheduleZoneUpdate(zone: ZoneUpdate) {
        this._zoneUpdate = zone;
    }

    setZone({ zone, grid, canvasSize, mainCamera, particlesCamera, levelInfo, totalSize, depthMap, wind }: ZoneUpdate) {
        this._mainCamera = mainCamera;
        this._particlesCamera = particlesCamera;
        this._levelInfo = levelInfo;
        this._totalSize = totalSize;
        this._canvasSize = canvasSize;
        this._grid = grid;
        this._depthMap = depthMap;

        if (wind) {
            this._windGrid.set(wind.x, wind.y);
        } else {
            this._windGrid.set(0, 0);
        }

        this._shape = zone;
        this._bounds = getBounds(this._shape);

        if (grid.tileSize.width !== this._tileWidth) {
            this._tileWidth = grid.tileSize.width;
            this.onTileWidthChanged(this._tileWidth);
        }

        this._windLocal.set(this._windGrid.x * this._tileWidth, -this._windGrid.y * this._tileWidth);

        const areaInLocalPixelsSq = getArea(zone);
        const areaInGridUnitsSq = areaInLocalPixelsSq / (grid.tileSize.width * grid.tileSize.height);
        this._zoneArea = areaInGridUnitsSq;

        if (this._debugMesh) {
            const shape3 = new Shape(zone.map(o => new Vector2(o!.x, -o!.y)));
            this._debugMesh.geometry = new ExtrudeGeometry(shape3, {
                depth: this._tileWidth * ZoneParticleSystem.MAX_HEIGHT_GRID,
                bevelEnabled: false,
            });
            this._debugMesh.material = new MeshBasicMaterial({
                color: new Color(0, 0, 255),
                opacity: 0.1,
                transparent: true,
                depthWrite: false,
            });
            this._debugMesh.layers.disableAll();
            this._debugMesh.layers.enable(VttCameraLayers.DefaultNoRaycasting);
            const wireframe = addDebugWireframe(this._debugMesh);
            wireframe.layers.set(VttCameraLayers.PerspectiveLighting);
        }

        this.onZoneChanged();
        if (this.zoneArea !== this._lastArea) {
            this._lastArea = this.zoneArea ?? 0;
            this.onRateChanged();
        }
    }

    protected onTileWidthChanged(tileWidth: number) {
        // Overridden in derived classes.
    }

    protected onZoneChanged() {
        // Overridden in derived classes.
    }

    protected onRateChanged() {
        // Overridden in derived classes.
    }

    protected updateParticleMaterialUniforms(material: ParticleMaterial) {
        material.depthTest = this._mainCamera?.type === "PerspectiveCamera";

        const uniforms = material.uniforms;
        uniforms.u_visibility.value = this.levelInfo?.renderInfo?.visibility.texture;
        uniforms.u_backgroundWidth.value = this.totalSize?.width ?? 0;
        uniforms.u_backgroundHeight.value = this.totalSize?.height ?? 0;
        uniforms.u_backgroundX.value = this.totalSize?.x ?? 0;
        uniforms.u_backgroundY.value = this.totalSize?.y ?? 0;
        (uniforms.u_canvasSize.value as Vector2).set(this.canvasSize?.width ?? 0, this.canvasSize?.height ?? 0);
        uniforms.u_scale.value = this.grid?.scale ?? 1;
        (uniforms.u_offset.value as Vector2).set(this.grid?.offset.x ?? 0, this.grid?.offset.y ?? 0);
        uniforms.u_isPerspective.value = this.mainCamera?.type === "PerspectiveCamera";
        uniforms.u_depthMap.value = this._depthMap;
        uniforms.u_maxHeight.value = this._tileWidth * ZoneParticleSystem.MAX_HEIGHT_GRID;
        uniforms.u_currentHeight.value = this._levelInfo?.height ?? 0;
        uniforms.u_tileSize.value = this._tileWidth;
    }
}

export function useZoneParticleSystem(
    createSystem: (scene: Scene) => ZoneParticleSystem,
    mainCamera: OrthographicCamera | PerspectiveCamera,
    levelInfo: LevelInfo,
    levelKey: string,
    totalSize: LocalRect | undefined,
    depthMap: Texture,
    size: Size,
    scene: MutableRefObject<Scene | undefined>,
    particlesCamera: PerspectiveCamera,
    grid: IGrid,
    location: Location,
    zone: LocalPixelPosition[] | undefined,
    amt: number | undefined
) {
    const { setRequiresPerspectiveLighting } = useCamera();
    const id = useId();

    const isSystemStarted = zone != null && amt != null && amt > 0;
    const [particleSystem, setParticleSystem] = useState<ZoneParticleSystem>();
    useEffect(() => {
        if (isSystemStarted && !particleSystem && scene.current) {
            const ps = createSystem(scene.current);
            setParticleSystem(ps);
        } else if (particleSystem && !isSystemStarted) {
            particleSystem?.stop();
            setParticleSystem(undefined);
            setRequiresPerspectiveLighting(id, false);
        }
    }, [isSystemStarted, particleSystem, createSystem, scene, id, setRequiresPerspectiveLighting]);

    const level = levelInfo[levelKey];
    useEffect(() => {
        if (isSystemStarted && particleSystem && scene.current) {
            if (!particleSystem?.isStarted) {
                particleSystem.particlesPerGridUnitPerMinute = amt!;
                particleSystem.setZone({
                    zone: zone!,
                    grid,
                    canvasSize: size,
                    particlesCamera: particlesCamera,
                    mainCamera: mainCamera,
                    levelInfo: level,
                    totalSize: totalSize,
                    depthMap: depthMap,
                    wind: location.wind,
                });
                setRequiresPerspectiveLighting(id, true);
                particleSystem.start();
            } else {
                particleSystem.particlesPerGridUnitPerMinute = amt!;
                particleSystem.setZone({
                    zone: zone!,
                    grid,
                    canvasSize: size,
                    particlesCamera: particlesCamera,
                    mainCamera: mainCamera,
                    levelInfo: level,
                    totalSize: totalSize,
                    depthMap: depthMap,
                    wind: location.wind,
                });
            }
        }

        // createSystem and isSystem not included because performance (they'll be the same every time anyway)
        // grid and three.size and visCache.particlesScene.camera not included because it only matters for initial setup
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        zone,
        amt,
        grid.tileSize.width,
        grid.tileSize.height,
        particleSystem,
        mainCamera,
        particlesCamera,
        level,
        totalSize,
        location.wind,
    ]);

    useEffect(() => {
        return () => {
            particleSystem?.stop();
            setRequiresPerspectiveLighting(id, false);
        };
    }, [id, setRequiresPerspectiveLighting, particleSystem]);

    useFrame((state, delta) => {
        particleSystem?.update(delta);
    });

    if (isSystemStarted && particleSystem != null) {
        particleSystem.scheduleZoneUpdate({
            zone: zone!,
            grid,
            canvasSize: size,
            mainCamera: mainCamera,
            particlesCamera: particlesCamera,
            levelInfo: level,
            totalSize: totalSize,
            depthMap: depthMap,
            wind: location.wind,
        });
    }
}

// Pulled from https://github.com/mrdoob/three.js/blob/c571a5a390accc25b281f0b9a739c63a050a7683/examples/jsm/materials/MeshGouraudMaterial.js
// Modified to add the lighting value to both the back and front sides, so that we can colour our rain and see it in the lighting colour even
// if it's only list from the back.
// Other modifications have been made to filter out particles that aren't currently visible.
const ParticleShader = {
    uniforms: UniformsUtils.merge([
        UniformsLib.common,
        UniformsLib.specularmap,
        UniformsLib.envmap,
        UniformsLib.aomap,
        UniformsLib.lightmap,
        UniformsLib.emissivemap,
        UniformsLib.fog,
        UniformsLib.lights,
        {
            emissive: { value: new Color(0x000000) },
        },
        {
            u_visibility: { type: "t", value: undefined as THREE.Texture | undefined },
            u_backgroundWidth: { value: 0 },
            u_backgroundHeight: { value: 0 },
            u_backgroundX: { value: 0 },
            u_backgroundY: { value: 0 },
            u_canvasSize: { value: new Vector2() }, // The width/height of the canvas we're drawing to.
            u_scale: { value: 1 },
            u_offset: { value: new Vector2() },
            u_isPerspective: { value: false },
            u_depthMap: { type: "t", value: undefined as THREE.Texture | undefined },
            u_maxHeight: { value: 0 },
            u_currentHeight: { value: 0 },
            u_tileSize: { value: 0 },
        },
    ]),

    vertexShader: /* glsl */ `
        #define USE_ENVMAP
        
        varying vec3 vWP;

		#define GOURAUD
		varying vec3 vLightFront;
		varying vec3 vIndirectFront;
		#ifdef DOUBLE_SIDED
			varying vec3 vLightBack;
			varying vec3 vIndirectBack;
		#endif
		#include <common>
		#include <uv_pars_vertex>
		#include <envmap_pars_vertex>
		#include <bsdfs>
		#include <lights_pars_begin>
		#include <color_pars_vertex>
		#include <fog_pars_vertex>
		#include <morphtarget_pars_vertex>
		#include <skinning_pars_vertex>
		#include <shadowmap_pars_vertex>
		#include <logdepthbuf_pars_vertex>
		#include <clipping_planes_pars_vertex>
		void main() {
			#include <uv_vertex>
			#include <color_vertex>
			#include <morphcolor_vertex>
			#include <beginnormal_vertex>
			#include <morphnormal_vertex>
			#include <skinbase_vertex>
			#include <skinnormal_vertex>
			#include <defaultnormal_vertex>
			#include <begin_vertex>
			#include <morphtarget_vertex>
			#include <skinning_vertex>
			#include <project_vertex>
			#include <logdepthbuf_vertex>
			#include <clipping_planes_vertex>
			#include <worldpos_vertex>

            vWP = worldPosition.xyz;

			#include <envmap_vertex>

			// inlining legacy <lights_lambert_vertex>
			vec3 diffuse = vec3( 1.0 );
			GeometricContext geometry;
			geometry.position = mvPosition.xyz;
			geometry.normal = normalize( transformedNormal );
			geometry.viewDir = ( isOrthographic ) ? vec3( 0, 0, 1 ) : normalize( -mvPosition.xyz );
			GeometricContext backGeometry;
			backGeometry.position = geometry.position;
			backGeometry.normal = -geometry.normal;
			backGeometry.viewDir = geometry.viewDir;
			vLightFront = vec3( 0.0 );
			vIndirectFront = vec3( 0.0 );
			#ifdef DOUBLE_SIDED
				vLightBack = vec3( 0.0 );
				vIndirectBack = vec3( 0.0 );
			#endif
			IncidentLight directLight;
			float dotNL;
			vec3 directLightColor_Diffuse;
			vIndirectFront += getAmbientLightIrradiance( ambientLightColor );
			vIndirectFront += getLightProbeIrradiance( lightProbe, geometry.normal );
			#ifdef DOUBLE_SIDED
                vIndirectFront += getLightProbeIrradiance( lightProbe, backGeometry.normal );
				vIndirectBack = vIndirectFront;
			#endif
			#if NUM_POINT_LIGHTS > 0
				#pragma unroll_loop_start
				for ( int i = 0; i < NUM_POINT_LIGHTS; i ++ ) {
					getPointLightInfo( pointLights[ i ], geometry, directLight );
                    dotNL = dot(-directLight.direction, directLight.direction);
					directLightColor_Diffuse = directLight.color;
					vLightFront += saturate( - dotNL ) * directLightColor_Diffuse;
				}
				#pragma unroll_loop_end
			#endif
			#if NUM_SPOT_LIGHTS > 0
				#pragma unroll_loop_start
				for ( int i = 0; i < NUM_SPOT_LIGHTS; i ++ ) {
					getSpotLightInfo( spotLights[ i ], geometry, directLight );
                    dotNL = dot(-directLight.direction, directLight.direction);
					directLightColor_Diffuse = directLight.color;
					vLightFront += saturate( - dotNL ) * directLightColor_Diffuse;
				}
				#pragma unroll_loop_end
			#endif
			#if NUM_DIR_LIGHTS > 0
				#pragma unroll_loop_start
				for ( int i = 0; i < NUM_DIR_LIGHTS; i ++ ) {
					getDirectionalLightInfo( directionalLights[ i ], geometry, directLight );
                    dotNL = dot(-directLight.direction, directLight.direction);
					directLightColor_Diffuse = directLight.color;
					vLightFront += saturate( - dotNL ) * directLightColor_Diffuse;
				}
				#pragma unroll_loop_end
			#endif
			#if NUM_HEMI_LIGHTS > 0
				#pragma unroll_loop_start
				for ( int i = 0; i < NUM_HEMI_LIGHTS; i ++ ) {
					vIndirectFront += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry.normal );
					#ifdef DOUBLE_SIDED
						vIndirectFront += getHemisphereLightIrradiance( hemisphereLights[ i ], backGeometry.normal );
					#endif
				}
				#pragma unroll_loop_end
			#endif
            #ifdef DOUBLE_SIDED
                vLightBack = vLightFront;
            #endif

			#include <shadowmap_vertex>
			#include <fog_vertex>
		}`,

    fragmentShader: /* glsl */ `
        uniform sampler2D u_visibility;
        ${commonShaderUniforms}
        uniform bool u_isPerspective;
        uniform sampler2D u_depthMap;
        uniform float u_maxHeight;
        uniform float u_currentHeight;
        uniform float u_tileSize;
        
        varying vec3 vWP;

        #define GOURAUD
		uniform vec3 diffuse;
		uniform vec3 emissive;
		uniform float opacity;
		varying vec3 vLightFront;
		varying vec3 vIndirectFront;
		#ifdef DOUBLE_SIDED
			varying vec3 vLightBack;
			varying vec3 vIndirectBack;
		#endif
		#include <common>
		#include <packing>
		#include <dithering_pars_fragment>
		#include <color_pars_fragment>
		#include <uv_pars_fragment>
		#include <map_pars_fragment>
		#include <alphamap_pars_fragment>
		#include <alphatest_pars_fragment>
		#include <aomap_pars_fragment>
		#include <lightmap_pars_fragment>
		#include <emissivemap_pars_fragment>
		#include <envmap_common_pars_fragment>
		#include <envmap_pars_fragment>
		#include <bsdfs>
		#include <lights_pars_begin>
		#include <fog_pars_fragment>
		#include <shadowmap_pars_fragment>
		#include <shadowmask_pars_fragment>
		#include <specularmap_pars_fragment>
		#include <logdepthbuf_pars_fragment>
		#include <clipping_planes_pars_fragment>

        ${commonShaderFunctions}

		void main() {
			#include <clipping_planes_fragment>
			vec4 diffuseColor = vec4( diffuse, opacity );
			ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
			vec3 totalEmissiveRadiance = emissive;
			#include <logdepthbuf_fragment>
			#include <map_fragment>
			#include <color_fragment>
			#include <alphamap_fragment>
			#include <alphatest_fragment>
			#include <specularmap_fragment>
			#include <emissivemap_fragment>
			// accumulation
			#ifdef DOUBLE_SIDED
				reflectedLight.indirectDiffuse += ( gl_FrontFacing ) ? vIndirectFront : vIndirectBack;
			#else
				reflectedLight.indirectDiffuse += vIndirectFront;
			#endif
			#include <lightmap_fragment>
			reflectedLight.indirectDiffuse *= BRDF_Lambert( diffuseColor.rgb );
			#ifdef DOUBLE_SIDED
				reflectedLight.directDiffuse = ( gl_FrontFacing ) ? vLightFront : vLightBack;
			#else
				reflectedLight.directDiffuse = vLightFront;
			#endif
			reflectedLight.directDiffuse *= BRDF_Lambert( diffuseColor.rgb ) * getShadowMask();
			// modulation
			#include <aomap_fragment>
			vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
			#include <envmap_fragment>
			#include <output_fragment>
			#include <tonemapping_fragment>
			#include <encodings_fragment>
			#include <fog_fragment>
			#include <premultiplied_alpha_fragment>
			#include <dithering_fragment>

            vec2 localPoint = vec2(vWP.x, -vWP.y);
            vec2 localUv = vec2((localPoint.x - u_backgroundX) / u_backgroundWidth, 1.0 - ((localPoint.y - u_backgroundY) / u_backgroundHeight));
            float levelDepth = texture2D(u_depthMap, localUv).g * u_maxHeight;

            // Use following line if we want to show particles over areas that should be visible given the current level the user is looking
            // at in perspective mode. Not sure what's best yet.
            // if (vWP.z < levelDepth || (!u_isPerspective && levelDepth > (u_currentHeight + 10.0))) {
            if (vWP.z < levelDepth || levelDepth > (u_currentHeight + 10.0)) {
                discard;
            }

            float opacity = min(min(1.0, (vWP.z - levelDepth) / u_tileSize), (u_maxHeight - vWP.z) / u_tileSize);

            if (localPoint.x < u_backgroundX || localPoint.x > (u_backgroundX + u_backgroundWidth) || localPoint.y < u_backgroundY || localPoint.y >= (u_backgroundY + u_backgroundHeight)) {
                gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
            } else {
                vec2 visibilityUv = u_isPerspective ? localUv : localPointToTexturePoint(localPoint);
                vec4 visibility = texture2D(u_visibility, visibilityUv);
                gl_FragColor = vec4(gl_FragColor.rgb, gl_FragColor.a * visibility.a * opacity);
            }
		}`,
};

export interface ParticleMaterialParameters extends ShaderMaterialParameters {
    color?: ColorRepresentation;
    opacity?: number;
    map?: Texture | null;
    alphaMap?: Texture | null;
}

export class ParticleMaterial extends ShaderMaterial {
    combine: THREE.Combine;

    constructor(parameters: ParticleMaterialParameters) {
        super();

        this.type = "MeshGouraudMaterial";
        this.combine = MultiplyOperation; // combine has no uniform

        this.fog = false; // set to use scene fog
        this.lights = true; // set to use scene lights
        this.clipping = false; // set to use user-defined clipping planes

        const shader = ParticleShader;

        this.uniforms = UniformsUtils.clone(shader.uniforms);
        this.vertexShader = shader.vertexShader;
        this.fragmentShader = shader.fragmentShader;

        const exposePropertyNames = [
            "map",
            "lightMap",
            "lightMapIntensity",
            "aoMap",
            "aoMapIntensity",
            "emissive",
            "emissiveIntensity",
            "emissiveMap",
            "specularMap",
            "alphaMap",
            "envMap",
            "reflectivity",
            "refractionRatio",
            "opacity",
            "diffuse",
        ];

        for (const propertyName of exposePropertyNames) {
            Object.defineProperty(this, propertyName, {
                get: function () {
                    return this.uniforms[propertyName].value;
                },
                set: function (value) {
                    this.uniforms[propertyName].value = value;
                },
            });
        }

        Object.defineProperty(this, "color", Object.getOwnPropertyDescriptor(this, "diffuse")!);

        this.setValues(parameters);
    }
}

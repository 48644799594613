import { Light, Token, TokenTemplate } from "../../store";
import {
    AttackType,
    createNamedRuleStore,
    DamageType,
    getRuleKey,
    MergeableRule,
    ModifiedValue,
    NamedRuleRef,
    NamedRuleStore,
    WeaponType,
} from "./common";
import { parse, eval as evalExpr } from "expression-eval";
import { KeyedList } from "../../common";

export enum WeaponItemType {
    Ranged = "R",
    Melee = "M",
}

export enum ArmorItemType {
    LightArmor = "LA",
    MediumArmor = "MA",
    HeavyArmor = "HA",
}

export enum ItemType {
    Generic = "G",
    Ammunition = "A",
    Ranged = "R",
    Shield = "S",
    Melee = "M",
    LightArmor = "LA",
    MediumArmor = "MA",
    HeavyArmor = "HA",
    Ring = "RG",
    Wand = "WD",
    Vehicle = "VEH",
    SpellCastingFocus = "SCF",
    Tool = "T",
    ArtisanTool = "AT",
    Instrument = "INS",
    FoodAndDrink = "FD",
    Mount = "MNT",
    Other = "OTH",
    Scroll = "SC",
    Potion = "P",
    TradeGood = "TG",
    Currency = "$",
    Ship = "SHP",
    Airship = "AIR",
    Rod = "RD",
    GamingSet = "GS",
    TackAndHarness = "TAH",
}

export const itemTypes: ItemType[] = [
    ItemType.Generic,
    ItemType.Ammunition,
    ItemType.Ranged,
    ItemType.Melee,
    ItemType.Shield,
    ItemType.LightArmor,
    ItemType.MediumArmor,
    ItemType.HeavyArmor,
    ItemType.Ring,
    ItemType.Wand,
    ItemType.Vehicle,
    ItemType.SpellCastingFocus,
    ItemType.Tool,
    ItemType.ArtisanTool,
    ItemType.Instrument,
    ItemType.FoodAndDrink,
    ItemType.Mount,
    ItemType.Other,
    ItemType.Scroll,
    ItemType.Potion,
    ItemType.TradeGood,
    ItemType.Currency,
    ItemType.Ship,
    ItemType.Airship,
    ItemType.Rod,
    ItemType.GamingSet,
    ItemType.TackAndHarness,
];

export type ItemRarity = "none" | "common" | "uncommon" | "rare" | "very rare" | "legendary" | "artifact"; // TODO: What values do we need here?

export type ItemTier = "major" | "minor";

export type WeaponArchetype = "sword" | "axe" | "spear" | "hammer" | "crossbow" | "bow" | "mace" | "net";

export interface ItemProperty extends NamedRuleRef {
    abbreviation: string;
    content?: string[];
}

export interface NamedItem {
    name: string;
    baseItem?: NamedRuleRef;
}

export interface ItemBase {
    name: string;
    source: string;
    type?: ItemType;
    rarity: ItemRarity;
    weight?: number;
    value?: number;
    wondrous?: boolean;
    content?: string;
    acModifier?: number;
    tier?: ItemTier;
    lights?: Light[];
    requiresAttunement?: boolean | string; // String describes attunement requirements - should be more formal.
}

export interface Item extends ItemBase {
    properties?: string[]; // This will be a ref to a global properties dictionary.
    baseItem?: NamedRuleRef;
}

/**
 * A partial item can specify only some attributes that it wants to override, but it MUST have a name/source and
 * reference a base item.
 */
export interface PartialItem extends Partial<Item> {
    name: string;
    source: string;
    baseItem: NamedRuleRef;
}

export interface DraggedItems {
    items: ResolvedItem[];
    token?: Token | TokenTemplate;
}

export interface ResolvedItem extends ItemBase {
    resolvedFrom: Item | PartialItem | NamedItem;
    properties?: ItemProperty[];
    baseItem?: ResolvedItem;
    quantity?: number;
}

interface InventoryItemProps {
    acquired: number;
    active?: boolean;
    attuned?: boolean;
}

export type StoredItem = (Item | PartialItem | NamedItem | NamedRuleRef) & { quantity?: number };
export type InventoryItem = StoredItem & InventoryItemProps;
export type ResolvedInventoryItem = ResolvedItem & InventoryItemProps & { id: string };
export type Inventory = KeyedList<InventoryItem>;
export type ResolvedInventory = KeyedList<ResolvedInventoryItem>;

export interface WeaponBase {
    type: ItemType.Ranged | ItemType.Melee;
    weaponType: WeaponType;
    archetype?: WeaponArchetype;
    range?: { near: number; far: number };
    dmg1h?: string;
    dmg2h?: string;
    dmgType?: DamageType;
    attackModifier?: number;
    dmgModifier?: number;
}

export interface Weapon extends WeaponBase {
    ammoType?: NamedRuleRef;
}

export interface ResolvedWeapon extends WeaponBase {
    ammoType?: ResolvedItem;
}

export interface ResolvedInventoryWeapon extends ResolvedWeapon {
    hitBonus: ModifiedValue;
    damageBonus: ModifiedValue;
    ability: "strength" | "dexterity";
}

export interface Armor {
    type: ItemType.LightArmor | ItemType.MediumArmor | ItemType.HeavyArmor;
    ac: number;
    strength?: number | null;
    disadvantageOnStealth?: boolean;
}

export interface ResolvedInventoryArmor extends Armor {
    acBonus: ModifiedValue;
}

export interface Shield {
    ac: number;
}

export interface ItemGroup extends Partial<ItemBase> {
    name: string;
    source: string;
    items: KeyedList<NamedRuleRef>;
}

export interface ItemPack extends Item {
    packContents: StoredItem[];
}

export interface ResolvedItemPack extends ResolvedItem {
    packContents: ResolvedItem[];
}

export type ItemFilter = ItemFilterSingle | ItemFilterSingle[];

interface ItemFilterSingle {
    /**
     * Matches all items that have the specified name, or are based on an item with the specified name.
     */
    name?: string;

    /**
     * Matches all items that have the specified name.
     */
    exactName?: string;

    /**
     * Matches all items that have one of these types.
     */
    type?: ItemType[];

    /**
     * Matches all weapons that have the specified attack type.
     */
    attackType?: AttackType;

    /**
     * Matches all items that belong to the specified item group.
     */
    group?: NamedRuleRef;

    /**
     * Matches all items that have the specified weapon type.
     */
    weaponType?: WeaponType;

    /**
     * Matches all items that have one of the specified item rarities.
     */
    rarity?: ItemRarity[];

    /**
     * Matches all weapons that have the specified damage type.
     */
    dmgType?: string;

    /**
     * If true, matches all weapons. If false, matches all non-weapons.
     */
    weapon?: boolean;

    /**
     * If true, matches all armor. If false, matches all non-armor.
     */
    armor?: boolean;

    /**
     * Matches all items that belong to one of the specified weapon archetypes.
     */
    weaponArchetype?: WeaponArchetype[];

    /**
     * Matches all items that contain all of the specified properties.
     */
    withProperties?: string[];

    /**
     * Matches all items that contain none of the specified properties.
     */
    withoutProperties?: string[];
}

export interface ItemVariant {
    name: string;
    source: string;
    requires: ItemFilter[];
    excludes?: ItemFilter[];
    namePrefix?: string;
    nameSuffix?: string;
    nameRemove?: string;
    weightExpression?: string | parse.Expression;
    valueExpression?: string | parse.Expression;
    inherits: Partial<Item> | Partial<Weapon> | Partial<Armor>;
}

export function filterItems(items: ResolvedItem[], filter: ItemFilter, itemStore: ItemStore) {
    const isArray = Array.isArray(filter);
    return items.filter(o =>
        isArray
            ? (filter as ItemFilterSingle[]).some(f => filterItemSingle(o, f, itemStore))
            : filterItemSingle(o, filter as ItemFilterSingle, itemStore)
    );
}

export function filterItem(item: ResolvedItem, filter: ItemFilter, itemStore: ItemStore) {
    if (Array.isArray(filter)) {
        return filter.some(o => filterItemSingle(item, o, itemStore));
    }

    return filterItemSingle(item, filter, itemStore);
}

function filterItemSingle(item: ResolvedItem, filter: ItemFilterSingle, itemStore: ItemStore) {
    if (filter.type) {
        let matchesType = false;
        for (let i = 0; i < filter.type.length; i++) {
            if (item.type === filter.type[i]) {
                matchesType = true;
            }
        }

        if (!matchesType) {
            return false;
        }
    }

    if (filter.group) {
        var group = itemStore.groups.get(filter.group);
        if (!group) {
            return false;
        }

        var key = getRuleKey(item);
        let found = false;
        for (let itemKey in group.items) {
            if (getRuleKey(group.items[itemKey]) === key) {
                found = true;
                break;
            }
        }

        if (!found) {
            return false;
        }
    }

    if (filter.weaponType) {
        const matchesWeaponType = isWeapon(item) && item.weaponType === filter.weaponType;
        if (!matchesWeaponType) {
            return false;
        }
    }

    if (filter.rarity) {
        let matchesRarity = false;
        for (let i = 0; i < filter.rarity.length; i++) {
            if (item.rarity === filter.rarity[i]) {
                matchesRarity = true;
            }
        }

        if (!matchesRarity) {
            return false;
        }
    }

    if (filter.dmgType) {
        const matchesDmgType = isWeapon(item) && item.dmgType === filter.dmgType;
        if (!matchesDmgType) {
            return false;
        }
    }

    if (filter.name && item.name !== filter.name && (!item.baseItem || item.baseItem.name !== filter.name)) {
        return false;
    }

    if (filter.exactName && item.name !== filter.exactName) {
        return false;
    }

    if (filter.weapon != null && !!isWeapon(item) !== filter.weapon) {
        return false;
    }

    if (filter.armor != null && !!isArmor(item) !== filter.armor) {
        return false;
    }

    if (filter.weaponArchetype) {
        if (!isWeapon(item)) {
            return false;
        }

        let matchesArchetype = false;
        for (let i = 0; i < filter.weaponArchetype.length; i++) {
            if (item.archetype === filter.weaponArchetype[i]) {
                matchesArchetype = true;
            }
        }

        if (!matchesArchetype) {
            return false;
        }
    }

    if (filter.withProperties?.length) {
        if (!item.properties) {
            return false;
        }

        for (let i = 0; i < filter.withProperties.length; i++) {
            const propertyToFind = filter.withProperties[i];
            if (!item.properties.some(o => o.abbreviation === propertyToFind)) {
                return false;
            }
        }
    }

    if (filter.withoutProperties?.length && item.properties) {
        for (let i = 0; i < filter.withoutProperties.length; i++) {
            const propertyToFind = filter.withoutProperties[i];
            if (item.properties.some(o => o.abbreviation === propertyToFind)) {
                return false;
            }
        }
    }

    if (filter.attackType) {
        switch (filter.attackType) {
            case "mw":
                if (item.type !== ItemType.Melee) {
                    return false;
                }

                break;
            case "rw":
                if (item.type !== ItemType.Ranged) {
                    return false;
                }

                break;
            case "mw,rw":
                if (item.type !== ItemType.Melee && item.type !== ItemType.Ranged) {
                    return false;
                }

                break;
        }
    }

    return true;
}

export function isItem(item: any): item is Item {
    return item != null && item["source"] != null && item["name"] && item["rarity"];
}

export function isInventoryItem(item: any): item is ResolvedInventoryItem {
    return isItem(item) && !!item["resolvedFrom"] && item["id"] != null;
}

export function isWeapon(item: ResolvedItem): item is ResolvedWeapon & ResolvedItem;
export function isWeapon(item: any): item is Weapon & Item;
export function isWeapon(item: any) {
    return isItem(item) && (item.type === "R" || item.type === "M");
}

export function isResolvedWeapon(item: any): item is ResolvedWeapon & ResolvedItem {
    return isWeapon(item) && !!item["resolvedFrom"];
}

export function isInventoryWeapon(item: any): item is ResolvedInventoryWeapon & ResolvedInventoryItem {
    return isResolvedWeapon(item) && item["id"] != null;
}

export function isArmor(item: ResolvedItem): item is Armor & ResolvedItem;
export function isArmor(item: any): item is Armor & Item;
export function isArmor(item: any) {
    return isItem(item) && (item.type === "LA" || item.type === "MA" || item.type === "HA");
}

export function isInventoryArmor(item: any): item is ResolvedInventoryArmor & ResolvedInventoryItem {
    return isArmor(item) && !!item["resolvedFrom"] && item["id"] != null;
}

export function isShield(item: ResolvedItem): item is Shield & ResolvedItem;
export function isShield(item: any): item is Shield & Item;
export function isShield(item: any) {
    return isItem(item) && item.type === "S";
}

export function isPack(item: ResolvedItem): item is ResolvedItemPack;
export function isPack(item: any): item is ItemPack;
export function isPack(item: any) {
    return isItem(item) && !!item["packContents"];
}

export interface ItemStore {
    properties: Map<string, ItemProperty>;
    groups: NamedRuleStore<ItemGroup>;
    items: NamedRuleStore<Item | PartialItem> & {
        byType: Map<ItemType, (Item | PartialItem)[]>;
        allResolved: (ResolvedItem & { key: string })[];
    };
}

export function createItemStore(
    properties: Map<string, ItemProperty>,
    items: Item[],
    itemGroups: MergeableRule<ItemGroup>[],
    itemVariants: ItemVariant[]
): ItemStore {
    const allResolved: (ResolvedItem & { key: string })[] = [];

    // Create a base store, we can use this to evaluate the variants and generate the final store.
    const baseStore = createNamedRuleStore(items);
    const baseItemStore: ItemStore = {
        properties: properties,
        groups: createNamedRuleStore(itemGroups),
        items: Object.assign(baseStore, {
            byType: new Map<ItemType, (Item | PartialItem)[]>(),
            allResolved: allResolved,
        }),
    };

    // This gets complicated because we have to have a resolved item to filter properly, but we haven't generated the resolved items yet.
    const allItems: (Item | PartialItem)[] = [];
    for (let item of items) {
        allItems.push(item);
        const resolvedItem = resolveItem(item, baseItemStore) as ResolvedItem & { key: string };
        resolvedItem.key = getRuleKey(resolvedItem);
        allResolved.push(resolvedItem);

        if (!item.baseItem) {
            for (let variant of itemVariants) {
                if (
                    variant.requires.some(o => filterItem(resolvedItem, o, baseItemStore)) &&
                    (variant.excludes == null ||
                        variant.excludes.every(o => !filterItem(resolvedItem, o, baseItemStore)))
                ) {
                    // This item matches the requirements for the variant, so we should generate & add a variant item.
                    const variantItem: PartialItem = Object.assign(
                        {},
                        {
                            name: item.name,
                            source: variant.source,
                            baseItem: { name: item.name, source: item.source },
                        },
                        variant.inherits
                    );

                    if (variant.nameRemove) {
                        variantItem.name = variantItem.name.replace(variant.nameRemove, "");
                    }

                    if (variant.namePrefix) {
                        variantItem.name = variant.namePrefix + variantItem.name;
                    }

                    if (variant.nameSuffix) {
                        variantItem.name = variantItem.name + variant.nameSuffix;
                    }

                    if (variantItem.name === item.name) {
                        console.error(
                            `A variant item could not be generated because its name is the same as its base item. The name is "${item.name}".`
                        );
                    } else {
                        Object.assign(variantItem, variant.inherits);

                        allItems.push(variantItem);
                        const resolvedItem = resolveItem(variantItem, baseItemStore) as ResolvedItem & { key: string };
                        resolvedItem.key = getRuleKey(resolvedItem);
                        allResolved.push(resolvedItem);
                    }

                    if (item.weight != null && variant.weightExpression) {
                        if (typeof variant.weightExpression === "string") {
                            variant.weightExpression = parse(variant.weightExpression);
                        }

                        variantItem.weight = evalExpr(variant.weightExpression, {
                            weight: item.weight,
                        });
                    }

                    if (item.value != null && variant.valueExpression) {
                        if (typeof variant.valueExpression === "string") {
                            variant.valueExpression = parse(variant.valueExpression);
                        }

                        variantItem.value = evalExpr(variant.valueExpression, {
                            value: item.value,
                        });
                    }
                }
            }
        }
    }

    const byType = new Map<ItemType, (Item | PartialItem)[]>();
    for (let i = 0; i < allItems.length; i++) {
        const itemsOfType = byType.get(allResolved[i].type ?? ItemType.Generic);
        if (itemsOfType) {
            itemsOfType.push(allItems[i]);
        } else {
            byType.set(allResolved[i].type ?? ItemType.Generic, [allItems[i]]);
        }
    }

    const ret = {
        properties: baseItemStore.properties,
        groups: baseItemStore.groups,
        items: Object.assign(createNamedRuleStore(allItems), {
            byType: byType,
            allResolved: allResolved,
        }),
    };
    return ret;
}

// TODO: Remove, use resolvedFrom field.
export function unresolveItem(resolvedItem: ResolvedItem): StoredItem {
    if (!resolvedItem.source) {
        // This is a named item with no base item.
        const item: StoredItem = {
            name: resolvedItem.name,
        };

        if (resolvedItem.baseItem) {
            item.baseItem = unresolveItem(resolvedItem.baseItem) as NamedRuleRef;
        }

        if (resolvedItem.quantity != null) {
            item.quantity = resolvedItem.quantity;
        }

        return item;
    }

    const item: StoredItem = {
        name: resolvedItem.name,
        source: resolvedItem.source,
    };
    if (resolvedItem.quantity != null) {
        item.quantity = resolvedItem.quantity;
    }

    return item;
}

export function resolveItem(
    itemOrPersonal: (Item | PartialItem | NamedItem | NamedRuleRef) & { quantity?: number },
    items: ItemStore
): ResolvedItem {
    if (itemOrPersonal["type"] === undefined) {
        if (itemOrPersonal["source"] != null && itemOrPersonal["baseItem"] != null) {
            // This is a PartialItem - it has both a source (so it's not a personal item) and
            // a baseItem (so it's not just a ref).
        } else {
            let isPersonal = true;
            if (itemOrPersonal["source"] != null) {
                // Source but no type - this is a ref.
                const item = items.items.get(itemOrPersonal as NamedRuleRef);

                // If the ref is bad for some reason, continue and return it as a named personal item.
                // That way you at least see the name instead of it disappearing.
                if (item) {
                    isPersonal = false;
                    itemOrPersonal = item;
                }
            }

            // No source or type - this is a simple named item.
            if (isPersonal) {
                const baseItemRef = itemOrPersonal["baseItem"] as NamedRuleRef | undefined;
                if (baseItemRef) {
                    const baseItemUnresolved = items.items.get(baseItemRef);
                    if (baseItemUnresolved) {
                        const baseItem = resolveItem(baseItemUnresolved, items);
                        const item = Object.assign({}, baseItem, {
                            name: itemOrPersonal.name,
                            source: "",
                        });
                        item.baseItem = baseItem;
                        return item;
                    }
                }

                const source = "";
                return {
                    name: itemOrPersonal.name,
                    source: source,
                    rarity: "common",
                    resolvedFrom: itemOrPersonal,
                };
            }
        }
    }

    const item = itemOrPersonal as Item | PartialItem;

    let baseItem: ResolvedItem | undefined;
    if (item.baseItem != null) {
        const baseItemUnresolved = items.items.get(item.baseItem);
        if (baseItemUnresolved) {
            baseItem = resolveItem(baseItemUnresolved, items);
        }
    }

    // Have to check if each property on item has been specified individually, as they're all optional
    // except for name & source if this is a PartialItem.
    const resolvedItem = Object.assign({}, baseItem, {
        name: item.name,
        source: item.source,
        resolvedFrom: item,
    } as ResolvedItem);

    if (baseItem) {
        resolvedItem.baseItem = baseItem;
    }

    if (item.type) {
        resolvedItem.type = item.type;
    }

    if (item.rarity) {
        resolvedItem.rarity = item.rarity;
    }

    if (item.wondrous) {
        resolvedItem.wondrous = item.wondrous;
    }

    if (item.value != null) {
        resolvedItem.value = item.value;
    }

    if (item.weight != null) {
        resolvedItem.weight = item.weight;
    }

    if (item.lights) {
        resolvedItem.lights = item.lights;
    }

    if (item.content != null) {
        // TODO: Do we want to merge these instead of overwriting?
        resolvedItem.content = item.content;
    }

    if (item.acModifier != null) {
        resolvedItem.acModifier = item.acModifier;
    }

    if (item.tier != null) {
        resolvedItem.tier = item.tier;
    }

    if (item.requiresAttunement != null) {
        resolvedItem.requiresAttunement = item.requiresAttunement;
    }

    if (item.properties) {
        resolvedItem.properties = item.properties.map(o => items.properties.get(o)).filter(o => !!o) as ItemProperty[];
    }

    if (itemOrPersonal.quantity) {
        resolvedItem.quantity = itemOrPersonal.quantity;
    }

    if (isWeapon(resolvedItem)) {
        const weapon = item as Partial<Weapon> & (Item | PartialItem);
        const resolvedWeapon = resolvedItem as ResolvedWeapon & ResolvedItem;

        if (weapon.weaponType) {
            resolvedWeapon.weaponType = weapon.weaponType;
        }

        if (weapon.range) {
            resolvedWeapon.range = weapon.range;
        }

        if (weapon.dmg1h != null) {
            resolvedWeapon.dmg1h = weapon.dmg1h;
        }

        if (weapon.dmg2h != null) {
            resolvedWeapon.dmg2h = weapon.dmg2h;
        }

        if (weapon.dmgType != null) {
            resolvedWeapon.dmgType = weapon.dmgType;
        }

        if (weapon.attackModifier != null) {
            resolvedWeapon.attackModifier = weapon.attackModifier;
        }

        if (weapon.dmgModifier != null) {
            resolvedWeapon.dmgModifier = weapon.dmgModifier;
        }

        if (weapon.archetype != null) {
            resolvedWeapon.archetype = weapon.archetype;
        }

        if (weapon.ammoType != null) {
            const ammoItem = items.items.get(weapon.ammoType);
            if (ammoItem) {
                resolvedWeapon.ammoType = resolveItem(ammoItem, items);
            }
        }
    }

    if (isArmor(resolvedItem)) {
        const armor = item as Partial<Armor> & (Item | PartialItem);
        const resolvedArmor = resolvedItem as Armor & ResolvedItem;

        if (armor.ac != null) {
            resolvedArmor.ac = armor.ac;
        }

        if (armor.strength) {
            if (armor.strength === null) {
                delete resolvedArmor.strength;
            } else {
                resolvedArmor.strength = armor.strength;
            }
        }

        if (armor.disadvantageOnStealth) {
            resolvedArmor.disadvantageOnStealth = armor.disadvantageOnStealth;
        }
    }

    if (isShield(resolvedItem)) {
        const shield = item as Partial<Shield> & (Item | PartialItem);

        if (shield.ac != null) {
            resolvedItem.ac = shield.ac;
        } else {
            shield.ac = 0;
        }
    }

    if (item["packContents"]) {
        const pack = item as Partial<ItemPack> & (Item | PartialItem);
        const resolvedPack = resolvedItem as ResolvedItemPack & ResolvedItem;
        resolvedPack.packContents = pack.packContents!.map(o => resolveItem(o, items));
    }

    return resolvedItem;
}

// export function isInventoryEmpty(inventory: ResolvedInventory) {
//     const values = Object.values(inventory);
//     return values.length > 0;
// }

export function getItemsInInventory(inventory: ResolvedInventory) {
    const keys = Object.keys(inventory);
    const items = keys.map(o => inventory[o]);
    items.sort((a, b) => b.acquired - a.acquired);
    return items;
}

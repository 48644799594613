import { KeyedList, WithRequiredProperty } from "../../common";
import {
    Ability,
    AbilityDamage,
    AbilityEffect,
    AbilityRange,
    ApplicableAbilityEffect,
    CoreAbility,
    DamageType,
    NamedRuleRef,
} from "./common";
import { DamageTypes } from "./creature";

export type SchoolOfMagic =
    | "Abjuration"
    | "Conjuration"
    | "Divination"
    | "Enchantment"
    | "Evocation"
    | "Illusion"
    | "Necromancy"
    | "Transmutation";

export interface SpellComponents {
    v?: boolean;
    s?: boolean;
    m?: {
        content: string;
        cost?: number;
        consume?: boolean;
    };
}

export interface SpellDamage extends AbilityDamage {
    // The amount that should be added for each spell level if cast with a higher level spell slot.
    perLevel?: string;
}

export interface SpellEffect extends AbilityEffect<SpellDamage> {
    damage?: Partial<
        DamageTypes<SpellDamage> & { perLevel?: { choice: DamageType[]; amount: string }; choice?: DamageType[] }
    >;
}

export type SpellRange = AbilityRange;

export function isSpell(spell: any): spell is Spell {
    return spell != null && spell["level"] != null && spell["school"] != null;
}

export interface Spell extends WithRequiredProperty<Ability<SpellEffect>, "time"> {
    displayName?: string;
    source: string;
    level: number;
    school: SchoolOfMagic;
    range: SpellRange;
    components: SpellComponents;
    content: string;
    contentHigherLevel?: string;

    // TODO: This shouldn't be needed when effects is fully fleshed out.
    savingThrow?: CoreAbility[];

    abilityCheck?: CoreAbility[];

    isRitual?: boolean;

    /**
     * Effects that can be applied by this spell.
     * These get merged into the global effects list, and so must have an appropriate name that won't conflict with other effects from the same source.
     */
    applicableEffects?: KeyedList<ApplicableAbilityEffect & Partial<NamedRuleRef>>;
}

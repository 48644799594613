/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, useEffect, useRef } from "react";
import { CampaignPlayer } from "../../store";
import { RtcSession } from "../../webrtc";
import { useUser } from "../contexts";

export const PlayerMedia: FunctionComponent<{ player: CampaignPlayer; stream: MediaStream }> = ({ player, stream }) => {
    const user = useUser();

    const videoRef = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        if (videoRef.current && videoRef.current.srcObject !== stream) {
            videoRef.current.srcObject = stream ? stream : null;
        }
    }, [stream]);

    const hasVideo = !!stream.getVideoTracks().length;
    return (
        <video
            autoPlay
            playsInline
            muted={user.id === player.userId}
            css={{
                width: hasVideo ? RtcSession.idealWidth : 0,
                height: hasVideo ? RtcSession.idealHeight : 0,
                objectFit: "cover",
            }}
            ref={videoRef}
        />
    );
};

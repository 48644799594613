/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Form, InputField, asField, IAsFieldProps } from "../Form";
import { Button } from "../Button";
import { useDispatch, useLocation, useViewport } from "../contexts";
import { addTrack, modifyLocation, modifyTrack, removeTrack } from "../../actions/location";
import { VttMode, useVttApp } from "../common";
import { LocalSearchable } from "../../localsearchable";
import { AudioType, isLocation, SearchableSetting, Location } from "../../store";
import { AnimatePresence } from "framer-motion";
import { AnimatedListItem } from "../motion";
import { ZoneBaseNotesProperties } from "./ZoneProperties";
import { Box } from "../primitives";
import { levelSettings } from "./LevelProperties";
import { RainLevel } from "../LocationStage/Particles/rain";
import { useDebounce } from "../utils";
import { Slider } from "../slider";
import { Point } from "../../position";
import { distanceBetween, pointAlongLine } from "../../grid";
import { getThemeColor } from "../../design/utils";
import { theme } from "../../design";

const tags = ["location"];

const labelSetting: SearchableSetting = {
    id: "label",
    label: "Label",
    tags: tags,
    render: () => <LabelSetting />,
};
const tileWidthSetting: SearchableSetting = {
    id: "tileWidth",
    label: "Tile width (pixels)",
    tags: ["tile", "grid", ...tags],
    render: () => <TileWidthSetting />,
};
const tileHeightSetting: SearchableSetting = {
    id: "tileHeight",
    label: "Tile height (pixels)",
    tags: ["tile", "grid", ...tags],
    render: () => <TileHeightSetting />,
};
const autoTileSetting: SearchableSetting = {
    id: "autoTile",
    label: "Configure grid from points",
    tags: ["background", "map", ...tags],
    render: () => <AutoTileSetting />,
};
const resetFowSetting: SearchableSetting = {
    id: "resetFow",
    label: "Reset fog of war",
    tags: ["fow", ...tags],
    render: () => <ResetFowSetting />,
};
const snowAmountSetting: SearchableSetting = {
    id: "snowAmount",
    label: "Snow",
    tags: ["weather", "winter", ...tags],
    render: () => <SnowAmountSetting />,
};
const rainAmountSetting: SearchableSetting = {
    id: "rainAmount",
    label: "Rain",
    tags: ["weather", ...tags],
    render: () => <RainAmountSetting />,
};
const windSetting: SearchableSetting = {
    id: "wind",
    label: "Wind",
    tags: ["weather", ...tags],
    render: () => <WindSetting />,
};

const weatherSettings: SearchableSetting[] = [rainAmountSetting, snowAmountSetting, windSetting];

const locationSettings: SearchableSetting[] = [
    labelSetting,
    tileWidthSetting,
    tileHeightSetting,
    autoTileSetting,
    resetFowSetting,
    ...weatherSettings,
];
const settings: SearchableSetting[] = [...locationSettings, ...levelSettings];

export const locationSettingsSearch = new LocalSearchable(settings, {
    idField: "id",
    searchableFields: ["label", "tags"],
    toResult: o => () => o.render(),
});

const LabelSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    return (
        <InputField
            label={labelSetting.label}
            disabled={!isLocation(location)}
            value={location?.label}
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                dispatch(
                    modifyLocation(campaign.id, location!.id, {
                        label: e.target.value == null ? undefined : e.target.value,
                    })
                )
            }
        />
    );
};

const TileWidthSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    return (
        <InputField
            variant="number"
            label={tileWidthSetting.label}
            disabled={!isLocation(location)}
            value={isLocation(location) ? location.tileSize.width : ""}
            required
            invalid={
                isLocation(location)
                    ? location.tileSize.width != null && location.tileSize.width <= 0
                        ? "The value must be a positive number."
                        : undefined
                    : undefined
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!isNaN(e.target.valueAsNumber) && e.target.valueAsNumber > 0) {
                    dispatch(
                        modifyLocation(campaign.id, location!.id, {
                            tileSize: {
                                width: e.target.valueAsNumber,
                                height: (location as Location).tileSize.height,
                                isConfigured: true,
                            },
                        })
                    );
                }
            }}
        />
    );
};

const TileHeightSetting = () => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    return (
        <InputField
            variant="number"
            label={tileHeightSetting.label}
            disabled={!isLocation(location)}
            value={isLocation(location) ? location.tileSize.height : ""}
            required
            invalid={
                isLocation(location)
                    ? location.tileSize.height != null && location.tileSize.height <= 0
                        ? "The value must be a positive number."
                        : undefined
                    : undefined
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!isNaN(e.target.valueAsNumber) && e.target.valueAsNumber > 0) {
                    dispatch(
                        modifyLocation(campaign.id, location!.id, {
                            tileSize: {
                                width: (location as Location).tileSize.width,
                                height: e.target.valueAsNumber,
                                isConfigured: true,
                            },
                        })
                    );
                }
            }}
        />
    );
};

const AutoTileSetting = () => {
    const setTool = useVttApp(state => state.setTool);

    // The bulk of this actually takes place in the LocationStage.
    return (
        <Box fullWidth flexDirection="column">
            <Button alignSelf="flex-start" onClick={() => setTool("grid")}>
                Configure grid
            </Button>
        </Box>
    );
};

const ResetFowSetting = () => {
    const { location, api } = useLocation();
    return (
        <Button
            fullWidth
            disabled={!isLocation(location)}
            onClick={() => {
                api.resetFow(location!.id);
            }}>
            Reset fog of war
        </Button>
    );
};

export const SnowAmount: FunctionComponent<{
    onModify: (value: number) => void;
    snowAmount?: number;
    disabled?: boolean;
}> = ({ onModify, snowAmount, disabled }) => {
    const [valueOverride, setValueOverride] = useState<number>();

    const onValueChanged = useDebounce((value: number) => {
        onModify(value);
        setValueOverride(undefined);
    }, 500);

    const value = valueOverride ?? snowAmount ?? 0;
    return (
        <Box flexDirection="column" alignItems="flex-start" flexGrow={1}>
            <Slider
                value={value}
                min={0}
                max={1000}
                disabled={disabled}
                onChange={v => {
                    setValueOverride(v);
                    onValueChanged(v);
                }}
            />
        </Box>
    );
};

export const RainAmount: FunctionComponent<{
    onModify: (value: number) => void;
    rainAmount?: number;
    disabled?: boolean;
}> = ({ onModify, rainAmount, disabled }) => {
    const [valueOverride, setValueOverride] = useState<number>();

    const onValueChanged = useDebounce((value: number) => {
        onModify(value);
        setValueOverride(undefined);
    }, 500);

    const value = valueOverride ?? rainAmount ?? 0;
    return (
        <Box flexDirection="column" alignItems="flex-start" flexGrow={1}>
            <Slider
                value={value}
                min={0}
                max={1000}
                disabled={disabled}
                onChange={v => {
                    setValueOverride(v);
                    onValueChanged(v);
                }}
                marks={{
                    [RainLevel.Light]: "Light",
                    [RainLevel.Heavy]: "Heavy",
                }}
            />
        </Box>
    );
};

const SnowAmountField = asField<HTMLDivElement, IAsFieldProps>(() => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    return (
        <SnowAmount
            disabled={!isLocation(location)}
            snowAmount={isLocation(location) ? location.snowAmount : undefined}
            onModify={value => {
                dispatch(modifyLocation(campaign.id, location!.id, { snowAmount: value }));
            }}
        />
    );
});

const SnowAmountSetting = () => {
    return <SnowAmountField label={snowAmountSetting.label} />;
};

// TODO: Move somewhere better
const ambientRainLightUri = "/rain-light.mp3";
const ambientRainHeavyUri = "/rain-heavy.mp3";

const RainAmountField = asField<HTMLDivElement, IAsFieldProps>(() => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    return (
        <RainAmount
            disabled={!isLocation(location)}
            rainAmount={isLocation(location) ? location.rainAmount : undefined}
            onModify={value => {
                if (isLocation(location)) {
                    // Automatically apply some ambient rain noise.
                    const rainUri =
                        value >= RainLevel.Heavy
                            ? ambientRainHeavyUri
                            : value >= RainLevel.Light
                            ? ambientRainLightUri
                            : undefined;

                    const rain = location.ambientAudio?.tracks["rain"];
                    if (rain && rainUri) {
                        dispatch(
                            modifyTrack(campaign.id, location.id, AudioType.Ambient, "rain", {
                                uri: rainUri,
                            })
                        );
                    } else if (rain) {
                        dispatch(removeTrack(campaign.id, location.id, AudioType.Ambient, "rain"));
                    } else if (rainUri) {
                        dispatch(
                            addTrack(campaign.id, location.id, AudioType.Ambient, {
                                id: "rain",
                                name: "Rain",
                                uri: rainUri,
                                volume: 25,
                            })
                        );
                    }

                    dispatch(modifyLocation(campaign.id, location.id, { rainAmount: value }));
                }
            }}
        />
    );
});

const RainAmountSetting = () => {
    return <RainAmountField label={rainAmountSetting.label} />;
};

const WIND_MAX_VELOCITY = 4;
const WIND_CTRL_WIDTH = 128;
const WIND_INCREMENT = 0.2;

const WindField = asField<HTMLDivElement, IAsFieldProps>(() => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [windOverride, setWindOverrideInternal] = useState<Point>();

    var setWindOverride = (p: Point | undefined) => {
        if (p && distanceBetween({ x: 0, y: 0 }, p) > WIND_MAX_VELOCITY) {
            setWindOverrideInternal(pointAlongLine({ x: 0, y: 0 }, p, WIND_MAX_VELOCITY));
        } else {
            setWindOverrideInternal(p);
        }
    };

    const [isHoverReset, setIsHoverReset] = useState<boolean>(false);

    const wind = windOverride ?? (isLocation(location) ? location.wind : undefined);
    useEffect(() => {
        if (canvasRef.current) {
            var context = canvasRef.current.getContext("2d");
            if (context) {
                context.clearRect(0, 0, WIND_CTRL_WIDTH, WIND_CTRL_WIDTH);

                const windX =
                    (wind != null ? (wind.x / WIND_MAX_VELOCITY) * (WIND_CTRL_WIDTH / 2) : 0) + WIND_CTRL_WIDTH / 2;
                const windY =
                    (wind != null ? (wind.y / WIND_MAX_VELOCITY) * (WIND_CTRL_WIDTH / 2) : 0) + WIND_CTRL_WIDTH / 2;
                if (wind) {
                    context.beginPath();
                    context.lineCap = "round";
                    context.moveTo(WIND_CTRL_WIDTH / 2, WIND_CTRL_WIDTH / 2);
                    context.lineTo(windX, windY);
                    context.strokeStyle = getThemeColor(theme.colors.grayscale[3]);
                    context.lineWidth = theme.space[1];
                    context.stroke();
                    context.closePath();
                }

                context.beginPath();
                context.fillStyle = getThemeColor(isHoverReset ? theme.colors.grayscale[6] : theme.colors.grayscale[7]);
                context.ellipse(
                    WIND_CTRL_WIDTH / 2,
                    WIND_CTRL_WIDTH / 2,
                    theme.space[2],
                    theme.space[2],
                    0,
                    0,
                    Math.PI * 2
                );
                context.fill();
                context.closePath();

                if (wind) {
                    context.beginPath();
                    context.fillStyle = getThemeColor(theme.colors.grayscale[3]);
                    context.ellipse(windX, windY, theme.space[1], theme.space[1], 0, 0, Math.PI * 2);
                    context.fill();
                    context.closePath();
                }
            }
        }
    }, [wind, isHoverReset]);

    const disabled = !isLocation(location);
    return (
        <Box
            borderRadius={WIND_CTRL_WIDTH / 2}
            tabIndex={disabled ? undefined : 0}
            bg="grayscale.9"
            width={WIND_CTRL_WIDTH}
            height={WIND_CTRL_WIDTH}
            css={{
                border: `2px solid ${theme.colors.grayscale[7]}`,
                cursor: "pointer",
                outline: "0",
                overflow: "hidden",
                pointerEvents: disabled ? "none" : undefined,
                opacity: disabled ? 0.2 : undefined,
                ":focus": {
                    border: `2px solid ${theme.colors.guidance.focus}`,
                },
            }}
            onPointerDown={e => {
                if (isHoverReset) {
                    dispatch(modifyLocation(campaign.id, location!.id, { wind: windOverride }));
                } else {
                    e.currentTarget.setPointerCapture(e.pointerId);
                    const x =
                        ((e.nativeEvent.offsetX - WIND_CTRL_WIDTH / 2) / (WIND_CTRL_WIDTH / 2)) * WIND_MAX_VELOCITY;
                    const y =
                        ((e.nativeEvent.offsetY - WIND_CTRL_WIDTH / 2) / (WIND_CTRL_WIDTH / 2)) * WIND_MAX_VELOCITY;
                    setWindOverride({ x, y });
                }
            }}
            onPointerMove={e => {
                if (e.currentTarget.hasPointerCapture(e.pointerId)) {
                    const x =
                        ((e.nativeEvent.offsetX - WIND_CTRL_WIDTH / 2) / (WIND_CTRL_WIDTH / 2)) * WIND_MAX_VELOCITY;
                    const y =
                        ((e.nativeEvent.offsetY - WIND_CTRL_WIDTH / 2) / (WIND_CTRL_WIDTH / 2)) * WIND_MAX_VELOCITY;
                    setWindOverride({ x, y });
                }

                setIsHoverReset(
                    windOverride == null &&
                        distanceBetween(
                            { x: WIND_CTRL_WIDTH / 2, y: WIND_CTRL_WIDTH / 2 },
                            { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
                        ) <= theme.space[2]
                );
            }}
            onPointerUp={e => {
                if (windOverride) {
                    dispatch(modifyLocation(campaign.id, location!.id, { wind: windOverride }));
                    setWindOverride(undefined);
                }
            }}
            onKeyDown={e => {
                switch (e.key) {
                    case "A":
                    case "a":
                    case "ArrowLeft": {
                        setWindOverride({ x: (wind?.x ?? 0) - WIND_INCREMENT, y: wind?.y ?? 0 });
                        break;
                    }
                    case "D":
                    case "d":
                    case "ArrowRight": {
                        setWindOverride({ x: (wind?.x ?? 0) + WIND_INCREMENT, y: wind?.y ?? 0 });
                        break;
                    }
                    case "W":
                    case "w":
                    case "ArrowUp": {
                        setWindOverride({ x: wind?.x ?? 0, y: (wind?.y ?? 0) - WIND_INCREMENT });
                        break;
                    }
                    case "S":
                    case "s":
                    case "ArrowDown": {
                        setWindOverride({ x: wind?.x ?? 0, y: (wind?.y ?? 0) + WIND_INCREMENT });
                        break;
                    }
                    case "Backspace":
                    case "Clear":
                    case "Delete": {
                        dispatch(modifyLocation(campaign.id, location!.id, { wind: undefined }));
                        setWindOverride(undefined);
                    }
                }
            }}
            onKeyUp={() => {
                if (windOverride) {
                    dispatch(modifyLocation(campaign.id, location!.id, { wind: windOverride }));
                }
            }}
            onBlur={() => {
                setWindOverride(undefined);
            }}>
            <canvas ref={canvasRef} width={WIND_CTRL_WIDTH} height={WIND_CTRL_WIDTH} />
        </Box>
    );
});

const WindSetting = () => {
    const { location } = useLocation();
    return (
        <WindField
            disabled={!isLocation(location)}
            label={windSetting.label}
            hint="Click or use arrow keys to change the wind direction. Click on the center or use the delete key to reset."
        />
    );
};

export const PlayerBarLocationNotes: FunctionComponent<{ mode: VttMode }> = ({ mode }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    const viewport = useViewport();
    const onChange = useCallback(
        change => dispatch(modifyLocation(campaign.id, location!.id, change)),
        [dispatch, campaign.id, location]
    );

    return (
        <Form fullWidth>
            {labelSetting.render()}
            <ZoneBaseNotesProperties zone={location} mode={mode} dispatch={onChange} viewport={viewport} />
        </Form>
    );
};

export const PlayerBarLocationProperties: FunctionComponent<{}> = () => {
    return (
        <Form fullWidth pb={3}>
            <AnimatePresence>
                {locationSettings
                    .filter(o => o !== labelSetting)
                    .map((o, i) => (
                        <AnimatedListItem layout="position" key={o.id} index={i} fullWidth className="form__field">
                            {o.render()}
                        </AnimatedListItem>
                    ))}
            </AnimatePresence>
        </Form>
    );
};

import { RootState } from "@react-three/fiber";
import { DistanceFieldGenerator } from "gpu-distance-field";
import { ReadonlyPath, ReadonlyPaths } from "js-angusj-clipper";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
    CanvasTexture,
    LinearMipMapNearestFilter,
    OrthographicCamera,
    Path,
    PerspectiveCamera,
    Raycaster,
    Scene,
    ShaderChunk,
    Shape,
    ShapeGeometry,
    Texture,
    Vector2,
} from "three";
import { PathResult } from "../../astar";
import { ILocalGrid, ILocalGridWithRef, localPoint, screenPoint, screenRect } from "../../grid";
import { GridPosition, LocalPixelPosition, Point, ScreenPixelPosition, Size } from "../../position";
import { Token, WithLevel } from "../../store";
import { haveDepsChanged, useForceUpdate } from "../utils";
import { LocationLevelRenderInfo } from "./contexts";

export enum ZIndexes {
    Background = -2,
    Underlay = -1,
    Floor = 0,
    Tokens = 1,
    Annotations = 6,
    Overlay = 7,
    UserInterface = 8,
    PriorityInterface = 9,
}

export interface HoverTracking {
    isHovering: boolean;
    setHoverPart?: (partId: string, isHovering: boolean) => void; // TODO: Include zindex here so we can rank different parts and find the actual overed parts.
}

export interface TokenWithLocalState extends Token {
    isDragging?: boolean;

    /**
     * The current drag position.
     */
    dragPos?: WithLevel<LocalPixelPosition>;

    /**
     * Current grid path during a drag operation.
     */
    currentGridPath?: PathResult<WithLevel<GridPosition>>;

    /**
     * Current level during a drag operation.
     */
    currentLevel?: string;

    waypoints?: PathResult<WithLevel<GridPosition>>[];
}

export function useHoverTracking(id: string, disable?: boolean): HoverTracking {
    // TODO: Use id to work out whether the component is being hovered over in a more global sense.

    const forceUpdate = useForceUpdate();
    const hoveredParts = useMemo<string[]>(() => {
        return [];
    }, []);
    if (disable) {
        hoveredParts.length = 0;
    }

    const setHoverPart = useCallback(
        (partId: string, isHovering: boolean) => {
            const i = hoveredParts.indexOf(partId);
            if (isHovering) {
                if (i < 0) {
                    hoveredParts.push(partId);
                }
            } else {
                if (i >= 0) {
                    hoveredParts.splice(i, 1);
                }
            }

            setTimeout(() => forceUpdate(), 0);
        },
        [hoveredParts, forceUpdate]
    );

    return {
        isHovering: hoveredParts.length > 0,
        setHoverPart: setHoverPart,
    };
}

export function clipperPathsToShapeGeometry(paths: ReadonlyPaths, holes?: ReadonlyPaths) {
    const shapes: Shape[] = [];
    for (let i = 0; i < paths.length; i++) {
        const ring = paths[i];
        const shape = new Shape();
        shape.moveTo(ring[0].x, -ring[0].y);
        for (let j = 0; j < ring.length; j++) {
            shape.lineTo(ring[j].x, -ring[j].y);
        }

        if (holes) {
            const hole = holes[i];
            const path = new Path();
            path.moveTo(hole[0].x, -hole[0].y);
            for (let j = 0; j < hole.length; j++) {
                path.lineTo(hole[j].x, -hole[j].y);
            }

            shape.holes.push(path);
        }

        shapes.push(shape);
    }

    return new ShapeGeometry(shapes);
}

export function getPixel(imagedata: ImageData, x: number, y: number) {
    var position = (x + imagedata.width * y) * 4;
    var data = imagedata.data;
    return { r: data[position], g: data[position + 1], b: data[position + 2], a: data[position + 3] };
}

export function segmentsToClipperPoly(segments: [Point, Point][]): ReadonlyPath {
    return segments.flatMap(o => [
        { x: Math.round(o[0].x), y: Math.round(o[0].y) },
        { x: Math.round(o[1].x), y: Math.round(o[1].y) },
    ]);
}

export interface SingleLevelInfoBasic {
    texture?: Texture;
    size?: Size;
    error?: Error;
}

export interface SingleLevelInfo extends SingleLevelInfoBasic {
    height: number;
    sources: Set<TokenWithLocalState>;

    // This is basically a ref, it receives the information as the level renders and should only be accessed
    // in a useFrame (i.e. during webgl rendering). To get this info within react rendering for the current
    // level, use useRenderedLocationLevel().
    renderInfo?: LocationLevelRenderInfo;
}

export interface LevelInfo {
    [levelKey: string]: SingleLevelInfo;
}

export const basicVertexShader =
    ShaderChunk.common +
    "\n" +
    ShaderChunk.logdepthbuf_pars_vertex +
    `
varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    ` +
    ShaderChunk.logdepthbuf_vertex +
    `
}`;

export const commonShaderUniforms = `
uniform float u_backgroundWidth;
uniform float u_backgroundHeight;
uniform float u_backgroundX;
uniform float u_backgroundY;
uniform vec2 u_canvasSize;
uniform float u_scale;
uniform vec2 u_offset;
`;

export const commonShaderFunctions = `
const float BASE = 255.;
const float BASE_2 = BASE * BASE;
const float BASE_3 = BASE * BASE * BASE;

// Returns the distance value stored in the distance
// field at a particular uv coordinate.
float getDistanceValue(in sampler2D sdf, in float sdfSize, in vec2 localPoint) {
    vec2 uv = vec2((localPoint.x - u_backgroundX) / u_backgroundWidth, 1.0 - ((localPoint.y - u_backgroundY) / u_backgroundHeight));
    vec4 value = texture2D(sdf, uv) * BASE;
    return ((value.x * BASE_2 + value.y * BASE + value.z - BASE_3 / 2.) / 1000.) * (u_backgroundWidth / sdfSize);
}

float random(vec2 st)
{
    return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
}

vec2 toScreenPoint(in vec2 localPoint) {
    return vec2(u_offset + (localPoint * u_scale));
}

vec2 toLocalPoint(in vec2 screenPos) {
    return vec2(screenPos - u_offset) / u_scale;
}

vec2 fragCoordToScreenPoint(in vec2 fragCoord) {
    return vec2(fragCoord.x, u_canvasSize.y - fragCoord.y);
}

vec2 fragCoordToLocalPoint(in vec2 fragCoord) {
    return toLocalPoint(fragCoordToScreenPoint(fragCoord));
}

// Given a screen point, returns a point that can be used to sample that point using texture2D with a texture the same size as the canvas.
vec2 screenPointToTexturePoint(in vec2 screenPoint) {
    return vec2(screenPoint.x / u_canvasSize.x, 1.0 - (screenPoint.y / u_canvasSize.y));
}

// Given a local point, returns a point that can be used to sample that point using texture2D with a texture the same size as the canvas.
vec2 localPointToTexturePoint(in vec2 localPoint) {
    return screenPointToTexturePoint(toScreenPoint(localPoint));
}
`;

// Returns the distance from a particular coordinate
// on the canvas to the nearest shape, as determined
// by the distance field.
//
// `pixels` is the output of calling
// `DistanceFieldGenerator.getPixels()`.
function getDistanceFromPixels(pixels: Uint8Array, canvasWidth: number, canvasHeight: number, x: number, y: number) {
    // In WebGL the y-coordinate increases as you go up
    // on your screen and in the DOM the y-coordinate
    // decreases as you go up on your screen. `pixels`
    // came right out of a WebGL texture, we need to
    // flip it.
    const flippedY = canvasHeight - y;

    // `pixels` is an array of r, g, b, a values between
    // 0 and 255. Figure out the index of the red value in
    // the pixel we want.
    const BYTES_PER_PIXEL = 4;
    const pixelsPerRow = canvasWidth * BYTES_PER_PIXEL;
    const redPixelIndex = flippedY * pixelsPerRow + x * BYTES_PER_PIXEL;

    // Pull out the values of the red pixel, along with
    // green and blue. alpha isn't used.
    const r = pixels[redPixelIndex];
    const g = pixels[redPixelIndex + 1];
    const b = pixels[redPixelIndex + 2];

    // The rgb values of each pixel store the distance as
    // a base 255 number added to BASE ^ 3 / 2 and multiplied
    // by 1000.
    const BASE = 255;
    const BASE_2 = BASE * BASE;
    const BASE_3 = BASE_2 * BASE;
    return (r * BASE_2 + g * BASE + b - BASE_3 / 2) / 1000;
}

interface DistanceFieldOptions {
    width: number | undefined;
    height: number | undefined;

    /**
     * By default the input will be white and you draw black onto it - the distance field will be with respect to
     * the black portion. If invert is set to true, this is reversed - the input will be black and you draw white
     * onto it.
     */
    invert?: boolean;
    disabled?: boolean;
    onDraw: (ctx: CanvasRenderingContext2D, size: { desired: Size; actual: Size }) => boolean;

    debug?: boolean;
}

// This generates a 2D SDF (signed distance field) canvas, where each pixel contains the distance to the nearest
// non-white pixel.
export function useDistanceField(options: DistanceFieldOptions, deps: any[]): Texture | undefined {
    const sdfGeneratorRef = useRef<DistanceFieldGenerator>();
    const sdfInput = useMemo(() => document.createElement("canvas"), []);
    const sdfCanvasRef = useRef<HTMLCanvasElement>();
    const sdfRef = useRef<Texture>();

    const size = useMemo<{ desired: Size; actual?: Size } | undefined>(() => {
        if (options.width != null && options.height != null) {
            return { desired: { width: options.width, height: options.height } };
        }

        return undefined;
    }, [options.width, options.height]);

    const prevDeps = useRef<any[]>();
    useEffect(() => {
        return () => {
            if (sdfGeneratorRef.current) {
                sdfGeneratorRef.current.destroy();
            }
        };
    }, []);

    if (!options.disabled) {
        let isInitialising = false;
        if (!sdfGeneratorRef.current && size) {
            sdfGeneratorRef.current = new DistanceFieldGenerator();
            isInitialising = true;

            if (options.debug) {
                document.body.append(sdfInput);
                sdfInput.style.position = "absolute";
                sdfInput.style.top = "0";
                sdfInput.style.left = "0";
                sdfInput.style.width = "1000px";
                sdfInput.style.height = Math.round((1000 / size.desired.width) * size.desired.height) + "px";
                sdfInput.onpointermove = e => {
                    if (size.actual) {
                        const pixels = sdfGeneratorRef.current?.getPixels();
                        if (pixels) {
                            const x = Math.round((e.clientX / sdfInput.clientWidth) * size.actual.width);
                            const y = Math.round((e.clientY / sdfInput.clientHeight) * size.actual.height);
                            const distance = getDistanceFromPixels(pixels, size.actual.width, size.actual.height, x, y);
                            console.log("Distance: " + distance);
                        }
                    }
                };
            }
        }

        if (size && (!size.actual || size.actual.width !== sdfInput.width || size.actual.height !== sdfInput.height)) {
            sdfInput.width = options.width!;
            sdfInput.height = options.height!;

            // Access some internals of the field generator to force it to update the new size, so we can read
            // the actual size back out.
            sdfGeneratorRef.current!["_resizeOutputCanvasAndTextures"](sdfInput.width, sdfInput.height);
            const gl: WebGLRenderingContext = sdfGeneratorRef.current!["_gl"].gl;

            size.actual = { width: gl.drawingBufferWidth, height: gl.drawingBufferHeight };

            if (size.actual.width !== sdfInput.width || size.actual.height !== sdfInput.height) {
                sdfInput.width = size.actual.width;
                sdfInput.height = size.actual.height;
            }
        }

        if ((isInitialising || haveDepsChanged(prevDeps.current, deps)) && size?.actual) {
            var ctx = sdfInput.getContext("2d")!;
            ctx.fillStyle = options.invert ? "black" : "white";
            ctx.lineWidth = 2;
            ctx.fillRect(0, 0, size.actual.width, size.actual.height);
            ctx.fillStyle = options.invert ? "white" : "black";

            if (options.onDraw(ctx, size as { desired: Size; actual: Size })) {
                sdfGeneratorRef.current!.generateSDF(sdfInput);
                const sdfOutput = sdfGeneratorRef.current!.outputCanvas();
                if (sdfOutput !== sdfCanvasRef.current) {
                    sdfRef.current = new CanvasTexture(
                        sdfOutput,
                        undefined,
                        undefined,
                        undefined,
                        LinearMipMapNearestFilter,
                        LinearMipMapNearestFilter
                    );
                    sdfRef.current.generateMipmaps = false;
                    sdfCanvasRef.current = sdfOutput;
                } else if (sdfRef.current) {
                    sdfRef.current.needsUpdate = true;
                }
            }
        }
    } else {
        if (sdfGeneratorRef.current) {
            sdfGeneratorRef.current.destroy();
            sdfGeneratorRef.current = undefined;
            sdfInput.width = 0;
            sdfInput.height = 0;
        }

        sdfRef.current = undefined;
        sdfCanvasRef.current = undefined;
    }

    prevDeps.current = deps;
    return sdfRef.current;
}

export function toDeviceNormalised(point: ScreenPixelPosition, canvasSize: Size | Vector2): Point {
    return {
        x: (point.x / canvasSize.width) * 2 - 1,
        y: -(point.y / canvasSize.height) * 2 + 1,
    };
}

const raycaster = new Raycaster();

export function clientPosToLocalPoint(
    clientX: number,
    clientY: number,
    localGrid: ILocalGrid,
    threeGet?: () => RootState
): LocalPixelPosition;
export function clientPosToLocalPoint(
    clientX: number,
    clientY: number,
    localGrid: ILocalGrid,
    camera?: PerspectiveCamera | OrthographicCamera,
    scene?: Scene,
    size?: Size
): LocalPixelPosition;
export function clientPosToLocalPoint(
    clientX: number,
    clientY: number,
    localGrid: ILocalGrid,
    camera: PerspectiveCamera | OrthographicCamera | (() => RootState) | undefined,
    scene?: Scene,
    size?: Size
): LocalPixelPosition {
    if (typeof camera === "function") {
        const state = camera();
        camera = state.camera;
        scene = state.scene;

        // If the element calling this is inside a portal, then it uses its own scene that has its parent set to the main scene.
        // We always want to use the main scene, so we go up until there's no parent scene and use that.
        while (scene && scene.parent) {
            scene = scene?.parent as Scene;
        }

        size = state.size;
    }

    let point: LocalPixelPosition | undefined;
    if (scene && size && camera?.type === "PerspectiveCamera") {
        var mouse = new Vector2();
        mouse.x = (clientX / size.width) * 2 - 1;
        mouse.y = -(clientY / size.height) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);

        const intersections = raycaster.intersectObjects(scene.children);
        if (intersections.length) {
            point = localPoint(intersections[0].point.x, -intersections[0].point.y);
        } else {
            // The point didn't intersect anything - really we should have some kind of infinite plane here to
            // intercept anything else and give it the correct position, or else allow returning undefined.
        }
    }

    if (!point) {
        point = localGrid.toLocalPoint(screenPoint(clientX, clientY));
    }

    return point;
}

export function pointerEventToLocalPoint(
    e: React.MouseEvent | React.PointerEvent | PointerEvent,
    localGrid: ILocalGrid,
    threeGet?: () => RootState
);
export function pointerEventToLocalPoint(
    e: React.MouseEvent | React.PointerEvent | PointerEvent,
    localGrid: ILocalGrid,
    camera?: PerspectiveCamera | OrthographicCamera,
    scene?: Scene
);
export function pointerEventToLocalPoint(
    e: React.MouseEvent | React.PointerEvent | PointerEvent,
    localGrid: ILocalGrid,
    camera: PerspectiveCamera | OrthographicCamera | (() => RootState) | undefined,
    scene?: Scene,
    size?: Size
) {
    if (typeof camera === "function") {
        return clientPosToLocalPoint(e.clientX, e.clientY, localGrid, camera);
    }

    return clientPosToLocalPoint(e.clientX, e.clientY, localGrid, camera, scene, size);
}

export enum VttCameraLayers {
    /**
     * Visible in all modes.
     */
    Default = 0,

    /**
     * Visible in all modes, but not to the raycaster.
     */
    DefaultNoRaycasting = 1,

    /**
     * Visible only in perspective mode.
     */
    PerspectiveOnly = 2,

    /**
     * Visible only in orthographic mode.
     */
    OrthographicOnly = 3,

    /**
     * Visible when rendering lighting for perspective mode. This could either be in perspective mode, or in orthographic mode
     * for lighting particles, which always render using a perspective camera.
     */
    PerspectiveLighting = 4,
}

export enum RenderOrder {
    Init = 0,
    VisibilityAndLighting = 1,
    BeforeMain = 2,
    BackgroundAndMain = 3,
    BeforeFinalComposite = 4,
    FinalComposite = 10,
    Cleanup = 11,
}

export function cameraDistanceToFit(camera: PerspectiveCamera, grid: ILocalGridWithRef, canvasSize: Size) {
    const viewport = grid.toLocalRect(screenRect(0, 0, canvasSize.width, canvasSize.height));
    const w = viewport.width / camera.fov;
    const h = viewport.height;

    // Convert camera fov degrees to radians
    var fov = camera.fov * (Math.PI / 180);

    // Calculate the camera distance
    var distance = Math.abs(Math.max(w, h) / 2 / Math.sin(fov / 2));
    return distance * 0.906; // TODO: Not sure why this works, but... it does.
}

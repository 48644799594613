import { white, black, focus, colour, getTheme, applyTheme } from "./palettes";
import {
    breakpoints,
    fonts,
    fontSizes,
    fontWeights,
    letterSpacings,
    lineHeights,
    radii,
    boxShadows,
    space,
    textStyles,
    densities,
} from "./tokens";
import { getThemeColorTiny } from "./utils";

const excludes = ["lightGrayscale", "darkGrayscale"];
let secondary = {};
Object.keys(colour).forEach(k => {
    if (excludes.includes(k)) {
        return;
    }

    const c = colour[k];
    secondary[k] = c;
});

// Construct a base colors object to use in a theme.
const guidance: GuidanceColours = {
    info: [colour.blues[0], colour.blues[8]],
    error: [colour.reds[0], colour.reds[8]],
    warning: [colour.oranges[0], colour.oranges[8]],
    success: [colour.greens[0], colour.greens[8]],
    focus,
};
const baseColors = {
    // Primary palettes
    black,
    white,

    ...(secondary as SecondaryColours),

    // Guidance palettes
    guidance,
};

// Construct a base theme with values we want.
const tokens = {
    breakpoints,
    fonts,
    fontSizes,
    fontWeights,
    letterSpacings,
    lineHeights,
    radii,
    boxShadows,
    space,
    textStyles,
    densities,
};

interface GuidanceColours {
    info: [string, string];
    error: [string, string];
    warning: [string, string];
    success: [string, string];
    focus: string;
}

interface SecondaryColours {
    grayscale: string[];
    accent: string[];
    blues: string[];
    cyans: string[];
    greens: string[];
    yellows: string[];
    oranges: string[];
    reds: string[];
    purples: string[];
    violets: string[];
}

export interface Colours extends SecondaryColours {
    black: string;
    white: string;
    guidance: GuidanceColours;
    foreground: string;
    background: string;
}

export interface ColoursWithTransparency extends Colours {
    transparency: [Colours, Colours, Colours, Colours, Colours, Colours];
    transparent: "transparent";
}

export type BaseTheme = {
    colors: ColoursWithTransparency;
    breakpoints: string[];
    fonts: { [key: string]: string };
    fontSizes: number[];
    fontWeights: { [key: string]: number };
    letterSpacings: { [key: string]: string };
    lineHeights: number[];
    radii: (number | string)[];
    boxShadows: string[];
    space: number[];
    textStyles: { [key: string]: { [key: string]: string } };
    densities: { [key: string]: number };
};

function withOpacity(color: string, opacity: number) {
    const c = getThemeColorTiny(color)?.setAlpha(opacity);
    if (!c) {
        return color;
    }

    const format = c.getFormat();
    if (format === "hex") {
        return c.toHex8String();
    }

    return c.toString(format as any);
}

function toTransparent(colours: Colours, opacity: number): Colours {
    const transparentColours = Object.assign({}, colours);

    for (let key in colours) {
        let value = colours[key];
        if (typeof value === "string") {
            // Convert colour
            transparentColours[key] = withOpacity(value, opacity);
        } else if (Array.isArray(value)) {
            // Array of strings
            transparentColours[key] = value.map(o => withOpacity(o, opacity));
        } else if (value) {
            const guidance = value as GuidanceColours;
            transparentColours[key] = {
                focus: withOpacity(guidance.focus, opacity),
                success: [withOpacity(guidance.success[0], opacity), withOpacity(guidance.success[1], opacity)],
                info: [withOpacity(guidance.info[0], opacity), withOpacity(guidance.info[1], opacity)],
                warning: [withOpacity(guidance.warning[0], opacity), withOpacity(guidance.warning[1], opacity)],
                error: [withOpacity(guidance.error[0], opacity), withOpacity(guidance.error[1], opacity)],
            } as GuidanceColours;
        }
    }

    return transparentColours;
}

function withTransparency(colours: Colours): ColoursWithTransparency {
    return {
        ...colours,
        transparent: "transparent",
        transparency:
            window.process?.env?.JEST_WORKER_ID === undefined
                ? [
                      toTransparent(colours, 0.8),
                      toTransparent(colours, 0.6),
                      toTransparent(colours, 0.4),
                      toTransparent(colours, 0.2),
                      toTransparent(colours, 0.05),
                      toTransparent(colours, 0),
                  ]
                : [colours, colours, colours, colours, colours, colours],
    };
}

export const theme: BaseTheme = {
    ...tokens,
    colors: withTransparency({ ...baseColors, ...getTheme("dark") }),
};

applyTheme(null, theme.colors);

export { randomA11y, randomColor, getTheme, themeDefinitions, applyTheme } from "./palettes";

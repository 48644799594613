import { Annotation, getAnnotationPos, isLineAnnotation, isRectAnnotation } from "../annotations";
import {
    arrayToTracks,
    AudioTrack,
    AudioTrackWithOptionalId,
    AudioTrackWithIndex,
    AudioType,
    Location,
    LocationPlayer,
    Playlist,
    Token,
    tracksToArray,
    SessionConnection,
    CombatParticipant,
    CombatEncounter,
    resolveToken,
    Campaign,
    Zone,
    createZone,
    AudioTrackWithId,
    LocationLevel,
    ResolvedToken,
} from "../store";
import {
    LocationAction,
    isTokensAction,
    isAnnotationAction,
    isLocationPlayerAction,
    LocationPlayerAction,
    LocationTrackAction,
    isZoneAction,
    ActionScope,
    CampaignAction,
    isLocationLevelAction,
} from "../actions/common";
import { copyState, reduceDictionary } from "./common";
import { reduceToken } from "./token";
import annotationReducer from "./annotation";
import zoneReducer from "./zone";
import { arrayMove } from "@dnd-kit/sortable";
import { LocalPixelPosition, Size } from "../position";
import { addToKeyedList, KeyedList, keyedListToKeyArray } from "../common";

const locationPlayerReducer = (state: LocationPlayer | undefined, action: LocationPlayerAction) => {
    return state;
};

const audioTrackReducer = (state: AudioTrackWithIndex | undefined, action: LocationTrackAction) => {
    return copyState(state, action.payload as Partial<AudioTrackWithIndex>);
};

export function reduceNextCombatTurn(state: Location, campaign: Campaign) {
    if (state.combat) {
        const combat = state.combat;
        let round = combat.round ?? 0;

        const participants = getCombatParticipants(combat, campaign, state);

        let current = combat.turn;
        let turn: number;
        do {
            // eslint-disable-next-line no-loop-func
            turn = participants.findIndex(o => o.tokenId === current) + 1;
            if (turn >= Object.keys(combat.participants).length) {
                turn = 0;
                round++;
            }

            current = participants[turn].tokenId;
        } while (!participants[turn].token);

        state = copyState(state, { combat: copyState(combat, { round: round, turn: participants[turn].tokenId }) });
    }

    return state;
}

function reduceCombatParticipants(state: CombatEncounter, location: Location, participants: string[]): CombatEncounter {
    const missingParticipants = participants.filter(o => !state.participants[o]);
    if (missingParticipants.length > 0) {
        const combatParticipants = Object.assign({}, state.participants);
        const combat = Object.assign({}, state, { participants: combatParticipants });
        missingParticipants.reduce<CombatParticipant>((p, c) => {
            if (location.tokens[c]) {
                p[c] = {};
            }

            return p;
        }, combat.participants);
        return combat;
    }

    return state;
}

export function compareInitiatives(a: CombatParticipant, b: CombatParticipant) {
    let i = 0;
    let as = 0;
    let bs = 0;
    while (
        as - bs === 0 &&
        ((a.initiative ? a.initiative[i] : undefined) != null || (b.initiative ? b.initiative[i] : undefined) != null)
    ) {
        as = (a.initiative ? a.initiative[i] : undefined) ?? 0;
        bs = (b.initiative ? b.initiative[i] : undefined) ?? 0;
        i++;
    }

    return bs - as;
}

export function getCombatParticipants(combat: CombatEncounter, campaign: Campaign, location: Location) {
    const participants: ({ token?: ResolvedToken; tokenId?: string } & CombatParticipant)[] = Object.keys(
        combat.participants
    ).map(o => {
        // Look up the actual token.
        const token = location.tokens[o] ? resolveToken(campaign, location.tokens[o]) : undefined;
        return Object.assign({}, { token: token, tokenId: o }, combat.participants[o]);
    }) as ({ token?: ResolvedToken; tokenId?: string } & CombatParticipant)[];
    participants.sort((a, b) => compareInitiatives(a, b));
    return participants;
}

const locationLevelReducer = (
    state: LocationLevel | undefined,
    action: CampaignAction,
    location: Location,
    sessionConnection: SessionConnection,
    isTargetted: boolean
) => {
    if (action.type === "ModifyLocationLevel") {
        return copyState(state, action.payload);
    } else if (action.type === "ApplyGridPlacement") {
        const gridPlacement = action.payload as { tileSize?: Size; backgroundImagePos?: LocalPixelPosition };
        return copyState(state, { backgroundImagePos: gridPlacement.backgroundImagePos });
    }

    return state;
};

const locationReducer = (state: Location | undefined, action: CampaignAction, sessionConnection: SessionConnection) => {
    if (!state) {
        return state;
    }

    if (isTokensAction(action)) {
        const tokens = reduceDictionary(
            state.tokens,
            (s, a) => reduceToken(s, a, state!, sessionConnection, true),
            action,
            action.props.tokens
        );
        if (tokens !== state.tokens) {
            state = copyState(state, { tokens: tokens });
        }
    }

    if (isAnnotationAction(action)) {
        if (action.type === "ConvertToZone") {
            const annotation = state.annotations[action.props.annotationId];
            if (annotation && annotation.pos) {
                const pos = getAnnotationPos(annotation, sessionConnection.session!.campaign, state, action.payload);
                const zone = createZone(state, pos, []);

                // TODO: Adjust points for rotation? Or include rotation in zone?
                if (isRectAnnotation(annotation)) {
                    zone.points.push(
                        { x: pos.x - annotation.width / 2, y: pos.y - annotation.height / 2 },
                        { x: pos.x + annotation.width / 2, y: pos.y - annotation.height / 2 },
                        { x: pos.x + annotation.width / 2, y: pos.y + annotation.height / 2 },
                        { x: pos.x - annotation.width / 2, y: pos.y + annotation.height / 2 }
                    );
                } else if (isLineAnnotation(annotation)) {
                    zone.points.push(...annotation.points);
                }

                const annotations = Object.assign({}, state.annotations);
                delete annotations[action.props.annotationId];
                const zones = Object.assign({}, state.zones);
                zones[zone.id] = zone;
                return copyState(state, { annotations: annotations, zones: zones });
            }
        } else {
            const annotations = reduceDictionary(
                state.annotations,
                (s, a) => annotationReducer(s, a, state!, sessionConnection, true),
                action,
                [action.props.annotationId]
            );
            if (annotations !== state.annotations) {
                state = copyState(state, { annotations: annotations });
            }
        }
    }

    if (isLocationLevelAction(action)) {
        const levels = reduceDictionary(
            state.levels,
            (s, a) => locationLevelReducer(s, a, state!, sessionConnection, true),
            action,
            [action.props.levelId]
        );
        if (levels !== state.levels) {
            state = copyState(state, { levels: levels });
        }
    }

    if (action.type === "ModifyLocation") {
        return copyState(state, action.payload);
    } else if (action.type === "AddToken") {
        const token = action.payload as Token;
        return copyState(state, { tokens: Object.assign({}, state.tokens, { [token.id]: token }) });
    } else if (action.type === "DeleteItems") {
        let deletedItems = action.payload as string[];
        let tokens: typeof state.tokens = {};
        let changed = false;
        for (let tokenId in state.tokens) {
            if (deletedItems.indexOf(tokenId) < 0) {
                tokens[tokenId] = state.tokens[tokenId];
            } else {
                changed = true;
            }
        }

        let annotations: typeof state.annotations = {};
        for (let annotationId in state.annotations) {
            if (deletedItems.indexOf(annotationId) < 0) {
                annotations[annotationId] = state.annotations[annotationId];
            } else {
                changed = true;
            }
        }

        let zones: typeof state.zones = {};
        for (let zoneId in state.zones) {
            if (deletedItems.indexOf(zoneId) < 0) {
                zones[zoneId] = state.zones[zoneId];
            } else {
                changed = true;
            }
        }

        if (changed) {
            state = changed ? copyState(state, { tokens: tokens, annotations: annotations, zones: zones }) : state;
        }
    } else if (action.type === "AddAnnotation") {
        const annotation = action.payload as Annotation;
        return copyState(state, { annotations: Object.assign({}, state.annotations, { [annotation.id]: annotation }) });
    } else if (action.type === "AddAudioTrack") {
        const trackToAdd = action.payload as AudioTrack;
        const type = (action as LocationAction).props.playlistType as AudioType;

        let oldPlaylist: Playlist | undefined;
        if (type === AudioType.Music) {
            oldPlaylist = state.music;
        } else if (type === AudioType.Ambient) {
            oldPlaylist = state.ambientAudio;
        }

        const tracks: AudioTrackWithOptionalId[] = tracksToArray(oldPlaylist?.tracks);
        tracks.push(trackToAdd);
        const newPlaylist = oldPlaylist
            ? copyState(oldPlaylist, { tracks: arrayToTracks(tracks) })
            : { tracks: arrayToTracks(tracks) };

        let newState: Partial<Location>;
        if (type === AudioType.Music) {
            newState = { music: newPlaylist };
        } else if (type === AudioType.Ambient) {
            newState = { ambientAudio: newPlaylist };
        } else {
            return state;
        }

        return copyState(state, newState);
    } else if (action.type === "ModifyAudioTrack") {
        const trackAction = action as LocationTrackAction;
        const type = (action as LocationAction).props.playlistType as AudioType;

        if (type === AudioType.Music) {
            const newTracks = reduceDictionary(state.music?.tracks, audioTrackReducer, trackAction, [
                trackAction.props.trackId,
            ]);
            const playlist = copyState(state.music, { tracks: newTracks });
            return copyState(state, { music: playlist });
        } else if (type === AudioType.Ambient) {
            const newTracks = reduceDictionary(state.ambientAudio?.tracks, audioTrackReducer, trackAction, [
                trackAction.props.trackId,
            ]);
            const playlist = copyState(state.ambientAudio, { tracks: newTracks });
            return copyState(state, { ambientAudio: playlist });
        }
    } else if (action.type === "RemoveAudioTrack") {
        const trackId = action.payload as string;
        const type = (action as LocationAction).props.playlistType as AudioType;

        let oldPlaylist: Playlist | undefined;
        if (type === AudioType.Music) {
            oldPlaylist = state.music;
        } else if (type === AudioType.Ambient) {
            oldPlaylist = state.ambientAudio;
        }

        if (oldPlaylist?.tracks[trackId]) {
            let newTracks = Object.assign({}, oldPlaylist?.tracks);
            delete newTracks[trackId];
            newTracks = arrayToTracks(tracksToArray(newTracks));

            if (type === AudioType.Music) {
                return copyState(state, { music: copyState(state.music, { tracks: newTracks }) });
            } else {
                return copyState(state, { ambientAudio: copyState(state.ambientAudio, { tracks: newTracks }) });
            }
        }
    } else if (action.type === "MoveAudioTrack") {
        const { trackId, newIndex } = action.payload;
        const type = (action as LocationAction).props.playlistType as AudioType;

        const playlist = type === AudioType.Music ? state.music?.tracks : state.ambientAudio?.tracks;
        if (!playlist) {
            return state;
        }

        const tracks = tracksToArray(playlist);
        const oldIndex = tracks.findIndex(o => o.id === trackId);
        const newTracks = arrayMove(tracks, oldIndex, newIndex);

        if (type === AudioType.Music) {
            return copyState(state, { music: copyState(state.music, { tracks: arrayToTracks(newTracks) }) });
        } else {
            return copyState(state, {
                ambientAudio: copyState(state.ambientAudio, { tracks: arrayToTracks(newTracks) }),
            });
        }
    } else if (action.type === "ReorderAudioTracks") {
        const order = action.payload as string[];
        const type = (action as LocationAction).props.playlistType as AudioType;

        const playlist = type === AudioType.Music ? state.music?.tracks : state.ambientAudio?.tracks;
        if (!playlist) {
            return state;
        }

        const tracks = tracksToArray(playlist);
        const newTracks: AudioTrackWithId[] = [];
        for (let i = 0; i < order.length; i++) {
            const i = tracks.findIndex(o => o.id === order[i]);
            if (i >= 0) {
                newTracks.push(tracks[i]);
                tracks.splice(i, 1);
            }
        }

        newTracks.push(...tracks);

        if (type === AudioType.Music) {
            return copyState(state, { music: copyState(state.music, { tracks: arrayToTracks(newTracks) }) });
        } else {
            return copyState(state, {
                ambientAudio: copyState(state.ambientAudio, { tracks: arrayToTracks(newTracks) }),
            });
        }
    } else if (action.type === "AddZone") {
        const zone = action.payload as Zone;
        return copyState(state, { zones: Object.assign({}, state.zones, { [zone.id]: zone }) });
    } else if (isZoneAction(action)) {
        const zones = reduceDictionary(state.zones, zoneReducer, action, [action.props.zoneId]);
        if (zones !== state.zones) {
            return copyState(state, { zones: zones });
        }
    } else if (isLocationPlayerAction(action)) {
        const players = reduceDictionary(state.players, locationPlayerReducer, action, [action.props.locationUserId]);
        if (players !== state.players) {
            return copyState(state, { players: players });
        }
    } else if (action.type === "AddCombatParticipants") {
        const combat = reduceCombatParticipants(state.combat ?? { participants: {} }, state, action.payload);
        if (combat !== state.combat) {
            return copyState(state, { combat: combat });
        }
    } else if (action.type === "RemoveCombatParticipants") {
        if (state.combat) {
            const combat = state.combat;
            const participantsToRemove = (action.payload as string[]).filter(o => combat.participants[o]);
            if (participantsToRemove.length > 0) {
                const participants = Object.assign({}, combat.participants);
                participantsToRemove.forEach(o => delete participants[o]);
                return copyState(state, { combat: copyState(combat, { participants: participants }) });
            }
        }
    } else if (action.type === "NextCombatTurn") {
        const oldTurn = state.combat;
        state = reduceNextCombatTurn(state, sessionConnection.session!.campaign);
        action.payload = oldTurn;
    } else if (action.type === "PreviousCombatTurn") {
        if (state.combat) {
            const combat = state.combat;
            let round = combat.round ?? 0;

            const participants = getCombatParticipants(combat, sessionConnection.session!.campaign, state);

            let current = combat.turn;
            let turn: number;
            do {
                // eslint-disable-next-line no-loop-func
                const i = participants.findIndex(o => o.tokenId === current);
                turn = (i < 0 ? 1 : i) - 1;
                if (turn < 0) {
                    turn = Object.keys(combat.participants).length - 1;
                    round--;
                }

                current = participants[turn].tokenId;
            } while (!participants[turn].token);

            if (round >= 0) {
                state = copyState(state, {
                    combat: copyState(combat, { round: round, turn: participants[turn].tokenId }),
                });
            }
        }
    } else if (action.type === "EndCombat") {
        if (state.combat) {
            state = copyState(state, { combat: undefined });
        }
    } else if (action.type === "SetCombatInitiative") {
        if (state.combat) {
            const participant = state.combat.participants[action.payload.tokenId];
            if (participant) {
                const newParticipant = copyState(participant, { initiative: action.payload.initiative });
                return copyState(state, {
                    combat: copyState(state.combat, {
                        participants: copyState(state.combat.participants, {
                            [action.payload.tokenId]: newParticipant,
                        }),
                    }),
                });
            }
        }
    } else if (action.type === "ApplyGridPlacement") {
        const gridPlacement = action.payload as { tileSize?: Size; backgroundImagePos?: LocalPixelPosition };
        return copyState(state, { tileSize: Object.assign({}, gridPlacement.tileSize, { isConfigured: true }) });
    } else if (action.type === "AddLevel") {
        const level = action.payload as LocationLevel;
        state = copyState(state, { levels: addToKeyedList(state.levels, level) });
        return state;
    } else if (action.type === "DeleteLevel") {
        const key = action.payload as string;

        // Shouldn't delete the default level for the location.
        if (state.defaultLevel !== key) {
            // Remove all of the tokens, annotations, zones for that level.
            const tokens = Object.values(state.tokens);
            const newTokens = Object.assign({}, state.tokens);
            for (let token of tokens) {
                if (token.pos.level === key) {
                    delete newTokens[token.id];
                }
            }

            const annotations = Object.values(state.annotations);
            const newAnnotations = Object.assign({}, state.annotations);
            for (let annotation of annotations) {
                if (annotation.pos?.level === key) {
                    delete newAnnotations[annotation.id];
                }
            }

            const zones = Object.values(state.zones);
            const newZones = Object.assign({}, state.zones);
            for (let zone of zones) {
                if (zone.pos.level === key) {
                    delete newZones[zone.id];
                }
            }

            state = copyState(state, {
                levels: copyState(state.levels, { [key]: undefined }),
                tokens: newTokens,
                annotations: newAnnotations,
                zones: newZones,
            });
        }
    } else if (action.type === "ReorderLevels") {
        const keys = action.payload as string[];

        // Get the levels in their current order.
        const existingKeys = keyedListToKeyArray(state.levels);
        if (existingKeys) {
            let levels: KeyedList<LocationLevel> = {};
            let count = 0;
            for (let i = 0; i < keys.length; i++) {
                let keyIndex = existingKeys.indexOf(keys[i]);
                if (keyIndex >= 0) {
                    levels[keys[i]] = Object.assign({}, state.levels[keys[i]], { order: count });
                    existingKeys.splice(keyIndex, 1);

                    count++;
                }
            }

            // Also add any keys missing from the reordered keys list - this should only happen if there's a conflict (almost never).
            for (let i = 0; i < existingKeys.length; i++) {
                levels[existingKeys[i]] = Object.assign({}, state.levels[existingKeys[i]], { order: count });
                count++;
            }

            state = copyState(state, { levels: levels });
        }
    }

    const scope = action.props.scope as ActionScope | undefined;
    if (scope != null) {
        // For this to work, the sessionConnection that we pass in must be updated to contain the changes that we've made so far.
        const oldSession = sessionConnection.session!;
        const oldCampaign = oldSession.campaign!;
        const oldLocations = oldCampaign.locations;
        const newLocations = Object.assign({}, oldLocations, { [action.props.locationId]: state });
        const newCampaign = Object.assign({}, oldCampaign, { locations: newLocations });
        const newSession = Object.assign({}, oldSession, { campaign: newCampaign });
        const newSessionConnection = Object.assign({}, sessionConnection, { session: newSession });

        if ((scope & ActionScope.Tokens) === ActionScope.Tokens) {
            const reducedTokens = reduceDictionary(
                state.tokens,
                (s, a) => reduceToken(s, a, state!, newSessionConnection, false),
                action
            );
            if (reducedTokens !== state.tokens) {
                state = copyState(state, { tokens: reducedTokens });
            }
        }

        if ((scope & ActionScope.Annotations) === ActionScope.Annotations) {
            const annotations = reduceDictionary(
                state.annotations,
                (s, a) => annotationReducer(s, a, state!, newSessionConnection, false),
                action
            );
            if (annotations !== state.annotations) {
                state = copyState(state, { annotations: annotations });
            }
        }
    }

    return state;
};

export default locationReducer;

import React, { useContext } from "react";
import { WebGLRenderTarget } from "three";
import { LocalRect, Size } from "../../position";
import { Segment } from "./Lighting/common";

export interface LocationLevelRenderInfo {
    /**
     * The obstruction segments for this level.
     */
    segments?: Segment[];

    /**
     * The size of the current level.
     */
    size?: Size;

    /**
     * The size of the entire location.
     */
    totalSize?: LocalRect;

    /**
     * A value indicating whether or not the user currently has at least one vision source selected.
     * The vision source is not necessarily on this level.
     */
    hasVisionSource: boolean;

    /**
     * Contains the background bitmap for this level for the last rendered frame.
     */
    background: WebGLRenderTarget;

    /**
     * Contains the visibility bitmap for the last rendered frame.
     */
    visibility: WebGLRenderTarget;

    /**
     * Contains the lighting bitmap for the last rendered frame.
     */
    lighting: WebGLRenderTarget;

    /**
     * Contains the darkvision bitmap for the last rendered frame.
     */
    darkvision: WebGLRenderTarget;
}

export const LocationLevelContext = React.createContext<LocationLevelRenderInfo>({} as LocationLevelRenderInfo);

export function useRenderedLocationLevel() {
    return useContext(LocationLevelContext);
}

import React, { FunctionComponent, PropsWithChildren } from "react";
import Modal from "react-modal";

import "./modal.css";
import { MotionOverlayCard } from "./motion";
import { Box } from "./primitives";

Modal.setAppElement(document.getElementById("root")!);
Modal.defaultStyles.overlay = {};
Modal.defaultStyles.content = {};

function parentSelector() {
    return document.getElementById("dialog") ?? document.body;
}

export const ModalContent: FunctionComponent<
    PropsWithChildren<{ header: React.ReactElement; footer?: React.ReactElement }>
> = ({ header, children, footer }) => {
    return (
        <React.Fragment>
            <Box p={3} flexShrink={1} fullWidth>
                {header}
            </Box>
            {/* <InOverlayCard
                flexDirection="column"
                bg="background"
                borderRadius={4}
                flexGrow={1}
                mx={3}
                p={3}
                mb={footer ? undefined : 3}>
                {children}
            </InOverlayCard> */}
            <Box flexDirection="column" flexGrow={1} mb={footer ? undefined : 3}>
                {children}
            </Box>
            {footer && (
                <Box p={3} flexShrink={1} fullWidth>
                    {footer}
                </Box>
            )}
        </React.Fragment>
    );
};

export const ModalDialog: FunctionComponent<Modal.Props & { width?: number; height?: number; p?: number }> = ({
    width,
    height,
    style,
    p,
    ...props
}) => {
    const w = width ? `${width * 100}%` : style?.content?.width;
    const h = height ? `${height * 100}%` : style?.content?.height;

    const finalContent = Object.assign({}, style?.content, { width: w, height: h });
    const finalStyle = Object.assign({}, style, { content: finalContent });

    return (
        <Modal parentSelector={parentSelector} closeTimeoutMS={200} {...props} style={finalStyle}>
            <MotionOverlayCard
                layout
                p={p}
                width={w ? "100%" : undefined}
                height={h ? "100%" : undefined}
                minWidth={400}
                borderRadius={4}
                color="foreground"
                boxShadowSize="l">
                {props.children}
            </MotionOverlayCard>
        </Modal>
    );
};

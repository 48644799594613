import React, { FunctionComponent } from "react";
import { LocalPixelPosition } from "../../position";
import { theme } from "../../design";
import { useValidatedLocation } from "../contexts";
import { Html } from "@react-three/drei";
import { Box } from "../primitives";
import { ThemeProvider } from "@emotion/react";

interface DistanceMessageProps {
    /**
     * The destination point of the measurement.npm
     */
    pos: LocalPixelPosition;

    /**
     * The distance being measured, in grid units.
     */
    distance?: number;

    horizontalAlignment?: "left" | "center" | "right";
    verticalAlignment?: "top" | "center" | "bottom";
}

export const DistanceMessage: FunctionComponent<DistanceMessageProps> = ({
    pos,
    distance,
    horizontalAlignment,
    verticalAlignment,
}) => {
    const { system, location } = useValidatedLocation();
    const unit = location.unit ? location.unit : system.defaultUnit;
    const unitsPerGrid = location.unitsPerGrid ? location.unitsPerGrid : system.defaultUnitsPerGrid;

    let translateX = 0;
    if (horizontalAlignment === "center") {
        translateX = -50;
    } else if (horizontalAlignment === "right") {
        translateX = -100;
    }

    let translateY = 0;
    if (verticalAlignment === "center") {
        translateY = -50;
    } else if (verticalAlignment === "bottom") {
        translateY = -100;
    }

    return (
        <mesh position={[pos.x, -pos.y, 0]}>
            <Html zIndexRange={[1, 0]} style={{ pointerEvents: "none", userSelect: "none" }}>
                <ThemeProvider theme={theme}>
                    <Box
                        bg="background"
                        borderRadius={4}
                        p={2}
                        style={{ transform: `translate(${translateX}%, ${translateY}%)` }}>
                        {distance != null ? Math.round(Math.abs(distance) * unitsPerGrid).toString() + unit : ""}
                    </Box>
                </ThemeProvider>
            </Html>
        </mesh>
    );
};

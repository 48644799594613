import React from 'react'

const HoofIcon = ({
    size = undefined,
    color = undefined,
    ...props
}) => (
    <svg
        {...props}
        viewBox='0 0 512 512'
        width={size}
        height={size}
        stroke={color}
    >
        <path d="M142.813 19.97c.048 56.36-28.065 101.353-64.188 136.81l39.47 4.564 14.936 1.72-8.186 12.655-33.563 51.843c30.768 3.768 54.396-1.36 88.25-14.97l12.626-5.062.22 13.595.718 44.906c20.064-13.115 36.285-30.203 50.28-50.124l4.94-7.062 7.436 4.375 54.594 32.03 10.156-30.375 3.938-11.844 10.25 7.126c30.46 21.155 66.024 31.13 102.218 34.125l-51.937-57.56-14.408-15.97 21.532.375 31.437.563c-17.77-17.895-35.47-39.554-49.56-62.97-16.887-28.06-28.705-58.756-27.97-88.75H142.812zm191.656 212.06l-10.064 30.064-3.625 10.937-9.967-5.842-40.907-24c10.515 32.963 18.264 78.16 24.563 123.843 6.117 44.372 10.706 88.847 14.31 121.814 14.294-4.76 27.926-11.046 40.408-18.594-.672-36.365-8.715-72.732-19.282-109.094 18.077 30.067 32.476 61.168 40.563 94.063 13.43-11.014 24.69-23.64 32.874-37.345-2.963-22.86-13.432-52.61-25.47-73.656 14.353 12.85 27.138 31.72 36.47 48.81 2.426-8.295 3.72-16.823 3.72-25.5-.002-32.12-15.122-77.045-40.19-115.842-14.964-4.858-29.543-11.293-43.405-19.657zm-160.564 2.69c-13.698 4.926-26.492 8.458-39.25 10.5-27.508 40.184-44.25 88.358-44.25 122.31 0 29.434 14.637 57.26 37.97 79.69 6.713-23.524 18.778-45.87 34.06-67.533-8.636 28.1-14.79 56.182-13.624 84.282 16.037 11.214 34.474 20.235 54.157 26.342 3.555-33.222 8.24-78.5 14.624-124.187 5.93-42.437 13.105-84.325 22.47-116.156-14.5 16.064-31.413 30.002-51.75 40.56l-13.408 6.97-.25-15.094-.75-47.687zm82.125 27.874c-7.814 29.17-14.536 67.482-19.936 106.125-5.968 42.7-10.45 85.298-13.938 118.03 18.716-6.198 35.124-8.003 48.72-7.03 6.917.493 13.055 1.684 18.437 3.374-3.48-32.118-7.78-72.96-13.375-113.53-5.358-38.854-12.008-77.447-19.907-106.97z" fill={color}></path>
    </svg>
)

HoofIcon.displayName = 'HoofIcon'

HoofIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
}

export default HoofIcon 
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, {
    FunctionComponent,
    useState,
    useEffect,
    PropsWithChildren,
    useCallback,
    RefObject,
    useRef,
    useMemo,
} from "react";
import { Campaign, CampaignPlayer, Handout, PermissionType, Permissions, UserInfo } from "../../store";
import { Box, Heading, Text } from "../primitives";
import { useCampaign, useDispatch, useRole, useUser } from "../contexts";
import { LobotomizedBox, useVttApp } from "../common";
import { Button } from "../Button";
import { PlusIcon } from "@radix-ui/react-icons";
import { nanoid } from "nanoid";
import { addHandout, removeHandout } from "../../actions/campaign";
import { SearchResult } from "../../localsearchable";
import { AnimatePresence } from "framer-motion";
import { AnimatedListItem, MotionForm, MotionTag, defaultAnimate, defaultExit, defaultInitial } from "../motion";
import { Message } from "../Message";
import EmptyState from "../EmptyState";
import styled from "@emotion/styled";
import { ScrollableTest } from "../ScrollableTest";
import { ListBox, ListItem } from "../ListBox";
import { theme } from "../../design";
import { Markdown, MarkdownEditorField } from "../markdown";
import { InputField } from "../Form";
import { modifyHandout } from "../../actions/handout";
import { Avatar, AvatarProps } from "../Avatar";
import { Tag } from "../Tag";
import { AnyAction, Dispatch } from "redux";
import create from "zustand";
import { ControlledMenu } from "../menus";
import { MenuItem, useMenuState } from "@szhsin/react-menu";
import { MarkdownActions } from "../MarkdownActions";
import { Pages } from "./Sidebar";
import { ModalDialog } from "../modal";
import FocusTrap from "focus-trap-react";
import { MotionAvatarGroup } from "../AvatarGroup";
import { getRandomPalette } from "../../design/utils";

const HANDOUT_PROPERTIES_WIDTH = theme.space[12];

interface HandoutLibraryState {
    selectedItem: string | undefined;
    setSelectedItem: (handout: Handout | undefined) => void;

    contextMenuTarget: string | undefined;
    setContextMenuTarget: (handout: Handout | undefined) => void;

    showContextMenu: ((handout: Handout, anchorRef: RefObject<Element>) => void) | undefined;
    provideContextMenu: (
        showContextMenu: ((handout: Handout, anchorRef: RefObject<Element>) => void) | undefined
    ) => void;
}

export const useHandoutLibraryProperties = create<HandoutLibraryState>(set => ({
    selectedItem: undefined,
    setSelectedItem: handout => set({ selectedItem: handout?.id }),
    contextMenuTarget: undefined,
    setContextMenuTarget: handout => set({ contextMenuTarget: handout?.id }),
    showContextMenu: undefined,
    provideContextMenu: provider => set({ showContextMenu: provider }),
}));

const ScrollableHack = styled(Box)`
    > :first-of-type {
        flex: 1 1 auto;
    }
`;

function createNewHandout(campaign: Campaign, user: UserInfo, dispatch: Dispatch<AnyAction>) {
    const time = Date.now();
    const handout: Handout = {
        id: nanoid(),
        permissions: { owner: user.id },
        contributors: { [user.id]: time },
        label: "New note",
        content: "",
        modified: time,
    };
    dispatch(addHandout(campaign, handout));
    return handout;
}

export const HandoutsTools: FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const { campaign } = useCampaign();
    const user = useUser();
    const setSelectedItem = useHandoutLibraryProperties(o => o.setSelectedItem);

    const { searchTerm, addPanel } = useVttApp();

    if (searchTerm !== "") {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <LobotomizedBox key="tools" flexDirection="row" fullWidth justifyContent="flex-end" px={3}>
            <Button
                shape="square"
                tooltip="Create new note/handout"
                alignSelf="flex-end"
                variant="tertiary"
                onClick={() => {
                    const handout = createNewHandout(campaign, user, dispatch);
                    setSelectedItem(handout);
                    addPanel({
                        id: handout.id,
                        children: () => <HandoutPropertyEditor id={handout.id} />,
                        width: HANDOUT_PROPERTIES_WIDTH,
                    });
                }}>
                <PlusIcon fill="currentcolor" />
            </Button>
        </LobotomizedBox>
    );
};

// TODO: This should probably be somewhere generic, with Permissions maybe.
function hasPermission(user: CampaignPlayer, permissions: Permissions | undefined, permission: PermissionType) {
    if (permissions == null) {
        return permission === "read";
    }

    if (permissions.owner === user.userId) {
        return true;
    }

    let p = permissions.user?.[user.userId]?.[permission];
    if (p != null) {
        return p;
    }

    p = permissions.role?.[user.role ?? "Player"]?.[permission];
    if (p != null) {
        return p;
    }

    p = permissions.all?.[permission];
    if (p != null) {
        return p;
    }

    return false;
}

type SimplifiedPermissionType = "all" | "GM" | "owner";

const PermissionBar: FunctionComponent<{
    permission: SimplifiedPermissionType;
    isOwner: boolean;
    onChanged: (permission: SimplifiedPermissionType) => void;
}> = ({ permission, isOwner, onChanged }) => {
    // const role = useRole();
    return (
        <Box>
            <Button size="s" toggled={permission === "all"} onClick={() => onChanged("all")}>
                Everyone
            </Button>
            {/* {role === "GM" && (
                <Button size="s" ml={2} toggled={permission === "GM"} onClick={() => onChanged("GM")}>
                    GMs
                </Button>
            )} */}
            <Button size="s" ml={2} toggled={permission === "owner"} onClick={() => onChanged("owner")}>
                {isOwner ? "Me" : "Owner"}
            </Button>
        </Box>
    );
};

function createPermissionGenerator(permissions: Permissions | undefined) {
    return (read: SimplifiedPermissionType, write: SimplifiedPermissionType) => {
        const p: Permissions = {
            owner: permissions?.owner,
        };

        if (read === "all") {
            p.all = { read: true };
        } else if (read === "GM") {
            p.role = { GM: { read: true } };
        }

        if (write === "all") {
            p.all = p.all ?? {};
            p.all.write = true;
        } else if (write === "GM") {
            p.role = p.role ?? { GM: {} };
            p.role.GM!.write = true;
        }

        return p;
    };
}

function getSimplifiedPermissionType(permissions: Permissions | undefined, type: PermissionType) {
    var p: SimplifiedPermissionType;
    if (permissions == null || permissions.all?.[type]) {
        p = "all";
    } else if (permissions.role?.GM?.[type]) {
        p = "GM";
    } else {
        p = "owner";
    }

    return p;
}

export const PermissionsEditor: FunctionComponent<{
    permissions?: Permissions;
    onChanged: (permissions: Permissions) => void;
}> = ({ permissions, onChanged }) => {
    const { campaign } = useCampaign();
    const owner = permissions?.owner ? campaign.players[permissions.owner] : undefined;
    const user = useUser();

    // TODO: For now we only support a few specific configurations of permissions. In the future we're future proofed
    // against more custom permission configurations, but we don't need to support them now.
    // GM_only can only be set if you ARE a GM. This is fine as a client side only option.
    // Me can only be set if you are the owner. This is also fine as a client side option.
    // All write options are only available if you already have write access.
    // Read - All GM_only Me
    // Write - All GM_only Me

    const read = getSimplifiedPermissionType(permissions, "read");
    const write = getSimplifiedPermissionType(permissions, "write");

    const hasWritePermission = hasPermission(campaign.players[user.id], permissions, "write");
    const isOwner = permissions?.owner === user.id;

    const generatePermissions = createPermissionGenerator(permissions);

    return (
        <Box fullWidth flexDirection="column" alignItems="flex-start">
            {owner && (
                <React.Fragment>
                    <Text mt={2} mb={1} fontSize={0} color="grayscale.3">
                        Created by
                    </Text>
                    <Box>
                        <Avatar
                            size="s"
                            name={owner.name}
                            bg={`${owner.colour}.7`}
                            color={`${owner.colour}.0`}
                            mr={2}
                        />
                        {owner.name}
                    </Box>
                </React.Fragment>
            )}
            {hasWritePermission && (
                <React.Fragment>
                    <Text mt={2} mb={1} fontSize={0} color="grayscale.3">
                        Visible to
                    </Text>
                    <PermissionBar
                        permission={read}
                        isOwner={isOwner}
                        onChanged={o => {
                            const p = generatePermissions(o, write);
                            onChanged(p);
                        }}
                    />

                    <Text mt={2} mb={1} fontSize={0} color="grayscale.3">
                        Editable by
                    </Text>
                    <PermissionBar
                        permission={write}
                        isOwner={isOwner}
                        onChanged={o => {
                            const p = generatePermissions(read, o);
                            onChanged(p);
                        }}
                    />
                </React.Fragment>
            )}
        </Box>
    );
};

export const HandoutPropertyEditor: FunctionComponent<{
    id: string;
}> = ({ id }) => {
    const user = useUser();
    const dispatch = useDispatch();
    const { api, campaign } = useCampaign();

    const handout = campaign.handouts[id];
    if (handout == null) {
        return <React.Fragment></React.Fragment>;
    }

    const player = campaign.players[user.id];
    const canEdit = hasPermission(player, handout.permissions, "write");
    return (
        <AnimatePresence mode="wait">
            {canEdit && (
                <MotionForm key={handout.id} initial={defaultInitial} animate={defaultAnimate} exit={defaultExit} p={3}>
                    <Heading as="h5" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                        {handout.label}
                    </Heading>

                    <InputField
                        label="Name"
                        required
                        value={handout.label}
                        disabled={!canEdit}
                        onChange={e => {
                            dispatch(modifyHandout(campaign, handout.id, { label: e.target.value }, user.id));
                        }}
                    />

                    {/* TODO: Some kind of permissions editor */}
                    <PermissionsEditor
                        permissions={handout.permissions}
                        onChanged={p => {
                            dispatch(modifyHandout(campaign, handout.id, { permissions: p }, user.id));

                            // If the handout is being shared to everyone, send a message so that they know.
                            if (!handout.permissions?.all?.read && p.all?.read) {
                                api.sendMessage(
                                    `The following handout has been shared:\n\n:handout[${handout.label}]{id="${handout.id}"}`,
                                    {
                                        notify: { toast: true },
                                    }
                                );
                            }
                        }}
                    />

                    <MarkdownActions markdown={() => `:handout[${handout.label}]{id="${handout.id}"}`} />

                    <MarkdownEditorField
                        label="Content"
                        required
                        editable
                        minLines={6}
                        defaultMarkdown={handout.content}
                        onMarkdownChange={md => {
                            dispatch(modifyHandout(campaign, handout.id, { content: md }, user.id));
                        }}
                        debounceChange={2000}
                    />
                </MotionForm>
            )}
            {!canEdit && (
                <Box fullWidth flexDirection="column" p={3}>
                    <Heading
                        as="h5"
                        css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }}
                        pr={4}
                        mb={3}>
                        {handout.label}
                    </Heading>

                    <Markdown>{handout.content}</Markdown>
                </Box>
            )}
        </AnimatePresence>
    );
};

enum HandoutRole {
    Handout,
    PersonalNote,
    SharedNote,
}

function getHandoutRole(handout: Handout, player: CampaignPlayer, campaign: Campaign) {
    const owner = handout.permissions?.owner ? campaign.players[handout.permissions?.owner] : undefined;
    if (owner == null || owner?.role === "GM") {
        if (handout.permissions?.all?.read) {
            return HandoutRole.Handout;
        }

        return handout.permissions == null ? HandoutRole.SharedNote : HandoutRole.PersonalNote;
    }

    return handout.permissions == null || handout.permissions.all?.read
        ? HandoutRole.SharedNote
        : HandoutRole.PersonalNote;
}

const HandoutListItemContent: FunctionComponent<{ handout: Handout }> = ({ handout }) => {
    const { campaign } = useCampaign();
    const user = useUser();
    const player = campaign.players[user.id];

    const avatarData = useMemo<AvatarProps[] | undefined>(() => {
        if (handout.contributors) {
            const contributors = Object.keys(handout.contributors);
            return contributors.map(o => {
                const campaignPlayer = campaign.players[o];
                const colour = campaignPlayer?.colour ?? getRandomPalette();
                return {
                    name: campaignPlayer?.name ?? o,
                    bg: `${colour}.7`,
                    color: `${colour}.0`,
                };
            });
        }

        return undefined;
    }, [campaign.players, handout.contributors]);

    const handoutRole = getHandoutRole(handout, player, campaign);
    let tag: JSX.Element;
    switch (handoutRole) {
        case HandoutRole.Handout:
            tag = (
                <MotionTag
                    key="handout"
                    layout
                    bg="purples.7"
                    color="purples.0"
                    initial={defaultInitial}
                    animate={defaultAnimate}
                    exit={defaultExit}>
                    Handout
                </MotionTag>
            );
            break;
        case HandoutRole.PersonalNote:
            tag = (
                <MotionTag
                    key="personalnote"
                    layout
                    bg="blues.7"
                    color="blues.0"
                    initial={defaultInitial}
                    animate={defaultAnimate}
                    exit={defaultExit}>
                    Personal note
                </MotionTag>
            );
            break;
        case HandoutRole.SharedNote:
            tag = (
                <MotionTag
                    bg="greens.7"
                    layout
                    color="greens.0"
                    initial={defaultInitial}
                    animate={defaultAnimate}
                    exit={defaultExit}>
                    Shared note
                </MotionTag>
            );
            break;
    }

    return (
        <React.Fragment>
            {handout.label}
            <Box>
                {handout.permissions?.owner === user.id && (
                    <Tag key="owner" bg="oranges.7" color="oranges.0" mr={1}>
                        Owner
                    </Tag>
                )}
                <AnimatePresence mode="popLayout">
                    {tag}
                    {avatarData && (
                        <MotionAvatarGroup
                            layout
                            key="avatars"
                            ml={1}
                            data={avatarData}
                            size="s"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}
                        />
                    )}
                </AnimatePresence>
            </Box>
        </React.Fragment>
    );
};

export const HandoutSearchResult: FunctionComponent<{
    handout: Handout;
}> = ({ handout }) => {
    const ref = useRef<HTMLDivElement>(null);
    const showContextMenu = useHandoutLibraryProperties(o => o.showContextMenu);
    return (
        <Box
            fullWidth
            alignItems="flex-start"
            flexDirection="column"
            ref={ref}
            onContextMenu={e => {
                showContextMenu?.(handout, ref);
                e.preventDefault();
            }}>
            <HandoutListItemContent handout={handout} />
        </Box>
    );
};

export const HandoutListItem: FunctionComponent<{
    handout: Handout;
    isSelected?: boolean;
    isActive?: boolean;
    isFocused?: boolean;
}> = ({ handout, isSelected, isActive, isFocused }) => {
    const ref = useRef<HTMLDivElement>(null);
    const showContextMenu = useHandoutLibraryProperties(o => o.showContextMenu);
    const contextMenuTarget = useHandoutLibraryProperties(o => o.contextMenuTarget);
    return (
        <ListItem
            ref={ref}
            selected={isSelected}
            active={isActive}
            focused={isFocused}
            isContextMenuTarget={contextMenuTarget === handout.id}
            flexDirection="column"
            onContextMenu={e => {
                showContextMenu?.(handout, ref);
                e.preventDefault();
            }}>
            <HandoutListItemContent handout={handout} />
        </ListItem>
    );
};

export const HandoutsHost: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [anchorRef, setAnchorRef] = useState<RefObject<Element>>();

    const { campaign } = useCampaign();
    const user = useUser();
    const player = campaign.players[user.id];

    var contextMenuTarget = useHandoutLibraryProperties(o => o.contextMenuTarget);
    const handout = contextMenuTarget != null ? campaign.handouts[contextMenuTarget] : undefined;

    const dispatch = useDispatch();

    const [menuProps, toggleMenu] = useMenuState({ transition: true });

    var setContextMenuTarget = useHandoutLibraryProperties(o => o.setContextMenuTarget);
    const showContextMenu = useCallback(
        (handout: Handout, anchorRef: RefObject<Element>) => {
            setContextMenuTarget(handout);
            setAnchorRef(anchorRef);
            toggleMenu(true);
        },
        [toggleMenu, setContextMenuTarget]
    );

    var setContextMenuProvider = useHandoutLibraryProperties(o => o.provideContextMenu);
    useEffect(() => {
        setContextMenuProvider(showContextMenu);
        return () => setContextMenuProvider(undefined);
    }, [showContextMenu, setContextMenuProvider]);

    const [pendingDelete, setPendingDelete] = useState<Handout>();
    const lastPendingDelete = useRef<Handout>();
    if (pendingDelete != null) {
        lastPendingDelete.current = pendingDelete;
    }

    return (
        <React.Fragment>
            {children}
            <ControlledMenu
                direction="bottom"
                align="center"
                {...menuProps}
                onClose={() => {
                    toggleMenu(false);
                    setContextMenuTarget(undefined);
                }}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onItemClick={e => {
                    e.syntheticEvent.stopPropagation();
                    e.syntheticEvent.preventDefault();
                }}
                anchorRef={anchorRef}>
                {handout && hasPermission(player, handout.permissions, "write") && (
                    <React.Fragment>
                        <MenuItem
                            onClick={() => {
                                navigator.clipboard.writeText(`:handout[${handout.label}]{id="${handout.id}"}`);
                            }}>
                            Copy markdown
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setPendingDelete(handout);
                            }}>
                            Delete
                        </MenuItem>
                    </React.Fragment>
                )}
            </ControlledMenu>

            <ModalDialog
                onRequestClose={() => {
                    setPendingDelete(undefined);
                }}
                isOpen={pendingDelete != null}
                style={{
                    content: {
                        width: theme.space[12],
                    },
                }}>
                <FocusTrap focusTrapOptions={{ initialFocus: "#modalDefault", allowOutsideClick: true }}>
                    <Box flexDirection="column" p={3} maxWidth={theme.space[13]} alignItems="flex-start">
                        <Heading
                            as="h3"
                            mb={3}
                            css={{ textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden" }}>
                            Delete {lastPendingDelete.current?.label}?
                        </Heading>
                        <Text>
                            Are you sure you want to delete{" "}
                            {lastPendingDelete.current ? lastPendingDelete.current.label : undefined}?
                        </Text>
                        <LobotomizedBox justifyContent="flex-end" mt={3} fullWidth>
                            <Button
                                disabled={pendingDelete == null}
                                id="modalDefault"
                                variant="primary"
                                onClick={async () => {
                                    if (pendingDelete) {
                                        dispatch(removeHandout(campaign.id, pendingDelete));
                                        setPendingDelete(undefined);
                                    }
                                }}>
                                Delete
                            </Button>
                            <Button
                                disabled={pendingDelete == null}
                                variant="secondary"
                                onClick={() => setPendingDelete(undefined)}>
                                Keep
                            </Button>
                        </LobotomizedBox>
                    </Box>
                </FocusTrap>
            </ModalDialog>
        </React.Fragment>
    );
};

export const HandoutsLibrary: FunctionComponent<{}> = React.memo(() => {
    const { searchTerm, searchResults, addPanel, clearPanels } = useVttApp();
    const search = searchResults.find(o => o.categoryId === Pages.Handouts)?.results as SearchResult<Handout, any>[];

    const { campaign } = useCampaign();
    const dispatch = useDispatch();
    const user = useUser();
    const role = useRole();

    const handouts = search
        ? search.map(o => o.originalItem)
        : Object.values(campaign.handouts).sort((a, b) => b.modified - a.modified);

    const { selectedItem, setSelectedItem } = useHandoutLibraryProperties();
    const selectedIndex = selectedItem == null ? -1 : handouts.findIndex(o => o.id === selectedItem);

    return search || handouts.length > 0 ? (
        <Box flexDirection="column" fullWidth flex="1 1 auto" position="relative">
            <AnimatePresence initial={false}>
                {searchTerm && (
                    <AnimatedListItem key="filtermsg" fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only handouts matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>
            <ScrollableHack flexDirection="column" fullWidth flex="1 1 auto">
                <ScrollableTest minimal pb={1} px={3}>
                    <HandoutsHost>
                        <ListBox<Handout>
                            selectActive
                            css={{
                                position: "relative",
                                flexDirection: "column",
                                alignItems: "stretch",
                                gap: theme.space[2],
                            }}
                            paddingY={3}
                            fullWidth
                            items={handouts}
                            selectedItems={selectedIndex < 0 ? undefined : [handouts[selectedIndex]]}
                            itemKey={o => o.id}
                            onSelectionChanged={o => {
                                setSelectedItem(o && o.length ? o[0] : undefined);
                                if (o.length) {
                                    addPanel({
                                        id: o[0].id,
                                        children: () => <HandoutPropertyEditor id={o[0].id} />,
                                        width: HANDOUT_PROPERTIES_WIDTH,
                                    });
                                } else {
                                    clearPanels();
                                }
                            }}>
                            {({ item, index, selected, active, focused }) => {
                                return (
                                    <AnimatedListItem key={item.id} index={index} fullWidth borderRadius={3}>
                                        <HandoutListItem
                                            handout={item}
                                            isSelected={selected}
                                            isActive={active}
                                            isFocused={focused}
                                        />
                                    </AnimatedListItem>
                                );
                            }}
                        </ListBox>
                    </HandoutsHost>
                </ScrollableTest>
            </ScrollableHack>
        </Box>
    ) : (
        <Box flex={1} fullWidth position="relative">
            <EmptyState
                onClick={() => {
                    const handout = createNewHandout(campaign, user, dispatch);
                    setSelectedItem(handout);
                    addPanel({
                        id: handout.id,
                        children: () => <HandoutPropertyEditor id={handout.id} />,
                        width: HANDOUT_PROPERTIES_WIDTH,
                    });
                }}
                label={role === "GM" ? "Create handout" : "Create note"}
                fullWidth
            />
        </Box>
    );
});

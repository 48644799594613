/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, ReactElement, RefAttributes, useEffect, useMemo, useRef, useState } from "react";
import { Text, Box, Scrollable, Heading } from "../primitives";
import { InputField, SelectField, CheckboxField } from "../Form";
import { Message } from "../Message";
import { Spacer } from "../Spacer";
import { Button } from "../Button";
import { withTooltip } from "../Tooltip";
import { useNotifications, INotificationProps } from "../Notifications";
import {
    ImageThumbnail,
    isAudio,
    LibraryItem,
    LibraryItemElement,
    LibraryItemHost,
    LibraryItemMetadata,
    UserArtLibrary,
    getPaletteForLibraryItemType,
    isModel,
} from "../../library";
import { motion, AnimatePresence } from "framer-motion";
import { AnimatedListItem, MotionForm, defaultInitial, defaultAnimate, defaultExit, DropOverlay } from "../motion";
import {
    ImageType,
    AudioType,
    Campaign,
    UserInfo,
    Location,
    ErrorHandler,
    getToken,
    isTokenTemplate,
    ModelType,
    isLocation,
    Zone,
} from "../../store";
import styled from "@emotion/styled";
import { Loading, useVttApp } from "../common";
import {
    getMatchingDataTransferItems,
    useDebounce,
    useDropEvents,
    useErrorHandler,
    useEvent,
    useForceUpdate,
    useLocalSetting,
} from "../utils";
import { copyState } from "../../reducers/common";
import { ZIndexField } from "../ZIndexSlider";
import { ZIndexes } from "../LocationStage/common";
import { isUniversalVttFile, tryImportUniversalVtt } from "../../universalvtt";
import { Dispatch } from "redux";
import MapIcon from "../icons/Map";
import { theme } from "../../design";
import DragonIcon from "../icons/Dragon";
import PortraitIcon from "../icons/Portrait";
import BarrelIcon from "../icons/Barrel";
import SoundIcon from "../icons/Sound";
import MusicIcon from "../icons/Music";
import { LocalSetting } from "../../common";
import { TagButton } from "../TagButton";
import { useAppState, useDispatch, useLocation, useSelection } from "../contexts";
import { modifyToken, setPortraitImage, setTokenImage } from "../../actions/token";
import { addTrack, modifyZone } from "../../actions/location";
import { ListBox } from "../ListBox";
import { Canvas } from "@react-three/fiber";
import { TokenModel } from "../LocationStage/TokenNode";
import { OrbitControls } from "@react-three/drei";
import { getSelectedZones } from "../selection";
import { Pages } from "./Sidebar";
import { MarkdownActions } from "../MarkdownActions";
import { SliderField } from "../slider";

// TODO: Remove when Scrollable is fixed so that we can put the flex prop on that (or until someone tells me what I'm doing wrong).
const ScrollableHack = styled(Box)`
    > :first-of-type {
        flex: 1 1 auto;
    }
`;

export async function dropFiles(
    files: FileList | File[],
    addNotification: (data: INotificationProps) => Promise<void>,
    errorHandler: ErrorHandler,
    type?: ImageType | AudioType,
    user?: UserInfo,
    campaign?: Campaign,
    dispatch?: Dispatch
): Promise<{ libraryItems?: LibraryItem[]; locations?: Location[] }> {
    const libraryItems: { libraryItem: LibraryItem; alreadyExisted: boolean }[] = [];
    const locations: Location[] = [];

    try {
        const universalVttFiles: File[] = [];
        const otherFiles: File[] = [];
        for (let i = 0; i < files.length; i++) {
            if (user && campaign && dispatch && type == null && isUniversalVttFile(files[i])) {
                universalVttFiles.push(files[i]);
            } else {
                otherFiles.push(files[i]);
            }
        }

        for (let vttFile of universalVttFiles) {
            const location = await tryImportUniversalVtt(user!, campaign!, vttFile, dispatch!, errorHandler);
            if (location) {
                locations.push(location);
            }
        }

        const results = await Promise.allSettled(
            otherFiles.map(o => UserArtLibrary.current.uploadFile(o, errorHandler, type))
        );
        for (let result of results.filter(o => o.status === "fulfilled")) {
            const value = (
                result as PromiseFulfilledResult<{
                    libraryItem: LibraryItem;
                    alreadyExisted: boolean;
                }>
            ).value;
            if (value.libraryItem) {
                libraryItems.push(value);
            }
        }

        var msg = "";
        if (libraryItems.length) {
            if (libraryItems.length > 1) {
                msg += `${libraryItems.length} items were successfully imported.`;
            } else {
                if (libraryItems[0].alreadyExisted) {
                    msg += `${libraryItems[0].libraryItem.name} already existed.`;
                } else {
                    msg += `${libraryItems[0].libraryItem.name} was successfully imported.`;
                }
            }
        }

        if (locations.length > 0) {
            if (msg) {
                msg += "\n\n";
            }

            if (locations.length > 1) {
                msg += `${locations.length} locations were successfully imported.`;
            } else {
                msg += `The location ${locations[0].label} was successfully imported.`;
            }
        }

        if (msg) {
            addNotification({
                content: (
                    <Message variant="success" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                        {msg}
                    </Message>
                ),
                canDismiss: true,
                timeout: 5000,
                showLife: true,
            });
        }
    } catch (e) {
        errorHandler.handleError(e, "File import failed.");
    }

    return {
        libraryItems: libraryItems.length > 0 ? libraryItems.map(o => o.libraryItem) : undefined,
        locations: locations.length > 0 ? locations : undefined,
    };
}

interface ArtFilter {
    type?: ImageType | AudioType | ModelType;
}

export const artFilterSetting = new LocalSetting<ArtFilter>("artfilter");

interface ArtFilterButtonProps {
    type: ImageType | AudioType | ModelType;
    previewedType: ImageType | AudioType | ModelType | undefined;
    setPreviewedType: (type: ImageType | AudioType | ModelType | undefined) => void;
    icon: ReactElement;
}

const ArtFilterButton: FunctionComponent<ArtFilterButtonProps & RefAttributes<HTMLElement>> = React.forwardRef<
    HTMLElement,
    ArtFilterButtonProps
>(({ type, previewedType, setPreviewedType, icon }, ref) => {
    const [filter, setFilter] = useLocalSetting(artFilterSetting);

    let isToggled = false;
    if (previewedType) {
        isToggled = previewedType === type;
    } else if (!filter || type === filter.type) {
        // There's no filter (so we're showing this type) or we're filtering by this type.
        isToggled = true;
    }

    return (
        <TagButton
            ref={ref}
            palette={!isToggled && filter?.type !== type ? "grayscale" : getPaletteForLibraryItemType(type)}
            toggled={isToggled}
            onHover={h => setPreviewedType(h ? type : undefined)}
            onClick={() => setFilter(filter?.type === type ? undefined : Object.assign({}, filter, { type: type }))}>
            {icon}
        </TagButton>
    );
});

const TooltipArtFilterButton = withTooltip(ArtFilterButton);

// function toggleType(type: ImageType | AudioType, filter: ArtFilter | undefined): ArtFilter | undefined {
//     const index = filter?.types ? filter.types.indexOf(type) : -1;
//     if (index >= 0) {
//         if (filter!.types!.length === 1) {
//             return undefined; // return Object.assign({}, filter, { types: undefined });
//         }

//         const types = filter!.types!.slice();
//         types.splice(index, 1);
//         return Object.assign({}, filter, { types: types });
//     }

//     const types = filter?.types ? filter.types.slice() : [];
//     types.push(type);
//     return Object.assign({}, filter, { types: types });
// }

const TooltipBox = withTooltip(Box);
const undefinedCapacity = { used: 0, max: 0 };

const ArtLibraryStorageBar: FunctionComponent<{}> = () => {
    useEvent(UserArtLibrary.current.capacityChanged, undefinedCapacity);
    const capacityUsed = UserArtLibrary.current.capacityUsed ?? 0;
    const capacityMax = UserArtLibrary.current.capacityMax;

    const usedMb = Math.round(capacityUsed / 1048576);
    const maxMb = Math.round(capacityMax / 1048576);

    const usedPct = (capacityUsed ?? 0) / capacityMax;
    const tooltip = `${usedMb}MB of ${maxMb}MB used`;
    return (
        <React.Fragment>
            <TooltipBox
                tooltip={tooltip}
                mx={3}
                mb={2}
                flexDirection="row"
                bg="grayscale.7"
                role="progressbar"
                aria-label={tooltip}
                aria-valuenow={usedMb}
                aria-valuemin={0}
                aria-valuemax={maxMb}
                css={{
                    minHeight: theme.space[2],
                    position: "relative",
                    borderRadius: theme.radii[4],
                    display: "flex",
                    flex: "1 0 0%",
                    overflow: "hidden",
                    "&::after": {
                        position: "absolute",
                        content: "''",
                        height: "100%",
                        width: "100%",
                        left: 0,
                        borderRadius: theme.radii[4],
                        transformOrigin: "0 50%",
                        transition: "transform 100ms ease-out",
                        zIndex: 1,
                        backgroundColor:
                            usedPct < 0.75
                                ? theme.colors.greens[6]
                                : usedPct < 0.9
                                ? theme.colors.oranges[6]
                                : theme.colors.reds[6],
                        transform: `translateX(${-100 + usedPct * 100}%)`,
                    },
                }}
            />
        </React.Fragment>
    );
};

export const ArtLibraryTools: FunctionComponent<{}> = () => {
    const [previewedType, setPreviewedType] = useState<ImageType | AudioType | ModelType>();

    return (
        <Box flexDirection="column" fullWidth alignItems="stretch">
            <ArtLibraryStorageBar />
            <Box flexDirection="row" css={{ gap: theme.space[2] }} justifyContent="flex-start" px={3}>
                {/* <Button onClick={() => setFilter(undefined)} disabled={filter == null}>⭯</Button>

                <Spacer direction="vertical" minHeight={theme.space[4]} /> */}

                <TooltipArtFilterButton
                    tooltip="Backgrounds"
                    type={ImageType.Background}
                    previewedType={previewedType}
                    setPreviewedType={setPreviewedType}
                    icon={<MapIcon />}
                />
                <TooltipArtFilterButton
                    tooltip="Tokens"
                    type={ImageType.Token}
                    previewedType={previewedType}
                    setPreviewedType={setPreviewedType}
                    icon={<DragonIcon />}
                />
                <TooltipArtFilterButton
                    tooltip="Portraits"
                    type={ImageType.Portrait}
                    previewedType={previewedType}
                    setPreviewedType={setPreviewedType}
                    icon={<PortraitIcon />}
                />
                <TooltipArtFilterButton
                    tooltip="Objects"
                    type={ImageType.Object}
                    previewedType={previewedType}
                    setPreviewedType={setPreviewedType}
                    icon={<BarrelIcon />}
                />

                <Spacer direction="vertical" minHeight={theme.space[4]} />

                <TooltipArtFilterButton
                    tooltip="Ambience"
                    type={AudioType.Ambient}
                    previewedType={previewedType}
                    setPreviewedType={setPreviewedType}
                    icon={<SoundIcon />}
                />
                <TooltipArtFilterButton
                    tooltip="Music"
                    type={AudioType.Music}
                    previewedType={previewedType}
                    setPreviewedType={setPreviewedType}
                    icon={<MusicIcon />}
                />

                <Spacer direction="vertical" minHeight={theme.space[4]} />

                <TooltipArtFilterButton
                    tooltip="Token model"
                    type={ModelType.Token}
                    previewedType={previewedType}
                    setPreviewedType={setPreviewedType}
                    icon={
                        <Text fontWeight="bold" color="inherit">
                            3D
                        </Text>
                    }
                />
            </Box>
        </Box>
    );
};

const ImageMetadataEditor: FunctionComponent<{
    libraryItem: LibraryItem;
    disabled?: boolean;
    onMetadataChanged: (metadata: LibraryItemMetadata) => void;
}> = ({ libraryItem, disabled, onMetadataChanged }) => {
    const dispatch = useDispatch();
    const [metadata, setMetadata] = useState(libraryItem.metadata);

    const updateMetadata = (metadata: LibraryItemMetadata) => {
        setMetadata(metadata);
        onMetadataChanged(metadata);
    };

    const { focusedToken } = useAppState();
    const { system, campaign, location } = useLocation();
    const token = getToken(campaign, location?.id, focusedToken);

    const [rotationOverride, setRotationOverride] = useState<number | undefined>();
    const onRotationChanged = useDebounce(
        (rotation: number | undefined) => {
            updateMetadata(
                copyState(metadata, {
                    rotation: rotation,
                })
            );
            setRotationOverride(undefined);
        },
        500,
        (rotation: number) => {
            const r = rotation === 0 ? undefined : rotation;
            setRotationOverride(r);
            return [r];
        }
    );

    const [renderScaleOverride, setRenderScaleOverride] = useState<number | undefined>();
    const onRenderScaleChanged = useDebounce(
        (scale: number | undefined) => {
            updateMetadata(
                copyState(metadata, {
                    renderScale: scale,
                })
            );
            setRenderScaleOverride(undefined);
        },
        500,
        (scale: number) => {
            const s = scale === 1 ? undefined : scale;
            setRenderScaleOverride(s);
            return [s];
        }
    );

    return (
        <React.Fragment>
            <ImageThumbnail libraryItem={libraryItem} rotation={rotationOverride ?? metadata.rotation} />
            <MarkdownActions markdown={`![${libraryItem.name}](${libraryItem.uri})`} />
            <SelectField
                label="Type"
                required
                value={metadata.type}
                disabled={disabled || libraryItem.isReadOnly}
                onChange={e => updateMetadata(copyState(metadata, { type: e.target.value as ImageType }))}>
                {[ImageType.Background, ImageType.Token, ImageType.Portrait, ImageType.Object].map(o => (
                    <option key={o} value={o}>
                        {o.substring(0, 1).toUpperCase() + o.substring(1)}
                    </option>
                ))}
            </SelectField>
            {metadata.type === ImageType.Token && (
                <React.Fragment>
                    <CheckboxField
                        id="canRotate"
                        checked={!!(metadata.canRotate ?? true)}
                        label="Can rotate"
                        disabled={disabled || libraryItem.isReadOnly}
                        onChange={e => {
                            updateMetadata(copyState(metadata, { canRotate: e.target.checked }));
                        }}
                    />
                    <SliderField
                        label="Default rotation"
                        hint={
                            metadata.type === ImageType.Token
                                ? "The angle that the image should be rotated to be facing directly down."
                                : undefined
                        }
                        min={-180}
                        max={180}
                        style={{
                            paddingBottom: theme.space[4],
                            marginLeft: theme.space[3],
                            marginRight: theme.space[3],
                            width: "auto",
                        }}
                        disabled={!isLocation(location)}
                        value={rotationOverride ?? metadata.rotation ?? 0}
                        included={false}
                        marks={{
                            "-180": "-180°",
                            "-90": "-90°",
                            0: "0°",
                            90: "90°",
                            180: "180°",
                        }}
                        onChange={onRotationChanged}
                    />
                    <SliderField
                        label="Render scale"
                        hint="The default scale to render the image at, relative to the size of the token."
                        min={0.2}
                        max={5}
                        style={{
                            paddingBottom: theme.space[4],
                            marginLeft: theme.space[3],
                            marginRight: theme.space[3],
                            width: "auto",
                        }}
                        disabled={!isLocation(location)}
                        value={renderScaleOverride ?? metadata.renderScale ?? 1}
                        step={0.05}
                        included={false}
                        marks={{
                            "0.2": "",
                            "0.5": "50%",
                            1: "100%",
                            2: "200%",
                            3: "300%",
                            4: "400%",
                            5: "500%",
                        }}
                        onChange={onRenderScaleChanged}
                    />
                </React.Fragment>
            )}
            {metadata.type === ImageType.Object && (
                <React.Fragment>
                    <InputField
                        variant="number"
                        label="Tile width"
                        value={metadata.tilePxWidth ?? ""}
                        hint={"The tile width in pixels that the art was designed for."}
                        min={0}
                        disabled={disabled || libraryItem.isReadOnly}
                        onChange={e => {
                            updateMetadata(
                                copyState(metadata, {
                                    tilePxWidth: isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber,
                                })
                            );
                        }}
                    />
                    <InputField
                        variant="number"
                        label="Tile height"
                        value={metadata.tilePxHeight ?? ""}
                        hint={"The tile height in pixels that the art was designed for."}
                        min={0}
                        disabled={disabled || libraryItem.isReadOnly}
                        onChange={e => {
                            updateMetadata(
                                copyState(metadata, {
                                    tilePxHeight: isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber,
                                })
                            );
                        }}
                    />
                    <ZIndexField
                        label="Z index"
                        value={
                            metadata.zIndex ??
                            (metadata.type === ImageType.Object ? ZIndexes.Underlay : ZIndexes.Tokens)
                        }
                        disabled={disabled || libraryItem.isReadOnly}
                        onChange={e =>
                            updateMetadata(
                                copyState(metadata, {
                                    zIndex:
                                        e === (metadata.type === ImageType.Object ? ZIndexes.Underlay : ZIndexes.Tokens)
                                            ? undefined
                                            : e,
                                })
                            )
                        }
                    />
                </React.Fragment>
            )}

            {metadata.type === ImageType.Token && (
                <React.Fragment>
                    <Button
                        fullWidth
                        disabled={token == null}
                        onClick={() => {
                            dispatch(
                                setTokenImage(
                                    campaign.id,
                                    isTokenTemplate(token) ? undefined : location?.id,
                                    [token!],
                                    libraryItem
                                )
                            );
                        }}>
                        Add to {token ? system.getDisplayName(token, campaign) : "token"}
                    </Button>
                </React.Fragment>
            )}

            {metadata.type === ImageType.Portrait && (
                <React.Fragment>
                    <Button
                        fullWidth
                        disabled={token == null}
                        onClick={() => {
                            dispatch(
                                setPortraitImage(
                                    campaign.id,
                                    isTokenTemplate(token) ? undefined : location?.id,
                                    [token!],
                                    libraryItem
                                )
                            );
                        }}>
                        Apply to {token ? system.getDisplayName(token, campaign) : "token"}
                    </Button>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const AudioMetadataEditor: FunctionComponent<{
    libraryItem: LibraryItem;
    disabled?: boolean;
    onMetadataChanged: (metadata: LibraryItemMetadata) => void;
}> = ({ libraryItem, disabled, onMetadataChanged }) => {
    const [metadata, setMetadata] = useState(libraryItem.metadata);
    const dispatch = useDispatch();
    const { system, campaign, location } = useLocation();

    const updateMetadata = (metadata: LibraryItemMetadata) => {
        setMetadata(metadata);
        onMetadataChanged(metadata);
    };

    const { focusedToken } = useAppState();
    const token = getToken(campaign, location?.id, focusedToken);

    const selection = useSelection();
    let zones: Zone[] | undefined;
    if (isLocation(location)) {
        zones = getSelectedZones(selection.primary, location);
        if (!zones.length) {
            zones = getSelectedZones(selection.secondary, location);
        }
    }

    let zone = zones?.[0];

    return (
        <React.Fragment>
            <SelectField
                label="Type"
                required
                value={metadata.type}
                disabled={disabled || libraryItem.isReadOnly}
                onChange={e => updateMetadata(copyState(metadata, { type: e.target.value as ImageType }))}>
                .
                {[AudioType.Music, AudioType.Ambient].map(o => (
                    <option key={o} value={o}>
                        {o.substring(0, 1).toUpperCase() + o.substring(1)}
                    </option>
                ))}
            </SelectField>

            <Button
                fullWidth
                disabled={location == null}
                onClick={() => {
                    dispatch(addTrack(campaign.id, location!.id, libraryItem.metadata.type as AudioType, libraryItem));
                }}>
                Add to location
            </Button>

            {metadata.type === AudioType.Ambient && (
                <React.Fragment>
                    <Button
                        fullWidth
                        disabled={token == null}
                        onClick={() => {
                            dispatch(
                                modifyToken(campaign, location, token!, {
                                    sound: {
                                        uri: libraryItem.uri,
                                        name: libraryItem.name,
                                    },
                                })
                            );
                        }}>
                        Apply to {token ? system.getDisplayName(token, campaign) : "token"}
                    </Button>
                </React.Fragment>
            )}

            {metadata.type === AudioType.Ambient && (
                <React.Fragment>
                    <Button
                        fullWidth
                        disabled={location == null || zone == null}
                        onClick={() => {
                            dispatch(
                                modifyZone(campaign, location!, zone!, {
                                    sound: {
                                        uri: libraryItem.uri,
                                        name: libraryItem.name,
                                    },
                                })
                            );
                        }}>
                        Apply to {zone?.label ?? zone?.code ?? "zone"}
                    </Button>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const ModelMetadataEditor: FunctionComponent<{
    libraryItem: LibraryItem;
    disabled?: boolean;
    onMetadataChanged: (metadata: LibraryItemMetadata) => void;
}> = ({ libraryItem, disabled, onMetadataChanged }) => {
    const errorHandler = useErrorHandler();
    // const [metadata, setMetadata] = useState(libraryItem.metadata);
    const dispatch = useDispatch();
    const { system, campaign, location } = useLocation();

    // const updateMetadata = (metadata: LibraryItemMetadata) => {
    //     setMetadata(metadata);
    //     onMetadataChanged(metadata);
    // };

    const { focusedToken } = useAppState();
    const token = getToken(campaign, location?.id, focusedToken);

    const [isUploadingThumbnail, setIsUploadingThumbnail] = useState(false);

    const canvasRef = useRef<HTMLCanvasElement>(null);

    return (
        <React.Fragment>
            {isLocation(location) && (
                <React.Fragment>
                    <Box
                        bg="grayscale.9"
                        borderRadius={4}
                        width={268}
                        height={384}
                        onPointerDown={e => {
                            (e.target as Element).setPointerCapture(e.pointerId);
                            e.preventDefault();
                        }}>
                        <Canvas
                            linear
                            flat
                            legacy
                            ref={canvasRef}
                            camera={{ up: [0, 0, 1], position: [0, -3, 1] }}
                            gl={{ preserveDrawingBuffer: true }}>
                            <ambientLight intensity={0.2} />
                            <pointLight position={[10, -10, 10]} />
                            <OrbitControls />

                            <TokenModel
                                modelUri={libraryItem.uri}
                                z={-1.5}
                                errorMessage={() => `Failed to download model ${libraryItem.name}.`}
                            />
                        </Canvas>
                    </Box>
                    <Button
                        disabled={isUploadingThumbnail}
                        fullWidth
                        onClick={() => {
                            setIsUploadingThumbnail(true);
                            canvasRef.current?.toBlob(async blob => {
                                if (blob) {
                                    await UserArtLibrary.current.uploadThumbnail(libraryItem, blob, errorHandler);
                                    setIsUploadingThumbnail(false);
                                }
                            });
                        }}>
                        Update thumbnail
                    </Button>
                </React.Fragment>
            )}
            <Button
                fullWidth
                disabled={token == null}
                onClick={() => {
                    dispatch(
                        modifyToken(
                            campaign,
                            location,
                            token!,
                            {
                                modelUri: libraryItem.uri,
                            },
                            true
                        )
                    );
                }}>
                Apply to {token ? system.getDisplayName(token, campaign) : "token"}
            </Button>
        </React.Fragment>
    );
};

const LibraryItemPropertyEditor: FunctionComponent<{
    libraryItem: LibraryItem;
}> = ({ libraryItem }) => {
    const audio = isAudio(libraryItem);
    const model = isModel(libraryItem);
    const errorHandler = useErrorHandler();

    const [updatedLibraryItem, setUpdatedLibraryItem] = useState<LibraryItem>();
    var update = useDebounce(async (m: LibraryItemMetadata, n?: string) => {
        await UserArtLibrary.current.updateMetadata(libraryItem, m, n, errorHandler);
    }, 500);

    const o = libraryItem.uri === updatedLibraryItem?.uri ? updatedLibraryItem : libraryItem;
    return (
        <AnimatePresence mode="wait">
            <MotionForm key={o.uri} initial={defaultInitial} animate={defaultAnimate} exit={defaultExit} p={3}>
                <Heading as="h5" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                    {libraryItem.name}
                </Heading>
                <InputField
                    label="Name"
                    required
                    value={o.name}
                    disabled={libraryItem.isReadOnly}
                    onChange={e => {
                        setUpdatedLibraryItem(copyState(o, { name: e.target.value }));
                        update(o.metadata, e.target.value);
                    }}
                />
                {audio && (
                    <AudioMetadataEditor
                        libraryItem={o}
                        onMetadataChanged={async m => {
                            setUpdatedLibraryItem(copyState(o, { metadata: m }));
                            update(m);
                        }}
                    />
                )}
                {model && (
                    <ModelMetadataEditor
                        libraryItem={o}
                        onMetadataChanged={async m => {
                            setUpdatedLibraryItem(copyState(o, { metadata: m }));
                            update(m);
                        }}
                    />
                )}
                {!audio && !model && (
                    <ImageMetadataEditor
                        libraryItem={o}
                        onMetadataChanged={async m => {
                            setUpdatedLibraryItem(copyState(o, { metadata: m }));
                            update(m);
                        }}
                    />
                )}
            </MotionForm>
        </AnimatePresence>
    );
};

const defaultLoadedCount = 30;

function filterToString(filter: ArtFilter) {
    let s = "";
    if (filter.type) {
        s += filterTypeToString(filter.type);
    }

    return s;
}

function filterTypeToString(type: ImageType | AudioType | ModelType): string {
    switch (type) {
        case ImageType.Background:
            return "backgrounds";
        case ImageType.Object:
            return "objects";
        case ImageType.Portrait:
            return "portraits";
        case ImageType.Token:
            return "tokens";
        case AudioType.Ambient:
            return "ambient sounds";
        case AudioType.Music:
            return "music";
        case ModelType.Token:
            return "token models";
    }
}

export const ArtLibrary: FunctionComponent<{}> = React.memo(() => {
    const { items, isLoading, error, triggerCount } = UserArtLibrary.current.useItems();

    const sortedItems = useMemo(() => {
        return items.toSorted((a, b) => {
            return (b.lastModified ?? 0) - (a.lastModified ?? 0);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, triggerCount]);

    const forceUpdate = useForceUpdate();
    const errorHandler = useErrorHandler();

    const [addNotification] = useNotifications();

    const { isDragOver, ...dropEvents } = useDropEvents(
        dt => {
            return getMatchingDataTransferItems(
                dt,
                [
                    "image",
                    "audio",
                    "model/stl",
                    "model/x.stl-ascii",
                    "model/x.stl-binary",
                    "application/sla",
                    "model/3mf",
                ],
                [".stl", ".3mf"]
            );
        },
        (data, e) => {
            if (e.dataTransfer.files.length) {
                dropFiles(e.dataTransfer.files, addNotification, errorHandler);
            }
        }
    );

    const { searchTerm, searchResults, addPanel, clearPanels } = useVttApp();
    const search = searchResults.find(o => o.categoryId === Pages.Art)?.results;

    const [loadedCount, setLoadedCount] = useState(defaultLoadedCount);
    useEffect(() => {
        setLoadedCount(defaultLoadedCount);
    }, [search]);

    let allLibraryItems = search ? search.map(o => o.originalItem as LibraryItem) : sortedItems;

    const [filter] = useLocalSetting<ArtFilter>(artFilterSetting, {});
    let isLocalFilterApplied = false;
    if (filter.type) {
        allLibraryItems = allLibraryItems.filter(o => o.metadata.type != null && filter.type === o.metadata.type);
        isLocalFilterApplied = true;
    }

    const libraryItems = allLibraryItems.slice(0, loadedCount);

    const [selectedItem, setSelectedItem] = useState<string>();

    const selectedIndex = selectedItem == null ? -1 : libraryItems.findIndex(o => o.uri === selectedItem);
    useEffect(() => {
        if (selectedItem && selectedIndex < 0) {
            clearPanels();
        }
    });

    return (
        <React.Fragment>
            <AnimatePresence>
                {error && (
                    <AnimatedListItem fullWidth>
                        <Message alignSelf="stretch" mx={3} my={2} variant="error" flex="0 1 auto" fullWidth>
                            An error was encountered loading your art library.
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {(searchTerm !== "" || isLocalFilterApplied) && (
                    <AnimatedListItem fullWidth>
                        <Message alignSelf="stretch" mx={3} my={2} variant="info" flex="0 1 auto" fullWidth>
                            <motion.div layout="position">
                                {searchTerm !== "" && !isLocalFilterApplied && (
                                    <p>Showing only art matching "{searchTerm}"</p>
                                )}
                                {searchTerm === "" && isLocalFilterApplied && (
                                    <p>Showing only {filterToString(filter)}</p>
                                )}
                                {searchTerm !== "" && isLocalFilterApplied && (
                                    <p>
                                        Showing only {filterToString(filter)} matching "{searchTerm}"
                                    </p>
                                )}
                            </motion.div>
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>
            <LibraryItemHost>
                <ScrollableHack flex="1 1 auto" flexDirection="column" position="relative" fullWidth {...dropEvents}>
                    <Scrollable fullWidth minimal py={1} px={3}>
                        {isLoading && (
                            <Box alignSelf="stretch" m={3}>
                                <Loading />
                            </Box>
                        )}
                        <AnimatePresence>
                            {!isLoading && !error && !items.length && (
                                <AnimatedListItem fullWidth>
                                    <Message alignSelf="stretch" my={2} variant="info" flex="0 1 auto" fullWidth>
                                        Your library is empty. Drop files here to add them to your library.
                                    </Message>
                                </AnimatedListItem>
                            )}
                        </AnimatePresence>
                        <ListBox<LibraryItem>
                            selectActive
                            css={{
                                position: "relative",
                                flexDirection: "column",
                                alignItems: "stretch",
                                gap: theme.space[2],
                            }}
                            paddingY={3}
                            fullWidth
                            items={libraryItems}
                            selectedItems={selectedIndex < 0 ? undefined : [libraryItems[selectedIndex]]}
                            itemKey={o => o.uri}
                            onSelectionChanged={o => {
                                setSelectedItem(o && o.length ? o[0].uri : undefined);
                                if (o.length) {
                                    addPanel({
                                        id: o[0].id,
                                        children: () => <LibraryItemPropertyEditor libraryItem={o[0]} />,
                                    });
                                } else {
                                    clearPanels();
                                }
                            }}>
                            {({ item, index, selected, active, focused }) => {
                                return (
                                    <AnimatedListItem
                                        key={item.name}
                                        index={index % defaultLoadedCount}
                                        fullWidth
                                        borderRadius={3}>
                                        <LibraryItemElement
                                            libraryItem={item}
                                            onUpdate={forceUpdate}
                                            canSelect
                                            isSelected={selected}
                                            isActive={active}
                                            isFocused={focused}
                                        />
                                    </AnimatedListItem>
                                );
                            }}
                        </ListBox>
                        {libraryItems.length < allLibraryItems.length && (
                            <Button
                                mx={3}
                                key="loadmore"
                                variant="tertiary"
                                onClick={() => setLoadedCount(loadedCount + defaultLoadedCount)}>
                                {allLibraryItems.length - loadedCount} more…
                            </Button>
                        )}
                    </Scrollable>
                    <DropOverlay isDragOver={!!isDragOver} borderBottomLeftRadius={4} borderBottomRightRadius={4} />
                </ScrollableHack>
            </LibraryItemHost>
        </React.Fragment>
    );
});

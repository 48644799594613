import { useEffect, useRef, ReactElement } from "react";
import { useNotifications } from "./contexts";

export function usePermanentNotification(isVisible: boolean, content: () => ReactElement) {
    const addNotification = useNotifications();
    const promise = useRef<{ promise: Promise<void>; resolve: () => void }>();
    const contentFactory = useRef<() => ReactElement>();
    contentFactory.current = content;
    useEffect(() => {
        if (isVisible && !promise.current) {
            let r: () => void;
            var p = new Promise<void>(resolve => {
                r = resolve;
            });

            promise.current = { promise: p, resolve: r! };

            addNotification({
                content: contentFactory.current!(),
                canDismiss: false,
                showLife: false,
                promise: () => p,
            });
        } else if (!isVisible && promise.current) {
            promise.current.resolve();
            promise.current = undefined;
        }
    }, [isVisible, promise, addNotification]);
    useEffect(() => {
        return () => {
            if (promise.current) {
                promise.current.resolve();
            }
        };
    }, []);
}

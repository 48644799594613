import React from 'react'
  
  const ClosePolygonIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 21,8.25v7.5  M 5.25,2.824l13.5,2.7  M 3,18.75V5.25  M 18.75,18.476l-13.5,2.7  M 1.313,0.75h3.374 c0.311,0,0.563,0.252,0.563,0.563v3.374c0,0.311-0.252,0.563-0.563,0.563H1.313c-0.311,0-0.563-0.252-0.563-0.563V1.313 C0.75,1.002,1.002,0.75,1.313,0.75z  M 19.313,3.75h3.374c0.311,0,0.563,0.252,0.563,0.563v3.374c0,0.311-0.252,0.563-0.563,0.563 h-3.374c-0.311,0-0.563-0.252-0.563-0.563V4.313C18.75,4.002,19.002,3.75,19.313,3.75z  M 19.313,15.75h3.374 c0.311,0,0.563,0.252,0.563,0.563v3.374c0,0.311-0.252,0.563-0.563,0.563h-3.374c-0.311,0-0.563-0.252-0.563-0.563v-3.374 C18.75,16.002,19.002,15.75,19.313,15.75z  M 1.313,18.75h3.374c0.311,0,0.563,0.252,0.563,0.563v3.374 c0,0.311-0.252,0.563-0.563,0.563H1.313c-0.311,0-0.563-0.252-0.563-0.563v-3.374C0.75,19.002,1.002,18.75,1.313,18.75z' />
    </svg>
  )
  
  ClosePolygonIcon.displayName = 'ClosePolygonIcon'
  
  ClosePolygonIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default ClosePolygonIcon
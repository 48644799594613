import React from 'react'
  
  const D8Icon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 512 512'
      width={size}
      height={size}
      stroke={color}
    >
        <path fill={color} d="M256 37.143L77.896 343.853h356.208zM230.154 49.79L72 164.233v157.91zm51.69 0L440 322.144V164.232zm-132.516 90.02v21.786l-8.486 9.68v-16.942c-2.772 3.24-5.692 6.367-8.385 9.664 3.536.293 2.484 5.43 2.207 7.752-1.746 9.536-8.154 17.575-13.404 24.79-1.943 2.544-3.943 5.075-6.008 7.507-4.65 3.4-8.49 4.39-10.775-1.463.294-7.806 3.84-15.22 8.046-20.967l5.288-7.918c-.048 2.462.1 4.97-.072 7.405-2.34 4.347-6.766 9.46-5.758 14.608 7.76.874 12.22-10.863 14.522-16.58.832-2.76 2.108-5.458-1.418-5.434v-7.264zm212.13 1.21l44.853 54.464v7.262l-30.304-36.31v8.472l-6.06-7.262c-.155-4.15-1.424-8.377-2.425-12.103-1.86-2.936-3.96-5.704-6.06-8.47zM256.07 203.86c8.37 0 14.733 1.53 19.088 4.587 4.355 3.027 6.533 7.475 6.533 13.344 0 3.924-1.08 7.198-3.243 9.823-2.13 2.626-5.297 4.572-9.498 5.838 4.694 1.298 8.23 3.415 10.61 6.35 2.408 2.902 3.614 6.576 3.614 11.024 0 6.857-2.286 12.047-6.858 15.57-4.54 3.49-11.288 5.233-20.246 5.233-8.988 0-15.784-1.744-20.386-5.234-4.572-3.522-6.858-8.712-6.858-15.57 0-4.447 1.19-8.12 3.57-11.024 2.408-2.935 5.96-5.052 10.655-6.35-4.2-1.265-7.366-3.21-9.497-5.837-2.13-2.625-3.198-5.9-3.198-9.822 0-5.837 2.18-10.285 6.534-13.343 4.354-3.058 10.748-4.586 19.18-4.586zm0 11.212c-2.84 0-5.035.742-6.58 2.225-1.513 1.482-2.27 3.583-2.27 6.3 0 2.75.757 4.866 2.27 6.348 1.545 1.483 3.74 2.225 6.58 2.225 2.78 0 4.926-.742 6.44-2.225 1.513-1.482 2.27-3.598 2.27-6.347 0-2.75-.757-4.85-2.27-6.3-1.514-1.484-3.66-2.226-6.44-2.226zm0 28.262c-3.336 0-5.9.912-7.69 2.734-1.793 1.823-2.69 4.433-2.69 7.83 0 3.398.897 6.008 2.69 7.83 1.79 1.792 4.354 2.688 7.69 2.688 3.305 0 5.837-.896 7.598-2.687 1.76-1.824 2.64-4.434 2.64-7.832 0-3.428-.88-6.038-2.64-7.83-1.76-1.822-4.293-2.734-7.598-2.734zM88.7 359.852L256 480.912l167.3-121.06zm139.42 23.23h54.548v4.842c-14.406 5.942-21.582 12.897-27.88 25.416h-15.76c7.104-13.803 14.546-17.714 26.67-25.416h-37.58z"/>
    </svg>
  )
  
  D8Icon.displayName = 'D8Icon'
  
  D8Icon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default D8Icon
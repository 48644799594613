/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useState } from "react";
import { Box, Image, Heading, Text, Grid, Checkbox } from "../../../../components/primitives";
import { Button } from "../../../../components/Button";
import { Tag } from "../../../../components/Tag";
import {
    Senses,
    creatureTypeToString,
    modifierFromAbilityScore,
    ResolvedMonster,
    MonsterSpellcasting,
    MonsterSpell,
    damageTypesToMarkdown,
    getSpellDc,
    getSpellAttackModifier,
    resolveModifiedValue,
    crToString,
    abilityMeetsCondition,
    canUseCombatAbility,
    getAttackModifiers,
    Monster,
} from "../../creature";
import {
    Location,
    Campaign,
    IMenuItem,
    AnnotationPlacementTemplate,
    DiceType,
    isLocation,
    LocationSummary,
    parseDiceBag,
    isToken,
} from "../../../../store";
import { HitPointBox } from "./../HitPointBox";
import {
    Ability,
    creatureConditions,
    DamageType,
    damageTypes,
    DnD5EToken,
    DnD5ETokenTemplate,
    evaluateMonsterExpression,
    fromRuleKey,
    getRuleKey,
    isDnD5EMonsterTemplate,
    isDnD5EMonsterToken,
    ModifiedValue,
    NamedRuleRef,
} from "../../common";
import { AbilityScoreBox } from "./../AbilityScoreBox";
import { theme } from "../../../../design";
import { useRules } from "./../hooks";
import { SkillsBox } from "./../SkillsBox";
import {
    LobotomizedBox,
    SidebarButton,
    SmallText,
    resolveUri,
    tokenImageSize,
    useVttApp,
} from "../../../../components/common";
import CharacterSheetIcon from "../../../../components/icons/CharacterSheet";
import CombatIcon from "../../../../components/icons/Combat";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import {
    defaultAnimate,
    defaultExit,
    defaultInitial,
    MotionBox,
    MotionCard,
    MotionGrid,
    MotionHeading,
    MotionInOverlayCard,
    MotionLobotomizedBox,
    sectionAnimate,
    sectionExit,
    sectionInitial,
} from "../../../../components/motion";
import BookPileIcon from "../../../../components/icons/BookPile";
import SpellbookIcon from "../../../../components/icons/Spellbook";
import { useDiceBag, useDispatch, useLocalGrid, useLocation, useLocationLevel } from "../../../../components/contexts";
import { Spell } from "../../spells";
import { SpellInfo } from "./../SpellInfo";
import { clearTokenSpellSlot, getCastMenuItems, markInnateSpellUse, markTokenSpellSlot } from "../../actions/spells";
import { Markdown } from "../../../../components/markdown";
import { AnyAction, Dispatch } from "redux";
import { MonsterSavingThrowBox } from "./MonsterSavingThrowBox";
import { ILocalGrid } from "../../../../grid";
import { ConditionEditor } from "./../ConditionEditor";
import {
    clearLegendaryResistance,
    longRest,
    markLegendaryResistance,
    modifyMonster,
    shortRest,
} from "../../actions/token";
import { Event, keyedListToArray, keyedListToKeyArray, mapKeyedList } from "../../../../common";
import { Annotation } from "../../../../annotations";
import {
    getModifiedValueTooltip,
    getValueTooltip,
    renderIconForAbility,
    TooltipText,
    useDispatchAbility,
} from "./../common";
import { HitDiceUsage } from "./../HitDieUsage";
import { AppliedAbilityEffectItem } from "./../AppliedAbilityEffectItem";
import { ScrollableTest } from "../../../../components/ScrollableTest";
import { CreatureAbility } from "./../CharacterSheet";
import { RollButtonHorizontal } from "./../RollButton";
import { TextPopup } from "../../../../components/TextPopup";
import { CombatResources } from "./../CombatResources";
import { deleteItems } from "../../../../actions/location";
import { MarkdownActions } from "../../../../components/MarkdownActions";
import { getCreatureEditor } from "../CreatureEditor";
import create from "zustand";

function sensesToString(senses: Senses<ModifiedValue>) {
    let s = "";
    if (senses.darkvision != null) {
        s += `darkvision ${resolveModifiedValue(senses.darkvision)} ft.`;
    }

    if (senses.blindsight != null) {
        if (s) {
            s += ", ";
        }

        s += `blindsight ${resolveModifiedValue(senses.blindsight)} ft.`;
    }

    if (senses.tremorsense != null) {
        if (s) {
            s += ", ";
        }

        s += `tremorsense ${resolveModifiedValue(senses.tremorsense)} ft.`;
    }

    if (senses.truesight != null) {
        if (s) {
            s += ", ";
        }

        s += `truesight ${resolveModifiedValue(senses.truesight)} ft.`;
    }

    return s;
}

const SpellsPage: FunctionComponent<{
    monster: ResolvedMonster;
    token: DnD5EToken | DnD5ETokenTemplate;
}> = ({ monster, token }) => {
    const { campaign, location, levelKey } = useLocationLevel();

    const annotationPlacementTemplateChanged = useVttApp(state => state.annotationPlacementTemplateChanged);
    const dispatch = useDispatch();
    const rules = useRules();
    const grid = useLocalGrid();

    const spellsByLevel: {
        [level: number]: {
            source: MonsterSpellcasting;
            sourceKey: string;
            spell: Spell;
            usage: MonsterSpell;
        }[];
    } = {};

    var spellcastingKeys = keyedListToKeyArray(monster.spellcasting!);
    for (let spellcastingKey of spellcastingKeys) {
        var spellcasting = monster.spellcasting![spellcastingKey];

        var keys = Object.keys(spellcasting.spells);
        for (let i = 0; i < keys.length; i++) {
            var usage = spellcasting.spells[keys[i]];
            if (usage) {
                const spellRef = fromRuleKey(keys[i]);
                if (spellRef) {
                    const spell = rules.spells.get(spellRef);
                    if (spell) {
                        let spellsForLevel = spellsByLevel[spell.level];
                        if (!spellsForLevel) {
                            spellsForLevel = [];
                            spellsByLevel[spell.level] = spellsForLevel;
                        }

                        spellsForLevel.push({
                            source: spellcasting,
                            sourceKey: spellcastingKey,
                            spell: spell,
                            usage: usage,
                        });
                    }
                }
            }
        }
    }

    // Always show levels that have spell slots, even if there are no spells, as you can still use the slots for lower levels.
    monster.spellSlots.forEach((o, i) => {
        if (o > 0 && !spellsByLevel[i + 1]) {
            spellsByLevel[i + 1] = [];
        }
    });

    const spellLevelsWithSpells = Object.keys(spellsByLevel).map(o => parseInt(o));
    return (
        <LayoutGroup>
            <LobotomizedBox fullWidth flexDirection="column">
                {spellLevelsWithSpells.map(lvl => {
                    const spells = spellsByLevel[lvl];
                    const maxSlots = monster.spellSlots ? monster.spellSlots[lvl - 1] : 0;
                    const usedSlots = (monster.usedSpellSlots ? monster.usedSpellSlots[lvl - 1] : undefined) ?? 0;
                    const slotElements: JSX.Element[] = [];
                    for (let s = 0; s < maxSlots; s++) {
                        const key = `${isToken(token) ? token.id : token.templateId}_L${lvl}S${s}`;
                        slotElements.push(
                            <Checkbox
                                key={key}
                                id={key}
                                mr={1}
                                checked={s < usedSlots}
                                disabled={!isLocation(location) || isDnD5EMonsterTemplate(token)}
                                onChange={e => {
                                    if (e.target.checked) {
                                        dispatch(markTokenSpellSlot(campaign, location!, [token], lvl, undefined));
                                    } else {
                                        dispatch(clearTokenSpellSlot(campaign, location!, [token], lvl, undefined));
                                    }
                                }}
                            />
                        );
                    }

                    return (
                        <MotionLobotomizedBox key={lvl} flexDirection="column" fullWidth layout>
                            <MotionBox
                                flexDirection="row"
                                fullWidth
                                justifyContent="space-between"
                                mt={lvl > 1 || !spellsByLevel[0] ? 2 : ""}
                                layout>
                                <Heading as="h6">{lvl === 0 ? "CANTRIPS" : `LEVEL ${lvl}`}</Heading>
                                <Box>
                                    {slotElements.length > 0 && (
                                        <React.Fragment>
                                            {slotElements}
                                            SLOTS
                                        </React.Fragment>
                                    )}
                                </Box>
                            </MotionBox>
                            {levelKey &&
                                spells?.map(o => (
                                    <SpellInfo
                                        key={getRuleKey(o.spell)}
                                        spell={o.spell}
                                        isInOverlay
                                        dc={getSpellDc(monster, o.source)}
                                        actions={getActionsForSpell(
                                            monster,
                                            dispatch,
                                            annotationPlacementTemplateChanged,
                                            campaign,
                                            location,
                                            levelKey,
                                            grid,
                                            token,
                                            o
                                        )}
                                        reset={o.usage.reset}
                                        maxUses={evaluateMonsterExpression(
                                            monster,
                                            o.usage.maxUsesExpr,
                                            o.usage.maxUses
                                        )}
                                        used={monster.innateSpellUsage?.[o.sourceKey]?.[getRuleKey(o.spell)]?.used}
                                    />
                                ))}
                        </MotionLobotomizedBox>
                    );
                })}
            </LobotomizedBox>
        </LayoutGroup>
    );
};

function getActionsForSpell(
    monster: ResolvedMonster,
    dispatch: Dispatch<AnyAction>,
    evt: Event<AnnotationPlacementTemplate<Annotation> | undefined>,
    campaign: Campaign,
    location: Location | LocationSummary | undefined,
    levelKey: string,
    grid: ILocalGrid,
    token: DnD5EToken | DnD5ETokenTemplate,
    spellInfo: {
        source: MonsterSpellcasting;
        sourceKey: string;
        spell: Spell;
        usage: MonsterSpell;
    }
): IMenuItem[] | undefined {
    if (!isLocation(location)) {
        return undefined;
    }

    if (spellInfo.usage.maxUses != null) {
        // This is an innate spell with limited uses.
        // TODO: This should support annotation templates too.
        var used = monster.innateSpellUsage?.[spellInfo.sourceKey]?.[getRuleKey(spellInfo.spell)]?.used;
        if ((used ?? 0) < spellInfo.usage.maxUses) {
            return [
                {
                    label: "Use",
                    onClick: () => {
                        dispatch(markInnateSpellUse(campaign, location, [token], spellInfo.sourceKey, spellInfo.spell));
                    },
                },
            ];
        }
    } else if (spellInfo.usage.level != null && spellInfo.usage.level > 0) {
        const dc = getSpellDc(monster, spellInfo.source);
        const attackModifier = getSpellAttackModifier(monster, spellInfo.source);
        return getCastMenuItems(
            monster,
            spellInfo.spell,
            dc,
            attackModifier,
            spellInfo.source.ability,
            dispatch,
            evt,
            campaign,
            location,
            levelKey,
            grid,
            token
        );
    }

    return undefined;
}

const MonsterAbilities: FunctionComponent<{
    token: DnD5EToken | DnD5ETokenTemplate;
    monster: ResolvedMonster;
    abilityKeys: string[];
}> = ({ token, monster, abilityKeys }) => {
    return (
        <React.Fragment>
            {abilityKeys.map(o => (
                <CreatureAbility token={token} creature={monster} abilityKey={o} key={o} />
            ))}
        </React.Fragment>
    );
};

const CommonAbilities: FunctionComponent<{
    token: DnD5EToken | DnD5ETokenTemplate;
    monster: ResolvedMonster;
    abilities: (Ability & NamedRuleRef)[];
}> = ({ token, monster, abilities }) => {
    return (
        <React.Fragment>
            {abilities.map(o => {
                const key = getRuleKey(o);
                return <CreatureAbility token={token} creature={monster} action={o} abilityKey={key} key={key} />;
            })}
        </React.Fragment>
    );
};

function isWeaponAttack(ability: Ability) {
    return (
        (ability.attack === "mw" || ability.attack === "rw" || ability.attack === "mw,rw") &&
        ability.attackModifier != null
    );
}

const ActionsPage: FunctionComponent<{
    monster: ResolvedMonster;
    token: DnD5EToken | DnD5ETokenTemplate;
}> = ({ monster, token }) => {
    const { campaign, location } = useLocation();
    const rules = useRules();
    const dispatch = useDispatch();
    const { setDice } = useDiceBag();
    const dispatchAbility = useDispatchAbility(token, monster);

    const actionsList = rules.actions.all.filter(
        o => (o.time?.amount === 1 && o.time?.unit === "action") || o.isAttackAction
    );
    const bonusActionsList = rules.actions.all.filter(o => o.time?.amount === 1 && o.time?.unit === "bonus");
    const reactionsList = rules.actions.all.filter(o => o.time?.amount === 1 && o.time?.unit === "reaction");
    const othersList = rules.actions.all.filter(
        o =>
            !(
                o.time?.amount === 1 &&
                (o.time?.unit === "action" || o.time?.unit === "bonus" || o.time?.unit === "reaction")
            ) && !o.isAttackAction
    );

    const actionToString = o => `:action{id="${getRuleKey({ name: o.name, source: o.source })}"}`;

    const actionsTextEntry = actionsList
        .filter(o => !o.effects)
        .map(actionToString)
        .join(", ");
    const bonusesTextEntry = bonusActionsList
        .filter(o => !o.effects)
        .map(actionToString)
        .join(", ");
    const reactionsTextEntry = reactionsList
        .filter(o => !o.effects)
        .map(actionToString)
        .join(", ");
    const othersTextEntry = othersList
        .filter(o => !o.effects)
        .map(o => `:action{id="${getRuleKey({ name: o.name, source: o.source })}"}`)
        .join(", ");

    const commonActionAbilities = actionsList.filter(o => o.effects);
    const commonBonusActionAbilities = bonusActionsList.filter(o => o.effects);
    const commonReactionAbilities = reactionsList.filter(o => o.effects);
    const commonOtherAbilities = othersList.filter(o => o.effects);

    let allAbilityKeys = keyedListToKeyArray(monster.abilities);
    const allActionAbilities = allAbilityKeys?.filter(
        o =>
            (monster.abilities![o].time?.unit === "action" || monster.abilities![o].isAttackAction) &&
            abilityMeetsCondition(monster, monster.abilities![o], rules)
    );
    let attackActionAbilities = allActionAbilities?.filter(o => isWeaponAttack(monster.abilities![o]));
    let actionAbilities = allActionAbilities?.filter(o => !isWeaponAttack(monster.abilities![o]));
    let bonusAbilities = allAbilityKeys?.filter(
        o =>
            monster.abilities![o].time?.unit === "bonus" && abilityMeetsCondition(monster, monster.abilities![o], rules)
    );
    let reactionAbilities = allAbilityKeys?.filter(
        o =>
            monster.abilities![o].time?.unit === "reaction" &&
            abilityMeetsCondition(monster, monster.abilities![o], rules)
    );
    let legendaryAbilities = allAbilityKeys?.filter(
        o =>
            monster.abilities![o].time?.unit === "legendary" &&
            abilityMeetsCondition(monster, monster.abilities![o], rules)
    );
    let otherAbilities = allAbilityKeys?.filter(o => {
        const unit = monster.abilities![o].time?.unit;
        return (
            !monster.abilities![o].isAttackAction &&
            unit !== "action" &&
            unit !== "bonus" &&
            unit !== "reaction" &&
            unit !== "legendary" &&
            abilityMeetsCondition(monster, monster.abilities![o], rules)
        );
    });

    const usedLegendaryResistances = monster.usedLegendaryResistances ?? 0;
    const legendaryResistanceElements: JSX.Element[] = [];
    for (let s = 0; s < (monster.legendaryResistances ?? 0); s++) {
        const key = `${isToken(token) ? token.id : token.templateId}_LR${s}`;
        legendaryResistanceElements.push(
            <Checkbox
                key={key}
                id={key}
                mr={1}
                checked={s < usedLegendaryResistances}
                disabled={!isLocation(location)}
                onChange={e => {
                    if (e.target.checked) {
                        dispatch(markLegendaryResistance(campaign, location!, [token]));
                    } else {
                        dispatch(clearLegendaryResistance(campaign, location!, [token]));
                    }
                }}
            />
        );
    }

    const isInCombat = isToken(token)
        ? isLocation(location) && location.combat && !!location.combat.participants[token.id]
        : false;
    return (
        <React.Fragment>
            <AnimatePresence initial={false}>
                {(isInCombat || legendaryResistanceElements.length > 0) && (
                    <MotionBox
                        layout
                        mb={3}
                        flexDirection="column"
                        alignItems="flex-start"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        css={{ gap: theme.space[2] }}>
                        <MotionHeading as="h6" layout="position">
                            RESOURCES
                        </MotionHeading>
                        {isInCombat && (
                            <MotionBox layout flexDirection="row">
                                <CombatResources token={token as DnD5EToken} creature={monster} />
                            </MotionBox>
                        )}
                        {legendaryResistanceElements.length > 0 && (
                            <MotionBox layout flexDirection="column" alignItems="flex-start">
                                <Text>Legendary Resistances</Text>
                                <Box flexDirection="row">{legendaryResistanceElements}</Box>
                            </MotionBox>
                        )}
                    </MotionBox>
                )}
            </AnimatePresence>

            <Box fullWidth flexDirection="column" alignItems="flex-start">
                <MotionHeading as="h6" layout="position">
                    ACTIONS
                </MotionHeading>
                <MotionInOverlayCard layout fullWidth borderRadius={3} p={2}>
                    {!!attackActionAbilities?.length && (
                        <MotionGrid
                            layout="position"
                            fullWidth
                            gridTemplateColumns="max-content minmax(0, 1fr) max-content max-content"
                            gridAutoRows="min-content"
                            css={{
                                columnGap: 0,
                                justifyItems: "stretch",
                                alignItems: "stretch",
                            }}>
                            <Text fontSize={3}>Weapon</Text>
                            <Text fontSize={3} textAlign="center">
                                Range
                            </Text>
                            <Text fontSize={3}>Hit</Text>
                            <Text fontSize={3}>Damage</Text>
                            {attackActionAbilities?.map(o => {
                                const ability = monster.abilities![o];
                                const hitBonus = ability.attackModifier!;

                                const attackModifiers = getAttackModifiers(monster, undefined, ability);

                                const effectWithDamage = keyedListToArray(ability.effects)?.find(o => o.damage);
                                let damageRoll: string | undefined;
                                let damageType: DamageType;
                                if (effectWithDamage) {
                                    for (let i = 0; damageRoll == null && i < damageTypes.length; i++) {
                                        damageRoll = effectWithDamage.damage![damageTypes[i]]?.base;
                                        damageType = damageTypes[i];
                                    }
                                }

                                const icon = renderIconForAbility(ability, monster, rules, 20);
                                return (
                                    <React.Fragment key={o}>
                                        <TextPopup
                                            text={ability.name}
                                            placement="top-start"
                                            css={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                gridColumn: "span 4 / span 4",
                                            }}>
                                            <Markdown>{`### ${ability.name}\n\n${
                                                ability.content != null ? ability.content : ""
                                            }`}</Markdown>
                                        </TextPopup>
                                        <Button
                                            size="s"
                                            disabled={!canUseCombatAbility(location, token, monster, ability, o)}
                                            css={{
                                                borderBottomRightRadius: 0,
                                                borderTopRightRadius: 0,
                                                height: "100%",
                                            }}
                                            onClick={() => {
                                                dispatchAbility?.(ability!, o);
                                            }}>
                                            {icon?.icon}
                                        </Button>
                                        <Box bg="grayscale.9">
                                            {ability.reach != null && <Text>{ability.reach}ft.</Text>}
                                            {ability.rangeNear != null && <Text>{ability.rangeNear}ft.</Text>}
                                            {typeof ability.range === "number" && (
                                                <SmallText> ({ability.range}ft.)</SmallText>
                                            )}
                                            {ability.range === "touch" && <SmallText> (5ft.)</SmallText>}
                                            {(ability.range === "sight" || ability.range === "unlimited") && (
                                                <SmallText> (unlimited)</SmallText>
                                            )}
                                        </Box>
                                        <RollButtonHorizontal
                                            size="s"
                                            tooltip={getValueTooltip(hitBonus, undefined, attackModifiers)}
                                            css2={{ height: "100%", borderRadius: 0 }}
                                            modifier={hitBonus}
                                            token={token}
                                            extraRolls={attackModifiers.extraRolls}
                                            advantage={attackModifiers.advantage}
                                            data={{ attack: ability.attack! }}>
                                            <Text fontSize={2}>{hitBonus >= 0 ? "+" + hitBonus : hitBonus}</Text>
                                        </RollButtonHorizontal>
                                        {damageRoll && (
                                            <Button
                                                size="s"
                                                fullWidth
                                                disabled={!isLocation(location)}
                                                css={{
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 0,
                                                    height: "100%",
                                                }}
                                                onClick={() => {
                                                    setDice(
                                                        parseDiceBag(damageRoll!, {
                                                            location: location!.id,
                                                            token: isToken(token) ? token.id : token.templateId,
                                                            data: { dmgType: damageType! },
                                                        })
                                                    );
                                                }}>
                                                <Text fontSize={2}>{damageRoll}</Text>
                                            </Button>
                                        )}
                                        {!damageRoll && <Box />}
                                        <Box css={{ gridColumn: "span 4 / span 4" }} mb={3}></Box>
                                    </React.Fragment>
                                );
                            })}
                        </MotionGrid>
                    )}
                    <Box
                        fullWidth
                        flexDirection="column"
                        alignItems="flex-start"
                        css={{ gap: theme.space[2] }}
                        mb={actionsTextEntry ? 2 : undefined}>
                        {actionAbilities != null && actionAbilities.length > 0 && (
                            <MonsterAbilities token={token} monster={monster} abilityKeys={actionAbilities} />
                        )}
                        {commonActionAbilities != null && commonActionAbilities.length > 0 && (
                            <CommonAbilities token={token} monster={monster} abilities={commonActionAbilities} />
                        )}
                        {actionsTextEntry && <Markdown>{actionsTextEntry}</Markdown>}
                    </Box>
                </MotionInOverlayCard>
            </Box>

            <Box mt={3} fullWidth flexDirection="column" alignItems="flex-start">
                <MotionHeading as="h6" layout="position">
                    BONUS ACTIONS
                </MotionHeading>
                <MotionInOverlayCard layout fullWidth borderRadius={3} p={2}>
                    <Box fullWidth flexDirection="column" alignItems="flex-start" css={{ gap: theme.space[2] }}>
                        {bonusAbilities != null && bonusAbilities.length > 0 && (
                            <MonsterAbilities token={token} monster={monster} abilityKeys={bonusAbilities} />
                        )}
                        {commonBonusActionAbilities != null && commonBonusActionAbilities.length > 0 && (
                            <CommonAbilities token={token} monster={monster} abilities={commonBonusActionAbilities} />
                        )}
                        {bonusesTextEntry && <Markdown>{bonusesTextEntry}</Markdown>}
                    </Box>
                </MotionInOverlayCard>
            </Box>

            <Box mt={3} fullWidth flexDirection="column" alignItems="flex-start">
                <MotionHeading as="h6" layout="position">
                    REACTIONS
                </MotionHeading>
                <MotionInOverlayCard layout fullWidth borderRadius={3} p={2}>
                    <Box fullWidth flexDirection="column" alignItems="flex-start" css={{ gap: theme.space[2] }}>
                        {reactionAbilities != null && reactionAbilities.length > 0 && (
                            <MonsterAbilities token={token} monster={monster} abilityKeys={reactionAbilities} />
                        )}
                        {commonReactionAbilities != null && commonReactionAbilities.length > 0 && (
                            <CommonAbilities token={token} monster={monster} abilities={commonReactionAbilities} />
                        )}
                        {reactionsTextEntry && <Markdown>{reactionsTextEntry}</Markdown>}
                    </Box>
                </MotionInOverlayCard>
            </Box>

            {legendaryAbilities && legendaryAbilities.length > 0 && (
                <Box mt={3} fullWidth flexDirection="column" alignItems="flex-start">
                    <MotionHeading as="h6" layout="position">
                        LEGENDARY ACTIONS
                    </MotionHeading>
                    <MotionInOverlayCard layout fullWidth borderRadius={3} p={2}>
                        <Box fullWidth flexDirection="column" alignItems="flex-start" css={{ gap: theme.space[2] }}>
                            {legendaryAbilities != null && legendaryAbilities.length > 0 && (
                                <MonsterAbilities token={token} monster={monster} abilityKeys={legendaryAbilities} />
                            )}
                        </Box>
                    </MotionInOverlayCard>
                </Box>
            )}

            <Box mt={3} fullWidth flexDirection="column" alignItems="flex-start">
                <MotionHeading as="h6" layout="position">
                    OTHER
                </MotionHeading>
                <MotionInOverlayCard layout fullWidth borderRadius={3} p={2}>
                    <Box fullWidth flexDirection="column" alignItems="flex-start" css={{ gap: theme.space[2] }}>
                        {otherAbilities != null && otherAbilities.length > 0 && (
                            <MonsterAbilities token={token} monster={monster} abilityKeys={otherAbilities} />
                        )}
                        {commonOtherAbilities != null && commonOtherAbilities.length > 0 && (
                            <CommonAbilities token={token} monster={monster} abilities={commonOtherAbilities} />
                        )}
                        {othersTextEntry && <Markdown>{othersTextEntry}</Markdown>}
                    </Box>
                </MotionInOverlayCard>
            </Box>
        </React.Fragment>
    );
};

export enum MonsterSheetPage {
    CoreAbilities,
    Actions,
    Spells,
    Features,
}

export const MonsterTags: FunctionComponent<{ monster: ResolvedMonster | Monster }> = ({ monster }) => {
    return (
        <Box flexDirection="row">
            {monster.creatureType && (
                <Tag mr={1} bg="purples.7" color="purples.0">
                    {creatureTypeToString(monster.creatureType)}
                </Tag>
            )}
            <Tag mr={1} bg="oranges.7" color="oranges.0">
                {monster.size}
            </Tag>
            {monster.alignment && (
                <Tag mr={1} bg="blues.7" color="blues.0">
                    {monster.alignment}
                </Tag>
            )}
            {monster.cr != null && (
                <Tag mr={1} bg="yellows.7" color="yellows.0">
                    CR{crToString(monster.cr)}
                </Tag>
            )}
        </Box>
    );
};

export const useMonsterSheet = create<{
    page: MonsterSheetPage;
    setPage: (page: MonsterSheetPage) => void;
}>(set => ({
    page: MonsterSheetPage.CoreAbilities,
    setPage: page => {
        set(() => ({ page: page }));
    },
}));

export const MonsterSheetContent: FunctionComponent<{
    monster: ResolvedMonster;
    thumbnailUri?: string;
    token: DnD5EToken | DnD5ETokenTemplate;
}> = ({ monster, thumbnailUri, token }) => {
    const imageUri = thumbnailUri ?? token.imageUri;

    const { page, setPage } = useMonsterSheet();
    const { campaign, location } = useLocation();
    const [isShortRestOpen, setIsShortRestOpen] = useState(false);
    const dispatch = useDispatch();

    const addOverlay = useVttApp(state => state.addOverlay);

    return (
        <Box flexDirection="column" alignItems="flex-start" flex="1 1 auto" fullWidth fullHeight>
            <MotionBox layout flexDirection="row" fullWidth justifyContent="flex-start" px={3} pt={3}>
                <Box
                    width={tokenImageSize}
                    height={tokenImageSize}
                    bg="grayscale.7"
                    borderRadius={3}
                    mr={2}
                    css={{ overflow: "hidden" }}>
                    {imageUri ? <Image src={resolveUri(imageUri)} responsive fullWidth fullHeight /> : ""}
                </Box>
                <Box flexDirection="column" alignItems="flex-start">
                    <Heading as="h4" mb={1}>
                        {monster.name}
                    </Heading>
                    <MonsterTags monster={monster} />
                </Box>
            </MotionBox>
            <MotionLobotomizedBox layout fullWidth mt={2} justifyContent="flex-start" px={3}>
                {isDnD5EMonsterToken(token) && (
                    <Button
                        disabled={!isLocation(location)}
                        onClick={() => addOverlay(getCreatureEditor(token.id, location?.id, "right"))}>
                        Edit…
                    </Button>
                )}
                {!isDnD5EMonsterTemplate(token) && (
                    <Button
                        disabled={!isLocation(location)}
                        toggled={isShortRestOpen}
                        onClick={() => {
                            setIsShortRestOpen(!isShortRestOpen);
                        }}>
                        Short rest
                    </Button>
                )}
                {!isDnD5EMonsterTemplate(token) && (
                    <Button
                        disabled={isShortRestOpen || !isLocation(location)}
                        onClick={() => {
                            dispatch(longRest(campaign, location, [token]));
                        }}>
                        Long rest
                    </Button>
                )}
                <MarkdownActions markdown={`:monster{id="${getRuleKey(monster)}"}`} />
            </MotionLobotomizedBox>
            <AnimatePresence>
                {isShortRestOpen && (
                    <MotionBox
                        flexDirection="column"
                        alignItems="flex-start"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        mt={2}
                        px={3}>
                        {(Object.keys(monster.maxHpInfo.dice ?? {}) as DiceType[]).map((o, i) => {
                            return (
                                <HitDiceUsage
                                    key={i}
                                    token={token}
                                    spent={monster.hitDiceSpent?.[o]}
                                    total={monster.maxHpInfo.dice?.[o]?.[0]?.amount ?? 1}
                                    constitution={resolveModifiedValue(monster.constitution)}
                                    hitDieType={o}
                                    hitDieRef={o}
                                />
                            );
                        })}
                        <Button
                            mt={2}
                            onClick={() => {
                                setIsShortRestOpen(false);
                                dispatch(shortRest(campaign, location, [token]));
                            }}>
                            Finish short rest
                        </Button>
                    </MotionBox>
                )}
                {monster.isDead && location && isDnD5EMonsterToken(token) && (
                    <Box px={3} mt={3} fullWidth>
                        <MotionCard
                            layout
                            px={3}
                            py={2}
                            fullWidth
                            borderRadius={4}
                            bg="guidance.error.1"
                            color="guidance.error.0"
                            boxShadowSize="none"
                            flexDirection="column"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <Text textAlign="center" color="inherit" fontSize={1}>
                                DEAD
                            </Text>
                            <Box mt={2} css={{ gap: theme.space[2] }}>
                                <Button
                                    onClick={() =>
                                        dispatch(
                                            modifyMonster(campaign, location, [token], {
                                                isDead: undefined,
                                            })
                                        )
                                    }>
                                    Change to unconscious
                                </Button>
                                <Button onClick={() => dispatch(deleteItems(campaign.id, location.id, [token]))}>
                                    Remove
                                </Button>
                            </Box>
                        </MotionCard>
                    </Box>
                )}
            </AnimatePresence>

            <MotionBox
                layout
                fullWidth
                px={3}
                py={2}
                mt={3}
                flexWrap="wrap"
                justifyContent="flex-start"
                css={{ gap: theme.space[2], boxSizing: "content-box" }}>
                <SidebarButton
                    tooltip="Core Abilities"
                    toggled={page === MonsterSheetPage.CoreAbilities}
                    onClick={() => setPage(MonsterSheetPage.CoreAbilities)}>
                    <CharacterSheetIcon />
                </SidebarButton>
                <SidebarButton
                    tooltip="Actions"
                    toggled={page === MonsterSheetPage.Actions}
                    onClick={() => setPage(MonsterSheetPage.Actions)}>
                    <CombatIcon />
                </SidebarButton>
                {monster.spellcasting && (
                    <SidebarButton
                        tooltip="Spells"
                        toggled={page === MonsterSheetPage.Spells}
                        onClick={() => setPage(MonsterSheetPage.Spells)}>
                        <SpellbookIcon />
                    </SidebarButton>
                )}
                <SidebarButton
                    tooltip="Features"
                    toggled={page === MonsterSheetPage.Features}
                    onClick={() => setPage(MonsterSheetPage.Features)}>
                    <BookPileIcon />
                </SidebarButton>
            </MotionBox>
            <ScrollableTest minimal fullWidth fullHeight alignItems="stretch" p={3}>
                <AnimatePresence mode="wait" initial={false}>
                    {page === MonsterSheetPage.CoreAbilities && (
                        <MotionBox
                            key="CoreAbilities"
                            layout
                            flexDirection="column"
                            initial={sectionInitial}
                            animate={sectionAnimate}
                            exit={sectionExit}>
                            <Grid
                                css={{ gap: theme.space[2] }}
                                gridTemplateColumns="1fr 1fr 1fr"
                                gridTemplateRows="auto auto"
                                mb={2}
                                fullWidth>
                                <AbilityScoreBox ability="strength" value={monster.strength} token={token} />
                                <AbilityScoreBox ability="dexterity" value={monster.dexterity} token={token} />
                                <AbilityScoreBox ability="constitution" value={monster.constitution} token={token} />
                                <AbilityScoreBox ability="intelligence" value={monster.intelligence} token={token} />
                                <AbilityScoreBox ability="wisdom" value={monster.wisdom} token={token} />
                                <AbilityScoreBox ability="charisma" value={monster.charisma} token={token} />
                            </Grid>
                            <HitPointBox token={token} creature={monster} />
                            <MotionInOverlayCard
                                layout
                                p={2}
                                flexDirection="column"
                                fullWidth
                                mb={2}
                                borderRadius={4}
                                boxShadowSize="none">
                                <Text textAlign="center" fontSize={1}>
                                    ARMOR CLASS
                                </Text>
                                <Box flexDirection="row">
                                    <Text fontSize={4} mr={2}>
                                        {monster.ac?.value ?? 10}
                                    </Text>
                                    {monster.ac?.from && <Markdown>{monster.ac?.from.join(", ")}</Markdown>}
                                </Box>
                            </MotionInOverlayCard>
                            <MotionInOverlayCard
                                layout
                                p={2}
                                flexDirection="column"
                                fullWidth
                                mb={2}
                                borderRadius={4}
                                boxShadowSize="none">
                                <Text textAlign="center" fontSize={1}>
                                    SAVING THROWS
                                </Text>
                                <Grid
                                    css={{ gap: theme.space[2] }}
                                    gridTemplateColumns="1fr 1fr"
                                    gridTemplateRows="auto auto auto">
                                    <MonsterSavingThrowBox
                                        variant="inOverlayTransparent"
                                        ability="strength"
                                        monster={monster}
                                        token={token}
                                    />
                                    <MonsterSavingThrowBox
                                        variant="inOverlayTransparent"
                                        ability="dexterity"
                                        monster={monster}
                                        token={token}
                                    />
                                    <MonsterSavingThrowBox
                                        variant="inOverlayTransparent"
                                        ability="constitution"
                                        monster={monster}
                                        token={token}
                                    />
                                    <MonsterSavingThrowBox
                                        variant="inOverlayTransparent"
                                        ability="intelligence"
                                        monster={monster}
                                        token={token}
                                    />
                                    <MonsterSavingThrowBox
                                        variant="inOverlayTransparent"
                                        ability="wisdom"
                                        monster={monster}
                                        token={token}
                                    />
                                    <MonsterSavingThrowBox
                                        variant="inOverlayTransparent"
                                        ability="charisma"
                                        monster={monster}
                                        token={token}
                                    />
                                </Grid>
                            </MotionInOverlayCard>
                            <SkillsBox token={token} skills={monster.skillChecks} />
                            <MotionInOverlayCard
                                layout
                                p={2}
                                flexDirection="column"
                                fullWidth
                                mb={2}
                                borderRadius={4}
                                boxShadowSize="none">
                                <Text textAlign="center" fontSize={1}>
                                    SPEED
                                </Text>
                                <Grid
                                    fullWidth
                                    paddingX={3}
                                    gridTemplateColumns="min-content auto"
                                    css={{ gap: `0 ${theme.space[3]}px` }}>
                                    {monster.speed.walk && (
                                        <React.Fragment>
                                            <Text color="grayscale.2">WALK</Text>
                                            <TooltipText tooltip={getModifiedValueTooltip(monster.speed.walk)}>
                                                {resolveModifiedValue(monster.speed.walk)}
                                            </TooltipText>
                                        </React.Fragment>
                                    )}

                                    {monster.speed.fly && (
                                        <React.Fragment>
                                            <Text color="grayscale.2">FLY</Text>
                                            <TooltipText tooltip={getModifiedValueTooltip(monster.speed.fly)}>
                                                {resolveModifiedValue(monster.speed.fly)}
                                            </TooltipText>
                                        </React.Fragment>
                                    )}

                                    {monster.speed.climb && (
                                        <React.Fragment>
                                            <Text color="grayscale.2">CLIMB</Text>
                                            <TooltipText tooltip={getModifiedValueTooltip(monster.speed.climb)}>
                                                {resolveModifiedValue(monster.speed.climb)}
                                            </TooltipText>
                                        </React.Fragment>
                                    )}

                                    {monster.speed.swim && (
                                        <React.Fragment>
                                            <Text color="grayscale.2">SWIM</Text>
                                            <TooltipText tooltip={getModifiedValueTooltip(monster.speed.swim)}>
                                                {resolveModifiedValue(monster.speed.swim)}
                                            </TooltipText>
                                        </React.Fragment>
                                    )}

                                    {monster.speed.burrow && (
                                        <React.Fragment>
                                            <Text color="grayscale.2">BURROW</Text>
                                            <TooltipText tooltip={getModifiedValueTooltip(monster.speed.burrow)}>
                                                {resolveModifiedValue(monster.speed.burrow)}
                                            </TooltipText>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </MotionInOverlayCard>
                            {monster.languages && monster.languages.length && (
                                <MotionInOverlayCard
                                    layout
                                    p={2}
                                    flexDirection="column"
                                    fullWidth
                                    mb={2}
                                    borderRadius={4}
                                    boxShadowSize="none">
                                    <Text textAlign="center" fontSize={1}>
                                        LANGUAGES
                                    </Text>
                                    <Box fullWidth paddingX={3} flexDirection="column" alignItems="flex-start">
                                        <Text>{monster.languages?.join(", ")}</Text>
                                    </Box>
                                </MotionInOverlayCard>
                            )}
                            <MotionInOverlayCard
                                layout
                                p={2}
                                flexDirection="column"
                                fullWidth
                                mb={2}
                                borderRadius={4}
                                boxShadowSize="none">
                                <Text textAlign="center" fontSize={1}>
                                    SENSES
                                </Text>
                                <Box fullWidth paddingX={3} flexDirection="column" alignItems="flex-start">
                                    <Text>
                                        {[
                                            sensesToString(monster.senses),
                                            `Passive Perception ${
                                                monster.passivePerception ??
                                                10 + modifierFromAbilityScore(resolveModifiedValue(monster.wisdom))
                                            }`,
                                        ].join(", ")}
                                    </Text>
                                </Box>
                            </MotionInOverlayCard>
                            {(monster.damageImmunities ||
                                monster.resistances ||
                                monster.vulnerabilities ||
                                monster.conditionImmunities) && (
                                <MotionInOverlayCard
                                    layout
                                    p={2}
                                    flexDirection="column"
                                    fullWidth
                                    mb={2}
                                    borderRadius={4}
                                    boxShadowSize="none">
                                    <Text textAlign="center" fontSize={1}>
                                        IMMUNITIES, RESISTANCES, VULNERABILITIES
                                    </Text>
                                    <Box fullWidth paddingX={3} flexDirection="column" alignItems="flex-start">
                                        {mapKeyedList(monster.damageImmunities, (o, i, k) => (
                                            <React.Fragment key={k}>
                                                <Markdown>{damageTypesToMarkdown("Immune", o)}</Markdown>
                                            </React.Fragment>
                                        ))}
                                        {mapKeyedList(monster.resistances, (o, i, k) => (
                                            <React.Fragment key={k}>
                                                <Markdown>{damageTypesToMarkdown("Resistant", o)}</Markdown>
                                            </React.Fragment>
                                        ))}
                                        {mapKeyedList(monster.vulnerabilities, (o, i, k) => (
                                            <React.Fragment key={k}>
                                                <Markdown>{damageTypesToMarkdown("Vulnerable", o)}</Markdown>
                                            </React.Fragment>
                                        ))}
                                        {mapKeyedList(monster.conditionImmunities, (o, i, k) => (
                                            <React.Fragment key={k}>
                                                <Markdown>{`Immune to ${creatureConditions
                                                    .filter(c => o[c])
                                                    .join(", ")}${o.condition ? ` (${o.condition})` : ""}`}</Markdown>
                                            </React.Fragment>
                                        ))}
                                    </Box>
                                </MotionInOverlayCard>
                            )}
                            {!isDnD5EMonsterTemplate(token) && (
                                <MotionInOverlayCard
                                    layout
                                    p={2}
                                    flexDirection="column"
                                    fullWidth
                                    mb={2}
                                    borderRadius={4}
                                    boxShadowSize="none"
                                    alignItems="stretch">
                                    <Text textAlign="center" fontSize={1}>
                                        CONDITIONS
                                    </Text>
                                    <Box paddingX={3} alignItems="stretch" flexDirection="column">
                                        <ConditionEditor creature={monster} token={token} />
                                    </Box>
                                </MotionInOverlayCard>
                            )}
                            {monster.effects && (
                                <MotionInOverlayCard
                                    layout
                                    p={2}
                                    flexDirection="column"
                                    fullWidth
                                    mb={2}
                                    borderRadius={4}
                                    boxShadowSize="none"
                                    alignItems="stretch">
                                    <Text textAlign="center" fontSize={1}>
                                        EFFECTS
                                    </Text>
                                    {mapKeyedList(monster.effects, (o, i, key) => (
                                        <AppliedAbilityEffectItem
                                            key={key}
                                            token={token}
                                            resolvedCreature={monster}
                                            effect={o}
                                            effectKey={key}
                                        />
                                    ))}
                                </MotionInOverlayCard>
                            )}
                        </MotionBox>
                    )}

                    {page === MonsterSheetPage.Actions && (
                        <MotionLobotomizedBox
                            key="Actions"
                            layout
                            flexDirection="column"
                            fullWidth
                            alignItems="flex-start"
                            initial={sectionInitial}
                            animate={sectionAnimate}
                            exit={sectionExit}>
                            <ActionsPage monster={monster} token={token} />
                        </MotionLobotomizedBox>
                    )}

                    {page === MonsterSheetPage.Spells && (
                        <MotionBox
                            key="Spells"
                            layout
                            flexDirection="column"
                            fullWidth
                            initial={sectionInitial}
                            animate={sectionAnimate}
                            exit={sectionExit}>
                            <SpellsPage monster={monster} token={token} />
                        </MotionBox>
                    )}

                    {page === MonsterSheetPage.Features && (
                        <MotionLobotomizedBox
                            key="Features"
                            layout
                            flexDirection="column"
                            fullWidth
                            alignItems="flex-start"
                            initial={sectionInitial}
                            animate={sectionAnimate}
                            exit={sectionExit}>
                            {monster.traits &&
                                mapKeyedList(monster.traits, (o, i, k) => (
                                    <Markdown key={k}>{`### ${o.name}\n${o.content}`}</Markdown>
                                ))}
                        </MotionLobotomizedBox>
                    )}
                </AnimatePresence>
            </ScrollableTest>
        </Box>
    );
};

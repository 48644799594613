import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import * as RadixPopover from "@radix-ui/react-popover";

export const Popover = ({ trigger, children }) => {
    return (
        <RadixPopover.Root>
            <RadixPopover.Trigger asChild>
                <Trigger type="button">{trigger}</Trigger>
            </RadixPopover.Trigger>
            <RadixPopover.Portal>
                <PopoverContent sideOffset={5}>{children}</PopoverContent>
            </RadixPopover.Portal>
        </RadixPopover.Root>
    );
};

const slideUpAndFade = keyframes({
    "0%": { opacity: 0, transform: "translateY(2px)" },
    "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
    "0%": { opacity: 0, transform: "translateX(-2px)" },
    "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
    "0%": { opacity: 0, transform: "translateY(-2px)" },
    "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
    "0%": { opacity: 0, transform: "translateX(2px)" },
    "100%": { opacity: 1, transform: "translateX(0)" },
});

const PopoverContent = styled(RadixPopover.Content)({
    boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
    },
});

const Trigger = styled("button")({
    all: "unset",
    fontFamily: "inherit",
    borderRadius: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "grayscale.3",
    "&:hover": { backgroundColor: "grayscale.3" },
    "&:focus": { boxShadow: `0 0 0 2px black` },
});

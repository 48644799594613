import React, { FunctionComponent, useMemo, RefObject, useCallback, PropsWithChildren } from "react";
import { Mesh, ShapeGeometry, Vector2 } from "three";
import { MeshProps, MeshBase, MaterialProps } from "./common";
import { Point } from "../../../position";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { withDragEvents } from "./dragevents";
import { useForwardedRef } from "../../utils";
import { areArraysEqual } from "../../../common";

interface ShapeProps extends MeshProps, MaterialProps {
    points: Point[];
}

export const Shape: FunctionComponent<PropsWithChildren<ShapeProps>> = React.forwardRef<Mesh, ShapeProps>(
    ({ points, ...props }, forwardedRef) => {
        const ref = useForwardedRef(forwardedRef);

        const cache = useMemo(
            () => ({
                points: undefined as Point[] | undefined,
                geometryNeedsUpdating: true,
            }),
            []
        );
        if (!cache.points) {
            cache.points = points;
        } else {
            if (!areArraysEqual(cache.points, points, (a, b) => a.x === b.x && a.y === b.y)) {
                cache.points = points;
                cache.geometryNeedsUpdating = true;
            }
        }

        const callback = useCallback(() => {
            if (cache.geometryNeedsUpdating && cache.points) {
                cache.geometryNeedsUpdating = false;
                const shape = new THREE.Shape(cache.points.map(o => new Vector2(o.x, -o.y)));
                ((ref as RefObject<Mesh>).current as Mesh).geometry = new ShapeGeometry(shape);
            }
        }, []); // eslint-disable-line react-hooks/exhaustive-deps
        useFrame(callback);

        return <MeshBase ref={ref} {...props}></MeshBase>;
    }
);

export const DraggableShape = withDragEvents(Shape);

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { commandsCtx, editorStateCtx, CmdKey, Editor } from "@milkdown/core";
import { EditorView } from "@milkdown/prose/view";
import { wrapIn, setBlockType } from "@milkdown/prose/commands";
import { EditorState, TextSelection } from "@milkdown/prose/state";
import { FunctionComponent, ReactNode, useEffect } from "react";
import { Box, Select } from "../primitives";
import { ToolbarButton } from "../Toolbar";
import { MarkType } from "@milkdown/prose/model";
import { Spacer } from "../Spacer";
import { liftListItem, sinkListItem } from "@milkdown/prose/schema-list";
import { useInstance } from "@milkdown/react";
import React from "react";
import {
    toggleEmphasisCommand,
    toggleStrongCommand,
    wrapInBulletListCommand,
    wrapInOrderedListCommand,
    liftListItemCommand,
    sinkListItemCommand,
    wrapInBlockquoteCommand,
    wrapInHeadingCommand,
    turnIntoTextCommand,
} from "@milkdown/preset-commonmark";
import { insertTableCommand } from "@milkdown/preset-gfm";
import { callCommand } from "@milkdown/utils";
import { useForceUpdate } from "../utils";
import { Event } from "../../common";

const hasMark = (state: EditorState | undefined, type: MarkType | undefined): boolean => {
    if (!type || !state) {
        return false;
    }

    const { from, $from, to, empty } = state.selection;
    if (empty) {
        return !!type.isInSet(state.storedMarks || $from.marks());
    }

    return state.doc.rangeHasMark(from, to, type);
};

const MenuToggleButton: FunctionComponent<{
    id: string | CmdKey<any>;
    icon: string | ReactNode;
    editor?: Editor;
    active?: boolean;
    disabled?: boolean;
}> = ({ id, icon, editor, active, disabled }) => {
    return (
        <ToolbarButton
            disabled={disabled || !editor}
            isToggled={active}
            onClick={e => {
                e.preventDefault();
                //ctx?.get(commandsCtx).call(id);
                editor?.action(callCommand(id));
            }}>
            <span className="material-symbols-outlined">{icon}</span>
        </ToolbarButton>
    );
};

export const MarkdownMenu: FunctionComponent<{ onChange: Event<EditorView> }> = ({ onChange }) => {
    const [, getInstance] = useInstance();

    const editor = getInstance();
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        const handler = () => forceUpdate();
        onChange.on(handler);
        return () => {
            onChange.off(handler);
        };
    }, [onChange, forceUpdate]);

    let disableBulletList = true;
    let disableOrderedList = true;
    let disableLiftListItem = true;
    let disableSinkListItem = true;
    let disableHeadings = true;
    let maxHeading = 0;
    let state: EditorState | undefined;

    if (editor) {
        state = editor.ctx.get(editorStateCtx);
        const nodeTypes = state.schema.nodes;
        const bulletListNode = nodeTypes?.bullet_list;
        disableBulletList = !bulletListNode || !wrapIn(bulletListNode)(state);
        const orderedListNode = nodeTypes?.ordered_list;
        disableOrderedList = !orderedListNode || !wrapIn(orderedListNode)(state);

        const listItemNode = nodeTypes?.list_item;
        disableLiftListItem = !listItemNode || !liftListItem(listItemNode)(state);
        disableSinkListItem = !listItemNode || !sinkListItem(listItemNode)(state);

        disableHeadings = false;
        const headingNode = nodeTypes?.heading;
        if (headingNode) {
            state.doc.nodesBetween(state.selection.from, state.selection.to, f => {
                if (f.type === headingNode) {
                    maxHeading = Math.max(f.attrs.level, maxHeading);
                }
            });
            const setToHeading = (level: number) => setBlockType(headingNode, { level })(state!);
            const heading1 = setToHeading(1);
            const heading2 = setToHeading(2);
            const heading3 = setToHeading(3);
            disableHeadings = !(state.selection instanceof TextSelection) || !(heading1 || heading2 || heading3);
        }
    }

    return (
        <Box
            bg="background"
            px={2}
            py={1}
            borderTopLeftRadius={3}
            borderTopRightRadius={3}
            fullWidth
            justifyContent="flex-start">
            <Select
                style={{ minWidth: "auto" }} // Couldn't get css prop or minWidth prop to work
                value={maxHeading.toString()}
                disabled={disableHeadings}
                onChange={e => {
                    const newHeading = Number(e.target.value);
                    const [key, payload] = newHeading
                        ? [wrapInHeadingCommand.key, newHeading]
                        : [turnIntoTextCommand.key, null];
                    editor?.ctx?.get(commandsCtx).call(key, payload);
                }}>
                <option key="1" value="1">
                    Heading 1
                </option>
                <option key="2" value="2">
                    Heading 2
                </option>
                <option key="3" value="3">
                    Heading 3
                </option>
                <option key="0" value="0">
                    Plain Text
                </option>
            </Select>
            <MenuToggleButton
                id={toggleStrongCommand.key}
                icon="format_bold"
                editor={editor}
                active={hasMark(state, state?.schema.marks.strong)}
                // disabled={state.schema.marks.strong}
            />
            <MenuToggleButton
                id={toggleEmphasisCommand.key}
                icon="format_italic"
                editor={editor}
                active={hasMark(state, state?.schema.marks.em)}
                // disabled={state.schema.marks.em}
            />
            <Spacer direction="vertical" mx={1} minHeight={20} />
            <MenuToggleButton
                id={wrapInBulletListCommand.key}
                icon="format_list_bulleted"
                editor={editor}
                disabled={disableBulletList}
            />
            <MenuToggleButton
                id={wrapInOrderedListCommand.key}
                icon="format_list_numbered"
                editor={editor}
                disabled={disableOrderedList}
            />
            <MenuToggleButton
                id={liftListItemCommand.key}
                icon="format_indent_decrease"
                editor={editor}
                disabled={disableLiftListItem}
            />
            <MenuToggleButton
                id={sinkListItemCommand.key}
                icon="format_indent_increase"
                editor={editor}
                disabled={disableSinkListItem}
            />
            <Spacer direction="vertical" mx={1} minHeight={20} />
            <MenuToggleButton id={wrapInBlockquoteCommand.key} icon="format_quote" editor={editor} />
            {/* <MenuToggleButton id="ToggleLink" icon="link" ctx={ctx} /> */}
            <MenuToggleButton id={insertTableCommand.key} icon="table" editor={editor} />
        </Box>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Annotation, AnnotationType, LineAnnotationType } from "../annotations";
import styled from "@emotion/styled";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { theme } from "../design";
import { ThreeBounce } from "better-react-spinkit";
import { Box, Text } from "./primitives";
import { Button } from "./Button";
import { LocalPixelPosition, LocalRect, Size } from "../position";
import { Texture } from "three";

import { Modifier, usePopper } from "react-popper";
import { VirtualElement } from "@popperjs/core";
import maxSize from "popper-max-size-modifier";
import { localPoint } from "../grid";
import { useSession } from "./contexts";
import { useForceUpdate, useIsMounted } from "./utils";
import {
    AnnotationPlacementTemplate,
    PlayerSection,
    SidebarPanelOptions,
    SidebarPanelState,
    getGameTime,
    msPerDay,
    msPerHour,
    msPerMinute,
    msPerSecond,
} from "../store";
import { cloudStorageProps, Event } from "../common";
import { SearchResult } from "../localsearchable";
import create from "zustand";

export type ExtractProps<T> = T extends React.FunctionComponent<infer X> ? X : never;

export type ToolType = "select" | "grid" | "zone" | "zonefill" | AnnotationType;
export type SubtoolType = LineAnnotationType;
export type VttMode = "build" | "play";
export type VttBuildMode = "base" | "zones" | "tokens";

export interface Viewport {
    levels: {
        [levelKey: string]: { texture?: Texture; size?: Size; error?: Error };
    };
    totalSize: LocalRect | undefined;
    position: LocalRect;
    moveTo: (pos: LocalPixelPosition) => void;
}

export function stopNonInputEvent(e: React.MouseEvent | React.PointerEvent) {
    e.stopPropagation();
    e.nativeEvent.stopPropagation();

    const target = e.target as HTMLElement;
    const tagName = target.tagName?.toLowerCase();
    if (tagName !== "input" && tagName !== "textarea" && tagName !== "select") {
        e.preventDefault();
        e.nativeEvent.preventDefault();
    }
}

export const LobotomizedBox = styled(Box)`
    > * + * {
        margin-top: ${props => (props.flexDirection === "column" ? props.theme.space[2] : 0)}px;
        margin-left: ${props =>
            props.flexDirection == null || props.flexDirection === "row" ? props.theme.space[2] : 0}px;
    }
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    cursor: default;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
`;

const isToggled = props =>
    props.toggled &&
    css`
        background-color: ${props.theme.colors.accent[7]};
        color: ${props.theme.colors.accent[2]};
        text-shadow: none;

        &:hover {
            background-color: ${props.theme.colors.accent[6]};
            color: ${props.theme.colors.accent[1]};
        }
    `;

export const SidebarButton = styled(Button)`
    background-color: transparent;
    color: ${props => props.theme.colors.grayscale[4]};
    padding-left: ${props => props.theme.space[2]}px;
    padding-right: ${props => props.theme.space[2]}px;
    text-shadow: inherit;

    &:hover {
        background-color: ${props => props.theme.colors.accent[6]};
        color: ${props => props.theme.colors.accent[1]};
        text-shadow: none;
    }

    ${isToggled}
`;

export const CustomSvg: FunctionComponent<{ svg: string } & ExtractProps<typeof Box>> = ({ svg, ...props }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current!.firstChild) {
            ref.current!.removeChild(ref.current!.firstChild);
        }

        const node = document.createRange().createContextualFragment(svg);
        if (node.childElementCount !== 1) {
            console.warn("Provided SVG does not contain exactly one child element:\n" + svg);
        } else if (node.firstElementChild?.nodeName !== "svg") {
            console.warn("Provided SVG does not contain an svg tag:\n" + svg);
        } else {
            ref.current!.append(node.firstElementChild);
        }
    }, [svg]);

    return <Box {...props} ref={ref} />;
};

export const SmallText = styled(Text)`
    font-size: ${props => props.theme.fontSizes[0]}px;
    color: ${props => props.theme.colors.grayscale[2]};
    white-space: pre;
`;

export const Loading: FunctionComponent<{ size?: "s" | "l" }> = ({ size }) => {
    return <ThreeBounce color={theme.colors.grayscale[3]} size={size === "l" ? theme.space[4] : undefined} />;
};

export const defaultLineWidth = 2;

export const tokenImageSize = 72;

// The line width to use for the touchable area of a line. This is added to the width of the line itself to
// add a second (invisible) line that is wider allowing it to be more easily interacted with.
export const interactiveLineWidth = theme.space[2];

const applyMaxSize: Partial<Modifier<string, object>> = {
    name: "applyMaxSize",
    enabled: true,
    phase: "beforeWrite",
    requires: ["maxSize"],
    fn({ state }) {
        // The `maxSize` modifier provides this data
        const { height } = state.modifiersData.maxSize;

        const finalHeight = Math.max(height - theme.space[3], 600);
        state.rects.popper = {
            ...state.rects.popper,
            height: finalHeight,
        };
        state.styles.popper = {
            ...state.styles.popper,
            height: `${finalHeight}px`,
        };
    },
};

export function useMaxSizePopper(
    referenceElement?: Element | VirtualElement | null | undefined,
    popperElement?: HTMLElement | null | undefined
) {
    return usePopper(referenceElement, popperElement, {
        modifiers: [maxSize, applyMaxSize, { name: "flip", phase: "beforeWrite", requires: ["applyMaxSize"] }],
        placement: "bottom-start",
    });
}

export function getZonePolygon(
    backgroundPosX: number,
    backgroundPosY: number,
    backgroundWidth: number,
    backgroundHeight: number
) {
    return [
        localPoint(backgroundPosX, backgroundPosY),
        localPoint(backgroundPosX + backgroundWidth, backgroundPosY),
        localPoint(backgroundPosX + backgroundWidth, backgroundPosY + backgroundHeight),
        localPoint(backgroundPosX, backgroundPosY + backgroundHeight),
    ];
}

export function resolveTime(ms: number) {
    const days = Math.floor(ms / msPerDay);
    const timeOfDay = ms % msPerDay;
    const hour = Math.floor(timeOfDay / msPerHour);
    const timeOfHour = timeOfDay % msPerHour;
    const minute = Math.floor(timeOfHour / msPerMinute);
    const timeOfMinute = timeOfHour % msPerMinute;
    const second = Math.floor(timeOfMinute / msPerSecond);
    return { days: days, hours: hour, minutes: minute, seconds: second };
}

export function useGameTime(onTick?: () => void) {
    const { session } = useSession();

    // Force a render on a regular basis to update the time.
    const forceUpdate = useForceUpdate();
    const isMounted = useIsMounted();
    const timeoutRef = useRef<number>();
    const speed = session.time.speed;
    useEffect(() => {
        if (timeoutRef.current != null) {
            clearTimeout(timeoutRef.current);
        }

        if (speed > 0) {
            const updateTime = () => {
                if (isMounted()) {
                    forceUpdate();
                    onTick?.();
                    timeoutRef.current = setTimeout(updateTime, msPerSecond / speed) as unknown as number;
                }
            };

            timeoutRef.current = setTimeout(updateTime, msPerSecond / speed) as unknown as number;
        }
    }, [speed, onTick, forceUpdate, isMounted]);

    return getGameTime(session.time);
}

const cldScheme = "cld://";

export function resolveUri(uri: string): string;
export function resolveUri(uri: string | undefined): string | undefined;
export function resolveUri(uri: string | undefined) {
    const baseUri = cloudStorageProps.baseUri;
    if (uri != null && baseUri != null && uri.startsWith(cldScheme)) {
        let path = uri.substring(cldScheme.length);

        if (baseUri.endsWith("/")) {
            return baseUri + (path.startsWith("/") ? path.substring(1) : path);
        } else {
            return baseUri + (path.startsWith("/") ? path : "/" + path);
        }
    }

    return uri;
}

export interface SearchCategoryResults<T = any> {
    categoryId: string;
    categoryLabel: string;
    results: SearchResult<T, () => JSX.Element>[];
    jumpTo?: () => JSX.Element;
}

interface VttAppState {
    isSearchExpanded: boolean;
    setIsSearchExpanded: (isExpanded: boolean) => void;

    searchTerm: string;
    searchResults: SearchCategoryResults[];
    hasSearchResults: boolean;
    setSearchTerm(searchTerm: string);
    setSearchResults(searchResults: SearchCategoryResults[]);

    mode: VttMode;
    buildMode: VttBuildMode;
    setMode: (mode: VttMode) => void;
    setBuildMode: (buildMode: VttBuildMode) => void;

    tool: ToolType;
    subtool?: SubtoolType;
    setTool: (tool: ToolType, subtool?: SubtoolType) => void;

    panels: SidebarPanelState[];
    overlay?: SidebarPanelState;
    addPanel(panel: SidebarPanelState & SidebarPanelOptions);
    addOverlay(panel: SidebarPanelState);
    closePanel(id: string);
    clearPanels();

    propertiesPage: "log" | "properties" | PlayerSection; // TODO: Rename to PropertiesPage
    setPropertiesPage: (section: "log" | "properties" | PlayerSection) => void;
    isPropertiesExpanded: boolean;
    setIsPropertiesExpanded: (isExpanded: boolean) => void;

    isCameraChanging: boolean;
    setIsCameraChanging: (isCameraChanging: boolean) => void;
    cameraMode: "orthographic" | "perspective";
    setCameraMode: (mode: "orthographic" | "perspective") => void;

    annotationPlacementTemplateChanged: Event<AnnotationPlacementTemplate<Annotation> | undefined>;
}

export const useVttApp = create<VttAppState>(set => ({
    isSearchExpanded: false,
    setIsSearchExpanded: isExpanded => {
        set(() => ({ isSearchExpanded: isExpanded }));
    },

    searchTerm: "",
    searchResults: [],
    hasSearchResults: false,
    setSearchTerm: searchTerm => set(() => ({ searchTerm: searchTerm })),
    setSearchResults: searchResults =>
        set(() => ({ searchResults: searchResults, hasSearchResults: searchResults.some(o => o.results.length > 0) })),

    mode: "play",
    buildMode: "tokens",
    setMode: mode => set(() => ({ mode: mode })),
    setBuildMode: buildMode => set(() => ({ buildMode })),

    tool: "select",
    setTool: (tool, subtool) => {
        set(() => ({
            tool: tool,
            subtool: subtool,
        }));
    },

    panels: [],
    addPanel: panel =>
        set(state => {
            const newPanels = state.panels.filter(o => (panel.type && o.type !== panel.type) || o.isPinned);
            const i = newPanels.findIndex(o => o.id === panel.id);
            if (i >= 0) {
                newPanels[i] = { ...newPanels[i], ...panel };
            } else {
                if (panel.addAfter) {
                    const j = newPanels.findIndex(o => o.id === panel.addAfter);
                    if (j >= 0) {
                        newPanels.splice(j + 1, 0, panel);
                    } else {
                        newPanels.unshift(panel);
                    }
                } else {
                    newPanels.unshift(panel);
                }
            }

            return {
                panels: newPanels,
            };
        }),
    addOverlay: panel =>
        set(() => {
            return {
                overlay: panel,
            };
        }),
    closePanel: id =>
        set(state => {
            const newPanels = state.panels.filter(o => o.id !== id);
            return {
                panels: newPanels,
                overlay: state.overlay?.id === id ? undefined : state.overlay,
            };
        }),
    clearPanels: () =>
        set(state => {
            const newPanels = state.panels.filter(o => o.isPinned);
            return {
                panels: newPanels,
            };
        }),

    propertiesPage: "log",
    isPropertiesExpanded: false,
    setPropertiesPage: page => set(() => ({ propertiesPage: page })),
    setIsPropertiesExpanded: isExpanded => set(() => ({ isPropertiesExpanded: isExpanded })),

    isCameraChanging: false,
    setIsCameraChanging: isCameraChanging => set(() => ({ isCameraChanging: isCameraChanging })),
    cameraMode: "orthographic",
    setCameraMode: mode => set(() => ({ cameraMode: mode })),

    annotationPlacementTemplateChanged: new Event<AnnotationPlacementTemplate<Annotation> | undefined>(),
}));

export const dragStatus: {
    isDragging: boolean;
} = {
    isDragging: false,
};

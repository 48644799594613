/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, ReactNode, Ref, useEffect, useRef, useState } from "react";
import { Box, Text, Input } from "../../../components/primitives";
import { Button } from "../../../components/Button";
import { Spell } from "../spells";
import { FeatureExpander } from "./FeatureExpander";
import { escapeRegExp } from "../../../common";
import {
    getRuleKey,
    ConeAreaOfEffect,
    CubeAreaOfEffect,
    CylinderAreaOfEffect,
    SphereAreaOfEffect,
    LineAreaOfEffect,
    AbilityUse,
    AbilityReset,
} from "../common";
import {
    AnimatedListItem,
    MotionLobotomizedBox,
    defaultAnimate,
    defaultInitial,
    defaultExit,
    AnimatedList,
    MotionBox,
} from "../../../components/motion";
import styled from "@emotion/styled";
import { Markdown } from "../../../components/markdown";
import { TooltipTag } from "./common";
import { IMenuItem } from "../../../store";
import { theme } from "../../../design";
import { useMenuState } from "@szhsin/react-menu";
import { ControlledMenu, renderContextMenuItem } from "../../../components/menus";
import { ExtractProps } from "../../../components/common";
import { ScrollableTest } from "../../../components/ScrollableTest";
import { MarkdownActions } from "../../../components/MarkdownActions";
import { AnimatePresence } from "framer-motion";

const SpellComponentDetails = styled(Text)`
    > div {
        display: inline;
    }
`;

export const SpellDetails: FunctionComponent<{ spell: Spell; dc?: number; nonInteractive?: boolean }> = ({
    spell,
    dc,
    nonInteractive,
}) => {
    const components: string[] = [];
    if (spell.components.v) {
        components.push("V");
    }

    if (spell.components.s) {
        components.push("S");
    }

    if (spell.components.m) {
        components.push("M");
    }

    const range =
        typeof spell.range === "number" ? `${spell.range}ft` : spell.range[0].toUpperCase() + spell.range.substr(1);
    let area: string | undefined;
    if (spell.aoe) {
        switch (spell.aoe.type) {
            case "cone":
                area = `/${(spell.aoe as ConeAreaOfEffect).length}ft cone`;
                break;
            case "cube":
                area = `/${(spell.aoe as CubeAreaOfEffect).length}ft cube`;
                break;
            case "cylinder":
                const cylinder = spell.aoe as CylinderAreaOfEffect;
                area = `/${cylinder.height != null ? `${cylinder.height}ft high ` : ""}${
                    cylinder.radius
                }ft radius cylinder`;
                break;
            case "line":
                const line = spell.aoe as LineAreaOfEffect;
                area = `/${line.width}ft wide ${line.length}ft long line`;
                break;
            case "sphere":
                area = `/${(spell.aoe as SphereAreaOfEffect).radius}ft sphere`;
                break;
        }
    }

    return (
        <MotionBox layout flexDirection="column" alignItems="flex-start">
            {!nonInteractive && <MarkdownActions mb={2} markdown={`:spell{id="${getRuleKey(spell)}"}`} />}
            <Box>
                <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                    Casting Time:{" "}
                </Text>
                <Text>{`${spell.time.amount} ${spell.time.unit}${spell.time.amount > 1 ? "s" : ""}`}</Text>
            </Box>
            <Box>
                <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                    Range/Area:{" "}
                </Text>
                <Text>
                    {range}
                    {area}
                </Text>
            </Box>
            <Box>
                <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                    Components:{" "}
                </Text>
                <Text css={{ whiteSpace: "pre" }}>{components.join(", ")} </Text>
                {spell.components.m?.content && (
                    <SpellComponentDetails color="grayscale.4" fontSize={0} lineHeight={0}>
                        <Markdown>{`(${spell.components.m.content})`}</Markdown>
                    </SpellComponentDetails>
                )}
            </Box>
            <Box>
                <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                    Duration:{" "}
                </Text>
                <Text>
                    {spell.duration?.isConcentration && `Concentration • `}
                    {spell.duration
                        ? `${spell.duration.amount ?? 1} ${spell.duration.unit}${
                              (spell.duration.amount ?? 1) > 1 ? "s" : ""
                          }`
                        : "Instantaneous"}
                </Text>
            </Box>
            {spell.savingThrow && (
                <Box>
                    <Text fontWeight="bold" css={{ whiteSpace: "pre" }}>
                        Save:{" "}
                    </Text>
                    <Text>
                        {spell.savingThrow.map(o => `${o[0].toUpperCase() + o.substr(1)}${dc ? ` ${dc}` : ""}`)}
                    </Text>
                </Box>
            )}

            <Box mt={2} flexDirection="column" alignItems="flex-start">
                <Markdown>{spell.content}</Markdown>
                {spell.contentHigherLevel && <Markdown>{spell.contentHigherLevel}</Markdown>}
            </Box>
        </MotionBox>
    );
};

interface SpellInfoProps extends Partial<AbilityUse> {
    spell: Spell;
    isInOverlay?: boolean;
    subtitle?: string;
    dc?: number;
    menu?: IMenuItem[];
    actions?: IMenuItem[];
    bg?: string;
    headerBg?: string;
    used?: number;
    cardHeader?: boolean;
    innerPaddingH?: number;
    innerPaddingV?: number;
    cursor?: string;
    layout?: boolean;
    afterDetails?: React.ReactNode;
}

const SpellMenuButton: FunctionComponent<{ spell: Spell; menu: IMenuItem }> = ({ spell, menu }) => {
    const ref = useRef(null);
    const [menuProps, toggleMenu] = useMenuState({ transition: true });

    const [isHover, setIsHover] = useState(0);

    const isSubitemsEnabled = menu.subitems && menu.subitems.some(o => !o.disabled);

    return (
        <React.Fragment>
            {menu.onClick && menu.subitems && (
                <React.Fragment>
                    <Button
                        ref={ref}
                        size="s"
                        disabled={menu.disabled}
                        toggled={menu.toggled}
                        css={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            paddingRight: theme.space[1],
                            backgroundColor:
                                isHover > 0 ? theme.colors.grayscale[isHover === 2 ? 7 : 6] + " !important" : undefined,
                        }}
                        onClick={e => {
                            menu.onClick!(spell);
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onMouseEnter={() => setIsHover(1)}
                        onMouseLeave={() => setIsHover(0)}>
                        {menu.label}
                    </Button>
                    <Button
                        size="s"
                        disabled={!isSubitemsEnabled}
                        css={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            paddingLeft: theme.space[1],
                            backgroundColor:
                                isHover > 0 ? theme.colors.grayscale[isHover === 1 ? 7 : 6] + " !important" : undefined,
                        }}
                        mr={2}
                        onClick={e => {
                            toggleMenu();
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onMouseEnter={() => setIsHover(2)}
                        onMouseLeave={() => setIsHover(0)}>
                        ≡
                    </Button>
                </React.Fragment>
            )}
            {!menu.onClick && menu.subitems && (
                <Button
                    size="s"
                    disabled={!isSubitemsEnabled}
                    mr={2}
                    ref={ref}
                    onClick={e => {
                        toggleMenu();
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                    {menu.label}
                </Button>
            )}
            {menu.onClick && !menu.subitems && (
                <Button
                    size="s"
                    disabled={menu.disabled}
                    toggled={menu.toggled}
                    mr={2}
                    onClick={e => {
                        menu.onClick!(spell);
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                    {menu.label}
                </Button>
            )}

            {menu.subitems && isSubitemsEnabled && (
                <ControlledMenu
                    {...menuProps}
                    anchorRef={ref}
                    onItemClick={e => {
                        e.syntheticEvent.stopPropagation();
                        e.syntheticEvent.preventDefault();
                    }}
                    onClose={() => toggleMenu(false)}>
                    {menu.subitems.map(o => renderContextMenuItem(o))}
                </ControlledMenu>
            )}
        </React.Fragment>
    );
};

export function getSpellTags(spell: Spell, reset?: AbilityReset, maxUses?: number, used?: number) {
    const tags: ReactNode[] = [];
    if (spell.duration?.isConcentration) {
        tags.unshift(
            <TooltipTag key="C" mr={1} bg="reds.7" color="reds.0" tooltip="Concentration">
                C
            </TooltipTag>
        );
    }

    // A spell being ritual is only relevant if you can't already just cast it at will.
    if (spell.isRitual && reset !== "will") {
        tags.unshift(
            <TooltipTag key="R" mr={1} bg="oranges.7" color="oranges.0" tooltip="Ritual">
                R
            </TooltipTag>
        );
    }

    if (reset === "will" || spell.level === 0) {
        tags.unshift(
            <TooltipTag key="W" mr={1} bg="purples.7" color="purples.0" tooltip="Cast at will">
                W
            </TooltipTag>
        );
    } else if (maxUses != null) {
        const usesRemaining = maxUses - (used ?? 0);

        const resetText = reset === "shortrest" ? "resets after a short rest" : "resets after a long rest";
        tags.unshift(
            <TooltipTag
                key="U"
                mr={1}
                bg="yellows.7"
                color="yellows.0"
                tooltip={`${usesRemaining} of ${maxUses} uses remaining (${resetText})`}>{`${usesRemaining}/${maxUses}`}</TooltipTag>
        );
    }

    return tags;
}

export const SpellInfo: FunctionComponent<SpellInfoProps & ExtractProps<typeof Box>> = ({
    spell,
    isInOverlay,
    actions,
    dc,
    subtitle,
    bg,
    headerBg,
    maxUses,
    reset,
    used,
    innerPaddingH,
    innerPaddingV,
    cardHeader,
    cursor,
    layout,
    afterDetails,
    ...boxProps
}) => {
    const header: ReactNode = (
        <AnimatePresence>
            {getSpellTags(spell, reset, maxUses, used)}
            {actions?.map(o => (
                <SpellMenuButton spell={spell} key={o.id ?? o.label} menu={o} />
            ))}
        </AnimatePresence>
    );

    if (subtitle == null) {
        subtitle = `${spell.level === 0 ? "Cantrip" : `Level ${spell.level}`} • ${spell.school}`;
    }

    // Attack/save, source?, better components.
    return (
        <FeatureExpander
            title={spell.displayName ?? spell.name}
            subtitle={subtitle}
            header={header}
            isInOverlay={isInOverlay}
            innerPaddingH={innerPaddingH}
            innerPaddingV={innerPaddingV}
            cardHeader={cardHeader}
            cursor={cursor}
            layout={layout}
            bg={bg}
            {...boxProps}>
            <SpellDetails spell={spell} dc={dc} />
            {afterDetails}
        </FeatureExpander>
    );
};

const defaultLoadedCount = 30;
const spellLevels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const SpellList = React.forwardRef<
    HTMLDivElement,
    {
        spells: Spell[];
        highlightSpells?: Spell[];
        dc?: number;
        filterInputRef?: Ref<HTMLInputElement>;
        actions?: (spell: Spell) => IMenuItem[];
        afterDetails?: (spell: Spell) => React.ReactNode;
        placeholder?: string;
        scroll?: boolean;
        hideContent?: boolean;
        animateEnterExit?: boolean;
    }
>(
    (
        {
            spells,
            highlightSpells,
            dc,
            actions,
            afterDetails,
            placeholder,
            filterInputRef,
            scroll,
            hideContent,
            animateEnterExit,
        },
        ref
    ) => {
        const [levelFilter, setLevelFilter] = useState<number | undefined>(undefined);
        const [nameFilter, setNameFilter] = useState<string | undefined>(undefined);

        const [loadedCount, setLoadedCount] = useState(defaultLoadedCount);
        useEffect(() => {
            setLoadedCount(defaultLoadedCount);
        }, [levelFilter, nameFilter]);

        const maxLevel = spells.reduce<number>((prev, current) => Math.max(prev, current.level), 0);
        const levels = spellLevels.slice(0, maxLevel + 1);

        const regx = nameFilter ? new RegExp(escapeRegExp(nameFilter), "i") : undefined;
        const filter = (o: Spell) => {
            if (levelFilter != null && levelFilter !== o.level) {
                return false;
            }

            if (regx != null && o.name.search(regx) < 0) {
                return false;
            }

            return true;
        };

        var filteredSpells = spells.filter(filter);
        var finalSpells = filteredSpells.slice(0, loadedCount);
        const listContent = hideContent ? undefined : (
            <React.Fragment>
                <AnimatedList ref={ref}>
                    {finalSpells.length === 0 && (nameFilter || placeholder) && (
                        <AnimatedListItem justifyContent="flex-start">
                            <Text fontStyle="italic" color="grayscale.2">
                                {nameFilter
                                    ? levelFilter != null
                                        ? levelFilter === 0
                                            ? `No cantrips matching "${nameFilter}"`
                                            : `No level ${levelFilter} spells matching "${nameFilter}"`
                                        : `No spells matching "${nameFilter}"`
                                    : levelFilter != null
                                    ? levelFilter === 0
                                        ? `No cantrips`
                                        : `No level ${levelFilter} spells`
                                    : placeholder}
                            </Text>
                        </AnimatedListItem>
                    )}
                    {finalSpells.slice(0, loadedCount).map((spell, i) => (
                        <AnimatedListItem key={getRuleKey(spell)} index={i - loadedCount} fullWidth>
                            <SpellInfo
                                isInOverlay
                                bg={
                                    highlightSpells == null ||
                                    highlightSpells.find(o => o.name === spell.name && o.source === spell.source) ==
                                        null
                                        ? undefined
                                        : "transparency.2.grayscale.6"
                                }
                                spell={spell}
                                dc={dc}
                                afterDetails={afterDetails ? afterDetails(spell) : undefined}
                                actions={actions ? actions(spell) : undefined}
                            />
                        </AnimatedListItem>
                    ))}
                </AnimatedList>

                {finalSpells.length < filteredSpells.length && (
                    <MotionBox layout alignSelf="flex-start">
                        <Button
                            key="loadmore"
                            variant="tertiary"
                            onClick={() => setLoadedCount(loadedCount + defaultLoadedCount)}>
                            {filteredSpells.length - loadedCount} more…
                        </Button>
                    </MotionBox>
                )}
            </React.Fragment>
        );

        return (
            <MotionLobotomizedBox
                layout={!hideContent ? "position" : false}
                flexDirection="column"
                alignItems="stretch"
                fullWidth
                fullHeight={scroll}
                initial={animateEnterExit ? defaultInitial : undefined}
                animate={animateEnterExit ? defaultAnimate : undefined}
                exit={animateEnterExit ? defaultExit : undefined}>
                <AnimatePresence>
                    {spells.length > 0 && (
                        <MotionLobotomizedBox
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}
                            layout={!hideContent}
                            flexDirection="column"
                            alignItems="stretch"
                            px={scroll ? 3 : 0}
                            pt={scroll ? 3 : 0}>
                            <Input
                                ref={filterInputRef}
                                placeholder="Filter spells by name"
                                onChange={e => setNameFilter(e.target.value ? e.target.value : undefined)}
                            />
                            <Box flexDirection="row" justifyContent="flex-start">
                                {levels.map(o => (
                                    <Button
                                        key={o}
                                        mr={2}
                                        toggled={levelFilter === o}
                                        onClick={() => setLevelFilter(o === levelFilter ? undefined : o)}>
                                        {o}
                                    </Button>
                                ))}
                            </Box>
                        </MotionLobotomizedBox>
                    )}
                </AnimatePresence>

                {!hideContent && scroll && (
                    <ScrollableTest px={3} pb={3} pt={2} minimal scrollDirection="vertical">
                        {listContent}
                    </ScrollableTest>
                )}
                {!hideContent && !scroll && listContent}
            </MotionLobotomizedBox>
        );
    }
);

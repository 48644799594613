/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, useCallback, useEffect, useRef } from "react";
import { Grid, Box, Text, Card } from "./primitives";
import { Button } from "./Button";
import { INotificationProps, useNotifications } from "./Notifications";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { VTTStore } from "../store";
import ProfileAvatar from "./ProfileAvatar";
import { NotificationContext, useUser } from "./contexts";
import { KeyboardShortcuts, useErrorHandler } from "./utils";
import Modal from "react-modal";
import { Popover } from "./Popover";

const mapStateToProps = (state: VTTStore) => state.userInfo;

const UserAvatar = connect((state: VTTStore) => state.userInfo)((props: ReturnType<typeof mapStateToProps>) => {
    if (!props.profile) {
        return <NavLink to="/login">Login</NavLink>;
    }

    return <ProfileAvatar profile={props.profile} />;
});

const Layout: FunctionComponent<any> = ({ history, children }) => {
    const [addNotification, setNotificationPosition] = useNotifications();
    const { handleResponse } = useErrorHandler();
    const user = useUser();

    const mainRef = useRef<HTMLDivElement>();
    useEffect(() => {
        Modal.setAppElement(mainRef.current!);
        return () => Modal.setAppElement(document.getElementById("root")!);
    }, []);

    const logOut = useCallback(async () => {
        const signoutResponse = await fetch("auth/signout");
        if (handleResponse(signoutResponse, () => `Failed to log out:\n${signoutResponse.statusText}`)) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    }, [handleResponse]);

    const notifications = useCallback<(o: INotificationProps) => Promise<any>>(
        o => {
            setNotificationPosition("top");
            return addNotification(o);
        },
        [addNotification, setNotificationPosition]
    );

    return (
        <NotificationContext.Provider value={notifications}>
            <KeyboardShortcuts useWindow>
                <Grid
                    id="vtt_main"
                    ref={mainRef as unknown as any}
                    fullWidth
                    fullHeight
                    bg="background"
                    as="main"
                    position="absolute" // For some reason if we don't have this, it's slightly too small?!
                    gridTemplateRows="auto 1fr auto"
                    gridTemplateColumns="auto auto 1fr auto auto"
                    gridTemplateAreas="'leftdrawer leftinnertop    top    rightinnertop    rightdrawer'
                                   'leftdrawer leftinner       main   rightinner       rightdrawer'
                                   'leftdrawer leftinnerbottom bottom rightinnerbottom rightdrawer'">
                    {children}

                    {/* Top right toolbar */}
                    {user.profile && (
                        <Box
                            css={{
                                gridArea: "rightdrawer",
                                position: "absolute",
                                top: "0",
                                right: "0",
                            }}
                            zIndex={90}
                            mt={2}
                            mr={2}
                            justifyContent="flex-end"
                            height={48}
                            width={48}
                            alignSelf="flex-start">
                            <Popover trigger={<UserAvatar />}>
                                <Card
                                    p={3}
                                    width={200}
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    bg="background"
                                    borderRadius={4}>
                                    <Button
                                        fullWidth
                                        variant="tertiary"
                                        justifyContent="flex-start"
                                        px={2}
                                        onClick={logOut}>
                                        <Text>Log out</Text>
                                    </Button>
                                </Card>
                            </Popover>
                        </Box>
                    )}
                </Grid>
            </KeyboardShortcuts>
        </NotificationContext.Provider>
    );
};

export default Layout;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { PropsWithChildren, ReactElement, useId, useRef, useState } from "react";
import { ExtractProps } from "./common";
import { Box } from "./primitives";
import { AnimatePresence } from "framer-motion";
import { theme } from "../design";

interface ListBoxItemProps<T> {
    selected: boolean;
    nextSelected?: boolean;
    prevSelected?: boolean;
    active: boolean;
    focused: boolean;
    item: T;
    key: string;
    index: number;
}

interface ListBoxProps<T> {
    items: T[];
    selectedItems?: T[];
    itemKey: (item: T) => string;
    children: (props: ListBoxItemProps<T>) => ReactElement;
    onSelectionChanged: (selectedItems: T[]) => void;
    selectActive?: boolean;
    multiSelect?: boolean;
}

export const ListItem = React.forwardRef<
    HTMLDivElement,
    PropsWithChildren<
        {
            selected?: boolean;
            active?: boolean;
            focused?: boolean;
            isContextMenuTarget?: boolean;
            onContextMenu?: React.MouseEventHandler<HTMLDivElement>;
        } & ExtractProps<typeof Box>
    >
>(({ selected, active, focused, isContextMenuTarget, children, ...props }, ref) => {
    const showFocused = focused || (document.hasFocus() && isContextMenuTarget);
    const bg = selected
        ? showFocused
            ? "grayscale.6"
            : "grayscale.8"
        : isContextMenuTarget
        ? "grayscale.8"
        : undefined;
    return (
        <Box
            ref={ref}
            alignItems="flex-start"
            justifyContent="flex-start"
            fullWidth
            borderRadius={3}
            px={3}
            my={-2}
            py={2}
            mb={1}
            {...props}
            bg={bg}
            css={{
                outline: bg == null && active ? `2px solid ${theme.colors.grayscale[6]}` : undefined,
                textShadow: bg != null ? "none" : "inherit",
                ":hover": {
                    background: selected && showFocused ? theme.colors.grayscale[6] : theme.colors.grayscale[7],
                    textShadow: "none",
                    zIndex: 1,
                },
            }}>
            {children}
        </Box>
    );
});

export const ListBox = <T extends unknown>({
    children,
    items,
    selectedItems,
    itemKey,
    onSelectionChanged,
    selectActive,
    multiSelect,
    onKeyDown,
    onFocus,
    onBlur,
    ...props
}: ListBoxProps<T> & Omit<ExtractProps<typeof Box>, "children">): JSX.Element => {
    const listId = useId();
    const [activeItem, setActiveItem] = useState<string>();
    const [hasFocus, setHasFocus] = useState(false);
    const ignoreReceivingFocus = useRef<boolean>(false);

    const moveActiveItem = (by: number) => {
        const ids = items.map(o => listId + "_" + btoa(itemKey(o)));
        let index = activeItem ? ids.indexOf(activeItem) + by : 0;
        if (index >= 0 && ids.length > index) {
            document.getElementById(ids[index])?.scrollIntoView({ block: "nearest" });
            setActiveItem(ids[index]);

            if (selectActive) {
                onSelectionChanged([items[index]]);
            }

            return true;
        }

        return false;
    };

    const ref = useRef<HTMLDivElement>(null);
    return (
        <Box
            role="listbox"
            aria-activedescendant={activeItem}
            position="relative"
            tabIndex={0}
            ref={ref}
            css={{ outline: 0 }}
            onFocus={e => {
                onFocus?.(e);
                if (ignoreReceivingFocus.current) {
                    ignoreReceivingFocus.current = false;
                } else {
                    const actualActiveItem =
                        activeItem == null
                            ? undefined
                            : items.find(o => listId + "_" + btoa(itemKey(o)) === activeItem);
                    let itemToScrollTo = actualActiveItem ? activeItem : undefined;
                    if (!itemToScrollTo && items.length) {
                        itemToScrollTo = listId + "_" + btoa(itemKey(items[0]));
                        setActiveItem(itemToScrollTo);

                        if (selectActive) {
                            onSelectionChanged([items[0]]);
                        }
                    }

                    if (itemToScrollTo != null) {
                        document.getElementById(itemToScrollTo)?.scrollIntoView({ block: "nearest" });
                    }
                }

                setHasFocus(true);
            }}
            onBlur={e => {
                onBlur?.(e);
                setHasFocus(false);
            }}
            onKeyDown={e => {
                onKeyDown?.(e);

                switch (e.key) {
                    case " ":
                        // Make the active item selected.
                        const item = items.find(o => listId + "_" + btoa(itemKey(o)) === activeItem);
                        onSelectionChanged(
                            item && !selectedItems?.some(o => itemKey(o) === itemKey(item)) ? [item] : []
                        );
                        e.preventDefault();
                        break;
                    case "ArrowDown":
                    case "ArrowRight":
                        if (moveActiveItem(1)) {
                            e.preventDefault();
                        }

                        break;
                    case "ArrowUp":
                    case "ArrowLeft":
                        if (moveActiveItem(-1)) {
                            e.preventDefault();
                        }

                        break;
                    case "Home":
                        if (items.length) {
                            let homeId = listId + "_" + btoa(itemKey(items[0]));
                            document.getElementById(homeId)?.scrollIntoView({ block: "nearest" });
                            setActiveItem(homeId);

                            if (selectActive) {
                                onSelectionChanged([items[0]]);
                            }

                            e.preventDefault();
                        }

                        break;
                    case "End":
                        if (items.length) {
                            let homeId = listId + "_" + btoa(itemKey(items[items.length - 1]));
                            document.getElementById(homeId)?.scrollIntoView({ block: "nearest" });
                            setActiveItem(homeId);

                            if (selectActive) {
                                onSelectionChanged([items[items.length - 1]]);
                            }

                            e.preventDefault();
                        }

                        break;
                }
            }}
            {...props}>
            <AnimatePresence mode="popLayout">
                {items.map((item, i) => {
                    const key = itemKey(item);
                    const itemId = listId + "_" + btoa(key);
                    const selected = !!selectedItems?.find(o => key === itemKey(o));

                    const nextItem = items[i + 1];
                    const prevItem = i > 0 ? items[i - 1] : undefined;
                    const nextItemKey = nextItem ? itemKey(nextItem) : undefined;
                    const prevItemKey = prevItem ? itemKey(prevItem) : undefined;
                    const nextSelected =
                        nextItemKey != null ? !!selectedItems?.find(o => nextItemKey === itemKey(o)) : undefined;
                    const prevSelected =
                        prevItemKey != null ? !!selectedItems?.find(o => prevItemKey === itemKey(o)) : undefined;

                    return (
                        <Box
                            key={key}
                            id={itemId}
                            role="option"
                            aria-selected={selected}
                            css={{ cursor: "pointer" }}
                            onPointerDown={e => {
                                // Prevent this from actually focusing, as that triggers the initial focus stuff
                                // that selects the first item. If we don't do this then our first click on the control
                                // just moves the selection to the first item.
                                ignoreReceivingFocus.current = true;
                            }}
                            onContextMenu={e => {
                                setActiveItem(listId + "_" + btoa(itemKey(item)));

                                if (selectActive && !selected) {
                                    onSelectionChanged([item]);
                                }
                            }}
                            onClick={e => {
                                if (!e.isDefaultPrevented()) {
                                    const clickedItemKey = itemKey(item);

                                    if (multiSelect && e.shiftKey && activeItem) {
                                        const startIndex = items.findIndex(
                                            o => listId + "_" + btoa(itemKey(o)) === activeItem
                                        );
                                        const endIndex = items.findIndex(o => itemKey(o) === clickedItemKey);

                                        if (endIndex >= startIndex) {
                                            onSelectionChanged(items.slice(startIndex, endIndex + 1));
                                        } else {
                                            onSelectionChanged(items.slice(endIndex, startIndex + 1).reverse());
                                        }
                                    } else if (multiSelect && e.ctrlKey && selectedItems) {
                                        setActiveItem(listId + "_" + btoa(clickedItemKey));

                                        const selectedKeys = selectedItems.map(o => itemKey(o));
                                        const isSelected = selectedKeys.indexOf(clickedItemKey) >= 0;
                                        if (isSelected) {
                                            onSelectionChanged(
                                                selectedItems.filter(o => itemKey(o) !== clickedItemKey)
                                            );
                                        } else {
                                            onSelectionChanged([item, ...selectedItems]);
                                        }
                                    } else {
                                        setActiveItem(listId + "_" + btoa(clickedItemKey));

                                        onSelectionChanged([item]);
                                    }

                                    ref.current?.focus();
                                }
                            }}>
                            {children({
                                item: item,
                                key: key,
                                selected: selected,
                                active: itemId === activeItem,
                                focused: hasFocus,
                                index: i,
                                nextSelected: nextSelected,
                                prevSelected: prevSelected,
                            })}
                        </Box>
                    );
                })}
            </AnimatePresence>
        </Box>
    );
};

import React from 'react'
  
  const SelectToolIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 11.5,3c0-0.828-0.672-1.5-1.5-1.5l0,0C9.172,1.5,8.5,2.172,8.5,3v11.25L6.061,12.3 c-0.664-0.531-1.632-0.423-2.163,0.24c-0.028,0.035-0.054,0.071-0.079,0.108l0,0c-0.345,0.517-0.345,1.19,0,1.707l3.65,5.475 c1.113,1.668,2.986,2.67,4.992,2.67h2.918c1.358,0,2.661-0.539,3.621-1.5l0,0c0.96-0.96,1.5-2.263,1.5-3.621V10.5 C20.5,9.672,19.828,9,19,9l0,0c-0.828,0-1.5,0.672-1.5,1.5V9.75c0-0.828-0.672-1.5-1.5-1.5l0,0c-0.828,0-1.5,0.672-1.5,1.5 c0-0.828-0.672-1.5-1.5-1.5l0,0c-0.828,0-1.5,0.672-1.5,1.5V3z ' />
    </svg>
  )
  
  SelectToolIcon.displayName = 'SelectToolIcon'
  
  SelectToolIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default SelectToolIcon
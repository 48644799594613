/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, {
    PropsWithChildren,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { ContainerURL, AnonymousCredential, StorageURL, Aborter } from "@azure/storage-blob";
import { Event, Deferred } from "./common";
import { useErrorHandler, useEvent } from "./components/utils";
import {
    BlobItem,
    BlobMetadata,
    ContainerGetPropertiesResponse,
} from "@azure/storage-blob/typings/src/generated/src/models";
import {
    AudioType,
    defaultSoundRadius,
    ErrorHandler,
    getToken,
    ImageType,
    isLocation,
    isTokenTemplate,
    ModelType,
    Zone,
} from "./store";
import { IDBPDatabase, openDB } from "idb";
import { LocalSearchable } from "./localsearchable";
import { FunctionComponent } from "react";
import { Box, Heading, Image, Text } from "./components/primitives";
import { Howl } from "howler";
import { DraggableBox } from "./components/draggable";
import { Button } from "./components/Button";
import { Message } from "./components/Message";
import { PercentageBar } from "./components/PercentageBar";
import { Tag } from "./components/Tag";
import { AnimatedListItem, MotionBox } from "./components/motion";
import { AnimatePresence } from "framer-motion";
import { PauseIcon, PlayIcon, StopIcon } from "@radix-ui/react-icons";
import { useAppState, useDispatch, useLocation, useSelection } from "./components/contexts";
import { MenuItem, useMenuState } from "@szhsin/react-menu";
import { ControlledMenu } from "./components/menus";
import { addTrack, modifyZone } from "./actions/location";
import { nanoid } from "nanoid";
import { modifyToken, setPortraitImage, setTokenImage } from "./actions/token";
import { LobotomizedBox, resolveUri, tokenImageSize } from "./components/common";
import { getSelectedZones } from "./components/selection";
import { ListItem } from "./components/ListBox";
import { ModalDialog } from "./components/modal";
import { theme } from "./design";
import FocusTrap from "focus-trap-react";

const DEFAULT_MAX_CAPACITY = 1048576 * 300;

let _container: { baseUri: string; uri: ContainerURL; version: number };
let _publicContainers: ContainerURL[];

export function isAudio(libraryItem: LibraryItem) {
    return libraryItem.metadata.type === AudioType.Ambient || libraryItem.metadata.type === AudioType.Music;
}

export function isModel(libraryItem: LibraryItem) {
    return libraryItem.metadata.type === ModelType.Token;
}

export function isImage(libraryItem: LibraryItem) {
    const type = libraryItem.metadata.type;
    return (
        type === ImageType.Background ||
        type === ImageType.Object ||
        type === ImageType.Portrait ||
        type === ImageType.Token
    );
}

interface UploadFilesResponse {
    success: (LibraryItem | { id: string; alreadyExists: boolean })[];
    fail: { file: string; reason: string }[];
    used: number;
}

interface DeleteFileResponse {
    used: number;
}

const LibraryItemContext = React.createContext(
    undefined as any as {
        showContextMenu: (item: LibraryItem, anchorRef: RefObject<Element>) => void;
        current?: LibraryItem;
    }
);

export const LibraryItemHost: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [item, setItem] = useState<LibraryItem>();
    const [anchorRef, setAnchorRef] = useState<RefObject<Element>>();
    const errorHandler = useErrorHandler();

    const { system, campaign, location } = useLocation();
    const dispatch = useDispatch();

    const [menuProps, toggleMenu] = useMenuState({ transition: true });

    const showContextMenu = useCallback(
        (item: LibraryItem, anchorRef: RefObject<Element>) => {
            setItem(item);
            setAnchorRef(anchorRef);
            toggleMenu(true);
        },
        [setItem, setAnchorRef, toggleMenu]
    );

    const contextValue = useMemo(
        () => ({
            showContextMenu: showContextMenu,
            current: item,
        }),
        [showContextMenu, item]
    );

    const { focusedToken } = useAppState();
    const token = getToken(campaign, location?.id, focusedToken);

    const selection = useSelection();
    let zones: Zone[] | undefined;
    if (isLocation(location)) {
        zones = getSelectedZones(selection.primary, location);
        if (!zones.length) {
            zones = getSelectedZones(selection.secondary, location);
        }
    }

    let zone = zones?.[0];

    const [pendingDelete, setPendingDelete] = useState<LibraryItem>();
    const lastPendingDelete = useRef<LibraryItem>();
    if (pendingDelete != null) {
        lastPendingDelete.current = pendingDelete;
    }

    return (
        <React.Fragment>
            <LibraryItemContext.Provider value={contextValue}>{children}</LibraryItemContext.Provider>
            <ControlledMenu
                direction="bottom"
                align="center"
                {...menuProps}
                onClose={() => {
                    toggleMenu(false);
                    setItem(undefined);
                }}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onItemClick={e => {
                    e.syntheticEvent.stopPropagation();
                    e.syntheticEvent.preventDefault();
                }}
                anchorRef={anchorRef}>
                {item && (
                    <React.Fragment>
                        {isAudio(item) && isLocation(location) && (
                            <React.Fragment>
                                <MenuItem
                                    onClick={() => {
                                        dispatch(
                                            addTrack(campaign.id, location.id, item.metadata.type as AudioType, {
                                                uri: item.uri,
                                                name: item.name,
                                            })
                                        );
                                    }}>
                                    Add to location
                                </MenuItem>
                            </React.Fragment>
                        )}
                        {item.metadata.type === AudioType.Ambient && token && (
                            <MenuItem
                                onClick={() => {
                                    dispatch(
                                        modifyToken(campaign, location, token!, {
                                            sound: { uri: item.uri, name: item.name, radius: defaultSoundRadius },
                                        })
                                    );
                                }}>
                                Apply to {token ? system.getDisplayName(token, campaign) : "token"}
                            </MenuItem>
                        )}
                        {item.metadata.type === AudioType.Ambient && zone && isLocation(location) && (
                            <MenuItem
                                onClick={() => {
                                    dispatch(
                                        modifyZone(campaign, location, zone!, {
                                            sound: { uri: item.uri, name: item.name, radius: defaultSoundRadius },
                                        })
                                    );
                                }}>
                                Apply to {zone?.label ?? zone?.code ?? "zone"}
                            </MenuItem>
                        )}
                        {isImage(item) && (
                            <MenuItem
                                onClick={() => {
                                    navigator.clipboard.writeText(`![${item.name}](${item.uri})`);
                                }}>
                                Copy markdown
                            </MenuItem>
                        )}
                        {item.metadata.type === ImageType.Token && token && (
                            <MenuItem
                                onClick={() => {
                                    dispatch(
                                        setTokenImage(
                                            campaign.id,
                                            isTokenTemplate(token) ? undefined : location?.id,
                                            [token!],
                                            item
                                        )
                                    );
                                }}>
                                Add to {token ? system.getDisplayName(token, campaign) : "token"}
                            </MenuItem>
                        )}
                        {item.metadata.type === ImageType.Portrait && token && (
                            <MenuItem
                                onClick={() => {
                                    dispatch(
                                        setPortraitImage(
                                            campaign.id,
                                            isTokenTemplate(token) ? undefined : location?.id,
                                            [token!],
                                            item
                                        )
                                    );
                                }}>
                                Apply to {token ? system.getDisplayName(token, campaign) : "token"}
                            </MenuItem>
                        )}
                        {!item.isReadOnly && (
                            <MenuItem
                                onClick={() => {
                                    setPendingDelete(item);
                                }}>
                                Delete
                            </MenuItem>
                        )}
                    </React.Fragment>
                )}
            </ControlledMenu>

            <ModalDialog
                onRequestClose={() => {
                    setPendingDelete(undefined);
                }}
                isOpen={pendingDelete != null}
                style={{
                    content: {
                        width: theme.space[12],
                    },
                }}>
                <FocusTrap focusTrapOptions={{ initialFocus: "#modalDefault", allowOutsideClick: true }}>
                    <Box flexDirection="column" p={3} maxWidth={theme.space[13]} alignItems="flex-start">
                        <Heading
                            as="h3"
                            mb={3}
                            css={{ textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden" }}>
                            Delete {lastPendingDelete.current?.name}?
                        </Heading>
                        <Text>
                            This will delete {lastPendingDelete.current?.name} from your account. Any location or token
                            in this campaign or any other that uses this item may not work correctly once it is gone.
                        </Text>
                        <LobotomizedBox justifyContent="flex-end" mt={3} fullWidth>
                            <Button
                                disabled={pendingDelete == null}
                                id="modalDefault"
                                variant="primary"
                                onClick={async () => {
                                    if (pendingDelete) {
                                        await UserArtLibrary.current.deleteItem(pendingDelete, errorHandler);
                                        setPendingDelete(undefined);
                                    }
                                }}>
                                Delete
                            </Button>
                            <Button
                                disabled={pendingDelete == null}
                                variant="secondary"
                                onClick={() => setPendingDelete(undefined)}>
                                Keep
                            </Button>
                        </LobotomizedBox>
                    </Box>
                </FocusTrap>
            </ModalDialog>
        </React.Fragment>
    );
};

export const ImageThumbnail: FunctionComponent<{
    libraryItem: LibraryItem;
    rotation?: number;
}> = ({ libraryItem, rotation }) => {
    // Use the thumbnail URI, but fall back to the main URI for tokens only - other images might be huge.
    let thumbnailUri = libraryItem.metadata.thumbnailUri;
    if (!thumbnailUri && libraryItem.metadata.type === "token") {
        thumbnailUri = libraryItem.uri;
    }

    thumbnailUri = resolveUri(thumbnailUri);

    return (
        <Box width={tokenImageSize} height={tokenImageSize} bg="grayscale.7" borderRadius={3}>
            {thumbnailUri && (
                <Image
                    src={thumbnailUri}
                    responsive
                    draggable={false}
                    css={{
                        transform: `rotate(${rotation ?? libraryItem.metadata.rotation ?? 0}deg)`,
                        transformOrigin: "center center",
                        objectFit: "contain",
                        height: "100%",
                        width: "100%",
                    }}
                />
            )}
        </Box>
    );
};

const ImageLibraryItemElement: FunctionComponent<{
    libraryItem: LibraryItem;
    palette: string;
    name: string;
    folder?: string;
    canSelect?: boolean;
    isSelected?: boolean;
}> = ({ libraryItem, palette, name, folder, canSelect, isSelected }) => {
    return (
        <DraggableBox
            draggableId={libraryItem.uri}
            dragOverlay
            data={libraryItem}
            type={`LibraryItem/${libraryItem.metadata.type}`}
            css={{ cursor: canSelect ? "pointer" : undefined }}
            fullWidth>
            <ImageThumbnail libraryItem={libraryItem} />
            <Box flex={1} ml={3} flexDirection="column" alignItems="flex-start">
                {libraryItem.metadata.type && (
                    <Tag mb={1} bg={`${palette}.7`} color={`${palette}.0`}>
                        {libraryItem.metadata.type}
                    </Tag>
                )}
                {folder && (
                    <Tag mb={1} css={{ maxWidth: "unset" }} bg={`yellows.7`} color={`yellows.0`}>
                        {folder.replaceAll("/", " • ").replaceAll("_", " ")}
                    </Tag>
                )}
                <Text
                    color="grayscale.2"
                    fontSize={0}
                    css={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        alignSelf: "stretch",
                    }}>
                    {name}
                </Text>
            </Box>
        </DraggableBox>
    );
};

// interface SoundProps {
//     src: string;
//     isPlaying: boolean;
// }

// const Sound: FunctionComponent<SoundProps> = ({ src, isPlaying }) => {
//     const [howl, setHowl] = useState<Howl>();

//     useEffect(() => {
//         const h = new Howl({ src: src })
//         setHowl(h);
//         return () => {
//             h.fade(h.volume(), 0, 500);
//             h.once("fade", () => h.stop());
//         };
//     }, [src]);

//     useEffect(() => {
//         if (howl) {
//             if (isPlaying) {
//                 howl.play();
//             } else {
//                 howl.stop();
//             }
//         }
//     }, [howl, isPlaying]);

//     return <React.Fragment></React.Fragment>
// }

interface AudioPlayerProps {
    src: string;
    onPlay: () => void;
    onPause: () => void;
    onStop: () => void;
}

const FADE_DURATION = 200;
const AudioPlayer: FunctionComponent<AudioPlayerProps> = ({ src, onPlay, onPause, onStop }) => {
    const [howl, setHowl] = useState<Howl>();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [percentComplete, setPercentComplete] = useState(0);

    // Make sure we clean up after ourselves.
    useEffect(() => {
        return () => {
            if (howl) {
                howl.once("fade", () => howl.stop());
                howl.fade(1, 0, FADE_DURATION);
            }
        };
    }, [howl]);
    const updatePosition = useCallback(() => {
        if (howl) {
            const duration = howl.duration();
            setPercentComplete(duration > 0 ? ((howl.seek() as number) / duration) * 100 : 0);
        } else {
            setPercentComplete(0);
        }
    }, [howl, setPercentComplete]);
    const t = useRef<number | undefined>();
    useEffect(() => {
        if (isPlaying) {
            const doUpdate = () => {
                updatePosition();
                t.current = setTimeout(doUpdate, 200) as any;
            };

            doUpdate();
        } else if (!isPaused) {
            setPercentComplete(0);
        }

        return () => {
            clearTimeout(t.current);
        };
    }, [howl, isPlaying, isPaused, updatePosition]);

    return (
        <Box flexDirection="row" mt={1} fullWidth>
            <Button
                shape="square"
                size="s"
                disabled={isLoading}
                onClick={() => {
                    if (!howl) {
                        setIsLoading(true);
                        const h = new Howl({ src: [src] });
                        h.on("play", () => {
                            h.volume(1);
                            setIsPlaying(true);
                            setIsPaused(false);
                            onPlay();
                        });
                        h.on("stop", () => {
                            setIsPlaying(false);
                            setIsPaused(false);
                            onStop();
                        });
                        h.on("pause", () => {
                            setIsPlaying(false);
                            setIsPaused(true);
                            onPause();
                        });
                        h.on("end", () => {
                            h.stop();
                        });
                        h.once("load", () => {
                            h.play();
                            setIsLoading(false);
                        });
                        h.once("loaderror", (id, error) => {
                            console.error(error);
                            setIsLoading(false);
                        });
                        setHowl(h);
                        setIsPlaying(true);
                        setIsPaused(false);
                        onPlay();
                    } else if (isPaused || !isPlaying) {
                        howl.play();
                        setIsPlaying(true);
                        setIsPaused(false);
                        onPlay();
                    } else {
                        howl.pause();
                        setIsPlaying(false);
                        setIsPaused(true);
                        onPause();
                    }
                }}>
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </Button>
            <Button
                shape="square"
                size="s"
                ml={2}
                disabled={!howl || (!isPlaying && !isPaused)}
                onClick={() => {
                    howl?.once("fade", () => howl.stop());
                    howl?.fade(1, 0, FADE_DURATION);
                    setIsPlaying(false);
                    setIsPaused(false);
                    onStop();
                }}>
                <StopIcon />
            </Button>

            <PercentageBar
                size="l"
                dangerThreshold={0}
                warningThreshold={0}
                ml={2}
                flexGrow={1}
                complete={percentComplete}
                total={100}
                width={"auto"}
                onClick={e => {
                    if (howl && (isPlaying || isPaused)) {
                        const x = e.nativeEvent.offsetX;
                        const width = (e.nativeEvent.target as HTMLElement).offsetWidth;
                        howl.fade(1, 0, 100);
                        howl.once("fade", () => {
                            howl.seek((x / width) * howl.duration());
                            updatePosition();
                            howl.fade(0, 1, 100);
                        });
                    }
                }}
            />
        </Box>
    );
};

const ModelLibraryItemElement: FunctionComponent<{
    libraryItem: LibraryItem;
    palette: string;
    name: string;
    folder?: string;
    canSelect?: boolean;
    isSelected?: boolean;
}> = ({ libraryItem, palette, name, folder, canSelect }) => {
    return (
        <DraggableBox
            draggableId={libraryItem.uri}
            dragOverlay
            data={libraryItem}
            type={`LibraryItem/${libraryItem.metadata.type}`}
            fullWidth
            flexDirection="row"
            css={{ cursor: canSelect ? "pointer" : undefined }}
            alignItems="flex-start">
            <ImageThumbnail libraryItem={libraryItem} />
            <Box flex={1} ml={3} flexDirection="column" alignItems="flex-start">
                {libraryItem.metadata.type && (
                    <Tag mb={1} bg={`${palette}.7`} color={`${palette}.0`}>
                        {libraryItem.metadata.type === ModelType.Token ? "token model" : libraryItem.metadata.type}
                    </Tag>
                )}
                {folder && (
                    <Tag mb={1} css={{ maxWidth: "unset" }} bg={`yellows.7`} color={`yellows.0`}>
                        {folder.replaceAll("/", " • ").replaceAll("_", " ")}
                    </Tag>
                )}
                <Text
                    color="grayscale.2"
                    fontSize={0}
                    css={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        alignSelf: "stretch",
                    }}>
                    {libraryItem.name}
                </Text>
            </Box>
        </DraggableBox>
    );
};

const AudioLibraryItemElement: FunctionComponent<{
    libraryItem: LibraryItem;
    palette: string;
    onUpdate?: () => void;
    name: string;
    folder?: string;
    canSelect?: boolean;
    isSelected?: boolean;
}> = ({ libraryItem, palette, onUpdate, name, folder, canSelect }) => {
    // const onDragStart = useCallback((e: React.DragEvent) => {
    //     e.dataTransfer.setData("text", libraryItem.uri);
    //     e.dataTransfer.setData(`LibraryItem/${libraryItem.metadata.type}`, JSON.stringify(libraryItem));

    //     if (libraryItem.metadata.type === AudioType.Ambient) {
    //         const token: Token = {
    //             id: nanoid(),
    //             pos: { x: Number.NaN, y: Number.NaN, type: PositionType.LocalPixel },
    //             sound: { uri: libraryItem.uri, name: libraryItem.name, radius: 10, volume: 100 }
    //         };
    //         e.dataTransfer.setData("Token", JSON.stringify(token));
    //     }
    // }, [libraryItem]);

    //        p={2}
    //        css={{ margin: -theme.space[2], marginBottom: 0 }}
    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <DraggableBox
            draggableId={libraryItem.uri}
            dragOverlay
            data={libraryItem}
            type={`LibraryItem/${libraryItem.metadata.type}`}
            fullWidth
            flexDirection="column"
            css={{ cursor: canSelect ? "pointer" : undefined }}
            alignItems="flex-start">
            <MotionBox layout="position" flexDirection="column" alignItems="flex-start" fullWidth>
                {libraryItem.metadata.type && (
                    <Tag mb={1} bg={`${palette}.7`} color={`${palette}.0`}>
                        {libraryItem.metadata.type}
                    </Tag>
                )}
                {folder && (
                    <Tag mb={1} css={{ maxWidth: "unset" }} bg={`yellows.7`} color={`yellows.0`}>
                        {folder.replaceAll("/", " • ").replaceAll("_", " ")}
                    </Tag>
                )}
                <Text
                    color="grayscale.2"
                    fontSize={0}
                    css={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        alignSelf: "stretch",
                    }}>
                    {libraryItem.name}
                </Text>

                <AudioPlayer
                    src={resolveUri(libraryItem.uri)}
                    onPlay={() => {
                        setIsPlaying(true);
                        if (onUpdate) {
                            onUpdate();
                        }
                    }}
                    onPause={() => {
                        setIsPlaying(false);
                        if (onUpdate) {
                            onUpdate();
                        }
                    }}
                    onStop={() => {
                        setIsPlaying(false);
                        if (onUpdate) {
                            onUpdate();
                        }
                    }}
                />
            </MotionBox>

            <AnimatePresence>
                {isPlaying && (
                    <AnimatedListItem fullWidth>
                        <Message alignSelf="stretch" mt={2} variant="info" flex="0 1 auto" fullWidth>
                            This sound is only being previewed. Nobody else can hear it.
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>
        </DraggableBox>
    );
};

export function getPaletteForLibraryItemType(type: AudioType | ImageType | ModelType | undefined) {
    switch (type) {
        case ImageType.Background:
            return "greens";
        case ImageType.Token:
            return "purples";
        case ImageType.Portrait:
            return "oranges";
        case ImageType.Object:
            return "reds";
        case AudioType.Music:
            return "cyans";
        case AudioType.Ambient:
            return "blues";
        case ModelType.Token:
            return "violets";
        default:
            return "reds";
    }
}

export const LibraryItemElement: FunctionComponent<{
    libraryItem: LibraryItem;
    onUpdate?: () => void;
    canSelect?: boolean;
    isSelected?: boolean;
    isActive?: boolean;
    isFocused?: boolean;
}> = ({ libraryItem, onUpdate, canSelect, isSelected, isActive, isFocused }) => {
    const { showContextMenu, current } = useContext(LibraryItemContext);
    const ref = useRef<HTMLDivElement>(null);

    const audio = isAudio(libraryItem);
    const model = isModel(libraryItem);

    let palette = getPaletteForLibraryItemType(libraryItem.metadata.type);

    let nameIndex = libraryItem.name.lastIndexOf("/");
    let name = nameIndex >= 0 ? libraryItem.name.substr(nameIndex + 1) : libraryItem.name;
    let folder = nameIndex >= 0 ? libraryItem.name.substr(0, nameIndex) : undefined;

    return (
        <ListItem
            ref={ref}
            selected={isSelected}
            active={isActive}
            focused={isFocused}
            isContextMenuTarget={current?.uri === libraryItem.uri}
            onContextMenu={e => {
                showContextMenu(libraryItem, ref);
                e.preventDefault();
            }}>
            {audio && (
                <AudioLibraryItemElement
                    libraryItem={libraryItem}
                    palette={palette}
                    onUpdate={onUpdate}
                    isSelected={isSelected}
                    name={name}
                    folder={folder}
                    canSelect={canSelect}
                />
            )}
            {model && (
                <ModelLibraryItemElement
                    libraryItem={libraryItem}
                    palette={palette}
                    isSelected={isSelected}
                    name={name}
                    folder={folder}
                    canSelect={canSelect}
                />
            )}
            {!audio && !model && (
                <ImageLibraryItemElement
                    libraryItem={libraryItem}
                    palette={palette}
                    isSelected={isSelected}
                    name={name}
                    folder={folder}
                    canSelect={canSelect}
                />
            )}
        </ListItem>
    );

    // return (
    //     <React.Fragment>
    //         <Box
    //             fullWidth
    //             bg={isSelected ? "accent.2" : isActive ? (isFocused ? "grayscale.7" : "grayscale.8") : undefined}
    //             onContextMenu={e => {
    //                 showContextMenu(libraryItem, ref);
    //                 e.preventDefault();
    //             }}
    //             ref={ref}
    //             borderRadius={3}
    //             css={{
    //                 outlineOffset: "2px",
    //                 outline: current?.uri === libraryItem.uri ? `2px solid ${theme.colors.guidance.focus}` : undefined,
    //                 textShadow: isSelected || isActive ? "none" : "inherit",
    //                 ":hover": {
    //                     background: canSelect
    //                         ? isSelected
    //                             ? theme.colors.accent[2]
    //                             : theme.colors.grayscale[8]
    //                         : "initial",
    //                     textShadow: canSelect ? "none" : "inherit",
    //                     zIndex: 1,
    //                 },
    //             }}
    //             px={canSelect ? 3 : undefined}
    //             my={canSelect ? -2 : undefined}
    //             py={canSelect ? 2 : undefined}
    //             mb={canSelect ? 1 : 2}>
    //             {audio && (
    //                 <AudioLibraryItemElement
    //                     libraryItem={libraryItem}
    //                     palette={palette}
    //                     onUpdate={onUpdate}
    //                     isSelected={isSelected}
    //                     name={name}
    //                     folder={folder}
    //                     canSelect={canSelect}
    //                 />
    //             )}
    //             {model && (
    //                 <ModelLibraryItemElement
    //                     libraryItem={libraryItem}
    //                     palette={palette}
    //                     isSelected={isSelected}
    //                     name={name}
    //                     folder={folder}
    //                     canSelect={canSelect}
    //                 />
    //             )}
    //             {!audio && !model && (
    //                 <ImageLibraryItemElement
    //                     libraryItem={libraryItem}
    //                     palette={palette}
    //                     isSelected={isSelected}
    //                     name={name}
    //                     folder={folder}
    //                     canSelect={canSelect}
    //                 />
    //             )}
    //         </Box>
    //     </React.Fragment>
    // );
};

export interface LibraryItemMetadata {
    type?: ImageType | AudioType | ModelType;
    thumbnailUri?: string;
    canRotate?: boolean;
    rotation?: number;

    /**
     * The scale at which to render the token. This is applied multiplicatively with the scale value. Defaults to 1 if not specified.
     * i.e. if the scale is 2 and the renderScale is 1.5, the token image is rendered at scale 3 while the
     * token hit box remains at scale 2.
     * This can be used to render tokens that overflow the bounds of their token.
     */
    renderScale?: number;

    /**
     * The tile width in pixels that the art was designed for.
     * Only relevant for non-token images.
     */
    tilePxWidth?: number;

    /**
     * The tile height in pixels that the art was designed for.
     * Only relevant for non-token images.
     */
    tilePxHeight?: number;

    /**
     * The width of the image in pixels
     */
    width?: number;

    /**
     * The height of the image in pixels.
     */
    height?: number;

    /**
     * The z-index at which the image should be displayed.
     */
    zIndex?: number;
}

export interface LibraryItem {
    id: string;
    name: string;
    uri: string;
    contentLength: number | undefined;
    lastModified?: number;
    metadata: LibraryItemMetadata;
    isReadOnly?: boolean;
}

interface CachedLibrary {
    version: number;
    items: LibraryItem[];
}

interface GlobalUploadProgress {
    total: number;
    completed: number;
    failed: number;
    sent: number;
    size: number;
}

type UploadProgress = [number | undefined, number | undefined, boolean, Error | undefined];

let uploadsInProgress: { [id: string]: UploadProgress } | undefined = undefined;
let uploadsPromise: Deferred<number> | undefined;

const uploadProgressChanged = new Event<GlobalUploadProgress>();
const uploadPromiseChanged = new Event<Promise<number> | undefined>();

/**
 * Hook to receive the promise for the current set of uploads.
 */
export function useUploadPromise(): Promise<number> | undefined {
    return useEvent(uploadPromiseChanged, uploadsPromise ? uploadsPromise.promise : undefined);
}

/**
 * Hook to receive global upload progress updates.
 */
export function useUploadProgress(): GlobalUploadProgress {
    return useEvent(uploadProgressChanged, getUploadSummary());
}

/**
 * Gets a promise that will be completed when all the current uploads complete, or undefined
 * if there are no uploads in progress.
 */
export function getUploadPromise(): Promise<number> | undefined {
    return uploadsPromise ? uploadsPromise.promise : undefined;
}

function updateUploadProgress(id: string, progress: UploadProgress) {
    // If the upload has already finished, don't do anything.
    if ((progress[2] || progress[3]) && !uploadsInProgress) {
        return;
    }

    if (!uploadsInProgress) {
        uploadsInProgress = {};
        uploadsPromise = new Deferred<number>();
        uploadPromiseChanged.trigger(uploadsPromise.promise);
    }

    uploadsInProgress[id] = progress;

    const summary = getUploadSummary();
    uploadProgressChanged.trigger(summary);

    if (summary.completed + summary.failed === summary.total) {
        if (uploadsInProgress) {
            uploadsInProgress = undefined;
            (uploadsPromise as Deferred<number>).resolve(summary.total);
            uploadsPromise = undefined;
            uploadPromiseChanged.trigger(undefined);
        }
    }
}

/**
 * Gets a summary of all the downloads currently in progress.
 */
export function getUploadSummary(): GlobalUploadProgress {
    let completedDownloads = 0;
    let totalDownloads = 0;
    let failedDownloads = 0;
    let totalSent = 0;
    let totalSize = 0;

    if (uploadsInProgress) {
        let keys = Object.keys(uploadsInProgress);
        for (let i = 0; i < keys.length; i++) {
            const [sent, size, completed, error] = uploadsInProgress[keys[i]];
            if (sent != null) {
                totalSent += sent;
            }

            if (size != null) {
                totalSize += size;
            }

            totalDownloads++;
            if (completed) {
                completedDownloads++;
            }

            if (error) {
                failedDownloads++;
            }
        }
    }

    return {
        total: totalDownloads,
        completed: completedDownloads,
        failed: failedDownloads,
        sent: totalSent,
        size: totalSize,
    };
}

export class UserArtLibrary {
    private static _current: UserArtLibrary;
    private _blobs: LibraryItem[] = [];
    private _version: number;
    private _init: Promise<LibraryItem[]> | undefined;
    private _itemsChanged: Event<LibraryItem[]>;
    private _error: Error | undefined;
    private _searchable: Promise<LocalSearchable<LibraryItem, () => jsx.JSX.Element>> | undefined;
    private _usedCapacity: number | undefined;
    private _capacityChanged: Event<{ used: number; max: number }>;

    constructor() {
        this._capacityChanged = new Event<{ used: number; max: number }>();
        this._itemsChanged = new Event<LibraryItem[]>();
        this._itemsChanged.on(() => delete this._searchable);
        this._version = 0;
        this._init = this.getLibraryItems().then(
            o => {
                this._blobs = o;
                this._version++;
                delete this._init;
                this._itemsChanged.trigger(this._blobs);
                return o;
            },
            err => {
                this._error = err;
                delete this._init;
                this._itemsChanged.trigger(this._blobs);
                return err;
            }
        );
    }

    static get currentVersion() {
        return UserArtLibrary._current?.version;
    }

    static get current() {
        if (!UserArtLibrary._current) {
            UserArtLibrary._current = new UserArtLibrary();
        }

        return UserArtLibrary._current;
    }

    get items(): LibraryItem[] {
        return this._blobs;
    }

    get version(): number {
        return this._version;
    }

    get loadingLibrary(): Promise<LibraryItem[]> | undefined {
        return this._init;
    }

    get capacityUsed() {
        return this._usedCapacity;
    }

    get capacityMax() {
        return DEFAULT_MAX_CAPACITY;
    }

    get capacityChanged() {
        return this._capacityChanged;
    }

    async getItemsAsync(): Promise<{ version: number; items: LibraryItem[] }> {
        await this._init;
        return { version: this._version, items: this.items };
    }

    getItemsSearchable(): Promise<LocalSearchable<LibraryItem, () => jsx.JSX.Element>> {
        if (this._searchable) {
            return this._searchable;
        }

        this._searchable = this.getItemsSearchableAsync();
        return this._searchable;
    }

    private updateCapacity(used: number) {
        this._usedCapacity = used;
        this._capacityChanged.trigger({ used: this._usedCapacity, max: this.capacityMax });
    }

    private async getItemsSearchableAsync() {
        await this._init;
        return new LocalSearchable(this._blobs, {
            idField: "name",
            searchableFields: [
                "name",
                {
                    name: "type",
                    extractor: o => (o as LibraryItem).metadata.type as string,
                },
                {
                    name: "tags",
                    extractor: o => {
                        const libraryItem = o as LibraryItem;
                        if (!libraryItem.metadata.type) {
                            return "";
                        }

                        if (Object.values(ImageType).indexOf(libraryItem.metadata.type as ImageType) >= 0) {
                            return ["image", "picture"];
                        }

                        if (Object.values(AudioType).indexOf(libraryItem.metadata.type as AudioType) >= 0) {
                            return ["sound", "audio"];
                        }

                        return "";
                    },
                },
            ],
            getObjectConfig: o => (!o.isReadOnly ? { boost: 2 } : undefined), // Writable items (i.e. the user's own art) get higher priority.
            toResult: o => () => <LibraryItemElement libraryItem={o} />,
        });
    }

    useItems() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return UserArtLibrary.useItemsInternal(this);
    }

    static useItems() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return UserArtLibrary.useItemsInternal(UserArtLibrary._current);
    }

    private static useItemsInternal(current: UserArtLibrary | undefined) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const triggeredRef = useRef<number>(0);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useEvent<
            { items: LibraryItem[]; isLoading: boolean; error: Error | undefined; triggerCount: number },
            LibraryItem[]
        >(
            current?._itemsChanged,
            {
                items: current?._blobs ?? [],
                isLoading: !!current?._init,
                error: current?._error,
                triggerCount: triggeredRef.current,
            },
            o => ({
                items: o,
                isLoading: !!current?._init,
                error: current?._error,
                triggerCount: ++triggeredRef.current,
            })
        );
    }

    async uploadThumbnail(item: LibraryItem, blob: Blob, errorHandler: ErrorHandler) {
        const formData = new FormData();
        formData.append("file", blob);

        const response = await fetch("api/storage/thumbnail?id=" + encodeURIComponent(item.id), {
            method: "POST",
            body: formData,
        });
        errorHandler.handleResponse(response);

        item.metadata.thumbnailUri = (await response.json()) as string;
        this._version++;
        this._itemsChanged.trigger(this._blobs);
    }

    async updateMetadata(
        item: LibraryItem,
        metadata: LibraryItemMetadata,
        name: string | undefined,
        errorHandler: ErrorHandler
    ) {
        const finalMetadata = Object.assign({}, { name: name }, metadata);
        delete finalMetadata.width;
        delete finalMetadata.height;

        const response = await fetch("api/storage/update?id=" + encodeURIComponent(item.id), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(finalMetadata),
        });
        if (errorHandler.handleResponse(response)) {
            // TODO: Update local library with new modified time.
            const r = (await response.json()) as { lastModified?: number };
            if (r?.lastModified != null) {
                item.lastModified = r.lastModified;
            }

            if (name != null) {
                item.name = name;
            }

            item.metadata = metadata;
            this._version++;
            this._itemsChanged.trigger(this._blobs);
        }
    }

    async uploadFile(
        file: File,
        errorHandler: ErrorHandler,
        type?: AudioType | ImageType
    ): Promise<{ libraryItem: LibraryItem | undefined; alreadyExisted: boolean }> {
        return this.uploadBlob(file, file.name, errorHandler, type);
    }

    private uploadQueue: (() => void)[] = [];

    async uploadBlob(
        blob: Blob,
        name: string,
        errorHandler: ErrorHandler,
        type?: ImageType | AudioType | LibraryItemMetadata
    ): Promise<{ libraryItem: LibraryItem | undefined; alreadyExisted: boolean }> {
        // Make sure we have the library before making any changes to it.
        await this._init;

        return new Promise<{ libraryItem: LibraryItem | undefined; alreadyExisted: boolean }>((resolve, reject) => {
            // Register the upload immediately, so that its progress is tracked, even if it's not our turn yet.
            const id = nanoid();
            updateUploadProgress(id, [0, blob.size, false, undefined]);

            const startNext = () => {
                this.uploadQueue.shift();
                if (this.uploadQueue.length > 0) {
                    this.uploadQueue[0]();
                }
            };
            const resolveAndStartNext = (value: { libraryItem: LibraryItem | undefined; alreadyExisted: boolean }) => {
                startNext();
                resolve(value);
            };
            const rejectAndStartNext = (reason: any) => {
                startNext();
                reject(reason);
            };
            const upload = () => {
                const formData = new FormData();

                if (type != null) {
                    if (typeof type === "string") {
                        formData.append("type", type);
                    } else {
                        formData.append("metadata", JSON.stringify(type));
                    }
                }

                formData.append("file", blob, name);

                const request = new XMLHttpRequest();
                let sent = 0;
                request.open("POST", "api/storage/upload", true);
                request.upload.onprogress = e => {
                    sent = e.loaded;
                    updateUploadProgress(id, [e.loaded, e.total, false, undefined]);
                };
                request.onload = () => {
                    try {
                        const response =
                            request.responseText === ""
                                ? undefined
                                : (JSON.parse(request.responseText) as UploadFilesResponse);
                        if (!response) {
                            const errorText = `The request to upload the file ${name} was successful, but did not return a valid response.`;
                            updateUploadProgress(id, [sent, blob.size, false, new Error(errorText)]);
                            rejectAndStartNext(errorText);
                            errorHandler.handleError(undefined, errorText);
                        } else {
                            const items = response.success;
                            let libraryItem: LibraryItem | undefined;
                            let alreadyExisted = false;
                            if (this._blobs) {
                                let addedCount = 0;
                                for (let i = 0; i < items.length; i++) {
                                    if (items[i]["alreadyExists"]) {
                                        // The item already exists, there's no need to update version etc.
                                        const existingItem = this._blobs.find(o => o.id === items[i].id);
                                        if (existingItem) {
                                            if (!libraryItem) {
                                                libraryItem = existingItem;
                                                alreadyExisted = true;
                                            }
                                        } else {
                                            // Server says it found an existing item, but we don't have it.
                                            const errorText = `The request to upload the file ${name} was successful, but did not return a valid response.`;
                                            updateUploadProgress(id, [sent, blob.size, false, new Error(errorText)]);
                                            rejectAndStartNext(errorText);
                                            errorHandler.handleError(undefined, errorText);
                                            return;
                                        }
                                    } else {
                                        this._blobs.push(items[i] as LibraryItem);
                                        addedCount++;
                                        if (!libraryItem) {
                                            libraryItem = items[i] as LibraryItem;
                                        }
                                    }
                                }

                                if (addedCount > 0) {
                                    this._version++;
                                    this._itemsChanged.trigger(this._blobs);
                                }
                            }

                            if (response.fail) {
                                for (let i = 0; i < response.fail.length; i++) {
                                    errorHandler.handleError(
                                        undefined,
                                        `Failed to upload ${response.fail[i].file}. ${response.fail[i].reason}`
                                    );
                                }
                            }

                            this.updateCapacity(response.used);

                            updateUploadProgress(id, [blob.size, blob.size, true, undefined]);
                            resolveAndStartNext({
                                libraryItem: libraryItem,
                                alreadyExisted: alreadyExisted,
                            });
                        }
                    } catch (e) {
                        updateUploadProgress(id, [sent, blob.size, false, e as Error]);
                        rejectAndStartNext(e);

                        errorHandler.handleError(
                            e,
                            `The request to upload the file ${name} was successful, but did not return a valid response.`
                        );
                    }
                };
                request.onerror = () => {
                    const errorText = request.responseText;
                    updateUploadProgress(id, [sent, blob.size, false, new Error(errorText)]);
                    rejectAndStartNext(errorText);

                    errorHandler.handleResponse(request.response, `Failed to upload ${name}.`);
                };
                request.send(formData);
            };

            this.uploadQueue.push(upload);
            if (this.uploadQueue.length === 1) {
                upload();
            }
        });
    }

    async deleteItem(item: LibraryItem, errorHandler: ErrorHandler) {
        if (item.isReadOnly) {
            throw new Error("Cannot delete read only items.");
        }

        var response = await fetch("api/storage/delete?id=" + encodeURIComponent(item.id), {
            method: "DELETE",
        });
        if (errorHandler.handleResponse(response)) {
            var r = (await response.json()) as DeleteFileResponse;
            this.updateCapacity(r.used);

            var i = this._blobs.findIndex(o => o.id === item.id);
            this._blobs.splice(i, 1);
            this._version++;
            this._itemsChanged.trigger(this._blobs);
        }
    }

    async getCustomFilesAsync(folder: string): Promise<{ uri: string; blob: BlobItem }[]> {
        // Fetch stuff that's in another random folder (i.e. dnd5e/rulesets, where json files are stored etc).
        const container = await getContainer();
        const qsi = container.uri.url.lastIndexOf("?");
        let urlNoParams = qsi >= 0 ? container.uri.url.substr(0, qsi) : container.uri.url;

        // Strip off the unnecessary baseUri part, it will shorten all the URIs which could be quite a bit less text overall.
        // It also supports the idea of packages being able to express their art paths as relative paths and have them work.
        // Could insulate stored URIs from problems with the azure cloud URI potentially changing.
        const customUrl = this.customiseUri(container.baseUri, urlNoParams);

        let marker: string | undefined;
        let blobs: { uri: string; blob: BlobItem }[] = [];
        do {
            const response = await container.uri.listBlobFlatSegment(Aborter.none, marker, {
                include: ["metadata"],
                prefix: folder,
            });

            blobs.push(
                ...response.segment.blobItems.map(blob => {
                    //const id = blob.name.substr(folder.length + 1);
                    //const name = decodeURIComponent(blob.metadata?.["name"]) ?? id;
                    return {
                        uri: customUrl + "/" + blob.name,
                        blob: blob,
                    };
                })
            );

            marker = response.nextMarker;
        } while (marker);

        return blobs;
    }

    private async getLibraryItems(): Promise<LibraryItem[]> {
        const container = await getContainer();

        const props = await container.uri.getProperties(Aborter.none);
        const usedCapacityString = props.metadata ? props.metadata["used"] : undefined;
        var used = usedCapacityString ? parseInt(usedCapacityString) : 0;
        this.updateCapacity(isNaN(used) ? 0 : used);

        const db = await openDB<CachedLibrary>("ArtLibrary", 1, {
            upgrade(db) {
                db.createObjectStore("Providers");
            },
        });

        const libraryItems = await this.getLibraryItemsFromContainer(
            container.baseUri,
            container.uri,
            container.version,
            false,
            db,
            props
        );
        const publicContainers = await getPublicContainers();
        for (let publicContainer of publicContainers) {
            try {
                libraryItems.push(
                    ...(await this.getLibraryItemsFromContainer(
                        container.baseUri,
                        publicContainer,
                        undefined,
                        true,
                        db
                    ))
                );
            } catch (e) {
                console.error(`Error getting library items from ${publicContainer.url}:\n${(e as any)?.toString()}`);
            }
        }

        db.close();

        return libraryItems;
    }

    private customiseUri(baseUri: string, uri: string) {
        if (!uri.startsWith(baseUri)) {
            return uri;
        }

        uri = uri.substring(uri[baseUri.length] === "/" ? baseUri.length + 1 : baseUri.length);
        return "cld://" + uri;
    }

    private async getLibraryItemsFromContainer(
        baseUri: string,
        container: ContainerURL,
        currentVersion: number | undefined,
        isReadOnly: boolean,
        db: IDBPDatabase<CachedLibrary>,
        props?: ContainerGetPropertiesResponse
    ) {
        const folder = "art";

        // Check for a cached library in local storage.
        // TODO: Store the version separately so that we don't have to parse the entire library if the version is wrong anyway?
        const qsi = container.url.lastIndexOf("?");
        let urlNoParams = qsi >= 0 ? container.url.substr(0, qsi) : container.url;
        const cachedItemJson = await db.get("Providers", urlNoParams);
        const library = cachedItemJson ?? { version: -1, items: [] };

        // Strip off the unnecessary baseUri part, it will shorten all the URIs which could be quite a bit less text overall.
        // It also supports the idea of packages being able to express their art paths as relative paths and have them work.
        // Could insulate stored URIs from problems with the azure cloud URI potentially changing.
        const customUrl = this.customiseUri(baseUri, urlNoParams);

        // If the current version of the container is the same as our cached one, then we don't need to request it again.
        if (currentVersion == null) {
            const containerProps = props ?? (await container.getProperties(Aborter.none));
            const currentVersionString = containerProps.metadata ? containerProps.metadata["version"] : undefined;
            currentVersion = currentVersionString ? parseInt(currentVersionString) : 0;
        }

        if (library.version === currentVersion) {
            return library.items;
        } else {
            library.version = currentVersion;
            library.items = [];
        }

        let marker: string | undefined;
        do {
            const response = await container.listBlobFlatSegment(Aborter.none, marker, {
                include: ["metadata"],
                prefix: folder,
            });

            let blobs = response.segment.blobItems;

            console.log(
                `Successfully listed ${blobs.length} blobs from ${urlNoParams}, request ID ${response.requestId}.`
            );
            library.items.push(...blobs.map(o => makeLibraryItem(o, customUrl, isReadOnly, folder)));

            marker = response.nextMarker;
        } while (marker);

        await db.put("Providers", library, urlNoParams);

        return library.items;
    }
}

function makeLibraryItem(blob: BlobItem, urlNoParams: string, isReadOnly: boolean, folder: string): LibraryItem {
    const id = blob.name.substr(folder.length + 1);
    const name = decodeURIComponent(blob.metadata?.["name"]) ?? id;
    return {
        id: id,
        name: name,
        uri: urlNoParams + "/" + blob.name,
        contentLength: blob.properties.contentLength,
        isReadOnly: isReadOnly,
        lastModified: blob.properties.lastModified.getTime(),
        metadata: getMetadata(
            blob.properties.contentType,
            blob.metadata ? metadataFromBlobMetadata(blob.metadata) : undefined,
            blob.properties.contentLength
        ),
    };
}

function metadataFromBlobMetadata(blobMetadata: BlobMetadata): LibraryItemMetadata {
    const metadata: LibraryItemMetadata = {};
    metadata.type = blobMetadata["type"];

    if (blobMetadata["thumbnailUri"]) {
        metadata.thumbnailUri = blobMetadata["thumbnailUri"];
    }

    if (blobMetadata["canRotate"]) {
        metadata.canRotate = blobMetadata["canRotate"] === "True" ? true : false;
    }

    if (blobMetadata["rotation"]) {
        metadata.rotation = parseInt(blobMetadata["rotation"], 10);
    }

    if (blobMetadata["renderScale"]) {
        metadata.rotation = parseInt(blobMetadata["renderScale"], 10);
    }

    if (blobMetadata["tilePxWidth"]) {
        metadata.tilePxWidth = parseInt(blobMetadata["tilePxWidth"], 10);
    }

    if (blobMetadata["tilePxHeight"]) {
        metadata.tilePxHeight = parseInt(blobMetadata["tilePxHeight"], 10);
    }

    if (blobMetadata["zIndex"]) {
        metadata.zIndex = parseFloat(blobMetadata["zIndex"]);
    }

    if (blobMetadata["width"]) {
        metadata.width = parseInt(blobMetadata["width"], 10);
    }

    if (blobMetadata["height"]) {
        metadata.height = parseInt(blobMetadata["height"]);
    }

    return metadata;
}

function getMetadata(
    contentType: string | undefined,
    metadata: LibraryItemMetadata | undefined,
    size?: number
): LibraryItemMetadata {
    if (!metadata) {
        metadata = {};
    }

    if (contentType && contentType.indexOf("audio") >= 0) {
        metadata.type = AudioType.Music;
    } else {
        // Assume this is an image, since we can't work it out.
        // Files over 500k are assumed to be a large map file.
        if (!metadata.type) {
            metadata.type = !size || size >= 500000 ? ImageType.Background : ImageType.Token;
        }
    }

    return metadata;
}

async function getPublicContainers(): Promise<ContainerURL[]> {
    if (!_publicContainers) {
        const response = await fetch("api/storage/public");
        const uris = (await response.json()) as string[];
        const anonCred = new AnonymousCredential();
        const pipeline = StorageURL.newPipeline(anonCred);
        _publicContainers = uris.map(o => new ContainerURL(o, pipeline));
    }

    return _publicContainers;
}

async function getContainer(): Promise<{ baseUri: string; uri: ContainerURL; version: number }> {
    if (!_container) {
        const response = await fetch("api/storage/token");
        const info = (await response.json()) as { baseUri: string; uri: string; version: number };
        const anonCred = new AnonymousCredential();
        const pipeline = StorageURL.newPipeline(anonCred);
        _container = {
            baseUri: info.baseUri,
            uri: new ContainerURL(info.baseUri + info.uri, pipeline),
            version: info.version,
        };
    }

    return _container;
}

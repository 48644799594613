/** @jsxRuntime classic */
/** @jsx jsx */
import { Interpolation, jsx } from "@emotion/react";
import React, { FunctionComponent, HTMLAttributes, PropsWithChildren, useCallback, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { AnimatePresence } from "framer-motion";
import { theme } from "../design";
import { defaultInitial, defaultAnimate, defaultExit, MotionCard } from "./motion";
import { ReactNode } from "react";
import { Instance, Placement, createPopper } from "@popperjs/core";
import { ExtractProps } from "./common";
import { mergeRefs } from "react-merge-refs";
import { Box, Grid } from "./primitives";
import { Theme } from "styled-system";

export const defaultPopupDelay = 200;

export const BoxPopup: FunctionComponent<
    PropsWithChildren<
        {
            content: ReactNode;
            placement?: Placement;
            delay?: number;
            disabled?: boolean;
        } & ExtractProps<typeof Box>
    >
> = ({ content, children, placement, delay, disabled, ...props }) => {
    const referenceElement = useRef<HTMLDivElement>(null);
    const popper = useRef<Instance>();
    const setPopperElement = useCallback(
        (popperElement: HTMLElement | null) => {
            if (referenceElement.current && popperElement) {
                if (popper.current) {
                    popper.current.destroy();
                }

                popper.current = createPopper(referenceElement.current, popperElement, {
                    placement: placement ?? "bottom",
                    modifiers: [
                        { name: "offset", options: { offset: [0, theme.space[2]] } },
                        { name: "preventOverflow", options: { padding: theme.space[3] } },
                    ],
                });
            } else if (popper.current) {
                popper.current.destroy();
            }
        },
        [placement]
    );

    const [isHover, setIsHover] = useState(false);
    const delayHandle = useRef<number>();

    if (disabled && delayHandle.current != null) {
        clearTimeout(delayHandle.current);
        delayHandle.current = undefined;
    }

    return (
        <React.Fragment>
            <Box
                ref={referenceElement}
                onPointerEnter={() => {
                    if (!disabled) {
                        if (delay != null && delay > 0) {
                            if (delayHandle.current != null) {
                                clearTimeout(delayHandle.current);
                            }

                            delayHandle.current = setTimeout(() => setIsHover(true), delay) as any as number;
                        } else {
                            setIsHover(true);
                        }
                    }
                }}
                onPointerLeave={() => {
                    if (delayHandle.current != null) {
                        clearTimeout(delayHandle.current);
                        delayHandle.current = undefined;
                    }

                    setIsHover(false);
                }}
                {...props}>
                {content}
            </Box>
            {ReactDOM.createPortal(
                <AnimatePresence>
                    {!disabled && isHover && (
                        <Box zIndex={1000} css={{ pointerEvents: "none" }} ref={setPopperElement}>
                            <MotionCard
                                onBeforeLayoutMeasure={() => {
                                    popper.current?.forceUpdate();
                                }}
                                layout="position"
                                p={3}
                                maxWidth={600}
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}
                                bg="grayscale.8"
                                borderRadius={3}>
                                {children}
                            </MotionCard>
                        </Box>
                    )}
                </AnimatePresence>,
                document.querySelector("#vtt_main")!
            )}
        </React.Fragment>
    );
};

export const GridPopup: FunctionComponent<
    PropsWithChildren<
        {
            content: ReactNode;
            placement?: Placement;
            delay?: number;
            disabled?: boolean;
        } & ExtractProps<typeof Grid>
    >
> = ({ content, children, placement, delay, disabled, ...props }) => {
    const referenceElement = useRef<HTMLDivElement>(null);
    const popper = useRef<Instance>();
    const setPopperElement = useCallback(
        (popperElement: HTMLElement | null) => {
            if (referenceElement.current && popperElement) {
                if (popper.current) {
                    popper.current.destroy();
                }

                popper.current = createPopper(referenceElement.current, popperElement, {
                    placement: placement ?? "bottom",
                    modifiers: [
                        { name: "offset", options: { offset: [0, theme.space[2]] } },
                        { name: "preventOverflow", options: { padding: theme.space[3] } },
                    ],
                });
            } else if (popper.current) {
                popper.current.destroy();
            }
        },
        [placement]
    );

    const [isHover, setIsHover] = useState(false);
    const delayHandle = useRef<number>();

    if (disabled && delayHandle.current != null) {
        clearTimeout(delayHandle.current);
        delayHandle.current = undefined;
    }

    return (
        <React.Fragment>
            <Grid
                ref={referenceElement}
                onPointerEnter={() => {
                    if (!disabled) {
                        if (delay != null && delay > 0) {
                            if (delayHandle.current != null) {
                                clearTimeout(delayHandle.current);
                            }

                            delayHandle.current = setTimeout(() => setIsHover(true), delay) as any as number;
                        } else {
                            setIsHover(true);
                        }
                    }
                }}
                onPointerLeave={() => {
                    if (delayHandle.current != null) {
                        clearTimeout(delayHandle.current);
                        delayHandle.current = undefined;
                    }

                    setIsHover(false);
                }}
                {...props}>
                {content}
            </Grid>
            {ReactDOM.createPortal(
                <AnimatePresence>
                    {!disabled && isHover && (
                        <Box zIndex={1000} css={{ pointerEvents: "none" }} ref={setPopperElement}>
                            <MotionCard
                                onBeforeLayoutMeasure={() => {
                                    popper.current?.forceUpdate();
                                }}
                                layout="position"
                                p={3}
                                maxWidth={600}
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}
                                bg="grayscale.8"
                                borderRadius={3}>
                                {children}
                            </MotionCard>
                        </Box>
                    )}
                </AnimatePresence>,
                document.querySelector("#vtt_main")!
            )}
        </React.Fragment>
    );
};

export const TextPopup = React.forwardRef<
    HTMLElement,
    PropsWithChildren<
        {
            text: ReactNode;
            noWrap?: boolean;
            placement?: Placement;
            offset?: number;
            delay?: number;
            disabled?: boolean;
        } & HTMLAttributes<HTMLElement>
    >
>(({ text, noWrap, placement, offset, children, delay, disabled, onClick, ...props }, ref) => {
    const referenceElement = useRef<HTMLElement>(null);
    const popper = useRef<Instance>();
    const setPopperElement = useCallback(
        (popperElement: HTMLElement | null) => {
            if (referenceElement.current && popperElement) {
                if (popper.current) {
                    popper.current.destroy();
                }

                popper.current = createPopper(referenceElement.current, popperElement, {
                    placement: placement ?? "bottom",
                    modifiers: [
                        { name: "offset", options: { offset: [0, offset ?? theme.space[2]] } },
                        { name: "preventOverflow", options: { padding: theme.space[3] } },
                    ],
                });
            } else if (popper.current) {
                popper.current.destroy();
            }
        },
        [placement, offset]
    );

    const [isHover, setIsHover] = useState(false);
    const delayHandle = useRef<number>();

    if (disabled && delayHandle.current != null) {
        clearTimeout(delayHandle.current);
        delayHandle.current = undefined;
    }

    // TODO: If there is an onClick, this should really be a styled link instead of a span.
    // We'd have to change the props/ref type to something more generic, maybe HTMLElement.

    const [isShowingPopup, setIsShowingPopup] = useState(false);

    const onPointerEnter: React.PointerEventHandler = () => {
        setIsHover(true);
        if (!disabled) {
            if (delay != null && delay > 0) {
                if (delayHandle.current != null) {
                    clearTimeout(delayHandle.current);
                }

                delayHandle.current = setTimeout(() => setIsShowingPopup(true), delay) as any as number;
            } else {
                setIsShowingPopup(true);
            }
        }
    };
    const onPointerLeave: React.PointerEventHandler = () => {
        if (delayHandle.current != null) {
            clearTimeout(delayHandle.current);
            delayHandle.current = undefined;
        }

        setIsHover(false);
        setIsShowingPopup(false);
    };
    const css: Interpolation<Theme> = {
        fontWeight: "bold",
        color: !disabled && isHover ? theme.colors.guidance.focus : undefined,
        whiteSpace: noWrap ? "nowrap" : undefined,
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: onClick != null ? "pointer" : undefined,
    };
    return (
        <React.Fragment>
            {!onClick && (
                <span
                    ref={mergeRefs([referenceElement, ref])}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    {...props}
                    css={css}>
                    {text}
                </span>
            )}
            {onClick && (
                <a
                    ref={mergeRefs([referenceElement, ref])}
                    onClick={onClick}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    {...props}
                    css={css}>
                    {text}
                </a>
            )}
            {ReactDOM.createPortal(
                <AnimatePresence>
                    {!disabled && isShowingPopup && (
                        <Box zIndex={1000} css={{ position: "absolute", pointerEvents: "none" }} ref={setPopperElement}>
                            <MotionCard
                                onBeforeLayoutMeasure={() => {
                                    popper.current?.forceUpdate();
                                }}
                                layout="position"
                                p={3}
                                maxWidth={600}
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}
                                bg="grayscale.8"
                                borderRadius={3}>
                                {children}
                            </MotionCard>
                        </Box>
                    )}
                </AnimatePresence>,
                document.querySelector("#vtt_main")!
            )}
        </React.Fragment>
    );
});

import React from 'react'
  
  const DeafenedIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={size} height={size} stroke={color}>
        <g transform="translate(14,-8)"><path d="M453.395 34.029l24.582 24.582L58.605 477.983 34.023 453.4zM212.917 243.597c-10.164-11.756-24.058-17.25-37.995-15.305q-.268 14.407-.642 28.451-.416 17.101-.748 32.951l42.335-42.335a46.754 46.754 0 0 0-2.929-3.762zM189.735 415.31c7.236 3.773 19.591-3.42 28.686-10.11l10.132 13.765c-13.167 9.694-24.507 14.514-34.276 14.514a26.517 26.517 0 0 1-12.44-3.003c-9.62-5.034-15.882-15.647-19.773-31.786l-48.33 48.299c25.864 71.523 159.463 42.816 159.463-70.647 0-65.581 98.82-69.343 122.365-155.06 6.296-22.915 8.423-43.906 6.958-63.059L176.59 384.154c3.41 21.418 8.753 28.878 13.146 31.155zM157.18 256.337c.609-25.116 1.24-51.088 1.304-77.872.064-26.207 5.43-47.903 15.946-64.48a74.58 74.58 0 0 1 39.641-31.24c40.005-13.777 91.393 3.302 110.61 27.788l-13.446 10.56c-15.038-19.153-59.243-33.304-91.595-22.167-20.991 7.214-34.768 23.887-40.71 48.8a101.16 101.16 0 0 1 21.525-15.294c22.07-11.393 44.056-10.549 63.593 2.448l-9.47 14.236c-12.387-8.24-25.843-9.694-39.983-4.275-22.22 8.454-37.408 29.563-39.01 34.415q0 16.117-.321 31.797c18.789-1.785 37.15 5.824 50.607 21.375.77.898 1.518 1.807 2.234 2.726L376.078 87.169c-14.011-18.79-32.95-34.607-55.577-47.668-54.465-31.55-211.043-12.665-211.043 108.856V353.79l46.77-46.813c.15-15.444.514-32.33.952-50.639z"></path></g>
    </svg>
  )
  
  DeafenedIcon.displayName = 'DeafenedIcon'
  
  DeafenedIcon.defaultProps = {
    size: 24,
    color: 'none',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
  }
  
  export default DeafenedIcon
import { Grid, Text } from "../../../components/primitives";
import { Tag } from "../../../components/Tag";
import { withTooltip } from "../../../components/Tooltip";
import { Location, isTokenTemplate } from "../../../store";
import {
    Ability,
    AppliedAbilityEffect,
    AppliedAbilityEffectChoices,
    DnD5EAnnotation,
    DnD5EToken,
    DnD5ETokenTemplate,
    fromRuleKey,
    ModifiedValue,
    MonsterFilter,
} from "../common";
import styled from "@emotion/styled";
import {
    Armor,
    filterItem,
    isArmor,
    isWeapon,
    ItemType,
    ResolvedInventoryItem,
    ResolvedInventoryWeapon,
    ResolvedItem,
    ResolvedWeapon,
} from "../items";
import {
    CharacterClass,
    CharacterRuleSet,
    CharacterSubclass,
    CreatureCondition,
    Feature,
    getMultiattackOption,
    getUnarmedWeapon,
    isCharacter,
    isMonster,
    MonsterAbility,
    ResolvedCharacter,
    ResolvedMonster,
    resolveModifiedValue,
} from "../creature";
import { CustomSvg, useVttApp } from "../../../components/common";
import MultiattackIcon from "../../../components/icons/weapons/Multiattack";
import ClawIcon from "../../../components/icons/weapons/Claw";
import TailIcon from "../../../components/icons/weapons/Tail";
import BreathIcon from "../../../components/icons/weapons/Breath";
import DetectIcon from "../../../components/icons/Detect";
import WingIcon from "../../../components/icons/weapons/Wing";
import FrightenedIcon from "../../../components/icons/Frightened";
import BeakIcon from "../../../components/icons/weapons/Beak";
import RockIcon from "../../../components/icons/weapons/Rock";
import FlameIcon from "../../../components/icons/weapons/Flame";
import ShieldBashIcon from "../../../components/icons/weapons/ShieldBash";
import ParryIcon from "../../../components/icons/weapons/Parry";
import TalonsIcon from "../../../components/icons/weapons/Talons";
import CombatIcon from "../../../components/icons/Combat";
import AxeIcon from "../../../components/icons/weapons/Axe";
import CrossbowIcon from "../../../components/icons/weapons/Crossbow";
import BowIcon from "../../../components/icons/weapons/Bow";
import WarhammerIcon from "../../../components/icons/weapons/Warhammer";
import ClubIcon from "../../../components/icons/weapons/Club";
import MaceIcon from "../../../components/icons/weapons/Mace";
import NetIcon from "../../../components/icons/weapons/Net";
import SpearIcon from "../../../components/icons/weapons/Spear";
import GreatswordIcon from "../../../components/icons/weapons/Greatsword";
import DaggerIcon from "../../../components/icons/weapons/Dagger";
import SwordIcon from "../../../components/icons/weapons/Sword";
import FistIcon from "../../../components/icons/weapons/Fist";
import PunchIcon from "../../../components/icons/Punch";
import HoofIcon from "../../../components/icons/weapons/Hoof";
import HornIcon from "../../../components/icons/weapons/Horn";
import BiteIcon from "../../../components/icons/weapons/Bite";
import PouchIcon from "../../../components/icons/Pouch";
import { icons } from "../../../components/icons";
import { Spell } from "../spells";
import LightArmorIcon from "../../../components/icons/armor/LightArmor";
import MediumArmorIcon from "../../../components/icons/armor/MediumArmor";
import HeavyArmorIcon from "../../../components/icons/armor/HeavyArmor";
import { useDispatch, useLocalGrid, useLocationLevel } from "../../../components/contexts";
import { getAnnotationTemplate } from "../abilities";
import { TargettedAnnotation } from "../../../annotations";
import { performAbility, setMultiattackOption } from "../actions/token";
import { keyedListToArray, keyedListToKeyArray } from "../../../common";
import StaffIcon from "../../../components/icons/weapons/Staff";
import TusksIcon from "../../../components/icons/weapons/Tusks";
import ArrowsIcon from "../../../components/icons/weapons/Arrows";
import create from "zustand";
import DashIcon from "../../../components/icons/Dash";
import DodgeIcon from "../../../components/icons/Dodge";

export const TooltipGrid = withTooltip(Grid);
export const TooltipText = withTooltip(Text);
export const TooltipTag = withTooltip(Tag);

export const TooltipHostGrid = styled(Grid)`
    > div > div {
        height: 100%;
        width: 100%;
    }
`;

export function getValueTooltip(
    value: number,
    name?: string,
    attackModifiers?: {
        advantages: { content: string; condition?: string }[];
        disadvantages: { content: string; condition?: string }[];
        extraRolls: { roll: string; reason: string; source?: AppliedAbilityEffect }[];
        autoCrit: boolean;
        advantage?: "adv" | "dis";
    }
) {
    const tooltipLines: string[] = [];
    if (value !== 0) {
        tooltipLines.push(`${name ?? "Base"} ${value > 0 ? "+" + value : value}`);
    }

    if (attackModifiers) {
        addAttackModifiersTooltips(tooltipLines, attackModifiers);
    }

    return tooltipLines.join("\\a ");
}

export function getModifiedValueTooltip(
    modifiedValue: ModifiedValue,
    attackModifiers?: {
        advantages: { content: string; condition?: string }[];
        disadvantages: { content: string; condition?: string }[];
        extraRolls: { roll: string; reason: string; source?: AppliedAbilityEffect }[];
        autoCrit: boolean;
        advantage?: "adv" | "dis";
    }
) {
    const tooltipLines =
        modifiedValue.modifiers.length > 0
            ? modifiedValue.modifiers.map(o => `${o.name} ${o.value >= 0 ? "+" + o.value : o.value}`)
            : [];
    if (modifiedValue.baseValue !== 0) {
        tooltipLines.splice(0, 0, `Base ${modifiedValue.baseValue}`);
    }

    if (modifiedValue.extraRolls) {
        for (let i = 0; i < modifiedValue.extraRolls.length; i++) {
            const extraRoll = modifiedValue.extraRolls[i];
            tooltipLines.push(
                `${extraRoll.reason} ${extraRoll.roll.startsWith("-") ? extraRoll.roll : "+" + extraRoll.roll}`
            );
        }
    }

    if (modifiedValue.multipliers) {
        for (let i = 0; i < modifiedValue.multipliers.length; i++) {
            const name = modifiedValue.multipliers[i].name;
            const multiplier = modifiedValue.multipliers[i].value;
            if (multiplier === 0) {
                tooltipLines.push(`${name}: reduced to 0`);
            } else if (multiplier === 0.5) {
                tooltipLines.push(`${name}: halved`);
            } else {
                tooltipLines.push(`${name}: reduced to ${Math.trunc(multiplier * 100)}%`);
            }
        }
    }

    if (attackModifiers) {
        addAttackModifiersTooltips(tooltipLines, attackModifiers);
    }

    return tooltipLines ? tooltipLines.join("\\a ") : undefined;
}

function addAttackModifiersTooltips(
    tooltipLines: string[],
    attackModifiers: {
        advantages: { content: string; condition?: string }[];
        disadvantages: { content: string; condition?: string }[];
        extraRolls: { roll: string; reason: string; source?: AppliedAbilityEffect }[];
        autoCrit: boolean;
        advantage?: "adv" | "dis";
    }
) {
    if (attackModifiers.extraRolls) {
        for (let i = 0; i < attackModifiers.extraRolls.length; i++) {
            const extraRoll = attackModifiers.extraRolls[i];
            tooltipLines.push(
                `${extraRoll.reason} ${extraRoll.roll.startsWith("-") ? extraRoll.roll : "+" + extraRoll.roll}`
            );
        }
    }
}

export function renderIconForItem(item: ResolvedItem, size?: number) {
    if (item.type === ItemType.Ammunition) {
        return <ArrowsIcon />;
    }

    if (isWeapon(item)) {
        return renderIconForWeapon(item, size);
    }

    if (isArmor(item)) {
        return renderIconForArmor(item, size);
    }

    return <PouchIcon size={size as any} />;
}

export function renderIconForArmor(armor: Armor & ResolvedItem, size?: number) {
    switch (armor.type) {
        case ItemType.LightArmor:
            return <LightArmorIcon size={size as any} />;
        case ItemType.MediumArmor:
            return <MediumArmorIcon size={size as any} />;
        case ItemType.HeavyArmor:
            return <HeavyArmorIcon size={size as any} />;
    }
}

export function renderIconForWeapon(weapon: ResolvedWeapon & ResolvedItem, size?: number) {
    switch (weapon.archetype) {
        case "axe":
            return <AxeIcon size={size as any} />;
        case "bow":
            return <BowIcon size={size as any} />;
        case "crossbow":
            return <CrossbowIcon size={size as any} />;
        case "hammer":
            return <WarhammerIcon size={size as any} />;
        case "mace":
            return <MaceIcon size={size as any} />;
        case "net":
            return <NetIcon size={size as any} />;
        case "spear":
            return <SpearIcon size={size as any} />;
        case "sword":
            if (weapon.properties?.some(o => o.abbreviation === "2H")) {
                return <GreatswordIcon size={size as any} />;
            }

            return <SwordIcon size={size as any} />;
        default:
            if (weapon.name === "Unarmed Strike") {
                return <FistIcon size={size as any} />;
            }

            let nameLc = weapon.name.toLowerCase();
            if (nameLc.indexOf("dagger") >= 0 || nameLc.indexOf("stilleto") >= 0 || nameLc.indexOf("knife") >= 0) {
                return <DaggerIcon size={size as any} />;
            }

            if (nameLc.indexOf("club") >= 0) {
                return <ClubIcon size={size as any} />;
            }

            if (nameLc.indexOf("staff") >= 0) {
                return <StaffIcon size={size as any} />;
            }

            return <CombatIcon size={size as any} />;
    }
}

function findAbility(creature: ResolvedCharacter | ResolvedMonster, name: string) {
    let ability = creature.abilities?.[name];
    if (ability == null) {
        if (isCharacter(creature)) {
            // If this is a character, then we can check inactive (replaced) abilities or see if it has been transformed and look there.
            ability = creature.inactiveAbilities?.[name] ?? creature.transformedInto?.abilities?.["~" + name];
        } else {
            // If this is a monster, then it could either.
            if (creature.transformedFrom) {
                ability =
                    creature.transformedFrom?.abilities?.[name] ??
                    creature.abilities?.["~" + name] ??
                    (isCharacter(creature.transformedFrom)
                        ? creature.transformedFrom?.inactiveAbilities?.[name]
                        : undefined);
            }

            if (ability == null) {
                ability = creature.transformedInto?.abilities?.["~" + name];
            }
        }
    }

    return ability;
}

export function renderIconForEffect(
    effect: AppliedAbilityEffect & {
        source?: string;
        feature?: Feature;
        ability?: Ability;
        spell?: Spell;
        condition?: CreatureCondition;
    },
    size?: number
) {
    let icon = effect.ability?.icon ?? effect.condition?.icon;

    if (icon != null) {
        if (icons[icon]) {
            return icons[icon]({ size: size });
        } else {
            return <CustomSvg color="inherit" width={size} height={size} svg={icon} />;
        }
    }

    return undefined;
}

export function renderIconForAbility(
    ability: Ability,
    creature: ResolvedCharacter | ResolvedMonster,
    rules: CharacterRuleSet,
    size?: number
): { icon: JSX.Element; isToggled: boolean } {
    let icon: JSX.Element | undefined;
    let isToggled = !!keyedListToArray(ability.effects)?.some(o => o.endApplied);
    if (ability.icon) {
        if (icons[ability.icon]) {
            icon = icons[ability.icon]({ size: size });
        } else {
            const referencedAbility = findAbility(creature, ability.icon);
            let iconText: string | undefined = ability.icon;
            if (referencedAbility) {
                iconText = referencedAbility.icon;
            } else {
                const actionRef = fromRuleKey(ability.icon);
                if (actionRef) {
                    iconText = rules.actions.get(actionRef)?.icon;
                }
            }

            if (iconText) {
                icon = <CustomSvg color="inherit" width={size} height={size} svg={iconText} />;
            }
        }
    }

    // TODO: Build a dictionary of names and cache these?
    if (!icon) {
        const nameLc = ability.name.toLowerCase();
        if (nameLc.indexOf("multiattack") >= 0) {
            icon = <MultiattackIcon size={size as any} />;
        } else if (nameLc.indexOf("dash") >= 0) {
            icon = <DashIcon size={size as any} />;
        } else if (nameLc.indexOf("dodge") >= 0) {
            icon = <DodgeIcon size={size as any} />;
        } else if (nameLc.indexOf("bite") >= 0) {
            icon = <BiteIcon size={size as any} />;
        } else if (nameLc.indexOf("fist") >= 0 || nameLc.indexOf("slam") >= 0 || nameLc.indexOf("martial art") >= 0) {
            icon = <FistIcon size={size as any} />;
        } else if (nameLc.indexOf("hoof") >= 0 || nameLc.indexOf("hooves") >= 0) {
            icon = <HoofIcon size={size as any} />;
        } else if (nameLc.indexOf("horn") >= 0 || nameLc.indexOf("gore") >= 0) {
            icon = <HornIcon size={size as any} />;
        } else if (nameLc.indexOf("claw") >= 0 || nameLc.indexOf("rake") >= 0) {
            icon = <ClawIcon size={size as any} />;
        } else if (nameLc.indexOf("tail") >= 0) {
            icon = <TailIcon size={size as any} />;
        } else if (nameLc.indexOf("breath") >= 0) {
            icon = <BreathIcon size={size as any} />;
        } else if (nameLc.indexOf("detect") >= 0) {
            icon = <DetectIcon size={size as any} />;
        } else if (nameLc.indexOf("wing") >= 0) {
            icon = <WingIcon size={size as any} />;
        } else if (nameLc.indexOf("fright") >= 0 || nameLc.startsWith("turn")) {
            icon = <FrightenedIcon size={size as any} />;
        } else if (nameLc.indexOf("beak") >= 0) {
            icon = <BeakIcon size={size as any} />;
        } else if (nameLc.indexOf("talon") >= 0) {
            icon = <TalonsIcon size={size as any} />;
        } else if (nameLc.indexOf("tusk") >= 0) {
            icon = <TusksIcon size={size as any} />;
        } else if (nameLc.indexOf("rock") >= 0 || nameLc.indexOf("boulder") >= 0) {
            icon = <RockIcon size={size as any} />;
        } else if (nameLc.indexOf("shield bash") >= 0) {
            icon = <ShieldBashIcon size={size as any} />;
        } else if (nameLc.indexOf("parry") >= 0) {
            icon = <ParryIcon size={size as any} />;
        } else if (nameLc.indexOf("hammer") >= 0 || nameLc.indexOf("maul") >= 0) {
            icon = <WarhammerIcon size={size as any} />;
        } else if (nameLc.indexOf("axe") >= 0) {
            icon = <AxeIcon size={size as any} />;
        } else if (nameLc.indexOf("crossbow") >= 0) {
            icon = <CrossbowIcon size={size as any} />;
        } else if (nameLc.indexOf("bow") >= 0) {
            icon = <BowIcon size={size as any} />;
        } else if (nameLc.indexOf("mace") >= 0) {
            icon = <MaceIcon size={size as any} />;
        } else if (nameLc.indexOf("net") >= 0) {
            icon = <NetIcon size={size as any} />;
        } else if (nameLc.indexOf("spear") >= 0 || nameLc.indexOf("javelin") >= 0 || nameLc.indexOf("pike") >= 0) {
            icon = <SpearIcon size={size as any} />;
        } else if (nameLc.indexOf("sword") >= 0 || nameLc.indexOf("scimitar") >= 0) {
            icon = <SwordIcon size={size as any} />;
        } else if (nameLc.indexOf("dagger") >= 0 || nameLc.indexOf("stilleto") >= 0 || nameLc.indexOf("knife") >= 0) {
            icon = <DaggerIcon size={size as any} />;
        } else if (nameLc.indexOf("flame") >= 0 || nameLc.indexOf("fire") >= 0) {
            icon = <FlameIcon size={size as any} />;
        } else if (nameLc.indexOf("club") >= 0) {
            icon = <ClubIcon size={size as any} />;
        } else if (nameLc.indexOf("staff") >= 0) {
            icon = <StaffIcon size={size as any} />;
        }
    }

    if (!icon) {
        icon = <PunchIcon size={size as any} />;
    }

    return { isToggled: isToggled, icon: icon };
}

export function useWeaponAttackOptions(
    token: DnD5EToken | DnD5ETokenTemplate,
    creature: ResolvedMonster | ResolvedCharacter,
    ability: Ability | undefined,
    abilityKey: string,
    rules: CharacterRuleSet
): {
    doWeaponAttack?: (item: ResolvedInventoryItem & { handed?: 1 | 2 }) => void;
    items?: (ResolvedInventoryItem & { handed?: 1 | 2 })[];
} {
    const annotationPlacementTemplateChanged = useVttApp(state => state.annotationPlacementTemplateChanged);
    const { location, levelKey } = useLocationLevel();
    const grid = useLocalGrid();

    if (!ability || isTokenTemplate(token)) {
        return {};
    }

    const hasWeaponChoice = ability.weaponAttack != null;
    let items: (ResolvedInventoryItem & { handed?: 1 | 2 })[] | undefined;
    let doWeaponAttack: ((item: ResolvedInventoryItem & { handed?: 1 | 2 }) => void) | undefined;
    if (hasWeaponChoice && isCharacter(creature)) {
        const weapons = keyedListToArray(creature.inventory).filter(o => isWeapon(o));
        if (weapons.length === 0) {
            weapons.push(getUnarmedWeapon(creature, rules));
        } else if (creature.itemModifiers) {
            const unarmedWeapon = getUnarmedWeapon(creature, rules);
            if (creature.itemModifiers.some(o => filterItem(unarmedWeapon, o.filter, rules.items))) {
                weapons.push(unarmedWeapon);
            }
        }

        const itemFilter = ability.weaponAttack?.weapon;
        const filter = (item: ResolvedInventoryItem & { handed?: 1 | 2 }) => {
            if (itemFilter && !filterItem(item, itemFilter, rules.items)) {
                return false;
            }

            if (ability.weaponAttack!.excludeAttackWeapon) {
                const attacks = creature.combatTurn?.attacks;
                const attackKeys = attacks ? Object.keys(attacks) : undefined;
                if (attackKeys && attackKeys.indexOf(item.id) >= 0) {
                    return false;
                }
            }

            return true;
        };
        items = weapons.filter(filter);

        // Duplicate any versatile weapons so that we can choose between 1h and 2h.
        for (let i = 0; i < items.length; i++) {
            if (items[i].properties?.find(o => o.abbreviation === "V")) {
                // Found a versatile weapon. Split it into two different items so that we can choose between 1h and 2h.
                const clone1 = Object.assign({}, items[i]);
                const clone2 = Object.assign({}, items[i]);
                clone1.name = clone1.name + " (1H)";
                clone1.handed = 1;
                clone2.name = clone2.name + " (2H)";
                clone2.handed = 2;
                items[i] = clone1;
                items.splice(i + 1, 0, clone2);
                i++;
            }
        }

        doWeaponAttack = item => {
            // A weapon attack should always produce a template, so don't worry about if it doesn't.
            const template = getAnnotationTemplate(
                location as Location,
                levelKey!,
                creature,
                token,
                grid,
                ability,
                abilityKey
            );
            if (template) {
                const annotation = (template.annotation as DnD5EAnnotation).dnd5e;

                annotation.item = (item as ResolvedInventoryItem).id;
                annotation.handed = item.handed ?? 1;
                annotation.attackModifier = resolveModifiedValue(
                    (item as ResolvedInventoryWeapon & ResolvedInventoryItem).hitBonus
                );

                annotationPlacementTemplateChanged.trigger(template);
            }
        };
    }

    return { doWeaponAttack, items };
}

export function useDispatchAbility(
    token: DnD5EToken | DnD5ETokenTemplate,
    creature: ResolvedCharacter | ResolvedMonster
) {
    const { campaign, location, levelKey } = useLocationLevel();
    const dispatch = useDispatch();
    const grid = useLocalGrid();
    const annotationPlacementTemplateChanged = useVttApp(state => state.annotationPlacementTemplateChanged);

    if (isTokenTemplate(token)) {
        return null;
    }

    return (ability: Ability, abilityKey: string, choices?: { [effectKey: string]: AppliedAbilityEffectChoices }) => {
        const isMonsterMultiattack = isMonster(creature) && (ability as MonsterAbility).attackOptions;
        if (isMonsterMultiattack) {
            const multiattackOptions = keyedListToKeyArray((ability as MonsterAbility).attackOptions!);
            if (multiattackOptions?.length === 1) {
                dispatch(setMultiattackOption(campaign, location, token, abilityKey, multiattackOptions[0]));
            }
        } else {
            const template = getAnnotationTemplate(
                location as Location,
                levelKey!,
                creature,
                token,
                grid,
                ability,
                abilityKey
            );
            if (template) {
                if (
                    isMonster(creature) &&
                    ability.isAttackAction &&
                    creature.combatTurn?.attackAbility &&
                    creature.combatTurn?.attackOption &&
                    template.annotation.type === "target" &&
                    (template.annotation as TargettedAnnotation).maxTargets === 1
                ) {
                    // If the annotation is targetting a single enemy, and it's part of a multiattack, then we can allow as many
                    // instances as are necessary to fill that multiattack.
                    const multiattack = getMultiattackOption(creature, ability);
                    if (multiattack?.option) {
                        (template.annotation as TargettedAnnotation).maxTargets =
                            multiattack.amount! - multiattack.used!;
                    }
                }

                annotationPlacementTemplateChanged.trigger(template);
            } else {
                dispatch(performAbility(campaign, location, token, abilityKey, choices));
            }
        }
    };
}

export enum CompendiumPage {
    Classes = "Classes",
    Backgrounds = "Backgrounds",
    Feats = "Feats",
    Items = "Items",
    Monsters = "Monsters",
    Races = "Races",
    Spells = "Spells",
}

interface CompendiumSpellFilter {
    characterClass?: CharacterClass;
    characterSubclass?: CharacterSubclass;
    level?: number;
    levelMode?: "equal" | "max";
}

interface CompendiumState {
    page: CompendiumPage;
    setPage: (page: CompendiumPage) => void;

    monsterFilter?: MonsterFilter;
    setMonsterFilter: (filter: MonsterFilter | undefined) => void;

    spellFilter?: CompendiumSpellFilter;
    setSpellFilter: (filter: CompendiumSpellFilter | undefined) => void;
}

export const useCompendium = create<CompendiumState>(set => ({
    page: CompendiumPage.Classes,
    setPage: page =>
        set(() => {
            return {
                page: page,
            };
        }),

    setMonsterFilter: filter => set(() => ({ monsterFilter: filter })),

    setSpellFilter: filter => set(() => ({ spellFilter: filter })),
}));

import { createTokensOrTokenTemplatesAction, TokensAction, TokenTemplatesAction } from "./common";
import { Campaign, LocationSummary, Token, TokenTemplate, WithLevel } from "../store";
import { LibraryItem } from "../library";
import { GridPosition, LocalPixelPosition } from "../position";

export function modifyToken(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    token: Token | TokenTemplate,
    props: Partial<Token>,
    modifyTemplate?: boolean
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction(
        "ModifyToken",
        props,
        campaignId,
        location,
        [token],
        undefined,
        !modifyTemplate
    );
}

export function modifyTokens(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    props: Partial<Token>
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("ModifyToken", props, campaignId, location, tokens, undefined, true);
}

export function setTokenImage(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    libraryItem: LibraryItem | undefined
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("SetTokenImage", libraryItem, campaignId, location, tokens);
}

export function removeTokenImage(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    uri: string
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("RemoveTokenImage", uri, campaignId, location, tokens);
}

export function setPortraitImage(
    campaignId: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    libraryItem: LibraryItem | undefined
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("SetPortraitImage", libraryItem, campaignId, location, tokens);
}

export function moveToken(
    campaignId: Campaign | string,
    location: LocationSummary | string,
    token: Token,
    path: WithLevel<GridPosition | LocalPixelPosition>[],
    cost: number,
    requiresApproval: boolean,
    rotation?: number
) {
    return createTokensOrTokenTemplatesAction(
        "MoveToken",
        { path, cost, requiresApproval, rotation },
        campaignId,
        location,
        [token],
        undefined,
        true
    );
}

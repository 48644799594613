import {
    LocationAction,
    AnnotationAction,
    LocationPlaylistAction,
    LocationTrackAction,
    ZoneAction,
    ActionScope,
} from "./common";
import { Annotation } from "../annotations";
import {
    Token,
    Location,
    AudioTrack,
    AudioType,
    AudioTrackWithOptionalId,
    Zone,
    LocationLevel,
    Campaign,
    LocationSummary,
} from "../store";
import { ILocalGrid } from "../grid";
import { LocalPixelPosition, Size } from "../position";

export function modifyLocation(campaignId: string, locationId: string, location: Partial<Location>): LocationAction {
    return {
        type: "ModifyLocation",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
        payload: location,
    };
}

export function modifyLocationLevel(
    campaignId: string,
    locationId: string,
    levelId: string,
    level: Partial<LocationLevel>
) {
    return {
        type: "ModifyLocationLevel",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            levelId: levelId,
        },
        payload: level,
    };
}

export function applyGridPlacement(
    campaignId: string,
    locationId: string,
    levelId: string,
    gridPlacement: { tileSize?: Size; backgroundImagePos?: LocalPixelPosition }
) {
    return {
        type: "ApplyGridPlacement",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            levelId: levelId,
        },
        payload: gridPlacement,
    };
}

export function addToken(campaignId: string, locationId: string, token: Token): LocationAction {
    return {
        type: "AddToken",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
        payload: token,
    };
}

export function deleteItems(
    campaignId: string,
    locationId: string,
    items: (Token | Annotation | Zone | string)[]
): LocationAction {
    return {
        type: "DeleteItems",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            scope: ActionScope.Tokens | ActionScope.Annotations,
        },
        payload: items.map(o => (typeof o === "string" ? o : o.id)),
    };
}

export function addAnnotation(campaignId: string, locationId: string, annotation: Annotation): LocationAction {
    return {
        type: "AddAnnotation",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
        payload: annotation,
    };
}

export function modifyAnnotation<T extends Annotation = Annotation>(
    campaignId: string,
    locationId: string,
    annotationId: string,
    annotation: Partial<T>
): AnnotationAction {
    return {
        type: "ModifyAnnotation",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            annotationId: annotationId,
        },
        payload: annotation,
    };
}

export function addZone(campaignId: string, locationId: string, zone: Zone): LocationAction {
    return {
        type: "AddZone",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
        payload: zone,
    };
}

export function convertToZone(
    campaignId: string,
    locationId: string,
    annotationId: string,
    grid: ILocalGrid
): AnnotationAction {
    return {
        type: "ConvertToZone",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            annotationId: annotationId,
        },
        payload: grid,
    };
}

export function modifyZone(
    campaignId: string | Campaign,
    locationId: string | LocationSummary,
    zoneId: string | Zone,
    zone: Partial<Zone>
): ZoneAction {
    return {
        type: "ModifyZone",
        props: {
            campaignId: typeof campaignId === "string" ? campaignId : campaignId.id,
            locationId: typeof locationId === "string" ? locationId : locationId.id,
            zoneId: typeof zoneId === "string" ? zoneId : zoneId.id,
        },
        payload: zone,
    };
}

export function addTrack(
    campaignId: string,
    locationId: string,
    playlistType: AudioType,
    track: Omit<AudioTrackWithOptionalId, "index">
): LocationPlaylistAction {
    return {
        type: "AddAudioTrack",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            playlistType: playlistType,
        },
        payload: track,
    };
}

export function modifyTrack(
    campaignId: string,
    locationId: string,
    playlistType: AudioType,
    trackId: string,
    track: Partial<Omit<AudioTrack, "index">>
): LocationTrackAction {
    return {
        type: "ModifyAudioTrack",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            playlistType: playlistType,
            trackId: trackId,
        },
        payload: track,
    };
}

export function removeTrack(
    campaignId: string,
    locationId: string,
    playlistType: AudioType,
    trackId: string
): LocationPlaylistAction {
    return {
        type: "RemoveAudioTrack",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            playlistType: playlistType,
        },
        payload: trackId,
    };
}

export function moveTrack(
    campaignId: string,
    locationId: string,
    playlistType: AudioType,
    trackId: string,
    newIndex: number
) {
    return {
        type: "MoveAudioTrack",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            playlistType: playlistType,
        },
        payload: { trackId: trackId, newIndex: newIndex },
    };
}

export function reorderTracks(campaignId: string, locationId: string, playlistType: AudioType, ids: string[]) {
    return {
        type: "ReorderAudioTracks",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            playlistType: playlistType,
        },
        payload: ids,
    };
}

export function playNextTrack(
    campaignId: string,
    locationId: string,
    playlistType: AudioType,
    currentCount: number
): LocationPlaylistAction {
    return {
        type: "PlayNextTrack",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            playlistType: playlistType,
            trackCount: currentCount,
        },
    };
}

export function playTrack(
    campaignId: string,
    locationId: string,
    playlistType: AudioType,
    trackId: string,
    currentCount: number
): LocationPlaylistAction {
    return {
        type: "PlayTrack",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            playlistType: playlistType,
            trackCount: currentCount,
        },
        payload: trackId,
    };
}

export function addCombatParticipants(campaignId: string, locationId: string, participants: string[]): LocationAction {
    return {
        type: "AddCombatParticipants",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
        payload: participants,
    };
}

export function removeCombatParticipants(
    campaignId: string,
    locationId: string,
    participants: string[]
): LocationAction {
    return {
        type: "RemoveCombatParticipants",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            scope: ActionScope.Tokens,
        },
        payload: participants,
    };
}

export function nextCombatTurn(campaignId: string, locationId: string): LocationAction {
    return {
        type: "NextCombatTurn",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            scope: ActionScope.Annotations | ActionScope.Tokens,
        },
    };
}

export function previousCombatTurn(campaignId: string, locationId: string): LocationAction {
    return {
        type: "PreviousCombatTurn",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
    };
}

export function endCombat(campaignId: string, locationId: string): LocationAction {
    return {
        type: "EndCombat",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            scope: ActionScope.Tokens,
        },
    };
}

export function setCombatInitiative(
    campaignId: string,
    locationId: string,
    token: string | Token,
    initiative: number[]
): LocationAction {
    const tokenId = typeof token === "string" ? token : token.id;
    return {
        type: "SetCombatInitiative",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
        payload: {
            tokenId: tokenId,
            initiative: initiative,
        },
    };
}

export function addLevel(campaign: Campaign | string, location: LocationSummary | string, level: LocationLevel) {
    const campaignId = typeof campaign === "string" ? campaign : campaign.id;
    const locationId = typeof location === "string" ? location : location.id;
    return {
        type: "AddLevel",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
        payload: level,
    };
}

export function deleteLevel(campaign: Campaign | string, location: LocationSummary | string, key: string) {
    const campaignId = typeof campaign === "string" ? campaign : campaign.id;
    const locationId = typeof location === "string" ? location : location.id;
    return {
        type: "DeleteLevel",
        props: {
            campaignId: campaignId,
            locationId: locationId,
            scope: ActionScope.Tokens | ActionScope.Annotations | ActionScope.CampaignPlayers,
        },
        payload: key,
    };
}

export function reorderLevels(campaign: Campaign | string, location: LocationSummary | string, keys: string[]) {
    const campaignId = typeof campaign === "string" ? campaign : campaign.id;
    const locationId = typeof location === "string" ? location : location.id;
    return {
        type: "ReorderLevels",
        props: {
            campaignId: campaignId,
            locationId: locationId,
        },
        payload: keys,
    };
}

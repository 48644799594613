import React, { FunctionComponent, useState, useCallback, useEffect } from "react";
import { RectAnnotation, Annotation, getAnnotationPos } from "../../annotations";
import { LocalPixelPosition } from "../../position";
import { useDispatch, useScale, useLocalGrid, useTokenOverrides, useValidatedLocationLevel } from "../contexts";
import { getPlayerColorPalette, getThemeColorPalette } from "../../design/utils";
import { theme } from "../../design";
import { DistanceMessage } from "./DistanceMessage";
import { translate, localPoint, rotate } from "../../grid";
import { AnnotationHandle } from "./AnnotationHandle";
import { modifyAnnotation } from "../../actions/location";
import { DraggableRect } from "./three2d/Rect";
import { HoverTracking, ZIndexes } from "./common";
import { DraggableLine, Line } from "./three2d/Line";
import { Token } from "../../store";
import { ThreeEvent } from "@react-three/fiber";
import { defaultLineWidth, interactiveLineWidth } from "../common";
import { AnimatePresence, usePresence } from "framer-motion";
import { usePreviousValue } from "../utils";
import { UiLayer } from "./UiLayer";
import { SelectionType } from "../selection";

interface RectAnnotationNodeProps extends HoverTracking {
    rect: RectAnnotation;
    activePoint?: LocalPixelPosition;
    isSelected: SelectionType;
    wasSelected: boolean;
    disabled?: boolean;
    snapPoint: (point: LocalPixelPosition, filter?: (o: Annotation) => boolean) => LocalPixelPosition;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    onPointerDown?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onPointerUp?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onOverrideAnnotation: (id: string, override: Partial<RectAnnotation> | undefined) => void;
    animateExit?: boolean;
}

const RectAnnotationNodeCore: FunctionComponent<RectAnnotationNodeProps> = ({
    rect,
    activePoint,
    isSelected,
    snapPoint,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideAnnotation,
    disabled,
    isHovering,
    setHoverPart,
    wasSelected,
    animateExit,
}) => {
    const dispatch = useDispatch();
    const { campaign, location, levelKey } = useValidatedLocationLevel();
    const grid = useLocalGrid();
    const scale = useScale();
    const { tokenOverrides } = useTokenOverrides();

    const showHandles = isSelected && !disabled && !rect.disableEdit;

    // We need to manage presence manually because of the AnnotationHandles being in their own AnimatePresence - they need
    // to be given a chance to animate out.
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        if (!isPresent && !animateExit && !showHandles) {
            safeToRemove?.();
        }
    }, [isPresent, safeToRemove, animateExit, showHandles]);

    const [isResizing, setIsResizing] = useState(false);

    const pos = getAnnotationPos(rect, campaign, location, grid, tokenOverrides);
    let width = rect.width;
    let height = rect.height;
    if (activePoint) {
        const p = activePoint;
        width = p.x - pos.x;
        height = p.y - pos.y;
    }

    let colours = disabled ? getThemeColorPalette("grayscale") : getPlayerColorPalette(campaign, rect.userId);

    const gridWidth = location.tileSize.width > 0 ? width / location.tileSize.width : 0;
    const gridHeight = location.tileSize.height > 0 ? height / location.tileSize.height : 0;

    const r = rect.rotation;

    const onAnnotationClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, rect);
            }
        },
        [rect, onClick]
    );

    const snapPointRect = useCallback(
        (point: LocalPixelPosition) => {
            return snapPoint(point, o => o.id !== rect.id);
        },
        [snapPoint, rect.id]
    );
    const onDragMove = useCallback(
        (e: ThreeEvent<PointerEvent>, p: LocalPixelPosition) => {
            p.x -= width / 2;
            p.y -= height / 2;
            onOverrideAnnotation(rect.id, {
                pos: { ...p, level: rect.pos?.level ?? levelKey },
            });
        },
        [rect.id, width, height, onOverrideAnnotation, rect.pos?.level, levelKey]
    );
    const onDragEnd = useCallback(() => {
        dispatch(
            modifyAnnotation<RectAnnotation>(campaign.id, location.id, rect.id, {
                pos: rect.pos,
            })
        );
        onOverrideAnnotation(rect.id, undefined);
    }, [dispatch, location, rect, campaign, onOverrideAnnotation]);
    const dragBoundFunc = useCallback(
        (v: LocalPixelPosition) => {
            v = translate(v, { x: -width / 2, y: -height / 2 });
            const p = snapPointRect(v);
            return translate(p, { x: width / 2, y: height / 2 });
        },
        [width, height, snapPointRect]
    );

    const isHover = onClick && (isHovering || !!activePoint);
    const wasFilled = usePreviousValue(!!rect.isFilled) ?? rect.isFilled;

    const points = [
        { x: -width / 2, y: -height / 2 },
        { x: width / 2, y: -height / 2 },
        { x: width / 2, y: height / 2 },
        { x: -width / 2, y: height / 2 },
    ];
    const disableDrag = onClick == null || disabled || !!rect.centerOn;
    const unselectedLineColor = isHover ? colours[4] : colours[3];
    const opacity = disabled ? 0.25 : 0.5;
    return (
        <>
            {(isPresent || animateExit) && (
                <>
                    <AnimatePresence>
                        {rect.isFilled && isPresent && (
                            <DraggableRect
                                x={pos.x + width / 2}
                                y={pos.y + height / 2}
                                width={width}
                                height={height}
                                animateEnterExit="nolayout"
                                initial={
                                    !wasFilled
                                        ? undefined
                                        : isSelected
                                        ? { color: colours[isHover ? 1 : 0], opacity, scale: 1 }
                                        : wasSelected
                                        ? { color: colours[1], opacity, scale: 1 }
                                        : undefined
                                }
                                exit={animateExit ? undefined : null}
                                zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Tokens - 0.01}
                                color={colours[isHover || isSelected ? 1 : 0]}
                                opacity={opacity}
                                rotation={r}
                                onPointerOver={() => setHoverPart?.("fill", true)}
                                onPointerOut={() => setHoverPart?.("fill", false)}
                                dragBoundFunc={dragBoundFunc}
                                onPointerDown={onPointerDown ? e => onPointerDown(e, rect) : undefined}
                                onPointerUp={onPointerUp ? e => onPointerUp(e, rect) : undefined}
                                cursor={disableDrag ? undefined : "pointer"}
                                disableDrag={disableDrag}
                                onDragMove={onDragMove}
                                onDragEnd={onDragEnd}
                                onClick={disabled || !onClick ? undefined : onAnnotationClick}
                            />
                        )}
                    </AnimatePresence>
                    <DraggableLine
                        x={pos.x + width / 2}
                        y={pos.y + height / 2}
                        opacity={0}
                        closed
                        zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                        rotation={r}
                        width={defaultLineWidth + interactiveLineWidth}
                        points={points}
                        onPointerOver={() => setHoverPart?.("line", true)}
                        onPointerOut={() => setHoverPart?.("line", false)}
                        dragBoundFunc={dragBoundFunc}
                        cursor={disableDrag ? undefined : "pointer"}
                        disableDrag={disableDrag}
                        onDragMove={onDragMove}
                        onDragEnd={onDragEnd}
                        onClick={disabled ? undefined : onAnnotationClick}
                        onPointerDown={onPointerDown ? e => onPointerDown(e, rect) : undefined}
                        onPointerUp={onPointerUp ? e => onPointerUp(e, rect) : undefined}
                    />
                    <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                        {isPresent && (
                            <Line
                                x={pos.x + width / 2}
                                y={pos.y + height / 2}
                                closed
                                zIndex={
                                    (isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations) + 0.01
                                }
                                rotation={r}
                                animateEnterExit="nolayout"
                                initial={
                                    isSelected || activePoint
                                        ? { color: unselectedLineColor, opacity: 1, scale: 1 }
                                        : wasSelected
                                        ? {
                                              color: theme.colors.guidance.focus,
                                              opacity: 1,
                                              scale: 1,
                                          }
                                        : undefined
                                }
                                exit={animateExit ? undefined : null}
                                color={
                                    isSelected === SelectionType.Primary
                                        ? theme.colors.guidance.focus
                                        : unselectedLineColor
                                }
                                width={defaultLineWidth}
                                points={points}
                            />
                        )}
                    </AnimatePresence>
                </>
            )}
            <UiLayer>
                <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                    {showHandles && isPresent && (
                        <>
                            <AnnotationHandle
                                x={pos.x + width / 2}
                                y={pos.y + height / 2}
                                offsetX={-width / 2}
                                offsetY={-height / 2}
                                rotation={r}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPointRect}
                                disableDrag={onClick == null}
                                onDragMove={pr => {
                                    // Unrotate the drag point to get where the point is relative to the shape's position.
                                    const originalCenter = localPoint(pos.x + width / 2, pos.y + height / 2);
                                    const p = rotate(pr, originalCenter, r != null ? -r : 0);

                                    // Calculate the new width and height by comparing the unrotated point against the position.
                                    const w = pos.x + width - p.x;
                                    const h = pos.y + height - p.y;

                                    // Calculate the new center position that keeps the rotated bottom right point in the same place.
                                    let center = localPoint(
                                        originalCenter.x - (w - width) / 2,
                                        originalCenter.y - (h - height) / 2
                                    );
                                    center = rotate(center, originalCenter, r != null ? r : 0);
                                    center.x -= w / 2;
                                    center.y -= h / 2;
                                    onOverrideAnnotation(rect.id, {
                                        width: w,
                                        height: h,
                                        pos: { ...center, level: rect.pos?.level ?? levelKey },
                                    });
                                    setIsResizing(true);
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<RectAnnotation>(campaign.id, location.id, rect.id, {
                                            pos: rect.pos,
                                            width: rect.width,
                                            height: rect.height,
                                        })
                                    );
                                    onOverrideAnnotation(rect.id, undefined);
                                    setIsResizing(false);
                                }}
                            />
                            <AnnotationHandle
                                x={pos.x + width / 2}
                                y={pos.y + height / 2}
                                offsetX={width / 2}
                                offsetY={-height / 2}
                                rotation={r}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPointRect}
                                disableDrag={onClick == null}
                                onDragMove={pr => {
                                    // Unrotate the drag point to get where the point is relative to the shape's position.
                                    const originalCenter = localPoint(pos.x + width / 2, pos.y + height / 2);
                                    const p = rotate(pr, originalCenter, r != null ? -r : 0);

                                    // Calculate the new width and height by comparing the unrotated point against the position.
                                    const w = p.x - pos.x;
                                    const h = pos.y + height - p.y;

                                    // Calculate the new center position that keeps the rotated bottom right point in the same place.
                                    let center = localPoint(
                                        originalCenter.x + (w - width) / 2,
                                        originalCenter.y - (h - height) / 2
                                    );
                                    center = rotate(center, originalCenter, r != null ? r : 0);
                                    center.x -= w / 2;
                                    center.y -= h / 2;
                                    onOverrideAnnotation(rect.id, {
                                        width: w,
                                        height: h,
                                        pos: { ...center, level: rect.pos?.level ?? levelKey },
                                    });
                                    setIsResizing(true);
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<RectAnnotation>(campaign.id, location.id, rect.id, {
                                            pos: rect.pos,
                                            width: rect.width,
                                            height: rect.height,
                                        })
                                    );
                                    onOverrideAnnotation(rect.id, undefined);
                                    setIsResizing(false);
                                }}
                            />
                            <AnnotationHandle
                                x={pos.x + width / 2}
                                y={pos.y + height / 2}
                                offsetX={width / 2}
                                offsetY={height / 2}
                                rotation={r}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPointRect}
                                disableDrag={onClick == null}
                                onDragMove={pr => {
                                    // Unrotate the drag point to get where the point is relative to the shape's position.
                                    const originalCenter = localPoint(pos.x + width / 2, pos.y + height / 2);
                                    const p = rotate(pr, originalCenter, r != null ? -r : 0);

                                    // Calculate the new width and height by comparing the unrotated point against the position.
                                    const w = p.x - pos.x;
                                    const h = p.y - pos.y;

                                    // Calculate the new center position that keeps the rotated top left point in the same place.
                                    let center = localPoint(
                                        originalCenter.x + (w - width) / 2,
                                        originalCenter.y + (h - height) / 2
                                    );
                                    center = rotate(center, originalCenter, r != null ? r : 0);
                                    center.x -= w / 2;
                                    center.y -= h / 2;
                                    onOverrideAnnotation(rect.id, {
                                        width: w,
                                        height: h,
                                        pos: { ...center, level: rect.pos?.level ?? levelKey },
                                    });
                                    setIsResizing(true);
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<RectAnnotation>(campaign.id, location.id, rect.id, {
                                            pos: rect.pos,
                                            width: rect.width,
                                            height: rect.height,
                                        })
                                    );
                                    onOverrideAnnotation(rect.id, undefined);
                                    setIsResizing(false);
                                }}
                            />
                            <AnnotationHandle
                                x={pos.x + width / 2}
                                y={pos.y + height / 2}
                                offsetX={-width / 2}
                                offsetY={height / 2}
                                rotation={r}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPointRect}
                                disableDrag={onClick == null}
                                onDragMove={pr => {
                                    // Unrotate the drag point to get where the point is relative to the shape's position.
                                    const originalCenter = localPoint(pos.x + width / 2, pos.y + height / 2);
                                    const p = rotate(pr, originalCenter, r != null ? -r : 0);

                                    // Calculate the new width and height by comparing the unrotated point against the position.
                                    const w = pos.x + width - p.x;
                                    const h = p.y - pos.y;

                                    // Calculate the new center position that keeps the rotated top left point in the same place.
                                    let center = localPoint(
                                        originalCenter.x - (w - width) / 2,
                                        originalCenter.y + (h - height) / 2
                                    );
                                    center = rotate(center, originalCenter, r != null ? r : 0);
                                    center.x -= w / 2;
                                    center.y -= h / 2;
                                    onOverrideAnnotation(rect.id, {
                                        width: w,
                                        height: h,
                                        pos: { ...center, level: rect.pos?.level ?? levelKey },
                                    });
                                    setIsResizing(true);
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<RectAnnotation>(campaign.id, location.id, rect.id, {
                                            pos: rect.pos,
                                            width: rect.width,
                                            height: rect.height,
                                        })
                                    );
                                    onOverrideAnnotation(rect.id, undefined);
                                    setIsResizing(false);
                                }}
                            />
                        </>
                    )}
                </AnimatePresence>
            </UiLayer>
            {(activePoint || isResizing) && (
                <DistanceMessage
                    distance={gridWidth}
                    pos={rotate(
                        translate(pos, {
                            x: width / 2,
                            y: height + theme.space[1] / scale,
                        }),
                        localPoint(pos.x + width / 2, pos.y + height / 2),
                        r != null ? r : 0
                    )}
                    horizontalAlignment="center"
                />
            )}
            {(activePoint || isResizing) && (
                <DistanceMessage
                    distance={gridHeight}
                    pos={rotate(
                        translate(pos, {
                            x: width + theme.space[1] / scale,
                            y: height / 2,
                        }),
                        localPoint(pos.x + width / 2, pos.y + height / 2),
                        r != null ? r : 0
                    )}
                    verticalAlignment="center"
                />
            )}
        </>
    );
};

export const RectAnnotationNode = React.memo(RectAnnotationNodeCore);

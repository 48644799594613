import React, { useMemo } from "react";
import { CampaignSummary } from "../../store";
import { AvatarProps } from "../Avatar";
import { useLocalSetting } from "../utils";
import { LocalSetting } from "../../common";
import { Stack } from "../primitives";
import { CampaignSummaryThumbnail } from "./CampaignSummaryThumbnail";
import { CampaignSummaryHeader } from "./CampaignSummaryHeader";
import { Spacer } from "../Spacer";
import { CampaignSummaryFooter } from "./CampaignSummaryFooter";

export type CampaignSummaryDetailsProps = {
    full?: boolean;
    campaign: CampaignSummary;
};

const randomColours: Array<string> = ["blues", "cyans", "greens", "yellows", "oranges", "reds", "purples", "violets"];

export const CampaignSummaryDetails = ({ full, campaign }: CampaignSummaryDetailsProps) => {
    const screenshotSetting = useMemo(() => new LocalSetting(campaign.id + ":screenshot"), [campaign.id]);
    const [screenshot] = useLocalSetting(screenshotSetting);

    const avatarData = useMemo<AvatarProps[] | undefined>(() => {
        if (campaign.players) {
            const players = Object.values(campaign.players);
            return players.map(o => {
                const colour = o.colour ?? randomColours[Math.floor(Math.random() * randomColours.length)];
                return {
                    name: o.name,
                    bg: `${colour}.7`,
                    color: `${colour}.0`,
                    variant: "glyph" as "glyph" | "initials" | undefined,
                };
            });
        }

        return undefined;
    }, [campaign.players]);

    const lastActive = campaign.lastSeen != null ? new Date(campaign.lastSeen) : campaign.lastSeen;
    return (
        <Stack fullWidth>
            <CampaignSummaryThumbnail campaign={campaign} screenshot={screenshot} />
            <Stack fullWidth alignItems="center" p={full ? 4 : 3} gap={4}>
                <CampaignSummaryHeader campaign={campaign} lastActive={lastActive} full={full} />
                {avatarData && (
                    <React.Fragment>
                        {full ? <Spacer bg="grayscale.9" /> : null}
                        <CampaignSummaryFooter avatarData={avatarData} />
                    </React.Fragment>
                )}
            </Stack>
        </Stack>
    );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent } from "react";
import { CoreAbility, DnD5EToken, DnD5ETokenTemplate, ModifiedValue } from "../common";
import { getCoreAbilityAbbr, modifierFromAbilityScore, resolveModifiedValue } from "../creature";
import { getModifiedValueTooltip } from "./common";
import { Box, Text } from "../../../components/primitives";
import { RollButton } from "./RollButton";
import { AdvantageBox } from "./AdvantageBox";

export const AbilityScoreBox: FunctionComponent<{
    ability: CoreAbility;
    value: ModifiedValue;
    token: DnD5EToken | DnD5ETokenTemplate;
    tooltipAlignment?: "start" | "center" | "end";
}> = ({ value, ability, token, tooltipAlignment }) => {
    const resolvedValue = resolveModifiedValue(value);
    const modifier = modifierFromAbilityScore(resolvedValue);

    // Can only include non-conditionals for the purposes of working out whether we should be at adv/dis by default,
    // because we can't evaluate most conditions automatically.
    const advantages = value.advantage?.filter(o => o.condition == null);
    const disadvantages = value.disadvantage?.filter(o => o.condition == null);

    let adv: "adv" | "dis" | undefined;
    if (!!(advantages?.length ?? 0) !== !!(disadvantages?.length ?? 0)) {
        adv = advantages?.length ? "adv" : "dis";
    }

    const tooltip = getModifiedValueTooltip(value);
    return (
        <Box flexDirection="column" alignItems="stretch" justifyContent="flex-start">
            <RollButton
                css={{ height: "auto", flexGrow: 1, width: "100%" }}
                variant="inOverlayBackground"
                token={token}
                modifier={modifier}
                advantage={adv}
                tooltip={tooltip}
                tooltipAlignment={tooltipAlignment}
                extraRolls={value.extraRolls}
                data={{ ability: ability }}>
                <Text fontSize={1} mt={2} color="grayscale.2">
                    {getCoreAbilityAbbr(ability)}
                </Text>
                <Text fontSize={4}>{modifier >= 0 ? "+" + modifier : modifier}</Text>
                <Text fontSize={0} mb={2} color="grayscale.2">
                    {resolvedValue}
                </Text>
            </RollButton>
            {value.advantage?.map((o, i) => (
                <AdvantageBox key={"adv" + i} advantage="adv" justifyContent="flex-start" mt={1} {...o} />
            ))}
            {value.disadvantage?.map((o, i) => (
                <AdvantageBox key={"dis" + i} advantage="dis" justifyContent="flex-start" mt={1} {...o} />
            ))}
            {value.fail?.map((o, i) => (
                <AdvantageBox key={"fail" + i} advantage="fail" justifyContent="flex-start" mt={1} {...o} />
            ))}
        </Box>
    );
};

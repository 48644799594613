/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box, Text, Image } from "./primitives";
import { Button } from "./Button";
import { FunctionComponent } from "react";
import { endCombat, nextCombatTurn, setCombatInitiative } from "../actions/location";
import { getCombatParticipants } from "../reducers/location";
import { CombatEncounter, CombatParticipant, isLocation, ResolvedToken } from "../store";
import { useDispatch, useLocation, useRole, useUser, useValidatedLocation } from "./contexts";
import { defaultAnimate, defaultExit, defaultInitial, MotionBox, MotionOverlayCard } from "./motion";
import { InlineScrollable } from "./InlineScrollable";
import { theme } from "../design";
import { resolveUri, tokenImageSize } from "./common";

const CombatParticipantItem: FunctionComponent<{
    isCurrent: boolean;
    token: ResolvedToken;
    participant: CombatParticipant;
}> = ({ isCurrent, token, participant }) => {
    const { location, campaign, system } = useValidatedLocation();
    const dispatch = useDispatch();
    const user = useUser();
    const role = useRole();

    const appearance = system.useTokenAppearance(token, campaign, location);
    return (
        <MotionBox
            layout
            pr={3}
            py={1}
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="stretch"
            initial={defaultInitial}
            animate={defaultAnimate}
            exit={defaultExit}
            fullWidth>
            {isCurrent && <MotionBox layout layoutId="combat_tracker_current" bg="blues.3" width={theme.space[2]} />}

            <MotionBox
                ml={isCurrent ? 2 : 3}
                layout="position"
                width={tokenImageSize}
                height={tokenImageSize}
                bg={isCurrent ? "blues.4" : "grayscale.7"}
                borderRadius={3}
                css={{ overflow: "hidden" }}>
                <Image src={resolveUri(appearance.imageUri)} responsive fullWidth fullHeight draggable={false} />
            </MotionBox>

            <MotionBox layout="position" flexDirection="column" ml={2} flexShrink={1} alignItems="flex-start">
                <Text fontSize={2}>{system.getDisplayName(token, campaign)}</Text>
                <Text color="grayscale.2" fontSize={0}>
                    {participant.initiative?.[0]}
                </Text>

                {!participant.initiative && (token.owner === user.id || (!token.owner && role === "GM")) && (
                    <Box>
                        {system.renderGetInitiative(token, initiative => {
                            dispatch(setCombatInitiative(campaign.id, location.id, token, initiative));
                        })}
                    </Box>
                )}

                {participant.initiative &&
                    (system.renderCombatTrackerItem?.(token, isCurrent, participant) ?? (
                        <Text>{participant.initiative[0]}</Text>
                    ))}
            </MotionBox>
        </MotionBox>
    );
};

export const CombatTracker: FunctionComponent<{ combat: CombatEncounter }> = ({ combat }) => {
    const { location, campaign } = useLocation();
    const dispatch = useDispatch();
    const role = useRole();
    const participants = isLocation(location) ? getCombatParticipants(combat, campaign, location) : [];
    const isStarted = combat.turn != null && combat.round != null;
    return (
        <MotionOverlayCard
            pb={2}
            pt={2}
            layout
            flexDirection="column"
            boxShadowSize="l"
            maxHeight="100%"
            maxWidth={theme.space[12]}
            borderRadius={4}
            initial={defaultInitial}
            animate={defaultAnimate}
            exit={defaultExit}
            css={{ pointerEvents: "all" }}>
            {/* <MotionBox layout="position" flexDirection="column" px={3}>
            <Heading px={3} as="h6">COMBAT TRACKER</Heading>
            {isStarted && <Text>Turn {participants.findIndex(o => o.token.id === combat.turn) + 1} of round {combat.round! + 1}</Text>}
        </MotionBox> */}
            <InlineScrollable>
                {participants
                    .filter(o => o.token)
                    .map(o => (
                        <CombatParticipantItem
                            key={o.token!.id}
                            isCurrent={o.token!.id === combat.turn}
                            token={o.token!}
                            participant={o}
                        />
                    ))}
            </InlineScrollable>
            <Box flexDirection="column" px={3} fullWidth>
                {role === "GM" && (
                    <Button
                        disabled={!isLocation(location)}
                        mt={2}
                        fullWidth
                        onClick={() => {
                            dispatch(nextCombatTurn(campaign.id, location!.id));
                        }}>
                        {isStarted ? "Next turn" : "Start combat"}
                    </Button>
                )}
                {role === "GM" && isStarted && (
                    <Button
                        disabled={!isLocation(location)}
                        mt={2}
                        fullWidth
                        onClick={() => {
                            dispatch(endCombat(campaign.id, location!.id));
                        }}>
                        End combat
                    </Button>
                )}
            </Box>
        </MotionOverlayCard>
    );
};

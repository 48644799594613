import React, { FunctionComponent } from "react";
import { Box } from "../primitives";
import { Button } from "../Button";

interface EmptyStateProps {
    onClick?: () => void;
    label?: any;

    [name: string]: any; // TODO: Required to allow phobon/base props to be passed through.
}

const EmptyState: FunctionComponent<EmptyStateProps> = ({ onClick, label, children, ...props }) => (
    <Box flexDirection="column" {...props}>
        {children}
        {onClick && (
            <Button mt={3} variant="primary" size="l" onClick={onClick}>
                {label}
            </Button>
        )}
    </Box>
);

export default EmptyState;

import { nanoid } from "nanoid";
import { Campaign, ErrorHandler, IGameSystem, Location, TokenTemplate, UserInfo, isLocation } from "./store";
import JSZip from "jszip";
import { LibraryItem, LibraryItemMetadata, UserArtLibrary } from "./library";
import { Dispatch } from "redux";
import { importCampaign, setPlayerLocation } from "./actions/campaign";
import { INotificationProps } from "./components/Notifications";
import { Message } from "./components/Message";
import { Event } from "./common";
import { resolveUri } from "./components/common";

export interface ExportOptions {
    /**
     * If currentUser, all media URLs found in the exported data that are in the current user's library will be
     * included as part of the exported package.
     * If all, then ALL media files will be included as part of the exported package.
     * Otherwise, the URLs will not be altered and no media will be included in the exported package.
     */
    includeMedia?: "none" | "currentUser" | "all";
}

interface MediaReplacement {
    id: string;
    name?: string;
    file: Promise<Blob>;
    metadata?: LibraryItemMetadata;
}

async function replaceMediaUris<T extends Object>(
    data: T,
    policy: "none" | "currentUser" | "all" | undefined,
    replacements: Map<string, MediaReplacement>
): Promise<T> {
    if (!policy || policy === "none") {
        return data;
    }

    const library = await UserArtLibrary.current.getItemsAsync();

    const libraryMap = new Map<string, LibraryItem>();
    for (let libraryItem of library.items) {
        libraryMap.set(libraryItem.uri.toLowerCase(), libraryItem);

        if (libraryItem.metadata.thumbnailUri) {
            libraryMap.set(libraryItem.metadata.thumbnailUri.toLowerCase(), libraryItem);
        }
    }

    const finalData = replaceMediaUrisCore(data, policy, libraryMap, replacements);
    return finalData;
}

async function downloadFile(url: string): Promise<Blob> {
    const response = await fetch(resolveUri(url));
    return response.blob();
}

function restoreMediaUris<T extends Object>(
    data: T,
    library: Map<string, { libraryItem: LibraryItem; alreadyExisted?: boolean }>
) {
    if (Array.isArray(data)) {
        return data.map(o => {
            if (typeof o === "object") {
                return restoreMediaUris(o, library);
            }

            return o;
        });
    }

    const keys = Object.keys(data);
    for (let p of keys) {
        const v = data[p];
        if (v != null) {
            if (typeof v === "string") {
                const lc = v.toLowerCase();

                let libraryItem: LibraryItem | undefined;
                let isThumbnail = false;
                let isRequired = false;

                if (lc.startsWith("file://media/")) {
                    isRequired = true;
                    libraryItem = library.get(lc.substring("file://media/".length))?.libraryItem;
                } else if (lc.startsWith("thumbnail://media/")) {
                    isThumbnail = true;
                    isRequired = true;
                    libraryItem = library.get(lc.substring("thumbnail://media/".length))?.libraryItem;
                }

                if (libraryItem) {
                    data = {
                        ...data,
                        [p]: isThumbnail ? libraryItem.metadata?.thumbnailUri ?? libraryItem.uri : libraryItem.uri,
                    };
                } else if (isRequired) {
                    throw new Error("Invalid file, could not find media.");
                }
            } else if (typeof v === "object") {
                const nv = restoreMediaUris(v, library);
                data = { ...data, [p]: nv };
            }
        }
    }

    return data;
}

function replaceMediaUrisCore<T extends Object>(
    data: T,
    policy: "currentUser" | "all",
    library: Map<string, LibraryItem>,
    replacements: Map<string, MediaReplacement>
) {
    if (Array.isArray(data)) {
        return data.map(o => {
            if (typeof o === "object") {
                return replaceMediaUrisCore(o, policy, library, replacements);
            }

            return o;
        });
    }

    const keys = Object.keys(data);
    for (let p of keys) {
        const v = data[p];
        if (v != null) {
            if (typeof v === "string") {
                // Could be a URL.
                const lc = v.toLowerCase();
                if (lc.startsWith("http://") || lc.startsWith("https://") || lc.startsWith("cld://")) {
                    // Ok, it's probably a URL.
                    // TODO: Could do a more exhaustive regex instead here to be sure.
                    let replacement = replacements.get(lc);
                    let isThumbnail = false;
                    if (!replacement) {
                        // No replacement yet, see if the url matches anything in the user's library.
                        const libraryItem = library.get(lc);

                        if (libraryItem) {
                            // Matched an item from the user's library, include it.
                            // TODO: Include metadata too.
                            isThumbnail = libraryItem.metadata.thumbnailUri?.toLowerCase() === lc;
                            replacement = {
                                id: libraryItem.id,
                                name: libraryItem.name,
                                file: downloadFile(libraryItem.uri),
                                metadata: libraryItem.metadata,
                            };
                        } else if (policy === "all") {
                            // Not part of the user's library, so we don't have name/metadata... but include it anyway.
                            replacement = { id: nanoid(), file: downloadFile(lc) };
                        }

                        if (replacement) {
                            replacements.set(lc, replacement);
                        }
                    }

                    if (replacement) {
                        data = {
                            ...data,
                            [p]: isThumbnail ? `thumbnail://media/${replacement.id}` : `file://media/${replacement.id}`,
                        };
                    }
                }
            } else if (typeof v === "object") {
                const nv = replaceMediaUrisCore(v, policy, library, replacements);
                data = { ...data, [p]: nv };
            }
        }
    }

    return data;
}

export function fileName(name: string) {
    const i = name.lastIndexOf("/");
    if (i >= 0) {
        name = name.substring(i + 1);
    }

    return name;
}

export function withoutExtension(name: string) {
    const i = name.lastIndexOf(".");
    if (i >= 0) {
        name = name.substring(0, i);
    }

    return name;
}

async function addMedia(zip: JSZip, replacements: Map<string, MediaReplacement>) {
    const media = zip.folder("media");

    if (media) {
        for (let replacement of Array.from(replacements.values())) {
            const blob = await replacement.file;
            media.file(`${replacement.id}`, blob);

            if (replacement.metadata) {
                let idNoExtension = withoutExtension(replacement.id);
                const metadataNoThumbnail = { ...replacement.metadata, name: replacement.name };
                delete metadataNoThumbnail.thumbnailUri;
                media.file(`metadata/${idNoExtension}.json`, JSON.stringify(metadataNoThumbnail));
            }
        }
    }
}

async function addTokenTemplate(
    tokenTemplate: TokenTemplate,
    zip: JSZip,
    replacements: Map<string, MediaReplacement>,
    options?: ExportOptions
) {
    tokenTemplate = await replaceMediaUris(tokenTemplate, options?.includeMedia, replacements);

    zip.file(`tokentemplates/${tokenTemplate.templateId}.json`, JSON.stringify(tokenTemplate));
}

async function addLocation(
    location: Location,
    campaign: Campaign,
    zip: JSZip,
    replacements: Map<string, MediaReplacement>,
    options?: ExportOptions
) {
    location = await replaceMediaUris(location, options?.includeMedia, replacements);

    zip.file(`locations/${location.id}.json`, JSON.stringify(location));

    for (let token of Object.values(location.tokens)) {
        if (token.templateId) {
            const tokenTemplate = campaign.tokens[token.templateId];
            if (tokenTemplate) {
                addTokenTemplate(tokenTemplate, zip, replacements, options);
            }
        }
    }
}

/**
 * Exports a package containing the specified location from the specified campaign as a zip file.
 * @param locationId The ID of the location to export.
 * @param campaign The campaign for the location to export.
 * @param options The export options.
 */
export async function exportLocation(locationId: string, campaign: Campaign, options?: ExportOptions) {
    const replacements = new Map<string, MediaReplacement>();

    let location = campaign.locations[locationId];
    if (!isLocation(location)) {
        throw new Error("Invalid location.");
    }

    const zip = new JSZip();
    await addLocation(location, campaign, zip, replacements, options);

    await addMedia(zip, replacements);

    // TODO: Specify a custom mime type here?
    return zip.generateAsync({ type: "blob", compression: "DEFLATE" });
}

export function getFilesForFolder(zip: JSZip, folder: string) {
    const folderPath = (folder.endsWith("/") ? folder.substring(0, folder.length - 1) : folder).split("/");
    return zip.filter((o, f) => {
        if (f.dir) {
            return false;
        }

        const path = f.name.split("/");

        if (folder == null || folder === "") {
            return path.length === 1;
        }

        if (path.length - 1 > folderPath.length) {
            return false;
        }

        for (let i = 0; i < folderPath.length; i++) {
            if (folderPath[i] !== path[i]) {
                return false;
            }
        }

        return true;
    });
}

async function importPackageCore(
    file: File,
    system: IGameSystem,
    campaign: Campaign,
    errorHandler: ErrorHandler
): Promise<{
    mediaById: Map<string, { libraryItem: LibraryItem; alreadyExisted?: boolean }>;
    tokenTemplates: TokenTemplate[];
    locations: Location[];
    messages: string[];
}> {
    const zip = await JSZip.loadAsync(file);

    const mediaById: Map<string, { libraryItem: LibraryItem; alreadyExisted?: boolean }> = new Map();

    // First, process all of the media files. We'll need those to fix the URLs in any json data.
    const mediaFiles = getFilesForFolder(zip, "media");
    const libraryItemPromises: Promise<void>[] = [];
    for (let mediaFile of mediaFiles) {
        const id = fileName(mediaFile.name);

        // Check if we have metadata for this file.
        const metadataFileName = `media/metadata/${withoutExtension(id)}.json`;
        const metadataFile = zip.file(metadataFileName);
        let metadata: (LibraryItemMetadata & { name?: string }) | undefined;
        if (metadataFile) {
            const metadataText = await metadataFile.async("text");
            metadata = JSON.parse(metadataText) as LibraryItemMetadata & { name?: string };
        }

        // Now get the main content for the media.
        const content = await mediaFile.async("blob");

        // Actually upload content. We should get the final metadata back containing thumbnail etc.
        const name = metadata?.name ?? id;
        if (metadata) {
            delete metadata.name;
        }

        libraryItemPromises.push(
            UserArtLibrary.current
                .uploadBlob(content, name, errorHandler, metadata)
                .then(libraryItem => {
                    if (libraryItem.libraryItem) {
                        mediaById.set(
                            id.toLowerCase(),
                            libraryItem as { libraryItem: LibraryItem; alreadyExisted?: boolean }
                        );
                    }
                })
                .catch(() => {
                    // TODO: Should this doom the entire import? Probably, right? Can't undo all the media we've already
                    // imported though. Maybe we should track any failures and just not import anything AFTER the media?
                })
        );
    }

    await Promise.allSettled(libraryItemPromises);

    // Now we've imported all the media files and know what their IDs & thumbnails etc are, we can proceed to importing
    // all the JSON based data.

    // TODO: Error handling - catch errors here and pass to ErrorHandler?

    const tokenTemplates: TokenTemplate[] = [];
    const tokenTemplateFiles = getFilesForFolder(zip, "tokentemplates");
    for (let templateFile of tokenTemplateFiles) {
        const templateText = await templateFile.async("text");
        let tokenTemplate = JSON.parse(templateText) as TokenTemplate;

        // Don't override token templates that already exist.
        if (!campaign.tokens[tokenTemplate.templateId]) {
            tokenTemplate = restoreMediaUris(tokenTemplate, mediaById);
            tokenTemplates.push(tokenTemplate);
        }
    }

    const locations: Location[] = [];
    const locationFiles = getFilesForFolder(zip, "locations");
    for (let locationFile of locationFiles) {
        const locationText = await locationFile.async("text");
        let location = JSON.parse(locationText) as Location;

        // Don't override locations that already exist.
        if (!campaign.locations[location.id]) {
            location = restoreMediaUris(location, mediaById);
            locations.push(location);
        }
    }

    const messages: string[] =
        (await system.importFile?.(zip, o => restoreMediaUris(o, mediaById), errorHandler)) ?? [];

    return {
        mediaById,
        tokenTemplates,
        locations,
        messages,
    };
}

export const importCompleted: Event<void> = new Event();

export async function importPackage(
    file: File,
    system: IGameSystem,
    campaign: Campaign,
    user: UserInfo,
    errorHandler: ErrorHandler,
    dispatch: Dispatch,
    addNotification: (data: INotificationProps) => Promise<void>
) {
    const role = campaign.players[user.id]?.role ?? "Player";
    if (role !== "GM") {
        errorHandler.handleError(undefined, "Only the GM can import packages.");
    }

    try {
        const { mediaById, tokenTemplates, locations, messages } = await importPackageCore(
            file,
            system,
            campaign,
            errorHandler
        );

        // Actually modify the campaign to add the templates/locations.
        dispatch(
            importCampaign(campaign.id, {
                locations: locations,
                tokenTemplates: tokenTemplates,
            })
        );

        if (locations.length) {
            dispatch(setPlayerLocation(campaign.id, user.id, locations[0].id));
        }

        // Build a message summarising what was imported and notify the user that the import was successful.
        var msg = "";
        if (mediaById.size) {
            const newLibraryItems = Array.from(mediaById.values()).filter(o => !o.alreadyExisted);
            if (newLibraryItems.length) {
                msg += `${newLibraryItems.length} art items were successfully imported.`;
            }
        }

        if (tokenTemplates.length > 0) {
            if (msg) {
                msg += "\n\n";
            }

            if (tokenTemplates.length > 1) {
                msg += `${tokenTemplates.length} tokens were successfully imported.`;
            } else {
                msg += `The token ${tokenTemplates[0].label} was successfully imported.`;
            }
        }

        if (locations.length > 0) {
            if (msg) {
                msg += "\n\n";
            }

            if (locations.length > 1) {
                msg += `${locations.length} locations were successfully imported.`;
            } else {
                msg += `The location ${locations[0].label} was successfully imported.`;
            }
        }

        for (let message of messages) {
            if (msg) {
                msg += "\n\n";
            }

            msg += message;
        }

        if (!msg) {
            msg = "The package import was successful, but everything in it already exists in this campaign.";
        }

        addNotification({
            content: (
                <Message
                    variant="success"
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, whiteSpace: "pre-wrap" }}>
                    {msg}
                </Message>
            ),
            canDismiss: true,
            timeout: 5000,
            showLife: true,
        });

        importCompleted.trigger();
    } catch (e) {
        errorHandler.handleError(e, "There was an error importing the package.");
    }
}

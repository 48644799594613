import React, { FunctionComponent, useCallback, useEffect } from "react";
import { LocalPixelPosition } from "../../position";
import { WindowAnnotation, Annotation, getAnnotationPos } from "../../annotations";
import { useDispatch, useLocalGrid, useValidatedLocationLevel } from "../contexts";
import { getThemeColor } from "../../design/utils";
import { theme } from "../../design";
import { localPoint } from "../../grid";
import { AnnotationHandle } from "./AnnotationHandle";
import { modifyAnnotation } from "../../actions/location";
import { interactiveLineWidth, VttMode } from "../common";
import { DraggableLine, Line } from "./three2d/Line";
import { HoverTracking, ZIndexes } from "./common";
import { Token } from "../../store";
import { ThreeEvent } from "@react-three/fiber";
import { AnimatePresence, usePresence } from "framer-motion";
import { UiLayer } from "./UiLayer";
import { SelectionType } from "../selection";

interface WindowAnnotationNodeProps extends HoverTracking {
    window: WindowAnnotation;
    activePoint?: LocalPixelPosition;
    isSelected: SelectionType;
    wasSelected: boolean;
    disabled?: boolean;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    onPointerDown?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    onPointerUp?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    mode: VttMode;
    onOverrideAnnotation: (id: string, override: Partial<WindowAnnotation> | undefined) => void;
    animateExit?: boolean;
}

const WindowAnnotationNodeCore: FunctionComponent<WindowAnnotationNodeProps> = ({
    window,
    activePoint,
    snapPoint,
    isSelected,
    onClick,
    onPointerDown,
    onPointerUp,
    mode,
    onOverrideAnnotation,
    disabled,
    isHovering,
    setHoverPart,
    wasSelected,
    animateExit,
}) => {
    const dispatch = useDispatch();
    const { campaign, location, levelKey } = useValidatedLocationLevel();
    const grid = useLocalGrid();
    const pos = getAnnotationPos(window, campaign, location, grid);
    const point = activePoint ? { x: activePoint.x - pos.x, y: activePoint.y - pos.y } : window.point;

    const onDragMove = useCallback(
        (e: ThreeEvent<PointerEvent>, p: LocalPixelPosition) => {
            onOverrideAnnotation(window.id, {
                pos: { ...p, level: window.pos?.level ?? levelKey },
            });
        },
        [window.id, onOverrideAnnotation, window.pos?.level, levelKey]
    );
    const onDragEnd = useCallback(() => {
        dispatch(
            modifyAnnotation<WindowAnnotation>(campaign.id, location.id, window.id, {
                pos: window.pos,
                point: window.point,
            })
        );
        onOverrideAnnotation(window.id, undefined);
    }, [campaign, location, window, dispatch, onOverrideAnnotation]);
    const onAnnotationClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, window);
            }
        },
        [window, onClick]
    );

    const isHover = onClick && (isHovering || !!activePoint);

    const showHandles = isSelected && !disabled && mode === "build";

    // We need to manage presence manually because of the AnnotationHandles being in their own AnimatePresence - they need
    // to be given a chance to animate out.
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        if (!isPresent && !animateExit && !showHandles) {
            safeToRemove?.();
        }
    }, [isPresent, animateExit, safeToRemove, showHandles]);

    const unselectedColor = getThemeColor(disabled ? theme.colors.grayscale[3] : theme.colors.oranges[isHover ? 4 : 3]);
    const color = isSelected === SelectionType.Primary ? theme.colors.guidance.focus : unselectedColor;
    const disableDrag = mode !== "build" || onClick == null || disabled;

    return (
        <>
            {(isPresent || animateExit) && (
                <>
                    <DraggableLine
                        x={pos.x}
                        y={pos.y}
                        points={[{ x: 0, y: 0 }, point]}
                        width={4 + interactiveLineWidth}
                        zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                        opacity={0}
                        onPointerOver={() => setHoverPart?.("line", true)}
                        onPointerOut={() => setHoverPart?.("line", false)}
                        onPointerDown={e => {
                            if (onPointerDown) {
                                onPointerDown(e, window);
                            }

                            const cancelEvent = onClick != null && e.nativeEvent.button !== 2;
                            if (cancelEvent) {
                                e.stopPropagation();
                                e.nativeEvent.stopPropagation();
                                e.nativeEvent.preventDefault();
                            }
                        }}
                        onPointerUp={onPointerUp ? e => onPointerUp(e, window) : undefined}
                        dragBoundFunc={v => snapPoint(v)}
                        cursor={disableDrag ? undefined : "pointer"}
                        disableDrag={disableDrag}
                        onDragMove={onDragMove}
                        onDragEnd={onDragEnd}
                        onClick={disabled ? undefined : onAnnotationClick}
                    />
                    <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                        {isPresent && (
                            <Line
                                x={pos.x}
                                y={pos.y}
                                points={[{ x: 0, y: 0 }, point]}
                                animateEnterExit="nolayout"
                                initial={
                                    isSelected
                                        ? { color: unselectedColor, opacity: 1, scale: 1 }
                                        : wasSelected
                                        ? {
                                              color: theme.colors.guidance.focus,
                                              opacity: 1,
                                              scale: 1,
                                          }
                                        : undefined
                                }
                                exit={animateExit ? undefined : null}
                                width={4}
                                zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                                color={color}
                                dashSize={theme.space[2]}
                                dashed={!window.obstructsLight}
                            />
                        )}
                    </AnimatePresence>
                </>
            )}
            <UiLayer>
                <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                    {showHandles && isPresent && (
                        <>
                            <AnnotationHandle
                                x={pos.x}
                                y={pos.y}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPoint}
                                disableDrag={onClick == null}
                                onDragMove={p => {
                                    onOverrideAnnotation(window.id, {
                                        pos: { ...p, level: window.pos?.level ?? levelKey },
                                        point: localPoint(point.x + (pos.x - p.x), point.y + (pos.y - p.y)),
                                    });
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<WindowAnnotation>(campaign.id, location.id, window.id, {
                                            pos: window.pos,
                                            point: window.point,
                                        })
                                    );
                                    onOverrideAnnotation(window.id, undefined);
                                }}
                            />
                            <AnnotationHandle
                                x={pos.x + point.x}
                                y={pos.y + point.y}
                                color={theme.colors.guidance.focus}
                                snapPoint={snapPoint}
                                disableDrag={onClick == null}
                                onDragMove={p => {
                                    onOverrideAnnotation(window.id, {
                                        point: { x: p.x - pos.x, y: p.y - pos.y },
                                    });
                                }}
                                onDragEnd={() => {
                                    dispatch(
                                        modifyAnnotation<WindowAnnotation>(campaign.id, location.id, window.id, {
                                            point: window.point,
                                        })
                                    );
                                    onOverrideAnnotation(window.id, undefined);
                                }}
                            />
                        </>
                    )}
                </AnimatePresence>
            </UiLayer>
        </>
    );
};

export const WindowAnnotationNode = React.memo(WindowAnnotationNodeCore);

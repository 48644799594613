/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState, FunctionComponent, useEffect, useMemo, useCallback, useRef, ChangeEvent } from "react";
import { Form, SelectField, asField, IAsFieldProps, CheckboxField } from "../Form";
import { Message } from "../Message";
import { useNotifications } from "../Notifications";
import { PercentageBar } from "../PercentageBar";

import { Box, Radio } from "../primitives";
import { LocalSearchable } from "../../localsearchable";
import { AnimatedListItem, MotionMessage, defaultAnimate, defaultInitial, defaultExit, MotionBox } from "../motion";
import { AnimatePresence, motion } from "framer-motion";
import { ExtractProps, Loading, LobotomizedBox, useVttApp } from "../common";
import { useLocalSetting, usePermission } from "../utils";
import { convertUserMediaError, RtcSession } from "../../webrtc";
import { theme } from "../../design";
import {
    audioInputDeviceSetting,
    videoInputDeviceSetting,
    musicMutedSetting,
    ambienceMutedSetting,
    musicVolumeSetting as musicVolumeLocalSetting,
    ambienceVolumeSetting as ambienceVolumeLocalSetting,
    LocalSetting,
    diceColorSetting,
    diceColors,
    antialiasTypeSetting,
    smaaQualitySetting,
} from "../../common";
import styled from "@emotion/styled";
import { VolumeSlider } from "../volumeslider";
import { SearchableSetting } from "../../store";
import { CirclePicker } from "react-color";
import { ScrollableTest } from "../ScrollableTest";
import { softShadowsSetting } from "../../common";
import { SMAAPreset } from "postprocessing";
import { Pages } from "./Sidebar";

// TODO: Remove when Scrollable is fixed so that we can put the flex prop on that (or until someone tells me what I'm doing wrong).
const ScrollableHack = styled(Box)`
    > :first-of-type {
        flex: 1 1 auto;
    }
`;

const MotionPercentageBar = motion(PercentageBar);

const tags = ["settings"];

const audioDeviceSetting: SearchableSetting = {
    id: "audioDevice",
    label: "Audio input device",
    tags: [...tags, "audio", "sound", "device", "microphone"],
    render: () => <AudioDeviceSetting />,
};
const videoDeviceSetting: SearchableSetting = {
    id: "videoDevice",
    label: "Video input device",
    tags: [...tags, "video", "camera", "device"],
    render: () => <VideoDeviceSetting />,
};

const musicVolumeSetting: SearchableSetting = {
    id: "musicVolume",
    label: "Music volume",
    tags: [...tags, "audio", "sound", "device"],
    render: () => (
        <VolumeSettingField
            label={musicVolumeSetting.label}
            required
            isMutedSetting={musicMutedSetting}
            volumeSetting={musicVolumeLocalSetting}
        />
    ),
};

const ambienceVolumeSetting: SearchableSetting = {
    id: "ambienceVolume",
    label: "Ambience volume",
    tags: [...tags, "audio", "sound", "device", "ambient"],
    render: () => (
        <VolumeSettingField
            label={ambienceVolumeSetting.label}
            required
            isMutedSetting={ambienceMutedSetting}
            volumeSetting={ambienceVolumeLocalSetting}
        />
    ),
};

const diceColorsSetting: SearchableSetting = {
    id: "diceColor",
    label: "Dice color",
    tags: [...tags, "dice", "die", "roll", "colour"],
    render: () => <DiceColorsSettingField label={diceColorsSetting.label} />,
};

const softShadowsEnabledSetting: SearchableSetting = {
    id: "softShadows",
    label: "Soft shadows",
    tags: [...tags, "performance", "graphics"],
    render: () => <SoftShadowsSetting />,
};

const antialiasSetting: SearchableSetting = {
    id: "antialias",
    label: "Antialiasing",
    tags: [...tags, "performance", "graphics"],
    render: () => <AntialiasSetting />,
};

const allSettings: SearchableSetting[] = [
    audioDeviceSetting,
    videoDeviceSetting,
    musicVolumeSetting,
    ambienceVolumeSetting,
    diceColorsSetting,
    softShadowsEnabledSetting,
    antialiasSetting,
];

const searchableFields: (keyof SearchableSetting)[] = ["label", "tags"];
const toResult: (o: SearchableSetting) => () => JSX.Element = o => () => o.render();
export const userSettingsSearch = new LocalSearchable(allSettings, {
    idField: "id",
    searchableFields: searchableFields,
    toResult: toResult,
});

const AudioDeviceSetting = () => {
    const [devices, setDevices] = useState<MediaDeviceInfo[] | undefined>();
    const browserPermission = usePermission("microphone" as any);
    const [add] = useNotifications();

    const [deviceId, setDeviceId] = useLocalSetting<string>(audioInputDeviceSetting);
    const [volume, setVolume] = useState<number | undefined>(undefined);
    const [isLoadingStream, setIsLoadingStream] = useState<boolean>();

    // Keep track of whether the element is still alive.
    // We need this to check from async functions whether or not the element still exists, otherwise stuff
    // might not get cleaned up properly.
    const asyncState = useMemo<{
        isAlive: boolean;
        stream?: MediaStream;
        currentDeviceId?: string;
    }>(() => ({ isAlive: true }), []);
    useEffect(() => {
        return () => {
            asyncState.isAlive = false;
            if (asyncState.stream) {
                asyncState.stream.getTracks().forEach(o => o.stop());
                delete asyncState.stream;
            }
        };
    }, [asyncState]);

    // Calculate the effective permission state given the browser's response and support of permissions API.
    let calcPermission: PermissionState | undefined;
    if (browserPermission == null || browserPermission !== "unsupported") {
        calcPermission = browserPermission;
    } else {
        // If every device has no label, then the browser hasn't granted permission to the devices yet.
        calcPermission = devices != null ? (devices.every(o => !o.label) ? "prompt" : "granted") : undefined;
    }

    const cleanStream = useCallback(() => {
        if (asyncState.stream) {
            asyncState.stream.getTracks().forEach(o => o.stop());
            delete asyncState.stream;
            delete asyncState.currentDeviceId;
            setVolume(undefined);
        }
    }, [asyncState]);

    const refreshDevices = useCallback(async () => {
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        const devices = allDevices.filter(o => o.kind === "audioinput");
        setDevices(devices);
        return devices;
    }, [setDevices]);

    // Refresh the device list when devices change.
    useEffect(() => {
        const handler = async () => {
            if (devices) {
                const updatedDevices = await refreshDevices();

                // If we've already been granted permission, there is at least one device, and we're not currently
                // displaying another stream, then try to start a stream for the required device.
                if (
                    calcPermission === "granted" &&
                    updatedDevices.length &&
                    asyncState.currentDeviceId == null &&
                    (deviceId == null || updatedDevices.some(o => o.deviceId === deviceId))
                ) {
                    requestStream(deviceId);
                }
            }
        };
        navigator.mediaDevices.addEventListener("devicechange", handler);
        return () => navigator.mediaDevices.removeEventListener("devicechange", handler);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices, calcPermission, deviceId]);

    // Kick off a request for the user media. This will be called if the user has granted permission to
    // their microphone already, or if the user clicks on a link to kick off the request.
    const requestStream = async (audioDeviceId: string | undefined) => {
        try {
            cleanStream();

            asyncState.currentDeviceId = audioDeviceId;
            setIsLoadingStream(true);
            const newStream = await navigator.mediaDevices.getUserMedia({
                audio: audioDeviceId ? { deviceId: { exact: audioDeviceId } } : true,
                video: false,
            });

            // Got an audio stream. We can now use this to show the input level.
            if (asyncState.isAlive) {
                asyncState.stream = newStream;
                newStream.getTracks().forEach(track => {
                    track.addEventListener("ended", async e => {
                        // The track has ended - this can be caused by the user revoking permission, which
                        // we can't detect using the permissions API in Firefox.
                        // It can also occur if the relevant device is unplugged.
                        cleanStream();
                        if (browserPermission === "unsupported") {
                            await refreshDevices();
                        }
                    });
                });

                if (calcPermission !== "granted") {
                    await refreshDevices();
                }

                const context = new AudioContext();
                const analyser = context.createAnalyser();
                analyser.fftSize = 256;
                analyser.smoothingTimeConstant = 0.4;
                analyser.minDecibels = -90;
                const buffer = new Uint8Array(analyser.frequencyBinCount);
                const sourceNode = context.createMediaStreamSource(newStream);
                sourceNode.connect(analyser);

                const updateVolume = () => {
                    analyser.getByteFrequencyData(buffer);
                    let max = 0;
                    for (let i = 0; i < buffer.length; i++) {
                        max = buffer[i] > max ? buffer[i] : max;
                    }

                    if (asyncState.isAlive && asyncState.stream === newStream) {
                        setVolume(max / 255);
                        setTimeout(updateVolume, 100);
                    }
                };
                updateVolume();
                setIsLoadingStream(false);
            } else {
                newStream.getTracks().forEach(o => o.stop());
            }
        } catch (e: any) {
            setIsLoadingStream(false);

            // If the device isn't found, we say so in our empty list already.
            if (e.name !== "NotFoundError") {
                const err = convertUserMediaError(e);
                add({
                    content: <Message variant="error">{err.message}</Message>,
                    canDismiss: true,
                });
            }
        }
    };

    useEffect(() => {
        if (browserPermission === "granted" || browserPermission === "unsupported") {
            refreshDevices();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [browserPermission]);
    useEffect(() => {
        if (calcPermission === "granted") {
            if (asyncState.currentDeviceId !== deviceId && (deviceId != null || asyncState.stream == null)) {
                requestStream(deviceId);
            }
        } else {
            cleanStream();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcPermission, deviceId, cleanStream, refreshDevices, asyncState]);

    return (
        <Box flexDirection="column" fullWidth>
            <SelectField
                label={audioDeviceSetting.label}
                required
                fullWidth
                value={deviceId}
                onChange={e => setDeviceId(e.target.value)}
                disabled={calcPermission !== "granted" || !devices || (devices && !devices.length)}>
                <option value="">
                    {!devices || (devices && devices.length)
                        ? `Default${devices ? " (" + devices[0].label + ")" : ""}`
                        : "No devices found"}
                </option>
                {devices
                    ? devices.map(o => (
                          <option key={o.deviceId} value={o.deviceId}>
                              {o.label}
                          </option>
                      ))
                    : undefined}
            </SelectField>

            <AnimatePresence mode="wait" initial={false}>
                {isLoadingStream && (
                    <MotionBox
                        key="loadingpreview"
                        height={theme.space[3]}
                        mt={2}
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <Loading />
                    </MotionBox>
                )}
                {volume != null && (
                    <MotionPercentageBar
                        key="volume"
                        fullWidth
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        total={100}
                        complete={volume * 100}
                        dangerThreshold={20}
                        warningThreshold={40}
                        mt={2}
                        size="l"
                    />
                )}
                {calcPermission === "prompt" && (
                    <MotionMessage
                        key="prompt"
                        variant="question"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        mt={2}
                        css={{ cursor: "pointer" }}
                        onClick={() => requestStream(deviceId)}>
                        Access to your devices is required for this setting. Click here to request permission.
                    </MotionMessage>
                )}
                {calcPermission === "denied" && (
                    <MotionMessage
                        key="denied"
                        variant="warning"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        mt={2}>
                        Access to your devices has been denied. Try changing the permission settings in your browser.
                    </MotionMessage>
                )}
            </AnimatePresence>
        </Box>
    );
};

const PreviewVideo: FunctionComponent<{ stream: MediaStream }> = ({ stream }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        if (videoRef.current && videoRef.current.srcObject !== stream) {
            videoRef.current.srcObject = stream ? stream : null;
        }
    }, [stream]);

    return (
        <video
            autoPlay
            playsInline
            css={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
            }}
            ref={videoRef}
        />
    );
};

const VideoDeviceSetting = () => {
    const [devices, setDevices] = useState<MediaDeviceInfo[] | undefined>();
    const browserPermission = usePermission("camera" as any);
    const [add] = useNotifications();

    const [deviceId, setDeviceId] = useLocalSetting(videoInputDeviceSetting);
    const [stream, setStream] = useState<MediaStream | undefined>();
    const [isLoadingStream, setIsLoadingStream] = useState<boolean>();

    // Keep track of whether the element is still alive.
    // We need this to check from async functions whether or not the element still exists, otherwise stuff
    // might not get cleaned up properly.
    const asyncState = useMemo<{
        isAlive: boolean;
        stream?: MediaStream;
        currentDeviceId?: string;
    }>(() => ({ isAlive: true }), []);
    useEffect(() => {
        return () => {
            asyncState.isAlive = false;
            if (asyncState.stream) {
                asyncState.stream.getTracks().forEach(o => o.stop());
                delete asyncState.stream;
            }
        };
    }, [asyncState]);

    // Calculate the effective permission state given the browser's response and support of permissions API.
    let calcPermission: PermissionState | undefined;
    if (browserPermission == null || browserPermission !== "unsupported") {
        calcPermission = browserPermission;
    } else {
        // If every device has no label, then the browser hasn't granted permission to the devices yet.
        calcPermission = devices != null ? (devices.every(o => !o.label) ? "prompt" : "granted") : undefined;
    }

    const cleanStream = useCallback(() => {
        if (asyncState.stream) {
            asyncState.stream.getTracks().forEach(o => o.stop());
            delete asyncState.stream;
            delete asyncState.currentDeviceId;
            setStream(undefined);
        }
    }, [asyncState]);

    const refreshDevices = useCallback(async () => {
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        const devices = allDevices.filter(o => o.kind === "videoinput");
        setDevices(devices);
        return devices;
    }, [setDevices]);

    // Refresh the device list when devices change.
    useEffect(() => {
        const handler = async () => {
            if (devices) {
                const updatedDevices = await refreshDevices();

                // If we've already been granted permission, there is at least one device, and we're not currently
                // displaying another stream, then try to start a stream for the required device.
                if (
                    calcPermission === "granted" &&
                    updatedDevices.length &&
                    asyncState.currentDeviceId == null &&
                    (deviceId == null || updatedDevices.some(o => o.deviceId === deviceId))
                ) {
                    requestStream(deviceId);
                }
            }
        };
        navigator.mediaDevices.addEventListener("devicechange", handler);
        return () => navigator.mediaDevices.removeEventListener("devicechange", handler);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices, calcPermission, deviceId]);

    // Kick off a request for the user media. This will be called if the user has granted permission to
    // their microphone already, or if the user clicks on a link to kick off the request.
    const requestStream = async (videoDeviceId: string | undefined) => {
        try {
            cleanStream();

            asyncState.currentDeviceId = videoDeviceId;
            setIsLoadingStream(true);
            const newStream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: videoDeviceId ? { deviceId: { exact: videoDeviceId } } : true,
            });

            // Got an audio stream. We can now use this to show the input level.
            if (asyncState.isAlive && asyncState.currentDeviceId === videoDeviceId) {
                asyncState.stream = newStream;
                newStream.getTracks().forEach(track => {
                    track.addEventListener("ended", async e => {
                        // The track has ended - this can be caused by the user revoking permission, which
                        // we can't detect using the permissions API in Firefox.
                        // It can also occur if the relevant device is unplugged.
                        cleanStream();
                        if (browserPermission === "unsupported") {
                            await refreshDevices();
                        }
                    });
                });

                if (calcPermission !== "granted") {
                    await refreshDevices();
                }

                setStream(newStream);
                setIsLoadingStream(false);
            } else {
                newStream.getTracks().forEach(o => o.stop());
            }
        } catch (e: any) {
            setIsLoadingStream(false);

            // If the device isn't found, we say so in our empty list already.
            if (e.name !== "NotFoundError") {
                const err = convertUserMediaError(e);
                add({
                    content: <Message variant="error">{err.message}</Message>,
                    canDismiss: true,
                });
            }
        }
    };

    useEffect(() => {
        if (browserPermission === "granted" || browserPermission === "unsupported") {
            refreshDevices();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [browserPermission]);
    useEffect(() => {
        if (calcPermission === "granted") {
            if (asyncState.currentDeviceId !== deviceId && (deviceId != null || asyncState.stream == null)) {
                requestStream(deviceId);
            }
        } else {
            cleanStream();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcPermission, deviceId, cleanStream, refreshDevices, asyncState]);

    return (
        <Box flexDirection="column" fullWidth>
            <SelectField
                label={videoDeviceSetting.label}
                required
                fullWidth
                value={deviceId}
                onChange={e => setDeviceId(e.target.value)}
                disabled={calcPermission !== "granted" || !devices || (devices && !devices.length)}>
                <option value="">
                    {!devices || (devices && devices.length)
                        ? `Default${devices ? " (" + devices[0].label + ")" : ""}`
                        : "No devices found"}
                </option>
                {devices
                    ? devices.map(o => (
                          <option key={o.deviceId} value={o.deviceId}>
                              {o.label}
                          </option>
                      ))
                    : undefined}
            </SelectField>

            <Box
                borderWidth={2}
                borderColor="grayscale.6"
                borderStyle="solid"
                alignSelf="flex-start"
                mt={2}
                borderRadius={3}
                width={RtcSession.idealWidth}
                height={RtcSession.idealHeight}
                overflow="hidden"
                css={{ boxSizing: "content-box" }}>
                <AnimatePresence mode="wait" initial={false}>
                    {!stream && !isLoadingStream && (
                        <MotionBox
                            key="nopreview"
                            color="grayscale.3"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            No preview available
                        </MotionBox>
                    )}
                    {isLoadingStream && (
                        <MotionBox
                            key="loadingpreview"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <Loading />
                        </MotionBox>
                    )}
                    {stream && (
                        <MotionBox
                            key="preview"
                            fullWidth
                            fullHeight
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <PreviewVideo stream={stream} />
                        </MotionBox>
                    )}
                </AnimatePresence>
            </Box>

            <AnimatePresence mode="wait" initial={false}>
                {calcPermission === "prompt" && (
                    <MotionMessage
                        key="prompt"
                        variant="question"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        mt={2}
                        css={{ cursor: "pointer" }}
                        onClick={() => requestStream(deviceId)}>
                        Access to your devices is required for this setting. Click here to request permission.
                    </MotionMessage>
                )}
                {calcPermission === "denied" && (
                    <MotionMessage
                        key="denied"
                        variant="warning"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        mt={2}>
                        Access to your devices has been denied. Try changing the permission settings in your browser.
                    </MotionMessage>
                )}
            </AnimatePresence>
        </Box>
    );
};

const VolumeSetting: FunctionComponent<{
    isMutedSetting: LocalSetting<boolean>;
    volumeSetting: LocalSetting<number>;
}> = ({ isMutedSetting, volumeSetting }) => {
    const [isMuted, setIsMuted] = useLocalSetting(isMutedSetting, false);
    const [volume, setVolume] = useLocalSetting(volumeSetting, 100);
    return <VolumeSlider isMuted={isMuted} onIsMutedChanged={setIsMuted} volume={volume} onVolumeChanged={setVolume} />;
};

const VolumeSettingField = asField<HTMLDivElement, ExtractProps<typeof VolumeSetting> & IAsFieldProps>(VolumeSetting);

const DiceColorsSetting: FunctionComponent<{}> = () => {
    const [color, setColor] = useLocalSetting(diceColorSetting);

    return (
        <LobotomizedBox fullWidth flexDirection="column" alignItems="flex-start">
            <Radio
                name="diceColor"
                value="diceColor_default"
                label="Use campaign color (default)"
                checked={color == null}
                onChange={() => setColor(undefined)}
                onClick={() => setColor(undefined)}
            />
            <Radio
                name="diceColor"
                value="diceColor_pick"
                label="Pick color"
                checked={color != null && color !== "random"}
                onChange={() => setColor(diceColors[0])}
                onClick={() => setColor(diceColors[0])}
            />
            <CirclePicker
                css={{ marginLeft: theme.space[4] }}
                color={color}
                onChange={e => setColor(e.hex)}
                colors={diceColors}
            />
            <Radio
                name="diceColor"
                value="diceColor_random"
                label="Random (each die gets a random color)"
                checked={color === "random"}
                onChange={() => setColor("random")}
                onClick={() => setColor("random")}
            />
        </LobotomizedBox>
    );
};

const DiceColorsSettingField = asField<HTMLDivElement, ExtractProps<typeof DiceColorsSetting> & IAsFieldProps>(
    DiceColorsSetting
);

const SoftShadowsSetting: FunctionComponent<{}> = () => {
    const [isEnabled, setIsEnabled] = useLocalSetting(softShadowsSetting, true);
    return (
        <CheckboxField
            id={softShadowsEnabledSetting.id}
            label={softShadowsEnabledSetting.label}
            hint="Disabling soft shadows may increase performance on some devices."
            checked={isEnabled}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setIsEnabled(e.target.checked);
            }}
        />
    );
};

const AntialiasSetting: FunctionComponent<{}> = () => {
    const [type, setType] = useLocalSetting(antialiasTypeSetting, "SMAA");
    const [smaaQuality, setSmaaQuality] = useLocalSetting(smaaQualitySetting, SMAAPreset.HIGH);

    return (
        <Box flexDirection="column" fullWidth alignItems="flex-start">
            <SelectField
                label="Antialiasing type"
                required
                fullWidth
                value={type}
                mb={2}
                onChange={e => {
                    if (e.target.value === "MSAA") {
                        setType("MSAA");
                    } else if (e.target.value === "SMAA") {
                        setType("SMAA");
                    } else {
                        setType("None");
                    }
                }}>
                <option value="">None</option>
                <option value="MSAA">MSAA</option>
                <option value="SMAA">SMAA</option>
            </SelectField>
            <SelectField
                label="Antialiasing quality"
                required
                fullWidth
                disabled={type !== "SMAA"}
                value={smaaQuality.toString()}
                onChange={e => {
                    setSmaaQuality(parseInt(e.target.value));
                }}>
                <option value={SMAAPreset.LOW.toString()}>Low</option>
                <option value={SMAAPreset.MEDIUM.toString()}>Medium</option>
                <option value={SMAAPreset.HIGH.toString()}>High</option>
                <option value={SMAAPreset.ULTRA.toString()}>Ultra</option>
            </SelectField>
        </Box>
    );
};

export const UserSettings: FunctionComponent<{}> = () => {
    const { searchTerm, searchResults } = useVttApp();
    const search = searchResults?.find(o => o.categoryId === Pages.UserSettings)?.results;

    const items = search ? allSettings.filter(o => search.find(sr => sr.id === o.id)) : allSettings;

    return (
        <Box flexDirection="column" fullWidth flex="1 1 auto">
            <AnimatePresence>
                {searchTerm && (
                    <AnimatedListItem fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only settings matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>
            <ScrollableHack flexDirection="column" fullWidth flex="1 1 auto">
                <ScrollableTest minimal py={1} px={3}>
                    <Form fullWidth>
                        <AnimatePresence>
                            {items.map((o, i) => (
                                <AnimatedListItem key={o.id} index={i} fullWidth className="form__field">
                                    {o.render()}
                                </AnimatedListItem>
                            ))}
                        </AnimatePresence>
                    </Form>
                </ScrollableTest>
            </ScrollableHack>
        </Box>
    );
};

import React, { PropsWithChildren, useEffect, useImperativeHandle, useRef } from "react";
import { Mesh, Texture } from "three";
import { degToRad } from "../../../grid";
import { ThreeEvent, useFrame } from "@react-three/fiber";
import { motion } from "framer-motion-3d";
import { defaultMotionScale } from "../../motion";
import { MotionValue, Transition, animate, useMotionValue, usePresence } from "framer-motion";

export interface PointerEventsProps {
    onPointerDown?: (e: ThreeEvent<PointerEvent>) => void;
    onPointerMove?: (e: ThreeEvent<PointerEvent>) => void;
    onPointerUp?: (e: ThreeEvent<PointerEvent>) => void;
    onClick?: (evt: ThreeEvent<MouseEvent>) => void;
    onPointerOver?: (e: ThreeEvent<PointerEvent>) => void;
    onPointerOut?: (e: ThreeEvent<PointerEvent>) => void;
}

// TODO: The position is declared as x and y here, but it would be nice to declare it as LocalPixelPosition instead.
// The reason it's x and y is that they are more easily animateable, but there might be something to help with that?
export interface PositionProps {
    x: number;
    y: number;
    offsetX?: number;
    offsetY?: number;
}

export interface RotationProps {
    /**
     * The rotation of the mesh around the x and y points.
     * In degrees if a number, but radians if a MotionValue. TODO: Fix this! Should be the same one way or another.
     */
    rotation?: number | MotionValue<number>;
}

export interface MaterialProps {
    opacity?: number;
    color?: string;
    texture?: Texture;
    noMaterial?: boolean;
}

export interface MeshProps extends PointerEventsProps, PositionProps, RotationProps, MaterialProps {
    name?: string;
    scale?: number;
    zIndex: number;
    raycast?: any;
    renderOrder?: number;

    animateEnterExit?: boolean | "nolayout";
    initial?: AnimatableMeshProps | null; // Null to specify no initial animation.
    exit?: AnimatableMeshProps | null; // Null to specify no exit animation.
    transition?: Transition;

    layers?: number;
}

export interface AnimatableMeshProps {
    opacity?: number;
    color?: string;
    scale?: number;
}

export const MeshBase = React.forwardRef<Mesh, PropsWithChildren<MeshProps>>(
    (
        { children, noMaterial, renderOrder, animateEnterExit, initial, exit, transition, layers, rotation, ...props },
        ref
    ) => {
        const scaleValue = props.scale == null ? 1 : props.scale;
        const isTransparent = !!props.texture || (props.opacity != null && props.opacity < 1);

        const doLayoutAnim = animateEnterExit && animateEnterExit !== "nolayout";

        let r: number | MotionValue | undefined;
        if (rotation != null) {
            if (typeof rotation === "number") {
                r = rotation == null ? 0 : -degToRad(rotation);
            } else {
                r = rotation;
            }
        }

        // Have had problems with bugs in framer-motion (presumably) that result in crashes if we use animations for scale here.
        // For future reference, the errors occur if any scale value other than 1 is used in an animate, initial or exit of a
        // motion.mesh component.
        // Have to do it manually for now and maybe hope for something better later.
        // TODO: Currently this scale animation ignores the transition prop.
        const innerRef = useRef<Mesh>(null);
        useImperativeHandle(ref, () => innerRef.current as any);
        const [isPresent, safeToRemove] = usePresence();

        const scaleMotion = useMotionValue(
            doLayoutAnim ? scaleValue * (initial?.scale ?? defaultMotionScale) : scaleValue
        );
        useEffect(() => {
            animate(scaleMotion, scaleValue);
        }, [scaleMotion, scaleValue]);
        const exitRef = useRef<AnimatableMeshProps | null>();
        exitRef.current = exit;
        useEffect(() => {
            if (!isPresent && doLayoutAnim && exitRef.current !== null) {
                animate(scaleMotion, exitRef.current?.scale ?? defaultMotionScale, {
                    onComplete: safeToRemove,
                });
            } else {
                safeToRemove?.();
            }
        }, [isPresent, doLayoutAnim, safeToRemove, scaleMotion]);
        useFrame(() => {
            innerRef.current?.scale.set(scaleMotion.get(), scaleMotion.get(), 1);
        });

        // The outer mesh is essentially a pivot point, so that you can rotate the inner mesh around on offset
        // point rather than its own position.
        return (
            <motion.mesh
                position={[props.x, -props.y, props.zIndex != null ? props.zIndex / 1000 : 0]}
                rotation={[0, 0, r ?? 0]}>
                <mesh
                    raycast={props.raycast ? props.raycast : Mesh.prototype.raycast}
                    ref={innerRef as any}
                    name={props.name}
                    position={[
                        props.offsetX != null ? props.offsetX : 0,
                        props.offsetY != null ? -props.offsetY : 0,
                        0,
                    ]}
                    layers={layers}
                    onPointerDown={props.onPointerDown}
                    onPointerUp={props.onPointerUp}
                    onPointerMove={props.onPointerMove}
                    onPointerOver={props.onPointerOver}
                    onPointerOut={props.onPointerOut}
                    onClick={props.onClick}>
                    {children}
                    {!noMaterial && (
                        <motion.meshBasicMaterial
                            attach="material"
                            depthWrite={false}
                            map={props.texture}
                            transparent={isTransparent}
                            transition={transition}
                            initial={
                                animateEnterExit && initial !== null
                                    ? { opacity: initial?.opacity ?? 0, color: initial?.color ?? props.color }
                                    : false
                            }
                            animate={{ opacity: props.opacity ?? 1, color: props.color }}
                            exit={
                                animateEnterExit && exit !== null
                                    ? { opacity: exit?.opacity ?? 0, color: exit?.color }
                                    : undefined
                            }
                        />
                    )}
                </mesh>
            </motion.mesh>
        );
    }
);

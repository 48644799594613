import styled from "@emotion/styled";

import { containerStyles } from "./containerProps";

import { stackSystem, StackProps } from "./Stack";
import { overlayStyles } from "./overlay";
import { ExtractProps } from "../common";
import React from "react";

const boxShadow = ({ boxShadowSize = "l", theme }: any) => {
    const boxShadows = {
        s: 0,
        m: 1,
        l: 2,
        xl: 3,
        xxl: 4,
    };

    return {
        "&::before": {
            boxShadow: boxShadowSize === "none" ? "none" : theme.boxShadows[boxShadows[boxShadowSize]],
        },
    };
};

export type BoxShadowSize = "none" | "s" | "m" | "l" | "xl" | "xxl";

export interface ICardProps {
    boxShadowIntensity?: number;
    boxShadowSize?: BoxShadowSize;
}

export type CardProps = ICardProps & StackProps;

export const Card = styled("div")<CardProps>(
    ({ boxShadowSize, boxShadowIntensity }) => ({
        position: "relative",
        display: "flex",
        flexDirection: "column",
        transition: "background-color 300ms ease-out",
        "&::before":
            boxShadowSize !== "none"
                ? {
                      position: "absolute",
                      content: '""',
                      width: "100%",
                      height: "100%",
                      pointerEvents: "none",
                      borderRadius: "inherit",
                      opacity: boxShadowIntensity,
                      left: 0,
                      top: 0,
                      transition: "opacity 180ms ease-out",
                  }
                : undefined,
    }),
    containerStyles,
    boxShadow,
    stackSystem
);

Card.displayName = "Card";

Card.defaultProps = {
    boxShadowSize: "l",
    boxShadowIntensity: 1,
};

export const OverlayCard = styled(Card)`
    ${props => overlayStyles(props)}
`;

export const InOverlayCard = React.forwardRef<HTMLDivElement, ExtractProps<typeof Card>>(({ bg, ...props }, ref) => {
    return (
        <Card ref={ref} bg={bg ?? "transparency.2.background"} boxShadowSize="none" boxShadowIntensity={0} {...props} />
    );
});

// export const InOverlayCard = styled(Card)`
//     background: ${props => props.theme.colors.transparency[0].background};
// `;
// border: 1px solid ${props => props.theme.colors.transparency[2].grayscale[6]};

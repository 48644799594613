/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box } from "../../../components/primitives";
import { Button } from "../../../components/Button";
import { Toggle } from "../../../components/Toggle";
import React, { FunctionComponent } from "react";
import { useDispatch, useLocation } from "../../../components/contexts";
import { Markdown } from "../../../components/markdown";
import { defaultExit, defaultAnimate, defaultInitial, MotionBox } from "../../../components/motion";
import { applyConditions, modifyCharacter, modifyMonster } from "../actions/token";
import {
    creatureConditions,
    CreatureConditionName,
    DnD5ECharacterToken,
    DnD5EMonsterToken,
    DnD5EToken,
    DnD5ETokenTemplate,
    getRuleKey,
    isDnD5ECharacterToken,
    isDnD5EMonsterToken,
    NamedRuleRef,
} from "../common";
import { conditionToString, isCharacter, ResolvedCharacter, ResolvedMonster } from "../creature";
import { theme } from "../../../design";
import FrightenedIcon from "../../../components/icons/Frightened";
import BlindedIcon from "../../../components/icons/Blinded";
import CharmedIcon from "../../../components/icons/Charmed";
import DeafenedIcon from "../../../components/icons/Deafened";
import GrappledIcon from "../../../components/icons/Grappled";
import InvisibleIcon from "../../../components/icons/Invisible";
import PoisonedIcon from "../../../components/icons/Poisoned";
import UnconsciousIcon from "../../../components/icons/Unconscious";
import StunnedIcon from "../../../components/icons/Stunned";
import RestrainedIcon from "../../../components/icons/Restrained";
import ProneIcon from "../../../components/icons/Prone";
import PetrifiedIcon from "../../../components/icons/Petrified";
import ParalyzedIcon from "../../../components/icons/Paralyzed";
import IncapacitatedIcon from "../../../components/icons/Incapacitated";
import ExhaustionIcon from "../../../components/icons/Exhaustion";
import { Spell } from "../spells";
import { useRules } from "./hooks";
import ConcentrationIcon from "../../../components/icons/Concentration";
import { AnimatePresence } from "framer-motion";

export const ConditionIcon: FunctionComponent<{
    condition: CreatureConditionName;
    size?: number;
}> = ({ condition, size }) => {
    switch (condition) {
        case "blinded":
            return <BlindedIcon size={size as any} />;
        case "charmed":
            return <CharmedIcon size={size as any} />;
        case "deafened":
            return <DeafenedIcon size={size as any} />;
        case "exhaustion":
            return <ExhaustionIcon size={size as any} />;
        case "frightened":
            return <FrightenedIcon size={size as any} />;
        case "grappled":
            return <GrappledIcon size={size as any} />;
        case "incapacitated":
            return <IncapacitatedIcon size={size as any} />;
        case "invisible":
            return <InvisibleIcon size={size as any} />;
        case "paralyzed":
            return <ParalyzedIcon size={size as any} />;
        case "petrified":
            return <PetrifiedIcon size={size as any} />;
        case "poisoned":
            return <PoisonedIcon size={size as any} />;
        case "prone":
            return <ProneIcon size={size as any} />;
        case "restrained":
            return <RestrainedIcon size={size as any} />;
        case "stunned":
            return <StunnedIcon size={size as any} />;
        case "unconscious":
            return <UnconsciousIcon size={size as any} />;
    }
};

const ConditionToggle: FunctionComponent<{
    creature: ResolvedCharacter | ResolvedMonster;
    token: DnD5EToken | DnD5ETokenTemplate;
    condition: CreatureConditionName;
}> = ({ creature, token, condition }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const hasCondition = creature.conditions?.[condition];

    // TODO: If the creature has the condition because of an effect rather than because of it being manually applied, don't let it
    // be removed here - instead it should be removed by removing the effect?
    return (
        <MotionBox layout flexDirection="column" alignItems="stretch">
            <Box flexDirection="row" justifyContent="stretch">
                <Box flexShrink={1} mr={2}>
                    <ConditionIcon condition={condition} />
                </Box>
                <Box flexGrow={1} justifyContent="stretch">
                    <Markdown>{`:condition[${conditionToString(condition)}]{condition=${condition}}`}</Markdown>
                </Box>
                <Toggle
                    css={{ flexShrink: 1 }}
                    toggled={!!hasCondition}
                    disabled={creature.conditions?.[condition] !== creature.resolvedFrom.conditions?.[condition]}
                    onClick={() => {
                        dispatch(
                            applyConditions(campaign, location, [token], {
                                [condition]: hasCondition ? undefined : true,
                            })
                        );
                    }}
                />
            </Box>
        </MotionBox>
    );
};

export const ConditionEditor: FunctionComponent<{
    creature: ResolvedCharacter | ResolvedMonster;
    token: DnD5EToken | DnD5ETokenTemplate;
}> = ({ creature, token }) => {
    const rules = useRules();
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    let concentratingOn: Spell | undefined;
    if (creature.concentrating?.name && creature.concentrating?.source) {
        concentratingOn = rules.spells.get(creature.concentrating as NamedRuleRef);
    }

    return (
        <MotionBox layout css={{ gap: theme.space[2] }} flexDirection="column" alignItems="stretch">
            {creatureConditions
                .filter(o => o !== "exhaustion")
                .map(o => (
                    <ConditionToggle key={o} creature={creature} token={token} condition={o} />
                ))}

            {isCharacter(creature) && (
                <MotionBox layout flexDirection="column" alignItems="stretch">
                    <Box flexDirection="row" justifyContent="stretch">
                        <Box flexShrink={1} mr={2}>
                            <ConditionIcon condition="exhaustion" />
                        </Box>
                        <Box flexGrow={1} justifyContent="stretch">
                            <Markdown>{`:condition[${conditionToString(
                                "exhaustion"
                            )}]{condition=exhaustion}`}</Markdown>
                        </Box>
                    </Box>

                    <Box flexDirection="row" my={2} justifyContent="flex-start">
                        <Button
                            variant="inOverlayTransparent"
                            toggled={creature.exhaustion == null}
                            onClick={() =>
                                dispatch(
                                    modifyCharacter(campaign, location, [token as DnD5ECharacterToken], {
                                        exhaustion: undefined,
                                    })
                                )
                            }>
                            --
                        </Button>
                        <Button
                            variant="inOverlayTransparent"
                            toggled={creature.exhaustion === 1}
                            onClick={() =>
                                dispatch(
                                    modifyCharacter(campaign, location, [token as DnD5ECharacterToken], {
                                        exhaustion: 1,
                                    })
                                )
                            }>
                            1
                        </Button>
                        <Button
                            variant="inOverlayTransparent"
                            toggled={creature.exhaustion === 2}
                            onClick={() =>
                                dispatch(
                                    modifyCharacter(campaign, location, [token as DnD5ECharacterToken], {
                                        exhaustion: 2,
                                    })
                                )
                            }>
                            2
                        </Button>
                        <Button
                            variant="inOverlayTransparent"
                            toggled={creature.exhaustion === 3}
                            onClick={() =>
                                dispatch(
                                    modifyCharacter(campaign, location, [token as DnD5ECharacterToken], {
                                        exhaustion: 3,
                                    })
                                )
                            }>
                            3
                        </Button>
                        <Button
                            variant="inOverlayTransparent"
                            toggled={creature.exhaustion === 4}
                            onClick={() =>
                                dispatch(
                                    modifyCharacter(campaign, location, [token as DnD5ECharacterToken], {
                                        exhaustion: 4,
                                    })
                                )
                            }>
                            4
                        </Button>
                        <Button
                            variant="inOverlayTransparent"
                            toggled={creature.exhaustion === 5}
                            onClick={() =>
                                dispatch(
                                    modifyCharacter(campaign, location, [token as DnD5ECharacterToken], {
                                        exhaustion: 5,
                                    })
                                )
                            }>
                            5
                        </Button>
                        <Button
                            variant="inOverlayTransparent"
                            toggled={creature.exhaustion === 6}
                            onClick={() =>
                                dispatch(
                                    modifyCharacter(campaign, location, [token as DnD5ECharacterToken], {
                                        exhaustion: 6,
                                    })
                                )
                            }>
                            6
                        </Button>
                    </Box>
                </MotionBox>
            )}

            <AnimatePresence>
                {concentratingOn && (
                    <MotionBox
                        layout
                        fullWidth
                        justifyContent="flex-start"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <Box flexShrink={1} mr={2}>
                            <ConcentrationIcon />
                        </Box>
                        <Box flexShrink={1} flexGrow={1} justifyContent="stretch">
                            <Markdown>{`:condition[Concentrating]{condition=concentration~phb} on :spell[${
                                concentratingOn.name
                            }]{spell="${getRuleKey(concentratingOn)}"}`}</Markdown>
                        </Box>
                        <Button
                            ml={2}
                            onClick={() => {
                                if (isDnD5ECharacterToken(token)) {
                                    dispatch(
                                        modifyCharacter(campaign, location, [token as DnD5ECharacterToken], {
                                            concentrating: undefined,
                                        })
                                    );
                                } else if (isDnD5EMonsterToken(token)) {
                                    dispatch(
                                        modifyMonster(campaign, location, [token as DnD5EMonsterToken], {
                                            concentrating: undefined,
                                        })
                                    );
                                }
                            }}>
                            Stop
                        </Button>
                    </MotionBox>
                )}
            </AnimatePresence>
        </MotionBox>
    );
};

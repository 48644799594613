import React from 'react'
  
  const DoorIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 512 512'
      width={size}
      height={size}
      stroke={color}
    >
      <path d="M271.125 17.813v145.562h66.938V34.5c-20.606-10.187-43.6-15.728-66.938-16.688zm-18.688.218c-22.768 1.465-45.25 7.305-65.53 17.376v127.97h65.53V18.03zM356.75 45.5v117.875h61.844c-2.66-48.27-21.532-83.515-48.656-107.53-4.2-3.72-8.6-7.17-13.188-10.345zm-188.53.813c-5.32 3.63-10.403 7.6-15.22 11.906-27.108 24.233-45.9 58.953-48.656 105.155h63.875V46.312zm269.936 97.312v19.5H459v-19.5h-20.844zm0 38.188v.25H84.78v32h353.376v.156H459v-32.407h-20.844zM104 232.75v130.375h64.22v-26.28c-2.152.362-4.346.56-6.595.56-21.91 0-39.875-17.965-39.875-39.874 0-21.907 17.964-39.874 39.875-39.874 2.25 0 4.443.2 6.594.563v-25.47H104zm82.906 0v34.03c8.893 7.334 14.594 18.41 14.594 30.75s-5.7 23.42-14.594 30.75v34.845h65.53V232.75h-65.53zm84.22 0v130.375h66.937V232.75h-66.938zm85.624 0v130.375h62.156V232.75H356.75zm81.406.156v17.938H459v-17.938h-20.844zm-276.53 43.438c-11.81 0-21.19 9.38-21.19 21.187 0 11.81 9.378 21.19 21.19 21.19 11.81 0 21.186-9.38 21.186-21.19s-9.376-21.186-21.187-21.186zm276.53 67.03v19.907H459v-19.905h-20.844zM84.78 381.813v32h353.376v.157H459v-32h-18.47v-.158H84.78zM104 432.5v59.844h64.22V432.5H104zm82.906 0v59.844h65.53V432.5h-65.53zm84.22 0v59.844h66.937V432.5h-66.938zm85.624 0v59.844h62.156V432.5H356.75zm81.406.156v17.938H459v-17.938h-20.844z" fill={color} fillOpacity="1"></path>
    </svg>
  )
  
  DoorIcon.displayName = 'DoorToolIcon'
  
  DoorIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
  }
  
  export default DoorIcon
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, PropsWithChildren, ReactElement, ReactNode, useId, useState } from "react";
import { Box, BoxProps, Heading } from "../../../../components/primitives";
import { theme } from "../../../../design";
import {
    MotionBox,
    sectionInitial,
    sectionAnimate,
    sectionExit,
    MotionLobotomizedBox,
} from "../../../../components/motion";
import { AnimatePresence, LayoutGroup } from "framer-motion";

const TabHeader: FunctionComponent<{
    tabId: string;
    panelId: string;
    label: string | ReactElement;
    isSelected: boolean;
    onSelected: () => void;
}> = ({ tabId, panelId, label, isSelected, onSelected }) => {
    return (
        <Box
            id={tabId}
            flexDirection="column"
            role="tab"
            aria-selected={isSelected}
            aria-controls={panelId}
            alignItems="stretch"
            onClick={onSelected}
            mr={2}
            css={{
                cursor: "pointer",
                color: isSelected ? theme.colors.grayscale[1] : theme.colors.grayscale[4],
                "&:hover": {
                    color: theme.colors.grayscale[1],
                },
            }}>
            <Heading as="h5" mb={isSelected ? 0 : "2px"} display="flex" color="unset">
                {label}
            </Heading>
            {isSelected && <MotionBox layoutId="selected" height={2} bg="grayscale.2" />}
        </Box>
    );
};

export const Tab: FunctionComponent<PropsWithChildren<{ id: string; label: string | ReactElement }>> = ({
    children,
}) => {
    return <React.Fragment>{children}</React.Fragment>;
};

let tabId = 0;
export const TabBox: FunctionComponent<PropsWithChildren<{} & BoxProps>> = ({ children, ...boxProps }) => {
    const [selected, setSelected] = useState(0);
    const [tabBoxId] = useState("Tab" + tabId++);

    const headers: ReactNode[] = [];
    const tabChildren: ReactNode[] = [];
    React.Children.forEach(children, element => {
        if (!React.isValidElement(element)) {
            return;
        }

        // TODO: Check that the element is the correct type.
        const { id, label } = element.props;
        const index = headers.length;
        headers.push(
            <TabHeader
                key={id}
                tabId={`${tabBoxId}-tab-${index}`}
                panelId={`${tabBoxId}-panel-${index}`}
                label={label}
                isSelected={index === selected}
                onSelected={() => setSelected(index)}
            />
        );
        tabChildren.push(element);
    });

    const id = useId();

    return (
        <Box flexDirection="column" alignItems="stretch" {...(boxProps as unknown as any)}>
            <MotionLobotomizedBox
                layout
                role="tablist"
                flexDirection="row"
                flexShrink={1}
                flexGrow={0}
                justifyContent="flex-start">
                <LayoutGroup id={id}>{headers}</LayoutGroup>
            </MotionLobotomizedBox>
            <AnimatePresence mode="wait" initial={false}>
                <MotionBox
                    layout="position"
                    key={selected}
                    id={`${tabBoxId}-panel-${selected}`}
                    aria-labelledby={`${tabBoxId}-tab-${selected}`}
                    flexDirection="column"
                    initial={sectionInitial}
                    animate={sectionAnimate}
                    exit={sectionExit}>
                    {tabChildren[selected]}
                </MotionBox>
            </AnimatePresence>
        </Box>
    );
};

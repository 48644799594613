/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent } from "react";
import { Heading, Select } from "../../../../components/primitives";
import { Alignment, Character, ResolvedCharacter, alignmentToString, alignments } from "../../creature";
import { LobotomizedBox } from "../../../../components/common";
import { FeatureChoices } from "./FeatureEditor";
import { DnD5ECharacterTemplate, DnD5ECharacterToken, getRuleKey, NamedRuleRef } from "../../common";
import { useRules } from "../hooks";
import { AnimatePresence } from "framer-motion";
import {
    MotionLobotomizedBox,
    defaultInitial,
    defaultAnimate,
    defaultExit,
    MotionForm,
} from "../../../../components/motion";
import { useDispatch, useLocation } from "../../../../components/contexts";
import { modifyCharacter } from "../../actions/token";
import { Markdown, MarkdownEditorField } from "../../../../components/markdown";
import { ScrollableTest } from "../../../../components/ScrollableTest";
import { ShowMore } from "../ShowMore";
import { SelectField } from "../../../../components/Form";
import { mapKeyedList } from "../../../../common";

export const DescriptionPage: FunctionComponent<{
    token: DnD5ECharacterTemplate | DnD5ECharacterToken;
    resolvedCharacter: ResolvedCharacter;
}> = ({ token, resolvedCharacter }) => {
    const { backgrounds } = useRules();
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const character = token.dnd5e as Character;
    return (
        <ScrollableTest fullWidth fullHeight minimal px={3}>
            <LobotomizedBox
                flexDirection="column"
                fullWidth
                fullHeight
                justifyContent="flex-start"
                alignItems="flex-start">
                <Heading as="h5">Background</Heading>
                <Select
                    minWidth="200px"
                    value={
                        character.background
                            ? JSON.stringify({
                                  name: character.background.name,
                                  source: character.background.source,
                              })
                            : undefined
                    }
                    onChange={e => {
                        if (e.target.value === "") {
                            dispatch(
                                modifyCharacter(campaign, location, [token], {
                                    background: undefined,
                                })
                            );
                        } else {
                            const value = JSON.parse(e.target.value) as NamedRuleRef;
                            const newBg = Object.assign({}, character.background, value);
                            dispatch(
                                modifyCharacter(campaign, location, [token], {
                                    background: newBg,
                                })
                            );
                        }
                    }}>
                    <option value="">- Choose a background -</option>
                    {backgrounds.all.map(o => (
                        <option key={getRuleKey(o)} value={JSON.stringify({ name: o.name, source: o.source })}>
                            {o.name}
                        </option>
                    ))}
                </Select>
                <AnimatePresence mode="wait">
                    {resolvedCharacter.background && (
                        <MotionLobotomizedBox
                            flexDirection="column"
                            alignItems="flex-start"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <FeatureChoices
                                feature={resolvedCharacter.background}
                                character={resolvedCharacter}
                                selections={character.background}
                                onSelectionsChanged={selections => {
                                    const newBg = Object.assign({}, selections, {
                                        name: character.background!.name,
                                        source: character.background!.source,
                                    });
                                    dispatch(
                                        modifyCharacter(campaign, location, [token], {
                                            background: newBg,
                                        })
                                    );
                                }}
                            />
                            {resolvedCharacter.background.backgroundContent && (
                                <ShowMore mt={3}>
                                    {mapKeyedList(resolvedCharacter.background.backgroundContent, (o, i, k) => (
                                        <Markdown key={k} mt={i > 0 ? 2 : 0}>
                                            {o.name != null ? `### ${o.name}\n${o.content}` : o.content}
                                        </Markdown>
                                    ))}
                                </ShowMore>
                            )}
                        </MotionLobotomizedBox>
                    )}
                </AnimatePresence>

                <MotionForm layout mt={3}>
                    <SelectField
                        label="Alignment"
                        value={resolvedCharacter.alignment ?? ""}
                        onChange={e => {
                            dispatch(
                                modifyCharacter(campaign, location, [token], {
                                    alignment: e.target.value === "" ? undefined : (e.target.value as Alignment),
                                })
                            );
                        }}>
                        <option value="">- Choose an alignment -</option>
                        {alignments.map(o => (
                            <option key={o} value={o}>
                                {alignmentToString(o)}
                            </option>
                        ))}
                    </SelectField>

                    <MarkdownEditorField
                        label="Appearance"
                        defaultMarkdown={character.appearance ?? ""}
                        editable
                        minLines={6}
                        onMarkdownChange={md => {
                            dispatch(modifyCharacter(campaign, location, [token], { appearance: md }));
                        }}
                        debounceChange={2000}
                    />
                </MotionForm>
            </LobotomizedBox>
        </ScrollableTest>
    );
};

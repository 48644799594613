import React, { FunctionComponent, useState, useEffect, PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { Box, Card } from "./primitives";
import { Message } from "./Message";
import { Button } from "./Button";
import { Cross1Icon } from "@radix-ui/react-icons";
import { usePermanentNotification } from "./notification";

const HelpCard = styled(Card)`
    pointer-events: all;
`;

interface HelpProps {
    id: string;
    canDismiss?: boolean;
}

const dataText = localStorage.getItem("help");
var data: { [id: string]: string } = dataText ? JSON.parse(dataText) : {};

export const Help: FunctionComponent<PropsWithChildren<HelpProps>> = ({ id, canDismiss, children }) => {
    let allowDismiss = canDismiss != null ? canDismiss : true;
    const [isDismissed, setIsDismissed] = useState(!!data[id]);

    // Bring the help back if the user presses F1 while it's relevant.
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (isDismissed && e.keyCode === 112) {
                delete data[id];
                localStorage.setItem("help", JSON.stringify(data));
                setIsDismissed(false);

                e.stopPropagation();
                e.preventDefault();
            }
        };
        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [isDismissed, id]);

    usePermanentNotification(!isDismissed, () => {
        return (
            <Box key={id} fullWidth>
                <HelpCard borderRadius={3} bg="background" boxShadowSize="m" boxShadowIntensity={0.7} width={400}>
                    <Box
                        flex={1}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        borderRadius="inherit"
                        overflow="hidden"
                        fullWidth>
                        <Message>{children}</Message>
                        {allowDismiss && (
                            <Button
                                variant="tertiary"
                                size="s"
                                shape="square"
                                ml={4}
                                mt={3}
                                mr={3}
                                onClick={e => {
                                    e.stopPropagation();
                                    data[id] = new Date().toISOString();
                                    localStorage.setItem("help", JSON.stringify(data));
                                    setIsDismissed(true);
                                }}>
                                <Cross1Icon fill="grayscale.2" />
                            </Button>
                        )}
                    </Box>
                </HelpCard>
            </Box>
        );
    });

    return <React.Fragment></React.Fragment>;
};

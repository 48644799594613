/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VTTStore, SessionPlayer, SessionConnection, GmSection, isLocation } from "../store";
import CampaignHost from "./campaignhost";
import { connect, DispatchProp } from "react-redux";
import { joinSession, leaveSession, initSession } from "../actions/session";
import { RouteComponentProps } from "react-router-dom";
import { useNotifications } from "./Notifications";
import { Message } from "./Message";
import {
    AppStateContext,
    AppStateProps,
    CameraContext,
    CameraContextProps,
    CampaignContext,
    getRole,
    SelectionContext,
    SessionConnectionContext,
    SessionContext,
    useUser,
} from "./contexts";
import { useVttApp } from "./common";
import { defaultSectionsNoSettings, settingsSection } from "./Sidebar";
import { getSelectedTokens } from "./selection";
import { Text } from "./primitives/Text";
import { Box } from "./primitives";
import { AnimatePresence } from "framer-motion";
import { MotionHeading, MotionMessage, MotionText, defaultAnimate, defaultExit, defaultInitial } from "./motion";

interface UrlParams {
    id: string;
}

const mapStateToProps = (state: VTTStore, ownProps: any) => {
    return {
        connection: state.sessions[ownProps.match.params.id],
    };
};

function setItemsIfChanged(existingItems: string[], newItems: string[] | undefined) {
    if (newItems == null || newItems.length === 0) {
        if (existingItems.length === 0) {
            return existingItems;
        }

        return newItems ?? [];
    }

    if (newItems.length === existingItems.length) {
        let hasChanged = false;
        for (let i = 0; i < newItems.length; i++) {
            if (newItems[i] !== existingItems[i]) {
                hasChanged = true;
                break;
            }
        }

        if (!hasChanged) {
            return existingItems;
        }
    }

    return newItems;
}

const SessionHost: FunctionComponent<
    ReturnType<typeof mapStateToProps> & RouteComponentProps<UrlParams> & DispatchProp
> = props => {
    const dispatch = props.dispatch;
    const campaignId = props.match.params.id;

    // TODO: Promise option is missing from the add notification options, but it still seems to work...
    const [add, setPosition] = useNotifications();
    const resolveJoined = useRef<((value: unknown) => void) | undefined>();

    const api = props.connection?.api;
    useEffect(() => {
        if (api) {
            const handler = () => {
                add({
                    content: (
                        <Message variant="error" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                            An error occurred attempting to apply your changes. The error has been logged and the last
                            verified state has been restored.
                        </Message>
                    ),
                    canDismiss: true,
                    showLife: false,
                    timeout: 999999999, // TODO: Check how to add notifications that don't expire.
                });
            };
            api.patchError.on(handler);
            return () => {
                api.patchError.off(handler);
            };
        }
    }, [api, add]);

    useEffect(() => {
        dispatch(initSession(campaignId));
        dispatch(joinSession(campaignId));
        setPosition("top");
        const promise = new Promise(resolve => (resolveJoined.current = resolve));
        add({
            content: <Message>Joining game…</Message>,
            canDismiss: false,
            promise: () => promise,
        } as any);
        return () => {
            dispatch(leaveSession(campaignId));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [reconnectIn, setReconnectIn] = useState<number>();
    const isDisconnected = props.connection?.isDisconnected ?? false;
    const isJoiningSession = props.connection?.isJoiningSession ?? false;
    useEffect(() => {
        let handle: number | undefined;
        if (isJoiningSession) {
            return;
        }

        if (isDisconnected) {
            if (reconnectIn == null) {
                setReconnectIn(10);
            }

            // Attempt to reconnect every now and again.
            handle = setTimeout(() => {
                if (reconnectIn === 1) {
                    setReconnectIn(undefined);
                    dispatch(joinSession(campaignId));
                } else if (reconnectIn != null) {
                    setReconnectIn(reconnectIn - 1);
                }
            }, 1000) as any;
        } else if (reconnectIn !== 0) {
            setReconnectIn(undefined);
        }

        return () => {
            if (handle != null) {
                clearInterval(handle);
            }
        };
    }, [isDisconnected, isJoiningSession, reconnectIn, campaignId, dispatch]);

    const isSessionReady = !!(props.connection && props.connection.session);
    useEffect(() => {
        if (isSessionReady && resolveJoined.current) {
            resolveJoined.current(undefined);
            resolveJoined.current = undefined;
        }
    }, [isSessionReady]);

    // Keep track of the players. When one is added or removed, show a notification.
    const previousPlayers = useRef<{ [userId: string]: SessionPlayer } | undefined>();
    useEffect(() => {
        let session = props.connection ? props.connection.session : undefined;
        if (session) {
            if (previousPlayers.current) {
                for (const userId in previousPlayers.current) {
                    if (!session.players[userId]) {
                        // The player has left!
                        setPosition("top");
                        add({
                            content: <Message>{`${session.campaign.players[userId].name} has left.`}</Message>,
                            canDismiss: true,
                            timeout: 3000,
                        });
                    }
                }

                for (const userId in session.players) {
                    if (!previousPlayers.current[userId]) {
                        // The player has joined!
                        setPosition("top");
                        add({
                            content: <Message>{`${session.campaign.players[userId].name} has joined.`}</Message>,
                            canDismiss: true,
                            timeout: 3000,
                        });
                    }
                }
            }

            previousPlayers.current = session.players;
        }
    });

    // A LOT of things use the session & campaign objects, so we give them separate contexts and build/memo their
    // context data individually, to make sure that we minimise renders by ensuring a change in the session doesn't
    // (necessarily) affect the campaign. Also keep everything we can well away from the SessionConnectionContext, as
    // hardly anything needs anything that is only available from that.
    const sessionData = useMemo(
        () =>
            isSessionReady && props.connection.api && props.connection.system
                ? {
                      session: props.connection.session!,
                      api: props.connection.api,
                      system: props.connection.system,
                  }
                : undefined,
        [isSessionReady, props.connection?.session, props.connection?.api, props.connection?.system]
    );
    const campaignData = useMemo(
        () =>
            isSessionReady && props.connection.api && props.connection.system
                ? {
                      campaign: props.connection.session!.campaign,
                      api: props.connection.api,
                      system: props.connection.system,
                  }
                : undefined,
        [isSessionReady, props.connection?.api, props.connection?.system, props.connection?.session]
    );

    const user = useUser();
    const role = getRole(user, campaignData?.campaign);

    const gameSystemSections = campaignData?.system.getGlobalSections ? campaignData.system.getGlobalSections() : [];
    const sections = [...defaultSectionsNoSettings, ...gameSystemSections, settingsSection].filter(
        o => role != null && o.roles.indexOf(role) >= 0
    );

    const [page, setPage] = useState<GmSection>(sections[0]);
    const sectionsRef = useRef(sections);
    sectionsRef.current = sections;
    useEffect(() => {
        setPage(sectionsRef.current[0]);
    }, [role]);

    const [primarySelection, setPrimarySelection] = useState<string[]>([]);
    const [secondarySelection, setSecondarySelection] = useState<string[]>([]);
    const selection = useMemo(() => {
        return {
            primary: primarySelection,
            secondary: secondarySelection,
            setSelection: (primary: string[], secondary?: string[]) => {
                // If the selection isn't actually changing, then don't set it to a new array to avoid unnecessary rendering.
                primary = setItemsIfChanged(primarySelection, primary);
                secondary = setItemsIfChanged(secondarySelection, secondary);

                if (primary !== primarySelection) {
                    setPrimarySelection(primary);
                }

                if (secondary !== secondarySelection) {
                    setSecondarySelection(secondary ?? []);
                }
            },
        };
    }, [primarySelection, secondarySelection]);

    // The focused token is the one that is targeted by any token related actions in the UI. Generally this will be
    // the selected token, if any - but it can be overridden, by a token edit dialog for example.
    const [focusedTokenOverride, setFocusedTokenOverride] = useState<string>();
    const player = campaignData?.campaign.players[user.id];
    const location = player ? campaignData!.campaign.locations[player.location] : undefined;
    const focusedToken = focusedTokenOverride
        ? focusedTokenOverride
        : isLocation(location)
        ? getSelectedTokens(selection.primary, location, selection.secondary)[0]?.id
        : undefined;

    const cameraMode = useVttApp(state => state.cameraMode);
    const isCameraChanging = useVttApp(state => state.isCameraChanging);
    const isPerspective = cameraMode === "perspective" || isCameraChanging;
    const hasBeenPerspective = useRef<boolean>(false);
    hasBeenPerspective.current = hasBeenPerspective.current || isPerspective;

    const appState = useMemo<AppStateProps>(
        () => ({
            searchPropertiesSection: page,
            setSearchPropertiesSection: setPage,
            searchPropertiesSections: sectionsRef,

            focusedToken: focusedToken,
            setFocusedToken: setFocusedTokenOverride,
        }),
        [page, focusedToken]
    );

    const [lightingReqs, setLightingReqs] = useState<{ [id: string]: boolean }>({});
    const lightReqsRef = useRef<{ [id: string]: boolean }>(lightingReqs);
    lightReqsRef.current = lightingReqs;
    const setRequiresPerspectiveLighting = useCallback((key: string, requiresLighting: boolean) => {
        if (!!lightReqsRef.current[key] !== requiresLighting) {
            if (!requiresLighting) {
                const newLightingReqs = Object.assign({}, lightReqsRef.current);
                delete newLightingReqs[key];
                setLightingReqs(newLightingReqs);
            } else {
                setLightingReqs(Object.assign({}, lightReqsRef.current, { [key]: requiresLighting }));
            }
        }
    }, []);
    const cameraState = useMemo<CameraContextProps>(
        () => ({
            isPerspective: isPerspective,
            isOrthographic: !isPerspective,
            hasPerspectiveLighting: isPerspective || Object.values(lightingReqs).some(o => o),
            setRequiresPerspectiveLighting: setRequiresPerspectiveLighting,
            hasBeenPerspective: hasBeenPerspective.current,
        }),
        [isPerspective, lightingReqs, setRequiresPerspectiveLighting]
    );

    return (
        <React.Fragment>
            <AnimatePresence>
                {isDisconnected && (
                    <Box fullWidth fullHeight gridArea="main" flexDirection="column">
                        <MotionMessage
                            flexDirection="column"
                            flexGrow="0 !important"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            {!props.connection.isServerError && (
                                <React.Fragment>
                                    <MotionHeading layout as="h3" color="inherit">
                                        Disconnected
                                    </MotionHeading>
                                    <MotionText layout mt={2} color="inherit" maxWidth={600}>
                                        The connection to the server has been lost.
                                    </MotionText>
                                </React.Fragment>
                            )}
                            {props.connection.isServerError && (
                                <React.Fragment>
                                    <MotionHeading layout as="h3" color="inherit">
                                        Server Error
                                    </MotionHeading>
                                    <MotionText layout mt={2} color="inherit" maxWidth={600}>
                                        There was an problem applying your changes on the server that has caused your
                                        session to become out of sync. This issue has been logged.
                                    </MotionText>
                                </React.Fragment>
                            )}
                            {reconnectIn != null && (
                                <Text mt={2} color="grayscale.2">
                                    Attempting to rejoin game in {reconnectIn} seconds…
                                </Text>
                            )}
                            {props.connection?.isJoiningSession && (
                                <Text mt={2} color="grayscale.2">
                                    Attempting to rejoin game…
                                </Text>
                            )}
                        </MotionMessage>
                    </Box>
                )}
            </AnimatePresence>

            {sessionData && campaignData && !isDisconnected && (
                <AppStateContext.Provider value={appState}>
                    <CameraContext.Provider value={cameraState}>
                        <SelectionContext.Provider value={selection}>
                            <SessionConnectionContext.Provider value={props.connection as Required<SessionConnection>}>
                                <SessionContext.Provider value={sessionData}>
                                    <CampaignContext.Provider value={campaignData}>
                                        <campaignData.system.renderCampaign>
                                            <CampaignHost />
                                        </campaignData.system.renderCampaign>
                                    </CampaignContext.Provider>
                                </SessionContext.Provider>
                            </SessionConnectionContext.Provider>
                        </SelectionContext.Provider>
                    </CameraContext.Provider>
                </AppStateContext.Provider>
            )}
        </React.Fragment>
    );
};

export default connect(mapStateToProps)(SessionHost);

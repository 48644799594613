import React from 'react'
  
  const PlayModeIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 512 512'
      width={size}
      height={size}
      stroke={color}
    >
      <path d="M253.8 30.44c-9.5 1.2-18.1 12.3-18.1 26.8 0 8 2.7 15 6.7 19.9l4.9 5.8-7.5 1.4c-5.2 1-9 3.8-12.3 8.3-3.4 4.6-5.9 10.76-7.6 18.06-3.2 13.1-3.9 29.1-4 43.3h17.5l1.6 21.9c6.8-.3 13.8-.5 20.9-.5 7.5 0 14.8.2 21.9.6l1.5-22h16.6c0-14.3-.2-30.6-3.1-43.9-1.6-7.2-4-13.46-7.3-17.96s-7.4-7.2-13.3-8.1l-7.6-1.2 4.8-6c3.9-4.8 6.5-11.8 6.5-19.6 0-15.4-9.6-26.8-20.1-26.8zM61.9 174.4c-9.5 1.2-18.1 12.3-18.1 26.8 0 8 2.7 15 6.7 19.9l4.9 5.8-7.5 1.4c-5.2 1-9 3.8-12.3 8.3-3.4 4.6-5.9 10.8-7.6 18.1-3.2 13.1-3.9 29.1-4 43.3h17.5l4.7 64.3c12.2 2.8 25.2 2.6 36.94 0l4.3-64.3H104c0-14.3-.2-30.6-3.1-43.9-1.57-7.2-3.97-13.5-7.27-18-3.29-4.5-7.39-7.2-13.29-8.1l-7.64-1.2 4.8-6c3.94-4.8 6.54-11.8 6.54-19.6 0-15.4-9.64-26.8-20.14-26.8zm384 0c-9.5 1.2-18.1 12.3-18.1 26.8 0 8 2.7 15 6.7 19.9l4.9 5.8-7.5 1.4c-5.2 1-9 3.8-12.3 8.3-3.4 4.6-5.9 10.8-7.6 18.1-3.2 13.1-3.9 29.1-4 43.3h17.5l4.7 64.3c12.2 2.8 25.2 2.6 36.9 0l4.3-64.3H488c0-14.3-.2-30.6-3.1-43.9-1.6-7.2-4-13.5-7.3-18s-7.4-7.2-13.3-8.1l-7.6-1.2 4.8-6c3.9-4.8 6.5-11.8 6.5-19.6 0-15.4-9.6-26.8-20.1-26.8zm-190 19c-36.7 0-69.9 5.3-93.2 13.5-11.6 4.1-20.7 9-26.3 13.7-5.7 4.7-7.5 8.6-7.5 11.8 0 3.2 1.8 7.1 7.5 11.8 5.6 4.7 14.7 9.5 26.3 13.7 23.3 8.2 56.5 13.5 93.2 13.5s69.9-5.3 93.2-13.5c11.6-4.2 20.7-9 26.3-13.7 5.7-4.7 7.5-8.6 7.5-11.8 0-3.2-1.8-7.1-7.5-11.8-5.6-4.7-14.7-9.6-26.3-13.7-23.3-8.2-56.5-13.5-93.2-13.5zm-105 79.2v45.8h18v-39.8c-4.2-1.2-8.3-2.4-12.2-3.8-2-.7-3.9-1.4-5.8-2.2zm210 0c-1.9.8-3.8 1.5-5.8 2.2-3.9 1.4-8 2.6-12.2 3.8v39.8h18zm-107.1 19c-9.5 1.2-18.1 12.3-18.1 26.8 0 8 2.7 15 6.7 19.9l4.9 5.8-7.5 1.4c-5.2 1-9 3.8-12.3 8.3-3.4 4.6-5.9 10.8-7.6 18.1-3.2 13.1-3.9 29.1-4 43.3h17.5l4.7 64.3c12.2 2.8 25.2 2.6 36.9 0l4.3-64.3h16.6c0-14.3-.2-30.6-3.1-43.9-1.6-7.2-4-13.5-7.3-18s-7.4-7.2-13.3-8.1l-7.6-1.2 4.8-6c3.9-4.8 6.5-11.8 6.5-19.6 0-15.4-9.6-26.8-20.1-26.8z" fill={color} fillOpacity="1"></path>
    </svg>
  )
  
  PlayModeIcon.displayName = 'PlayModeIcon'
  
  PlayModeIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default PlayModeIcon
import React, { FunctionComponent, useMemo, RefObject, useCallback } from "react";
import { Mesh, ShapeGeometry } from "three";
import { MeshProps, MeshBase, MaterialProps } from "./common";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { withCursor, withDragEvents } from "./dragevents";
import { useForwardedRef } from "../../utils";
import { degToRad } from "../../../grid";

interface EllipseProps extends MeshProps, MaterialProps {
    xRadius: number;
    yRadius: number;
    angle?: number;
}

export const Ellipse: FunctionComponent<EllipseProps> = React.forwardRef<Mesh, EllipseProps>(
    ({ xRadius, yRadius, angle, ...props }, forwardedRef) => {
        const ref = useForwardedRef(forwardedRef);

        const cache = useMemo<Partial<EllipseProps> & { geometryNeedsUpdating: boolean }>(
            () => ({
                geometryNeedsUpdating: true,
            }),
            []
        );
        if (cache.xRadius == null) {
            cache.xRadius = xRadius;
            cache.yRadius = yRadius;
            cache.angle = angle;
        } else {
            if (cache.xRadius !== xRadius || cache.yRadius !== yRadius) {
                cache.xRadius = xRadius;
                cache.yRadius = yRadius;
                cache.geometryNeedsUpdating = true;
            }

            if (cache.angle !== angle) {
                cache.angle = angle;
                cache.geometryNeedsUpdating = true;
            }
        }

        const callback = useCallback(() => {
            if (cache.geometryNeedsUpdating && cache.xRadius != null && cache.yRadius != null) {
                cache.geometryNeedsUpdating = false;

                // TODO: The angle is modified to start at -90 deg to match previous behaviour for Konva.
                // Should probably fix so that the behaviour for this component is as expected now, and
                // fix any follow on problems that causes.
                const shape = new THREE.Shape();
                shape.lineTo(0, -cache.yRadius);
                const endAngle = cache.angle != null ? cache.angle : 360;
                shape.absellipse(0, 0, cache.xRadius, cache.yRadius, degToRad(-90), degToRad(endAngle - 90), false, 0);
                shape.lineTo(0, 0);
                ((ref as RefObject<Mesh>).current as Mesh).geometry = new ShapeGeometry(shape, 64);
            }
        }, []); // eslint-disable-line react-hooks/exhaustive-deps
        useFrame(callback);

        return <MeshBase ref={ref} {...props}></MeshBase>;
    }
);

export const DraggableEllipse = withDragEvents(Ellipse);
export const EllipseWithCursor = withCursor(Ellipse);

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from "@emotion/react";
import { ControlledMenu as ControlledMenuInner, MenuItem, SubMenu } from "@szhsin/react-menu";
import React from "react";

// TODO: These should be included in app.tsx or something.
import { menuSelector, menuItemSelector, menuDividerSelector } from "@szhsin/react-menu/style-utils";
import "@szhsin/react-menu/dist/core.css";
import styled from "@emotion/styled";
import { IMenuItem } from "../store";

const menuShow = keyframes`
    from {
        opacity: 0;
    }
`;
const menuHide = keyframes`
    to {
        opacity: 0;
    }
`;

export const ControlledMenu = styled(ControlledMenuInner)`
    ${menuSelector.name} {
        font-size: ${props => props.theme.fontSizes[1]}px;
        user-select: none;
        box-shadow: ${props => props.theme.boxShadows[0]};
        text-shadow: none;
        border-radius: ${props => props.theme.radii[3]}px;
        min-width: 20rem;
        color: ${props => props.theme.colors.foreground};
        background-color: ${props => props.theme.colors.background};
    }

    ${menuSelector.stateOpening} {
        animation: ${menuShow} 0.15s ease-out;
    }

    ${menuSelector.stateClosing} {
        animation: ${menuHide} 0.2s ease-out forwards;
    }

    ${menuItemSelector.name} {
        &:first-of-type {
            border-top-left-radius: ${props => props.theme.radii[3]}px;
            border-top-right-radius: ${props => props.theme.radii[3]}px;
        }

        &:last-of-type {
            border-bottom-left-radius: ${props => props.theme.radii[3]}px;
            border-bottom-right-radius: ${props => props.theme.radii[3]}px;
        }

        padding-left: ${props => props.theme.space[3]}px;
        padding-right: ${props => props.theme.space[3]}px;
        padding-top: ${props => props.theme.space[2]}px;
        padding-bottom: ${props => props.theme.space[2]}px;
    }

    ${menuItemSelector.hover} {
        color: ${props => props.theme.colors.foreground};
        background-color: ${props => props.theme.colors.grayscale[9]};
    }

    ${menuDividerSelector.name} {
        margin: 0.5rem 0.625rem;
    }

    ${menuItemSelector.submenu} {
        position: relative;
        padding-right: ${props => props.theme.space[4] + props.theme.space[1]}px;
        &::after {
            content: "▶";
            position: absolute;
            right: ${props => props.theme.space[2]}px;
        }
    }

    .szh-menu__item--type-checkbox {
        padding-left: ${props => props.theme.space[4] + props.theme.space[1]}px;
    }

    .szh-menu__item--type-checkbox::before {
        position: absolute;
        left: ${props => props.theme.space[2]}px;
    }

    .szh-menu__item--type-checkbox.szh-menu__item--checked::before {
        content: "✔";
    }

    .szh-menu__submenu {
        > div {
            border-radius: 0 !important;
        }

        &:first-of-type {
            > div {
                border-top-left-radius: ${props => props.theme.radii[3]}px !important;
                border-top-right-radius: ${props => props.theme.radii[3]}px !important;
            }
        }

        &:last-of-type {
            > div {
                border-bottom-left-radius: ${props => props.theme.radii[3]}px !important;
                border-bottom-right-radius: ${props => props.theme.radii[3]}px !important;
            }
        }
    }
`;

export function renderContextMenuItem(o: IMenuItem) {
    return (
        <React.Fragment key={o.label}>
            {o.subitems && o.subitems.length ? (
                <SubMenu label={o.label} disabled={o.disabled} onClick={o.onClick}>
                    {o.subitems.map(renderContextMenuItem)}
                </SubMenu>
            ) : (
                <MenuItem disabled={o.disabled} onClick={o.onClick}>
                    {o.label}
                </MenuItem>
            )}
        </React.Fragment>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { ThemeContext } from "@emotion/react";
import { useContextBridge } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { AnimatePresence } from "framer-motion";
import React, { FunctionComponent, useRef, PropsWithChildren, useContext, useMemo } from "react";
import { createRoot, Root } from "react-dom/client";
import { Camera, Group, Object3D, Vector3 } from "three";
import { getBounds, screenPoint } from "../../grid";
import { LocalPixelPosition, ScreenPixelPosition } from "../../position";
import { stopNonInputEvent } from "../common";
import {
    AnnotationCacheContext,
    AnnotationOverrideContext,
    AppStateContext,
    CampaignContext,
    DiceBagContext,
    DispatchContext,
    LocalGridContext,
    ScaleContext,
    SelectionContext,
    SessionConnectionContext,
    SessionContext,
    TokenOverrideContext,
    useCampaign,
    UserContext,
    ViewportContext,
    ZoneOverrideContext,
} from "../contexts";
import { useForceUpdate } from "../utils";
import { Event } from "../../common";

interface HtmlAdornerContextProps {
    layoutUpdated: Event<ScreenPixelPosition>;
}

const HtmlAdornerContext = React.createContext(undefined as any as HtmlAdornerContextProps);

interface HtmlAdornerLayoutProps {
    boundingPosVertical?: "to" | "ti" | "c" | "bi" | "bo";
    boundingPosHorizontal?: "lo" | "li" | "c" | "ri" | "ro";
    minFullWidth?: boolean;
    minFullHeight?: boolean;
}

export function useHtmlAdorner() {
    return useContext(HtmlAdornerContext);
}

export interface HtmlAdornerProps extends HtmlAdornerLayoutProps {
    pointerEvents?: "none" | "all";

    pos?: LocalPixelPosition | LocalPixelPosition[];
}

const v1 = new Vector3();

function calculatePosition(el: Object3D, camera: Camera, size: { width: number; height: number }): ScreenPixelPosition {
    camera.updateMatrixWorld();
    el.updateWorldMatrix(true, false);

    const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
    objectPos.project(camera);
    const widthHalf = size.width / 2;
    const heightHalf = size.height / 2;
    return screenPoint(objectPos.x * widthHalf + widthHalf, -(objectPos.y * heightHalf) + heightHalf);
}

// TODO: Should start taking into account the level here. Change the position to be WithLevel<LocalPixelPosition>, and get the zindex
// from the level. Have to have some kind of util function to get the z-position of a level for a location anyway, should be using it
// to position the camera as well.
const Html2: FunctionComponent<
    PropsWithChildren<
        HtmlAdornerLayoutProps & {
            position: LocalPixelPosition | LocalPixelPosition[];
            adornerContext: HtmlAdornerContextProps;
        }
    >
> = ({
    children,
    position,
    boundingPosHorizontal,
    boundingPosVertical,
    minFullHeight,
    minFullWidth,
    adornerContext,
}) => {
    const { gl, events } = useThree();
    const target = (events.connected || gl.domElement.parentNode) as HTMLElement;

    const root = React.useRef<Root>();
    const group = React.useRef<Group>(null!);
    const [el] = React.useState(() => document.createElement("div"));

    React.useLayoutEffect(() => {
        if (group.current) {
            const currentRoot = (root.current = createRoot(el));
            el.style.cssText = `position:absolute;top:0;left:0;pointer-events:none;z-index:1;pointer-events:none;`;

            if (target) {
                target.appendChild(el);
            }

            return () => {
                if (target) {
                    target.removeChild(el);
                }

                currentRoot.unmount();
            };
        }
    }, [target, el]);

    React.useLayoutEffect(() => {
        root.current?.render(
            <div
                style={{
                    transform: "none",
                }}
                children={children}
            />
        );
    });

    const positionRef = useRef<{ screenX: number; screenY: number }>();
    useFrame(state => {
        if (group.current) {
            let screenPos: ScreenPixelPosition;
            let transformX = 0;
            let transformY = 0;
            if (Array.isArray(position)) {
                // Convert the local positions to screen coords, and get the bounding box of that.
                const screenPoints = position.map(o => {
                    group.current.position.set(o.x, -o.y, 0);
                    return calculatePosition(group.current, state.camera, state.size);
                });

                // Get the screen bounds.
                const bounds = getBounds(screenPoints);

                if (el) {
                    if (minFullWidth) {
                        el.style.minWidth = `${bounds.width}px`;
                    }

                    if (minFullHeight) {
                        el.style.minHeight = `${bounds.height}px`;
                    }
                }

                let posX = bounds.x;
                let posY = bounds.y;
                switch (boundingPosHorizontal) {
                    case "lo":
                        transformX = -100;
                        posX = bounds.x;
                        break;
                    case "li":
                        posX = bounds.x;
                        break;
                    case "c":
                        transformX = -50;
                        posX = bounds.x + bounds.width / 2;
                        break;
                    case "ri":
                        transformX = -100;
                        posX = bounds.x + bounds.width;
                        break;
                    case "ro":
                        posX = bounds.x + bounds.width;
                        break;
                }

                switch (boundingPosVertical) {
                    case "to":
                        transformY = -100;
                        posY = bounds.y;
                        break;
                    case "ti":
                        posY = bounds.y;
                        break;
                    case "c":
                        transformY = -50;
                        posY = bounds.y + bounds.height / 2;
                        break;
                    case "bi":
                        transformY = -100;
                        posY = bounds.y + bounds.height;
                        break;
                    case "bo":
                        posY = bounds.y + bounds.height;
                        break;
                }

                screenPos = screenPoint(posX, posY);
            } else {
                group.current.position.set(position.x, -position.y, 0);
                screenPos = calculatePosition(group.current, state.camera, state.size);

                // TODO: Bounding pos sizes for points. "c" should center on the point, "lo" should be -100, etc.
            }

            if (screenPos.x !== positionRef.current?.screenX || screenPos.y !== positionRef.current?.screenY) {
                if (!positionRef.current) {
                    positionRef.current = { screenX: screenPos.x, screenY: screenPos.y };
                } else {
                    positionRef.current.screenX = screenPos.x;
                    positionRef.current.screenY = screenPos.y;
                }

                el.style.transform = `translate3d(${screenPos.x}px,${screenPos.y}px,0) translate3d(${transformX}%,${transformY}%,0)`;
            }

            adornerContext.layoutUpdated.trigger(screenPos);
        }
    }, 5);

    return <group ref={group}></group>;
};

const HtmlAdornerImpl: FunctionComponent<
    PropsWithChildren<
        HtmlAdornerLayoutProps & {
            pos: LocalPixelPosition | LocalPixelPosition[];
            onExitComplete: () => void;
            pointerEvents?: "none" | "all";
        }
    >
> = ({ pos, onExitComplete, children, pointerEvents, ...props }) => {
    const { system } = useCampaign();
    const Bridge = useContextBridge(
        DispatchContext,
        ThemeContext,
        UserContext,
        SessionConnectionContext,
        SessionContext,
        CampaignContext,
        AppStateContext,
        SelectionContext,
        DiceBagContext,
        TokenOverrideContext,
        AnnotationOverrideContext,
        ZoneOverrideContext,
        AnnotationCacheContext,
        LocalGridContext,
        ViewportContext,
        ScaleContext,
        ...system.getContexts()
    );

    const adornerContext = useMemo<HtmlAdornerContextProps>(() => {
        return {
            layoutUpdated: new Event<ScreenPixelPosition>(),
        };
    }, []);

    return (
        <Html2 position={pos} adornerContext={adornerContext} {...props}>
            <div
                onPointerDown={stopNonInputEvent}
                onPointerUp={stopNonInputEvent}
                onClick={stopNonInputEvent}
                css={{
                    cursor: "default",
                    pointerEvents: pointerEvents ?? "all",
                }}>
                <Bridge>
                    <HtmlAdornerContext.Provider value={adornerContext}>
                        <AnimatePresence onExitComplete={onExitComplete}>{pos && children}</AnimatePresence>
                    </HtmlAdornerContext.Provider>
                </Bridge>
            </div>
        </Html2>
    );
};

// TODO: Consider having the children prop be a function, so that we call it to render the children ONLY IF posRef.current is TRUE!
// Otherwise we have to make sure that the caller conditionally renders the children as well as conditionally providing a pos.
export const HtmlAdorner: FunctionComponent<PropsWithChildren<HtmlAdornerProps>> = ({
    pos,
    children,
    pointerEvents,
    ...props
}) => {
    const forceUpdate = useForceUpdate();

    const posRef = useRef(pos);
    if (pos) {
        posRef.current = pos;
    }

    return (
        <React.Fragment>
            {posRef.current && (
                <HtmlAdornerImpl
                    {...props}
                    pos={posRef.current}
                    onExitComplete={() => {
                        posRef.current = undefined;
                        forceUpdate();
                    }}
                    pointerEvents={pointerEvents}>
                    {children}
                </HtmlAdornerImpl>
            )}
        </React.Fragment>
    );
};

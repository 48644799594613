/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid, Heading } from "../../../../components/primitives";
import { Button } from "../../../../components/Button";
import { Progress, ProgressStep } from "../../../../components/Progress";
import { Character, resolveCharacter } from "../../creature";

import { RacePage } from "./RacePage";
import { ClassPage } from "./ClassPage";
import { AbilityScoresPage } from "./AbilityScoresPage";
import { DescriptionPage } from "./DescriptionPage";
import { EquipmentPage } from "./EquipmentPage";
import { InitialPage } from "./InitialPage";
import { useRules } from "../hooks";
import { DnD5ECharacterTemplate, DnD5ECharacterToken } from "../../common";
import { sectionAnimate, sectionExit, sectionInitial, MotionBox, MotionHeading } from "../../../../components/motion";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import { useAppState, useDispatch, useLocation } from "../../../../components/contexts";
import { ResolvedToken, isTokenTemplate } from "../../../../store";
import { TokenImage } from "../../../../components/TokenImage";
import { theme } from "../../../../design";
import { CharacterTags } from "../TokenListItem";
import { SidebarButton } from "../../../../components/common";
import { modifyCharacter } from "../../actions/token";
import { ModalContent } from "../../../../components/modal";

enum CharacterCreationPage {
    Initial,
    Race,
    Class,
    Abilities,
    Description,
    Equipment,
}

export const CharacterCreator: FunctionComponent<{
    character: DnD5ECharacterTemplate | ResolvedToken<DnD5ECharacterToken>;
    onComplete: (character: DnD5ECharacterTemplate | DnD5ECharacterToken) => void;
}> = ({ character, onComplete }) => {
    const rules = useRules();
    const [page, setPage] = useState(CharacterCreationPage.Initial);
    const { system, campaign, location } = useLocation();
    const dispatch = useDispatch();

    // The focused token is the one being edited while the character creator is mounted.
    const { setFocusedToken } = useAppState();
    useEffect(() => {
        setFocusedToken(isTokenTemplate(character) ? character.templateId : character.id);
        return () => setFocusedToken(undefined);
    });

    const unresolvedCharacter = character.dnd5e as Character;
    const c = useMemo(() => {
        if (!unresolvedCharacter) {
            return undefined;
        }

        return resolveCharacter(unresolvedCharacter, campaign, rules);
    }, [unresolvedCharacter, rules, campaign]);

    const showEquipmentRef = useRef<boolean>(character.dnd5e.inventory == null && character.dnd5e.currency == null);

    if (!c) {
        return <React.Fragment />;
    }

    let nextEnabled: boolean;
    switch (page) {
        case CharacterCreationPage.Initial:
            nextEnabled = !!c.name;
            break;
        case CharacterCreationPage.Race:
            nextEnabled = !!c.race;
            break;
        case CharacterCreationPage.Class:
            nextEnabled = Object.values(c.classes).length > 0;
            break;
        case CharacterCreationPage.Abilities:
            nextEnabled = true; // TODO: Prevent going further until abilities are specified? Probably not necessary?
            break;
        case CharacterCreationPage.Description:
            nextEnabled = true;
            break;
        case CharacterCreationPage.Equipment:
            nextEnabled = false;
            break;
        default:
            nextEnabled = false;
            break;
    }

    // If the character still needs to do starting equipment when the dialog is opened, show that page until the dialog
    // closes no matter what happens in between.
    let canFinish =
        (showEquipmentRef.current &&
            page === CharacterCreationPage.Equipment &&
            (character.dnd5e.inventory != null || character.dnd5e.currency != null)) ||
        (!showEquipmentRef.current && page === CharacterCreationPage.Description);

    const header = (
        <React.Fragment>
            {character.dnd5e.isIncomplete && (
                <Grid
                    fullWidth
                    gridTemplateColumns="min-content 1fr"
                    gridTemplateRows="min-content min-content"
                    borderTopLeftRadius={4}
                    borderTopRightRadius={4}>
                    <AnimatePresence mode="wait">
                        <TokenImage key={character.imageUri} token={character} gridColumn={1} gridRow={1} mr={3} />
                    </AnimatePresence>
                    <Box
                        gridRow={1}
                        gridColumn={2}
                        alignSelf="center"
                        alignItems="flex-end"
                        justifyContent="flex-start"
                        css={{ gap: theme.space[3] }}>
                        <Heading as="h3">{system.getDisplayName(character, campaign)}</Heading>
                        <CharacterTags token={character} character={unresolvedCharacter} />
                    </Box>
                    <Box mt={3} pt={3} pb={5} px={6} gridRow={2} gridColumn="1 / 3">
                        <Progress>
                            <ProgressStep
                                current={page === CharacterCreationPage.Initial}
                                onClick={() => setPage(CharacterCreationPage.Initial)}
                                mode="full">
                                Name
                            </ProgressStep>
                            <ProgressStep
                                current={page === CharacterCreationPage.Race}
                                onClick={() => setPage(CharacterCreationPage.Race)}
                                mode="full">
                                Species
                            </ProgressStep>
                            <ProgressStep
                                current={page === CharacterCreationPage.Class}
                                onClick={() => setPage(CharacterCreationPage.Class)}>
                                Class
                            </ProgressStep>
                            <ProgressStep
                                current={page === CharacterCreationPage.Abilities}
                                onClick={() => setPage(CharacterCreationPage.Abilities)}>
                                Abilities
                            </ProgressStep>
                            <ProgressStep
                                current={page === CharacterCreationPage.Description}
                                onClick={() => setPage(CharacterCreationPage.Description)}>
                                Description
                            </ProgressStep>
                            {showEquipmentRef.current && (
                                <ProgressStep
                                    current={page === CharacterCreationPage.Equipment}
                                    onClick={() => setPage(CharacterCreationPage.Equipment)}>
                                    Equipment
                                </ProgressStep>
                            )}
                        </Progress>
                    </Box>
                </Grid>
            )}
            {!character.dnd5e.isIncomplete && (
                <Grid
                    fullWidth
                    gridTemplateColumns="min-content 1fr"
                    gridTemplateRows="min-content min-content"
                    borderTopLeftRadius={4}
                    borderTopRightRadius={4}>
                    <AnimatePresence mode="wait">
                        <TokenImage key={character.imageUri} token={character} gridColumn={1} gridRow={1} mr={3} />
                    </AnimatePresence>
                    <Box
                        gridRow={1}
                        gridColumn={2}
                        alignSelf="center"
                        alignItems="flex-end"
                        justifyContent="flex-start"
                        css={{ gap: theme.space[3] }}
                        mr={3}>
                        <Heading as="h3">{system.getDisplayName(character, campaign)}</Heading>
                        <CharacterTags token={character} character={unresolvedCharacter} />
                    </Box>
                    <MotionBox
                        mt={3}
                        gridRow={2}
                        gridColumn="1 / 3"
                        alignItems="flex-end"
                        layout
                        flexWrap="wrap"
                        justifyContent="flex-start"
                        css={{ gap: theme.space[2], boxSizing: "content-box" }}>
                        <SidebarButton
                            tooltip="Name, images"
                            toggled={page === CharacterCreationPage.Initial}
                            onClick={() => setPage(CharacterCreationPage.Initial)}>
                            Name
                        </SidebarButton>
                        <SidebarButton
                            tooltip="Species"
                            toggled={page === CharacterCreationPage.Race}
                            onClick={() => setPage(CharacterCreationPage.Race)}>
                            Species
                        </SidebarButton>
                        <SidebarButton
                            tooltip="Class levels, features, spells, hit points"
                            toggled={page === CharacterCreationPage.Class}
                            onClick={() => setPage(CharacterCreationPage.Class)}>
                            Class
                        </SidebarButton>
                        <SidebarButton
                            tooltip="Ability scores"
                            toggled={page === CharacterCreationPage.Abilities}
                            onClick={() => setPage(CharacterCreationPage.Abilities)}>
                            Abilities
                        </SidebarButton>
                        <SidebarButton
                            tooltip="Description"
                            toggled={page === CharacterCreationPage.Description}
                            onClick={() => setPage(CharacterCreationPage.Description)}>
                            Description
                        </SidebarButton>
                        {showEquipmentRef.current && (
                            <SidebarButton
                                tooltip="Equipment, money"
                                toggled={page === CharacterCreationPage.Equipment}
                                onClick={() => setPage(CharacterCreationPage.Equipment)}>
                                Equipment
                            </SidebarButton>
                        )}
                    </MotionBox>
                </Grid>
            )}
        </React.Fragment>
    );

    const footer = (
        <React.Fragment>
            {character.dnd5e.isIncomplete && (
                <Box fullWidth flexDirection="row" justifyContent="space-between">
                    <Button disabled={page === CharacterCreationPage.Initial} onClick={() => setPage(page - 1)}>
                        Previous
                    </Button>
                    <Box>
                        <Button disabled={!nextEnabled} onClick={() => setPage(page + 1)}>
                            Next
                        </Button>
                        <Button
                            variant="primary"
                            ml={2}
                            disabled={!canFinish}
                            onClick={() => {
                                if (canFinish) {
                                    dispatch(
                                        modifyCharacter(campaign, location, [character], {
                                            isIncomplete: undefined,
                                        })
                                    );
                                }

                                onComplete(character);
                            }}>
                            {canFinish ? "Finish" : "Close"}
                        </Button>
                    </Box>
                </Box>
            )}
            {!character.dnd5e.isIncomplete && (
                <Box fullWidth flexDirection="row" justifyContent="flex-end">
                    <Button variant="primary" ml={2} onClick={() => onComplete(character)}>
                        Close
                    </Button>
                </Box>
            )}
        </React.Fragment>
    );

    return (
        <LayoutGroup>
            <ModalContent header={header} footer={footer}>
                {character.dnd5e.isIncomplete && (
                    <Box px={3} flexShrink={1} fullWidth pt={2}>
                        {page === CharacterCreationPage.Initial && (
                            <MotionHeading layout="position" as="h3">
                                Choose a Name
                            </MotionHeading>
                        )}
                        {page === CharacterCreationPage.Race && (
                            <MotionHeading layout="position" as="h3">
                                Choose a Race
                            </MotionHeading>
                        )}
                        {page === CharacterCreationPage.Class && (
                            <MotionHeading layout="position" as="h3">
                                Choose a Class
                            </MotionHeading>
                        )}
                        {page === CharacterCreationPage.Abilities && (
                            <MotionHeading layout="position" as="h3">
                                Ability Scores
                            </MotionHeading>
                        )}
                        {page === CharacterCreationPage.Description && (
                            <MotionHeading layout="position" as="h3">
                                Description
                            </MotionHeading>
                        )}
                        {page === CharacterCreationPage.Equipment && (
                            <MotionHeading layout="position" as="h3">
                                Equipment
                            </MotionHeading>
                        )}
                    </Box>
                )}
                <AnimatePresence mode="wait" initial={false}>
                    <MotionBox
                        layout
                        key={page}
                        flexGrow={1}
                        mt={character.dnd5e.isIncomplete ? 2 : undefined}
                        fullWidth
                        initial={sectionInitial}
                        animate={sectionAnimate}
                        exit={sectionExit}>
                        {page === CharacterCreationPage.Initial && (
                            <InitialPage character={character} resolvedCharacter={c} />
                        )}
                        {page === CharacterCreationPage.Race && <RacePage token={character} resolvedCharacter={c} />}
                        {page === CharacterCreationPage.Class && <ClassPage token={character} resolvedCharacter={c} />}
                        {page === CharacterCreationPage.Abilities && (
                            <AbilityScoresPage token={character} resolvedCharacter={c} />
                        )}
                        {page === CharacterCreationPage.Description && (
                            <DescriptionPage token={character} resolvedCharacter={c} />
                        )}
                        {page === CharacterCreationPage.Equipment && (
                            <EquipmentPage token={character} resolvedCharacter={c} />
                        )}
                    </MotionBox>
                </AnimatePresence>
            </ModalContent>
        </LayoutGroup>
    );
};

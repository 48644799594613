/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState, ChangeEvent, FunctionComponent, useMemo } from "react";
import { Message } from "../Message";
import { Form, InputField, CheckboxField } from "../Form";

import { Box } from "../primitives";
import { useDispatch, useRole, useCampaign, useUser, useNotifications } from "../contexts";
import { setCampaignLabel, setCampaignDescription, setCampaignSharedVision } from "../../actions/campaign";
import { AnimatedListItem } from "../motion";
import { AnimatePresence } from "framer-motion";
import styled from "@emotion/styled";
import { SearchableSetting } from "../../store";
import { ScrollableTest } from "../ScrollableTest";
import { Button } from "../Button";
import { importPackage } from "../../export";
import { useErrorHandler } from "../utils";
import { MarkdownEditorField } from "../markdown";
import { Pages } from "./Sidebar";
import { useVttApp } from "../common";

// TODO: Remove when Scrollable is fixed so that we can put the flex prop on that (or until someone tells me what I'm doing wrong).
const ScrollableHack = styled(Box)`
    > :first-of-type {
        flex: 1 1 auto;
    }
`;

const tags = ["campaign"];

const labelSetting: SearchableSetting = {
    id: "label",
    label: "Label",
    tags: tags,
    render: () => <LabelSetting />,
};
const descriptionSetting: SearchableSetting = {
    id: "description",
    label: "Description",
    tags: tags,
    render: () => <DescriptionSetting />,
};
const sharedVisionSetting: SearchableSetting = {
    id: "sharedVision",
    label: "Shared party vision",
    tags: [...tags, "vision", "party"],
    render: () => <SharedVisionSetting />,
};
const importSetting: SearchableSetting = {
    id: "importPackage",
    label: "Import",
    tags: [...tags, "import", "package"],
    render: () => <ImportSetting />,
};

export const playerCampaignSettings: SearchableSetting[] = [];
export const allCampaignSettings: SearchableSetting[] = [
    labelSetting,
    descriptionSetting,
    sharedVisionSetting,
    importSetting,
    ...playerCampaignSettings,
];

// const searchableFields: (keyof SearchableSetting)[] = ["label", "tags"];
// const toResult: (o: SearchableSetting) => () => JSX.Element = o => () => o.render();
// export const campaignSettingsPlayerSearch = new LocalSearchable(playerSettings, { idField: "id", searchableFields: searchableFields, toResult: toResult });
// export const campaignSettingsGmSearch = new LocalSearchable(allSettings, { idField: "id", searchableFields: searchableFields, toResult: toResult });

const LabelSetting = () => {
    const dispatch = useDispatch();
    const { campaign } = useCampaign();
    const [label, setLabel] = useState(campaign.label);
    return (
        <InputField
            label={labelSetting.label}
            value={label}
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)}
            onBlur={() => dispatch(setCampaignLabel(campaign.id, label))}
        />
    );
};

const DescriptionSetting = () => {
    const dispatch = useDispatch();
    const { campaign } = useCampaign();

    return (
        <MarkdownEditorField
            label={descriptionSetting.label}
            editable
            noSystem
            minLines={6}
            defaultMarkdown={campaign.description ?? ""}
            onMarkdownChange={md => {
                dispatch(setCampaignDescription(campaign.id, md));
            }}
            debounceChange={2000}
        />
    );
};

const SharedVisionSetting = () => {
    const dispatch = useDispatch();
    const { campaign } = useCampaign();
    const sharedVision = campaign.sharedVision;
    return (
        <CheckboxField
            id={sharedVisionSetting.id}
            label={sharedVisionSetting.label}
            hint="If shared vision is enabled, all party members can see anything that other party members can see."
            checked={!!(sharedVision == null || sharedVision === "party")}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const sv = sharedVision == null || sharedVision === "party" ? "none" : "party";
                dispatch(setCampaignSharedVision(campaign.id, sv));
            }}
        />
    );
};

const ImportSetting = () => {
    const dispatch = useDispatch();
    const errorHandler = useErrorHandler();
    const { system, campaign } = useCampaign();
    const user = useUser();
    const addNotification = useNotifications();

    return (
        <Button
            onClick={() => {
                const input = document.createElement("input");
                input.type = "file";

                input.onchange = e => {
                    var file = (e.target as HTMLInputElement).files?.[0];
                    if (file) {
                        importPackage(file, system, campaign, user, errorHandler, dispatch, addNotification);
                    }
                };

                input.click();
            }}>
            Import package
        </Button>
    );
};

export const CampaignProperties: FunctionComponent<{}> = () => {
    const role = useRole();
    const { system } = useCampaign();

    const { searchTerm, searchResults } = useVttApp();
    const search = searchResults.find(o => o.categoryId === Pages.CampaignSettings)?.results;

    const gmSettings = useMemo(() => [...allCampaignSettings, ...(system.getCampaignSettings?.() ?? [])], [system]);
    const settings = role === "GM" ? gmSettings : playerCampaignSettings;
    const items = search ? settings.filter(o => search.find(sr => sr.id === o.id)) : settings;

    return (
        <Box flexDirection="column" fullWidth flex="1 1 auto">
            <AnimatePresence>
                {searchTerm && (
                    <AnimatedListItem fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only settings matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>
            <ScrollableHack flexDirection="column" fullWidth flex="1 1 auto">
                <ScrollableTest minimal py={1} px={3}>
                    <Form fullWidth>
                        <AnimatePresence>
                            {items.map((o, i) => (
                                <AnimatedListItem key={o.id} index={i} fullWidth className="form__field">
                                    {o.render()}
                                </AnimatedListItem>
                            ))}
                        </AnimatePresence>
                    </Form>
                </ScrollableTest>
            </ScrollableHack>
        </Box>
    );
};

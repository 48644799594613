import React, { FunctionComponent, useCallback, useEffect } from "react";
import { LocalPixelPosition } from "../../position";
import {
    EllipseAnnotation,
    Annotation,
    ConeAnnotation,
    LineAreaAnnotation,
    getLineAreaPoints,
    getAnnotationPos,
} from "../../annotations";
import { useDispatch, useLocalGrid, useValidatedLocationLevel } from "../contexts";
import { getPlayerColorPalette, getThemeColorPalette } from "../../design/utils";
import { theme } from "../../design";
import { modifyAnnotation } from "../../actions/location";
import { HoverTracking, ZIndexes } from "./common";
import { DraggableLine, Line } from "./three2d/Line";
import { Token } from "../../store";
import { ThreeEvent } from "@react-three/fiber";
import { defaultLineWidth, interactiveLineWidth } from "../common";
import { SelectionType } from "../selection";

interface LineAreaAnnotationNodeProps extends HoverTracking {
    line: LineAreaAnnotation;
    activePoint?: LocalPixelPosition;
    isSelected: SelectionType;
    wasSelected: boolean;
    disabled?: boolean;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onPointerDown?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onPointerUp?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onOverrideAnnotation: (id: string, override: Partial<ConeAnnotation> | undefined) => void;
    animateExit?: boolean;
}

const LineAreaAnnotationNodeCore: FunctionComponent<LineAreaAnnotationNodeProps> = ({
    line,
    activePoint,
    snapPoint,
    isSelected,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideAnnotation,
    disabled,
    isHovering,
    setHoverPart,
    wasSelected,
    animateExit,
}) => {
    const dispatch = useDispatch();
    const { campaign, location, levelKey } = useValidatedLocationLevel();
    const grid = useLocalGrid();
    let colours = disabled ? getThemeColorPalette("grayscale") : getPlayerColorPalette(campaign, line.userId);

    useEffect(() => {
        return () => {
            setHoverPart?.("line", false);
        };
    }, [setHoverPart]);

    const onDragMove = useCallback(
        (e: ThreeEvent<PointerEvent>, p: LocalPixelPosition) => {
            onOverrideAnnotation(line.id, {
                pos: { ...p, level: line.pos?.level ?? levelKey },
            });
        },
        [line.id, line.pos?.level, levelKey, onOverrideAnnotation]
    );
    const onDragEnd = useCallback(() => {
        dispatch(
            modifyAnnotation<EllipseAnnotation>(campaign.id, location.id, line.id, {
                pos: line.pos,
            })
        );
        onOverrideAnnotation(line.id, undefined);
    }, [dispatch, location, line, campaign, onOverrideAnnotation]);

    const pos = getAnnotationPos(line, campaign, location, grid);
    const points = getLineAreaPoints(line, pos, activePoint);

    const onAnnotationClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, line);
            }
        },
        [line, onClick]
    );

    const isHover = onClick && (isHovering || !!activePoint);

    const disableDrag = onClick == null || disabled || !!line.centerOn;
    const unselectedLineColor = colours[isHover ? 4 : 3];

    return (
        <>
            <DraggableLine
                x={pos.x}
                y={pos.y}
                opacity={0}
                zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                width={defaultLineWidth + interactiveLineWidth}
                points={points}
                cursor={disableDrag ? undefined : "pointer"}
                onPointerOver={() => setHoverPart?.("line", true)}
                onPointerOut={() => setHoverPart?.("line", false)}
                disableDrag={disableDrag}
                onDragMove={onDragMove}
                onDragEnd={onDragEnd}
                onClick={disabled ? undefined : onAnnotationClick}
                onPointerDown={onPointerDown ? e => onPointerDown(e, line) : undefined}
                onPointerUp={onPointerUp ? e => onPointerUp(e, line) : undefined}
            />
            <Line
                x={pos.x}
                y={pos.y}
                animateEnterExit="nolayout"
                initial={
                    isSelected
                        ? { color: unselectedLineColor, opacity: 1, scale: 1 }
                        : wasSelected
                        ? {
                              color: theme.colors.guidance.focus,
                              opacity: 1,
                              scale: 1,
                          }
                        : undefined
                }
                exit={animateExit ? undefined : null}
                zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                color={isSelected === SelectionType.Primary ? theme.colors.guidance.focus : unselectedLineColor}
                width={defaultLineWidth}
                points={points}
            />
        </>
    );
};

export const LineAreaAnnotationNode = React.memo(LineAreaAnnotationNodeCore);

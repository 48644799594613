/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch, useLocation } from "../../../components/contexts";
import { DnD5EToken, DnD5ETokenTemplate, isDnD5EMonsterTemplate } from "../common";
import { isMonster, ResolvedCharacter, ResolvedMonster, resolveModifiedValue } from "../creature";
import { Box, Grid, Text, Input, InOverlayCard } from "../../../components/primitives";
import { Button } from "../../../components/Button";
import { withTooltip } from "../../../components/Tooltip";
import { damageTokens, healTokens, setTokensTempHp } from "../actions/hp";
import { getModifiedValueTooltip } from "./common";
import { animate } from "framer-motion";
import { diceBagToExpression } from "../../../store";

const TooltipBox = withTooltip(Box);

export const HitPointBox: FunctionComponent<{
    token: DnD5EToken | DnD5ETokenTemplate;
    creature: ResolvedCharacter | ResolvedMonster;
}> = ({ token, creature }) => {
    const [value, setValue] = useState(0);
    const inputRef = useRef<HTMLInputElement>();
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const [tempFocused, setTempFocused] = useState(false);

    let maxHp = resolveModifiedValue(creature.maxHp);
    let maxHpTooltip = getModifiedValueTooltip(creature.maxHp);

    const currentHp = creature.hp ?? maxHp;

    // Animate the current HP.
    const currentHpRef = useRef<HTMLSpanElement>();
    useEffect(() => {
        const fromText = currentHpRef.current?.textContent;
        let from = fromText == null ? currentHp : parseInt(fromText);
        if (isNaN(from)) {
            from = currentHp;
        }

        const controls = animate(from, currentHp, {
            duration: 0.5,
            onUpdate(value) {
                if (currentHpRef.current) {
                    currentHpRef.current!.textContent = value.toFixed(0);
                }
            },
        });
        return () => controls.stop();
    }, [currentHp]);

    return (
        <InOverlayCard p={2} flexDirection="column" fullWidth mb={2} borderRadius={3} boxShadowSize="none">
            {isDnD5EMonsterTemplate(token) && (
                <Box flexDirection="column">
                    <Text textAlign="center" fontSize={1}>
                        HIT POINTS
                    </Text>
                    {(creature as ResolvedMonster).maxHpInfo.dice && (
                        <Text textAlign="center" fontSize={0} color="grayscale.2">
                            {diceBagToExpression((creature as ResolvedMonster).maxHpInfo.dice!)}
                        </Text>
                    )}
                    {(creature as ResolvedMonster).maxHpInfo.average && (
                        <Text fontSize={6}>{(creature as ResolvedMonster).maxHpInfo.average}</Text>
                    )}
                </Box>
            )}
            {!isDnD5EMonsterTemplate(token) && (
                <Grid gridTemplateColumns="2fr 3fr 2fr" gridTemplateRows="auto 1fr">
                    <Box
                        flexDirection="column"
                        alignItems="stretch"
                        css={{ gridRowStart: 1, gridRowEnd: 3, gridColumnStart: 1 }}>
                        <Button
                            variant="success"
                            size="s"
                            disabled={!value || creature.isDead}
                            onClick={() => {
                                dispatch(healTokens(campaign, location, [token], value));
                                setValue(0);
                                inputRef.current!.value = "";
                            }}>
                            Heal
                        </Button>
                        <Input
                            ref={inputRef as any}
                            disabled={creature.isDead}
                            mt={1}
                            mb={1}
                            fullWidth
                            type="number"
                            min={0}
                            defaultValue=""
                            onChange={e => {
                                setValue(isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber);
                            }}
                        />
                        <Button
                            variant="danger"
                            size="s"
                            fullWidth
                            disabled={!value || creature.isDead}
                            onClick={() => {
                                dispatch(damageTokens(campaign, location, [token], value));
                                setValue(0);
                                inputRef.current!.value = "";
                            }}>
                            Dmg
                        </Button>
                    </Box>

                    {isMonster(creature) ? (
                        <Box flexDirection="column">
                            <Text textAlign="center" fontSize={1}>
                                HIT POINTS
                            </Text>
                            {creature.maxHpInfo.dice && (
                                <Text textAlign="center" fontSize={0} color="grayscale.2">
                                    {diceBagToExpression(creature.maxHpInfo.dice)}
                                </Text>
                            )}
                        </Box>
                    ) : (
                        <Text textAlign="center" fontSize={1}>
                            HIT POINTS
                        </Text>
                    )}

                    <Text textAlign="center" fontSize={1} color="grayscale.2">
                        TEMP
                    </Text>

                    <TooltipBox flexDirection="row" tooltip={maxHpTooltip}>
                        <Text ref={currentHpRef} fontSize={6}></Text>
                        <Text fontSize={6} color="grayscale.2">
                            /
                        </Text>
                        <Text fontSize={6}>{maxHp}</Text>
                    </TooltipBox>

                    <Input
                        placeholder="--"
                        fontSize={4}
                        css={{
                            alignSelf: "flex-start",
                            textAlign: "center",
                            border: tempFocused ? undefined : "2px solid transparent",
                            background: tempFocused ? undefined : "none",
                        }}
                        fullWidth
                        type="number"
                        min={0}
                        value={creature.tempHp ?? ""}
                        onFocus={e => setTempFocused(true)}
                        onBlur={e => setTempFocused(false)}
                        onChange={e => {
                            const amt = e.target.valueAsNumber;
                            dispatch(setTokensTempHp(campaign, location, [token], isNaN(amt) ? undefined : amt));
                        }}
                    />
                </Grid>
            )}
        </InOverlayCard>
    );
};

import { Theme } from "@emotion/react";

// export function overlayStyles(props: { theme: Theme }) {
//     return `
//         background-color: ${props.theme.colors.background};
//     `;
// }

// export function overlayStyles(props: { theme: Theme }) {
//     return `
//     border-radius: ${props.theme.radii[4]}px;
//     background-color: rgba(255,255,255,0.05);
//     border: 1px solid rgba(255,255,255,0.05);
//     backdrop-filter: blur(4px);

//     text-shadow: black 0 0 12px;
// `;
// }

// export function overlayStyles(props: { theme: Theme }) {
//     return `
//     border-radius: ${props.theme.radii[4]}px;
//     background-color: rgba(0,0,0,0.25);
//     border: 1px solid rgba(0,0,0,0.25);
//     backdrop-filter: blur(4px);

//     text-shadow: black 0 0 12px;
// `;
// }

export function overlayBackgroundStyles(props: { theme: Theme }) {
    return `
        background-color: ${props.theme.colors.transparency[2].background};
        backdrop-filter: blur(6px);
        text-shadow: black 0 0 12px;
    `;
}

export function overlayBorderStyles(props: { theme: Theme }) {
    return `
        border: 1px solid ${props.theme.colors.transparency[4].background};
    `;
}

export function overlayStyles(props: { theme: Theme }) {
    return `
        ${overlayBackgroundStyles(props)}
        ${overlayBorderStyles(props)}
    `;
}

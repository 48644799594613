/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, PropsWithChildren } from "react";
import { Box, Text } from "../../../components/primitives";
import { theme } from "../../../design";
import { ExtractProps } from "../../../components/common";
import { MotionBox, defaultInitial, defaultAnimate, defaultExit } from "../../../components/motion";

function advantageToAbbr(advantage: "adv" | "dis" | "fail") {
    switch (advantage) {
        case "adv":
            return "ADV";
        case "dis":
            return "DIS";
        case "fail":
            return "FAIL";
    }
}

export const AdvantageBox: FunctionComponent<
    PropsWithChildren<
        {
            advantage: "adv" | "dis" | "fail";
            reason: string;
            condition?: string;
            compact?: boolean;
            allowWrap?: boolean;
        } & ExtractProps<typeof MotionBox>
    >
> = ({ advantage, children, reason, condition, compact, allowWrap, ...props }) => {
    const text = condition ? `${condition} (${reason})` : reason;
    return (
        <MotionBox
            initial={defaultInitial}
            animate={defaultAnimate}
            exit={defaultExit}
            flexDirection="row"
            justifyContent="flex-start"
            {...props}
            title={text}
            alignItems="flex-start">
            <Box
                bg={
                    advantage === "adv"
                        ? "guidance.success.1"
                        : advantage === "dis"
                        ? "guidance.warning.1"
                        : "guidance.error.1"
                }
                borderRadius={3}
                mr={2}
                p={1}
                width={theme.space[4]}>
                <Text
                    color={
                        advantage
                            ? "guidance.success.0"
                            : advantage === "dis"
                            ? "guidance.warning.0"
                            : "guidance.error.0"
                    }
                    fontSize="8px"
                    fontWeight="bold">
                    {advantageToAbbr(advantage)}
                </Text>
            </Box>
            {!compact && (
                <Text
                    css={{
                        whiteSpace: allowWrap ? "normal" : "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}>
                    {text}
                </Text>
            )}
            {children}
        </MotionBox>
    );
};

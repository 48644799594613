import React, { FunctionComponent } from "react";
import { useCampaign } from "../../../components/contexts";
import { ResolvedToken, SidebarPanelState, getResolvedToken } from "../../../store";
import {
    DnD5ECharacterTemplate,
    DnD5ECharacterToken,
    DnD5EMonsterTemplate,
    DnD5EMonsterToken,
    isDnD5ECharacterTemplate,
    isDnD5ECharacterToken,
    isDnD5EMonsterTemplate,
    isDnD5EMonsterToken,
} from "../common";
import { CharacterCreator } from "./character/CharacterCreator";
import { MonsterCreator } from "./monster/MonsterCreator";
import { useVttApp } from "../../../components/common";

const CreatureEditor: FunctionComponent<{ id: string; locationId?: string }> = React.memo(({ id, locationId }) => {
    const { campaign } = useCampaign();
    const editedToken = getResolvedToken(campaign, locationId, id);

    const close = useVttApp(state => state.closePanel);

    if (isDnD5ECharacterTemplate(editedToken) || isDnD5ECharacterToken(editedToken)) {
        return (
            <CharacterCreator
                character={editedToken as DnD5ECharacterTemplate | ResolvedToken<DnD5ECharacterToken>}
                onComplete={() => {
                    close(id);
                }}
            />
        );
    } else if (isDnD5EMonsterTemplate(editedToken) || isDnD5EMonsterToken(editedToken)) {
        return (
            <MonsterCreator
                token={editedToken as DnD5EMonsterTemplate | ResolvedToken<DnD5EMonsterToken>}
                onComplete={() => {
                    close(id);
                }}
            />
        );
    }

    return <React.Fragment></React.Fragment>;
});

export function getCreatureEditor(id: string, locationId?: string, align?: "left" | "right"): SidebarPanelState {
    return {
        id: id,
        isPinned: true,
        width: 600,
        maxWidth: 900,
        align: align,
        children: () => <CreatureEditor id={id} locationId={locationId} />,
    };
}

import { Campaign, Handout } from "../store";
import { HandoutAction } from "./common";

export function modifyHandout(
    campaignId: Campaign | string,
    handoutId: Handout | string,
    handout: Omit<Partial<Handout>, "contributors" | "modified">,
    user: string
): HandoutAction {
    return {
        type: "ModifyHandout",
        props: {
            campaignId: typeof campaignId === "string" ? campaignId : campaignId.id,
            handoutId: typeof handoutId === "string" ? handoutId : handoutId.id,
        },
        payload: { userId: user, handout: handout },
    };
}

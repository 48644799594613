/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box, Heading, Link, Text } from "../primitives";
import { CheckboxField, Form, InputField } from "../Form";
import { AnimatePresence, motion } from "framer-motion";
import React, { ChangeEvent, FunctionComponent, useCallback } from "react";
import { modifyZone } from "../../actions/location";
import { isPointInPolygon, localPoint } from "../../grid";
import { LocalPixelPosition } from "../../position";
import { Zone, Location, LocationSummary, isLocation, NoteBase } from "../../store";
import { Viewport, VttMode, useVttApp } from "../common";
import { useDispatch, useLocation, useLocationLevel, useRole } from "../contexts";
import { Markdown, MarkdownEditorField } from "../markdown";
import { defaultAnimate, defaultExit, defaultInitial, MotionBox } from "../motion";
import { theme } from "../../design";

function getZonesAtPoint(location: Location, pos: LocalPixelPosition) {
    const zonesAtPoint: Zone[] = [];
    for (let zoneId in location.zones) {
        const zone = location.zones[zoneId];

        if (isPointInPolygon(pos, zone)) {
            zonesAtPoint.push(zone);
        }
    }

    // TODO: Sort zones by some kind of zindex, or parent/child relationship?
    return zonesAtPoint;
}

const IsInsideSetting: FunctionComponent<{ zone: Zone }> = ({ zone }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocationLevel();

    return (
        <CheckboxField
            id={zone.id + "_isInside"}
            label="Inside"
            hint="If enabled, this zone is considered to be inside (as opposed to outside). An inside zone will not use day/night cycle lighting and weather effects will not apply within the zone."
            disabled={!zone || !isLocation(location)}
            checked={zone ? !!zone.isInside : undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(
                    modifyZone(campaign.id, location!.id, zone, {
                        isInside: e.target.checked,
                    })
                );
            }}
        />
    );
};

// const OverrideWrapper: FunctionComponent<PropsWithChildren<{ overrideValue: boolean, onOverrideValueChanged: (overrideValue: boolean) => void, buffer?: number, disabled?: boolean }>> = ({ overrideValue, onOverrideValueChanged, children, buffer, disabled }) => {
//     return <Box fullWidth flexDirection="row">
//         <Checkbox disabled={disabled} mr={buffer ?? 2} checked={overrideValue} onClick={(e: MouseEvent<HTMLInputElement>) => {
//             onOverrideValueChanged(!overrideValue);
//         }} onChange={(e: ChangeEvent<HTMLInputElement>) => {
//             onOverrideValueChanged(!overrideValue);
//         }} />
//         {children}
//     </Box>
// }

export const ZoneBaseNotesProperties: FunctionComponent<{
    zone: Zone | Location | LocationSummary | undefined;
    mode: VttMode;
    dispatch: (change: Partial<Zone | Location>) => void;
    viewport?: Viewport;
}> = ({ zone, mode, dispatch, viewport }) => {
    const { location } = useLocation();
    const role = useRole();
    const onChange = useCallback((md: string) => dispatch({ gmNotes: md }), [dispatch]);
    const setMode = useVttApp(state => state.setMode);

    if (role !== "GM") {
        return <React.Fragment></React.Fragment>;
    }

    if (mode === "play") {
        let zones: NoteBase[] = isLocation(location) ? [location] : [];
        if (isLocation(location) && viewport) {
            const viewportCenter = localPoint(
                viewport.position.x + viewport.position.width / 2,
                viewport.position.y + viewport.position.height / 2
            );
            zones.push(...getZonesAtPoint(location, viewportCenter));
        }

        return (
            <AnimatePresence>
                {zones.map((o, i) => (
                    <MotionBox
                        key={o["id"] ? o["id"] : i}
                        flexDirection="column"
                        alignItems="flex-start"
                        layout
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <Box>
                            {(o as Zone).code && (
                                <React.Fragment>
                                    <Heading as="h4" css={{ whiteSpace: "pre" }} color="grayscale.4">
                                        {(o as Zone).code} •{" "}
                                    </Heading>
                                </React.Fragment>
                            )}
                            <Heading as="h4">{o.label}</Heading>
                        </Box>
                        {!o.gmNotes && (
                            <Text fontStyle="italic">
                                No notes have been made for this {isLocation(o) ? "location" : "zone"}.
                            </Text>
                        )}
                        {o.gmNotes && <Markdown>{o.gmNotes}</Markdown>}
                    </MotionBox>
                ))}
                <motion.div layout css={{ marginTop: theme.space[3] }}>
                    <Link onClick={() => setMode("build")}>Switch to build mode</Link> to edit the notes for this
                    location.
                </motion.div>
            </AnimatePresence>
        );
    }

    return (
        <React.Fragment>
            {zone !== location && (
                <React.Fragment>
                    <InputField
                        fullWidth
                        label="Label"
                        variant="text"
                        disabled={!zone || !isLocation(location)}
                        value={zone?.label}
                        required
                        onChange={e => dispatch({ label: e.target.value })}
                    />
                    <InputField
                        fullWidth
                        label="Code"
                        hint="A short code that can be used in a map legend."
                        variant="text"
                        maxLength={4}
                        disabled={!zone || !isLocation(location)}
                        value={(zone as Zone)?.code ?? ""}
                        onChange={e => dispatch({ code: e.target.value })}
                    />
                </React.Fragment>
            )}
            <MarkdownEditorField
                label="GM Notes"
                onMarkdownChange={onChange}
                editable
                minLines={6}
                debounceChange={2000}
                defaultMarkdown={zone?.gmNotes ?? ""}
            />
        </React.Fragment>
    );
};

export const ZoneNotesProperties: FunctionComponent<{
    zone: Zone;
    mode: VttMode;
    viewport?: Viewport;
}> = ({ zone, mode, viewport }) => {
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();
    const onChange = useCallback(
        change => dispatch(modifyZone(campaign.id, location!.id, zone.id, change)),
        [dispatch, campaign.id, location, zone.id]
    );

    return (
        <Form fullWidth pb={3}>
            <ZoneBaseNotesProperties zone={zone} mode={mode} dispatch={onChange} viewport={viewport} />
        </Form>
    );
};

export const ZoneCoreProperties: FunctionComponent<{ zone: Zone }> = ({ zone }) => {
    return (
        <Form fullWidth pb={3}>
            <IsInsideSetting zone={zone} />
        </Form>
    );
};

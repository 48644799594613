import { ThunkDispatch } from "redux-thunk";
import {
    AudioType,
    VTTStore,
    Campaign,
    isTokenTemplate,
    isToken,
    Token,
    TokenTemplate,
    LocationSummary,
} from "../store";
import { Action, AnyAction } from "redux";

export interface PayloadAction<T = any> extends Action {
    payload?: T;
    error?: any;
    props?: { [key: string]: any };
}

export enum ActionScope {
    Default = 0,
    Tokens = 1,
    Annotations = 2,
    Zones = 4,
    Locations = 8,
    CampaignPlayers = 16,
}

export interface CampaignAction extends PayloadAction {
    props: {
        campaignId: string;
        scope?: ActionScope;
        [key: string]: any;
    };
}

export interface LocationAction extends CampaignAction {
    props: {
        campaignId: string;
        locationId: string;
        [key: string]: any;
    };
}

export interface LocationLevelAction extends LocationAction {
    props: {
        campaignId: string;
        locationId: string;
        levelId: string;
        [key: string]: any;
    };
}

export interface TokenTemplatesAction extends CampaignAction {
    props: {
        campaignId: string;
        templates: string[];
    };
}

export interface CampaignPlayerAction extends CampaignAction {
    props: {
        campaignId: string;
        userId: string;
        [key: string]: any;
    };
}

export interface HandoutAction extends CampaignAction {
    props: {
        campaignId: string;
        handoutId: string;
    };
}

export interface LocationPlayerAction extends LocationAction {
    props: {
        campaignId: string;
        locationId: string;
        locationUserId: string;
        [key: string]: any;
    };
}

export interface TokensAction extends LocationAction {
    props: {
        campaignId: string;
        locationId: string;
        tokens: string[];
        [key: string]: any;
    };
}

export interface AnnotationAction extends LocationAction {
    props: {
        campaignId: string;
        locationId: string;
        annotationId: string;
        [key: string]: any;
    };
}

export interface ZoneAction extends LocationAction {
    props: {
        campaignId: string;
        locationId: string;
        zoneId: string;
        [key: string]: any;
    };
}

export interface LocationPlaylistAction extends LocationAction {
    props: {
        campaignId: string;
        locationId: string;
        playlistType: AudioType;
        trackCount?: number;
    };
}

export interface LocationTrackAction extends LocationAction {
    props: {
        campaignId: string;
        locationId: string;
        playlistType: AudioType;
        trackId: string;
    };
}

export interface ObstructionAction extends LocationAction {
    props: {
        campaignId: string;
        locationId: string;
        obstructionId: string;
        [key: string]: any;
    };
}

export function isCampaignAction(action: AnyAction): action is CampaignAction {
    return action.props && action.props.campaignId;
}

export function isHandoutAction(action: AnyAction): action is HandoutAction {
    return action.props && action.props.handoutId;
}

export function isLocationAction(action: AnyAction): action is LocationAction {
    return isCampaignAction(action) && action.props.locationId;
}

export function isCampaignPlayerAction(action: AnyAction): action is CampaignPlayerAction {
    return isCampaignAction(action) && action.props.userId;
}

export function isLocationPlayerAction(action: AnyAction): action is LocationPlayerAction {
    return isLocationAction(action) && action.props.locationUserId;
}

export function isLocationPlaylistAction(action: AnyAction): action is LocationPlaylistAction {
    return isLocationAction(action) && action.props.playlistType;
}

export function isTokensAction(action: AnyAction): action is TokensAction {
    return isLocationAction(action) && action.props.tokens;
}

export function isTokenTemplatesAction(action: AnyAction): action is TokenTemplatesAction {
    return isCampaignAction(action) && action.props.templates;
}

export function isAnnotationAction(action: AnyAction): action is AnnotationAction {
    return isLocationAction(action) && action.props.annotationId;
}

export function isLocationLevelAction(action: AnyAction): action is LocationLevelAction {
    return isLocationAction(action) && action.props.levelId;
}

export function isZoneAction(action: AnyAction): action is ZoneAction {
    return isLocationAction(action) && action.props.zoneId;
}

export function isObstructionAction(action: AnyAction): action is ObstructionAction {
    return isLocationAction(action) && action.props.obstructionId;
}

/**
 * Returns an action creator that calls the specified async function, dispatching a PayloadAction on start,
 * success and error.
 */
export function asyncActionCreator<T>(
    type: string,
    promise: (dispatch: ThunkDispatch<VTTStore, void, PayloadAction<T>>) => Promise<T>,
    props?: { [key: string]: any }
) {
    return async (dispatch: ThunkDispatch<VTTStore, void, PayloadAction<T>>) => {
        dispatch({ type: type + ":start", props: props });

        try {
            let payload = await promise(dispatch);
            dispatch({
                type: type + ":success",
                payload: payload,
                props: props,
            });

            return payload;
        } catch (error) {
            dispatch({
                type: type + ":error",
                error: error,
                props: props,
            });
            throw error;
        }
    };
}

/**
 * Creates an action that is handled by one or more tokens or token templates.
 * @param type
 * @param payload
 * @param campaign
 * @param location
 * @param tokens
 * @param scope
 * @param preventTemplateForwarding If true, the action will never be sent to the template, even if the token is not set to ignore its template. Use when an action is only ever relevant to a token, never to the template (e.g. it relates to an ongoing combat turn, which is stored on the token).
 * @param sendToTokenAndTemplate If true, then IF the action would be sent to the template, also send it to the token. Use when the token needs to respond to the action even if there is a template (e.g. it affects an ongoing combat turn, which is stored on the token).
 */
export function createTokensOrTokenTemplatesAction(
    type: string,
    payload: any,
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    scope?: ActionScope,
    preventTemplateForwarding?: boolean,
    sendToTokenAndTemplate?: boolean
): TokensAction | TokenTemplatesAction {
    const tokensToTarget: string[] = [];
    const tokenTemplatesToTarget: string[] = [];
    for (let i = 0; i < tokens.length; i++) {
        const item = tokens[i];

        if (isTokenTemplate(item)) {
            if (tokenTemplatesToTarget.indexOf(item.templateId) < 0) {
                tokenTemplatesToTarget.push(item.templateId);
            }
        } else if (isToken(item)) {
            // Actions target the template by default if there is one.
            if (!preventTemplateForwarding && item.templateId && !item.ignoreTemplate) {
                if (tokenTemplatesToTarget.indexOf(item.templateId) < 0) {
                    tokenTemplatesToTarget.push(item.templateId);
                }

                if (sendToTokenAndTemplate) {
                    if (tokensToTarget.indexOf(item.id) < 0) {
                        tokensToTarget.push(item.id);
                    }
                }
            } else if (tokensToTarget.indexOf(item.id) < 0) {
                tokensToTarget.push(item.id);
            }
        }
    }

    return {
        type: type,
        props: {
            campaignId: typeof campaign === "string" ? campaign : campaign.id,
            locationId: typeof location === "string" ? location : location?.id,
            tokens: tokensToTarget,
            templates: tokenTemplatesToTarget,
            scope: scope,
        },
        payload: payload,
    };
}

import React from 'react'
  
  const LineToolIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 1.5,17.25H6c0.414,0,0.75,0.336,0.75,0.75v4.5c0,0.414-0.336,0.75-0.75,0.75H1.5 c-0.414,0-0.75-0.336-0.75-0.75V18C0.75,17.586,1.086,17.25,1.5,17.25z  M 18,0.75h4.5c0.414,0,0.75,0.336,0.75,0.75V6 c0,0.414-0.336,0.75-0.75,0.75H18c-0.414,0-0.75-0.336-0.75-0.75V1.5C17.25,1.086,17.586,0.75,18,0.75z  M 17.47,6.53L6.53,17.47' />
    </svg>
  )
  
  LineToolIcon.displayName = 'LineToolIcon'
  
  LineToolIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default LineToolIcon
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, Suspense, useMemo, useRef } from "react";
import { Box, Card } from "../primitives";

import { CampaignSummary } from "../../store";
import { useErrorHandler } from "../utils";
import { wrapForSuspense } from "../../common";
import { ExtractProps, Loading } from "../common";
import { useInView } from "framer-motion";
import { CampaignSummaryDetails } from "./CampaignSummaryDetails";

interface ICampaignSummaryCardProps {
    campaign: CampaignSummary;
    full?: boolean;
}

type CampaignSummaryCardProps = ICampaignSummaryCardProps & ExtractProps<typeof Card>;

const CampaignSummaryCardContent: FunctionComponent<{
    fullSummary: { read(): CampaignSummary | undefined };
    full?: boolean;
}> = ({ fullSummary, full }) => {
    const campaign = fullSummary.read();

    // TODO: Proper error state.
    return campaign ? <CampaignSummaryDetails campaign={campaign} full={full} /> : null;
};

export const CampaignSummaryCard: FunctionComponent<CampaignSummaryCardProps> = ({
    campaign,
    borderRadius,
    color,
    full,
    ...props
}) => {
    const { handleResponse } = useErrorHandler();

    const ref = useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, { once: true });
    const fetchFullSummary = useMemo(() => {
        if (isInView) {
            return wrapForSuspense(
                fetch("api/campaigns/" + encodeURIComponent(campaign.id)).then(o => {
                    if (handleResponse(o, "Failed to fetch campaign summary.")) {
                        return o.json() as Promise<CampaignSummary>;
                    }
                })
            );
        }

        return undefined;
    }, [isInView, handleResponse, campaign.id]);

    return (
        <Card
            ref={ref}
            color={color as any} // TODO: Type error
            borderRadius={4}
            boxShadowSize="l"
            minHeight={full ? "50vh" : "initial"}
            css={{
                transition: "transform 90ms ease-out",
                "&::before": {
                    opacity: 0,
                },
                "&:hover": {
                    "&::before": {
                        opacity: 0.5,
                    },
                },
            }}
            bg="background"
            {...props}>
            {fetchFullSummary && (
                <Suspense
                    fallback={
                        <Box alignItems="center" justifyItems="center" flexGrow={1}>
                            <Loading />
                        </Box>
                    }>
                    <CampaignSummaryCardContent fullSummary={fetchFullSummary} full={full} />
                </Suspense>
            )}
        </Card>
    );
};

export interface Size {
    width: number;
    height: number;
}

export interface Point {
    x: number;
    y: number;
}

export interface Rect extends Size, Point {}

export interface Position<T extends PositionType = PositionType> extends Point {
    type: T;
}

export enum PositionType {
    Grid = "grid",
    LocalPixel = "pxl",
    ScreenPixel = "pxs",
}

export interface GridPosition extends Position<PositionType.Grid> {}

export interface LocalPixelPosition extends Position<PositionType.LocalPixel> {}

export interface ScreenPixelPosition extends Position<PositionType.ScreenPixel> {}

export interface PositionRect<T extends PositionType> extends Position<T>, Size {
    type: T;
}

export interface GridRect extends PositionRect<PositionType.Grid> {}

export interface LocalRect extends PositionRect<PositionType.LocalPixel> {}

export interface ScreenRect extends PositionRect<PositionType.ScreenPixel> {}

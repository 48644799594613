import { createLightShader, defaultLightTags, LightShaderTemplate } from "./common";

const defaultLightShaderText = createLightShader(`
    float distToP = distance(localFragCoord, u_position);
    gl_FragColor = vec4(u_color, (1.0 - smoothstep(u_near / u_far, 1.0, distToP / u_far)) * u_brightness);
`);
export const defaultLightShader: LightShaderTemplate = {
    id: "default",
    label: "Default light",
    tags: defaultLightTags,
    shader: defaultLightShaderText,
};

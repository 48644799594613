/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, useState } from "react";
import { BoxProps } from "../../../components/primitives";
import { PlusIcon, MinusIcon } from "@radix-ui/react-icons";
import { MotionBox, MotionHeading, defaultAnimate, defaultExit, defaultInitial } from "../../../components/motion";
import { AnimatePresence } from "framer-motion";

export const BasicExpander: FunctionComponent<
    React.PropsWithChildren<{ title: string; defaultIsExpanded?: boolean } & BoxProps>
> = ({ title, defaultIsExpanded, children, ...boxProps }) => {
    const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);
    return (
        <MotionBox layout fullWidth flexDirection="column" alignItems="stretch" {...(boxProps as any)}>
            <MotionBox
                layout
                aria-expanded={isExpanded}
                css={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                }}
                onClick={() => setIsExpanded(!isExpanded)}>
                <MotionHeading as="h5" mr={2} layout="position">
                    {title}
                </MotionHeading>
                <MotionBox layout>
                    {isExpanded && <MinusIcon />}
                    {!isExpanded && <PlusIcon />}
                </MotionBox>
            </MotionBox>
            <AnimatePresence>
                {isExpanded && (
                    <MotionBox
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        layout="position"
                        position="relative"
                        fullWidth
                        pt={2}
                        flexDirection="column"
                        alignItems="stretch">
                        {children}
                    </MotionBox>
                )}
            </AnimatePresence>
        </MotionBox>
    );
};

import React, { FunctionComponent, useCallback, useEffect } from "react";
import { LocalPixelPosition, Point } from "../../position";
import { Annotation, ConeAnnotation, TargettedAnnotation } from "../../annotations";
import { useLocalGrid, useValidatedLocation, useScale, useTokenOverrides } from "../contexts";
import { getPlayerColorPalette, getThemeColorPalette } from "../../design/utils";
import { theme } from "../../design";
import { HoverTracking, ZIndexes } from "./common";
import { LineWithCursor, Line } from "./three2d/Line";
import { resolveToken, Token } from "../../store";
import { ThreeEvent } from "@react-three/fiber";
import { applyOverrides } from "../../reducers/common";
import { CircleWithCursor } from "./three2d/Circle";
import { lengthOfVector, pointAlongLine } from "../../grid";
import { Html } from "@react-three/drei";
import { interactiveLineWidth } from "../common";
import { AnimatePresence, usePresence } from "framer-motion";
import { SelectionType } from "../selection";

interface TargettedAnnotationNodeProps extends HoverTracking {
    targetted: TargettedAnnotation;
    activePoint?: LocalPixelPosition;
    isSelected: SelectionType;
    wasSelected: boolean;
    disabled?: boolean;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onPointerDown?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onPointerUp?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onOverrideAnnotation: (id: string, override: Partial<ConeAnnotation> | undefined) => void;
    animateExit?: boolean;
}

const TargettedAnnotationNodeCore: FunctionComponent<TargettedAnnotationNodeProps> = ({
    targetted,
    activePoint,
    snapPoint,
    isSelected,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideAnnotation,
    disabled,
    isHovering,
    setHoverPart,
    wasSelected,
    animateExit,
}) => {
    const { system, campaign, location } = useValidatedLocation();
    const grid = useLocalGrid();
    const { tokenOverrides } = useTokenOverrides();
    const scale = useScale();

    // We need to manage presence manually because of the AnnotationHandles being in their own AnimatePresence - they need
    // to be given a chance to animate out.
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        if (!isPresent && !animateExit) {
            safeToRemove?.();
        }
    }, [isPresent, animateExit, safeToRemove]);

    let colours = disabled ? getThemeColorPalette("grayscale") : getPlayerColorPalette(campaign, targetted.userId);

    const onAnnotationClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, targetted);
            }
        },
        [targetted, onClick]
    );

    const isHover = onClick && (isHovering || !!activePoint);

    let source = location.tokens[targetted.tokenId];
    if (!source) {
        return <React.Fragment></React.Fragment>;
    }

    source = resolveToken(campaign, applyOverrides(source, tokenOverrides));

    const targets: Token[] = [];
    for (let key in targetted.targets) {
        const target = location.tokens[key];
        if (target) {
            targets.push(target);
        }
    }

    const sourceAppearance = system.getTokenAppearance?.(source, campaign, location) ?? source;
    const pos = grid.toLocalCenterPoint(source.pos, sourceAppearance.scale);

    const unselectedLineColor = colours[isHover ? 4 : 3];
    const lineColor = isSelected === SelectionType.Primary ? theme.colors.guidance.focus : unselectedLineColor;
    return (
        <React.Fragment>
            {(isPresent || animateExit) && (
                <AnimatePresence onExitComplete={safeToRemove ?? undefined}>
                    {isPresent &&
                        targets.map(o => {
                            const target = applyOverrides(resolveToken(campaign, o), tokenOverrides);
                            const targetAppearance = system.getTokenAppearance?.(target, campaign, location) ?? target;
                            const targetPos = grid.toLocalCenterPoint(target.pos, targetAppearance.scale);
                            const name = targetted.id + "_" + target.id;
                            const onTargetPointerDown = onPointerDown
                                ? (e: ThreeEvent<PointerEvent>) => {
                                      onPointerDown(e, targetted);
                                  }
                                : undefined;
                            const onTargetPointerUp = onPointerUp
                                ? (e: ThreeEvent<PointerEvent>) => {
                                      onPointerUp(e, targetted);
                                  }
                                : undefined;

                            const count = targetted.targets[target.id];
                            const a: Point = { x: pos.x, y: pos.y };
                            const b: Point = { x: targetPos.x, y: targetPos.y };
                            const midPoint =
                                count > 1
                                    ? pointAlongLine(a, b, lengthOfVector({ x: b.x - a.x, y: b.y - a.y }) / 2)
                                    : undefined;

                            return (
                                <React.Fragment key={name}>
                                    <LineWithCursor
                                        x={0}
                                        y={0}
                                        opacity={0}
                                        points={[a, b]}
                                        width={3 + interactiveLineWidth}
                                        cursor={disabled ? undefined : "pointer"}
                                        onPointerDown={onTargetPointerDown}
                                        onPointerUp={onTargetPointerUp}
                                        onClick={disabled ? undefined : onAnnotationClick}
                                        onPointerOver={() => setHoverPart?.(`${o.id}-line`, true)}
                                        onPointerOut={() => setHoverPart?.(`${o.id}-line`, false)}
                                        zIndex={
                                            isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations
                                        }
                                    />
                                    <Line
                                        x={0}
                                        y={0}
                                        points={[a, b]}
                                        width={3}
                                        animateEnterExit="nolayout"
                                        initial={
                                            isSelected
                                                ? { color: unselectedLineColor, opacity: 1, scale: 1 }
                                                : wasSelected
                                                ? {
                                                      color: theme.colors.guidance.focus,
                                                      opacity: 1,
                                                      scale: 1,
                                                  }
                                                : undefined
                                        }
                                        exit={animateExit ? undefined : null}
                                        color={lineColor}
                                        zIndex={
                                            isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations
                                        }
                                    />
                                    <CircleWithCursor
                                        x={targetPos.x}
                                        y={targetPos.y}
                                        cursor={disabled ? undefined : "pointer"}
                                        animateEnterExit="nolayout"
                                        initial={
                                            isSelected
                                                ? { color: unselectedLineColor, opacity: 1, scale: 1 }
                                                : wasSelected
                                                ? {
                                                      color: theme.colors.guidance.focus,
                                                      opacity: 1,
                                                      scale: 1,
                                                  }
                                                : undefined
                                        }
                                        exit={animateExit ? undefined : null}
                                        color={lineColor}
                                        radius={theme.space[2] / scale}
                                        onPointerDown={onTargetPointerDown}
                                        onPointerUp={onTargetPointerUp}
                                        onClick={disabled ? undefined : onAnnotationClick}
                                        onPointerOver={() => setHoverPart?.(`${o.id}-target`, true)}
                                        onPointerOut={() => setHoverPart?.(`${o.id}-target`, false)}
                                        segments={12}
                                        zIndex={
                                            isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations
                                        }
                                    />
                                    {midPoint && (
                                        <CircleWithCursor
                                            x={midPoint.x}
                                            y={midPoint.y}
                                            cursor={disabled ? undefined : "pointer"}
                                            animateEnterExit="nolayout"
                                            initial={
                                                isSelected
                                                    ? { color: unselectedLineColor, opacity: 1, scale: 1 }
                                                    : wasSelected
                                                    ? {
                                                          color: theme.colors.guidance.focus,
                                                          opacity: 1,
                                                          scale: 1,
                                                      }
                                                    : undefined
                                            }
                                            exit={animateExit ? undefined : null}
                                            color={lineColor}
                                            radius={theme.space[3] / scale}
                                            onPointerDown={onTargetPointerDown}
                                            onPointerUp={onTargetPointerUp}
                                            onClick={disabled ? undefined : onAnnotationClick}
                                            onPointerOver={() => setHoverPart?.(`${o.id}-mid`, true)}
                                            onPointerOut={() => setHoverPart?.(`${o.id}-mid`, false)}
                                            segments={24}
                                            zIndex={
                                                isSelected || activePoint
                                                    ? ZIndexes.UserInterface
                                                    : ZIndexes.Annotations
                                            }>
                                            <Html
                                                center
                                                zIndexRange={[1, 0]}
                                                style={{ pointerEvents: "none", userSelect: "none" }}>
                                                {count}
                                            </Html>
                                        </CircleWithCursor>
                                    )}
                                </React.Fragment>
                            );
                        })}
                </AnimatePresence>
            )}
        </React.Fragment>
    );
};

export const TargettedAnnotationNode = React.memo(TargettedAnnotationNodeCore);

import React, { FunctionComponent, MutableRefObject, PropsWithChildren, useEffect, useState } from "react";

import {
    Box,
    Card,
    Scrollable,
    Heading,
    Input,
    Grid,
    Image,
    Text,
    OverlayCard,
    InOverlayCard,
    Select,
} from "./primitives";
import { Message } from "./Message";
import { Tag } from "./Tag";
import { Spacer } from "./Spacer";
import { Button } from "./Button";
import { Form } from "./Form";
import { Toolbar } from "./Toolbar";
import { ExtractProps, Loading, LobotomizedBox } from "./common";
import {
    motion,
    AnimatePresence,
    MotionValue,
    useMotionValue,
    animate,
    usePresence,
    ValueAnimationTransition,
} from "framer-motion";
import { theme } from "../design";
import { dragDropPalette } from "./draggable";
import { Deferred } from "../common";
import { OverlayToolbar } from "./Toolbar/Toolbar";

export const MotionToolbar = motion(Toolbar);
export const MotionOverlayToolbar = motion(OverlayToolbar);
export const MotionCard = motion(Card);
export const MotionBox = motion(Box);
export const MotionImage = motion(Image);
export const MotionGrid = motion(Grid);
export const MotionMessage = motion(Message);
export const MotionLoading = motion(Loading);
export const MotionForm = motion(Form);
export const MotionScrollable = motion(Scrollable) as unknown as any; // TODO: The children prop doesn't come through for some reason?
export const MotionHeading = motion(Heading) as unknown as any; // TODO: The children prop doesn't come through for some reason?
export const MotionLobotomizedBox = motion(LobotomizedBox);
export const MotionInput = motion(Input);
export const MotionSelect = motion(Select);
export const MotionTag = motion(Tag);
export const MotionText = motion(Text);
export const MotionSpacer = motion(Spacer);
export const MotionOverlayCard = motion(OverlayCard);
export const MotionInOverlayCard = motion(InOverlayCard);

// Button has a tooltip, which makes it behave strangely with framer-motion (because the tooltip adds a wrapper element, but the
// ref that is forwarded is the inner button). This causes it to look like the button is bouncing around when it shouldn't be.
// If you want to animate a button, you'll just have to wrap it in a MotionBox or something instead.
export const MotionButton = motion(Button);

export const defaultMotionScale = 0.95;

export const defaultInitial = { opacity: 0, scale: defaultMotionScale };
export const defaultAnimate = { opacity: 1, scale: 1 };
export const defaultExit = { opacity: 0, scale: defaultMotionScale };
export const sectionInitial = { opacity: 0, scale: 0.97, originX: 0.5, originY: 0 };
export const sectionAnimate = { opacity: 1, scale: 1, originX: 0.5, originY: 0 };
export const sectionExit = { opacity: 0, scale: 0.97, originX: 0.5, originY: 0 };
export const tinyInitial = { opacity: 0, scale: 0.8 };
export const tinyAnimate = { opacity: 1, scale: 1 };
export const tinyExit = { opacity: 0, scale: 0.8 };

type BoxProps = Omit<ExtractProps<typeof Box>, "ref">;
type MotionBoxProps = Omit<ExtractProps<typeof MotionBox>, "ref">;

export const AnimatedList = React.forwardRef<HTMLDivElement, BoxProps & { initial?: boolean }>(
    ({ children, style, initial, ...props }, ref) => {
        // TODO: Using the css prop doesn't work here for some reason.
        const mystyle = { gap: theme.space[2] };
        const finalStyle = style ? Object.assign({}, style, mystyle) : mystyle;
        return (
            <Box
                fullWidth
                paddingY={2}
                position="relative"
                flexDirection="column"
                {...props}
                style={finalStyle}
                ref={ref}>
                <AnimatePresence mode="popLayout" initial={initial}>
                    {children}
                </AnimatePresence>
            </Box>
        );
    }
);

export interface AnimatedListItemProps {
    index?: number;
}

export const AnimatedListItem = React.forwardRef<
    HTMLDivElement,
    {
        index?: number;
        layout?: boolean | "size" | "position" | "preserve-aspect" | undefined;
    } & MotionBoxProps
>(({ children, index, initial, animate, exit, layout, ...props }, ref) => {
    const finalInitial = initial ? Object.assign({}, defaultInitial, initial) : defaultInitial;
    const finalAnimate = Object.assign(
        {},
        defaultAnimate,
        animate,
        index != null && index > 0 ? { transition: { delay: 0.02 * index } } : undefined
    );
    const finalExit = exit ? Object.assign({}, defaultExit, exit) : defaultExit;
    return (
        <MotionBox
            layout={layout !== undefined ? layout : true}
            fullWidth
            initial={finalInitial}
            animate={finalAnimate}
            exit={finalExit}
            {...props}
            ref={ref}>
            {children}
        </MotionBox>
    );
});

export const DropOverlay: FunctionComponent<ExtractProps<typeof MotionBox> & { isDragOver: boolean }> = ({
    children,
    isDragOver,
    style,
    ...props
}) => {
    // css prop doesn't work here for some reason.
    return (
        <MotionBox
            style={Object.assign({}, style, { pointerEvents: "none" as any })}
            zIndex={1}
            position="absolute"
            left={0}
            top={0}
            right={0}
            bottom={0}
            {...props}
            initial={{ opacity: 0 }}
            animate={{ opacity: isDragOver ? 0.3 : 0 }}
            bg={dragDropPalette[1]}
        />
    );
};

const inactiveShadow = "0px 0px 0px rgba(0,0,0,0.8)";

export function useDragShadow(value: MotionValue<number>) {
    const [isDragging, setIsDragging] = useState(false);
    const boxShadow = useMotionValue(inactiveShadow);

    useEffect(() => {
        let isActive = false;
        return value.on("change", latest => {
            const wasActive = isActive;
            if (latest !== 0) {
                isActive = true;
                if (isActive !== wasActive) {
                    animate(boxShadow, "5px 5px 10px rgba(0,0,0,0.3)");
                    setIsDragging(true);
                }
            } else {
                isActive = false;
                if (isActive !== wasActive) {
                    animate(boxShadow, inactiveShadow);
                    setIsDragging(false);
                }
            }
        });
    }, [value, boxShadow]);

    return { isDragging, boxShadow };
}

/**
 * Use to repeat AnimatePresence status so that you can have an AnimatePresence that also animates when the component is removed from
 * its AnimatePresence ancestor, e.g.
 * <AnimatePresence>
 *     {condition && <AnimatePresenceForwarder>
 *         {condition2 && <Children />}
 *     </AnimatePresenceForwarder>}
 * </AnimatePresence>
 * Will animate exit animations on Children if EITHER condition or condition2 is changed to false.
 *
 * If this doesn't seem to be working, try ensuring that every child has a key.
 */
export const AnimatePresenceRepeater: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [isPresent, safeToRemove] = usePresence();
    return <AnimatePresence onExitComplete={safeToRemove ?? undefined}>{isPresent && children}</AnimatePresence>;
};

export interface AnimationStep {
    values: AnimationStepValue[];
    onStarting?: () => void;
}

export interface AnimationStepValue {
    motionValue: MotionValue<number>;
    value: number;
    options?: ValueAnimationTransition;
}

export function animateSequence(idRef: MutableRefObject<number | undefined>, steps: AnimationStep[]) {
    if (steps.length > 0) {
        const id = (idRef.current ?? 0) + 1;
        idRef.current = id;

        const firstStep = steps[0];
        firstStep.onStarting?.();
        applyAnimationStep(firstStep);

        return animateSequenceCore(idRef, id, steps).finally(() => {
            if (idRef.current === id) {
                idRef.current = undefined;
            }
        });
    } else {
        return Promise.resolve();
    }
}

async function animateSequenceCore(idRef: MutableRefObject<number | undefined>, id: number, steps: AnimationStep[]) {
    try {
        for (let i = 1; i < steps.length && id === idRef.current; i++) {
            steps[i].onStarting?.();
            await performAnimationStep(steps[i]);
        }
    } catch {
        // The animation was cancelled.
        throw new Error();
    }
}

function performAnimationStep(step: AnimationStep): Promise<void> {
    let remaining = step.values.length;

    const op = new Deferred<void>();
    for (let i = 0; i < step.values.length; i++) {
        const mv = step.values[i].motionValue;
        const v = step.values[i].value;

        // eslint-disable-next-line no-loop-func
        performAnimationValue(mv, v, step.values[i].options, cancel => {
            if (remaining > 0) {
                remaining = cancel ? -1 : remaining - 1;

                if (remaining === 0) {
                    // The step completed successfully, move onto the next one.
                    op.resolve();
                } else if (remaining < 0) {
                    // The animation was cancelled, reject it and skip the rest of the steps.
                    op.reject(undefined);
                }
            } else {
                // The animation was already cancelled, we can safely ignore.
            }
        });
    }

    return op.promise;
}

function performAnimationValue(
    motionValue: MotionValue<number>,
    value: number,
    options: ValueAnimationTransition | undefined,
    onComplete: (cancel: boolean) => void
) {
    const unsubComplete = motionValue.on("animationComplete", () => {
        onComplete(false);
        unsubComplete();
        unsubCancel();
    });
    const unsubCancel = motionValue.on("animationCancel", () => {
        onComplete(true);
        unsubCancel();
        unsubComplete();
    });

    animate(motionValue, value, options);
}

function applyAnimationStep(step: AnimationStep) {
    for (let i = 0; i < step.values.length; i++) {
        const mv = step.values[i].motionValue;
        mv.set(step.values[i].value);
    }
}

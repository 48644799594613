/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { Box, Text } from "../../../../components/primitives";
import { getCoreAbilityAbbr, ResolvedMonster, resolveModifiedValue } from "../../creature";
import { advantageOrDisadvantage, CoreAbility, DnD5EToken, DnD5ETokenTemplate } from "../../common";
import { theme } from "../../../../design";
import { RollButtonHorizontal } from "../RollButton";
import { SavingThrowLogEntry } from "../../logentries";
import { RollOptions } from "../../../../store";
import { AdvantageBox } from "../AdvantageBox";
import { ButtonVariant } from "../../../../components/Button";

export const MonsterSavingThrowBox: FunctionComponent<
    Omit<RollOptions, "token" | "location"> & {
        ability: CoreAbility;
        variant?: ButtonVariant;
        monster: ResolvedMonster;
        token: DnD5EToken | DnD5ETokenTemplate;
        onRoll?: (roll: { unconfirmed: SavingThrowLogEntry; confirmed: Promise<SavingThrowLogEntry> }) => void;
    }
> = ({ variant, ability, monster, token, onRoll, data, ...rollOptions }) => {
    const modifier = resolveModifiedValue(monster.savingThrows[ability]);

    // Can only include non-conditionals for the purposes of working out whether we should be at adv/dis by default,
    // because we can't evaluate most conditions automatically.
    let adv = advantageOrDisadvantage(
        monster.savingThrows[ability].advantage,
        monster.savingThrows[ability].disadvantage
    );

    return (
        <Box flexDirection="column" alignItems="stretch" flexGrow={1} flexShrink={1}>
            <RollButtonHorizontal
                variant={variant}
                token={token}
                modifier={modifier}
                advantage={adv}
                extraRolls={monster.savingThrows[ability].extraRolls}
                data={Object.assign({}, data, { savingThrow: ability })}
                onRoll={onRoll as any}
                {...rollOptions}>
                <Box width={theme.space[2]} height={theme.space[2]} borderRadius="100%" bg="grayscale.2" mr={2} />
                <Text fontSize={1} color="grayscale.2" style={{ flexGrow: 1 }}>
                    {getCoreAbilityAbbr(ability)}
                </Text>
                <Text fontSize={4}>{modifier >= 0 ? "+" + modifier : modifier}</Text>
            </RollButtonHorizontal>
            {monster.savingThrows[ability].advantage?.map((o, i) => (
                <AdvantageBox key={i} advantage="adv" justifyContent="flex-start" paddingX={3} mt={1} {...o} />
            ))}
            {monster.savingThrows[ability].disadvantage?.map((o, i) => (
                <AdvantageBox key={i} advantage="dis" justifyContent="flex-start" paddingX={3} mt={1} {...o} />
            ))}
            {monster.savingThrows[ability].fail?.map((o, i) => (
                <AdvantageBox key={i} advantage="fail" justifyContent="flex-start" paddingX={3} mt={1} {...o} />
            ))}
        </Box>
    );
};

import { IGameSystem, Light, LightType, TokenTemplate } from "../../../store";
import { LightShaderTemplate } from "./common";
import { defaultLightShader } from "./default";
import { torchLightShader } from "./torch";
import { pulseLightShader } from "./pulse";
import { flickerLightShader } from "./flicker";

export * from "./common";
export * from "./default";

export const lightTemplates: { [key in LightType]: LightShaderTemplate } = {
    default: defaultLightShader,
    torch: torchLightShader,
    pulse: pulseLightShader as LightShaderTemplate,
    flicker: flickerLightShader,
};

export const lightTokenTemplates = Object.keys(lightTemplates).map<TokenTemplate>(o => {
    const lightTemplate = lightTemplates[o];
    return {
        templateId: lightTemplate.id,
        light: { type: lightTemplate.id },
        sound: lightTemplate.sound,
    };
});

export function resolveLight<T extends Light>(system: IGameSystem, light?: T) {
    const template = light ? lightTemplates[light.type] : undefined;
    const finalLight = Object.assign({}, system.defaultLight, template?.defaults, light);
    return finalLight;
}

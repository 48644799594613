import { createTokensOrTokenTemplatesAction, TokensAction, TokenTemplatesAction } from "../../../actions/common";
import { Token, TokenTemplate, Campaign, LocationSummary } from "../../../store";
import { ResolvedInventoryItem, ResolvedItem } from "../items";

export function equipItems(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    item: { ids: string[]; active: boolean }
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_EquipItem", item, campaign, location, tokens);
}

export function attuneItems(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    item: { ids: string[]; attuned: boolean }
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_AttuneItem", item, campaign, location, tokens);
}

export function modifyCurrency(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    currency: { [abbr: string]: number }
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_ModifyCurrency", currency, campaign, location, tokens);
}

export function addItems(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    items: {
        from?: Token | TokenTemplate;
        to?: Token | TokenTemplate;
        items: (number | ResolvedItem)[];
        inventoryKeys?: string[];
    }
): TokensAction | TokenTemplatesAction {
    if (items.from || items.to) {
        // If we're moving an item from one inventory to another, from and to must both be specified
        // and no tokens should be specified.
        if (items.from != null && items.to == null) {
            throw new Error("If from is specified, then to must be.");
        }

        if (tokens.length) {
            throw new Error("If from or to are specified, no other tokens should be specified.");
        }

        tokens = items.from ? [items.from, items.to!] : [items.to!];
    }

    return createTokensOrTokenTemplatesAction("DnD5E_AddItems", items, campaign, location, tokens);
}

export function removeItems(
    campaign: Campaign | string,
    location: LocationSummary | string | undefined,
    tokens: (Token | TokenTemplate)[],
    items: ResolvedInventoryItem[]
): TokensAction | TokenTemplatesAction {
    return createTokensOrTokenTemplatesAction("DnD5E_RemoveItems", items, campaign, location, tokens);
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { Box, Text } from "../../../components/primitives";
import { PlusIcon, MinusIcon } from "@radix-ui/react-icons";
import { Badge } from "../../../components/Badge";
import {
    sectionAnimate,
    sectionInitial,
    sectionExit,
    MotionBox,
    MotionInOverlayCard,
} from "../../../components/motion";
import { theme } from "../../../design";
import { AnimatePresence, motion } from "framer-motion";
import { Markdown } from "../../../components/markdown";
import { ExtractProps } from "../../../components/common";

export const MotionBadge = motion(Badge);

export const FeatureExpander: FunctionComponent<
    React.PropsWithChildren<
        {
            title: string | React.ReactElement | (string | React.ReactElement)[];
            isInOverlay?: boolean;
            cardHeader?: boolean;
            innerPaddingH?: number;
            innerPaddingV?: number;
            subtitle?: string;
            badge?: string;
            header?: ReactNode;
            expandedByDefault?: boolean;
            cursor?: string;
            layout?: boolean | "size" | "position" | "preserve-aspect";
        } & ExtractProps<typeof Box>
    >
> = ({
    title,
    subtitle,
    isInOverlay,
    badge,
    children,
    header,
    expandedByDefault,
    innerPaddingH,
    innerPaddingV,
    cardHeader,
    cursor,
    bg,
    layout,
    ...boxProps
}) => {
    const [isExpanded, setIsExpanded] = useState(expandedByDefault);
    return (
        <Box fullWidth {...(boxProps as any)}>
            <MotionInOverlayCard
                layout={layout ?? true}
                fullWidth
                bg={bg ?? (!isInOverlay ? "grayscale.8" : undefined)}
                flexShrink={1}
                flexDirection="column"
                alignItems="flex-start"
                position="relative"
                style={{
                    borderTopLeftRadius: cardHeader ? 4 : 3,
                    borderTopRightRadius: cardHeader ? 4 : 3,
                    borderBottomLeftRadius: cardHeader ? 0 : 3,
                    borderBottomRightRadius: cardHeader ? 0 : 3,
                }}>
                <MotionBox
                    layout={layout ?? true}
                    css={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        cursor: cursor ?? "pointer",
                    }}
                    px={innerPaddingH ?? 2}
                    py={innerPaddingV ?? 2}
                    fullWidth
                    animate={{
                        backgroundColor: badge != null ? theme.colors.transparency[2].grayscale[6] : undefined,
                    }}
                    aria-expanded={isExpanded}
                    borderTopLeftRadius={cardHeader ? 4 : 3}
                    borderTopRightRadius={cardHeader ? 4 : 3}
                    borderBottomLeftRadius={isExpanded || cardHeader ? 0 : 3}
                    borderBottomRightRadius={isExpanded || cardHeader ? 0 : 3}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}>
                    <MotionBox
                        layout={layout ?? true ? "position" : false}
                        flexDirection="column"
                        alignItems="flex-start"
                        flexShrink={1}>
                        <Markdown>{"### " + title}</Markdown>
                        {subtitle && (
                            <Text color="grayscale.4" fontSize={0} lineHeight={0}>
                                {subtitle}
                            </Text>
                        )}
                    </MotionBox>
                    <Box>
                        {header}
                        {isExpanded && <MinusIcon />}
                        {!isExpanded && <PlusIcon />}
                    </Box>
                </MotionBox>
                <AnimatePresence mode="popLayout">
                    {isExpanded && (
                        <MotionBox
                            key="content"
                            fullWidth
                            layout={layout ?? true ? "position" : false}
                            initial={sectionInitial}
                            animate={sectionAnimate}
                            exit={sectionExit}
                            px={innerPaddingH ?? 2}
                            py={innerPaddingV ?? 2}
                            flexDirection="column"
                            overflow="hidden"
                            position={isExpanded ? "relative" : "absolute"}
                            alignItems="flex-start">
                            {children}
                        </MotionBox>
                    )}
                    {badge && (
                        <MotionBadge
                            key="badge"
                            layout={layout ?? true ? "position" : false}
                            initial={sectionInitial}
                            animate={sectionAnimate}
                            exit={sectionExit}
                            bg="guidance.info.1"
                            color="guidance.info.0"
                            css={{
                                position: "absolute",
                                right: -theme.space[2],
                                top: -theme.space[2],
                            }}>
                            {badge}
                        </MotionBadge>
                    )}
                </AnimatePresence>
            </MotionInOverlayCard>
        </Box>
    );
};

/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";

import { Vector, VectorProps } from "../primitives";

export const AlertCircle: React.FunctionComponent<VectorProps> = forwardRef(
  (props, ref) => (
    <Vector ref={ref} {...props} viewBox="0 0 24 24">
      <path d="M11.25 13.5C11.25 13.9142 11.5858 14.25 12 14.25C12.4142 14.25 12.75 13.9142 12.75 13.5H11.25ZM12.75 5.25C12.75 4.83579 12.4142 4.5 12 4.5C11.5858 4.5 11.25 4.83579 11.25 5.25H12.75ZM12 15.75C11.3788 15.75 10.875 16.2538 10.875 16.875H12.375C12.375 17.0822 12.2072 17.25 12 17.25V15.75ZM10.875 16.875C10.875 17.4962 11.3788 18 12 18V16.5C12.2072 16.5 12.375 16.6678 12.375 16.875H10.875ZM12 18C12.6212 18 13.125 17.4962 13.125 16.875H11.625C11.625 16.6678 11.7928 16.5 12 16.5V18ZM13.125 16.875C13.125 16.2538 12.6212 15.75 12 15.75V17.25C11.7928 17.25 11.625 17.0822 11.625 16.875H13.125ZM12.75 13.5V5.25H11.25V13.5H12.75ZM12 1.5C17.7988 1.5 22.5 6.20121 22.5 12H24C24 5.37279 18.6272 0 12 0V1.5ZM22.5 12C22.5 17.7988 17.7988 22.5 12 22.5V24C18.6272 24 24 18.6272 24 12H22.5ZM12 22.5C6.20121 22.5 1.5 17.7988 1.5 12H0C0 18.6272 5.37279 24 12 24V22.5ZM1.5 12C1.5 6.20121 6.20121 1.5 12 1.5V0C5.37279 0 0 5.37279 0 12H1.5Z" />
    </Vector>
  )
);

AlertCircle.displayName = "AlertCircle";

AlertCircle.defaultProps = {
  width: 24,
  height: 24,
  fill: "currentcolor",
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { asField, IAsFieldProps } from "./Form";
import { Button } from "./Button";
import { theme } from "../design";
import { defaultAnimate, defaultExit, defaultInitial, MotionBox, MotionImage } from "./motion";
import { ImageType, Token, TokenTemplate } from "../store";
import { LobotomizedBox, useVttApp } from "./common";
import { useAppState, useDispatch, useLocation } from "./contexts";
import { useLocalSetting } from "./utils";
import { artFilterSetting } from "./Sidebar/ArtLibrary";
import { defaultSections, Pages } from "./Sidebar";
import { AnimatePresence } from "framer-motion";
import { setPortraitImage } from "../actions/token";
import { dragDropPalette } from "./draggable";

interface PortraitImageProps {
    token: Token | TokenTemplate;
    isDragOver: boolean;
    isDragActive: boolean;
}

const artSection = defaultSections.find(o => o.id === Pages.Art)!;

export const PortraitImage: FunctionComponent<PortraitImageProps> = ({ token, isDragOver, isDragActive }) => {
    const { searchPropertiesSection, setSearchPropertiesSection } = useAppState();
    const { isSearchExpanded, setIsSearchExpanded } = useVttApp();
    const [artFilter, setArtFilter] = useLocalSetting(artFilterSetting);
    const dispatch = useDispatch();
    const { campaign, location } = useLocation();

    const isFindingPortraits =
        isSearchExpanded && searchPropertiesSection === artSection && artFilter?.type === ImageType.Portrait;

    return (
        <MotionBox layout="position" fullWidth justifyContent="flex-start" alignItems="flex-start">
            <MotionBox
                width={102}
                height={138}
                animate={{
                    background: isDragActive
                        ? isDragOver
                            ? dragDropPalette[1]
                            : dragDropPalette[0]
                        : theme.colors.grayscale[9],
                }}
                borderRadius={3}
                css={{ overflow: "hidden" }}>
                <AnimatePresence mode="wait" initial={false}>
                    {token.portraitImageUri ? (
                        <MotionImage
                            key={token.portraitImageUri}
                            src={token.portraitImageUri}
                            responsive
                            fullWidth
                            fullHeight
                            draggable={false}
                            initial={defaultInitial}
                            animate={Object.assign({}, defaultAnimate, {
                                opacity: isDragActive ? 0.5 : 1,
                            })}
                            exit={defaultExit}
                        />
                    ) : (
                        ""
                    )}
                </AnimatePresence>
            </MotionBox>

            <LobotomizedBox ml={2} flexDirection="column" alignItems="stretch">
                <Button
                    disabled={isFindingPortraits}
                    onClick={() => {
                        setArtFilter({ type: ImageType.Portrait });
                        setSearchPropertiesSection(artSection);
                        setIsSearchExpanded(true);
                    }}>
                    {token.portraitImageUri ? "Find a new portrait…" : "Find a portrait…"}
                </Button>
                <Button
                    disabled={!token.portraitImageUri}
                    onClick={() => {
                        dispatch(setPortraitImage(campaign, location, [token], undefined));
                    }}>
                    Remove portrait
                </Button>
            </LobotomizedBox>
        </MotionBox>
    );
};

export const PortraitImageField = asField<HTMLDivElement, PortraitImageProps & IAsFieldProps>(PortraitImage);

import { HubConnection, HubConnectionState } from "@aspnet/signalr";
import { IRtcSelf, IRtcSession, IRtcUser } from "./store";
import {
    audioInputDeviceSetting,
    Event,
    localSettingChanged,
    LocalSettingChangedEvent,
    videoInputDeviceSetting,
} from "./common";
import hark, { Harker } from "hark";

class RtcBase {
    private _updated: Event<void>;
    private _session: RtcSession;
    private _stream: MediaStream | undefined;
    private _hark: Harker | undefined;
    private _harkStream: MediaStream | undefined;
    private _isSpeaking: boolean;

    constructor(session: RtcSession) {
        this._session = session;
        this._isSpeaking = false;
        this._updated = new Event();
    }

    get stream() {
        return this._stream;
    }

    get isSpeaking() {
        return this._isSpeaking;
    }

    get updated() {
        return this._updated;
    }

    get session(): IRtcSession {
        return this._session;
    }

    protected get sessionInternal(): RtcSession {
        return this._session;
    }

    protected setStream(stream: MediaStream, isLocal: boolean) {
        if (this._stream === stream) {
            return;
        }

        this.disposeStream();

        this._stream = stream;
        if (isLocal) {
            this._harkStream = this._stream.clone();
        }

        this._hark = hark(this._harkStream ? this._harkStream : this._stream);
        this._hark.on("speaking", () => {
            this._isSpeaking = true;
            this._updated.trigger();
        });
        this._hark.on("stopped_speaking", () => {
            this._isSpeaking = false;
            this._updated.trigger();
        });
        this._updated.trigger();

        stream.addEventListener("addtrack", e => {
            // console.log("Stream for peer ${} stream added track of kind " + e.track.kind);
            this.updated.trigger();
        });
        stream.addEventListener("removetrack", e => {
            // console.log("Peer stream removed track of kind " + e.track.kind);
            this.updated.trigger();
        });
    }

    dispose() {
        this.disposeStream();
    }

    private disposeStream() {
        if (this._stream) {
            this._stream.getTracks().forEach(track => track.stop());

            if (this._harkStream) {
                this._harkStream.getTracks().forEach(track => track.stop());
            }

            this._hark!.stop();
        }
    }
}

class RtcSelf extends RtcBase implements IRtcSelf {
    constructor(session: RtcSession, stream: MediaStream) {
        super(session);
        this.setStream(stream, true);
    }

    /**
     * Gets a value indicating whether the video is muted for this local user.
     * Setting this to true prevents the camera feed from being sent to peers.
     */
    get isVideoMuted() {
        const track = this.videoTrack;
        return !!track && !track.enabled;
    }

    set isVideoMuted(value: boolean) {
        const track = this.videoTrack;
        if (track) {
            track.enabled = !value;
            this.updated.trigger();
        }
    }

    /**
     * Gets or sets a value indicating whether video is enabled for this session.
     */
    get isVideoEnabled() {
        return this.sessionInternal.isVideoEnabled;
    }

    set isVideoEnabled(value: boolean) {
        this.sessionInternal.isVideoEnabled = value;
    }

    /**
     * Gets a value indicating whether the audio is muted for this local user.
     * Setting this to true prevents the sound gathered from the mic from being sent to peers.
     */
    get isAudioMuted() {
        const track = this.audioTrack;
        return !!track && !track.enabled;
    }

    set isAudioMuted(value: boolean) {
        const track = this.audioTrack;
        if (track) {
            // TODO: I think this will prevent the speech detection from working. Really we still want to
            // allow that to work, so that we can show a prompt that you're still muted when we detect
            // speech from the local user while muted.
            track.enabled = !value;
            this.updated.trigger();
        }
    }

    private get videoTrack() {
        if (!this.stream) {
            return undefined;
        }

        const tracks = this.stream.getVideoTracks();
        return tracks.length ? tracks[0] : undefined;
    }

    private get audioTrack() {
        if (!this.stream) {
            return undefined;
        }

        const tracks = this.stream.getAudioTracks();
        return tracks.length ? tracks[0] : undefined;
    }
}

class RtcPeer extends RtcBase implements IRtcUser {
    private _userId: string;
    private _connection: RTCPeerConnection;

    constructor(session: RtcSession, userId: string, connection: RTCPeerConnection) {
        super(session);
        this._userId = userId;
        this._connection = connection;

        this._connection.addEventListener("track", e => {
            console.log(`Received media track of kind ${e.track.kind} from ${this._userId}.`);
            setTimeout(() => this.setStream(e.streams[0], false), 0);
        });
    }

    get userId() {
        return this._userId;
    }

    get connection() {
        return this._connection;
    }

    dispose() {
        super.dispose();
        this._connection.close();
    }
}

export function convertUserMediaError(error: Error) {
    // Handle specific errors with some good error messages.
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    let e = error;
    if (error) {
        if (error.name === "NotAllowedError") {
            e = new Error("Access to the microphone and/or camera was denied.");
        } else if (error.name === "NotFoundError") {
            e = new Error("Could not find a microphone and/or camera device.");
        } else if (error.name === "NotReadableError") {
            e = new Error(
                "A hardware error occurred at the operating system, browser, or Web page level which prevented access to the microphone and/or camera."
            );
        } else if (error.name === "OverconstrainedError") {
            // TODO: Support this? We're not even providing constraints (yet)...
        } else if (error.name === "SecurityError") {
            e = new Error("Access to the microphone and/or camera was denied by the browser.");
        }

        e.name = error.name;
    }

    return e;
}

export class RtcSession implements IRtcSession {
    private _rtc: HubConnection;
    private _updated: Event<void>;
    private _peers: { [id: string]: RtcPeer };
    private _isJoined: boolean;
    private _joiningPromise: Promise<void> | undefined;
    private _leavingPromise: Promise<void> | undefined;
    private _self: RtcSelf | undefined;
    private _isVideoEnabled: boolean;

    private _localSettingChanged: (e: LocalSettingChangedEvent) => void;

    static readonly idealAspectRatio = 4 / 3;
    static readonly idealHeight = 200;
    static readonly idealWidth = RtcSession.idealAspectRatio * RtcSession.idealHeight;

    constructor(rtc: HubConnection) {
        this._rtc = rtc;
        this._updated = new Event();
        this._peers = {};
        this._isJoined = false;
        this._isVideoEnabled = true;

        this._rtc.onclose(o => {
            if (o) {
                this.leave();
            }
        });

        this._localSettingChanged = async e => {
            if (this._isJoined && (e.setting === audioInputDeviceSetting || e.setting === videoInputDeviceSetting)) {
                this.updateStream();
            }
        };
    }

    private async updateStream() {
        if (!this.self?.stream) {
            return;
        }

        const stream = await this.getStream();
        const audio = stream.getAudioTracks();
        const video = stream.getVideoTracks();

        const requiresNegotiation = new Set<RtcPeer>();
        this.updateTrack(audio && audio.length ? audio[0] : undefined, "audio", requiresNegotiation);
        this.updateTrack(video && video.length ? video[0] : undefined, "video", requiresNegotiation);

        this.updated.trigger();
        this.self.updated.trigger();

        requiresNegotiation.forEach(peer => this.negotiate(peer));
    }

    private updateTrack(track: MediaStreamTrack | undefined, kind: string, requiresNegotiation: Set<RtcPeer>) {
        if (!track && !kind) {
            return;
        }

        const stream = this.self?.stream;
        if (stream) {
            const selfTrack = stream.getTracks().find(o => o.kind === kind);
            if (selfTrack) {
                selfTrack.stop();
                stream.removeTrack(selfTrack);
            }

            if (track) {
                stream.addTrack(track);
            }
        }

        for (let peerId in this._peers) {
            const peer = this._peers[peerId];
            const connection = peer.connection;
            const sender = connection.getSenders().find(o => o.track?.kind === kind);
            if (sender) {
                if (track) {
                    sender.replaceTrack(track);
                } else {
                    connection.removeTrack(sender);
                    requiresNegotiation.add(peer);
                }
            } else if (track) {
                connection.addTrack(track, ...(stream ? [stream] : []));
                requiresNegotiation.add(peer);
            }
        }
    }

    private async negotiate(peer: RtcPeer) {
        console.log(`Creating offer for peer ${peer.userId}...`);

        const sessionDescription = await peer.connection.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
        });
        await peer.connection.setLocalDescription(sessionDescription);

        console.log(`Successfully created offer for peer ${peer.userId}:`);
        // console.log(sessionDescription);

        this._rtc.invoke("relayRtcSessionDescription", peer.userId, sessionDescription);
    }

    /**
     * Occurs when one of the properties (including the dictionary of peers) is updated.
     */
    get updated() {
        return this._updated;
    }

    /**
     * Gets a value indicating whether the current user is connected to the RTC call.
     */
    get isJoined() {
        return this._isJoined;
    }

    /**
     * Gets a value indicating whether the current user is attempting to connect to the RTC call.
     */
    get isJoining() {
        return !!this._joiningPromise && !this._isJoined;
    }

    /**
     * Gets a value indicating whether the current user is currently leaving the RTC call.
     */
    get isLeaving() {
        return !!this._leavingPromise && this._isJoined;
    }

    /**
     * Gets or sets a value indicating whether video will be included in the call if available.
     */
    get isVideoEnabled() {
        return this._isVideoEnabled;
    }

    set isVideoEnabled(value: boolean) {
        if (!!value !== !!this._isVideoEnabled) {
            this._isVideoEnabled = value;
            this.self?.updated.trigger();
            this.updateStream();
        }
    }

    get self(): IRtcSelf | undefined {
        return this._self;
    }

    get peers(): { readonly [id: string]: IRtcUser } {
        return this._peers;
    }

    async join(withVideo?: boolean) {
        if (this._leavingPromise) {
            return;
        }

        this._isVideoEnabled = withVideo == null ? true : withVideo;
        if (!this._joiningPromise) {
            this._joiningPromise = this.joinCore().catch(e => {
                delete this._joiningPromise;
                this._updated.trigger();

                return Promise.reject(convertUserMediaError(e));
            });
            this._updated.trigger();
        }

        return this._joiningPromise;
    }

    private async getStream(): Promise<MediaStream> {
        let stream: MediaStream | undefined;
        let audioConstraints = this.getAudioConstraints();
        let videoConstraints = this.getVideoConstraints();
        do {
            // Try to get the stream with video. If it works, great! If not, there might not
            // be a video device, or the one that the user has specified is not found. In that
            // case, attempt to continue with audio only.
            try {
                stream = await navigator.mediaDevices.getUserMedia({
                    audio: audioConstraints,
                    video: videoConstraints,
                });
            } catch (err: any) {
                if (videoConstraints && err && err.name === "NotFoundError") {
                    stream = await navigator.mediaDevices.getUserMedia({
                        audio: audioConstraints,
                        video: false,
                    });
                } else {
                    throw err;
                }
            }

            const audioConstraintsAfter = this.getAudioConstraints();
            const videoConstraintsAfter = this.getVideoConstraints();
            if (
                JSON.stringify(audioConstraints) !== JSON.stringify(audioConstraintsAfter) ||
                JSON.stringify(videoConstraints) !== JSON.stringify(videoConstraintsAfter)
            ) {
                stream.getTracks().forEach(o => o.stop());
                stream = undefined;
                audioConstraints = audioConstraintsAfter;
                videoConstraints = videoConstraintsAfter;
            }
        } while (stream == null);
        return stream;
    }

    private getAudioConstraints(): boolean | MediaTrackConstraints {
        let audioInputDevice = audioInputDeviceSetting.getValue();
        return audioInputDevice ? { deviceId: { exact: audioInputDevice } } : true;
    }

    private getVideoConstraints(): boolean | MediaTrackConstraints {
        if (!this.isVideoEnabled) {
            return false;
        }

        const constraints: MediaTrackConstraints = {
            aspectRatio: { ideal: RtcSession.idealAspectRatio },
            width: { ideal: RtcSession.idealWidth },
            height: { ideal: RtcSession.idealHeight },
        };

        let videoInputDevice = videoInputDeviceSetting.getValue();
        if (videoInputDevice) {
            constraints.deviceId = { exact: videoInputDevice };
        }

        return constraints;
    }

    private async joinCore() {
        // First get access to our local media stream
        const stream = await this.getStream();

        // Now that we definitely have what the user requested, listen for changes in their preferences.
        localSettingChanged.on(this._localSettingChanged);

        this._self = new RtcSelf(this, stream);

        this._rtc.on("addRtcPeer", async (peerId, shouldCreateOffer) => {
            console.log(`Adding RTC peer ${peerId}, create offer is ${!!shouldCreateOffer}.`);

            if (this._peers[peerId]) {
                console.log(`RTC peer ${peerId} already existed.`);
                return;
            }

            const peerConnection = new RTCPeerConnection({
                iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
            });

            const peer = new RtcPeer(this, peerId, peerConnection);
            this._peers[peerId] = peer;
            peerConnection.addEventListener("icecandidate", e => {
                if (e.candidate) {
                    // console.log(`Found ICE candidate for peer ${peerId}:`);
                    // console.log(e.candidate);
                    this._rtc.invoke("relayRtcIceCandidate", peerId, e.candidate);
                }
            });

            // Stream our local media (camera/microphone) to the peer.
            for (const track of stream.getTracks()) {
                console.log(`Adding track of kind ${track.kind} to peer connection ${peerId}.`);
                peerConnection.addTrack(track, stream);
            }

            if (shouldCreateOffer) {
                await this.negotiate(peer);
            }

            // peerConnection.addEventListener("negotiationneeded", e => {
            //     console.log(`A change to the RTC connection for peer ${peerId} requires negotiation.`);

            //     // TODO: Tried to add this to enable adding/removing video/audio tracks after the initial setup,
            //     // but it seems to fire extra times during setup (on the non-offer-creating side) and cause issues.
            //     //createOffer();
            // });

            this._updated.trigger();
        });

        this._rtc.on("setRtcSessionDescription", async (peerId, sessionDescription) => {
            console.log(`Received session description from RTC peer ${peerId}:`);
            // console.log(sessionDescription);

            const peerConnection = (this._peers[peerId] as RtcPeer).connection;
            await peerConnection.setRemoteDescription(new RTCSessionDescription(sessionDescription));

            console.log(`Successfully set remote description for peer ${peerId}.`);

            if (sessionDescription.type === "offer") {
                console.log(`Remote description from peer ${peerId} was an offer, creating an answer...`);

                const answer = await peerConnection.createAnswer({
                    offerToReceiveAudio: true,
                    offerToReceiveVideo: true,
                });
                await peerConnection.setLocalDescription(answer);

                console.log(`Successfully created answer to peer ${peerId}:`);
                // console.log(answer);

                this._rtc.invoke("relayRtcSessionDescription", peerId, answer);
            }
        });

        this._rtc.on("addRtcIceCandidate", (peerId, candidate) => {
            // console.log(`Received ICE candidate from peer ${peerId}:`);
            // console.log(candidate);

            const peerConnection = (this._peers[peerId] as RtcPeer).connection;
            peerConnection.addIceCandidate(candidate);
        });

        this._rtc.on("removeRtcPeer", peerId => {
            console.log(`Received notification that peer ${peerId} has left.`);

            const peer = this._peers[peerId];
            peer.dispose();

            delete this._peers[peerId];
            this._updated.trigger();
        });

        console.log("Joining RTC...");
        await this._rtc.invoke("joinRtc");

        console.log("Successfully joined RTC.");
        this._isJoined = true;
        this._updated.trigger();
    }

    async leave() {
        // TODO: Support leaving when you're still joining.

        if (!this._leavingPromise) {
            this._leavingPromise = this.leaveCore().catch(e => {
                delete this._leavingPromise;
                this._updated.trigger();
                return Promise.reject(e);
            });
            this._updated.trigger();
        }

        return this._leavingPromise;
    }

    private async leaveCore() {
        this._rtc.off("addRtcPeer");
        this._rtc.off("setRtcSessionDescription");
        this._rtc.off("addRtcIceCandidate");
        this._rtc.off("removeRtcPeer");
        localSettingChanged.off(this._localSettingChanged);

        if (this._rtc.state === HubConnectionState.Connected) {
            await this._rtc.invoke("leaveRtc");
        }

        this._self?.dispose();
        delete this._self;

        for (var peerId in this._peers) {
            console.log(`Disposing RTC user ${peerId} on leave.`);
            this._peers[peerId].dispose();
        }

        this._peers = {};
        delete this._leavingPromise;
        delete this._joiningPromise;
        this._isJoined = false;
        this._updated.trigger();
    }
}

import React from 'react'
  
  const SearchIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 9.813 0.7489999999999988 A 9.063 9.063 0 0 1 9.813 18.875 A 9.063 9.063 0 0 1 9.813 0.7489999999999988 M 16.221,16.22l23.25,23.25' />
    </svg>
  )
  
  SearchIcon.displayName = 'SearchIcon'
  
  SearchIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default SearchIcon
import React from 'react'

const SwordIcon = ({
    size = undefined,
    color = undefined,
    ...props
}) => (
    <svg
        {...props}
        viewBox='0 0 512 512'
        width={size}
        height={size}
        stroke={color}
    >
        <path d="M256 25c-13.926 0-31.544 1.105-45.613 5.2-14.07 4.094-23.213 10.325-25.662 20.007-1.661 6.566.726 15.172 7.03 24.953 6.306 9.782 16.126 20.111 26.401 29.264 16.976 15.121 32.104 25.063 37.844 28.752 5.74-3.689 20.868-13.631 37.844-28.752 10.275-9.153 20.095-19.482 26.4-29.264 6.305-9.781 8.692-18.387 7.031-24.953-2.449-9.682-11.592-15.913-25.662-20.008C287.543 26.105 269.926 25 256 25zm-39 102.027v12.758l39 32.5 39-32.5v-12.758c-18.142 14.699-34.277 24.633-34.277 24.633L256 154.572l-4.723-2.912s-16.135-9.934-34.277-24.633zm0 36.188v41.57L241.941 184zm78 0L270.059 184 295 204.785zm-39 32.5l-39 32.5V231h19.273l13.364-13.363L256 211.27 275.729 231H295v-2.785zm175.771 24.783c-15.255 13.207-31.532 20.581-48.66 24.348-19.619 4.314-40.22 4.227-63.14 4.154h-26.244l14 14H384v18h-74.904l-8.4 14H352c31.07 0 60.695 13.027 79.646 28.627L483.273 272zm-351.552.01L28.727 272l51.627 51.627C99.305 308.027 128.93 295 160 295h51.305l-8.4-14H128v-18h76.273l14-14H192c-24 0-44.575.091-63.953-4.215-16.904-3.756-32.736-11.132-47.828-24.277zM256 236.727l-36.668 36.668L256 334.508l36.668-61.113zM201 313v180h46V354.492L222.105 313zm88.895 0L265 354.492V493h46V313z" fill={color}></path>
    </svg>
)

SwordIcon.displayName = 'SwordIcon'

SwordIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'currentcolor'
}

export default SwordIcon 
import React, { FunctionComponent } from "react";
import { LocalPixelPosition } from "../../position";
import { LineAnnotation, Annotation, getAnnotationPos } from "../../annotations";
import { theme } from "../../design";
import { useDispatch, useLocalGrid, useValidatedLocationLevel } from "../contexts";
import { getPlayerColorPalette, getThemeColorPalette, getThemeColor } from "../../design/utils";
import { modifyAnnotation } from "../../actions/location";
import { Token } from "../../store";
import { ThreeEvent } from "@react-three/fiber";
import { EditablePolygon } from "./EditablePolygon";
import { HoverTracking, ZIndexes } from "./common";
import { usePreviousValue } from "../utils";
import { SelectionType } from "../selection";

interface LineAnnotationNodeProps extends HoverTracking {
    line: LineAnnotation;
    activePoint?: LocalPixelPosition;
    isSelected: SelectionType;
    wasSelected: boolean;
    disabled?: boolean;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    onPointerDown?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onPointerUp?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onOverrideAnnotation: (id: string, override: Partial<LineAnnotation> | undefined) => void;
    animateExit?: boolean;
}

const LineAnnotationNodeCore: FunctionComponent<LineAnnotationNodeProps> = ({
    line,
    activePoint,
    isSelected,
    snapPoint,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideAnnotation,
    disabled,
    isHovering,
    setHoverPart,
    wasSelected,
    animateExit,
}) => {
    const dispatch = useDispatch();
    const { campaign, location, levelKey } = useValidatedLocationLevel();
    const grid = useLocalGrid();

    const pos = getAnnotationPos(line, campaign, location, grid);
    let colours = disabled ? getThemeColorPalette("grayscale") : getPlayerColorPalette(campaign, line.userId);

    const isHover = onClick && (isHovering || !!activePoint);
    const unselectedUnhoverLineColor =
        line.subtype === "wall" && !disabled ? getThemeColor(theme.colors.accent[3]) : colours[3];
    const unselectedHoverLineColor =
        line.subtype === "wall" && !disabled ? getThemeColor(theme.colors.accent[4]) : colours[4];
    const unselectedLineColor = isHover ? colours[4] : colours[3];

    const wasFilled = usePreviousValue(!!line.isFilled) ?? line.isFilled;
    const fillInitialColor = !wasFilled
        ? undefined
        : isSelected
        ? colours[isHover ? 1 : 0]
        : wasSelected
        ? colours[1]
        : undefined;
    const lineInitialColor =
        isSelected || activePoint ? unselectedLineColor : wasSelected ? theme.colors.guidance.focus : undefined;

    const showHandles = !!isSelected && !disabled;

    return (
        <React.Fragment>
            <EditablePolygon
                pos={pos}
                points={line.points}
                activePoint={activePoint}
                closed={line.isClosed}
                filled={line.isFilled}
                fillOpacity={disabled ? 0.25 : 0.5}
                fillColor={colours[isHover || isSelected ? 1 : 0]}
                fillInitialColor={fillInitialColor}
                fillHoverColor={colours[1]}
                lineColor={
                    isSelected === SelectionType.Primary ? theme.colors.guidance.focus : unselectedUnhoverLineColor
                }
                lineInitialColor={lineInitialColor}
                lineHoverColor={isSelected ? theme.colors.guidance.focus : unselectedHoverLineColor}
                isHovering={isHovering}
                setHoverPart={setHoverPart}
                animateExit={animateExit}
                showHandles={showHandles}
                rotation={line.rotation}
                zIndex={isSelected || activePoint ? ZIndexes.UserInterface : undefined}
                fillZIndex={isSelected ? undefined : ZIndexes.Tokens - 0.01}
                onClick={
                    onClick && !disabled
                        ? e => {
                              onClick(e, line);
                          }
                        : undefined
                }
                onOverridePosition={pos =>
                    onOverrideAnnotation(line.id, {
                        pos: { ...pos, level: line.pos?.level ?? levelKey },
                    })
                }
                onSetPosition={pos => {
                    dispatch(
                        modifyAnnotation<LineAnnotation>(campaign.id, location.id, line.id, {
                            pos: {
                                ...pos,
                                level: line.pos?.level ?? levelKey,
                            },
                        })
                    );
                    onOverrideAnnotation(line.id, undefined);
                }}
                onOverridePoints={(pos, points) =>
                    onOverrideAnnotation(line.id, {
                        pos: { ...pos, level: line.pos?.level ?? levelKey },
                        points: points,
                    })
                }
                onSetPoints={(pos, points) => {
                    dispatch(
                        modifyAnnotation<LineAnnotation>(campaign.id, location.id, line.id, {
                            pos: {
                                ...pos,
                                level: line.pos?.level ?? levelKey,
                            },
                            points: points,
                        })
                    );
                    onOverrideAnnotation(line.id, undefined);
                }}
                onPointerDown={onPointerDown ? e => onPointerDown(e, line) : undefined}
                onPointerUp={onPointerUp ? e => onPointerUp(e, line) : undefined}
                lineWidth={line.subtype === "wall" ? 4 : 2}
                snapPoint={snapPoint}
            />
        </React.Fragment>
    );
};

export const LineAnnotationNode = React.memo(LineAnnotationNodeCore);

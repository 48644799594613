import { createLightShader, defaultLightTags, LightShaderTemplate } from "./common";
import { PulseLight } from "../../../store";

const pulseShaderText = createLightShader(
    `
    float distToP = distance(localFragCoord, u_position);
    float pulse = ((sin((u_time + u_timeOffset) * ((2.0 * PI) / u_pulseTime)) + 1.0) / 2.0);
    float minBrightness = min(u_minBrightness, u_brightness);
    float brightness = ((u_brightness - minBrightness) * pulse) + minBrightness;
    gl_FragColor = vec4(u_color, (1.0 - smoothstep(u_near / u_far, 1.0, distToP / u_far)) * brightness);
`,
    `
#define PI 3.1415926538
uniform float u_time;
uniform float u_timeOffset;
uniform float u_pulseTime;
uniform float u_minBrightness;
`
);
export const pulseLightShader: LightShaderTemplate<PulseLight> = {
    id: "pulse",
    label: "Pulsing light",
    tags: [...defaultLightTags, "pulse"],
    shader: pulseShaderText,
    defaults: {
        minBrightness: 0.5,
        pulseDuration: 2,
    },
    onInit: material => {
        material.uniforms.u_time = { value: 0 };
        material.uniforms.u_timeOffset = { value: 0 };
        material.uniforms.u_pulseTime = { value: 0 };
        material.uniforms.u_minBrightness = { value: 0 };
    },
    onBeforeRender: ({ context, material, pointLight, light }) => {
        const uniforms = material.uniforms;
        uniforms.u_minBrightness.value = light.minBrightness;
        uniforms.u_pulseTime.value = light.pulseDuration;
        uniforms.u_time.value = context.clock.elapsedTime;

        if (pointLight) {
            const pulse =
                (Math.sin(
                    (uniforms.u_time.value + uniforms.u_timeOffset.value) *
                        ((2.0 * Math.PI) / uniforms.u_pulseTime.value)
                ) +
                    1.0) /
                2.0;
            const minBrightness = Math.min(uniforms.u_minBrightness.value, uniforms.u_brightness.value);
            const brightness = (uniforms.u_brightness.value - minBrightness) * pulse + minBrightness;
            pointLight.intensity = brightness;
        }
    },
};

import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";

// TODO: This is just Scrollable from ./primitives copy/pasted into here.
// For some reason framer-motion works fine with this one, but not when we import it from ./primitives - no idea why.
// As far as I can tell they should be essentially identical.

import { motion } from "framer-motion";
import {
    compose,
    space,
    layout,
    position,
    SpaceProps,
    LayoutProps,
    BorderProps,
    ColorProps,
    PositionProps,
    BackgroundProps,
    gridRow,
    gridColumn,
    gridArea,
    GridRowProps,
    GridColumnProps,
    GridAreaProps,
} from "styled-system";
import { Box, BoxProps, boxSystem, CoverProps, FullHeightProps, FullWidthProps } from "./primitives";

export type ContainerProps = SpaceProps &
    LayoutProps &
    BorderProps &
    ColorProps &
    PositionProps &
    BackgroundProps &
    GridPositionProps &
    CoverProps &
    FullWidthProps &
    FullHeightProps;

export const containerStyles: any = {
    boxSizing: "border-box",
    minWidth: 0,
};

type GridPositionProps = GridRowProps & GridColumnProps & GridAreaProps;
const gridPosition = compose(gridRow, gridColumn, gridArea);

const allLayoutProps = compose(space, layout, position, gridPosition);
const { propNames } = allLayoutProps;
const allProps = ["fullWidth", "fullHeight", "cover", ...(propNames as string[])];

/**
 * A utility function to destructure layout props from a set of props.
 * This is useful for positioning items within an Higher Order Component and simplify the incoming API.
 */
const destructureLayoutProps = (props: { [key: string]: any }): { [key: string]: any }[] => {
    const passthroughProps = {};
    const layoutProps = {};
    Object.keys(props).forEach((key: string) => {
        const prop = props[key];

        if (!allProps.includes(key)) {
            passthroughProps[key] = prop;
            return false;
        }

        layoutProps[key] = prop;
        return true;
    });

    return [layoutProps, passthroughProps];
};

const scrollDirectionProps = ({ scrollDirection = "vertical" }) => {
    const scrollDirections = {
        vertical: {
            overflowY: "scroll",
            flexDirection: "column",
            "> div": {
                position: "absolute",
                flexDirection: "column",
                top: 0,
                left: 0,
                right: 0,
            },
        },
        horizontal: {
            overflowX: "scroll",
            "> div": {
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
            },
        },
    };

    return scrollDirections[scrollDirection];
};

const minimalStyle = ({ minimal = false, ...props }) => {
    if (minimal) {
        const color = themeGet(`colors.${props.scrollbarColor}`)(props) || props.scrollbarColor;
        return {
            "&:hover": {
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: color,
                },
            },
            "&::-webkit-scrollbar": {
                width: props.theme.space[1],
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "inherit",
                borderRadius: props.theme.radii[3],
            },
            "&::-webkit-scrollbar-thumb": {
                transition: "background-color 180ms ease-out",
                backgroundColor: color,
                borderRadius: props.theme.radii[3],

                "&:hover": {
                    backgroundColor: color,
                },
            },
        };
    }

    return null;
};

export interface IScrollableProps {
    minimal?: boolean;
    scrollDirection?: "vertical" | "horizontal";
    scrollbarColor?: string;
    style?: any;
    css?: any;
}
export type ScrollableProps = IScrollableProps & BoxProps;
export const ScrollableContainer = motion(
    styled("div")<ScrollableProps>(
        {
            position: "relative",
            overflow: "hidden",
            alignItems: "flex-start",
            justifycontent: "flex-start",
        },
        containerStyles,
        scrollDirectionProps,
        minimalStyle,
        boxSystem
    )
);

export const ScrollableTest: React.FunctionComponent<PropsWithChildren<ScrollableProps>> = ({
    minimal,
    scrollDirection,
    scrollbarColor,
    children,
    ...props
}) => {
    const { style } = props;
    const [layoutProps, passthroughProps] = destructureLayoutProps(props);

    const { width, height, fullWidth, fullHeight, flex, gridArea, ...rest } = layoutProps;
    const containerProps: any = {
        style,
        width,
        height,
        fullWidth,
        fullHeight,
        flex,
        gridArea,
    };
    return (
        <ScrollableContainer
            minimal={minimal}
            scrollDirection={scrollDirection}
            scrollbarColor={scrollbarColor}
            layoutScroll
            {...containerProps}>
            <Box flex={1} alignItems="flex-start" justifyContent="flex-start" {...passthroughProps} {...rest}>
                {children}
            </Box>
        </ScrollableContainer>
    );
};

ScrollableTest.displayName = "ScrollableTest";

ScrollableTest.defaultProps = {
    minimal: false,
    scrollDirection: "vertical",
    scrollbarColor: "hsla(0, 0%, 0%, 0.4)",
    flex: 1,
    width: "inherit",
    height: "inherit",
};

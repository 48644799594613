import React, { FunctionComponent, useCallback } from "react";
import { LocalPixelPosition } from "../../position";
import { EllipseAnnotation, Annotation, ConeAnnotation, getConePoints, getAnnotationPos } from "../../annotations";
import { useDispatch, useLocalGrid, useValidatedLocationLevel } from "../contexts";
import { getPlayerColorPalette, getThemeColorPalette } from "../../design/utils";
import { theme } from "../../design";
import { modifyAnnotation } from "../../actions/location";
import { HoverTracking, ZIndexes } from "./common";
import { DraggableLine, Line } from "./three2d/Line";
import { Token } from "../../store";
import { ThreeEvent } from "@react-three/fiber";
import { interactiveLineWidth } from "../common";
import { SelectionType } from "../selection";

interface ConeAnnotationNodeProps extends HoverTracking {
    cone: ConeAnnotation;
    activePoint?: LocalPixelPosition;
    isSelected: SelectionType;
    wasSelected: boolean;
    disabled?: boolean;
    onClick?(evt: ThreeEvent<MouseEvent>, o: Annotation | Token): void;
    snapPoint: (point: LocalPixelPosition) => LocalPixelPosition;
    onPointerDown?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onPointerUp?: (evt: ThreeEvent<PointerEvent>, o: Annotation | Token) => void;
    onOverrideAnnotation: (id: string, override: Partial<ConeAnnotation> | undefined) => void;
    animateExit?: boolean;
}

const ConeAnnotationNodeCore: FunctionComponent<ConeAnnotationNodeProps> = ({
    cone,
    activePoint,
    snapPoint,
    isSelected,
    onClick,
    onPointerDown,
    onPointerUp,
    onOverrideAnnotation,
    disabled,
    isHovering,
    setHoverPart,
    wasSelected,
    animateExit,
}) => {
    const dispatch = useDispatch();
    const { campaign, location, levelKey } = useValidatedLocationLevel();
    const grid = useLocalGrid();

    let colours = disabled ? getThemeColorPalette("grayscale") : getPlayerColorPalette(campaign, cone.userId);

    const onDragMove = useCallback(
        (e: ThreeEvent<PointerEvent>, p: LocalPixelPosition) => {
            onOverrideAnnotation(cone.id, {
                pos: { ...p, level: cone.pos?.level ?? levelKey },
            });
        },
        [cone.id, cone.pos?.level, levelKey, onOverrideAnnotation]
    );
    const onDragEnd = useCallback(() => {
        dispatch(
            modifyAnnotation<EllipseAnnotation>(campaign.id, location.id, cone.id, {
                pos: cone.pos,
            })
        );
        onOverrideAnnotation(cone.id, undefined);
    }, [dispatch, location, cone, campaign, onOverrideAnnotation]);

    const pos = getAnnotationPos(cone, campaign, location, grid);
    const points = getConePoints(cone, pos, activePoint);

    const onAnnotationClick = useCallback(
        (e: ThreeEvent<MouseEvent>) => {
            if (onClick) {
                onClick(e, cone);
            }
        },
        [cone, onClick]
    );

    const isHover = onClick && (isHovering || !!activePoint);

    const disableDrag = onClick == null || disabled || !!cone.centerOn;
    const unselectedLineColor = colours[isHover ? 4 : 3];
    return (
        <>
            <DraggableLine
                x={pos.x}
                y={pos.y}
                zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                opacity={0}
                width={2 + interactiveLineWidth}
                points={points}
                cursor={disableDrag ? undefined : "pointer"}
                onPointerOver={() => setHoverPart?.("line", true)}
                onPointerOut={() => setHoverPart?.("line", false)}
                disableDrag={disableDrag}
                onDragMove={onDragMove}
                onDragEnd={onDragEnd}
                onClick={disabled ? undefined : onAnnotationClick}
                onPointerDown={onPointerDown ? e => onPointerDown(e, cone) : undefined}
                onPointerUp={onPointerUp ? e => onPointerUp(e, cone) : undefined}
            />
            <Line
                x={pos.x}
                y={pos.y}
                animateEnterExit="nolayout"
                initial={
                    isSelected
                        ? { color: unselectedLineColor, opacity: 1, scale: 1 }
                        : wasSelected
                        ? { color: theme.colors.guidance.focus, opacity: 1, scale: 1 }
                        : undefined
                }
                exit={animateExit ? undefined : null}
                zIndex={isSelected || activePoint ? ZIndexes.UserInterface : ZIndexes.Annotations}
                color={isSelected === SelectionType.Primary ? theme.colors.guidance.focus : unselectedLineColor}
                width={2}
                points={points}
            />
        </>
    );
};

export const ConeAnnotationNode = React.memo(ConeAnnotationNodeCore);

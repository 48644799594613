import React from 'react'
  
  const MouseIcon = ({
    size = undefined,
    color = undefined,
    ...props
  }) => (
    <svg
      {...props}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      stroke={color}
    >
      <path d='M 6.75,23.25L6.75,23.25c-3.314,0-6-2.686-6-6v-6c0-3.314,2.686-6,6-6l0,0c3.314,0,6,2.686,6,6v6 C12.75,20.564,10.064,23.25,6.75,23.25z  M 0.75,12.75h12  M 6.75,12.75v-7.5l0,0c0-2.485,2.015-4.5,4.5-4.5l0,0 c2.485,0,4.5,2.015,4.5,4.5V7.5c0,2.071,1.679,3.75,3.75,3.75l0,0c2.071,0,3.75-1.679,3.75-3.75V0.75' />
    </svg>
  )
  
  MouseIcon.displayName = 'MouseIcon'
  
  MouseIcon.defaultProps = {
    size: 24,
    color: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none'
  }
  
  export default MouseIcon
import React, { FunctionComponent } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { space, borderRadius, fontSize, SpaceProps, BorderRadiusProps, FontSizeProps } from "styled-system";
import { Vector } from "../../primitives";

import SocialNetworkType from "./SocialNetworkType";

interface ISocialButtonProps {
    network: string;
}

type SocialButtonProps = ISocialButtonProps & SpaceProps & BorderRadiusProps & FontSizeProps;

const networkType = (props: SocialButtonProps): any => {
    const networkTypes: any = {
        Microsoft: css`
            background-color: hsl(240, 7%, 97%);
            color: hsl(199, 100%, 47%);
            fill: hsl(199, 100%, 40%);

            &:visited {
                color: hsl(199, 100%, 47%);
            }

            &:hover {
                background-color: hsl(199, 100%, 95%);
            }
        `,
        Google: css`
            background-color: hsl(5, 81%, 56%);
            color: white;
            fill: white;

            &:visited {
                color: white;
            }

            &:hover {
                background-color: hsl(5, 81%, 45%);
                color: white;
            }
        `,
    };

    return props.network && networkTypes[props.network];
};

const SocialLink = styled.a`
    text-decoration: none;
    transition: background-color 90ms ease-out;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 0%;
    white-space: pre;

    ${borderRadius}
    ${space}
  ${fontSize}

  svg {
        fill: inherit;
    }

    &:hover {
        text-decoration: none;
    }

    ${networkType}
`;

const MicrosoftIcon = (props: any) => (
    <Vector width={32} height={32} viewBox="0 0 32 32" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 16C28 27 27 28 16 28C5 28 4 27 4 16C4 5 5 4 16 4C27 4 28 5 28 16ZM10 10.5C10 10.2239 10.2239 10 10.5 10H14.5C14.7761 10 15 10.2239 15 10.5V14.5C15 14.7761 14.7761 15 14.5 15H10.5C10.2239 15 10 14.7761 10 14.5V10.5ZM17.5 10C17.2239 10 17 10.2239 17 10.5V14.5C17 14.7761 17.2239 15 17.5 15H21.5C21.7761 15 22 14.7761 22 14.5V10.5C22 10.2239 21.7761 10 21.5 10H17.5ZM17 17.5C17 17.2239 17.2239 17 17.5 17H21.5C21.7761 17 22 17.2239 22 17.5V21.5C22 21.7761 21.7761 22 21.5 22H17.5C17.2239 22 17 21.7761 17 21.5V17.5ZM10.5 17C10.2239 17 10 17.2239 10 17.5V21.5C10 21.7761 10.2239 22 10.5 22H14.5C14.7761 22 15 21.7761 15 21.5V17.5C15 17.2239 14.7761 17 14.5 17H10.5Z"
        />
    </Vector>
);

const GoogleIcon = (props: any) => (
    <Vector width={32} height={32} viewBox="0 0 32 32" {...props}>
        <g>
            <path d="M16,28c11,0 12,-1 12,-12c0,-11 -1,-12 -12,-12c-11,0 -12,1 -12,12c0,11 1,12 12,12Zm0.142,-10.558l0,-2.675l6.731,0.01c0.588,2.728 -0.735,8.223 -6.731,8.223c-3.944,0 -7.142,-3.134 -7.142,-7c0,-3.866 3.198,-7 7.142,-7c1.852,0 3.54,0.691 4.81,1.825l-2.01,1.97c-0.754,-0.633 -1.73,-1.017 -2.8,-1.017c-2.379,0 -4.308,1.89 -4.308,4.222c0,2.332 1.929,4.222 4.308,4.222c1.998,0 3.38,-1.159 3.888,-2.78l-3.888,0Z"></path>
        </g>
    </Vector>
);

const SocialButton: FunctionComponent<SocialButtonProps> = ({ network, ...props }) => (
    <SocialLink network={network} href={`auth/signin/${network}`} {...props}>
        {network === SocialNetworkType.Microsoft && <MicrosoftIcon mr={2} />}
        {network === SocialNetworkType.Google && <GoogleIcon mr={2} />}
        {`Sign in with ${network}`}
    </SocialLink>
);

SocialButton.defaultProps = {
    borderRadius: 4,
    pl: 2,
    pr: 3,
    py: 2,
    mx: 2,
    fontSize: 2,
};

export default SocialButton;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import RcSlider, { SliderProps } from "rc-slider/lib/Slider";
import { FunctionComponent } from "react";
import { theme } from "../design";
import { Box } from "./primitives";
import { asField, IAsFieldProps } from "./Form";

export const Slider: FunctionComponent<SliderProps> = props => {
    return (
        <RcSlider
            style={{
                flexGrow: 1,
                backgroundColor: "unset",
            }}
            railStyle={{
                backgroundColor: props.disabled ? theme.colors.grayscale[8] : theme.colors.grayscale[7],
            }}
            trackStyle={{
                backgroundColor: props.disabled ? theme.colors.grayscale[6] : theme.colors.accent[5],
                transition: "background-color ease-out 200ms",
            }}
            handleStyle={{
                border: "none",
                backgroundColor: props.disabled ? theme.colors.grayscale[5] : theme.colors.foreground,
                boxShadow: "unset",
                transition: "background-color ease-out 200ms",
            }}
            dotStyle={{
                border: "none",
                backgroundColor: props.disabled ? theme.colors.grayscale[6] : theme.colors.grayscale[3],
            }}
            {...props}
        />
    );
};

export const SliderField = asField<HTMLDivElement, SliderProps & IAsFieldProps>(Slider);

export const PercentageSlider: FunctionComponent<SliderProps> = props => {
    return (
        <Box px={3} pb={4} fullWidth>
            <Slider
                min={0}
                max={1}
                step={0.05}
                marks={{
                    0: "0%",
                    "0.25": "25%",
                    "0.5": "50%",
                    "0.75": "75%",
                    1: "100%",
                }}
                {...props}
            />
        </Box>
    );
};

export const PercentageSliderField = asField<HTMLDivElement, SliderProps & IAsFieldProps>(PercentageSlider);

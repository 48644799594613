import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    MutableRefObject,
    useMemo,
    PropsWithChildren,
    FunctionComponent,
    useContext,
} from "react";
import { useNotifications, useUser } from "./contexts";
import {
    Event,
    Deferred,
    localSettingChanged,
    LocalSetting,
    LocalSettingChangedEvent,
    isPromise,
    wrapForSuspense,
} from "../common";
import { Profile, ErrorHandler } from "../store";
import { LineBasicMaterial, LineSegments, Mesh, WireframeGeometry } from "three";

import { Message } from "./Message";
import { getProfile, loadProfiles, loadProfilesSync, setProfile } from "../userprofiles";
import { Box } from "./primitives";
import { dragStatus, resolveUri } from "./common";

function getErrorMessage(e: any) {
    if (e instanceof Error) {
        return e.message;
    }

    return e;
}

export function getFileName(url: string, includeExtension?: boolean) {
    var matches = url && typeof url.match === "function" && url.match(/\/?([^/.]*)\.?([^/]*)$/);
    if (!matches) {
        return undefined;
    }

    if (includeExtension && matches.length > 2 && matches[2]) {
        return matches.slice(1).join(".");
    }

    return matches[1];
}

export function handleResponse(
    response: Response,
    message?: string | ((response: Response) => string),
    errorHandler?: ErrorHandler
) {
    if (errorHandler) {
        // We've been given an error handler. Use that and return the result.
        return errorHandler.handleResponse(response, message);
    }

    const errorMessage = getErrorForResponse(response, message);
    if (errorMessage != null) {
        throw new Error(errorMessage);
    }

    return true;
}

function getErrorForResponse(response: Response, message?: string | ((response: Response) => string)) {
    if (!response.ok) {
        const errorMessage = message
            ? typeof message === "string"
                ? message
                : message(response)
            : response.statusText;
        return errorMessage;
    }

    return undefined;
}

export function useErrorHandler(): ErrorHandler {
    const addNotification = useNotifications();

    const addError = useCallback(
        (message: string) => {
            addNotification({
                content: (
                    <Message variant="error" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                        {message}
                    </Message>
                ),
                canDismiss: true,
                showLife: false,
                timeout: 999999999, // TODO: Check how to add notifications that don't expire.
            });
        },
        [addNotification]
    );
    const handleResponse = useCallback(
        (response, message) => {
            const errorMessage = getErrorForResponse(response, message);
            if (errorMessage != null) {
                console.error(
                    `${errorMessage.replace("\n", " ")} [${response.url}] ${response.status} (${response.statusText})`
                );
                addError(errorMessage);
            }

            return response.ok;
        },
        [addError]
    );
    const handleError = useCallback(
        (e, message) => {
            const errorMessage = message ? (typeof message === "string" ? message : message(e)) : getErrorMessage(e);
            if (e instanceof Error) {
                console.error(e);
            } else {
                console.error(errorMessage);
            }

            addError(errorMessage);
        },
        [addError]
    );

    const data = useMemo(
        () => ({
            handleResponse: handleResponse,
            handleError: handleError,
        }),
        [handleResponse, handleError]
    );

    return data;
}

export function useEvent<T>(evt: Event<T> | undefined, initialValue: T): T;
export function useEvent<T, U>(
    evt: Event<U> | undefined,
    initialValue: T,
    mapValue: (args: U) => T,
    filterValue?: (args: U) => boolean
): T;
export function useEvent<T, U>(
    evt: Event<U> | undefined,
    initialValue: T,
    mapValue?: (args: U) => T,
    filterValue?: (args: U) => boolean
): T {
    const [value, setValue] = useState<T>(initialValue);
    useEffect(() => {
        if (evt) {
            const handler = (args: U) => {
                if (!filterValue || filterValue(args)) {
                    setValue(mapValue ? mapValue(args) : (args as unknown as T));
                }
            };

            evt.on(handler);
            return () => {
                evt.off(handler);
            };
        }
    }, [evt, mapValue, filterValue]);
    return value;
}

export function useLocalSetting<T>(setting: LocalSetting<T>): [T | undefined, (value: T | undefined) => void];
export function useLocalSetting<T>(setting: LocalSetting<T>, defaultValue: T): [T, (value: T) => void];
export function useLocalSetting<T>(
    setting: LocalSetting<T>,
    defaultValue?: T
): [T | undefined, (value: T | undefined) => void] {
    const initialValue = setting.getValue() ?? defaultValue;
    const value = useEvent<T | undefined, LocalSettingChangedEvent>(
        localSettingChanged,
        initialValue,
        o => o.value as T | undefined,
        o => o.setting === setting
    );
    const setValue = useCallback(v => setting.setValue(v), [setting]);
    return [value ?? defaultValue, setValue];
}

/**
 * Gets the current permission state for the permission with the specified name.
 * Rerenders when the state of the permission changes (i.e. when the user grants/revokes permissions).
 * @param name The permission to use.
 */
export function usePermission(name: PermissionName): PermissionState | "unsupported" | undefined {
    const [permission, setPermission] = useState(undefined as PermissionState | "unsupported" | undefined);
    const permissionTemp = useMemo<{
        isLive: boolean;
        status?: PermissionStatus;
        handler?: () => void;
    }>(() => ({ isLive: true }), []);
    useEffect(() => {
        (async () => {
            try {
                const micPermissions = await navigator.permissions.query({
                    name: name,
                });

                if (!permissionTemp.isLive) {
                    return;
                }

                const onPermissionChanged = () => {
                    setPermission(micPermissions.state);
                };
                permissionTemp.status = micPermissions;
                permissionTemp.handler = onPermissionChanged;
                micPermissions.addEventListener("change", onPermissionChanged);

                onPermissionChanged();
            } catch (e) {
                setPermission("unsupported");
            }
        })();

        return () => {
            if (permissionTemp.handler) {
                permissionTemp?.status?.removeEventListener("change", permissionTemp.handler);
            }

            permissionTemp.isLive = false;
        };
    }, [name, permissionTemp]);

    return permission;
}

const defaultUseVideoState: {
    video: HTMLVideoElement | undefined;
    status: "loading" | "loaded" | "failed";
} = { video: undefined, status: "loading" };

export function useVideo(url): [HTMLVideoElement | undefined, "loading" | "loaded" | "failed"] {
    const [state, setState] = useState(defaultUseVideoState);
    const video = state.video;
    const status = state.status;

    useEffect(() => {
        if (!url) {
            return;
        }

        const vid = document.createElement("video");

        const onLoad = () => {
            setState({ video: vid, status: "loaded" });
        };

        const onError = () => {
            setState({ video: vid, status: "failed" });
        };

        vid.addEventListener("canplay", onLoad);
        vid.addEventListener("error", onError);
        vid.src = url;

        return () => {
            vid.removeEventListener("canplay", onLoad);
            vid.removeEventListener("error", onError);
        };
    }, [url]);
    return [video, status];
}

// Keep track of all images in progress so that we can provide a summary.
type DownloadProgress<T = Blob> = [T | undefined, number | undefined, number | undefined, Error | undefined];

interface GlobalDownloadProgress {
    total: number;
    completed: number;
    failed: number;
    received: number;
    size: number;
}

let downloadsInProgress: { [url: string]: DownloadProgress } | undefined = undefined;
let downloadsPromise: Deferred<number> | undefined;

const downloadProgressChanged = new Event<GlobalDownloadProgress>();
const downloadPromiseChanged = new Event<Promise<number> | undefined>();

/**
 * Hook to receive the promise for the current set of downloads.
 */
export function useDownloadPromise(): Promise<number> | undefined {
    return useEvent(downloadPromiseChanged, downloadsPromise ? downloadsPromise.promise : undefined);
}

/**
 * Hook to receive global download progress updates.
 */
export function useDownloadProgress(): GlobalDownloadProgress {
    return useEvent(downloadProgressChanged, getDownloadSummary());
}

function updateDownloadProgress(url: string, progress: DownloadProgress) {
    if (!downloadsInProgress) {
        downloadsInProgress = {};
        downloadsPromise = new Deferred<number>();
        downloadPromiseChanged.trigger(downloadsPromise.promise);
    }

    downloadsInProgress[url] = progress;

    const summary = getDownloadSummary();
    downloadProgressChanged.trigger(summary);

    if (summary.completed + summary.failed === summary.total) {
        if (downloadsInProgress) {
            downloadsInProgress = undefined;
            (downloadsPromise as Deferred<number>).resolve(summary.total);
            downloadsPromise = undefined;
            downloadPromiseChanged.trigger(undefined);
        }
    }
}

/**
 * Gets a promise that will be completed when all the current downloads complete, or undefined
 * if there are no downloads in progress.
 */
export function getDownloadPromise(): Promise<number> | undefined {
    return downloadsPromise ? downloadsPromise.promise : undefined;
}

/**
 * Gets a summary of all the downloads currently in progress.
 */
export function getDownloadSummary(): GlobalDownloadProgress {
    let completedDownloads = 0;
    let totalDownloads = 0;
    let failedDownloads = 0;
    let totalReceived = 0;
    let totalSize = 0;

    if (downloadsInProgress) {
        let keys = Object.keys(downloadsInProgress);
        for (let i = 0; i < keys.length; i++) {
            const [image, received, size, error] = downloadsInProgress[keys[i]];
            if (received != null) {
                if (error && size != null) {
                    // Count failed downloads as fully received, so that progress bars
                    // look accurate in terms of how much we're still expecting to receive.
                    totalReceived += size;
                } else {
                    totalReceived += received;
                }
            }

            if (size != null) {
                totalSize += size;
            }

            totalDownloads++;
            if (image) {
                completedDownloads++;
            }

            if (error) {
                failedDownloads++;
            }
        }
    }

    return {
        total: totalDownloads,
        completed: completedDownloads,
        failed: failedDownloads,
        received: totalReceived,
        size: totalSize,
    };
}

/**
 * Downloads a file and makes the progress available through the useDownloadProgress and useDownloadPromise hooks, and the
 * getDownloadSummary function.
 * @param url The url of the file to download.
 * @param onProgress The callback that receives the progress reports.
 * @param onError Called to handle any errors during the download process.
 */
export async function downloadFile(url: string, onProgress?: (data: DownloadProgress) => void): Promise<Blob> {
    try {
        updateDownloadProgress(url, emptyProgress);
        const response = await fetch(url);
        if (response && handleResponse(response) && response.body) {
            const reader = response.body.getReader();
            const mimeType = response.headers.get("Content-Type");
            const contentLengthRaw = response.headers.get("Content-Length");
            const contentLength = contentLengthRaw != null ? parseInt(contentLengthRaw, 10) : Number.NaN;

            let receivedLength = 0;
            let chunks: Uint8Array[] = [];
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }

                if (value != null) {
                    chunks.push(value);
                    receivedLength += value.length;
                    const data: DownloadProgress = [undefined, receivedLength, contentLength, undefined];
                    onProgress?.(data);
                    updateDownloadProgress(url, data);
                }
            }

            try {
                const data: DownloadProgress = [
                    new Blob(chunks, { type: mimeType ? mimeType : undefined }),
                    receivedLength,
                    contentLength,
                    undefined,
                ];
                onProgress?.(data);
                updateDownloadProgress(url, data);
                return data[0]!;
            } catch (e: any) {
                const data: DownloadProgress = [undefined, receivedLength, contentLength, e];
                onProgress?.(data);
                updateDownloadProgress(url, data);
                throw e;
            }
        } else {
            const e = new Error("The request did not return a valid response.");
            const data: DownloadProgress = [undefined, undefined, undefined, e];
            onProgress?.(data);
            updateDownloadProgress(url, data);
            throw e;
        }
    } catch (e: any) {
        const data: DownloadProgress = [undefined, undefined, undefined, e];
        onProgress?.(data);
        updateDownloadProgress(url, data);
        throw e;
    }
}

const emptyProgress: DownloadProgress = [undefined, undefined, undefined, undefined];

// TODO: Provide label of image and use in error/info messages.
export function useFileWithProgress(url: string | undefined, errorMessage: string | ((e: any) => string)) {
    url = resolveUri(url);
    const { handleResponse, handleError } = useErrorHandler();
    const [data, setData] = useState<DownloadProgress>(emptyProgress);
    const [dataUrl, setDataUrl] = useState(url);
    const errorMessageRef = useRef<string | ((e: any) => string)>(errorMessage);
    errorMessageRef.current = errorMessage;
    useEffect(() => {
        if (url) {
            setData(emptyProgress);
            setDataUrl(url);
            const getFile = async (url: string) => {
                try {
                    await downloadFile(url, setData);
                } catch (e) {
                    handleError(e, err => {
                        if (typeof errorMessageRef.current === "string") {
                            return errorMessageRef.current;
                        }

                        return errorMessageRef.current(err);
                    });
                }
            };
            getFile(url);
        }
    }, [url, handleResponse, handleError]);

    return url !== dataUrl ? emptyProgress : data;
}

export function useFileUrlWithProgress(
    url: string | undefined,
    errorMessage: string | ((e: any) => string)
): DownloadProgress<string> {
    const progress = useFileWithProgress(url, errorMessage);
    const blob = progress[0];

    const urlRef = useRef<string>();
    if (blob && !urlRef.current) {
        urlRef.current = URL.createObjectURL(blob);
    } else if (!blob && urlRef.current) {
        URL.revokeObjectURL(urlRef.current);
        urlRef.current = undefined;
    }

    useEffect(() => {
        return () => {
            if (urlRef.current) {
                URL.revokeObjectURL(urlRef.current);
            }
        };
    }, []);

    const finalProgress: DownloadProgress<string> = useMemo(
        () => [urlRef.current, progress[1], progress[2], progress[3]],
        [progress]
    );
    return finalProgress;
}

function updateProfiles(userProfiles: { [id: string]: Profile }, userIds: string[], profiles: Profile[] | undefined) {
    if (profiles) {
        for (let i = 0; i < userIds.length; i++) {
            if (profiles[i] && userProfiles[userIds[i]] !== profiles[i]) {
                userProfiles = { ...userProfiles, [userIds[i]]: profiles[i] };
            }
        }
    }

    return userProfiles;
}

export function useProfiles(userIds: string[]) {
    const [isLoadingProfiles, setIsLoadingProfiles] = useState(false);
    const [userProfiles, setUserProfiles] = useState(() => updateProfiles({}, userIds, loadProfilesSync(userIds)));
    const errorHandler = useErrorHandler();
    const user = useUser();
    const isMounted = useIsMounted();
    const requestRef = useRef<Promise<void>>();

    useEffect(() => {
        const current = getProfile(user.id);
        if ((!current || isPromise(current)) && user.profile) {
            setProfile(user.id, user.profile);
        }

        const profiles = loadProfilesSync(userIds);
        setUserProfiles(updateProfiles(userProfiles, userIds, profiles));
        if (!profiles) {
            setIsLoadingProfiles(true);
            const request = loadProfiles(userIds.filter(o => !!o) as string[], errorHandler).then(o => {
                if (isMounted() && requestRef.current === request) {
                    setIsLoadingProfiles(false);
                    setUserProfiles(updateProfiles(userProfiles, userIds, loadProfilesSync(userIds)));
                }
            });
            requestRef.current = request;
        }
    }, [user.id, user.profile, userProfiles, userIds, isMounted, errorHandler]);

    return { profiles: userProfiles, isLoadingProfiles: isLoadingProfiles };
}

export function useProfilesForSuspense(userIds: string[]) {
    // TODO: Move this so that react specific stuff goes in components/common or components/utils or something.
    const errorHandler = useErrorHandler();

    let profiles: Profile[] = [];
    for (let i = 0; i < userIds.length; i++) {
        const maybeProfile = getProfile(userIds[i]);
        if (maybeProfile && !isPromise(maybeProfile)) {
            profiles.push(maybeProfile);
        }
    }

    if (profiles.length === userIds.length) {
        return profiles;
    }

    return wrapForSuspense(loadProfiles(userIds, errorHandler)).read();
}

export function useForwardedRef<T>(ref: React.Ref<T>) {
    const innerRef = useRef<T>(null);
    useEffect(() => {
        if (!ref) {
            return;
        }

        if (typeof ref === "function") {
            ref(innerRef.current);
        } else {
            (ref as MutableRefObject<T | null>).current = innerRef.current;
        }
    });

    return innerRef;
}

let canvasDragData: any;
let canvasDrop: ((data: any) => void) | undefined;

export function startCanvasDrag(data: any) {
    canvasDragData = data;
}

export function endCanvasDrag(isCancelled?: boolean) {
    if (canvasDrop && !isCancelled) {
        canvasDrop(canvasDragData);
        canvasDrop = undefined;
    }

    canvasDragData = undefined;
}

export function getCanvasDragData() {
    return canvasDragData;
}

export function hasDropOffer() {
    return canvasDrop != null;
}

export function offerAcceptDrop(accept: ((data: any) => void) | undefined) {
    if (canvasDragData) {
        canvasDrop = accept;
    } else if (accept) {
        console.warn("A drop callback was passed to canvasDragOver when no drop is in progress.");
    }
}

export function getMatchingDataTransferItems(dt: DataTransfer, types: string[], extensions?: string[]) {
    const matchingItems: DataTransferItem[] = [];
    for (let i = 0; i < dt.items.length; i++) {
        if (dt.items[i].type === "" && extensions) {
            // If the file has no association, we have to assume that it will be ok otherwise most
            // people won't be able to drop things like stl files.
            const file = dt.items[i].getAsFile();
            if (file != null) {
                const extensionIndex = file.name.lastIndexOf(".");
                if (extensionIndex >= 0) {
                    const extension = file.name.slice(extensionIndex);
                    if (extensions.indexOf(extension.toLowerCase()) >= 0) {
                        matchingItems.push(dt.items[i]);
                    }
                }
            } else {
                matchingItems.push(dt.items[i]);
            }
        } else {
            for (let j = 0; j < types.length; j++) {
                if (dt.items[i].type.includes(types[j])) {
                    matchingItems.push(dt.items[i]);
                }
            }
        }
    }

    return matchingItems;
}

/**
 * Get events that can be used to set up an element for accepting a drop from generic HTML5 DnD.
 * Spread the resulting object so that you extract isDragOver, then put the rest into a props object which
 * should then be spread onto the element that you want to accept the drop.
 * @param type The mime type (i.e. "image/png") of the data you want to accept.
 * @param handleDrop The function to call when data of the specified type has been dropped.
 */
export function useDropEvents(
    types: string | string[] | ((dt: DataTransfer) => DataTransferItem[]),
    handleDrop: (data: DataTransferItem[], e: React.DragEvent) => void,
    handleDragEnter?: (data: DataTransferItem[], e: React.DragEvent) => void,
    handleDragLeave?: (data: DataTransferItem[], e: React.DragEvent) => void
) {
    const dragEnterCount = useRef<number>(0);
    const [dragOverType, setDragOverType] = useState<string[]>();

    const typeslc =
        typeof types === "string"
            ? [types.toLowerCase()]
            : Array.isArray(types)
            ? types.map(o => o.toLowerCase())
            : undefined;
    const userMatch = typeof types === "function" ? types : undefined;

    var gmi = userMatch ? userMatch : (dt: DataTransfer) => getMatchingDataTransferItems(dt, typeslc ?? []);
    const onDragOver = (e: React.DragEvent) => {
        if (gmi(e.dataTransfer).length) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const onDragEnter = (e: React.DragEvent) => {
        const matchingItems = gmi(e.dataTransfer);
        if (matchingItems.length) {
            dragEnterCount.current++;

            if (dragEnterCount.current === 1) {
                setDragOverType(matchingItems.map(o => o.type));
                handleDragEnter?.(matchingItems, e);
            }
        }
    };
    const onDragLeave = (e: React.DragEvent) => {
        if (dragEnterCount.current > 0) {
            dragEnterCount.current--;

            if (dragEnterCount.current === 0) {
                setDragOverType(undefined);
                handleDragLeave?.(gmi(e.dataTransfer), e);
            }
        }
    };
    const onDrop = (e: React.DragEvent) => {
        dragEnterCount.current = 0;
        setDragOverType(undefined);

        var matchingItems = gmi(e.dataTransfer);
        if (matchingItems.length) {
            e.preventDefault();
            e.stopPropagation();

            handleDrop(matchingItems, e);
        }
    };

    return {
        isDragOver: dragOverType,
        onDragOver,
        onDragEnter,
        onDragLeave,
        onDrop,
    };
}

export function useConstant<T>(fn: () => T): T {
    const ref = React.useRef<{ value: T }>();

    if (!ref.current) {
        ref.current = { value: fn() };
    }

    return ref.current.value;
}

export function useDebounce(fn: (...args: any[]) => void, ms: number, gatherArgs?: (...args: any[]) => any[]) {
    const ref = React.useRef<number | undefined>();

    return (...args: any[]) => {
        if (ref.current != null) {
            clearTimeout(ref.current);
        }

        if (gatherArgs) {
            args = gatherArgs(...args);
        }

        ref.current = setTimeout(() => fn(...args), ms) as any; // TODO: Node types messing with stuff here.
    };
}

export function useIsMounted() {
    const ref = useRef<boolean>(false);
    useEffect(() => {
        ref.current = true;
        return () => {
            ref.current = false;
        };
    });

    return useCallback(() => ref.current, []);
}

export function useForceUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick(tick => tick + 1);
    }, []);
    return update;
}

const emptyArray = [];

/**
 * Get the values from a dictionary, returning the same array reference so long as the dictionary does not change.
 * Note that if a filter is specified, you will need to use useCallback to prevent a new array being returned every time.
 * @param dictionary The dictionary to use the values from.
 * @param filter A filter to apply to the values before returning. Note that the filter should be memoised using useCallback.
 * @returns The values of the dictionary.
 */
export function useDictionaryValues<T>(dictionary: { [id: string]: T }, filter?: (o: T) => boolean): T[] {
    const dictionaryRef = useRef<{ [id: string]: T }>();
    const valuesRef = useRef<T[]>();
    const filterRef = useRef<(o: T) => boolean>();

    if (dictionaryRef.current === dictionary && filterRef.current === filter) {
        // If the dictionary object (and the filter) hasn't changed, then the values must be the same.
        return valuesRef.current ?? emptyArray;
    }

    // The dictionary has changed, so build the new values array.
    let values = Object.values(dictionary);
    if (filter) {
        values = values.filter(filter);
    }

    valuesRef.current = values;
    dictionaryRef.current = dictionary;
    filterRef.current = filter;
    return values;
}

export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log("Changed props:", changedProps);
        }
        prev.current = props;
    });
}

export function addDebugWireframe(mesh: Mesh) {
    let wireframeMesh: LineSegments;
    if (mesh.children.length) {
        wireframeMesh = mesh.children[0] as LineSegments;
        wireframeMesh.geometry = new WireframeGeometry(mesh.geometry);
    } else {
        const geo = new WireframeGeometry(mesh.geometry);
        wireframeMesh = new LineSegments(geo, new LineBasicMaterial({ color: 0xffffff, linewidth: 2 }));
        mesh.add(wireframeMesh);
    }

    return wireframeMesh;
}

export function useHaveDepsChanged(deps: any[]) {
    const prevDeps = useRef<any[]>();

    const hasChanged = haveDepsChanged(prevDeps.current, deps);
    prevDeps.current = deps;
    return hasChanged;
}

export function haveDepsChanged(prev: any[] | undefined, current: any[]) {
    if (!prev) {
        return true;
    }

    if (prev.length !== current.length) {
        return true;
    }

    for (let i = 0; i < current.length; i++) {
        if (prev[i] !== current[i]) {
            return true;
        }
    }

    return false;
}

export interface KeyboardShortcutOptions {
    isDisabled?: boolean;
    priority?: number;

    /**
     * If true, this keyboard shortcut is related to drag & drop events, and keyboard events that
     * are normally filtered out because they're related to a drag in progress should not be.
     */
    isDragDrop?: boolean;
}

interface KeyboardShortcutState {
    shortcut: string[];
    callback: (ev: React.KeyboardEvent | KeyboardEvent) => void;
    options?: KeyboardShortcutOptions;

    current: string[];
}

export function filterKeyEvent(ev: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) {
    // Ignore keystrokes inside inputs, they're exempt from triggering keyboard shortcuts.
    if (
        ev.target &&
        ((ev.target as HTMLElement).tagName === "INPUT" ||
            (ev.target as HTMLElement).tagName === "TEXTAREA" ||
            (ev.target as HTMLElement).classList.contains("ProseMirror") ||
            (ev.target as HTMLDivElement).isContentEditable)
    ) {
        // Allow the escape key though, that one is useful even inside inputs.
        return ev.key === "Escape";
    }

    return true;
}

let shortcutId = 0;

const KeyboardShortcutContext = React.createContext<{ [id: string]: KeyboardShortcutState }>(
    undefined as any as { [id: string]: KeyboardShortcutState }
);

export const KeyboardShortcuts: FunctionComponent<PropsWithChildren<{ useWindow?: boolean }>> = ({
    useWindow,
    children,
}) => {
    const shortcuts: { [id: string]: KeyboardShortcutState } = useMemo(() => ({}), []);

    const onKeyDown = useCallback(
        (ev: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => {
            if (!filterKeyEvent(ev)) {
                return;
            }

            const toBeExecuted: KeyboardShortcutState[] = [];

            const ids = Object.keys(shortcuts);
            for (let i = 0; i < ids.length; i++) {
                const shortcut = shortcuts[ids[i]];
                if (ev.key === shortcut.shortcut[shortcut.current.length]) {
                    shortcut.current.push(ev.key);

                    if (shortcut.current.length === shortcut.shortcut.length) {
                        toBeExecuted.push(shortcut);
                    }
                }
            }

            if (toBeExecuted.length) {
                // Sort by priority - higher priority options can be handled first, and may prevent
                // lower priority shortcuts from executing.
                toBeExecuted.sort((a, b) => (b.options?.priority ?? 0) - (a.options?.priority ?? 0));

                // TODO: Currently using defaultPrevented to check if we should keep executing...
                // Possibly we should be using our own flag rather than overloading the meaning of
                // the defaultPrevented flag.
                for (let i = 0; i < toBeExecuted.length && !ev.defaultPrevented; i++) {
                    // If a drag is in progress, ignore any keys involved in drag related keyboard events.
                    if (
                        !dragStatus.isDragging ||
                        toBeExecuted[i].options?.isDragDrop ||
                        (ev.key !== "Space" && ev.key !== "Enter" && ev.key !== "Escape")
                    ) {
                        toBeExecuted[i].callback(ev);
                    }
                }
            }
        },
        [shortcuts]
    );
    const onKeyUp = useCallback(
        (ev: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => {
            const ids = Object.keys(shortcuts);
            for (let i = 0; i < ids.length; i++) {
                const shortcut = shortcuts[ids[i]];

                const currentIndex = shortcut.current.indexOf(ev.key);
                if (currentIndex >= 0) {
                    shortcut.current.splice(currentIndex);
                }
            }
        },
        [shortcuts]
    );

    useEffect(() => {
        if (useWindow) {
            window.addEventListener("keydown", onKeyDown);
            window.addEventListener("keyup", onKeyUp);

            return () => {
                window.removeEventListener("keydown", onKeyDown);
                window.removeEventListener("keyup", onKeyUp);
            };
        }
    }, [useWindow, onKeyDown, onKeyUp]);

    if (useWindow) {
        return <KeyboardShortcutContext.Provider value={shortcuts}>{children}</KeyboardShortcutContext.Provider>;
    }

    return (
        <Box fullWidth fullHeight onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
            <KeyboardShortcutContext.Provider value={shortcuts}>{children}</KeyboardShortcutContext.Provider>;
        </Box>
    );
};

/**
 * Listens for a keyboard shortcut, calling the callback when it is executed.
 * @param shortcut An ordered array of key values as per https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values. The keys must be pressed in the specified order.
 * @param callback The callback to call when the keyboard shortcut is used.
 * @param options Extra options that may affect how the shortcut is executed.
 */
export function useKeyboardShortcut(
    shortcut: string[] | string,
    callback: (ev: React.KeyboardEvent | KeyboardEvent) => void,
    options?: KeyboardShortcutOptions
) {
    const id = useMemo(() => shortcutId++, []);
    const shortcuts = useContext(KeyboardShortcutContext);

    useEffect(() => {
        return () => {
            delete shortcuts[id];
        };
    }, [id, shortcuts]);

    if (options?.isDisabled) {
        delete shortcuts[id];
    } else {
        const sc = typeof shortcut === "string" ? [shortcut] : shortcut;
        if (!shortcuts[id]) {
            shortcuts[id] = { shortcut: sc, callback, options, current: [] };
        } else {
            const data = shortcuts[id];
            data.shortcut = sc;
            data.callback = callback;
            data.options = options;
        }
    }
}

export function usePreviousValue<T>(value: T): T | undefined {
    const ref = useRef<T>();
    const previousValue = ref.current;
    ref.current = value;
    return previousValue;
}

export function startsWithVowel(s: string) {
    const c = s[0].toLowerCase();
    return c === "a" || c === "e" || c === "i" || c === "o" || c === "u";
}

export function humanize(num: number) {
    if (typeof num == "undefined") {
        return;
    }

    if (num % 100 >= 11 && num % 100 <= 13) {
        return num + "th";
    }

    switch (num % 10) {
        case 1:
            return num + "st";
        case 2:
            return num + "nd";
        case 3:
            return num + "rd";
    }

    return num + "th";
}

// const lightThemeMq = window.matchMedia?.("(prefers-color-scheme: light)");
// function getOsTheme(): ThemeType {
//     return lightThemeMq?.matches ? "light" : "dark";
// }

// export function useTheme(): [ThemeType, (theme: ThemeType | undefined) => void] {
//     const [themeName, setThemeName] = useLocalSetting(themeSetting);

//     const applyTheme = useCallback((theme: ThemeType | undefined) => {
//         const root: HTMLElement | null = document.querySelector(":root");
//         if (!root) {
//             throw Error(":root element not found");
//         }

//         const newTheme = getTheme((theme as ThemeType) ?? getOsTheme());

//         root.style.setProperty("--c-foreground", newTheme.foreground);
//         root.style.setProperty("--c-background", newTheme.background);
//         newTheme.grayscale.forEach((c, i) => {
//             root.style.setProperty(`--c-grayscale-${i}`, c);
//         });
//     }, []);

//     const setTheme = useCallback(
//         (theme: ThemeType | undefined) => {
//             applyTheme(theme);
//             setThemeName(theme);
//         },
//         [applyTheme, setThemeName]
//     );

//     // This deliberately only runs on the first render - from that point on the theme is updated when setting it,
//     // not on the next render.
//     useEffect(() => {
//         applyTheme((themeName as ThemeType) ?? getOsTheme());

//         // Respond if the user changes their OS theme.
//         const handler: (ev: MediaQueryListEvent) => any = () => {
//             if (themeSetting.getValue() == null) {
//                 applyTheme(undefined);
//             }
//         };
//         lightThemeMq?.addEventListener("change", handler);
//         return () => {
//             lightThemeMq?.removeEventListener("change", handler);
//         };

//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     return [(themeName as ThemeType) ?? getOsTheme(), setTheme];
// }

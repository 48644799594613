import { Annotation } from "./annotations";
import {
    Position,
    LocalPixelPosition,
    GridPosition,
    Size,
    LocalRect,
    ScreenRect,
    Point,
    PositionType,
} from "./position";
import { GlobalPathState, GridType, ILocalGrid } from "./grid";
import { PropsWithChildren, ReactElement, ReactNode } from "react";
import { Omit } from "react-redux";
import { SearchCategoryResults, VttMode } from "./components/common";
import { Event, KeyedList, WithOverride, keyedListToKeyArray } from "./common";
import { LocalSearchableFieldConfig } from "./localsearchable";
import { Action, Dispatch } from "redux";
import { nanoid } from "nanoid";
import { SelectionType } from "./components/selection";
import JSZip from "jszip";

/**
 * Contains information about a single user.
 */
export interface UserInfo {
    id: string;
    profile?: Profile;
}

/**
 * Contains the publicly available information about a user.
 */
export interface Profile {
    userId: string;
    name: string;
}

export enum ImageType {
    Background = "background",
    Token = "token",
    Portrait = "portrait",
    Object = "object",
}

export enum AudioType {
    Music = "music",
    Ambient = "ambience",
}

export enum ModelType {
    Token = "model-token",
}

export interface AudioTrack {
    uri: string;
    name: string;
    volume?: number;
}

export type AudioTrackWithId = AudioTrack & { id: string };
export type AudioTrackWithOptionalId = AudioTrack & { id?: string };
export type AudioTrackWithIndex = AudioTrack & { index: number };

// Note that the tracks are accessed by ID and not as an array so that merges between conflicting changes can work
// correctly. This is consistently followed everywhere, it just seems strange here because a playlist is inherently
// ordered. We handle the order using the index property on the track instead.
export interface Playlist {
    tracks: { [id: string]: AudioTrackWithIndex };
}

export function tracksToArray(tracks: { [id: string]: AudioTrackWithIndex } | undefined): AudioTrackWithId[] {
    if (!tracks) {
        return [];
    }

    const keys = Object.keys(tracks);
    return keys.map(o => Object.assign({ id: o }, tracks[o])).sort((a, b) => a.index - b.index);
}

export function arrayToTracks(arr: AudioTrackWithOptionalId[]) {
    const tracks: { [id: string]: AudioTrackWithIndex } = {};
    for (let i = 0; i < arr.length; i++) {
        const track = Object.assign({}, arr[i]) as any;
        track["index"] = i;

        let id = track.id;
        if (!id) {
            id = nanoid();
        }

        delete track.id;
        tracks[id] = track;
    }

    return tracks;
}

export type LightType = "default" | "torch" | "pulse" | "flicker";

export interface Light {
    /**
     * The type of light. Certain effects (e.g. flickering firelight) may be applied by different light types.
     */
    type: LightType;

    /**
     * The distance from the light (in grid units) that the light level starts to attenuate.
     * (i.e. up to this distance, the light is fully effective).
     * If not specified, the game system default is used.
     */
    innerRadius?: number;

    /**
     * The maximum distance from the light (in grid units) that is illuminated.
     * The light level between the innerRadius and the outerRadius is interpolated.
     * If not specified, the game system default is used.
     */
    outerRadius?: number;

    /**
     * The brightness of the light, between 0 and 1.
     */
    brightness?: number;

    /**
     * The color of the light, defaulting to white.
     */
    color?: string;
}

export interface PulseLight {
    /**
     * The minimum brightness of the light, between 0 and 1.
     */
    minBrightness?: number;

    /**
     * The duration of a single pulse, in seconds.
     */
    pulseDuration?: number;
}

export interface TorchLight {
    // TODO: Add configurable stuff for torch lights.
}

export interface PositionedLight extends Light {
    /**
     * The ID of the light. Note when consuming this property that it may represent a token or other light owner.
     */
    id: string;

    /**
     * The position of the light.
     */
    pos: WithLevel<GridPosition | LocalPixelPosition>;
}

export const defaultSoundRadius = 10;

export interface TokenAudio extends AudioTrack {
    radius?: number;
}

interface AnimationInstanceOptions {
    /**
     * If specified, the video will loop indefinitely, until the animation is explicitly halted.
     * To loop the entire video, specify a from of 0 and no to value.
     * Values are in seconds.
     */
    loop?: { from: number; to?: number };

    /**
     * The size of a single tile within the video, used to scale the animation to match the grid at the location it is used.
     */
    tileSize?: number;

    /**
     * If the image width would be stretched, also change the image height to preserve the aspect ratio.
     */
    preserveAspect?: boolean;

    /**
     * If this is a projectile animation, the X position where the projectile will begin in pixels.
     */
    sourceX?: number;

    /**
     * If this is a projectile animation, the X position wehre the projectile will end (i.e. hit the target) in pixels.
     */
    targetX?: number;

    lights?: {
        /**
         * The delay after the step starts before the light should appear, in seconds.
         * Note that this delay happens after the step's delay is finished, so it's the time in the actual video file that
         * the light should appear.
         */
        delay?: number;

        light: Light;

        // TODO: More stuff here to deal with:
        // - Stopping the light before the end of the video

        keyframes?: AnimationKeyframe<"brightness" | "position">[];
    }[];
}

export interface AnimationGroup extends AnimationInstanceOptions {
    files: AnimationFile[];
}

export interface AnimationFile extends AnimationInstanceOptions {
    /**
     * The URI for the video file.
     */
    uri: string;
}

export interface AnimationKeyframe<T extends string> {
    time: number;
    props: { [id in T]: number | undefined };
    easing?: "linear" | "easeIn" | "easeOut" | "easeInOut";
}

export interface AnimationStep extends AnimationInstanceOptions {
    /**
     * Specifies the location that the animation will play at. Usually this can be left blank, which will default to
     * "source" for spells targetting self, "projectile" for targetted spells, and "target" for spells with an annotation
     * not centered on the source.
     */
    at?: "source" | "target" | "projectile";

    /**
     * The minimum delay before starting the animation, in seconds.
     */
    minDelay?: number;

    /**
     * The maximum delay before starting the animation, in seconds.
     */
    maxDelay?: number;

    /**
     * The URI for the video to play for this animation step. If more than one URI is specified, one may be chosen at random, or they may
     * be used in sequence for different targets (depending on the parent animation sequence).
     */
    animations?: AnimationGroup;

    /**
     * The URI for the video to play for specific ranges. The one that is closest to the actual range will be used.
     */
    animationsByRange?: {
        [id: string]: AnimationGroup;
    };

    // TODO: Need something to say whether or not we wait for this step to complete before starting the next?

    zIndex?: number;
}

/**
 * Base type for animation sequences.
 * Can be used as is for animations that require no extra information apart from their context,
 * i.e. animations for status effects that always target the token they're applied to.
 */
export interface AnimationSequence {
    /**
     * The annotation that is being animated. The way the animation is executed then depends on the
     * details of the annotation (for example, the source would be the caster, the target would be the annotation's pos
     * and the projectile would go between for a circle annotation (i.e. a fireball), but for a LineAreaAnnotation the
     * projectile would go from one end of the line to the other, with the target being the far (non-pos) end).
     */
    annotation?: Annotation;

    steps: AnimationStep[];
}

export type TokenType = "creature" | "object" | "audio" | "light";

/**
 * Returns either the token ID or the token's template ID, depending on which one should be modified
 * when editing the token.
 * @param token The token to get the ID for.
 */
export function getModifyId(token: Token | TokenTemplate) {
    if (isTokenTemplate(token)) {
        return token.templateId;
    }

    return token.templateId && !token.ignoreTemplate ? token.templateId : token.id;
}

export interface TokenAppearance {
    /**
     * The URI for the image for this token.
     */
    imageUri?: string;

    /**
     * The URI for the 3D model for this token.
     */
    modelUri?: string;

    /**
     * Whether this token can rotate (this should be set to false for portrait style tokens where rotation doesn't make sense).
     */
    canRotate?: boolean;

    /**
     * Angle to rotate the token to get it to the default orientation (facing down).
     */
    defaultRotation?: number;

    /**
     * The scale at which to display this token. Defaults to 1 if not specified.
     */
    scale?: number;

    /**
     * The scale at which to render the token. This is applied multiplicatively with the scale value. Defaults to 1 if not specified.
     * i.e. if the scale is 2 and the renderScale is 1.5, the token image is rendered at scale 3 while the
     * token hit box remains at scale 2.
     * This can be used to render tokens that overflow the bounds of their token.
     */
    renderScale?: number;
}

export interface Token extends TokenAppearance, NoteBase {
    id: string;

    /**
     * The ID of the template for this token, or undefined.
     */
    templateId?: string;

    /**
     * The current position of this token within the location.
     */
    pos: WithLevel<GridPosition | LocalPixelPosition>;

    /**
     * The ID of the player that currently owns this token, if any.
     */
    owner?: string;

    /**
     * Gets the light for this token, if any.
     */
    light?: Light;

    /**
     * Gets the audio track to play for this token.
     */
    sound?: TokenAudio;

    /**
     * Angle that this token is rotated, in degrees.
     */
    rotation?: number;

    /**
     * The type of token. If not specified, then the token type is detected based on its properties.
     * * token: the token is a full token, treated as a living character/NPC in the world.
     * * object: the token is treated as an inanimate object, part of the environment.
     * * audio: the token is treated as audio only, even if an image has been specified.
     * * light: the token is treated as light only, even if an image has been specified.
     */
    type?: TokenType;

    /**
     * A value indicating whether the template for this token should be ignored. If falsy then any values not specified directly
     * on the token will delegate to the value on the template if available.
     * This may also be used to indicate whether actions should be targeted at the token or the template.
     */
    ignoreTemplate?: boolean;

    /**
     * The proposed path for the token to travel pending GM approval.
     */
    nextPath?: WithLevel<GridPosition>[];

    /**
     * The last path that the token travelled to get to its current position.
     */
    prevPath?: WithLevel<GridPosition>[];

    /**
     * The URI for a portrait image for this token.
     */
    portraitImageUri?: string;

    /**
     * A value indicating whether this token is visible to players.
     */
    isPlayerVisible?: boolean;

    /**
     * The tile size in pixels that the art was designed for.
     * Only relevant for object tokens to display at the correct scale. If not specified, the image will be displayed at its natural size.
     */
    tileSize?: Size;

    /**
     * The zIndex to display at. This is usually only set for object images that should display on top of tokens, although it can specify
     * any zIndex. If not specified, objects are displayed under tokens.
     */
    zIndex?: number;

    /**
     * The opacity to apply when one or more creature tokens intersect with this token.
     */
    occupiedOpacity?: number;

    /**
     * Gets or sets a value indicating whether the image for this token will be modified to upen up windows
     * through which creature tokens underneath can be seen.
     * Only relevant for object tokens with zIndex > 2.
     */
    windowOpacity?: number;

    /**
     * The opacity to apply when one or more visibility sources are active and their visibility intersects with this token.
     * This is mainly useful when the entire location is revealed, as otherwise it's very similar to just setting the opacity.
     */
    visibleOpacity?: number;

    images?: TokenImageMetadata[];
}

export interface ObjectToken extends Token {
    type: "object";
}

export interface TokenImageMetadata {
    uri: string;
    thumbnailUri?: string;
    canRotate?: boolean;
    rotation?: number;
    renderScale?: number;
}

export type TokenTemplate = Omit<Token, "id" | "pos"> & {
    templateId: string;
};

export function isLocation(location: LocationSummary | Location | NoteBase | undefined): location is Location {
    return location?.["tokens"] != null;
}

export function isToken(item: any): item is Token {
    return (
        item &&
        item.id &&
        item.pos &&
        !item.points &&
        (item.templateId ||
            item.imageUri ||
            item.light ||
            item.sound ||
            item.type === "creature" ||
            item.type === "object" ||
            item.type === "audio" ||
            item.type === "light")
    );
}

export function isTokenTemplate(item: any): item is TokenTemplate {
    return item && item.templateId && !item.id;
}

export function isZone(item: any): item is Zone {
    // TODO: This definition could use some work once there's more properties that are unique to Zone.
    return item && item.id && item.pos && item.points && item.label && !item.type && !isToken(item);
}

export interface NoteBase {
    /**
     * The label of the zone.
     */
    label?: string;

    /**
     * Markdown content intended as notes for the GM for this entity.
     */
    gmNotes?: string;
}

export interface ZoneBase extends NoteBase {
    /**
     * Whether or not the location is considered to be inside (or underground, etc). This affects whether the day/night
     * cycle is used, and whether weather effects (rain, snow) can fall in this area.
     */
    isInside?: boolean;
}

export interface Zone extends ZoneBase {
    id: string;

    pos: WithLevel<LocalPixelPosition>;
    points: Point[];

    /**
     * A short code (i.e. M1, C8) that can be shown on a map legend.
     */
    code?: string;

    /**
     * Gets the audio track to play for this zone.
     */
    sound?: TokenAudio;
}

export function createZone(location: Location, pos: WithLevel<LocalPixelPosition>, points: Point[]) {
    const zone: Zone = {
        id: nanoid(),
        label: `Zone ${Object.keys(location.zones).length + 1}`,
        pos: pos,
        points: points,
    };
    return zone;
}

/**
 * Contains information about a player relevant only to the containing location.
 */
export interface LocationPlayer {}

export interface CombatParticipant {
    /**
     * The initiative numbers for the participant, with each number after the first acting as a tiebreaker for the previous
     * number, if multiple participants should have the same number.
     */
    initiative?: number[];
}

export interface CombatEncounter {
    /**
     * The round of combat that is currently underway, starting at 0 for the first round (i.e. the number of times every participant has acted).
     */
    round?: number;

    /**
     * The ID of the token whose turn it is.
     */
    turn?: string;

    /**
     * The participants of the combat encounter.
     * The ID is the the id of the token that is participating in the encounter.
     */
    participants: {
        [id: string]: CombatParticipant;
    };
}

export interface LocationSummary extends NoteBase {
    id: string;

    /**
     * The URI for a thumbnail for this location.
     */
    thumbnailUri?: string;
}

export type LocationLevelVisibility = "default" | "always";

export interface LocationLevel extends ZoneBase {
    /**
     * The label of the level. This is just informational for the DM.
     */
    label?: string;

    /**
     * Determines when this level will be visible.
     * Note that even if the level is visible, it can be rendered partially or completely transparent by occlusion rules etc.
     */
    visibility?: LocationLevelVisibility;

    /**
     * Indicates whether the level should be hidden in build mode.
     * Toggling the level on and off in build mode is useful when designing a level after placing an always visible roof level
     * that would otherwise be constantly getting in the way.
     */
    hiddenInBuild?: boolean;

    /**
     * The opacity to apply when one or more creature tokens are beneath this level.
     */
    occupiedOpacity?: number;

    /**
     * Whether or not to reveal the map to players, even if their character(s) cannot see parts of it. Other settings such
     * as light level still applies, and lights will still work and be obscured etc. Use this for maps where the players
     * already know the layout, or for world/city maps etc.
     */
    revealAll?: boolean;

    /**
     * Whether or not this level will be cut away to show visible areas of lower levels.
     */
    cutAway?: boolean;

    /**
     * The level of the light, from 0 (complete darkness) to 1 (full sun, full visibility). Defaults to 1 if not specified.
     */
    lightLevel?: number;

    /**
     * The color of the light, defaulting to black (untinted).
     */
    lightColor?: string;

    /**
     * The URI for the background image for this location.
     */
    backgroundImageUrl?: string;

    /**
     * The position at which to display the background image.
     */
    backgroundImagePos?: LocalPixelPosition;

    /**
     * Whether or not to disable the dynamic day/night lighting cycle for this location.
     */
    disableDayNight?: boolean;
}

export function getLevelLabel(level: LocationLevel, index: number) {
    return level.label ?? (index === 0 ? "Ground" : "Level " + index);
}

export function getLevelKeysInRenderOrder(location: Location) {
    return keyedListToKeyArray(location.levels);
}

export interface Location extends LocationSummary {
    tileSize: Size & { isConfigured?: boolean };

    /**
     * The unit to use for this location. Usually this will be the default for the system (default if unspecified),
     * but some locations (i.e. overworld maps, city maps, etc) may use a different scale to others.
     */
    unit?: string;

    /**
     * The number of units per grid unit (i.e. a single square, for square grids). This will normally be the default
     * for the system (default if unspecified), but some locations (i.e. overworld maps, city maps, etc) may use a
     * different scale to others.
     */
    unitsPerGrid?: number;

    levels: KeyedList<LocationLevel>;

    defaultLevel: string;

    tokens: { [tokenId: string]: Token };

    annotations: { [annotationId: string]: Annotation };

    zones: { [zoneId: string]: Zone };

    /**
     * The music playlist for this location.
     */
    music?: Playlist;

    /**
     * The tracks that provide looped ambient audio for this location.
     */
    ambientAudio?: Playlist;

    /**
     * Contains information about a specific player relevant only to this location.
     */
    players?: { [userId: string]: LocationPlayer };

    /**
     * The combat tracker information for any combat currently taking place at this location.
     */
    combat?: CombatEncounter;

    /**
     * A value indicating whether lighting will be displayed by default in build mode (if no specific
     * vision source has been selected).
     */
    showLightingInBuild?: boolean;

    /**
     * The amount of snow particles falling at this location.
     */
    snowAmount?: number;

    /**
     * The amount of rain particles falling at this location.
     */
    rainAmount?: number;

    /**
     * The wind strength at this location, represented as a 2D vector in grid units.
     */
    wind?: Point;
}

/**
 * Contains information about a player relevant only to the current session.
 * (i.e. information that is not persisted with the campaign, such as connection/WebRTC info)
 */
export interface SessionPlayer {
    userId: string;
    isRtcEnabled?: boolean;

    // TODO: Information relevant to the game here, such as current WebRTC info.
}

export interface SessionPlaylist {
    trackCount: number;
    startTime?: number;
    id?: string;
}

/**
 * Contains information about a location relevant only to the current session.
 */
export interface SessionLocation {
    music?: SessionPlaylist;
}

export interface IRtcUser {
    /**
     * Gets the stream from this user. The stream will contain an audio track and optionally a video track.
     */
    readonly stream?: MediaStream;

    /**
     * Gets a value indicating whether this user is currently speaking.
     */
    readonly isSpeaking: boolean;

    /**
     * Gets the session that this user connection is part of.
     */
    readonly session: IRtcSession;

    /**
     * Occurs when any of the properties of the object change.
     */
    readonly updated: Event<void>;
}

export interface IRtcSelf extends IRtcUser {
    /**
     * Gets or sets a value indicating whether the video is currently muted (temporarily not transmitting).
     */
    isVideoMuted: boolean;

    /**
     * Gets or sets a value indicating whether the audio is currently muted.
     */
    isAudioMuted: boolean;

    /**
     * Gets or sets a value indicating whether video is currently enabled.
     */
    isVideoEnabled: boolean;
}

export interface IRtcSession {
    /**
     * Gets a value indicating whether the current user is in the call.
     */
    readonly isJoined: boolean;

    /**
     * Gets a value indicating whether the current user is currently joining the call.
     */
    readonly isJoining: boolean;

    /**
     * Gets a value indicating whether the current user is currently leaving the call.
     */
    readonly isLeaving: boolean;

    /**
     * Gets the current user's outgoing stream details.
     */
    readonly self?: IRtcSelf;

    /**
     * Gets the details of incoming streams for all connected users.
     */
    readonly peers: { readonly [id: string]: IRtcUser };

    /**
     * Joins the session (call) for the current campaign.
     * @param withVideo True to transmit video (camera) in the stream, as well as audio.
     */
    join(withVideo?: boolean): Promise<void>;

    /**
     * Leaves the current session.
     */
    leave(): Promise<void>;

    /**
     * Occurs when any of the properties of the object change.
     */
    updated: Event<void>;
}

export interface LogEntryOptions {
    location?: string;
    token?: string;
    data?: any;
    notify?: LogEntryNotification;
}

export interface RollOptions extends LogEntryOptions {
    state?: any;
}

/**
 * A template for placing an annotation.
 */
export interface AnnotationPlacementTemplate<T extends Annotation> {
    // The annotation template.
    annotation: Omit<T, "id" | "userId">;

    // The origin of the template - the range is relative to this point.
    // TODO: Support a position instead of a token here too?
    origin: Token;

    // The maximum distance away the annotation's center can be from the origin, in local pixel units.
    range: number;

    // Called just before placing the annotation, to provide any last minute changes before applying the annotation.
    onPlacing?: (annotation: Annotation, session: Session) => Annotation;

    // Called when the final annotation is placed.
    onPlaced?: (annotation: Annotation) => void;
}

export interface ISessionApi {
    roll(
        expression: string,
        options?: RollOptions
    ): Promise<{ unconfirmed: DiceRollLogEntry & { state?: any }; confirmed: Promise<DiceRollLogEntry> }>;
    sendMessage(message: string, options?: LogEntryOptions): Promise<MessageLogEntry>;
    loadOlderSessions(time: number): Promise<void>;
    ping(location: string, pos: Position): Promise<void>;
    resetFow(locationId: string, levelKey?: string, userId?: string);
    updateFow(locationId: string, levelKey: string, fow: Point[][], userId?: string): Promise<void>;
    getFow(locationId: string, levelKey: string): Promise<Point[][] | undefined>;

    fowReset: Event<{ locationId: string; levels: string[] }>;
    pinged: Event<{ player: string; location: string; pos: Position }>;
    rolled: Event<{ unconfirmed: DiceRollLogEntry & { state?: any }; confirm: () => Promise<void> }>;

    /**
     * Occurs when an error is encountered applying client side patches on the server.
     * If this event fires, then one or more patches have been rolled back.
     */
    patchError: Event<Error>;

    rtc?: IRtcSession;

    disconnect();
}

/**
 * The status of a connection to a session.
 */
export interface SessionConnection {
    isJoiningSession: boolean;
    isLeavingSession: boolean;

    isSendingChanges: boolean;
    sendingChangesCount: number;

    hasPendingChanges: boolean;
    pendingChangesCount: number;

    isDisconnected?: boolean;
    isServerError?: boolean;
    isConnected?: boolean;

    session?: Session;
    api?: ISessionApi;
    system?: IGameSystem;
}

/**
 * Contains information about a single session of a campaign.
 */
export interface Session {
    sessionId: string;
    players: { [userId: string]: SessionPlayer };
    locations: { [locationId: string]: SessionLocation } | undefined;
    campaign: Campaign;
    log: { [id: string]: LogEntry & { id: string } };
    previousSessions: { [id: string]: CampaignSession };
    allPreviousSessionsLoaded?: boolean;
    startTime: number;
    startGameTime: number;
    time: CampaignTime;

    /**
     * The currently active combat location. Only one location can have an active combat at a time, as it is linked to
     * the campaign time. If this is specified, then a warning should be shown before allowing another combat to start.
     */
    combatLocation?: string;

    /**
     * The base storage URI.
     */
    storageUri: string;
}

export type CampaignRole = "GM" | "Player";

export interface CampaignPlayerSummary extends Profile {
    colour?: string;
    role?: CampaignRole;

    /**
     * Date/time string indicating the last time the user was active in this campaign.
     */
    lastSeen?: number;
}

export type PermissionType = "read" | "write";

export interface Permissions {
    /**
     * The baseline permissions where not specified elsewhere.
     */
    all?: {
        [permission in PermissionType]?: boolean;
    };

    /**
     * Permissions by user.
     */
    user?: {
        [id: string]: {
            [permission in PermissionType]?: boolean;
        };
    };

    /**
     * Permissions by role.
     */
    role?: {
        [role in CampaignRole]?: {
            [permission in PermissionType]?: boolean;
        };
    };

    /**
     * The user ID of the owner.
     * The owner always has all permissions.
     */
    owner?: string;
}

export interface Handout {
    id: string;

    label: string;

    /**
     * If specified, gives the current permissions for this handout.
     * If not specified, read permission for the current user is implied but nothing else.
     */
    permissions?: Permissions;

    /**
     * Tracks the last time a user contributed to the handout.
     */
    contributors?: { [id: string]: number };

    content: string;

    /**
     * The time the handout was last modified.
     */
    modified: number;
}

/**
 * Contains information about a player that is relevant to the overall campaign and needs to be persisted
 * between sessions (e.g. current location, colour, primary token, etc).
 */
export interface CampaignPlayer extends CampaignPlayerSummary {
    location: string;
    level?: string;
}

export const msPerSecond = 1000;
export const msPerMinute = msPerSecond * 60;
export const msPerHour = msPerMinute * 60;
export const msPerDay = msPerHour * 24;

/**
 * Gets the in game time (ms since start of campaign).
 * @param time The campaign time.
 * @returns Milliseconds since the start of the campaign, in game time.
 */
export function getGameTime(time: CampaignTime) {
    if (time.speed === 0) {
        return time.gameTime;
    }

    const now = Date.now();
    const realMsSinceChange = now - time.realTime;
    const gameMsSinceChange = realMsSinceChange * time.speed;
    return time.gameTime + gameMsSinceChange;
}

/**
 * gameTime/realTime refers to the last time the time was changed, so it starts at 0 (so does speed) and you can calculate
 * the in game time by multiplying the difference between current real time and realTime by speed and adding
 * that to gameTime.
 * When the session is closed it must set the speed to 0 and set the realTime and gameTime appropriately.
 * TODO: How is time during combat handled? Paused at start, then system can add a certain amount of time at the end of a round?
 * TODO: How do we react to certain times occurring (i.e. spell duration expires). Could just get everyone to
 * do it, the system should handle the conflicts - i.e. the first one to go through will remove the effect, then
 * everyone else will retry it and find it already removed, so no change.
 */
export interface CampaignTime {
    /**
     * The current speed that in-game time is passing, as a ratio to real time.
     * So 0 is paused, 1 is real-time, 2 means 2 hours pass in game for every hour in real time, etc.
     */
    speed: number;

    /**
     * The time at the point the speed last changed, in game time.
     */
    gameTime: number;

    /**
     * The time at the point the speed last changed, in real time.
     */
    realTime: number;
}

export interface CampaignBase {
    id: string;
    label: string;
    description?: string;

    /**
     * The URL for the cover image for this campaign.
     */
    coverImageUrl?: string;

    /**
     * The current in-game time for the campaign. During a session, this can be ignored, use the time available from Session.time.
     * This is loaded at the start of the session to populate the session's gameTime, and updated at the end of a session.
     */
    gameTime: number;

    /**
     * A value indicating whether the current game time should be shown to the players.
     */
    hideTime?: boolean;
}

/**
 * Contains information about a previously completed session.
 */
export interface CampaignSession {
    id: string;
    campaignId: string;
    startTime: number;
    endTime: number;
    startGameTime: number;
    endGameTime: number;
}

/**
 * Partial information about a campaign, suitable for showing in a list without loading the full campaign.
 */
export interface CampaignSummary extends CampaignBase {
    /**
     * Date/time string indicating the last time the current user was active in this campaign.
     */
    lastSeen?: number;

    players?: { [id: string]: CampaignPlayerSummary };
}

export interface LogHeader {
    token?: string;
    location?: string;
    data?: any;
    userId: string;
}

export interface LogEntryNotification {
    /**
     * Whether or not the owner of the log entry should receive notification.
     * If false, only the owner will not be shown notifications for this log entry, unless
     * the flag for that type of notification is explicitly set to true.
     */
    owner?: boolean;

    /**
     * A value indicating whether or not a notification should be shown on the token for
     * this log entry. Only applies to log entries that have an associated token.
     */
    token?: boolean;

    /**
     * A value indicating whether a toast notification should be shown for this log entry.
     */
    toast?: boolean;

    /**
     * Do not show a notification if a token or annotation with the specified ID is selected.
     */
    notSelected?: string;
}

export function shouldShowNotification(
    type: "toast" | "token",
    userId: string,
    logEntry: LogEntry,
    selection: string[] | boolean | undefined,
    defaultValue: boolean
) {
    if (!logEntry.notify) {
        return defaultValue;
    }

    if (logEntry.notify.owner === false && userId === logEntry.userId) {
        if (type === "toast") {
            return logEntry.notify.toast === true;
        } else {
            return logEntry.notify.token === true;
        }
    }

    // If the value for the notification type has been explicitly set, respect that above anything else.
    if (type === "toast") {
        if (logEntry.notify.toast != null) {
            return logEntry.notify.toast;
        }
    } else {
        if (logEntry.notify.token != null) {
            return logEntry.notify.token;
        }
    }

    if (logEntry.notify.notSelected != null) {
        // Only show the notification if the specified object is NOT selected.
        // No selection being passed in counts as not selected.
        if (
            typeof selection === "boolean"
                ? selection
                : selection && selection.indexOf(logEntry.notify.notSelected) >= 0
        ) {
            return false;
        }
    }

    return defaultValue;
}

export interface LogEntry extends LogHeader {
    id?: string;
    type?: "message" | "roll"; // Future possibilities: creature/spell/ability/race/etc, would be system specific.
    time: number;

    notify?: LogEntryNotification;

    // TODO: Lots more to do here for GM only entries, player only entries, etc.
}

export interface MessageLogEntry extends LogEntry {
    type: "message";
    message: string;
}

export type DiceType = "d4" | "d6" | "d8" | "d10" | "d12" | "d20" | "d100";

export function diceTypeToAverage(diceType: DiceType): number {
    switch (diceType) {
        case "d4":
            return 3;
        case "d6":
            return 4;
        case "d8":
            return 5;
        case "d10":
            return 6;
        case "d12":
            return 7;
        case "d100":
            return 51;
    }

    return 0;
}

export function diceTypeToMax(diceType: DiceType): number {
    switch (diceType) {
        case "d4":
            return 4;
        case "d6":
            return 6;
        case "d8":
            return 8;
        case "d10":
            return 10;
        case "d12":
            return 12;
        case "d100":
            return 100;
    }

    return 0;
}

export interface DiceTerm {
    scalar?: number;
    result: number;
    type: DiceType;
    isExcluded?: boolean;
}

export interface DiceRoll {
    expression: string;
    result: number;
    terms: DiceTerm[];
}

export type DiceRollLogEntry = LogEntry & DiceRoll & { type: "roll" };

/**
 * Contains all the information necessary to start a persistent game.
 */
export interface Campaign extends CampaignBase {
    /**
     * Gets the ID of the system for this campaign.
     */
    system?: string;

    /**
     * Gets all of the locations in the campaign, keyed by ID.
     */
    locations: { [locationId: string]: Location | LocationSummary };

    /**
     * Gets the player specific information related to the campaign (serialised state, information relevant
     * only to the current session goes in the Session/PlayerSession).
     */
    players: { [userId: string]: CampaignPlayer };

    gridType?: GridType;

    /**
     * The way that vision is shared between players. Defaults to party if not specified, meaning all players
     * will be able to see what any member of their party can see - this is the closest to tabletop games.
     */
    sharedVision?: "party" | "none";

    /**
     * Partial information about tokens that persist between locations for this campaign.
     */
    tokens: { [tokenId: string]: TokenTemplate };

    /**
     * Gets the handouts for this campaign.
     */
    handouts: { [handoutId: string]: Handout };
}

export type CampaignInit = Omit<Campaign, "id">;

export interface TokenAdornerProps<T extends Token = Token> {
    token: WithOverride<T>;
    mode: VttMode;
    isSelected: SelectionType;
}

export interface AnnotationAdornerProps<T extends Annotation = Annotation> {
    annotation: T;
    campaign: Campaign;
    grid: ILocalGrid;
    mode: VttMode;
    isSelected: SelectionType;
    localBounds: LocalRect;
    screenBounds: ScreenRect;
}

export interface SidebarPanelState {
    id: string;
    width?: number;
    maxWidth?: number;
    isPinned?: boolean;
    type?: string;
    align?: "left" | "right";
    children: () => JSX.Element;
}

export interface SidebarPanelOptions {
    /**
     * If specified, only existing unpinned panels of the specified type will be removed.
     */
    clearType?: string;

    /**
     * If specified, the panel is inserted after the panel with the specified ID, if found.
     */
    addAfter?: string;
}

export interface GmSection {
    id: string;
    label: string;
    labelLowerCase?: string;
    roles: CampaignRole[];
    renderGlyph: () => ReactElement;
    renderTools?: () => ReactElement | undefined;
    render: () => ReactElement;
}

export interface PlayerSection {
    id: string;
    key?: () => string;
    label: string;
    renderGlyph: () => ReactElement;
    render: () => ReactElement;
}

export interface ErrorHandler {
    handleResponse(response: Response, message?: string | ((response: Response) => string)): boolean;
    handleError(e: any, message?: string | ((e: any) => string));
}

const parseDiceBagRegex = /([+-]?[0-9]+)d([0-9]+)\s*(([+-])\s*([0-9]*))*/i;

const parseDiceBagCache: { [roll: string]: DiceBag } = {};

function getAverageTermsResult(terms: DiceBagTerm[], avPerTerm: number) {
    let av = 0;
    for (let i = 0; i < terms.length; i++) {
        if (terms[i].op === "-") {
            av -= terms[i].amount * avPerTerm;
        } else {
            av += terms[i].amount * avPerTerm;
        }
    }

    // TODO: Currently ignoring the keep/drop value.

    return av;
}

export function getAverageResult(bag: DiceBag | string) {
    if (typeof bag === "string") {
        bag = parseDiceBag(bag);
    }

    let av = 0;

    // TODO: Wow, getting the average is a lot harder than I thought, unless we ignore all
    // the drop lowest/highest stuff.
    if (bag.d4) {
        av += getAverageTermsResult(bag.d4, 2.5);
    }

    if (bag.d6) {
        av += getAverageTermsResult(bag.d6, 3.5);
    }

    if (bag.d8) {
        av += getAverageTermsResult(bag.d8, 4.5);
    }

    if (bag.d10) {
        av += getAverageTermsResult(bag.d10, 5.5);
    }

    if (bag.d12) {
        av += getAverageTermsResult(bag.d12, 6.5);
    }

    if (bag.d20) {
        av += getAverageTermsResult(bag.d20, 10.5);
    }

    if (bag.d100) {
        av += getAverageTermsResult(bag.d100, 50.5);
    }

    if (bag.modifier != null) {
        av += bag.modifier;
    }

    return av;
}

/**
 * If the specified roll is simple, converts it into a dice bag with a dice bag term in it.
 * Otherwise, returns a dice bag using a special term.
 * @param roll The roll to parse in standard dice notation.
 * @param options The options to include in the bag.
 */
export function parseDiceBag(roll: string, options?: RollOptions): DiceBag {
    const cachedBag = parseDiceBagCache[roll];
    if (cachedBag) {
        return options ? { ...cachedBag, options: options } : cachedBag;
    }

    const justNumber = Number(roll);
    if (!isNaN(justNumber)) {
        const bag = {
            modifier: justNumber,
        };
        parseDiceBagCache[roll] = bag;
        return bag;
    }

    var groups = parseDiceBagRegex.exec(roll);
    if (groups) {
        var amt = parseInt(groups[1]);
        var dice = groups[2];
        if (
            dice === "4" ||
            dice === "6" ||
            dice === "8" ||
            dice === "10" ||
            dice === "12" ||
            dice === "20" ||
            dice === "100"
        ) {
            var term: DiceBagTerm = { amount: Math.abs(amt), op: amt < 0 ? "-" : undefined };
            var bagModifier: number | undefined;
            if (groups[5]) {
                var modifier = parseInt(groups[5]);
                if (groups[4] === "-") {
                    modifier = -modifier;
                }

                bagModifier = modifier;
            }

            const baseBag = {
                ["d" + dice]: [term],
                modifier: bagModifier,
            };
            parseDiceBagCache[roll] = baseBag;
            return options ? { ...baseBag, options: options } : baseBag;
        }
    }

    return { special: roll, options: options };
}

/**
 * Returns a value indicating whether the specified dice bag actually contains any dice to roll, or just a modifier.
 * @param bag The bag.
 */
export function diceBagRequiresRoll(bag: DiceBag) {
    return (
        bag["d4"]?.some(o => (o.amount ?? 0) > 0) ||
        bag["d6"]?.some(o => (o.amount ?? 0) > 0) ||
        bag["d8"]?.some(o => (o.amount ?? 0) > 0) ||
        bag["d10"]?.some(o => (o.amount ?? 0) > 0) ||
        bag["d12"]?.some(o => (o.amount ?? 0) > 0) ||
        bag["d20"]?.some(o => (o.amount ?? 0) > 0) ||
        bag["d100"]?.some(o => (o.amount ?? 0) > 0)
    );
}

function evaluateTermsLocal(terms: DiceBagTerm[], sides: number) {
    let result = 0;
    for (let term of terms) {
        const rolls: number[] = [];
        for (let i = 0; i < term.amount; i++) {
            rolls.push(Math.floor(Math.random() * sides) + 1);
        }

        if (term.keep != null) {
            // TODO: Support keep/drop.
            throw new Error("Term not supported");
        }

        let termResult = 0;
        for (let i = 0; i < rolls.length; i++) {
            termResult += rolls[i];
        }

        if (term.op === "-") {
            result -= termResult;
        } else {
            result += termResult;
        }
    }

    return result;
}

export function evaluateDiceBagLocal(bag: DiceBag) {
    let result = 0;
    if (bag.d4) {
        result += evaluateTermsLocal(bag.d4, 4);
    }

    if (bag.d6) {
        result += evaluateTermsLocal(bag.d6, 6);
    }

    if (bag.d8) {
        result += evaluateTermsLocal(bag.d8, 8);
    }

    if (bag.d10) {
        result += evaluateTermsLocal(bag.d10, 10);
    }

    if (bag.d12) {
        result += evaluateTermsLocal(bag.d12, 12);
    }

    if (bag.d20) {
        result += evaluateTermsLocal(bag.d20, 20);
    }

    if (bag.d100) {
        result += evaluateTermsLocal(bag.d100, 100);
    }

    if (bag.diceModifier) {
        throw new Error("Dice modifier not supported");
    }

    if (bag.modifier) {
        result += bag.modifier;
    }

    return result;
}

/**
 * Adds two dice bags together. The options for the first bag will be retained, and the options from the second will be discarded.
 * @param a The first dice bag.
 * @param b The second dice bag.
 */
export function addDiceBags(a: DiceBag, b: DiceBag | undefined): DiceBag {
    if (!b) {
        return a;
    }

    let modifier: number | undefined;
    if (a.modifier == null) {
        modifier = b.modifier;
    } else if (b.modifier == null) {
        modifier = a.modifier;
    } else {
        modifier = a.modifier + b.modifier;
    }

    return Object.assign({}, a, {
        d4: addTerms(a.d4, b.d4),
        d6: addTerms(a.d6, b.d6),
        d8: addTerms(a.d8, b.d8),
        d10: addTerms(a.d10, b.d10),
        d12: addTerms(a.d12, b.d12),
        d20: addTerms(a.d20, b.d20),
        d100: addTerms(a.d100, b.d100),
        modifier: modifier,
    });
}

export function reduceDiceBags(diceBags: (DiceBag | undefined)[] | undefined) {
    if (!diceBags) {
        return undefined;
    }

    return diceBags.reduce<DiceBag | undefined>((p, c) => {
        if (c == null) {
            return p;
        }

        return addDiceBags(c, p);
    }, undefined);
}

function visitTerms(
    originalBag: DiceBag,
    bag: DiceBag,
    dice: DiceType,
    callback: (term: DiceBagTerm) => DiceBagTerm | undefined
): DiceBag {
    let terms = bag[dice];
    if (terms != null) {
        for (let i = 0; i < terms.length; i++) {
            const term = terms[i];
            const r = callback(term);
            if (r !== bag[dice]) {
                if (bag === originalBag) {
                    bag = Object.assign({}, bag);
                }

                if (r == null) {
                    if (terms.length === 1) {
                        delete bag[dice];
                    } else {
                        terms = terms.slice();
                        terms.splice(i, 1);
                        i--;
                        bag[dice] = terms;
                    }
                } else {
                    terms = terms.slice();
                    terms[i] = r;
                    bag[dice] = terms;
                }
            }
        }
    }

    return bag;
}

export function visitDiceBagTerms(a: DiceBag, callback: (term: DiceBagTerm) => DiceBagTerm | undefined): DiceBag {
    let bag = a;
    bag = visitTerms(a, bag, "d4", callback);
    bag = visitTerms(a, bag, "d6", callback);
    bag = visitTerms(a, bag, "d8", callback);
    bag = visitTerms(a, bag, "d10", callback);
    bag = visitTerms(a, bag, "d12", callback);
    bag = visitTerms(a, bag, "d20", callback);
    bag = visitTerms(a, bag, "d100", callback);
    return bag;
}

function addTerms(a?: DiceBagTerm[], b?: DiceBagTerm | DiceBagTerm[]) {
    if (a == null) {
        if (b == null) {
            return undefined;
        }

        return Array.isArray(b) ? b : [b];
    }

    if (b == null) {
        return a;
    }

    if (Array.isArray(b)) {
        let finalTerms = a;
        for (let i = 0; i < b.length; i++) {
            finalTerms = addTerms(finalTerms, b[i])!;
        }

        return finalTerms;
    }

    const newA = a.slice();
    const addToTermIndex = a.findIndex(o => (o.op ?? "+") === (b.op ?? "+"));
    if (addToTermIndex >= 0) {
        newA[addToTermIndex] = Object.assign({}, a[addToTermIndex], {
            amount: a[addToTermIndex].amount + b.amount,
            keep: a[addToTermIndex].keep ?? b.keep,
        });
    } else {
        newA.push(b);
    }

    return newA;
}

export interface DiceBagTerm {
    /**
     * The number of dice to roll.
     */
    amount: number;

    op?: "+" | "-";

    /**
     * If positive number, keep the specified number of dice with the highest values.
     * If a negative number, keep the specified number of dice with the lowest values.
     */
    keep?: number;
}

export type DiceBagDice = {
    [dice in DiceType]?: DiceBagTerm[];
};

export interface StoredDiceBag extends DiceBagDice {
    special?: string;
}

export interface DiceBag extends StoredDiceBag {
    /**
     * Extra operation applied to all dice rolls (only the dice, not any modifiers), e.g. " * 2".
     */
    diceModifier?: string;

    options?: RollOptions;
    onCancelled?: () => void;
    onRolled?: (roll: {
        unconfirmed: DiceRollLogEntry & { state?: any };
        confirmed: Promise<DiceRollLogEntry>;
    }) => void;

    /**
     * The number to add or subtract from the result of the dice.
     */
    modifier?: number;
}

function addTermExpressions(
    expression: string,
    die: number,
    terms: DiceBagTerm[] | undefined,
    diceModifier: string | undefined,
    op: "+" | "-",
    termCount: { count: number }
) {
    if (terms) {
        for (let i = 0; i < terms.length; i++) {
            const term = terms[i];
            if ((term.op ?? "+") === op) {
                expression += `${expression ? term.op ?? "+" : term.op === "-" ? "-" : ""}${getTermExpression(
                    die,
                    term,
                    diceModifier
                )}`;
                termCount.count++;
            }
        }
    }

    return expression;
}

function getTermExpression(die: number, term: DiceBagTerm, diceModifier: string | undefined) {
    let diceTerm: string;
    if (term.keep != null && term.keep !== 0) {
        diceTerm = `${term.amount}d${die}${term.keep < 0 ? `l${Math.abs(term.keep)}` : `k${term.keep}`}`;
    } else {
        diceTerm = `${term.amount}d${die}`;
    }

    if (diceModifier) {
        diceTerm = `(${diceTerm}${diceModifier})`;
    }

    return diceTerm;
}

export function diceBagToExpression(diceToRoll: DiceBag, prefix?: "+" | "-") {
    let expression = diceToRoll.special ?? "";
    let termCount = { count: 0 };

    if (diceToRoll.d4) {
        expression = addTermExpressions(expression, 4, diceToRoll.d4, diceToRoll.diceModifier, "+", termCount);
    }

    if (diceToRoll.d6) {
        expression = addTermExpressions(expression, 6, diceToRoll.d6, diceToRoll.diceModifier, "+", termCount);
    }

    if (diceToRoll.d8) {
        expression = addTermExpressions(expression, 8, diceToRoll.d8, diceToRoll.diceModifier, "+", termCount);
    }

    if (diceToRoll.d10) {
        expression = addTermExpressions(expression, 10, diceToRoll.d10, diceToRoll.diceModifier, "+", termCount);
    }

    if (diceToRoll.d12) {
        expression = addTermExpressions(expression, 12, diceToRoll.d12, diceToRoll.diceModifier, "+", termCount);
    }

    if (diceToRoll.d20) {
        expression = addTermExpressions(expression, 20, diceToRoll.d20, diceToRoll.diceModifier, "+", termCount);
    }

    if (diceToRoll.d100) {
        expression = addTermExpressions(expression, 100, diceToRoll.d100, diceToRoll.diceModifier, "+", termCount);
    }

    if (diceToRoll.d4) {
        expression = addTermExpressions(expression, 4, diceToRoll.d4, diceToRoll.diceModifier, "-", termCount);
    }

    if (diceToRoll.d6) {
        expression = addTermExpressions(expression, 6, diceToRoll.d6, diceToRoll.diceModifier, "-", termCount);
    }

    if (diceToRoll.d8) {
        expression = addTermExpressions(expression, 8, diceToRoll.d8, diceToRoll.diceModifier, "-", termCount);
    }

    if (diceToRoll.d10) {
        expression = addTermExpressions(expression, 10, diceToRoll.d10, diceToRoll.diceModifier, "-", termCount);
    }

    if (diceToRoll.d12) {
        expression = addTermExpressions(expression, 12, diceToRoll.d12, diceToRoll.diceModifier, "-", termCount);
    }

    if (diceToRoll.d20) {
        expression = addTermExpressions(expression, 20, diceToRoll.d20, diceToRoll.diceModifier, "-", termCount);
    }

    if (diceToRoll.d100) {
        expression = addTermExpressions(expression, 100, diceToRoll.d100, diceToRoll.diceModifier, "-", termCount);
    }

    if (diceToRoll.modifier != null && diceToRoll.modifier !== 0) {
        expression = `${expression}${expression.length > 0 ? (diceToRoll.modifier < 0 ? "-" : "+") : ""}${Math.abs(
            diceToRoll.modifier
        )}`;
        termCount.count++;
    }

    if (prefix === "+") {
        // If the expression starts with -, then it's just a const and we can ignore the +. i.e. if the expression is "-2" then
        // we don't want to return "+-2", we want "-2"!
        if (expression.startsWith("-")) {
            return expression;
        }

        return `+${expression}`;
    } else if (prefix === "-") {
        return termCount.count > 1 ? `-(${expression})` : `-${expression}`;
    }

    return expression;
}

export interface IMenuItem {
    id?: string;
    label: string;
    disabled?: boolean;
    onClick?: (props: any) => any;
    toggled?: boolean;

    subitems?: IMenuItem[];
}

export interface CustomMarkdownNode {
    name: string;
    type: "container" | "block" | "inline";
    attributes: {
        [name: string]: {
            default?: any;
        };
    };
    updateAttributes?: (attributes: { label?: string; [name: string]: string | undefined }) => {
        label?: string;
        [name: string]: string | undefined;
    };
    render: (props: { label?: string; ref?: React.Ref<HTMLElement> } & { [name: string]: any }) => JSX.Element;
    selectable?: boolean;
}

export interface SearchableSetting {
    id: string;
    label: string;
    tags?: string[];
    render: () => JSX.Element;
}

export interface SearchableSetting {
    id: string;
    label: string;
    tags?: string[];
    render: () => JSX.Element;
}

export type WithLevel<T extends Position> = T & {
    level: string;
};

export interface DragPreview {
    shape: LocalPixelPosition[];
    hover: boolean;
    feedback?: string | (() => ReactNode);
    onDrop?: (dropPoint: LocalPixelPosition) => void;
}

export interface IGameSystem {
    /**
     * The ID of the game system.
     */
    readonly id: string;

    /**
     * The default unit to use within locations (e.g. "ft", "m").
     */
    readonly defaultUnit: string;

    /**
     * The default number of the default unit per grid square (or hex, etc). This is how much it costs in terms of
     * that unit to move from one grid location to an adjacent grid location, assuming no modifiers.
     */
    readonly defaultUnitsPerGrid: number;

    /**
     * Gets the light defaults for this system.
     */
    readonly defaultLight: Required<Pick<Light, Exclude<keyof Light, "type">>>;

    /**
     * Gets the time taken for each combat round, in ms.
     */
    readonly timePerCombatRound;

    /**
     * Gets the types that are supported by this system for dropping onto the map.
     */
    readonly dropTypes: string[];

    /**
     * Gets the display name to use for the specified token or token template.
     */
    getDisplayName(token: Token | TokenTemplate, campaign: Campaign);

    /**
     * Gets the drag drop preview area (if any) to show when the specified data is dragged over the specified position on the map.
     * @param campaign The campaign.
     * @param location The location being dragged over.
     * @param grid The grid to use.
     * @param type The type of the data being dragged.
     * @param data The data being dragged.
     * @param dropPoint The point on the map that the data is being dragged over.
     */
    getDragPreview?(
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        type: string,
        data: any,
        dispatch: Dispatch,
        dropPoint?: LocalPixelPosition
    ): DragPreview[] | undefined;

    /**
     * Renders at the start of token templates, can be used to inject hooks to trigger refreshes due to filter changes etc.
     */
    renderTokenTemplates?(): React.ReactNode;

    /**
     * Filters the specified list of token templates according to any filters that are currently configured by the user
     * specifically for this system (usually using the UI rendered by renderTokenTools).
     * @param allTokens The tokens to filter.
     * @param campaign The campaign.
     * @param searchTerm The search term that has already been applied to get the results passed as allTokens.
     */
    filterTokenTemplates?(
        allTokens: TokenTemplate[],
        campaign: Campaign,
        searchTerm?: string
    ): { tokenTemplates: TokenTemplate[]; filterMessage?: string; filterApplied?: boolean };

    /**
     * Gets all of the token templates (i.e. default tokens such as creatures, sounds, lights, etc that are specific
     * to the system).
     */
    getTokenTemplates(): { [id: string]: TokenTemplate };

    getTokenTemplateSearchFields(): LocalSearchableFieldConfig<TokenTemplate>[];

    getTokenContextMenuItems(
        target: ResolvedToken | TokenTemplate | undefined,
        selection: Token[],
        campaign: Campaign,
        location?: Location
    ): IMenuItem[];

    /**
     * Gets the dark vision range for the specified token (the distance that they can see in darkness), in grid units.
     * @param token The token to get the dark vision range for.
     * @param campaign The campaign.
     * @param location The location.
     */
    getTokenDarkvision(token: Token, campaign: Campaign, location: Location): number;

    /**
     * Gets the movement range for a token, in grid units (traversal cost).
     * @param token The token to get the range for.
     * @param campaign The campaign that the token is in.
     * @param location The location that the token is in.
     */
    getTokenRange?(token: Token, campaign: Campaign, location: Location): number | undefined;

    /**
     * Gets the appearance information for a token. If not specified then the token itself is used.
     * If the system does not have any overrides for the token's appearance, the token itself may be returned unaltered.
     * @param token The token to get the appearance for.
     * @param campaign The campaign that the token is in.
     * @param location The location that the token is in.
     */
    getTokenAppearance?(token: Token, campaign: Campaign, location: Location): TokenAppearance;

    useTokenAppearance(token: Token, campaign: Campaign, location: Location): TokenAppearance;

    renderTokenTemplate(tokenTemplate: TokenTemplate): JSX.Element | null;

    getTokenTemplatePanel?(tokenTemplate: TokenTemplate): SidebarPanelState | undefined;

    renderGetInitiative(token: ResolvedToken, setInitiative: (initiative: number[]) => void): JSX.Element | null;

    renderCombatTrackerItem?(token: ResolvedToken, isCurrent: boolean, participant: CombatParticipant);

    /**
     * Render the detail section of an annotation template, appears during annotation placement.
     * @param template The template being placed.
     * @param campaign The campaign.
     * @param location The location.
     */
    renderAnnotationTemplateDetails?(
        template: AnnotationPlacementTemplate<Annotation>,
        campaign: Campaign,
        location: Location
    ): JSX.Element | null;

    getLightsForToken(token: Token, campaign: Campaign): Light[] | undefined;

    /**
     * Renders some tools that will appear with the tokens.
     * Can be used to add new tokens etc.
     */
    renderTokenTools?(): JSX.Element | undefined;

    /**
     * Renders system specific options for rolling dice.
     */
    renderDiceTools?(props: { dice: DiceBag; setDice: (dice: DiceBag) => void }): JSX.Element | undefined;

    getContexts(): React.Context<any>[];

    /**
     * Renders any contexts that are required by components in this system.
     * TODO: Replace this with useContextBridge and remove?
     */
    renderContexts(props: PropsWithChildren<{}>): JSX.Element | null;

    /**
     * Renders the entire campaign.
     */
    renderCampaign(props: PropsWithChildren<{}>): JSX.Element | null;

    /**
     * Renders additional content inside the campaign providers.
     */
    renderCampaignContent?(): JSX.Element | null;

    /**
     * Gets the sections to add to the GM section of the interface. These will be available alongside the
     * default GM sections.
     */
    getGlobalSections?(): GmSection[];

    /**
     * Gets the sections to add to the Player section of the interface. These will be available alongside the
     * default Player sections.
     */
    getPlayerSections?(selection: { primary: string[]; secondary: string[] }, location: Location): PlayerSection[];

    /**
     * Configures the specified location with the default settings for this game system, returning
     * the fully configured location.
     */
    onNewLocation?(location: Location): Location;

    /**
     * Renders the adorners for the specified token that are specific to this game system.
     * The adorners should be absolutely positioned react elements in screen coordinates obtained
     * using the specified grid.
     */
    renderTokenAdorners?(props: TokenAdornerProps): ReactElement[];

    /**
     * Renders the adorners for the specified annotation that are specific to this game system.
     * The adorners should be absolutely positioned react elements in screen coordinates obtained
     * using the specified grid.
     */
    renderAnnotationAdorners?(props: AnnotationAdornerProps): ReactElement[];

    /**
     * Renders any system specific content for the specified log entry.
     * @param logEntry The log entry to render.
     * @param token The token that this log entry is being rendered alongside. If specified, details of the token
     *              should not be rendered in the message itself.
     */
    renderLogHeader?(logEntry: LogHeader, token?: Token | TokenTemplate);

    search?(query: string, role: CampaignRole): Promise<SearchCategoryResults<any>[]>;

    /**
     * Reduce an action that refers to a specific token or tokens.
     */
    reduceToken(
        token: Token | TokenTemplate,
        action: Action,
        session: Session,
        location: Location | undefined,
        isTargetted: boolean
    ): Token | TokenTemplate;

    /**
     * Reduce an action that refers to a specific annotation.
     */
    reduceAnnotation(
        annotation: Annotation,
        action: Action,
        session: Session,
        location: Location,
        isTargetted: boolean
    ): Annotation | undefined;

    /**
     * Reduce and action that refers to the campaign.
     * @param campaign The current campaign state.
     * @param action The action to reduce.
     */
    reduceCampaign?(campaign: Campaign, action: Action): Campaign;

    /**
     * Gets the custom markdown nodes supported by this game system.
     */
    getMarkdownNodes?(): CustomMarkdownNode[];

    /**
     * Gets the system specific campaign settings.
     */
    getCampaignSettings?(): SearchableSetting[];

    /**
     * Gets the cost of traversing between two adjacent grid positions.
     * The state object is provided to share information between multiple calls to this function during a single path finding.
     */
    getTraversalCost(
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        token: Token | undefined,
        a: WithLevel<GridPosition>,
        b: WithLevel<GridPosition>,
        from: WithLevel<GridPosition>,
        to: WithLevel<GridPosition>,
        state: GlobalPathState<WithLevel<GridPosition>>,
        nodeState?: any
    ): number | { cost: number; compareCost?: number; nodeState?: any };

    /**
     * Gets the range between two tokens/positions. The returned range is in grid positions, so multiply by the location's/default units
     * per grid to get the system specific value.
     * @param campaign The campaign to get range related settings from.
     * @param location The location that this range is being calculated in.
     * @param grid The grid to use to calculate the range.
     * @param from The position or token to measure from.
     * @param to The position or token to measure to.
     */
    getGridRange(
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        from: Token | GridPosition,
        to: Token | GridPosition
    ): number;

    importFile?(zip: JSZip, processJson: <T extends Object>(o: T) => T, errorHandler: ErrorHandler): Promise<string[]>;
}

/**
 * The root object of the app state.
 */
export interface VTTStore {
    userInfo: UserInfo;
    sessions: { [campaignId: string]: SessionConnection };
}

export function getResolvedToken(
    campaign: Campaign,
    locationId?: string,
    tokenId?: string
): ResolvedToken | TokenTemplate | undefined {
    const token = getToken(campaign, locationId, tokenId);
    return isToken(token) ? resolveToken(campaign, token) : token;
}

export function getToken(campaign: Campaign, locationId?: string, tokenId?: string): Token | TokenTemplate | undefined {
    if (!tokenId) {
        return undefined;
    }

    const location = locationId != null ? campaign.locations[locationId] : undefined;
    if (!location) {
        // This can only be a template.
        return campaign.tokens[tokenId];
    }

    // Either token from the location, or a template.
    if (isLocation(location)) {
        return location.tokens[tokenId] ?? campaign.tokens[tokenId];
    }

    return undefined;
}

export function getGridTokensAt(
    location: Location,
    pos: GridPosition | LocalPixelPosition,
    grid: ILocalGrid,
    role: CampaignRole
) {
    const gridPos = grid.toGridPoint(pos);

    const tokens: Token[] = [];
    for (let tokenId in location.tokens) {
        const token = location.tokens[tokenId];
        if (
            token.pos.type === PositionType.Grid &&
            grid.contains(gridPos, token.pos, token.scale) &&
            (token.isPlayerVisible == null || token.isPlayerVisible || role === "GM") &&
            tokens.indexOf(token) < 0
        ) {
            tokens.push(token);
        }
    }

    return tokens;
}

export function getTokenType(campaign: Campaign, token: TokenTemplate): TokenType | undefined;
export function getTokenType(
    campaign: Campaign,
    token: Omit<Token, "pos">,
    template?: TokenTemplate
): TokenType | undefined;
export function getTokenType(
    campaign: Campaign,
    token: Omit<Token, "pos"> | TokenTemplate,
    template?: TokenTemplate
): TokenType | undefined {
    if (token.type) {
        return token.type;
    }

    if (!template && token.templateId && isToken(token) && !token.ignoreTemplate) {
        template = campaign.tokens[token.templateId];
    }

    if (template && template.type) {
        return template.type;
    }

    return (
        token.type ??
        (token.imageUri
            ? "creature"
            : token.light
            ? "light"
            : token.sound
            ? "audio"
            : template
            ? getTokenType(campaign, template)
            : undefined)
    );
}

export type ResolvedToken<T extends Token = Token> = T & { resolvedFrom: Token };

export function resolveToken<T extends Token>(campaign: Campaign, token: T): ResolvedToken<T>;
export function resolveToken<T extends Token>(campaign: Campaign, token: T | undefined): ResolvedToken<T> | undefined;
export function resolveToken<T extends Token>(campaign: Campaign, token: T | undefined): ResolvedToken<T> | undefined {
    if (!token) {
        return undefined;
    }

    if (token.templateId && !token.ignoreTemplate) {
        const template = campaign.tokens[token.templateId];
        if (template) {
            // Need to make sure that the system props (i.e. dnd5e prop) are left intact, but that other basic token properties
            // are merged from the template.
            return Object.assign({}, token, {
                id: token.id,
                pos: token.pos,
                type: getTokenType(campaign, token, template),
                imageUri: token.imageUri ?? template.imageUri,
                portraitImageUri: token.portraitImageUri ?? template.portraitImageUri,
                modelUri: token.modelUri ?? template.modelUri,
                canRotate: token.canRotate ?? template.canRotate,
                defaultRotation: token.defaultRotation ?? template.defaultRotation,
                isPlayerVisible: token.isPlayerVisible,
                light: token.light ?? template.light,
                owner: token.owner ?? template.owner,
                rotation: token.rotation,
                scale: token.scale ?? template.scale,
                renderScale: token.renderScale ?? template.renderScale,
                sound: token.sound ?? template.sound,
                templateId: token.templateId,
                resolvedFrom: token,
            });
        }
    }

    return { ...token, resolvedFrom: token };
}

export function getTokenOwner(campaign: Campaign, token: Token) {
    if (token.owner != null) {
        return token.owner;
    }

    if (token.templateId != null) {
        return campaign.tokens[token.templateId]?.owner;
    }

    return undefined;
}
